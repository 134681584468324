import { Collections } from '@local/client-contracts';

type COLLECTION_ICON_TYPE = 'icon-curated' | 'icon-live-collection' | 'icon-wiki';
type CollectionTypeToIcon = {
  [key in Collections.Kind]: COLLECTION_ICON_TYPE;
};

export const COLLECTION_TYPE_TO_ICON_MAP: CollectionTypeToIcon = {
  Live: 'icon-live-collection',
  Static: 'icon-curated',
  Wiki: 'icon-wiki',
};
