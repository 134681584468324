import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PopupRef } from '@local/ui-infra';
import { Collections } from '@local/client-contracts';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeyboardHelperService } from '@shared/helper/keyboard-helper.service';
import { LogService } from '@shared/services';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { isKey, keyCodes, KeyName } from '@local/ts-infra';
import { Logger } from '@unleash-tech/js-logger';
import { cloneDeep, isArray } from 'lodash';
import { NgScrollbar } from 'ngx-scrollbar';
import { AvatarItemModel } from 'src/app/bar/models/avatar-item.model';
import { HubService } from 'src/app/bar/services/hub.service';
import { ScrollService } from '../../views/results/services/scroll.service';
import { SelectedItemPopupData } from './models/select-item-base';
import { randomColor } from '@shared/utils';

export interface SelectedItem {
  isNew?: boolean;
  collection?: Partial<Collections.Collection>;
  avatarList?: AvatarItemModel[];
  itemsNumber?: number;
  name?: string;
  id?: string;
  icon?: string;
  iconColor?: string;
  disable?: boolean;
  isInvalid?: boolean;
}

export type SelectedItemModel = SelectedItem | SelectedItem[];

export type TelemetryEvent = { target?: string; trigger?: string };
@UntilDestroy()
@Component({
  selector: 'selected-item-popup',
  templateUrl: './selected-item-popup.component.html',
  styleUrls: ['./selected-item-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedItemPopupComponent implements OnInit, OnDestroy {
  private readonly NO_RESULTS_TAB_NAME = 'No results';
  private readonly MAX_HEIGHT = 350;

  // keyboard
  private keysHandlerId: string;
  private keyboardHelperService: KeyboardHelperService;
  private collections: Collections.Collection[] = [];

  private newName: string;
  private selectedIndex = null;
  private hideAutoComplete = false;

  // scroll
  private scrollService: ScrollService<any>;
  private logger: Logger;

  data: SelectedItemPopupData;
  multiple: boolean;
  wikiId: string;
  scrollHeight: string;

  selectedItem: SelectedItemModel;
  chosenItems: SelectedItem[] = [];

  @ViewChild(CdkVirtualScrollViewport) scrollViewport: CdkVirtualScrollViewport;
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;

  @Output() primaryButton = new EventEmitter<{ target: string; selectedItem: SelectedItemModel; checkboxChecked?: boolean }>();
  @Output() onSelect = new EventEmitter<SelectedItemModel>();
  @Output() onTelemetryEvent = new EventEmitter<TelemetryEvent>();

  constructor(
    private hubService: HubService,
    private ref: PopupRef<SelectedItemPopupComponent, SelectedItemPopupData>,
    private cdr: ChangeDetectorRef,
    private keyboardService: KeyboardService,
    private ngZone: NgZone,
    logService: LogService
  ) {
    this.logger = logService.scope('SelectedItemPopupComponent');
  }

  ngOnDestroy(): void {
    this.keyboardService.unregisterKeyHandler(this.keysHandlerId);
    if (this.data?.displaySelectedItems) {
      this.onTelemetryEvent.emit({ target: 'add' });
      this.primaryButton.emit({
        target: 'save',
        selectedItem: this.chosenItems,
      });
    }
  }

  ngOnInit(): void {
    this.data = cloneDeep(this.ref.data);
    this.multiple = this.data?.autoCompleteSettings?.multiple;
    this.selectedItem = this.data.selected;
    if (this.data?.displaySelectedItems) {
      this.onAddButton();
    }
    this.setUpKeyboardHelperService();
  }

  async onSelectItem(item: SelectedItemModel) {
    const isNoResultItem = !isArray(item) && item?.name === this.NO_RESULTS_TAB_NAME;
    if (item === null || isNoResultItem) {
      this.selectedItem = null;
      this.cdr.markForCheck();
      return;
    }

    if (!this.multiple && !isArray(item)) {
      if (!item?.isNew && this.ref.data.isInvalidItem(item)) return;
    } else if (this.multiple && isArray(item)) {
      if (item.some((i) => this.ref.data.isInvalidItem(i))) return;
    }

    this.selectedItem = item;

    this.onSelect.emit(this.selectedItem);
    this.onTelemetryEvent.emit({});
    this.cdr.markForCheck();
  }

  async onPrimaryClick(target?: string, trigger?: string) {
    if (isArray(this.selectedItem) && !this.data.allowPrimaryClick) {
      this.onAddButton();
      return;
    }
    this.selectedItem = this.selectedItem as SelectedItem;
    if (target) {
      this.onTelemetryEvent.emit({ target, trigger });
    }
    this.primaryButton.emit({
      target: 'save',
      selectedItem: { ...this.selectedItem, name: this.selectedItem.name },
    });
    setTimeout(() => {
      this.ref.destroy();
    }, 0);
  }

  onAddButton() {
    const selected = this.selectedItem as SelectedItem[];
    if (!selected?.length) return;
    this.chosenItems.push(...selected);
    this.selectedItem = [];
    this.chosenItems.forEach((elm) => {
      if (elm.id === 'home') {
        elm.icon = 'icon-home';
        elm.iconColor = '#3D4247';
      } else {
        elm.iconColor = randomColor();
      }
      if (!elm.isNew) {
        const item = this.data?.itemsList.find((i) => i.id === elm.id);
        item.disable = true;
      }
    });

    this.calcScrollHeight();
    this.cdr.markForCheck();
  }

  calcScrollHeight() {
    const height = this.chosenItems.length * 50;
    this.scrollHeight = (height > this.MAX_HEIGHT ? this.MAX_HEIGHT : height + this.chosenItems.length * 10) + 'px';
  }

  rightButtonClick(selected: SelectedItem, index: number) {
    if (isArray(this.selectedItem)) {
      const afterButtonClick = () => {
        this.chosenItems.splice(index, 1);
        const item = this.data?.itemsList.find((i) => i.id === selected.id);
        item.disable = false;
        this.calcScrollHeight();
        this.cdr.markForCheck();
      };
      this.data.checkButtonClick(afterButtonClick, selected);
    }
  }

  inputChanged(event) {
    if (event.hasOwnProperty('query')) return;
    const selectedItem = this.selectedItem as SelectedItem;
    if (!this.multiple && selectedItem?.isNew) {
      selectedItem.isInvalid = this.newName !== event;
    }
    this.newName = event;
    this.cdr.markForCheck();
  }

  onCancelClick(target: string) {
    this.onTelemetryEvent.emit({ target });
    this.ref.destroy();
  }

  // keyboard handling
  setUpKeyboardHelperService() {
    this.keyboardHelperService = new KeyboardHelperService();
    this.keyboardHelperService.updateCdr.pipe(untilDestroyed(this)).subscribe(() => this.cdr.markForCheck());
    this.scrollService = new ScrollService(this.ngZone);
    this.registerKeyHandler();
  }

  private registerKeyHandler() {
    if (this.keysHandlerId) return;
    this.keysHandlerId = this.keyboardService.registerKeyHandler((keys, event) => {
      this.keyboardHelperService.handleArrows(keys, event);
      this.scrollService.scrollIfNeeded(this.selectedIndex, this.collections);
      this.handleKeys(keys, event);
      event.stopPropagation();
    }, 9);
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    if (isKey(event, keyCodes.enter)) {
      event.stopPropagation();
      event.preventDefault();
      if (this.selectedItem) {
        this.onPrimaryClick('add', 'keyboard');
        return;
      }
    }
    if (isKey(event, keyCodes.escape)) {
      if (this.hideAutoComplete) {
        this.hideAutoComplete = false;
        return;
      }
      this.ref.destroy();
      this.hideAutoComplete = false;
    }
  }

  onHideAutoComplete() {
    this.hideAutoComplete = true;
  }
}
