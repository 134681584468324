<div *ngIf="initials; else iconTemplate" [ngClass]="['profile', avatarShape]" [ngStyle]="{ 'background-color': color }" [style]="sizeAvatar">
  {{ initials }}
</div>
<ng-template #iconTemplate>
  <u-icon
    [model]="model"
    [fallback]="fallback"
    [ngClass]="{
      'user-avatar': true, 
      'circle-avatar': avatarShape === 'circle', 
      'rectangle-avatar': avatarShape === 'rectangle'
    }"
    [styles]="sizeAvatar"
    [customClass]="customClass"
    [referrerpolicy]="'no-referrer'"
  ></u-icon
></ng-template>
