import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Applications, Style } from '@local/client-contracts';
import { isNativeWindow } from '@local/common-web';
import { PopupService } from '@local/ui-infra';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TabsComponent } from '@shared/components/tabs/tabs.component';
import { EventsService, LogService } from '@shared/services';
import { ApplicationsService } from '@shared/services/applications.service';
import { NativeAppLinkService } from '@shared/services/native-app-link.service';
import { ReportsService } from '@shared/services/reports.service';
import { RouterService } from '@shared/services/router.service';
import { componentServicesRpcProvider, ServicesRpcService } from '@shared/services/rpc.service';
import { Logger } from '@unleash-tech/js-logger';
import { NgScrollbar } from 'ngx-scrollbar';
import { BehaviorSubject, combineLatest, firstValueFrom, ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { HubService } from 'src/app/bar/services/hub.service';
import { ChatInputComponent } from '../../../components/chat-input/chat-input.component';
import { ToasterData } from '../../../components/toaster/toaster-data';
import { AppsFilter } from './apps-filter';
import { AppsPipe } from './apps.pipe';
import { ShowToasterService } from 'src/app/bar/services/show-toaster.service';

const tabs = ['all', 'local', 'linked'] as const;
type Tabs = (typeof tabs)[number];

@UntilDestroy()
@Component({
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss', './shared.scss'],
  providers: [componentServicesRpcProvider, AppsPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppsComponent implements OnInit, OnDestroy {
  @ViewChild('tabs') tabsComp: TabsComponent;
  @ViewChild('chatInput') chatInputComp: ChatInputComponent;
  @ViewChild('noResults') noResults: ElementRef;
  @ViewChild(NgScrollbar) scrollContainer: NgScrollbar;

  filter: AppsFilter = {};
  previousFilter: AppsFilter;
  logger: Logger;
  hasLocal: boolean;

  activeTab: Tabs = 'all';
  private _activeTab: Tabs = this.activeTab;
  private appsLength: number;
  private readonly destroy$ = new Subject<void>();
  readonly SUGGESTIONS_MIN_LENGTH = 2;
  readonly SUGGESTIONS_MAX_LENGTH = 50;
  private readonly isNativeWindow = isNativeWindow();
  private readonly TOASTER_DATA: ToasterData = {
    id: 'data-success',
    content: 'Thank you. We got your message and we’ll get back to you soon!',
  };

  items: Applications.DisplayItem[];
  recommended: Applications.DisplayItem[];
  notRecommended: Applications.DisplayItem[];
  local: Applications.DisplayItem[];
  linked: Applications.DisplayItem[];
  searchQuery: string;
  keysHandlerId: string;
  pageViewEventSent: boolean;
  loading$ = new BehaviorSubject<boolean>(true);
  emptyStateIcon: Style.Icon;
  isInternalUser: boolean;
  tabChange$ = new Subject<string>();
  dataReady$ = new ReplaySubject<boolean>();

  constructor(
    private applicationServices: ApplicationsService,
    private services: ServicesRpcService,
    private route: ActivatedRoute,
    private eventsService: EventsService,
    private hubService: HubService,
    private cdr: ChangeDetectorRef,
    private popupService: PopupService,
    private appsPipe: AppsPipe,
    private reportsService: ReportsService,
    private routerService: RouterService,
    private nativeAppLinkService: NativeAppLinkService,
    private showToasterService: ShowToasterService,
    logService: LogService
  ) {
    this.logger = logService.scope('AppsComponent');

    this.emptyStateIcon = {
      lightUrl: './assets/empty-state/no-apps/empty-state-no-apps-light.svg',
      darkUrl: './assets/empty-state/no-apps/empty-state-no-apps-dark.svg',
    };
  }

  ngOnInit(): void {
    this.hubService.enableCollectionIconFunc = undefined;
    this.hubService.readOnly = false;
    this.hubService.searchMethod = 'Quick-Search';
    this.hubService.placeholder = 'Search for app';
    this.initSubscriptions();
    this.hubService.changeFocusStateMultiCalls(true, true);
    this.pageViewEventSent = false;
  }

  ngOnDestroy(): void {
    this.services.destroy();
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
    this.tabChange$.next('');
  }

  get notRecommendedTitle() {
    return {
      title: 'Explore all apps',
      titleEnd: this.notRecommended?.length ? `(${this.notRecommended?.length})` : null,
    };
  }

  get linkedTitle() {
    return {
      title: 'Connected apps',
      titleEnd: this.linked?.length ? `(${this.linked?.length})` : null,
    };
  }

  get searchTitle() {
    return `Showing ${this.appsLength} Apps`;
  }

  private async initSubscriptions() {
    this.dataReady$.next(false);
    combineLatest([this.routerService.active$, this.routerService.queryParams$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([route, params]) => {
        if (Object.keys(params).length === 0) {
          this.tabChange$.next('all');
        }
      });
    if (!this.isNativeWindow) {
      this.nativeAppLinkService?.status$.pipe(takeUntil(this.destroy$)).subscribe(async () => {
        this.onAllUpdate((await firstValueFrom(this.applicationServices.all$)) || []);
        this.onSortedUpdate(await firstValueFrom(this.applicationServices.sorted$));
        this.dataReady$.next(true);
        this.cdr.markForCheck();
      });
    }

    this.loading$.pipe(takeUntil(this.destroy$)).subscribe((loading) => {
      this.hubService.preventSearch = loading;
      if (!loading) {
        this.hubService.changeFocusStateMultiCalls(true, true);
      }
    });

    combineLatest([this.applicationServices.all$, this.applicationServices.sorted$])
      .pipe(
        takeUntil(this.destroy$),
        filter(([all, sorted]) => !!all && !!sorted)
      )
      .subscribe(([all, sorted]) => {
        this.onAllUpdate(all);
        this.onSortedUpdate(sorted);
        this.dataReady$.next(true);
        this.loading$.next(false);
        this.cdr.markForCheck();
      });

    this.route.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe((params) => this.onParamsUpdate(params));
  }

  private async onSortedUpdate(sorted: Applications.SortedDisplayItems) {
    for (const property in sorted || {}) {
      sorted[property] = await this.applicationServices.ignoreApps(sorted[property]);
      this.cdr.markForCheck();
    }

    const { recommended, local, linked, alphabetical } = sorted;
    this.updateLocalTab(local);
    this.recommended = [];
    this.local = [];
    this.linked = [];
    this.notRecommended = [];
    this.recommended = recommended || [];
    this.local = local;
    this.linked = linked;
    this.notRecommended = [...sorted.static, ...alphabetical];
  }

  get showFilteredList(): boolean {
    return Object.keys(this.filter).length > 0;
  }

  get selectedTabItems(): Applications.DisplayItem[] {
    switch (this.activeTab) {
      case 'all':
        return this.items;
      case 'linked':
        return this.linked;
      case 'local':
        return this.local;
      default:
        return this.items;
    }
  }

  private updateLocalTab(local: Applications.DisplayItem[]): void {
    const localChanged = local?.length !== this.local?.length;

    if (localChanged && this.local?.length > 0) {
      this.setFilter({ local: true });
      this.tabsComp?.selectTab('local');
      this.activeTab = 'local';
    }
  }

  private async onParamsUpdate(params: ParamMap) {
    await firstValueFrom(this.dataReady$.pipe(filter((v) => !!v)));
    const filterFromUrl: Partial<AppsFilter> = {};
    params.keys.forEach((key) => {
      if (params.get(key) === 'true') {
        filterFromUrl[key] = true;
      }
      if (params.get(key) === 'false') {
        filterFromUrl[key] = false;
      }
    });

    this.onSearchInput(params.get('q'));
    this.filter = filterFromUrl;
    this.activeTab = this.filterToTabId(filterFromUrl);
    this.cdr.markForCheck();
  }

  private async onAllUpdate(apps: Applications.DisplayItem[]): Promise<void> {
    const list = await this.applicationServices.ignoreApps(apps);
    this.cdr.markForCheck();
    this.items = list;
  }

  connectApps() {
    this.onTabChange('all');
    setTimeout(() => {
      this.tabsComp.selectTab('all');
    }, 0);
  }

  onTabChange(event: Tabs): void {
    if (this._activeTab === event) return;
    switch (event) {
      case 'all':
        this.setFilter({});
        break;
      case 'linked':
        this.setFilter({ linked: true });
        this.tabChange$.next('');
        break;
      case 'local':
        this.setFilter({ local: true });
        break;
    }
    this._activeTab = event;
    this.scrollContainer?.scrollTo({ top: 0 });
  }

  private onSearchInput(query: string): void {
    this.searchQuery = query?.trim();
    if (query?.length > 2) {
      this.eventsService.event('search_apps', { search: { query }, location: { title: 'apps.all' } });
    }
    if (!query?.length) {
      this.pageViewEventSent = false;
    }
    const items =
      this.searchQuery && this.selectedTabItems ? this.appsPipe.transform(this.selectedTabItems, { query: this.searchQuery }) : [];
    this.appsLength = items.length;
    if (!this.appsLength && !this.pageViewEventSent) {
      this.eventsService.event('apps_no_results', { location: { title: 'apps.all/no_results' } });
      this.pageViewEventSent = true;
    }
    this.cdr.markForCheck();
  }

  setFilter(filter: Partial<AppsFilter>) {
    if (JSON.stringify(this.filter) === JSON.stringify(filter)) {
      return;
    }

    Object.entries(this.filter).forEach(([k, v]) => this.hubService.removeState(k, v + ''));

    if (this.activeTab === 'all') {
      this.eventsService.event('apps_view_mode.all_apps');
    } else if (this.activeTab === 'linked') {
      this.eventsService.event('apps_view_mode.my_apps');
    }
    this.eventsService.event('pageview', { location: { title: 'apps.' + this.activeTab } });

    for (const key in filter) {
      this.hubService.addState(key, [filter[key] + '']);
    }

    this.cdr.markForCheck();
  }

  private filterToTabId(filter: AppsFilter): Tabs {
    if (Object.keys(filter).length === 0) {
      return 'all';
    }
    if (filter.linked) {
      return 'linked';
    }
    if (filter.local) {
      return 'local';
    }
    return 'all';
  }

  async send(message: string) {
    await this.reportsService.submit('Suggestion', message);
    this.showToasterService.showToaster(this.TOASTER_DATA);
  }
}
