import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { Search } from '@local/client-contracts';
import { PopupRef } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HubService } from 'src/app/bar/services/hub.service';
import { DynamicComponentBase } from '@local/ui-infra';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '@shared/loader.service';
import { EmbedService } from '@shared/embed.service';
import { isNativeWindow, isEmbed } from '@local/common-web';
import { WindowService } from '@shared/services';
import { DisplayItemData } from 'src/app/bar/views/results';

@UntilDestroy()
@Component({
  selector: 'file-preview-popup',
  templateUrl: './file-preview-popup.component.html',
  styleUrls: ['./file-preview-popup.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilePreviewPopupComponent implements OnInit, DynamicComponentBase<any> {
  data: { item: Search.ResultResourceItem & DisplayItemData };
  componentFather: any;
  model: Search.ResultResourceItem;
  isLauncher: boolean;
  @Output() loaded = new EventEmitter<any>();

  windowMode = false;
  private ref: PopupRef<FilePreviewPopupComponent, Search.ResultResourceItem>;

  constructor(
    private cdr: ChangeDetectorRef,
    private hubService: HubService,
    private activeRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private injector: Injector,
    private windowService: WindowService,
    private embedService: EmbedService
  ) {}

  ngOnInit(): void {
    this.windowMode = this.activeRoute.snapshot.data.windowMode;

    if (this.windowMode) {
      this.loaderService.ready$.next(true);
      this.model = this.data.item;
    } else {
      this.ref = this.injector.get(PopupRef);
      this.model = this.ref.data;
    }

    this.hubService.isLauncher$.pipe(untilDestroyed(this)).subscribe((l) => (this.isLauncher = l));
    this.cdr.markForCheck();
  }

  closePopup() {
    this.ref?.destroy();
    if (this.windowMode) {
      if (isNativeWindow()) this.windowService.close();
      else if (isEmbed()) this.embedService.close();
    }
  }
}
