import { Injectable } from '@angular/core';
import { NativeApp } from '@local/client-contracts';
import { NativeAppLinkRpcInvoker, observable } from '@local/common';
import { isNativeWindow } from '@local/common-web';
import { ServicesRpcService } from '@shared/services/rpc.service';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NativeAppLinkService implements NativeApp.LinkService {
  private service: NativeApp.LinkService;
  private status: string;
  private _status$ = new ReplaySubject<NativeApp.Status>(1);
  private _trackingIds$ = new ReplaySubject<NativeApp.TrackingIds>(1);
  private _canSearchPc$ = new ReplaySubject<boolean>(1);
  private readonly isNativeWindow = isNativeWindow();

  @observable
  get canSearchPc$(): Observable<boolean> {
    return this._canSearchPc$;
  }

  get status$(): Observable<NativeApp.Status> {
    return this._status$;
  }

  get trackingIds$(): Observable<NativeApp.TrackingIds> {
    return this._trackingIds$;
  }

  constructor(services: ServicesRpcService) {
    this.service = services.invokeWith(NativeAppLinkRpcInvoker, 'nativeapplink');
    if (!this.isNativeWindow) {
      this.service.status$.subscribe((res: NativeApp.Status) => {
        this._status$.next(res);
        this.status = res;
        this._canSearchPc$.next(this.canSearchPc());
      });
      this.service.trackingIds$.subscribe((ids) => {
        this._trackingIds$.next(ids);
      });
    } else {
      this._canSearchPc$.next(true);
      this._status$.complete();
      this._trackingIds$.complete();
    }
  }

  link(t: NativeApp.TrackingIds): Promise<void> {
    return this.service.link(t);
  }

  setBlink(v: string): Promise<void> {
    return this.service.setBlink(v);
  }

  canSearchPc() {
    return this.isNativeWindow || (this.status && this.status === 'running');
  }
}
