import { Injectable } from '@angular/core';
import { HomePins, HomeTabs } from '@local/client-contracts';
import { observable, performanceCheckpoint } from '@local/common';
import { isEmbed } from '@local/common-web';
import { isEmpty, isEqual } from 'lodash';
import { filter, firstValueFrom, map, Observable, ReplaySubject, Subject } from 'rxjs';
import { HomePinsService } from 'src/app/bar/services/home-pins.service';
import { HomeTabsService } from 'src/app/bar/services/home-tabs.service';
import { EmbedService } from '@shared/embed.service';
import { WorkspacesService } from 'src/app/bar/services';
import { GridChanges, GridLayoutSettings, GridSelectorType, GridWidgetSettings } from '../widgets/grid/grid.component';

export const SETTINGS_WIDGET = {
  minRows: 1,
  minCols: 1,
  maxCols: 3,
  cellHeight: 53,
  baseRows: 6,
};

export type Published = HomeTabs.Widget & { firstTime?: boolean }; // first time the widget was published (and not saved as the last variable)

@Injectable()
export class WidgetsService {
  private readonly isEmbed = isEmbed();
  private disabled: boolean;
  private resizeEvent$ = new Subject<GridLayoutSettings>();
  private changeEvent$ = new Subject<GridChanges>();
  private _scrollToWidget$ = new Subject<HomeTabs.Widget>();
  private _published$ = new Subject<Published>();
  private _published: Published;

  editMode: boolean;
  triggerWidgetFocus$ = new Subject<string>();
  firstLoad = false;

  private _widgetsSettings$ = new ReplaySubject<HomeTabs.Widget[]>(1);

  @observable
  get published$() {
    return this._published$.asObservable();
  }

  get published(): Published {
    return this._published;
  }

  set published(widget: Published) {
    this._published = widget;
    this._published$.next(widget);
  }

  constructor(
    private homePinService: HomePinsService,
    private homeTabsService: HomeTabsService,
    private embedService: EmbedService,
    private workspacesService: WorkspacesService
  ) {
    this.setup();
  }

  async setup() {
    performanceCheckpoint('time-to-dashboard-init');
    await this.checkDisabledWidgets();
    if (this.disabled) {
      return;
    }
  }

  @observable
  get scrollToWidget$() {
    return this._scrollToWidget$.asObservable();
  }

  getResize$(id: string): Observable<GridLayoutSettings> {
    return this.resizeEvent$.pipe(filter((a) => a.id === id));
  }

  submitResizeEvent(event: GridLayoutSettings) {
    this.resizeEvent$.next(event);
  }

  getChange$(id: string): Observable<GridLayoutSettings> {
    return this.changeEvent$.pipe(
      map((a) => a.find((b) => b.id === id)),
      filter((a) => !!a)
    );
  }

  submitChangeEvent(event: GridChanges) {
    this.changeEvent$.next(event);
  }

  async checkDisabledWidgets() {
    if (this.isEmbed) {
      const embedInline = await this.embedService?.isInline();
      this.disabled = this.isEmbed && !embedInline;
    }
    this.disabled = false;
  }

  private getWidgetSelector(widget: HomeTabs.Widget): GridSelectorType {
    switch (widget.type) {
      case 'recently_viewed':
        return 'recents-widget';
      case 'calendar':
        return 'calendar-widget';
      case 'favorites':
        return 'favorites-widget';
      case 'post':
        return 'post-widget';
      case 'header':
        return 'header-widget';
      default:
        return 'collection-widget';
    }
  }

  getWidgetSettings$<T extends HomeTabs.Widget>(widgetId: string) {
    return this._widgetsSettings$.pipe(
      map((widgets) => widgets?.find((w) => w.id === widgetId) as T),
      filter((widgets) => !!widgets)
    );
  }

  setWidgetSettings(widgetSettings: HomeTabs.Widget[]) {
    this._widgetsSettings$.next(widgetSettings);
  }

  getViewSettings(elm: HomeTabs.Widget): GridWidgetSettings {
    const { cols, rows, x, y } = elm.viewSetting;
    const selector: GridSelectorType = this.getWidgetSelector(elm);
    const noResize = !this.workspacesService.isOwnerOrAdmin || ['post-widget', 'header-widget'].includes(selector);
    return {
      id: elm.id,
      w: cols,
      h: rows,
      x,
      y,
      selector,
      noResize,
      noMove: !this.workspacesService.isOwnerOrAdmin,
      minH: elm.type === 'header' ? SETTINGS_WIDGET.minRows : SETTINGS_WIDGET.baseRows,
      minW: SETTINGS_WIDGET.minCols,
      input: { widgetId: elm.id },
    } as GridWidgetSettings;
  }

  async updateSettingWidget(updateItem: HomeTabs.Widget) {
    if (!updateItem || isEmpty(updateItem)) return;
    const allTabs = await firstValueFrom(this.homeTabsService.all$);
    const tab: HomeTabs.HomeTab = allTabs.find((t) => t.id === updateItem.tabId);
    const changedWidget = tab?.widgets?.some((w) => w.id === updateItem.id && !isEqual(w, updateItem));
    if (!tab || !changedWidget) return;
    return this.homeTabsService.updateWidgets(updateItem.tabId, [updateItem]);
  }

  async createHomePin(homePin: HomePins.HomePin): Promise<HomePins.HomePin> {
    return this.homePinService.create(homePin);
  }

  async add(tabId: string, widget: HomeTabs.Widget, addAsFirst?: boolean, newState?: boolean) {
    const newWidget = await this.homeTabsService.addWidget(tabId, widget, addAsFirst, newState);
    this.published = { ...newWidget, firstTime: true };
    this._scrollToWidget$.next(newWidget);
  }

  update(tabId: string, widgets: HomeTabs.Widget[]) {
    return this.homeTabsService.updateWidgets(tabId, widgets);
  }

  delete(tabId: string, widgetId: string) {
    return this.homeTabsService.deleteWidget(tabId, widgetId);
  }
}
