<div class="badges-container">
  <bar-badge
    *ngIf="(isOnline$ | async) === false"
    class="badge"
    pTooltip="Offline mode. Unleash has the best experience when you are online"
    tooltipPosition="bottom"
    tooltipStyleClass="u-tooltip"
    tabindex="0"
    [intent]="'primary'"
    [text]="'Offline mode'"
  >
    <u-icon #icon [model]="{ type: 'font', value: 'icon-offline' }"></u-icon>
  </bar-badge>
  <u-icon
    *ngIf="pageType === 'home' && isOwnerOrAdmin"
    (click)="customizeBtnClicked()"
    class="icon"
    data-cy="customize-appearance"
    [model]="{ type: 'font', value: 'icon-edit' }"
    tooltipPosition="bottom"
    pTooltip="Customize appearance"
    tooltipStyleClass="u-tooltip"
    [ngClass]="{ focused: methodsList[selectedIndex]?.id === 'customize' }"
  ></u-icon>
  <u-icon
    *ngIf="pageType === 'home' && isOwnerOrAdmin"
    (click)="announcementBtnClicked()"
    class="icon"
    data-cy="create-new-post"
    [model]="{ type: 'font', value: 'icon-create-widget' }"
    tooltipPosition="bottom"
    pTooltip="Create new post"
    tooltipStyleClass="u-tooltip"
    [ngClass]="{ focused: methodsList[selectedIndex]?.id === 'post' }"
  ></u-icon>
  <u-icon
    #quickLinks
    *ngIf="pageType === 'home' && !disableQuickLinks"
    (click)="quickLinksBtnClicked($event)"
    class="icon"
    data-cy="quick-links"
    [model]="{ type: 'font', value: 'icon-apps' }"
    tooltipPosition="bottom"
    pTooltip="Quick Links"
    tooltipStyleClass="u-tooltip"
    [ngClass]="{ active: popupQuickLinkOpen, focused: methodsList[selectedIndex]?.id === 'quick_links' }"
  ></u-icon>
  <control-button
    id="error"
    #errorButton
    *ngIf="!!error"
    [pTooltip]="error?.message ?? error"
    tooltipPosition="bottom"
    tooltipStyleClass="u-tooltip"
    class="control-button"
    [intent]="'danger'"
    [icon]="'icon-warning2'"
    tabindex="0"
    (click)="$event.stopPropagation(); onErrorClick()"
    [active]="(activePage$ | async) === 'error'"
  >
  </control-button>
</div>
