<div class="downloads-wrapper" *ngIf="downloadsService.allCount" [@fade]>
  <smooth-height [trigger]="isOpen">
    <div class="downloads-container" [hidden]="!isOpen" [ngClass]="{ multiple: downloadsService.allCount > 1 }">
      <ng-container *ngFor="let download of all | keyvalue; index as i; trackBy: trackById">
        <download-item
          *ngIf="download.value"
          [index]="i"
          [downloadRes]="download.value"
          [animate]="downloadsService.allCount > 1"
          (open)="onOpen($event)"
          (show)="onShow($event)"
          (clear)="onClear($event)"
          (cancel)="onCancel($event)"
          (retry)="onRetry($event)"
        ></download-item>
      </ng-container>
    </div>
  </smooth-height>
  <div
    (click)="toggle()"
    class="downloads-bar"
    [ngClass]="{ active: downloadsService.activeCount, hidden: downloadsService.allCount < 2, open: isOpen }"
  >
    <div class="progress-bar-container" *ngIf="downloadsService.activeCount" [@change]>
      <div class="progress-bar">
        <mat-progress-spinner
          [mode]="downloadsService.activeCount ? 'determinate' : 'indeterminate'"
          [diameter]="26"
          [value]="downloadsService.activeCount"
          [strokeWidth]="1"
        ></mat-progress-spinner>
        <u-icon class="icon" [model]="{ type: 'font', value: 'icon-download2' }"></u-icon>
      </div>
    </div>
    <div
      class="text"
      [pTooltip]="
        !isOpen && downloadsService.activeCount
          ? downloadsService.activeCount + ' file' + (downloadsService.activeCount > 1 ? 's' : '') + ' downloading'
          : undefined
      "
      tooltipPosition="bottom"
      tooltipStyleClass="u-tooltip"
    >
      {{ downloadsService.activeCount ? 'All Downloads' : downloadsService.completedCount + ' downloads completed' }}
    </div>

    <div class="actions">
      <div class="clear-all" (click)="clearAll()" [hidden]="!downloadsService.activeCount" [@change]>Clear All</div>
      <div class="expand-toggle">
        <u-icon class="icon" [model]="{ type: 'font', value: 'icon-chevron-up' }" [ngClass]="{ open: isOpen }"></u-icon>
      </div>
    </div>
  </div>
</div>
