import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { capitalCase, isDateInRange, isValidDate, upperCaseWords } from '@local/ts-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';
import { Calendar } from 'primeng/calendar';
import { PopupRef } from '../../services/popup/popup-ref';
import { UInputComponent } from '../u-input/u-input.component';
import { DatePickerOption, DatePickerPopupData, OptionsDropdownSettings } from './date-picker-data.model';
import { DateValue } from './date-value.model';

type InputType = 'start' | 'end';
type Inputs<T> = { start: T; end: T };
type Time = { year: number; month: number };

type FullDate = {
  value: Date;
  time?: Time;
};

@UntilDestroy()
@Component({
  selector: 'u-date-picker',
  templateUrl: './u-date-picker.component.html',
  styleUrls: ['./u-date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UDatePickerComponent implements OnInit, OnDestroy, AfterViewInit {
  headerTitle: string;
  readonly validInputDateFormats = ['M-D-YYYY', 'M/D/YYYY', 'M.D.YYYY', 'MMM D, YYYY', 'MM/DD/YYYY', 'MM.DD.YYYY'];
  readonly inputPlaceholderFormat = '(mm/dd/yyyy)';
  /* eslint-disable no-useless-escape */
  private readonly dateRegex = /[(\.)|(\-)]/gi;
  datePickerOptions: DatePickerOption[];
  selectedTypeValue = {};
  selectedValue: {
    start?: FullDate;
    end?: FullDate;
    type: string;
  };
  inputs: { values: Inputs<string>; errors: Inputs<boolean | string>; placeholders: Inputs<string> };
  datePickerType: string;
  selectionMode: 'range' | 'single' = 'single';
  currentDate: Time;
  popupData: DatePickerPopupData;
  supportEndPickerType: string[];
  resetDateWhenChangePicker: boolean;
  displayPlaceholderType: boolean;
  minDate: Date;
  maxDate: Date;
  dropDownOptions: OptionsDropdownSettings;
  styleHeight: number;
  optionsAppendTo = 'body';
  @ViewChild('startInput') startInput: UInputComponent;
  @ViewChild('endInput') endInput: UInputComponent;
  @ViewChild(Calendar) calendar: Calendar;
  @Output() onSelectedChange = new EventEmitter<DateValue>();

  private readonly dateFormatToSet = 'MMM D, YYYY';
  private _dateValue: Date[];

  @HostBinding('style.--height')
  get height() {
    return this.styleHeight + 'vh';
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown($event: KeyboardEvent) {
    const key = $event.code;
    if (key === 'Escape') {
      if (this.popupData.enableClosePopup && !this.popupData.enableClosePopup()) {
        return;
      }
      this.closePopup();
      $event.stopPropagation();
    }
  }

  get displayEndInput() {
    return this.supportEndPickerType.includes(this.datePickerType);
  }

  get isOneInputState() {
    return !this.supportEndPickerType.includes(this.datePickerType);
  }

  get hasEndingDateOnly() {
    const { start, end } = this.selectedValue || {};
    return !this.isOneInputState && end?.value && !start?.value;
  }

  get dateValue(): Date[] | Date {
    if (this._dateValue?.length === 1) {
      return this._dateValue[0];
    }
    return this._dateValue;
  }

  set dateValue(value: Date[] | Date) {
    if (Array.isArray(value)) {
      this._dateValue = value as Date[];
      return;
    }
    if (!value) {
      this._dateValue = [null, null];
    }
    this._dateValue = [value as Date];
  }

  constructor(private cdr: ChangeDetectorRef, private ref: PopupRef<UDatePickerComponent, DatePickerPopupData>) {}

  ngOnInit(): void {
    this.initData();
    this.setDefaultState();
    this.cdr.markForCheck();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.passSelectedValue();
      this.focusFirstInput();
    }, 100);
  }

  ngOnDestroy(): void {
    this.passSelectedValue();
  }

  private initData() {
    this.popupData = this.ref.data;
    const {
      datePickerOptions,
      filterTitle,
      styleHeight,
      datePickerOptionsSubject$,
      defaultDatePickerType,
      resetDateWhenChangePicker,
      supportEndPickerType,
      displayPlaceholderType,
      minDate,
      maxDate,
      optionsDropdownSettings,
      optionsAppendTo,
    } = this.popupData;
    this.headerTitle = upperCaseWords(filterTitle);
    this.datePickerType = defaultDatePickerType;
    this.selectedTypeValue = datePickerOptions.find((v) => v.value == defaultDatePickerType);
    this.datePickerOptions = datePickerOptions;
    this.supportEndPickerType = supportEndPickerType || [];
    this.resetDateWhenChangePicker = resetDateWhenChangePicker;
    this.displayPlaceholderType = displayPlaceholderType;
    this.minDate = minDate;
    this.maxDate = maxDate;
    this.styleHeight = styleHeight;
    if (optionsAppendTo) {
      this.optionsAppendTo = optionsAppendTo;
    }
    if (datePickerOptionsSubject$) {
      this.initDatePickerOptionsSubject();
    }
    this.inputs = {
      values: {
        start: null,
        end: null,
      },
      errors: {
        start: false,
        end: false,
      },
      placeholders: {
        start: `${capitalCase(this.datePickerType)} ${this.inputPlaceholderFormat}`,
        end: null,
      },
    };
    this.currentDate = {
      year: moment().year(),
      month: moment().month() + 1,
    };
    this.selectedValue = {
      type: this.datePickerType,
    };
    this.dropDownOptions = optionsDropdownSettings;
  }

  private initDatePickerOptionsSubject() {
    this.popupData.datePickerOptionsSubject$.pipe(untilDestroyed(this)).subscribe((options) => {
      this.datePickerOptions = options;
      const selectedOption = options.find((op) => op.selected);
      if (selectedOption) {
        this.selectedTypeValue = selectedOption;
        this.datePickerType = selectedOption.value;
        this.selectedValue.type = this.datePickerType;
      }
      this.cdr.markForCheck();
    });
  }

  setDefaultState() {
    if (!this.popupData.selectedValue) return;
    const type = this.popupData.selectedValue?.type?.toLowerCase();
    const selectedOption = this.datePickerOptions.find((option) => option.value?.toLowerCase() === type);
    this.onDatePickerTypeChange(selectedOption);
    this.selectedTypeValue = selectedOption;

    // select the dates according to the default state
    const { start, end } = this.popupData.selectedValue || {};
    const date = isValidDate(start) ? start : end;
    this.selectDate(date, 'start', true);
    if (this.supportEndPickerType.includes(type)) {
      this.selectDate(end, 'end', true);
    }
    this.setInputValue();
    this.cdr.markForCheck();
  }

  focusFirstInput() {
    setTimeout(() => {
      this.startInput?.inputElement?.el.nativeElement.focus();
    }, 50);
  }

  passSelectedValue() {
    const { start, end } = this.selectedValue || {};
    const value: DateValue = {
      start: start?.value,
      end: end?.value,
      type: this.selectedValue.type,
    };
    this.onSelectedChange.emit(value);
  }

  selectDate(value: Date, inputType: InputType, updateValue?: boolean) {
    if (!value) return;
    const otherInputType: InputType = inputType === 'start' ? 'end' : 'start';

    let isCorrectRange = true;
    if (!this.isOneInputState) {
      isCorrectRange = this.isCorrectRange(value, inputType);
      if (this.hasEndingDateOnly) {
        const end = this.selectedValue.end.value;
        this._dateValue = [value, end];
      } else if (this.selectedValue?.start?.value && this.selectedValue?.end?.value) {
        if (!isCorrectRange) {
          this._dateValue[inputType] = value;
          this._dateValue[otherInputType] = null;
          this.inputs.values[otherInputType] = null;
          this.cdr.markForCheck();
        }
      }
    }
    this.resetMarkedSelectedDateIfNeeded(isCorrectRange, false, false, false);

    if (inputType === 'start') {
      this.currentDate = {
        month: value.getMonth() + 1,
        year: value.getFullYear(),
      };
    }

    // update date value
    if (updateValue) {
      if (this.isOneInputState) {
        this._dateValue = [value];
      } else {
        if (!this.dateValue) {
          this._dateValue = [];
        }
        this._dateValue[inputType] = value;
        if (inputType == 'start' && this._dateValue[otherInputType]) {
          //when you select a start date, the end date is reset
          this._dateValue[otherInputType] = null;
        }
      }
    }
    this.selectedValue[inputType] = {
      value,
      time: { month: value.getMonth() + 1, year: value.getFullYear() },
    };
    if (!this._dateValue?.[otherInputType]) {
      this.selectedValue[otherInputType] = null;
    }
    this.markSelectedDates();
    this.passSelectedValue();
    this.cdr.markForCheck();
  }

  resetSelectedDate(inputType: InputType) {
    if (this.isOneInputState) {
      this._dateValue = null;
    } else if (this.dateValue) {
      this._dateValue[inputType] = null;
      if (this._dateValue.every((v) => !v)) {
        this._dateValue = null;
      }
    }
    this.selectedValue[inputType] = {
      value: null,
      time: null,
    };
    this.resetMarkedSelectedDateIfNeeded(false, inputType === 'start', inputType === 'end');
    this.passSelectedValue();
    this.cdr.markForCheck();
  }

  onChangeInput(event, type: InputType) {
    this.inputs.values[type] = event;
    if (event === '') {
      this.inputs.errors[type] = false;
      this.resetSelectedDate(type);
    }
  }

  saveInputValue(type: InputType) {
    const value = this.inputs.values[type];
    if (!value) {
      return;
    }
    if (!this.validateDateFormat(value)) {
      this.inputs.errors[type] = 'Invalid date';
      return;
    }
    if (!this.isInRange(value)) {
      this.inputs.errors[type] = 'Date out of range';
      return;
    }
    const date = new Date(value.replace(this.dateRegex, '/'));
    const isChanged = this.selectedValue?.[type]?.value?.getTime() !== date?.getTime();
    this.selectDate(date, type, isChanged);
    this.inputs.errors[type] = false;
  }

  private isInRange(value: string): boolean {
    const date = new Date(value);
    return isDateInRange(date, this.minDate, this.maxDate);
  }

  onFocusInput(type: InputType) {
    this.inputs.errors[type] = false;
  }

  onClickInput(type: InputType) {
    if (this.isOneInputState) return;
    const inputElement = type === 'start' ? this.startInput : this.endInput;
    inputElement?.inputElement?.el?.nativeElement?.focus();
  }

  onDatePickerTypeChange(event: { value: string }) {
    // reset everything
    if (this.resetDateWhenChangePicker) {
      if (!this.isOneInputState) {
        this._dateValue = [];
      }
      this.resetMarkedSelectedDateIfNeeded(false);
      this.resetHighlightedItems();
      this._dateValue = null;
      this.inputs.values = {
        start: null,
        end: null,
      };
      this.selectedValue.start = null;
      this.selectedValue.end = null;
    }

    this.datePickerType = event.value;
    this.selectedValue.type = this.datePickerType;
    this.selectionMode = this.isOneInputState ? 'single' : 'range';
    this.setInputsPlaceholders(event.value);
    this.passSelectedValue();
    this.cdr.markForCheck();
  }

  setInputsPlaceholders(type: string) {
    if (this.isOneInputState) {
      let placeholder: string;
      if (this.displayPlaceholderType) {
        placeholder = `${capitalCase(type)} ${this.inputPlaceholderFormat}`;
      } else {
        placeholder = this.inputPlaceholderFormat;
      }
      this.inputs.placeholders.start = placeholder;
    } else {
      this.inputs.placeholders = {
        start: `Starting ${this.inputPlaceholderFormat}`,
        end: `Ending ${this.inputPlaceholderFormat}`,
      };
    }
  }

  onChangeMonth(event) {
    this.currentDate = event;
    this.markSelectedDates();
  }

  onCalendarDateSelected(event: Date) {
    let isCorrectRange = true;
    if (!this.isOneInputState) {
      isCorrectRange = this.isCorrectRange(event);
      const { start, end } = this.selectedValue || {};
      if (this.hasEndingDateOnly) {
        this._dateValue = [event, end?.value];
      } else if (start && end) {
        this._dateValue = [event, null];
      }
    }
    this.resetMarkedSelectedDateIfNeeded(isCorrectRange);
    const dates = {};
    const keys = ['start', 'end'];
    for (let index = 0; index < this._dateValue.length; index++) {
      const d: Date = this._dateValue[index];
      if (!d) {
        continue;
      }
      const key = keys[index];
      if (d === event) {
        dates[key] = { time: this.currentDate, value: d };
      } else {
        dates[key] = this.selectedValue?.[key];
      }
    }
    this.selectedValue = { ...dates, type: this.selectedValue.type };
    this.setInputValue();
    this.passSelectedValue();
    this.markSelectedDates();
  }

  isCorrectRange(event: Date, inputType?: InputType) {
    const valuesToValidate =
      this.hasEndingDateOnly || inputType === 'start'
        ? { startDate: event, endDate: this.selectedValue?.end?.value }
        : {
            startDate: this.selectedValue?.start?.value,
            endDate: event,
          };
    const { startDate, endDate } = valuesToValidate;
    if (!startDate || !endDate) return true;

    return startDate.getTime() <= endDate.getTime();
  }

  markSelectedDates() {
    const first = this.selectedValue.start;
    const markFirst = first?.time?.month == this.currentDate.month && first?.time.year == this.currentDate.year;
    let markLast = false;
    if (!this.isOneInputState) {
      const last = this.selectedValue.end;
      markLast = last?.time?.month == this.currentDate.month && last?.time.year == this.currentDate.year;
    }
    setTimeout(() => {
      const highlightedItems = Array.from(document.getElementsByClassName('p-highlight')).filter((s) => s.localName === 'span');
      if (markFirst) {
        highlightedItems[0]?.classList.add('selected-date');
      }
      if (markLast) {
        highlightedItems[highlightedItems.length - 1]?.classList.add('selected-date');
      }
      this.cdr.markForCheck();
    }, 100);
  }

  resetMarkedSelectedDateIfNeeded(doCheck = true, firstOnly?: boolean, lastOnly?: boolean, resetInputs = true) {
    let previousSelected = Array.from(document.getElementsByClassName('selected-date'));
    if (firstOnly) {
      previousSelected = [previousSelected[0]];
    } else if (lastOnly) {
      previousSelected = [previousSelected[1]];
    }
    if (!firstOnly && !lastOnly) {
      firstOnly = true;
      lastOnly = true;
    }
    const needToReset = this.isOneInputState || (!this.isOneInputState && this.selectedValue?.start && this.selectedValue?.end);
    if (!doCheck || needToReset) {
      for (const prev of previousSelected) {
        prev?.classList.remove('selected-date');
      }
      if (!resetInputs) return;
      if (firstOnly) {
        this.inputs.values.start = null;
      }
      if (lastOnly) {
        this.inputs.values.end = null;
      }
    }
  }

  resetHighlightedItems() {
    const previousHighlighted = Array.from(document.getElementsByClassName('p-highlight'));
    for (const prev of previousHighlighted) {
      prev.classList.remove('p-highlight');
    }
  }

  setInputValue() {
    const startDate = this.selectedValue.start?.value;
    const endDate = this.selectedValue.end?.value;
    const startInput = startDate ? moment(startDate).format(this.dateFormatToSet) : null;
    this.inputs.values.start = startInput;
    const endInput = endDate ? moment(endDate).format(this.dateFormatToSet) : null;
    this.inputs.values.end = endInput;
    this.inputs.errors = {
      start: false,
      end: false,
    };
  }

  getValueIndex(inputType: InputType) {
    return inputType === 'start' ? 0 : 1;
  }

  validateDateFormat(value: string) {
    return this.validInputDateFormats.some((format) => {
      return moment(value, format, true).isValid();
    });
  }

  closePopup() {
    this.ref.destroy();
    this.ref.close();
  }
}
