import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { Commands, WebSearch } from '@local/client-contracts';
import { isEmbed } from '@local/common-web';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeysNavigationComponent } from '@shared/components/keys-navigation.component';
import { EventInfo, LogService, TelemetryService } from '@shared/services';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { RouterService } from '@shared/services/router.service';
import { WebSearchService } from '@shared/services/web-search.service';
import { isEnterKey, KeyName } from '@local/ts-infra';
import { filter, take } from 'rxjs/operators';
import * as uuid from 'uuid';
import { CommandsService } from '../../services/commands/commands.service';
import { HubService } from '../../services/hub.service';
import { TagsService } from '../../services/tags.service';
import { WEB_SEARCH_ITEMS } from '../hub/shared/sidebar/menu-items';
import { WebSearchResultItemComponent } from './web-search-results-item/web-search-results-item.component';

export type WebSearchEvent = 'session_start' | 'end' | 'redirect';

@UntilDestroy()
@Component({
  templateUrl: './web-search.component.html',
  styleUrls: ['./web-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebSearchComponent
  extends KeysNavigationComponent<WebSearch.ResultItem, WebSearchResultItemComponent>
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('view', { static: false }) viewElement: ElementRef;
  currentEngine: string;
  private currentQuery: string;
  private displayedResults: WebSearch.ResultItem[] = [];

  private textCleared: boolean;
  private responseStatus: WebSearch.Status;
  private offline = false;
  private cacheHit = false;
  private exception: string = null;

  private isNewSearchSession = true;
  private sessionStartTime: number;
  private sessionId: string;
  private clientSearchId: string;

  private readonly isEmbed: boolean = isEmbed();
  private readonly placeholder = 'Search anything across the web';
  keyboardOn = false;

  constructor(
    logService: LogService,
    private hubService: HubService,
    cdr: ChangeDetectorRef,
    private webSearchService: WebSearchService,
    private commandsService: CommandsService,
    private analyticsService: TelemetryService,
    keyboardService: KeyboardService,
    private routingService: RouterService,
    private tagsService: TagsService
  ) {
    super(logService, cdr, keyboardService);
    this.logger = logService.scope('WebSearchComponent');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToChanges();
    this.setBarPlaceholder();
    this.hubService.readOnly = false;
    this.hubService.fullFocus = true;
    setTimeout(() => {
      this.hubService.changeFocusState(true);
    }, 0);
    this.hubService.readOnly$
      .pipe(
        untilDestroyed(this),
        filter((v) => !v),
        take(1)
      )
      .subscribe(() => this.hubService.changeFocusStateMultiCalls(true, true));
  }

  ngAfterViewInit() {
    const suggestion = WEB_SEARCH_ITEMS.find((s) => s.id.split('_').slice(-1)[0].startsWith(this.currentEngine));
    if (suggestion) {
      this.tagsService.all = [this.hubService.suggestionsToTag(suggestion)];
    } else {
      this.logger.warn("couldn't find any suggestion tag for the engine", { engin: this.currentEngine });
    }
  }

  ngOnDestroy(): void {
    this.tagsService.all = [];
    super.ngOnDestroy();
  }

  get items(): WebSearch.ResultItem[] {
    return this.displayedResults;
  }

  trackResult: TrackByFunction<WebSearch.ResultItem> = (index: number, item: WebSearch.ResultItem): string => item.id;

  private subscribeToChanges(): void {
    const func = (query: string) => {
      if (this.currentQuery === query) {
        return;
      }
      if (!this.currentEngine) return;
      if (!query || this.textCleared) {
        this.isNewSearchSession = true;
        this.textCleared = false;
        this.sessionId = null;
        this.displayedResults = [];
      }

      if (this.hubService.suggestionQuery === this.hubService.query) return;
      this.currentQuery = query;

      if (this.shouldStartNewSession()) {
        this.initNewSession();
      }
      if (this.hubService.searchMethod === 'Search-On-Enter') {
        this.hubService.changeFocusState(false, true);
      }
      this.search(query);
      this.isNewSearchSession = false;
    };

    this.hubService.query$.pipe(untilDestroyed(this)).subscribe(func);
    this.hubService.textCleared$.pipe(untilDestroyed(this)).subscribe((isCleared: boolean) => (this.textCleared = isCleared));
    this.routingService.activeRoute$
      .pipe(
        untilDestroyed(this),
        filter(({ snapshot }) => snapshot.paramMap.has('engine'))
      )
      .subscribe(async ({ snapshot: { paramMap } }) => {
        this.hubService.searchMethod = this.isEmbed || (await this.hubService.getIsLauncher()) ? 'Quick-Search' : 'Search-On-Enter';
        this.hubService.autoFocus = this.hubService.searchMethod === 'Quick-Search';
        this.currentEngine = paramMap.get('engine');
        func(this.hubService.query);
      });
  }

  private setBarPlaceholder(): void {
    this.hubService.placeholder = this.placeholder;
  }

  private shouldStartNewSession(): boolean {
    return !!(
      !this.sessionId ||
      (this.sessionStartTime && Date.now() - this.sessionStartTime >= 60000 * 5) ||
      (this.isNewSearchSession && Date.now() - this.sessionStartTime >= 30000)
    );
  }

  private initNewSession(): void {
    this.sessionId = uuid.v4();
    this.sessionStartTime = Date.now();
  }

  private async search(query: string): Promise<void> {
    this.hubService.loading = true;

    this.clientSearchId = uuid.v4();

    if (this.isNewSearchSession) {
      this.event('session_start');
    }

    const result: WebSearch.Result = await this.webSearchService.search({ engine: this.currentEngine, query: query });
    const searchResults: WebSearch.ResultItem[] = result?.items;
    this.responseStatus = result?.status;
    this.exception = result?.exception;
    this.offline = result?.status === 'skipped'; // We get 'skipped' status only when there is no internet connection

    if (this.currentQuery !== query) return;

    this.hubService.loading = false;
    this.event('end');

    this.displayedResults = searchResults;
    this.selectedIndex = this.hubService.searchMethod === 'Quick-Search' ? 0 : null;
    this.cdr.markForCheck();
  }

  protected handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    if (keys.length > 1) return;
    if (['ArrowDown', 'ArrowUp'].includes(keys[0])) {
      if (this.selectedIndex === 0 && keys[0] === 'ArrowUp') {
        this.hubService.changeFocusState(keys[0] === 'ArrowUp', true);
      }
      this.keyboardOn = true;
    }
    super.handleKeys(keys, event);
    if (event.propagationStopped) {
      return;
    }

    const key = keys[0];
    if (isEnterKey(key)) {
      const item = this.items[this.selectedIndex];
      if (!item || this.hubService.focusPosition !== 'searchBar') {
        return;
      }
      this.openUrl(item.url);
    } else if (key === 'ArrowDown' || key === 'ArrowUp') {
      this.updateBarQuery();
    } else {
      this.hubService.suggestionQuery = null;
    }
  }

  private updateBarQuery(): void {
    this.hubService.suggestionQuery = this.items[this.selectedIndex]?.view?.title?.text.replace(/<[^>]*>/g, '');
    this.hubService.query = this.hubService.suggestionQuery;
  }

  private getStartEventMetadata(): Partial<EventInfo> {
    return {
      location: {
        title: this.hubService.currentLocation,
      },
      exception: this.exception,
      search: {
        trigger: 'user_query',
        sessionId: this.sessionId,
        clientSearchId: this.clientSearchId,
        source: 'web',
        origin: this.currentEngine,
        offline: this.offline,
      },
    };
  }

  private getEndEventMetadata(): Partial<EventInfo> {
    return {
      location: {
        title: this.hubService.currentLocation,
      },
      exception: this.exception,
      search: {
        trigger: 'user_query',
        sessionId: this.sessionId,
        clientSearchId: this.clientSearchId,
        resultsCount: this.displayedResults?.length || 0,
        cacheHit: this.cacheHit,
        responseStatus: this.responseStatus,
        source: 'web',
        origin: this.currentEngine,
        offline: this.offline,
      },
    };
  }

  private getConversionEventMetadata(): Partial<EventInfo> {
    return {
      location: {
        title: this.hubService.currentLocation,
      },
      exception: this.exception,
      resources: [{ appId: this.currentEngine }],
      search: {
        sessionId: this.sessionId,
        clientSearchId: this.clientSearchId,
        source: 'web',
        origin: this.currentEngine,
      },
    };
  }

  private getEventMetadata(type: WebSearchEvent): Partial<EventInfo> {
    switch (type) {
      case 'session_start':
        return this.getStartEventMetadata();
      case 'end':
        return this.getEndEventMetadata();
      case 'redirect':
        return this.getConversionEventMetadata();
    }
  }

  private event(type: WebSearchEvent): void {
    const event: EventInfo = { ...this.getEventMetadata(type), category: 'web_search', name: type };
    this.analyticsService.event(event);
  }

  onItemClick(index: number): void {
    this.selectedIndex = index;
    const item: WebSearch.ResultItem = this.items[this.selectedIndex];
    this.openUrl(item.url);
  }

  private openUrl(url: string): void {
    try {
      this.commandsService.executeCommand({ type: 'open-url', url } as Commands.OpenUrl);
    } catch (e) {
      this.exception = JSON.stringify(e);
      throw e;
    }
    this.event('redirect');
  }

  @HostListener('mousemove')
  onMouseMove() {
    this.keyboardOn = false;
  }

  onHoverItem(i: number) {
    if (!this.keyboardOn) {
      this.selectedIndex = i;
    }
  }
}
