import { LogService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';

export abstract class WikiCardMediaHandler {
  private logger: Logger;

  constructor(logService: LogService) {
    this.logger = logService.scope('WikiCardMediaHandler');
  }

  abstract insertMediaToHtml(html: string);
  abstract removeAllMediaSrc(html: string);
}
