import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'u-edit-text',
  templateUrl: './u-edit-text.component.html',
  styleUrls: ['./u-edit-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UEditTextComponent implements AfterViewInit {
  readonly MAX_NUMBER = 524288;

  @Output() onBlur: EventEmitter<void> = new EventEmitter<void>();
  @Output() onTextChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onKeydown: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
  @Output() onEnterPressed: EventEmitter<void> = new EventEmitter<void>();

  @Input() readonly: boolean = false;
  @Input() focusNoColor: boolean = false;
  @Input() textSelected = false;
  @Input() maxLength: number = this.MAX_NUMBER;
  @Input() ignoreEnter = false;

  _placeholder: string;
  @Input() set placeholder(v: string) {
    if (this.placeholder !== v && this.contenteditable && this.allowFocus) {
      this.setCursorAtEnd();
    }
    this._placeholder = v;
  }

  get placeholder(): string {
    return this._placeholder;
  }

  _allowFocus: boolean = true;

  @Input() set allowFocus(v: boolean) {
    const prev = this._allowFocus;
    this._allowFocus = v;

    if (v && !prev) {
      this.showFocus();
    }
  }

  get allowFocus(): boolean {
    return this._allowFocus;
  }

  @ViewChild('contenteditable') contenteditable: ElementRef;

  isFirstInit: boolean = true;

  // for testing purposes
  private text: string;

  constructor(private cdr: ChangeDetectorRef, private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    if (this.allowFocus) {
      this.showFocus();
    }
  }

  showFocus() {
    setTimeout(() => {
      this.contenteditable.nativeElement.focus();
      if (this.textSelected) {
        this.selectText();
      } else {
        this.setCursorAtEnd();
      }
      this.cdr.markForCheck();
    }, 250);
  }

  private selectText() {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(this.contenteditable.nativeElement);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  setCursorAtEnd() {
    window.getSelection().selectAllChildren(this.contenteditable.nativeElement);
    window.getSelection().collapseToEnd();
    this.cdr.markForCheck();
  }

  onInputChange($event) {
    this.text = $event.textContent;
    this.onTextChange.emit($event.textContent);
  }

  onClickEvent() {
    if (this.readonly) return;
    this.isFirstInit = false;
    this.cdr.markForCheck();
    this.onClick.emit();
  }

  onKeydownMain(event: KeyboardEvent) {
    this.isFirstInit = false;
    event.stopPropagation();
    if (!this.isKeyAllowed(event)) {
      return;
    }
    if (event.key === 'Enter') {
      this.onEnterPressed.emit();
      if (this.ignoreEnter) {
        event.preventDefault();
      }
    }

    this.cdr.markForCheck();
    this.onKeydown.emit(event);
  }

  isKeyAllowed(event: KeyboardEvent): boolean {
    const isValidShortcut = event.ctrlKey && event.keyCode !== 86;
    const isNavigationOrDeleteKey = [8, 16, 17, 37, 38, 39, 40, 46].includes(event.keyCode);

    const text = (event.target as HTMLElement).innerText || '';

    const selection = window.getSelection();
    const hasSelection = selection && selection.rangeCount > 0 && !selection.getRangeAt(0).collapsed;

    if (text.length >= this.maxLength && !isNavigationOrDeleteKey && !isValidShortcut && !hasSelection) {
      event.preventDefault();
      return false;
    }

    return true;
  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault();

    let cleanText = (event.clipboardData?.getData('text/plain') || '')
        .replace(/[\u200B-\u200D\uFEFF]/g, '')
        .replace(/\s+/g, ' ')
        .trim();

    const contenteditableEl = this.contenteditable.nativeElement;
    const currentContent = contenteditableEl.innerText || '';

    const availableLength = this.maxLength - currentContent.length;
    if (cleanText.length > availableLength) {
        cleanText = cleanText.substring(0, availableLength);
    }

    document.execCommand("insertText", false, cleanText);
    this.onTextChange.emit(contenteditableEl.innerText);
  }

}
