import { Assistants } from '@local/client-contracts';
import { SourceSettings } from '../source-settings';

export interface AssistantIncontextSourceSettings extends SourceSettings {
  type: 'assistant-incontext';
  requestMaxCount: number;
  localOnly?: boolean;
  contentSearch?: boolean;
  advancedSearch?: boolean;
  useSourceFilters?: boolean;
  sessionId?: string;
  data: Assistants.AssistantTicketRequest;
}
