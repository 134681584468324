import { ElementRef } from '@angular/core';
import { KeyName } from '@local/ts-infra';
import { CustomKeyboardEvent } from '@shared/services/keyboard.service';
import { Subject } from 'rxjs';
import { InfiniteScrollService } from 'src/app/bar/views/results/services/infinite-scroll.service';

export type ScrollMode = 'horizontal' | 'vertical';

export class InfiniteKeyboardHelperService {
  private items: Array<any>;
  private infiniteScrollService: InfiniteScrollService;
  private nextKey: KeyName;
  private prevKey: KeyName;
  selectedIndex: number;
  updateCdr = new Subject<void>();
  updateSelectedIndex = new Subject<number>();

  constructor(private scrollMode: ScrollMode = 'vertical') {
    this.updateScrollMode(this.scrollMode);
    this.infiniteScrollService = new InfiniteScrollService();
  }

  get keys() {
    return { prevKey: this.prevKey, nextKey: this.nextKey };
  }

  onInit(selectedIndex: number, items: Array<any>) {
    this.selectedIndex = selectedIndex;
    this.items = items;
  }

  updateScrollMode(scrollMode: ScrollMode) {
    if (scrollMode === 'vertical') {
      this.nextKey = 'ArrowDown';
      this.prevKey = 'ArrowUp';
    } else {
      this.nextKey = 'ArrowRight';
      this.prevKey = 'ArrowLeft';
    }
  }

  onSelectedIndex(value: number, event: CustomKeyboardEvent, selectedEl: ElementRef) {
    if (!value) {
      this.selectedIndex = value;
    } else {
      this.selectedIndex += value;
    }
    this.checkIfScrollNeeded(selectedEl);
    event.preventDefault();
    event.stopPropagation();
    this.updateCdr.next();
    this.updateSelectedIndex.next(this.selectedIndex);
  }

  async checkIfScrollNeeded(selectedEl: ElementRef) {
    const newIdx = this.infiniteScrollService?.scrollIfNeeded(selectedEl);
    if (typeof newIdx === 'number') {
      this.selectedIndex = newIdx;
    }
  }
}
