import { MemorySearch, Search } from '@local/client-contracts';
import { EmbedService } from '@shared/embed.service';
import { LogService } from '@shared/services';
import { MemorySearchService } from '@shared/services/memory-search.service';
import pluralize from 'pluralize';
import { HeaderItem, SearchResults } from 'src/app/bar/views/results/models/results-types';
import { MemorySearchClient } from '../memory-search-client/memory-search-client';
import { SearchRequest } from '../search-request';
import { EmbedItemsSourceSettings } from './embed-items-source-settings';

export class EmbedItemsSearchClient extends MemorySearchClient<EmbedItemsSourceSettings> {
  constructor(logService: LogService, memorySearchService: MemorySearchService, private embedService: EmbedService) {
    super(logService, memorySearchService);
    this.logger = logService.scope('embed-items');
  }

  async getInput(): Promise<MemorySearch.Item[]> {
    if (!this.embedService) return [];

    // start with the newest first
    let res = Object.values(this.embedService.items).map((t) => ({
      searchText: t.title + ' ' + t.subtitle + ' ' + (t.keywords?.join(' ') || '') + ' ' + this.embedService.itemTypes[t.type || '']?.title,
      data: t,
      sortValue: null,
    }));

    return res;
  }
  async getOutput(items: MemorySearch.Item[]): Promise<SearchResults[]> {
    let res = [];
    let seen = {};

    for (let i of items) {
      if (!seen[i.data.type]) {
        for (let j of items.filter((x) => x.data.type == i.data.type)) {
          res.push({ ...j.data, _type: j.data.type, type: 'embed-item' });
        }
        seen[i.data.type] = true;
      }
    }
    return res;
  }

  protected addHeaders(request: SearchRequest<EmbedItemsSourceSettings>, items: any[], resultCount: number): void {
    let seen = {};
    let citems = [...items];
    for (let i = 0; i < citems.length; ++i) {
      if (!seen[citems[i]._type]) {
        let itemType = this.embedService.itemTypes[citems[i]._type];
        if (!itemType) continue;
        const h: HeaderItem = {
          type: 'header',
          clickable: false,
          origin: '',
          title: pluralize(itemType.title).toUpperCase(),
        };
        items.splice(i, 0, h);
        seen[citems[i]._type] = true;
      }
    }
  }
}
