import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Walkthrough } from '@local/client-contracts';
import { TourActionData, WalkthroughService } from '@shared/services/walkthrough.service';
import { PopupRef } from '@local/ui-infra';

@Component({
  selector: 'tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('blink', [
      transition('false => true', animate(500, keyframes([style({ opacity: '0' }), style({ opacity: '1' }), style({ opacity: '0' })]))),
    ]),
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate(500, style({ opacity: 1 }))]),
      transition(':leave', [animate(500, style({ opacity: 0 }))]),
    ]),
    trigger('change', [
      transition(
        (fromState, toState) => {
          return fromState !== toState;
        },
        [style({ opacity: 0 }), animate(250, style({ opacity: 1 }))]
      ),
    ]),
  ],
})
export class TourComponent implements OnInit {
  tour: Walkthrough.Tour;
  @Output() action: EventEmitter<TourActionData> = new EventEmitter();
  @Output() show: EventEmitter<string> = new EventEmitter();
  blinkAnimation: boolean;

  constructor(
    private ref: PopupRef<TourComponent, Walkthrough.Tour>,
    private cdr: ChangeDetectorRef,
    private walkthroughService: WalkthroughService
  ) {}

  ngOnInit(): void {
    this.tour = this.ref.data;
    this.walkthroughService.tourChange.subscribe(() => this.cdr.markForCheck());
  }

  get step(): Walkthrough.Step {
    return this.tour.steps[this.tour.status.activeStep];
  }

  get activeStep(): number {
    return this.tour.status.activeStep + 1;
  }

  onAction(data: TourActionData): void {
    this.action.emit(data);
    this.cdr.markForCheck();
  }

  onShow(id: string): void {
    this.show.emit(id);
  }

  animateButton(): void {
    this.blinkAnimation = true;
    this.cdr.markForCheck();
  }

  onAnimateDone(): void {
    this.blinkAnimation = false;
    this.cdr.markForCheck();
  }
}
