<overlay class="oauth" *ngIf="isCreating === false" (closeOverlay)="closeSyncMessage.emit()">
  <ng-container overlay-title>
    <div class="auth-loader" [ngClass]="theme === 'light' ? 'auth-loader-light' : 'auth-loader-dark'"></div>
    Waiting for authorization
  </ng-container>
  <ng-container overlay-content>
    Please finish authorization on your browser
    <br />
  </ng-container>
  <ng-container overlay-actions>
    <u-button label="Cancel" (onClick)="abort.emit()" type="secondary"></u-button>
  </ng-container>
</overlay>
