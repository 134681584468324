<div class="step" *ngIf="step">
  <div class="step-icon" *ngIf="showIcon" [@change]="showIcon">
    <u-icon class="svg-icon" [model]="{ type: 'img' }" [inSvg]="step.icon"></u-icon>
  </div>
  <div data-cy="step-title" class="step-title" [@change]="step.title">{{ step.title }}</div>
  <div class="step-text" [@change]="step.text">
    <ng-container *ngFor="let t of step.text">
      <p class="key-button" [innerHTML]="t"></p>
    </ng-container>
    <div class="step-tip" *ngIf="tip">
      <u-icon class="icon-unleash" [model]="{ type: 'font', value: 'icon-unleash-logo' }"></u-icon>
      <span class="label">pro tip:</span>
      <span class="key-button" [innerHTML]="tip"></span>
    </div>
    <div class="step-note" *ngIf="step.note"><span class="label">note:</span> {{ step.note }}</div>
  </div>
  <div *ngIf="step.buttons" class="step-buttons">
    <ng-container *ngFor="let button of step.buttons">
      <button
        *ngIf="button.action === 'next' || button.action === 'complete'; else backButton"
        (click)="onAction(button.action)"
        [class]="'button ' + button.action"
        data-cy="tour-button"
      >
        <span [@change]="button.text">{{ button.text }}</span>
      </button>
      <ng-template #backButton>
        <button (click)="onAction(button.action)" [class]="'button ' + button.action">
          <u-icon *ngIf="button.action === 'back'" class="back-button" [model]="{ type: 'font', value: 'icon-chevron-left' }"></u-icon>
          <span [@change]="button.text">{{ button.text }}</span>
        </button>
      </ng-template>
    </ng-container>
  </div>
</div>
