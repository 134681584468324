export function changeDensity(hex, density) {
  let { r, g, b } = extractRgbFromHex(hex);

  // Modify the density of the color by scaling the RGB values and clamping them to the range 0-255
  r = Math.max(0, Math.min(255, Math.round(r * density)));
  g = Math.max(0, Math.min(255, Math.round(g * density)));
  b = Math.max(0, Math.min(255, Math.round(b * density)));

  // Convert the modified RGB values back to a hex color string
  const newHex = '#' + ('0' + r.toString(16)).slice(-2) + ('0' + g.toString(16)).slice(-2) + ('0' + b.toString(16)).slice(-2);
  return newHex;
}

export function isHexColorDark(hex) {
  const { r, g, b } = extractRgbFromHex(hex);
  return (r + g + b) / 3 < 128;
}

export function hexToRGB(hex) {
  const { r, g, b } = extractRgbFromHex(hex);
  return `${r}, ${g}, ${b}`;
}

export function extractRgbFromHex(hex: string): { r: number; g: number; b: number } {
  return {
    r: parseInt(hex.slice(1, 3), 16),
    g: parseInt(hex.slice(3, 5), 16),
    b: parseInt(hex.slice(5, 7), 16),
  };
}

const randomColorProfile: string[] = [
  'var(--primary-600)',
  'var(--red-600)',
  'var(--yellow-600)',
  'var(--green-600)',
  'var(--turquoise-600)',
];

export function randomColor(): string {
  return randomColorProfile[Math.floor(Math.random() * randomColorProfile.length)];
}
