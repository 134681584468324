export type UGalleriaSize = 'carousel-small' | 'carousel-medium' | 'carousel-large' | 'post-small' | 'post-medium';
export type UGalleriaBreakpoints = {
  [key in UGalleriaSize]: { width: number; height: number };
};

export const CAROUSEL_BREAKPOINTS: UGalleriaBreakpoints = {
  'post-small': { width: 280, height: 230 },
  'post-medium': { width: 420, height: 330 },
  'carousel-small': { width: 600, height: 230 },
  'carousel-medium': { width: 800, height: 330 },
  'carousel-large': { width: 1200, height: 330 },
};
