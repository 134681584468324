export function getFileSize(size: number, includeBytes = true, includeDecimalPoint = true): string {
  const fileSizeBytes = size;
  if (fileSizeBytes < 1024) {
    return includeBytes ? fileSizeBytes.toFixed(0) + ' bytes' : '1 KB';
  } else if (fileSizeBytes < 1024 * 1000) {
    const fileSizeKB = fileSizeBytes / 1024;
    return fileSizeKB.toFixed(includeDecimalPoint ? 1 : 0) + ' KB';
  } else {
    const fileSizeMB = fileSizeBytes / (1024 * 1024);
    return fileSizeMB.toFixed(includeDecimalPoint ? 1 : 0) + ' MB';
  }
}

const sizeRegex = /^(\d+)\s*([KMGT]B)$/i;

export function convertFileSizeToBytes(fileSize) {
  const match = fileSize.match(sizeRegex);

  if (!match) {
    console.error("Invalid file size format. Please provide a valid size like '58KB' or '20MB'.");
    return null;
  }

  const numericPart = parseFloat(match[1]);
  const unit = match[2].toUpperCase();

  const unitMap = {
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
    TB: 1024 * 1024 * 1024 * 1024,
  };

  if (!unitMap[unit]) {
    console.error("Unsupported unit. Please use 'KB', 'MB', 'GB', or 'TB'.");
    return null;
  }

  return numericPart * unitMap[unit];
}
