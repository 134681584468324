import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '@shared/loader.service';
import { LogService } from '@shared/services';
import { SessionService } from '@shared/services/session.service';
import { SlackBotService } from '@shared/services/slack-bot.service';
import { Logger } from '@unleash-tech/js-logger';
import { filter, firstValueFrom } from 'rxjs';

@Component({
  templateUrl: `./install-slack.component.html`,
  styleUrls: [`./install-slack.component.scss`],
})
export class InstallSlackComponent {
  private readonly REDIRECT_PATH = 'add-to/slack';
  private readonly logger: Logger;

  constructor(
    private loaderService: LoaderService,
    private slackService: SlackBotService,
    private route: ActivatedRoute,
    logService: LogService,
    private sessionService: SessionService
  ) {
    this.logger = logService.scope('InstallSlackComponent');
    loaderService.ready$.next(false);
    this.redirectToNextStage();
  }

  private async redirectToNextStage() {
    const qp = await firstValueFrom(this.route.queryParams);
    const session = await firstValueFrom(this.sessionService.current$.pipe(filter((s) => !!s)));
    const user = session.user;
    const { id: userId, email } = user;
    const origin = this.sessionService.getTargetOrigin(qp?.origin);
    if (!origin) {
      this.logger.error('no vailed origin', { origin, qp });
    }
    const res = await firstValueFrom(this.slackService.installUrl$(session.workspace?.accountId, session.token, true, this.REDIRECT_PATH));
    const status = res?.installed ? 'installed' : 'redirect';
    const url = res?.redirectUrl;
    window.opener?.postMessage({ type: 'unleash:slack-install', userId, email, status, url }, origin);
    this.loaderService.ready$.next(true);
  }
}
