import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FileUpload } from 'primeng/fileupload';
import { IStyleService, STYLE_SERVICE } from '../../injectionToken/style.service.interface';
import { FileUploadPreview } from '../../types/file-upload-preview';
import { Scheme } from '../../types/scheme';
import { UiIconModel } from '../../types/ui.icon.model';
import { getFileSize } from '../../utils/file-utils';
@UntilDestroy()
@Component({
  selector: 'u-file-upload',
  templateUrl: './u-file-upload.component.html',
  styleUrls: ['./u-file-upload.component.scss'],
})
export class UFileUploadComponent implements OnInit, AfterViewInit {
  private readonly ACCEPT_ALL = '*';

  @ViewChild('fileUpload') fileUpload: FileUpload;
  @ViewChild('contentPlaceHolder', { read: ElementRef }) contentPlaceHolder: ElementRef;

  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileUploadClick: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding('attr.theme')
  @Input()
  staticTheme: Scheme;

  @Input() textOnly: boolean;

  @HostBinding('style')
  @Input()
  style: any;

  @Input() previewSize: { width: string; height: string };
  @Input() styleClass: string;
  @Input() accept: string = this.ACCEPT_ALL;
  @Input() maxFileSize: number = 5 * 1024 * 1024; //5mb
  @Input() label: string = null;
  @Input() showErrorMessage = true;
  @Input() errorMessage: string;
  @Input() mode: 'basic' | 'advanced' = 'basic';
  @Input() openFileOnClick = false;
  @Input() uploadOnHoverText = 'Drop an image here';
  @Input() dragAndDropText = 'Drop an image here or';
  @Input() uploadText = 'Upload a file';
  @Input() showThemeName = true;
  @Input() disabled = false;
  @Input() hideRemoveIcon = false;
  @Input() displayUploadIcon = false;
  @Input() definitionOfAcceptType: string;
  @Input() errorFileTypeMsg = 'Upload failed. JPG,PNG, SVG only.';

  private _userFilePreviewUrl: any;
  public get userFilePreview(): any {
    return this._userFilePreviewUrl;
  }
  @Input() public set userFilePreview(file: FileUploadPreview) {
    if (!file?.url?.length) {
      this.selectedFile = null;
      return;
    }
    this._userFilePreviewUrl = file.url;
    this.selectedFile = file;
    this.cdr.markForCheck();
  }

  private _dragAndDropFile: boolean;
  @HostBinding('attr.dragAndDropFile')
  @Input()
  public set dragAndDropFile(value: boolean) {
    this._dragAndDropFile = value;
  }
  get dragAndDropFile() {
    return this._dragAndDropFile;
  }

  @HostBinding('attr.dragging')
  isDragging = false;

  @HostBinding('class.text-only')
  get isTextOnly() {
    return this.textOnly;
  }

  selectedFile: FileUploadPreview;
  showReplaceButton = false;
  icon: UiIconModel;
  theme: Scheme;

  private darkModeIcon: UiIconModel = {
    type: 'font',
    value: 'icon-dark-mode_light',
  };

  private lightModeIcon: UiIconModel = {
    type: 'font',
    value: 'icon-light-mode_light',
  };

  getMaxSizeErrorMessage(fileSize: number) {
    return `Upload failed. Max file size of ${getFileSize(fileSize, false, false)}`;
  }

  constructor(private cdr: ChangeDetectorRef, @Inject(STYLE_SERVICE) private styleService: IStyleService, private el: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.dragAndDropFile && !!this.fileUpload?.content?.nativeElement && !!this.contentPlaceHolder?.nativeElement) {
      this.fileUpload.content.nativeElement.appendChild(this.contentPlaceHolder.nativeElement);
      this.fileUpload.content.nativeElement.style.width = this.previewSize?.width;
      this.fileUpload.content.nativeElement.style.height = this.previewSize?.height;
      this.cdr.markForCheck();
    }
    const dragArea: HTMLElement = this.el.nativeElement.querySelector('.p-fileupload-content');
    if (dragArea) {
      dragArea.addEventListener('dragover', () => {
        this.isDragging = true;
        this.cdr.markForCheck();
      });
      dragArea.addEventListener('dragleave', () => {
        this.isDragging = false;
        this.cdr.markForCheck();
      });
      dragArea.addEventListener('drop', () => {
        this.isDragging = false;
        this.cdr.markForCheck();
      });
    }
  }

  ngOnInit(): void {
    if (this.staticTheme) {
      this.theme = this.staticTheme;
      this.updateThemeIcon();
    } else {
      this.styleService.theme$.pipe(untilDestroyed(this)).subscribe((s) => {
        this.theme = s;
        this.updateThemeIcon();
      });
    }
  }

  private updateThemeIcon() {
    this.icon = this.theme === 'light' ? this.lightModeIcon : this.darkModeIcon;
    this.cdr.markForCheck();
  }

  onSelectEvent(event) {
    const file = event.files[0];
    const isLargeFile = file.size > this.maxFileSize;
    const isFileTypeNotSupported = this.accept !== this.ACCEPT_ALL && !this.accept.includes(file.type);
    if (isFileTypeNotSupported || isLargeFile) {
      this.errorMessage = isFileTypeNotSupported ? this.errorFileTypeMsg : this.getMaxSizeErrorMessage(this.maxFileSize);
      this.onErrorEvent(this.errorMessage);
      this.cdr.markForCheck();
      return;
    }
    this.errorMessage = null;

    this.selectedFile = {
      url: URL.createObjectURL(event.files[0]),
      name: event.files[0].name,
    };
    this.onSelect.emit(event.files[0]);
    this.fileUpload.clear();
    this.cdr.markForCheck();
  }

  onErrorEvent($event) {
    this.onError.emit($event);
  }

  onFileUploadClick($event) {
    if ($event.pointerType === 'mouse') {
      // file-upload bug , when click it fires two events
      if (this.openFileOnClick) {
        this.choose();
      }
      this.fileUploadClick.emit();
    }
  }

  onRemove() {
    this.selectedFile = null;
    this.isDragging = false;
    this.fileUpload.content.nativeElement.appendChild(this.contentPlaceHolder.nativeElement);
    this.onSelect.emit(null);
    this.cdr.markForCheck();
  }

  choose() {
    this.fileUpload.choose();
  }

  toggleReplaceButton(showButton: boolean) {
    this.showReplaceButton = showButton && !this.openFileOnClick;
    this.cdr.markForCheck();
  }
}
