import { Search } from '@local/client-contracts';
import { MemorySearchSettings } from '../memory-search-client/memory-search-client';

export interface CollectionsSourceSettings extends MemorySearchSettings {
  type: 'collection';
  maxCount?: number;
  sorting?: Search.Sort;
  title?: string;
  isFavoriteItem?: boolean;
  collectionIds?: string[];
  debounceTime?: number;
}
