import { Injectable } from '@angular/core';
import { GetVisitsRequest, GetVisitsResponse, SearchVisitsRequest, SearchVisitsResponse, Visits, VisitsRpcInvoker } from '@local/common-web';
import { Observable } from 'rxjs';
import { ServicesRpcService } from './rpc.service';

@Injectable({
  providedIn: 'root',
})
export class VisitsService implements Visits {
  private service: Visits;

  constructor(rpc: ServicesRpcService) {
    this.service = rpc.invokeWith(VisitsRpcInvoker, 'visits');
  }

  get(request: GetVisitsRequest): Promise<GetVisitsResponse> {
    return this.service.get(request);
  }
  
  async search(request: SearchVisitsRequest): Promise<Observable<SearchVisitsResponse>> {
    return this.service.search(request);
  }
  
  async cancelSearches(sessionId: string): Promise<void> {
    return this.service.cancelSearches(sessionId);
  }
}
