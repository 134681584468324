import { MemorySearch } from '@local/client-contracts';
import { BrowserBookmarksRpcInvoker } from '@local/common';
import { isNativeWindow } from '@local/common-web';
import { LogService, NativeServicesRpcService } from '@shared/services';
import { BrowserExtensionService } from '@shared/services/browser-extension.service';
import { DownloadsService } from '@shared/services/downloads.service';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { firstValueFrom } from 'rxjs';
import { BrowserBookmarkItem, SearchResults } from 'src/app/bar/views/results/models/results-types';
import { MemorySearchClient } from '../memory-search-client/memory-search-client';
import { SearchRequest } from '../search-request';
import { BrowserBookmarksSourceSettings } from './browser-bookmarks-source-settings';

export class BrowserBookmarksSearchClient extends MemorySearchClient<BrowserBookmarksSourceSettings> {
  constructor(
    logService: LogService,
    memorySearchService: MemorySearchService,
    private browserExtService: BrowserExtensionService,
    private nativeServices: NativeServicesRpcService,
    private downloadsService: DownloadsService
  ) {
    super(logService, memorySearchService, ['Alphabetical']);
    this.logger = logService.scope('browser-bookmarks');
  }

  async getInput(request: SearchRequest<BrowserBookmarksSourceSettings>): Promise<MemorySearch.Item[]> {
    const target = isNativeWindow() ? this.nativeServices : await firstValueFrom(this.browserExtService.current$);
    if (!target) return;
    const svc = target.invokeWith(BrowserBookmarksRpcInvoker, 'browserbookmarks');

    const bookmarks = await firstValueFrom(svc.all$);
    const sorting = request.sourceSettings.sorting;
    return bookmarks.map((t) => ({
      searchText: t.title + ' ' + t.path.join(' ') + ' ' + t.url + ' in browser bookmark',
      data: <BrowserBookmarkItem>{ ...t, type: 'browser-bookmark' },
      sortValue: sorting?.by === 'Alphabetical' ? t.title : null,
    }));
  }

  async getOutput(items: MemorySearch.Item[]): Promise<SearchResults[]> {
    const promises: Promise<string>[] = [];
    const res = [];
    for (const item of items) {
      const data = item.data as BrowserBookmarkItem;
      if (data.profile?.pictureFileName) {
        promises.push(
          this.downloadsService.getLocalFileUrl(data.profile.pictureFileName).then((p) => (data.profile.pictureFileName = p.url))
        );
      }
      res.push(item.data);
    }
    await Promise.all(promises);
    return res;
  }
}
