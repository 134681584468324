import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Preferences } from '@local/client-contracts';
import { getModifiers, isSingleKey, removeModifiers } from '@local/ts-infra';
import { performanceCheckpoint } from '@local/common';
import { isEmbed, isNativeWindow } from '@local/common-web';
import { CustomColorItem, WorkspaceCustomColorProperty, workspaceCustomColorsMap } from '@shared/consts';
import { EmbedService } from '@shared/embed.service';
import { LogService } from '@shared/services';
import { hexToRGB, isHexColorDark, isProdEnv } from '@shared/utils';
import { updateTheme } from '@shared/utils/theme.util';
import { Logger } from '@unleash-tech/js-logger';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { WorkspacesService } from 'src/app/bar/services';
import { KeyboardService } from './keyboard.service';
import { PreferencesService } from './preferences.service';
import { ServicesRpcService } from './rpc.service';

export type Scheme = Preferences.Theme;
export type ApplicableScheme = Exclude<Preferences.Theme, 'auto'>;
export type Theme = Record<ApplicableScheme, SchemeValue>;
export type SchemeValue = Record<string, string>;
export interface Style {
  scheme: Scheme;
}
@Injectable({
  providedIn: 'root',
})
export class StyleService {
  style$ = new ReplaySubject<{ [key: string]: string }>(1);
  currentScheme: Scheme;
  loadedVariables: Record<string, string>;
  theme: Theme;
  theme$: ReplaySubject<Scheme> = new ReplaySubject<Scheme>(1);
  private logger: Logger;
  private renderer: Renderer2;
  private readonly isEmbed = isEmbed();
  private CSS_TEXT_REGEX = /\-\-(?<property>[a-z0-9\-]+)\:*\s?(?<value>(.+?));/gim;
  private CSS_SCHEME_REGEX = /\[data\-scheme\s*\=\s*(?:\"|\')(?<scheme>[a-z]+)(?:\"|\')\]/g;

  constructor(
    private services: ServicesRpcService,
    logService: LogService,
    rendererFactory: RendererFactory2,
    private preferences: PreferencesService,
    private keyboardService: KeyboardService,
    private embedService: EmbedService,
    private workspacesService: WorkspacesService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.logger = logService.scope(this.constructor.name);
  }

  init() {
    this.theme$.subscribe((s) => (this.currentScheme = s));
    this.loadStaticVariables();
    this.initStyles();
    this.loadSchemeVariables();
    this.initThemeLogic();
    this.handleSchemeChange();
    this.initWorkspaceCustomColors();
  }

  private initStyles() {
    this.style$.subscribe((style) => {
      Object.keys(style).forEach((property) => {
        document.body.style.setProperty(property, style[property]);
      });
    });
  }

  private initWorkspaceCustomColors() {
    this.workspacesService.current$.pipe(filter((w) => !!w)).subscribe((workspace) => {
      const colors = workspace.media?.colors;
      if (!colors) return;

      const { primary, secondary } = colors;
      const isCustom = !!primary?.background || !!primary?.foreground || !!secondary?.background || !!secondary?.foreground;

      for (let [key, customColor] of Object.entries(workspaceCustomColorsMap)) {
        key = key as string;
        customColor = customColor as CustomColorItem;
        const color = colors[customColor.type]?.[customColor.subtype];
        if (!color) {
          continue;
        }
        if (customColor.rootPropertyName) {
          this.toggleRootProperty(customColor.rootPropertyName, true);
        }
        if (customColor.colorName === '--side-bar-color-bg-secondary') {
          this.toggleRootProperty('sidebar-custom-color-theme', isHexColorDark(color) ? 'dark' : 'light');
        }
        // create a tertiary text color for sidebar
        if (customColor.colorName === '--side-bar-color-text-secondary' && customColor.generatedColor) {
          this.updateRootColor(customColor.generatedColor.colorName, hexToRGB(color));
        }
        if (customColor.createRgbVersion) {
          this.updateRootColor(`${customColor.colorName}-rgb`, hexToRGB(color));
        }
        const isRgb = customColor.colorFormat === 'rgb';
        this.updateRootColor(customColor.colorName, isRgb ? hexToRGB(color) : color);
      }
    });
  }

  toggleRootProperty(name: WorkspaceCustomColorProperty, value: any) {
    this.renderer.setAttribute(document.body, name, value);
  }

  updateRootColor(varName: string, color: string) {
    if (!color) return;
    document.body.style.setProperty(varName, color);
  }

  private handleSchemeChange() {
    // Used in developer console only for debugging

    const toggleScheme = async () => {
      if (isProdEnv()) {
        return;
      }

      const nscheme: Scheme = (await firstValueFrom(this.theme$)) == 'dark' ? 'light' : 'dark';
      this.theme$.next(nscheme);
    };
    this.services.handle('style.toggleScheme', () => toggleScheme());
    this.keyboardService.registerKeyHandler((keys) => {
      const mod = getModifiers(keys);
      const nonMod = removeModifiers([...keys]);
      if (isSingleKey('b', nonMod) && mod.length === 1 && (mod[0] === 'control' || mod[0] === 'command')) {
        toggleScheme();
      }
    });
  }

  private async initThemeLogic() {
    // The final scheme is resolved on the main (electron) side which considers the OS and the Window support of the theme
    this.theme$.subscribe(async (scheme) => {
      this.onThemeChange(scheme ?? 'light');
    });

    performanceCheckpoint('init scheme logic');

    if (!this.isEmbed || !(await this.embedService?.isExternalWebSite())) {
      this.preferences.property$('general', 'theme').subscribe(async (v) => {
        this.applyUserScheme(v);
      });
    } else {
      this.embedService.options$.subscribe(async (o) => {
        if (!(await this.embedService.isExternalWebSite())) {
          return;
        }
        this.applyUserScheme(o.theme);
      });
    }

    const windowNameAttributeObserver = new MutationObserver((m) => {
      if (!m.length) return;
      this.setFrameStyles();
    });
    windowNameAttributeObserver.observe(document.body, { attributes: true, attributeFilter: ['window-name'] });

    window.matchMedia('(prefers-color-scheme: light)').onchange = async (ev: MediaQueryListEvent) =>
      (await this.getTheme()) === 'auto' && ev.matches && this.theme$.next('light');
    window.matchMedia('(prefers-color-scheme: dark)').onchange = async (ev: MediaQueryListEvent) =>
      (await this.getTheme()) === 'auto' && ev.matches && this.theme$.next('dark');
  }

  private async getTheme() {
    if (this.isEmbed) {
      const opts = await firstValueFrom(this.embedService.options$);
      return opts.theme;
    }
    return (await firstValueFrom(this.preferences.current$))?.general.theme;
  }

  private async applyUserScheme(scheme?: Preferences.Theme) {
    // Emit initial value in case of no response / timeout in the infra with main

    const pref = scheme;

    if (pref === 'auto') {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.theme$.next('dark');
      } else if (window.matchMedia('(prefers-color-scheme: light)').matches) {
        this.theme$.next('light');
      }
    } else {
      this.theme$.next(pref);
    }
  }

  private async onThemeChange(scheme) {
    if (this.embedService) this.embedService.updateBackground(scheme);

    this.renderer.setAttribute(document.body, 'data-theme', scheme);
    this.renderer.setStyle(document.body, 'color-scheme', scheme);
    this.renderer.setAttribute(document.body, 'data-scheme', scheme);
    updateTheme(scheme);

    this.setActiveThemeVariablesOnRoot(scheme);
    this.style$.next({ ...this.loadedVariables, ...this.theme?.[scheme] });

    if (!!this.loadedVariables && !!this.theme && !!this.theme[scheme]) {
      this.setFrameStyles();
    }
  }

  private loadSchemeVariables() {
    const schemePrefix = 'data-scheme';

    const cssRules: CSSStyleRule[] = Array.from(document.styleSheets)
      .filter((sheet) => sheet.href === null || sheet.href.startsWith(window.location.origin))
      .flatMap((sheet) => Array.from(sheet.cssRules)) as CSSStyleRule[];

    const themes: Theme = { dark: {}, light: {} };
    for (const { cssText } of cssRules) {
      if (!cssText) continue;
      if (!cssText.includes(`${schemePrefix}`)) continue;

      //The Regex expression answers to the format of [data-scheme]="value"
      const scheme = Array.from(cssText.matchAll(this.CSS_SCHEME_REGEX))[0].groups['scheme'];
      themes[scheme] = { ...this.cssTextToObject(cssText), ...(themes[scheme] ?? {}) };
    }
    if (!Object.keys(themes).every((s) => ['dark', 'light'].includes(s)) || !Object.values(themes).every((v) => !!Object.keys(v).length)) {
      this.logger.warn('Not all default schemes are loaded!');
    }

    this.theme = themes;
  }

  private loadStaticVariables() {
    const loadedVariables = {};
    const styleSheets = document.styleSheets;
    const origin = window.location.origin;
    for (const sheet of styleSheets) {
      if (sheet.href && !sheet.href.startsWith(origin)) {
        return;
      }
      for (const rule of Array.from(sheet.cssRules) as CSSStyleRule[]) {
        if (rule.selectorText === ':root') {
          Object.assign(loadedVariables, this.cssTextToObject(rule.cssText));
        }
      }
    }
    this.loadedVariables = loadedVariables;
  }

  /** In order to prevent white ugly background  we need ot set the document color*/
  private async setFrameStyles() {
    const noRadius = isNativeWindow();

    if (noRadius) {
      document.body.style.removeProperty('border-radius');
    }
    if (this.isEmbed && (await firstValueFrom(this.embedService.options$).then((s) => !s.popup && s.inline))) {
      document.body.style.setProperty('background-color', 'transparent');
    }
    const scheme = await this.theme$.pipe(take(1)).toPromise();
    const theme = this.theme[scheme];
    if (!theme) return;
    const bg = theme['color-bg'];
    const valueExtractor = new RegExp(/\(--(.+)\)/gim);
    for (const property of Object.keys(this.loadedVariables)) {
      if (!bg?.includes(property)) continue;

      const color = valueExtractor.exec(bg)['1'];

      if (!this.loadedVariables[color]) {
        this.logger.warn(`No color value found for ${color}, failed to update windows background`);
        return;
      }

      if (!this.isEmbed) {
        document.documentElement.style.setProperty('background-color', this.loadedVariables[color]);
      }
      if (!noRadius) {
        document.body.style.setProperty('border-radius', '6px');
      }
    }
  }

  /**  We are setting the variables in the body level but some of the styles needs to be set in the root level
   * So every time the theme changes we are setting them on the :root.
   */
  private setActiveThemeVariablesOnRoot(scheme) {
    for (const [property, value] of Object.entries(this.theme?.[scheme] || {})) {
      document.documentElement.style.setProperty(`--${property}`, value + '');
    }
  }

  private cssTextToObject(css: string): object {
    const final = {};
    const matches = css.matchAll(this.CSS_TEXT_REGEX);

    for (const { groups } of matches) {
      final[groups.property] = groups.value;
    }

    return final;
  }
}
