import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'chase-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent {
  @Input() text: string;

  @Input() highlights: Array<string> = [];

  @Input() shouldTruncate: boolean = false;

  @Input() behavior: 'ltr' | 'rtl' | 'outwards' | 'inwards' = 'ltr';

  @Input() selected: boolean;

  constructor(private ref: ElementRef) {}

  get nativeElement(): HTMLElement {
    return this.ref?.nativeElement;
  }

  @HostBinding(`class`) get class() {
    return { [this.behavior]: true, truncate: this.shouldTruncate, selected: this.selected };
  }
}
