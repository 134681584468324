import { Injectable } from '@angular/core';
import { isEmbed } from '@local/common-web';
import { EmbedService } from '@shared/embed.service';
import { Observable, ReplaySubject, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssistantIncontextService {
  private readonly isEmbed = isEmbed();
  private _response$: ReplaySubject<any> = new ReplaySubject(1);

  constructor(private embedService: EmbedService) {
    if (this.isEmbed) {
      this.embedService.assistantContext$.subscribe(async (v) => {
        const options = await firstValueFrom(this.embedService.options$);
        this._response$.next({ ...v, experienceId: options?.assistantId });
      });
    }
  }

  get context$(): Observable<any> {
    return this._response$;
  }
}
