<section class="navigation-bar">
  <div #progressContainer class="progress-container">
    <div #progress class="progress"></div>

    <button
      *ngFor="let step of steps; let i = index"
      class="step"
      [class.completed]="step.completed"
      [class.active]="selectedIndex === i"
      (click)="onStepClicked(i)"
    >
      <span *ngIf="step.label" #label [class.completed]="step.completed" [class.active]="selectedIndex === i" class="step-label">
        {{ step.label }}
      </span>
    </button>
  </div>
</section>
