<u-icon class="plan-canceled-icon" [model]="{ type: 'img' }" [inSvg]="'/assets/workspace-plan/plan_canceled.svg'"></u-icon>
<h1 class="plan-canceled-title">No plan selected</h1>
<div class="plan-canceled-subtitle">
  <div>There is no active plan for this workspace.</div>
  <ng-container *ngTemplateOutlet="isOwner ? ownerCanceledMessage : canceledMessage"></ng-container>
  <u-button *ngIf="isOwner" class="select-plan-btn" label="Select a Plan" type="primary" (click)="selectPlanClicked.emit()"></u-button>
  <a class="log-out" (click)="logOut()">Log out</a>
</div>

<ng-template #ownerCanceledMessage>Please select a plan in order to use Unleash</ng-template>
<ng-template #canceledMessage
  >Please contact your workspace owner - <br />
  {{ ownerEmail }}</ng-template
>
