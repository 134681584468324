<div class="header">
  <div class="title">
    <span>Oops! Something went wrong...</span>
  </div>
  <div class="actions">
    <span class="action" (click)="onCreateIssue()">
      <u-icon class="icon" [model]="{ type: 'font', value: 'icon-plus-solid' }"></u-icon>
    </span>
    <span class="action" (click)="onCopy()">
      <u-icon class="icon" [model]="{ type: 'font', value: 'icon-copy' }"></u-icon>
    </span>
    <span class="action" (click)="onClose()">
      <u-icon class="icon" [model]="{ type: 'font', value: 'icon-times-circle1' }"></u-icon>
    </span>
  </div>
</div>

<div class="content" *ngIf="error$ | async as error">
  <div class="message">
    {{ error.message || error }}
  </div>
  <div class="stack" *ngIf="showStack">
    {{ error.stack }}
  </div>
</div>
