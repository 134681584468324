import { Injectable } from '@angular/core';
import { Favorites } from '@local/client-contracts';
import { FavoritesRpcInvoker, observable } from '@local/common';
import { ServicesRpcService } from '@shared/services';
import { firstValueFrom, map, Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FavoritesService implements Favorites.Service {
  private service: Favorites.Service;
  private _all$ = new ReplaySubject<Favorites.Item[]>(1);

  constructor(services: ServicesRpcService) {
    this.service = services.invokeWith(FavoritesRpcInvoker, 'favorites');
    this.service.all$.subscribe((a) => this._all$.next(a));
  }

  async isFavorite(id: string): Promise<boolean> {
    const all = await firstValueFrom(this._all$);
    return all.some((f) => f.id === id);
  }

  getByType$(type: Favorites.Type): Observable<Favorites.Item[]> {
    return this._all$.pipe(map((f) => f.filter((i) => i.type === type)));
  }

  create(context: Favorites.CreateRequest): Promise<void> {
    return this.service.create(context);
  }

  delete(resourceId: string): Promise<void> {
    return this.service.delete(resourceId);
  }

  @observable
  get all$(): Observable<Favorites.Item[]> {
    return this._all$;
  }
}
