<overlay>
  <ng-container overlay-title>{{ titleText }}</ng-container>

  <ng-container overlay-content *ngIf="displayText">{{ displayText }}</ng-container>

  <ng-container overlay-actions>
    <u-button
      *ngIf="showBack"
      label="Back"
      type="secondary"
      [styles]="{ width: '120px' }"
      style="margin: 8px"
      (onClick)="goBack()"
    ></u-button>
    <u-button *ngIf="showTryAgain" label="Try Again" type="primary" [styles]="{ width: '120px' }" style="margin: 8px" (click)="tryAgain()">
      <u-icon [model]="redoIcon" [styles]="{ fontSize: '14px', marginRight: '8px' }"></u-icon>
    </u-button>
  </ng-container>
</overlay>
