import { Injectable } from '@angular/core';
import { HomeSettings } from '@local/client-contracts';
import { observable } from '@local/common';
import { LogService, ServicesRpcService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';
import { ReplaySubject, distinctUntilChanged, map, firstValueFrom } from 'rxjs';
import { HomeSettingsRpcInvoker } from './invokers/home-settings.rpc-invoker';
import { cloneDeep, isEqual } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class HomeSettingsService {
  private logger: Logger;
  private service: HomeSettings.Service;
  private _settings$ = new ReplaySubject<HomeSettings.HomeSettings>(1);

  constructor(services: ServicesRpcService, logger: LogService) {
    this.logger = logger.scope('HomeSettingsService');
    this.service = services.invokeWith(HomeSettingsRpcInvoker, 'homesettings');
    this.service.all$.subscribe((all) => {
      this._settings$.next(all?.[0]);
    });
  }

  @observable
  get orderTabs$() {
    return this._settings$.pipe(
      distinctUntilChanged((prev, next) => {
        return isEqual(prev, next);
      }),
      map((s) => s?.tabIds || [])
    );
  }

  update(homeSettingId: string, actions: HomeSettings.UpdateAction[]): Promise<void> {
    return this.service.update(homeSettingId, actions);
  }

  refresh(): Promise<void> {
    return this.service.refresh();
  }

  async updateTabs(tabIds: string[]) {
    const settings = cloneDeep(await firstValueFrom(this._settings$));
    this._settings$.next({ ...settings, tabIds });
    return this.update(settings?.id, [{ field: 'tabIds', value: tabIds, type: 'Update' }]);
  }

  async addTab(newTabId: string) {
    const settings = await firstValueFrom(this._settings$);
    const tabIds = cloneDeep(settings?.tabIds) || [];
    tabIds.push(newTabId);
    return this.updateTabs(tabIds);
  }

  async removeTab(tabId: string) {
    const settings = await firstValueFrom(this._settings$);
    const tabIds = cloneDeep(settings?.tabIds) || [];
    const updatedTabs = tabIds.filter((t) => t !== tabId);
    if (updatedTabs.length !== tabIds.length) {
      return this.updateTabs(updatedTabs);
    }
  }
}
