import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouterService } from '@shared/services/router.service';
import { GlobalErrorHandler } from 'src/app/global-error-handler';

@UntilDestroy()
@Component({
  templateUrl: './assistant-error.component.html',
  styleUrls: ['./assistant-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssistantErrorComponent {
  error: Error;
  get error$() {
    return this.errorHandler.error$;
  }
  constructor(private errorHandler: GlobalErrorHandler, private routerService: RouterService) {
    this.error$.pipe(untilDestroyed(this)).subscribe((e) => {
      this.error = e;
      if (!e) this.routerService.back();
    });
  }
}
