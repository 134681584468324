import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TrackByFunction,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { eventToKeys, getKeySymbol, getModifiers, KeyName, removeModifiers } from '@local/ts-infra';
import { Tooltip } from 'primeng/tooltip';
import { TabComponent } from './tab/tab.component';
import { throttle } from 'lodash';

const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TabsComponent),
  multi: true,
};

@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class TabsComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy {
  @Output() ngModelChange = new EventEmitter<string>();
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @ViewChildren('tabLabel') tabsEl: QueryList<ElementRef>;
  @ViewChildren(Tooltip) tooltips: QueryList<Tooltip>;
  @Input() initialValue?: string;
  @Input() set tabChange(val: string) {
    if (!val) return;
    this.value = val;
  }
  private resizeObserver;
  activeTabWidth: number;
  indicatorPosition = 0;

  //The internal data model
  private innerValue = '';

  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  controlSymbol: string;

  //get accessor
  get value(): string {
    return this.innerValue;
  }

  //set accessor including call the onchange callback
  set value(v: string) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
      this.ngModelChange.emit(v);
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const keys: KeyName[] = eventToKeys(event);
    const modifiers: KeyName[] = getModifiers(keys);
    if (modifiers.length !== 1) return;

    const [modifier] = modifiers;
    if (!['command', 'control'].includes(modifier)) return;

    const nonModifiers: KeyName[] = removeModifiers([...keys]);
    if (!nonModifiers.length) {
      this.tooltips['_results'].forEach((t: Tooltip) => t.container ?? t.show());
      return;
    }
    const [nonModifier] = nonModifiers;
    const prefix = 'digit';
    if (!nonModifier.startsWith(prefix)) return;
    const tabIndex: number = parseInt(nonModifier.substring(prefix.length)) - 1;
    const id: string = this.tabs.get(tabIndex)?.id;
    if (!id) {
      this.tooltips.forEach((t: Tooltip) => t.hide());
      return;
    }
    this.value = id;
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    const keys: KeyName[] = eventToKeys(event);
    const modifiers: KeyName[] = getModifiers(keys);
    const nonModifiers: KeyName[] = removeModifiers([...keys]);
    if (modifiers.length !== 1 || nonModifiers.length) return;
    const [modifier] = modifiers;
    if (modifier !== 'command' && modifier != 'control') return;
    this.tooltips['_results'].forEach((t: Tooltip) => t.hide());
  }

  constructor(private cdr: ChangeDetectorRef) {
    this.controlSymbol = getKeySymbol('control');
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.tooltips?.forEach((t: Tooltip) => t.hide());
      }
    });
  }

  ngOnDestroy(): void {
    this.resizeObserver?.disconnect();
  }

  ngOnInit() {
    this.ngModelChange.subscribe((v) => {
      this.selectTab(v);
    });
  }

  ngAfterViewInit() {
    // Initialize active tab according to initial value
    // if there is no active tab set, activate the first
    this.tabs.notifyOnChanges();
    this.initialValue ? this.selectTab(this.initialValue) : this.selectTab(this.tabs.first.id);
    this.tabs.changes.subscribe(() => {
      this.cdr.detectChanges();
    });
    this.tabsEl.toArray().forEach((t) => {
      const throttledUpdate = throttle(() => this.updateTabsWidth(), 20);
      this.resizeObserver = new (<any>window).ResizeObserver(() => {
        throttledUpdate();
      });
      this.resizeObserver.observe(t.nativeElement);
    });
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  selectTab(tabId: string) {
    const index = this.tabs.toArray().findIndex((tab) => tab.id === tabId);
    const curr = this.tabs.toArray()[index];
    if (!curr) return;

    // deactivate all tabs
    this.tabs.toArray().forEach((tab) => (tab.active = false));

    // activate the tab the user has clicked on.
    curr.active = true;

    this.updateTabsWidth();
    this.cdr.markForCheck();
  }

  private updateTabsWidth() {
    const index = this.tabs.toArray().findIndex((tab) => tab.id === this.innerValue);

    const tabsWidth = this.tabsEl.map((t) => t.nativeElement.offsetWidth);

    this.activeTabWidth = tabsWidth[index];
    let newPosition = 0;

    for (const i of tabsWidth.keys()) {
      if (i < index) {
        newPosition += tabsWidth[i];
      }
    }

    this.indicatorPosition = newPosition;
    this.cdr.markForCheck();
  }

  trackTabs: TrackByFunction<TabComponent> = (index: number, item: TabComponent): string => item.id;
}
