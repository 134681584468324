import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PopupRef } from '@local/ui-infra';

export interface RenameAttachmentPopupData {
  text: string;
  inputPlaceholder?: string;
  defaultInputModel?: string;
}

@UntilDestroy()
@Component({
  selector: 'rename-attachment-popup',
  templateUrl: './rename-attachment-popup.component.html',
  styleUrls: ['./rename-attachment-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenameAttachmentPopupComponent implements OnInit {
  private readonly DEFAULT_TITLE = 'Untitled';
  private newName: string;

  @Input() text: string;
  @Input() inputPlaceholder: string;
  @Input() defaultInputModel: string = this.DEFAULT_TITLE;

  @Output() onRename = new EventEmitter<string>();

  constructor(private ref: PopupRef<RenameAttachmentPopupComponent, RenameAttachmentPopupData>) {}

  ngOnInit(): void {
    if (!this.ref.data) return;
    const { text, inputPlaceholder, defaultInputModel } = this.ref.data;
    this.text = text;
    this.inputPlaceholder = inputPlaceholder || text;
    this.defaultInputModel = defaultInputModel || this.DEFAULT_TITLE;
  }

  async rename() {
    if (this.newName === this.text) return;
    this.text = this.newName || this.DEFAULT_TITLE;
    this.onRename.emit(this.newName);
  }

  onChangeName(val: string) {
    this.newName = val;
  }
}
