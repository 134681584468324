import { EventEmitter } from '@angular/core';
import { Applications, OAuth, OAuth1, OAuth2, Signin } from '@local/client-contracts';
import { take } from 'rxjs';

export abstract class OAuthWindow {
  public userClosed$: EventEmitter<null> = new EventEmitter<null>();
  public completed$: EventEmitter<string> = new EventEmitter<string>();
  public isOpen$: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected _settings?: OAuthSettings;

  abstract open(changeLocation?: boolean): void;
  abstract close(): void;
  abstract changeLocation(): Promise<void>;
  destroy(): void {
    this._settings.destroy();
  }
  get settings(): OAuthSettings {
    return this._settings;
  }
}

export class OAuth2UnattendedWindow extends OAuthWindow {
  constructor(private oauth2Sessions: OAuth2.SessionService, settings: OAuth2UnattendedSettings) {
    super();
    this._settings = settings;
  }
  open(changeLocation?: boolean): void {
    if (changeLocation) {
      this.changeLocation();
    }
  }
  close(): void {
    return;
  }
  async changeLocation(): Promise<void> {
    const url = await this._settings.generateUrl();
    await this.oauth2Sessions.complete(url);
  }
}
export class OAuthNoOpWindow extends OAuthWindow {
  changeLocation(): Promise<void> {
    return;
  }
  open(changeLocation?: boolean): void {}
  close(): void {}
}
export class OAuthBrowserWindow extends OAuthWindow {
  changeLocation(): Promise<void> {
    throw new Error('Change location is not supported on browser windows.');
  }

  constructor(settings: OAuthSettings) {
    super();
    this._settings = settings;
  }

  close(): void {}

  open(): void {
    this.settings.generateUrl().then((url) => {
      const u = new URL(url);
      u.searchParams.append('blink', '1');
      window.open(u.toString(), '_blank');
    });
  }
}

export class OAuthPopupWindow extends OAuthWindow {
  private window: Window;
  private closeInterval: NodeJS.Timeout;

  constructor(settings: OAuthSettings) {
    super();
    this._settings = settings;
    this.userClosed$.pipe(take(1)).subscribe(() => this.isOpen$.emit(false));
  }

  close(): void {
    if (this.window && !this.window.closed) {
      clearInterval(this.closeInterval);
      this.window.close();
      this.isOpen$.emit(false);
    }
  }

  public async changeLocation(): Promise<void> {
    if (!this.window) {
      return;
    }
    const url = await this.settings.generateUrl();
    this.window.location.href = url;
    this.window.focus();
  }

  public get isBlank(): boolean {
    return this.window && this.window.location.href === 'about:blank';
  }

  open(changeLocation?: boolean): void {
    const height = Math.floor(Math.max(Math.floor(window.outerHeight * 0.4), 550));
    const width = Math.floor(Math.max(Math.floor(window.outerWidth * 0.35), 700));
    this.window = window.open('', '_blank', this.getOAuthWindowProperties(width, height));

    this.closeInterval = setInterval(() => {
      if (this.window && this.window.closed) {
        if (this.closeInterval) {
          clearInterval(this.closeInterval);
        }
        this.userClosed$.emit(null);
      }
    }, 100);

    if (changeLocation) {
      this.changeLocation();
    }

    this.isOpen$.emit(true);
  }

  private getOAuthWindowProperties(w: number, h: number) {
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    return `height=${h},width=${w},status=yes,toolbar=no,menubar=no,location=no,top=${top},left=${left}`;
  }
}

export class MixedOAuthSettings implements OAuthSettings {
  private authType?: Applications.AuthenticationType;
  private settings?: OAuth1.Request | OAuth2.Request;

  constructor(
    private id: string,
    private app: Applications.DisplayItem,
    private oauth1Sessions: OAuth1.SessionService,
    private oauth2Sessions: OAuth2.SessionService
  ) {}

  public async generateUrl(): Promise<string> {
    if (!this.authType) {
      throw new Error('MixedOAuthSettings must be prepared first.');
    }
    const oauth2Settings = this.settings as OAuth2.Request;
    const oauth1Settings = this.settings as OAuth1.Request;

    let result: OAuth.Session;
    if (this.authType === 'oauth2') {
      result = await this.oauth2Sessions.create(this.id, oauth2Settings, this.app.id);
    } else if (this.authType === 'oauth1') {
      result = await this.oauth1Sessions.create(this.id, oauth1Settings, this.app.id);
    }

    return result.url;
  }

  prepare(authType: Applications.AuthenticationType, settings: OAuth1.Request | OAuth2.Request): void {
    this.authType = authType;
    this.settings = settings;
  }

  destroy() {
    this.oauth1Sessions.destroy(this.id);
    this.oauth2Sessions.destroy(this.id);
  }
}

export interface OAuthSettings {
  generateUrl(): Promise<string>;
  destroy();
}
export abstract class OAuthSettingsBase<TRequest, TResult extends OAuth.SessionResult> implements OAuthSettings {
  constructor(private id: string, private app: Applications.DisplayItem, private sessions: OAuth.SessionService<TRequest, TResult>) {}

  public async generateUrl(): Promise<string> {
    const settings = this.getSettings();

    const result = await this.sessions.create(this.id, settings, this.app.id);
    return result.url;
  }

  abstract getSettings(): TRequest;

  destroy() {
    this.sessions.destroy(this.id);
  }
}

export class OAuth2Settings extends OAuthSettingsBase<OAuth2.Request, OAuth2.SessionResult> {
  constructor(
    id: string,
    app: Applications.DisplayItem,
    sessions: OAuth.SessionService<OAuth2.Request, OAuth2.SessionResult>,
    private linkSettings: Applications.LinkSettings
  ) {
    super(id, app, sessions);
  }
  getSettings(): OAuth2.Request {
    const settings = this.linkSettings?.authentication?.oAuth2;

    return {
      clientId: settings.clientId,
      endpoint: settings.authorizeEndpoint,
      redirectUri: settings.redirectUri,
      scopes: settings.scopes,
      grantType: 'code',
    };
  }
}

export class OAuth2SignInSettings implements OAuthSettings {
  constructor(
    private id: string,
    private sessions: OAuth.SessionService<OAuth2.Request, OAuth2.SessionResult>,
    private settings: Signin.SignInSettings
  ) {}
  public async generateUrl(): Promise<string> {
    const settings = this.getSettings();

    const result = await this.sessions.create(this.id, settings);
    return result.url;
  }

  getSettings(): OAuth2.Request {
    const settings = this.settings;

    return {
      name: settings.name,
      clientId: settings.clientId,
      endpoint: settings.authorizeEndpoint,
      redirectUri: settings.redirectUri,
      scopes: settings.scopes,
      grantType: 'code',
    };
  }
  destroy() {
    this.sessions.destroy(this.id);
  }
}

export class OAuth2UnattendedSettings extends OAuth2Settings {
  constructor(
    id: string,
    app: Applications.DisplayItem,
    sessions: OAuth.SessionService<OAuth2.Request, OAuth2.SessionResult>,
    linkSettings: Applications.LinkSettings,
    private refreshToken: string
  ) {
    super(id, app, sessions, linkSettings);
  }
  getSettings(): OAuth2.Request {
    const settings = super.getSettings();
    settings.grantType = 'refresh';
    settings.refreshToken = this.refreshToken;
    return settings;
  }
}
export class OAuth1Settings extends OAuthSettingsBase<OAuth1.Request, OAuth1.SessionResult> {
  constructor(
    id: string,
    app: Applications.DisplayItem,
    sessions: OAuth.SessionService<OAuth1.Request, OAuth1.SessionResult>,
    private linkSettings: Applications.LinkSettings
  ) {
    super(id, app, sessions);
  }

  getSettings(): OAuth1.Request {
    const settings = this.linkSettings?.authentication?.oAuth1;

    return {
      authorizeUri: settings.authorizeEndpoint,
      redirectUri: settings.redirectUri,
      scopes: settings.scopes,
      consumerKey: settings.consumerKey,
      extraAuthParams: settings.authorizeParameters?.reduce((a, v) => ({ ...a, [v.name]: v.value }), {}),
    };
  }
}
