import { FlexibleConnectedPositionStrategyOrigin } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { Mail, Search } from '@local/client-contracts';
import { PopupRef, PopupService } from '@local/ui-infra';
import { ServicesRpcService } from '@shared/services';
import { filter, take } from 'rxjs';
import { PreviewService, ResultPopupData } from 'src/app/bar/services/preview.service';
import { MailRpcInvoker } from '../../../../services/invokers/mail-rpc-invoker';
import { Recipient } from '../../../results-views/mail/mail-build-result-view.service';
import { InvokeCommand } from '../../../results/models/invoke-command.model';
import { MailPreviewPopupComponent } from '../components/mail-preview-popup/mail-preview-popup.component';
import { MailPreviewRecipientsComponent } from '../components/mail-preview-recipients/mail-preview-recipients.component';

export type MailCommandModel = { command: InvokeCommand; model: Search.ResultResourceItem };
@Injectable({
  providedIn: 'root',
})
export class MailService {
  private readonly previewType = 'mail';
  private service: Mail.Service;
  private _popupRef: PopupRef<MailPreviewPopupComponent, ResultPopupData>;
  private _recipientsPopupRef: PopupRef<MailPreviewRecipientsComponent, Recipient[]>;

  get popupRef(): PopupRef<MailPreviewPopupComponent, ResultPopupData> {
    return this._popupRef;
  }
  set popupRef(value: PopupRef<MailPreviewPopupComponent, ResultPopupData>) {
    this._popupRef = value;
  }

  get popupRefRecipients(): PopupRef<MailPreviewRecipientsComponent, Recipient[]> {
    return this._recipientsPopupRef;
  }

  set popupRefRecipients(value: PopupRef<MailPreviewRecipientsComponent, Recipient[]>) {
    this._recipientsPopupRef = value;
  }

  constructor(private popupService: PopupService, service: ServicesRpcService, private previewService: PreviewService) {
    this.service = service.invokeWith(MailRpcInvoker, 'mail');

    this.previewService.openPreviewPopup$.pipe(filter((i) => i.previewType === this.previewType)).subscribe(({ data }) => {
      const popupData = data as ResultPopupData;
      this.openMailPopUp(popupData);
    });

    this.previewService.clearPreviewPopup$.pipe(filter((previewType) => previewType === this.previewType)).subscribe(() => {
      if (this.popupRef) {
        this.popupRef.destroy();
        this.popupRef = null;
      }
    });
  }

  get(id: string): Promise<Mail.Mail> {
    return this.service.get(id);
  }

  refreshInbox(): Promise<void> {
    return this.service.refreshInbox();
  }

  get isMailPopupOpen() {
    return this.popupRef;
  }

  openMailPopUp(data: ResultPopupData): PopupRef<MailPreviewPopupComponent, ResultPopupData> {
    if (this.popupRef) {
      this.popupRef.destroy();
    }
    this.popupRef = this.popupService.open('center', MailPreviewPopupComponent, data, {
      position: 'center',
      backdropStyle: 'blur-2',
    });

    this.popupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.popupRef.destroy();
      this.popupRef = null;
      this.previewService.onDestroyPreview(data?.item as Search.ResultResourceItem, this.previewType, data?.preventClearQueryParams);
      this.previewService.setPreviewState('previous', data.index, data.item);
    });
    return this.popupRef;
  }

  openRecipientsPopup(recipients: Recipient[], positionOrigin: FlexibleConnectedPositionStrategyOrigin | 'center') {
    if (this.popupRefRecipients) {
      this.popupRefRecipients.destroy();
    }
    this.popupRefRecipients = this.popupService.open(positionOrigin, MailPreviewRecipientsComponent, recipients);
    this.popupRefRecipients.destroy$.pipe(take(1)).subscribe(() => {
      this.popupRefRecipients.destroy();
      this.popupRefRecipients = null;
    });
    return this.popupRefRecipients;
  }
}
