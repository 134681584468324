import { Injectable } from '@angular/core';
import { Wiki } from '@local/client-contracts';
import { LogService, ServicesRpcService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { observable } from '@local/common';
import { WikiDraftsRpcInvoker } from '../invokers/wiki-drafts-rpc-invoker';
import { HubService } from '../hub.service';

@Injectable()
export class WikiDraftsService {
  private service: Wiki.DraftsService;
  private logger: Logger;
  private _all$ = new BehaviorSubject<Wiki.DraftStructure[]>(null);

  constructor(logger: LogService, services: ServicesRpcService, private hubService: HubService) {
    this.logger = logger.scope('WikiDraftsService');
    this.service = services.invokeWith(WikiDraftsRpcInvoker, 'wikidrafts');
    this.service.all$.subscribe((all) => {
      this._all$.next(all);
    });
  }

  @observable
  get all$(): Observable<Wiki.DraftStructure[]> {
    return this._all$.asObservable();
  }

  get all(): Wiki.DraftStructure[] {
    return this._all$.value;
  }

  @observable
  get draftsCount$(): Observable<number> {
    return this.service.draftsCount$;
  }

  async get(cardId: string, useCache: boolean): Promise<Wiki.DraftStructure> {
    return this.service.get(cardId, useCache);
  }

  async create(req: Wiki.CreateDraftRequest): Promise<string> {
    return this.service.create(req);
  }

  async delete(cardId: string): Promise<void> {
    return this.service.delete(cardId);
  }

  async update(req: Wiki.UpdateDraftRequest): Promise<string> {
    return this.service.update(req);
  }

  async publish(req: Wiki.PublishDraftRequest): Promise<Wiki.Card> {
    return this.service.publish(req);
  }

  async openAllDrafts() {
    const url = '/drafts';
    this.hubService.openUrl(url);
  }
}
