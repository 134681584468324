import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Omnibox } from '@local/client-contracts';

@Component({
  selector: 'suggestions-autocomplete',
  templateUrl: './suggestions-autocomplete.component.html',
  styleUrls: ['./suggestions-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionsAutocompleteComponent {
  get lastQueryWord() {
    return this.query?.slice(Math.max(0, this.query.lastIndexOf(' ')))?.trim();
  }

  get nonLastQueryWord() {
    return this.query?.slice(this.query.lastIndexOf(this.lastQueryWord), this.query.length).trim();
  }
  EXCLUDED_TAGS = ['account', 'app', 'type', 'service'];
  @Input() model: Omnibox.Suggestion;
  @Input() query: string;
  @Output() select = new EventEmitter<void>();

  onSelect() {
    this.select.emit();
  }
}
