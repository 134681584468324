import { Injectable } from '@angular/core';
import { HomeTabs, Permissions } from '@local/client-contracts';
import { observable } from '@local/common';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EventsService, LogService, ServicesRpcService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { HomeSettingsService } from './home-settings.service';
import { HomeTabsRpcInvoker } from './invokers/home-tabs.rpc-invoker';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class HomeTabsService {
  private logger: Logger;
  private service: HomeTabs.Service;
  private _all$ = new ReplaySubject<HomeTabs.HomeTab[]>(1);
  private _createTab$ = new Subject<HomeTabs.HomeTab>();
  private _moveToTab$ = new Subject<string>();

  constructor(
    services: ServicesRpcService,
    logger: LogService,
    private eventsService: EventsService,
    private homeSettingsService: HomeSettingsService
  ) {
    this.logger = logger.scope('HomeTabsService');
    this.service = services.invokeWith(HomeTabsRpcInvoker, 'hometabs');
    this.service.all$.subscribe((all) => {
      this._all$.next(all);
    });
  }

  @observable
  get all$(): Observable<HomeTabs.HomeTab[]> {
    return this._all$.asObservable();
  }

  @observable
  get createTab$() {
    return this._createTab$;
  }

  @observable
  get moveToTab$(): Observable<string> {
    return this._moveToTab$.asObservable();
  }

  async create(id: string, name?: string): Promise<HomeTabs.HomeTab> {
    const tab: HomeTabs.HomeTab = { name: name || 'New Tab', id, disabled: false, widgets: [] };
    this.eventsService.event('home_tabs.create', {
      location: { title: 'home' },
    });
    this._createTab$.next(tab);
    await this.homeSettingsService.addTab(id);
    return this.service.create(tab);
  }

  async addWidget(tabId: string, widget: HomeTabs.Widget, addAsFirst?: boolean, newState?: boolean): Promise<HomeTabs.Widget> {
    return this.service.addWidget(tabId, widget, addAsFirst, newState);
  }

  async deleteWidget(tabId: string, widgetId: string): Promise<void> {
    return this.service.deleteWidget(tabId, widgetId);
  }

  async delete(id: string): Promise<void> {
    await this.homeSettingsService.removeTab(id);
    return this.service.delete(id);
  }

  async update(homeTab: HomeTabs.HomeTab, actions: HomeTabs.UpdateAction[], shareOptions?: Permissions.ShareOptions): Promise<void> {
    return this.service.update(homeTab, actions, shareOptions);
  }

  async get(tabId: string): Promise<HomeTabs.HomeTab> {
    return this.service.get(tabId);
  }

  async updateWidgets(tabId: string, widgets: HomeTabs.Widget[]): Promise<void> {
    return this.service.updateWidgets(tabId, widgets);
  }

  async getHomeTabId(): Promise<string> {
    return this.service.getHomeTabId();
  }

  refresh(): Promise<void> {
    return this.service.refresh();
  }

  async moveWidgetToTab(
    widget: HomeTabs.Widget,
    currentTabId: string,
    newTabId: string,
    shared?: boolean,
    addAsFirst?: boolean,
    navigate?: boolean
  ) {
    await this.deleteWidget(currentTabId, widget.id);
    await this.addWidget(newTabId, { ...widget, tabId: newTabId, isShared: shared }, addAsFirst, currentTabId !== newTabId);
    if (navigate) {
      this._moveToTab$.next(newTabId);
    }
  }
}
