import { Component, ComponentRef, Input, OnInit, ViewChild } from '@angular/core';
import { DynamicComponentBase } from './dynamic-component-base.component';
import { DynamicComponentItem } from './dynamic-component-item.model';
import { DynamicComponentPlaceholderDirective } from './dynamic-component-placeholder.directive';

@Component({
  selector: 'dynamic-component-loader',
  template: `<ng-template dynamicComponentPlaceholder></ng-template>`,
})
export class DynamicComponentLoaderComponent implements OnInit {
  @Input() set dynamicItem(item: DynamicComponentItem<any>) {
    this._dynamicItem = item;
    this.loadComponent();
  }
  @Input() componentFather: any;
  @ViewChild(DynamicComponentPlaceholderDirective, { static: true }) dynamicPlaceholderHost!: DynamicComponentPlaceholderDirective;

  private _dynamicItem: DynamicComponentItem<any>;
  private onInit: boolean;
  private componentRef: ComponentRef<DynamicComponentBase<any>>;

  get dynamicItem() {
    return this._dynamicItem;
  }

  get instance() {
    return this.componentRef.instance;
  }

  ngOnInit(): void {
    this.onInit = true;
    this.loadComponent();
  }

  loadComponent() {
    if (!this.dynamicItem || !this.onInit) {
      return;
    }
    const viewContainerRef = this.dynamicPlaceholderHost.viewContainerRef;
    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent<DynamicComponentBase<any>>(this.dynamicItem.component);
    this.componentRef.instance.data = this.dynamicItem.data;
    this.componentRef.instance.componentFather = this.componentFather;
  }
}
