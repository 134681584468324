import { PopupService } from '@local/ui-infra';
import { Injectable } from '@angular/core';
import { HomePinsService } from '../home-pins.service';
import { HomeTabsService } from '../home-tabs.service';
import { HomePins } from '@local/client-contracts';
import { WorkspacesService } from '../workspaces.service';
import { HubService } from '../hub.service';
import { SelectedItem, TelemetryEvent } from '../../components/selected-item-popup/selected-item-popup.component';
import { EventsService } from '@shared/services';
import { MoveWidgetToTabSelect } from '../../components/selected-item-popup/models/move-widget-to-tab-select';
import { cutText } from '@local/ts-infra';
import { generateId, isCollectionWidget } from '@local/common-web';
import { AvatarListService } from '../avatar-list.service';
import { ShowToasterService } from '../show-toaster.service';
import { SelectItemsBasePopupService } from './select-items-popup-base.service';
import { SelectedItemPopupData } from '../../components/selected-item-popup/models/select-item-base';
import { CollectionsService } from '../collections.service';
import { filter } from 'rxjs';

@Injectable()
export class MoveHeaderWidgetToTabPopupService extends SelectItemsBasePopupService<MoveWidgetToTabSelect> {
  private readonly READONLY_TABS = ['home'];
  private readonly HOME_TAB: SelectedItem;
  private readonly SETUP_DATA: SelectedItemPopupData = {
    title: 'Move to Tab',
    type: 'collection',
    description: 'Select which tab you want to pin the header to',
    placeholder: 'Select Tab',
  };
  private selectedTabs: SelectedItem[] = [];

  constructor(
    protected popupService: PopupService,
    protected workspacesService: WorkspacesService,
    protected homePinsService: HomePinsService,
    protected homeTabService: HomeTabsService,
    protected showToasterService: ShowToasterService,
    protected hubService: HubService,
    protected eventsService: EventsService,
    protected avatarListService: AvatarListService,
    protected collectionsService: CollectionsService
  ) {
    super(popupService, workspacesService);
    this.HOME_TAB = {
      name: 'Home',
      id: 'home',
      icon: 'icon-arrow-right-square',
      avatarList: [this.avatarListService.createWorkspaceAvatar()],
    };
    this.initTabs();
    this.isInvalidItem = (selectedItem: SelectedItem) => !selectedItem?.name;
    this.collectionsService.openSelectPopup$.pipe(filter((r) => r.type === 'move-header-widget')).subscribe((res) => {
      this.openSelectedItemPopup(res.data as MoveWidgetToTabSelect);
    });
  }

  protected getPopupDataOptions(): SelectedItemPopupData {
    return this.SETUP_DATA;
  }

  private initTabs() {
    this.homeTabService.all$.subscribe(async (all) => {
      const list: SelectedItem[] = [];
      for (const tab of all) {
        if (!this.READONLY_TABS.includes(tab.id)) {
          const avatarList = await this.avatarListService.createAvatarList(tab.accountId, tab.shareOptions, false);
          list.push({
            name: tab.name,
            icon: 'icon-arrow-right-square',
            id: tab.id,
            avatarList,
          });
        }
      }
      list.unshift(this.HOME_TAB);
      this.selectedTabs = list;
    });
  }

  async openSelectedItemPopup(data: MoveWidgetToTabSelect) {
    data.itemsList = data.selectedTabId ? this.selectedTabs?.filter((t) => t.id !== data.selectedTabId) : this.selectedTabs;
    return super.openSelectedItemPopup(data);
  }

  protected async initSelectItems() {}
  protected async init() {}

  protected onTelemetryEvent(event: TelemetryEvent) {
    this.eventsService.event('home_tabs.selected_tab', {
      location: { title: this.hubService.currentLocation },
      target: event?.target ?? 'tab_selection',
      label: event?.trigger ?? 'mouse_click',
    });
  }

  protected async onPrimaryButtonClick() {
    const selectedItem = this.selectedItem as SelectedItem;
    const updateActions: HomePins.UpdateAction[] = [{ field: 'tabId', type: 'Update', value: selectedItem.id }];
    const widget = this.data.widget;
    if (!widget) {
      return;
    }
    let updateHomePin;
    if (isCollectionWidget(this.data.widget)) {
      const homePin = this.homePinsService.getByPinedId(this.data.widget.collectionId);
      if (homePin) {
        updateHomePin = this.homePinsService.update(homePin, updateActions, !this.isOwnerOrAdmin);
      }
    }
    const isShare = !this.isOwnerOrAdmin;
    if (selectedItem.isNew) {
      selectedItem.id = generateId();
      await this.homeTabService.create(selectedItem.id, selectedItem.name);
    }
    await this.homeTabService.moveWidgetToTab(
      widget,
      widget.tabId,
      selectedItem.id,
      isShare,
      this.data.moveToFirst,
      this.hubService.currentLocation === 'home'
    );
    if (updateHomePin) {
      await updateHomePin;
    }
    if (this.data.toasterContent) {
      this.showToasterService.showToaster({
        id: 'move-to-tab',
        content: this.data.toasterContent.replace('{*}', cutText(selectedItem.name, 40)),
      });
    }
  }
}
