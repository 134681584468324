<div
  class="file-preview-popup-container"
  [ngClass]="{ launcher: isLauncher, 'window-mode': windowMode }"
  (click)="$event.stopPropagation()"
>
  <div *ngIf="windowMode" class="drag-div"></div>
  <file-preview-container
    [isLauncher]="isLauncher"
    [model]="model"
    [size]="'popup'"
    (onClosePopup)="closePopup()"
    (loaded)="loaded.emit()"
    [isPopup]="true"
  ></file-preview-container>
</div>
