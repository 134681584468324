import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, TemplateRef, HostListener } from '@angular/core';

@Component({
  selector: 'tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {
  @Input() tabTemplate: TemplateRef<any>;
  @Input() title: string;
  @Input() active = false;
  @Input() id: string;
  constructor() {}
}
