import { animate, state, style, transition, trigger } from '@angular/animations';
import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TrackByFunction } from '@angular/core';
import { Commands, Downloads } from '@local/client-contracts';
import { isResourceDownloadRequest } from '@local/common';
import { EventInfo, EventsService } from '@shared/services';
import { DownloadsService } from '@shared/services/downloads.service';
import { RouterService } from '@shared/services/router.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommandsService } from 'src/app/bar/services/commands/commands.service';
import { HubService } from 'src/app/bar/services/hub.service';

@Component({
  selector: 'downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1, filter: 'blur(0)' })),
      transition(':enter', [style({ opacity: 0, filter: 'blur(4px)' }), animate(200)]),
      transition(':leave', animate(250, style({ opacity: 0, filter: 'blur(4px)' }))),
    ]),
    trigger('change', [
      transition(':enter', [style({ opacity: 0 }), animate(200, style({ opacity: 1 }))]),
      transition(':leave', [animate(200, style({ opacity: 0 }))]),
    ]),
  ],
})
export class DownloadsComponent implements OnInit, OnDestroy {
  all: Downloads.Downloads;
  isOpen: boolean;
  private destroy$ = new Subject();
  readonly clearCountdown: number = 5000;

  trackById: TrackByFunction<KeyValue<string, Downloads.Download>> = (index: number, item: KeyValue<string, Downloads.Download>) =>
    item.key;

  constructor(
    public downloadsService: DownloadsService,
    private commandsService: CommandsService,
    private cdr: ChangeDetectorRef,
    private barService: HubService,
    private eventsService: EventsService,
    private routingService: RouterService
  ) {}

  ngOnInit(): void {
    this.routingService.active$.pipe(takeUntil(this.destroy$)).subscribe(() => this.onPageChange());
    this.downloadsService.downloadsChange.subscribe(() => this.onDownloadsChange());
    this.downloadsService.downloadStarted.subscribe(() => this.open());
  }

  private onDownloadsChange(): void {
    const newAll = {};
    const ids = Object.keys(this.downloadsService.all);
    const setTimeoutIds = [];
    ids.forEach((id) => {
      if (this.downloadsService.all[id].visibility !== 'hidden') {
        newAll[id] = this.downloadsService.all[id];
        if (!this.all[id]) {
          setTimeoutIds.push(id);
        }
      }
    });

    setTimeout(() => {
      setTimeoutIds.forEach((id) => {
        if (this.all[id]?.status === 'completed') {
          this.onClear(id, 'auto');
        }
      });
    }, this.clearCountdown);

    this.all = newAll;
    this.cdr.markForCheck();
  }

  private open() {
    this.isOpen = true;
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
    const eventMetadata: Partial<EventInfo> = this.getClicksMetadata(this.isOpen ? 'expand' : 'collapse');
    this.eventsService.event('drag_drop.clicks', eventMetadata);
    this.cdr.markForCheck();
  }

  onPageChange(): void {
    if (this.downloadsService.allCount < 2) {
      if (!this.isOpen) this.open();
      return;
    }
    this.isOpen = false;
    this.cdr.markForCheck();
  }

  async onOpen(id: string): Promise<void> {
    const { path, name, status, visibility } = this.downloadsService.all[id];
    if (status !== 'completed' || visibility !== 'visible') return;

    const eventMetadata: Partial<EventInfo> = this.getClicksMetadata('open', id);
    this.eventsService.event('drag_drop.clicks', eventMetadata);

    const command: Commands.OpenUrl = { type: 'open-url', url: `${path}/${name}` };
    await this.commandsService.executeCommand(command, {});
    this.onClear(id, 'auto');
  }

  async onShow(id: string): Promise<void> {
    const eventMetadata: Partial<EventInfo> = this.getClicksMetadata('show', id);
    this.eventsService.event('drag_drop.clicks', eventMetadata);
    const { path } = this.downloadsService.all[id];
    const command: Commands.OpenUrl = { type: 'open-url', url: path };
    await this.commandsService.executeCommand(command, {});
    this.onClear(id, 'auto');
  }

  async onCancel(id: string): Promise<void> {
    const eventMetadata: Partial<EventInfo> = this.getClicksMetadata('cancel', id);
    this.eventsService.event('drag_drop.clicks', eventMetadata);
    await this.downloadsService.cancel(id);
    this.cdr.markForCheck();
  }

  async onRetry(id: string): Promise<void> {
    const eventMetadata: Partial<EventInfo> = this.getClicksMetadata('retry', id);
    this.eventsService.event('drag_drop.clicks', eventMetadata);
    await this.downloadsService.retry(id);
    this.cdr.markForCheck();
  }

  async onClear(id: string, trigger = 'click'): Promise<void> {
    if (trigger === 'click') {
      const eventMetadata: Partial<EventInfo> = this.getClicksMetadata('clear', id);
      this.eventsService.event('drag_drop.clicks', eventMetadata);
    }
    await this.downloadsService.clear(id);
    if (!Object.keys(this.downloadsService.all).length) this.isOpen = false;
    this.cdr.markForCheck();
  }

  async clearAll(): Promise<void> {
    const eventMetadata: Partial<EventInfo> = this.getClicksMetadata('clear');
    this.eventsService.event('drag_drop.clicks', eventMetadata);
    await this.downloadsService.clearAll();
    this.isOpen = false;
    this.cdr.markForCheck();
  }

  private getClicksMetadata(target: string, id?: string): Partial<EventInfo> {
    const download = id ? this.downloadsService.all[id] : undefined;
    let label: string;
    if (download && isResourceDownloadRequest(download)) {
      label = download.resource.appId;
    }
    return {
      category: 'interaction.click',
      name: 'downloads_bar',
      target,
      label,
    };
  }
}
