<ng-container [ngSwitch]="pickerButtonType">
  <ng-container *ngSwitchCase="'font'">
    <u-icon
      [class.readonly]="readonly"
      class="select-emoji border"
      (click)="!readonly ? openPickerPopup($event) : null"
      [model]="model"
      [styles]="{ fontSize: placeholderFontSize, color: placeholderColor }"
    ></u-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'emoji'">
    <div class="select-emoji" [class.readonly]="readonly" (click)="!readonly ? openPickerPopup($event) : null">
      <u-emoji [emoji]="sheet" [unicode]="emojiUnicode" [nativePrefix]="nativePrefix" [spriteEmojiSize]="spriteEmojiSize"></u-emoji>
    </div>
  </ng-container>
</ng-container>
