<ng-container *ngTemplateOutlet="componentType === 'popup' ? popup : signInPage"></ng-container>

<ng-template #signInPage>
  <h1>Collaborate with your team</h1>
  <h4>Invite members and grant access to the workspace</h4>

  <div class="email-container">
    <div class="invite-title">Invite by email</div>

    <ng-container *ngTemplateOutlet="chips"></ng-container>

    <ng-container *ngTemplateOutlet="linkBtnContainer"></ng-container>
  </div>

  <ng-container *ngTemplateOutlet="sendInviteBtn"></ng-container>

  <div class="skip" (click)="skip()">Skip for now</div>
</ng-template>

<ng-template #popup>
  <u-icon class="close-icon" (mousedown)="closePopup()" [model]="closeIcon" [styles]="{ fontSize: '12px' }"></u-icon>
  <h1>Invite to Workspace</h1>
  <h4>Invite members and grant access to the workspace</h4>

  <ng-container *ngTemplateOutlet="chips"></ng-container>

  <ng-container *ngTemplateOutlet="linkBtnContainer"></ng-container>

  <div class="popup-footer-btns">
    <u-button class="cancel-btn" label="Cancel" type="secondary" [styles]="{ width: '100%' }" (onClick)="closePopup()"></u-button>
    <ng-container *ngTemplateOutlet="sendInviteBtn"></ng-container>
  </div>
</ng-template>

<ng-template #linkBtnContainer>
  <div class="invite-link-container border-bottom">
    <div class="invite-link-description">
      Share this link to invite others to <br />
      the workspace
    </div>
    <u-button
      (onClick)="onCopyInviteClick()"
      tooltipPosition="top"
      [tooltipDisabled]="!tooltipText"
      [pTooltip]="tooltipText"
      tooltipStyleClass="u-tooltip"
      label="Copy link"
      type="secondary"
      fontIcon="link2"
      class="copy-link"
      [disabled]="disableCopyLink"
    ></u-button>
  </div>
</ng-template>

<ng-template #chips>
  <u-chips
    class="border-bottom"
    [class.banner-at-bottom]="showMaxPlanUsersBanner"
    [showClear]="true"
    [style]="chipsStyle"
    [validationRegex]="chipsValidationRegex"
    (itemsChanged)="onChipsChanged($event)"
    (inputValueChanged)="onChipsInputValueChanged($event)"
    [items]="emails"
    [placeholder]="'Type or paste emails, separated by comma'"
    [maxTags]="maxTags"
    [errorMessage]="errorMessage"
  ></u-chips>
  <trial-banner
    *ngIf="showMaxPlanUsersBanner"
    [bannerType]="'text-as-button'"
    [bannerText]="'Upgrade'"
    [showButton]="false"
    (onClick)="onUpgradeButtonClicked()"
  ></trial-banner>
</ng-template>
<ng-template #sendInviteBtn>
  <u-button
    class="send-invite-btn"
    [disabled]="currentEmail === null || (currentEmail === '' && !emails.length) || isInvalidTagError"
    label="Send Invite"
    type="primary"
    (onClick)="sendInvite()"
    [style]="{ height: '40px', fontSize: '16px' }"
  ></u-button>
</ng-template>
