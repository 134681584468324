import { Injectable } from '@angular/core';
import { HomePins, Permissions } from '@local/client-contracts';
import { observable } from '@local/common';
import { LogService, ServicesRpcService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';
import { Observable, ReplaySubject } from 'rxjs';
import { HomePinsRpcInvoker } from './invokers/home-pins.rpc-invoker';

@Injectable({
  providedIn: 'root',
})
export class HomePinsService {
  private logger: Logger;
  private service: HomePins.Service;
  private _all$ = new ReplaySubject<HomePins.HomePin[]>(1);
  private all: HomePins.HomePin[];

  constructor(services: ServicesRpcService, logger: LogService) {
    this.logger = logger.scope('HomePinsService');
    this.service = services.invokeWith(HomePinsRpcInvoker, 'homepins');
    this.service.all$.subscribe((all) => {
      this._all$.next(all);
      this.all = all;
    });
  }

  @observable
  get all$(): Observable<HomePins.HomePin[]> {
    return this._all$.asObservable();
  }

  create(homePin: HomePins.HomePin, privatePin?: boolean): Promise<HomePins.HomePin> {
    return this.service.create(homePin, privatePin);
  }

  createMany(items: HomePins.HomePin[], privatePin: boolean = true) {
    return this.service.createMany(items, privatePin);
  }

  delete(id: string): Promise<void> {
    return this.service.delete(id);
  }

  update(homePin: HomePins.HomePin, actions: HomePins.UpdateAction[], privatePin?: boolean): Promise<void> {
    return this.service.update(homePin, actions, privatePin);
  }

  deleteByPinedId(pinedId: string, type: HomePins.HomePinType): Promise<void> {
    return this.service.deleteByPinedId(pinedId, type);
  }

  getByPinedId(pinedId: string): HomePins.HomePin {
    return this.all?.find((h) => h.pinedId === pinedId);
  }

  getAllByPinedId(pinedId: string): HomePins.HomePin[] {
    return this.all?.filter((h) => h.pinedId === pinedId) || [];
  }

  refresh(): Promise<void> {
    return this.service.refresh();
  }
}
