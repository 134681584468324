import { SideBarOptions } from './settings-sidebar.component';

export const sideBarOptionsData: SideBarOptions[] = [
  {
    title: 'ACCOUNT',
    sections: [
      {
        title: 'General',
        icon: {
          type: 'font',
          value: 'icon-user1',
        },
        path: 'general',
        telemetry: 'account.general',
      },
      {
        title: 'Preferences',
        icon: {
          type: 'font',
          value: 'icon-preferences',
        },
        path: 'preferences',
        telemetry: 'account.preferences',
      },
      {
        title: 'App Upgrades',
        icon: {
          type: 'font',
          value: 'icon-upgrade',
        },
        path: 'app-upgrades',
        telemetry: 'account.upgrades',
      },
    ],
  },
  {
    title: 'WORKSPACE',
    sections: [
      {
        title: 'General',
        icon: {
          type: 'font',
          value: 'icon-settings-company',
        },
        path: 'workspace-general',
        telemetry: 'workspace.general',
      },
      {
        title: 'Settings',
        icon: {
          type: 'font',
          value: 'icon-cog2',
        },
        path: 'workspace-settings',
        telemetry: 'workspace.settings',
      },
      {
        title: 'Appearance',
        icon: {
          type: 'font',
          value: 'icon-palette',
        },
        path: 'workspace-appearance',
        telemetry: 'workspace.appearance',
      },
      {
        title: 'Members',
        icon: {
          type: 'font',
          value: 'icon-members',
        },
        path: 'members',
        telemetry: 'workspace.members',
      },
      {
        title: 'API Keys',
        icon: {
          type: 'font',
          value: 'icon-file-key',
        },
        path: 'api-key',
        telemetry: 'workspace.api-key',
      },
      {
        title: 'Your Plan',
        icon: {
          type: 'font',
          value: 'icon-credit-card',
        },
        path: 'plan',
        telemetry: 'workspace.your-plan',
      },
    ],
  },
];
