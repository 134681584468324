import { BannerMessage } from '@shared/services/banner.service';

export const HEADER_BANNER_MESSAGES: { [key: string]: BannerMessage } = {

  newVersion: {
    id: 'new_version',
    priority: 1,
    style: { backgroundColor: 'var(--color-primary)' },
    enableClosing: true,
    content: 'Your Unleash app has been just upgraded to <b>New Version</b>!',
    buttonText: 'What\'s New',
    icon: { type: 'font', value: 'icon-party' },
  },

  connectPc: {
    id: 'connect_pc',
    priority: 4,
    style: { backgroundColor: 'var(--green-500)' },
    enableClosing: true,
    content: 'Connect your computer to activate our local file search or do it later from the apps gallery',
    buttonText: 'Connect',
    icon: { type: 'font', value: 'icon-desktop-light' },
    locationsList: ['home'],
    clickTarget: 'connect',
  },

  trialPayment: {
    id: 'trial_payment',
    priority: 5,
    content: '',
    enableClosing: false,
    buttonText: 'Upgrade',
    persistentBanner: true,
    style: { backgroundColor: 'var(--yellow-500)', color: 'var(--gray-800)', fontSize: '14px', fontWeight: '500' },
    buttonStyle: { color: 'var(--gray-900)', borderColor: 'var(--gray-900)' },
    class: 'trial-payment',
    icon: { type: 'font', value: 'icon-stars' },
  },

  pcAvailable: {
    id: 'pc_available',
    priority: 6,
    style: { backgroundColor: 'var(--green-500)' },
    enableClosing: true,
    content: 'File search is now available!',
    locationsList: ['home'],
    icon: { type: 'font', value: 'icon-desktop-light' },
  },
};
