import { Injectable } from '@angular/core';
import { EventsService, LogService } from '@shared/services';
import { KeyboardService } from '@shared/services/keyboard.service';
import { RouterService } from '@shared/services/router.service';
import { Logger } from '@unleash-tech/js-logger';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, startWith, take, takeUntil } from 'rxjs/operators';
import { SearchPopupData, SearchPopupItem, SearchPopupItemType, SelectPayload, TelemetryTarget } from './model';
import { SearchPopupComponent } from './search-popup.component';
import { PopupRef, PopupService } from '@local/ui-infra';
import { observable } from '@local/common';
@Injectable()
export class SearchPopUpService {
  protected keyHandlerId: string;
  protected ref: PopupRef<SearchPopupComponent, SearchPopupData>;
  protected _all: SearchPopupItem<SearchPopupItemType>[] = [];
  protected _all$: BehaviorSubject<SearchPopupItem<SearchPopupItemType>[]> = new BehaviorSubject([]);
  private _select$ = new Subject<SelectPayload>();
  protected _placeholder$ = new ReplaySubject<string>(1);
  protected logger: Logger;
  readonly close$ = new Subject<{ via: TelemetryTarget }>();
  private _telemetryName: string;

  @observable
  get all$(): Observable<SearchPopupItem<SearchPopupItemType>[]> {
    return this._all$;
  }

  set all(value: SearchPopupItem<SearchPopupItemType>[]) {
    this._all = value;
    this._all$.next(value);
  }

  get all() {
    return this._all;
  }

  get telemetryName(): string {
    return this._telemetryName || 'command_bar';
  }

  set telemetryName(value: string) {
    this._telemetryName = value;
  }

  @observable
  get select$(): Observable<SelectPayload> {
    return this._select$.asObservable();
  }

  protected set select(value: SelectPayload) {
    this._select$.next(value);
  }

  get sortBy() {
    return this._sortBy;
  }

  set sortBy(value: (a: SearchPopupItem<'parent'>, b: SearchPopupItem<'parent'>) => number) {
    this._sortBy = value;
  }

  get placeholder$() {
    return this._placeholder$.asObservable();
  }

  get supportsTelemetry() {
    return !!this.telemetryName && !!this.eventsService && !!this.routerService;
  }

  set placeholder(value: string) {
    this._placeholder$.next(value);
  }

  constructor(
    protected popupService: PopupService,
    protected keyboardService: KeyboardService,
    private name: string,
    logService: LogService,
    protected eventsService?: EventsService,
    protected routerService?: RouterService,
    telemetryName?: string
  ) {
    this.logger = logService.scope(`${name}Service`);
    this.telemetryName = telemetryName;
  }

  destroy(): void {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
      this.keyHandlerId = undefined;
    }
    if (this.ref) {
      this.ref.close();
      this.ref.destroy();
    }
  }

  sendEventCommandBarOpen(via: TelemetryTarget) {
    if (this.supportsTelemetry) {
      via
        ? this.eventsService.event(`command_bar.open`, { location: { title: this.routerService.active }, target: via })
        : console.warn(`argument missing, no open trigger reported`);
    }
  }

  open(via: TelemetryTarget): void {
    if (this.ref) return;

    this.ref = this.popupService.open(
      'center',
      SearchPopupComponent,
      { items$: this.all$, placeholder$: this.placeholder$, sortBy: this.sortBy, telemetryName: this.telemetryName, name: this.name },
      { closeOnClickOut: true, hasBackdrop: true, backdropStyle: 'blur-1', fullScreenDialog: true }
    );

    combineLatest([this.ref.close$, this.ref.compInstance.select$.pipe(startWith(null))])
      .pipe(takeUntil(this.ref.destroy$))
      .subscribe(([c, s]) => {
        this.close(!s ? c?.via : null); // Report only when close wasn't invoked by select
        this.close$.next(undefined);
      });

    this.ref.compInstance.select$.pipe(takeUntil(this.ref.destroy$)).subscribe(({ item, via }) => {
      this.invoke(item, via);
    });

    this.ref.compInstance.searchSelect$.pipe(takeUntil(this.ref.destroy$)).subscribe((via) => {
      this.close(via);
    });

    this.ref.destroy$.pipe(take(1)).subscribe(() => (this.ref = null));
    this.sendEventCommandBarOpen(via);
  }

  close(via?: TelemetryTarget): void {
    if (!this.ref) {
      return;
    }
    if (this.supportsTelemetry && via) {
      this.eventsService.event(`command_bar.cancel`, { location: { title: this.routerService.active }, target: via });
    }
    this.ref.destroy();
  }

  @observable
  add(item: SearchPopupItem<'parent'>): Observable<SelectPayload> {
    if (this._all.some((i) => i.id === item.id)) {
      console.info(`item ${item.id} already exist, removing old items`);
      this.remove(item.id);
    }
    item.children.forEach((c) => (c.parentId = item.id));
    this.all = [...this._all, item];
    return this.select$FilteredByParent(item);
  }

  remove(id: string) {
    this.all = this.all.filter((i) => i.id !== id);
  }

  invoke(item: SearchPopupItem<'child'>, via?: TelemetryTarget) {
    this.ref.destroy();
    this.close(null);
    this.select = { item, via };
  }

  protected _sortBy(a: SearchPopupItem<'parent'>, b: SearchPopupItem<'parent'>): number {
    return 0;
  }

  /** @returns a filtered observable that will emit only when items from the supplied parent will get selected. including the parent */
  private select$FilteredByParent(item: SearchPopupItem<'parent'>) {
    return this.select$.pipe(filter(({ item: { parentId } }) => item.id === parentId));
  }
}
