<div class="constant-filter" [ngClass]="{ disabled: model.disabled }">
  <u-icon *ngIf="model?.icon" class="constant-icon" [model]="model.icon" [styles]="{ fontSize: '14px' }"></u-icon>
  <span *ngIf="keepPlaceholder">{{ model.title }}{{ valuesToDisplay?.length || model.constantLabel ? ':' : '' }} </span>
  <div *ngFor="let value of valuesToDisplay; let last = last" class="constant-value">
    <u-icon
      *ngIf="model?.viewDetails?.showItemIcon && value.icon"
      class="value-icon"
      [model]="value.icon"
      [styles]="{ fontSize: '14px', width: '14px' }"
    ></u-icon>
    <span>{{ value.title }}</span>
    <div *ngIf="!last" class="coma">,</div>
  </div>
  <div *ngIf="moreItemsCount" class="extra-items" tooltipPosition="top" [pTooltip]="moreItemsToolTip" tooltipStyleClass="u-tooltip">
    {{ ' +' + moreItemsCount }}
  </div>
  <div class="const-label" *ngIf="model.constantLabel">
    {{ model.constantLabel }}
  </div>
</div>
