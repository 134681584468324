import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { UiIconModel } from '../../types/ui.icon.model';

@Component({
  selector: 'u-icon-blink',
  templateUrl: './u-icon-blink.component.html',
  styleUrls: ['./u-icon-blink.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIconBlinkComponent {
  @Input() model: UiIconModel;
  @Input() backgroundColor = 'var(--color-primary)';
  @Input() icon1FontSize = '18px';
  @Input() icon2FontSize = '10px';
}
