import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { getNodeIdFromRoute } from '../nav-tree-node-id.resolver';
import { CHAT_PAGE_PATH } from '../../utils/constants';

@Injectable()
export class CanActivateChatGuard {
  constructor(private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const currentUrl = this.router.url;
    const nodeId: string = getNodeIdFromRoute(route);
    if (nodeId === CHAT_PAGE_PATH && currentUrl.startsWith(`/${CHAT_PAGE_PATH}/`) && state.url === `/${CHAT_PAGE_PATH}`) {
      return false;
    }
    return true;
  }
}
