import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { findChildren, getIntersection } from '@shared/utils/elements-util';
import { ReplaySubject, Subject, take } from 'rxjs';

export interface Step {
  label: string;
  completed: boolean;
}

@UntilDestroy()
@Component({
  selector: 'stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent implements OnDestroy {
  static MIN_STEP_WIDTH = 64;
  private _selectedIndex: number;

  @Input() steps: Step[];
  @Input() selectStepOnClick = false;
  @Input() set selectedIndex(v: number) {
    const lengthSteps = this.steps?.length;
    if (lengthSteps && lengthSteps <= v) return;
    if (!lengthSteps) {
      v = 0;
    }
    this._selectedIndex = v;
    this.setStepByIndex(v);
  }

  get selectedIndex() {
    return this._selectedIndex;
  }

  @ViewChildren('label') labelsRef: QueryList<ElementRef>;
  @ViewChild('progress') progressRef: ElementRef;
  @ViewChild('progressContainer') progressContainerRef: ElementRef;

  private readonly destroy$ = new Subject();
  private readonly viewInit$ = new ReplaySubject(1);

  @HostBinding('style.minWidth') get minWidth() {
    return this.steps.length * StepperComponent.MIN_STEP_WIDTH + 'px';
  }

  constructor(private cdr: ChangeDetectorRef, private el: ElementRef<HTMLElement>) {}

  ngAfterViewInit() {
    this.labelsRef.changes.pipe(untilDestroyed(this)).subscribe(() => {
      this.setHostMargin();
    });

    this.viewInit$.next(undefined);
    this.labelsRef.notifyOnChanges();

    this.observeLabelsDisplay();
  }

  ngOnDestroy() {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  onStepClicked(index: number) {
    if (!this.selectStepOnClick) {
      return;
    }
    this.selectedIndex = index;
  }

  /** recalculate the margin of the component on resize. This is determined by the component size,
   * on some breakpoints we hide the labels that cross the 'box boundary' so we need to recalculate the box actual size
   */
  private observeLabelsDisplay() {
    const checkIfFirstOrLastLabelsVisible = () =>
      [this.labelsRef.first, this.labelsRef.last].every((o) => {
        if (!o) {
          return;
        }
        const el = o.nativeElement;
        return getComputedStyle(<HTMLElement>el).display !== 'none';
      });

    let shouldSpace: boolean;
    const obs = new ResizeObserver(() => {
      const s = checkIfFirstOrLastLabelsVisible();
      if (s !== shouldSpace) {
        // Optimization to save function calls
        this.setHostMargin();
      }
      shouldSpace = s;
    });
    obs.observe(this.el.nativeElement);

    this.destroy$.pipe(untilDestroyed(this)).subscribe(() => obs.disconnect());
  }

  /** Since the text of the first and last step is overflowing the container they are not considered in the layout
   * This padding adds the 'needed space for the element to count them in
   */
  private setHostMargin() {
    if (!this.progressContainerRef) return;
    const { nativeElement: el } = this.progressContainerRef;
    const stepsEl = findChildren(el, '.step');
    if (!stepsEl?.length) return;

    const last = stepsEl[stepsEl.length - 1];
    const first = stepsEl[0];

    const shouldSpaceLeft = first.childElementCount > 0 && getComputedStyle(<HTMLElement>first.firstChild).display !== 'none';
    const shouldSpaceRight = first.childElementCount > 0 && getComputedStyle(<HTMLElement>first.firstChild).display !== 'none';

    const leftIntersection = shouldSpaceLeft ? getIntersection(<HTMLElement>first.firstChild, el).left : 0;
    const rightIntersection = shouldSpaceRight ? getIntersection(<HTMLElement>last.firstChild, el).right : 0;

    const { marginTop, marginBottom } = getComputedStyle(el);
    el.style.margin = `${marginTop} ${rightIntersection}px ${marginBottom}  ${leftIntersection}px`;
  }

  private async setStepByIndex(index: number) {
    const updateProgressBar = (index: number) => {
      if (index === null) {
        return;
      }

      const next = (index / (this.steps.length - 1)) * 100 + '%';
      const current = this.progressRef.nativeElement.style.maxWidth;
      if (next === current) {
        // Prevent change detection in the stepper and all descendent childs
        return;
      }
      this.progressRef.nativeElement.style.maxWidth = next;
      this.steps.forEach((step, i) => (i <= index ? (step.completed = true) : (step.completed = false)));
      this.cdr.detectChanges();
    };
    this.viewInit$.pipe(take(1), untilDestroyed(this)).subscribe(() => {
      updateProgressBar(index);
    });
  }
}
