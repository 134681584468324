<div class="tag-group-popup-background">
  <u-tree
    [value]="tagsNode"
    [filter]="true"
    [styles]="{ width: '390px' }"
    [emptyMessage]="'No results found.'"
    [filterPlaceholder]="'Search...'"
    [disabledButton]="true"
    [nodeTemplate]="nodeTemplate"
    virtualNodeHeight="32"
    scrollHeight="308px"
    (onSearch)="onSearch($event)"
  >
  </u-tree>
  <div class="footer" *ngIf="tooltip">
    {{ tooltip }}
  </div>
</div>

<ng-template #nodeTemplate let-node>
  <link-tag [tag]="node?.data?.tag" [markedText]="markedText"></link-tag>
</ng-template>
