import { Injectable } from '@angular/core';
import { Wiki } from '@local/client-contracts';
import { keyBy } from 'lodash';
import { BlobsService } from 'src/app/bar/services/blob.service';

export type AttachmentsStorage = { [blobId: string]: Wiki.CardAttachment };

@Injectable()
export class WikiAttachmentStorageHandler {
  private storageByCard: { [cardId: string]: AttachmentsStorage } = {};

  constructor(private blobsService: BlobsService) {}

  initCardAttachments(cardId: string, attachments: Wiki.CardAttachment[]) {
    const cardStorage = keyBy(attachments || [], 'blobId');
    this.storageByCard[cardId] = cardStorage;
  }

  addAttachment(cardId: string, attachment: Wiki.CardAttachment) {
    if (!attachment.blobId) {
      return;
    }
    if (!this.storageByCard[cardId]) {
      this.storageByCard[cardId] = {};
    }
    this.storageByCard[cardId][attachment.blobId] = attachment;
  }

  getAttachment(cardId: string, blobId: string) {
    return this.storageByCard[cardId]?.[blobId];
  }

  updateAttachmentName(cardId: string, blobId: string, newName: string) {
    if (!this.storageByCard[cardId]?.[blobId]) {
      return;
    }
    this.storageByCard[cardId][blobId].name = newName;
  }

  getAttachments(cardId: string, blobIds: string[]) {
    if (!blobIds?.length) {
      return [];
    }
    return blobIds.map((blobId) => this.getAttachment(cardId, blobId)).filter((a) => !!a);
  }

  resetCardAttachments(cardId: string, currentBlobIds: string[]) {
    this.deleteUnRelevantAttachments(cardId, currentBlobIds);
    delete this.storageByCard[cardId];
  }

  private deleteUnRelevantAttachments(cardId: string, currentBlobIds: string[]) {
    const currentBlobIdsSet = new Set(currentBlobIds || []);
    const unRelevantIds = Object.keys(this.storageByCard[cardId] || {}).filter((id) => !currentBlobIdsSet.has(id));
    if (unRelevantIds?.length) {
      this.blobsService.delete(unRelevantIds);
    }
  }
}
