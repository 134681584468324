import { Injectable } from '@angular/core';
import { Commands, LocalActions } from '@local/client-contracts';
import { LocalActionsRpcInvoker } from '@shared/services/invokers/local-actions.rpc-invoker';
import { Logger } from '@unleash-tech/js-logger';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { LogService } from './log.service';
import { ServicesRpcService } from './rpc.service';

@Injectable({
  providedIn: 'root',
})
export class LocalActionsService implements LocalActions.Service {
  private service: LocalActions.Service;
  private logger: Logger;
  constructor(logService: LogService, private services: ServicesRpcService) {
    this.logger = logService.scope('LocalActionsService');
    this.service = this.services.invokeWith(LocalActionsRpcInvoker, 'localactions');
  }

  get all$(): Observable<LocalActions.LocalActionItem[]> {
    return this.service.all$;
  }

  all(): Promise<LocalActions.LocalActionItem[]> {
    return this.all$.pipe(take(1)).toPromise();
  }

  getActionUrl(actionId: string): Promise<Commands.OpenUrlParams> {
    return this.service.getActionUrl(actionId);
  }
}
