import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PopupRef } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { collectionContent } from '../../helpers/collection.content';
import { addAssistantsContent } from '../../../assistant-page/helpers/assistant.content';
import { ExperiencesService } from 'src/app/bar/services/experiences.service';
import { FlagsService } from '@shared/services/flags.service';

export type MultiChoicePopupType = 'collections' | 'wikis' | 'wiki' | 'assistants';

export type OptionValue = { text: string; description: string; icon: any; value: string; disabled?: boolean; warningText?: string };

export interface MultiChoicePopupData {
  type: MultiChoicePopupType;
}

@UntilDestroy()
@Component({
  selector: 'multi-choice-popup',
  templateUrl: './multi-choice-popup.component.html',
  styleUrls: ['./multi-choice-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiChoicePopupComponent implements OnInit {
  @Output() onClickEvent = new EventEmitter<any>();
  options: OptionValue[];
  selectedIndex: number = 0;

  constructor(
    private ref: PopupRef<MultiChoicePopupComponent, MultiChoicePopupData>,
    private cdr: ChangeDetectorRef,
    private assistantService: ExperiencesService,
    private flagsService: FlagsService
  ) {}

  ngOnInit(): void {
    switch (this.ref.data.type) {
      case 'collections':
        this.buildCollectionsOptions();
        break;
      case 'wiki':
      case 'wikis':
        this.buildWikisOptions();
        break;
      case 'assistants':
        this.options = addAssistantsContent;
        this.initSlackBotInstallInfo();
        break;
    }
  }

  private async initSlackBotInstallInfo() {
    this.assistantService.disableCreateSlackAssistant$.pipe(untilDestroyed(this)).subscribe((val) => {
      if (!val) {
        return;
      }
      this.options.forEach((option) => {
        if (option.warningText) {
          option['disabled'] = true;
        }
      });
    });
  }

  private buildCollectionsOptions() {
    this.options = [
      {
        text: 'Curated Collection',
        description: collectionContent.newCuratedCollectionPopupDescription,
        icon: { type: 'font', value: 'icon-curated' },
        value: 'Static',
      },
      {
        text: 'Live Collection',
        description: collectionContent.newLiveCollectionPopupDescription,
        icon: { type: 'font', value: 'icon-live-collection' },
        value: 'Live',
      },
    ];
  }

  private buildWikisOptions() {
    this.options = [
      {
        text: 'New Card',
        description: collectionContent.newCardPopupDescription,
        icon: { type: 'font', value: 'icon-card' },
        value: 'Card',
      },
    ];
    if (this.ref.data.type === 'wikis') {
      this.options.unshift({
        text: 'New Wiki',
        description: collectionContent.newWikiCollectionPopupDescription,
        icon: { type: 'font', value: 'icon-wiki' },
        value: 'Wiki',
      });
    } else {
      this.options.push({
        text: 'New Folder',
        description: collectionContent.newFolderPopupDescription,
        icon: { type: 'font', value: 'icon-folder' },
        value: 'Folder',
      });
    }
  }

  onClickOption(option) {
    if (option.disabled) {
      return;
    }
    this.onClickEvent.emit(option.value);
    this.ref.destroy();
  }

  onHoverItem(index: number) {
    this.selectedIndex = index;
    this.cdr.markForCheck();
  }
}
