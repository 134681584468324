import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ConstantFilter, DisplaySearchFilterValue } from '../models';

@Component({
  selector: 'constant-filter',
  templateUrl: './constant-filter.component.html',
  styleUrls: ['./constant-filter.component.scss'],
})
export class ConstantFilterComponent {
  private _model: ConstantFilter;
  private selectedItems: DisplaySearchFilterValue[];
  valuesToDisplay: DisplaySearchFilterValue[];
  moreItemsToolTip: string;
  moreItemsCount: number;

  @Input() set model(value: ConstantFilter) {
    this._model = value;
    this.selectedItems = (this._model.values || []).filter((v) => v.selected);
    this.valuesToDisplay = this.selectedItems.slice(0, this.limitShowSelected);
    if (this.selectedItems.length > this.limitShowSelected) {
      this.moreItemsCount = this.selectedItems.length - this.limitShowSelected;
      this.moreItemsToolTip = this.getMoreLabelsTooltip();
    }
    this.cdr.markForCheck();
  }
  @Input() limitShowSelected = 2;
  @Input() keepPlaceholder: boolean;

  get model(): ConstantFilter {
    return this._model;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  private getMoreLabelsTooltip(): string {
    const items = this.selectedItems.slice(this.limitShowSelected).map((elm) => elm.value);
    return items.join(', ');
  }
}
