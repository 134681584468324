import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UColorPickerComponent } from './u-color-picker.component';
import { ColorChromeModule } from 'ngx-color/chrome';
import { UButtonModule } from '../u-button/u-button.module';

@NgModule({
  declarations: [UColorPickerComponent],
  imports: [CommonModule, ColorChromeModule, UButtonModule],
  exports: [UColorPickerComponent],
})
export class UColorPickerModule {}
