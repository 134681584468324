<div class="people-preview-popup-background" (click)="closePopUp()">
  <div
    class="people-preview-popup-container"
    [ngClass]="{
      'people-preview-popup-c-loading': !current,
      isLauncher: model?.isLauncher,
      'window-mode': windowMode && !embedInline,
      'embed-inline-mode': embedInline
    }"
    (click)="(false); $event.stopPropagation()"
  >
    <div *ngIf="!current && !(error$ | async); else content" class="people-preview-popup-container-loading">
      <loader-overlay class="people-preview-popup-loading" [displayText]="true"> </loader-overlay>
    </div>
    <ng-template #content>
      <preview-error *ngIf="error$ | async; else people_content" (again)="tryAgain()"></preview-error>
      <ng-template #people_content>
        <div class="people-preview-popup-close-popup">
          <u-icon
            *ngIf="!(isEmbed && embedInline)"
            data-cy="people-preview-popup-close-btn"
            class="people-preview-popup-close-btn"
            (click)="closePopUp()"
            [model]="closeIcon"
            [styles]="{ fontSize: '13.9px' }"
          ></u-icon>
        </div>
        <people-preview
          (closePopup)="closePopUp()"
          [size]="'popup'"
          [person]="current"
          [personIndex]="model?.index"
          (loaded)="loaded.emit($event)"
          [windowMode]="windowMode"
          [previewType]="previewType"
        ></people-preview>
      </ng-template>
    </ng-template>
  </div>
</div>
