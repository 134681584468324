import { Injectable } from '@angular/core';
import { Commands, Expert, People, Search, Style } from '@local/client-contracts';
import { PopupRef, PopupService } from '@local/ui-infra';
import { ServicesRpcService } from '@shared/services';
import { distinctUntilChanged, filter, firstValueFrom, Observable, ReplaySubject, Subject, take } from 'rxjs';
import { HubService } from 'src/app/bar/services/hub.service';
import { PeopleRpcInvoker } from 'src/app/bar/services/invokers/people-rpc-invoker';
import { PreviewService, ResultPopupData } from 'src/app/bar/services/preview.service';
import { InvokeCommand } from '../../../results/models/invoke-command.model';
import { PreviewType, RESULT_ACTION_SETTING } from '../../../results/models/view-filters';
import { PeoplePreviewPopupComponent } from '../components/people-preview-popup/people-preview-popup.component';
import { ContactLinkViewModel } from '../models/contact-link-view.model';
import { CommandsService } from 'src/app/bar/services/commands/commands.service';
import { ApplicationsService } from '@shared/services/applications.service';
import { LinksService } from '@shared/services/links.service';
import { CommandContext, DisplayItemData } from '../../../results';

export type PopUpSource = 'Results' | 'Preview';
const POPUP_SIZE = 536;
const POPUP_LAUNCHER_SIZE = 460;

export type PeopleCommandModel = { command: InvokeCommand; model: Search.ResultResourceItem };

@Injectable({
  providedIn: 'root',
})
export class PeopleService {
  private readonly previewTypes = ['people', 'relevant-people'];
  private _popupRef: PopupRef<PeoplePreviewPopupComponent, ResultPopupData>;
  private _popupSize: number = POPUP_SIZE;

  get all(): People.PersonContext[] {
    return this._all;
  }

  get popupSize(): number {
    return this._popupSize;
  }

  set popupSize(value: number) {
    this._popupSize = value;
  }

  get popupRef(): PopupRef<PeoplePreviewPopupComponent, ResultPopupData> {
    return this._popupRef;
  }

  set popupRef(value: PopupRef<PeoplePreviewPopupComponent, ResultPopupData>) {
    this._popupRef = value;
  }

  private peopleLinkId: string;
  private service: People.Service;
  private _all: People.PersonContext[] = [];
  private _all$ = new ReplaySubject<People.PersonContext[]>(1);
  private _details$ = new ReplaySubject<People.PersonDetails[]>(1);

  constructor(
    private popupService: PopupService,
    services: ServicesRpcService,
    private previewService: PreviewService,
    private hubService: HubService,
    private commandsService: CommandsService,
    private applicationService: ApplicationsService,
    private linksService: LinksService
  ) {
    this.service = services.invokeWith(PeopleRpcInvoker, 'people');
    this.service.people$.subscribe((all) => {
      this._all$.next(all);
      this._all = all || [];
    });
    this.service.details$.subscribe((all) => this._details$.next(all));
    this.linksService.peopleLinkId$.subscribe((peopleLinkId) => {
      const newPeopleLinkId = peopleLinkId;
      if (this.peopleLinkId !== newPeopleLinkId) {
        this.peopleLinkId = newPeopleLinkId;
        RESULT_ACTION_SETTING.people.condition.links = this.peopleLinkId ? [this.peopleLinkId] : [];
      }
    });

    this.previewService.openPreviewPopup$
      .pipe(
        distinctUntilChanged(),
        filter((i) => this.previewTypes.includes(i.previewType))
      )
      .subscribe(({ data }) => {
        const popupData = data as ResultPopupData;
        this.openPersonPopUp(popupData);
      });

    this.previewService.clearPreviewPopup$.pipe(filter((previewType) => this.previewTypes.includes(previewType))).subscribe(() => {
      if (this.popupRef) {
        this.popupRef.close();
      }
    });
  }

  get isPeoplePopupOpen() {
    return this.popupRef;
  }

  get currentLocation() {
    return this.hubService.currentLocation;
  }

  openPersonPopUp(data: ResultPopupData): PopupRef<PeoplePreviewPopupComponent, ResultPopupData> {
    this.popupRef = this.popupService.open('center', PeoplePreviewPopupComponent, data, {
      closeOnClickOut: true,
      position: 'center',
      backdropStyle: 'blur-2',
    });

    this.popupSize = data.isLauncher ? POPUP_LAUNCHER_SIZE : POPUP_SIZE;
    this.popupRef.close$.pipe(take(1)).subscribe(async () => {
      this.popupRef.destroy();
      this.popupRef = null;
      const previewType = (data?.item as DisplayItemData)?.action?.type as PreviewType;
      this.previewService.onDestroyPreview(data?.item as Search.ResultResourceItem, previewType, data?.preventClearQueryParams);
      this.previewService.setPreviewState('previous', data.index, data.item);
    });
    return this.popupRef;
  }

  get all$(): Observable<People.PersonContext[]> {
    return this._all$;
  }

  get details$(): Observable<People.PersonContext[]> {
    return this._details$;
  }

  async getPeople(externalIds?: string[], emails?: string[]) {
    return await this.service.getPeople(externalIds, emails);
  }

  async getPerson(externalId?: string, email?: string) {
    return await this.service.getPerson(externalId, email);
  }

  async getExperts(searchRequest: Expert.GetExpertsRequest) {
    return await this.service.getExperts(searchRequest);
  }

  //logic
  sendEmail(details: People.PersonDetails): void {
    const mailText = 'mailto:' + details?.email;
    window.open(mailText);
  }

  copyEmail(details: People.PersonDetails): void {
    this.commandsService.executeCommand({
      type: 'copy-clipboard',
      value: `${details.email}`,
    } as Commands.CopyClipboard);
  }

  search(request: People.SearchRequest): Promise<People.SearchResponse> {
    return this.service.search(request);
  }

  nextPage(pageToken: string): Promise<People.SearchResponse> {
    return this.service.nextPage(pageToken);
  }

  async directMessage(contact: ContactLinkViewModel): Promise<void> {
    if (contact?.url) {
      const res = await this.commandsService.executeCommand(contact.url, contact.context);
      if (!res.data.opened && res.data.askUserInput) {
        this.commandsService.executeCommand(contact.url, { ...contact.context, openWith: 'browser' });
      }
    }
  }

  async buildContactLinks(details: People.PersonDetails, searchId: string): Promise<Record<string, ContactLinkViewModel>> {
    const contactLinks: Record<string, ContactLinkViewModel> = {};
    for (const c of details?.contactLinks || []) {
      const linksPromise = firstValueFrom(this.linksService.all$);
      const app = await this.applicationService.one(c.type);
      const link = await linksPromise.then((links) => links?.find((l) => l.appId === c.type));
      switch (c.type) {
        case 'office365':
          contactLinks['Teams'] = this.generateContactLinkIcon(
            'Teams',
            {
              lightUrl: this.generateTeamIconUrl(app, 'lightUrl'),
              darkUrl: this.generateTeamIconUrl(app, 'darkUrl'),
            },
            c.url,
            { searchId: searchId, linkId: link?.id }
          );
          break;
        default:
          contactLinks[c.type] = this.generateContactLinkIcon(c.type, app.icon, c.url, { searchId: searchId, linkId: link?.id });
          break;
      }
    }
    return contactLinks;
  }

  private generateTeamIconUrl(app, state: 'lightUrl' | 'darkUrl') {
    return '/apps/office365'.concat(app.services.find((s) => s.name === 'Teams').icon[state]);
  }

  private generateContactLinkIcon(type: string, value: Style.Icon, url: Commands.Command, context: CommandContext): ContactLinkViewModel {
    return {
      icon: { type: 'img', value },
      type,
      url,
      context,
    };
  }
}
