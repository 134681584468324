import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { USidebarComponent } from './u-sidebar.component';
import { SidebarModule } from 'primeng/sidebar';
import { UButtonModule } from '../u-button/u-button.module';

@NgModule({
  declarations: [USidebarComponent],
  imports: [CommonModule, FormsModule, SidebarModule, UButtonModule],
  exports: [USidebarComponent],
})
export class USidebarModule {}
