import {
  AnswersSourceSettings,
  CollectionsSourceSettings,
  GoLinksSourceSettings,
  LinkResourcesSourceSettings,
  LocalActionSourceSettings,
  MailSourceSettings,
  RecentSearchesSourceSettings,
  SourceSettings,
} from '.';
import { EmbedItemsSourceSettings } from './embed-items';
import { FavoritesSourceSettings } from './favorites/favorites-source-settings';
import { PeopleSourceSettings } from './people/people-source-settings';
import { RelevantPeopleSourceSettings } from './relevant-people/relevant-people-source-settings';
import { ResourcesFavoritesSourceSettings } from './resources-favorites/resources-favorites-source-settings';
import { StaticCollectionItemSourceSettings } from './static-collection-items';
import { VisitsSourceSettings } from './visits';
import { WikiCollectionItemSourceSettings } from './wiki-collection-items';

export const isLinkResourcesSettings = (x: SourceSettings): x is LinkResourcesSourceSettings => x.type === 'link-resources';
export const isMailResourcesSettings = (x: SourceSettings): x is MailSourceSettings => x.type === 'mail-resources';
export const isGroupResourcesSettings = (x: SourceSettings): x is MailSourceSettings => x.type === 'group-resources';
export const isLocalActionSettings = (x: SourceSettings): x is LocalActionSourceSettings => x.type === 'local-action';
export const isRecentSearchesSettings = (x: SourceSettings): x is RecentSearchesSourceSettings => x.type === 'recent-search';
export const isEmbedItemsSettings = (x: SourceSettings): x is EmbedItemsSourceSettings => x.type === 'embed-items';
export const isGoLinksSettings = (x: SourceSettings): x is GoLinksSourceSettings => x.type === 'go-links';
export const isVisitsSettings = (x: SourceSettings): x is VisitsSourceSettings => x.type === 'visits';
export const isResourcesFavoritesSettings = (x: SourceSettings): x is ResourcesFavoritesSourceSettings => x.type === 'resources-favorites';
export const isFavoritesSettings = (x: SourceSettings): x is FavoritesSourceSettings => x.type === 'favorites';
export const isStaticCollectionSettings = (x: SourceSettings): x is StaticCollectionItemSourceSettings =>
  x.type === 'static-collection-items';
export const isWikiCollectionSettings = (x: SourceSettings): x is WikiCollectionItemSourceSettings => x.type === 'wiki-collection-items';
export const isCollectionsSettings = (x: SourceSettings): x is CollectionsSourceSettings => x.type === 'collection';
export const isRelevantPeopleSettings = (x: SourceSettings): x is RelevantPeopleSourceSettings => x.type === 'relevant-people';
export const isPeopleSettings = (x: SourceSettings): x is PeopleSourceSettings => x.type === 'people';
export const isAnswersSettings = (x: SourceSettings): x is AnswersSourceSettings => x.type === 'answers';
