<overlay class="oauth">
  <ng-container overlay-content><span class="title">Select who can access</span></ng-container>
  <ng-container overlay-note>
    <div style="display: flex; flex-direction: column">
      <share-options
        (optionChecked)="optionChecked.emit($event)"
        (focusButton)="onFocusedButton($event)"
        (cancelButton)="cancelButton.emit()"
        (updateButton)="onShareDoneClickEmit()"
        [componentOptions]="{
          isPopUp: false,
          hasBorder: true,
          allowPopupHeader: false,
          showPrivateLink: false,
          showSubTitle: false,
          showDeleteLinkMsg: false,
          showButtons: false,
          showRestricted: resourceAccessType !== 'Permissions',
          checkedOptionIndex: 1
        }"
        [user]="user"
        [appId]="appId"
      >
      </share-options>
      <div *ngIf="true" cloptionCheckedass="message">
        {{
          sharedLinkOption?.level === 'Private'
            ? 'A private link cannot be shared once created'
            : 'You will be able to change later who has access to this link'
        }}
      </div>
    </div>
  </ng-container>

  <ng-container overlay-actions>
    <u-button
      label="Cancel"
      type="secondary"
      [styles]="{ width: '196px' }"
      (click)="onActionEvent.emit('cancel'); cancelButton.emit()"
      [ngClass]="{ focus: focusedButton === 0 }"
    >
    </u-button>
    <u-button
      class="create-button"
      [label]="'Done'"
      type="primary"
      (onClick)="onShareDoneClickEmit()"
      [disabled]="doneButtonDisabled"
      [styles]="{ width: '196px' }"
      [ngClass]="{ focus: focusedButton === 1 }"
    >
    </u-button>
  </ng-container>
</overlay>
