import { MemorySearch, Search, Wiki } from '@local/client-contracts';
import { LogService } from '@shared/services';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { firstValueFrom, Observable, ReplaySubject, Subscription } from 'rxjs';
import { SearchResults } from 'src/app/bar/views';
import { MemorySearchClient } from '../memory-search-client/memory-search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { WikiDraftsSourceSettings } from './wiki-drafts-source-settings';
import { WikiDraftsService } from '../../../wikis/wiki-drafts.service';
import { WikiCardBuilderService } from '../../../wikis/wiki-cards-builder.service';
import { Action, RESULT_ACTION_SETTING } from 'src/app/bar/views/results/models/view-filters';

export class WikiDraftsSearchClient extends MemorySearchClient<WikiDraftsSourceSettings> {
  private instances: { [sessionName: string]: { sub?: Subscription; refreshRunning?: boolean } } = {};
  private searchFinished = false;

  constructor(
    logService: LogService,
    memorySearchService: MemorySearchService,
    private wikiDraftService: WikiDraftsService,
    private cardBuilderService: WikiCardBuilderService
  ) {
    super(logService, memorySearchService, ['Alphabetical', 'Time']);
    this.logger = logService.scope('drafts');
  }

  getInput(request: SearchRequest<WikiDraftsSourceSettings>, response: SearchResponse): Observable<MemorySearch.Item[]> {
    const input$ = new ReplaySubject<MemorySearch.Item[]>(1);
    try {
      this.loadDrafts(request, response, input$);
    } catch (e) {
      input$.error(e);
    }
    return input$;
  }

  async loadDrafts(request: SearchRequest<WikiDraftsSourceSettings>, response: SearchResponse, input$: ReplaySubject<MemorySearch.Item[]>) {
    this.searchFinished = false;
    const sessionName = request.sessionName;

    this.handleChanges(request, response);

    await this.getDrafts(request.sourceSettings, input$, response);

    this.searchFinished = true;
    if (this.instances[sessionName]) {
      this.instances[sessionName].refreshRunning = false;
    }
    input$.complete();
  }

  private async getDrafts(settings: WikiDraftsSourceSettings, input$: ReplaySubject<MemorySearch.Item[]>, response: SearchResponse) {
    if (response.cancelled) {
      return;
    }

    const drafts = await firstValueFrom(this.wikiDraftService.all$);

    if (response.cancelled) {
      return;
    }

    if (!drafts?.length) {
      input$.next([]);
      return;
    }

    const draftItems = drafts
      .filter((d) => d)
      ?.sort((a, b) => b.modifiedTime - a.modifiedTime)
      ?.map((x) => ({ data: { ...x, type: 'wiki-draft' }, searchText: this.getText(x), sortValue: this.getSortText(settings.sorting, x) }));

    input$.next(draftItems || []);

    return draftItems;
  }

  private getSortText(sorting: Search.Sort, item: Wiki.Draft): string | number {
    let sortValue: number | string;
    switch (sorting?.by) {
      case 'Alphabetical':
        sortValue = item?.title;
        break;
      case 'Timestamp':
        sortValue = item?.modifiedTime;
        break;
    }
    return sortValue;
  }

  private getText(item: Wiki.Draft): string {
    const fileAttachments = item?.attachments?.filter((a) => a.type === 'File' || !a.type);
    const attachmentsName = fileAttachments?.map((at) => at.name).join(' ') || '';
    return `${item.title} ${attachmentsName}`.trim();
  }

  private handleChanges(request: SearchRequest<WikiDraftsSourceSettings>, response: SearchResponse) {
    const sessionName = request.sessionName;
    this.initSession(request.id, sessionName);

    const onInstanceSub = (res) => {
      if (this.searchFinished && res) {
        const instance = this.instances[sessionName];
        if (instance && !instance.refreshRunning && !response.cancelled) {
          this.instances[sessionName].refreshRunning = true;
          response.extra = res;
          this.refresh(request, response);
        }
      }
    };

    const updates: Observable<any>[] = [this.wikiDraftService.all$];

    for (const update of updates) {
      this.instances[sessionName].sub.add(
        update.subscribe((res) => {
          onInstanceSub(res);
        })
      );
    }
  }

  private initSession(id: number, sessionName: string): void {
    const subscription = this.instances[sessionName]?.sub;
    if (subscription) {
      this.destroy(id, sessionName);
    }
    this.instances[sessionName] = {};
    this.instances[sessionName].sub = new Subscription();
  }

  destroy(id: number, sessionName: string): void {
    this.instances[sessionName]?.sub?.unsubscribe();
    this.instances[sessionName] = null;
  }

  async getOutput(items: MemorySearch.Item[]): Promise<SearchResults[]> {
    const tasks = [];
    const action: Action = {
      type: 'wiki card',
      click: { ...RESULT_ACTION_SETTING['wiki-card']?.action.click },
    };

    for (const item of items) {
      tasks.push(this.cardBuilderService.buildDraftResultView(item.data));
    }

    const cards = await Promise.all(tasks);

    return cards?.filter((c) => !!c).map((ca) => ({ ...ca, action }));
  }
}
