import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { KeyName, getModifiers, isCtrlOrCommandCombination, isSemicolonKey } from '@local/ts-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { keyIconMap } from '@shared/pipes';
import { EventsService, LogService } from '@shared/services';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { Logger } from '@unleash-tech/js-logger';
import { cloneDeep, isEqual, uniqBy } from 'lodash';
import { HubService } from 'src/app/bar/services/hub.service';
import { ResultsService } from 'src/app/bar/services/results.service';
import { FilterType, FiltersList } from '../filters-list';
import {
  DisplaySearchFilterValue,
  Filter,
  FilterChangeData,
  FilterChangeDataAction,
  FilterChangeDataItem,
  GroupDisplaySearchFilterValue,
  SearchFilterValue,
  isGroupFilterValue,
  isSpecialFilterValue,
} from '../models';
import { OpenFilterData, ValuesUpdatedEvent } from '../multi-select-filter-base.component';
import { ResultFiltersFocusService } from '../result-filters-focus.service';
import { isTimeFilter } from 'src/app/bar/utils/filters-utils';

type FilterView = Filter & { displayIndex: number };
@UntilDestroy()
@Component({
  selector: 'results-filters-box',
  templateUrl: './results-filters-box.component.html',
  styleUrls: ['./results-filters-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsFiltersBoxComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('filterElement') filterRefs: QueryList<FilterType>;

  showClearAll: boolean;
  lastKeys: KeyName[];
  lastEvent: CustomKeyboardEvent;
  tooltipClearFilters = `Clear filters<div class="clear-filters-tooltip-shortcut">${keyIconMap.commandorcontrol.value}</div><div class="clear-filters-tooltip-shortcut">;</div>`;

  private list: FiltersList;
  private keyHandlerId: string;
  private _filters: FilterView[] = [];
  private pannelToggledKeyboard: boolean;
  private selectedGroupFilter: FilterView;
  private _selectedFilters: DisplaySearchFilterValue[];
  private selectedGroupFilterIndex = -1;
  private filterVersions: number[] = [];
  private hasSelectedGroupFilter: boolean;
  private _indexOpen: number;
  private logger: Logger;
  private valuesUpdatedSet = new Set<string>();
  private componentFocused: boolean;

  @Input() separateLayout: boolean;
  @Input() index: number;
  @Input() hideEmptyFilters = true;
  @Input() fullDetailIds: string[];
  @Input() keepPlaceholder: boolean = true;
  @Input() showFilterTooltip: boolean;

  private _enableClearAll: boolean;
  @Input()
  set enableClearAll(value: boolean) {
    this._enableClearAll = value;
    this.computeClearAll();
  }
  get enableClearAll(): boolean {
    return this._enableClearAll;
  }

  @Output() filterChange = new EventEmitter<FilterChangeData>();
  @Output() openWithKeyboard = new EventEmitter<void>();
  @Output() clearAll = new EventEmitter<void>();
  @Output() closeFilters = new EventEmitter<void>();
  @Output() onValuesUpdated = new EventEmitter<ValuesUpdatedEvent>();
  @Output() indexOpen$ = new EventEmitter<number>();
  @Output() onClick = new EventEmitter<number>();

  @Input()
  set filters(val: Filter[]) {
    const mapped: FilterView[] = val?.map((f, index) => ({ ...f, displayIndex: index })) || [];
    if (isEqual(this._filters, mapped)) {
      return;
    }
    this.valuesUpdatedSet.clear();
    this.updateModel(mapped || [], !!this.selectedGroupFilter);
  }

  computeClearAll() {
    this.showClearAll = this.enableClearAll && this._selectedFilters?.length > 0;
  }

  get filters(): FilterView[] {
    return this._filters;
  }

  get sideFilters() {
    return this.filters.filter((f) => f.viewDetails?.position === 'Side');
  }

  get mainFilters() {
    return this.filters.filter((f) => f.viewDetails?.position === 'Main' || !f.viewDetails?.position);
  }

  get indexOpen(): number {
    return this._indexOpen;
  }

  set indexOpen(value: number) {
    this.indexOpen$.emit(value);
    this._indexOpen = value;
  }

  get isCollectionsView() {
    return this.hubService.currentLocation.startsWith('collections');
  }

  constructor(
    private hubService: HubService,
    private cdr: ChangeDetectorRef,
    private eventsService: EventsService,
    private keyboardService: KeyboardService,
    logger: LogService,
    public resultsService: ResultsService,
    private resultFiltersFocusService: ResultFiltersFocusService
  ) {
    this.logger = logger.scope('ResultsFloatingFiltersComponent');
  }

  ngOnInit() {
    this.resultFiltersFocusService
      .focus$(this.index)
      .pipe(untilDestroyed(this))
      .subscribe((val) => {
        this.componentFocused = val;
        if (this.list) {
          this.handleComponentFocused();
        }
      });
  }

  closeAll() {
    this.indexOpen = null;
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    this.unregisterKeyHandler();
  }

  ngAfterViewInit() {
    this.list = new FiltersList(this.filterRefs, null, '_model.displayIndex');
    if (this.componentFocused) {
      this.handleComponentFocused();
    }
    this.registerKeyboardHandler();
  }

  shouldOpen(index: number) {
    if (this.indexOpen >= 0) {
      return this.indexOpen === index;
    }
    return index === this.selectedGroupFilterIndex;
  }

  private updateModel(newFilters: FilterView[], refresh = false, handleClearAll = true) {
    const oldLength = this.filters?.length || 0;
    if (oldLength) {
      const newLength = newFilters.length;
      if (newLength <= this.indexOpen) {
        this.indexOpen = null;
      }
      for (let index = 0; index < Math.min(oldLength, newLength); index++) {
        const filter = this._filters[index];
        const newFilter = newFilters[index];
        const newOpenedIndex = index === this.indexOpen && !refresh;
        if (filter.name === newFilter.name && filter.picker === newFilter.picker && !['spread', 'nested'].includes(newFilter.picker)) {
          if (filter.valuesFetcher) {
            filter.valuesFetcher = newFilter.valuesFetcher;
          } else {
            filter.values = newFilter.values;
          }
          this.filterVersions[index] = this.filterVersions[index] || 0;
          this.filterVersions[index]++;
          filter.viewDetails = newFilter.viewDetails;
          filter.disabled = newFilter.disabled;
          filter.readonly = newFilter.readonly;
          if (isTimeFilter(filter) && isTimeFilter(newFilter)) {
            filter.pickerTypes = newFilter.pickerTypes;
            filter.minDate = newFilter.minDate;
            filter.maxDate = newFilter.maxDate;
          }
        } else {
          this._filters[index] = newFilters[index];
          this.filterVersions[index] = 1;
          if (newOpenedIndex) {
            this.indexOpen = null;
          }
        }
      }
      if (oldLength < newLength) {
        this._filters.push(...newFilters.slice(oldLength, newLength));
      } else if (oldLength > newLength) {
        this._filters = this._filters.slice(0, newLength);
      }
    } else {
      this._filters = newFilters;
    }
    for (const filter of this._filters) {
      for (const filterValue of filter?.values) {
        if (!isGroupFilterValue(filterValue)) {
          continue;
        }
        filterValue.disabled =
          filterValue.childFilter?.values?.every((v) => v.disabled) &&
          (!filterValue.childFilter?.valuesFetcher || this.valuesUpdatedSet.has(filter.name));
      }
    }
    this.updateSelected(handleClearAll);
    this.cdr.markForCheck();
  }

  private updateSelected(handleClearAll = true) {
    this._selectedFilters = uniqBy(
      this._filters.map((f) => f.values?.filter((v) => (f.picker === 'free-text' ? v.value?.length : v.selected))).flat(),
      (f) => f.id
    );
    if (handleClearAll) {
      this.computeClearAll();
    }
  }

  private unregisterKeyHandler() {
    if (!this.keyHandlerId) return;
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    this.keyHandlerId = null;
  }

  registerKeyboardHandler() {
    if (this.keyHandlerId || !this.list) return;
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => {
      this.handleKeys(keys, event);
      this.lastKeys = keys;
      this.lastEvent = event;
    }, 8);
    this.cdr.markForCheck();
  }

  valuesUpdated($event: ValuesUpdatedEvent) {
    const filter = this.filters.find((f) => f.name === $event.name && f.picker === $event.picker);
    if (!filter) {
      this.logger.warn('filter not found on valuesUpdated', { name: $event.name });
    }
    filter.values = $event.values;
    this.valuesUpdatedSet.add($event.name);
    const supportTag = filter.supportSingleTag || filter.values.find((v) => v.supportTag);
    const handleClearAll = !supportTag && !$event.filterInput;
    this.updateSelected(handleClearAll);
    this.onValuesUpdated.emit($event);
  }

  handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent) {
    const modifiers = getModifiers(keys);
    const key = keys[0];
    const componentFocused = this.componentFocused;
    if (!modifiers.length && isSemicolonKey(event) && !componentFocused) {
      const filterToOpenIndex = this.filters.findIndex((f) => f.viewDetails?.openOnSemicolon);
      if (filterToOpenIndex < 0) {
        return;
      }
      this.indexOpen = filterToOpenIndex;
      this.pannelToggledKeyboard = true;
      this.openWithKeyboard.emit();
      event.preventDefault();
      this.cdr.markForCheck();
      return;
    }
    if (isCtrlOrCommandCombination(event, 'semicolon') && this.showClearAll) {
      this.onClearAllFilters();
      return;
    }
    if (!componentFocused) {
      return;
    }
    if (key === 'tab') {
      event.preventDefault();
    }
    if (modifiers.length === 1 && modifiers[0] === 'shift' && key === 'tab') {
      if (this.list.markedIndex === 0) {
        this.list.unmark();
        this.cdr.markForCheck();
        return;
      }
      this.list.mark(this.list.markedIndex ? 'prev' : 'last');
      this.postKeyboardEvent(event);
      return;
    } else if (modifiers.length) {
      return;
    }
    if (key === 'tab' && this.list.markedIndex === null) {
      this.list.mark('first');
      this.postKeyboardEvent(event);
      return;
    }
    if (key === 'ArrowRight' || key === 'tab') {
      if (this.list.markedIndex === this.list.length - 1) {
        this.list.unmark();
        this.cdr.markForCheck();
        return;
      }
      this.list.mark('next');
      this.postKeyboardEvent(event);
      return;
    }
    if (key === 'ArrowLeft') {
      if (this.list.markedIndex === 0) {
        this.list.unmark();
        this.cdr.markForCheck();
        return;
      }
      this.list.mark('prev');
      this.postKeyboardEvent(event);
      return;
    }
    if (key === 'ArrowDown' || key === 'ArrowUp') {
      if (this.list.markedIndex >= 0) {
        this.list.unmark();
        this.cdr.markForCheck();
        return;
      }
      if (this.list.markedIndex === null || this.list.markedIndex === undefined) {
        this.list.mark('first');
        event.stopPropagation();
        return;
      }
    }
  }

  handleComponentFocused() {
    if (this.componentFocused) {
      this.list.mark('first');
    } else {
      this.list.unmark();
    }
    this.cdr.markForCheck();
  }

  postKeyboardEvent(event: CustomKeyboardEvent) {
    this.cdr.markForCheck();
    event.stopPropagation();
  }

  onClearAllFilters() {
    this.clearAll.emit();
  }

  back() {
    if (!this.selectedGroupFilter) {
      this.indexOpen = null;
      this.closeFilters.emit();
      this.cdr.markForCheck();
      return;
    }
    this.resetGroupFilter(true, false);
  }

  private resetGroupFilter(returnGroupFilter = true, resetIndex = true) {
    if (!this.selectedGroupFilter) {
      return;
    }
    if (returnGroupFilter) {
      const index = this.selectedGroupFilterIndex;
      this.filters[index] = cloneDeep(this.selectedGroupFilter);
    }
    if (resetIndex) this.indexOpen = null;
    this.selectedGroupFilterIndex = -1;
    this.selectedGroupFilter = null;
    this.cdr.markForCheck();
  }

  private onClosedFilter() {
    this.resetGroupFilter();
    if (this.pannelToggledKeyboard) {
      this.hubService.changeFocusState(true, true);
      this.pannelToggledKeyboard = false;
    }
  }

  datePickerClosed() {
    this.onClosedFilter();
  }

  async multiSelectOpenChanged(data: OpenFilterData, filterName: string) {
    const { opened, reset } = data;
    if (!opened) {
      if (!reset && this.selectedGroupFilter && !this.hasSelectedGroupFilter) {
        this.hasSelectedGroupFilter = true;
      } else {
        if (this.hasSelectedGroupFilter) {
          this.hasSelectedGroupFilter = false;
        }
        this.indexOpen = null;
        if (reset) {
          this.onClosedFilter();
        }
        this.cdr.markForCheck();
        return;
      }
    }
    if (!(this.indexOpen || this.indexOpen === 0)) {
      this.indexOpen = this.filters.findIndex((f) => f.name === filterName);
    }
  }

  private sendActionTelemetry(action: FilterChangeDataAction, target: string, currentFilterValues: DisplaySearchFilterValue[] = []) {
    const key = `filters.${action.toLowerCase()}`;

    const filterSuggestions = {};
    for (const filterValue of currentFilterValues) {
      const name = filterValue.filterName;
      filterSuggestions[name] = filterSuggestions[name] || [];
      filterSuggestions[name].push(filterValue.title);
    }
    this.eventsService.event(key, {
      target,
      location: {
        title: this.hubService.currentLocation,
      },
      jsonData: JSON.stringify({ filter_suggestion: filterSuggestions }),
    });
  }

  hitClearAllTelemetry(target: string) {
    this.eventsService.event('collections.clear_filters_prompt', {
      target,
      location: {
        title: this.hubService.currentLocation,
      },
    });
  }

  private extractFilters(name: string, changeDataItem: FilterChangeDataItem): SearchFilterValue[] {
    const { values } = changeDataItem;
    const filter = this.filters.find((f) => f.name === name);
    if (filter?.picker === 'free-text') {
      return [
        {
          id: `${filter.name}:value`,
          title: values[0].value,
          value: values[0].value,
          subtitle: '',
          filterName: filter.name,
        } as DisplaySearchFilterValue,
      ];
    }
    return values;
  }

  private addGroupFilter(name: string, filterValue: GroupDisplaySearchFilterValue, displayIndex: number) {
    const newFilter = filterValue.childFilter;
    if (newFilter.picker === 'toggle') {
      this.filterChange.emit({
        action: 'Set',
        name: newFilter.name,
        current: { values: [{ id: 'yes', value: 'yes', filterName: newFilter.name }] },
        changes: { values: [{ id: 'yes', value: 'yes', filterName: newFilter.name }] },
      });
      setTimeout(() => {
        this.indexOpen = null;
        this.cdr.markForCheck();
      }, 0);
      return;
    }
    const clone = cloneDeep(this.filters);
    const index = (this.selectedGroupFilterIndex = this.filters.findIndex((f) => f.name === name));
    this.selectedGroupFilter = cloneDeep(this.filters[index]);
    clone[index] = { ...newFilter, displayIndex };
    this.updateModel(clone, true, false);
    this.cdr.markForCheck();
  }

  private handleFilterAction(data: FilterChangeData): boolean {
    let name = data.name;
    const filter = this.filters.find((f) => f.name === name);
    if (filter?.picker === 'free-text') {
      this.hubService.query = data.current.values[0].value;
      this.filterChange.emit({
        name,
        action: 'Set',
        current: { values: [data.current.values[0].value] },
        changes: { values: [data.current.values[0].value] },
      });
      return true;
    }
    const action = data.action;
    if (action === 'ClearAll') {
      this.indexOpen = null;
      const filterNames = new Set<string>();
      filterNames.add(name);
      for (const v of filter?.values?.filter((v) => v.selected) || []) {
        filterNames.add(v.filterName);
      }
      for (const name of filterNames) {
        this.filterChange.emit({ action, name, current: { values: [] }, changes: data.changes });
      }
      this.sendActionTelemetry(action, 'all filters', []);
      return true;
    }
    const changes = data.changes;
    const filterValues = this.extractFilters(name, changes) as DisplaySearchFilterValue[];
    const filterValue = filterValues[0];
    const isGroupFilter = filterValue && isGroupFilterValue(filterValue);
    const isAddAction = action === 'Add';
    if (isSpecialFilterValue(filterValue)) {
      this.filterChange.emit({
        name: filterValue.filterName,
        action: 'Set',
        current: { values: [] },
        changes: { values: [data.current.values[0]] },
      });
      return true;
    }
    if (isAddAction && isGroupFilter) {
      // checks if the chosen filter value is group
      this.addGroupFilter(name, filterValue, filter.displayIndex);
      return false;
    }
    const currentFilterValues = this.extractFilters(name, data.current) as DisplaySearchFilterValue[];
    let currentSelected = this._selectedFilters;
    if (isAddAction || action === 'Remove') {
      if (filterValue?.filterName && name !== filterValue?.filterName) {
        name = filterValue.filterName;
      }
      currentSelected = currentSelected.filter((f) => isAddAction || f.id !== filterValue.id);
      if (isAddAction) {
        currentSelected.push(filterValue);
      }
      const newSelected: DisplaySearchFilterValue[] = currentFilterValues.filter((f) => f.filterName === name);
      this.filterChange.emit({
        name,
        action,
        current: { values: newSelected },
        changes: data.changes,
        supportTag: filterValue.supportTag,
      });
    }
    if (action === 'Set') {
      currentSelected = currentSelected.filter((f) => f.filterName !== filterValue.filterName);
      currentSelected.push(filterValue);
      const values = currentFilterValues;
      this.filterChange.emit({ name, action, current: { values }, changes: data.changes, supportTag: filterValue.supportTag });
    }
    this._selectedFilters = currentSelected;
    const target = `${filterValue.filterName}:${filterValue.value}`;
    this.sendActionTelemetry(action, target, currentSelected);
    return true;
  }

  async onFilterChange(data: FilterChangeData) {
    const report = this.handleFilterAction(data);
    if (!report) {
      return;
    }
    this.resetGroupFilter();
  }
}
