import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Accounts, Applications, Commands, Filters, Fyis, Groups, Links, Results, Workspace } from '@local/client-contracts';
import { capitalCase, getKeyName, isEnterKey, isPrintableKey } from '@local/ts-infra';
import { PopupRef, PopupService } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TimeMultiSelectComponent } from '@shared/components/filters/time-multi-select/time-multi-select.component';
import { DateFormat, RestrictedConstant } from '@shared/consts';
import { EventsService } from '@shared/services';
import { ApplicationsService } from '@shared/services/applications.service';
import { DesktopService } from '@shared/services/desktop.service';
import { LinksService } from '@shared/services/links.service';
import { SessionService } from '@shared/services/session.service';
import { getTimeFromNowInText } from '@shared/utils/date-format.util';
import { isEmpty, isEqual, pickBy } from 'lodash';
import moment from 'moment';
import { Tooltip } from 'primeng/tooltip';
import { Subscription, fromEvent, takeUntil } from 'rxjs';
import { AppPopupComponent, AppPopupData } from 'src/app/bar/components/app-popup/app-popup.component';
import { SelectedList, ShareOptionsComponent, ShareOptionsModel } from 'src/app/bar/components/share-options/share-options.component';
import { WorkspacesService } from 'src/app/bar/services';
import { ShowToasterService } from 'src/app/bar/services/show-toaster.service';
import { LinkCommand, LinkDeleteCommand, LinkRefreshCommand, LinkUpdateCommand } from '.';
import { LinkTimeFilterComponent } from '../../links/components/link-time-filter/link-time-filter.component';
import { LinkFullDisplayItem } from '../app-item/app-item.component';
import { State } from '../apps-state';
import { TagGroupPopupComponent } from '../tag-group-popup/tag-group-popup.component';
import { AvatarListService } from 'src/app/bar/services/avatar-list.service';
import { AvatarItemModel } from 'src/app/bar/models/avatar-item.model';
import { isIcon } from '@shared/utils';

export interface DropDownOpenLinkItem {
  label: string;
  value: Commands.OpenWith;
}

const timeFilterContent = {
  title: 'Time filter',
};

@UntilDestroy()
@Component({
  selector: 'link-item',
  templateUrl: './link-item.component.html',
  styleUrls: ['../shared.scss', './link-item.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkItemComponent implements OnInit, OnDestroy {
  readonly MAX_DISPLAY_TAGS = 3;
  readonly tooltipMessage: string = 'Outdated links may cause information to not be accurate. Please reconnect to update the information.';
  private readonly MAX_COLORS = 8;
  private app: Applications.DisplayItem;
  private isOpenedPopup: boolean;
  private isClosedTagGroupPopup: boolean;
  private compPopupRef: PopupRef<AppPopupComponent, AppPopupData>;
  private click$: Subscription;
  private messagePopupRef: PopupRef<AppPopupComponent, AppPopupData>;
  private updatePopupRef: PopupRef<ShareOptionsComponent, ShareOptionsModel>;
  private savedMessagePopupRef: PopupRef<AppPopupComponent, AppPopupData>;

  updateItem: LinkFullDisplayItem;
  userName: string;
  restrictedTooltip: string = RestrictedConstant.TOOLTIP;
  editMode = false;
  isValid: boolean;
  deleteInitiated: boolean;
  LINK_NAME_MAX_LENGTH = 50;
  stateLink: State;
  sharedTooltip: string;
  groupsMembersList: (Accounts.WorkspaceAccount | Groups.WorkspaceGroup)[] = [];
  palette: string[] = [];
  originalLink: LinkFullDisplayItem;
  hideResults: boolean;
  openLinkOptions: Array<DropDownOpenLinkItem> = [
    { label: 'Default Browser', value: 'browser' },
    { label: 'Desktop App', value: 'app' },
  ];
  openLinkSelected: DropDownOpenLinkItem;
  showOpenLinkDropDown = false;
  displaySharedLink: boolean;
  workspace: Workspace.Workspace;
  workspaceIcon: Results.Icon;
  displayModifiedSyncTime: string;
  preDisplayModifiedSyncTime: string;
  tooltipModifiedSyncTime: string;
  displayModifiedTime: Results.BulletPart;
  displayCreatedTime: Results.BulletPart;
  timeFilterContent = timeFilterContent;
  disabledTimeFilter: boolean;
  connectionType: string;
  @Input() usedColors: string[];
  @Input() linkName: string;
  @Input() appName: string;
  @Output() invoke: EventEmitter<LinkCommand> = new EventEmitter();
  @Output() edit: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('click') click: ElementRef;
  @ViewChild('document') document: ElementRef;
  @ViewChild('nameInput') nameInputRef: ElementRef;
  @ViewChild(LinkTimeFilterComponent) linkTimeFilter: LinkTimeFilterComponent;
  @ViewChild('deletePopupContent') deletePopupContent: ElementRef;
  @ViewChildren(Tooltip) tooltips;

  private _link: LinkFullDisplayItem;

  get link() {
    return this._link;
  }

  @Input() set link(link: LinkFullDisplayItem) {
    if (!this.isItemChanged(link, this.link)) {
      return;
    }
    if (!this.isPaletteOpen) {
      this._link = link;
      this.updateItem = { ...this.link };
      this.originalLink = { ...link };
      this.setSharedTooltip();
      this.setStateIndicator();
      this.setOpenLinkOptions();
    } else if (this.link.syncStatus !== link.syncStatus) {
      this.link.syncStatus = link.syncStatus;
      this.setStateIndicator();
    }
    this.connectionType = `${!this.updateItem?.resourcePermissions?.enabled ? 'Discovery' : 'Permission based'}`;
    this.setLastSync();
    this.setCreationTime();
    this.setModificationTime();
  }

  private _lastSync: Fyis.Sync;

  get lastSync() {
    return this._lastSync;
  }

  @Input() set lastSync(v: Fyis.Sync) {
    this._lastSync = v;
    this.setLastSync();
  }

  private _isPaletteOpen = false;

  get isPaletteOpen(): boolean {
    return this._isPaletteOpen;
  }

  set isPaletteOpen(value) {
    if (this._isPaletteOpen == value) return;
    if (value) {
      this.click$ = fromEvent(document, 'click').subscribe((event: MouseEvent) => this.onClickOut(event));
    } else if (this.click$) {
      this.onUpdate();
      this.click$.unsubscribe();
    }
    this._isPaletteOpen = value;
    this.setLastSync();
  }

  get isShared(): boolean {
    return this.displaySharedLink
      ? this.link.shareOptions && this.link.shareOptions.level.toLocaleLowerCase() !== 'private'
      : !this.link.ownedByMe;
  }

  get paletteClass() {
    return {
      [this.link?.color]: this.link?.color,
      'edit-mode': this.isPaletteOpen,
      stale: this.link.syncStatus === 'stale',
    };
  }

  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    public workspacesService: WorkspacesService,
    private linksService: LinksService,
    private eventsService: EventsService,
    private popupService: PopupService,
    private sessionService: SessionService,
    private desktopService: DesktopService,
    private workspaceService: WorkspacesService,
    private applicationsService: ApplicationsService,
    private showToasterService: ShowToasterService,
    private avatarListService: AvatarListService
  ) {
    this.isPaletteOpen = false;
    this.workspaceService.ownerOrAdmin$.pipe(untilDestroyed(this)).subscribe((s) => (this.displaySharedLink = s));
  }

  ngOnInit(): void {
    this.hideResults = this.link.hide;

    this.linkName = this.link.name;
    const initialPalette = new Array(this.MAX_COLORS).fill(null).map((v, i) => `ml-c${i + 1}`);
    initialPalette.filter((color) => {
      return this.usedColors.includes(color) ? false : true;
    });
    this.setStateIndicator();

    this.app = this.applicationsService.apps[this.link.appId];

    this.workspaceService.current$.pipe(untilDestroyed(this)).subscribe((w) => {
      this.workspace = w;
      this.workspaceIcon = this.workspaceService.getLogo(true);
      this.setSharedTooltip();
      this.cdr.markForCheck();
    });

    this.sessionService.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.userName = `${user?.firstName} ${user?.lastName}`;
      this.setSharedTooltip();
      this.cdr.markForCheck();
    });
    this.setPalette();
  }

  ngOnDestroy(): void {
    this.onUpdate();
    if (this.click$) this.click$.unsubscribe();
  }

  @HostBinding('class')
  get active() {
    return this.isPaletteOpen ? 'edit-mode' : '';
  }

  @HostBinding('class.loading') get loading() {
    return this.deleteInitiated;
  }

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    if (this.isPaletteOpen === true) return;

    // In case of click inside the 'danger zone' screen. event race with 'deleteInitiated'
    if (!document.contains(event.target as HTMLElement)) return;

    if (event.composedPath().some((el: HTMLElement) => el.id === 'remove-link')) return;

    event.stopPropagation();
    this.setPaletteState(true);
  }

  setPalette() {
    const initialPalette = new Array(this.MAX_COLORS).fill(null).map((v, i) => `ml-c${i + 1}`);
    if (this.usedColors.length > this.MAX_COLORS) {
      this.palette = initialPalette;
      return;
    }
    const availableColors = [this.link?.color, ...initialPalette.filter((color) => !this.usedColors.includes(color))];
    this.palette = availableColors.length === 0 ? initialPalette : availableColors;
  }

  private isItemChanged(item1, item2): boolean {
    return JSON.stringify(item1) !== JSON.stringify(item2);
  }

  openLearnMore(event) {
    window.open(RestrictedConstant.URL, '_blank');
    event.stopPropagation();
  }

  private setSharedTooltip() {
    this.groupsMembersList = [...(this.link?.accounts || []), ...(this.link?.groups || [])];
    let res = 'Shared with';
    let displayList: any[];
    switch (this.link.shareOptions.level) {
      case 'Public':
        res = `${res} ${this.workspace?.name}`;
        break;

      case 'Protected':
        if (!this.groupsMembersList.length) {
          this.sharedTooltip = null;
          break;
        }
        displayList = this.groupsMembersList.slice(0, 3);
        for (const item of displayList) {
          res += ` ${item.name}`;
          if (item !== displayList[displayList.length - 1]) {
            res += ',';
          }
        }

        if (this.groupsMembersList.length > 3) {
          res += ` and ${this.link.groups?.length + this.link.accounts?.length - 3} more`;
        }

        this.sharedTooltip = res;
    }
    if (this.isShared) {
      res += `\nConnection Type: ${this.connectionType}`;
      this.sharedTooltip = res;
    }
  }

  private onClickOut(event: MouseEvent) {
    if (this.isOpenedPopup) return;
    if (this.isClosedTagGroupPopup) {
      this.isClosedTagGroupPopup = false;
      return;
    }
    const timeFilter = this.linkTimeFilter?.resultsFiltersBox?.filterRefs?.first;
    if (timeFilter) {
      const isFilterVisible = (timeFilter as TimeMultiSelectComponent)?.uMultiSelect?.multiSelect?.overlayVisible;
      const isCalendarVisible = (timeFilter as TimeMultiSelectComponent)?.datePickerService?.isVisible;
      if (isFilterVisible || isCalendarVisible) {
        return;
      }
    }
    if (!this.isPaletteOpen || this.elementRef.nativeElement.contains(event.target)) return;
    if (this.deleteInitiated) this.deleteInitiated = false;
    this.setPaletteState(false);
    this.onUpdate();
  }

  async toggleLinkVisibility(): Promise<void> {
    this.hideResults = !this.hideResults;
    const target = this.hideResults ? 'link_visibility_off' : 'link_visibility_on';
    this.eventsService.event('link_visibility', { target, location: this.getLocation() });
    if (this.isPaletteOpen) {
      this.link.hide = !this.link.hide;
      this.updateItem.hide = this.link.hide;
      return;
    }
    await this.linksService.update(this.link.id, { hide: this.hideResults });
  }

  private popupTagGroupRef: PopupRef<TagGroupPopupComponent, Links.TagGroup>;

  openTagsPopup() {
    if (this.popupTagGroupRef) {
      this.closeTagGroupPopup();
    }
    this.popupTagGroupRef = this.popupService.open<TagGroupPopupComponent, Links.TagGroup>(
      'center',
      TagGroupPopupComponent,
      this.link.tagGroup,
      {
        position: 'center',
        backdropStyle: 'blur-2',
      }
    );
    this.popupTagGroupRef.close$.pipe(untilDestroyed(this)).subscribe(() => {
      this.isClosedTagGroupPopup = true;
    });
  }

  closeTagGroupPopup() {
    this.popupTagGroupRef.destroy();
    this.popupTagGroupRef = null;
  }

  enforceMaxLength(event: KeyboardEvent) {
    const key = getKeyName(event);

    if (isEnterKey(key)) {
      event.preventDefault();
      this.setName();
      return;
    }

    if (this.linkName.length >= this.LINK_NAME_MAX_LENGTH && event.key != 'Backspace') {
      const inputEl = event.currentTarget as HTMLInputElement;
      const selectionCount = inputEl?.selectionEnd - inputEl?.selectionStart;
      if (selectionCount !== 0 && this.linkName.length - selectionCount > this.LINK_NAME_MAX_LENGTH && isPrintableKey(event)) {
        event.preventDefault();
        const nameInputTooltip = this.tooltips._results.find((tooltip) => tooltip.el.nativeElement.id === 'nameInput');
        nameInputTooltip.show();
      }
      return true;
    }
    this.sentEditEvent('link_name');
  }

  setName() {
    if (!this.linkName) this.linkName = this.link.name;

    if (this.link.name === this.linkName) return;

    if (this.linkName.length <= this.LINK_NAME_MAX_LENGTH && this.linkName !== '') {
      this.isValid = true;
      this.updateItem.name = this.linkName.trim();
    } else {
      this.isValid = false;
    }
  }

  setEditStyle(editMode: boolean) {
    if (this.editMode === editMode) return;
    this.editMode = editMode;
  }

  private async setOpenLinkOptions() {
    if (this.desktopService) {
      this.showOpenLinkDropDown = await this.desktopService.isDesktopAppInstalled(this.link.appId);
    }
    if (this.showOpenLinkDropDown && this.link?.preferences?.openWith) {
      this.openLinkSelected = this.openLinkOptions.find((op) => op.value === this.link.preferences.openWith);
    }
  }

  onChangeColor(color: Links.Colors, $event: MouseEvent, index: number) {
    [this.palette[0], this.palette[index]] = [this.palette[index], this.palette[0]];
    this.updateItem.color = color;
    this.link.color = color;
    this.cdr.markForCheck();
    this.sentEditEvent('link_color');
  }

  isEqualModels(originalItem: LinkFullDisplayItem, updateItem: LinkFullDisplayItem): Partial<LinkFullDisplayItem> {
    return pickBy(updateItem, (v, k) => !isEqual(originalItem[k], v));
  }

  onUpdate() {
    this.setName();
    const updated: Partial<LinkFullDisplayItem> = this.isEqualModels(this.originalLink, this.updateItem);
    if (isEmpty(updated)) return;
    delete updated.accounts;
    delete updated.groups;
    const command: LinkUpdateCommand = { type: 'update', id: this.link.id, payload: updated };
    this.invoke.emit(command);
  }

  onDelete() {
    this.deleteInitiated = false;
    const command: LinkDeleteCommand = { type: 'delete', id: this.link.id };
    this.invoke.emit(command);
  }

  async openDeletePopup() {
    const position: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top', offsetY: -40 }];
    this.compPopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      {
        message: 'Are you sure you want to delete this link?',
        showButtons: true,
        content: {
          secondaryButton: 'Cancel',
          primaryButton: 'Delete',
        },
        templateContent: this.isShared ? this.deletePopupContent : undefined,
      },
      { position, width: '286px' }
    );
    this.compPopupRef.compInstance.primaryButton.pipe(takeUntil(this.compPopupRef.destroy$)).subscribe(() => {
      this.onDelete();
      this.sentPopupEvent('delete_link_prompt', 'delete', true);
    });
    this.compPopupRef.compInstance.secondaryButton.pipe(takeUntil(this.compPopupRef.destroy$)).subscribe((event) => {
      event.stopPropagation();
      this.compPopupRef.close();
      this.sentPopupEvent('delete_link_prompt', 'cancel', true);
    });
    this.sentEditEvent('delete_link');
  }

  onRefresh() {
    const command: LinkRefreshCommand = {
      type: 'refresh',
      id: this.link.id,
      key: this.link.key,
      payload: this.link,
    };
    this.invoke.emit(command);
  }

  setPaletteState(shouldOpen: boolean) {
    if (this.isPaletteOpen === shouldOpen || this.deleteInitiated) return;
    this.isPaletteOpen = shouldOpen;

    if (this.isPaletteOpen) {
      // We have to use setTimeout since that by the execution time the input doesn't exist yet
      // It also covers a bug in chrome when item is rendered and focused immediately sometimes wouldn't work
      setTimeout(() => {
        if (this.nameInputRef) this.nameInputRef.nativeElement.focus();
      }, 500);
    }
    this.edit.emit(shouldOpen);
    this.eventsService.event('link_action.edit_link', {
      label: this.link.id,
      location: this.getLocation(),
      jsonData: JSON.stringify({ link_id: [this.link.id] }),
    });
  }

  setStateIndicator() {
    switch (this.link.syncStatus) {
      case 'stale': {
        this.stateLink = 'Outdated';
        break;
      }
      case 'initial': {
        this.stateLink = 'Syncing';
        break;
      }
      case 'failed': {
        this.stateLink = 'Failed';
        break;
      }
      case 'completed': {
        this.stateLink = 'Connected';
        break;
      }
      default: {
        this.stateLink = 'Syncing';
        break;
      }
    }
    this.cdr.markForCheck();
  }

  updatePermissions(shareOptions: Links.ShareOptions, selectedList: SelectedList) {
    if (shareOptions) this.updateItem.shareOptions = shareOptions;
    this.updateItem.groups = [];
    this.updateItem.accounts = [];
    for (const item of selectedList) {
      if ('membersCount' in item) {
        this.updateItem.groups.push(item);
        continue;
      }
      this.updateItem.accounts.push(item);
    }
    this.isOpenedPopup = false;
    this.updatePopupRef.destroy();
    this.updateItem.shareOptions = shareOptions;
    this.groupsMembersList = selectedList;
    this.cdr.markForCheck();
    this.updatePopupRef.destroy();
  }

  private getLocation() {
    return { title: `connect_apps/${this.appName}/link_edit` };
  }

  openUpdatePopup(event) {
    event.stopPropagation();
    this.isOpenedPopup = true;
    const updateLink = { ...this.link, ...this.updateItem };

    this.updatePopupRef = this.popupService.open<ShareOptionsComponent, ShareOptionsModel>(
      'center',
      ShareOptionsComponent,
      {
        link: updateLink,
        workspaceLogo: this.workspaceIcon,
        headerIcon: !this.isShared ? { lightUrl: this.link?.avatar } : this.workspaceIcon,
        linkColor: this.link?.color,
        componentOptions: {
          isPopUp: true,
          hasBorder: false,
          allowPopupHeader: true,
          showPrivateLink: false,
          showSubTitle: true,
          showDeleteLinkMsg: true,
          showButtons: true,
          showRestricted: true,
        },
      },
      { position: 'center', backdropStyle: 'blur-2', closeOnClickOut: false }
    );

    this.updatePopupRef.compInstance.updateButton
      .pipe(takeUntil(this.updatePopupRef.destroy$))
      .subscribe(({ shareOptions, selectedList }) => {
        this.sentPopupEvent('update_link_access', 'update');
        if (shareOptions.level === 'Protected' && !selectedList?.length) {
          this.openAnyMambersPopup(shareOptions, selectedList);
          return;
        }
        this.updatePermissions(shareOptions, selectedList);
      });
    this.updatePopupRef.compInstance.cancelButton.pipe(takeUntil(this.updatePopupRef.destroy$)).subscribe((shareOptions) => {
      this.sentPopupEvent('update_link_access', 'cancel');
      if (this.isItemChanged(shareOptions, this.updateItem.shareOptions)) {
        this.openCancelPopup();
        return;
      }
      this.updatePopupRef.destroy();
      this.isOpenedPopup = false;
    });
    this.updatePopupRef.compInstance.deleteLink.pipe(takeUntil(this.updatePopupRef.destroy$)).subscribe(() => {
      this.openDeletePopup();
      this.updatePopupRef.destroy();
      this.isOpenedPopup = false;
    });

    this.eventsService.event('edit_link.manage_access', {
      label: this.link.id,
      location: this.getLocation(),
      jsonData: JSON.stringify({ link_id: [this.link.id] }),
    });
  }

  openCancelPopup() {
    const position: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top', offsetY: -40 }];
    this.messagePopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      {
        message: 'Closing the window will discard changes',
        showButtons: true,
        content: {
          secondaryButton: 'Cancel',
          primaryButton: 'Discard',
        },
      },
      { position, width: '286px' }
    );
    this.messagePopupRef.compInstance.primaryButton.pipe(takeUntil(this.messagePopupRef.destroy$)).subscribe((primaryEvent) => {
      primaryEvent.event.stopPropagation();
      this.messagePopupRef.destroy();
      this.updatePopupRef.destroy();
      this.isOpenedPopup = false;
      this.sentPopupEvent('cancel_link_prompt', 'yes');
    });
    this.messagePopupRef.compInstance.secondaryButton.pipe(takeUntil(this.messagePopupRef.destroy$)).subscribe((event) => {
      event.stopPropagation();
      this.messagePopupRef.destroy();
      this.sentPopupEvent('cancel_link_prompt', 'no');
    });
  }

  openAnyMambersPopup(shareOptions: Links.ShareOptions, selectedList: SelectedList) {
    const position: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' }];
    this.messagePopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      {
        message:
          'You have not selected any group / member to share this link with. Once created, you can choose to share this link with others.',
        showButtons: true,
        rightButtonStyle: { type: 'primary', size: 124 },
        leftButtonStyle: { size: 124 },
        content: {
          secondaryButton: 'Edit link',
          primaryButton: 'Continue',
          title: 'This link will be shared with you only',
        },
      },
      { position }
    );
    this.messagePopupRef.compInstance.primaryButton.pipe(takeUntil(this.messagePopupRef.destroy$)).subscribe((primaryEvent) => {
      primaryEvent.event.stopPropagation();
      this.messagePopupRef.destroy();
      this.updatePermissions(shareOptions, selectedList);
      this.sentPopupEvent('no_member_selected', 'continue', true);
    });
    this.messagePopupRef.compInstance.secondaryButton.pipe(takeUntil(this.messagePopupRef.destroy$)).subscribe((event) => {
      event.stopPropagation();
      this.messagePopupRef.destroy();
      this.sentPopupEvent('no_member_selected', 'back', true);
    });
  }

  sentPopupEvent(key: string, target: string, shouldJsonData?: boolean) {
    this.eventsService.event(`link_action.${key}`, {
      target,
      label: this.link.id,
      location: this.getLocation(),
      jsonData: shouldJsonData ? JSON.stringify({ link_id: [this.link.id] }) : null,
    });
  }

  sentEditEvent(key: string) {
    this.eventsService.event(`edit_link.${key}`, {
      label: this.link.id,
      location: this.getLocation(),
      jsonData: JSON.stringify({ link_id: [this.link.id] }),
    });
  }

  onOpenLinkSelect(selection: DropDownOpenLinkItem) {
    this.openLinkSelected = selection;
    this.linksService.updateOpenWith(this.link.id, selection.value);
  }

  setLastSync() {
    if (!this.lastSync?.endTime) return;
    this.displayModifiedSyncTime = capitalCase(getTimeFromNowInText(this.lastSync.endTime)).toLowerCase();
    this.preDisplayModifiedSyncTime = this.isPaletteOpen ? 'Last synced ' : '';
    this.tooltipModifiedSyncTime = `${!this.isPaletteOpen ? 'Last synced ' : ''}${moment(this.lastSync.endTime).format(
      DateFormat.FULL_DATE_HOURS_A
    )}`;
    this.cdr.markForCheck();
  }

  async setModificationTime() {
    const time = capitalCase(getTimeFromNowInText(this.link?.modifiedTime)).toLowerCase();
    const avatar = await this.avatarListService.getOwnerAvatar(this.link?.modifiedBy);
    const name = this.getUserText(this.link?.modifiedBy, avatar);
    const text = `Last modified ${time}`;
    this.displayModifiedTime = {
      text: text + (avatar?.imgUrl ? ` by ${name}` : ''),
      icon: {
        lightUrl: isIcon(avatar?.imgUrl) ? avatar.imgUrl.lightUrl : avatar?.imgUrl,
        rounded: true,
        size: 'large',
      },
      tooltip: '',
    };
    this.cdr.markForCheck();
  }

  getUserText(accountId: string, avatar: AvatarItemModel) {
    if (this.workspaceService.isMe(accountId)) {
      return 'you';
    }
    return avatar.name;
  }

  async setCreationTime() {
    if (!this.link?.createBy || !this.link?.createTime) return;
    const time = capitalCase(getTimeFromNowInText(this.link.createTime)).toLowerCase();
    const avatar = await this.avatarListService.getOwnerAvatar(this.link.createBy);
    const name = this.getUserText(this.link.createBy, avatar);
    const text = `Created ${time} by ${name}`;
    this.displayCreatedTime = {
      text,
      icon: {
        lightUrl: isIcon(avatar.imgUrl) ? avatar.imgUrl.lightUrl : avatar.imgUrl,
        rounded: true,
        size: 'large',
      },
      tooltip: '',
    };
    this.cdr.markForCheck();
  }

  async onFilterChange($event: Filters.Values) {
    this.disabledTimeFilter = true;
    this.cdr.markForCheck();
    if (isEqual(this.link.timeFilter, $event)) {
      this.disabledTimeFilter = false;
      this.cdr.markForCheck();
      return;
    }
    this.openSuccessfullySavedPopup();
    const prevTimeFilterValue = this.link.timeFilter;
    this.link.timeFilter = $event;
    try {
      const updatedLink = await this.linksService.update(this.link.id, { timeFilter: $event });
      this.link.filtersPolicy = updatedLink.filtersPolicy;
    } catch (error) {
      this.link.timeFilter = prevTimeFilterValue;
      this.showToasterService.showErrorToaster('save-link-time-filter');
    } finally {
      this.disabledTimeFilter = false;
      this.cdr.markForCheck();
    }
  }

  openSuccessfullySavedPopup() {
    this.isOpenedPopup = true;
    const position: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' }];
    this.savedMessagePopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      {
        message: 'Please note that the changes may take up to 24 hours to take effect',
        showButtons: true,
        onlyPrimaryButton: true,
        rightButtonStyle: { type: 'primary', size: 100 },
        content: {
          title: 'The range has been adjusted',
          primaryButton: 'Got it',
        },
        messageStyle: { fontSize: '12px', lineHeight: '18px' },
      },
      { position }
    );
    this.savedMessagePopupRef.compInstance.primaryButton.pipe(takeUntil(this.savedMessagePopupRef.destroy$)).subscribe(() => {
      this.savedMessagePopupRef.destroy();
      setTimeout(() => {
        this.isOpenedPopup = false;
      }, 0);
    });
  }
}
