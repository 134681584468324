<div
  class="summary-popup-container"
  (mouseover)="hover = true"
  (mouseleave)="hover = false; data.position !== 'center' ? closePopup() : null"
  (mousemove)="$event.stopPropagation()"
>
  <ng-container *ngIf="summary">
    <div class="summary-popup-header">
      <div class="summary-popup-header-content">
        <u-icon class="icon" [model]="INDICATE_SUMMARY_ICON"></u-icon>
        <div class="title">Summary</div>
      </div>
      <div *ngIf="hover" class="summary-popup-header-action">
        <u-buttons
          [pTooltip]="tooltipCopyText"
          tooltipPosition="bottom"
          tooltipStyleClass="u-tooltip"
          (click)="copyText()"
          type="secondary"
          class="space icon"
        >
          <u-icon [model]="{ type: 'font', value: 'icon-duplicate' }"></u-icon>
        </u-buttons>
        <u-buttons
          pTooltip="Open resource"
          tooltipPosition="bottom"
          tooltipStyleClass="u-tooltip"
          (click)="openResource()"
          type="secondary"
          class="icon"
          [ngClass]="{ space: data.displayX }"
        >
          <u-icon [model]="{ type: 'font', value: 'icon-launch' }"></u-icon>
        </u-buttons>
        <u-icon
          *ngIf="data.displayX"
          class="icon"
          (mousedown)="closePopup()"
          [model]="{ type: 'font', value: 'icon-Windows-close' }"
        ></u-icon>
      </div>
    </div>
    <div class="summary">{{ summary }}</div>
  </ng-container>
  <ng-container *ngIf="errorSummary && !summary">
    <div class="summary-popup-error">
      <div class="summary-popup-error-icon">!</div>
      <div class="summary-popup-error-message" [innerHTML]="errorSummary"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="!summary && !errorSummary">
    <result-ghost class="summary-popup-loading" [showAvatar]="false" [ghostingLine]="7"></result-ghost>
  </ng-container>
</div>
