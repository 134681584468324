import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UImageCropperComponent } from './u-image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UFileUploadModule } from '../u-file-upload/u-file-upload.module';

@NgModule({
  declarations: [UImageCropperComponent],
  imports: [CommonModule, FormsModule, ImageCropperModule, UFileUploadModule],
  exports: [UImageCropperComponent],
})
export class UImageCropperModule {}
