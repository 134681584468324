<p-selectButton
  [options]="options"
  optionLabel="label"
  optionValue="value"
  optionDisabled="disabled"
  [disabled]="disabled"
  [tabindex]="tabindex"
  [multiple]="multiple"
  styleClass="u-select-button {{ styleClass }}"
  (onOptionClick)="onOptionClickEvent($event)"
  (onChange)="onChangeEvent($event)"
  [(ngModel)]="selectedValue"
>
</p-selectButton>
