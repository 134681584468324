import { Injectable } from '@angular/core';
import { Collections } from '@local/client-contracts';
import { isLiveCollection, isStaticCollection, isWikiCollection } from '@local/common-web';
import { SearchResultContext } from './search';
import { LinkResourcesResultExtra, LinkResourcesSourceSettings } from './search/client';
import { isLinkResourcesSettings, isStaticCollectionSettings, isWikiCollectionSettings } from './search/client/source-setings.util';
import { StaticCollectionItemSourceSettings } from './search/client/static-collection-items';
import { WikiCollectionItemSourceSettings } from './search/client/wiki-collection-items';

@Injectable()
export class CollectionsSearchHelperService {
  static readonly WIDGET_CARDS_MAX_COUNT = 20;

  static getSourceSettings(
    collection: Collections.Collection,
    liveSourceSettings: Function,
    searchParams?: Collections.SearchParams | Collections.WikiSearchParams | Collections.LiveSearchParams,
    stateView?: boolean,
    cache?: 'first' | 'only' | 'skip',
    onlyCount?: boolean
  ): LinkResourcesSourceSettings[] | StaticCollectionItemSourceSettings[] | WikiCollectionItemSourceSettings[] {
    if (isStaticCollection(collection)) {
      return this.getStaticSourceSettings(collection, stateView, onlyCount);
    }
    if (isWikiCollection(collection)) {
      return this.getWikiSourceSettings(collection, searchParams, stateView, cache);
    }
    return liveSourceSettings(collection, searchParams);
  }

  static getStaticSourceSettings(
    collection: Collections.Collection,
    stateView?: boolean,
    onlyCount?: boolean
  ): StaticCollectionItemSourceSettings[] {
    return [
      {
        id: 'static-collection-items',
        collection: collection,
        collectionId: collection.id,
        type: 'static-collection-items',
        noHeader: true,
        stateView,
        onlyCount: onlyCount,
      } as StaticCollectionItemSourceSettings,
    ];
  }

  static getWikiSourceSettings(
    collection: Collections.Collection,
    searchParams?: Collections.WikiSearchParams,
    stateView?: boolean,
    cache?: 'first' | 'only' | 'skip'
  ): WikiCollectionItemSourceSettings[] {
    return [
      {
        id: 'wiki-collection-items',
        collection: collection,
        collectionId: collection.id,
        type: 'wiki-collection-items',
        noHeader: true,
        sorting: searchParams?.sort,
        cache,
        stateView,
        maxCount: CollectionsSearchHelperService.WIDGET_CARDS_MAX_COUNT,
      } as WikiCollectionItemSourceSettings,
    ];
  }

  static getCollectionResultsCount(collection: Collections.Collection, ctx: SearchResultContext): number {
    if (isLiveCollection(collection)) {
      const resourceSource = ctx.sources?.find((s) => isLinkResourcesSettings(s.source));
      const extra = resourceSource?.extra as LinkResourcesResultExtra;
      const cloud = extra.search.origins?.Cloud?.response;
      const local = extra.search.origins?.Local?.response;
      return cloud?.totalResults || 0 + local?.totalResults || 0;
    }
    if (isStaticCollection(collection)) {
      const resourceSource = ctx.sources?.find((s) => isStaticCollectionSettings(s.source));
      const extra = resourceSource?.extra;
      return extra?.totalResults || 0;
    }
    if (isWikiCollection(collection)) {
      const resourceSource = ctx.sources?.find((s) => isWikiCollectionSettings(s.source));
      const extra = resourceSource?.extra;
      return extra?.totalResults || ctx.items?.length;
    }
  }
}
