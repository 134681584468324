import { Injectable } from '@angular/core';
import { CanActivateViewGuard } from './can-activate-view.guard';
import { FlagsService } from '@shared/services/flags.service';
import { RouterService } from '@shared/services/router.service';
import { Constants } from '@local/common';

@Injectable()
export class CanActivateWikiGuard extends CanActivateViewGuard {
  protected flag = Constants.DISABLED_WIKIS_FLAG;

  constructor(routerService: RouterService, flags: FlagsService) {
    super(routerService, flags);
  }
}
