import { ElementRef, QueryList } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { uniq } from 'lodash';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TagComponent } from 'src/app/bar/components/suggestions-tags/suggestion-tag/suggestion-tag.component';
import { MultiSelectWrapperComponent } from './multi-select-wrapper/multi-select-wrapper.component';
import { SpreadFilterComponent } from './spread/spread-filter.component';
import { ToggleFilterComponent } from './toggle/toggle-filter.component';
import { AvatarMultiSelectFilterComponent } from './avatar-multi-select-filter/avatar-multi-select-filter.component';
import { TimeMultiSelectComponent } from './time-multi-select/time-multi-select.component';

export type FilterType =
  | ToggleFilterComponent
  | SpreadFilterComponent
  | TagComponent
  | MultiSelectWrapperComponent
  | AvatarMultiSelectFilterComponent
  | TimeMultiSelectComponent;
export type BrowseEvent = 'first' | 'prev' | 'next' | 'last' | 'line-below' | 'line-up' | number;
@UntilDestroy()
export class FiltersList {
  private readonly SORT_SPLIT_CHAR: string = '.';
  private _markedIndex: number = null;
  private lineIndices: number[]; // indices of the first item index of each line
  alternativeMarkedIndex: number;
  updateScreen = new Subject();
  canUpdateScreen: boolean = true;

  get markedIndex(): number {
    return this._markedIndex;
  }

  get marked() {
    return this.items[this._markedIndex];
  }

  get markedEl(): HTMLElement {
    if (this.marked) return this.filterToEl(this.marked);
  }

  get markedLine() {
    return this.getItemLine(this._markedIndex);
  }

  get length() {
    return this.list.length;
  }

  get items(): Array<FilterType | ElementRef> {
    return this.list.toArray();
  }

  get isMarkedFirstLine() {
    return this.markedLine === 0;
  }

  get isMarkedLastLine() {
    return this.markedLine === this.lines - 1;
  }

  get lines(): number {
    return uniq(this.items.map((a) => this.filterToEl(a).getBoundingClientRect()?.y).filter((a) => !!a)).length;
  }

  private getItemLine(index: number) {
    let prev = this.lineIndices[0];
    let line = 0;
    for (const next of this.lineIndices.slice(1)) {
      if (index >= prev && index < next) {
        return line;
      }
      line++;
      prev = next;
    }
    return this.lineIndices.length - 1;
  }

  constructor(private list: QueryList<FilterType | ElementRef>, private elementRef = null, private indexSort: string = null) {
    this.subscribeToChanges();
    this.initList();
    this.updateScreen.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res) {
        this.updateDomWithMarkedIndex();
      }
    });
  }

  private initList() {
    this.lineIndices = [];
    let height = -1;
    let index = 0;
    for (const item of this.items) {
      const elm = this.filterToEl(item);
      if (elm) {
        const { top, bottom } = elm.getBoundingClientRect();
        if (top > height) {
          this.lineIndices.push(index);
        }
        index++;
        height = Math.max(height, bottom);
      }
    }
  }

  unmark() {
    this._markedIndex = null;
    this.updateScreen.next(this.canUpdateScreen);
  }

  mark(event: BrowseEvent) {
    if (!this.length) {
      this._markedIndex = null;
      return;
    }

    if (event === 'first') {
      this._markedIndex = 0;
      this.updateScreen.next(this.canUpdateScreen);
      return;
    }

    if (event === 'last') {
      this._markedIndex = this.length - 1;
      this.updateScreen.next(this.canUpdateScreen);
      return;
    }

    const targetIndex = this.eventToIndex(event);

    if (targetIndex >= this.length) {
      this.mark('first');
      return;
    }

    if (targetIndex < 0) {
      this.mark('last');
      return;
    }

    this._markedIndex = targetIndex;
    this.updateScreen.next(this.canUpdateScreen);
  }

  /** Keeps our list in the same order as it rendered on the dom */
  private subscribeToChanges() {
    try {
      this.list.notifyOnChanges();
    } catch (e) {
      // sometimes if the list was already destroyed this throws 'object unsubscribed' issue
      return;
    }
    this.list.changes
      .pipe(
        untilDestroyed(this),
        map((filters) =>
          filters
            .toArray()
            .filter((f) => !!f)
            .sort((a, b) => this.indexOfFilter(a) - this.indexOfFilter(b))
        )
      )
      .subscribe((items) => {
        this.list.reset(items);
        this.updateScreen.next(this.canUpdateScreen);
        this.initList();
      });
  }

  reset(items) {
    this.list.reset(items);
  }

  private filterToEl(filter: FilterType | ElementRef): HTMLElement {
    if (this.elementRef) return filter[this.elementRef];
    if ((filter as FilterType)?.ref?.nativeElement) return (filter as FilterType).ref.nativeElement;
    else if (filter instanceof ElementRef) return filter.nativeElement;
  }

  private indexOfFilter(filter: FilterType) {
    if (this.indexSort) {
      return this.getNestedSortIndex(filter);
    }
    const el: HTMLElement = this.filterToEl(filter);
    if (!el?.parentElement) return;
    return [...[].slice.call(el.parentElement.children)].indexOf(el);
  }

  private getNestedSortIndex(filter: FilterType): number {
    let res: FilterType = filter;
    for (const p of this.indexSort.split(this.SORT_SPLIT_CHAR)) {
      res = res?.[p];
    }
    return Number(res);
  }

  private updateDomWithMarkedIndex() {
    const realMarkedIndex = this.alternativeMarkedIndex ?? this.markedIndex;
    for (const [i, filter] of this.items.entries()) {
      const el: HTMLElement = this.filterToEl(filter);
      if (i !== realMarkedIndex || realMarkedIndex === null) {
        el?.classList.remove('marked');
      } else if (realMarkedIndex !== null) {
        el?.classList.add('marked');
      }
    }
  }

  private eventToIndex(event: BrowseEvent): number {
    const line = this.markedLine;
    const itemNumberInLine = this.markedIndex - this.lineIndices[line];
    const isFirstLine = line === 0;
    const isLastLine = this.isMarkedLastLine;
    switch (event) {
      case 'first':
        return 0;
      case 'last':
        return this.length;
      case 'next':
        return ++this._markedIndex;
      case 'prev':
        return --this._markedIndex;
      case 'line-up':
        if (isFirstLine) {
          return this._markedIndex;
        }
        const prevLineIndex = this.lineIndices[line - 1];
        return Math.min(prevLineIndex + itemNumberInLine, this.lineIndices[line] - 1);
      case 'line-below':
        if (isLastLine) {
          return this._markedIndex;
        }
        const nextLineIndex = this.lineIndices[line + 1];
        const next = nextLineIndex + itemNumberInLine;
        return Math.min(next, this.items.length - 1);
      default: {
        if (Number.isInteger(event as any)) {
          return event;
        }
      }
    }
  }
}
