import { Injectable } from '@angular/core';
import { Search } from '@local/client-contracts';

@Injectable({
  providedIn: 'root',
})
export class AnswerResourcesService {
  private _resources: { [id: string]: Search.ResultResourceItem } = {};

  set resources(value: Search.ResultResourceItem[]) {
    this._resources = value.reduce(
      (dict, item) => {
        dict[item.resource.id] = item;
        return dict;
      },
      {} as { [id: string]: Search.ResultResourceItem }
    );
  }

  getResourceById(id: string) {
    return this._resources?.[id];
  }

  clearResources() {
    this._resources = {};
  }
}
