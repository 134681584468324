import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { isIconEmoji } from '@local/ts-infra';
import { HighlightStyle } from '@shared/pipes';
import { Tag } from '@local/client-contracts/src/links';

export type LinkTagView = 'tag' | 'text';
@Component({
  selector: 'link-tag',
  templateUrl: './link-tag.component.html',
  styleUrls: ['./link-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkTagComponent {
  private _tag: Tag;
  private _viewMode: LinkTagView = 'text';

  highlightStyle: HighlightStyle = 'default';
  maxCharsCount = 18;

  @Input() set tag(value: Tag) {
    this._tag = this.buildTag(value);
  }
  @Input() set viewMode(value: LinkTagView) {
    this._viewMode = value;
    if (this.viewMode === 'tag') {
      this.maxCharsCount = 10;
    }
    this.cdr.markForCheck();
  }
  @Input() markedText: string[];

  get tag(): Tag {
    return this._tag;
  }

  get viewMode(): LinkTagView {
    return this._viewMode;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  private buildTag(tag: Tag): Tag {
    const pathTag = tag?.path;

    if (!pathTag?.length || pathTag.length <= 3) {
      return this.buildTooltip(tag);
    }

    const tooltip = pathTag.map((p) => p.text).join(' / ');
    const shortenedPath = [pathTag[0], { text: '...', tooltip }, pathTag[pathTag.length - 1]];

    return this.buildTooltip({ ...tag, path: shortenedPath });
  }

  private buildTooltip(tag: Tag): Tag {
    return {
      ...tag,
      tooltip: tag.tooltip || tag.text,
      path: tag?.path?.map((p) => {
        return { ...p, tooltip: p['tooltip'] || p.text };
      }),
    };
  }

  isEmoji(icon: string): boolean {
    return isIconEmoji(icon);
  }
}
