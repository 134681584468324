<div class="wiki-item-selection-background">
  <div class="wiki-item-selection-wrapper">
    <div class="container">
      <a *ngIf="!data.styleOptions?.disableCloseButton" class="dismiss-button" (click)="onCloseClick()">
        <u-icon class="close-button" [model]="{ type: 'font', value: 'icon-times-large' }"></u-icon>
      </a>
      <header>
        <h3>{{ data?.title }}</h3>
      </header>
      <ng-container *ngIf="data?.viewMode === 'move'">
        <div *ngIf="data?.warningMessage" class="warning">
          <div class="warning-icon"><div class="icon">!</div></div>
          <div class="warning-message">{{ data?.warningMessage }}</div>
        </div>
        <div class="path">
          <span class="breadcrumbs-text">Current:</span
          ><breadcrumbs [showBreadcrumbsItems]="true" [popupModeItems]="breadcrumbsItems"></breadcrumbs>
        </div>
      </ng-container>
      <div class="content">
        <div class="auto-complete-container">
          <p class="description">{{ data?.description }}</p>
          <div class="auto-complete">
            <u-auto-complete
              field="name"
              [placeholder]="'Select Wiki'"
              [suggestionItems]="suggestionWikis"
              (onChange)="onSelectWikiItem($event)"
              [autoFocus]="true"
              [expandOnInputClick]="true"
              [showArrow]="data?.autoCompleteSettings?.showArrow"
              [selectedItems]="selectedWikiItem"
              [width]="'432px'"
              [closeOnChange]="false"
              [displayDismissButton]="data?.autoCompleteSettings?.displayDismissButton"
              [displayCreateNew]="data?.autoCompleteSettings?.displayCreateNew"
              [createNewOption]="data?.autoCompleteSettings?.createNewOption"
            >
              <ng-template let-item pTemplate="item">
                <div
                  *ngIf="!item.isNew"
                  class="auto-complete-select-item"
                  (click)="item.name === 'No results' ? $event.stopPropagation() : null"
                >
                  <u-emoji *ngIf="!!item.collection?.emoji" [unicode]="item.collection?.emoji"></u-emoji>
                  <u-icon
                    class="auto-complete-item-icon"
                    *ngIf="!item.collection?.emoji && data?.autoCompleteSettings?.allowIconInViewList && item.icon"
                    [model]="{ type: 'font', value: item.icon }"
                    [style]="{ fontSize: '12px', marginRight: '4px', color: item.iconColor }"
                  ></u-icon>
                  <span class="title">{{ item.name }}{{ currentWikiId && item.id === currentWikiId ? ' (current)' : '' }}</span>
                  <span *ngIf="item.collection && !item.isNew" class="resources-count"
                    >{{ item.itemsNumber === 10000 ? '10,000+' : (item.itemsNumber ?? 0) }} {{ 'cards' }}</span
                  >
                  <avatar-list class="avatar-list" [model]="item.avatarList" [spreadAvatarsCount]="3" [tooltipLimit]="10"></avatar-list>
                </div>
              </ng-template>
            </u-auto-complete>
          </div>
        </div>
        <div class="auto-complete-container">
          <p class="description">{{ data?.secondDescription }}</p>
          <div class="auto-complete">
            <u-auto-complete
              #uAutoCompleteFolder
              field="name"
              [placeholder]="'Select Folder'"
              [suggestionItems]="suggestionFolders"
              (onChange)="onSelectFolderItem($event)"
              [expandOnInputClick]="true"
              [showArrow]="data?.autoCompleteSettings?.showArrow && selectedWikiItem"
              [removeSelected]="data?.autoCompleteSettings?.removeSelected"
              [selectedItems]="selectedFolderItem"
              [width]="'432px'"
              [closeOnChange]="false"
              [disabled]="!selectedWikiItem"
              [displayDismissButton]="data?.autoCompleteSettings?.displayDismissButton"
              [displayCreateNew]="data?.autoCompleteSettings?.displayCreateNew"
              [createNewOption]="data?.autoCompleteSettings?.createNewOption"
            >
              <ng-template let-item pTemplate="item">
                <div *ngIf="!item.isNew" class="auto-complete-select-item" [ngClass]="{ 'item-disable': item.disable }">
                  <u-icon
                    *ngIf="item.icon"
                    class="auto-complete-item-icon"
                    [model]="{ type: 'font', value: item.icon }"
                    [style]="{ fontSize: '12px', marginRight: '4px', color: item.iconColor }"
                  ></u-icon>
                  <span class="title">{{ item.name }}{{ currentFolderId && item.id === currentFolderId ? ' (current)' : '' }}</span>
                  <span class="resources-count">{{ item.itemsNumber === 10000 ? '10,000+' : (item.itemsNumber ?? 0) }} cards</span>
                  <u-icon
                    *ngIf="item.disable"
                    class="icon-exclamation-mark"
                    [model]="{ type: 'font', value: 'icon-exclamation-mark-circle' }"
                    [styles]="{ fontSize: '16px', color: 'var(--yellow-600)' }"
                    [pTooltip]="'Folder limit reached'"
                    tooltipStyleClass="u-tooltip"
                    tooltipPosition="left"
                  ></u-icon>
                </div>
              </ng-template>
            </u-auto-complete>
          </div>
        </div>
      </div>

      <div class="buttons-container">
        <u-button
          id="cancel"
          label="Cancel"
          class="cancel"
          type="secondary"
          [disabled]="data?.styleOptions?.disableCloseButton"
          [styles]="{ width: '96px', height: '32px' }"
          (onClick)="onCloseClick()"
        >
        </u-button>
        <u-button
          class="add-button"
          id="add"
          [label]="data?.buttonText"
          type="primary"
          [disabled]="disableMoveButton"
          [styles]="{ width: '96px', height: '32px' }"
          (onClick)="onPrimaryClick()"
          [loading]="loadingSaveButton"
        >
        </u-button>
      </div>
    </div>
  </div>
</div>
