import { ConnectedPosition } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { Collections, Permissions, Wiki } from '@local/client-contracts';
import { observable } from '@local/common';
import { generateId } from '@local/common-web';
import { PopupRef, PopupService } from '@local/ui-infra';
import { LogService } from '@shared/services';
import { RouterService } from '@shared/services/router.service';
import { Observable, Subject, filter, take } from 'rxjs';
import { SelectedItemPopupData } from 'src/app/bar/components/selected-item-popup/models/select-item-base';
import { SelectedItem } from 'src/app/bar/components/selected-item-popup/selected-item-popup.component';
import { CollectionsUtilService } from 'src/app/bar/services/collections-util.service';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { ShowToasterService } from 'src/app/bar/services/show-toaster.service';
import { WikiCardsService } from 'src/app/bar/services/wikis/wiki-cards.service';
import { WikisService } from 'src/app/bar/services/wikis/wikis.service';
import { WikiItemMoveToPopupComponent } from '../components/wiki-item-move-to-popup/wiki-item-move-to-popup.component';
import { WikiCardSaveFlowHandler } from '../helpers/wiki/wiki-card-save-flow-handler';
import { removeAllAttachments } from '../helpers/wiki/wiki-card-utils';
import { wikiContent } from '../helpers/wiki/wiki.content';
import { WikiCardFileService } from './wiki-card-file.service';
import { PreviewService, DRAFT_CARD_URL_PARAM, NEW_CARD_URL_PARAM } from 'src/app/bar/services/preview.service';
import { Logger } from '@unleash-tech/js-logger';
import { WikiDraftsService } from 'src/app/bar/services/wikis/wiki-drafts.service';
import { SessionService } from '@shared/services/session.service';
import { ActivatedRoute } from '@angular/router';

export type TypeView = 'move' | 'create-card' | 'duplicate-card';
@Injectable({
  providedIn: 'root',
})
export class WikiItemSelectionPopupService {
  private readonly NEW_CARD_URL = 'a/new';
  private readonly SETUP_DATA: SelectedItemPopupData = {
    secondDescription: wikiContent.addToWikiPopupMessageOptional,
    warningMessage: wikiContent.moveToWikiWarningMessage,
  };
  private _popupRef: PopupRef<WikiItemMoveToPopupComponent, SelectedItemPopupData>;
  private data: SelectedItemPopupData;
  private _destroySelectPopup$ = new Subject<{ processId: string; newPath?: Wiki.Path[]; newId?: string }>();
  private logger: Logger;
  private accountId: string;

  @observable
  get destroySelectPopup$(): Observable<{ processId: string; newPath?: Wiki.Path[]; newId?: string }> {
    return this._destroySelectPopup$;
  }

  get popupRef() {
    return this._popupRef;
  }

  constructor(
    protected popupService: PopupService,
    private collectionsService: CollectionsService,
    private wikisService: WikisService,
    private showToasterService: ShowToasterService,
    private wikiCardsService: WikiCardsService,
    private routerService: RouterService,
    private collectionsHelperService: CollectionsUtilService,
    private wikiCardFileService: WikiCardFileService,
    private previewService: PreviewService,
    private wikiCardSaveFlowHandler: WikiCardSaveFlowHandler,
    private wikiDraftService: WikiDraftsService,
    private sessionService: SessionService,
    private activeRoute: ActivatedRoute,
    logger: LogService
  ) {
    this.initOpenSelectPopupSub();
    this.logger = logger.scope('WikiItemSelectionPopupService');
    this.sessionService.current$.subscribe((sessionInfo) => {
      this.accountId = sessionInfo?.workspace?.accountId;
    });
  }

  private initOpenSelectPopupSub() {
    this.collectionsService.openSelectPopup$.pipe(filter((r) => r.type === 'move-to-wiki-popup')).subscribe(async (res) => {
      this.openWikiItemSelectionPopup('move', res.id, res.options.data.context);
    });
  }

  protected getPopupDataOptions(typeView: TypeView): SelectedItemPopupData {
    return {
      ...this.SETUP_DATA,
      description: typeView === 'move' ? wikiContent.moveToWikiPopupMessage : wikiContent.addToWikiPopupMessage,
      autoCompleteSettings: {
        showArrow: true,
        allowIconInViewList: true,
        displayDismissButton: true,
        displayCreateNew: true,
        createNewOption: true,
      },
    };
  }

  openWikiItemSelectionPopup(typeView: TypeView, processId?: string, data?: SelectedItemPopupData, openItem = true) {
    if (this.popupRef) {
      this._popupRef.destroy();
    }

    this.data = data;
    let newPath: Wiki.Path[];
    let newId: string;

    const position: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' }];
    this._popupRef = this.popupService.open<WikiItemMoveToPopupComponent, SelectedItemPopupData>(
      'center',
      WikiItemMoveToPopupComponent,
      {
        allowPrimaryClick: true,
        ...data,
        ...this.getPopupDataOptions(typeView),
        title: this.popupTitleByView(typeView),
        viewMode: typeView === 'move' ? typeView : 'create',
        buttonText: this.buttonTextByView(typeView),
        autoCompleteSettings: { ...this.getPopupDataOptions(typeView).autoCompleteSettings, ...data?.autoCompleteSettings },
      },
      { closeOnClickOut: true, position, backdropStyle: 'blur-2' }
    );
    this.popupRef.compInstance.primaryButton.subscribe(async (value) => {
      const { path: initialPath, id } = await this.onPrimaryButtonClick(typeView, value, openItem);
      newPath = initialPath;
      newId = id;
      this._popupRef?.destroy();
    });
    this.popupRef.destroy$.pipe(take(1)).subscribe(() => {
      this._popupRef = null;
      this._destroySelectPopup$.next({ processId, newPath, newId });
      const purl = this.routerService.queryParams['purl'];
      if (purl && purl === this.NEW_CARD_URL) {
        this.routerService.removeQueryParam('purl', true);
      }
    });
    return this.popupRef;
  }

  private popupTitleByView(typeView: TypeView): string {
    switch (typeView) {
      case 'create-card':
      case 'duplicate-card':
        return 'Create card';
      case 'move':
        return 'Move to...';
    }
  }

  private buttonTextByView(typeView: TypeView): string {
    switch (typeView) {
      case 'create-card':
      case 'duplicate-card':
        return 'Create';
      case 'move':
        return 'Move';
    }
  }

  duplicateCard(item: Wiki.Card) {
    const wiki = this.collectionsService.getCollectionById(item.collectionId);
    const canEdit = this.collectionsHelperService.canEdit(wiki);
    this.openWikiItemSelectionPopup('duplicate-card', null, { item, autoCompleteSettings: { showSelected: canEdit } });
  }

  private async onPrimaryButtonClick(typeView: TypeView, value, openItem: boolean): Promise<{ path: Wiki.Path[]; id?: string }> {
    try {
      let isNewParent: boolean;
      if (value.selectedWiki?.isNew) {
        value.selectedWiki = await this.createNewWiki(value.selectedWiki.name?.trim());
        isNewParent = true;
      }
      if (value.selectedFolder?.isNew) {
        value.selectedFolder = (await this.createNewFolder(value.selectedFolder.name?.trim(), value.selectedWiki.id))?.folder;
        isNewParent = true;
      }
      const newParent = value.selectedFolder ? value.selectedFolder : value.selectedWiki;
      let currentId = '';
      switch (typeView) {
        case 'move':
          currentId = await this.moveCard(newParent.id);
          break;
        case 'create-card':
          currentId = await this.createCard(value.selectedWiki.id, newParent.id, openItem);
          break;
        case 'duplicate-card':
          currentId = await this.duplicate({ ...this.data.item, collectionId: value.selectedWiki.id }, newParent);
          break;
      }
      const prevPath = (isNewParent ? [{ name: value.selectedWiki?.name, id: value.selectedWiki?.id }] : newParent.path) || [];
      return { path: [...prevPath, { id: newParent.id, name: newParent.name || newParent.title }], id: currentId };
    } catch (error) {
      this.logger.error('error in wiki selection popup', error);
      this.displayErrorToaster();
      return { path: [], id: '' };
    }
  }

  private async createNewFolder(folderTitle: string, wikiId: string): Promise<Wiki.CreateFolderResponse> {
    return this.wikisService.createFolder({ wikiId, title: folderTitle });
  }

  private async createNewWiki(wikiTitle: string): Promise<Collections.Collection> {
    return this.collectionsService.createCollection({ kind: 'Wiki', title: wikiTitle, id: generateId() }, false);
  }

  private async moveCard(newParentId: string): Promise<string> {
    await this.wikisService.moveItem({
      itemId: this.data?.item?.id,
      prevParentId: this.data.item?.path[this.data.item?.path.length - 1].id,
      newParentId: newParentId,
    });
    this.displayToaster();
    return this.data?.item?.id;
  }

  private async createCard(wikiId: string, newParentId: string, openItem: boolean): Promise<string> {
    const { id, title } = await this.wikiCardSaveFlowHandler.saveCardNewMode({ ...this.data?.item, collectionId: wikiId }, newParentId);
    if (openItem) {
      this.previewService.setPreviewState(
        'popup',
        0,
        {
          type: 'result',
          filterType: 'wiki-local',
          id,
          view: { title: { text: title }, icon: null },
          action: { type: 'wiki card' },
          source: 'wiki-drafts',
        },
        { [NEW_CARD_URL_PARAM]: true }
      );
    }
    return id;
  }

  private async duplicate(item, newParent: SelectedItem): Promise<string> {
    const card = await this.wikiCardsService.getCard(item?.id, false);
    const collectionId = item.collectionId;
    const { title, content, contentType, contentText, attachments, tags } = card;
    const duplicated = {
      collectionId,
      title: `Copy of ${title}`,
      content: attachments?.length ? null : content,
      contentType,
      contentText,
      tags,
    };
    const { id: newCardId, title: newTitle } = await this.wikiCardSaveFlowHandler.saveCardNewMode(duplicated, newParent.id);

    if (attachments?.length) {
      //Update after creation because the following to cardId
      const shareOptions: Permissions.ShareOptions = { level: 'Following', followingIds: [newCardId] };
      const {
        content: updatedContent,
        attachments: updatedAttachments,
        status,
      } = await this.wikiCardFileService.duplicateAttachments(content, attachments, shareOptions);

      const updatedDraft: Wiki.Draft = this.wikiCardSaveFlowHandler.getDraft(
        {
          attachments: status === 'failed' ? [] : updatedAttachments,
          content: status === 'failed' ? removeAllAttachments(content) : updatedContent,
          contentText,
          title: newTitle,
        },
        this.accountId
      );

      await this.wikiDraftService.update({ draft: updatedDraft, cardId: newCardId });
    }

    const isFullPage = this.activeRoute.snapshot?.params?.param === this.wikiCardsService.FULL_PAGE_CARD_PARAM;

    if (isFullPage) {
      const url = this.wikiCardsService.getCardUrl(newTitle, newCardId);
      this.routerService.navigateByUrl(`${url}?${DRAFT_CARD_URL_PARAM}=true`);
    } else {
      this.previewService.setPreviewState(
        'popup',
        0,
        {
          type: 'result',
          filterType: 'wiki-local',
          id: newCardId,
          view: { title: { text: newTitle }, icon: null },
          action: { type: 'wiki card' },
          source: 'wiki-drafts',
        },
        { [NEW_CARD_URL_PARAM]: true }
      );
    }

    return newCardId;
  }

  private displayErrorToaster() {
    this.showToasterService.showToaster({
      id: 'save-error',
      title: 'Oops.. something went wrong. Please try again! ',
      icon: { type: 'font', value: 'icon-duo-exclamation-circle' },
      iconIntent: 'danger',
    });
  }

  private displayToaster() {
    const item = this.data?.item;
    const itemType = item.type === 'folder' ? 'Folder' : 'Card';
    const name = item?.view?.title?.text || item?.title || '';
    this.showToasterService.showToaster({
      id: 'move',
      content: `${itemType} "${name}" was moved successfully`,
      intent: 'primary',
      icon: { type: 'font', value: 'icon-check-circle' },
      iconIntent: 'success',
    });
  }
}
