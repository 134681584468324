import { Injectable } from '@angular/core';
import { Commands, Links } from '@local/client-contracts';
import { observable, ValueStorage } from '@local/common';
import { SessionService } from '@shared/services/session.service';
import { cloneDeep } from 'lodash';
import { map, Observable, ReplaySubject } from 'rxjs';
import { LinksRpcInvoker } from './invokers/links.rpc-invoker';
import { ServicesRpcService } from './rpc.service';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LinksService implements Links.Service {
  private service: Links.Service;
  links: Record<string, Links.DisplayItem>;
  private visLinks$: ReplaySubject<Links.DisplayItem[]> = new ReplaySubject(1);
  private storageOpenWith: ValueStorage<Links.OpenWith>;
  private readonly STORAGE_KEY = 'linksOpenWith';
  private openWithPerLink: Links.OpenWith = {};
  private _peopleLinkId$: ReplaySubject<string> = new ReplaySubject(1);

  private _all$: ReplaySubject<Links.DisplayItem[]> = new ReplaySubject(1);

  get peopleLinkId$(): Observable<string> {
    return this._peopleLinkId$.asObservable();
  }

  constructor(services: ServicesRpcService, private sessionStorageService: SessionStorageService, private sessionService: SessionService) {
    this.service = services.invokeWith(LinksRpcInvoker, 'linkspage');
    this.service.visible$.subscribe((x) => this.visLinks$.next(this.mergeLocalDataPerLink(x)));
    this.service.all$.subscribe((links) => {
      this.all = links;
    });
    this.all$.subscribe(
      (l) =>
        (this.links = l.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {}))
    );

    this.service.peopleLinkId$.subscribe((id) => this._peopleLinkId$.next(id));
    this.initStorage();
  }

  private initStorage() {
    this.sessionService.current$.subscribe(async (value) => {
      if (value) {
        this.storageOpenWith = this.sessionStorageService
          .getStore('roaming', 'account')
          .entry<Links.OpenWith>(this.STORAGE_KEY);
        this.storageOpenWith.current$.subscribe(async (res) => {
          this.openWithPerLink = cloneDeep(res || {});
        });
      }
    });
  }

  private mergeLocalDataPerLink(links: Links.DisplayItem[]): Links.DisplayItem[] {
    links.forEach((link) => {
      link.preferences.openWith = this.openWithPerLink[link.id];
    });
    return links;
  }

  async updateOpenWith(linkId: string, openWith: Commands.OpenWith) {
    this.openWithPerLink[linkId] = openWith;
    await this.storageOpenWith.set(this.openWithPerLink);
  }

  one$(linkId: string): Observable<Links.DisplayItem> {
    return this.service.one$(linkId).pipe(map((link) => this.mergeLocalDataPerLink([link])[0]));
  }

  async one(linkId: string): Promise<Links.DisplayItem> {
    return this.mergeLocalDataPerLink([await this.service.one(linkId)])[0];
  }

  getOpenWith(linkId: string): Commands.OpenWith {
    return this.openWithPerLink[linkId];
  }

  byApp$(appId: string): Observable<Links.DisplayItem[]> {
    return this.service.byApp$(appId).pipe(map((links) => this.mergeLocalDataPerLink(links)));
  }

  @observable
  get all$(): Observable<Links.DisplayItem[]> {
    return this._all$.pipe(map((links) => this.mergeLocalDataPerLink(links)));
  }

  set all(value: Links.DisplayItem[]) {
    this._all$.next(value);
  }

  @observable
  get visible$(): Observable<Links.DisplayItem[]> {
    return this.visLinks$;
  }

  create(createLink: Links.CreateLink<any, any>): Promise<Links.CreateResult> {
    if (!createLink?.key) {
      throw new Error("'key' member is mandatory to create link.");
    }
    if (!createLink?.name) {
      throw new Error("'name' member is mandatory to create link.");
    }

    if (!createLink.secrets) {
      createLink.secrets = {};
    }
    if (!createLink.settings) {
      createLink.settings = {};
    }

    return this.service.create(createLink);
  }

  async update(linkId: string, payload: Partial<Links.Link<any, any>>): Promise<Links.DisplayItem> {
    const link = await this.service.update(linkId, payload);
    return this.mergeLocalDataPerLink([link])[0];
  }

  delete(id: string): Promise<void> {
    return this.service.delete(id);
  }

  refresh(linkId: string, createLink: Links.CreateLink<any, any>): Promise<void> {
    return this.service.refresh(linkId, createLink);
  }

  sync(): Promise<void> {
    //TODO: fix
    return;
  }

  refreshAll(): Promise<void> {
    return this.service.refreshAll();
  }
}
