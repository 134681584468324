import { UiIconModel } from '@local/ui-infra';
import { Constants } from '@local/common';
import { isMac } from '@local/common-web';

export interface WalkThroughStep {
  icon?: {
    model: UiIconModel;
    size: 'regular' | 'small';
  };
  comment?: string;
  title: string;
  subTitle?: {
    section1: string;
    section2?: string;
    showShortcut?: boolean;
    link?: { text: string; url: string };
  };
  action?: { title?: string; link?: { text: string; url: string; icon?: UiIconModel }; subTitle?: string };
  telemetryLabel: string;
}

const isMacState = isMac();

export const STEPS: WalkThroughStep[] = [
  {
    title: 'Welcome to Unleash',
    subTitle: { section1: 'Your super tool to search for anything on your cloud, computer and anywhere else.' },
    icon: {
      model: {
        type: 'img',
        value: { lightUrl: 'assets/walkthrough/steps/step1_light.svg', darkUrl: 'assets/walkthrough/steps/step1_dark.svg' },
      },
      size: 'regular',
    },
    telemetryLabel: 'welcome',
  },
  {
    title: 'What can I find with Unleash?',
    subTitle: {
      section1: `Your pages in Notion and Confluence, Cases in Salesforce, Files in Google Drive, PRs in Github, Resources in AWS and basically everything - <br> All from one place.`,
    },
    icon: {
      model: {
        type: 'img',
        value: { lightUrl: 'assets/walkthrough/steps/step2.svg' },
      },
      size: 'regular',
    },
    telemetryLabel: 'find_with_unleash',
  },
  {
    title: 'Advanced Filters',
    subTitle: {
      section1: 'Want to see all tasks that are assigned to you in the current sprint? Add filters to refine your search.',
    },
    action: { title: 'PRO TIP:', subTitle: `Hit <b>;</b> in the Search Page to add filters` },
    icon: {
      model: {
        type: 'img',
        value: { lightUrl: 'assets/walkthrough/steps/step3.svg' },
      },
      size: 'regular',
    },
    telemetryLabel: 'filters',
  },
  {
    title: 'Personalized Homepage',
    subTitle: {
      section1:
        'All the information you need on hand - Unleash search bar, upcoming meetings, items you recently interacted with and items marked as favorites. ',
    },
    icon: {
      model: {
        type: 'img',
        value: { lightUrl: 'assets/walkthrough/steps/step4.svg' },
      },
      size: 'small',
    },
    action: {
      title: 'PRO TIP:',
      subTitle: 'Set Unleash as your browser’s default for new tabs by installing the',
      link: { text: 'Browser Extension', url: Constants.DOWNLOAD_EXTENSION },
    },
    telemetryLabel: 'personalized_hp',
  },
  {
    title: 'Search from anywhere on the web',
    action: {
      link: {
        text: 'Requires the Browser Extension',
        url: Constants.DOWNLOAD_EXTENSION,
      },
    },
    subTitle: {
      section1: `Click on <span class="primary-subtitle">Unleash Dial</span> or hit`,
      section2: 'to pull-up the Unleash Launcher and search for resources across your tools without disrupting your workflow.',
      showShortcut: true,
    },
    icon: {
      model: {
        type: 'img',
        value: { lightUrl: isMacState ? 'assets/walkthrough/steps/step5_mac.svg' : 'assets/walkthrough/steps/step5_windows.svg' },
      },
      size: 'regular',
    },
    telemetryLabel: 'search',
  },
  {
    title: 'Search from anywhere on your desktop',
    subTitle: {
      section1: 'Find documents in the cloud and on your local PC and access them faster with Unleash desktop app’s quick launcher. ',
      link: {
        text: ' Learn more',
        url: Constants.LOCAL_SEARCH_GUIDE_URL,
      },
    },
    action: {
      link: {
        text: 'Download Desktop App',
        url: Constants.DOWNLOAD_DEKSTOP_APP,
      },
    },
    icon: {
      model: {
        type: 'img',
        value: { lightUrl: 'assets/walkthrough/steps/step6.png' },
      },
      size: 'regular',
    },
    telemetryLabel: 'local_search',
  },
  {
    comment: 'Before you go...',
    title: 'Connect more apps',
    subTitle: {
      section1:
        'You can connect additional apps at any time by visiting the apps page, selecting from the available apps and connecting your account.',
    },
    icon: {
      model: {
        type: 'img',
        value: { lightUrl: 'assets/walkthrough/steps/step7.svg' },
      },
      size: 'small',
    },
    telemetryLabel: 'connect_apps',
  },
  {
    comment: 'Before you go...',
    title: 'Join Unleash Community',
    subTitle: {
      section1:
        'Be the first to know about new releases, request features & integrations, ask questions, and stay updated on all things Unleash!',
    },
    action: {
      link: {
        text: 'Join Slack Community',
        url: 'https://join.slack.com/t/unleashsocommunity/shared_invite/zt-mn213lhc-lrRupDAsA_EqiswAAbB0~w',
        icon: {
          type: 'img',
          value: { lightUrl: 'assets/walkthrough/primary_slack.svg' },
        },
      },
    },
    icon: {
      model: {
        type: 'img',
        value: { lightUrl: 'assets/walkthrough/steps/step8_light.svg', darkUrl: 'assets/walkthrough/steps/step8_dark.svg' },
      },
      size: 'small',
    },
    telemetryLabel: 'join_community',
  },
];
