import { Component, Input } from '@angular/core';
import { AvatarItemModel } from 'src/app/bar/models/avatar-item.model';

@Component({
  selector: 'avatar-list-tooltip',
  templateUrl: './avatar-list-tooltip.component.html',
  styleUrls: ['./avatar-list-tooltip.component.scss'],
})
export class AvatarListTooltipComponent {
  @Input() items: AvatarItemModel[] = [];
  @Input() header: string;
  @Input() footer: string;
  @Input() sizeAvatarTooltip: string = '20px';
}
