import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceHtmlEncode',
})
export class SpaceHtmlEncodePipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    return value?.replaceAll(' ', '\u00A0');
  }
}
