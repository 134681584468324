import { NgZone } from '@angular/core';
import { LinkSettings, SessionInfo } from '@local/client-contracts';
import { getRpcLogExtension } from '@local/common';
import { ManifestRpcHandler, SessionRpcHandler } from '@local/common-web';
import { OAuthCompletionRpcHandler } from '@shared/services/oauth-windows/oauth-completions-rpc-handler';
import { Logger } from '@unleash-tech/js-logger';
import { LogRpcHandler, Rpc, RpcObservableExtension, RpcOptions, RpcWebWindowChannel } from '@unleash-tech/js-rpc';
import { StyleRpcInvoker } from './style-rpc-invoker';

export class FrameRpcService {
  private channel: RpcWebWindowChannel;
  private rpc: Rpc;
  public styleInvoker: LinkSettings.StyleService;
  constructor(
    private settings: LinkSettings.Service,
    private oauthCompletion: OAuthCompletionRpcHandler,
    private ngzone: NgZone,
    private window: Window,
    private logger: Logger,
    private sessionInfo: SessionInfo
  ) {
    this.logger = logger.scope('NewLinkRpcService');
  }

  async setChannel(appId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        if (!this.window) throw new Error('No window detected');

        this.ngzone.runOutsideAngular(async () => {
          //@ts-ignore
          this.channel = new RpcWebWindowChannel(this.window, null, location.origin);
          const options: RpcOptions = {};
          this.rpc = new Rpc(this.channel, this.logger, options);

          this.rpc.addExtension(getRpcLogExtension('app-client-frame', 'renderer', this.logger));
          this.rpc.addExtension(new RpcObservableExtension());

          this.rpc.handleWith(this.settings, 'settings');
          this.rpc.handleWith(new ManifestRpcHandler(`./apps/${appId}`), 'manifest');
          this.rpc.handleWith(new SessionRpcHandler(this.sessionInfo), 'session');
          this.rpc.handleWith(this.oauthCompletion, 'oauthCompletion');
          this.styleInvoker = this.rpc.invokeWith(StyleRpcInvoker, 'style');
          this.rpc.handleWith(new LogRpcHandler(this.logger), 'log');
          resolve();
        });
      } catch (err) {
        const errMsg = 'Failed to set rpc channel.';
        this.logger.error(errMsg, { reason: err });
        reject({ msg: errMsg, reason: err });
      }
    });
  }

  destroy() {
    this.rpc.close();
    this.rpc = null;
  }
}
