import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HubService } from 'src/app/bar/services/hub.service';
import { PreviewViewModel } from 'src/app/bar/services/preview.service';
import { PreviewContainerComponent } from '../../../preview/components/preview-container/preview-container.component';
import { SearchResultContext } from 'src/app/bar/services/search';
import { DirtyChangeStatus } from '../../../preview/model/preview-component';
import { InvokeCommand } from '../../models/invoke-command.model';
import { PreviewItem } from '../../../results-views/results/results.component';
import { BehaviorSubject, debounce, distinctUntilChanged, timer } from 'rxjs';
import { PreviewMode } from '../../../preview/model/preview-mode';
import { PreviewType } from '../../models/view-filters';

@UntilDestroy()
@Component({
  selector: 'preview-results',
  templateUrl: './preview-results.component.html',
  styleUrls: ['./preview-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewResultsComponent {
  readonly RESULTS_MIN_SIZE: number = 380;

  selected$ = new BehaviorSubject<PreviewItem[]>(null);
  selectedForPreview$ = this.selected$.asObservable().pipe(
    distinctUntilChanged(),
    debounce(() => timer(200))
  );

  isLauncher: boolean;

  @Input() resultsTemplate: any;
  @Input() smallView = false;
  @Input() scrollHeight: string;
  @Input() displayedContext: SearchResultContext;
  @Input() searchId: string;
  @Input() previewHeight: number;
  @Input() selectedIndex: number;
  @Input() isPreviewMode: boolean;
  @Input() previewWidth: number;
  @Input() maxPreviewWidth: number;
  @Input() minPreviewWidth: number;
  @Input() visiblePreview: boolean;
  @Input() visibleResults: boolean;
  @Input() hiddenPreview: boolean;
  @Input() previewViewModel: PreviewViewModel = {};
  @Input() previewType: PreviewType;
  @Input() set selected(value: PreviewItem[]) {
    this.selected$.next(value);
  }

  @ViewChild('previewSplitArea') previewRef: ElementRef;
  @ViewChild(PreviewContainerComponent) previewContainer: PreviewContainerComponent;

  @Output() invoke = new EventEmitter<InvokeCommand>();
  @Output() invokeDirty = new EventEmitter<void>();
  @Output() previewDragEnd = new EventEmitter<any>();
  @Output() sizeChanged: EventEmitter<PreviewMode> = new EventEmitter<PreviewMode>();

  constructor(private cdr: ChangeDetectorRef, private hubService: HubService) {
    this.hubService.isLauncher$.pipe(untilDestroyed(this)).subscribe((l) => (this.isLauncher = l));
  }

  isDirty() {
    return this.previewContainer?.isDirty();
  }

  onInvokeDirty($event: DirtyChangeStatus) {
    if ($event !== 'open') {
      this.invokeDirty.emit();
      this.previewContainer.clear();
      this.cdr.markForCheck();
    }
  }
}
