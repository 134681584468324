import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { Collections, Results, Wiki } from '@local/client-contracts';
import { isLiveCollection, isStaticCollection, isWikiCollection } from '@local/common-web';
import { DynamicComponentBase, UInputComponent } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventsService } from '@shared/services';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { getModifiers, keyCodes, KeyName } from '@local/ts-infra';
import { windowSizeObserver } from '@shared/utils';
import { BreakpointsWidth, SCREEN_SIZE_MAX_WIDTH } from '@shared/utils/break-point.utils';
import { cloneDeep, isEqual } from 'lodash';
import { debounceTime, distinctUntilChanged, filter, Subject } from 'rxjs';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { NavTreeService } from 'src/app/bar/services/nav-tree.service';
import { SidebarService } from 'src/app/bar/services/sidebar.service';
import { collectionContent } from '../../helpers/collection.content';
import { CreateCollectionType } from '../../models/create-collection.type';
import { CollectionPopupService } from '../../services/collection-popup.service';
import { WikiCardPreviewService } from '../../services/wiki-card-preview.service';
import { CollectionsUtilService } from 'src/app/bar/services/collections-util.service';
import { COLLECTION_TYPE_TO_ICON_MAP } from '../../helpers/collection-type-icon-map';

export enum CollectionsComponentFocused {
  TITLE = 1,
  DESCRIPTION = 2,
  TAGS = 3,
  FILTERS = 4,
  SEARCH = 5,
  RESULTS = 6,
}

type InputWidthMap = {
  [key in BreakpointsWidth]: number;
};
@UntilDestroy()
@Component({
  selector: 'collection-view',
  templateUrl: './collection-view.component.html',
  styleUrls: ['./collection-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionViewComponent implements OnInit, AfterViewInit, OnDestroy, DynamicComponentBase<any> {
  data: any;
  componentFather: any;
  itemsAmount = 0;

  initialCollection: Collections.Collection;

  collectionViewState: CreateCollectionType;
  inputMaxLength = 280;

  // for collection-header-line
  lastSync: number;

  collectionContent = collectionContent;

  // keyboard
  private keysHandlerId: string;
  componentFocused: CollectionsComponentFocused;
  CollectionsComponentFocused = CollectionsComponentFocused;

  formInputChanged = new Subject<{ key: 'title' | 'description'; value: string }>();
  @ViewChild('inputTitle') inputTitle: UInputComponent;
  updateHeight: EventEmitter<void> = new EventEmitter();

  private inputWidth: Partial<InputWidthMap> = {
    small: 150,
    medium: 200,
    large: 250,
  };

  currentState: { title: string; description: string };
  canEdit: boolean;
  loading = true;

  layoutMode: Results.LayoutType = 'list';

  get collectionTypeIconMap() {
    return COLLECTION_TYPE_TO_ICON_MAP;
  }

  get isLiveCollection() {
    return this.collectionsService.currentCollectionView?.kind === 'Live';
  }

  constructor(
    private hubService: HubService,
    private eventsService: EventsService,
    private cdr: ChangeDetectorRef,
    public collectionsService: CollectionsService,
    private keyboardService: KeyboardService,
    private sidebarService: SidebarService,
    private navTreeService: NavTreeService,
    private location: Location,
    public collectionPopupService: CollectionPopupService,
    private wikiCardPreviewService: WikiCardPreviewService,
    private collectionsHelperService: CollectionsUtilService
  ) {}

  ngOnInit(): void {
    this.hubService.clearQueryParams(['mode', 'purl']);
    this.collectionsService.currentCollection$
      .pipe(
        untilDestroyed(this),
        filter((r) => !!r)
      )
      .subscribe((currentCollection) => {
        this.onCurrentCollectionChanged(currentCollection);
        this.loading = false;
        this.hubService.readOnly = true;
      });
    this.sideBarCheck();
    setTimeout(() => {
      this.hubService.readOnly = true;
    }, 0);
    this.setKeysHandler();
    this.setWindowSizeObserver();
  }

  get isAllowOutsideClick() {
    return !this.wikiCardPreviewService.popupRef;
  }

  onCurrentCollectionChanged(currentCollection: Collections.Collection) {
    const prevCollection = this.collectionsService.currentCollectionView;
    this.collectionsService.currentCollectionView = currentCollection;
    this.cdr.detectChanges();
    if (!prevCollection || !isEqual(prevCollection?.id, currentCollection?.id)) {
      this.checkViewState();
      this.onCollectionChanged();
      this.setDefaultView(currentCollection);
    }
    this.canEdit = this.collectionsHelperService.canEdit(this.collectionsService.currentCollectionView);
    this.onUpdateCollection();
    this.cdr.markForCheck();
  }

  private setDefaultView(collection: Collections.Collection) {
    if (this.collectionsService.currentCollectionView.layout) {
      this.layoutMode = this.collectionsService.currentCollectionView.layout;
      return;
    }
    // todo find where setting the searchParams.layoutMode to gallery
    if (collection.searchParams?.layoutMode) {
      this.layoutMode = collection.searchParams.layoutMode;
      return;
    }
    this.layoutMode = 'list';
  }

  setKeysHandler() {
    this.keysHandlerId = this.keyboardService.registerKeyHandler((keys, event) => {
      if (
        this.componentFocused === CollectionsComponentFocused.TITLE ||
        this.componentFocused === CollectionsComponentFocused.DESCRIPTION
      ) {
        this.handleKeys(keys, event);
      }
    }, 7);
  }

  setWindowSizeObserver() {
    windowSizeObserver()
      .pipe(untilDestroyed(this))
      .subscribe(({ width }) => {
        if (width >= SCREEN_SIZE_MAX_WIDTH.large) {
          this.inputMaxLength = this.inputWidth.large;
        } else if (width >= SCREEN_SIZE_MAX_WIDTH.medium) {
          this.inputMaxLength = this.inputWidth.medium;
        } else {
          this.inputMaxLength = this.inputWidth.small;
        }
        this.cdr.markForCheck();
      });
  }

  checkViewState() {
    let newMode = (<any>this.location.getState()).mode;
    if (Array.isArray(newMode)) newMode = newMode[0];

    if (newMode) {
      this.collectionViewState = newMode;
    } else {
      this.collectionViewState = 'edit';
    }
    this.cdr.markForCheck();
  }

  sideBarCheck() {
    this.sidebarService.onNodeClick$.pipe(untilDestroyed(this)).subscribe((nodeClickedId) => {
      if (this.navTreeService.currentNode?.id === nodeClickedId) {
        this.collectionsService.currentCollection$.next(null);
        this.navTreeService.refreshCurrentNodeWithSameValue();
      }
    });
  }

  onCollectionChanged() {
    const collection = this.collectionsService.currentCollectionView;
    if (this.collectionViewState === 'new') {
      this.componentFocused = CollectionsComponentFocused.TITLE;
    } else {
      if (isLiveCollection(collection)) {
        this.componentFocused = collection?.searchParams ? CollectionsComponentFocused.RESULTS : CollectionsComponentFocused.FILTERS;
      } else if (isStaticCollection(collection)) {
        this.componentFocused = (<Collections.StaticCollection>collection)?.items?.length
          ? CollectionsComponentFocused.RESULTS
          : CollectionsComponentFocused.TAGS;
      } else if (isWikiCollection(collection)) {
        this.componentFocused = (<Wiki.WikiCollection>collection)?.cardIds?.length
          ? CollectionsComponentFocused.RESULTS
          : CollectionsComponentFocused.TAGS;
      }
    }
    this.currentState = {
      title: collection.title,
      description: collection.description,
    };
    this.collectionsService.collectionActions = [];
    this.cdr.markForCheck();
    setTimeout(() => {
      if (this.collectionViewState === 'new') {
        this.inputTitle.inputElement?.el.nativeElement.setSelectionRange(0, 0);
        this.inputTitle.model = null;
        this.inputTitle.focusInput();
        this.cdr.detectChanges();
      }
    }, 100);
  }

  onUpdateCollection() {
    if (isLiveCollection(this.collectionsService.currentCollectionView)) {
      this.lastSync = this.collectionsService.currentCollectionView?.lastSyncTime;
    }
    this.initialCollection = cloneDeep(this.collectionsService.currentCollectionView);
  }

  ngOnDestroy(): void {
    this.keyboardService.unregisterKeyHandler(this.keysHandlerId);
    this.collectionsService.currentCollectionView = null;
  }

  ngAfterViewInit(): void {
    this.formInputChanged.pipe(debounceTime(800), distinctUntilChanged(), untilDestroyed(this)).subscribe((input) => {
      if (input.key === 'description') {
        this.updateHeight.emit();
      }
      let value = input.value;
      if (input.key === 'title') {
        value = !input.value ? 'Untitled' : input.value;
      }
      const addInputAction: Collections.UpdateAction = {
        field: input.key,
        type: 'Update',
        value: value,
      };
      this.currentState[input.key] = value;
      const id = this.collectionsService.currentCollectionView.id;
      this.collectionsService.updateCollection(id, [addInputAction], false);
      this.cdr.markForCheck();
    });
  }

  changeFocus(event: CollectionsComponentFocused) {
    const focused = CollectionsComponentFocused[event]?.toLowerCase();
    this.collectionsService.onFocusChange = focused;
  }

  onSelectedEmoji(emoji: EmojiData) {
    const unicode = emoji?.unified;
    const emojiAction: Collections.UpdateAction = {
      field: 'emoji',
      type: 'Update',
      value: unicode,
    };
    this.collectionsService.currentCollectionView = { ...this.collectionsService.currentCollectionView, emoji: unicode };
    const id = this.collectionsService.currentCollectionView.id;
    this.collectionsService.updateCollection(id, [emojiAction], false);

    this.eventsService.event('collections.collection_emoji', {
      location: { title: this.hubService.currentLocation },
      label: emoji ? 'added' : 'remove',
    });
    this.cdr.markForCheck();
  }

  changeComponentFocus($event: CollectionsComponentFocused) {
    this.changeFocus($event);
    this.componentFocused = $event;
    this.sendInputClickEvent($event);
    this.cdr.markForCheck();
  }

  sendInputClickEvent(source: CollectionsComponentFocused) {
    this.eventsService.event(`collections.${CollectionsComponentFocused[source].toLowerCase()}`, {
      location: { title: this.hubService.currentLocation },
    });
  }

  updateLastSync($event) {
    this.lastSync = $event;
    this.cdr.markForCheck();
  }

  onTagChange($event) {
    this.collectionsService.updateCollectionView('tags', $event.tags);
    const id = this.collectionsService.currentCollectionView.id;
    this.collectionsService.updateCollection(id, [$event.action], false);
  }

  //KEY HANDLE SECTION
  //@@@@@@@@@@@@@@@@@@@@@
  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent) {
    const key = keys[0];
    const modifiers = getModifiers(keys);

    switch (key) {
      case keyCodes.enter.toLowerCase():
      case keyCodes.tab.toLowerCase(): {
        if (modifiers?.length === 1 && modifiers[0] === 'shift') {
          this.componentFocusedFinish('prev');
        } else {
          this.componentFocusedFinish('next');
        }
        event.stopPropagation();
        event.preventDefault();
        break;
      }
      default:
    }
  }

  componentFocusedFinish(direction: 'prev' | 'next' | null) {
    const collection = this.collectionsService.currentCollectionView;
    if (!collection) return;
    switch (direction) {
      case 'next':
        // enum length
        if (this.componentFocused < Object.keys(CollectionsComponentFocused).length / 2) {
          this.componentFocused++;
        }
        break;
      case 'prev':
        if (this.componentFocused > 1) {
          this.componentFocused--;
        }
        break;
      default:
        break;
    }
    if (this.componentFocused === CollectionsComponentFocused.FILTERS && isStaticCollection(collection)) {
      this.componentFocused = (<Collections.StaticCollection>collection)?.items?.length
        ? CollectionsComponentFocused.RESULTS
        : CollectionsComponentFocused.SEARCH;
    } else if (this.componentFocused === CollectionsComponentFocused.SEARCH && isLiveCollection(collection)) {
      this.componentFocusedFinish(direction);
    } else if (isWikiCollection(collection) && this.componentFocused === CollectionsComponentFocused.FILTERS) {
      this.componentFocused =
        (<Wiki.WikiCollection>collection)?.cardIds?.length > 0 ? CollectionsComponentFocused.RESULTS : CollectionsComponentFocused.SEARCH;
    }
    this.cdr.detectChanges();
  }

  componentFocusedUpdate($event) {
    this.componentFocused = $event;
    this.changeFocus($event);
    if (this.componentFocused !== CollectionsComponentFocused.TITLE) {
      this.inputTitle?.blurInput();
    }
    this.cdr.detectChanges();
  }

  changeViewMode() {
    this.layoutMode = this.layoutMode === 'list' ? 'gallery' : 'list';
    this.eventsService.event('collections.layout', {
      label: this.layoutMode,
      location: {
        title: this.hubService.currentLocation,
      },
    });
    const action: Collections.UpdateAction = {
      field: 'layout',
      type: 'Update',
      value: this.layoutMode,
    };
    if (this.collectionsHelperService.canEdit(this.collectionsService.currentCollectionView)) {
      this.collectionsService.updateCollection(this.collectionsService.currentCollectionView.id, [action], false);
    }
  }

  get tooltipText(): string {
    const el: HTMLElement = this.inputTitle?.inputElement?.el.nativeElement;
    if (el?.offsetWidth < el?.scrollWidth) {
      return this.currentState?.title;
    }
    return null;
  }
}
