import { Injectable } from '@angular/core';
import { Config } from '@environments/config';
import { observable } from '@local/common';
import { RouterService } from '@shared/services/router.service';
import { cloneDeep, isInteger } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { ComponentFocused } from '../models/component-focused.model';
import { PreviewFocus } from '../models/preview-focus';
import { PageType } from '../views/results/models/view-filters';
import { PreviewMode } from '../views/preview/model/preview-mode';
import { PreviewService } from './preview.service';

@Injectable({
  providedIn: 'root',
})
export class PreviewKeyboardService {
  private _compFocused: ComponentFocused;
  private _previewState: PreviewMode;
  private componentsList: PreviewFocus[] = [PreviewFocus.Results, PreviewFocus.Filters, PreviewFocus.PreviewToggle, PreviewFocus.Preview];
  private _componentFocused$: Subject<ComponentFocused> = new Subject();

  @observable
  get componentFocused$(): Observable<ComponentFocused> {
    return this._componentFocused$.asObservable();
  }

  set previewState(val: PreviewMode) {
    if (this.previewState === val) return;
    this._previewState = val;

    if (this.activePreview) {
      this.setComponentsList();
    }
  }

  get previewState(): PreviewMode {
    return this._previewState;
  }

  get componentFocused(): ComponentFocused {
    return this._compFocused;
  }

  private set componentFocused(value: ComponentFocused) {
    this._componentFocused$.next(cloneDeep(value));
  }

  get activePreview(): boolean {
    return Config.preview?.supportedViews?.includes(this.currentLocation as PageType);
  }

  private get currentLocation(): string {
    const url = this.routerService.activeRoute?.snapshot?.url;
    return url ? url[0]?.path : '';
  }

  constructor(private routerService: RouterService, private previewService: PreviewService) {
    this.init();
    this.previewService.previewModelChange$.subscribe((v) => {
      this.previewState = v.previewState;
    });
  }

  init() {
    this._compFocused = { compId: 0, compIndex: 0, direction: 'first' };
    this.componentFocused = this.componentFocused;

    if (this.activePreview) {
      this.setComponentsList();
    }
  }

  private setComponentsList(compFocused = 0) {
    switch (this.previewState) {
      case 'side':
        this.componentsList = [PreviewFocus.Results, PreviewFocus.Filters, PreviewFocus.PreviewToggle, PreviewFocus.Preview];
        break;
      case 'none':
        this.componentsList = [PreviewFocus.Results, PreviewFocus.Filters, PreviewFocus.PreviewToggle];
        break;
      case 'full':
        this.componentsList = [PreviewFocus.Filters, PreviewFocus.Preview];
        break;
      default:
        this.componentsList = [PreviewFocus.Results, PreviewFocus.Filters];
    }
    this.setComponentFocused(compFocused);
  }

  setComponentFocused(val: any) {
    if (!this.activePreview) return;

    if (!this.componentFocused) {
      this._compFocused = {};
    }
    const listLength: number = this.componentsList.length;
    const compIndex = this.componentsList.findIndex((v) => v === this.componentFocused.compId);
    if (isInteger(val)) {
      this._compFocused = { compIndex: val, compId: this.componentsList[val], direction: 'first' };
    }
    if (val === 'prev') {
      this._compFocused.compId = compIndex === 0 ? this.componentsList[listLength - 1] : this.componentsList[compIndex - 1];
      this._compFocused.direction = 'last';
    }
    if (val === 'next') {
      this._compFocused.compId = compIndex === listLength - 1 ? this.componentsList[0] : this.componentsList[compIndex + 1];
      this._compFocused.direction = 'first';
    }
    this.componentFocused = this.componentFocused;
  }
}
