<div class="avatar-multi-select-filter" [ngClass]="{ disabled: disabled }">
  <ng-container *ngIf="displayedAvatars">
    <ng-container *ngFor="let avatar of displayedAvatars; let last = last; let i = index"
      ><entity-icon
        class="filter-item-icon"
        [ngClass]="{
          disabled: avatar?.disabled,
          last: last && avatar.isMoreAccounts && displayedAvatars.length > DISPLAYED_AVATARS_LIMIT
        }"
        [model]="avatar.icon"
        [size]="'22px'"
        [state]="isItemSelected(avatar) ? 'selected' : avatar.disabled ? 'disabled' : 'default'"
        [pTooltip]="!avatar.isMoreAccounts ? avatar.value : undefined"
        tooltipPosition="bottom"
        tooltipStyleClass="u-tooltip"
        (click)="onAccountClick($event, avatar)"
      ></entity-icon>
    </ng-container>
  </ng-container>

  <u-multi-select
    [hideSelection]="true"
    [placeholder]="model.title"
    [model]="options"
    [multiSelectIcon]="model?.icon"
    [(ngModel)]="selectedItems"
    (onSelectedChange)="onChange($event)"
    (onFilter)="onFilter($event)"
    (onPanelShow)="onPanel(true)"
    (onPanelHide)="onPanel(false)"
    (onClearAllEvent)="onClearAllEvent()"
    optionLabel="value"
    [filterPlaceholder]="model.title + '...'"
    [limitShowSelectedLabels]="2"
    [isNoColorCheckbox]="true"
  >
    <ng-template let-item pTemplate="item">
      <div class="avatar-line">
        <span class="icon-container" *ngIf="item.icon">
          <entity-icon class="filter-item-icon" [model]="item.icon" [size]="'16px'"></entity-icon>
        </span>
        <span class="option-text">
          {{ item.value }}
        </span>
      </div>
    </ng-template>
  </u-multi-select>

  <u-icon
    class="avatar-multi-select-filter-arrow-icon"
    [class.open]="uMultiSelect?.multiSelect?.overlayVisible"
    [model]="{ type: 'font', value: 'icon-chevron-down1' }"
    *ngIf="model?.values.length > DISPLAYED_AVATARS_LIMIT + 1"
    (click)="togglePanel()"
  ></u-icon>
</div>
