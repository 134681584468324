import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { LogService } from '@shared/services';
import { CustomKeyboardEvent, KeyBindingHandler, KeyboardService } from '@shared/services/keyboard.service';
import { getKeyName, isKey, keyCodes, KeyName } from '@local/ts-infra';
import { isClickInElement, isFullyInViewport } from '@shared/utils/utils';
import { Logger } from '@unleash-tech/js-logger';
import { NgScrollbar } from 'ngx-scrollbar';
import { Subject } from 'rxjs';
import scrollIntoView from 'scroll-into-view-if-needed';

export type BrowseAction = 'prev' | 'next';

/**
 * This component is a infra component for keyboard and keys browsing.
 * In order to scroll make sure to mark the list items with the ref #scrollItem;
 */
@Component({
  template: '',
})
export class KeysNavigationComponent<ModelType, ComponentType extends { handleKeys?: KeyBindingHandler; [key: string]: any }>
  implements OnInit, OnDestroy, AfterViewInit
{
  protected logger: Logger;
  private _items: ModelType[];
  protected keyHandlerId: string;
  protected autoFocusOnLoad = true;
  private _itemsInView = new Set<number>();
  private _itemsInView$ = new Subject<Array<number>>();
  // Can be supplied from the heiress component, used to scroll all the way up / down.
  protected scrollArea: ElementRef | NgScrollbar;

  get itemsInView$() {
    return this._itemsInView$;
  }

  get items() {
    return this._items;
  }

  set items(value: ModelType[]) {
    this._items = value;
    if (this.selectedIndex === null && this.autoFocusOnLoad) {
      setTimeout(() => this.select('first'), 0);
    }
  }

  get selectedIndex(): number {
    return this._selectedIndex;
  }

  set selectedIndex(value: number) {
    if (!this.items) return;
    if (value === this._selectedIndex) return;

    if (value > this.items.length - 1) {
      this._selectedIndex = this.items.length - 1;
    } else if (value < 0) {
      this._selectedIndex = 0;
    } else {
      this._selectedIndex = value;
    }
  }

  get firstSelectableIndex() {
    return this.nextSelectableIndex(0);
  }

  get selectedEl(): HTMLElement | null {
    if (this.elementsRefs?.toArray()[this.selectedIndex]) {
      return this.elementsRefs.toArray()[this.selectedIndex].nativeElement;
    }
  }

  get selectedItem(): ModelType {
    if (this._selectedIndex == null) {
      return;
    }

    return this.items && this.items[this._selectedIndex];
  }

  private _selectedIndex: number = null;

  protected keysHandlers: Partial<Record<KeyName, (event: KeyboardEvent) => any>> = {};
  constructor(
    logService: LogService,
    protected cdr: ChangeDetectorRef,
    protected keyboardService: KeyboardService
  ) {
    this.logger = logService.scope('KeysNavigationComponent');
  }

  ngOnInit() {
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 5);
  }

  ngAfterViewInit() {
    this.maintainItemsInView();
  }

  ngOnDestroy() {
    if (this.keyHandlerId) this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
  }

  @ViewChildren('scrollItem', { read: ElementRef }) elementsRefs: QueryList<ElementRef>;
  @ViewChildren('scrollItem') itemsComps: QueryList<ComponentType>;

  @HostListener('window:click', ['$event']) onClick(event: MouseEvent): void {
    if (!this.elementsRefs) return;
    for (const [i, ref] of this.elementsRefs.toArray().entries()) {
      if (this.isSelectable(this.items[i]) && isClickInElement(ref.nativeElement, event)) {
        this._selectedIndex = i;
        return;
      }

      this.clearSelection();
    }
  }

  // ** Using intersection observer is very performence efficient,
  // used to maintain a list of all the visible items so we can report them to telemetry
  private maintainItemsInView() {
    if (!this.elementsRefs) {
      this.logger.warn('No elementsRefs found, visible items list wont be maintained');
      return;
    }
    this.elementsRefs.forEach(({ nativeElement }, index) => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting === false) this._itemsInView.delete(index);
          if (entries[0].isIntersecting === true) this._itemsInView.add(index);
          this._itemsInView$.next(Array.from(this._itemsInView));
        },
        { threshold: [0] }
      );

      observer.observe(nativeElement);
    });
  }

  select(index: 'next' | 'prev' | 'first' | 'last' | number): void {
    if (!this.items?.length) {
      this.selectedIndex = null;
      return;
    }

    if (this._selectedIndex === index) {
      return;
    }

    if (index === 'last') {
      index = this.items?.length ?? 0;
    }

    if (typeof index === 'number') {
      if (index > this._selectedIndex) {
        this.selectedIndex = this.nextSelectableIndex(index);
      } else if (index < this._selectedIndex) this.selectedIndex = this.prevSelectableIndex(index);
    } else if (index === 'prev') {
      const prev = this.prevSelectableIndex();
      if (typeof prev === 'number') {
        this.selectedIndex = prev;
      }
    } else if (index === 'next') {
      const next = this.nextSelectableIndex();
      if (typeof next === 'number') {
        this.selectedIndex = next;
      }
    } else if (index === 'first') {
      const next = this.nextSelectableIndex(0);
      if (typeof next === 'number') {
        this.selectedIndex = next;
      }
    }
    this.cdr.markForCheck();
    this.scrollIfNeeded();
  }

  private getTop() {
    const computedStyleMap = this.scrollArea.nativeElement?.computedStyleMap;
    if (computedStyleMap?.get) {
      return computedStyleMap.get('height').value;
    }
    return +this.scrollArea.nativeElement.style.height.split('px')[0];
  }

  protected async scrollIfNeeded(): Promise<void> {
    if (this.scrollArea instanceof NgScrollbar) {
      const scrollTop = this.scrollArea.viewport.scrollTop;

      if (this.nextSelectableIndex(0) === this.selectedIndex && scrollTop !== 0) {
        return this.scrollArea.scrollTo({ top: 0, duration: 20 });
      }

      if (this.selectedIndex === this.items.length - 1) {
        return this.scrollArea.scrollTo({
          duration: 20,
          top: this.getTop(),
        });
      }

      const { offLayoutHeight, itemsHeight } = this.elementsRefs
        .toArray()
        .slice(0, this.selectedIndex)
        .reduce(
          (acc, { nativeElement: el }: ElementRef) => {
            const computedPosition = window.getComputedStyle(el)['position'];
            // We need to deduct any 'sticky' elements since they dont count the the scroll height
            if (computedPosition === 'sticky' || computedPosition === 'absolute') {
              acc.offLayoutHeight += el.clientHeight;
            } else {
              acc.itemsHeight += el.clientHeight;
            }
            return acc;
          },
          { offLayoutHeight: 0, itemsHeight: 0 }
        );

      // Since it's virtual scroll the element might not be rendered and we need to scroll either way
      const itemNotRenderedYet =
        !this.selectedEl &&
        this.items.length > this.elementsRefs.length &&
        (this.scrollArea.viewport?.viewPort?.nativeElement as HTMLElement).classList.contains('virtual-scroll');
      if (itemNotRenderedYet || !isFullyInViewport(this.selectedEl, this.scrollArea.viewport.nativeElement, { top: offLayoutHeight })) {
        this.scrollArea.scrollTo({ top: itemsHeight });
      }
    } else {
      if (!this.selectedEl) return;
      scrollIntoView(this.selectedEl, {
        scrollMode: 'if-needed',
        block: 'center',
        inline: 'nearest',
        behavior: 'auto',
        boundary: this.selectedEl.parentElement.parentElement,
      });
      const scrollContainer = this.scrollArea?.nativeElement || this.selectedEl.parentElement;
      if (this.selectedIndex === 0) scrollContainer.scrollTo({ top: 0, behavior: 'smooth' });
      if (this.selectedIndex === this.items.length - 1) scrollContainer.scrollTo({ top: scrollContainer.scrollHeight, behavior: 'smooth' });
    }
  }

  private nextSelectableIndex = (from?: number) => {
    if (!this.items) {
      return;
    }

    const i = (from ?? this.selectedIndex != null) ? this.selectedIndex + 1 : 0;

    const index = this.items.slice(i, this.items.length).findIndex((i) => this.isSelectable(i)) + i;

    if (index !== -1) {
      return index;
    }
  };

  private prevSelectableIndex = (from?: number) => {
    if (!this.items) {
      return;
    }

    const i = (from ?? this.selectedIndex ?? this.items?.length) - 1;
    const index = this.items
      .slice(0, i + 1)
      .reverse()
      .findIndex((i) => this.isSelectable(i));
    if (index !== -1) {
      return i - index;
    }
  };

  protected handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    if (!this.items) return;
    if (keys.length === 1) {
      const key = keys[0];
      if (key === 'ArrowUp') {
        const prevIndex = this.selectedIndex;
        if (this.selectedIndex !== null) this.select('prev');
        if (prevIndex === 0) this.selectedIndex = null;
        this.cdr.markForCheck();
        event.stopPropagation();
        event.preventDefault();
        return;
      } else if (key === 'ArrowDown') {
        this.select('next');
        this.cdr.markForCheck();
        event.stopPropagation();
        event.preventDefault();
        return;
      }
    }

    if (this.selectedIndex >= 0) {
      const selected = this.itemsComps.get(this.selectedIndex);
      if (selected && selected.handleKeys) {
        selected.handleKeys(keys);
      }
    }
  }

  clearSelection() {
    this._selectedIndex = null;
  }

  protected eventToSelectAction(event: KeyboardEvent): BrowseAction | null {
    if (isKey(event, keyCodes.ArrowUp)) {
      return 'prev';
    } else if (isKey(event, keyCodes.ArrowDown)) {
      return 'next';
    }
  }

  keyHandler(event: KeyboardEvent): void {
    const name = getKeyName(event);
    return this.keysHandlers[name] ? this.keysHandlers[name](event) : null;
  }

  /** Override in the case when we want to skip some items, like headers */
  protected isSelectable(x: ModelType): boolean {
    return true;
  }
}
