import { PopupService } from '@local/ui-infra';
import { SelectItemsBasePopupService } from './select-items-popup-base.service';
import { Injectable } from '@angular/core';
import { MoveCollectionWidgetToTabSelect } from '../../components/selected-item-popup/models/move-collection-widget-to-tab-select';
import { WorkspacesService } from '../workspaces.service';
import { HubService } from '../hub.service';
import { SelectedItem, TelemetryEvent } from '../../components/selected-item-popup/selected-item-popup.component';
import { EventsService } from '@shared/services';
import { filter, firstValueFrom, take, takeUntil } from 'rxjs';
import { CollectionPopupService } from '../../views/collections-page/services/collection-popup.service';
import { CollectionsService } from '../collections.service';
import { ShowToasterService } from '../show-toaster.service';
import { SelectedItemPopupData } from '../../components/selected-item-popup/models/select-item-base';
import { generateId } from '@local/common-web';
import { HomeTabsService } from '../home-tabs.service';
import { isEmpty } from 'lodash';
import { cutText } from '@local/ts-infra';

@Injectable()
export class MoveCollectionWidgetToTabPopupService extends SelectItemsBasePopupService<MoveCollectionWidgetToTabSelect> {
  private readonly SETUP_DATA: SelectedItemPopupData = {
    title: 'Pin "$$" to Tabs',
    type: 'pin',
    description: 'Select the tabs you would like to pin this collection to',
    placeholder: 'Select Tab',
    warningMessage: 'Ensure that users who have access to the collection also have access to the tab.',
  };
  private showAgainCheckbox: boolean;

  constructor(
    protected popupService: PopupService,
    protected workspacesService: WorkspacesService,
    private showToasterService: ShowToasterService,
    private hubService: HubService,
    protected eventsService: EventsService,
    protected collectionPopupService: CollectionPopupService,
    protected collectionsService: CollectionsService,
    protected homeTabService: HomeTabsService
  ) {
    super(popupService, workspacesService);
    this.collectionsService.openSelectPopup$.pipe(filter((r) => r.type === 'pin-collection-to-tab')).subscribe((res) => {
      this.openSelectedItemPopup(res.data as MoveCollectionWidgetToTabSelect);
    });
    this.isInvalidItem = (selectedItem: SelectedItem) => !selectedItem?.name;
  }

  protected async init() {
    this.showAgainCheckbox = false;
  }

  protected onTelemetryEvent(event: TelemetryEvent) {
    this.eventsService.event('home_tabs.selected_tab', {
      location: { title: this.hubService.currentLocation },
      target: event?.target ?? 'tab_selection',
      label: event?.trigger ?? 'mouse_click',
    });
  }

  protected getPopupDataOptions(): SelectedItemPopupData {
    return {
      ...this.SETUP_DATA,
      title: this.SETUP_DATA.title.replace('$$', this.data.collection.title),
      autoCompleteSettings: {
        multiple: true,
        displayDismissButton: false,
        removeSelected: true,
        showArrow: false,
        allowIconInViewList: false,
      },
      styleOptions: {
        hideSelectionBorder: true,
        hidesButtonsContainer: true,
        showAddButton: true,
        overrideAutoCompleteClass: 'auto-complete-multiple-override',
      },
      componentStyleVars: {},
      allowPrimaryClick: false,
      displaySelectedItems: true,
      renderSelectedOptions: {
        icon: true,
        title: true,
        description: true,
        rightButton: {
          label: 'Unpin',
          icon: 'icon-windows-unpin',
        },
      },
      checkButtonClick: (afterButtonClick, selected) => this.checkButtonClick(afterButtonClick, selected),
    };
  }

  checkButtonClick(afterButtonClick: Function, selected: SelectedItem) {
    if (this.data.collection.isShared && !this.showAgainCheckbox) {
      const pinPopupRef = this.collectionPopupService.openPinSharedCollectionMessagePopup(selected.name, {
        show: true,
        value: false,
      });
      pinPopupRef.compInstance.primaryButton.pipe(take(1)).subscribe(() => afterButtonClick());
      pinPopupRef.compInstance.checkBoxChanged.pipe(takeUntil(pinPopupRef.destroy$)).subscribe((value) => (this.showAgainCheckbox = value));
    } else {
      afterButtonClick();
    }
  }

  protected async onPrimaryButtonClick(selectedItem: SelectedItem[]) {
    const prevPins = {};
    const previousSelected = this.data.selected as SelectedItem[];

    if (!selectedItem.length && !previousSelected.length) return;
    if (!selectedItem.length && previousSelected.length) {
      this.collectionsService.deleteManyPins(this.data.currentPins || []);
      return;
    }
    previousSelected.forEach((select) => {
      prevPins[select.id] = select;
    });

    const pinToTabs = [];
    const newTabsIds = [];

    for (const select of selectedItem || []) {
      if (select.isNew) {
        const id = generateId();
        newTabsIds.push(id);
        await this.homeTabService.create(id, select.name);
        pinToTabs.push(id);
      } else if (!prevPins[select.id]) {
        pinToTabs.push(select.id);
      } else {
        delete prevPins[select.id];
      }
    }

    if (newTabsIds.length > 0) {
      //wait for the new tabs to update in the backend because create method is lazy
      await firstValueFrom(
        this.homeTabService.all$.pipe(
          filter((res) => {
            const currTabsIds = new Set(res.map((r) => r.id));
            return newTabsIds.every((r) => currTabsIds.has(r));
          })
        )
      );
    }

    const tasks = [];
    if (pinToTabs.length > 0) {
      tasks.push(this.collectionsService.pinToTabs(pinToTabs, this.data.collection));
    }

    Object.keys(prevPins).forEach((key) => {
      // remove all leftovers
      const pin = this.data.currentPins?.find((p) => p.tabId === key);
      if (pin) {
        tasks.push(this.collectionsService.deletePinById(pin));
      }
    });
    await Promise.all(tasks);
    if (!this.data.collection.pinBy?.length) {
      this.showToasterService.showToaster({
        id: 'collection-pin',
        content: `Collection "${cutText(this.data.collection.title, 40)}" pinned successfully`,
        intent: 'primary',
      });
    } else if (pinToTabs.length > 0 || !isEmpty(prevPins)) {
      this.showToasterService.showToaster({
        id: 'collection-pin',
        content: `Your updates to collection "${cutText(this.data.collection.title, 40)}" have been saved`,
        intent: 'primary',
      });
    }

    if (selectedItem.length > 0) {
      this.eventsService.event('home_tabs.selected_tab', {
        label: 'mouse_click',
        target: selectedItem.map((s) => s.name).toString(),
        location: { title: this.hubService.currentLocation },
      });
    }
  }
}
