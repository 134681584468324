import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FlagsService {
  constructor() {}

  public get isNotProd() {
    return window.location.origin !== 'https://app.unleash.so';
  }

  get<T>(flag: string): T | null {
    if (this.isNotProd) {
      const key = 'flags.' + flag;
      const rawFlag = window.sessionStorage.getItem(key);
      if (rawFlag) {
        return rawFlag as unknown as T;
      }
    }
    return null;
  }

  isFlagOn(flag: string): boolean {
    return this.isNotProd && this.get<boolean>(flag);
  }

  set(flag: string, value: string | boolean): void {
    if (this.isNotProd) {
      const key = 'flags.' + flag;
      window.sessionStorage.setItem(key, value.toString());
    }
  }

  remove(flag: string) {
    if (this.isNotProd) {
      const key = 'flags.' + flag;
      window.sessionStorage.removeItem(key);
    }
  }
}
