<as-split
  class="results-split-wrapper"
  direction="horizontal"
  unit="percent"
  (dragEnd)="previewDragEnd.emit($event)"
  [ngClass]="{ 'limit-content': isPreviewMode }"
>
  <as-split-area
    order="0"
    [size]="100 - previewWidth"
    [style]="{ backgroundColor: 'transparent', height: '100%' }"
    [visible]="visibleResults"
    [ngStyle]="visibleResults && !smallView ? { 'min-width': RESULTS_MIN_SIZE + 'px' } : {}"
  >
    <ng-container *ngTemplateOutlet="resultsTemplate"></ng-container>
  </as-split-area>
  <as-split-area
    *ngIf="!smallView"
    #previewSplitArea
    order="2"
    [size]="previewWidth"
    [maxSize]="maxPreviewWidth"
    [minSize]="minPreviewWidth"
    [visible]="visiblePreview"
    [style]="{
      display: hiddenPreview ? 'none' : 'grid',
      height: scrollHeight
    }"
  >
    <ng-container>
      <preview
        [telemetrySearch]="{
          search: { clientSearchId: displayedContext?.clientSearchId, sessionId: displayedContext?.sessionId, searchId: searchId }
        }"
        [isLauncher]="isLauncher"
        [previewViewModel]="previewViewModel"
        [previewMode]="previewViewModel.previewState"
        [previewType]="previewType"
        [previewWidth]="previewRef?.nativeElement?.offsetWidth"
        [previewHeight]="previewHeight"
        [items]="selectedForPreview$ | async"
        [index]="selectedIndex"
        (sizeChanged)="sizeChanged.emit($event)"
        (invokeDirty)="onInvokeDirty($event)"
        (invoke)="invoke.emit($event)"
      ></preview>
    </ng-container>
  </as-split-area>
</as-split>
