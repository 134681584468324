<div class="share-options-popup-wrapper" (click)="onClickClosePopup($event, 'background')">
  <div class="share-options-popup-container" #shareOptionsPermissionsPopup (click)="$event.stopPropagation()">
    <ng-container *ngIf="model; else loading">
      <div class="share-options-popup-header">
        <div class="share-options-popup-header-content">
          <div class="share-options-title">{{ model.title }}</div>
          <u-icon
            class="close-icon"
            (click)="onClickClosePopup($event, 'close')"
            [model]="{ type: 'font', value: 'icon-Windows-close' }"
            [styles]="{ fontSize: '16px', color: 'var(--gray-250)' }"
          ></u-icon>
        </div>
        <div *ngIf="model.title" class="divider"></div>
      </div>

      <members-selector
        [model]="model"
        [suggestions]="suggestions"
        [selectedSuggestions]="selectedSuggestions"
        [creator]="creator"
        [owOrAdmins]="owOrAdmins"
        [workspacePermission]="workspacePermission"
        [invitedSuggestions]="invitedSuggestions"
        [workspace]="workspace"
        [heightPopup]="heightPopup"
        (inviteChanged)="updateInvitedSuggestions($event)"
        (permissionChanged)="updateWorkspacePermission($event)"
        [heightScroll]="345"
        [viewType]="'share'"
      ></members-selector>
    </ng-container>

    <ng-template #loading>
      <loader-overlay class="popup-loading"> </loader-overlay>
    </ng-template>
  </div>
</div>
