import { MemorySearch } from '@local/client-contracts';
import { LogService } from '@shared/services';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { ResultItem } from 'src/app/bar/views';
import { SuggestionsSearchService } from '../../../suggestions-search.service';
import { MemorySearchClient } from '../memory-search-client/memory-search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { SuggestionsSourceSettings } from './suggestions-source-settings';

export class SuggestionsSearchClient extends MemorySearchClient<SuggestionsSourceSettings> {
  constructor(
    logService: LogService,
    memorySearchService: MemorySearchService,
    private suggestionsSearchService: SuggestionsSearchService
  ) {
    super(logService, memorySearchService);
    this.logger = logService.scope('suggestions-search-client');
  }

  async getInput(request: SearchRequest<SuggestionsSourceSettings>, response: SearchResponse): Promise<MemorySearch.Item[]> {
    const items = await this.suggestionsSearchService.getSuggestions();
    const memoryItems: MemorySearch.Item[] = items.map((i) => ({ data: i, searchText: null, sortValue: null }));
    return memoryItems;
  }

  async getOutput(items: MemorySearch.Item[]): Promise<ResultItem[]> {
    return items.map((i) => i.data);
  }
}
