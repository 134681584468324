import { WikiCardBlobService } from '../../../services/wiki-card-blob.service';
import { WikiCardMediaHandler } from './wiki-card-media-handler';
import { BlobsService } from 'src/app/bar/services/blob.service';
import { LogService } from '@shared/services';

export class WikiCardVideoHandler extends WikiCardMediaHandler {
  private readonly DEFAULT_WIDTH = 300;
  private readonly DEFAULT_HEIGHT = 150;

  constructor(protected wikiCardBlobService: WikiCardBlobService, protected blobsService: BlobsService, logService: LogService) {
    super(logService);
  }

  async insertMediaToHtml(html: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const videoElements = doc.querySelectorAll('video');
    const promises = Array.from(videoElements).map(async (vid) => {
      if (vid.id) {
        const source = vid.querySelector('source');
        if (source) {
          try {
            source.src = await this.blobsService.getBlobFullUrl(vid.id);
          } catch (error) {
            source.src = '';
          }
        }
      }
    });
    await Promise.all(promises);
    return doc.body.innerHTML;
  }

  removeAllMediaSrc(html: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const videoElements = doc.querySelectorAll('video');
    for (const vid of videoElements) {
      const source = vid.querySelector('source');
      if (!vid.id) {
        let poster = vid?.poster;
        if (poster) {
          poster = poster.split('/')?.pop();
          vid.id = poster;
          vid.poster = '';
        }
      }
      // this fix an issue when the html element does not update the width and height
      if (!vid.width) {
        vid.width = this.DEFAULT_WIDTH;
      }
      if (!vid.height) {
        vid.height = this.DEFAULT_HEIGHT;
      }
      source.src = '';
    }
    return doc.body.innerHTML;
  }
}
