import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'exclamation-circle',
  templateUrl: './exclamation-circle.component.html',
  styleUrls: ['./exclamation-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExclamationCircleComponent {
  @Input() size = '20px';

  get fontSize() {
    return `calc(${this.size} - 4px)`;
  }
}
