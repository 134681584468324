import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from '@shared/services/session.service';
import { firstValueFrom } from 'rxjs';
export const DEFAULT_PERSON_AVATAR = '/assets/bar/people/people-avatar.svg';

@Component({
  template: '',
})
export class GoLinkRedirectComponent {
  constructor(private sessionService: SessionService) {}

  async ngOnInit() {
    // in the rare case that needed cookies are not ready
    const s = await firstValueFrom(this.sessionService.current$);

    let href = `https://go.${location.host.replace('app.', '')}`;

    if (location.host.includes('localhost')) {
      href = 'http://localhost:8080';
    } else if (location.host.includes('previews')) {
      const splitPreviewsUrl = location.host.split('previews');
      href = `https://${splitPreviewsUrl[0]}go.previews${splitPreviewsUrl[1]}`;
    }
    let hash = location.hash;
    if (hash?.startsWith('#non=')) hash += `&session=` + escape(JSON.stringify(s));

    location.href = `${href}${location.pathname.slice(3)}${hash}`;
  }
}
