import { Pipe, PipeTransform } from '@angular/core';
import { isMac } from '@local/common-web';
import { capitalCase } from '@local/ts-infra';

export const keyIconMap: Record<string, { type: 'font-icon' | 'fa' | 'unicode'; value: string }> = {
  winkey: { type: 'fa', value: 'font-icon icon-windows' },
  command: { type: 'unicode', value: '⌘' },
  meta: { type: 'unicode', value: '⌘' },
  options: { type: 'unicode', value: '⌥' },
  enter: { type: 'font-icon', value: 'font-icon icon-key-enter' },
  control: { type: 'unicode', value: isMac() ? '⌃' : 'Ctrl' },
  alt: { type: 'unicode', value: isMac() ? '⌥' : 'Alt' },
  shift: { type: 'unicode', value: isMac() ? '⇧' : 'Shift' },
  backspace: { type: 'unicode', value: '⌫' },
  tab: { type: 'unicode', value: '⇥' },
  commandorcontrol: { type: 'unicode', value: isMac() ? '⌘' : 'Ctrl' },
  escape: { type: 'unicode', value: 'esc' },
};

@Pipe({
  name: 'keyIcon',
})
export class KeyIconPipe implements PipeTransform {
  transform(key: string | Array<string>, separator = '+'): string {
    if (Array.isArray(key)) {
      return key.map((k) => this.generateIcon(k)).join(` ${separator} `);
    } else {
      return this.generateIcon(key);
    }
  }

  private generateIcon(key: string) {
    if (!key) return;
    const icon = keyIconMap[key.toLowerCase()];
    if (!icon) {
      return capitalCase(key);
    }

    if (icon.type !== 'unicode') {
      return `<i class="${icon.value}"></i>`;
    }

    return icon.value;
  }

  reverse(shortcut: string) {
    if (!shortcut) return;
    const s = shortcut.split('+').map((k) => k.trim());
    const final = [];
    for (const key of s) {
      const keyName = Object.entries(keyIconMap).find(([_, i]) => i.value === key);
      keyName ? final.push(keyName[0]) : final.push(key);
    }

    return final;
  }
}
