import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding } from '@angular/core';
import { isEmbed } from '@local/common-web';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EmbedService } from '@shared/embed.service';
import { BannerService } from '@shared/services/banner.service';
import { startWith } from 'rxjs';
import { HubService } from 'src/app/bar/services/hub.service';
import { ChatErrorState } from '../../model';
import { ChatsService } from '../../services/chats.service';

@UntilDestroy()
@Component({
  selector: 'chat-page',
  templateUrl: './chat-page.component.html',
  styleUrls: ['./chat-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatPageComponent {
  private isEmbed = isEmbed();
  private _isEmbedInline: boolean;
  bannerActive: boolean;
  hideChatSidebar = false;
  errorState: ChatErrorState = 'not-found';
  @HostBinding('attr.embedInline')
  get embedInline() {
    return this._isEmbedInline;
  }

  constructor(
    private hubService: HubService,
    private cdr: ChangeDetectorRef,
    private bannerService: BannerService,
    private embedService: EmbedService,
    private chatsService: ChatsService
  ) {}

  ngOnInit() {
    this.initSideBar();
    this.initView();
    this.subscribeErrorState();
  }

  private async initView() {
    this.bannerService.bannerActive$.pipe(untilDestroyed(this), startWith(false)).subscribe((isBannerActive) => {
      this.bannerActive = isBannerActive && !this.isEmbed;
      this.cdr.markForCheck();
    });
    this._isEmbedInline = await this.embedService?.isInline();
    this.cdr.markForCheck();
  }

  private async initSideBar() {
    const globalAssistantId = await this.hubService.globalAssistantId;
    this.hideChatSidebar = !!globalAssistantId;
    this.cdr.markForCheck();
  }
  subscribeErrorState() {
    this.chatsService.chatErrorState$.pipe(untilDestroyed(this)).subscribe((error: ChatErrorState) => {
      this.errorState = error;
      this.cdr.markForCheck();
    });
  }
}
