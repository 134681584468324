<div class="app-popup">
  <div class="app-popup-container" [ngStyle]="data?.popupStyle">
    <u-icon
      *ngIf="data.showCloseIcon"
      (click)="onCloseButton($event)"
      class="close-icon"
      [model]="{ type: 'font', value: 'icon-times' }"
      [styles]="{ fontSize: '18px' }"
    ></u-icon>

    <div class="app-popup-icons">
      <div class="danger-icon" *ngIf="data.showErrorIcon"><div class="icon">!</div></div>
    </div>

    <div class="title" *ngIf="data.content?.title" [ngStyle]="data?.titleStyle" [innerHtml]="data.content.title"></div>
    <div
      class="app-popup-message"
      data-cy="app-popup-message"
      *ngIf="data.message"
      [innerHtml]="data.message"
      [ngStyle]="data?.messageStyle"
    ></div>
    <div *ngIf="data.templateContentPosition === 'center'"><ng-container *ngTemplateOutlet="data.templateContent"></ng-container></div>
    <div *ngIf="data.showButtons" class="app-popup-buttons">
      <u-button
        *ngIf="!data.onlyPrimaryButton"
        [label]="data.content?.secondaryButton"
        [styles]="{ width: data.leftButtonStyle.size + 'px', fontSize: data.leftButtonStyle.fontSize + 'px' }"
        [type]="data.leftButtonStyle.type"
        class="left-button"
        data-cy="secondary-button"
        (click)="onSecondaryButton($event)"
        [ngClass]="{ focus: !selectedKey }"
        [elementSize]="data.leftButtonStyle.elementSize"
      ></u-button>
      <u-button
        *ngIf="!data.onlySecondaryButton"
        [label]="data.content?.primaryButton"
        [styles]="{ width: data.rightButtonStyle.size + 'px', fontSize: data.rightButtonStyle.fontSize + 'px' }"
        [type]="data.rightButtonStyle.type"
        data-cy="primary-button"
        (click)="onPrimaryButton($event)"
        [ngClass]="{ focus: selectedKey && selectedKey !== -1 }"
        [elementSize]="data.rightButtonStyle.elementSize"
        [loading]="loadingPrimaryButton"
      >
      </u-button>
    </div>
    <div *ngIf="!data.showButtons && data.content?.supportMail" class="app-popup-support">
      <a [href]="'mailto:' + data.content.supportMail" target="_blank" (click)="close()">{{ data.content.supportMail }}</a>
    </div>
    <div *ngIf="data.templateContentPosition === 'bottom'"><ng-container *ngTemplateOutlet="data.templateContent"></ng-container></div>
    <div *ngIf="data?.checkbox" class="checkbox-section">
      <u-checkbox [checked]="data.checkbox.value" [disabled]="data.checkbox.disabled" [binary]="true" (onChange)="onChangeCheckbox($event)">
      </u-checkbox>
      <div [ngClass]="{ disabled: data.checkbox.disabled }" class="text">{{ data.checkbox.text }}</div>
    </div>
  </div>
</div>
