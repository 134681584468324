import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './app-logo.component.html',
  styleUrls: ['./app-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppLogoComponent {
  @HostBinding('class.primary')
  @Input()
  primary: boolean;

  @Input() width: string = '164px';
  @Input() height: string = '40px';
}
