import { Collections, Commands, Resources, Results, Search } from '@local/client-contracts';
import { isLinkResourceItem } from '@local/common-web';
import { generateTitleUrl } from '@local/ts-infra';
import { DateFormat } from '@shared/consts';
import { formatSize, isIcon } from '@shared/utils';
import { getTimeFromNowInText } from '@shared/utils/date-format.util';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { AvatarItemModel } from 'src/app/bar/models/avatar-item.model';
import { CollectionItem, SearchResults, isCollectionStaticItem } from 'src/app/bar/views';
import { AvatarListService } from '../../../avatar-list.service';
import { BlobsService } from '../../../blob.service';
import { CollectionsUtilService } from '../../../collections-util.service';
import { CollectionsService } from '../../../collections.service';
import { UrlResolverService } from '../../../url-resolver.service';
import { WorkspacesService } from '../../../workspaces.service';

export class StaticCollectionItemBuilder {
  constructor(
    private collectionsService: CollectionsService,
    private collectionsHelperService: CollectionsUtilService,
    private blobsService: BlobsService,
    private workspaceService: WorkspacesService,
    private avatarListService: AvatarListService,
    private urlResolverService: UrlResolverService
  ) {}

  ///@@ Result item logic
  buildResultView(item: Collections.UrlItem, data: Resources.WebsiteMetadata, collectionId: string) {
    const resultItem: Search.StaticCollectionResultItem = {
      type: 'result',
      id: item.id,
      isFavorite: false,
      view: {
        icon: { lightUrl: data.icon ?? './assets/collections/collection-icon.svg' },
        title: {
          text: data?.title === '' || !data?.title ? 'Untitled' : data.title,
          onClick: { type: 'open-url', url: item.url } as Commands.OpenUrl,
        },
        bullets: [{ parts: [{ icon: { name: 'icon-go-links' }, text: item.url, tooltip: item.url }] }],
      },
      showResultSections: {
        enableCommandBar: false,
        hasResource: false,
      },
      collectionId,
      kind: 'static-collection-item',
    };
    return resultItem;
  }

  async buildItemResource(item: Collections.LinkResourceItem, collectionId: string): Promise<Collections.LinkResourceItem> {
    if (isCollectionStaticItem(item) && isLinkResourceItem(item)) {
      const updateSearchResource = await this.collectionsService.buildResourceStaticItem(item, collectionId);
      return <Collections.LinkResourceItem>{
        ...item,
        searchResource: updateSearchResource,
      };
    }
  }

  async updateCollectionItem(item, addedTime: string): Promise<CollectionItem> {
    const allCollections = await firstValueFrom(this.collectionsService.all$);
    const collection = allCollections.find((c) => c.id === item.id);
    return { ...collection, type: 'collection', showResultSections: { showNewIndication: addedTime } };
  }

  createLinkBullet(url: string): Results.BulletPart {
    return {
      text: url,
      icon: { name: 'icon-go-links' },
      tooltip: url,
    };
  }

  private async createTimeBullet(time: number, collectionId: string, timePhrase: string, userId?: string) {
    const { text, avatar } = await this.getUpdateByPhrase(collectionId, userId);
    const timeToDisplay = getTimeFromNowInText(time ?? Date.now());
    let tooltip = moment(time).format(DateFormat.FULL_DATE_HOURS_A);
    if (avatar.isNameFromEmail) {
      tooltip = `${avatar.email} - ${tooltip}`;
    }
    return {
      text: `${timePhrase} by ${text}  ${timeToDisplay}`,
      icon: { lightUrl: isIcon(avatar.imgUrl) ? avatar.imgUrl.lightUrl : avatar.imgUrl, rounded: true },
      tooltip,
    };
  }

  private createFileSizeBullet(size: number) {
    return {
      text: formatSize(size),
      icon: null,
      tooltip: null,
    };
  }

  private async getUpdateByPhrase(collectionId: string, userId?: string): Promise<{ text: string; avatar: AvatarItemModel }> {
    const collection = await this.collectionsService.getById(collectionId);
    let avatar;
    if (userId) {
      avatar = this.avatarListService.getAvatarById(userId);
    } else {
      avatar = await this.avatarListService.getOwnerAvatar(collection.accountId);
    }
    if (
      (userId && this.collectionsService.sessionInfo?.user?.id === userId) ||
      (!userId && this.workspaceService.isMe(collection.accountId))
    ) {
      return { text: 'You', avatar };
    } else {
      return { text: avatar.name.replace('(Owner)', ''), avatar };
    }
  }

  updateCollectionUrlItemTitle(collection: Collections.UrlItem, newTitle: string) {
    return { ...collection, title: newTitle, modifiedTime: Date.now(), modifiedBy: this.collectionsService.sessionInfo?.user?.id };
  }

  updateCollectionHeaderItemTitle(item: Collections.HeaderItem, newTitle: string) {
    return { ...item, text: newTitle, modifiedBy: this.collectionsService.sessionInfo?.user?.id };
  }

  updateCollectionFileItemTitle(item: Collections.FileItem, newTitle: string) {
    return { ...item, name: newTitle };
  }

  async buildFileView(
    item: Collections.FileItem,
    collection: Collections.StaticCollection,
    addedTime?: string,
    showInputTitle?: boolean,
    stateView?: boolean
  ): Promise<Search.CollectionFileResultItem & SearchResults> {
    const canEdit = this.collectionsHelperService.canEdit(this.collectionsService.currentCollectionView);
    const collectionId = item.collectionId || collection.id;

    const newBullets = [];
    const uploadByBullet: Results.BulletPart = await this.createTimeBullet(item.uploadTime, collectionId, 'Uploaded', item.uploadBy);
    const fileSizeBullet: Results.BulletPart = this.createFileSizeBullet(item.size);
    newBullets.push(...[{ parts: [uploadByBullet] }, { parts: [fileSizeBullet] }]);
    const url = this.blobsService.getDownloadUrl(item.fileId);
    const blobUrl = await this.blobsService.getBlobFullUrl(item.fileId);
    const thumbnailUrl = this.blobsService.getThumbnailUrl(item.fileId);
    const dynamicCommands = [];
    if (canEdit) {
      dynamicCommands.push('add-to-collection');
      if (!stateView) {
        dynamicCommands.push(...['rename', 'remove']);
      }
    }
    return {
      type: 'collection-file',
      kind: 'static-collection-item',
      id: item.id,
      fileId: item.fileId,
      collectionId,
      view: {
        title: {
          text: item.name,
          onClick: item?.meta?.mimeType
            ? ({
                type: 'open-file',
                value: location.origin + '/' + generateTitleUrl('b', item.name, item.id),
              } as Commands.DynamicCommand)
            : null,
          onDrag: { type: 'download-url', url } as Commands.DownloadUrl,
        },
        icon: item.icon,
        bullets: newBullets,
        thumbnail: { url: thumbnailUrl },
      },
      isFavorite: !!item.favoriteMarkedTime,
      dynamicCommands,
      showResultSections: {
        enableCommandBar: false,
        hasResource: false,
        calcBullets: true,
        showInputTitle,
        showBullets: true,
        showContextMenu: true,
        showPreviewIcon: !!item?.meta?.mimeType,
        showSummaryIcon: true,
        showNewIndication: addedTime,
      },
      action: item?.meta?.mimeType
        ? {
            type: 'collection-file',
            click: { primary: { type: 'preview' } },
          }
        : null,
      meta: item?.meta,
      blobUrl,
    };
  }

  async buildUrlItemView(
    item: Collections.UrlItem,
    collection: Collections.StaticCollection,
    addedTime: string,
    showInputTitle?: boolean
  ): Promise<Search.CollectionUrlResultItem> {
    const metaData = await this.urlResolverService.resolveSite(item.url);
    const canEdit = this.collectionsHelperService.canEdit(this.collectionsService.currentCollectionView);
    const newBullets = [];
    const dynamicCommands = canEdit ? ['rename', 'remove'] : [];
    const collectionId = item.collectionId || collection.id;

    const createdLinkBullet: Results.BulletPart = this.createLinkBullet(item.url);
    const action: string = item.createdTime !== item.modifiedTime ? 'Updated' : 'Created';
    const createdByBullet: Results.BulletPart = await this.createTimeBullet(item.modifiedTime, collection.id, action, item.modifiedBy);
    newBullets.push(...[{ parts: [createdLinkBullet] }, { parts: [createdByBullet] }]);
    if (item['searchResource'] && !item.icon) {
      item.icon = item['searchResource'].view.icon;
    }

    return {
      type: 'collection-url',
      kind: 'static-collection-item',
      id: item.id,
      collectionId,
      url: item.url,
      thumbnail: item.thumbnail,
      thumbnailExpired: item.thumbnailExpired,
      view: {
        title: {
          text: item.title,
          onClick: { type: 'open-url', url: item.url } as Commands.OpenUrl,
        },
        icon: { lightUrl: metaData?.icon || './assets/collections/collection-icon.svg' },
        bullets: newBullets,
      },
      dynamicCommands,
      showResultSections: {
        enableCommandBar: false,
        hasResource: false,
        calcBullets: true,
        showInputTitle,
        showBullets: true,
        showContextMenu: true,
        showNewIndication: addedTime,
      },
    };
  }

  async buildHeaderView(item, showInputTitle?: boolean): Promise<Search.CollectionResultItem> {
    const canEdit = this.collectionsHelperService.canEdit(this.collectionsService.currentCollectionView);
    return {
      id: item.id,
      type: 'collection-header',
      kind: 'static-collection-item',
      view: {
        title: {
          text: item.text,
        },
        icon: null,
      },
      showResultSections: {
        hasResource: false,
        isEditable: true,
        showInputTitle,
        showContextMenu: false,
      },
      dynamicCommands: canEdit ? ['rename', 'copy', 'remove'] : [],
    };
  }
}
