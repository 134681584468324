import { Commands, Downloads, Interactions, Search } from '@local/client-contracts';
import { InteractionsRpcInvoker } from '@local/common';
import { isEmbed, isSafari } from '@local/common-web';
import { EmbedService } from '@shared/embed.service';
import { ServicesRpcService, WindowService } from '@shared/services';
import { DownloadsService } from '@shared/services/downloads.service';
import { LinksService } from '@shared/services/links.service';
import { RouterService } from '@shared/services/router.service';
import { copyToClipboard, isLocalActionContextResource } from '@shared/utils';
import { firstValueFrom } from 'rxjs';
import { DisplayItemData, isPreviewClickAction, SearchResults } from '../../views';
import { FavoritesService } from '../favorites.service';
import { PreviewService } from '../preview.service';
import { SummaryService } from '../summary.service';

export class CopyToClipboardInvoker implements Commands.CommandInvoker {
  type = 'copy-clipboard';

  async invoke(data: { command: Commands.CopyClipboard; context: Commands.Context }): Promise<Commands.Response> {
    await copyToClipboard(data.command.value);
    return;
  }
}

export class OpenPageInvoker implements Commands.CommandInvoker {
  type = 'open-page';
  constructor(private routerService: RouterService) {}
  async invoke(data: { command: Commands.OpenPageCommand; context: Commands.Context }): Promise<Commands.Response> {
    const url = data.command.url;
    await this.routerService.navigateByUrl(url);
    return;
  }
}

export class OpenUrlInvoker implements Commands.CommandInvoker {
  type = 'open-url';
  private interactions: Interactions.Service;
  private pcLinks: Set<string>;

  private readonly isEmbed = isEmbed();
  private readonly isSafari = isSafari();
  private readonly PC_ID = 'pc';

  constructor(
    services: ServicesRpcService,
    linksService: LinksService,
    private commandsService?: Commands.Service,
    private embed?: EmbedService,
    private window?: WindowService,
    private downloadsService?: DownloadsService
  ) {
    this.interactions = services.invokeWith(InteractionsRpcInvoker, 'interactions');
    linksService.all$.subscribe(
      (links) => (this.pcLinks = new Set<string>(links?.filter((l) => l.appId === this.PC_ID).map((l) => l.id) || []))
    );
  }

  async invoke(data: { command: Commands.OpenUrl; context: Commands.Context }): Promise<Commands.OpenUrlResponse> {
    let { command, context } = data;
    const res = await this.innerInvoke(command, context);
    if (res?.data.opened && context?.linkId && context.resource?.id) {
      this.interactions.record({
        level: 'Resource',
        linkId: context.linkId,
        resourceId: context.resource.id,
        searchId: context.searchId,
        interaction: 'Click',
      });
    }
    return res;
  }

  private async innerInvoke(command: Commands.OpenUrl, context: Commands.Context): Promise<Commands.OpenUrlResponse> {
    if (context?.linkId && context?.resource) {
      if (isLocalActionContextResource(context.resource)) {
        const params = context.resource.params;
        if (params) {
          if (params.highlights.length > 1 && !this.isEmbed) {
            return { type: 'open-url', data: { askUserInput: true, params, opened: false } };
          }
          const openActionCommand = params.highlights[0]?.view?.title.onClick as Commands.OpenUrl;
          window.open(openActionCommand.url);
          return { type: 'open-url', data: { opened: true } };
        }
      }
    }
    const isLocalLink = context?.linkId ? this.pcLinks.has(context.linkId) : false;
    if (this.commandsService && ((!this.isEmbed && !this.isSafari) || isLocalLink)) {
      const res = (await this.commandsService.executeCommand(command, context)) as Commands.OpenUrlResponse;

      if (res?.data) {
        if (this.window && (await firstValueFrom(this.window?.style$)) != 'standard' && !res.data.askUserInput) {
          this.window.hide();
        }
        if (res.data.opened || res.data.askUserInput) return res as Commands.OpenUrlResponse;
      }
    }
    if (this.embed) {
      const title: any = (<Search.ResultItem>context.item)?.view?.title;
      let downloadUrl = title?.onDrag?.url;
      if (downloadUrl)
        downloadUrl = await this.downloadsService.getDownloadUrl({
          type: 'Resource',
          url: downloadUrl,
          resource: (<Search.ResultResourceItem>context.item).resource,
        } as Downloads.ResourceDownload);

      const newWindow = !(<any>self).newTabPage; //(<any>window.event)?.metaKey || (<any>window.event)?.ctrlKey;
      const data = (<Search.ResultResourceItem>context.item)?.resource?.data;
      await this.embed.openUrl(command.url, downloadUrl, newWindow, data);
    } else {
      let openIn = undefined;
      if (this.embed) {
        openIn = '_top';
      } else if (context.openInSelf) {
        openIn = '_self';
      }
      window.open(command.url, openIn);
    }

    return { type: 'open-url', data: { opened: true } };
  }
}

export class ChangeFavoriteStatusInvoker implements Commands.CommandInvoker {
  type = 'change-favorite-status';

  constructor(private favorites: FavoritesService) {}

  async invoke(data: { command: Commands.ChangeFavoriteStatus; context: Commands.Context }): Promise<Commands.Response> {
    const { command, context } = data;
    const resourceId = context.resource?.id || (context.item as Search.ResultResourceItem)?.resource?.id;
    const linkId = context.linkId || (context.item as Search.ResultResourceItem)?.resource?.linkId;
    if (command.status === 'add') await this.favorites.create({ id: resourceId, parentId: linkId, type: 'link-resource' });
    else await this.favorites.delete(resourceId);
    return;
  }
}

export class GoLinksChangeFavoriteStatusInvoker implements Commands.CommandInvoker {
  type = 'go-links-change-favorite-status';

  constructor(private favorites: FavoritesService) {}

  async invoke(data: {
    command: Commands.GoLinksChangeFavoriteStatus;
    context: Commands.Context;
    invokerItem: Commands.CommandInvoker;
  }): Promise<Commands.Response> {
    const { command } = data;
    if (command.status === 'add') {
      await this.favorites.create({ id: command.id, type: 'go-links' });
    } else {
      await this.favorites.delete(command.id);
    }
    return;
  }
}

export class PreviewInvoker implements Commands.CommandInvoker {
  type = 'preview';

  constructor(private previewService: PreviewService) {}

  async invoke(data: {
    command: Commands.Preview<SearchResults>;
    context: Commands.Context;
    invokerItem: Commands.CommandInvoker;
  }): Promise<Commands.Response> {
    const command = data.command;
    const model = command.model as DisplayItemData;
    if (!model || !isPreviewClickAction(model.action)) return;
    this.previewService.setPreviewState(command.state, command.selectedIndex, command.model);
  }
}

export class SummaryInvoker implements Commands.CommandInvoker {
  type = 'summary';

  constructor(private summaryService: SummaryService) {}

  async invoke(data: {
    command: Commands.Summary;
    context: Commands.Context;
    invokerItem: Commands.CommandInvoker;
  }): Promise<Commands.Response> {
    this.summaryService.openSummaryPopup(data.command);
    return;
  }
}

export class DownloadUrlInvoker implements Commands.CommandInvoker {
  type = 'download-url';

  constructor(private downloadsService: DownloadsService) {}

  async invoke(data: {
    command: Commands.DownloadUrl;
    context: Commands.Context;
    invokerItem: Commands.CommandInvoker;
  }): Promise<Commands.Response> {
    const command = data.command;
    await this.downloadsService.urlDownload({
      type: 'Url',
      url: command.url,
      icon: command.icon,
      name: command.name,
      grantType: command.grantType,
    });
    return;
  }
}
