import { ResourceMemorySourceSettings } from '../resources-memory-search';

export interface VisitsSourceSettings extends ResourceMemorySourceSettings {
  type: 'visits';
  cache: 'skip' | 'first' | 'only';
  defaultSort: 'frequent-first' | 'recent-first';
  includeInteractions: 'wait' | 'cache';
  includeTraits: 'wait' | 'cache';
  displayOneLine?: boolean;
  lastVisitDaysAgo?: number;
  excludeTypes?: string[];
  minVisits?: number;
  minDays?: number;
  disableHighlights?: boolean;
  disableSnippet?: boolean;
}
