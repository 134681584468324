<svg id="Layer_1" data-name="Layer 1" preserveAspectRatio="xMaxYMid slice" xmlns="http://www.w3.org/2000/svg">
  <!-- viewBox="0 0 500 560" -->
  <defs>
    <style>
      .cls-1 {
        fill: #1b1c21;
      }
      .cls-10,
      .cls-2 {
        fill: #e4e4e4;
      }
      .cls-3,
      .cls-4 {
        fill: #474747;
      }
      .cls-10,
      .cls-3,
      .cls-5,
      .cls-7 {
        fill-rule: evenodd;
      }
      .cls-5,
      .cls-9 {
        fill: #e4e4e4;
      }
      .cls-6,
      .cls-7 {
        fill: #353540;
      }
      .cls-8 {
        fill: #bcbec0;
      }
      .cls-11 {
        fill: #d1d3d4;
      }
    </style>
  </defs>
  <title>Untitled-2</title>
  <rect id="background" class="cls-1" x="-0.79" width="500.79" height="560" />

  <g id="stars">
    <path
      class="cls-5"
      d="M168.74,508.16c0,.45-.19.58-.4.94s-.08.87-.46,1.09-.72,1.15-1.17,1.15-.7-1.13-1.06-1.34-1.05-.12-1.27-.49-.67-.9-.67-1.35,1-.69,1.18-1.05.64-.13,1-.35.36-1.12.81-1.12.47.88.83,1.09,1.25-.27,1.47.11S168.74,507.71,168.74,508.16Z"
    />
    <path class="cls-5" d="M255.72,448.65c0,.5-1.09.16-1.59.16s-.3.34-.3-.16-.19,0,.3,0S255.72,448.16,255.72,448.65Z" />
    <path class="cls-5" d="M244.94,414.83c0,.49-.46.15-1,.15s-.33.34-.33-.15-.16-1.34.33-1.34S244.94,414.33,244.94,414.83Z" />
    <path class="cls-5" d="M201.31,512.92c0,.5-.8-.13-1.3-.13s-.68.63-.68.13.19-1.23.68-1.23A1.64,1.64,0,0,1,201.31,512.92Z" />
    <path
      class="cls-5"
      d="M148.88,396.05c0,.43.41.47.13.75s-.33.86-.76.86-1.1,0-1.38-.23.27-.95.27-1.38-.82-1.37-.54-1.66,1.22.39,1.65.39.9-.2,1.19.08S148.88,395.61,148.88,396.05Z"
    />
    <path class="cls-5" d="M30.08,356.43c0,.49.24,1.77-.25,1.77A3.2,3.2,0,0,1,28,356.43c0-.5,1.32,0,1.82,0S30.08,355.93,30.08,356.43Z" />
    <polygon
      class="cls-2"
      points="241.3 415.98 241.86 418.43 244.31 418.98 241.86 419.54 241.3 421.98 240.75 419.54 238.3 418.98 240.75 418.43 241.3 415.98"
    />

    <polygon
      class="cls-2"
      points="125.3 339.8 125.87 342.32 128.39 342.89 125.87 343.46 125.3 345.98 124.73 343.46 122.21 342.89 124.73 342.32 125.3 339.8"
    />
    <polygon
      class="cls-2"
      points="82.7 473.14 83.26 475.28 85.7 475.77 83.26 476.26 82.7 478.4 82.14 476.26 79.7 475.77 82.14 475.28 82.7 473.14"
    />
    <polygon
      class="cls-2"
      points="147.45 448.02 148.02 450.54 150.54 451.11 148.02 451.68 147.45 454.2 146.88 451.68 144.36 451.11 146.88 450.54 147.45 448.02"
    />

    <path
      class="cls-2"
      d="M158.85,351.1a1.12,1.12,0,0,0-.8-.41h-.63a1.23,1.23,0,0,0-.83.26,1.15,1.15,0,0,0-.42.8,1.14,1.14,0,0,0,.26.83,1.12,1.12,0,0,0,.8.42h.64a1.25,1.25,0,0,0,.83-.26,1.19,1.19,0,0,0,.42-.8A1.21,1.21,0,0,0,158.85,351.1Z"
    />
    <path
      class="cls-2"
      d="M48.37,447a1.17,1.17,0,0,0-.8-.42,1.19,1.19,0,0,0-.83.27,1.13,1.13,0,0,0-.42.8v.63a1.23,1.23,0,0,0,.27.84,1.17,1.17,0,0,0,1.63.15,1.12,1.12,0,0,0,.42-.8v-.64A1.2,1.2,0,0,0,48.37,447Z"
    />
    <path
      class="cls-2"
      d="M118.81,455.74a1.19,1.19,0,0,0-1.25,1.07,1.18,1.18,0,0,0,1.07,1.25,1.19,1.19,0,0,0,1.25-1.07A1.16,1.16,0,0,0,118.81,455.74Z"
    />
    <path class="cls-2" d="M123.18,357.8a.38.38,0,0,0-.4.34v.64a.37.37,0,0,0,.34.39.36.36,0,0,0,.4-.34v-.63A.38.38,0,0,0,123.18,357.8Z" />
    <path class="cls-2" d="M136.47,368.56c-.47,0-.54.74-.06.74S137,368.56,136.47,368.56Z" />
    <path class="cls-2" d="M178.24,355.91a.36.36,0,0,0-.4.33v.64a.37.37,0,0,0,.34.39.36.36,0,0,0,.4-.33v-.64A.37.37,0,0,0,178.24,355.91Z" />
    <path class="cls-2" d="M144.07,369.83c-.48,0-.54.73-.06.73S144.54,369.83,144.07,369.83Z" />
    <path class="cls-2" d="M148.5,372.36c-.48,0-.54.74-.06.74S149,372.36,148.5,372.36Z" />
    <path class="cls-2" d="M151,373a.38.38,0,0,0-.4.34V374a.38.38,0,0,0,.34.4.37.37,0,0,0,.4-.34v-.63A.38.38,0,0,0,151,373Z" />
    <path class="cls-2" d="M97.54,407.45c-.47,0-.53.74-.06.74S98,407.45,97.54,407.45Z" />
    <path class="cls-2" d="M105.14,408.72c-.47,0-.54.74-.06.74S105.62,408.72,105.14,408.72Z" />
    <path class="cls-2" d="M97.19,420.88c-.48,0-.54.74-.06.74S97.66,420.88,97.19,420.88Z" />
    <path class="cls-2" d="M266.15,420c-.48,0-.54.74-.06.74S266.62,420,266.15,420Z" />
    <path class="cls-2" d="M60.91,339.43c-.47,0-.54.74-.06.74S61.39,339.43,60.91,339.43Z" />
    <path class="cls-2" d="M230.78,498.5c-.47,0-.54.73-.06.73S231.26,498.5,230.78,498.5Z" />
    <path class="cls-2" d="M201.46,488.26c-.47,0-.54.74-.06.74S201.94,488.26,201.46,488.26Z" />
    <path class="cls-2" d="M136.83,520.27c-.47,0-.54.74-.06.74S137.31,520.27,136.83,520.27Z" />
    <path class="cls-2" d="M112.1,411.88a.38.38,0,0,0-.4.34v.63a.38.38,0,0,0,.34.4.36.36,0,0,0,.4-.34v-.63A.38.38,0,0,0,112.1,411.88Z" />
    <path class="cls-2" d="M104.84,457a.59.59,0,0,0-.62.53v.64a.59.59,0,0,0,.53.62.58.58,0,0,0,.63-.53v-.63A.6.6,0,0,0,104.84,457Z" />
    <path class="cls-2" d="M84.59,448.73a.57.57,0,0,0-.62.53v.63a.57.57,0,0,0,.53.62.57.57,0,0,0,.62-.53v-.63A.57.57,0,0,0,84.59,448.73Z" />
    <path class="cls-2" d="M189.6,449H189a.58.58,0,1,0-.09,1.16h.63a.58.58,0,0,0,.09-1.16Z" />
    <path class="cls-2" d="M154.25,459.65h-.63a.58.58,0,1,0-.09,1.16h.63a.59.59,0,0,0,.62-.53A.58.58,0,0,0,154.25,459.65Z" />
    <path class="cls-2" d="M166.23,394.93c-.74,0-.84,1.16-.09,1.16S167,394.93,166.23,394.93Z" />
    <path class="cls-2" d="M66.24,427.84c-.74,0-.84,1.16-.09,1.16S67,427.84,66.24,427.84Z" />
    <path class="cls-2" d="M71.21,436.14c-.74,0-.84,1.16-.09,1.16S72,436.14,71.21,436.14Z" />
    <path class="cls-2" d="M74.47,441.76h-.64a.59.59,0,0,0-.62.54.57.57,0,0,0,.53.62h.63a.6.6,0,0,0,.63-.53A.59.59,0,0,0,74.47,441.76Z" />
    <path class="cls-2" d="M95,433h-.63a.6.6,0,0,0-.63.53.59.59,0,0,0,.53.63h.63a.6.6,0,0,0,.63-.54A.59.59,0,0,0,95,433Z" />
    <path class="cls-2" d="M77.37,428.42h-.63a.6.6,0,0,0-.63.53.59.59,0,0,0,.53.63h.64a.59.59,0,0,0,.62-.54A.58.58,0,0,0,77.37,428.42Z" />
    <path class="cls-2" d="M84.12,403.82h-.63a.58.58,0,1,0-.1,1.15H84a.59.59,0,0,0,.62-.53A.57.57,0,0,0,84.12,403.82Z" />
    <path class="cls-2" d="M80.06,338.85h-.63a.58.58,0,0,0-.09,1.16H80a.58.58,0,1,0,.09-1.16Z" />
    <path class="cls-2" d="M43.45,417.47h-.89a.58.58,0,1,0-.09,1.15h.89a.59.59,0,0,0,.63-.53A.58.58,0,0,0,43.45,417.47Z" />
    <path class="cls-2" d="M77.63,456.32a.58.58,0,0,0-.62.53v.63a.58.58,0,1,0,1.15.1v-.64A.59.59,0,0,0,77.63,456.32Z" />
    <path class="cls-2" d="M10.66,474.87a.59.59,0,0,0-.62.54V476a.58.58,0,0,0,1.15.09v-.63A.59.59,0,0,0,10.66,474.87Z" />
    <path class="cls-2" d="M87.12,455.69c-.74,0-.84,1.15-.09,1.15S87.87,455.69,87.12,455.69Z" />
    <path class="cls-2" d="M70.67,466.44c-.74,0-.84,1.16-.09,1.16S71.42,466.44,70.67,466.44Z" />
    <path class="cls-2" d="M52.32,433.54c-.75,0-.85,1.15-.1,1.15S53.07,433.54,52.32,433.54Z" />
    <path class="cls-2" d="M159.27,408.22c-.74,0-.84,1.16-.09,1.16S160,408.22,159.27,408.22Z" />
    <path class="cls-2" d="M206.1,381.64c-.74,0-.84,1.16-.09,1.16S206.85,381.64,206.1,381.64Z" />
    <path class="cls-2" d="M128.65,466.84c-.74,0-.84,1.16-.09,1.16S129.4,466.84,128.65,466.84Z" />
    <path class="cls-2" d="M132,461.78c-.74,0-.84,1.15-.09,1.15S132.77,461.78,132,461.78Z" />
    <path class="cls-2" d="M132.87,460.93c-.74,0-.85,1.16-.09,1.16S133.62,460.93,132.87,460.93Z" />
    <path
      class="cls-2"
      d="M210.49,347.26a.31.31,0,0,0-.34.29v.84a.32.32,0,0,0,.29.34.31.31,0,0,0,.34-.29v-.84A.31.31,0,0,0,210.49,347.26Z"
    />
    <path class="cls-2" d="M238.33,355.17a.31.31,0,0,0-.34.29v.84a.32.32,0,1,0,.63.05v-.84A.32.32,0,0,0,238.33,355.17Z" />
    <path class="cls-2" d="M212.18,379.33c-.4,0-.46.63-.05.63S212.59,379.33,212.18,379.33Z" />
    <path class="cls-2" d="M32.41,473.85H32c-.41,0-.46.63-.05.63h.37C32.77,474.48,32.82,473.85,32.41,473.85Z" />
    <path
      class="cls-5"
      d="M111.66,410.9c0,.43.41.47.13.75s-.33.87-.76.87-1.1,0-1.38-.24.27-.95.27-1.38-.82-1.37-.54-1.65,1.22.39,1.65.39.91-.21,1.19.07S111.66,410.47,111.66,410.9Z"
    />
    <polygon
      class="cls-2"
      points="45.48 487.99 46.04 490.13 48.48 490.62 46.04 491.11 45.48 493.26 44.92 491.11 42.48 490.62 44.92 490.13 45.48 487.99"
    />
    <polygon
      class="cls-2"
      points="110.23 462.87 110.8 465.39 113.32 465.96 110.8 466.54 110.23 469.05 109.66 466.54 107.14 465.96 109.66 465.39 110.23 462.87"
    />
    <path
      class="cls-2"
      d="M11.15,461.82a1.16,1.16,0,0,0-1.63-.16,1.15,1.15,0,0,0-.42.8v.64a1.18,1.18,0,0,0,1.07,1.25,1.19,1.19,0,0,0,.83-.27,1.1,1.1,0,0,0,.42-.8v-.63A1.19,1.19,0,0,0,11.15,461.82Z"
    />
    <path class="cls-2" d="M81.59,470.6a1.16,1.16,0,1,0,1.07,1.25A1.16,1.16,0,0,0,81.59,470.6Z" />
    <path class="cls-2" d="M60.32,422.31c-.47,0-.53.73,0,.73S60.8,422.31,60.32,422.31Z" />
    <path class="cls-2" d="M67.92,423.57c-.47,0-.54.74-.06.74S68.4,423.57,67.92,423.57Z" />
    <path class="cls-2" d="M60,435.73c-.47,0-.54.74-.06.74S60.44,435.73,60,435.73Z" />
    <path class="cls-2" d="M72.35,426.1c-.47,0-.54.74-.06.74S72.83,426.1,72.35,426.1Z" />
    <path class="cls-2" d="M74.88,426.74a.36.36,0,0,0-.4.34v.63a.38.38,0,0,0,.34.4.38.38,0,0,0,.4-.34v-.63A.37.37,0,0,0,74.88,426.74Z" />
    <path class="cls-2" d="M82.69,435.21a.38.38,0,0,0-.4.34v.64a.37.37,0,0,0,.34.39.36.36,0,0,0,.4-.34v-.63A.38.38,0,0,0,82.69,435.21Z" />
    <path class="cls-2" d="M67.62,471.81a.57.57,0,0,0-.62.53V473a.58.58,0,1,0,1.16.09v-.63A.59.59,0,0,0,67.62,471.81Z" />
    <path class="cls-2" d="M47.37,463.58a.59.59,0,0,0-.62.53v.63a.6.6,0,0,0,.53.63.59.59,0,0,0,.63-.53v-.64A.59.59,0,0,0,47.37,463.58Z" />
    <path class="cls-2" d="M117,474.51h-.63a.59.59,0,0,0-.63.53.58.58,0,0,0,.54.62h.63a.58.58,0,0,0,.09-1.15Z" />
    <path class="cls-2" d="M29,442.7c-.74,0-.84,1.15-.09,1.15S29.77,442.7,29,442.7Z" />
    <path class="cls-2" d="M34,451c-.74,0-.84,1.16-.09,1.16S34.75,451,34,451Z" />
    <path class="cls-2" d="M37.25,456.62h-.64a.59.59,0,0,0-.62.53.57.57,0,0,0,.53.62h.63a.58.58,0,1,0,.1-1.15Z" />
    <path class="cls-2" d="M57.75,447.81h-.63a.6.6,0,0,0-.63.53A.59.59,0,0,0,57,449h.64a.59.59,0,0,0,.62-.53A.58.58,0,0,0,57.75,447.81Z" />
    <path class="cls-2" d="M40.15,443.27h-.63a.6.6,0,0,0-.63.54.59.59,0,0,0,.53.62h.64a.59.59,0,0,0,.62-.53A.58.58,0,0,0,40.15,443.27Z" />
    <path class="cls-2" d="M46.9,418.67h-.63a.6.6,0,0,0-.63.53.59.59,0,0,0,.53.63h.64a.59.59,0,0,0,.62-.54A.58.58,0,0,0,46.9,418.67Z" />
    <path class="cls-2" d="M6.23,432.32H5.34a.58.58,0,1,0-.09,1.16h.89a.58.58,0,1,0,.09-1.16Z" />
    <path class="cls-2" d="M40.41,471.17a.59.59,0,0,0-.62.54v.63a.58.58,0,0,0,.53.62.57.57,0,0,0,.62-.53v-.63A.59.59,0,0,0,40.41,471.17Z" />
    <path class="cls-2" d="M49.9,470.54c-.74,0-.84,1.16-.09,1.16S50.65,470.54,49.9,470.54Z" />
    <path class="cls-2" d="M33.45,481.3c-.74,0-.84,1.16-.09,1.16S34.2,481.3,33.45,481.3Z" />
    <path class="cls-2" d="M15.1,448.39c-.75,0-.85,1.16-.1,1.16S15.85,448.39,15.1,448.39Z" />
    <path class="cls-2" d="M91.43,481.69c-.74,0-.84,1.16-.09,1.16S92.18,481.69,91.43,481.69Z" />
    <path class="cls-2" d="M94.8,476.63c-.74,0-.84,1.16-.09,1.16S95.55,476.63,94.8,476.63Z" />
    <path class="cls-2" d="M95.65,475.79c-.74,0-.84,1.15-.09,1.15S96.4,475.79,95.65,475.79Z" />
    <polygon
      class="cls-2"
      points="79.58 540.59 80.13 538.44 82.58 537.96 80.13 537.47 79.58 535.32 79.02 537.47 76.58 537.96 79.02 538.44 79.58 540.59"
    />
    <polygon
      class="cls-2"
      points="144.33 565.71 144.9 563.19 147.42 562.62 144.9 562.04 144.33 559.52 143.75 562.04 141.24 562.62 143.75 563.19 144.33 565.71"
    />
    <path class="cls-2" d="M115.69,558a1.18,1.18,0,0,1-1.25-1.06,1.16,1.16,0,1,1,1.25,1.06Z" />
    <path class="cls-2" d="M101.72,556.77a.57.57,0,0,1-.62-.53v-.63a.58.58,0,1,1,1.15-.1v.64A.59.59,0,0,1,101.72,556.77Z" />
    <path class="cls-2" d="M56.15,545.38h-.63a.59.59,0,0,1-.62-.53.58.58,0,0,1,.53-.63h.63a.6.6,0,0,1,.63.54A.59.59,0,0,1,56.15,545.38Z" />
    <path class="cls-2" d="M202.41,501.65h-.64a.59.59,0,0,1-.62-.53.58.58,0,0,1,.53-.62h.63a.58.58,0,1,1,.1,1.15Z" />
    <path
      class="cls-2"
      d="M151.13,554.07h-.63a.6.6,0,0,1-.63-.53.59.59,0,0,1,.53-.63H151a.59.59,0,0,1,.62.54A.59.59,0,0,1,151.13,554.07Z"
    />
    <path class="cls-2" d="M35.11,555.82c-.74,0-.84-1.15-.09-1.15S35.86,555.82,35.11,555.82Z" />
    <path
      class="cls-2"
      d="M262.46,528.89h-.9a.59.59,0,0,1-.62-.54.59.59,0,0,1,.53-.62h.9a.59.59,0,0,1,.62.53A.58.58,0,0,1,262.46,528.89Z"
    />
    <path class="cls-2" d="M74.51,557.4a.58.58,0,0,1-.63-.53v-.63a.58.58,0,1,1,1.16-.09v.63A.58.58,0,0,1,74.51,557.4Z" />
    <path class="cls-2" d="M84,558c-.74,0-.84-1.16-.09-1.16S84.75,558,84,558Z" />
    <path class="cls-2" d="M67.55,547.28c-.75,0-.85-1.16-.1-1.16S68.3,547.28,67.55,547.28Z" />
    <path class="cls-2" d="M128.9,552c-.74,0-.84-1.16-.09-1.16S129.65,552,128.9,552Z" />
    <path class="cls-2" d="M29.29,539.87h-.38c-.4,0-.46-.63-.05-.63h.38C29.64,539.24,29.7,539.87,29.29,539.87Z" />
    <path class="cls-5" d="M466.75,354.53c0,.49.07.92-.43.92s-1.64-.43-1.64-.92,1.14-1.51,1.64-1.51S466.75,354,466.75,354.53Z" />
    <path class="cls-5" d="M206.1,381.26c0,.5-1.41,1.27-1.9,1.27s0-.77,0-1.27-.47-.77,0-.77S206.1,380.77,206.1,381.26Z" />
    <path class="cls-5" d="M297.74,396.49c0,.49-.86,1.65-1.35,1.65s-1.53-1.16-1.53-1.65,1-.87,1.53-.87S297.74,396,297.74,396.49Z" />
    <path
      class="cls-5"
      d="M257.81,493.24c0,.48.89,1.08.57,1.4a2.37,2.37,0,0,1-1.4.74c-.48,0-.62-.89-.93-1.21s-1.78-.45-1.78-.93,1.86-.22,2.18-.54.05-1.55.53-1.55.73.73,1,1.05S257.81,492.76,257.81,493.24Z"
    />
    <path class="cls-5" d="M360.68,359.22c0,.5.24,1.78-.26,1.78s-1.82-1.28-1.82-1.78,1.33,0,1.82,0S360.68,358.73,360.68,359.22Z" />
    <polygon
      class="cls-2"
      points="349.19 368.64 350.71 370.01 352.6 369.24 351.23 370.76 352 372.65 350.49 371.28 348.59 372.05 349.97 370.53 349.19 368.64"
    />
    <polygon
      class="cls-2"
      points="453.98 341.88 454.56 344.4 457.07 344.97 454.56 345.55 453.98 348.06 453.41 345.55 450.89 344.97 453.41 344.4 453.98 341.88"
    />
    <polygon
      class="cls-2"
      points="411.38 475.22 411.94 477.36 414.38 477.85 411.94 478.34 411.38 480.49 410.83 478.34 408.38 477.85 410.83 477.36 411.38 475.22"
    />
    <polygon
      class="cls-2"
      points="476.13 450.1 476.7 452.62 479.22 453.19 476.7 453.76 476.13 456.28 475.56 453.76 473.04 453.19 475.56 452.62 476.13 450.1"
    />
    <path
      class="cls-2"
      d="M487.53,353.19a1.1,1.1,0,0,0-.8-.42h-.63a1.23,1.23,0,0,0-.83.26,1.19,1.19,0,0,0-.42.8,1.17,1.17,0,0,0,.27.84,1.12,1.12,0,0,0,.8.41h.63a1.23,1.23,0,0,0,.83-.26,1.19,1.19,0,0,0,.42-.8A1.14,1.14,0,0,0,487.53,353.19Z"
    />
    <path
      class="cls-2"
      d="M377.06,449a1.14,1.14,0,0,0-.8-.41,1.16,1.16,0,0,0-.84.26,1.14,1.14,0,0,0-.41.8v.63a1.26,1.26,0,0,0,.26.84,1.17,1.17,0,0,0,1.63.15,1.1,1.1,0,0,0,.42-.8v-.63A1.27,1.27,0,0,0,377.06,449Z"
    />
    <path
      class="cls-2"
      d="M297.1,474.22a1.18,1.18,0,0,0-1.25,1.07,1.16,1.16,0,0,0,1.06,1.25,1.18,1.18,0,0,0,1.25-1.07A1.16,1.16,0,0,0,297.1,474.22Z"
    />
    <path
      class="cls-2"
      d="M213.87,372.22a1.18,1.18,0,0,0-1.25,1.07,1.16,1.16,0,0,0,1.06,1.25,1.18,1.18,0,0,0,1.25-1.07A1.16,1.16,0,0,0,213.87,372.22Z"
    />
    <path class="cls-2" d="M451.86,359.89a.36.36,0,0,0-.39.34v.63a.37.37,0,1,0,.73.06v-.64A.36.36,0,0,0,451.86,359.89Z" />
    <path class="cls-2" d="M465.15,370.65c-.47,0-.53.73-.06.73S465.63,370.65,465.15,370.65Z" />
    <path class="cls-2" d="M472.75,371.91c-.47,0-.54.74-.06.74S473.23,371.91,472.75,371.91Z" />
    <path class="cls-2" d="M477.18,374.44c-.47,0-.54.74-.06.74S477.66,374.44,477.18,374.44Z" />
    <path class="cls-2" d="M479.71,375.08a.36.36,0,0,0-.4.33v.64a.37.37,0,0,0,.34.39.36.36,0,0,0,.4-.33v-.64A.37.37,0,0,0,479.71,375.08Z" />
    <path class="cls-2" d="M433.82,410.8c-.47,0-.54.74-.06.74S434.3,410.8,433.82,410.8Z" />
    <path class="cls-2" d="M425.87,423c-.47,0-.54.74-.06.74S426.35,423,425.87,423Z" />
    <path class="cls-2" d="M320.81,459.83c-.47,0-.53.73-.05.73S321.29,459.83,320.81,459.83Z" />
    <path class="cls-2" d="M297,332.67c-.48,0-.54.74-.06.74S297.51,332.67,297,332.67Z" />
    <path class="cls-2" d="M303.9,436.41c-.47,0-.53.73-.06.73S304.38,436.41,303.9,436.41Z" />
    <path class="cls-2" d="M438.25,413.33c-.47,0-.54.74-.06.74S438.73,413.33,438.25,413.33Z" />
    <path class="cls-2" d="M494.92,397c-.75,0-.85,1.15-.1,1.15S495.67,397,494.92,397Z" />
    <path class="cls-2" d="M256.4,355.94h-.63a.58.58,0,0,0-.09,1.15h.63a.58.58,0,1,0,.09-1.15Z" />
    <path class="cls-2" d="M399.9,438.22c-.75,0-.85,1.16-.1,1.16S400.65,438.22,399.9,438.22Z" />
    <path class="cls-2" d="M408.75,340.94h-.64a.59.59,0,0,0-.62.53.57.57,0,0,0,.53.62h.63a.6.6,0,0,0,.63-.53A.58.58,0,0,0,408.75,340.94Z" />
    <path class="cls-2" d="M218.42,486.34h-.9a.59.59,0,0,0-.62.53.58.58,0,0,0,.53.63h.9a.58.58,0,0,0,.09-1.16Z" />
    <path class="cls-2" d="M266,454.24a.58.58,0,0,0-.63.53v.63a.58.58,0,1,0,1.16.09v-.63A.58.58,0,0,0,266,454.24Z" />
    <path class="cls-2" d="M283.83,375.55a.59.59,0,0,0-.62.53v.64a.59.59,0,0,0,.53.62.58.58,0,0,0,.63-.53v-.63A.6.6,0,0,0,283.83,375.55Z" />
    <path class="cls-2" d="M265.46,406.48h-.64a.58.58,0,0,0-.09,1.15h.63a.58.58,0,1,0,.1-1.15Z" />
    <path class="cls-2" d="M282.68,416.6h-.9a.59.59,0,0,0-.62.53.58.58,0,0,0,.53.63h.89a.6.6,0,0,0,.63-.53A.59.59,0,0,0,282.68,416.6Z" />
    <path class="cls-2" d="M268.62,421a.59.59,0,0,0-.62.53v.64a.58.58,0,0,0,.53.62.57.57,0,0,0,.62-.53v-.63A.59.59,0,0,0,268.62,421Z" />
    <path class="cls-2" d="M399.35,468.53c-.74,0-.84,1.16-.09,1.16S400.1,468.53,399.35,468.53Z" />
    <path class="cls-2" d="M381,435.62c-.74,0-.84,1.16-.09,1.16S381.75,435.62,381,435.62Z" />
    <path class="cls-2" d="M488,410.3c-.74,0-.84,1.16-.09,1.16S488.7,410.3,488,410.3Z" />
    <path class="cls-2" d="M361.09,475.94h-.37c-.41,0-.46.63-.05.63H361C361.45,476.57,361.5,475.94,361.09,475.94Z" />
    <polygon
      class="cls-2"
      points="408.26 542.67 408.82 540.53 411.26 540.04 408.82 539.55 408.26 537.4 407.7 539.55 405.26 540.04 407.7 540.53 408.26 542.67"
    />
    <path
      class="cls-2"
      d="M444.37,560.07a1.19,1.19,0,0,1-1.25-1.07,1.17,1.17,0,0,1,1.07-1.25,1.19,1.19,0,0,1,1.25,1.07A1.16,1.16,0,0,1,444.37,560.07Z"
    />
    <path
      class="cls-2"
      d="M294,543.67a1.18,1.18,0,0,1-1.25-1.07,1.16,1.16,0,0,1,1.06-1.25,1.18,1.18,0,0,1,1.25,1.07A1.16,1.16,0,0,1,294,543.67Z"
    />
    <path class="cls-2" d="M317.69,558.06c-.47,0-.53-.73-.06-.73S318.17,558.06,317.69,558.06Z" />
    <path class="cls-2" d="M462.39,495.53c-.47,0-.54-.73-.06-.73S462.87,495.53,462.39,495.53Z" />
    <path class="cls-2" d="M430.4,558.85a.57.57,0,0,1-.62-.53v-.63a.58.58,0,0,1,1.16-.09v.63A.58.58,0,0,1,430.4,558.85Z" />
    <path class="cls-2" d="M352,508.07h-.64a.59.59,0,0,1-.62-.53.57.57,0,0,1,.53-.62h.63a.58.58,0,1,1,.1,1.15Z" />
    <path
      class="cls-2"
      d="M427.89,503.45h-.64a.59.59,0,0,1-.62-.54.58.58,0,0,1,.53-.62h.63a.6.6,0,0,1,.63.53A.59.59,0,0,1,427.89,503.45Z"
    />
    <path class="cls-2" d="M479.81,556.15h-.63a.58.58,0,1,1-.09-1.15h.63a.58.58,0,0,1,.62.53A.57.57,0,0,1,479.81,556.15Z" />
    <path class="cls-2" d="M215.3,531.55h-.9a.58.58,0,0,1-.62-.54.57.57,0,0,1,.53-.62h.89a.6.6,0,0,1,.63.53A.59.59,0,0,1,215.3,531.55Z" />
    <path
      class="cls-2"
      d="M403.19,559.49a.58.58,0,0,1-.62-.53v-.64a.58.58,0,0,1,.53-.62.57.57,0,0,1,.62.53v.63A.6.6,0,0,1,403.19,559.49Z"
    />
    <path class="cls-2" d="M314.7,547.2a.57.57,0,0,1-.62-.53V546a.6.6,0,0,1,.53-.63.59.59,0,0,1,.63.53v.64A.59.59,0,0,1,314.7,547.2Z" />
    <path class="cls-2" d="M396.23,549.36c-.74,0-.84-1.16-.09-1.16S397,549.36,396.23,549.36Z" />
    <path class="cls-5" d="M46.85,253.38c0,.49-.77,1.72-1.27,1.72s-1.49-1.23-1.49-1.72,1-.08,1.49-.08S46.85,252.88,46.85,253.38Z" />
    <path class="cls-5" d="M12,272.83c0,.5-1.45,1.72-1.94,1.72s-1-1.22-1-1.72.5-1.82,1-1.82S12,272.33,12,272.83Z" />
    <path class="cls-5" d="M123.52,288.89c0,.49.35.3-.15.3s-.82.19-.82-.3.33-1,.82-1S123.52,288.39,123.52,288.89Z" />
    <path
      class="cls-5"
      d="M165.67,170.83c0,.45-.19.57-.4.94s-.08.87-.46,1.08-.72,1.15-1.17,1.15-.69-1.12-1.06-1.33-1.05-.12-1.27-.5-.67-.89-.67-1.34,1-.7,1.18-1.06.64-.13,1-.35.36-1.12.81-1.12.47.88.83,1.09,1.26-.27,1.47.11S165.67,170.38,165.67,170.83Z"
    />
    <path class="cls-5" d="M252.65,111.32c0,.49-1.09.15-1.58.15s-.31.34-.31-.15-.19,0,.31,0S252.65,110.82,252.65,111.32Z" />
    <path class="cls-5" d="M133.09,14.39c0,.5.06.92-.44.92s-1.63-.42-1.63-.92,1.14-1.5,1.63-1.5S133.09,13.9,133.09,14.39Z" />
    <path
      class="cls-5"
      d="M66.43,342.57c0,.48,0,1.24-.29,1.56s-1.08.06-1.56.06a1.41,1.41,0,0,1-1-.57c-.32-.32-1-.57-1-1.05s.95-.46,1.27-.78.29-.77.78-.77a1.29,1.29,0,0,1,1,.5C65.94,341.84,66.43,342.09,66.43,342.57Z"
    />
    <path class="cls-5" d="M235.9,333.71c0,.5-.41,1.48-.9,1.48s-1.06-1-1.06-1.48.56-.17,1.06-.17S235.9,333.22,235.9,333.71Z" />
    <path class="cls-5" d="M122.32,301.59c0,.49-1,1.35-1.49,1.35s-1.82-.86-1.82-1.35,1.33-1.23,1.82-1.23S122.32,301.09,122.32,301.59Z" />
    <path class="cls-5" d="M198.24,175.58c0,.5-.8-.12-1.3-.12s-.68.62-.68.12.19-1.22.68-1.22A1.65,1.65,0,0,1,198.24,175.58Z" />
    <path
      class="cls-5"
      d="M145.81,58.71c0,.43.41.47.13.75s-.33.87-.76.87-1.1,0-1.38-.24.27-1,.27-1.38-.82-1.37-.54-1.66,1.22.4,1.65.4.91-.21,1.19.07S145.81,58.28,145.81,58.71Z"
    />
    <path class="cls-5" d="M229.18,243.23c0,.5.39,1.86-.1,1.86s-.87-1.36-.87-1.86.37-1.76.87-1.76S229.18,242.74,229.18,243.23Z" />
    <path class="cls-5" d="M27,19.09c0,.49.24,1.77-.25,1.77a3.2,3.2,0,0,1-1.82-1.77c0-.5,1.32,0,1.82,0S27,18.59,27,19.09Z" />
    <polygon
      class="cls-2"
      points="238.24 78.64 238.79 81.09 241.24 81.64 238.79 82.2 238.24 84.64 237.68 82.2 235.24 81.64 237.68 81.09 238.24 78.64"
    />
    <polygon
      class="cls-2"
      points="17.44 29.22 18.96 30.59 20.85 29.82 19.48 31.34 20.25 33.23 18.74 31.86 16.84 32.63 18.21 31.11 17.44 29.22"
    />
    <polygon
      class="cls-2"
      points="122.23 2.46 122.81 4.98 125.32 5.55 122.81 6.12 122.23 8.64 121.66 6.12 119.14 5.55 121.66 4.98 122.23 2.46"
    />

    <polygon
      class="cls-2"
      points="144.38 110.68 144.95 113.2 147.47 113.77 144.95 114.34 144.38 116.86 143.81 114.34 141.29 113.77 143.81 113.2 144.38 110.68"
    />

    <path
      class="cls-2"
      d="M45.3,109.62a1.12,1.12,0,0,0-.8-.41,1.14,1.14,0,0,0-.83.26,1.15,1.15,0,0,0-.42.8v.63a1.21,1.21,0,0,0,.27.84,1.17,1.17,0,0,0,1.63.15,1.12,1.12,0,0,0,.42-.8v-.63A1.21,1.21,0,0,0,45.3,109.62Z"
    />
    <path class="cls-2" d="M115.75,118.4a1.19,1.19,0,0,0-1.26,1.07,1.16,1.16,0,1,0,2.32.18A1.16,1.16,0,0,0,115.75,118.4Z" />
    <path class="cls-2" d="M120.11,20.47a.36.36,0,0,0-.4.33v.64a.38.38,0,0,0,.34.4.38.38,0,0,0,.4-.34v-.64A.37.37,0,0,0,120.11,20.47Z" />
    <path class="cls-2" d="M133.4,31.22c-.47,0-.53.74-.06.74S133.88,31.22,133.4,31.22Z" />
    <path class="cls-2" d="M175.17,18.57a.36.36,0,0,0-.4.34v.63a.38.38,0,0,0,.34.4.38.38,0,0,0,.4-.34V19A.37.37,0,0,0,175.17,18.57Z" />
    <path class="cls-2" d="M145.43,35c-.48,0-.54.74-.06.74S145.9,35,145.43,35Z" />
    <path class="cls-2" d="M148,35.65a.38.38,0,0,0-.4.34v.64a.37.37,0,0,0,.34.39.36.36,0,0,0,.4-.33v-.64A.38.38,0,0,0,148,35.65Z" />
    <path class="cls-2" d="M94.47,70.12c-.47,0-.53.73,0,.73S95,70.12,94.47,70.12Z" />
    <path class="cls-2" d="M102.07,71.38c-.47,0-.54.74-.06.74S102.55,71.38,102.07,71.38Z" />
    <path class="cls-2" d="M263.08,82.65c-.48,0-.54.74-.06.74S263.55,82.65,263.08,82.65Z" />
    <path class="cls-2" d="M109.4,164.8c-.47,0-.54.74-.06.74S109.88,164.8,109.4,164.8Z" />
    <path class="cls-2" d="M101.78,119.61a.59.59,0,0,0-.63.54v.63a.58.58,0,0,0,1.16.09v-.63A.6.6,0,0,0,101.78,119.61Z" />
    <path class="cls-2" d="M81.52,111.39a.57.57,0,0,0-.62.53v.63a.6.6,0,0,0,.53.63.59.59,0,0,0,.63-.53V112A.59.59,0,0,0,81.52,111.39Z" />
    <path class="cls-2" d="M186.53,111.7h-.63a.58.58,0,0,0-.62.54.57.57,0,0,0,.53.62h.63a.58.58,0,1,0,.09-1.16Z" />
    <path class="cls-2" d="M163.8,57.59h-.64a.59.59,0,0,0-.62.54.57.57,0,0,0,.53.62h.63a.6.6,0,0,0,.63-.53A.59.59,0,0,0,163.8,57.59Z" />
    <path class="cls-2" d="M163.16,57.59c-.74,0-.84,1.16-.09,1.16S163.91,57.59,163.16,57.59Z" />
    <path class="cls-2" d="M77,1.51h-.63a.59.59,0,0,0-.62.54.57.57,0,0,0,.53.62h.63a.6.6,0,0,0,.63-.53A.59.59,0,0,0,77,1.51Z" />
    <path class="cls-2" d="M40.38,80.13h-.89a.58.58,0,0,0-.09,1.16h.89a.6.6,0,0,0,.63-.54A.59.59,0,0,0,40.38,80.13Z" />
    <path class="cls-2" d="M74.56,119a.59.59,0,0,0-.62.53v.64a.59.59,0,0,0,.53.62.57.57,0,0,0,.62-.53v-.63A.59.59,0,0,0,74.56,119Z" />
    <path class="cls-2" d="M7.59,137.54a.57.57,0,0,0-.62.53v.63a.59.59,0,0,0,.53.63.58.58,0,0,0,.62-.54v-.63A.58.58,0,0,0,7.59,137.54Z" />
    <path class="cls-2" d="M67.6,129.11c-.74,0-.84,1.15-.09,1.15S68.35,129.11,67.6,129.11Z" />
    <path class="cls-2" d="M203,44.3c-.74,0-.84,1.16-.09,1.16S203.78,44.3,203,44.3Z" />
    <path class="cls-2" d="M125.58,129.5c-.74,0-.84,1.16-.09,1.16S126.33,129.5,125.58,129.5Z" />
    <path class="cls-2" d="M129.8,123.59c-.74,0-.84,1.16-.09,1.16S130.55,123.59,129.8,123.59Z" />
    <path class="cls-2" d="M207.43,9.92a.32.32,0,0,0-.35.29v.84a.32.32,0,0,0,.3.34.31.31,0,0,0,.34-.29v-.84A.32.32,0,0,0,207.43,9.92Z" />
    <path class="cls-2" d="M235.26,17.83a.32.32,0,0,0-.34.29V19a.32.32,0,0,0,.29.34.31.31,0,0,0,.34-.29v-.84A.32.32,0,0,0,235.26,17.83Z" />
    <path class="cls-2" d="M209.11,42c-.4,0-.46.63-.05.63S209.52,42,209.11,42Z" />
    <path class="cls-2" d="M29.34,136.52H29c-.41,0-.46.63,0,.63h.37C29.7,137.15,29.75,136.52,29.34,136.52Z" />
    <path
      class="cls-5"
      d="M108.59,73.56c0,.43.41.47.13.75s-.32.87-.76.87-1.1,0-1.38-.24.27-1,.27-1.38-.82-1.37-.54-1.65,1.22.39,1.65.39.91-.21,1.19.08S108.59,73.13,108.59,73.56Z"
    />

    <path
      class="cls-2"
      d="M8.08,124.48a1.17,1.17,0,0,0-.8-.42A1.18,1.18,0,0,0,6,125.13v.63A1.18,1.18,0,0,0,7.1,127a1.14,1.14,0,0,0,.83-.27,1.1,1.1,0,0,0,.42-.8v-.63A1.19,1.19,0,0,0,8.08,124.48Z"
    />
    <path class="cls-2" d="M78.53,133.26a1.16,1.16,0,1,0,1.06,1.25A1.17,1.17,0,0,0,78.53,133.26Z" />
    <path class="cls-2" d="M57.26,85c-.48,0-.54.74-.06.74S57.73,85,57.26,85Z" />
    <path class="cls-2" d="M64.85,86.24c-.47,0-.54.73-.06.73S65.33,86.24,64.85,86.24Z" />
    <path class="cls-2" d="M71.81,89.4a.37.37,0,0,0-.4.34v.63a.38.38,0,0,0,.34.4.38.38,0,0,0,.4-.34V89.8A.38.38,0,0,0,71.81,89.4Z" />
    <path class="cls-2" d="M79.62,97.88a.36.36,0,0,0-.4.33v.64a.38.38,0,0,0,.34.4.38.38,0,0,0,.4-.34v-.64A.36.36,0,0,0,79.62,97.88Z" />
    <path class="cls-2" d="M44.3,126.24a.59.59,0,0,0-.62.53v.64a.59.59,0,0,0,.53.62.58.58,0,0,0,.63-.53v-.63A.6.6,0,0,0,44.3,126.24Z" />
    <path class="cls-2" d="M30.93,113.66c-.75,0-.85,1.15-.1,1.15S31.68,113.66,30.93,113.66Z" />
    <path class="cls-2" d="M54.68,110.47h-.63a.6.6,0,0,0-.63.54.59.59,0,0,0,.53.62h.64a.59.59,0,0,0,.62-.53A.58.58,0,0,0,54.68,110.47Z" />
    <path class="cls-2" d="M43.83,81.33H43.2a.6.6,0,0,0-.63.53.59.59,0,0,0,.53.63h.64a.59.59,0,0,0,.62-.53A.58.58,0,0,0,43.83,81.33Z" />
    <path class="cls-2" d="M3.17,95h-.9a.58.58,0,0,0-.09,1.16h.89a.6.6,0,0,0,.63-.53A.59.59,0,0,0,3.17,95Z" />
    <path class="cls-2" d="M46.84,133.2c-.75,0-.85,1.16-.1,1.16S47.59,133.2,46.84,133.2Z" />
    <path class="cls-2" d="M88.36,144.36c-.74,0-.84,1.15-.09,1.15S89.11,144.36,88.36,144.36Z" />
    <path class="cls-2" d="M91.74,139.29c-.75,0-.85,1.16-.1,1.16S92.49,139.29,91.74,139.29Z" />
    <polygon
      class="cls-2"
      points="235.11 260.41 235.67 257.96 238.11 257.4 235.67 256.85 235.11 254.41 234.56 256.85 232.11 257.4 234.56 257.96 235.11 260.41"
    />
    <polygon
      class="cls-2"
      points="14.32 309.83 15.83 308.46 17.73 309.23 16.36 307.71 17.13 305.82 15.61 307.19 13.72 306.42 15.09 307.94 14.32 309.83"
    />
    <polygon
      class="cls-2"
      points="119.11 336.59 119.68 334.07 122.2 333.5 119.68 332.92 119.11 330.41 118.54 332.92 116.02 333.5 118.54 334.07 119.11 336.59"
    />
    <polygon
      class="cls-2"
      points="182.15 348.52 182.72 346 185.24 345.43 182.72 344.85 182.15 342.34 181.58 344.85 179.06 345.43 181.58 346 182.15 348.52"
    />
    <polygon
      class="cls-2"
      points="76.51 203.25 77.06 201.1 79.51 200.62 77.06 200.13 76.51 197.98 75.95 200.13 73.51 200.62 75.95 201.1 76.51 203.25"
    />

    <path
      class="cls-2"
      d="M169.11,305a1.1,1.1,0,0,1-.8.42h-.63a1.19,1.19,0,0,1-.83-.27,1.17,1.17,0,0,1-.42-.8,1.18,1.18,0,0,1,1.07-1.25h.63a1.18,1.18,0,0,1,1.25,1.07A1.14,1.14,0,0,1,169.11,305Z"
    />
    <path
      class="cls-2"
      d="M152.66,325.28a1.1,1.1,0,0,1-.8.42h-.63a1.26,1.26,0,0,1-.84-.26,1.14,1.14,0,0,1-.41-.8,1.16,1.16,0,0,1,.26-.84,1.14,1.14,0,0,1,.8-.41h.63a1.26,1.26,0,0,1,.84.26,1.17,1.17,0,0,1,.15,1.63Z"
    />
    <path
      class="cls-2"
      d="M42.18,229.43a1.18,1.18,0,0,1-.8.41,1.14,1.14,0,0,1-.83-.26,1.12,1.12,0,0,1-.42-.8v-.64a1.2,1.2,0,0,1,.27-.83,1.17,1.17,0,0,1,.8-.42,1.19,1.19,0,0,1,.83.27,1.13,1.13,0,0,1,.42.8v.63A1.23,1.23,0,0,1,42.18,229.43Z"
    />
    <path
      class="cls-2"
      d="M112.62,220.65a1.19,1.19,0,0,1-1.25-1.07,1.16,1.16,0,0,1,1.07-1.25,1.19,1.19,0,0,1,1.25,1.07A1.18,1.18,0,0,1,112.62,220.65Z"
    />
    <path class="cls-2" d="M44.27,288.36A1.18,1.18,0,0,1,43,287.3a1.16,1.16,0,0,1,1.07-1.25,1.16,1.16,0,1,1,.18,2.31Z" />
    <path class="cls-2" d="M117,318.58a.36.36,0,0,1-.4-.34v-.63a.38.38,0,0,1,.34-.4.38.38,0,0,1,.4.34v.63A.38.38,0,0,1,117,318.58Z" />
    <path class="cls-2" d="M172.05,320.48a.37.37,0,0,1-.4-.34v-.63a.38.38,0,0,1,.34-.4.38.38,0,0,1,.4.34v.63A.38.38,0,0,1,172.05,320.48Z" />
    <path class="cls-2" d="M137.87,306.56c-.47,0-.53-.74-.06-.74S138.35,306.56,137.87,306.56Z" />
    <path class="cls-2" d="M142.3,304c-.47,0-.53-.74-.06-.74S142.78,304,142.3,304Z" />
    <path class="cls-2" d="M144.84,303.39a.36.36,0,0,1-.4-.33v-.64a.37.37,0,1,1,.73-.06V303A.36.36,0,0,1,144.84,303.39Z" />
    <path class="cls-2" d="M91,255.51c-.47,0-.53-.74,0-.74S91.47,255.51,91,255.51Z" />
    <path class="cls-2" d="M46.64,268.06c-.47,0-.53-.73-.06-.73S47.12,268.06,46.64,268.06Z" />
    <path class="cls-2" d="M54.72,337c-.47,0-.54-.74-.06-.74S55.19,337,54.72,337Z" />
    <path class="cls-2" d="M1.81,234.41c-.48,0-.54-.73-.06-.73S2.28,234.41,1.81,234.41Z" />
    <path class="cls-2" d="M195.27,188.12c-.47,0-.54-.73-.06-.73S195.75,188.12,195.27,188.12Z" />
    <path class="cls-2" d="M106.28,174.25c-.48,0-.54-.74-.06-.74S106.75,174.25,106.28,174.25Z" />
    <path class="cls-2" d="M130.64,156.11c-.48,0-.54-.73-.06-.73S131.11,156.11,130.64,156.11Z" />
    <path class="cls-2" d="M98.65,219.43a.57.57,0,0,1-.62-.53v-.63a.6.6,0,0,1,.53-.63.58.58,0,0,1,.62.54v.63A.57.57,0,0,1,98.65,219.43Z" />
    <path class="cls-2" d="M53.09,208h-.64a.59.59,0,0,1-.62-.53.57.57,0,0,1,.53-.62H53a.58.58,0,1,1,.1,1.15Z" />
    <path class="cls-2" d="M78.4,227.66a.57.57,0,0,1-.62-.53v-.63a.58.58,0,1,1,1.15-.1V227A.59.59,0,0,1,78.4,227.66Z" />
    <path class="cls-2" d="M183.41,227.34h-.63a.58.58,0,1,1-.1-1.15h.64a.59.59,0,0,1,.62.53A.57.57,0,0,1,183.41,227.34Z" />
    <path
      class="cls-2"
      d="M199.34,164.32h-.63a.6.6,0,0,1-.63-.54.59.59,0,0,1,.53-.62h.64a.59.59,0,0,1,.62.53A.58.58,0,0,1,199.34,164.32Z"
    />
    <path
      class="cls-2"
      d="M160.67,281.45H160a.58.58,0,0,1-.62-.53.57.57,0,0,1,.53-.62h.63a.59.59,0,0,1,.63.53A.58.58,0,0,1,160.67,281.45Z"
    />
    <path class="cls-2" d="M160,281.45c-.74,0-.84-1.15-.09-1.15S160.79,281.45,160,281.45Z" />
    <path class="cls-2" d="M60.05,248.55c-.75,0-.85-1.16-.1-1.16S60.8,248.55,60.05,248.55Z" />
    <path class="cls-2" d="M68.27,234.62h-.63a.59.59,0,0,1-.62-.53.57.57,0,0,1,.53-.62h.63a.58.58,0,1,1,.09,1.15Z" />
    <path class="cls-2" d="M54.9,237.1h-.63a.59.59,0,0,1-.62-.53.57.57,0,0,1,.53-.62h.63a.58.58,0,1,1,.09,1.15Z" />
    <path class="cls-2" d="M73.87,337.53h-.63a.58.58,0,1,1-.09-1.15h.63a.58.58,0,0,1,.62.53A.57.57,0,0,1,73.87,337.53Z" />
    <path class="cls-2" d="M37.26,258.92h-.89a.6.6,0,0,1-.63-.53.59.59,0,0,1,.53-.63h.9a.58.58,0,0,1,.62.54A.57.57,0,0,1,37.26,258.92Z" />
    <path class="cls-2" d="M259.39,191.55h-.89a.6.6,0,0,1-.63-.53.59.59,0,0,1,.53-.63h.9a.59.59,0,0,1,.62.53A.58.58,0,0,1,259.39,191.55Z" />
    <path class="cls-2" d="M4.47,201.51a.58.58,0,0,1-.63-.53v-.63a.6.6,0,0,1,.54-.63.59.59,0,0,1,.62.53v.64A.59.59,0,0,1,4.47,201.51Z" />
    <path class="cls-2" d="M80.93,220.7c-.74,0-.84-1.16-.09-1.16S81.68,220.7,80.93,220.7Z" />
    <path class="cls-2" d="M46.12,242.85c-.74,0-.84-1.16-.09-1.16S46.87,242.85,46.12,242.85Z" />
    <path class="cls-2" d="M204.3,329.13a.32.32,0,0,1-.34-.29V328a.33.33,0,0,1,.29-.34.32.32,0,0,1,.34.29v.85A.33.33,0,0,1,204.3,329.13Z" />
    <path class="cls-2" d="M232.14,321.22a.32.32,0,0,1-.34-.29v-.84a.32.32,0,1,1,.63-.06v.85A.33.33,0,0,1,232.14,321.22Z" />
    <path
      class="cls-2"
      d="M250.87,287.94a.31.31,0,0,1-.34-.29v-.84a.31.31,0,0,1,.29-.34.31.31,0,0,1,.34.29v.84A.32.32,0,0,1,250.87,287.94Z"
    />
    <path class="cls-2" d="M206,297.06c-.4,0-.46-.63-.05-.63S206.4,297.06,206,297.06Z" />
    <path class="cls-2" d="M26.22,202.53h-.38c-.4,0-.46-.63-.05-.63h.38C26.57,201.9,26.63,202.53,26.22,202.53Z" />
    <path class="cls-5" d="M285.07,240.11c0,.49-1.42.95-1.91.95a.89.89,0,0,1-.83-.95c0-.5.33-.72.83-.72S285.07,239.61,285.07,240.11Z" />
    <path class="cls-5" d="M454.12,291.69c0,.49.35.3-.15.3s-.82.19-.82-.3.33-1.05.82-1.05S454.12,291.19,454.12,291.69Z" />
    <path class="cls-5" d="M463.68,17.19c0,.49.07.92-.43.92s-1.64-.43-1.64-.92,1.14-1.5,1.64-1.5S463.68,16.69,463.68,17.19Z" />
    <path class="cls-5" d="M282.12,95.51c0,.5-1,1.23-1.5,1.23s-.24-.73-.24-1.23-.26-.81.24-.81S282.12,95,282.12,95.51Z" />
    <path class="cls-5" d="M277.49,350.89c0,.5-1.45-.14-1.94-.14s-1,.64-1,.14a1,1,0,0,1,1-.89C276,350,277.49,350.39,277.49,350.89Z" />
    <path
      class="cls-5"
      d="M397,345.37c0,.48,0,1.24-.3,1.55s-1.07.07-1.56.07a1.37,1.37,0,0,1-1-.58c-.32-.31-1-.56-1-1s.95-.46,1.27-.78.29-.77.77-.77a1.3,1.3,0,0,1,1.05.5C396.54,344.64,397,344.88,397,345.37Z"
    />
    <path class="cls-5" d="M452.92,304.38c0,.5-1,1.36-1.49,1.36s-1.82-.86-1.82-1.36,1.33-1.23,1.82-1.23S452.92,303.89,452.92,304.38Z" />
    <path class="cls-5" d="M294.67,59.15c0,.5-.86,1.65-1.35,1.65s-1.53-1.15-1.53-1.65,1-.87,1.53-.87S294.67,58.65,294.67,59.15Z" />
    <path
      class="cls-5"
      d="M254.74,155.9c0,.48.89,1.09.57,1.4a2.35,2.35,0,0,1-1.4.74c-.48,0-.61-.89-.93-1.2s-1.77-.46-1.77-.94,1.85-.22,2.17-.53.05-1.56.53-1.56.73.74,1,1.05S254.74,155.42,254.74,155.9Z"
    />
    <path class="cls-5" d="M246.73,334.82c0,.5.56,1.38.06,1.38s-.22-.88-.22-1.38-.27-1.74.22-1.74S246.73,334.32,246.73,334.82Z" />

    <polygon
      class="cls-2"
      points="450.91 4.54 451.49 7.06 454 7.63 451.49 8.21 450.91 10.72 450.34 8.21 447.82 7.63 450.34 7.06 450.91 4.54"
    />
    <polygon
      class="cls-2"
      points="408.31 137.88 408.87 140.03 411.31 140.51 408.87 141 408.31 143.15 407.76 141 405.31 140.51 407.76 140.03 408.31 137.88"
    />
    <polygon
      class="cls-2"
      points="473.06 112.76 473.64 115.28 476.15 115.85 473.64 116.43 473.06 118.94 472.49 116.43 469.97 115.85 472.49 115.28 473.06 112.76"
    />

    <path
      class="cls-2"
      d="M374,111.71a1.15,1.15,0,0,0-.8-.42,1.21,1.21,0,0,0-.84.26,1.14,1.14,0,0,0-.41.8V113a1.25,1.25,0,0,0,.26.83,1.19,1.19,0,0,0,.8.42,1.23,1.23,0,0,0,.84-.27,1.12,1.12,0,0,0,.41-.8v-.63A1.23,1.23,0,0,0,374,111.71Z"
    />
    <path class="cls-2" d="M294,136.89a1.18,1.18,0,0,0-1.25,1.06,1.16,1.16,0,1,0,1.25-1.06Z" />
    <path
      class="cls-2"
      d="M210.8,34.88A1.18,1.18,0,0,0,209.55,36a1.16,1.16,0,0,0,1.06,1.25,1.18,1.18,0,0,0,1.25-1.07A1.16,1.16,0,0,0,210.8,34.88Z"
    />
    <path class="cls-2" d="M448.79,22.55a.37.37,0,0,0-.39.34v.63a.37.37,0,1,0,.73.06V23A.38.38,0,0,0,448.79,22.55Z" />
    <path class="cls-2" d="M462.08,33.31c-.47,0-.53.73-.05.73S462.56,33.31,462.08,33.31Z" />
    <path class="cls-2" d="M469.68,34.57c-.47,0-.54.74-.06.74S470.16,34.57,469.68,34.57Z" />
    <path class="cls-2" d="M474.11,37.1c-.47,0-.54.74-.06.74S474.59,37.1,474.11,37.1Z" />
    <path class="cls-2" d="M423.16,72.2c-.47,0-.54.73-.06.73S423.63,72.2,423.16,72.2Z" />
    <path class="cls-2" d="M430.75,73.46c-.47,0-.53.74-.06.74S431.23,73.46,430.75,73.46Z" />
    <path class="cls-2" d="M422.8,85.62c-.47,0-.54.74-.06.74S423.28,85.62,422.8,85.62Z" />
    <path class="cls-2" d="M317.75,122.49c-.48,0-.54.73-.06.73S318.22,122.49,317.75,122.49Z" />
    <path class="cls-2" d="M438.08,166.88c-.47,0-.53.74-.06.74S438.56,166.88,438.08,166.88Z" />
    <path class="cls-2" d="M462.44,185c-.47,0-.53.73-.06.73S462.92,185,462.44,185Z" />
    <path class="cls-2" d="M187.67,168.71c-.47,0-.53.74-.06.74S188.15,168.71,187.67,168.71Z" />
    <path class="cls-2" d="M427.94,177.11h-.63a.58.58,0,1,0-.09,1.15h.63a.58.58,0,0,0,.62-.53A.57.57,0,0,0,427.94,177.11Z" />
    <path class="cls-2" d="M479.86,124.4h-.63a.59.59,0,0,0-.62.53.57.57,0,0,0,.53.62h.63a.58.58,0,1,0,.09-1.15Z" />
    <path class="cls-2" d="M491.85,59.68c-.75,0-.85,1.15-.1,1.15S492.6,59.68,491.85,59.68Z" />
    <path class="cls-2" d="M391.85,92.59c-.74,0-.84,1.15-.09,1.15S392.6,92.59,391.85,92.59Z" />
    <path class="cls-2" d="M400.08,106.51h-.63a.6.6,0,0,0-.63.53.59.59,0,0,0,.53.63H400a.59.59,0,0,0,.62-.54A.57.57,0,0,0,400.08,106.51Z" />
    <path class="cls-2" d="M420.58,97.7H420a.6.6,0,0,0-.63.54.59.59,0,0,0,.54.62h.63a.59.59,0,0,0,.62-.53A.58.58,0,0,0,420.58,97.7Z" />
    <path class="cls-2" d="M409.73,68.56h-.63a.58.58,0,1,0-.09,1.16h.63a.58.58,0,0,0,.09-1.16Z" />
    <path class="cls-2" d="M405.68,3.6H405a.59.59,0,0,0-.62.53.57.57,0,0,0,.53.62h.63a.58.58,0,1,0,.1-1.15Z" />
    <path class="cls-2" d="M215.35,149h-.9a.59.59,0,0,0-.62.54.59.59,0,0,0,.53.62h.9a.59.59,0,0,0,.62-.53A.58.58,0,0,0,215.35,149Z" />
    <path
      class="cls-2"
      d="M258.05,152.94a.61.61,0,0,0-.62.61v.72a.64.64,0,0,0,.53.71.61.61,0,0,0,.62-.61v-.72A.64.64,0,0,0,258.05,152.94Z"
    />
    <path class="cls-2" d="M336.27,139.62a.58.58,0,0,0-.62.53v.63a.6.6,0,0,0,.53.63.59.59,0,0,0,.63-.53v-.64A.59.59,0,0,0,336.27,139.62Z" />
    <path class="cls-2" d="M280.77,38.21a.59.59,0,0,0-.63.53v.64a.59.59,0,0,0,.53.62.58.58,0,0,0,.63-.53v-.63A.6.6,0,0,0,280.77,38.21Z" />
    <path class="cls-2" d="M262.39,69.14h-.63a.6.6,0,0,0-.63.53.59.59,0,0,0,.53.63h.64a.59.59,0,0,0,.62-.54A.58.58,0,0,0,262.39,69.14Z" />
    <path class="cls-2" d="M265.55,83.69a.59.59,0,0,0-.62.54v.63a.58.58,0,0,0,1.16.09v-.63A.6.6,0,0,0,265.55,83.69Z" />
    <path class="cls-2" d="M275.05,83.06c-.75,0-.85,1.16-.1,1.16S275.8,83.06,275.05,83.06Z" />
    <path class="cls-2" d="M396.28,131.19c-.74,0-.84,1.16-.09,1.16S397,131.19,396.28,131.19Z" />
    <path class="cls-2" d="M377.93,98.28c-.74,0-.84,1.16-.09,1.16S378.68,98.28,377.93,98.28Z" />
    <path class="cls-2" d="M358,138.6h-.37c-.41,0-.46.63,0,.63H358C358.38,139.23,358.43,138.6,358,138.6Z" />
    <polygon
      class="cls-2"
      points="447.79 338.67 448.36 336.15 450.88 335.58 448.36 335.01 447.79 332.49 447.22 335.01 444.7 335.58 447.22 336.15 447.79 338.67"
    />
    <polygon
      class="cls-2"
      points="405.19 205.33 405.75 203.19 408.19 202.7 405.75 202.21 405.19 200.07 404.63 202.21 402.19 202.7 404.63 203.19 405.19 205.33"
    />
    <polygon
      class="cls-2"
      points="469.94 230.45 470.51 227.93 473.03 227.36 470.51 226.79 469.94 224.27 469.37 226.79 466.85 227.36 469.37 227.93 469.94 230.45"
    />

    <path
      class="cls-2"
      d="M481.34,327.37a1.12,1.12,0,0,1-.8.41h-.63a1.23,1.23,0,0,1-.83-.26,1.15,1.15,0,0,1-.42-.8,1.22,1.22,0,0,1,.26-.84,1.14,1.14,0,0,1,.8-.41h.64a1.25,1.25,0,0,1,.83.26,1.19,1.19,0,0,1,.42.8A1.21,1.21,0,0,1,481.34,327.37Z"
    />
    <path
      class="cls-2"
      d="M441.3,222.73a1.19,1.19,0,0,1-1.25-1.07,1.18,1.18,0,0,1,1.07-1.25,1.19,1.19,0,0,1,1.25,1.07A1.16,1.16,0,0,1,441.3,222.73Z"
    />
    <path
      class="cls-2"
      d="M290.91,206.33a1.18,1.18,0,0,1-1.25-1.07,1.16,1.16,0,0,1,1.06-1.25,1.18,1.18,0,0,1,1.25,1.07A1.16,1.16,0,0,1,290.91,206.33Z"
    />
    <path
      class="cls-2"
      d="M445.67,320.67a.38.38,0,0,1-.4-.34v-.64a.37.37,0,0,1,.34-.39.36.36,0,0,1,.4.33v.64A.38.38,0,0,1,445.67,320.67Z"
    />
    <path class="cls-2" d="M459,309.91c-.47,0-.54-.74-.06-.74S459.44,309.91,459,309.91Z" />
    <path class="cls-2" d="M466.56,308.64c-.48,0-.54-.74-.06-.74S467,308.64,466.56,308.64Z" />
    <path class="cls-2" d="M471,306.11c-.48,0-.54-.74-.06-.74S471.46,306.11,471,306.11Z" />
    <path
      class="cls-2"
      d="M473.52,305.48a.38.38,0,0,1-.4-.34v-.64a.37.37,0,0,1,.34-.39.36.36,0,0,1,.4.34v.63A.38.38,0,0,1,473.52,305.48Z"
    />
    <path class="cls-2" d="M419.68,257.59c-.47,0-.54-.74-.06-.74S420.15,257.59,419.68,257.59Z" />
    <path class="cls-2" d="M314.62,220.73c-.47,0-.53-.74-.06-.74S315.1,220.73,314.62,220.73Z" />
    <path class="cls-2" d="M328.9,351c-.47,0-.54-.74-.06-.74S329.38,351,328.9,351Z" />
    <path class="cls-2" d="M383.4,339c-.47,0-.54-.74-.06-.74S383.88,339,383.4,339Z" />
    <path class="cls-2" d="M459.32,158.2c-.47,0-.54-.74-.06-.74S459.8,158.2,459.32,158.2Z" />
    <path class="cls-2" d="M442.4,258.11a.38.38,0,0,1-.4-.34v-.63a.38.38,0,0,1,.34-.4.38.38,0,0,1,.4.34v.63A.38.38,0,0,1,442.4,258.11Z" />
    <path
      class="cls-2"
      d="M427.33,221.52a.59.59,0,0,1-.62-.54v-.63a.59.59,0,0,1,.53-.62.58.58,0,0,1,.63.53v.63A.6.6,0,0,1,427.33,221.52Z"
    />
    <path class="cls-2" d="M381.77,210.13h-.63a.6.6,0,0,1-.63-.54A.59.59,0,0,1,381,209h.63a.6.6,0,0,1,.63.53A.59.59,0,0,1,381.77,210.13Z" />
    <path class="cls-2" d="M349,170.73h-.64a.59.59,0,0,1-.62-.53.57.57,0,0,1,.53-.62h.63a.59.59,0,0,1,.63.53A.58.58,0,0,1,349,170.73Z" />
    <path class="cls-2" d="M424.82,166.11h-.64a.58.58,0,0,1-.09-1.16h.63a.6.6,0,0,1,.63.53A.59.59,0,0,1,424.82,166.11Z" />
    <path class="cls-2" d="M407.08,229.74a.57.57,0,0,1-.62-.53v-.63a.58.58,0,1,1,1.16-.09v.63A.58.58,0,0,1,407.08,229.74Z" />
    <path
      class="cls-2"
      d="M489.36,259.49h-.64a.59.59,0,0,1-.62-.53.58.58,0,0,1,.53-.63h.63a.6.6,0,0,1,.63.53A.59.59,0,0,1,489.36,259.49Z"
    />
    <path class="cls-2" d="M476.74,218.82h-.63a.6.6,0,0,1-.63-.54.59.59,0,0,1,.54-.62h.63a.58.58,0,0,1,.09,1.16Z" />
    <path
      class="cls-2"
      d="M489.36,283.54h-.64a.59.59,0,0,1-.62-.54.57.57,0,0,1,.53-.62h.63a.6.6,0,0,1,.63.53A.59.59,0,0,1,489.36,283.54Z"
    />
    <path class="cls-2" d="M488.72,283.54c-.74,0-.84-1.16-.09-1.16S489.47,283.54,488.72,283.54Z" />
    <path
      class="cls-2"
      d="M250.21,324.62h-.63a.6.6,0,0,1-.63-.54.59.59,0,0,1,.54-.62h.63a.59.59,0,0,1,.62.53A.58.58,0,0,1,250.21,324.62Z"
    />
    <path class="cls-2" d="M393.7,242.33c-.74,0-.84-1.16-.09-1.16S394.46,242.33,393.7,242.33Z" />
    <path class="cls-2" d="M397,236.71h-.64a.59.59,0,0,1-.62-.54.58.58,0,0,1,.53-.62h.63a.6.6,0,0,1,.63.53A.59.59,0,0,1,397,236.71Z" />
    <path
      class="cls-2"
      d="M417.46,245.51h-.63a.6.6,0,0,1-.63-.53.59.59,0,0,1,.53-.63h.64a.59.59,0,0,1,.62.54A.58.58,0,0,1,417.46,245.51Z"
    />
    <path class="cls-2" d="M402.55,339.62h-.63a.59.59,0,0,1-.62-.54.57.57,0,0,1,.53-.62h.63a.58.58,0,1,1,.09,1.16Z" />
    <path class="cls-2" d="M311.63,209.86a.57.57,0,0,1-.62-.53v-.63a.58.58,0,1,1,1.16-.09v.63A.59.59,0,0,1,311.63,209.86Z" />
    <path
      class="cls-2"
      d="M259.85,226.32a.59.59,0,0,1-.63-.54v-.63a.59.59,0,0,1,.53-.62.58.58,0,0,1,.63.53v.63A.6.6,0,0,1,259.85,226.32Z"
    />
    <path class="cls-2" d="M409.61,222.78c-.74,0-.84-1.15-.09-1.15S410.36,222.78,409.61,222.78Z" />
    <path class="cls-2" d="M277.64,305a.57.57,0,0,1-.62-.53v-.63a.6.6,0,0,1,.53-.63.58.58,0,0,1,.62.53v.64A.59.59,0,0,1,277.64,305Z" />
    <path
      class="cls-2"
      d="M259.27,274.08h-.64a.59.59,0,0,1-.62-.54.57.57,0,0,1,.53-.62h.63a.6.6,0,0,1,.63.53A.59.59,0,0,1,259.27,274.08Z"
    />
    <path class="cls-2" d="M262.43,259.52a.58.58,0,0,1-.62-.53v-.64a.58.58,0,0,1,1.15-.09v.63A.6.6,0,0,1,262.43,259.52Z" />
    <path class="cls-2" d="M481.76,270.25c-.74,0-.84-1.16-.09-1.16S482.51,270.25,481.76,270.25Z" />
    <path class="cls-2" d="M388.73,312a.59.59,0,0,1-.63-.54v-.63a.58.58,0,0,1,.54-.62.57.57,0,0,1,.62.53v.63A.6.6,0,0,1,388.73,312Z" />
  </g>
</svg>
