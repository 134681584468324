import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { PopupRef, PopupService, UTextareaComponent } from '@local/ui-infra';
import { AppPopupComponent, AppPopupData } from '../../app-popup/app-popup.component';
import { take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { KeyName, isEnterKey } from '@local/ts-infra';

type FocusState = 'textArea' | 'leftButton' | 'rightButton';
@UntilDestroy()
@Component({
  selector: 'verification-request-popup',
  templateUrl: './verification-request-popup.component.html',
  styleUrls: ['./verification-request-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerificationRequestPopupComponent implements AfterViewInit, OnDestroy {
  private readonly WARNING_POPUP_DATA: AppPopupData = {
    showButtons: true,
    rightButtonStyle: { type: 'primary', size: 126, fontSize: 14, elementSize: 'small' },
    leftButtonStyle: { size: 126, fontSize: 14, elementSize: 'small' },
    content: {
      secondaryButton: 'Cancel',
      primaryButton: 'Send a request',
    },
    popupStyle: { paddingTop: '32px' },
    message: 'Are you sure you want to leave <br> without sending the request?',
    messageStyle: { fontSize: '14px' },
  };
  private keyHandlerId: string;
  private warningPopupRef: PopupRef<AppPopupComponent, AppPopupData>;
  requestMessage: string = '';
  currentFocus: FocusState;

  @Output() sendMessage = new EventEmitter<string>();
  @ViewChild('textAreaRequest') textAreaRequest: UTextareaComponent;

  get validRequest(): boolean {
    return this.requestMessage?.trim()?.length >= 11;
  }

  constructor(
    private ref: PopupRef<VerificationRequestPopupComponent, any>,
    private cdr: ChangeDetectorRef,
    private popupService: PopupService,
    private keyboardService: KeyboardService
  ) {
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys: Array<KeyName>, event) => this.handleKeys(keys, event), 9);
  }

  ngOnDestroy(): void {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.focusTextArea();
    }, 0);
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    event.stopPropagation();
    const key = keys[0];
    if (key === 'escape') {
      if (this.validRequest) {
        this.openWarningPopup();
      } else {
        this.closePopup();
      }
      return;
    }
    if (isEnterKey(key)) {
      if (this.currentFocus === 'leftButton') {
        this.closePopup();
      } else if (this.currentFocus === 'rightButton') {
        this.sendRequest();
      }
    }
    switch (key) {
      case 'ArrowDown':
        if (this.currentFocus === 'textArea') {
          this.currentFocus = this.validRequest ? 'rightButton' : 'leftButton';
          this.textAreaRequest?.textArea?.nativeElement?.blur();
        }
        break;
      case 'ArrowUp':
        if (['rightButton', 'leftButton'].includes(this.currentFocus)) {
          this.focusTextArea();
        }
        break;
      case 'ArrowRight':
        if (this.currentFocus === 'leftButton' && this.validRequest) {
          this.currentFocus = 'rightButton';
        }
        break;
      case 'ArrowLeft':
        if (this.currentFocus === 'rightButton') {
          this.currentFocus = 'leftButton';
        }
        break;
    }
    this.cdr.markForCheck();
  }

  sendRequest() {
    if (!this.validRequest) {
      return;
    }
    this.sendMessage.emit(this.requestMessage);
    this.closePopup();
  }

  onRequestChange($event) {
    this.requestMessage = $event;
    this.cdr.markForCheck();
  }

  closePopup() {
    this.ref.destroy();
  }

  private openWarningPopup() {
    this.warningPopupRef = this.popupService.open<AppPopupComponent, AppPopupData>('center', AppPopupComponent, this.WARNING_POPUP_DATA, {
      position: 'center',
      closeOnClickOut: true,
      backdropStyle: 'blur-2',
    });
    this.warningPopupRef.compInstance.primaryButton.pipe(take(1), untilDestroyed(this)).subscribe(() => {
      this.sendRequest();
      this.closeWarningPopup();
    });
    this.warningPopupRef.compInstance.secondaryButton.pipe(take(1), untilDestroyed(this)).subscribe(() => {
      this.closeWarningPopup();
      this.closePopup();
    });
  }

  private closeWarningPopup() {
    this.warningPopupRef.destroy();
    this.warningPopupRef = null;
  }

  onFocusTextArea() {
    this.currentFocus = 'textArea';
    this.cdr.markForCheck();
  }

  focusTextArea() {
    this.textAreaRequest?.textArea?.nativeElement.focus();
    this.currentFocus = 'textArea';
    this.cdr.markForCheck();
  }

  clickOutPopup() {
    if (this.warningPopupRef) {
      this.closeWarningPopup();
      return;
    }
    if (this.validRequest) {
      this.openWarningPopup();
    } else {
      this.closePopup();
    }
  }
}
