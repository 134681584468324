import { Links } from '@local/client-contracts';

export type LinkCommandType = 'delete' | 'update' | 'refresh';

export interface LinkCommand {
  type: LinkCommandType;
  id: string;
}
export interface LinkDeleteCommand extends LinkCommand {
  type: 'delete';
}
export interface LinkUpdateCommand extends LinkCommand {
  type: 'update';
  payload: Partial<Links.DisplayItem>;
}
export interface LinkRefreshCommand extends LinkCommand {
  type: 'refresh';
  key: string;
  payload: Partial<Links.DisplayItem>;
}

export const isLinkRefreshCommand = (x: LinkCommand): x is LinkRefreshCommand => x.type === 'refresh';
export const isLinkDeleteCommand = (x: LinkCommand): x is LinkDeleteCommand => x.type === 'delete';
export const isLinkUpdateCommand = (x: LinkCommand): x is LinkUpdateCommand => x.type === 'update';
