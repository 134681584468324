import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Applications, Filters, Links } from '@local/client-contracts';
import { DatePickerType } from '@local/common-web';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Filter, FilterChangeData } from '@shared/components/filters/models';
import { ResultsFiltersBoxComponent } from '@shared/components/filters/results-filters-box/results-filters-box.component';
import { Subscription, filter } from 'rxjs';
import { FiltersService } from 'src/app/bar/services/filters.service';
import { StaticDataService } from 'src/app/bar/services/static-data.service';
import { ResultsFilterEvent, ResultsFilterEventOperation } from 'src/app/bar/views/collections-page/models';

interface LinkTimeFilterOptions {
  appendTo?: boolean;
  disable?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'link-time-filter',
  templateUrl: './link-time-filter.component.html',
  styleUrls: ['./link-time-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkTimeFilterComponent implements OnInit, OnDestroy {
  private readonly SPECIFIC_FILTER = 'specific';
  private readonly RELATIVE_FILTER = 'relative';
  private readonly ALL_PICKER_TYPES: DatePickerType[] = ['before', 'after', 'range'];

  private _currentFilterPolicy: Links.LinkFiltersPolicy;
  private meetingTimeApps: string[];

  get currentFilterPolicy(): Links.LinkFiltersPolicy {
    return this._currentFilterPolicy;
  }

  @Input()
  set currentFilterPolicy(value: Links.LinkFiltersPolicy) {
    this._currentFilterPolicy = value;
    this.setFilters();
  }

  private _filters: Filter[];

  @Input() set filters(v: Filter[]) {
    this._filters = v;
  }

  get filters(): Filter[] {
    return this._filters;
  }

  private filtersSub: Subscription;
  private filterName: string;

  @Output() filterChange = new EventEmitter();
  @Input() app: Applications.DisplayItem;
  @Input() currentFilter: Filters.Values;
  @Input() options: LinkTimeFilterOptions;
  @Input() isDisabledMode: boolean;
  @Input() keepPlaceholder = true;
  @ViewChild(ResultsFiltersBoxComponent) resultsFiltersBox: ResultsFiltersBoxComponent;

  constructor(private filtersService: FiltersService, private staticDataService: StaticDataService, private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.filtersSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.staticDataService
      .getFiltersPolicy$()
      .pipe(
        untilDestroyed(this),
        filter((filter) => Object.keys(filter).includes('appWithMeetingTime'))
      )
      .subscribe((filterPolicy) => {
        this.meetingTimeApps = filterPolicy['appWithMeetingTime'];
        this.setFilters();
      });
  }

  private setFilters() {
    if (!this.meetingTimeApps) {
      return;
    }
    const now = Date.now();
    this.filterName = this.meetingTimeApps.includes(this.app.id) ? 'meetingTimeLinks' : 'timeLinks';
    this.filtersSub?.unsubscribe();
    this.filtersSub = this.filtersService
      .getFloatingResultsFilters({
        sessionName: 'new-link-relative-time',
        typeSuggestedFilters: [],
        baseFilters: [this.filterName].map((n) => ({ name: n })),
        selectedFilters: this.currentFilter ?? {},
        spreadAsMultiSelect: true,
        showSingleItemSelected: true,
        allowEmptyFilter: true,
        timeFilterSettings: {
          pickerTypes: this.ALL_PICKER_TYPES,
          maxDate: new Date(now),
          defaultCustomTimeFilter: 'after',
        },
      })
      .subscribe((f) => {
        this.changeFilterConfig(f);
        this.filters = f;
        this.cdr.markForCheck();
      });
  }

  private changeFilterConfig(filters: Filter[]) {
    if (!filters?.length) {
      return;
    }

    if (this.isDisabledMode) {
      filters[0].picker = 'constant';
    }

    if (this.options?.disable) {
      filters[0].disabled = true;
    }

    const fieldsConfigToChange = {
      appendTo: this.options?.appendTo ? 'body' : null,
      showClearAll: true,
    };

    const filterDetails = filters[0].viewDetails;

    Object.entries(fieldsConfigToChange).forEach(([key, value]) => {
      filterDetails[key] = value;
    });
    this.cdr.markForCheck();
  }

  onFilterChange(data: FilterChangeData) {
    if (['ClearAll', 'Remove'].includes(data.action)) {
      this.currentFilter = null;
    } else {
      const filterChange: ResultsFilterEvent = {
        name: data.name,
        operation: data.action.toLowerCase() as ResultsFilterEventOperation,
        value: data.changes.values?.[0]?.value,
      };
      this.currentFilter = { ...this.currentFilter, [filterChange.name]: [filterChange.value as string] };
    }
    this.filterChange.emit(this.currentFilter);
    this.setFilters();
  }
}
