import { SearchContextData } from './search-context-data';
import { SourceWorkContext } from './source-work-context';

export interface WorkContext extends SearchContextData {
  sourceWorkContexts: SourceWorkContext[];
  orders: {
    resultIndex: number;
    innerIndex: number;
  }[];
}
