import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UInputComponent } from '../u-input/u-input.component';

@Component({
  selector: 'u-input-number',
  templateUrl: './u-input-number.component.html',
  styleUrls: ['./u-input-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UInputNumberComponent {
  private _minValue = 0;
  @Input() inputModel: string;
  @Input() styles: any = {};
  @Input() inputPattern = '^[0-9]+$';
  @Input() set minValue(value: number) {
    this._minValue = value;
    this.setMinAsValue();
    this.cdr.markForCheck();
  }
  @Input() maxValue: number = Number.MAX_VALUE;
  @Input() disabled: boolean;
  @Input() resetToMaxOnClickOut: boolean;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(UInputComponent) uInput: UInputComponent;

  get minValue(): number {
    return this._minValue;
  }

  constructor(private cdr: ChangeDetectorRef) {
    this.setMinAsValue();
    this.cdr.markForCheck();
  }

  private setMinAsValue() {
    if (!this.inputModel) {
      this.inputModel = this.minValue.toString();
    }
  }

  onChangeEvent($event) {
    this.inputModel = $event;
    if (this.inputModel) {
      this.onChange.emit(this.inputModel);
    }
  }

  incrementNumber() {
    let numberValue = Number(this.inputModel);
    if (numberValue < this.maxValue) {
      numberValue++;
      this.inputModel = numberValue.toString();
      this.cdr.markForCheck();
      this.onChange.emit(this.inputModel);
    }
  }

  decrementNumber() {
    let numberValue = Number(this.inputModel);
    if (numberValue > this.minValue) {
      numberValue--;
      this.inputModel = numberValue.toString();
      this.cdr.markForCheck();
      this.onChange.emit(this.inputModel);
    }
  }

  onKeydown($event) {
    if ($event.key === 'Backspace') return;
    const reg = new RegExp(this.inputPattern);
    if (!reg.test($event.key)) {
      $event.preventDefault();
      $event.stopPropagation();
      return;
    }
    const number = Number((this.inputModel || '') + $event.key);
    if (isNaN(number)) {
      if (!this.inputModel) {
        this.inputModel = this.minValue.toString();
      }
      $event.preventDefault();
      $event.stopPropagation();
      return;
    }
    if (number < this.minValue || number > this.maxValue) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  onBlur() {
    if (!this.resetToMaxOnClickOut || !!this.inputModel) {
      return;
    }
    this.onChangeEvent(this.maxValue.toString());
  }
}
