import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayComponent {
  @Input() custom = false;
  @Input() showDynamicEllipsis: boolean;

  @Output() closeOverlay = new EventEmitter<void>();
  @Output() backdropClick = new EventEmitter<MouseEvent>();
  @HostBinding('attr.id') id = Math.abs(Math.floor(Math.random() * 100));

  @HostListener('click', ['$event']) onClick(e: MouseEvent) {
    if (+(e.composedPath()[0] as HTMLElement).id === this.id) this.backdropClick.emit(e);
  }

  onCloseOverlay(): void {
    this.closeOverlay.emit();
  }
}
