import { MemorySearch, Search } from '@local/client-contracts';
import { Queries, QueriesRpcInvoker, QueryResult } from '@local/common-web';
import { EventsService, LogService, ServicesRpcService } from '@shared/services';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { MemorySearchClient } from '../memory-search-client/memory-search-client';
import { SearchRequest } from '../search-request';
import { QueriesSourceSettings } from './queries-source-settings';

export class QueriesSearchClient extends MemorySearchClient<QueriesSourceSettings> {
  private queries: Queries;

  constructor(logService: LogService, memorySearchService: MemorySearchService, private servicesRpc: ServicesRpcService) {
    super(logService, memorySearchService, ['Alphabetical']);
    this.logger = logService.scope('queries');
    this.queries = this.servicesRpc.invokeWith(QueriesRpcInvoker, 'queries');
  }

  protected async rank(queryTokens: string[], items: MemorySearch.Item[], settings: QueriesSourceSettings): Promise<MemorySearch.Item[]> {
    const superRanked = await super.rank(queryTokens, items, settings);
    if (settings.sorting) {
      return superRanked;
    }
    return superRanked.sort((a, b) => {
      const qa = <QueryResult>a.data;
      const qb = <QueryResult>b.data;

      if (qa.class == 'term-in' && qb.class != 'term-in') {
        return 1;
      }

      if (qb.class == 'term-in' && qa.class != 'term-in') {
        return -1;
      }

      return a.index - b.index;
    });
  }

  async getInput(request: SearchRequest<QueriesSourceSettings>): Promise<MemorySearch.Item[]> {
    const results = await this.queries.build({ query: request.query });
    return results.map(
      (data) =>
        <MemorySearch.Item>{
          searchText: data.searchText,
          data,
          sortValue: data.title,
        }
    );
  }

  async getOutput(items: MemorySearch.Item[]): Promise<Search.ResultItem[]> {
    return items.map((x) => ({ ...x.data, url: this.buildUrl(x.data), type: 'query' }));
  }

  private buildUrl(result: QueryResult): string {
    const filters = Object.entries(result.filters || {})
      .filter((x) => x[1])
      .map((x) => 'if-t-' + x[0] + '=' + escape(x[1].join(',')))
      .join('&');
    const query = result.query ? `q=${escape(result.query)}&` : '';
    return `/search?${query}${filters}`;
  }
}
