import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Links, User } from '@local/client-contracts';
import { ResourceAccessType } from 'src/app/bar/components/rlp/choose-resource-access-type/choose-resource-access-type.component';

@Component({
  selector: 'new-link-share',
  templateUrl: './new-link-share.component.html',
  styleUrls: ['./new-link-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewLinkShareComponent {
  @Output() optionChecked = new EventEmitter();
  @Output() cancelButton = new EventEmitter();
  @Output() onActionEvent = new EventEmitter();
  @Output() onShareDoneClick = new EventEmitter<Links.ShareOptions>();

  @Input() tempSharedLinkOption: Links.ShareOptions;
  @Input() focusedButton: number;
  @Input() appId: string;
  @Input() user: User.Info;
  @Input() resourceAccessType: ResourceAccessType;

  get doneButtonDisabled() {
    const option = this.tempSharedLinkOption;
    return option === undefined || (option.level === 'Protected' && !option.accountIds?.length && !option.groupIds?.length);
  }

  onFocusedButton(index: number) {
    this.focusedButton = index;
  }

  onShareDoneClickEmit() {
    this.onShareDoneClick.emit(this.tempSharedLinkOption);
  }
}
