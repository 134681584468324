import { Commands, Search } from '@local/client-contracts';
import { generateId } from '@local/common-web';
import { getExtensionByFileName, generateTitleUrl, getIconByExtension } from '@local/ts-infra';
import { BlobsService } from 'src/app/bar/services/blob.service';
import { CommandsService } from 'src/app/bar/services/commands/commands.service';
import tinymce from 'tinymce';
import { SearchResults } from '../../../results';
import { PreviewService } from 'src/app/bar/services/preview.service';
import { Injectable } from '@angular/core';

@Injectable()
export class WikiCardMenuActions {
  constructor(private commandsService: CommandsService, private blobsService: BlobsService, private previewService: PreviewService) {}

  onPreview() {
    const selectedElement = tinymce.activeEditor.selection.getNode();
    if (selectedElement.tagName.toLocaleLowerCase() === 'img') {
      const name = selectedElement.getAttribute('alt');
      this.openPreview(selectedElement.id, getIconByExtension(getExtensionByFileName(name)), selectedElement.id);
    }
  }

  onDownload() {
    const selectedElement = tinymce.activeEditor.selection.getNode();
    if (selectedElement.tagName.toLocaleLowerCase() === 'img') {
      this.startDownload(selectedElement.id);
    }
  }

  onDelete() {
    tinymce.activeEditor.execCommand('Delete');
  }

  async startDownload(blobId: string) {
    const blobUrl = await this.blobsService.getBlobFullUrl(blobId);

    const command: Commands.DownloadUrl = {
      type: 'download-url',
      url: blobUrl,
    };
    this.commandsService.executeCommand(command);
  }

  async openPreview(blobId: string, iconUrl: string, collectionId: string) {
    const model = await this.buildFileView(blobId, iconUrl, collectionId);
    this.previewService.openPreviewPopup(model, true);
  }

  private async buildFileView(
    blobId: string,
    iconUrl: string,
    collectionId: string
  ): Promise<Search.CollectionFileResultItem & SearchResults> {
    if (!blobId) return;
    const id = generateId();
    const [meta, blobUrl] = await Promise.all([this.blobsService.getBlobMetadata(blobId, null), this.blobsService.getBlobFullUrl(blobId)]);
    return {
      type: 'collection-file',
      kind: 'static-collection-item',
      id,
      fileId: blobId,
      collectionId,
      view: {
        title: {
          text: meta.name,
          onClick: {
            type: 'open-file',
            value: location.origin + '/' + generateTitleUrl('b', meta.name, id),
          } as Commands.DynamicCommand,
          onDrag: { type: 'download-url', url: this.blobsService.getDownloadUrl(blobId) } as Commands.DownloadUrl,
        },
        icon: { lightUrl: iconUrl },
        thumbnail: { url: this.blobsService.getThumbnailUrl(blobId) },
      },
      action: {
        type: 'collection-file',
        click: { primary: { type: 'preview' } },
      },
      blobUrl,
      meta,
    };
  }
}
