<div class="multi-choice-container">
  <div
    class="multi-choice-item"
    *ngFor="let option of options; let i = index"
    (click)="onClickOption(option)"
    (mouseover)="onHoverItem(i)"
    [class.selected]="i === selectedIndex && !option.disabled"
    [class.disabled]="!!option.disabled || notAllowedTooltip"
  >
    <div class="wrapper-item-text">
      <div class="item-text-line" [attr.data-cy]="'item-text-line-' + option.text">
        <u-icon *ngIf="option.icon" class="item-icon" [model]="option.icon"></u-icon>
        <div class="item-text">{{ option.text }}</div>
      </div>
      <div class="item-description">{{ option.description }}</div>
    </div>
    <div
      *ngIf="option.disabled && option.warningText"
      class="warning-icon"
      [escape]="false" 
      [pTooltip]="option.warningText"
      tooltipPosition="left"
      tooltipStyleClass="u-tooltip"
    >
      <div class="icon">!</div>
    </div>
  </div>
</div>
