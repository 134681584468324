import moment from 'moment';
const WEEK_ORDINALS = ['first', 'second', 'third', 'fourth', 'fifth'];

export const getWeekInMonth = (date: Date): number => {
  return Math.floor((date.getDate() - 1) / 7) + 1;
};

export const getOrdinalWeek = (date: Date): string => {
  const weekInMonthNum: number = getWeekInMonth(date);
  if (weekInMonthNum >= 1 && weekInMonthNum <= 5) {
    return getOrdinal(weekInMonthNum - 1);
  }
  return '';
};

export const getOrdinal = (num: number) => {
  return WEEK_ORDINALS[num];
};

export const geDayOfWeek = (date: Date): string => {
  const dayOfWeek = moment(date).format('dddd');
  return dayOfWeek;
};

export const getDayOfWeekByNumber = (num: number): string => {
  return moment().day(num).format('dddd');
};
