import { Chats } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class ChatsRpcInvoker implements Chats.Service {
  @invoker
  get all$(): Observable<Chats.ChatSessionsByAssistant> {
    return;
  }

  @invoker
  createSession(assistantId: string, newChatId: string, currentChatId?: string): Promise<void> {
    return;
  }

  @invoker
  createHistoryItem(history: Chats.ChatHistoryItem) {
    return;
  }
}
