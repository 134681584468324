import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@Component({
  selector: 'disclaimer-alert',
  templateUrl: './disclaimer-alert.component.html',
  styleUrls: ['./disclaimer-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class DisclaimerAlertComponent {
  DISCLAIMER_MESSAGE = 'AI can sometimes make mistakes. Please check for accuracy before using responses.';
  disableDisclaimer: false;
  @Input() message: string = this.DISCLAIMER_MESSAGE;
}
