import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { Accounts, Groups, Links, Style, User, Workspace } from '@local/client-contracts';
import { FilterInvoker, PopupRef } from '@local/ui-infra';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RestrictedConstant } from '@shared/consts';
import { EventsService } from '@shared/services';
import { AccountsService } from '@shared/services/accounts.service';
import { GroupsService } from '@shared/services/groups.service';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { getModifiers, isEnterKey, KeyName, capitalCase } from '@local/ts-infra';
import { cloneDeep } from 'lodash';
import { combineLatest } from 'rxjs';
import { LinkFullDisplayItemShareOptions } from '../../models/link-full-display-item.model';
import { WorkspacesService } from '../../services';
import { HubService } from '../../services/hub.service';
import { getDefaultProfile } from '@shared/utils/set-icon.util';
import { OptionsPopupComponent } from '../../views/hub/components/launcher-avatar-popup/options-popup.component';
import { IdentitiesSuggestionsService, SuggestionsList } from '../../services/identities-suggestions.service';
export interface LinkOptionModel {
  checked?: boolean;
  level: Links.LinkLevel;
  restricted?: boolean;
}

export interface ShareOptionsComponentOptions {
  isPopUp: boolean;
  hasBorder: boolean;
  allowPopupHeader: boolean;
  showPrivateLink: boolean;
  showSubTitle: boolean;
  showDeleteLinkMsg: boolean;
  showButtons: boolean;
  showRestricted: boolean;
  updateGroupAndMembers?: string;
  showTitle?: string;
  isPopUpWithAllContent?: boolean;
  showFooterButtons?: boolean;
  shareLevelTelemetryName?: string;
  checkedOptionIndex?: number;
}
export interface ShareOptionsModel {
  link?: LinkFullDisplayItemShareOptions;
  workspaceLogo?: Style.Icon;
  linkColor?: Links.Colors;
  componentOptions: ShareOptionsComponentOptions;
  headerIcon?: Style.Icon;
}

export type SelectedList = (Accounts.WorkspaceAccount | Groups.WorkspaceGroup)[];

type UpdatePopupDetails = { shareOptions: Links.ShareOptions; selectedList: SelectedList };
@UntilDestroy()
@Component({
  selector: 'share-options',
  templateUrl: './share-options.component.html',
  styleUrls: ['./share-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareOptionsComponent implements OnInit, OnDestroy {
  private workspace: Workspace.Workspace;
  readonly suggestionFallBack = getDefaultProfile();
  options: LinkOptionModel[] = [];
  readonly MEMBER_ICON_FALLBACK = getDefaultProfile();
  isLoading: boolean;
  activeIndex = 0;
  focusedIndex: number;
  suggestions: SuggestionsList = [];
  shareOptions: Links.ShareOptions;
  selectedSuggestions: SelectedList = [];
  updateMode: boolean;
  model: ShareOptionsModel;
  openedPanel: boolean;
  private keyHandlerId: string;
  readonly restrictedTooltip: string = RestrictedConstant.TOOLTIP;
  private isChanges: boolean;
  filterTags: FilterInvoker;

  @Input() user: User.Info;
  @Input() appId: string;

  @Input() componentOptions: ShareOptionsComponentOptions;

  @Output() optionChecked = new EventEmitter<Links.ShareOptions>();
  @ViewChild('autoComplete') autoCompleteRef: any;
  @Output() updateButton = new EventEmitter<UpdatePopupDetails>();
  @Output() cancelButton = new EventEmitter<Links.ShareOptions>();
  @Output() focusButton = new EventEmitter<number>();
  @Output() deleteLink = new EventEmitter();

  constructor(
    private cdr: ChangeDetectorRef,
    private eventsService: EventsService,
    private groupsService: GroupsService,
    private accountsService: AccountsService,
    private keyboardService: KeyboardService,
    private workspacesService: WorkspacesService,
    private hubService: HubService,
    @Optional() private ref: PopupRef<OptionsPopupComponent, ShareOptionsModel>,
    private identitiesSuggestionsService: IdentitiesSuggestionsService
  ) {
    this.filterTags = (event): SuggestionsList => this.identitiesSuggestionsService.searchSuggestions(event.query, this.suggestions);
  }

  get disableUpdate() {
    if (this.componentOptions.isPopUpWithAllContent) {
      if (this.updateMode) {
        const sameShareLevel = this.model.link.shareOptions.level === this.shareOptions.level;
        if (this.shareOptions.level === 'Protected' && this.selectedSuggestions.length === 0) return true;
        return sameShareLevel && (this.shareOptions.level !== 'Protected' || this.isSameSelectedSuggestions());
      }
      return this.activeIndex === null || (this.activeIndex === 1 && this.selectedSuggestions.length === 0);
    } else {
      return !this.activeIndex;
    }
  }

  ngOnDestroy(): void {
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
  }

  removeOwner(list: Accounts.WorkspaceAccount[]) {
    if (this.componentOptions.isPopUpWithAllContent) {
      return list?.filter((item) => item.id !== this.workspace.accountId);
    } else {
      return list?.filter((item) => !item.isOwner);
    }
  }

  get userAccess() {
    return this.componentOptions.isPopUpWithAllContent ? 'Owner' : this.workspace?.isOwner ? 'Owner' : 'Admin';
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 10);
    this.model = this.ref?.data;
    this.componentOptions = this.componentOptions || this.model?.componentOptions;
    combineLatest([this.workspacesService.current$, this.accountsService.all$, this.groupsService.all$]).subscribe({
      next: ([workspace, accounts, groups]) => {
        if (!workspace) {
          return;
        }
        this.workspace = cloneDeep(workspace);
        accounts = accounts?.filter((item) => item.id !== this.workspace.accountId); // exclude "me"
        this.workspace.accounts = accounts;
        this.workspace.groups = groups?.map((g) => ({ ...g, name: capitalCase(g.name) }));
        if (this.appId) {
          this.eventsService.event('pageview', { location: this.getLocation() });
        }
        if (this.model?.link && !this.model?.link?.accounts && !this.model?.link?.groups) {
          this.updateModelValues();
          this.selectedSuggestions = [...(this.model?.link?.accounts || []), ...(this.model?.link?.groups || [])];
        }
        this.initLinkOption();
        this.isLoading = false;
        this.suggestions = [
          { name: 'Members', items: this.workspace.accounts },
          { name: 'Groups', items: this.workspace.groups },
        ];
        this.cdr.markForCheck();
      },
    });

    this.updateMode = this.model?.link ? true : false;
    this.selectedSuggestions = [...(this.model?.link?.accounts || []), ...(this.model?.link?.groups || [])];
    this.focusedIndex = this.updateMode ? 1 : 0;
    this.cdr.markForCheck();
  }

  updateModelValues() {
    this.model.link.accounts = [];
    this.model.link.shareOptions.accountIds?.forEach((account) => {
      const found = this.workspace.accounts.find((w) => w.id === account);
      this.model.link.accounts.push(found);
    });
    this.model.link.groups = [];
    this.model.link.shareOptions.groupIds?.forEach((group) => {
      const found = this.workspace.groups.find((w) => w.id === group);
      this.model.link.groups.push(found);
    });
  }

  isSameSelectedSuggestions() {
    const initData: any = [...(this.model.link?.accounts || []), ...(this.model?.link?.groups || [])];
    if (initData.length !== this.selectedSuggestions.length) return false;
    for (let i = 0; i < initData.length; i++) {
      if (!this.selectedSuggestions.some((s) => s?.id === initData[i]?.id)) {
        return false;
      }
    }
    return true;
  }

  sentSharedEvent(target: string) {
    this.eventsService.event('link_action.shared_link_members', {
      target,
      name: this.componentOptions.shareLevelTelemetryName ?? 'shared_link_members',
      location: this.getLocation(),
      jsonData: this.updateMode ? JSON.stringify({ link_id: [this.model.link.id] }) : null,
    });
  }

  initLinkOption() {
    this.options = [{ level: 'Private' }, { level: 'Protected' }, { level: 'Public' }];

    if (this.updateMode) {
      this.setUpdateOption();
      return;
    }
    const checkedIndex = this.componentOptions.checkedOptionIndex;
    this.activeIndex = checkedIndex;
    this.options[checkedIndex].checked = true;
    this.emitOptionChecked(checkedIndex);
  }

  setUpdateOption() {
    this.shareOptions = this.model?.link?.shareOptions;
    const index = this.options.findIndex((o) => o.level === this.shareOptions?.level);
    this.options[index].restricted = this.model?.link?.shareOptions?.restricted;
    this.options[index].checked = true;
    this.activeIndex = index;
    this.appId = this.model.link.appId;
    setTimeout(() => {
      if (this.activeIndex === 1) {
        this.focusInput();
      }
    }, 0);
  }

  private emitOptionChecked(index?: number) {
    const optionIndex = index ?? this.activeIndex;
    this.shareOptions = { level: this.options[optionIndex].level };
    this.shareOptions.restricted = this.options[optionIndex].restricted;
    if (optionIndex === 1) {
      this.shareOptions.accountIds = this.getMembers();
      this.shareOptions.groupIds = this.getGroups();
    }
    if (this.updateMode) return;
    this.optionChecked.emit(this.shareOptions);
  }

  getMembers(): string[] {
    return this.selectedSuggestions?.filter((i) => 'email' in i).map((e) => e.id);
  }

  getGroups(): string[] {
    return this.selectedSuggestions?.filter((i) => 'membersCount' in i).map((e) => e.id);
  }

  clickCheckbox(event: PointerEvent | CustomKeyboardEvent, index: number, target?: string) {
    event?.preventDefault();
    if (this.options[index].checked) {
      this.options[index].checked = false;
      this.options[index].restricted = false;
      this.activeIndex = null;
      this.optionChecked.emit();
      return;
    }
    this.activeIndex = index;
    this.focusedIndex = this.activeIndex;
    this.options.map((o) => (o.checked = false));
    this.options[index].checked = true;
    const restrictedOption = this.options.find((o) => o.restricted);
    if (restrictedOption) {
      this.options[index].restricted = index !== 0;
      restrictedOption.restricted = false;
    }
    if (this.activeIndex === 1) {
      this.focusInput();
    }
    this.emitOptionChecked();
    this.sentLinkAccessEvent();
  }

  private sentLinkAccessEvent() {
    const level = this.options[this.activeIndex].level;
    this.eventsService.event('link_action.link_access', {
      location: this.getLocation(),
      name: this.componentOptions.shareLevelTelemetryName ?? 'link_access',
      target: level === 'Protected' ? 'members' : level === 'Public' ? 'worksapce' : 'private',
    });
  }

  focusInput() {
    this.autoCompleteRef?.autoComplete?.el?.nativeElement?.children[0]?.children[0]?.lastChild?.firstChild?.focus();
  }

  typeOfWorkspaceAccount(item: Accounts.WorkspaceAccount | Groups.WorkspaceGroup): boolean {
    return item && 'email' in item;
  }

  onSelectedSuggestions(event) {
    let target;
    if (!event) {
      target = [];
      event = [];
    } else if (this.selectedSuggestions.length > event.length) {
      const removedItem = this.selectedSuggestions[this.selectedSuggestions.length - 1];
      target = this.typeOfWorkspaceAccount(removedItem) ? 'remove_member' : 'remove_group';
    } else {
      target = this.typeOfWorkspaceAccount(event[event.length - 1]) ? 'select_member' : 'select_group';
    }

    this.sentSharedEvent(target);

    this.openedPanel = true;
    this.selectedSuggestions = event;
    if (!this.options[1].checked) this.clickCheckbox(null, 1);
    this.emitOptionChecked();
    this.isChanges = true;
  }

  getLocation() {
    const suffix = this.updateMode ? 'link_edit' : 'link_access';
    if (this.componentOptions.isPopUpWithAllContent) {
      return { title: `${this.hubService.currentLocation}/${suffix}` };
    }
    return { title: `/connect_apps/${this.appId}/${suffix}` };
  }

  onClickRestricted(event: PointerEvent | KeyboardEvent) {
    if (this.updateMode) return;
    event.preventDefault();
    event.stopPropagation();
    if (this.activeIndex === 0 || !this.options?.[this.activeIndex]) return;
    this.options[this.activeIndex].restricted = !this.options[this.activeIndex]?.restricted;
    this.emitOptionChecked();

    this.eventsService.event('link_action.shared_link_members', {
      label: this.options[this.activeIndex].restricted ? 'restricted' : 'unrestricted',
      target: 'restricted_checkbox',
      location: this.getLocation(),
    });
  }

  onUpdateButton(event) {
    if (this.activeIndex === null || this.activeIndex === undefined) return;
    event.stopPropagation();
    this.updateButton.emit({ shareOptions: this.shareOptions, selectedList: this.selectedSuggestions });
  }

  onCancelButton(event) {
    event.stopPropagation();
    this.cancelButton.emit(this.shareOptions);
  }

  onDelete(event) {
    event.stopPropagation();
    this.deleteLink.emit(true);
    this.close();
  }

  close() {
    this.ref.destroy();
  }

  onAutoCompleteClicked(event) {
    if (!this.isChanges && event.path?.find((e) => e?.classList?.contains('p-autocomplete-multiple-container'))) {
      this.sentSharedEvent('search_member');
      if (this.activeIndex !== 1) {
        this.clickCheckbox(event, 1);
      }
    }
    this.isChanges = false;
  }

  isOverlayVisible() {
    return this.autoCompleteRef?.autoComplete?.overlayVisible;
  }

  handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    if (this.openedPanel) {
      event.stopPropagation();
      return;
    }

    const key = keys[0];
    const modifiers = getModifiers(keys);
    const lengthArrayOptions: number = this.options.length - 1;
    const elementsLength: number = this.updateMode ? lengthArrayOptions + 2 : lengthArrayOptions + 3;

    if ((isEnterKey(key) || key === 'space') && !this.isOverlayVisible()) {
      if (this.activeIndex !== undefined && this.focusedIndex <= lengthArrayOptions) {
        this.clickCheckbox(event, this.focusedIndex, 'keyboard');
      } else if (this.focusedIndex === elementsLength) {
        this.onUpdateButton(event);
      } else if (this.focusedIndex === elementsLength - 1) {
        this.onCancelButton(event);
      } else if (this.componentOptions.showRestricted && this.focusedIndex === lengthArrayOptions + 1) {
        this.onClickRestricted(event);
      }
      this.cdr.markForCheck();
      event.stopPropagation();
      return;
    }

    if (key === 'tab') {
      if (modifiers[0] === 'shift') {
        if (this.focusedIndex === undefined) {
          this.focusedIndex = elementsLength;
        } else if (this.focusedIndex === 0) {
          this.focusedIndex = undefined;
        } else {
          this.focusedIndex--;
        }
      } else if (this.focusedIndex === undefined) {
        this.focusedIndex = this.updateMode ? 1 : 0;
      } else if (this.focusedIndex === elementsLength) {
        this.focusedIndex = undefined;
      } else {
        this.focusedIndex++;
      }

      if (!this.updateMode && elementsLength - this.focusedIndex <= 1) {
        this.focusButton.emit(elementsLength === this.focusedIndex ? 1 : 0);
      } else this.focusButton.emit();

      event.preventDefault();
      event.stopPropagation();
      this.cdr.markForCheck();
      return;
    }
  }

  openLearnMore(event) {
    window.open(RestrictedConstant.URL, '_blank');
    event.stopPropagation();
  }
}
