<span class="text"><ng-content></ng-content></span>

<div class="loader-container" [style]="loaderStyle">
  <div class="circle" [style]="circleStyle">
    <div class="inner"></div>
  </div>
  <div class="circle" [style]="circleStyle">
    <div class="inner"></div>
  </div>
  <div class="circle" [style]="circleStyle">
    <div class="inner"></div>
  </div>
</div>
