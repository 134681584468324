<overlay [custom]="true" class="drag-trap waiting-auth">
  <div class="inner">
    <div class="logo-container">
      <div>
        <loader [size]="60"></loader>
      </div>
    </div>
    <div class="title">Waiting for authorization</div>
    <div class="content">Please finish authorization on your browser</div>
    <div class="actions" *ngIf="showCancel">
      <button class="btn" (click)="onCancelClick()" [clickTrack]="clickTrack">Cancel</button>
    </div>

    <div class="external-link" [class.hidden]="!link || !showLink || !showCancel">
      Not working?
      <a
        [pTooltip]="tooltipText"
        tooltipPosition="top"
        tooltipStyleClass="u-tooltip"
        [href]="link"
        (contextmenu)="onRightClick($event)"
        (auxclick)="onRightClick($event)"
        (click)="onLinkClicked($event)"
        class="external-link-link"
      >
        Click here to open
      </a>
    </div>
  </div>
</overlay>
