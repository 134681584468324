import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UiIconModel } from '@local/ui-infra';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'fyi-empty-state',
  templateUrl: './fyi-empty-state.component.html',
  styleUrls: ['./fyi-empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FyiEmptyStateComponent {
  readonly emptyStateIcon: UiIconModel = {
    type: 'img',
    value: {
      lightUrl: './assets/bar/fyi/empty-state-light.svg',
    },
  };

  constructor() {}
}
