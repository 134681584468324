import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Applications, Links, Results, Workspace } from '@local/client-contracts';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventsService, ServicesRpcService, componentServicesRpcProvider } from '@shared/services';
import { LinksService } from '@shared/services/links.service';
import { NativeAppLinkService } from '@shared/services/native-app-link.service';
import { RouterService } from '@shared/services/router.service';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';
import { WorkspacesService } from 'src/app/bar/services';
import { PcPreferencesService } from '../../../../services/pc-preferences.service';
import { State } from '../apps-state';

@UntilDestroy()
@Component({
  selector: 'app-list-item',
  templateUrl: './app-list-item.component.html',
  styleUrls: ['../shared.scss', './app-list-item.component.scss'],
  providers: [componentServicesRpcProvider],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppListItemComponent implements OnDestroy, OnInit {
  status$ = new BehaviorSubject<'no-links' | 'stale' | 'sync-failed' | 'success'>('success');
  showLocal$ = new BehaviorSubject<boolean>(false);
  staleText: string[];
  @Input() mode: 'side' | undefined;
  @Input() telemetryLocation: string;
  private _model: Applications.DisplayItem;
  private _links: Links.DisplayItem[];
  private ownerOrAdmin: boolean;
  stateItem: State;
  workspaceIcon: Results.Icon;
  sharedLinks: Record<string, boolean> = {};
  showShareableApp: boolean;
  sharedTooltip: string;
  hasSharedLinks: number;
  workspaceType: Workspace.WorkspaceType;
  isPcLink: boolean;
  isPcEnable: boolean;
  isNativeRunning: boolean;

  get model() {
    return this._model;
  }
  get links() {
    return this._links;
  }

  @Input() set links(links: Links.DisplayItem[]) {
    this._links = links;
    this.setSharedLinks();
    this.hasSharedLinks = this.links?.filter((l) => l.shareOptions?.level !== 'Private')?.length;
  }
  @Input() disabled: boolean;

  @Input() set model(value: Applications.DisplayItem) {
    if (!value) return;
    this._model = value;
    this.isPcLink = value.id === 'pc';
    if (Object.keys(this.sharedLinks).length) {
      this.updateStates(value);
      this.cdr.markForCheck();
    }
  }

  @HostBinding('class') get ngClass() {
    if (this.status$.getValue()) {
      return { [this.status$.getValue()]: true };
    }
  }

  constructor(
    private eventsService: EventsService,
    private routerService: RouterService,
    private activeRoute: ActivatedRoute,
    private services: ServicesRpcService,
    private cdr: ChangeDetectorRef,
    private pcPreferencesService: PcPreferencesService,
    private nativeAppLinkService: NativeAppLinkService,
    private linksService: LinksService,
    private workspaceService: WorkspacesService
  ) {}

  ngOnInit(): void {
    this.workspaceService.ownerOrAdmin$.pipe(untilDestroyed(this)).subscribe((s) => {
      this.showShareableApp = this.ownerOrAdmin = s;
      this.setSharedLinks();
      this.cdr.markForCheck();
    });
    this.workspaceService.current$
      .pipe(
        untilDestroyed(this),
        filter((ws) => !!ws)
      )
      .subscribe((workspace) => {
        this.workspaceIcon = this.workspaceService.getLogo();
        this.workspaceType = workspace.type;
        this.updateStates(this._model);
        this.cdr.markForCheck();
      });

    this.nativeAppLinkService.canSearchPc$?.pipe(untilDestroyed(this)).subscribe((s) => {
      this.isNativeRunning = s;
      this.cdr.markForCheck();
    });

    firstValueFrom(this.linksService.all$).then((links) => {
      this.isPcEnable = links.some((l) => l.appId === 'pc');
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.services.destroy();
  }

  @HostListener('click')
  onAppItemClick($event: MouseEvent): void {
    if (this.isPcLink || this.disabled) return;
    if (this.model.links?.length === 0) {
      this.selectAndRedirect();
    } else {
      this.selectAndRedirect();
    }
  }

  setSharedLinks(): void {
    if (this.links) {
      this.links.forEach((link) => {
        const condition = this.showShareableApp ? link.shareOptions?.level.toLowerCase() !== 'private' : !link.ownedByMe;
        this.sharedLinks[link.name] = condition;
      });
    }
  }

  selectAndRedirect(oauthSessionId?: string) {
    const route = ['connect', this.model.id];
    if (this.model.links?.length === 0) {
      this.eventsService.event('app_item.connect', {
        location: { title: this.fetchSource() },
        label: this.model.id,
      });
    } else {
      this.eventsService.event('app_actions', {
        location: { title: this.fetchSource() },
        label: this.model.id,
      });
    }
    this.routerService.navigate(route, {
      queryParams: {
        source: this.fetchSource(),
        published: this.model.published,
        oauthSessionId: oauthSessionId,
      },
    });
  }

  fetchSource(): string {
    return this.telemetryLocation || this.activeRoute.snapshot.url[0].path;
  }

  private updateStates(model: Applications.DisplayItem) {
    const { published, links, syncStatus } = model;

    if (links?.length <= 0) {
      this.status$.next('no-links');
    } else {
      switch (syncStatus) {
        case 'stale': {
          this.stateItem = 'Outdated';
          this.staleText = ['A link is', 'outdated'];
          if (links?.length > 1) this.staleText = ['Some links are', 'outdated'];
          const displayStale = links?.some((link) => link.stale && (!this.sharedLinks[link.name] || link.ownedByMe || this.ownerOrAdmin));
          this.status$.next(displayStale ? 'stale' : 'success');
          break;
        }
        case 'initial': {
          this.stateItem = 'Syncing';
          this.status$.next('success');
          break;
        }
        case 'failed': {
          this.stateItem = 'Failed';
          this.status$.next('sync-failed');
          break;
        }
        default: {
          this.stateItem = 'Connected';
          this.status$.next('success');
          break;
        }
      }
    }

    // Local
    //&& ['stale', 'sync-failed'].includes(this.status$.getValue())
    this.showLocal$.next(!published);
  }

  async togglePcLink() {
    this.isPcEnable = !this.isPcEnable;
    if (this.isPcEnable) {
      await this.pcPreferencesService.createPcLink();
    } else await this.pcPreferencesService.deletePcLink();
    this.cdr.markForCheck();
    this.eventsService.event('app_item.connect_pc', { target: this.isPcEnable ? 'connect_on' : 'connect_off' });
  }
}
