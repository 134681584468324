import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { PopupRef, UInputNumberComponent } from '@local/ui-infra';
import * as moment from 'moment';
import { IntervalModel, IntervalType } from '../../../services/custom-interval.service';
import { geDayOfWeek, getOrdinalWeek } from '../../../utils/custom-interval-utils';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { KeyName } from '@local/ts-infra';

export type CustomIntervalData = {
  selectedDate: Date;
  optionType: IntervalType;
};
type RepeatOptionType = 'week' | 'month' | 'year' | 'day';
type RepeatOption = { label: string; value: RepeatOptionType };
type MonthlyOptionType = 'weekly' | 'specific';
type MonthlyOption = { label: string; value: MonthlyOptionType };
@Component({
  selector: 'custom-interval',
  templateUrl: './custom-interval.component.html',
  styleUrls: ['./custom-interval.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomIntervalComponent implements AfterViewInit, OnDestroy {
  private readonly defaultRepeatOption: RepeatOptionType = 'week';
  readonly REPEAT_OPTIONS: RepeatOption[] = [
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
    { label: `Year`, value: 'year' },
    { label: 'Day', value: 'day' },
  ];
  private inputDate: Date;
  private repeatInterval: number = 1;
  private keyHandlerId: string;
  weekdays: { value: string; selected: boolean; index: number }[];
  selectedRepeatOption: RepeatOption;
  selectedMonthlyOption: MonthlyOption;
  inputDateFormatDate: string;
  monthlyOptions: MonthlyOption[];
  existWeekdaysSelected: boolean;
  @Output() selectCustomInterval = new EventEmitter<IntervalModel>();
  @ViewChild(UInputNumberComponent) uInputNumber: UInputNumberComponent;

  constructor(
    private ref: PopupRef<CustomIntervalComponent, CustomIntervalData>,
    private cdr: ChangeDetectorRef,
    private keyboardService: KeyboardService
  ) {
    this.initData(ref.data);
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 10);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.uInputNumber?.uInput?.focusInput();
    }, 0);
  }

  ngOnDestroy(): void {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    }
  }

  private initData(data: CustomIntervalData) {
    this.inputDate = data.selectedDate;
    const selectedType = ['monthWeekday', 'monthDate'].includes(data.optionType) ? 'month' : data.optionType;
    this.selectedRepeatOption = this.REPEAT_OPTIONS.find((op) => op.value === selectedType);
    if (!this.selectedRepeatOption) {
      this.selectedRepeatOption = this.REPEAT_OPTIONS.find((op) => op.value === this.defaultRepeatOption);
    }
    this.initWeekdays();
    this.initMonthlyOptions();
    this.inputDateFormatDate = moment(this.inputDate).format('MMMM DD');
    this.cdr.markForCheck();
  }

  get disableSaveButton(): boolean {
    return this.selectedRepeatOption.value === 'week' && !this.existWeekdaysSelected;
  }

  private initWeekdays() {
    const inputDay: number = this.inputDate.getDay();
    this.weekdays = moment.weekdays().map((d, index) => ({ value: d.charAt(0), selected: inputDay === index, index }));
    this.existWeekdaysSelected = true;
  }

  private initMonthlyOptions() {
    const dayOfMonth = this.inputDate.getDate();
    const dayOfWeek = geDayOfWeek(this.inputDate);
    const monthlyOnDate = `Monthly on the ${dayOfMonth}st`;
    const monthlyOnDayOfWeek = `Monthly on the ${getOrdinalWeek(this.inputDate)} ${dayOfWeek}`;
    this.monthlyOptions = [
      { label: monthlyOnDate, value: 'specific' },
      { label: monthlyOnDayOfWeek, value: 'weekly' },
    ];
    this.selectedMonthlyOption = this.monthlyOptions[0];
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    const key = keys[0];
    if (key === 'escape') {
      this.closePopup();
      event.stopPropagation();
      return;
    }
  }

  onRepeatOptionSelect(option: RepeatOption) {
    this.selectedRepeatOption = option;
  }

  onMonthlyOptionSelect(option: MonthlyOption) {
    this.selectedMonthlyOption = option;
  }

  onSelectWeekday(index: number) {
    this.weekdays[index].selected = !this.weekdays[index].selected;
    this.existWeekdaysSelected = !!this.weekdays.find((w) => w.selected);
    this.cdr.markForCheck();
  }

  onChangeInterval($event) {
    this.repeatInterval = Number($event);
  }

  closePopup() {
    this.ref.destroy();
  }

  done() {
    let name: string = this.selectedRepeatOption.value;
    if (this.selectedRepeatOption.value === 'month') {
      switch (this.selectedMonthlyOption.value) {
        case 'specific':
          name = 'monthDate';
          break;
        case 'weekly':
          name = 'monthWeekday';
          break;
      }
    }
    const weekdays = this.weekdays.filter((d) => d.selected).map((day) => day.index);
    const model: IntervalModel = { name: name as IntervalType, interval: this.repeatInterval || 1, weekdays };
    this.selectCustomInterval.emit(model);
    this.closePopup();
  }
}
