export const questionnaireContent = {
  upload: 'Upload',
  mapping: 'Mapping',
  processing: 'Processing',
  summary: 'Summary',
  uploadTitle: 'Please upload your file',
  uploadSubTitle: 'This will allow us to identify the columns-containing questions, and automatically provide accurate answers.',
  mappingTitle: 'Mapping your Questions',
  mappingSubTitle: 'We’ve made automatic predictions for the question columns and the answer columns that Unleash will fill.',
  learnMore: 'Learn more',
  mappingDescription: '*You have the option to edit our selection or add more columns ',
  processingTitle: 'Processing',
  processingSubTitle: 'This may take few minutes..',
  prepareProcessing: 'Setting up process...',
  preparing: 'Preparing your updated file',
  summaryTitle: 'RFP is Ready!',
  summarySubTitle: 'Yay! The auto-answer process has been completed. <br> The file is now ready for download!',
  runProcess: 'Run Process',
  uploadOnHoverText: 'Drop a file here',
  dragAndDropText: 'Drag & drop your file here or',
  uploadText: 'Upload file',
  definitionOfAcceptType: 'xls, xlsx',
  errorFileTypeMsg: 'Upload failed. XLS, XLSX only.',
  errorUploadMsg: 'Oops.. something went wrong. Please try again',
  errorIncorrectContent: 'Upload failed. This file does not contain any relevant data.',
  columnPlaceholder: 'Column',
  headerPlaceholder: 'Header',
  emptyColumn: '-',
  undefinedColumn: 'Undefined',
  tooltipColumn: 'Column number',
  maxQuestionsDescription: 'Worksheets can have up to 8 questions',
  maxQuestions: 8,
  requestForProposal: 'Request for proposal',
  summaryTotal: 'Summary (Total)',
  noAnswered: 'Not answered',
  currentColumnRegex: new RegExp('^[a-zA-Z]{1,}[1-9]{1,}$'),
  errorTooltip: 'Both fields must have valid values',
  errorRequiredOneLineTooltip: 'Complete at least one set of Q&As',
  errorSameValue: 'Both fields cannot contain the same value',
};
