import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ElementRef, NgZone, QueryList } from '@angular/core';
import { isFullyInViewport } from '@shared/utils';
import { NgScrollbar } from 'ngx-scrollbar';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, pairwise } from 'rxjs/operators';
import { ScrollTrigger } from '../models/results-types';
import { prevSiblingThatHasSelector } from '../utils/results.util';

export type CollectionItem = { displayIndex?: number };
export type AggregatedItem<T> = { timestamp: number; item: T };
export type ScrollData = { offset: { top: number; bottom: number }; trigger: ScrollTrigger };
/**
 @important All items in the service must have a 'displayIndex' property  
 @important All the rendered items on the HTML must have index dataset to represnt their rendering order
 *   example:   <item [attr.data-index]="item.displayIndex"></item>
*/
export class ScrollService<T extends CollectionItem> {
  private IMPRESSION_PENALTY_TIME = 1000;
  private _itemHeights: number[] | [number] = [];
  private elements: QueryList<ElementRef>;
  private impressioned = new Array<AggregatedItem<T>>();
  private penaltyPassed = (time: number): boolean => new Date().getTime() - this.IMPRESSION_PENALTY_TIME >= time;

  private sub: Subscription;
  viewport: CdkVirtualScrollViewport;

  impressioned$ = new Subject<T[]>();
  items: T[];
  scroll$: Subject<ScrollData>;

  constructor(private ngZone: NgZone) {}

  get itemHeights(): number[] | [number] {
    return this._itemHeights;
  }

  /** Height of each item in the rendered list */
  set itemHeights(value: number[] | [number]) {
    this._itemHeights = value;
    if (!this.viewport) return;
    if (value.length === 1) this.viewport.setTotalContentSize(value[0] * this.items.length);
    else this.viewport.setTotalContentSize(this._itemHeights.reduce((a, c) => a + c, 0));
  }

  get viewportEl(): HTMLElement {
    return this.viewport?.elementRef?.nativeElement;
  }

  destroy() {
    this.reportAggregated();
    if (this.sub) this.sub.unsubscribe();
    this.sub = null;
    this.scroll$?.complete();
  }

  /** This gets called on every DoCheck, since the virtual scroll list have an *ngIf statement
   * Which means that we have to subscribe to the new ref everytime. We do have logic to ensure to not subscribe more then once
   * To the same scroll ref.
   */
  init(scrollbarRef: NgScrollbar, elements: QueryList<ElementRef>, viewport: CdkVirtualScrollViewport) {
    if (this.sub) this.destroy();

    this.sub = new Subscription();
    this.viewport = viewport;
    this.elements = elements;
    this.scroll$ = new Subject<ScrollData>();
    this.initScrollImpression(scrollbarRef);
    this.initNextPageOnScroll(scrollbarRef);
    this.disableHoverOnScroll(scrollbarRef, elements);
  }

  /**
   *  @description preform a scroll action if the selected index is out of the current view
   * ---
   * @behavior
   * #### Current item is already in view
   * **scroll-up -**  After scrolling the  element before the last element in view will be the first
   * **scroll-down -**  After scrolling the  last element in view will be the middle ish.
   *
   * #### Current item is out of view
   * After scrolling the item will be in the middle ish and selected.
   *
   * #### special-cases:
   *  -  First element will scroll all the way up
   *  -  last element will scroll all the way up
   *
   * @return
   * The selectedIndex after the scrolling, important to keep in sync with the Dom.
   */
  scrollIfNeeded(selectedIndex: number, items: any[]): number {
    let selected = selectedIndex;
    if (!this.viewport) return;

    if (selected === 0) {
      this.scrollToStart();
      return;
    }

    if (selected === items.length) {
      this.scrollToEnd();
      return;
    }

    const resultInView = this.resultInView('all');

    const indexesInView = resultInView.map((el) => +el.dataset['index']);

    const [first, last] = [resultInView[0], resultInView[resultInView.length - 1]];

    if (!first || !last) return;

    const [firstIndexInView, lastIndexInView] = [+first.dataset['index'], +last.dataset['index']];

    if (selected !== null && selected !== lastIndexInView + 1 && selected !== firstIndexInView - 1 && !indexesInView.includes(selected)) {
      const nextOffset = this.itemHeights.slice(0, selected - 1).reduce((a, c) => a + c, 0);
      this.viewport.scrollToOffset(nextOffset, 'smooth');
      return;
    }

    if (selected > lastIndexInView) {
      // We decided that we need to scroll down
      const nextOffset = this.viewport.measureScrollOffset() + last.clientHeight + this.viewport.getViewportSize() / 2;
      this.viewport.scrollToOffset(nextOffset, 'smooth');

      /** ngx-scrollbar has a bug that even after setting the right value in the line above,
       * it's initiate scroll action that take the item out of the view, which break the sync between
       * the scroll index and the selected index. this condition fixes it.
       * More info: https://github.com/MurhafSousli/ngx-scrollbar/issues/379
       */
      selected = lastIndexInView + 1;
      return selected;
    }

    if (selected < firstIndexInView) {
      // We decided we need to scroll up
      const prevEl = prevSiblingThatHasSelector(first, '.result-item');
      if (!prevEl) return;
      const { top: elTop } = prevEl.getBoundingClientRect();
      const { top: viewTop } = this.viewportEl.getBoundingClientRect();
      const diff = viewTop - elTop;
      const nextOffset = this.viewport.measureScrollOffset() - diff;
      this.viewport.scrollToOffset(nextOffset, 'smooth');

      /** Same explanation as above */
      selected = firstIndexInView - 1;
      return selected;
    }
  }

  /** @behavior The next element after the last element in view will be the first in the 'next viewport'
   * **special-case:**
   *  -  last page will scroll all the way down
   */
  scrollPageDown() {
    if (!this.viewport || !this.viewportEl) return;

    if (this.viewportEl.scrollHeight <= this.viewportEl.scrollTop + this.viewportEl.offsetHeight) return;

    const last = this.resultInView('last');

    const { top: elTop } = last.getBoundingClientRect();
    const { bottom: viewBottom, height: viewHeight } = this.viewportEl.getBoundingClientRect();
    const diff = viewBottom - elTop;

    const nextOffset = Math.min(this.viewportEl.scrollHeight, this.viewport.measureScrollOffset() + viewHeight - diff);

    this.viewport.scrollToOffset(nextOffset, 'smooth');
  }

  /** @behavior The element before the first element in view will be the last in the 'next viewport'
   * **special-case:**
   *  -  first page will scroll all the way up
   */
  scrollPageUp() {
    if (!this.viewport || this.viewportEl.scrollTop === 0) return;

    const last = this.resultInView('first');
    const nextLast = prevSiblingThatHasSelector(last, '.result-item');

    const { bottom: elBottom } = nextLast.getBoundingClientRect();
    const { top: viewTop, height: viewHeight } = this.viewportEl.getBoundingClientRect();
    const diff = viewTop - elBottom;

    let nextOffset = this.viewport.measureScrollOffset() - viewHeight - diff;
    if (nextOffset < viewHeight) nextOffset = 0;

    this.viewport.scrollToOffset(nextOffset, 'smooth');
  }

  scrollToEnd() {
    if (!this.viewport) return;

    const { scrollHeight, clientHeight } = this.viewportEl;
    this.viewport.scrollToOffset(scrollHeight - clientHeight, 'smooth');
  }

  scrollToStart() {
    if (!this.viewport) return;

    this.viewport.scrollToOffset(0, 'smooth');
  }

  /** While scrolling if the user mouse is over the results the 'hover'
   * effect cause repainting and slow down the performance */
  private disableHoverOnScroll(scrollbar: NgScrollbar, elements: QueryList<ElementRef>) {
    const DISABLE_HOVER_CLASS = 'disable-hover';
    let timer: NodeJS.Timeout;
    const scroll = scrollbar.scrolled.subscribe(() => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      elements.forEach(({ nativeElement }: { nativeElement: HTMLElement }) => nativeElement.classList.add(DISABLE_HOVER_CLASS));

      timer = setTimeout(() => {
        elements.forEach(({ nativeElement }: { nativeElement: HTMLElement }) => nativeElement.classList.remove(DISABLE_HOVER_CLASS));
      }, 250);
    });
    this.sub.add(scroll);
  }

  private initNextPageOnScroll(scrollbar: NgScrollbar) {
    let trigger: ScrollTrigger;

    const pageDown = fromEvent(document, 'keydown')
      .pipe(filter((event: KeyboardEvent) => event.key === 'PageDown'))
      .subscribe(() => (trigger = 'pagedown_key'));

    const end = fromEvent(document, 'keydown')
      .pipe(filter((event: KeyboardEvent) => event.key === 'End'))
      .subscribe(() => (trigger = 'reach_bottom_keyboard'));

    const wheel = fromEvent(document, 'wheel').subscribe(() => (trigger = 'reach_bottom_scroll'));

    const scroll = scrollbar.scrolled.pipe(map((ev: UIEvent) => (ev.target as HTMLElement).scrollTop)).subscribe((next) => {
      this.scroll$.next({ offset: { top: next, bottom: this.viewport.measureScrollOffset('bottom') }, trigger });
    });

    this.sub.add(pageDown);
    this.sub.add(end);
    this.sub.add(wheel);
    this.sub.add(scroll);
  }

  private initScrollImpression(scrollbar: NgScrollbar) {
    const scroll = scrollbar.scrolled
      .pipe(
        map(() => this.resultInView('all').map((el) => +el.dataset['index'])),
        distinctUntilChanged(),
        pairwise()
      )
      .subscribe(([prev, next]) => {
        const missing = (prev || []).filter((item) => (next || []).indexOf(item) < 0); // diff

        for (const index of missing) {
          const idx = this.impressioned.findIndex((r) => r.item.displayIndex === index);
          if (idx === -1) continue;

          if (!this.penaltyPassed(this.impressioned[idx].timestamp)) this.impressioned.splice(idx, 1);
        }

        for (const index of next) {
          const ai = this.impressioned.findIndex((r) => r.item.displayIndex === index);
          if (ai !== -1) continue;

          const item = this.items.find((r) => r?.displayIndex === index);
          if (!item) continue;

          this.impressioned.push({ timestamp: new Date().getTime(), item });
        }
      });

    const scrollEnd = this.scroll$.pipe(debounceTime(this.IMPRESSION_PENALTY_TIME + 10)).subscribe(() => this.reportAggregated());

    this.sub.add(scroll);
    this.sub.add(scrollEnd);
  }

  private reportAggregated() {
    const items = this.impressioned
      .filter((ar) => this.penaltyPassed(ar.timestamp))
      .map((ar) => ar.item)
      .sort((a, b) => a.displayIndex - b.displayIndex);

    if (!items.length) return;
    this.impressioned$.next(items);
    this.impressioned = this.impressioned.filter((ar) => !this.penaltyPassed(ar.timestamp));
  }

  resultInView(which: 'all'): HTMLElement[];
  resultInView(which: 'first' | 'last'): HTMLElement;
  resultInView(which: 'first' | 'last' | 'all'): HTMLElement | HTMLElement[] {
    return this.ngZone.runOutsideAngular(() => {
      if (!this.viewport) return;

      const els = this.elements.map<HTMLElement>((e) => e.nativeElement);
      const viewportEl = this.viewport.elementRef.nativeElement;

      if (which === 'all') {
        return els.filter((el) => isFullyInViewport(el, viewportEl));
      }

      if (which === 'first') return els.find((el) => isFullyInViewport(el, viewportEl));

      return els.find((curr, i) => {
        const sibling = els[i + 1];

        if (!sibling) return curr;

        return isFullyInViewport(curr, viewportEl) && !isFullyInViewport(sibling, viewportEl);
      });
    });
  }
}
