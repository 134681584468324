<span class="non-marked-text">{{ query?.slice(0, query.lastIndexOf(lastQueryWord)) | spaceHtmlEncode }}</span>
<div class="underlay">
  <span #autoCompleteText class="query"
    ><span query>{{ nonLastQueryWord }}</span>
    <u-icon class="arrow-icon" [model]="{ type: 'font', value: 'icon-arrow-right' }"></u-icon>
  </span>
  <div (click)="onSelect()" class="tag">
    <entity-icon [model]="model.icon" [size]="'12px'"></entity-icon>

    <span
      class="tag-label"
      *ngIf="model.type != 'page' && !EXCLUDED_TAGS.includes(model.label?.toLowerCase()) && model.label && model.data?.showLabelOnTag"
      [innerHTML]="model.label ? (model.label + ' ' | capitalize: true | highlight: [lastQueryWord || '']:'autocomplete-highlight') : ''"
    ></span>
    <span
      data-cy="searchbar-tag-text"
      class="tag-text"
      [innerHTML]="
        model.title + (model.subtitle ? ' (' + model.subtitle + ')' : '') | highlight: [lastQueryWord || '']:'autocomplete-highlight'
      "
    ></span>
  </div>
</div>
