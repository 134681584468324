import { Injectable } from '@angular/core';
import { Resources, Search } from '@local/client-contracts';
import { UrlResolverRpcInvoker } from '@local/common';
import { isValidPatternUrl } from '@local/ts-infra';
import { ServicesRpcService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class UrlResolverService {
  private service: Resources.UrlResolver;

  constructor(rpc: ServicesRpcService) {
    this.service = rpc.invokeWith(UrlResolverRpcInvoker, 'urlresolver');
  }

  async resolve(url: string): Promise<Search.ResultResourceItem | Resources.WebsiteMetadata> {
    if (isValidPatternUrl(url) === false) {
      return;
    }
    const res = await this.resolveToLinkResource(url);
    if (res) {
      return res;
    }
    return this.resolveSite(url);
  }

  resolveToLinkResource(url: string): Promise<Search.ResultResourceItem> {
    return this.service.resolveResource(url);
  }

  async resolveSite(url: string): Promise<Resources.WebsiteMetadata> {
    return this.service.resolveWebsite(url);
  }
}
