import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndicatorComponent implements OnInit {
  @Input() intent: 'danger' | 'warning' | 'success' | 'primary';
  @Input() name: string;
  @Input() size: string = '7px';
  @Input() blink: boolean = false;
  @Input() color: string;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (!this.color && !this.intent) {
      this.intent = 'primary';
    }
    this.cdr.markForCheck();
  }

  get StyleClass() {
    return {
      indicator: true,
      [this.intent]: true,
      blink: this.blink,
    };
  }
}
