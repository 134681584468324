<div class="add-quick-links-popup-container" data-cy="add-quick-links-popup-container">
  <ng-container *ngIf="viewModel; else loading">
    <div class="add-quick-links-popup-header">
      <div class="add-quick-links-popup-header-content">
        <div class="title">{{ editQuickLink ? 'Edit' : 'Add New' }} Link</div>
        <u-icon class="close-icon" (click)="closePopup()" [model]="closeIcon" [styles]="{ fontSize: '12px' }"></u-icon>
      </div>
      <div class="divider"></div>
    </div>

    <div class="add-quick-links-popup-main">
      <div class="add-quick-links-popup-content">
        <div class="title">URL</div>
        <u-input
          [styles]="{ width: '432px', height: '32px', 'padding-right': '32px' }"
          [inputSize]="'medium'"
          [placeholder]="'Add new link'"
          [removeText]="true"
          (onChange)="onUrlChange($event)"
          (onFocus)="onFocus('url')"
          (onBlur)="onBlurUrl($event)"
          [model]="viewModel?.url"
          [ngClass]="{ 'errors-input': errors?.errorUrl, 'filled-input': viewModel?.url, loading: loadingButton }"
          #inputUrl
          id="inputUrl"
          ><i *ngIf="loadingButton" class="pi pi-spin pi-spinner"></i
        ></u-input>
        <div class="errors" *ngIf="errors?.errorUrl">
          {{ errors?.errorUrl }}
        </div>
      </div>
      <div class="add-quick-links-popup-content">
        <div class="title">Name</div>
        <div class="add-quick-links-popup-name">
          <u-file-upload
            [pTooltip]="viewModel.imageUrl || viewModel.blobId ? 'Replace image' : 'Upload image'"
            tooltipPosition="bottom"
            tooltipStyleClass="u-tooltip"
            (fileUploadClick)="onClickUpload()"
            (onSelect)="onUpload($event)"
            (onError)="onUploadError('', $event)"
            [showErrorMessage]="false"
            [openFileOnClick]="true"
            [userFilePreview]="iconFile"
            [accept]="accept"
            [mode]="'advanced'"
          ></u-file-upload>
          <u-input
            [model]="viewModel?.name"
            [styles]="{ width: '392px', height: '32px', 'padding-right': '32px' }"
            [placeholder]="'Ex. App name'"
            [inputSize]="'medium'"
            [inputFocus]="focusName"
            [removeText]="true"
            (onChange)="onNameChange($event)"
            [ngClass]="{ 'errors-input': errors?.errorName, 'filled-input': viewModel?.name }"
            (onFocus)="onFocus('name')"
          >
          </u-input>
        </div>
        <div class="errors" *ngIf="errors?.errorName">
          {{ errors?.errorName }}
        </div>
      </div>
      <div *ngIf="quickLinksService.isOwnerOrAdmin" class="add-quick-links-popup-content share">
        <u-checkbox [checked]="viewModel.isShareWorkspace" [disabled]="editQuickLink" [binary]="true" (click)="onClickCheckbox($event)">
        </u-checkbox>
        <div [ngClass]="{ disabled: editQuickLink }" class="text">Share with workspace</div>
      </div>
    </div>

    <div class="add-quick-links-popup-action">
      <div class="button-content">
        <u-button
          [label]="'Cancel'"
          [styles]="{ width: '96px' }"
          type="secondary"
          class="left-button"
          (mousedown)="closePopup('cancel')"
          id="cancel"
        ></u-button>
        <u-button
          id="createButton"
          [label]="editQuickLink ? 'Update' : 'Add'"
          [disabled]="disabledButton"
          [styles]="{ width: '96px' }"
          type="primary"
          (click)="saveQuickLink()"
        ></u-button>
      </div>
    </div>
  </ng-container>

  <ng-template #loading>
    <loader-overlay class="add-quick-links-popup-loading"> </loader-overlay>
  </ng-template>
</div>
