import { Injectable } from '@angular/core';
import { Calculators } from '@local/client-contracts';
import { ServicesRpcService } from '@shared/services';
import { CalculatorsRpcInvoker } from './calculators.rpc-invoker';

@Injectable({
  providedIn: 'root',
})
export class CalculatorsService implements Calculators.Service {
  private service: Calculators.Service;
  constructor(host: ServicesRpcService) {
    this.service = host.invokeWith(CalculatorsRpcInvoker, 'calculators');
  }

  calculate(query: string): Promise<Calculators.Item> {
    return this.service.calculate(query);
  }
}
