import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UDatePickerComponent } from './u-date-picker.component';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { UInputModule } from '../u-input/u-input.module';
import { UDropdownModule } from '../u-dropdown/u-dropdown.module';

@NgModule({
  declarations: [UDatePickerComponent],
  imports: [CommonModule, FormsModule, CalendarModule, UInputModule, UDropdownModule],
  exports: [UDatePickerComponent],
})
export class UDateRangeModule {}
