import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DynamicComponentItem, DynamicComponentLoaderComponent } from '@local/ui-infra';
import { RouterService } from '@shared/services/router.service';
import { PreviewKey } from '../../results/models/view-filters';
import { getPreviewTypeByPreviewKey } from '../helpers/preview.utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UrlPreviewService } from 'src/app/bar/services/url-preview.service';
import { FilePreviewService } from '../file-preview/services/file-preview.service';
import { PeopleService } from '../people-preview/services/people.service';
import { MailService } from '../mail-preview/services/mail.service';

export interface ResourcePreviewData {
  component: any;
  url: string;
}
@UntilDestroy()
@Component({
  selector: 'preview-hub',
  templateUrl: './preview-hub.component.html',
  styleUrls: ['./preview-hub.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewHubComponent implements OnInit {
  dynamicItem: DynamicComponentItem<any>;
  @ViewChild(DynamicComponentLoaderComponent) dynamicComponent: DynamicComponentLoaderComponent;

  constructor(
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService,
    private urlPreviewService: UrlPreviewService,
    // filePreviewService, peopleService and mailService are only for initialize when we refresh the page
    private filePreviewService: FilePreviewService,
    private peopleService: PeopleService,
    private mailService: MailService
  ) {}

  ngOnInit() {
    this.loadPreview();

    this.routerService.navigated$.pipe(untilDestroyed(this)).subscribe((url) => {
      //validate the route is preview like
      if (!!getPreviewTypeByPreviewKey(this.routerService?.params?.param as PreviewKey)) {
        this.loadPreview();
      }
    });
  }

  async loadPreview() {
    const data = await this.urlPreviewService.loadPreview(this.routerService?.params, true);
    this.dynamicItem = data.dynamicItem;
    this.cdr.markForCheck();
    if (data.url) {
      this.routerService.navigateByUrl(data.url);
    }
  }
}
