import { Flags } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class FlagsRpcInvoker implements Flags.Service {
  @invoker
  get all$(): Observable<Flags.Flag[]> {
    return;
  }

  @invoker
  isEnabled(flagName: string, linkId?: string): Promise<boolean> {
    return;
  }
}
