import { Questionnaire } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class QuestionnaireRpcInvoker implements Questionnaire.Service {
  @invoker
  load(req: Questionnaire.LoadRequest): Promise<Questionnaire.LoadResponse> {
    return;
  }

  @invoker
  create(request: Questionnaire.CreateQuestionnaireRequest): Promise<Questionnaire.CreateQuestionnaireResponse> {
    return;
  }

  @invoker
  delete(id: string): Promise<void> {
    return;
  }

  @invoker
  status(id: string): Promise<Questionnaire.QuestionnaireStatusResponse> {
    return;
  }
}
