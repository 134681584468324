import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ColorEvent } from 'ngx-color';
import { ChromeComponent } from 'ngx-color/chrome';
import { debounceTime, fromEvent } from 'rxjs';
import { PopupRef } from '../../services/popup/popup-ref';
import { PopupService } from '../../services/popup/popup.service';
import { UiIconModel } from '../../types/ui.icon.model';

@UntilDestroy()
@Component({
  selector: 'u-color-picker',
  templateUrl: './u-color-picker.component.html',
  styleUrls: ['./u-color-picker.component.scss'],
})
export class UColorPickerComponent implements OnDestroy, OnInit, AfterViewInit {
  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.cpPopupRef) {
      this.cpPopupRef.destroy();
      event.stopPropagation();
    }
  }

  @ViewChild(ChromeComponent) chrome: ChromeComponent;
  @ViewChild('colorPickerButton', { read: ElementRef }) buttonRef: ElementRef;

  @Input() originalColor: string;
  @Input() originalTextColor: string;
  @Input() title: string;
  @Input() id: string;
  @Input() resetBtn = true;
  @Input() isSecondary = false;

  private _textColor: string;
  public get textColor(): string {
    return this._textColor;
  }
  @Input() public set textColor(value: string) {
    if (!value) return;
    this._textColor = value;
    if (this.isSecondary) {
      this.displayColorText = value;
    }
  }

  private _color;
  public get color() {
    return this._color;
  }
  @Input() public set color(value) {
    if (!value) return;
    this._color = value;
    if (!this.isSecondary) {
      this.displayColorText = value;
    }
  }

  @Output() colorChangeEvent = new EventEmitter<ColorEvent>();
  @Output() colorResetEvent = new EventEmitter<string>();

  private initialColor: string;
  private prevColor: string;
  private prevTextColor: string;
  displayColorText: string;
  showResetBtn: boolean;
  cpPopupRef: PopupRef<ChromeComponent, any>;
  isColorDark: boolean;
  colorPickerHidden = true;
  colorPickerIcon: UiIconModel = {
    type: 'font',
    value: 'icon-eye-dropper',
  };

  constructor(private cdr: ChangeDetectorRef, private popupService: PopupService) {}

  ngAfterViewInit(): void {
    fromEvent(window, 'resize')
      .pipe(debounceTime(100), untilDestroyed(this))
      .subscribe(() => {
        if (this.cpPopupRef) {
          this.cpPopupRef.destroy();
        }
      });
  }

  ngOnInit(): void {
    this.prevColor = this.initialColor = this.color;
    if (!this.textColor && this.originalTextColor) {
      this.prevTextColor = this.textColor = this.originalTextColor;
    }
  }

  openColorPicker() {
    const inputDimensions = this.buttonRef.nativeElement.getBoundingClientRect();
    const { x, y } = inputDimensions;
    const position: ConnectedPosition[] = [
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
        offsetY: inputDimensions.height - 50,
        offsetX: inputDimensions.width / 2,
      },
    ];
    this.cpPopupRef = this.popupService.open<ChromeComponent, any>({ x, y }, ChromeComponent, {}, { position });
    this.cpPopupRef.compInstance.disableAlpha = true;
    const displayColor = this.isSecondary ? this.textColor : this.color;
    this.cpPopupRef.compInstance.color = displayColor ?? this.originalColor;
    this.cpPopupRef.compInstance.onChangeComplete.pipe(untilDestroyed(this)).subscribe((event: ColorEvent) => {
      this.colorChanged(event.color.hex);
      this.colorChangeEvent.emit(event);
    });
  }

  onReset() {
    this.displayColorText = this.color = this.initialColor ?? this.originalColor;
    this.showResetBtn = false;
    this.colorResetEvent.emit(this.initialColor);
  }

  colorChanged(hexColor: string) {
    if (!this.isValidHexColor(hexColor)) {
      if (this.isSecondary) {
        this.textColor = this.prevTextColor;
      } else {
        this.color = this.prevColor;
      }
      this.displayColorText = this.isSecondary ? this.prevTextColor : this.prevColor;
      this.cdr.markForCheck();
      return;
    }

    if (this.isSecondary) {
      this.prevTextColor = this.textColor = hexColor;
    } else {
      this.prevColor = this.color = hexColor;
    }
    this.displayColorText = this.isSecondary ? this.textColor : this.color;
    this.showResetBtn = this.resetBtn && this.initialColor !== hexColor;
    this.cdr.markForCheck();
  }

  onChange(value) {
    this.color = value;
    this.colorChanged(value);
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.cpPopupRef?.destroy();
  }

  private isValidHexColor(color) {
    return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color);
  }
}
