import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { TimerService } from '../../services/timer.service';

@Component({
  selector: 'dynamic-ellipsis',
  templateUrl: './dynamic-ellipsis.component.html',
  styleUrls: ['./dynamic-ellipsis.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicEllipsisComponent implements OnInit, OnDestroy {
  dynamicEllipsis: string = '';
  private dynamicEllipsisInterval;

  constructor(private timerService: TimerService) {}

  ngOnInit(): void {
    this.initDynamicEllipsis();
  }

  ngOnDestroy() {
    if (this.dynamicEllipsisInterval) this.timerService.unregister(this.dynamicEllipsisInterval);
  }

  initDynamicEllipsis() {
    if (this.dynamicEllipsisInterval) return;

    this.dynamicEllipsisInterval = this.timerService.register(
      () => {
        if (this.dynamicEllipsis.length < 3) {
          this.dynamicEllipsis += '.';
        } else {
          this.dynamicEllipsis = '.';
        }
      },
      { focusInterval: 250 }
    );
  }
}
