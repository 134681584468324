import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep, isEqual, isNil } from 'lodash';
import { MenuItem } from 'primeng/api';
import { windowSizeObserver } from '../../utils/window-size-observer';
import { UInputComponent } from '../u-input/u-input.component';

export interface Tab {
  name: string;
  icon?: string;
  amount?: number;
  tooltip?: string;
  id: string;
  disabled?: boolean;
  editMode?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'u-tab-view',
  templateUrl: './u-tab-view.component.html',
  styleUrls: ['./u-tab-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UTabViewComponent implements AfterViewInit {
  private readonly TAB_ACTION_WIDTH = 24;
  private readonly TAB_ICON_WIDTH = 24;
  private readonly MORE_DROPDOWN_WIDTH = 70;
  private readonly PADDING_TAB = 8;
  private readonly GAP_TAB = 8;
  private readonly TAB_MAX_WIDTH = 132;
  private readonly TAB_NAME_LETTER_PX_SIZE = 8.5;

  private windowSize$ = windowSizeObserver();
  private _tabsStatic: Tab[];
  private _tabsMoving: Tab[];
  private _innerTabsMoving: Tab[];
  private _innerTabsStatic: Tab[];
  private previousIndex: number;
  private currentIndex: number;
  activeTabId: string;
  staticTabsLimit: number = 0;
  movingTabsLimit: number = 0;
  showOnOptions: MenuItem[] = [];

  @Input() set tabsStatic(value: Tab[]) {
    if (isEqual(this._innerTabsStatic, value)) {
      return;
    }
    this._innerTabsStatic = cloneDeep(value);
    this._tabsStatic = value;
    this.initTabs();
    this.cdr.markForCheck();
  }

  get tabsStatic(): Tab[] {
    return this._tabsStatic;
  }

  @Input() set tabsMoving(value: Tab[]) {
    if (isEqual(this._innerTabsMoving, value)) {
      return;
    }
    const editMode = this._innerTabsMoving?.filter((e) => !!e.editMode) || [];
    if (editMode?.length && editMode.every((t) => !!value.find((e) => t.id === e.id)?.editMode)) {
      return;
    }

    this._innerTabsMoving = cloneDeep(value);
    this._tabsMoving = cloneDeep(value);
    this.initTabs();
    this.cdr.markForCheck();
  }

  get tabsMoving(): Tab[] {
    return this._tabsMoving;
  }

  @Input() selectedTabId: string;
  @Input() tabsActionStatic: Tab[];
  @Input() openLeftMenuBar: boolean;
  @Input() hasDelayTab: boolean = true;
  @Output() onChangeActiveTab = new EventEmitter<any>();
  @Output() onAction = new EventEmitter<any>();
  @Output() onOpenContextMenu = new EventEmitter<any>();
  @Output() onItemsOrderChange = new EventEmitter<Tab[]>();

  @ViewChildren('staticTabs', { read: ElementRef }) staticTabsRef: QueryList<ElementRef>;
  @ViewChildren('movingTabs', { read: ElementRef }) movingTabsRef: QueryList<ElementRef>;
  @ViewChild('tabsContainer', { read: ElementRef }) tabsContainerRef: ElementRef;
  @ViewChild('static', { read: ElementRef }) staticTabsContainerRef: ElementRef;
  @ViewChild('moving', { read: ElementRef }) movingTabsContainerRef: ElementRef;

  private inputTitle: UInputComponent;
  private progressInitTab: boolean;
  private firstInit = true;

  @HostBinding('style.--menubar-list-right-position') get rightListPosition() {
    if (!this.openLeftMenuBar) {
      return;
    }
    let tabsContainerWidth = 0;
    if (this.movingTabsContainerRef) {
      tabsContainerWidth += this.movingTabsContainerRef.nativeElement.offsetWidth;
    }
    if (this.staticTabsContainerRef) {
      tabsContainerWidth += this.staticTabsContainerRef.nativeElement.offsetWidth;
    }
    tabsContainerWidth += this.MORE_DROPDOWN_WIDTH;
    const right = window.innerWidth - tabsContainerWidth;
    return `${right}px`;
  }

  constructor(protected cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.initTabs();
    this.windowSize$.pipe(untilDestroyed(this)).subscribe(() => {
      this.initTabs();
    });
  }

  displayInput($event: UInputComponent) {
    this.inputTitle = $event;
    this.cdr.markForCheck();
  }

  private initTabs() {
    if (this.tabsMoving?.length) {
      this.staticTabsLimit = this.tabsStatic.length;
    }
    if (this.progressInitTab) return;
    this.progressInitTab = true;
    const tabs = [...Object.values(this.tabsStatic || []), ...Object.values(this._tabsMoving || [])];
    this.activeTabId = tabs[0]?.id;
    this.showOnOptions = [{ label: 'More', items: [] }];
    this.cdr.markForCheck();
    setTimeout(
      () => {
        this.addTooltipToTab();
        this.setTabsLimit();
        this.progressInitTab = false;
        this.firstInit = false;
      },
      this.firstInit && this.hasDelayTab ? 700 : 0
    );
  }

  addTooltipToTab() {
    const tabsMovingRef = this.movingTabsRef?.toArray();
    tabsMovingRef?.forEach((tab, index) => {
      if (tab.nativeElement?.offsetWidth === this.TAB_MAX_WIDTH) {
        const tab = this._tabsMoving[index];
        this._tabsMoving[index] = { ...tab, tooltip: tab.name };
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    this.previousIndex = event.previousIndex;
    this.currentIndex = event.currentIndex;
    moveItemInArray(this._tabsMoving, event.previousIndex, event.currentIndex);
    const tabs = [...this.tabsStatic, ...this._tabsMoving];
    if (this.previousIndex !== this.currentIndex) {
      this.onItemsOrderChange.emit(tabs);
    }
    this.openTab(event, this._tabsMoving[this.currentIndex]);
  }

  openTab(event, tab: Tab) {
    if (!tab || tab?.editMode) {
      return;
    }
    this.selectedTabId = this.activeTabId = tab.id;
    this.onChangeActiveTab.emit({ event, tab: tab.id });
  }

  doAction(event, action: string) {
    this.onAction.emit({ event, action });
    this.inputTitle = null;
  }

  private showAndHideTabs(event, tabsLimit: number, tabs: Tab[]) {
    const index = tabs.findIndex((obj) => obj?.id === event?.item?.id);
    if (index < 0) {
      return;
    }
    const selectedItem = tabs[index];
    tabs.splice(index, 1);
    const item = tabs[tabsLimit - 1];
    tabs.splice(tabsLimit - 1, 1, selectedItem);
    tabs.push(item);
    const label =
      item?.amount !== null && item?.amount !== undefined ? `${item?.name} <span class="tab-amount">${item?.amount}</span>` : item?.name;
    this.showOnOptions[0].items.unshift({
      label,
      icon: item?.icon,
      id: item?.id,
      escape: false,
      command: (event) => {
        this.showAndHideTabs(event, tabsLimit, tabs);
      },
    });
    this.showOnOptions[0].items = this.showOnOptions[0].items.filter((obj) => obj?.id !== event?.item?.id);
    this.showOnOptions = [...this.showOnOptions]; // trigger update
    this.selectedTabId = selectedItem?.id;
    this.openTab(event?.event, selectedItem);
  }

  renameTab(event: { inputElement: UInputComponent; defaultInputModel: string }, tabId: string) {
    this.inputTitle = event.inputElement;
    this.onAction.emit({ event, action: 'rename', tabId });
  }

  openContextMenu(event, tabId: string) {
    event.preventDefault();
    this.onOpenContextMenu.emit({ event, tabId });
  }

  setTabsLimit() {
    this.showOnOptions[0].items = [];
    if (!this.tabsMoving?.length) {
      this.setStaticTabsLimit();
      return;
    }
    this.setMovingTabsLimit();
  }

  private setStaticTabsLimit() {
    this._tabsStatic = cloneDeep(this._innerTabsStatic); //keeps static tabs initial order
    let tabsLimit = 0;
    let width = 0;

    if (!this.staticTabsRef?.toArray()) {
      return;
    }

    width = this.TAB_ACTION_WIDTH * (this.tabsActionStatic?.length || 0) + this.MORE_DROPDOWN_WIDTH;
    const tabViewWidth = this.tabsContainerRef.nativeElement.offsetWidth;
    this._tabsStatic?.forEach((tab) => {
      width += tab?.name?.length * this.TAB_NAME_LETTER_PX_SIZE + this.PADDING_TAB + this.GAP_TAB;
      if (tab?.icon) {
        width += this.TAB_ICON_WIDTH;
      }
      if (tabViewWidth > width) {
        tabsLimit++;
      }
    });

    this.insertTabsToDropdown(tabsLimit, this._tabsStatic);

    this._tabsStatic?.forEach((item, index) => {
      if (this.selectedTabId === item?.id && index >= tabsLimit) {
        this.showAndHideTabs({ item }, tabsLimit, this._tabsStatic);
      }
    });

    this.staticTabsLimit = tabsLimit;
    this.cdr.markForCheck();
  }

  private setMovingTabsLimit() {
    let tabsLimit = 0;
    let width = 0;
    let firstTabsWidth = 0;
    this.staticTabsLimit = this.tabsStatic?.length;

    const firstStaticTabs = this.staticTabsRef?.toArray();
    if (!this.movingTabsRef?.toArray() || !firstStaticTabs) {
      return;
    }

    firstStaticTabs?.forEach((tab) => {
      firstTabsWidth += tab.nativeElement.offsetWidth + this.PADDING_TAB;
    });

    width = this.TAB_ACTION_WIDTH * this.tabsActionStatic?.length + firstTabsWidth + this.MORE_DROPDOWN_WIDTH;

    const tabViewWidth = this.tabsContainerRef.nativeElement.offsetWidth;
    this._tabsMoving?.forEach((tab) => {
      width += tab?.name?.length * this.TAB_NAME_LETTER_PX_SIZE + this.PADDING_TAB;
      if (tabViewWidth > width) {
        tabsLimit++;
      }
    });

    this.insertTabsToDropdown(tabsLimit, this._tabsMoving);

    this._tabsMoving?.forEach((item, index) => {
      if ((item?.editMode || this.selectedTabId === item?.id) && index >= tabsLimit) {
        this.showAndHideTabs({ item }, tabsLimit, this._tabsMoving);
      }
    });
    this.movingTabsLimit = tabsLimit;
    this.cdr.markForCheck();
  }

  private insertTabsToDropdown(tabsLimit: number, tabs: Tab[]) {
    if (tabsLimit >= tabs?.length) {
      return;
    }
    tabs?.slice(tabsLimit).forEach((tab) => {
      if (tab) {
        const label =
          tab.amount !== null && tab.amount !== undefined ? `${tab.name} <span class="tab-amount">${tab.amount}</span>` : tab.name;
        this.showOnOptions[0].items.push({
          label,
          icon: tab.icon,
          id: tab.id,
          escape: false,
          command: (event) => {
            this.showAndHideTabs(event, tabsLimit, tabs);
          },
        });
      }
    });
    this.tabsActionStatic?.forEach((tab) => {
      if (tab) {
        this.showOnOptions[0].items.push({
          label: tab.name,
          icon: tab.icon,
          command: (event) => {
            this.doAction(event, tab?.name);
          },
        });
      }
    });
  }

  onEditTitleClickOut(tab: Tab) {
    if (this.inputTitle && this.inputTitle.model !== tab.name) {
      this.renameTab(
        {
          inputElement: this.inputTitle,
          defaultInputModel: tab.name,
        },
        tab.id
      );
      this.inputTitle = null;
    } else {
      tab.editMode = false;
      this.cdr.markForCheck();
    }
  }

  checkIsNil(value: any): boolean {
    return isNil(value);
  }
}
