import { AfterViewInit, Directive, ElementRef, Inject, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Downloads, Flags, Resources, Results, Style } from '@local/client-contracts';
import { isNativeWindow, isWindows } from '@local/common-web';
import { STYLE_SERVICE } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventInfo, EventInfoSearch, EventsService, WindowService } from '@shared/services';
import { DownloadsService } from '@shared/services/downloads.service';
import { FlagsService } from '@shared/services/flags.service';
import { StyleService } from '@shared/services/style.service';
import { isCopyTextCommand, isDownloadedFileCommand, isDownloadUrlCommand, join } from '@shared/utils';
import { svgToDataURL } from '@shared/utils/image-util';
import { isEqual } from 'lodash';
import { firstValueFrom, fromEvent } from 'rxjs';
import { HubService } from 'src/app/bar/services/hub.service';

export type ResultElement = Results.Badge | Results.BulletPart | Results.Button | Results.Subtitle | Results.Tag | Results.Title;
interface DragEventInfo {
  position: number;
  list: string;
  search?: Partial<EventInfoSearch>;
}

export interface DragMetadata {
  type: Downloads.DownloadType;
  resource?: Resources.Resource;
  icon: Style.Icon;
  eventInfo?: DragEventInfo;
}
export interface DragData {
  metadata: DragMetadata;
  model: ResultElement;
}

@UntilDestroy()
@Directive({
  selector: '[dragAndDrop]',
})
export class DragAndDropDirective implements AfterViewInit, OnDestroy {
  @Input('dragAndDrop') set dragData(data: DragData) {
    const changed = !isEqual(data, this.data);
    this.data = data;
    if (!this.isNative) {
      return;
    }
    this.setData(changed);
  }
  private downloadId: string;
  private data: DragData;
  private isAlwaysOnTop: boolean;
  private isNative = isNativeWindow();
  private timeout: NodeJS.Timeout;
  private flags: Flags.Flag[];

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private downloads: DownloadsService,
    private barService: HubService,
    private windowService: WindowService,
    private eventsService: EventsService,
    @Inject(STYLE_SERVICE) private styleService: StyleService,
    flagsService: FlagsService
  ) {
    if (this.isNative) {
      this.windowService.alwaysOnTop$.pipe(untilDestroyed(this)).subscribe((alwaysOnTop) => {
        this.isAlwaysOnTop = alwaysOnTop;
      });
      flagsService.all$.pipe(untilDestroyed(this)).subscribe((f) => (this.flags = f));
    }
  }

  isDataSet = false;
  viewInitialized = false;

  get resource(): Resources.Resource {
    return this.data?.metadata?.resource;
  }

  get eventInfo(): DragEventInfo {
    return this.data?.metadata?.eventInfo;
  }

  ngAfterViewInit() {
    if (!isNativeWindow()) {
      return;
    }

    const el = this.elementRef.nativeElement;
    if (!el) {
      return;
    }

    fromEvent(el, 'dragstart')
      .pipe(untilDestroyed(this))
      .subscribe((ev: DragEvent) => {
        this.onDragStart(ev);
      });

    fromEvent(el, 'dragend')
      .pipe(untilDestroyed(this))
      .subscribe((ev: DragEvent) => {
        this.onDragEnd(ev);
      });
    this.viewInitialized = true;
  }

  ngOnDestroy(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  private setData(changed: boolean) {
    if (!this.data?.model?.onDrag) {
      this.removeAnchor();
      return;
    }
    if (changed) {
      this.timeout = setTimeout(() => {
        this.download();
      }, 500);
    }
    this.wrapElementWithAnchor();
  }

  private wrapElementWithAnchor() {
    const element: HTMLElement = this.elementRef.nativeElement as HTMLElement;
    if (element.parentElement.nodeName === 'A') return;

    const anchor: HTMLElement = this.renderer.createElement('a');
    anchor.style.setProperty('color', 'inherit');
    anchor.style.setProperty('width', '100%');

    const parent: ParentNode = element.parentNode;
    element.setAttribute('draggable', 'true');

    this.renderer.insertBefore(parent, anchor, element);
    this.renderer.appendChild(anchor, element);
  }

  private removeAnchor() {
    const element: HTMLElement = this.elementRef.nativeElement as HTMLElement;
    if (element.parentElement.nodeName !== 'A') return;
    const targetElement = element.parentElement.parentElement;
    element.parentElement.remove();
    targetElement.appendChild(element);
  }

  private async download(): Promise<void> {
    if (!this.data) return;
    const { model, metadata } = this.data;
    const icon = metadata?.icon;
    if (!model || !model.onDrag || !isDownloadUrlCommand(model.onDrag)) return;
    const url: string = model.onDrag.url;
    if (this.data.metadata.type === 'Resource') {
      const request: Downloads.ResourceDownloadRequest = { type: 'Resource', url, resource: this.resource, icon, name: model.text };
      this.downloadId = await this.downloads.resourceDownload(request, 'hidden');
    } else {
      const request: Downloads.UrlDownloadRequest = { type: 'Url', url, icon, name: model.text };
      this.downloadId = await this.downloads.urlDownload(request, 'hidden');
    }
  }

  private async onDragStart(ev: DragEvent) {
    ev.stopPropagation();
    const element: HTMLElement = this.elementRef.nativeElement as HTMLElement;
    const model = this.data?.model;
    const isEnabledDownload: Flags.Flag = this.flags?.find(
      (f) => f.flag === 'fileDownloads' && f.linkId === this.data?.metadata?.resource?.linkId
    );

    if (!model || !model.onDrag || (!isEnabledDownload?.value && this.data.metadata.type === 'Resource')) {
      ev.preventDefault();
      return;
    }
    element.style.setProperty('opacity', '0.4');
    if (model.onDrag) {
      if (isDownloadUrlCommand(model.onDrag)) {
        this.eventsService.event('drag_drop.interaction', this.getInteractionEventMetadata());
        const download: Downloads.Download = this.downloads.all[this.downloadId];
        if (!download || download.status !== 'completed') return;
        const fullPath: string = join(download.path, download.name);
        await this.dragFile(ev, fullPath);
      } else if (isDownloadedFileCommand(model.onDrag)) {
        this.eventsService.event('drag_drop.interaction', this.getDownloadItemDraggedEventMetadata());
        await this.dragFile(ev, model.onDrag.path);
      } else if (isCopyTextCommand(model.onDrag)) {
        this.eventsService.event('drag_drop.interaction', this.getInteractionEventMetadata());
        ev.dataTransfer.setData('text/plain', model.onDrag.text);
      }
    }
    element.style.setProperty('opacity', '1');
  }

  private async dragFile(ev: DragEvent, path: string): Promise<void> {
    ev.dataTransfer.setData('DownloadURL', path);
    ev.preventDefault();
    let iconDataURL: string;
    if (this.data?.metadata?.icon) {
      iconDataURL = await svgToDataURL(
        (await firstValueFrom(this.styleService.theme$)) === 'dark' && this.data.metadata.icon.darkUrl
          ? this.data.metadata.icon.darkUrl
          : this.data.metadata.icon.lightUrl
      );
    }
    if (this.isAlwaysOnTop && isWindows()) this.windowService.alwaysOnTop(false);
    await this.downloads.dragStarted(path, iconDataURL, this.data.metadata.resource?.appId);
    if (this.isAlwaysOnTop && isWindows()) this.windowService.alwaysOnTop(true);
    this.eventsService.event('drag_drop.conversion', this.getConversionEventMetadata('download'));
  }

  private async onDragEnd(ev: DragEvent) {
    ev.stopPropagation();
    const element: HTMLElement = this.elementRef.nativeElement as HTMLElement;
    element.style.setProperty('opacity', '1');
    const model = this.data?.model;
    if (!model) return;

    if (!model.onDrag) return;

    if (!isDownloadUrlCommand(model.onDrag)) {
      this.eventsService.event('drag_drop.conversion', this.getConversionEventMetadata('copy'));
      return;
    }

    await this.downloads.dragged(this.downloadId);
    this.eventsService.event('drag_drop.conversion', this.getConversionEventMetadata('download'));
  }

  private getInteractionEventMetadata(): Partial<EventInfo> {
    const location: string = this.barService.currentLocation;
    const dragEventInfo: DragEventInfo = this.eventInfo;
    const metaData: Partial<EventInfo> = {
      category: 'drag_drop',
      name: 'result_item',
      search: dragEventInfo.search,
      location: {
        title: location,
      },
    };
    if (this.data.metadata.type === 'Resource') {
      metaData.label = this.resource.appId;
    }
    return metaData;
  }

  private getConversionEventMetadata(label: string): Partial<EventInfo> {
    const location: string = this.barService.currentLocation;
    const dragEventInfo: DragEventInfo = this.eventInfo;
    const metaData: Partial<EventInfo> = {
      category: 'resources',
      name: 'action',
      label,
      target: 'drag_drop',
      search: dragEventInfo.search,
      resources: [
        {
          position: dragEventInfo.position,
          category: undefined,
          list: dragEventInfo.list,
        },
      ],
      location: {
        title: location,
      },
    };
    if (this.data.metadata.type === 'Resource') {
      metaData.resources[0] = {
        ...metaData.resources[0],
        appId: this.resource.appId,
        linkId: this.resource.linkId,
        id: this.resource.id,
        type: this.resource.type,
      };
    }
    return metaData;
  }

  private getDownloadItemDraggedEventMetadata(): Partial<EventInfo> {
    const dragEventInfo: DragEventInfo = this.eventInfo;
    const metaData: Partial<EventInfo> = {
      category: 'drag_drop',
      name: 'downloads_bar',
      resources: [
        {
          position: dragEventInfo.position,
          category: undefined,
          list: 'downloads_bar',
        },
      ],
    };
    if (this.data.metadata.type === 'Resource') {
      metaData.resources[0] = {
        ...metaData.resources[0],
        appId: this.resource.appId,
        id: this.resource.id,
        linkId: this.resource.linkId,
        type: this.resource.type,
      };
    }
    return metaData;
  }
}
