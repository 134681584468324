import { Ai } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class AiServiceRpcInvoker implements Ai.Service {
  @invoker
  summarize$(req: Ai.SummarizeRequest): Observable<Ai.SummarizeResponse> {
    return;
  }
}
