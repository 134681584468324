import { Injectable } from '@angular/core';
import { Preferences, Style, Telemetry as TelemetryContracts, Window } from '@local/client-contracts';
import { HitData, initPerformanceCheckpoint, Telemetry, TelemetryRpcInvoker } from '@local/common';
import { isEmbed, isNativeWindow } from '@local/common-web';
import { EmbedService } from '@shared/embed.service';
import { firstValueFrom } from 'rxjs';
import { NativeServicesRpcService } from '..';
import { ServicesRpcService } from '../rpc.service';
import { WindowService } from '../window.service';
import { EventInfo } from './event-info';

@Injectable({
  providedIn: 'root',
})
export class TelemetryService {
  private telemetry: Telemetry;
  private windowInfoPromise: Promise<Window.WindowInfo>;
  private buffer: HitData[] = [];
  private readonly isEmbed = isEmbed();
  private timer: NodeJS.Timeout;
  private readonly MAX_BUFFER_LENGTH = 30;
  private readonly BUFFER_TIMEOUT = 20 * 1000;

  constructor(
    private services: ServicesRpcService,
    private nativeServices: NativeServicesRpcService,
    windowService: WindowService,
    private embedService: EmbedService
  ) {
    this.telemetry = (this.nativeServices || this.services).invokeWith(TelemetryRpcInvoker, 'telemetry');
    this.initTimeout();
    this.telemetry.performanceEnabled().then((p) => {
      if (p) {
        initPerformanceCheckpoint(this.telemetry);
      }
    });

    if (isNativeWindow()) {
      this.windowInfoPromise = windowService.getInfo();
    } else {
      this.windowInfoPromise = (async () => {
        return {
          id: '0',
          name: this.isEmbed ? ((await embedService.isExternalWebSite()) ? 'embed' : 'browser-extension-launcher') : 'web',
        };
      })();
    }
  }

  setDisplay(theme: Style.Theme, screens: TelemetryContracts.ScreensInfo): Promise<void> {
    return this.telemetry.setDisplay(theme, screens);
  }

  exception(e: Error | string): Promise<void> {
    const message = e instanceof Error ? e.message : e;
    return this.hit('analytics', { exception: message });
  }

  event(event: EventInfo): Promise<void> {
    return this.hit('analytics', event);
  }

  preferences(reportReason: 'edit' | 'upgrade', data: Preferences.Preferences): Promise<void> {
    return this.telemetry.preferences(reportReason, data);
  }

  insight(
    name: string,
    data: {
      [key: string]: any;
    }
  ): Promise<void> {
    return this.telemetry.insight(name, data);
  }

  workspaceSettings(data: any): Promise<void> {
    return this.telemetry.workspaceSettings(data);
  }

  private async hit(type: string, data: { [key: string]: any }): Promise<void> {
    const visible = this.isEmbed ? await firstValueFrom(this.embedService.visible$) : document.visibilityState === 'visible';
    const event = {
      ...data,
      window: { ...(await this.windowInfoPromise), visible, focused: document.hasFocus() },
    };
    this.buffer.push({ type, data: event, time: Date.now() });
    if (this.buffer.length >= this.MAX_BUFFER_LENGTH) {
      this.sendTelemetry();
    }
  }

  private initTimeout() {
    this.timer = setTimeout(() => {
      this.sendTelemetry(true);
    }, this.BUFFER_TIMEOUT);
  }

  private async sendTelemetry(clearTimer?: boolean) {
    if (clearTimer && this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    if (this.buffer.length > 0) {
      const hits = this.buffer.splice(0, this.buffer.length);
      await this.telemetry.hit(hits);
    }
    this.initTimeout();
  }

  async flush(waitOnFlush = true) {
    await this.sendTelemetry(true);
    const flush = this.telemetry.flush();
    if (waitOnFlush) {
      await flush;
      return;
    }
    return flush;
  }
}
