import { Injectable } from '@angular/core';
import { Omnibox } from '@local/client-contracts';
import { observable } from '@local/common';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  private _removed$: Subject<Omnibox.Tag>;

  public get all(): Omnibox.Tag[] {
    return this._all$.value;
  }
  public set all(v: Omnibox.Tag[]) {
    this._all$.next(v);
  }

  private _all$: BehaviorSubject<Omnibox.Tag[]>;

  @observable
  get removed$(): Observable<Omnibox.Tag> {
    return this._removed$;
  }

  set removed(v: Omnibox.Tag) {
    this._removed$.next(v);
  }

  @observable
  get all$(): Observable<Omnibox.Tag[]> {
    return this._all$;
  }

  constructor() {
    this._all$ = new BehaviorSubject<Omnibox.Tag[]>([]);
    this._removed$ = new Subject<Omnibox.Tag>();
  }

  reset() {
    this.all = [];
  }

  add(...tags: Omnibox.Tag[]) {
    for (let s of tags) {
      let dx = this.all.findIndex((x) => x.id == s.id);
      if (dx != -1) this.all[dx] = s;
      else this.all = [...this.all, s];
    }

    // do not override previous object and force update of selected scope ids that might have been ready before scope was added
    this.all = [...this.all];
  }

  remove(...ids: string[]) {
    let newTags = this.all;
    for (let id of ids) {
      let dx = this.all.findIndex((x) => x.id === id);

      if (dx == -1) continue;
      this.removed = newTags[dx];
      newTags = newTags.filter((x) => x.id !== id);
    }
    this.all = [...newTags];
  }
}
