import { Filters, Search } from '@local/client-contracts';
import { EventInfo, LogService } from '@shared/services';
import { getDisplayHeader } from '@shared/utils/header-builder.util';
import { Logger } from '@unleash-tech/js-logger';
import { isObservable, map, Observable, of } from 'rxjs';
import { SearchResults, TelemetryTrigger } from 'src/app/bar/views';
import { FiltersService } from '../../../filters.service';
import { HubService } from '../../../hub.service';
import { LinkResourcesSearchClient, LinkResourcesSourceSettings } from '../link-resources';
import { SearchClient } from '../search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { MailSourceSettings } from './mail-source-settings';

export const INBOX_OPTIONS = ['INBOX', 'Inbox'];
export class MailSearchClient implements SearchClient<MailSourceSettings> {
  private logger: Logger;

  constructor(
    private logService: LogService,
    private LinkResourceSearchClient: LinkResourcesSearchClient,
    private hubService: HubService,
    private filtersService: FiltersService
  ) {
    this.logger = this.logService.scope('mail-resources');
  }

  supportsSort(sort: Search.Sort): boolean {
    return true;
  }

  supportsFilters(filters: Filters.Values): boolean {
    return true;
  }

  async search(request: SearchRequest<MailSourceSettings>, response: SearchResponse): Promise<Observable<void>> {
    let sourceSettings = request.sourceSettings;
    sourceSettings = this.removeAnyLabelWhenNeeded(sourceSettings);
    const innerResponse = response.clone();
    const settings: LinkResourcesSourceSettings = { ...sourceSettings, type: 'link-resources', caching: { strategy: 'cache-and-source' } };
    const res = await this.LinkResourceSearchClient.search({ ...request, sourceSettings: settings }, innerResponse);
    if (isObservable(res)) {
      return res.pipe(
        map(() => {
          return this.handleInnerResponse(request, response, innerResponse);
        })
      );
    }
    return of(this.handleInnerResponse(request, response, innerResponse));
  }

  nextPage(request: SearchRequest<MailSourceSettings>, response: SearchResponse, trigger: TelemetryTrigger): Promise<void> {
    const settings: LinkResourcesSourceSettings = { ...request.sourceSettings, type: 'link-resources' };
    const req: SearchRequest<LinkResourcesSourceSettings> = { ...request, sourceSettings: settings };
    return this.LinkResourceSearchClient.nextPage(req, response, trigger);
  }

  getTelemetryEndEvent(response: SearchResponse): Partial<EventInfo>[] {
    return this.LinkResourceSearchClient.getTelemetryEndEvent(response);
  }

  destroy(): void {
    this.LinkResourceSearchClient.destroy();
  }

  private handleInnerResponse(request: SearchRequest<MailSourceSettings>, response: SearchResponse, innerResponse: SearchResponse) {
    if (!innerResponse.done) {
      return;
    }
    response.extra = innerResponse.extra;
    if (!innerResponse.items || !Array.isArray(innerResponse.items[0])) {
      response.complete(true);
      return;
    }

    const itemsArray = innerResponse.items as SearchResults[][];
    if (itemsArray.every((a) => !a.length)) {
      response.complete(true);
      return;
    }

    response.extra = <MailSourceSettings>innerResponse.extra;

    const items: SearchResults[] = innerResponse.items[0] as SearchResults[];
    const itemsWithHeaders: SearchResults[] = [];

    const sourceSettings = request.sourceSettings;
    const inlineFilters = sourceSettings.filters?.preFilters;
    const postFilters = sourceSettings.filters?.postFilters;
    const inboxFilterOnly = this.hasOnlyInboxFilter(inlineFilters, postFilters, INBOX_OPTIONS);
    const cloudTotalCount = response.extra?.cloudTotalResults;

    // remove "labels" post filter if needed
    if (inlineFilters?.anyLabel?.length === 1 && !!response?.extra?.postFilters) {
      const { label, ...filters } = response?.extra?.postFilters || {};
      response.extra.postFilters = filters;
    }

    response.extra.inboxFilterOnly = inboxFilterOnly;

    if (items.length > 0) {
      let { title, titleEnd } = getDisplayHeader(
        { title: sourceSettings.header?.title, titleEnd: sourceSettings.header?.titleEnd },
        cloudTotalCount
      );
      if (sourceSettings.isDefault && response.extra?.postFilters?.account?.length > 1) {
        title = sourceSettings.header.multipleAccountsTitle;
      }
      const header: SearchResults = {
        type: 'header',
        clickable: false,
        origin: 'mail',
        title,
        titleEnd,
      };
      itemsWithHeaders.push(header);
    }

    response.items = [...itemsWithHeaders, ...items];
    response.complete(true);
  }

  private removeAnyLabelWhenNeeded(sourceSettings: MailSourceSettings) {
    const currentNode = sourceSettings.node;
    const sourceFilters = sourceSettings.filters.preFilters;
    const defaultFilters = ['type', 'app', 'anyLabel'];
    const appliedFilters = Object.keys(sourceSettings.filters?.preFilters).filter((filterName) => !defaultFilters.includes(filterName));
    if (currentNode.id === 'mail') {
      if (
        sourceFilters.anyLabel?.length &&
        (this.hubService.query || Object.keys(this.filtersService.postFilters).length || appliedFilters.length)
      ) {
        const { anyLabel, ...inline } = sourceSettings.filters?.preFilters || {};
        sourceSettings.filters.preFilters = inline;
      }
    }
    return sourceSettings;
  }

  private hasOnlyInboxFilter(inlineFilters: Filters.Values, postFilters: Filters.Values, inboxesOptions: string[]) {
    const { anyLabel, label } = inlineFilters;
    const labelFilter = label?.length > 0 ? label : anyLabel;
    if (labelFilter?.length !== inboxesOptions.length) return false;
    if (Object.keys(postFilters).length > 0) return false;

    for (const label of labelFilter) {
      if (!inboxesOptions.includes(label)) {
        return false;
      }
    }
    return true;
  }
}
