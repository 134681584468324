import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { Config } from '@environments/config';
import { Developer } from '@local/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoaderService } from '@shared/loader.service';
import { NativeServicesRpcService, ServicesRpcService } from '@shared/services';
import { RouterService } from '@shared/services/router.service';
import { copyToClipboard } from '@shared/utils';
import { isKey, keyCodes, removeFirstLine } from '@local/ts-infra';
import { LogRecord } from '@unleash-tech/js-logger';
import { Subject } from 'rxjs';
import { GlobalErrorHandler } from 'src/app/global-error-handler';
import { HubService } from '../../../services/hub.service';

@UntilDestroy()
@Component({
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPageComponent implements OnInit {
  showStack = Config.environment != 'production';
  error: Error;
  private readonly destroy$ = new Subject();
  private actions: Developer.Actions;

  get error$() {
    return this.errorHandler.error$;
  }
  get stringifiedError(): string {
    return this.errorToString(this.error) ?? '';
  }

  constructor(
    private errorHandler: GlobalErrorHandler,
    private routerService: RouterService,
    private services: ServicesRpcService,
    private hubService: HubService,
    private nativeServices: NativeServicesRpcService,
    private loaderService: LoaderService
  ) {
    this.actions = (this.nativeServices || this.services).invokeWith(Developer.DeveloperActionsRpcInvoker, 'developeractions');
    this.loaderService.ready$.next(true);
    this.error$.pipe(untilDestroyed(this)).subscribe((e) => {
      this.error = e;
      if (!e) this.routerService.back();
    });
  }

  ngOnInit(): void {
    this.hubService.readOnly = true;
  }

  onCopy(): Promise<void> {
    return copyToClipboard(this.stringifiedError);
  }

  async onCreateIssue() {
    const record: LogRecord = {
      message: this.stringifiedError,
      type: 'error',
      params: {},
      scope: '',
      time: Date.now(),
    };
    const url = await this.actions.createIssueUrl(Config.version, record);
    window.open(url);
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (isKey(event, keyCodes.escape)) {
      this.errorHandler.clear();
    }
  }

  onClose(): void {
    this.errorHandler.error = null;
  }

  errorToString(error: Error): string {
    if (!error) return;
    const name = error.name === undefined ? 'Error' : String(error.name);
    const msg = error.message === undefined ? 'Error' : String(error.message);
    const stack = error.stack === undefined ? 'Error' : String(error.stack);

    if (name === '' || (msg === '' && stack === '')) {
      return name;
    }

    return `${name}: ${msg}  \n ${removeFirstLine(stack)}`;
  }
}
