import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Questionnaire } from '@local/client-contracts';
import { getAssistantTitle } from '@local/common-web';
import { getExtensionByFileName, getFileNameWithoutExt } from '@local/ts-infra';
import { PopupRef, UiIconModel } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Step } from '@shared/components/stepper/stepper.component';
import { LoaderService } from '@shared/loader.service';
import { LogService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';
import { BehaviorSubject } from 'rxjs';
import { SelectedItem } from 'src/app/bar/components/selected-item-popup/selected-item-popup.component';
import { BlobsService } from 'src/app/bar/services/blob.service';
import { ExperiencesService } from 'src/app/bar/services/experiences.service';
import { QuestionnaireService } from 'src/app/bar/services/questionnaire.service';
import { questionnaireContent } from './questionnaire.content';

export enum QuestionnaireEnum {
  Upload,
  Mapping,
  Processing,
  Summary,
  Loading,
}

export type QuestionnairePopupData = {
  processId?: string;
  assistantId?: string;
};

export interface QuestionnaireData {
  blobId?: string;
  worksheets?: Questionnaire.Worksheet[];
  assistantId?: string;
  fileName?: string;
  extension?: string;
  processId?: string;
  activeProcess?: Questionnaire.QuestionnaireStatusResponse;
}

@UntilDestroy()
@Component({
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnaireComponent implements OnInit, OnDestroy {
  readonly questionnaireContent = questionnaireContent;
  readonly closeIcon: UiIconModel = {
    type: 'font',
    value: 'icon-Windows-close',
  };
  private _currentStep: QuestionnaireEnum;
  private ref: PopupRef<QuestionnaireComponent, QuestionnairePopupData>;
  private completedInterval: NodeJS.Timeout;
  private logger: Logger;
  currentStep$: BehaviorSubject<QuestionnaireEnum> = new BehaviorSubject(QuestionnaireEnum.Loading);
  questionnaireEnum = QuestionnaireEnum;
  preparingUpdatedFile: boolean;
  windowMode: boolean;
  downloadFile: boolean;
  assistantsList: SelectedItem[] = [];
  selectedAssistant: SelectedItem;
  data: QuestionnaireData = {};
  steps: Step[] = [
    { label: questionnaireContent.upload, completed: false },
    { label: questionnaireContent.mapping, completed: false },
    { label: questionnaireContent.processing, completed: false },
    { label: questionnaireContent.summary, completed: false },
  ];

  get currentStep(): QuestionnaireEnum {
    return this._currentStep;
  }

  set currentStep(value: QuestionnaireEnum) {
    this.currentStep$.next(value);
    if (value !== QuestionnaireEnum.Loading) {
      this._currentStep = value;
    }
  }

  constructor(
    private questionnaireService: QuestionnaireService,
    private loaderService: LoaderService,
    private activeRoute: ActivatedRoute,
    private blobsService: BlobsService,
    private logService: LogService,
    private assistantService: ExperiencesService,
    private cdr: ChangeDetectorRef
  ) {
    this.logger = this.logService.scope('Questionnaire');
  }

  ngOnInit(): void {
    this.windowMode = this.activeRoute.snapshot.data.windowMode;
    if (this.windowMode) {
      this.loaderService.ready$.next(true);
    } else {
      this.ref = this.questionnaireService.questionnairePopupRef;
    }
    this.initProcess();
  }

  ngOnDestroy(): void {
    if (this.completedInterval) {
      clearInterval(this.completedInterval);
      this.completedInterval = null;
    }
  }

  initAssistants() {
    this.data.assistantId = this.ref?.data?.assistantId || this.activeRoute?.snapshot?.queryParams['assistantId'];
    this.assistantService.visible$.pipe(untilDestroyed(this)).subscribe(async (all) => {
      this.assistantsList =
        all
          ?.filter((a) => a.experienceType === 'rfp')
          ?.map((assistant) => {
            return {
              name: getAssistantTitle(assistant),
              id: assistant.id,
            };
          }) || [];

      if (this.data?.assistantId) {
        this.selectedAssistant = this.assistantsList.find((a) => a.id === this.data.assistantId);
        this.assistantsList = this.assistantsList.filter((a) => a.id !== this.data.assistantId);
        this.assistantsList.unshift(this.selectedAssistant);
      }

      this.cdr.markForCheck();
    });
  }

  async initProcess() {
    let processId =
      this.activeRoute?.snapshot?.params['processId'] ||
      this.ref?.data?.processId ||
      (await this.questionnaireService.getCurrentProcessId());

    if (['null', 'undefined'].includes(processId)) {
      processId = await this.questionnaireService.getCurrentProcessId();
    }

    if (processId) {
      await this.initData(processId);
    } else {
      this.currentStep = QuestionnaireEnum.Upload;
    }

    this.initAssistants();
  }

  private async initData(processId: string) {
    try {
      this.data.processId = processId;
      this.data.activeProcess = await this.questionnaireService.status(processId);
      const blob = await this.blobsService.getBlobMetadata(this.data.activeProcess.resultBlobId, null);
      this.setFile(blob.name);
      this.currentStep = QuestionnaireEnum.Upload;

      switch (this.data.activeProcess.status) {
        case 'Completed':
          this.currentStep = QuestionnaireEnum.Summary;
          break;
        case 'ExtractingQuestions':
        case 'ProcessingQuestions':
          this.currentStep = QuestionnaireEnum.Processing;
          break;
        case 'Failed':
        case 'Canceled':
          this.questionnaireService.setCurrentProcessId();
          this.currentStep = QuestionnaireEnum.Upload;
          break;
      }
    } catch (error) {
      this.logger.error('failed to get status rfp', error);
      this.questionnaireService.setCurrentProcessId();
      this.currentStep = QuestionnaireEnum.Upload;
    }
  }

  async onUploadFile(request: QuestionnaireData) {
    this.data = request;
  }

  goToMapping(request: QuestionnaireData) {
    this.data = request;
    this.currentStep = QuestionnaireEnum.Mapping;
  }

  private setFile(name: string) {
    this.data.fileName = getFileNameWithoutExt(name);
    this.data.extension = getExtensionByFileName(name);
  }

  async runProcess(updatedWorksheets: Questionnaire.Worksheet[], processId: string) {
    this.data.worksheets = updatedWorksheets;
    this.data.processId = processId;
    this.questionnaireService.setCurrentProcessId(this.data.processId);
    this.currentStep = QuestionnaireEnum.Processing;
  }

  async processingFinish() {
    this.preparingUpdatedFile = true;
    this.currentStep = QuestionnaireEnum.Loading;
    await this.awaitToCompletedProcessing();
  }

  private async awaitToCompletedProcessing() {
    this.data.activeProcess = await this.questionnaireService.status(this.data.processId);
    this.completedInterval = setInterval(async () => {
      if (this.data.activeProcess.status !== 'Completed') {
        this.data.activeProcess = await this.questionnaireService.status(this.data.processId);
      } else {
        this.currentStep = QuestionnaireEnum.Summary;
        clearInterval(this.completedInterval);
        this.completedInterval = null;
      }
    }, 5000);
  }

  onDownloadFile() {
    this.downloadFile = true;
  }

  async goBack() {
    await this.questionnaireService.setCurrentProcessId();
    this.currentStep = QuestionnaireEnum.Mapping;
  }

  async closePopup() {
    if (this.downloadFile) {
      this.questionnaireService.closePopup(this.windowMode);
      this.questionnaireService.setCurrentProcessId();
      return;
    }

    if (!this.data?.fileName) {
      this.questionnaireService.closePopup(this.windowMode);
      return;
    }

    this.questionnaireService.openWarningPopup(this.currentStep, this.data.processId, this.data.blobId, this.windowMode);
  }
}
