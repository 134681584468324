import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { EmojiMapItem } from '../../../types/emoji-item';
import { SpriteCssEmoji } from '../../../types/emoji-sprite';
import { SpriteCssEmojiPosition } from '../../../types/emoji-sprite-position';
import { EMOJI_BG_IMAGE_URL, EMOJI_TABLE_COLUMNS, EMOJI_TABLE_ROWS, EMOJI_UNICODE_SHEET } from '../u-emoji.consts';

@Component({
  selector: 'u-emoji',
  templateUrl: './u-emoji-item.component.html',
  styleUrls: ['./u-emoji-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UEmojiComponent {
  @Input() nativePrefix: string;
  @Input() spriteEmojiSize = 18;
  @Input() fallbackNativePrefix = '&#x';
  private _unicode: string;
  @Input() set unicode(value: string) {
    if (!value) return;
    if (value === this._unicode) return;
    this._unicode = value;
    const emoji = this.unicodeToEmojiItem(value);
    if (emoji) {
      this.emojiType = 'emoji';
      this.emojiAsSprite = this.buildEmojiSprite([emoji.sheet[0], emoji.sheet[1]]);
    } else {
      this.emojiType = 'native';
      this.nativeEmoji = (this.nativePrefix ? '' : this.fallbackNativePrefix) + decodeURIComponent(value);
    }
  }

  @Input()
  public set emoji(value: SpriteCssEmojiPosition) {
    if (!value) return;
    setTimeout(() => {
      this.emojiAsSprite = this.buildEmojiSprite(value);
      this.emojiType = 'emoji';
      this.cdr.markForCheck();
    }, 0);
  }

  emojiAsSprite: SpriteCssEmoji;
  nativeEmoji: string;
  emojiType: 'native' | 'emoji' = null;

  constructor(private cdr: ChangeDetectorRef) {}

  private unicodeToEmojiItem(unicode: string): EmojiMapItem {
    const exp = unicode.toLocaleLowerCase().replace(this.nativePrefix?.toLocaleLowerCase(), '');
    return EMOJI_UNICODE_SHEET.find((e) => e.unicode.toLocaleLowerCase() === exp);
  }

  private buildEmojiSprite(position: SpriteCssEmojiPosition): SpriteCssEmoji {
    return {
      width: `${this.spriteEmojiSize}px`,
      height: `${this.spriteEmojiSize}px`,
      display: 'inline-block',
      'background-image': `url(${EMOJI_BG_IMAGE_URL})`,
      'background-size': `${100 * EMOJI_TABLE_COLUMNS}% ${100 * EMOJI_TABLE_ROWS}%`,
      'background-position': this.getSpritePosition(position, EMOJI_TABLE_COLUMNS),
    };
  }

  private getSpritePosition(sheet: SpriteCssEmojiPosition, sheetColumns: number) {
    const [sheetX, sheetY] = sheet;
    const multiply = 100 / (sheetColumns - 1);
    return `${multiply * sheetX}% ${multiply * sheetY}%`;
  }
}
