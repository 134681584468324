import { Window } from '@local/client-contracts';
import { observable } from '@local/common';
import { handler } from '@unleash-tech/js-rpc';
import { BehaviorSubject, combineLatest, firstValueFrom, fromEvent, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { NativeMainRpcService } from '.';
import { WindowRpcInvoker } from './invokers';

export class WindowService implements Window.Service {
  private rpcInvoker: Window.Service;

  private _pinned$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _alwaysOnTop$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  get titleBar(): Promise<Partial<Array<Window.Controls>> | null> {
    return null;
  }
  get pinned() {
    return this._pinned$.getValue();
  }

  async openAuxWindow(settings: Window.OpenAuxWindowOptions) {
    this.rpcInvoker.openAuxWindow(settings);
  }

  async switchToStandard(u?: string) {
    return this.rpcInvoker.switchToStandard(u);
  }

  async openNative(u?: string) {
    return this.rpcInvoker.openNative(u);
  }

  get style$(): Observable<Window.WindowStyle> {
    return this.rpcInvoker.style$;
  }

  getInfo(): Promise<Window.WindowInfo> {
    return this.rpcInvoker.getInfo();
  }

  set pinned(value: boolean) {
    this.alwaysOnTop(value);
    this._pinned$.next(value);
  }

  get pinned$() {
    return combineLatest([this._alwaysOnTop$, this._pinned$]).pipe(map(([a, p]) => p));
  }

  get visibility(): boolean {
    return document.visibilityState === 'visible';
  }

  get isFocused() {
    if (document.querySelectorAll('iframe')) {
      return (
        document.hasFocus() ||
        Array.from(document.querySelectorAll('iframe')).some((el) => (el as HTMLIFrameElement)?.contentWindow?.document?.hasFocus())
      );
    }
    return document.hasFocus();
  }

  get properties(): Promise<Window.WindowProperties> {
    return this.rpcInvoker.properties;
  }

  @observable
  get alwaysOnTop$(): Observable<boolean> {
    return this._alwaysOnTop$;
  }

  public set _alwaysOnTop(value: boolean) {
    this._alwaysOnTop$.next(value);
  }

  get minimized$(): Observable<boolean> {
    return this.rpcInvoker.minimized$;
  }

  @handler
  get visibility$() {
    return fromEvent(document, 'visibilitychange').pipe(
      map((v) => this.visibility),
      distinctUntilChanged()
    );
  }

  @observable
  get autoHide$(): Observable<boolean> {
    return;
  }

  async autoHide(value: boolean) {
    const res = await this.rpcInvoker.autoHide(value);
    return res;
  }

  get focused$(): Observable<boolean> {
    return this.rpcInvoker.focused$;
  }

  @observable
  get visible$(): Observable<boolean> {
    return;
  }

  get maximized$(): Observable<boolean> {
    return this.rpcInvoker.maximized$;
  }

  get isFullScreen$(): Observable<boolean> {
    return this.rpcInvoker.isFullScreen$;
  }

  get visible(): Promise<boolean> {
    return this.rpcInvoker.visible;
  }
  get size(): Promise<{ width: number; height: number }> {
    return this.rpcInvoker.size;
  }

  constructor(private services: NativeMainRpcService) {
    if (!services) return;
    this.rpcInvoker = this.services.invokeWith(WindowRpcInvoker, 'window');
    this.services.handleWith(this, 'window');
    this.rpcInvoker.alwaysOnTop$.subscribe((v) => (this._alwaysOnTop = v));
    this.initPin();
  }

  setResizable(b: boolean): Promise<void> {
    return this.rpcInvoker.setResizable(b);
  }

  openDevTools(): Promise<void> {
    return this.rpcInvoker.openDevTools();
  }

  private initPin() {
    this.services.handle('PinnedByUser', (p: boolean) => {
      this.pinned = p;
    });
  }

  resize(width?: number, height?: number): Promise<void> {
    return this.rpcInvoker.resize(width, height);
  }

  close(): Promise<void> {
    return this.rpcInvoker.close();
  }

  show(): Promise<void> {
    return this.rpcInvoker.show();
  }

  hide(): Promise<void> {
    return this.rpcInvoker.hide();
  }

  async maximize(forcedValue?: boolean): Promise<void> {
    return this.rpcInvoker.maximize(forcedValue);
  }

  restore(): Promise<void> {
    return this.services.invoke('window.restore');
  }

  minimize(): Promise<void> {
    return this.services.invoke('window.minimize');
  }
  moveTop(): Promise<void> {
    return this.rpcInvoker.moveTop();
  }
  async setFullScreen(): Promise<void> {
    return this.rpcInvoker.setFullScreen(!(await firstValueFrom(this.isFullScreen$)));
  }

  async alwaysOnTop(alwaysOnTop?: boolean): Promise<boolean> {
    const res = await this.rpcInvoker.alwaysOnTop(alwaysOnTop);
    return res;
  }
}
