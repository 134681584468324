import { Injectable } from '@angular/core';
import { Preferences } from '@local/client-contracts';
import { EventInfo } from './event-info';
import { eventsMap } from './event-map';
import { TelemetryService } from './telemetry.service';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(public analyticsService: TelemetryService) {}

  preferences(reportReason: 'edit' | 'upgrade', data: Preferences.Preferences, time?: number): Promise<void> {
    return this.analyticsService.preferences(reportReason, data);
  }

  async event(key: string, info?: Partial<EventInfo>, flush: boolean = false) {
    const parts = key.split('.');
    if (parts.length === 1) {
      let ev = eventsMap[key] as EventInfo;
      if (!ev) {
        return;
      }
      if (info) {
        ev = Object.assign({}, ev, info);
      }
      this.analyticsService.event(ev);
    } else if (parts.length === 2) {
      if (!eventsMap[parts[0]] || !eventsMap[parts[0]][parts[1]]) {
        return;
      }
      const { category, name, target, label, nonInteraction, location } = eventsMap[parts[0]] as EventInfo;
      let ev: EventInfo = { category, name, target, label, nonInteraction, location };
      ev = Object.assign({}, ev, eventsMap[parts[0]][parts[1]]);
      if (info) {
        Object.assign(ev, info);
      }
      await this.analyticsService.event(ev);
    } else {
      console.warn('No match for event in event map', { key });
    }
    if (flush) await this.flush();
  }

  flush(waitOnFlush = true) {
    return this.analyticsService.flush(waitOnFlush);
  }
}
