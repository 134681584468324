<section class="events-container" *ngIf="items && items.length > 0 && !isLoading && !error">
  <ng-scrollbar [autoHeightDisabled]="false" #scrollArea>
    <div class="headline" [@simpleFadeAnimation]="'in'">
      Hello <span>{{ user }},</span> here are your meetings for the upcoming week...
    </div>

    <div *ngFor="let items of eventsByDay | keyvalue; trackBy: trackEventsByDate">
      <span
        [@simpleFadeAnimation]="'in'"
        class="list-title"
        [pTooltip]="getDateForTooltip(items)"
        tooltipPosition="bottom"
        tooltipStyleClass="u-tooltip"
        *ngIf="eventsByDay[items.key].length > 0"
      >
        {{ getDateFromTimestamp(items.key) }}
      </span>
      <section class="events-table">
        <ul class="events-list" tabindex="-1" #list>
          <up-next-result-item
            tabindex="1"
            class="events-item-row table"
            *ngFor="let item of items.value; index as i; trackBy: trackEventsByResourceId"
            [index]="getIndex(item)"
            [model]="item"
            [pageNameForTelemetry]="'calendar'"
            (excluded)="excludeResource(items.key, i, getIndex(item))"
            [@simpleFadeAnimation]="'in'"
            (click)="selectedIndex = getIndex(item)"
            [class.selected]="selectedIndex === getIndex(item)"
            [searchInfo]="searchInfo"
            [selected]="selectedIndex === getIndex(item)"
            #scrollItem
          >
          </up-next-result-item>
        </ul>
      </section>
    </div>
  </ng-scrollbar>
</section>

<error-overlay
  *ngIf="error && !isLoading"
  (closeOverlay)="goBack()"
  (back)="goBack()"
  (again)="tryAgain()"
  displayText="For some reason we could not fetch your upcoming events."
>
</error-overlay>

<div *ngIf="!isLoading && !error && items?.length == 0">
  <ng-container *ngIf="(isOnline$ | async) === false; else emptyState">
    <app-empty-state
      [title]="'Whoops!'"
      [subtitle]="'Re-connect your internet to access this part of the app.'"
      [svgUrl]="'./assets/bar/calendar/offline-state.svg'"
    ></app-empty-state>
  </ng-container>
  <ng-template #emptyState>
    <ng-container>
      <app-empty-state
        class="no-events-empty-state"
        title="You’re off the hook!"
        subtitle="No upcoming meeting or events scheduled"
        [svgUrl]="'./assets/bar/calendar/empty-state.svg'"
      ></app-empty-state>
    </ng-container>
  </ng-template>
</div>

<connect-apps
  class="connect-apps"
  *ngIf="!isLoading && !error && items === undefined && apps.length"
  [model]="{
    apps: apps,
    title: { app: 'Connect your calendar now to know what\'s next' },
    viewName: 'calendar',
    disableAppsTitle: 'To connect your calendar contact workspace administrator'
  }"
></connect-apps>
