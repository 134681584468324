import { Injectable } from '@angular/core';
import { Accounts } from '@local/client-contracts';
import { AccountsRpcInvoker } from '@local/common';
import { Observable, ReplaySubject } from 'rxjs';
import { NativeServicesRpcService, ServicesRpcService } from '.';
import { sanitize } from '@local/ts-infra';

@Injectable({
  providedIn: 'root',
})
export class AccountsService implements Accounts.Service {
  private service: Accounts.Service;
  private _all: Accounts.WorkspaceAccount[];
  private _all$ = new ReplaySubject<Accounts.WorkspaceAccount[]>(1);
  private _accountsLength: number;
  private _accountsLength$ = new ReplaySubject<number>(1);
  constructor(services: ServicesRpcService, nativeServices: NativeServicesRpcService) {
    this.service = (nativeServices || services).invokeWith(AccountsRpcInvoker, 'accounts');
    this.service.all$.subscribe((accounts) => {
      if (!accounts) {
        this._all = accounts;
        this._all$.next(accounts);
        return;
      }
      const weAccounts: Accounts.WorkspaceAccount[] = [];
      for (const account of accounts) {
        weAccounts.push({
          ...account,
          firstName: sanitize(account.firstName),
          lastName: sanitize(account.lastName),
          email: sanitize(account.email),
          name: sanitize(account.name),
        });
      }
      this._all = weAccounts;
      this._all$.next(weAccounts);
    });
    this.service.accountsLength$.subscribe((len) => {
      this._accountsLength$.next(len);
    });
  }
  search$(searchProperties: Accounts.SearchRequest): Observable<Accounts.SearchResponse> {
    return this.service.search$(searchProperties);
  }

  refresh(): Promise<void> {
    return this.service.refresh();
  }

  get all$(): ReplaySubject<Accounts.WorkspaceAccount[]> {
    return this._all$;
  }

  get all(): Accounts.WorkspaceAccount[] {
    return this._all;
  }

  get accountsLength$(): Observable<number> {
    return this._accountsLength$;
  }
  get accountsLength(): number {
    return this._accountsLength;
  }
}
