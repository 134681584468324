<div class="request-item">
  <avatar [size]="'24px'" [url]="item.userImage"></avatar>
  <div class="request-item-content">
    <span class="request-item-title">{{ item.userName || DEFAULT_USER_NAME }}</span>
    <span class="request-item-sub-title" [pTooltip]="dateFormatted" tooltipPosition="bottom" tooltipStyleClass="u-tooltip"
      >Requested verification {{ dateAsString }}</span
    >
    <div class="request-item-message">
      <span #messageRequest class="message-text" [ngClass]="{ show: showFullMessage }">{{ item.message }}</span>
      <u-button
        *ngIf="showMoreMessageButton"
        [label]="showFullMessage ? 'Show less' : 'Show more'"
        type="secondary"
        class="show-button no-state"
        (click)="showFullMessage = !showFullMessage; maxHeightMessage()"
      >
      </u-button>
    </div>
  </div>
</div>
