import { Injectable } from '@angular/core';
import { PopupRef, PopupService } from '@local/ui-infra';
import { OpenWithPromptComponent, OpenWithPromptData } from '@shared/components/prompt/open-with-prompt.component';

@Injectable()
export class OpenWithPromptService {
  private popupRef: PopupRef<OpenWithPromptComponent, OpenWithPromptData>;

  constructor(protected popupService: PopupService) {}

  onOpenWithPrompt(promptData: OpenWithPromptData) {
    if (this.popupRef) {
      this.popupRef.destroy();
      this.popupRef = null;
    }
    this.popupRef = this.popupService.open<OpenWithPromptComponent, OpenWithPromptData>('center', OpenWithPromptComponent, null, {
      backdropStyle: 'blur-1',
      fullScreenDialog: true,
    });
    this.popupRef.compInstance.model = promptData;
    return this.popupRef;
  }
}
