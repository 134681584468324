<button class="delete-button" *ngIf="clearable && keys.length" (click)="update.emit([])">Delete</button>
<div
  class="keyboard-shortcut"
  [class.empty]="!keys || keys.length === 0"
  [class.read-only]="readonly"
  (click)="listenToKeyboard()"
  *ngIf="!editing; else editMode"
  [pTooltip]="readonly ? null : 'Edit'"
  tooltipPosition="top"
  tooltipStyleClass="u-tooltip"
>
  <ng-container *ngIf="!keys || keys.length === 0">
    <button class="cancel">Set</button>
  </ng-container>
  <div class="key" [class.error]="errorMsg" [class.read-only]="readonly" *ngIf="keys && keys.length > 0">
    <ng-container *ngFor="let key of keys; last as last">
      <span [innerHTML]="key | titlecase | keyIcon"></span>
      <span class="plus" *ngIf="!last">+</span>
    </ng-container>
  </div>
</div>

<ng-template #editMode>
  <div class="keyboard-shortcut editing" [class.error]="errorMsg">
    <div
      class="key"
      (click)="tempKeys.length ? null : cancelKeyboardListener()"
      [class.error]="errorMsg"
      [class.read-only]="readonly"
      [class.empty]="tempKeys && tempKeys.length === 0 && !errorMsg"
    >
      <ng-container *ngFor="let key of tempKeys || []; last as last">
        <span [innerHTML]="key | titlecase | keyIcon"></span>
        <span class="plus" *ngIf="!last">+</span>
      </ng-container>
      <ng-container *ngIf="tempKeys && tempKeys.length === 0 && !errorMsg">
        <div class="ellipsis-container">
          <dynamic-ellipsis></dynamic-ellipsis>
        </div>
      </ng-container>
    </div>
    <p class="error-message" [class.visible]="errorMsg">{{ errorMsg }}</p>
  </div>
</ng-template>
