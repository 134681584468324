import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UTreeComponent } from './u-tree.component';
import { TreeModule } from 'primeng/tree';
import { HighlightSearchPipe } from '../../pipes/highlightSearch.pipe';
import { UiInfraModule } from '../../ui-infra.module';
import { UIconModule } from '../u-icon/u-icon.module';

@NgModule({
  declarations: [UTreeComponent, HighlightSearchPipe],
  imports: [CommonModule, TreeModule, UiInfraModule, UIconModule],
  exports: [UTreeComponent],
})
export class UTreeModule {}
