import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, HostBinding, HostListener, Input, ViewChild } from '@angular/core';
import { Intent } from '../../views/hub/shared/model';

/** In order to render icon in the projected content add #icon on the container */
@Component({
  selector: 'bar-badge',
  templateUrl: './bar-badge.component.html',
  styleUrls: ['./bar-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarBadgeComponent {
  @ViewChild('icon') iconContainerRef: ElementRef;
  @ContentChild('icon') projectedIcon: ElementRef;
  @Input() intent?: Intent = 'none';
  @Input() text?: string;
  @Input()
  tooltip?: string;

  @HostBinding('class') get class() {
    return { [this.intent]: true, ['has-text']: !!this.text || !!this.projectedIcon };
  }

  /** Since it's a 'dumb' UI component  and the usage of native events will look like `(click)="doSomething()"`
   *  we are stopping the propagation of the events so it will be handled only by the native dom and not angular */
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
