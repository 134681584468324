import { Injectable } from '@angular/core';
import { Window } from '@local/client-contracts';
import { AppName, observable } from '@local/common';
import { getBrowser, isEmbed, isMobile, isNativeWindow } from '@local/common-web';
import { EmbedService } from '@shared/embed.service';
import { distinctUntilChanged, Observable, ReplaySubject } from 'rxjs';
import { EventsService } from './telemetry';
import { WindowService } from './window.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private _windowStyle$: ReplaySubject<Window.WindowStyle> = new ReplaySubject(1);
  private _focus$: ReplaySubject<boolean> = new ReplaySubject(1);
  private _visible$: ReplaySubject<boolean> = new ReplaySubject(1);
  private lastFocus: number;
  private lastBlur: number;
  private readonly isEmbed = isEmbed();
  private readonly isNative = isNativeWindow();
  private readonly isMobile = isMobile();

  browser = getBrowser();
  platform: AppName;
  readyTime: number;

  get startTime(): number {
    return (<any>self).__START_TIME_APP;
  }

  private set windowStyle(value: Window.WindowStyle) {
    this._windowStyle$.next(value);
  }

  @observable
  get windowStyle$(): Observable<Window.WindowStyle> {
    return this._windowStyle$;
  }

  @observable
  get focus$(): Observable<boolean> {
    return this._focus$.pipe(distinctUntilChanged());
  }

  @observable
  get visible$(): Observable<boolean> {
    return this._visible$.pipe(distinctUntilChanged());
  }

  constructor(private windowService: WindowService, private embedService: EmbedService, private eventsService: EventsService) {
    if (this.windowService) {
      this.windowService.style$.subscribe((m) => {
        this.windowStyle = m;
        this.setPlatformType();
      });
    }
    if (this.isEmbed) {
      this.embedService.options$.subscribe((o) => {
        this.windowStyle = o.type == 'quick-search' || o.type == 'assistant' ? 'floating-bar' : 'standard';
        this.setPlatformType();
      });
    } else if (!this.isNative) {
      this.windowStyle = 'standard';
      this.setPlatformType();
    }
    document.addEventListener(
      'focus',
      (event) => {
        this.onFocusChanged(true);
      },
      { passive: true, capture: true }
    );

    document.addEventListener(
      'blur',
      (event) => {
        this.onFocusChanged(false);
      },
      { passive: true, capture: true }
    );
    this._visible$.next(!document.hidden);
    document.addEventListener('visibilitychange', () => {
      this._visible$.next(!document.hidden);
    });
  }

  setReadyTime() {
    this.readyTime = Date.now() - this.startTime;
  }

  private onFocusChanged(focus: boolean) {
    const isDeveloper = location.pathname.startsWith('/developer');
    const now = Date.now();
    if (!focus || document.visibilityState === 'hidden') {
      this._focus$.next(false);
      this.lastBlur = now;
      return;
    }
    const prevFocus = this.lastFocus;
    this.lastFocus = now;
    if (prevFocus + 200 > now || this.lastBlur + 200 > now) {
      return;
    }
    if (!isDeveloper && !this.isEmbed && !this.isNative) {
      this.eventsService.event('launch.web');
    }
    this._focus$.next(true);
  }

  switchToStandard(url?: string) {
    this.windowService.switchToStandard(url);
    this.windowStyle = null;
  }

  private async setPlatformType() {
    if (this.isNative) {
      this.platform = 'desktop';
      return;
    }
    if (this.isMobile) {
      this.platform = 'mweb';
      return;
    }
    if (this.isEmbed) {
      this.platform = (await this.embedService.isExternalWebSite()) ? 'embed' : 'extension';
      return;
    }
    this.platform = 'web';
  }
}
