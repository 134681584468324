<div class="new-static-item-container" data-cy="add-item-container">
  <div
    class="new-static-item"
    *ngFor="let option of staticItemsOptions; let i = index"
    (click)="onClickOption(option)"
    (mouseover)="onHoverItem(i)"
    [class.selected]="i === selectedIndex"
  >
    <div class="item-text-line">
      <u-icon *ngIf="option.icon" class="item-icon" [model]="{ type: 'font', value: option.icon }"></u-icon>
      <div class="item-text">{{ option?.text }}</div>
    </div>
  </div>
  <div class="file-upload-container" (mouseover)="onHoverItem(fileItemIndex)" [class.selected]="fileItemIndex === selectedIndex">
    <u-icon [model]="{ type: 'font', value: 'icon-file-empty' }" [styles]="{ fontSize: '12px' }"></u-icon>
    <u-file-upload
      data-cy="file-upload-button"
      class="upload-image-button"
      [label]="'File'"
      (onSelect)="onUpload($event)"
      (onError)="onUploadError($event)"
      [maxFileSize]="imageMaxSize"
      [textOnly]="true"
    ></u-file-upload>
  </div>
</div>
