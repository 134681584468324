import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UButtonComponent } from './u-button.component';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [UButtonComponent],
  imports: [CommonModule, ButtonModule],
  exports: [UButtonComponent],
})
export class UButtonModule {}
