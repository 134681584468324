import { OAuth, OAuth2, Signin } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class OAuth2SignInSessionsRpcInvoker implements Signin.SignInSessionService {
  @invoker
  creating$(id: string): Observable<void> {
    return;
  }
  @invoker
  complete(url: string): Promise<void> {
    return;
  }
  @invoker
  completed$(id: string): Observable<OAuth2.SessionResult> {
    return;
  }

  @invoker
  create(id: string, request: OAuth2.Request, appId: string): Promise<OAuth.Session> {
    return;
  }

  @invoker
  reset(id: string): Promise<void> {
    return;
  }

  @invoker
  destroy(id: string, reason?: string): Promise<void> {
    return;
  }
}
