import { Collections, Permissions, Search, Verifications } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class CollectionsRpcInvoker implements Collections.Service {
  @invoker
  get all$(): Observable<Collections.Collection[]> {
    return;
  }
  @invoker
  get(id: string): Promise<Collections.Collection> {
    return;
  }

  @invoker
  create(collection: Collections.Collection): Promise<void> {
    return;
  }

  @invoker
  delete(id: string): Promise<void> {
    return;
  }

  @invoker
  update(id: string, actions: Collections.UpdateAction[], shareOptions: Permissions.ShareOptions): Promise<void> {
    return;
  }

  @invoker
  getCacheStrategy(id: string): Search.SearchCacheStrategy {
    return;
  }

  @invoker
  refresh(): Promise<void> {
    return;
  }

  @invoker
  createPolicy(createPolicy: Verifications.CreatePolicy, collectionId: string): Promise<void> {
    return;
  }

  @invoker
  updatePolicy(updatePolicy: Verifications.UpdatePolicy, collectionId: string): Promise<void> {
    return;
  }

  @invoker
  getScopes(id: string, accountId: string): Promise<Collections.scopesResponse> {
    return;
  }
}
