<a class="dismiss-button" (click)="onCancel.emit()">
  <u-icon class="close-button" [model]="{ type: 'font', value: 'icon-times-large' }"></u-icon>
</a>

<ng-container *ngTemplateOutlet="!showFileUploadError ? main : fileUploadError"></ng-container>

<!-- TEMPLATES -->
<ng-template #main>
  <header>
    <div class="header-content-container">
      <div class="header-content">
        <div class="header-content-title">Edit Image</div>
        <div class="header-content-subtitle">
          {{ sizeImage === 'large' ? DESCRIPTION_SIZE_IMAGES.large : DESCRIPTION_SIZE_IMAGES.small }}
        </div>
      </div>
    </div>
  </header>
  <div *ngIf="stage === 'upload'" class="cropped-image-name-container">
    <u-icon [model]="{ type: 'font', value: 'icon-file-image' }"></u-icon>
    <div>{{ imageName }}</div>
  </div>
  <section class="cropper-section" [class.no-bg]="isLoading">
    <ng-container *ngIf="!isLoading; else loader">
      <ng-container [ngSwitch]="stage">
        <ng-container *ngSwitchCase="'crop'">
          <u-image-cropper
            [imageFile]="imageToCrop"
            [aspectRatio]="aspectRatio"
            (onCroppedImageReady)="onCroppedImageReady($event)"
          ></u-image-cropper>
        </ng-container>
        <ng-container *ngSwitchCase="'upload'">
          <u-file-upload
            (onSelect)="fileChangeEvent($event)"
            [dragAndDropFile]="true"
            [mode]="'advanced'"
            [showThemeName]="false"
            [userFilePreview]="croppedImagePreview"
            [accept]="supportedImageTypes"
          ></u-file-upload>
        </ng-container>
      </ng-container>
    </ng-container>
  </section>
  <div *ngIf="stage === 'upload'" class="link-input">
    <div class="input-link-label-container">
      <span class="input-link-label">Destination URL</span>
      <span class="input-link-sublabel">(Option to add an external link)</span>
    </div>
    <u-input
      #inputLink
      [model]="link"
      [class.invalid-url]="!isValidUrl"
      (onBlur)="onLinkInputBlur($event.value)"
      (onChange)="onLinkInputChange($event)"
      placeholder="Type or paste link"
      [inputSize]="'medium'"
      [removeText]="true"
      [class.filled-input]="!!link"
    ></u-input>
    <div *ngIf="!isValidUrl" class="invalid-url-error">Invalid URL</div>
  </div>

  <div class="image-cropper-buttons">
    <u-button label="Cancel" type="secondary" (onClick)="onCancel.emit()"></u-button>
    <ng-container [ngSwitch]="stage">
      <ng-container *ngSwitchCase="'crop'">
        <u-button label="Next" type="primary" (onClick)="onNext()"></u-button>
      </ng-container>
      <ng-container *ngSwitchCase="'upload'">
        <u-button [disabled]="isLoading || !isValidUrl" label="Save" type="primary" (onClick)="onSave()"></u-button>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #loader>
  <loader-overlay
    [darkOnly]="previewMode === 'popup'"
    [styles]="{ backgroundColor: 'transparent', backdropFilter: 'none' }"
  >
  </loader-overlay>
</ng-template>

<ng-template #fileUploadError>
  <div class="file-upload-error-container">
    <div class="file-upload-error-title">Image upload failed</div>
    <div class="file-upload-error-subtitle">Please try again or upload a different image.</div>
    <div class="file-upload-error-buttons-container">
      <u-button label="Cancel" type="secondary" (onClick)="onCancel.emit()"></u-button>
      <u-button label="Try again" type="primary" (onClick)="tryAgainFileUpload()"></u-button>
    </div>
  </div>
</ng-template>
