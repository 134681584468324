import { Config } from '@environments/config';
import { AssistantsGuideModel } from '../../models/assistants-guide.model';

export const assistantsGuidesContent = {
  assistantsGuides: {
    slack: {
      icon: { lightUrl: '/assets/bar/results/share/slack_icon.svg' },
      integrationImgModel: {
        type: 'img',
        value: { lightUrl: './assets/assistants/integration-slack-light.svg', darkUrl: './assets/assistants/integration-slack-dark.svg' },
      },
      header: 'How to invite the Unleash bot<br />to your Slack channel?',
      installStep: 'Unleash to Slack.',
      installStepLabel: 'Install',
      stepsArray: [
        {
          stepText: 'From any Slack channel, use the command <b>/invite @Unleash</b> to invite the bot to that channel.',
          stepIcon: 'icon-slack1',
        },
        {
          stepText: 'Visit the Assistants page in Unleash to configure the bot’s data sources and preferences.',
          stepIcon: 'icon-unleash-logo',
        },
      ],
      video: { name: 'slack-video.mp4', icon: '/assets/file-icons/video.svg', srcUrl: Config.slackUrl, mimeType: 'video/mp4' },
    } as AssistantsGuideModel,
    zendesk: {
      icon: { lightUrl: '/assets/assistants/zendesk-icon-light.svg', darkUrl: '/assets/assistants/zendesk-icon-dark.svg' },
      integrationImgModel: {
        type: 'img',
        value: { lightUrl: './assets/assistants/integration-zendesk-light.svg' },
      },
      header: 'How to connect the Unleash app to your<br />Zendesk Workspace?',
      installStep: 'the Unleash App.',
      installStepLabel: 'Install',
      stepsArray: [
        { stepText: 'Login to your Zendesk support account.' },
        { stepText: 'From your Zendesk support account locate and click on the Unleash icon at the top of the page or in the side bar.' },
      ],
      image: {
        name: 'zendesk-steps.png',
        icon: '/assets/file-icons/png.svg',
        srcUrl: './assets/assistants/zendesk-steps.png',
        mimeType: 'image/png',
      },
    } as AssistantsGuideModel,
    teams: {
      icon: { lightUrl: './assets/assistants/microsoft-teams-icon.svg' },
      integrationImgModel: {
        type: 'img',
        value: { lightUrl: './assets/assistants/integration-teams-light.svg' },
      },
      header: 'How to connect the Unleash app<br />to your Teams?',
      installStep: 'Unleash to a team.',
      installStepLabel: 'Add',
      installStepIcon: 'icon-teams',
      stepsArray: [
        {
          stepText: 'From the team’s channels, use the command <b>invite @Unleash</b> to invite <br />the bot.',
          stepIcon: 'icon-teams',
        },
        {
          stepText: 'Visit the Assistants page in Unleash to configure the bot’s data sources and preferences.',
          stepIcon: 'icon-unleash-logo',
        },
      ],
      video: { name: 'teams-video.mp4', icon: '/assets/file-icons/video.svg', srcUrl: Config.teamsUrl, mimeType: 'video/mp4' },
    } as AssistantsGuideModel,
  },
};
