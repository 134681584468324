<div class="mail-preview-popup-wrapper" (click)="closePopUp()">
  <div
    data-cy="mail-preview-container"
    class="mail-preview-popup-container"
    [ngClass]="{ launcher: isLauncher, 'window-mode': windowMode && !embedInline, 'embed-inline-mode': embedInline }"
    (click)="$event.stopPropagation()"
  >
    <div *ngIf="!model && !(error$ | async); else content" class="mail-preview-popup-container-loading">
      <loader-overlay class="mail-preview-popup-loading" [displayText]="true"> </loader-overlay>
    </div>
    <ng-template #content>
      <div class="mail-preview-popup-close-popup">
        <u-icon
          *ngIf="!(isEmbed && embedInline)"
          data-cy="mail-preview-close-btn"
          class="mail-preview-popup-close-btn"
          (click)="closePopUp()"
          [model]="closeIcon"
          [styles]="{ fontSize: '12px' }"
        ></u-icon>
      </div>
      <mail-preview-container
        class="mail-preview-container-content"
        [model]="model.item"
        [size]="'popup'"
        (loaded)="loaded.emit()"
      ></mail-preview-container>
    </ng-template>
  </div>
</div>
