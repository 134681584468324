import { MemorySearch, People } from '@local/client-contracts';
import { LogService } from '@shared/services';
import { ApplicationsService } from '@shared/services/applications.service';
import { LinksService } from '@shared/services/links.service';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { cloneDeep, flatMap, uniq } from 'lodash';
import { Observable, ReplaySubject, Subscription, filter, firstValueFrom, from, of, startWith } from 'rxjs';
import { SearchResults } from 'src/app/bar/views';
import { PeopleService } from 'src/app/bar/views/preview/people-preview/services/people.service';
import { Action } from 'src/app/bar/views/results/models/view-filters';
import { FiltersService } from '../../../filters.service';
import { ResultsService } from '../../../results.service';
import { SourceResultItems } from '../../models/source-results-items.model';
import { ResourcesMemorySearchClient } from '../resources-memory-search';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { PeopleSourceSettings } from './people-source-settings';
import { ExperiencesService } from '../../../experiences.service';

const PEOPLE_FILTERS_LIST: string[] = ['department', 'jobTitle', 'location', 'isManager', 'managedBy'];

export class PeopleSearchClient extends ResourcesMemorySearchClient<PeopleSourceSettings> {
  private instances: { [sessionName: string]: { subscriptions?: Subscription[]; refreshRunning?: boolean } } = {};

  constructor(
    logService: LogService,
    memorySearchService: MemorySearchService,
    filtersService: FiltersService,
    appsService: ApplicationsService,
    protected linksService: LinksService,
    peopleService: PeopleService,
    private resultsService: ResultsService,
    private experiencesService: ExperiencesService
  ) {
    super(
      logService,
      memorySearchService,
      filtersService,
      appsService,
      linksService,
      peopleService,
      ['Alphabetical'],
      ['class', ...PEOPLE_FILTERS_LIST]
    );
    this.logger = logService.scope('people-search-client');
  }

  supportsMatch() {
    return false;
  }

  getInput(request: SearchRequest<PeopleSourceSettings>, response: SearchResponse): Observable<MemorySearch.Item[]> {
    request.sourceSettings.matchStrategy = request.sourceSettings.matchStrategy || 'Prefix';
    if (!request.query || request.query?.length > request.sourceSettings.minQueryLength) {
      const subject = new ReplaySubject<MemorySearch.Item[]>(1);
      this.getInputInner(request, subject, response);
      return subject;
    }
    return of([]);
  }

  private async getInputInner(
    request: SearchRequest<PeopleSourceSettings>,
    subject: ReplaySubject<MemorySearch.Item[]>,
    response: SearchResponse
  ) {
    const settings = request.sourceSettings;
    const assistantId = settings.assistantId;
    if (assistantId) {
      const enableSearch = await this.shouldEnableSearchWithAssistant(request.sourceSettings.assistantId);
      if (!enableSearch) {
        subject.next([]);
        return;
      }
    }
    const filters = settings.filters;
    const searchRequest: People.SearchRequest = {
      query: request.query,
      sorting: settings.sorting,
      maxCount: settings.maxCount,
      postFilters: filters?.postFilters || {},
      preFilters: filters?.preFilters || {},
      excludeFilters: filters?.excludeFilters || [],
    };
    const searchPromise = this.peopleService.search(searchRequest);
    const searchObserver: Observable<People.SearchResponse> = from(searchPromise).pipe(
      startWith(null),
      filter((x) => !!x)
    );
    this.destroy(request.id, request.sessionName);
    this.instances[request.sessionName] = { subscriptions: [] };
    this.instances[request.sessionName].subscriptions.push(
      searchObserver.subscribe((res) => {
        if (response.cancelled) {
          return;
        }
        const items = res.items;
        const lastPageInfo = { startIndex: 0, endIndex: items.length - 1, last: !res.pageToken };
        response.extra = { totalResults: res.totalResults, pageToken: res.pageToken, lastPageInfo, searchMode: res.searchMode };
        subject.next(items);
      })
    );
  }

  // HACK: If an assistant ID exists, search people only if peopleLink is in assistant's dataSources links
  private async shouldEnableSearchWithAssistant(assistantId: string) {
    const [assistant, peopleLinkId] = await Promise.all([
      this.experiencesService.getExperience(assistantId),
      firstValueFrom(this.linksService.peopleLinkId$),
    ]);
    if (!assistant || !peopleLinkId) {
      return false;
    }
    const linkIds = flatMap(assistant.filtersDataSources, (fd) => fd?.backendFilters?.linkId || []);
    const uniqueLinkIds = uniq(linkIds);
    return uniqueLinkIds.includes(peopleLinkId);
  }

  protected addHeaders(
    request: SearchRequest<PeopleSourceSettings>,
    items: SearchResults[],
    resultCount: number,
    totalResults: number,
    response: SearchResponse
  ): void {
    const total = this.isMemoryMode(response) ? items.length : response.extra?.totalResults;
    return super.addHeaders(request, items, resultCount, total, response);
  }

  protected addHighlights(items: SourceResultItems, queryTokens: string[], response: SearchResponse): SourceResultItems {
    if (this.isMemoryMode(response)) {
      return super.addHighlights(items, queryTokens, response);
    }
    return items;
  }

  nextPage(request: SearchRequest<PeopleSourceSettings>, response: SearchResponse): Promise<void> {
    return this.innerNextPage(request, response);
  }

  private async innerNextPage(request: SearchRequest<PeopleSourceSettings>, response: SearchResponse) {
    const prevItems = cloneDeep(response.items)?.flat();
    const extra = response.extra;
    if (!extra) {
      const sourceSettings = request.sourceSettings as PeopleSourceSettings;
      this.logger.warn('innerNextPage - no search extra', { settings: sourceSettings });
      return;
    }
    if (extra?.nextPagePromise) {
      return;
    }

    const pageToken = extra?.pageToken;

    if (!pageToken) {
      return;
    }
    const source = request.sourceSettings;
    try {
      response.notifyUpdated();
      extra.nextPagePromise = this.peopleService.nextPage(pageToken);

      const nextPageResponse: People.SearchResponse = await extra.nextPagePromise;
      if (response.cancelled) {
        return;
      }

      const prevEndIndex = extra.lastPageInfo?.endIndex || 0;
      if (extra.lastPageInfo?.startIndex && !extra.fetchedPages) {
        extra.fetchedPages = 0;
      }

      extra.lastPageInfo = {
        startIndex: prevEndIndex,
        endIndex: prevEndIndex + nextPageResponse.items.length - 1,
        last: !nextPageResponse.pageToken,
      };
      extra.pageToken = nextPageResponse.pageToken;

      const newItems = await this.getOutput(nextPageResponse.items, source);
      const items = prevItems.concat(newItems);
      response.extra = extra;
      response.items = items;
    } catch (e) {
      this.logger.error('failed passing the next people page', { trigger: request.trigger });
    } finally {
      if (!response.cancelled) {
        extra.nextPagePromise = null;
        response.notifyUpdated();
      }
    }
  }

  async getOutput(items: MemorySearch.Item[], sourceSettings: PeopleSourceSettings): Promise<SearchResults[]> {
    let index = 0;
    const arrOutput: SearchResults[] = [];
    for (const item of items) {
      const action: Action = await this.resultsService.getResultAction(item.data);
      if (sourceSettings.viewMode === 'gallery') {
        this.convertItemBullets(item);
      }
      const view = item.data.view;
      if (view?.iconOverlay) {
        view.icon = view.iconOverlay;
        view.iconOverlay = null;
      }
      arrOutput.push({ ...item.data, type: 'person', action, settings: { viewMode: sourceSettings.viewMode, index } });
      index++;
    }
    return arrOutput;
  }

  private convertItemBullets(item: MemorySearch.Item) {
    const data = item.data.resource.traits;
    if (data.department) {
      item.data.view.bullets = [{ parts: [{ text: data.department, icon: { name: 'icon-department' }, tooltip: 'Department' }] }];
    } else {
      item.data.view.bullets = [];
    }
  }

  private isMemoryMode(response: SearchResponse) {
    const searchMode = response.extra.searchMode;
    return searchMode === 'Memory';
  }

  async filter(items: MemorySearch.Item[], settings: PeopleSourceSettings): Promise<any[]> {
    items = await super.filter(items, settings);
    const postFilters = settings.filters?.postFilters;
    const preFilters = settings.filters?.preFilters;
    const filtersValues: Record<string, string[]> = {};
    PEOPLE_FILTERS_LIST.forEach((filter) => {
      const values = [
        ...(postFilters[filter] || []).map((j) => j.toLowerCase()),
        ...(preFilters[filter] || []).map((j) => j.toLowerCase()),
      ];
      if (values.length) {
        filtersValues[filter] = values;
      }
    });
    if (!Object.values(filtersValues).some((val) => val.length)) {
      return items;
    }
    const filterItems = items.filter((item) => {
      const personData = item.data?.resource?.traits;
      return Object.keys(filtersValues).every((f) => {
        switch (f) {
          case 'isManager':
            return personData[f];
          case 'managedBy':
            return (
              filtersValues[f].includes(personData[f]?.email?.toLowerCase()) ||
              filtersValues[f].includes(personData[f]?.name?.toLowerCase())
            );
          default:
            return filtersValues[f].includes(personData[f]?.toLowerCase());
        }
      });
    });
    return filterItems;
  }

  protected async rank(queryTokens: string[], items: MemorySearch.Item[], settings: PeopleSourceSettings): Promise<MemorySearch.Item[]> {
    if (!queryTokens?.length && !settings.sorting) {
      return super.rank(queryTokens, items, settings);
    }
    return items;
  }

  destroy(id: number, sessionName: string): void {
    const instance = this.instances[sessionName];
    if (instance) {
      instance.subscriptions?.forEach((c) => c.unsubscribe());
      delete this.instances[sessionName];
    }
  }

  protected defaultSort(items: MemorySearch.Item[]): MemorySearch.Item[] {
    return items.sort((a, b) => a?.data?.view?.title?.text?.localeCompare(b?.data?.view?.title?.text));
  }
}
