import { ElementRef } from '@angular/core';
import { Commands, Results, Search } from '@local/client-contracts';
import { Constants } from '@local/common';
import { generateTitleUrl } from '@local/ts-infra';
import { PopupRef } from '@local/ui-infra';
import { ContextMenuComponent, ContextMenuData, ContextMenuItem, ContextMenuService } from '@shared/components';
import { FlagsService } from '@shared/services/flags.service';
import { ResourcesService } from '@shared/services/resources.service';
import { generateFullPrefixedURL, getShareAssetsPath, isClickInElement, isOpenUrlCommand } from '@shared/utils';
import { Logger } from '@unleash-tech/js-logger';
import { Subject, fromEvent } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { GlobalErrorHandler } from 'src/app/global-error-handler';
import { ResultCommandBuilder, isEnabledSummary, isWikiCard, isWikiCardLocal, isWikiCardRemote } from '../../views/results';
import { DisplayItemData, ResultContextMenuItem, TelemetryTrigger } from '../../views/results/models/results-types';
import { ResultCommandBuilderParams } from '../../views/results/services/result-command-builder.service';
import { HubService } from '../hub.service';

export type ContextMenuInvokeSource = 'actions' | 'title' | 'subtitle';
export type ContextMenu = { event: MouseEvent; source: ContextMenuInvokeSource; itemId: string };

export class ResultsItemContextMenuHelper {
  ref: PopupRef<ContextMenuComponent, ContextMenuData> | null;
  source: ContextMenuInvokeSource | null;
  items: Array<ResultContextMenuItem> = [];
  invoke$ = new Subject<{ item: ResultContextMenuItem; trigger: TelemetryTrigger }>();
  invokeShareMenu$ = new Subject<{ item: ResultContextMenuItem; trigger: TelemetryTrigger }>();
  move$ = new Subject<{ event: MouseEvent; source: ContextMenuInvokeSource }>();
  private model: Search.ResultItem;

  constructor(
    private builder: ResultCommandBuilder,
    private logger: Logger,
    private contextMenuService: ContextMenuService,
    private hostRef: ElementRef,
    private titleRef: ElementRef,
    private subtitleRef: ElementRef,
    private actionsRef: ElementRef,
    private errorHandler: GlobalErrorHandler,
    private resourcesService: ResourcesService,
    private hubService: HubService,
    protected flagsService: FlagsService
  ) {}

  async open(
    event: MouseEvent,
    model?: Search.ResultItem,
    source?: ContextMenuInvokeSource,
    openState?: Commands.PreviewState,
    isPopUp?: boolean,
    allowShareMenu = false,
    allowOpenSource = true,
    allowDownload = true,
    sharedOrder: number = undefined,
    previewable = false,
    itemsToRemove: string[] = [],
    actionsReadOnly = false
  ): Promise<PopupRef<ContextMenuComponent, ContextMenuData>> {
    if (source === 'actions') event.stopPropagation();
    this.model = model;
    this.source = source;

    if (!allowOpenSource) itemsToRemove.push('open');
    if (!allowDownload) itemsToRemove.push('download');

    let items: ResultContextMenuItem[] = this.items;
    try {
      const itemsRequest = await this.buildItemsRequest(openState, model, source, isPopUp, previewable, actionsReadOnly);
      items = await this.builder.build(itemsRequest);
      items = items.filter((item) => !itemsToRemove.includes(item.id));
    } catch (error) {
      this.logger?.warn('Failed to dynamically create context menu', error);
      this.errorHandler.error = error;
    }
    let openAt: { x: number; y: number } = event;
    if (source === 'actions' && this.actionsRef) {
      const { width, left, top, height } = this.actionsRef.nativeElement.getBoundingClientRect();
      if (width && height) {
        openAt = { x: left + width / 2, y: top + height / 2 };
      }
    }
    if (!isWikiCard(model) && allowShareMenu) {
      const shareMenu = await this.createShareMenu();
      if (!sharedOrder) {
        sharedOrder = items.length;
      }
      if (shareMenu) {
        items.splice(sharedOrder, 0, {
          icon: { type: 'font-icon', value: 'icon-share1' },
          text: 'Share',
          id: 'share',
          items: shareMenu,
        });
      }
    }

    this.ref = this.contextMenuService.open(
      openAt,
      {
        items,
        onInvoke: (item: ResultContextMenuItem, trigger) => {
          this.invoke$.next({ item, trigger });
        },
      },
      { position: source === 'actions' ? 'below' : 'right' }
    );

    this.ref.destroy$.subscribe(() => {
      this.source = null;
    });
    // this.moveOnRightClick(allowShareMenu, openState, isPopUp, allowOpenSource, allowDownload, sharedOrder, previewable);
    return this.ref;
  }

  async buildItemsRequest(
    state?: Commands.PreviewState,
    model?: Search.ResultItem,
    source?: ContextMenuInvokeSource,
    isPopUp?: boolean,
    previewable = false,
    actionsReadOnly?: boolean,
    removeCommands = {}
  ): Promise<ResultCommandBuilderParams> {
    const copyText: string = source === 'subtitle' ? model.view.subtitle?.text : source === 'title' ? model?.view.title?.text : null;
    const title = model?.view?.title as Results.Title;
    const titleClick: Commands.OpenUrl | null = isOpenUrlCommand(title?.onClick) ? title?.onClick : null;
    let { url, appUri } = titleClick || { url: undefined, appUri: undefined };
    const dynamicCommands = this.model?.dynamicCommands ?? [];
    if (
      !isWikiCard(model) &&
      (model as Search.ResultItem)?.type !== 'collection-header' &&
      !dynamicCommands.find((d) => d === 'add-to-collection')
    ) {
      dynamicCommands.unshift('add-to-collection');
    }

    const [disableCollections, disableWikis, disablePeopleActions] = await Promise.all([
      this.flagsService.isEnabled(Constants.DISABLED_GO_LINKS_FLAG),
      this.flagsService.isEnabled(Constants.DISABLED_COLLECTIONS_FLAG),
      this.flagsService.isEnabled(Constants.DISABLED_PEOPLE_ACTION_FLAG),
    ]);

    let id = model.id;
    if (isWikiCardLocal(model)) {
      removeCommands['favorite'] = true;
      id = model.resourceId;
    }

    if (disablePeopleActions) {
      removeCommands['open'] = true;
      removeCommands['copyUrl'] = true;
    }

    if (disableCollections || disableWikis) {
      removeCommands['dynamicCommands'] = true;
    }

    if (isWikiCardRemote(model)) {
      url = generateFullPrefixedURL('/' + generateTitleUrl('a', model.view.title.text, model.resource.externalId), 'path');
    }
    return {
      resourceId: id,
      open: { appUri, url },
      isFavorite: { isFavorite: model.isFavorite },
      copy: copyText ? { text: copyText } : null,
      preview: { type: 'preview', previewable, state, model: model as Search.ResultResourceItem },
      dynamicCommands,
      removeCommands,
      summary: isEnabledSummary((model as DisplayItemData)?.action) ? { command: model.view.title.onClick, resourceId: id } : null,
    };
  }

  private moveOnRightClick(
    allowShareMenu: boolean,
    state?: Commands.PreviewState,
    isPopup?: boolean,
    allowOpenSource?: boolean,
    allowDownload?: boolean,
    sharedOrder?: number,
    previewable?: boolean
  ) {
    const ref = this.ref;
    if (!ref) return;

    const rightClick$ = fromEvent<MouseEvent>(ref.overlayRef.backdropElement, 'contextmenu').pipe(takeUntil(ref.destroy$));

    const auxClick$ = fromEvent<MouseEvent>(ref.overlayRef.backdropElement, 'auxclick').pipe(
      filter((e) => e.button === 2),
      takeUntil(ref.destroy$)
    );

    const onMouseEvent = (event: MouseEvent) => {
      this.ref.destroy();
      if (this.hostRef && !isClickInElement(this.hostRef.nativeElement, event)) return;

      if (this.titleRef && isClickInElement(this.titleRef.nativeElement, event)) {
        return this.move$.next({ event, source: 'title' });
      }

      if (this.subtitleRef && isClickInElement(this.subtitleRef.nativeElement, event)) {
        return this.move$.next({ event, source: 'subtitle' });
      }

      if (this.actionsRef && isClickInElement(this.actionsRef.nativeElement, event)) {
        return this.move$.next({ event, source: 'actions' });
      }
      this.open(event, this.model, null, state, isPopup, allowShareMenu, allowOpenSource, allowDownload, sharedOrder, previewable);
    };

    rightClick$.pipe(takeUntil(this.ref.destroy$)).subscribe((e) => onMouseEvent(e));
    auxClick$.pipe(takeUntil(this.ref.destroy$)).subscribe((e) => onMouseEvent(e));
  }

  async createShareMenu() {
    const titleClick: Commands.OpenUrl | null = isOpenUrlCommand(this.model.view?.title?.onClick) ? this.model.view.title.onClick : null;
    const items = await this.resourcesService.listShareItems(titleClick?.url, this.model.id, this.model.view?.title?.text);
    if (!items?.length) {
      return;
    }
    const menuItems = items.map(
      (i) =>
        ({
          icon: { type: 'img', value: { lightUrl: getShareAssetsPath(i.icon) } },
          id: i.id,
          text: i.text,
          command: { type: 'open-url', url: i.desktopUri || i.url } as Commands.OpenUrl,
        } as ContextMenuItem)
    );
    return {
      items: menuItems,
      onInvoke: (item: ResultContextMenuItem, trigger) => this.invokeShareMenu$.next({ item, trigger }),
      menuWidth: 200,
    };
  }
}
