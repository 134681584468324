import { Injectable } from '@angular/core';
import { Search, Results } from '@local/client-contracts';
import { cloneDeep, isEqual, pick, uniq } from 'lodash';
import { BehaviorSubject, distinctUntilChanged, firstValueFrom, map } from 'rxjs';
import { HubService } from 'src/app/bar/services/hub.service';
import { ResultsService } from 'src/app/bar/services/results.service';
import { SearchResults } from '../../results';
import { Lock } from 'semaphore-async-await';

export type ChatResourcesCache = { [externalId: string]: Search.ResultResourceItem };
@Injectable()
export class ChatResourcesService {
  private _resourcesCache$ = new BehaviorSubject<ChatResourcesCache>({});
  private updateCacheLock = new Lock();

  get resourcesCache$() {
    return this._resourcesCache$;
  }

  constructor(private resultsService: ResultsService, private hubService: HubService) {}

  async updateRemoteResources(externalIds: string[]) {
    const assistantId = await this.hubService.globalAssistantId;
    const uniqExternalIds = uniq(externalIds);
    const resources: Results.LinkResource[] = (uniqExternalIds || []).map((id) => ({ linkId: null, resourceId: null, externalId: id }));
    const getItemsRequest: Results.GetItemsRequest = {
      cache: 'first',
      resources,
      includeHiddenLinks: !!assistantId,
      idType: 'external',
    };
    this.resultsService.getItems$(getItemsRequest).subscribe(async (res) => {
      this.updateResourceInCache(res, true);
    });
  }

  async updateResourceInCache(resources: Search.ResultResourceItem[], fromRemote?: boolean) {
    if (!resources?.length) {
      return;
    }
    try {
      await this.updateCacheLock.acquire();
      const updatedCache = cloneDeep((await firstValueFrom(this.resourcesCache$)) || {});
      for (const r of resources || []) {
        if (!r) {
          continue;
        }
        if (fromRemote) {
          (r as SearchResults).action = await this.resultsService.getResultAction(r);
        }
        const externalId = r?.resource?.externalId;
        if (externalId) {
          updatedCache[externalId] = r;
        }
      }
      this._resourcesCache$.next(updatedCache);
    } finally {
      this.updateCacheLock.release();
    }
  }

  getResourcesByIds$(ids: string[]) {
    return this.resourcesCache$.pipe(
      map((all) => {
        const relevantResources = pick(all, ids);
        return Object.values(relevantResources);
      }),
      distinctUntilChanged((prev, current) => isEqual(prev, current))
    );
  }

  async resetCache() {
    try {
      await this.updateCacheLock.acquire();
      this._resourcesCache$.next({});
    } finally {
      this.updateCacheLock.release();
    }
  }
}
