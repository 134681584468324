import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IStyleService } from '../injectionToken/style.service.interface';
import { Scheme } from '../types/scheme';

@Injectable()
export class OptionalStyleService implements IStyleService {
  readonly bodyAttributeTheme: string = 'data-scheme';
  theme$ = new BehaviorSubject<Scheme>('light');

  get theme() {
    return this.theme$.value;
  }

  constructor() {
    this.theme$.next(this.theme as Scheme);
    const observer = new MutationObserver(() => {
      const dataScheme = document.body.getAttribute(this.bodyAttributeTheme);
      if (this.theme !== dataScheme) {
        this.theme$.next(dataScheme as Scheme);
      }
    });
    observer.observe(document.body, { attributes: true });
  }

  changeTheme() {
    document.body.setAttribute(this.bodyAttributeTheme, this.theme === 'light' ? 'dark' : 'light');
  }
}
