<div class="header">
  <section class="search-container">
    <input class="search-input" #searchInput type="text" (keydown)="onKeyDown($event)" [(ngModel)]="query" />
    <div
      class="search-placeholder"
      #searchInputAutoComplete
      [innerHTML]="
        hasUserInteracted
          ? (query | inputAutocomplete: (selectedItem?.type === 'search-line' ? null : selectedItem?.title):null:searchInputAutoComplete) ??
            (placeholder$ | async)
          : (placeholder$ | async)
      "
    ></div>
  </section>
</div>
<smooth-height [trigger]="query">
  <section #resultsContainer class="results-container">
    <ng-scrollbar
      [autoHeightDisabled]="false"
      class="virtual-scroll"
      visibility="hover"
      [ngClass]="{ 'no-scroll-bar': noScrollbar }"
      [style.maxHeight.px]="MAX_SCROLL_HEIGHT"
    >
      <cdk-virtual-scroll-viewport appendOnly [itemSize]="33" ngScrollbarView smoothScroll scrollViewport [class.virtual-scroll]="true">
        <ng-container *cdkVirtualFor="let item of items$ | async; index as i; trackBy: trackItem" [ngSwitch]="item.type">
          <ng-container *ngSwitchCase="'search-line'">
            <div class="search-line" (click)="onSearch()" #scrollItem [class.selected]="selectedIndex === i">
              <entity-icon [model]="item.icon" [size]="'14px'" class="search-line-icon"></entity-icon>
              <div class="search-line-text">
                {{ item.title }} “<strong>{{ query }}</strong
                >”
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'parent'">
            <p class="results-item-title" #scrollItem>{{ item.title }}</p>
          </ng-container>
          <ng-container *ngSwitchCase="'child'">
            <search-popup-item
              [model]="item"
              [class.selected]="selectedIndex === i"
              (click)="onItemClick($event, item)"
              #scrollItem
            ></search-popup-item>
          </ng-container>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </ng-scrollbar>
    <div class="no-results-container" *ngIf="noResults$ | async">
      <h3 class="no-results-static">There are no results for</h3>
      <h3>
        <q class="no-results-dynamic">{{ query }}</q>
      </h3>
    </div>
  </section>
</smooth-height>
