import { FlexibleConnectedPositionStrategyOrigin } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { LogService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ContextMenuComponent } from './context-menu.component';
import { ContextMenuData } from './models';
import { PopupRef, PopUpOptions, GlobalPosition, PopupService } from '@local/ui-infra';
@Injectable({
  providedIn: 'root',
})
export class ContextMenuService {
  private sub: Subscription;
  private refs: PopupRef<ContextMenuComponent, ContextMenuData>[] = [];
  private logger: Logger;

  private _closed = new Subject<boolean>();
  get closed$() {
    return this._closed;
  }

  constructor(private popupService: PopupService, private logService: LogService) {
    this.logger = this.logService.scope('ContextMenuService');
  }

  /** Automatically closes on click out, sometimes the backdrop fails so this is a good backup with zero cost.  */
  open(
    positionOrigin: FlexibleConnectedPositionStrategyOrigin | GlobalPosition | 'center',
    data: ContextMenuData,
    options?: PopUpOptions,
    layer: number = 1
  ): PopupRef<ContextMenuComponent, ContextMenuData> {
    if (layer === 1 && this.refs.length === 2) this.refs.forEach((r) => r.destroy());

    this.refs.push(
      this.popupService.open(positionOrigin, ContextMenuComponent, data, {
        position: 'right',
        ...(options ?? {}),
      })
    );
    this.refs[layer - 1].destroy$.pipe(take(1)).subscribe(() => {
      this.refs.splice(layer - 1, 1);
      if (layer === 1 && this.refs.length > 0) {
        // someone close layer 1 but there is still layer 2
        this.destroyRef(1);
      }
    });
    return this.refs[layer - 1];
  }

  destroy() {
    if (!this.sub) return;
    this.sub.unsubscribe();
    this.sub = null;
    this.refs = [];
  }

  destroyRef(position: number) {
    if (position - 1 < this.refs.length) {
      this.refs[position - 1].destroy();
      this.refs.splice(position - 1, 1);
    }
    this._closed.next(true);
  }

  destroyAllRefs() {
    this.refs.forEach((ref) => {
      ref.destroy();
    });
    this.refs = [];
    this._closed.next(null);
  }

  currentOpenRefs() {
    return this.refs.length;
  }
}
