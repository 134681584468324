import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { InstallZendeskComponent } from './install-zendesk/install-zendesk.component';

const components = [InstallZendeskComponent];

const providers = [];

const imports = [SharedModule];

@NgModule({
  imports: [...imports],
  declarations: [...components],
  exports: [...components],
  providers: [...providers],
})
export class ZendeskInstallModule {}
