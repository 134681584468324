import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UTableComponent } from './u-table.component';
import { TableModule } from 'primeng/table';
import { UiInfraModule } from '../../ui-infra.module';
@NgModule({
  declarations: [UTableComponent ],
  imports: [CommonModule, FormsModule, TableModule ,UiInfraModule],
  exports: [UTableComponent],
})
export class UTableModule {}
