import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UAutoCompleteComponent } from './u-auto-complete.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { UIconModule } from '../u-icon/u-icon.module';
@NgModule({
  declarations: [UAutoCompleteComponent],
  imports: [CommonModule, AutoCompleteModule, FormsModule, UIconModule],
  exports: [UAutoCompleteComponent],
})
export class UAutoCompleteModule {}
