import { Component, ChangeDetectionStrategy, Input, HostListener, HostBinding } from '@angular/core';
import { ContextMenuItem } from '@shared/components';
import { isRightClick } from '@shared/utils';

@Component({
  selector: 'square-button',
  templateUrl: './square-button.component.html',
  styleUrls: ['./square-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // encapsulation: ViewEncapsulation.Emulated,
})
export class SquareButtonComponent {
  @Input() items: ContextMenuItem[] = [];

  @Input()
  @HostBinding('class.active')
  active;

  /** Since it's a 'dumb' UI component  and the usage of native events will look like `(click)="doSomething()"`
   *  we are stopping the propagation of the events so it will be handled only by the native dom and not angular */

  @HostListener('contextmenu', ['$event'])
  onRightClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  /** On Some OS / Mouse, the event for right click is 'auxclick' */
  @HostListener('auxclick', ['$event']) onAuxClick(event: MouseEvent) {
    if (isRightClick(event)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /** On Some OS / Mouse, the event for right click is 'auxclick' */
  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}
