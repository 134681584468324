import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Collections } from '@local/client-contracts';
import { RouterService } from '@shared/services/router.service';
import { CollectionsService } from 'src/app/bar/services/collections.service';

@Injectable({ providedIn: 'root' })
export class CollectionResolver {
  constructor(private collectionsService: CollectionsService, private routerService: RouterService) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<Collections.Collection> {
    const fullId = route.params.id;
    const id = fullId.substring(fullId.lastIndexOf('-') + 1);
    const collection = await this.collectionsService.getById(id);
    if (collection) {
      return collection;
    }
    this.routerService.navigateByUrl('/not-found');
    return null;
  }
}
