import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { Chips } from 'primeng/chips';
import { ChipsItem } from '../../types/chips';

@Component({
  selector: 'u-chips',
  templateUrl: './u-chips.component.html',
  styleUrls: ['./u-chips.component.scss'],
})
export class UChipsComponent implements AfterViewInit {
  private regex: RegExp;
  private timeoutRef;
  private inputValue: string;
  chipsInput: HTMLInputElement;
  showClearBtn: boolean;
  maxTagsError: boolean;
  invalidTagError: boolean;

  @HostListener('document:keydown.backspace', ['$event'])
  onKeydown(event: KeyboardEvent) {
    this.itemsChanged.emit(this.items);
    event.stopPropagation();
  }

  @ViewChild(Chips) chips: Chips;

  @Input() separator = ',';
  @Input() styleClass: string;
  @Input() style = { width: '500px', maxHeight: '200px' };
  @Input() maxTags: number = null;
  @Input() placeholder: string;
  @Input() items: ChipsItem[] = [];
  @Input() errorMessage: string;
  @Input() disabled: boolean = false;

  @Output() onMaxTagsError: EventEmitter<void> = new EventEmitter();
  @Output() inputValueChanged: EventEmitter<string> = new EventEmitter();
  @Output() itemsChanged: EventEmitter<ChipsItem[]> = new EventEmitter<ChipsItem[]>();

  private _showClear;
  get showClear() {
    return this._showClear;
  }
  @Input() set showClear(value) {
    this.showClearBtn = this._showClear = value;
  }

  private _validationRegex: RegExp;
  get validationRegex(): RegExp {
    return this._validationRegex;
  }
  @Input() set validationRegex(value: RegExp) {
    this._validationRegex = value;
    if (value) {
      this.regex = new RegExp(this.validationRegex);
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.chipsInput = this.chips.inputViewChild.nativeElement;
    this.chipsInput.focus();
    this.chipsInput.addEventListener('input', (event: InputEvent) => {
      if (this.timeoutRef) {
        clearTimeout(this.timeoutRef);
      }
      this.timeoutRef = setTimeout(() => {
        const value = event.target?.['value'] as string;
        if (value === '') {
          this.inputValueChanged.emit(value);
          return;
        }
        if (this.regex) {
          this.inputValue = this.regex.test(value) ? value : null;
        } else {
          this.inputValue = value;
        }
        this.inputValueChanged.emit(this.inputValue);
        this.cdr.markForCheck();
      }, 100);
    });

    this.chips.onPaste = (event) => {
      if (!this.chips.disabled) {
        if (this.separator) {
          const pastedData = (event.clipboardData || window['clipboardData'])?.getData('Text');
          if (!pastedData) {
            return;
          }
          let tags = pastedData.split(this.separator);
          if (this.maxTags) {
            tags = tags.slice(0, this.maxTags - this.items?.length);
          }
          tags.forEach((val) => {
            this.chips.addItem(event, val, true);
          });
          this.chips.inputViewChild.nativeElement.value = '';
        }

        this.chips.updateFilledState();
      }
    };
    // with markForCheck angular will throw ExpressionChangedAfterItHasBeenCheckedError
    this.cdr.detectChanges();
  }

  onClear() {
    this.chips.inputViewChild.nativeElement.value = '';
    this.chips.disabled = false;
    this.inputValue = null;
    this.items = [];

    this.inputValueChanged.emit(this.inputValue);
    this.itemsChanged.emit(this.items);
    this.chips.inputViewChild.nativeElement.value = '';
    this.cdr.markForCheck();
  }

  onRemove(item: ChipsItem) {
    this.items = this.items.filter((i) => i.value !== item.value);
    if (!this.items?.length) {
      this.showClearBtn = false;
    } else {
      this.showClearBtn = this.showClear;
    }
    this.itemsChanged.emit(this.items);
    this.cdr.markForCheck();
  }

  modelChanged(values: string[] | ChipsItem[]) {
    if (!values?.length) return;
    values.forEach((v) => {
      if (typeof v === 'string') {
        const item: ChipsItem = { value: v, isValid: true };
        this.items[this.items.indexOf(v as any)] = item;
      }
    });
  }

  onAdd() {
    if (this.validationRegex) {
      this.items = this.items.map((i) => {
        return {
          value: i.value.trim(),
          isValid: this.regex.test(i.value.trim()),
        };
      });
      this.styleInvalidTags();
    }
    this.inputValue = null;
    this.inputValueChanged.emit(this.inputValue);
    this.itemsChanged.emit(this.items);
    this.cdr.markForCheck();
  }

  // using this way instead of :has selector, due to electron old chromium version (version v102)
  private styleInvalidTags() {
    setTimeout(() => {
      const tags: HTMLElement[] = this.chips.el.nativeElement.querySelectorAll('.p-chips-multiple-container .p-chips-token');
      tags.forEach((tag) => {
        if (tag.children[0].classList.contains('invalid-mail')) {
          tag.classList.add('invalid-tag');
        }
      });
    }, 0);
  }
}
