<div class="container">
  <div *ngIf="dragAndDropFile" class="file-upload-advanced-header">
    <div *ngIf="showThemeName" class="theme-info">
      <u-icon class="theme-icon" [model]="icon" [styles]="{ fontSize: '14px' }"></u-icon>
      {{ theme }}
    </div>
    <div *ngIf="selectedFile && dragAndDropFile && !hideRemoveIcon">
      <a class="link-button" target="_blank" [style]="{ color: 'var(--red-500)' }" (click)="onRemove()">Remove</a>
    </div>
  </div>
  <p-fileUpload
    #fileUpload
    [mode]="mode"
    [showUploadButton]="false"
    [showCancelButton]="false"
    [chooseLabel]="label"
    class="u-file-upload"
    [class.text-only]="textOnly"
    name="myfile[]"
    [accept]="accept"
    [maxFileSize]="maxFileSize"
    [disabled]="disabled"
    chooseIcon=""
    (click)="onFileUploadClick($event)"
    (onSelect)="onSelectEvent($event)"
    (onError)="onErrorEvent($event)"
    (onRemove)="onRemove()"
    [styleClass]="styleClass"
    [attr.file-selected]="!!selectedFile"
    [attr.theme]="theme"
    [attr.disabled]="disabled"
  >
    <ng-template pTemplate="content">
      <div *ngIf="selectedFile">
        <div class="file-upload-preview" (mouseover)="toggleReplaceButton(true)" (mouseleave)="toggleReplaceButton(false)">
          <img [src]="selectedFile.url | safe : 'resource'" [style]="{ width: '100%' }" />
          <u-button
            *ngIf="showReplaceButton"
            (click)="choose()"
            class="file-upload-replace"
            type="secondary"
            fontIcon="edit"
            label="Replace"
          ></u-button>
        </div>
      </div>
    </ng-template>
  </p-fileUpload>
  <div class="error-message" *ngIf="errorMessage && showErrorMessage">
    {{ errorMessage }}
  </div>
</div>

<!-- Hack to display placeholder -->
<div [hidden]="true">
  <div [hidden]="selectedFile" class="upload-placeholder" #contentPlaceHolder>
    <ng-container *ngTemplateOutlet="!isDragging ? uploadPlaceholder : uploadOnHover"></ng-container>
  </div>
</div>

<ng-template #uploadPlaceholder>
  <div class="upload-icon"><u-icon *ngIf="displayUploadIcon" [model]="{ type: 'font', value: 'icon-upload-cloud' }"></u-icon></div>
  <span class="drag-text">{{ dragAndDropText }}</span
  ><br />
  <a class="link-button" target="_blank" (click)="choose()">{{ uploadText }}</a>
  <div class="accept-types" *ngIf="definitionOfAcceptType">{{ definitionOfAcceptType }}</div>
</ng-template>

<ng-template #uploadOnHover>
  <span class="upload-on-hover">{{ uploadOnHoverText }}</span>
</ng-template>
