import { Component, OnInit } from '@angular/core';
import { LoaderService } from '@shared/loader.service';
import { LogService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';

@Component({
  templateUrl: `./install-zendesk.component.html`,
  styleUrls: [`./install-zendesk.component.scss`],
})
export class InstallZendeskComponent implements OnInit {
  private readonly logger: Logger;

  constructor(loaderService: LoaderService, logService: LogService) {
    this.logger = logService.scope('InstallZendeskComponent');
    loaderService.ready$.next(false);
  }
  ngOnInit() {
    window.close();
  }
}
