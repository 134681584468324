import { ElementRef, Injectable } from '@angular/core';
import {
  Collections,
  Experiences,
  Fyis as Fyi,
  HomeTabs,
  Links as LinksContracts,
  NotificationPayload,
  Wiki,
} from '@local/client-contracts';
import { DEFAULT_KEYBOARD_SHORTCUTS, Fyis, FyisRpcInvoker, observable } from '@local/common';
import { getAssistantTitle, isEmbed } from '@local/common-web';
import { PopUpOptions, PopupRef, PopupService } from '@local/ui-infra';
import { EventsService, LogService } from '@shared/services';
import { Shortcuts } from '@shared/services/keyboard.service';
import { LinksService } from '@shared/services/links.service';
import { RouterService } from '@shared/services/router.service';
import { NativeServicesRpcService, ServicesRpcService } from '@shared/services/rpc.service';
import { SessionService } from '@shared/services/session.service';
import { TimerService } from '@shared/services/timer.service';
import { TitleBarService } from '@shared/services/title-bar.service';
import { normalizeKeys } from '@local/ts-infra';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import * as moment from 'moment/moment';
import { combineLatest, filter, firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { TelemetryTrigger } from '../views';
import { FyiPopupComponent } from '../views/hub/fyi-dropdown/fyi-popup/fyi-popup.component';
import { FyisReadRpcInvoker } from './invokers/fyis-read-rpc-invoker';
import { EmbedService } from '@shared/embed.service';
import { isVerificationFyi } from '../utils/fyi-utils';
import { VerificationFyiData, VerificationsFyiHelperService } from './verifications-fyi-helper-service';
import { CollectionsService } from './collections.service';
import { HomeTabsService } from './home-tabs.service';
import { WikiCardsService } from './wikis/wiki-cards.service';
import { SyncsService } from '@shared/services/syncs.service';
import { ExperiencesService } from './experiences.service';

type anyFyi =
  | Fyi.LinkStaledFyi
  | Fyi.VersionUpdateFyi
  | Fyi.InitialSyncFyi
  | Fyi.AdminAnnouncementFyi
  | Fyi.CollectionSharedFyi
  | Fyi.CollectionPinnedFyi
  | Fyi.VerificationFyi
  | Fyi.ExperienceFyi;
type ActionSourceType = 'badges';

const SYNC_REFRESH_RATE = 5000;
@Injectable()
export class FyiService {
  fyisPerDay: Record<number, Fyi.Fyi[]> = {};

  private _count$: ReplaySubject<number> = new ReplaySubject(1);
  private _dismissibleCount$: ReplaySubject<number> = new ReplaySubject(1);
  private _active$: ReplaySubject<boolean> = new ReplaySubject(1);
  private _shortcutsLoaded: Promise<FyiShortcuts>;
  private service: Fyis;
  private readService: Fyi.ReadService;
  private refreshInterval: number;

  private readIdFyis: string[] = [];
  private _unread$: ReplaySubject<number> = new ReplaySubject<number>(1);
  private ref: PopupRef<FyiPopupComponent, any>;
  private initCountUnread: boolean;
  private actionSource: ActionSourceType;
  private position: {
    top?: number;
    right?: number;
    left?: number;
    bottom?: number;
  };
  private options: PopUpOptions;
  private readonly isEmbed = isEmbed();
  private disabled: boolean;
  private verificationFyiTemp: Record<string, Fyi.VerificationFyi> = {};
  private collections: Collections.Collection[] = [];
  private experiences: Experiences.ExperienceItem[] = [];
  private runningSyncs: string[] = [];

  @observable
  get unread$(): Observable<number> {
    return this._unread$.asObservable();
  }

  private set unread(value: number) {
    this._unread$.next(value);
  }

  @observable
  get active$(): Observable<boolean> {
    return this._active$.asObservable();
  }

  private set active(value: boolean) {
    this._active$.next(value);
  }

  @observable
  get count$(): Observable<number> {
    return this._count$.asObservable();
  }

  private set count(value: number) {
    this._count$.next(value);
  }

  getShortcuts(): Promise<FyiShortcuts> {
    return this._shortcutsLoaded;
  }

  get notifications$(): Observable<NotificationPayload> {
    return this.service.notification$;
  }

  @observable
  get dismissibleCount$(): Observable<number> {
    return this._dismissibleCount$;
  }

  private set dismissibleCount(value: number) {
    this._dismissibleCount$.next(value);
  }

  private get fyis$(): Observable<Fyi.Fyi[]> {
    return this.service.all$;
  }

  get links$(): Observable<LinksContracts.DisplayItem[]> {
    return this.links.all$;
  }

  private readonly settingHeightPopup = {
    heightDay: 36,
    heightFyi: 87,
    heightActiveFyi: 48,
    widthSmallBreakpoints: 910,
    heightSmallBreakpoints: 590,
    heightSmallPopup: 350,
    heightMediumPopup: 560,
    heightSpaceScroll: 20,
    minFyis: 8,
    spaceBottom: 155,
  };

  constructor(
    private webServices: ServicesRpcService,
    private nativeServices: NativeServicesRpcService,
    private logService: LogService,
    private timerService: TimerService,
    private eventsService: EventsService,
    private links: LinksService,
    private routerService: RouterService,
    private sessionService: SessionService,
    private popupService: PopupService,
    public titleBarService: TitleBarService,
    private embedService: EmbedService,
    private verificationsFyiHelperService: VerificationsFyiHelperService,
    private homeTabService: HomeTabsService,
    private collectionsService: CollectionsService,
    private wikiCardsService: WikiCardsService,
    private syncsService: SyncsService,
    private experiencesService: ExperiencesService
  ) {
    this.setup();
    this.initVerificationsFyis();
  }

  async setup() {
    this.logService.scope('FyiService');
    await this.checkDisabledFyi();
    if (this.disabled) {
      this.readIdFyis = [];
      return;
    }
    const services = this.nativeServices || this.webServices;
    this.service = services.invokeWith(FyisRpcInvoker, 'fyis');
    this.readService = this.webServices.invokeWith(FyisReadRpcInvoker, 'fyisread');
    this._shortcutsLoaded = this.loadShortcuts();
    this.readService.readIds$.subscribe((r) => {
      if (!r) {
        this.clear();
      }
      this.readIdFyis = r;
    });
    this.initStorage();
    this.initRoute();
  }

  async checkDisabledFyi() {
    if (this.isEmbed) {
      const embedInline = await this.embedService?.isInline();
      this.disabled = this.isEmbed && !embedInline;
    }
    this.disabled = false;
  }

  private initVerificationsFyis() {
    this.verificationsFyiHelperService.objectsMap$.subscribe((map) => {
      Object.values(this.verificationFyiTemp || {}).forEach((fyi) => {
        const object = map[fyi.objectId];
        if (object) {
          this.updateVerificationFyi(fyi, object);
          delete this.verificationFyiTemp[fyi.id];
        }
        this.updateCount();
      });
    });
  }

  private initStorage() {
    this.sessionService.current$.pipe(distinctUntilChanged((a, b) => a?.id === b?.id)).subscribe(async (value) => {
      if (value) {
        this.initData();
      }
    });
  }

  private initData() {
    combineLatest([
      this.sessionService.current$,
      this.fyis$,
      this.syncsService.all$,
      this.links$,
      this.collectionsService.all$,
      this.homeTabService.all$,
      this.experiencesService.visible$,
    ]).subscribe(async ([session, fyis, syncs, links, collections, tabs, experiences]) => {
      this.collections = collections;
      this.experiences = experiences;
      this.updateRunningSyncs(syncs, links);
      if (this.runningSyncs?.length) {
        this.startSync();
      } else {
        this.stopSync();
      }
      if (session) {
        await this.initReadFyi();
        await this.updateData(fyis, syncs, links, tabs);
      }
    });
  }

  private async updateData(fyis: Fyi.Fyi[], syncs: Fyi.Sync[], links: LinksContracts.DisplayItem[], tabs: HomeTabs.HomeTab[]) {
    if (!fyis) return;
    if (!syncs) return;
    if (!links) return;

    fyis.sort((fyiA, fyiB) => fyiA.lastUpdateTime - fyiB.lastUpdateTime);

    this.count = fyis.length;
    if (this.initCountUnread) {
      this.unread = fyis.length;
      this.initCountUnread = false;
    }

    this.removeDismissedFyis(fyis);

    for (const fyi of fyis) {
      if (isVerificationFyi(fyi)) {
        const object = this.verificationsFyiHelperService.getItemById(fyi.objectId);
        if (object) {
          this.updateVerificationFyi(fyi, object);
        } else {
          this.verificationFyiTemp[fyi.id] = cloneDeep(fyi);
        }
        continue;
      }
      switch (fyi?.type) {
        case 'link_staled':
          const newFyi = fyi as Fyi.LinkStaledFyi;
          await this.addOrUpdate(newFyi, newFyi.isLinkExists);
          break;
        case 'version_update':
          await this.addOrUpdate(fyi as Fyi.VersionUpdateFyi);
          break;
        case 'admin_announcements':
          await this.addOrUpdate(fyi as Fyi.AdminAnnouncementFyi);
          break;
        case 'initial_sync': {
          const newFyi = fyi as Fyi.InitialSyncFyi;
          // No sync data for fyi.
          const matchingSync = syncs.find((s) => s.id === newFyi.syncId);
          if (matchingSync) {
            newFyi.syncStatus = matchingSync.status;
            newFyi.resourcesCount = matchingSync.resourcesCount;
          }
          await this.addOrUpdate(newFyi, newFyi.isLinkExists);
          break;
        }
        case 'collection_shared': {
          const collectionFyi = fyi as Fyi.CollectionSharedFyi;
          const collectionExist = this.doesCollectionExist(collectionFyi.collectionId);
          collectionFyi.deleted = !collectionExist;
          await this.addOrUpdate(collectionFyi);
          break;
        }
        case 'collection_pinned': {
          const collectionPinned = fyi as Fyi.CollectionPinnedFyi;
          const collectionExist = this.doesCollectionExist(collectionPinned.collectionId);
          const tab = tabs.find((t) => t.id === collectionPinned.tabId);
          collectionPinned.deleted = !collectionExist || !tab;
          if (tab) {
            collectionPinned.tabName = tab.name;
          }
          await this.addOrUpdate(collectionPinned);
          break;
        }
        case 'experience_shared': {
          const experienceFyi = fyi as Fyi.ExperienceFyi;
          const experience = this.getExperience(experienceFyi.experienceId);
          experienceFyi.deleted = !experience;
          experienceFyi.experienceTitle = getAssistantTitle(experience);
          experienceFyi.experienceType = experience?.experienceType;
          await this.addOrUpdate(experienceFyi);
          break;
        }
      }
    }

    this.updateCount();
  }

  private async updateVerificationFyi(fyi: Fyi.VerificationFyi, object: VerificationFyiData) {
    const objectExist: boolean =
      object.type === 'card' ? !!(await this.doesCardExist(fyi.objectId)) : this.doesCollectionExist(fyi.objectId);
    if (!objectExist) {
      fyi.deleted = true;
    }
    fyi.objectTitle = object.title;
    fyi.objectType = object.type;
    await this.addOrUpdate(fyi);
  }

  private updateRunningSyncs(syncs: Fyi.Sync[], links: LinksContracts.DisplayItem[]) {
    if (!syncs || !links) {
      this.runningSyncs = [];
      return;
    }
    const linkIds = new Set(links.map((l) => l.id));
    const activeSync = (sync: Fyi.Sync): boolean => {
      return sync.status === 'Started' && linkIds.has(sync.linkId);
    };
    this.runningSyncs = syncs.filter((s) => activeSync(s)).map((s) => s.linkId);
  }

  private doesCollectionExist(id: string): boolean {
    return this.collections?.some((col) => col.id === id);
  }

  private async doesCardExist(id: string): Promise<Wiki.Card> {
    return this.wikiCardsService.getCard(id, true);
  }

  private getExperience(id: string): Experiences.ExperienceItem {
    return this.experiences?.find((exp) => exp.id === id);
  }

  private removeDismissedFyis(fyis: Fyi.Fyi[]) {
    this.removeFyiIf((fyi) => !fyis.find((f) => f.id === fyi.id));
  }

  private updateCount() {
    this.count = Object.values(this.fyisPerDay).reduce((total, fyis) => total + fyis.length, 0);
    this.dismissibleCount = this.listDismissible().length;
    this.updateCountUnreadFyis();
  }

  private updateCountUnreadFyis() {
    this.unread = Object.values(this.fyisPerDay).reduce((total, fyis) => total + (fyis?.filter((fyi) => !fyi.read)?.length || 0), 0);
  }

  private async addOrUpdate<T extends anyFyi>(item: T, isLinkExists = true) {
    if (!isLinkExists) this.removeOutdated<T>(item);

    item = await this.updateReadFyi(item);
    const date = this.getFyiStoreDateKey(item);

    if (this.fyisPerDay[date]) {
      const i = this.fyisPerDay[date].findIndex((f) => f.id === item.id);
      if (i < 0) {
        this.fyisPerDay[date].unshift(item);
      } else Object.assign(this.fyisPerDay[date][i], item);
    } else {
      this.fyisPerDay[date] = [item];
    }
  }

  private removeOutdated<T extends anyFyi>(item: T) {
    this.removeFyiIf((fyi) => fyi.id === item.id && fyi.lastUpdateTime < item.lastUpdateTime);
  }

  private getFyiStoreDateKey(item: anyFyi): number {
    return moment(item.lastUpdateTime).startOf('day').unix();
  }

  dismissVersionUpdate(): void {
    for (const date in this.fyisPerDay) {
      this.fyisPerDay[date].filter((f) => f.type === 'version_update').forEach((fyi) => this.dismiss(fyi.id));
    }
  }

  dismissLinkStaled(linkId: string): void {
    for (const date in this.fyisPerDay) {
      const fyiToDismiss = this.fyisPerDay[date].find((f) => f.type === 'link_staled' && (f as Fyi.LinkStaledFyi).linkId === linkId);
      if (fyiToDismiss) {
        this.dismiss(fyiToDismiss.id);
        return;
      }
    }
  }

  async dismiss(id: string) {
    try {
      this.removeFyiIf((fyi) => fyi.id === id);
      this.updateCount();
      await this.service.dismiss(id);
    } catch (error) {
      this.logService.logger.error('fyi: error dismissing fyi', { fyiId: id, error });
    }
  }

  async dismissAll(location: string, trigger: TelemetryTrigger) {
    const dismissible = this.listDismissible();
    if (!dismissible.length) return;
    this.eventsService.event('fyi_dismiss_all', {
      location: { title: location },
      target: trigger,
    });
    this.closePopup();
    this.ref.destroy();
    await this.service.dismissMany(dismissible);
    this.removeFyiIf(() => true);
    this.updateCount();
    await this.clear();
  }

  listDismissible(): string[] {
    let dismissible: string[] = [];
    const all = Object.values(this.fyisPerDay);
    for (const list of all) {
      const dismiss = list.filter((f) => this.dismissible(f)).map((f) => f.id);
      dismissible = [...dismissible, ...dismiss];
    }
    return dismissible;
  }

  dismissible(fyi: Fyi.Fyi): boolean {
    return (
      fyi.type !== 'initial_sync' ||
      (fyi.type === 'initial_sync' && (fyi as Fyi.InitialSyncFyi)?.syncStatus !== 'Started') ||
      (fyi.type === 'initial_sync' && !(fyi as Fyi.InitialSyncFyi)?.isLinkExists)
    );
  }

  private stopSync() {
    if (this.refreshInterval) {
      this.timerService.unregister(this.refreshInterval);
      this.refreshInterval = null;
    }
  }

  private startSync() {
    if (this.refreshInterval) return;
    this.refreshRunningSyncs();
    this.refreshInterval = this.timerService.register(
      () => {
        this.refreshRunningSyncs();
      },
      { visibleInterval: SYNC_REFRESH_RATE }
    );
  }

  private refreshRunningSyncs() {
    if (!this.runningSyncs?.length) {
      return;
    }
    return this.syncsService.refresh(this.runningSyncs);
  }

  private removeFyiIf(condition: (fyi: Fyi.Fyi) => boolean) {
    for (const date in this.fyisPerDay) {
      const newFyiList: Fyi.Fyi[] = [];
      this.fyisPerDay[date].forEach((fyi: Fyi.Fyi, i: number) => {
        if (!condition(fyi)) {
          newFyiList.push(fyi);
        }
      });
      if (newFyiList.length) this.fyisPerDay[date] = newFyiList;
      else delete this.fyisPerDay[date];
    }
  }

  private async loadShortcuts(): Promise<FyiShortcuts> {
    const shortcuts = Object.values(DEFAULT_KEYBOARD_SHORTCUTS).filter((s) => s.keys && s.id.includes('_fyi'));
    const r = {};
    for (const s of shortcuts) r[s.id.split('_')[2]] = normalizeKeys(<string[]>s.keys);

    return <FyiShortcuts>r;
  }

  private async initReadFyi() {
    if (!isEmpty(this.readIdFyis)) return;
    // first login
    if (!Object.keys(this.fyisPerDay).length) {
      this.initCountUnread = true;
    } else if (Object.keys(this.fyisPerDay).length) {
      await this.markAllRead();
    }
  }

  async updateReadFyi<T extends anyFyi>(item: T): Promise<T> {
    if (isEmpty(this.readIdFyis)) return item;
    if (this.readIdFyis?.includes(item.id)) {
      item.read = true;
    }
    return item;
  }

  private initRoute() {
    this.routerService.queryParams$
      .pipe(
        take(1),
        filter((p) => p?.fyi)
      )
      .subscribe(async () => {
        await this.closePopup();
      });
    this.routerService.queryParams$.pipe(filter((p) => p?.fyi === 'open')).subscribe(() => {
      if (this.actionSource) {
        this.openPopup();
      }
    });
  }

  addOpenFyiQueryParam(action: ActionSourceType, fyiButtonRef: ElementRef, menuWidth: number) {
    if (this.routerService.queryParams['fyi'] === 'open') {
      this.closePopup();
      this.closePopupLinkStaled();
      return;
    }
    const location = { title: this.titleBarService.locationTitle };
    this.eventsService.event('header.fyi', { location });

    const { x, y } = fyiButtonRef.nativeElement.getBoundingClientRect();
    this.position = { left: x + menuWidth, top: y };
    this.actionSource = action;
    this.options = {
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'top',
        },
      ],
    };
    this.routerService.addQueryParam({ fyi: 'open' }, true);
  }

  async openPopup() {
    if (this.ref) {
      this.ref.destroy();
    }
    this.active = true;
    const popupHeight = this.calcHeight();
    const count = await firstValueFrom(this.count$);
    const popupHeightNumber = count === 0 ? -288 : Number(`-${popupHeight.slice(0, popupHeight.length - 2)}`);
    this.position.top = this.position.top + popupHeightNumber - 12;
    this.ref = this.popupService.open(this.position, FyiPopupComponent, {}, { position: 'right', ...(this.options ?? {}) });
    this.ref.destroy$.pipe(take(1)).subscribe(() => {
      this.ref = null;
    });
    this.ref.close$.subscribe(async () => {
      await this.closePopup();
    });
  }

  calcHeight(): string {
    const heightScreen: number = window.innerHeight;
    const widthScreen: number = window.innerWidth;
    const countDays: number = Object.keys(this.fyisPerDay).length;
    const Fyis: Fyi.Fyi[] = this.getFlatFyisPerDay();
    const countFyis: number = Fyis.length;
    const countActiveFyis = Fyis.map((fyi) => fyi as Fyi.AppDataFyi).filter(
      (item) => item.type === 'link_staled' || item.type === 'version_update' || !item.isLinkExists
    ).length;

    let heightPopup = 0;

    if (Fyis.length > this.settingHeightPopup.minFyis) {
      const height = heightScreen - this.settingHeightPopup.spaceBottom - this.settingHeightPopup.heightSpaceScroll;
      return `${height > this.settingHeightPopup.heightMediumPopup ? this.settingHeightPopup.heightMediumPopup : height}px`;
    }

    heightPopup = countDays * this.settingHeightPopup.heightDay + countFyis * this.settingHeightPopup.heightFyi;
    heightPopup += countActiveFyis * this.settingHeightPopup.heightActiveFyi;
    if (
      (widthScreen < this.settingHeightPopup.widthSmallBreakpoints || heightScreen < this.settingHeightPopup.heightSmallBreakpoints) &&
      heightPopup > this.settingHeightPopup.heightSmallPopup
    )
      return `${this.settingHeightPopup.heightSmallPopup}px`;
    if (
      (widthScreen < this.settingHeightPopup.widthSmallBreakpoints || heightScreen < this.settingHeightPopup.heightSmallBreakpoints) &&
      heightPopup < this.settingHeightPopup.heightSmallPopup
    )
      return `${heightPopup + this.settingHeightPopup.heightSpaceScroll}px`;
    if (heightPopup > this.settingHeightPopup.heightMediumPopup) return `${this.settingHeightPopup.heightMediumPopup}px`;
    return `${heightPopup + this.settingHeightPopup.heightSpaceScroll}px`;
  }

  private getFlatFyisPerDay(): Fyi.Fyi[] {
    const fyis = Object.values(this.fyisPerDay).flatMap((fyiPerDay) => fyiPerDay);
    return fyis;
  }

  closePopupLinkStaled() {
    this.ref?.destroy();
  }

  async closePopup() {
    this.routerService.removeQueryParam('fyi', true);
    this.active = false;
    if (!this.ref) return;
    this.markAllRead();
  }

  async clear() {
    await this.readService.updateRead([]);
  }

  async markAllRead() {
    this.unread = 0;
    const newIds = [];
    Object.values(this.fyisPerDay).flatMap((list) =>
      list.map((fyi) => {
        fyi.read = true;
        newIds.push(fyi.id);
      })
    );
    if (isEqual(newIds, this.readIdFyis)) {
      return;
    }
    this.readIdFyis = newIds;
    await this.readService.updateRead(this.readIdFyis);
  }

  async markFyiRead(idFyiItem: string) {
    const newIds = [];
    Object.values(this.fyisPerDay).flatMap((list) =>
      list
        .filter((fyi) => fyi.id === idFyiItem)
        .map((item) => {
          item.read = true;
          newIds.push(item.id);
        })
    );
    this.updateCountUnreadFyis();
    if (isEqual(newIds, this.readIdFyis)) {
      return;
    }
    this.readIdFyis = newIds;
    await this.readService.updateRead(this.readIdFyis);
  }
}

export interface FyiShortcuts extends Shortcuts {
  open: Array<string>;
  clearAll: Array<string>;
  exclude: Array<string>;
}
