import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { People, Search } from '@local/client-contracts';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EventInfo } from '@shared/services';
import { BehaviorSubject, filter, Observable, Subject, Subscription } from 'rxjs';
import { EXTRA_SMALL_WIDTH_PREVIEW, SMALL_WIDTH_PREVIEW } from 'src/app/bar/services/preview.service';
import { ResultsService } from 'src/app/bar/services/results.service';
import { DisplayItemData, SearchResults, isRelevantPeopleItem } from 'src/app/bar/views/results';
import { PreviewComponent } from '../../../model/preview-component';
import { PreviewMode } from '../../../model/preview-mode';
import { fade } from '../../fade.animations';
import { PeopleService } from '../../services/people.service';
import { PreviewType } from 'src/app/bar/views/results/models/view-filters';
@UntilDestroy()
@Component({
  selector: 'people-preview-container',
  templateUrl: './people-preview-container.component.html',
  styleUrls: ['./people-preview-container.component.scss'],
  animations: [fade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeoplePreviewContainerComponent implements PreviewComponent, OnDestroy {
  startLoad: boolean;
  private readonly MAX_WIDTH_CONTENT = 1378;
  private readonly previewTypes = ['people', 'relevant-people'];
  private _isFavorite: boolean;
  previewType: PreviewType;
  _previewWidth: number;
  @Input() set model(val: Search.ResultResourceItem & DisplayItemData) {
    if (!this.previewTypes.includes(val?.action?.type) || (this._model && this._model.id === val?.id)) {
      return;
    }
    this.previewType = val?.action?.type as PreviewType;
    this._model = val;
    this.initData();
  }
  get model(): Search.ResultResourceItem {
    return this._model;
  }
  @Input() set previewWidth(val: number) {
    if (!val) return;
    this._previewWidth = val;
    this.smallWidthPreview = val < SMALL_WIDTH_PREVIEW && this.size != 'full' ? true : false;
    this.extraSmallWidthPreview = val < EXTRA_SMALL_WIDTH_PREVIEW && this.size != 'full' ? true : false;
    this.cdr.markForCheck();
  }

  @Input() set isFavorite(val: boolean) {
    this._isFavorite = val;
    this.cdr.markForCheck();
  }

  get isFavorite(): boolean {
    return this._isFavorite;
  }

  get previewWidth() {
    return this._previewWidth > this.MAX_WIDTH_CONTENT ? this.MAX_WIDTH_CONTENT : this._previewWidth - 1;
  }

  get current(): People.PersonContext {
    return this.current$.value;
  }

  set current(val: People.PersonContext) {
    this.current$.next(val);
  }

  telemetrySearch: Partial<EventInfo>;
  size$: Subject<PreviewMode> = new Subject<PreviewMode>();
  size: PreviewMode;
  index: number;
  current$ = new BehaviorSubject<People.PersonContext>(null);
  error$ = new BehaviorSubject(false);
  isLauncher?: boolean;
  smallWidthPreview: boolean;
  extraSmallWidthPreview: boolean;
  private _model: Search.ResultResourceItem;
  private subscription: Subscription;

  constructor(private cdr: ChangeDetectorRef, private peopleService: PeopleService, private resultsService: ResultsService) {}

  ngOnDestroy(): void {
    this.unsubscribePerson();
  }

  async initData() {
    if (isRelevantPeopleItem(this.model)) {
      await this.addActionToItems(this.model.expert.contributions);
      this.current = this.model.expert;
      this.cdr.markForCheck();
    } else {
      this.initPerson();
    }
  }
  initPerson() {
    this.error$.next(false);
    this.unsubscribePerson();
    this.current = null;
    const timeout = setTimeout(() => {
      this.startLoad = true;
      this.cdr.markForCheck();
    }, 50);
    this.peopleService
      .getPerson(this.model?.resource?.externalId)
      .then((val) => {
        this.subscription = val.pipe(filter((val) => !!val)).subscribe({
          next: async (res) => {
            const actionTask = this.resultsService.getResultAction(res.details.source);
            this.current = res;
            (this.current.details.source as SearchResults).action = await actionTask;
            this.current.details.source.view = this.model?.view;
            clearTimeout(timeout);
            this.startLoad = false;
            this.cdr.markForCheck();
          },
          error: () => {
            this.error$.next(true);
          },
        });
      })
      .catch(() => {
        this.error$.next(true);
      });
    this.cdr.markForCheck();
  }

  async addActionToItems(items: Search.ResultResourceItem[]) {
    for (const item of items) {
      (item as SearchResults).action = await this.resultsService.getResultAction(item);
    }
  }

  tryAgain(): void {
    this.initPerson();
  }

  private unsubscribePerson() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
}
