import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'session-expire-popup',
  templateUrl: './session-expire-popup.component.html',
  styleUrls: ['./session-expire-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionExpirePopupComponent {
  @Output() signIn = new EventEmitter();
}
