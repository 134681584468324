import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventInfo, EventInfoSearch, EventsService } from '@shared/services';
import { RouterService } from '@shared/services/router.service';
import { firstValueFrom } from 'rxjs';
import { ResultItem } from '../views/results/models/results-types';
import { HubService } from './hub.service';

export type ViewPages = 'calendar' | 'shared_files' | 'favorites' | 'recently_viewed' | 'home';

interface ResourceImpressionItem {
  id: string;
  list: string;
  appId: string;
  type: string;
  position: number;
  linkId: string;
}

@Injectable({
  providedIn: 'root',
})
export class TelemetryHelperService {
  constructor(private eventsService: EventsService, private hubService: HubService, private routerService: RouterService) {}

  public async impressionEvent(
    pageName: ViewPages,
    resources: ResultItem[],
    search: Partial<EventInfoSearch>
  ): Promise<void> {
    const activeRoute: ActivatedRoute = await firstValueFrom(this.routerService.activeRoute$);
    const searchTrigger: string[] = (await firstValueFrom(activeRoute.queryParams))['search-trigger'];
    const event: Partial<EventInfo> = {
      search,
      resources: this.buildResourcesForImpressionEvent(pageName, resources),
      location: {
        title: this.hubService.currentLocation,
      },
    };
    if (searchTrigger) {
      event.jsonData = { 'action.trigger': searchTrigger[0] };
    }
    this.eventsService.event(`${pageName}.impression`, event);
  }

  private buildResourcesForImpressionEvent(pageName: ViewPages, resources: ResultItem[]): ResourceImpressionItem[] {
    if (!resources) return [];
    const items = resources.map((r, i) => ({
      id: r.resource.id,
      list: pageName,
      appId: r.resource.appId,
      type: r.resource.type,
      position: r.displayIndex || i,
      linkId: r.resource.linkId,
    }));
    if (resources.every((i) => !!i.displayIndex)) items.sort((a, b) => a.position - b.position);
    return items;
  }
}
