import { Injectable } from '@angular/core';
import { Config } from '@environments/config';
import { BrowserExtensionConfig } from '@environments/config-interface';
import { SessionInfo } from '@local/client-contracts';
import { ManualPromise } from '@local/common';
import { isEdge, isFirefox, isNativeWindow, isSafari, RpcBrowserExtensionChannel } from '@local/common-web';
import { EmbedService } from '@shared/embed.service';
import { Logger } from '@unleash-tech/js-logger';
import { invoker, Rpc, RpcChannel, RpcObservableExtension, RpcWebPortChannel } from '@unleash-tech/js-rpc';
import { distinctUntilChanged, firstValueFrom, map, Observable, ReplaySubject } from 'rxjs';
import Semaphore from 'semaphore-async-await';
import { LogService } from '.';
import { TimerService } from './timer.service';
import { delay } from '@local/ts-infra';

export class BrowserExtensionSessionRpcInvoker {
  @invoker
  update(s: SessionInfo, origin: string): Promise<void> {
    return;
  }
  @invoker
  get current$(): Observable<SessionInfo> {
    return;
  }
}

export interface BrowserExtension {
  version: number;
  rpc: Rpc;
}

export const CONTRACT_VERSION = 2;

@Injectable({
  providedIn: 'root',
})
export class BrowserExtensionService {
  private _current$: ReplaySubject<BrowserExtension> = new ReplaySubject(1);
  private _currentSet = false;
  private _sfFrame: any;
  private _session: SessionInfo;
  private logger: Logger;

  set session(v: SessionInfo) {
    return;
    if (isSafari() && v) {
      this._session = v;
      this._sfFrame.src = 'safari-extension://unleash?command=hello&url=' + escape(location.href) + '&sessionId=' + v.id;
    }
  }

  get current$(): Observable<Rpc> {
    return this._current$.pipe(
      map((b) => (b?.version >= CONTRACT_VERSION ? b?.rpc : null)),
      distinctUntilChanged()
    );
  }

  get version$(): Observable<number> {
    return this._current$.pipe(
      map((b) => b?.version),
      distinctUntilChanged()
    );
  }

  set current(value: BrowserExtension) {
    this._current$.next(value);
  }

  constructor(private logService: LogService, private timer: TimerService, private embedService: EmbedService) {
    this.logger = logService.scope('BrowserExtensionService');
    if (isSafari()) {
      this.current = null;
      return;
      //TODO: to be used in the future for extension
      const frm = document.createElement('iframe');
      frm.style.display = 'none';
      this._sfFrame = frm;
      document.body.appendChild(frm);

      addEventListener('hashchange', () => {
        if (location.hash.startsWith('#ext:')) {
          const jdata = JSON.parse(atob(location.hash.substring(5)));
          if (jdata.command == 'hello:ack') {
            const nonce = jdata.nonce;
            frm.src =
              'safari-extension://unleash?command=session&url=' +
              escape(location.href.slice(0, location.href.indexOf('#'))) +
              '&nonce=' +
              nonce +
              '&session=' +
              escape(JSON.stringify(this._session));
            location.hash = '';
          }
        }
      });
    }

    if (isNativeWindow()) {
      this.current = null;
      return;
    }

    this.attemptConnect();
    window.addEventListener('focus', async () => {
      this.attemptConnect();
    });

    let sub = null;
    this._current$.subscribe((c) => {
      if (!c) {
        if (!sub)
          sub = this.timer.register(
            () => {
              this.attemptConnect();
            },
            { visibleInterval: 5000 }
          );
        return;
      }
      if (sub) {
        this.timer.unregister(sub);
        sub = null;
      }
    });
  }

  // Firefox and some other browser do not support direct connect
  private async attemptConnectLegacy() {
    let viaParent = false;
    // set by content script once ready
    if (!document.documentElement.getAttribute('__unleash__init') && !this._sfFrame) {
      if (this.embedService && (await this.embedService.isExternalWebSite())) {
        this._currentSet = true;
        this.current = null;
        return;
      } else if (this.embedService) viaParent = true;
    }

    const mc = new MessageChannel();
    const mp = new ManualPromise();

    mc.port2.onmessage = (m) => {
      const type = m.data?.type;
      if (type === 'unleash:ext:disconnect') {
        if (!mp.status) mp.resolve(null);
        this._currentSet = true;
        this.current = null;
      }
      if (type === 'unleash:ext:connect:ack') {
        const c = new RpcWebPortChannel(mc.port2);
        if (!mp.status) mp.resolve(null);
        this._currentSet = true;

        this.current = { rpc: this.createRpc(c), version: m.data?.version };
      }
    };
    setTimeout(() => {
      if (!mp.status) {
        this._currentSet = true;
        this.current = null;
        mp.resolve(null);
      }
    }, 2000);

    const win = viaParent ? window.parent : window;

    win?.postMessage({ type: 'unleash:ext:connect', port: mc.port1 }, '*', [mc.port1]);

    return mp;
  }

  private sem = new Semaphore(1);

  private async attemptConnect() {
    const rt = window.chrome?.runtime || (<any>window).browser?.runtime;
    await this.sem.acquire();

    if (this._currentSet && (await firstValueFrom(this._current$))) {
      this.sem.release();
      return;
    }

    if (!rt) {
      try {
        // firefox works with older exception types

        if (!window.chrome && !(<any>window).browser) await this.attemptConnectLegacy();
        else {
          this._currentSet = true;
          this.current = null;
        }
        return;
      } finally {
        this.sem.release();
      }
    }

    let done = false;
    let killed = false;

    // connect doesn't throw if the extension is not  there it will only call onDisconn+ect
    const config: BrowserExtensionConfig = Config.browserExtension;
    let ids = isSafari() ? [config.webkit.id] : isFirefox() ? [config.gecko.id] : [config.chromium.id];

    if (isEdge()) ids.unshift(config.edge.id);

    // edge has a thing on google related websites they are changing the userAgent not to include edge
    // it would be better always to rely in an embed on the embed id rather ids
    if (this.embedService && !(await this.embedService.isExternalWebSite())) {
      let id = await (await firstValueFrom(this.embedService.options$)).id;
      const PREF = 'extension:';
      if (id.startsWith(PREF)) {
        id = id.substring(PREF.length);
        ids = [id];
      }
    }
    let failures = 0;

    for (let id of ids) {
      try {
        const port = rt.connect(id);

        port.onDisconnect.addListener(() => {
          if (killed) return;
          if (++failures == ids.length) {
            killed = true;
            done = true;
            this.sem.release();
            this._currentSet = true;
            this.current = null;
          }
        });

        port.onMessage.addListener((e) => {
          if (done) return;

          if (e?.type == 'unleash:ext:connect:ack') {
            done = true;
            const c = new RpcBrowserExtensionChannel(port);
            this.sem.release();
            this._currentSet = true;
            this.current = { rpc: this.createRpc(c), version: e.version };
          }
        });

        const MAX_TIMEOUT = 2000;
        delay(MAX_TIMEOUT).then(() => {
          if (killed || done) return;
          if (++failures == ids.length) {
            killed = true;
            done = true;
            this.sem.release();
            this._currentSet = true;
            this.current = null;
          }
        });

        await port.postMessage({ type: 'unleash:ext:connect' });
      } catch (e) {
        if (killed) continue;
        if (++failures == ids.length) {
          done = true;
          killed = true;
          this.sem.release();
          this._currentSet = true;
          this.current = null;
        }
      }
    }
  }

  private createRpc(c: RpcChannel): Rpc {
    const rpc = new Rpc(c, this.logger);

    rpc.addExtension(new RpcObservableExtension());
    return rpc;
  }
}
