import { NgModule } from '@angular/core';
import { UTextareaInteractiveComponent } from './u-textarea-interactive.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { UIconModule } from '../u-icon/u-icon.module';
import { UTextareaModule } from '../u-textarea/u-textarea.module';
import { UButtonModule } from '../u-button/u-button.module';

@NgModule({
  declarations: [UTextareaInteractiveComponent],
  imports: [CommonModule, FormsModule, UTextareaModule, TooltipModule, UIconModule, UButtonModule],
  exports: [UTextareaInteractiveComponent],
})
export class UTextareaInteractiveModule {}
