import { Injectable } from '@angular/core';
import { Reports } from '@local/client-contracts';
import { ReportsRpcInvoker } from '@local/common';
import { ServicesRpcService } from '.';

@Injectable({
  providedIn: 'root',
})
export class ReportsService implements Reports.Service {
  private service: Reports.Service;
  constructor(host: ServicesRpcService) {
    this.service = host.invokeWith(ReportsRpcInvoker, 'reports');
  }

  submit(type: Reports.ReportsType, message: string): Promise<void> {
    return this.service.submit(type, message);
  }
}
