import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'u-menubar',
  templateUrl: './u-menubar.component.html',
  styleUrls: ['./u-menubar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UMenubarComponent {
  @Input() items: MenuItem[];
  @Input() styles: any = {};
}
