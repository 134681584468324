import { Constants } from '@local/common';

export const wikiContent = {
  wikiEmptyStateTitle1: 'Wikis',
  wikiEmptyStateSubtitle1: 'Create bite sized knowledge cards and organize them into',
  wikiEmptyStateSubtitle2: 'topics to share with your team.',
  wikiEmptyStateBtnText: 'Create New Wiki',
  learnMore: 'Learn More',
  cardEmptyStateTitle: 'Create cards and share knowledge with your team',
  cardEmptyStateSubtitle: "Try starting with short FAQs like “what is the company's policy on working remote?”",
  cardEmptyStateBtnText: 'Create New Card',
  moveToWikiWarningMessage:
    'When moving a folder or card to another wiki, it will adopt the verification policy of the destination wiki. A folder will move with all the cards it contains.',
  folderEmptyStateTitle: 'Create folders to organize cards by topics',
  folderEmptyStateSubtitle: "Try starting with short FAQs like “what is the company's policy on working remote?”",
  folderEmptyStateBtnText: 'Create New Folder',
  draftEmptyStateTitle: "You don't have any saved drafts",
  draftEmptyStateSubtitle: 'Your drafts of unsaved changes to cards are available here, ready to pick up where you left off.',
  addToWikiPopupMessage: 'Select a Wiki to add the card to',
  addToWikiPopupMessageOptional: 'Select a folder (optional)',
  moveToWikiPopupMessage: 'Select a Wiki to move the card/folder to',
  moveToWiki: 'Move to...', // or create card
};

export const cardEmptyStateContent = [
  {
    type: 'card',
    titleLine: wikiContent.cardEmptyStateTitle,
    subtitleLine: wikiContent.cardEmptyStateSubtitle,
    iconSvg: {
      lightUrl: 'assets/empty-state/wiki/card-empty-state.svg',
    },
    btnText: wikiContent.cardEmptyStateBtnText,
    learnMoreLink: Constants.LEARN_MORE_CARDS_URL,
  },
];

export const wikiEmptyStateContent = [
  {
    type: 'folder',
    titleLine: wikiContent.folderEmptyStateTitle,
    subtitleLine: wikiContent.folderEmptyStateSubtitle,
    iconSvg: {
      lightUrl: 'assets/empty-state/wiki/folder-empty-state.svg',
    },
    btnText: wikiContent.folderEmptyStateBtnText,
    learnMoreLink: Constants.LEARN_MORE_FOLDER_WIKIS_URL,
  },
  ...cardEmptyStateContent,
];

export const DraftEmptyStateContent = [
  {
    type: 'draft',
    titleLine: wikiContent.draftEmptyStateTitle,
    subtitleLine: wikiContent.draftEmptyStateSubtitle,
    iconSvg: {
      lightUrl: 'assets/empty-state/wiki/draft-empty-state-light.svg',
      darkUrl: 'assets/empty-state/wiki/draft-empty-state-dark.svg',
    },
    learnMoreLink: Constants.LEARN_MORE_DRAFTS_URL,
  },
];
