const CLOUD_MAX_TOTAL = 10000;

export const resultHeaderCountKey = '$$count$$';

export function getDisplayHeader(settings: { title: string; titleEnd: string }, totalResults: number) {
  const addPlus = totalResults >= CLOUD_MAX_TOTAL;
  const displayHeader = { title: '', titleEnd: '' };
  for (const [key, value] of Object.entries(settings || {})) {
    if (value?.includes(resultHeaderCountKey)) {
      const replaceValue = `${totalResults}${addPlus ? '+' : ''}`;
      displayHeader[key] = value.replace(resultHeaderCountKey, replaceValue);
    } else {
      displayHeader[key] = value;
    }
  }
  return displayHeader;
}
