import { Calculators, Filters, Search } from '@local/client-contracts';
import { EventInfo, EventsService, LogService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';
import { TelemetryTrigger } from 'src/app/bar/views';
import { CalculatorsService } from 'src/app/bar/views/calculators/calculators.service';
import { HubService } from '../../../hub.service';
import { SearchClient } from '../search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { SearchResponseType } from '../search-response-type';
import { CalculatorSourceSettings } from './calculator-source-settings';

export class CalculatorSearchClient implements SearchClient<CalculatorSourceSettings> {
  private logger: Logger;

  constructor(
    private logService: LogService,
    private eventsService: EventsService,
    private calculatorsService: CalculatorsService,
    private hubService: HubService
  ) {
    this.logger = this.logService.scope('link-resources');
  }

  supportsSort(sort: Search.Sort): boolean {
    return false;
  }

  supportsFilters(filters: Filters.Values): boolean {
    return false;
  }

  search(request: SearchRequest<CalculatorSourceSettings>, response: SearchResponse): SearchResponseType {
    return this.innerSearch(request, response);
  }

  nextPage(request: SearchRequest<CalculatorSourceSettings>, response: SearchResponse, trigger: TelemetryTrigger): Promise<void> {
    return;
  }

  destroy(): void {
    return;
  }

  private async innerSearch(request: SearchRequest<CalculatorSourceSettings>, response: SearchResponse) {
    const sourceSettings = request.sourceSettings;
    if (!request.query || request.query.length < sourceSettings.minQueryLength) {
      response.complete(true);
      return;
    }
    const r = await this.calculatorsService.calculate(request.query);
    response.items = r ? [r] : [];
    if (r) {
      this.sendCalculatorResultsEvent(r, request);
    }
    response.complete(true);
  }

  getTelemetryEndEvent(response: SearchResponse): Partial<EventInfo>[] {
    return;
  }

  private sendCalculatorResultsEvent(calculation: Calculators.Item, request: SearchRequest<CalculatorSourceSettings>) {
    const type = calculation.resource.result.type;
    this.eventsService.event('calculator.results', {
      target: type,
      label: calculation.resource.result.displayText,
      location: { title: this.hubService.currentLocation },
      jsonData: JSON.stringify({
        calculator: {
          type: `${type}_conversion`,
          source: type !== 'Math' ? calculation.resource.result.source : undefined,
          dest: calculation.resource.result.dest,
          defaultDest: !!calculation.resource.result.defaultDest,
        },
      }),
      search: {
        sessionId: request.sessionId,
        clientSearchId: request.clientSearchId,
      },
    });
  }
}
