<div
  class="contenteditable"
  id="editable"
  [class.readonly]="readonly"
  [class.focus-no-color]="focusNoColor"
  #contenteditable
  contenteditable
  (input)="onInputChange($event.target)"
  (blur)="onBlur.emit()"
  (click)="onClickEvent()"
  (keydown)="onKeydownMain($event)"
  (paste)="onPaste($event)"
>
  <mark *ngIf="isFirstInit">{{ placeholder }}</mark>
  <ng-container *ngIf="!isFirstInit">{{ placeholder }}</ng-container>
</div>
