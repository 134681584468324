import { Injectable } from '@angular/core';
import { Commands, LocalActions, Search, Wiki } from '@local/client-contracts';
import { isEmbed } from '@local/common-web';
import { OpenWithPromptData } from '@shared/components/prompt/open-with-prompt.component';
import { EventInfo, EventInfoResource, EventsService } from '@shared/services';
import { LinksService } from '@shared/services/links.service';
import { RouterService } from '@shared/services/router.service';
import { isLinkContextResource, isLocalActionContextResource, isOpenUrlCommand } from '@shared/utils';
import { merge } from 'lodash';
import { Observable, ReplaySubject, takeUntil } from 'rxjs';
import { PeopleInvokeCommand } from '../../views/results-views/people/people-view-item/people-view-item.component';
import { InvokeCommand } from '../../views/results/models/invoke-command.model';
import {
  BrowserBookmarkItem,
  BrowserTabItem,
  CommandContext,
  SearchResults,
  TelemetryTrigger,
} from '../../views/results/models/results-types';
import { PageType } from '../../views/results/models/view-filters';
import { isBookmark, isHeader, isResourceItem, isWikiCard, isWikiItem, listNameByType } from '../../views/results/utils/results.util';
import { CollectionsService } from '../collections.service';
import { HubService } from '../hub.service';
import { OpenWithPromptService } from '../open-with-prompt.service';
import { ParamsSelectorService } from '../params-selector.service';
import { CommandsService } from './commands.service';
import { OpenSelectPopupType } from '../select-items/select-items-popup-base.service';

export type InvokeCommandResult = { status: 'Done' | 'OpenWithPromptData'; data?: OpenWithPromptData };
@Injectable()
export class ResultCommandService {
  private isEmbed = isEmbed();

  constructor(
    protected hubService: HubService,
    protected eventsService: EventsService,
    private commandsService: CommandsService,
    private routerService: RouterService,
    private paramsSelectorService: ParamsSelectorService,
    private linksService: LinksService,
    private collectionsService: CollectionsService,
    private openWithPromptService: OpenWithPromptService
  ) {}

  onInvoke(
    items: SearchResults[],
    data: InvokeCommand,
    type: Search.ResultType,
    sessionId: string,
    clientSearchId: string,
    searchId: string,
    index: number,
    lastHeaderIndex: number,
    viewName?: PageType,
    paramsSelectionRedirect?: string,
    resourceListType?: string
  ): Observable<InvokeCommandResult> {
    const result$ = new ReplaySubject<InvokeCommandResult>(1);
    this.innerInvoke(
      result$,
      items,
      data,
      type,
      sessionId,
      clientSearchId,
      searchId,
      index,
      lastHeaderIndex,
      viewName,
      paramsSelectionRedirect,
      resourceListType
    );
    return result$;
  }

  private async innerInvoke(
    result$: ReplaySubject<InvokeCommandResult>,
    items: SearchResults[],
    data: InvokeCommand,
    type: Search.ResultType,
    sessionId: string,
    clientSearchId: string,
    searchId: string,
    index: number,
    lastHeaderIndex: number,
    viewName?: PageType,
    paramsSelectionRedirect?: string,
    resourceListType?: string
  ) {
    try {
      const { command, trigger, eventInfo, invokerItem } = data;
      const context = { ...(data.context || {}), searchId };
      context.item = data.invokerItem;

      if (invokerItem) {
        const resourceInvokerItem: Search.ResourceItem = isResourceItem(invokerItem) ? <Search.ResourceItem>invokerItem : undefined;
        context.linkId = resourceInvokerItem?.resource?.linkId || context.linkId;
        const kind = invokerItem.type === 'local-action' || invokerItem.type === 'collection-file' ? invokerItem.type : 'link-resource';
        if (!context.resource) {
          context.resource = { kind };
        } else {
          context.resource.kind = kind;
        }
        context.resource.id = resourceInvokerItem?.resource?.id || context.resource?.id;
        if (isLinkContextResource(context.resource)) {
          context.resource.type = resourceInvokerItem?.resource?.type || context.resource?.type;
        } else if (isLocalActionContextResource(context.resource)) {
          context.resource.params = (<LocalActions.LocalActionItem>invokerItem).params || context.resource?.params;
        }
      }
      const position = this.getRealPosition(items, index, lastHeaderIndex);
      const eventMetadata = merge(
        this.getEventMetadata(sessionId, clientSearchId, searchId, trigger, type, invokerItem, position, resourceListType),
        eventInfo ?? {}
      );

      if (isOpenUrlCommand(command) && context.linkId && !context.openWith) {
        context.openWith = await this.linksService.getOpenWith(context.linkId);
      }

      if (!context.openWith && isOpenUrlCommand(command)) {
        const res = await this.executeCommand(command, context, type, eventMetadata, null);
        if (!res.data.opened && res.data.askUserInput) {
          if (res.data.params && invokerItem && type === 'local-action') {
            const commandResponse = res as Commands.OpenUrlResponse;
            const action = invokerItem as LocalActions.LocalActionItem;
            if (paramsSelectionRedirect) {
              await this.routerService.navigateByUrl(paramsSelectionRedirect);
            }
            this.paramsSelectorService.addParamsSelector({
              icon: action.icon,
              name: action.title,
              resourceName: '',
              ...commandResponse.data.params,
            });

            result$.next({ status: 'Done' });
            return;
          } else {
            if (data.defaultOpenWith || this.isEmbed) {
              await this.executeCommand(command, { ...context, openWith: 'browser', searchId }, type, eventMetadata);
            } else {
              result$.next({ status: 'Done' });
              this.askAndInvokeOpenUrl(command, context, type, eventMetadata);
              return;
            }
          }
        }
      } else {
        const subType = viewName === 'people' ? (data as PeopleInvokeCommand)?.subType : undefined;
        await this.executeCommand(command, context, type, eventMetadata, subType);
      }
    } finally {
      result$.complete();
    }
  }

  getEventMetadata(
    sessionId: string,
    clientSearchId: string,
    searchId: string,
    target: string,
    type: Search.ResultType,
    item: Search.Item,
    position: number,
    resourceListType?: string
  ): Partial<EventInfo> {
    switch (type) {
      case 'go-link':
        return this.getGoLinkEventMetadata(<Search.GoLinkResultItem>item, sessionId, clientSearchId, searchId, target, position);
      case 'browser-bookmark':
      case 'browser-tab':
        return this.getBrowserEventMetadata(
          item as BrowserBookmarkItem | BrowserTabItem,
          sessionId,
          clientSearchId,
          searchId,
          target,
          position,
          resourceListType
        );
      case 'person':
      case 'result':
        return this.getResultEventMetadata(
          <Search.ResultResourceItem>item,
          sessionId,
          clientSearchId,
          searchId,
          target,
          position,
          resourceListType
        );
      case 'calculator':
        return this.getCalcEventMetadata(sessionId, clientSearchId, searchId, target);
      case 'local-action':
        if (!item) return {};
        return this.getActionEventMetadata(item as LocalActions.LocalActionItem, sessionId, clientSearchId, searchId, target, position);
    }
  }

  private getCalcEventMetadata(sessionId: string, clientSearchId: string, searchId: string, target: string): Partial<EventInfo> {
    return {
      category: 'calculator',
      name: 'interaction',
      target,
      location: { title: this.hubService.currentLocation },
      search: {
        searchId,
        sessionId,
        clientSearchId,
      },
    };
  }

  private getActionEventMetadata(
    item: LocalActions.LocalActionItem,
    sessionId: string,
    clientSearchId: string,
    searchId: string,
    target: string,
    position: number
  ): Partial<EventInfo> {
    const actionId = item.id.substring(item.id.indexOf('_') + 1, item.id.length);

    const appId = item.id;
    const firstLinkId = Object.keys(item.urls)[0];
    const linkId = item.urls[firstLinkId][0].linkId;
    return {
      target,
      location: { title: this.hubService.currentLocation },
      label: item.id.replace('_', ':'),
      resources: [
        {
          id: `${appId}_${actionId}`,
          list: 'action_list',
          appId: appId,
          type: `action:${actionId}`,
          position,
          linkId,
        },
      ],
      search: {
        searchId,
        sessionId,
        clientSearchId,
      },
    };
  }

  private getGoLinkEventMetadata(
    item: Search.GoLinkResultItem,
    sessionId: string,
    clientSearchId: string,
    searchId: string,
    target: string,
    position: number
  ): Partial<EventInfo> {
    if (!item) return;
    return {
      target,
      location: { title: this.hubService.currentLocation },
      search: {
        searchId,
        clientSearchId,
        sessionId,
      },
      resources: [
        {
          id: item.data?.nameId,
          list: item.data?.favorite ? 'favorite' : undefined,
          appId: 'go_link',
          type: 'go_link',
          position,
          linkId: item.id,
        },
      ],
    };
  }

  private getBrowserEventMetadata(
    item: BrowserBookmarkItem | BrowserTabItem,
    sessionId: string,
    clientSearchId: string,
    searchId: string,
    target: string,
    position: number,
    resourceListType?: string
  ): Partial<EventInfo> {
    return {
      target,
      location: { title: this.hubService.currentLocation },
      search: {
        searchId,
        clientSearchId,
        sessionId,
      },
      resources: [
        {
          appId: isBookmark(item) ? 'bookmark' : 'open_tabs',
          id: isBookmark(item) ? item.title : item.id,
          list: resourceListType || listNameByType(item),
          position,
          type: item.type,
          linkId: item.profile?.id,
        },
      ],
    };
  }

  private getResultEventMetadata(
    item: Search.ResultResourceItem,
    sessionId: string,
    clientSearchId: string,
    searchId: string,
    target: string,
    position: number,
    resourceListType?: string
  ): Partial<EventInfo> {
    const id = isWikiCard(item) ? item.id : item?.resource?.id;
    return {
      target,
      location: { title: this.hubService.currentLocation },
      search: {
        searchId,
        clientSearchId,
        sessionId,
      },
      resources: [
        {
          id,
          list: resourceListType || listNameByType(item),
          appId: item?.resource?.appId ?? resourceListType,
          type: item?.resource?.type ?? resourceListType,
          position,
          linkId: item?.resource?.linkId,
        },
      ],
    };
  }

  getRealPosition(items: SearchResults[], index: number, lastHeaderIndex: number) {
    const maxIndex = Math.min(index, lastHeaderIndex);
    if (!items) return index;
    return index - items.slice(0, maxIndex + 1)?.filter((i) => isHeader(i)).length;
  }

  async executeCommand(
    command: Commands.Command,
    context: CommandContext,
    type: Search.ResultType,
    event?: Partial<EventInfo>,
    subType?: any
  ): ReturnType<CommandsService['executeCommand']> {
    let res;
    if (['add-to-collection', 'move-to-collection'].includes(command.type)) {
      let type = `${command.type}-popup` as OpenSelectPopupType;
      if (isWikiItem(context.item)) {
        type = 'move-to-wiki-popup';
      }
      this.collectionsService.openSelectPopup$.next({
        data: null,
        type,
        options: { doSetup: true, data: { command, context } },
      });
    } else if (['go-links-change-favorite-status', 'change-favorite-status'].includes(command.type)) {
      const item = <Search.StaticCollectionResultItem>context.item;
      if (item?.kind !== 'static-collection-item') {
        res = await this.commandsService.executeCommand(command, context);
      }
    } else if (command.type !== 'share') {
      res = await this.commandsService.executeCommand(command, context);
    }

    event = {
      location: { title: this.hubService.currentLocation },
      ...event,
      target: ['mouse_click', 'context_menu_click'].includes(event?.target) ? 'mouse_click' : 'keyboard',
    };
    switch (command.type) {
      case 'open-url':
        if (
          (res.data.opened && ['result', 'go-link', 'quick-link'].includes(type)) ||
          type === 'local-action' ||
          type === 'browser-bookmark'
        ) {
          if (context?.openWith) {
            const jsonData = JSON.parse(event?.jsonData || '{}');
            jsonData.openWith = {
              ...(jsonData.openWith || {}),
              target: context.openWith,
            };
            event = { ...event, jsonData: JSON.stringify(jsonData) };
          }
          event = { location: { title: this.hubService.currentLocation }, ...event, label: undefined };
          this.eventsService.event('results.redirect', event);
        } else if (type === 'calculator') {
          this.eventsService.event('calculator.interaction', {
            label: 'see_source',
            ...event,
          });
        }
        break;
      case 'run-action':
        this.eventsService.event('results.action', {
          label: (command as Commands.RunAction).name,
          ...event,
        });
        break;
      case 'copy-clipboard':
        if (type === 'result' || type === 'go-link') {
          this.eventsService.event('results.action', {
            label: subType || 'copy_clipboard',
            ...event,
          });
        } else if (type === 'calculator') {
          this.eventsService.event('calculator.interaction', {
            label: 'copy',
            ...event,
          });
        }
        break;
      case 'change-favorite-status':
        if (type === 'result') {
          this.eventsService.event('results.action', event);
        }
        break;
      case 'add-to-collection':
        this.eventsService.event('results.action', { ...event, label: 'add_to_collection' });
        break;
      case 'download-url':
        this.eventsService.event('results.action', { ...event, label: 'download' });
        break;
      case 'share':
        this.eventsService.event('results.action', { ...event, label: 'share' });
        break;
      case 'preview':
        this.eventsService.event('preview.resource', { ...event });
        break;
      case 'summary':
        this.eventsService.event('summary.conversion', { ...event });
        break;
    }
    return res;
  }

  async askAndInvokeOpenUrl(
    command: Commands.Command,
    commandContext: CommandContext,
    resultType: Search.ResultType,
    event: Partial<EventInfo>
  ) {
    const data = {
      title: `How would you like to open this ${resultType === 'result' ? 'resource' : 'action'}?`,
      linkId: commandContext.linkId,
      command,
      commandContext,
      event,
    };

    const popupRef = this.openWithPromptService.onOpenWithPrompt(data);
    popupRef.compInstance.executeCommand.pipe(takeUntil(popupRef.destroy$)).subscribe((e) => this.executePromptSelection(e));
  }

  executePromptSelection(data: { command: Commands.Command; commandContext: CommandContext; event: Partial<EventInfo> }) {
    const { command, commandContext, event } = data;
    this.executeCommand(command, commandContext, 'result', event);
  }

  async toggleFavorite(
    model: Search.ResultResourceItem,
    eventResources: Partial<EventInfoResource>[],
    trigger: TelemetryTrigger,
    searchId: string
  ) {
    const label = `${model.isFavorite ? 'add' : 'remove'}_favorite`;
    await this.executeCommand(
      { type: 'change-favorite-status', status: model.isFavorite ? 'add' : 'remove' } as Commands.ChangeFavoriteStatus,
      { searchId, linkId: model.link.id, resource: { id: model.resource.id, kind: 'link-resource' } },
      'result',
      {
        label,
        target: trigger,
      }
    );
    this.eventsService.event('context_menu.open_from_preview', {
      label,
      location: { title: this.hubService.currentLocation },
      resources: eventResources,
    });
  }
}
