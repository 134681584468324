import moment from 'moment';

export type DateFormat = 'today' | 'yesterday' | 'this_month' | 'this_year' | 'other';

export function getDateFormat(date: Date): DateFormat {
  const today = new Date();
  const yesterday = moment(today).subtract('1', 'days');

  if (moment(date).isSame(today, 'day')) {
    return 'today';
  } else if (moment(date).isSame(yesterday, 'day')) {
    return 'yesterday';
  } else if (moment(date).isSame(today, 'month')) {
    return 'this_month';
  } else if (moment(date).isSame(today, 'year')) {
    return 'this_year';
  } else {
    return 'other';
  }
}

export function getTimeFromNowInText(createdTime: string | number = null): string {
  return moment(new Date(createdTime)).local().fromNow();
}
