import { Mail } from '@local/client-contracts';
import { async, invoker } from '@unleash-tech/js-rpc';

export class MailRpcInvoker implements Mail.Service {
  @invoker
  get(id: string): Promise<Mail.Mail> {
    return;
  }

  @invoker
  @async
  refreshInbox(): Promise<void> {
    return;
  }
}
