import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { isMac } from '@local/common-web';
import { PopupRef } from '@local/ui-infra';
import { EventsService } from '@shared/services';
import { pushTag } from '@shared/analytics';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { isEnterKey, KeyName } from '@local/ts-infra';
import { BehaviorSubject } from 'rxjs';
import { HubService } from '../../services/hub.service';
import { STEPS } from './walkthrough-steps';

@Component({
  selector: 'walkthrough-gallery',
  templateUrl: './walkthrough-gallery.component.html',
  styleUrls: ['./walkthrough-gallery.component.scss'],
})
export class WalkthroughGalleryComponent implements OnInit, OnDestroy {
  private stepIndex: number;
  private keyHandlerId: string;
  private location: string;
  private isMac = isMac();
  shortcut: string[];
  step$: BehaviorSubject<any> = new BehaviorSubject({});
  maxSteps: number;
  @Output() close = new EventEmitter<boolean>();
  @Output() updateStorage = new EventEmitter<number>();

  constructor(
    private ref: PopupRef<WalkthroughGalleryComponent, any>,
    private cdr: ChangeDetectorRef,
    private keyboardService: KeyboardService,
    private eventsService: EventsService,
    private hubService: HubService
  ) {}

  ngOnInit(): void {
    this.location = this.hubService.currentLocation;
    this.shortcut = ['CommandOrControl', this.isMac ? 'J' : 'M'];
    this.registerKeyboardHandler();
    this.initStepsData();
  }

  private initStepsData() {
    this.maxSteps = this.ref.data.maxSteps;
    this.stepIndex = this.ref.data?.index;
    this.updateStep();
  }

  private registerKeyboardHandler() {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    }
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 8);
  }

  ngOnDestroy(): void {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
      this.keyHandlerId = null;
    }
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    const key = keys[0];
    if (isEnterKey(key)) {
      this.next();
      event.stopPropagation();
    }
  }

  next() {
    this.eventsService.event('walkthrough.interaction', {
      label: STEPS[this.stepIndex - 1].telemetryLabel,
      location: { title: this.location },
      target: this.stepIndex === this.maxSteps ? 'done' : 'next',
    });
    if (this.stepIndex === 1 || this.stepIndex === this.maxSteps) {
      const targetAction = this.stepIndex === 1 ? 'start_walkthrough' : 'completed_walkthrough';
      pushTag({ event: targetAction });
    }
    if (this.stepIndex === this.maxSteps) {
      this.close.emit(true);
      return;
    }
    this.stepIndex++;
    this.updateStep(true);
  }

  openUrl(url: string) {
    window.open(url, '_blank');
  }

  changeStep(index: number) {
    if (index === this.stepIndex || index > this.stepIndex + 1) return;
    this.eventsService.event('walkthrough.interaction', {
      label: STEPS[index - 1].telemetryLabel,
      location: { title: this.location },
      target: index < this.stepIndex ? 'back' : 'next',
    });
    this.stepIndex = index;
    this.updateStep(true);
  }

  private updateStep(updateStorage: boolean = false) {
    this.step$.next(STEPS[this.stepIndex - 1]);
    if (updateStorage) {
      this.updateStorage.emit(this.stepIndex);
    }
    this.cdr.markForCheck();
  }
}
