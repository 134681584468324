import { Pipe, PipeTransform } from '@angular/core';

/** @param query User Query
 * @param selected The current selected / suggested title
 * @return the value formatted to the right or to the left
 */
@Pipe({
  name: 'inputAutocomplete',
})
export class InputAutocompletePipe implements PipeTransform {
  private separatorChar = '\xa0\xa0-\xa0\xa0';
  transform(query: string, selected: string, prefix?: string, element?: HTMLElement): string {
    if (!query && !selected) return;

    const rightValue = (value: string) => query + this.separatorChar + value;
    const leftValue = (selected: string) =>
      query + (query.length <= selected?.length ? selected.substring(query.length, selected?.length) : selected);

    selected = selected ?? '';

    if (!selected.length) {
      this.dispatchEvent(selected, element);
      return leftValue(selected);
    }

    const q = query?.toLowerCase() ?? '';
    const valueSplit = selected.toLowerCase().indexOf(q);
    const hasQuery = !!q || valueSplit !== 0;

    if (prefix) {
      const result = `${prefix} ${selected}`;
      if (hasQuery) {
        this.dispatchEvent(result, element);
        return rightValue(result);
      } else {
        return result;
      }
    }
    const result = hasQuery && !selected.toLowerCase().startsWith(q) ? rightValue(selected) : leftValue(selected);

    this.dispatchEvent(result, element);
    return result;
  }

  /** Dispatch value that the transform fn supplied and if available emit custom event from the host element
   * So the component can be up to date with the recent pipe return value.
   */
  private dispatchEvent(value: string, element: HTMLElement) {
    if (element) {
      let emittedValue = value;
      if (value.split(this.separatorChar).length > 1) {
        emittedValue = value.split(this.separatorChar)[1];
      }
      const ev: CustomEvent<string> = new CustomEvent('autocomplete-changed', { detail: emittedValue });
      element.dispatchEvent(ev);
    }
  }
}
