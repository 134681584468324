import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { STYLE_SERVICE } from '@local/ui-infra';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RouterService } from '@shared/services/router.service';
import { componentServicesRpcProvider } from '@shared/services/rpc.service';
import { StyleService } from '@shared/services/style.service';
import { Embed, IReportEmbedConfiguration, models, service } from 'powerbi-client';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { AnalyticsService } from 'src/app/bar/services/analytics.service';
import { HubService } from 'src/app/bar/services/hub.service';

@UntilDestroy()
@Component({
  selector: 'analytics',
  providers: [componentServicesRpcProvider],
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsComponent {
  isLoading = true;
  isEmbed: boolean;
  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  };
  reportClass = 'report-container';
  phasedEmbeddingFlag = false;
  @ViewChild('report') reportObj!: PowerBIReportEmbedComponent;

  eventHandlersMap = new Map([['rendered', () => this.onRendered()]]) as Map<
    string,
    (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null
  >;

  constructor(
    private analyticsService: AnalyticsService,
    private hubService: HubService,
    private routerService: RouterService,
    private cdr: ChangeDetectorRef,
    @Inject(STYLE_SERVICE) private styleService: StyleService
  ) {}

  ngOnInit() {
    this.hubService.placeholder = '';
    this.hubService.suggestionsEnabled = false;
    this.hubService.autoCompleteEnabled = false;
    if (this.routerService.active === 'analytics') {
      this.hubService.readOnly = true;
    }
    this.loadAnalytics();
  }

  private async loadAnalytics() {
    this.analyticsService.analytics$.subscribe(async (analyticsData) => {
      if (!analyticsData) {
        return;
      }
      this.reportConfig = {
        ...this.reportConfig,
        id: analyticsData.reportId,
        accessToken: analyticsData.token,
        settings: {
          localeSettings: {
            language: 'en-us',
          },
          layoutType: models.LayoutType.Custom,
          customLayout: {
            displayOption: models.DisplayOption.FitToWidth,
          },
          background: models.BackgroundType.Transparent,
        },
      };
      this.isEmbed = true;
      if (!this.isLoading) {
        const report = this.reportObj.getReport();
        await report.setAccessToken(analyticsData.token);
        report.refresh();
      }
      this.cdr.markForCheck();
    });
  }

  private async onRendered() {
    this.isLoading = false;
    this.cdr.markForCheck();
  }
}
