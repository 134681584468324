import { Config } from '@environments/config';
import { SessionInfo } from '@local/client-contracts';
import { AppName } from '@local/common';
import { BrowserName } from '@local/common-web';
import { TelemetryService } from '@shared/services';
import { AppService } from '@shared/services/app.service';
import { SearchResultContext } from 'src/app/bar/services/search';

export class TicketTracker {
  private sentOnCompleted = false;
  private readonly MIN_STAGE = 15;
  private readonly MAX_STAGE = 60;
  constructor(appService: AppService, private analyticsService: TelemetryService) {
    this.state = {
      iterations: 0,
      completed: false,
      clientDetails: {
        browser: appService.browser,
        platform: appService.platform,
        version: Config.version,
      },
      stage: this.MIN_STAGE,
      abandoned: false,
      clients: {},
    };
  }

  state: {
    id?: number;
    iterations: number;
    stage: number;
    trigger?: string;
    url?: string;
    completed: boolean;
    ticketId?: string;
    triggeredTime?: number;
    clientDetails: {
      browser: BrowserName;
      version: string;
      platform: AppName;
      accountId?: string;
      workspaceId?: string;
    };
    abandoned: boolean;
    clients: any;
  };

  updateAccount(s: SessionInfo) {
    this.state.clientDetails.accountId = s?.workspace?.accountId;
    this.state.clientDetails.workspaceId = s?.workspace?.id;
  }

  updateSearchParams(ticketId: string) {
    this.state.ticketId = ticketId;
  }

  start(time: number, trigger: string) {
    if (this.state && (!this.state.completed || !this.sentOnCompleted) && this.state.stage !== this.MIN_STAGE) {
      // sending uncompleted state before moving to the next search
      this.state.abandoned = true;
      this.analyticsService.insight('long-assistant-ticket', this.state);
    }
    this.state.clients = {};
    this.state.ticketId = null;
    this.state.trigger = trigger;
    this.state.triggeredTime = time;
    this.state.completed = false;
    this.state.abandoned = false;
    this.state.stage = this.MIN_STAGE;
    this.sentOnCompleted = false;
  }

  track(ctx: SearchResultContext) {
    if (!ctx) {
      return;
    }
    const now = Date.now();
    const startTime = this.state.triggeredTime;
    const completed = ctx.searchCompleted;
    for (const source of ctx.sources || []) {
      const id = source.source.id || source.source.type;
      const client = this.state.clients[id] || {};
      if (client?.done) {
        continue;
      }
      const req = source.extra;
      const performance = req?.performance;
      client.performance = {
        ...(performance?.general || {}),
      };

      client.searchId = source.extra?.searchId;
      client.done = source.done;
      client.source = source.source;
      if (source.error) {
        client.error = source.error;
      }
      if (client.done) {
        client.duration = source.duration;
        client.itemsLength = source.items?.length;
      }
      this.state.clients[id] = client;
    }
    this.state.id = ctx.id;
    this.state.completed = completed;
    this.state.url = location.href;
    const currentStage = this.state.stage;
    if (now - startTime > currentStage * 1000 && ((completed && !this.sentOnCompleted) || currentStage < this.MAX_STAGE)) {
      this.state.stage = !completed ? currentStage * 2 : this.MAX_STAGE;
      if (completed) {
        this.sentOnCompleted = true;
      }
      this.analyticsService.insight('long-assistant-ticket', this.state);
    }
  }
}
