<div class="create-post-popup-wrapper" (click)="onCancel.emit()">
  <div class="create-post-popup-container" (click)="$event.stopPropagation()">
    <header class="create-post-title">{{ data?.isEditMode ? 'Edit Post' : 'Create New Post' }}</header>
    <h5 class="create-post-subtitle">Anyone who has access to the tab will be able to see this</h5>
    <a class="dismiss-button" (click)="onCancel.emit()">
      <u-icon class="close-button" [model]="{ type: 'font', value: 'icon-times-large' }"></u-icon>
    </a>
    <section class="post-content-upload-section">
      <div class="post-title-input" [class.invalid-title]="!isValidTitle">
        <u-input
          #inputTitle
          [model]="title"
          (onChange)="onTitleInputChange($event)"
          placeholder="Add title"
          label="Title"
          [inputSize]="'medium'"
          [removeText]="true"
          [class.filled-input]="!!title"
        ></u-input>
        <div *ngIf="!isValidTitle" class="invalid-title-error">Title already exists</div>
      </div>
      <div class="post-image-upload">
        <span class="post-image-upload-title">Images</span>
        <div class="radio-button">
          <u-radio-button
            [options]="SELECTED_SIZE_OPTIONS"
            [selectedOption]="selectedSize"
            (onClick)="onSelectedSizeImage($event)"
          ></u-radio-button>
        </div>
        <div class="post-image-upload-footer">
          Upload up to 5 images, {{ selectedSize === 'large' ? DESCRIPTION_SIZE_IMAGES.large : DESCRIPTION_SIZE_IMAGES.small }}
        </div>
        <div>
          <u-file-upload
            (onSelect)="fileChangeEvent($event)"
            [style]="{ width: '100%', height: '48px' }"
            [label]="'+ Upload Image'"
            [accept]="supportedImageTypes"
            [disabled]="disableFileUpload"
          ></u-file-upload>
        </div>
      </div>
      <div *ngIf="data?.isEditMode && !carousel.length" class="remove-warning">Posts without images will be removed upon saving</div>

      <div *ngIf="isMaxHightPopup; else carouselFiles">
        <ng-scrollbar visibility="hover" [style.height]="'108px'">
          <ng-container *ngTemplateOutlet="carouselFiles"></ng-container>
        </ng-scrollbar>
      </div>

      <ng-template #carouselFiles>
        <ng-container *ngIf="carousel">
          <div cdkDropList (cdkDropListDropped)="drop($event)" class="carousel-items-container">
            <div
              *ngFor="let carouselItem of carousel; let i = index"
              cdkDrag
              class="carousel-item"
              (mouseover)="carouselHoveredIndex = i"
              (mouseleave)="carouselHoveredIndex = null"
              [ngClass]="{ hovered: carouselHoveredIndex === i }"
              (auxclick)="openContextMenu($event, carouselItem, i)"
              (contextmenu)="openContextMenu($event, carouselItem, i)"
            >
              <div class="carousel-item-name-container">
                <u-icon
                  (click)="onCloseButton($event)"
                  cdkDragHandle
                  class="drag-icon"
                  [model]="{ type: 'font', value: 'icon-dragging-ind' }"
                  [styles]="{ fontSize: '10px' }"
                ></u-icon>
                <u-icon class="carousel-item-icon" [model]="{ type: 'font', value: 'icon-file-image' }"></u-icon>
                <span
                  class="carousel-item-name"
                  (click)="openEditMode(i)"
                  [pTooltip]="displayImagesNames[i]?.tooltip"
                  tooltipPosition="top"
                  tooltipStyleClass="u-tooltip"
                  >{{ displayImagesNames[i]?.title }}</span
                >
                <div
                  *ngIf="showWarningImage"
                  class="warning-icon"
                  [pTooltip]="WARNING_TOOLTIP"
                  tooltipPosition="top"
                  tooltipStyleClass="u-tooltip"
                >
                  <div class="icon">!</div>
                </div>
              </div>
              <div class="remove-carousel-item" (click)="removeCarouselItem(i)">Remove</div>
              <square-button
                [active]="contextMenuOpened"
                class="square-button"
                (click)="$event.stopPropagation(); openContextMenu($event, carouselItem, i)"
                (auxclick)="$event.stopPropagation(); openContextMenu($event, carouselItem, i)"
                (contextmenu)="$event.stopPropagation(); openContextMenu($event, carouselItem, i)"
                #actions
                >•••</square-button
              >

              <div *cdkDragPreview class="cdk-drag-preview-carousel-item">
                <u-icon class="carousel-item-icon" [model]="{ type: 'font', value: 'icon-file-image' }"></u-icon>
                <span>{{ carouselItem.imageName }}</span>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </section>
    <section class="audience-container">
      <div class="auto-complete-container">
        <h6 class="audience-subtitle-header">Pin to Tab</h6>
        <h6 class="audience-subtitle">Select a homepage tab to pin this post to</h6>
        <u-auto-complete
          class="select-tab-auto-complete"
          (onChange)="onAutoCompleteChange($event)"
          [suggestionItems]="autoCompleteTabItems"
          [selectedItems]="tab"
          field="name"
          [multiple]="false"
          [expandOnInputClick]="true"
          [showArrow]="true"
          [removeSelected]="false"
          [placeholder]="'Select a Tab'"
        >
          <ng-template let-tab pTemplate="item">
            <div class="tab-autocomplete-item">
              <span class="autocomplete-tab-name">{{ tab.name }}</span>
              <avatar-list class="avatar-list" [model]="tab.avatarList" [spreadAvatarsCount]="3" [tooltipLimit]="10"></avatar-list>
            </div>
          </ng-template>
        </u-auto-complete>
      </div>

      <div class="carousel-settings">
        <u-checkbox
          label="Auto-play gallery"
          [checked]="carouselAutoplay"
          [binary]="true"
          inputId="binary"
          (onChange)="carouselAutoplayToggle($event)"
          [disabled]="disableAutoplayButton"
        ></u-checkbox>
      </div>

      <div class="create-new-post-buttons">
        <u-button label="Cancel" type="secondary" (onClick)="onCancel.emit()"></u-button>
        <u-button
          [label]="data?.isEditMode ? 'Save' : 'Publish'"
          type="primary"
          [disabled]="data?.isEditMode ? !saveEnabled : !publishEnabled"
          (onClick)="onPublishPost()"
        ></u-button>
      </div>
    </section>
  </div>
</div>
