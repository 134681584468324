import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Search } from '@local/client-contracts';
import { PopupRef } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeyboardHelperService } from '@shared/helper/keyboard-helper.service';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { isKey, keyCodes, KeyName } from '@local/ts-infra';
import { isEqual } from 'lodash';
import { NgScrollbar } from 'ngx-scrollbar';
import { SearchParamsService } from 'src/app/bar/services/search-params.service';
import { ScrollService } from '../../../results/services/scroll.service';
import { SortOption } from '../../../results/utils/sorts.utils';

export interface SortingFilterData {
  sortOptions: SortOption[];
}

@UntilDestroy()
@Component({
  selector: 'sorting-filter-popup',
  templateUrl: './sorting-filter-popup.component.html',
  styleUrls: ['./sorting-filter-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortingFilterPopupComponent implements OnInit, OnDestroy {
  private readonly itemHeight = 33;

  @HostBinding('style.height')
  getHeight() {
    return this.sortOptions.length * this.itemHeight + 'px';
  }

  @Output() onSortEvent: EventEmitter<Search.Sort> = new EventEmitter<Search.Sort>();
  sortOptions: SortOption[];
  @Input() currentSort: Search.Sort;

  // keyboard
  private keysHandlerId: string;
  selectedIndex = null;
  private keyboardHelperService: KeyboardHelperService;

  // scroll
  private scrollService: ScrollService<any>;
  @ViewChild(CdkVirtualScrollViewport) scrollViewport: CdkVirtualScrollViewport;
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;

  constructor(
    private ref: PopupRef<SortingFilterPopupComponent, SortingFilterData>,
    private searchParamsService: SearchParamsService,
    private keyboardService: KeyboardService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const { sortOptions } = this.ref.data;
    this.sortOptions = sortOptions;

    this.getActiveSort();
    this.setUpKeyboardHelperService();
    this.keyboardHelperService.onInit(this.selectedIndex, this.sortOptions, this.scrollService);
  }

  ngOnDestroy(): void {
    this.keyboardService.unregisterKeyHandler(this.keysHandlerId);
  }

  getActiveSort() {
    if (!this.currentSort) {
      const sort = this.searchParamsService.getSort();
      this.currentSort = sort || this.sortOptions[0].value;
    }
    this.sortOptions.forEach((elm, index) => {
      const selected = isEqual(elm.value, this.currentSort);
      if (selected) {
        this.selectedIndex = index;
      }
    });
  }

  onClickSort(sort: SortOption) {
    this.onSortEvent.emit(sort.value);
    this.ref.destroy();
  }

  setUpKeyboardHelperService() {
    this.keyboardHelperService = new KeyboardHelperService();
    this.keyboardHelperService.updateCdr.pipe(untilDestroyed(this)).subscribe(() => this.cdr.markForCheck());
    this.keyboardHelperService.updateSelectedIndex.pipe(untilDestroyed(this)).subscribe((index) => {
      this.selectedIndex = index;
      if (!this.sortOptions[this.selectedIndex]) return;
    });
    this.scrollService = new ScrollService(this.ngZone);
    this.registerKeyHandler();
  }

  private registerKeyHandler() {
    if (this.keysHandlerId) return;
    this.keysHandlerId = this.keyboardService.registerKeyHandler((keys, event) => {
      this.keyboardHelperService.handleArrows(keys, event);
      this.scrollService.scrollIfNeeded(this.selectedIndex, this.sortOptions);
      this.handleKeys(keys, event);
      event.stopPropagation();
    }, 9);
  }

  private handleKeys(eventKeys: Array<KeyName>, event: CustomKeyboardEvent): void {
    if (isKey(event, keyCodes.enter)) {
      this.onClickSort(this.sortOptions[this.selectedIndex]);
      event.stopPropagation();
    }
  }
}
