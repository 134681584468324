import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'times-button',
  templateUrl: './times-button.component.html',
  styleUrls: ['./times-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesButtonComponent {
  @Input() size: string = '14px';
  @Input() backgroundColor: string = 'var(--color-bg)';
  @Input() color: string = 'var(--color-text-tertiary)';

  @Input() opacity: string;

  @HostBinding('style') get style() {
    return {
      color: this.color,
      backgroundColor: this.backgroundColor,
      fontSize: this.size,
      width: this.size,
      height: this.size,
      opacity: this.opacity,
    };
  }
  constructor() {}
}
