<overlay class="oauth" *ngIf="show">
  <ng-container overlay-title>
    <div class="auth-loader" [ngClass]="theme === 'light' ? 'auth-loader-light' : 'auth-loader-dark'"></div>
    Hang tight!
  </ng-container>
  <ng-container overlay-content>
    We're linking you in.
    <br />
  </ng-container>
</overlay>
