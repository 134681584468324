import { Commands, Search } from '@local/client-contracts';
import { getExtensionByFileName, getIconByExtension } from '@local/ts-infra';
import { PopupRef, PopupService } from '@local/ui-infra';
import { ContextMenuComponent, ContextMenuData, ContextMenuItem, ContextMenuService } from '@shared/components';
import { EventInfo, EventsService } from '@shared/services';
import { stopEvent } from '@shared/utils/elements-util';
import { ReplaySubject, takeUntil } from 'rxjs';
import { BlobsService } from 'src/app/bar/services/blob.service';
import { ShowToasterService } from 'src/app/bar/services/show-toaster.service';
import tinymce from 'tinymce';
import { SearchResults } from '../../../results';
import {
  RenameAttachmentPopupComponent,
  RenameAttachmentPopupData,
} from '../../components/rename-attachment-popup/rename-attachment-popup.component';
import { CardEditorConstants } from '../../components/wiki-card-popup/card-editor/card-editor.constants';
import { WikiCardBlobService } from '../../services/wiki-card-blob.service';
import { convertFileSizeToBytes, getFileSize } from '@shared/utils';
import { WikiCardMenuActions } from './wiki-card-menu-actions.helper';
import { attachmentsRegex, validateFileSize } from './wiki-card-utils';
import { observable } from '@local/common';
import { WikiAttachmentStorageHandler } from './wiki-attachment-storage-handler';

export class WikiCardFileHelper {
  private readonly MAX_ATTACHMENT_FILE_SIZE_MB = 5;

  private destroy$ = new ReplaySubject<boolean>(1);
  private _saveEditor$ = new ReplaySubject<void>(1);
  private _contextMenuOpened: boolean;
  private contextMenuPopup: PopupRef<ContextMenuComponent, ContextMenuData>;
  private renameAttachmentPopup: PopupRef<RenameAttachmentPopupComponent, RenameAttachmentPopupData>;

  @observable
  get saveEditor$() {
    return this._saveEditor$.asObservable();
  }

  get contextMenuOpened(): boolean {
    return this._contextMenuOpened;
  }

  get renameAttachmentPopupOpen() {
    return this.renameAttachmentPopup;
  }

  constructor(
    private blobsService: BlobsService,
    private showToasterService: ShowToasterService,
    private contextMenuService: ContextMenuService,
    private popupService: PopupService,
    private eventsService: EventsService,
    private wikiCardBlobService: WikiCardBlobService,
    private wikiCardMenuActions: WikiCardMenuActions,
    private wikiAttachmentStorageHandler: WikiAttachmentStorageHandler
  ) {}

  uploadFile(cardId: string) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', '*');
    input.onchange = () => {
      const file: File = input.files[0];
      this.uploadAttachment(file, cardId);
    };
    input.click();
  }

  async uploadAttachment(file: File, cardId: string) {
    const { name, size, type } = file;
    if (!validateFileSize(size, this.MAX_ATTACHMENT_FILE_SIZE_MB, this.showToasterService)) {
      return;
    }
    const id = await this.wikiCardBlobService.createBlob(cardId, name, type);
    if (!id) {
      return;
    }
    this.blobsService.upload(file, id).catch(() => {
      this.wikiCardBlobService.failedToUpload(file.name, 'upload_attachment_failed', id);
    });
    const icon = getIconByExtension(getExtensionByFileName(name));
    const ext = getExtensionByFileName(name);
    const selectedNode = tinymce.activeEditor.selection.getNode();
    const wikiAttachment = this.wikiCardBlobService.createdAttachment(id, type, name, size, 'File');
    this.wikiAttachmentStorageHandler.addAttachment(cardId, wikiAttachment);
    if (selectedNode.textContent || selectedNode.nodeName.toLocaleLowerCase() !== 'p') {
      if (selectedNode.querySelector('video')) {
        selectedNode.insertAdjacentHTML('afterend', '<p><br></p>');
        tinymce.activeEditor.selection.setCursorLocation(selectedNode.nextElementSibling, 0);
        tinymce.activeEditor.focus();
      } else {
        tinymce.activeEditor.selection.select(selectedNode, true);
        tinymce.activeEditor.selection.collapse(false);
        tinymce.activeEditor.insertContent('<p><br></p>');
      }
    }
    this.insertAttachment(id, icon, name, size, ext);
  }

  private insertAttachment(id: string, icon: string, name: string, size: number, ext: string) {
    const insertAttachment = this.getAttachmentHtml(id, icon, name, size, ext);
    tinymce.activeEditor.execCommand('mceInsertContent', false, insertAttachment);
  }

  getAttachmentHtml(id: string, icon: string, name: string, size: number, ext: string) {
    return `<div class="${CardEditorConstants.CLASS_ATTACHMENT}" id="${id}" contenteditable="false" >
              ${this.getInnerAttachmentHtml(icon, name, size, ext)} 
            </div><p class="bogus-attachment"><br data-mce-bogus="1"></p>`;
  }

  getInnerAttachmentHtml(icon: string, name: string, size: number, ext: string) {
    return `<div class="details-attachment">
                <img class="${CardEditorConstants.CLASS_ICON_ATTACHMENT}" src="${icon}" />
                <span class="${CardEditorConstants.CLASS_NAME_ATTACHMENT}">${name}</span>
                <span  class="${CardEditorConstants.CLASS_SIZE_ATTACHMENT}">${getFileSize(size)}</span>
                <span style="display:none" class="${CardEditorConstants.CLASS_REAL_SIZE_ATTACHMENT}">${size}</span>
                <span style="display:none" class="${CardEditorConstants.CLASS_EXT_ATTACHMENT}">${ext}</span>
              </div>
              <div class="action-attachment">
              <span class="icon-delete-attachment">t</span>                                 
              <div class="context-menu-attachment">•••</div>
              </div>
            `;
  }

  retrieveAttachmentsToHtml(html: string) {
    // Create a temporary div element to parse the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    // Find all attachments within the parsed HTML
    const allAttachments = tempDiv.querySelectorAll(`.${CardEditorConstants.CLASS_ATTACHMENT}`);

    allAttachments.forEach((attach) => {
      if (!attach.innerHTML) {
        // to be able to be backward compatible
        const name = attach.getAttribute('name');
        const size = attach.getAttribute('size');
        const iconAttr = attach.getAttribute('icon');
        let ext = attach.getAttribute('ext');
        if (!ext) {
          ext = getExtensionByFileName(iconAttr ? `${name}.${iconAttr}` : name);
        }
        const icon = getIconByExtension(ext);
        attach.removeAttribute('ext');
        attach.removeAttribute('icon');
        attach.removeAttribute('size');
        attach.removeAttribute('name');
        attach.setAttribute('contenteditable', 'false');
        attach.innerHTML = this.getInnerAttachmentHtml(icon, name, Number(size), ext);
      }
    });

    return tempDiv.innerHTML;
  }

  extractAttachmentsFromEditor(html: string) {
    // Create a temporary div element to parse the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    // Find all attachments within the parsed HTML
    const allAttachments = tempDiv.querySelectorAll(`.${CardEditorConstants.CLASS_ATTACHMENT}`);

    allAttachments.forEach((attach) => {
      const ext = attach.querySelector(`.${CardEditorConstants.CLASS_EXT_ATTACHMENT}`);
      if (ext) {
        attach.setAttribute('ext', ext.textContent);
      } else {
        const image = attach.querySelector(`.${CardEditorConstants.CLASS_ICON_ATTACHMENT}`);
        let icon;
        if (!image || !image?.['src']?.length) {
          icon = null;
        } else {
          const spliter = image['src'].split('/');
          icon = spliter[spliter.length - 1].split('.')[0];
        }
        if (icon) {
          attach.setAttribute('icon', icon);
        }
      }

      const name = attach.querySelector(`.${CardEditorConstants.CLASS_NAME_ATTACHMENT}`);
      const real_size = attach.querySelector(`.${CardEditorConstants.CLASS_REAL_SIZE_ATTACHMENT}`);
      let actualSize;
      if (!real_size) {
        const size = attach.querySelector(`.${CardEditorConstants.CLASS_SIZE_ATTACHMENT}`);
        actualSize = !size.textContent ? 0 : convertFileSizeToBytes(size.textContent);
        actualSize = !actualSize ? 0 : actualSize;
      } else {
        actualSize = real_size.textContent;
      }
      attach.setAttribute('name', name.textContent);
      attach.setAttribute('size', actualSize);

      attach.removeAttribute('contenteditable');
      attach.innerHTML = '';
    });

    return tempDiv.innerHTML;
  }

  getAttachment(event: any, stopPropagationEvent = true) {
    const attachment = event.target.closest(`.${CardEditorConstants.CLASS_ATTACHMENT}`);
    if (!attachment) return;
    if (stopPropagationEvent) {
      stopEvent(event);
    }
    return attachment;
  }

  openAttachmentContextMenu(
    event: { x: number; y: number },
    attachment: any,
    collectionId: string,
    canEdit: boolean,
    locationTitle: string,
    editorPosition: { x: number; y: number },
    cardId: string
  ) {
    if (this.contextMenuPopup) {
      this.contextMenuPopup.destroy();
    }
    this._contextMenuOpened = true;
    attachment.classList.add(CardEditorConstants.CLASS_ACTIVE_ATTACHMENT);
    const items = this.buildContextMenuItems(attachment, collectionId, canEdit, editorPosition);
    this.contextMenuPopup = this.contextMenuService.open(
      event,
      {
        items,
        onInvoke: (item: ContextMenuItem) => this.handleContextMenuCommand(item, locationTitle, cardId),
        minWidthItem: 90,
      },
      { position: 'right' }
    );
    setTimeout(() => {
      tinymce.activeEditor.selection.getNode().setAttribute(CardEditorConstants.CLASS_TINYMCE_FOCUS_BORDER, '1');
    }, 0);
    this.contextMenuPopup.destroy$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      attachment.classList.remove(CardEditorConstants.CLASS_ACTIVE_ATTACHMENT);
      setTimeout(() => {
        tinymce.activeEditor.selection.getNode().removeAttribute(CardEditorConstants.CLASS_TINYMCE_FOCUS_BORDER);
      }, 0);
      this._contextMenuOpened = false;
      this._saveEditor$.next();
    });
    return this.contextMenuPopup;
  }

  buildContextMenuItems(
    attachment: any,
    collectionId: string,
    canEdit: boolean,
    editorPosition: { x: number; y: number }
  ): ContextMenuItem[] {
    const contextMenuItems: ContextMenuItem[] = [];
    contextMenuItems.push(
      {
        id: 'preview',
        text: 'Preview',
        data: { attachment, collectionId },
        command: { type: 'preview' } as Commands.Preview<Search.CollectionFileResultItem & SearchResults>,
        icon: { type: 'font-icon', value: 'icon-eye' },
      },
      {
        id: 'download',
        text: 'Download',
        data: { attachment },
        command: { type: 'download-url' } as Commands.DownloadUrl,
        icon: { type: 'font-icon', value: 'icon-download2' },
      }
    );
    if (canEdit) {
      contextMenuItems.push({
        id: 'rename',
        text: 'Rename',
        data: { attachment, editorPosition },
        command: { type: 'rename-tab' },
        icon: { type: 'font-icon', value: 'icon-register' },
      });
      contextMenuItems.push({
        id: 'delete',
        text: 'Delete',
        data: { attachment },
        command: { type: 'delete-tab' },
        icon: { type: 'font-icon', value: 'icon-delete' },
      });
    }
    return contextMenuItems;
  }

  private async handleContextMenuCommand(command: ContextMenuItem, locationTitle: string, cardId: string) {
    const attachment = command.data.attachment;
    const blobId = attachment.id;
    this.sendEvent('context_menu.open', locationTitle, { label: command.id });
    switch (command.id) {
      case 'preview': {
        const iconUrl = attachment.querySelector('img')?.src;
        this.wikiCardMenuActions.openPreview(attachment.id, iconUrl, command.data.collectionId);
        break;
      }
      case 'download':
        this.wikiCardMenuActions.startDownload(blobId);
        break;
      case 'rename':
        this.renameAttachment(attachment, command.data.editorPosition, cardId);
        break;
      case 'delete':
        attachment.remove();
        this._saveEditor$.next();
        break;
    }
    this.sendEvent('results.action', locationTitle, { label: command.id, target: 'mouse_click' });
  }

  sendEvent(key: string, locationTitle: string, info: Partial<EventInfo>): void {
    this.eventsService.event(key, {
      ...info,
      location: { title: locationTitle },
    });
  }

  private renameAttachment(attachment: any, editorPosition: { x: number; y: number }, cardId: string) {
    const attachmentName = attachment.querySelector(`.${CardEditorConstants.CLASS_NAME_ATTACHMENT}`);
    const { top, right, left } = attachmentName.getBoundingClientRect();
    const position = { top: top + 25 + editorPosition.y, right, left: left + editorPosition.x };
    if (this.renameAttachmentPopup) {
      this.renameAttachmentPopup.destroy();
    }
    setTimeout(() => {
      attachment.classList.add(CardEditorConstants.CLASS_ACTIVE_ATTACHMENT);
    }, 0);
    this.renameAttachmentPopup = this.popupService.open(
      position,
      RenameAttachmentPopupComponent,
      { text: attachmentName?.textContent },
      { hasBackdrop: false }
    );
    this.renameAttachmentPopup.destroy$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      attachment.classList.remove(CardEditorConstants.CLASS_ACTIVE_ATTACHMENT);
      this._saveEditor$.next();
    });
    this.renameAttachmentPopup.compInstance.onRename.pipe(takeUntil(this.destroy$)).subscribe(async (newName) => {
      await this.onRenameAttachment(newName, attachment, cardId);
    });
  }

  private async onRenameAttachment(newName: string, attachment: any, cardId: string) {
    const attachmentName = attachment.querySelector(`.${CardEditorConstants.CLASS_NAME_ATTACHMENT}`);
    this.renameAttachmentPopup.destroy();
    if (!newName) return;
    const blobId = attachment.id;
    const attachmentObj = this.wikiAttachmentStorageHandler.getAttachment(cardId, blobId);
    if (!attachmentObj) {
      return;
    }
    const currentName = attachmentObj.name;
    const extension = `.${getExtensionByFileName(currentName)}`;
    const newNameWithExtension = newName.endsWith(extension) ? newName : newName + extension;
    this.updateAttachmentName(blobId, newNameWithExtension);
    this.wikiAttachmentStorageHandler.updateAttachmentName(cardId, blobId, newName);
    attachmentName.innerText = newName;
    this._saveEditor$.next();
  }

  private async updateAttachmentName(id: string, newName: string) {
    return this.blobsService.update(id, newName);
  }

  addFailedClassToAttachment(html: string, attachmentIds: string[], changeParent?: boolean): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    attachmentIds.forEach((id) => {
      const elementToMark = doc.getElementById(id);
      if (elementToMark) {
        if (changeParent) {
          elementToMark.parentElement.classList.add(CardEditorConstants.CLASS_FAILED_ATTACHMENT);
        } else {
          elementToMark.classList.add(CardEditorConstants.CLASS_FAILED_ATTACHMENT);
        }
      }
    });

    return doc.body.innerHTML;
  }

  removeClassFromAttachment(html: string, classToRemoved: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const elementsWithClass = doc.querySelectorAll(`.${classToRemoved}`);
    elementsWithClass.forEach((element) => {
      element.classList.remove(classToRemoved);
    });

    return doc.body.innerHTML;
  }

  destroy() {
    this.destroy$.next(true);
    this._saveEditor$.complete();
    this.destroy$.complete();
    this.renameAttachmentPopup?.destroy();
  }

  getAttachmentsIds(html: string): string[] {
    if (!html) return;
    const ids = html.match(attachmentsRegex.FIND_ID_RXJS) || [];
    return ids.map((id) => id.match(attachmentsRegex.EXTRACT_ID_RXJS)?.[1]);
  }
}
