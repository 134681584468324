import { Injectable } from '@angular/core';
import { Walkthrough } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';

@Injectable({
  providedIn: 'root',
})
export class WalkthroughRpcInvoker implements Walkthrough.Service {
  @invoker
  isUsingCache(): Promise<boolean> {
    return;
  }

  @invoker
  useCache(use: boolean): Promise<void> {
    return;
  }

  @invoker
  getTour(id: string): Promise<Walkthrough.Tour> {
    return;
  }
  @invoker
  saveProgress(id: string, tour: Walkthrough.Tour): Promise<void> {
    return;
  }
  @invoker
  skipAll(use: boolean): Promise<void> {
    return;
  }
}
