import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DateValue, getStartAndEndDateFormat, stringToDateValue } from '@local/common-web';
import { MultiSelectModel, MultiSelectOptionsUpdatedEvent, MultiSelectSelectedEvent } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TelemetryService } from '@shared/services';
import { DatePickerService } from '@shared/services/date-picker.service';
import { KeyboardService } from '@shared/services/keyboard.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { DisplaySearchFilterValue, TimeDropdown } from '../models';
import { MultiSelectFilterBaseComponent } from '../multi-select-filter-base.component';

@UntilDestroy()
@Component({
  selector: 'time-multi-select',
  templateUrl: './time-multi-select.component.html',
  styleUrls: ['./time-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeMultiSelectComponent extends MultiSelectFilterBaseComponent<TimeDropdown> implements OnInit, OnDestroy {
  readonly limitShowSelectedLabels = 2;
  private readonly CUSTOM_PATTERN = /^(range|after|before)/i;
  private _customValueSelected: DateValue;
  private mode: 'Dropdown' | 'Calendar' = 'Dropdown';
  customStartDisplay: string;
  customEndDisplay: string;
  selectedToDisplay: DisplaySearchFilterValue[] = [];
  filterTooltip: string;

  @Input() filterTitle: string;
  @Input() isFullDetailLine: boolean;
  @Input() showFilterTooltip: boolean;
  @Output() closeFilter: EventEmitter<any> = new EventEmitter();

  @Input() set model(val: TimeDropdown) {
    super.model = val;
    const hasCustom = this.selectedItems?.find((itemValue) => itemValue?.value?.toLowerCase()?.startsWith('custom'));
    if (!hasCustom) {
      this.customValueSelected = null;
    }
  }
  get model() {
    return super.model;
  }

  get options(): MultiSelectModel {
    return {
      optionLabel: 'value',
      options: this.model?.values,
      open: this.open,
      fetcher: this.optionsFetcher,
      version: this.version || 0,
    };
  }

  get customValueSelected() {
    return this._customValueSelected;
  }

  set customValueSelected(val: DateValue) {
    if (!val) {
      this._customValueSelected = null;
      this.filterTooltip = '';
      if (this.showFilterTooltip) {
        const selected = this.model.values.find((item) => item.selected);
        this.filterTooltip = selected ? `${this.model?.title}: ${selected?.title}` : '';
      }
    } else {
      if (!val.start && !val.end) {
        return;
      }
      this._customValueSelected = val;
      const { startDisplay, endDisplay } = getStartAndEndDateFormat(val);
      this.customEndDisplay = endDisplay;
      this.customStartDisplay = startDisplay;
      if (this.customStartDisplay && this.customEndDisplay) {
        this.filterTooltip = `${this.model?.title}: ${this.customStartDisplay} to  ${this.customEndDisplay}`;
      } else {
        this.filterTooltip = `${this.model?.title}: ${this.customStartDisplay ? 'After' : 'Before'}: ${
          this.customStartDisplay || this.customEndDisplay
        }`;
      }
      this.cdr.markForCheck();
    }
  }

  get titlesToDisplay() {
    const selectedItems = this.model?.values?.filter((v) => v.selected) || [];
    return selectedItems.slice(0, this.limitShowSelectedLabels);
  }

  get multiSelectIcon() {
    if (this.allowMultiSelectIcon) {
      return this.allowMultiSelectIcon;
    }
    return this.model.icon;
  }

  get displayCustomDateItemTemplate() {
    return this.customValueSelected || this.model.values.find((option) => option.type === 'custom-date');
  }

  get displayCustomDateTemplate() {
    return !!this.customValueSelected && this.model.values.find((option) => option.type === 'custom-date');
  }

  constructor(
    public cdr: ChangeDetectorRef,
    protected telemetryService: TelemetryService,
    public ref: ElementRef,
    protected keyboardService: KeyboardService,
    protected hubService: HubService,
    public datePickerService: DatePickerService
  ) {
    super(cdr, telemetryService, ref, keyboardService, hubService);
  }

  ngOnInit(): void {
    if (this.model.values?.length) {
      const filterValue = this.model.values.find((item) => item?.type === 'custom-date');
      this.customValueSelected = filterValue ? stringToDateValue(filterValue.value) : null;
      if (!this.customValueSelected && this.showFilterTooltip) {
        const selected = this.model.values.find((item) => item.selected);
        this.filterTooltip = selected ? `${this.model?.title}: ${selected.title}` : '';
      }
    }
  }

  ngOnDestroy(): void {
    this.customValueSelected = null;
    this.datePickerService.valueSelected = null;
    this.cdr.markForCheck();
    super.ngOnDestroy();
  }

  optionsUpdated($event: MultiSelectOptionsUpdatedEvent) {
    const options = $event.options as DisplaySearchFilterValue[];
    const filterValue = options?.find((item) => item?.type === 'custom-date');
    this.customValueSelected = filterValue ? stringToDateValue(filterValue.value) : null;
    if (!$event.filterInput) {
      const selectedItems = options?.filter((v) => v.selected) || [];
      this.selectedToDisplay = selectedItems.slice(0, this.limitShowSelectedLabels);
    }
    super.optionsUpdated($event);
  }

  onChange($event: MultiSelectSelectedEvent) {
    const { start, end } = this.customValueSelected || {};
    const customValueSelected =
      start || end
        ? {
            type: this.customValueSelected?.type,
            start: !!start ? new Date(start) : start,
            end: !!end ? new Date(end) : end,
          }
        : null;
    const value = $event.itemValue?.value?.toLowerCase();
    const customSelected = value?.startsWith('custom');
    if (customSelected) {
      const element = this.uMultiSelect.multiSelect.el.nativeElement;
      this.datePickerService.onCustomSelected(
        element,
        this.filterTitle || this.model.title,
        customValueSelected,
        this.model.pickerTypes,
        this.model.minDate,
        this.model.maxDate,
        this.model.defaultCustomTimeFilter
      );
      this.subscribeToDatePickerChanges();
      this.mode = 'Calendar';
    } else if (!this.CUSTOM_PATTERN.test(value)) {
      this.customValueSelected = null;
      if (this.showFilterTooltip && value?.startsWith('last')) {
        this.filterTooltip = `${this.model?.title}: ${value}`;
      }
    }
    if (this.mode === 'Calendar') {
      this.hubService.changeFocusState(false);
      this.subscribeToDatePickerClose();
    } else {
      if (customSelected) {
        this.filterCustomDate();
      } else {
        super.onChange($event);
      }
    }
  }

  onPanel(isPanelOpen: boolean) {
    const reset = !isPanelOpen && (this.mode === 'Dropdown' || !this.datePickerService.isVisible);
    super.onPanel(isPanelOpen, reset);
  }

  getMoreLabelsTooltip(): string {
    const items = this.selectedItems.slice(this.limitShowSelectedLabels).map((elm) => elm.value);
    return items.join(', ');
  }

  subscribeToDatePickerChanges() {
    this.datePickerService.valueSelected$.pipe(untilDestroyed(this)).subscribe((val) => {
      if (val) {
        this.uMultiSelect?.initTemplates();
        this.uMultiSelect.cdr.markForCheck();
        this.customValueSelected = { ...val };
        this.cdr.markForCheck();
      }
    });
  }

  subscribeToDatePickerClose() {
    this.datePickerService.close$.pipe(untilDestroyed(this)).subscribe(async () => {
      let selectedItems: string[] = [];
      if (!this.customValueSelected?.start && !this.customValueSelected?.end) {
        this.customValueSelected = null;
      } else {
        const stringValue = this.getFilterCustomValue(this.customValueSelected);
        selectedItems = [stringValue];
        this.cdr.markForCheck();
        this.selectedItems = selectedItems.map((v) => this.datePickerService.getFilter(this.model.name, v, this.model.icon));
        if (this.selectedItems.length) {
          this.filterCustomDate();
        }
      }
      this.closeFilter.emit(this.model.name);
      this.mode = 'Dropdown';
      this.cdr.markForCheck();
    });
  }

  private getFilterCustomValue(dateValue: DateValue) {
    const filterValue = this.datePickerService.buildSelectedTimeEventValue(dateValue);
    const value = `${dateValue.type}:${filterValue}`;
    return value;
  }

  filterCustomDate() {
    const value = this.getFilterCustomValue(this.customValueSelected);
    this.onFilterChange.emit({
      name: this.model.name,
      changes: {
        values: [
          {
            filterName: this.model.name,
            id: `${this.model.name}-${value}`,
            value,
          },
        ],
      },
      current: {
        values: [
          {
            filterName: this.model.name,
            id: `${this.model.name}-${value}`,
            value,
          },
        ],
      },
      action: 'Set',
    });
  }
}
