import { Stats } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class StatsRpcInvoker implements Stats.Service {
  @invoker
  get updated$(): Observable<Stats.Stat> {
    return;
  }

  @invoker
  getViewsById(ids: Set<string>): Promise<Stats.StatVal[]> {
    return;
  }

  @invoker
  upsert(stats: Stats.Stat): Promise<void> {
    return;
  }

  @invoker
  delete(id: string): Promise<void> {
    return;
  }

  @invoker
  refresh(): Promise<void> {
    return;
  }
}
