import { ComponentRef, Injectable } from '@angular/core';
import { Config } from '@environments/config';
import { Commands, Downloads, Resources, Search, Style } from '@local/client-contracts';
import { PopupRef, PopupService } from '@local/ui-infra';
import { LogService } from '@shared/services';
import { DownloadsService } from '@shared/services/downloads.service';
import { getDownloadIcon } from '@shared/utils/set-icon.util';
import { filter, ReplaySubject, Subject, Subscription, takeUntil } from 'rxjs';
import { PopupData, PreviewService } from 'src/app/bar/services/preview.service';
import { InvokeCommand } from '../../../results/models/invoke-command.model';
import { ResultContextMenuItem, Select, TelemetryTrigger } from '../../../results/models/results-types';
import { FilePreviewPopupComponent } from '../components/file-preview-popup/file-preview-popup.component';
import { FilePreviewBase, ResultPreviewData } from '../file-preview-types';

export type FileCommandModel = { command: InvokeCommand; model: Search.ResultResourceItem };
@Injectable({
  providedIn: 'root',
})
export class FilePreviewService {
  private readonly previewType = 'files';
  private clearCacheTimeoutRef: ReturnType<typeof setTimeout>;
  private previewComponentsCache: {
    srcId: string;
    srcUrl: string;
    previewComponent: ComponentRef<FilePreviewBase>;
    subscriptions: Subscription[];
  }[] = [];
  private popupRef: PopupRef<FilePreviewPopupComponent, Search.ResultResourceItem>;
  contextMenuInvoke$ = new Subject<{ item: ResultContextMenuItem; trigger: TelemetryTrigger }>();
  executeCommand$: Subject<FileCommandModel> = new Subject();
  preview$ = new ReplaySubject<ResultPreviewData>(1);

  constructor(
    private downloadsService: DownloadsService,
    private logService: LogService,
    private popupService: PopupService,
    private previewService: PreviewService
  ) {
    this.startClearCacheTimeout();
    this.logService.scope('FilePreviewContainerService');

    this.previewService.openPreviewPopup$.pipe(filter((i) => i.previewType === this.previewType)).subscribe(({ data }) => {
      this.openFilePopUp(data);
    });

    this.previewService.clearPreviewPopup$.pipe(filter((previewType) => previewType === this.previewType)).subscribe((res) => {
      if (this.popupRef) {
        this.popupRef.destroy();
        this.popupRef = null;
      }
    });
  }

  get isFilePopupOpen() {
    return this.popupRef;
  }

  openFilePopUp(data: PopupData): PopupRef<FilePreviewPopupComponent, Search.ResultResourceItem> {
    if (this.popupRef) {
      this.popupRef.destroy();
    }

    const item = data.item as Search.ResultResourceItem;

    this.popupRef = this.popupService.open('center', FilePreviewPopupComponent, item, {
      position: 'center',
      backdropStyle: 'blur-1',
    });

    this.popupRef.destroy$.subscribe(() => {
      this.popupRef = null;
      this.previewService.onDestroyPreview(item, this.previewType, data?.preventClearQueryParams);
    });
    return this.popupRef;
  }

  private startClearCacheTimeout() {
    this.clearCacheTimeoutRef = setTimeout(() => {
      this.clearCache();
      this.logService.logger.info(
        `File preview cache cleared, after ${Config.preview.files.cacheClearTimeoutMinutes} minutes of inactivity.`
      );
    }, Config.preview.files.cacheClearTimeoutMinutes * 1000 * 60);
  }

  public refreshCacheTimeout() {
    clearTimeout(this.clearCacheTimeoutRef);
    this.clearCacheTimeoutRef = null;
    this.startClearCacheTimeout();
  }

  updatePreviewsCache(srcId: string, srcUrl: string, previewComponent: ComponentRef<FilePreviewBase>, subscriptions: Subscription[]) {
    if (this.previewComponentsCache.length === Config.preview.files.cacheLimit) {
      const lastPreview = this.previewComponentsCache.pop();
      lastPreview.subscriptions.forEach((sub) => sub?.unsubscribe());
      lastPreview.previewComponent.destroy();
    }
    this.previewComponentsCache.unshift({ srcId, srcUrl, previewComponent, subscriptions });
  }

  removeFromCache(srcId: string) {
    this.previewComponentsCache = this.previewComponentsCache.filter((c) => c.srcId !== srcId);
  }

  getPreviewFromCache(srcId: string) {
    return this.previewComponentsCache.find((p) => p.srcId === srcId);
  }

  changePreviewVisibility(component: ComponentRef<FilePreviewBase>, show: boolean) {
    if (!component?.instance) return;
    component.instance.show = show;
  }

  getContextMenuInvoke(itemId: string, preview: ResultPreviewData) {
    return this.contextMenuInvoke$.pipe(filter(() => itemId === preview.model.id));
  }

  async getPreviewUrl(model: Search.ResultResourceItem): Promise<{ model: Search.ResultResourceItem; srcUrl?: string; error?: any }> {
    if (!model?.view?.title?.onDrag || model['srcUrl']) {
      return { model };
    }
    const icon = getDownloadIcon(model.view.icon);
    let url: string;
    try {
      const requestUrl = (<Commands.DownloadUrl>model.view.title.onDrag).url;
      const request: Downloads.DownloadRequest = this.getDownloadRequest(requestUrl, icon, model.resource);
      url = await this.downloadsService.getDownloadUrl(request, true);
      return { model, srcUrl: url };
    } catch (e) {
      throw { model, error: e };
    }
  }

  private getDownloadRequest(url: string, icon: Style.Icon, resource: Resources.Resource): Downloads.DownloadRequest {
    if (resource) {
      return {
        url,
        type: 'Resource',
        resource,
        icon,
      } as Downloads.ResourceDownloadRequest;
    }
    return {
      url,
      type: 'Url',
      icon,
    } as Downloads.UrlDownloadRequest;
  }

  clearCache() {
    this.previewComponentsCache = [];
  }
}
