import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UGalleriaModel } from './u-galleria.model';
import { PrimeTemplate } from 'primeng/api';
import { UGalleriaSize, CAROUSEL_BREAKPOINTS } from './u-galleria-breakpoints';
import { Galleria } from 'primeng/galleria';

@Component({
  selector: 'u-galleria',
  templateUrl: './u-galleria.component.html',
  styleUrls: ['./u-galleria.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UGalleriaComponent implements AfterViewInit {
  itemTemplate: TemplateRef<any>;
  currentPage: UGalleriaModel;

  @ViewChild(Galleria) private galleria: Galleria;

  @ContentChildren(PrimeTemplate) templates: QueryList<any>;

  @Input() model: UGalleriaModel[] = [];
  @Input() showIndicators = false;
  @Input() showNavigators = false;
  @Input() circular = true;
  @Input() autoPlay: boolean;
  @Input() autoplayInterval: number;
  @Input() styles: any = {};
  @Input() size: UGalleriaSize;

  @Output() onClick = new EventEmitter<UGalleriaModel>();

  @HostBinding('style.width')
  get width(): string {
    if (this.size) {
      return CAROUSEL_BREAKPOINTS[this.size].width + 'px';
    }
    return '100%';
  }

  @HostBinding('style.height')
  get height(): string {
    if (this.size) {
      return CAROUSEL_BREAKPOINTS[this.size].height + 'px';
    }
  }

  get carouselRef(): Galleria {
    return this.galleria;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;
      }
    });
    setTimeout(() => {
      this.currentPage = this.galleria?.value?.[0];
    }, 0);
    this.cdr.markForCheck();
  }

  pageClick(event: MouseEvent) {
    if (event.target['tagName'] === 'IMG') {
      this.onClick.emit(this.currentPage);
    }
  }

  activeIndexChange(index: number) {
    this.currentPage = this.galleria.value[index];
    this.cdr.markForCheck();
  }
}
