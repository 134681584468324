import { Injectable } from '@angular/core';
import { Accounts, GoLinks, Search, Workspace } from '@local/client-contracts';
import { ValueStorage } from '@local/common';
import { SettingsFilter } from '@local/common-web';
import { PopupRef, PopupService } from '@local/ui-infra';
import { DisplaySearchFilterValue, Filter } from '@shared/components/filters/models';
import { EventsService, LogService, ServicesRpcService } from '@shared/services';
import { AccountsService } from '@shared/services/accounts.service';
import { RouterService } from '@shared/services/router.service';
import { SessionStorageService } from '@shared/services/session-storage.service';
import { SessionService } from '@shared/services/session.service';
import { Logger } from '@unleash-tech/js-logger';
import { isEmpty, isEqual, pickBy } from 'lodash';
import { map, Observable, ReplaySubject, take, takeUntil } from 'rxjs';
import { AppPopupComponent, AppPopupData } from '../components/app-popup/app-popup.component';
import { GoLinksPopupComponent, GoLinkViewModel } from '../views/results-views/go-links/go-links-popup/go-links-popup.component';
import { HubService } from './hub.service';
import { GoLinksIndexerRpcInvoker } from './invokers/go-links-indexer-rpc-invoker';
import { GoLinksRpcInvoker } from './invokers/go-links-rpc-invoker';
import { ResultsService } from './results.service';
import { WorkspacesService } from './workspaces.service';

export interface GoLinksPopupModel {
  item?: Search.GoLinkResultItem;
  url?: string;
  name?: string;
}

export const hasVariable = (url: string) => {
  if (!url) return;
  return url.includes('{*}');
};

@Injectable()
export class GoLinksService {
  private popupRef: PopupRef<GoLinksPopupComponent, GoLinksPopupModel>;
  private warningPopupRef: PopupRef<AppPopupComponent, AppPopupData>;
  workspaceAccounts: Accounts.WorkspaceAccount[];
  isOpenedPopup: boolean;
  private workspace: Workspace.Workspace;
  private service: GoLinks.Service;
  private indexerService: GoLinks.Indexer;
  private logger: Logger;
  private visitStorage: ValueStorage<Record<string, GoLinks.GetVisitBatchItem>>;
  private _newGoLink$: ReplaySubject<string> = new ReplaySubject();
  private isOwnerOrAdmin: boolean;

  get newGoLink$() {
    return this._newGoLink$.asObservable();
  }

  set newGoLink(value: string) {
    this._newGoLink$.next(value);
  }

  private static readonly popupData: AppPopupData = {
    message: 'Closing the window will discard <br> changes.',
    showButtons: true,
    content: {
      secondaryButton: 'Cancel',
      primaryButton: 'Discard',
    },
  };

  constructor(
    private popupService: PopupService,
    private accountsService: AccountsService,
    services: ServicesRpcService,
    private resultsService: ResultsService,
    private sessionStorageService: SessionStorageService,
    private hubService: HubService,
    private eventsService: EventsService,
    private routerService: RouterService,
    logService: LogService,
    private workspaceService: WorkspacesService
  ) {
    this.logger = logService.scope('GoLinksService');
    this.service = services.invokeWith(GoLinksRpcInvoker, 'golinks');
    this.indexerService = services.invokeWith(GoLinksIndexerRpcInvoker, 'golinksindexer');
    this.workspaceService.current$.subscribe((workspace) => {
      this.workspace = workspace;
    });
    this.initWorkspaceAccounts();
    this.visitStorage = this.sessionStorageService
      .getStore('local', 'account')
      .entry<Record<string, GoLinks.GetVisitBatchItem>>('goLinksVisits');
    this.workspaceService.ownerOrAdmin$.subscribe((s) => (this.isOwnerOrAdmin = s));
  }

  get allAvailableLinks$(): Observable<GoLinks.Item[]> {
    return this.service.all$.pipe(map((a) => Object.values(a?.links || {}).filter((link) => this.canViewGoLink(link))));
  }

  get accountId(): string {
    return this.workspace?.accountId;
  }

  private initWorkspaceAccounts() {
    this.accountsService.all$.subscribe((value) => {
      this.workspaceAccounts = value;
    });
  }

  get isCreateGoLinkPopupOpen() {
    return this.popupRef;
  }

  isDefaultGoLinkIcon(icon: string): boolean {
    if (!icon) {
      return;
    }
    return icon.endsWith('go-link-default-icon.svg') || icon.endsWith('go-link-deafult-icon.svg');
  }

  openPopup(goLink?: Search.GoLinkResultItem, url?: string, name?: string) {
    if (this.popupRef) {
      this.closePopup();
    }
    this.eventsService.event('go_links.screen', {
      name: goLink ? 'edit_go_links_modal' : 'create_go_links_modal',
      location: {
        title: this.hubService.currentLocation,
      },
    });
    this.isOpenedPopup = true;
    this.popupRef = this.popupService.open<GoLinksPopupComponent, GoLinksPopupModel>(
      'center',
      GoLinksPopupComponent,
      <GoLinksPopupModel>{
        item: goLink,
        url,
        name,
      },
      {
        position: 'center',
        backdropStyle: 'blur-2',
      }
    );

    this.popupRef.close$.pipe(take(1)).subscribe(() => {
      this.openWarningPopup();
    });
  }

  closePopup() {
    this.routerService.removeQueryParam(['golinks', 'name']);
    this.popupRef.destroy();
    this.popupRef = null;
    this.isOpenedPopup = false;
  }

  openWarningPopup(data: AppPopupData = GoLinksService.popupData, deleted: boolean = false, id?: string) {
    if (this.warningPopupRef) {
      this.warningPopupRef.destroy();
    }
    this.warningPopupRef = this.popupService.open<AppPopupComponent, AppPopupData>('center', AppPopupComponent, data, {
      position: 'center',
    });
    this.warningPopupRef.compInstance.primaryButton.pipe(takeUntil(this.warningPopupRef.destroy$)).subscribe(() => {
      this.warningPopupRef.destroy();
      this.warningPopupRef = null;
      if (deleted) {
        this.service.delete(id).then(() => {
          this.resultsService.updateRefreshCount();
        });
      } else {
        this.closePopup();
      }
      this.sendImpressionEvent(deleted, deleted ? 'delete' : 'discard');
    });
    this.warningPopupRef.compInstance.secondaryButton.pipe(takeUntil(this.warningPopupRef.destroy$)).subscribe(() => {
      this.warningPopupRef.destroy();
      this.warningPopupRef = null;

      this.sendImpressionEvent(deleted, 'cancel');
    });
  }

  private sendImpressionEvent(deleted: boolean, target: string) {
    this.eventsService.event('go_links.action', {
      name: deleted ? 'delete_go_links_prompt' : 'unsaved_go_links_warning',
      target,
      location: {
        title: this.hubService.currentLocation,
      },
    });
  }

  async create(item: GoLinkViewModel): Promise<boolean> {
    const goLink: GoLinks.CreateRequest = {
      description: item.description,
      url: item.url,
      name: item.name,
      tags: item.tags?.map((tag) => tag.name),
      listed: item.listed,
      iconLink: item.iconLink,
    };
    try {
      await this.service.create(goLink);
      this.resultsService.updateRefreshCount();
      return true;
    } catch (error) {
      this.logger.error('golinks error create', error);
      return false;
    }
  }

  getById(name: string): Promise<GoLinks.GetByIdResponse> {
    return this.service.get(name);
  }

  isEqual(originalItem: GoLinks.UpdateRequest, updateItem: GoLinks.UpdateRequest): Partial<GoLinks.UpdateRequest> {
    return pickBy(updateItem, (v, k) => !isEqual(originalItem[k], v));
  }

  async update(originalItem: GoLinks.UpdateRequest, updateItem: GoLinks.UpdateRequest): Promise<boolean> {
    const updated = this.isEqual(originalItem, updateItem);
    if (isEmpty(updated)) {
      this.closePopup();
      return;
    }
    const goLinkUpdated: GoLinks.UpdateRequest = {
      id: originalItem.id,
      lastModifiedTime: originalItem.lastModifiedTime,
      ...updated,
    };

    try {
      await this.service.update(goLinkUpdated);
      this.closePopup();
      this.resultsService.updateRefreshCount();
      return true;
    } catch (error) {
      this.logger.error('golinks error update', error);
      return false;
    }
  }

  delete(id: string): void {
    const popupData: AppPopupData = {
      message: 'You will not be able to recover it',
      showButtons: true,
      content: {
        title: 'Delete this link?',
        secondaryButton: 'Cancel',
        primaryButton: 'Delete',
      },
    };
    this.openWarningPopup(popupData, true, id);
  }

  async getVisited(request: GoLinks.GetVisitsBatchRequest, useCache: boolean = false): Promise<Record<string, GoLinks.GetVisitBatchItem>> {
    if (useCache) {
      const cacheData: Record<string, GoLinks.GetVisitBatchItem> = (await this.visitStorage.get()) || {};
      return Object.fromEntries(
        Object.entries(cacheData)
          .filter(([key]) => request.linkIds.includes(key))
          .map(([key, value]) => {
            value.entries = Object.values(value.entries);
            return [key, value];
          })
      );
    }
    const results = await this.service.getBatchVisits(request);

    const cacheData: Record<string, GoLinks.GetVisitBatchItem> = {};

    results?.entries.forEach((visitItem) => (cacheData[visitItem.linkId] = visitItem));

    this.visitStorage.set(cacheData);

    return Object.fromEntries(Object.entries(cacheData).filter(([key]) => request.linkIds.includes(key)));
  }

  getTagList(): Promise<GoLinks.TagItem[]> {
    return this.indexerService.getTags();
  }

  createVisit(request: GoLinks.CreateVisitRequest): Promise<void> {
    return this.service.createVisit(request);
  }

  validateField(key: 'url' | 'name', value: string): Promise<GoLinks.SearchItem[]> {
    return this.indexerService.validateField(key, value);
  }

  canEditOrDelete(item: GoLinks.SearchItem): boolean {
    return this.isOwnerOrAdmin || this.workspace?.accountId === item.createdBy;
  }

  canViewGoLink(item: GoLinks.SearchItem | GoLinks.Item): boolean {
    return item.listed || this.isOwnerOrAdmin || this.workspace?.accountId === item.createdBy;
  }

  async refresh(): Promise<void> {
    this.service.refresh();
  }

  async getPostFilters(filtersSetting: SettingsFilter[], postFilters: Search.ResponseFilters): Promise<Filter[]> {
    const allOptions = (await this.indexerService.search({})).suggestedFilters; // to do tomer move this to the every search response instead of makeing another search
    const filters: Filter[] = [];

    for (const filter of filtersSetting) {
      switch (filter.name) {
        case 'createdBy':
          filters.push({
            type: 'post',
            name: filter.name,
            title: filter.title,
            icon: { type: 'font-icon', value: 'icon-user-circle' },
            picker: 'multi-select',
            viewDetails: {
              isTwoLine: true,
              showClearAll: true,
            },
            values: (allOptions.createdBy || []).map(
              (value) =>
                <DisplaySearchFilterValue>{
                  id: value.id,
                  value: value.id,
                  title: value.title,
                  subtitle: value.subtitle || '',
                  icon: value.icon,
                  filterName: 'createdBy',
                }
            ),
            disabled: false,
          });
          break;

        case 'tags':
          filters.push({
            type: 'post',
            name: filter.name,
            title: filter.title,
            icon: { type: 'font-icon', value: 'icon-tag-label' },
            picker: 'multi-select',
            values: (allOptions?.tags || []).map(
              (value) =>
                <DisplaySearchFilterValue>{
                  id: value.id,
                  value: value.id,
                  title: value.title,
                  subtitle: value.subtitle || '',
                  icon: value.icon,
                  filterName: 'tags',
                }
            ),
            disabled: false,
            viewDetails: {
              showClearAll: true,
            },
          }) || [];
          break;

        case 'favorite':
          const availableFavorites = allOptions?.favorite || [];
          if (!availableFavorites.length) {
            continue;
          }
          filters.push({
            type: 'post',
            name: filter.name,
            title: filter.title,
            icon: { type: 'font-icon', value: 'icon-star1' },
            picker: 'toggle',
            values: availableFavorites?.map(
              (value) =>
                <DisplaySearchFilterValue>{
                  id: value.id,
                  value: value.id,
                  title: value.title,
                  subtitle: value.subtitle || '',
                  icon: value.icon,
                  filterName: 'favorite',
                }
            ),
            disabled: false,
            viewDetails: {
              showSplitLine: true,
            },
          });
          break;
        case 'unlisted':
          const availableUnlisted = allOptions?.unlisted || [];
          if (availableUnlisted.length <= 1) {
            continue;
          }
          filters.push({
            type: 'post',
            name: filter.name,
            title: filter.title,
            picker: 'toggle',
            values: availableUnlisted?.map(
              (value) =>
                <DisplaySearchFilterValue>{
                  id: value.id,
                  value: value.id,
                  title: value.title,
                  subtitle: value.subtitle || '',
                  icon: value.icon,
                  filterName: 'unlisted',
                }
            ),
            disabled: false,
            viewDetails: {
              showSplitLine: true,
            },
          } as Filter);
          break;
        default:
          break;
      }
    }

    return filters;
  }
}
