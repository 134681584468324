import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ElementSize } from '../../types/element-size';

export type UButtonType = 'primary' | 'primary-danger' | 'secondary' | 'secondary-danger' | 'secondary-light' | 'tertiary' | 'link';

@Component({
  selector: 'u-button',
  templateUrl: './u-button.component.html',
  styleUrls: ['./u-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UButtonComponent implements AfterViewInit {
  @Input() disabled = false;
  @Input() label: string = null;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() type: UButtonType = 'primary';
  @Input() styles: any = {};
  @Input() withIcon = false;
  @Input() onlyIcon = false;
  @ViewChild('button') button: ElementRef;
  @Input() fontIcon: string = null;
  @Input() iconPos: 'left' | 'right' = 'left';
  @Input() iconStyles: any = {};
  @Input() elementSize: ElementSize;
  @Input() permanentDarkMode: boolean;
  @Input() loading = false;
  @Input() customPrimary = false;

  private _active = false;
  @Input() set active(v: boolean) {
    if (this._active && !v) {
      this.button.nativeElement.blur();
    }
    this._active = v;
  }
  get active() {
    return this._active;
  }

  ngAfterViewInit(): void {
    if (!this.label) {
      const a = this.button.nativeElement.getElementsByClassName('p-button-label');
      a[0]?.remove();
    }
    if (this.iconStyles) {
      const icon = this.button.nativeElement.firstChild || {};
      icon.style = icon.style || {};
      for (const [key, value] of Object.entries(this.iconStyles)) {
        icon.style[key] = value;
      }
    }
  }
}
