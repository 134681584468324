import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { Search } from '@local/client-contracts';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EventInfo } from '@shared/services';
import { EXTRA_SMALL_WIDTH_PREVIEW, SMALL_WIDTH_PREVIEW } from 'src/app/bar/services/preview.service';
import { WikiCardPopupComponent } from 'src/app/bar/views/collections-page/components/wiki-card-popup/wiki-card-popup.component';
import { PreviewComponent } from '../../../model/preview-component';
import { PreviewMode, PreviewWidthBreakpoint } from '../../../model/preview-mode';
import { fade } from '../../../people-preview/fade.animations';
import { WikiCardPopupModel } from 'src/app/bar/views/collections-page/models/wiki-card-model';
import { isWikiDraft } from 'src/app/bar/views/results';
@UntilDestroy()
@Component({
  selector: 'card-preview',
  templateUrl: './card-preview.component.html',
  styleUrls: ['./card-preview.component.scss'],
  animations: [fade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardPreviewComponent implements PreviewComponent {
  @Input() size: PreviewMode;
  @Input() telemetrySearch: Partial<EventInfo>;
  @ViewChild(WikiCardPopupComponent) cardComponent: WikiCardPopupComponent;

  private _previewWidth: number;
  private _previewHeight: number;
  private _model: Search.WikiCardCollectionResultItem;

  cardModel: WikiCardPopupModel;
  previewWidthBreakpoint: PreviewWidthBreakpoint;
  @Input() set model(val: Search.WikiCardCollectionResultItem) {
    if (val.id !== this._model?.id || !this.cardModel) {
      this._model = val;
      this.createCardModel();
      this.cdr.markForCheck();
    }
  }

  get model(): Search.WikiCardCollectionResultItem {
    return this._model;
  }

  @Input() set previewWidth(val: number) {
    if (!val) return;
    this._previewWidth = val;
    this.previewWidthBreakpoint = val < EXTRA_SMALL_WIDTH_PREVIEW ? 'extra-small' : val < SMALL_WIDTH_PREVIEW ? 'small' : 'medium';
    this.cdr.markForCheck();
  }

  get previewWidth() {
    return this._previewWidth;
  }

  @Input() set previewHeight(val: number) {
    if (!val) return;
    this._previewHeight = val;
    this.cdr.markForCheck();
  }

  get previewHeight() {
    return this._previewHeight;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  createCardModel() {
    this.cardModel = {
      pageMode: 'inline',
      cardId: this.model?.['resource']?.externalId || this.model?.id,
      viewMode: isWikiDraft(this.model) ? 'draft' : 'published',
    };
  }
}
