import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class NavTreeNodeIdResolver {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string {
    return getNodeIdFromRoute(route);
  }
}

export const getNodeIdFromRoute = (route: ActivatedRouteSnapshot): string => {
  if (!route?.params) {
    return;
  }

  if (route.params['nodeId']) {
    return route.params['nodeId'];
  }
  if (route.params) {
    let id = route.routeConfig.path;
    Object.keys(route.params).forEach((param) => {
      id = id?.replace(`:${param}`, route.params[param]);
    });
    return id;
  }
  const id = route.params['nodeId'];

  if (!id) {
    console.info(`No node id detected in route params ${id}, returning route path as fallback`);
  }
  return id ?? route.routeConfig.path;
};
