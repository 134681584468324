import { Observable } from 'rxjs';
import { InternetStatus } from '@local/client-contracts';
import { Injectable } from '@angular/core';
import { ServicesRpcService } from './rpc.service';
import { map } from 'rxjs/operators';
import { NativeServicesRpcService } from '.';

@Injectable({
  providedIn: 'root',
})
export class InternetService {
  status: InternetStatus;
  isOnline$: Observable<boolean>;
  constructor(private services: ServicesRpcService, private nativeServices: NativeServicesRpcService) {
    this.status$.subscribe((s) => (this.status = s));
    this.isOnline$ = this.status$.pipe(map((status) => status === 'online'));
  }

  get status$(): Observable<InternetStatus> {
    return (this.nativeServices || this.services).observable('internet.status$');
  }
}
