import { Wiki } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class WikisRpcInvoker implements Wiki.WikisService {
  @invoker
  getWikiTree(request: Wiki.WikiSearchRequest): Promise<Wiki.TreeNode> {
    return;
  }

  @invoker
  deleteWiki(req: Wiki.DeleteWikiRequest): Promise<void> {
    return;
  }

  @invoker
  deleteWikiItem(req: Wiki.DeleteItemRequest): Promise<void> {
    return;
  }

  @invoker
  deleteMany(req: Wiki.DeleteManyItemsRequest): Promise<void> {
    return;
  }

  @invoker
  moveItem(req: Wiki.MoveItemRequest): Promise<void> {
    return;
  }

  @invoker
  createFolder(req: Wiki.CreateFolderRequest): Promise<Wiki.CreateFolderResponse> {
    return;
  }

  @invoker
  createCard(req: Wiki.UpsertCardRequest): Promise<Wiki.CreateCardResponse> {
    return;
  }

  @invoker
  getFoldersByWikiId(wikiId: string): Promise<Wiki.FolderStructure[]> {
    return;
  }

  @invoker
  getWikisWithCardsCount(): Promise<Wiki.WikiStructure[]> {
    return;
  }

  @invoker
  getCountCardsWiki(wikiId: string): Promise<number> {
    return;
  }
}
