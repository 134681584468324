import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CardSetSelection, CardSetSelectionItem } from '../../../../shared/components/card-set-selection/card-set.model';

export type ResourceAccessType = 'Discovery' | 'Permissions';
export type ResourceAccessTypeCardSelectionItem = Omit<CardSetSelectionItem, 'id'> & { id: ResourceAccessType };
@UntilDestroy()
@Component({
  selector: 'choose-resource-access-type',
  templateUrl: './choose-resource-access-type.component.html',
  styleUrls: ['./choose-resource-access-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseResourceAccessTypeComponent {
  @Output() type = new EventEmitter<ResourceAccessType>();

  @Input() set appName(name: string) {
    this.initCards(name);
  }

  cardsSet: CardSetSelection;
  cards: ResourceAccessTypeCardSelectionItem[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  private initCards(appName: string) {
    this.cards = [
      {
        id: 'Discovery',
        title: 'Discovery',
        content: 'People you shared the link with<br />will be able to find all<br />resources based on your<br />permissions.',
      },
      {
        id: 'Permissions',
        title: 'Permission based',
        content: `People you shared the link<br />with will be able to find<br />resources based on their<br />original permissions in<br />${appName}`,
      },
    ];
    this.cardsSet = {
      cards: this.cards,
    };
    this.cdr.markForCheck();
  }

  cardSelected(type: ResourceAccessType) {
    this.type.emit(type);
  }
}
