import { Injectable } from '@angular/core';
import { WebSearch } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';

@Injectable({
  providedIn: 'root',
})
export class WebSearchRpcInvoker implements WebSearch.Service {
  constructor() {}

  @invoker
  search(request: WebSearch.SearchRequest): Promise<WebSearch.Result> {
    return;
  }
}
