import { OAuth } from '@local/client-contracts';
import { isLocalEnv } from '@shared/utils';
import { handler } from '@unleash-tech/js-rpc';

interface AuthrorizeResult {
  url: string;
}
interface MessageEnvelope<T> {
  type: string;
  message: T;
}

export class OAuthCompletionRpcHandler implements OAuth.CompletionService {
  private static initialized = false;

  constructor(private completion: OAuth.CompletionService) {}

  private async onWindowMessage(that: OAuthCompletionRpcHandler, event: MessageEvent<MessageEnvelope<AuthrorizeResult>>) {
    const current = window.location.origin;
    const sender = event.origin;
    if (current !== sender && !current.endsWith(`.${new URL(sender).hostname}`) && !isLocalEnv()) {
      return;
    }

    const msg = event.data;
    if (!msg) return;
    if (msg.type != 'authorize-result') return;

    if (msg.message?.url) {
      await that.complete(msg.message.url);
    }
  }

  public init() {
    if (!OAuthCompletionRpcHandler.initialized) {
      const that = this;
      window.addEventListener('message', (e) => that.onWindowMessage(that, e));
      OAuthCompletionRpcHandler.initialized = true;
    }
  }

  @handler
  complete(url: string): Promise<void> {
    return this.completion.complete(url);
  }
}
