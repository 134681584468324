<div [class.large-size]="fontSize === 'large'">
  <div *ngIf="showTempTitle" #resultTitle class="title-temp" (click)="onTextClick($event)">
    <span class="highlight-title">{{ text }}</span>
  </div>
  <u-input
    *ngIf="!showTempTitle"
    #resultInputTitle
    class="no-state inline-title-input"
    [placeholder]="inputPlaceholder || text"
    [model]="tempInputModel"
    (onKeydown)="onKeyDown($event)"
    (onEnter)="blurUInput()"
    [inputFocus]="true"
    [maxlength]="maxlength"
    (onBlur)="blurUInput()"
    (onChange)="onChangeInput($event)"
  ></u-input>
</div>
