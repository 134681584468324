export const collectionContent = {
  created: 'created',
  updated: 'updated',
  collections: 'Collections',
  wikis: 'Wikis',
  emptyCollectionMessage1: 'Collections allow you to group resources and links from',
  emptyCollectionMessage2: 'across your apps to a specific place. ',
  staticCollectionMessageRow1: 'Quickly organize information',
  staticCollectionMessageRow2: 'by adding resources, links and',
  staticCollectionMessageRow3: 'files to a collection.',
  liveCollectionMessageRow1: 'Turn a search query',
  liveCollectionMessageRow2: 'into a live collection',
  liveCollectionMessageRow3: 'for real-time updates.',
  createCollection: 'Hit C to get started',
  noResults: 'No results',
  untitled: 'Untitled',
  descriptionPlaceholder: 'Add description (optional)',
  errorToastMessage: 'Oops.. something went wrong. Please try again!',
  addToStaticCollection: 'Add',
  deleteStaticCollection: 'Remove',
  staticCollectionEmptyState: 'Add items by dragging and dropping, pasting, or uploading them',
  addLinkInputPlaceholder: 'Enter URL here..',
  addLinkInputError: 'Invalid URL. Please try again.',
  addToCollectionPopupMessage: 'Select the Collection to add the resource to',
  addToCollectionPopupMessageWiki: 'Select the Collection to add the card to',
  addToCollection: 'Add to Collection',
  moveToCollection: 'Move to Collection',
  addToCollectionPlaceholder: 'Select',
  missingFilters: 'The items in this collection are search results from the owner account, and may be different than yours',
  reset: 'Reset',
  save: 'Save',
  saveAsNew: 'Save as new',
  saveAndVerify: 'Save and Verify',
  saveForEveryone: 'Save for everyone',
  add: 'Add',
  addCard: 'Add Card',
  createCard: 'Create Card',
  addItem: 'Add Item',
  sizeUploadLimit: 'The file exceeds the maximum allowed size of 5MB',
  pin: 'Pin',
  unpin: 'Unpin',
  selectCollection: 'Please select a collection',
  missingTitle: 'Please add title',
  missingContent: 'Please add content',
  pinCollectionOwnerMessage: 'Pinning this collection will display it on the homepages of all users who have access to it.',
  unpinCollectionOwnerMessage: 'Unpinning this collection will remove it from the homepages of all users who have access to it.',
  multipleFieldsMissing: 'Please fill out all required fields to save this card',
  moveToCollectionWarningMessage:
    'When a wiki card is moved to another collection, it will inherit the verification policy granted to the destination collection.',
  newCuratedCollectionPopupDescription: 'Use to organize links and resources by topic',
  newLiveCollectionPopupDescription: 'Turn a search query into a live collection for real-time updates',
  newWikiCollectionPopupDescription: 'Organize knowledge into topics',
  newCardPopupDescription: 'Capture knowledge as cards',
  newFolderPopupDescription: 'Organize cards into folders',
  learnMore: 'Learn More',
  newCollection: 'New Collection',
  createNew: 'Create New',
  deleteCollection: 'Delete',
};
