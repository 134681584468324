<div class="content" [class.content-launcher]="isLauncher">
  <h1 class="title" [class.title-launcher]="isLauncher" [innerHTML]="title"></h1>
  <p
    *ngIf="subtitle"
    class="subtitle"
    [ngClass]="{ 'subtitle-launcher': isLauncher, 'primary-subtitle': showAdditionalSubtitle, 'primary-subtitle-launcher': isLauncher }"
  >
    {{ subtitle }}
  </p>
  <ng-container *ngIf="showAdditionalSubtitle">
    <ng-content select="[subtitle]"></ng-content>
  </ng-container>
  <u-button *ngIf="buttonText" [label]="buttonText" type="primary" [styles]="{ width: '100%' }" (click)="actionClick.emit()"></u-button>
</div>
<u-icon
  *ngIf="svgUrl"
  class="image"
  [class.no-animation]="focus$ | async"
  [class]="animation"
  [inSvg]="svgUrl"
  [model]="{ type: 'img' }"
  [class.image-launcher]="isLauncher"
></u-icon>

<u-icon *ngIf="icon" [model]="{ type: 'img', value: icon }"></u-icon>
