import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, WritableSignal, signal } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LogService } from '@shared/services';
import { Breadcrumb, BreadcrumbsService } from '@shared/services/breadcrumbs.service';
import { RouterService } from '@shared/services/router.service';
import { removeFirstBackslash } from '@local/ts-infra';
import { Logger } from '@unleash-tech/js-logger';
import { combineLatest, Observable, startWith, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { getWidthBreakpointScreen } from '@shared/utils';
import { HubService } from 'src/app/bar/services/hub.service';

@UntilDestroy()
@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  private readonly MAX_CHARACTER_LARGE_SCREEN = 36;
  private readonly MAX_CHARACTER = 24;
  private readonly MAX_OPEN_ITEMS = 3;
  private popupMode: boolean;
  canGoBack$: Observable<boolean>;
  canGoForward$: Observable<boolean>;
  back$: Observable<any>;
  forward$: Observable<any>;
  items: Breadcrumb[];
  showPath: WritableSignal<boolean> = signal(true);
  showGhost: WritableSignal<boolean> = signal(false);
  private history: Record<string, Breadcrumb[]> = {};
  private readonly destroy$ = new Subject();
  protected logger: Logger;

  @Input() showArrows: boolean;
  @Input() showBreadcrumbsItems: boolean;
  @Input() styles: { [key: string]: string };

  @Input() set popupModeItems(value: Breadcrumb[]) {
    this.popupMode = true;
    this.transformsItems(value);
  }

  get isLargeScreen() {
    return ['large', 'extra-large'].includes(getWidthBreakpointScreen());
  }

  constructor(
    public breadcrumbsService: BreadcrumbsService,
    private routingService: RouterService,
    private logService: LogService,
    private cdr: ChangeDetectorRef,
    private hubService: HubService
  ) {
    this.logger = this.logService.scope('BreadcrumbsComponent');
    this.breadcrumbsService.items$.pipe().subscribe((items) => {
      if (this.popupMode) {
        return;
      }
      this.transformsItems(items);
      cdr.markForCheck();
    });
  }

  ngOnInit(): void {
    this.initObservables();
    this.breadcrumbsService.show$.pipe(untilDestroyed(this)).subscribe((val) => {
      this.showPath.set(val);
    });
    this.breadcrumbsService.showGhost$.pipe(untilDestroyed(this)).subscribe((val) => {
      this.showGhost.set(val);
    });
    this.routingService.active$.pipe(untilDestroyed(this)).subscribe((route) => {
      if (!route.startsWith('c/')) {
        this.showPath.set(true);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  private initObservables() {
    this.canGoBack$ = combineLatest([this.routingService.canGoBack$, this.breadcrumbsService.navigationEnabled$]).pipe(
      takeUntil(this.destroy$),
      map(([cgb, ne]) => cgb && ne),
      distinctUntilChanged()
    );

    this.canGoForward$ = combineLatest([this.routingService.canGoForward$, this.breadcrumbsService.navigationEnabled$]).pipe(
      takeUntil(this.destroy$),
      map(([cgf, ne]) => cgf && ne),
      distinctUntilChanged()
    );

    const buildTitle = (b: Breadcrumb[]) => `<span class="no-wrap">${b?.map(({ title }) => title).join(' / ')} </span>`;

    const extractPath = (url: string): string => {
      try {
        if (!url) return;
        const path = url.startsWith('http') ? new URL(url).pathname : new URL(`http://duumy.com/${url}`).pathname;
        return removeFirstBackslash(path);
      } catch (error) {
        this.logger.error('Invalid URL', url);
      }
    };

    combineLatest([this.routingService.currentHistory$.pipe(startWith(null)), this.breadcrumbsService.items$.pipe(startWith(null))])
      .pipe(
        takeUntil(this.destroy$),
        filter(([current, items]) => !!current && !!items)
      )
      .subscribe(([current, items]) => {
        if (current) {
          this.history[extractPath(this.routingService.current)] = items;
        }
      });

    this.back$ = this.routingService.back$.pipe(
      takeUntil(this.destroy$),
      map((back) => extractPath(back)),
      map((back) => this.history[back] && buildTitle(this.history[back])),
      distinctUntilChanged()
    );

    this.forward$ = this.routingService.forward$.pipe(
      takeUntil(this.destroy$),
      map((forward) => extractPath(forward)),
      map((forward) => this.history[forward] && buildTitle(this.history[forward])),
      distinctUntilChanged()
    );
  }

  transformsItems(items: Breadcrumb[]) {
    if (items?.length > this.MAX_OPEN_ITEMS) {
      const cutOffItemsTooltip = this.getTooltip(items);
      const placeholderItem = { title: '...', path: '', notClickable: true, tooltip: cutOffItemsTooltip };
      this.items = [items[0], placeholderItem, ...items.slice(-2)];
    } else {
      this.items = items;
    }
    this.cdr.markForCheck();
  }

  private getTooltip(items: Breadcrumb[]) {
    const cutOffItems = items.slice(1, items.length - 2);
    return cutOffItems.map((item) => item.title).join(' / ');
  }

  getTitleTooltip(title: string) {
    return title?.length > (this.isLargeScreen ? this.MAX_CHARACTER_LARGE_SCREEN : this.MAX_CHARACTER) ? title : null;
  }

  async onNavClick(direction: 'back' | 'forward'): Promise<void> {
    if (direction === 'back') {
      await this.routingService.back();
      return;
    }
    await this.routingService.forward();
  }

  navigate(path: string): void {
    const url = path.endsWith('/') ? path.slice(0, -1) : path;
    if (this.popupMode) {
      this.hubService.openUrl(url);
    } else {
      this.routingService.navigateByUrl(url);
    }
  }
  goBack(): void {
    window.history.go(-1);
  }
}
