import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTitleComponent } from './input-title/input-title.component';
import { UInputModule } from '../components/u-input/u-input.module';

const comps = [InputTitleComponent];

@NgModule({
  declarations: comps,
  imports: [CommonModule, UInputModule],
  exports: comps,
})
export class InfraComponentsSharedModule {}
