import { Injectable } from '@angular/core';
import { MemorySearch, Search } from '@local/client-contracts';
import { ServicesRpcService } from '.';
import { MemorySearchRpcInvoker } from './invokers/memory-search-rpc-invoker';
import { SessionStorageService } from './session-storage.service';
import { SessionService } from './session.service';
import { SimpleTextTokenizer } from '@local/common';

@Injectable({
  providedIn: 'root',
})
export class MemorySearchService implements MemorySearch.Service {
  private service: MemorySearch.Service;
  private tokenizer: SimpleTextTokenizer;

  constructor(services: ServicesRpcService, session: SessionService, sessionStorage: SessionStorageService) {
    this.service = services.invokeWith(MemorySearchRpcInvoker, 'memorysearch');
    this.tokenizer = new SimpleTextTokenizer(session, sessionStorage);
  }

  tokenize(s: string): string[] {
    return this.tokenizer.tokenize(s);
  }

  search(request: MemorySearch.Request): Promise<MemorySearch.Response> {
    return this.service.search(request);
  }

  rank(queryTokens: string[], items: MemorySearch.Item[], sorting: Search.Sort): Promise<MemorySearch.Item[]> {
    return this.service.rank(queryTokens, items, sorting);
  }
}
