import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Preferences } from '@local/client-contracts';
import { currentTheme, getTheme } from '@shared/utils/theme.util';

@Component({
  selector: 'new-link-save',
  templateUrl: './new-link-save.component.html',
  styleUrls: ['./new-link-save.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewLinkSaveComponent {
  @Input() show: boolean;
  theme: Preferences.Theme = getTheme(currentTheme());
}
