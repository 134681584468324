import { Injectable } from '@angular/core';
import { Stats, Wiki } from '@local/client-contracts';
import { LogService, ServicesRpcService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';
import { WikisRpcInvoker } from '../invokers/wikis-rpc-invoker';
import { TreeNode } from 'primeng/api';
import { WikiCardBuilderService } from './wiki-cards-builder.service';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { CollectionsService } from '../collections.service';
import { ShowToasterService } from '../show-toaster.service';
import { ToasterData } from '../../components/toaster/toaster-data';
import { observable } from '@local/common';
import { WikiCardCollectionResultItem } from '../search/client/wiki-collection-items';

@Injectable()
export class WikisService {
  readonly FOLDER_PREFIX = '_';
  readonly FOLDER_LIMIT = 3;
  private service: Wiki.WikisService;
  private logger: Logger;
  private _createdCard$ = new Subject<string>();

  @observable
  get createdCard$(): Observable<string> {
    return this._createdCard$;
  }

  constructor(
    logger: LogService,
    services: ServicesRpcService,
    private cardBuilderService: WikiCardBuilderService,
    private collectionsService: CollectionsService,
    private showToasterService: ShowToasterService
  ) {
    this.logger = logger.scope('WikisService');
    this.service = services.invokeWith(WikisRpcInvoker, 'wikis');
    this.collectionsService.deletedWiki$.subscribe(async (request) => {
      await this.deleteWiki(request);
    });
  }

  async getWikiTree(request: Wiki.WikiSearchRequest): Promise<Wiki.TreeNode> {
    return this.service.getWikiTree(request);
  }

  async getFoldersByWikiId(wikiId: string): Promise<Wiki.FolderStructure[]> {
    return this.service.getFoldersByWikiId(wikiId);
  }

  async getCountCardsWiki(wikiId: string): Promise<number> {
    return this.service.getCountCardsWiki(wikiId);
  }

  async getWikisWithCardsCount(): Promise<Wiki.WikiStructure[]> {
    return this.service.getWikisWithCardsCount();
  }

  async getViewWikiTree(wikiTree: Wiki.TreeNode, searchView?: boolean, stateView?: boolean): Promise<TreeNode> {
    if (!wikiTree.children?.length) {
      return wikiTree;
    }
    let index = -1;
    const wiki = (await firstValueFrom(this.collectionsService.all$))?.find((w) => w.id === wikiTree.id);
    const updatedChildren: TreeNode[] = [];

    const cardsInTree = this.getFlattenTree(wikiTree.children)?.filter((node) => node?.type === 'card');
    const statDictionary = await this.cardBuilderService.createStatDictionary(cardsInTree);

    for (const childNode of wikiTree.children) {
      const { lastIndex, node } = await this.convertChildToView(
        childNode,
        wiki as Wiki.WikiCollection,
        statDictionary,
        index + 1,
        searchView,
        stateView
      );
      index = lastIndex; // Increment index after processing each child
      if (node) {
        updatedChildren.push(node);
      }
    }
    return { data: { ...wikiTree }, children: updatedChildren, type: 'wiki', expanded: true, draggable: false };
  }

  private async convertChildToView(
    child: Wiki.TreeNode,
    wiki: Wiki.WikiCollection,
    statDictionary: { [id: string]: Stats.StatVal },
    index = 0,
    searchView?: boolean,
    stateView?: boolean
  ): Promise<{ lastIndex: number; node: TreeNode }> {
    if (!child.id) {
      return { lastIndex: index, node: null };
    }

    if (child.type === 'card') {
      const node = await this.buildCardView(child, wiki, statDictionary?.[child.id], index, searchView, stateView);
      return { lastIndex: index, node };
    }

    if (!child.children) {
      const node = this.buildFolderView(child, index);
      return { lastIndex: index, node };
    }

    let currentIndex = index;
    const updatedChildren: TreeNode[] = [];
    for (const childNode of child.children) {
      const { lastIndex, node } = await this.convertChildToView(childNode, wiki, statDictionary, currentIndex + 1);
      currentIndex = lastIndex;
      updatedChildren.push(node);
    }

    const node = this.buildFolderView(child, index, updatedChildren);
    return { lastIndex: currentIndex, node };
  }

  private buildFolderView(folder: Wiki.TreeNode, index: number, children: TreeNode[] = []): TreeNode {
    return {
      data: {
        id: folder?.id,
        type: 'folder',
        itemsCount: folder?.itemsCount,
        countSubLevels: folder?.countSubLevels,
        title: folder?.data?.title,
        path: folder?.path,
        resultIndex: index,
      },
      expanded: folder.expanded,
      children,
      key: folder?.id,
    };
  }

  private async buildCardView(
    node: Wiki.TreeNode,
    wiki: Wiki.WikiCollection,
    stat: Stats.StatVal,
    index: number,
    searchView?: boolean,
    stateView?: boolean
  ): Promise<TreeNode> {
    const card = node.data as Wiki.Card;
    const isUnpublishedCard = card.draft && !card.publishedTime;
    let view: WikiCardCollectionResultItem;
    if (isUnpublishedCard) {
      view = await this.cardBuilderService.buildDraftResultView({ ...card.draft, cardId: card.id }, true, true);
    } else {
      view = await this.cardBuilderService.buildCardResultView(card, wiki, stat, true, searchView, stateView);
    }
    return {
      data: {
        ...view,
        path: node.path,
        source: isUnpublishedCard ? 'wiki-drafts' : 'wiki-collection-items',
        type: 'result',
        resultIndex: index,
      },
      leaf: true,
      droppable: false,
      label: node?.data?.title,
      key: card.id,
    };
  }

  getFlattenTree(tree: TreeNode[]): TreeNode[] {
    return tree?.flatMap((node) => [node, ...(node.children?.length ? this.getFlattenTree(node.children) : [])]);
  }

  async deleteWiki(req: Wiki.DeleteWikiRequest): Promise<void> {
    return this.service.deleteWiki(req);
  }

  async deleteMany(req: Wiki.DeleteManyItemsRequest): Promise<void> {
    return this.service.deleteMany(req);
  }

  async deleteWikiItem(req: Wiki.DeleteItemRequest) {
    await this.service.deleteWikiItem(req);
  }

  async moveItem(req: Wiki.MoveItemRequest): Promise<void> {
    return this.service.moveItem(req);
  }

  async createFolder(req: Wiki.CreateFolderRequest): Promise<Wiki.CreateFolderResponse> {
    return this.service.createFolder(req);
  }

  async createCard(req: Wiki.UpsertCardRequest): Promise<Wiki.CreateCardResponse> {
    const res = await this.service.createCard(req);
    this._createdCard$.next(res.id);
    return res;
  }

  showToaster(toasterData: ToasterData) {
    this.showToasterService.showToaster(toasterData);
  }

  validateLimitFolder(current, newParent): boolean {
    return current?.type !== 'folder' || (newParent?.path.length || 0) + current?.countSubLevels < this.FOLDER_LIMIT;
  }
}
