<u-multi-select
  [ngClass]="{ disabled: currentModel.disabled }"
  [styles]="{ width: 'fit-content', minWidth: MIN_WIDTH_MULTI_SELECT }"
  [model]="options"
  [noCheckbox]="true"
  [enableBack]="true"
  [enableWriteValue]="false"
  [oneValue]="true"
  [displayLabel]="'title'"
  [placeholder]="currentModel.title"
  [displayBackIcon]="currentModel.viewDetails?.showBackIcon"
  [filterMatchMode]="filterMatchMode"
  [appendTo]="appendTo"
  [separateSelected]="separateSelected"
  [clearSearchAfterSelect]="clearSearchAfterSelect"
  [sortBy]="currentModel.viewDetails?.sortBy"
  [filterPlaceholder]="filterPlaceholder"
  [virtualScroll]="virtualScroll && (currentModel.values.length > 5 || !currentModel.values.length)"
  [showClearAll]="currentModel.viewDetails?.showClearAll"
  [autoSort]="autoSort"
  [maxCharacters]="MAX_CHARACTERS"
  [showItemTooltip]="showItemTooltip"
  [showHasValues]="currentModel.viewDetails?.showHasValues"
  [filterDisabled]="filterDisabled"
  [readonly]="currentModel.readonly"
  [wideWidth]="currentModel.viewDetails?.wideWidth"
  (onSelectedChange)="onChange($event)"
  (onBack)="onBackFilter()"
  (onPanelHide)="onPanel(false)"
  (onPanelShow)="onPanel(true)"
  (onClearAllEvent)="onClearAllEvent()"
>
  <ng-container *ngIf="currentModel.viewDetails?.isFullDetailLine">
    <ng-template let-item pTemplate="item">
      <multi-select-value
        [item]="item"
        [showIndicatorInList]="currentModel.viewDetails.showIndicatorInList"
        [showItemIcon]="currentModel.viewDetails?.showItemIcon"
        [showItemLabel]="currentModel.viewDetails?.showItemLabel"
        [showRemoveIcon]="currentModel.viewDetails?.showRemoveIcon"
        [showItemIconLabel]="currentModel.viewDetails?.showItemIconLabel"
        [maxCharacters]="MAX_CHARACTERS"
        [iconLabelOnHover]="item?.childFilter?.values?.length > 1 && !item.disabled ? OPEN_NESTED_FILTER_ICON : null"
      ></multi-select-value>
    </ng-template>
  </ng-container>

  <ng-template pTemplate="selectedItems">
    <ng-container *ngIf="selectedValuesToDisplay; else regularView">
      <div
        class="selected-items-container"
        [pTooltip]="selectedValuesToDisplay.tooltip"
        tooltipPosition="top"
        tooltipStyleClass="u-tooltip"
      >
        <span>{{ selectedValuesToDisplay.firstValueFilter }}:</span>
        <u-icon
          *ngIf="selectedValuesToDisplay.firstValueIcon"
          class="selected-icon"
          [styles]="{ fontSize: '14px', width: '14px' }"
          [model]="selectedValuesToDisplay.firstValueIcon"
        ></u-icon>
        <span>{{ selectedValuesToDisplay.firstValueTitle }}</span>
        <span class="nested-values-titles">{{ selectedValuesToDisplay.nestedValuesTitles }}</span>
      </div>
    </ng-container>
    <ng-template #regularView>
      <div class="selected-items-container">
        <span>{{ currentModel.title }}</span>
      </div>
    </ng-template>
  </ng-template>
</u-multi-select>
