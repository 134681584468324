import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UTextareaComponent } from '../u-textarea/u-textarea.component';
@Component({
  selector: 'u-textarea-interactive',
  templateUrl: './u-textarea-interactive.component.html',
  styleUrls: ['./u-textarea-interactive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UTextareaInteractiveComponent {
  @Input() rows = 1;
  @Input() cols = 1;
  @Input() placeholder = '';
  @Input() styles: any = {};
  @Input() autoResize = true;
  @Input() autofocus = true;
  @Input() disabled = false;
  @Input() model: string;
  @Input() ignoreEnter: boolean;
  @Input() readonly = false;
  @Input() disableAutoResize = true;
  @Input() maxLength: number;
  @Input() setMaxLength: boolean;
  @Input() inlineExtraIcon: string;
  @Input() inlineExtraIconTooltip: string;
  @Input() inlineExtraIconDisabled: boolean;
  @Input() minHeight = 24;

  @Input() extraIconStyles = {
    color: 'var(--primary-A100)',
    width: '22px',
    height: '22px',
    fontSize: '14px',
  };
  @Input() leftSideContent: any;
  @Input() footerTemplate: any;
  @Input() maxRowLimit = 5;
  @Input() maxWords: number;

  @ViewChild(UTextareaComponent) textAreaComp: UTextareaComponent;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFocus: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onInlineExtraIconClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleValidationResult: EventEmitter<boolean> = new EventEmitter<boolean>();

  isFocus = false;

  constructor(private cdr: ChangeDetectorRef) {}

  onChangeEvent($event) {
    this.onChange.emit($event);
  }

  onFocusEvent($event) {
    this.isFocus = true;
    this.cdr.markForCheck();
    this.onFocus.emit($event);
  }
  onBlurEvent($event) {
    this.isFocus = false;
    this.cdr.markForCheck();
    this.onBlur.emit($event);
  }

  onEnterEvent($event) {
    this.onEnter.emit($event);
  }
  onInlineExtraIconClickEvent($event) {
    this.onInlineExtraIconClick.emit({ target: $event, value: this.model });
  }

  handleMaxContentLengthEvent($event) {
    this.handleValidationResult.emit($event);
  }

  clearInput() {
    this.textAreaComp.clearInput();
  }
  setFocus() {
    this.textAreaComp.setFocus();
  }
}
