import { Route } from '@angular/router';
import { Constants } from '@local/common';
import { AssistantErrorComponent } from '@shared/components/assistant-error/assistant-error.component';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';
import { CHAT_PAGE_PATH } from '../utils/constants';
import { AnalyticsComponent } from '../views/analytics/analytics.component';
import { AssistantIncontextComponent } from '../views/assistant-incontext/components/assistant-incontext/assistant-incontext.component';
import { AssistantComponent } from '../views/assistant-page/components/assistant/assistant.component';
import { AssistantsGuideComponent } from '../views/assistant-page/components/assistants-guide/assistants-guide.component';
import { AssistantsComponent } from '../views/assistant-page/components/assistants/assistants.component';
import { AssistantResolver } from '../views/assistant-page/services/assistant.resolver';
import { UpNextComponent } from '../views/calendar-page/up-next/up-next.component';
import { CollectionViewComponent } from '../views/collections-page/components/collection-view/collection-view.component';
import { CollectionsContainerComponent } from '../views/collections-page/components/collections-container/collections-container.component';
import { WikiCardPopupComponent } from '../views/collections-page/components/wiki-card-popup/wiki-card-popup.component';
import { CollectionResolver } from '../views/collections-page/services/collection.resolver';
import { HomePageComponent } from '../views/home-page/home-page.component';
import { AppItemComponent, AppsComponent, HubComponent } from '../views/hub';
import { NewLinkComponent } from '../views/hub/links/components/new-link/new-link.component';
import { PreviewHubComponent } from '../views/preview/preview-hub/preview-hub.component';
import { GoLinkRedirectComponent } from '../views/results-views/go-links/go-links-redirector.component';
import { QuestionnaireComponent } from '../views/results-views/questionnaire/questionnaire.component';
import { ResultsComponent } from '../views/results-views/results/results.component';
import { ErrorPageComponent } from '../views/special-views/error/error-page.component';
import { WebSearchComponent } from '../views/web-search/web-search.component';
import { ErrorGuard } from './error.route-guard';
import { AnalyticsGuard } from './guards/analytics.guard';
import { CanActivateCollectionsGuard } from './guards/can-activate-collection.guard';
import { CanActivateGoLinksGuard } from './guards/can-activate-go-link.guard';
import { CanActivateWikiGuard } from './guards/can-activate-wiki.guard';
import { CanDeactivateCollectionGuard } from './guards/can-deactivate-collection.guard';
import { HomeGuard } from './guards/home.guard';
import { NavTreeNodeIdResolver } from './nav-tree-node-id.resolver';
import { NavTreeNodeTitleResolver } from './nav-tree-node-title.resolver';
import { navTreeNodeMatcher } from './nav-tree-node.matcher';
import { NavTreeNodeResolver } from './nav-tree-node.resolver';
import { RouteData } from './route-data';
import { WebSearchResolver } from './web-search.resolver';
import { CanActivateChatGuard } from './guards/can-activate-chat.guard';
import { ChatPageComponent } from '../views/chat-page/components/chat-page/chat-page.component';

export interface MainRoute extends Route {
  data?: RouteData;
  children?: MainRoute[];
}
export const routes: MainRoute[] = [
  {
    matcher: (routes) => {
      if (routes[0]?.path == 'go') {
        return { consumed: routes };
      }
      return <any>null;
    },
    component: GoLinkRedirectComponent,
  },
  {
    path: 'window/questionnaire/:processId',
    component: QuestionnaireComponent,
    data: {
      windowMode: true,
      node: {
        id: 'window',
        type: 'root',
        title: 'window',
        data: {
          location: 'aux.search',
        },
      },
    },
  },
  {
    path: 'window/questionnaire',
    component: QuestionnaireComponent,
    data: {
      windowMode: true,
      node: {
        id: 'window',
        type: 'root',
        title: 'window',
        data: {
          location: 'aux.search',
        },
      },
    },
  },
  {
    path: 'window/:param/:id',
    component: PreviewHubComponent,
    data: {
      windowMode: true,
      node: {
        id: 'window',
        type: 'root',
        title: 'window',
        data: {
          location: 'aux.search',
        },
      },
    },
  },
  {
    path: 'window/:param/:id/:link/:resource',
    component: PreviewHubComponent,
    data: {
      windowMode: true,
      node: {
        id: 'window',
        type: 'root',
        title: 'window',
        data: {
          location: 'aux.search',
        },
      },
    },
  },
  {
    path: 'assistant-incontext',
    component: AssistantIncontextComponent,
  },
  {
    path: 'assistant-error',
    component: AssistantErrorComponent,
  },
  {
    path: '',
    component: HubComponent,
    // Bar Component is taking care of the redirection immediately to the hub, since angular router doesn't handle it well
    // (BC of the multiple router outlets)

    children: [
      {
        path: '',
        component: HomePageComponent,
        canActivate: [HomeGuard],
        resolve: { node: NavTreeNodeResolver },
        data: {
          title: 'Home',
          id: 'home',
          componentName: 'HomePageComponent',
          clickTrack: 'hub_side_menu.home',
          icon: { type: 'font-icon', value: 'icon-home' },
          disableLoaderRemoval: true,
        },
      },
      {
        path: 'search',
        component: ResultsComponent,
        resolve: { node: NavTreeNodeResolver },
        data: {
          title: 'Search',
          id: 'search',
          clickTrack: 'hub_side_menu.search',
          componentName: 'ResultsComponent',
          icon: { type: 'font-icon', value: 'icon-search-icon-fixed' },
        },
      },
      {
        path: 'calendar',
        component: UpNextComponent,
        resolve: { node: NavTreeNodeResolver },
        data: {
          title: 'Calendar',
          componentName: 'UpNextComponent',
          clickTrack: 'hub_side_menu.calendar',
          icon: {
            type: 'font-icon',
            value: 'icon-calendar-empty font-icon',
          },
          id: 'calendar',
        },
      },
      {
        path: 'analytics',
        component: AnalyticsComponent,
        canActivate: [AnalyticsGuard],
        resolve: { node: NavTreeNodeResolver },
        data: {
          id: 'analytics',
          title: 'Analytics',
          componentName: 'AnalyticsComponent',
          clickTrack: 'hub_side_menu.analytics',
          icon: { type: 'font-icon', value: 'icon-cube' },
          suggestionsEnabled: false,
          readOnly: true,
        },
      },
      {
        path: 'connect/:appId/new',
        component: NewLinkComponent,
        resolve: { node: NavTreeNodeResolver },
        data: { id: 'new-link', intercom: false },
      },
      {
        path: 'connect/:appId',
        component: AppItemComponent,
        resolve: { node: NavTreeNodeResolver },
        data: { id: 'links', intercom: false },
      },
      {
        path: 'connect',
        component: AppsComponent,
        resolve: { node: NavTreeNodeResolver },
        data: {
          id: 'apps',
          title: 'Connect Apps',
          suggestionsEnabled: false,
          intercom: false,
          autoFocus: false,
          clickTrack: 'hub_side_menu.apps',
          icon: { value: 'icon-apps', type: 'font-icon' },
          pageViewName: 'apps.all',
        },
      },
      {
        path: 'settings',
        resolve: { node: NavTreeNodeResolver },
        loadChildren: () => import('./../views/settings/settings.module').then((m) => m.SettingsModule),
        data: {
          id: 'settings',
          title: 'Settings',
          clickTrack: 'hub_side_menu.preferences',
          icon: { type: 'font-icon', value: 'icon-cog2' },
        },
      },
      {
        path: 'card/:id',
        component: WikiCardPopupComponent,
        canActivate: [CanActivateWikiGuard],
        data: {
          componentName: 'CollectionViewComponent',
          clickTrack: 'hub_side_menu.collection_view',
          fullPage: true,
          disableFlag: Constants.DISABLED_WIKIS_FLAG,
        },
      },
      {
        path: 'wikis',
        component: CollectionsContainerComponent,
        canActivate: [CanActivateWikiGuard],
        resolve: { node: NavTreeNodeResolver },
        data: {
          title: 'Wikis',
          id: 'wikis',
          componentName: 'CollectionsContainerComponent',
          clickTrack: 'hub_side_menu.wikis',
          icon: { type: 'font-icon', value: 'icon-card' },
          disableFlag: Constants.DISABLED_WIKIS_FLAG,
        },
      },
      {
        path: 'collections',
        component: CollectionsContainerComponent,
        canActivate: [CanActivateCollectionsGuard],
        resolve: { node: NavTreeNodeResolver },
        data: {
          title: 'Collections',
          id: 'collections',
          componentName: 'CollectionsContainerComponent',
          clickTrack: 'hub_side_menu.collections',
          icon: { type: 'font-icon', value: 'icon-collection' },
          disableFlag: Constants.DISABLED_COLLECTIONS_FLAG,
        },
      },
      {
        path: 'c/:id',
        component: CollectionViewComponent,
        canDeactivate: [CanDeactivateCollectionGuard],
        canActivate: [CanActivateCollectionsGuard],
        resolve: { collection: CollectionResolver },
        data: {
          componentName: 'CollectionViewComponent',
          clickTrack: 'hub_side_menu.collection_view',
          icon: { type: 'font-icon', value: 'icon-collection' },
          disableFlag: Constants.DISABLED_COLLECTIONS_FLAG,
        },
      },
      {
        path: 'wikis/:id',
        component: CollectionViewComponent,
        canDeactivate: [CanDeactivateCollectionGuard],
        canActivate: [CanActivateCollectionsGuard],
        resolve: { collection: CollectionResolver },
        data: {
          componentName: 'CollectionViewComponent',
          clickTrack: 'hub_side_menu.collection_view',
          icon: { type: 'font-icon', value: 'icon-collection' },
          disableFlag: 'disableCollections',
        },
      },
      {
        path: 'assistants',
        component: AssistantsComponent,
        resolve: { node: NavTreeNodeResolver },
        data: {
          componentName: 'AssistantsComponent',
          clickTrack: 'hub_side_menu.assistants',
          icon: { type: 'font-icon', value: 'icon-assistant' },
        },
      },
      {
        path: 'assistant/:new/:title',
        component: AssistantComponent,
      },
      {
        path: 'assistant/:id',
        component: AssistantComponent,
        resolve: { assistant: AssistantResolver },
        data: {
          componentName: 'AssistantComponent',
          clickTrack: 'hub_side_menu.assistant',
          icon: { type: 'font-icon', value: 'icon-assistant' },
        },
      },
      {
        path: 'assistants/add-to/:id',
        component: AssistantsGuideComponent,
      },
      { path: 'web-search/:engine', component: WebSearchComponent, resolve: { engine: WebSearchResolver }, data: { id: 'web-search' } },
      {
        path: CHAT_PAGE_PATH,
        component: ChatPageComponent,
        canActivate: [CanActivateChatGuard],
        data: {
          title: 'Chat',
          id: CHAT_PAGE_PATH,
          componentName: 'ChatPageComponent',
          icon: { type: 'font-icon', value: 'icon-chat-dots' },
        },
        children: [
          {
            path: ':id',
            component: ChatPageComponent,
            data: {
              title: 'Chat',
              id: CHAT_PAGE_PATH,
              componentName: 'ChatPageComponent',
              icon: { type: 'font-icon', value: 'icon-chat-dots' },
            },
          },
        ],
        resolve: { node: NavTreeNodeResolver },
      },
      {
        path: ':param/:id',
        component: PreviewHubComponent,
        resolve: { node: NavTreeNodeResolver },
      },
      {
        path: ':param/:id/:link/:resource',
        component: PreviewHubComponent,
        resolve: { node: NavTreeNodeResolver },
      },
      {
        path: 'error',
        component: ErrorPageComponent,
        resolve: { node: NavTreeNodeResolver },
        canActivate: [ErrorGuard],
        data: { title: 'Error', id: 'error' },
      },
      {
        path: 'not-found',
        component: PageNotFoundComponent,
      },
      {
        matcher: navTreeNodeMatcher,
        component: ResultsComponent,
        canActivate: [CanActivateGoLinksGuard],
        resolve: { node: NavTreeNodeResolver, id: NavTreeNodeIdResolver, title: NavTreeNodeTitleResolver },
        data: { componentName: 'ResultsComponent' },
      },
    ],
  },
];
