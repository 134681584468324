import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Commands, Walkthrough } from '@local/client-contracts';
import { DEFAULT_PREFERENCES } from '@local/common';
import { getOSShortcut } from '@local/ts-infra';
import { KeyIconPipe } from '@shared/pipes';
import { KeyboardService } from '@shared/services/keyboard.service';
import { PreferencesService } from '@shared/services/preferences.service';
import { TourActionData } from '@shared/services/walkthrough.service';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommandsService } from 'src/app/bar/services/commands/commands.service';

const STEP_TRANSITION = 500;
@Component({
  selector: 'step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('blink', [
      transition(
        '* => true',
        animate(1000, keyframes([style({ opacity: '0' }), style({ opacity: '1' }), style({ opacity: '0' }), style({ opacity: '1' })]))
      ),
    ]),
    trigger('change', [
      transition(
        (fromState, toState) => {
          return fromState !== toState;
        },
        [style({ opacity: 0 }), animate(STEP_TRANSITION, style({ opacity: 1 }))]
      ),
    ]),
  ],
})
export class StepComponent implements OnInit, OnDestroy {
  private _step: Walkthrough.Step;
  private readonly destroy$ = new Subject<void>();
  showIcon: boolean;
  openApplicationKeys: string[];
  @Input() set step(value: Walkthrough.Step) {
    this.handleStepChange(value).then(() => {
      this.show.emit(this.step.id);
    });
  }

  get step() {
    return this._step;
  }
  tip: SafeHtml | string;

  @Output() action: EventEmitter<TourActionData> = new EventEmitter();
  @Output() show: EventEmitter<string> = new EventEmitter();
  @ViewChild('proTip') proTip: ElementRef;

  constructor(
    private cdr: ChangeDetectorRef,
    private keyIconPipe: KeyIconPipe,
    private commandsService: CommandsService,
    private domSanitizer: DomSanitizer,
    private preferencesService: PreferencesService,
    private keyboardService: KeyboardService
  ) {
    Window['StepComponent'] = this;
  }

  ngOnInit(): void {
    this.preferencesService.current$.pipe(takeUntil(this.destroy$)).subscribe(async (p) => {
      const keys = (p.instant || DEFAULT_PREFERENCES.instant).keyboardShortcut?.keys;
      if (!keys) return;
      this.openApplicationKeys = keys as string[];
      await this.handleStepChange(this.step);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  onAction(action: Walkthrough.StepButtonAction) {
    this.action.emit({ id: this.step.id, action });
  }

  private async handleStepChange(value: Walkthrough.Step): Promise<void> {
    if (!value) return;
    this.tip = await this.handleTags(value?.tip);
    this.showIcon = !!value?.icon;

    this._step = value;
    this.cdr.markForCheck();
  }

  private async handleTags(value: string): Promise<SafeHtml> {
    if (!value) return;
    const keyId: string = value.match(/<b>(.+)<\/b>/)?.[1];
    if (keyId) {
      const keys = await this.handleKeys(value, keyId);
      return keys;
    } else if (value.match(/>(.+)<\/a>/)) {
      return this.handleLink(value);
    }
    return value;
  }

  private async handleKeys(value: string, keyId: string): Promise<SafeHtml> {
    if (!keyId) return value;
    if (keyId === 'openApplication' && this.openApplicationKeys) {
      return this.openApplicationKeys;
    }
    const shortcut = await firstValueFrom(this.keyboardService.get(keyId));
    if (!shortcut) return value;
    const keys = getOSShortcut(shortcut.keys);
    if (!keys) return value;
    const keyIcon = this.keyIconPipe.transform(keys);
    return value.replace(keyId, keyIcon);
  }

  private handleLink(value: string): SafeHtml {
    const url = value.match(/href="([^\'\"]+)/g)[0]?.replace('href="', '');
    if (!url) return value;
    const tip: string = value.replace(
      /href="([^\'\"]+)/g,
      `style="color: var(--color-primary); cursor: pointer; text-decoration: underline;" onClick="Window.StepComponent.openUrl('${url}')"`
    );
    return this.domSanitizer.bypassSecurityTrustHtml(tip);
  }

  openUrl(url: string): void {
    const openUrlCmd: Commands.OpenUrl = { type: 'open-url', url };
    this.commandsService.executeCommand(openUrlCmd, {});
  }
}
