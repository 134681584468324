import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport, VirtualScrollStrategy, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { ChangeDetectorRef, Directive, ElementRef, forwardRef, Input } from '@angular/core';
import { clamp } from '@local/ts-infra';
import { isEqual } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

type ItemHeight = number[];
type Range = [number, number];
const intersects = (a: Range, b: Range): boolean =>
  (a[0] <= b[0] && b[0] <= a[1]) || (a[0] <= b[1] && b[1] <= a[1]) || (b[0] < a[0] && a[1] < b[1]);

const last = <T>(value: T[]): T => value[value.length - 1];
export const BUFFER_BEFORE = 3;
export const BUFFER_AFTER = 3;
export class CustomVirtualScrollStrategy implements VirtualScrollStrategy {
  private scrolledIndexChange$ = new Subject<number>();
  private viewport?: CdkVirtualScrollViewport;

  public _maxBufferPx = 100;
  public _minBufferPx = 100;
  public scrolledIndexChange: Observable<number> = this.scrolledIndexChange$.pipe(distinctUntilChanged());

  constructor(private itemHeights: ItemHeight, private calculateRange: boolean = true) {}

  public attach(viewport: CdkVirtualScrollViewport) {
    this.viewport = viewport;
    this.updateTotalContentSize();
    this.updateRenderedRange();
  }

  public detach() {
    this.scrolledIndexChange$.complete();
    delete this.viewport;
  }

  public onContentRendered() {
    return;
  }

  public onContentScrolled() {
    this.updateRenderedRange();
  }

  public onDataLengthChanged() {
    this.updateTotalContentSize();
    this.updateRenderedRange();
  }

  public onRenderedOffsetChanged() {
    return;
  }

  public scrollToIndex(index: number, behavior: ScrollBehavior) {
    this.viewport?.scrollToOffset(this.getItemOffset(index), behavior);
  }

  public updateItemHeights(itemHeights: ItemHeight, calculateRange = true) {
    this.itemHeights = itemHeights;
    this.updateTotalContentSize();
    this.calculateRange = calculateRange;
    this.updateRenderedRange();
  }

  private getItemOffset(index: number): number {
    return this.itemHeights.slice(0, index).reduce((acc, itemHeight) => acc + itemHeight, 0);
  }

  private getListRangeAt(scrollOffset: number, viewportSize: number): ListRange {
    type Acc = { itemIndexesInRange: number[]; currentOffset: number };
    const visibleOffsetRange: Range = [scrollOffset, scrollOffset + viewportSize];
    const itemsInRange = this.itemHeights.reduce<Acc>(
      (acc, itemHeight, index) => {
        const itemOffsetRange: Range = [acc.currentOffset, acc.currentOffset + itemHeight];
        return {
          currentOffset: acc.currentOffset + itemHeight,
          itemIndexesInRange: intersects(itemOffsetRange, visibleOffsetRange) ? [...acc.itemIndexesInRange, index] : acc.itemIndexesInRange,
        };
      },
      { itemIndexesInRange: [], currentOffset: 0 }
    ).itemIndexesInRange;

    return {
      start: clamp(0, (itemsInRange[0] ?? 0) - BUFFER_BEFORE, this.itemHeights.length - 1),
      end: clamp(0, (last(itemsInRange) ?? 0) + BUFFER_AFTER, this.itemHeights.length),
    };
  }

  private getTotalContentSize(): number {
    return this.itemHeights.reduce((a, b) => a + b, 0);
  }

  private updateRenderedRange() {
    if (!this.viewport) {
      return;
    }
    if (!this.calculateRange) {
      const defaultRange = {
        start: 0,
        end: this.itemHeights.length,
      };
      this.viewport.setRenderedRange(defaultRange);
      this.viewport.setRenderedContentOffset(this.getItemOffset(defaultRange.start));
      this.scrolledIndexChange$.next(defaultRange.start);
      return;
    }

    const viewportSize = this.viewport.getViewportSize();
    const scrollOffset = this.viewport.measureScrollOffset();
    const newRange = this.getListRangeAt(scrollOffset, viewportSize);
    const oldRange = this.viewport?.getRenderedRange();

    if (isEqual(newRange, oldRange)) {
      return;
    }

    this.viewport.setRenderedRange(newRange);
    this.viewport.setRenderedContentOffset(this.getItemOffset(newRange.start));
    this.scrolledIndexChange$.next(newRange.start);
  }

  private updateTotalContentSize() {
    const contentSize = this.getTotalContentSize();
    this.viewport?.setTotalContentSize(contentSize);
  }
}

function factory(dir: CustomVirtualScrollDirective) {
  return dir.scrollStrategy;
}

@Directive({
  selector: 'cdk-virtual-scroll-viewport[customVirtualScrollStrategy]',
  providers: [
    {
      provide: VIRTUAL_SCROLL_STRATEGY,
      useFactory: factory,
      deps: [forwardRef(() => CustomVirtualScrollDirective)],
    },
  ],
})
export class CustomVirtualScrollDirective {
  @Input() set itemHeights(value: ItemHeight) {
    this._itemHeights = value;
    this.scrollStrategy.updateItemHeights(this._itemHeights, this.calculateRange);
    this.cdr.markForCheck();
  }

  @Input() calculateRange = true;

  get itemHeights() {
    return this._itemHeights;
  }
  private _itemHeights: ItemHeight = [];

  public scrollStrategy: CustomVirtualScrollStrategy = new CustomVirtualScrollStrategy(this.itemHeights);

  constructor(private elRef: ElementRef, private cdr: ChangeDetectorRef) {}
}
