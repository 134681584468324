import { Applications } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class ApplicationsPageRpcInvoker implements Applications.Service {
  @invoker
  getDescription(appId: string): PromiseLike<Applications.Description> {
    return;
  }
  @invoker
  hasType(types: string[]): Promise<Applications.DisplayItem[]> {
    return;
  }
  @invoker
  async one(appId: string, local?: boolean): Promise<Applications.DisplayItem> {
    return;
  }
  @invoker
  one$(appId: string, local?: boolean): Observable<Applications.DisplayItem> {
    return;
  }

  @invoker
  get all$(): Observable<Applications.DisplayItem[]> {
    return;
  }

  @invoker
  get sorted$(): Observable<Applications.SortedDisplayItems> {
    return;
  }
}
