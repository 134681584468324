<div class="card-footer-container">
  <collection-tag-section
    ghost
    [ghostUntil]="ghostUntil"
    [ghostStyle]="{ width: '300px', height: '20px', margin: '6px' }"
    [initialTags]="tags"
    [closeDropdown]="closeDropdownTags"
    [isLiveUpdate]="false"
    [id]="'1'"
    [notifyAvailableTagsChange]="wikiCardsService.cardsTags$"
    (onTagChange)="onTagChange($event)"
    (componentFocusedUpdate)="changeFocus('card-tags')"
    [canEdit]="canEdit"
    [itemSubtitle]="'cards'"
    [inlineMode]="['inline', 'full'].includes(pageMode)"
  >
  </collection-tag-section>
</div>
