import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UTextareaComponent } from './u-textarea.component';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
  declarations: [UTextareaComponent],
  imports: [CommonModule, FormsModule, InputTextareaModule],
  exports: [UTextareaComponent],
})
export class UTextareaModule {}
