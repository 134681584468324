export class ErrorGoLinksConstants {
  static readonly EMPTY_URL: string = 'Please enter a URL.';
  static readonly INVALID_URL: string = 'The URL field is not valid.';
  static readonly EXISTS_URL_SINGLE: string = 'Go link already exists for this destination URL. See ';
  static readonly EXISTS_URL_MULTIPLE: string = 'Multiple Go Links already exist for this destination URL. See ';
  static readonly EMPTY_NAME: string = 'Please enter a link name.';
  static readonly INVALID_NAME: string = 'Only letters, numbers, -, and _ are allowed.';
  static readonly EXISTS_NAME_LISTED: string = 'Name already ';
  static readonly EXISTS_NAME_UNLISTED: string = 'Name already exists. Try something else.';
  static readonly EXISTS_NAME_CREATED_BY_YOU: string = 'Name already exists. You can edit the link ';
}
