import { DeepPartial } from '@local/ts-infra';
import { OnboardingState } from '@local/client-contracts';

export const getOnboardingStateObject = (stage: string): DeepPartial<OnboardingState> => {
  let common: DeepPartial<OnboardingState> = {
    kyc: { completed: false },
    selectApps: { completed: false },
    connectApps: { completed: false, current: {}, apps: [] },
    completed: false,
  };

  switch (stage) {
    case 'kyc': {
      common = {
        ...common,
        kyc: { completed: false },
        selectApps: { completed: false },
        connectApps: { completed: false },
        completed: false,
      };
      break;
    }
    case 'select-apps': {
      common = {
        ...common,
        kyc: { completed: true },
        selectApps: { completed: false },
        connectApps: { completed: false },
        completed: false,
      };
      break;
    }
    case 'connect-apps': {
      common = {
        ...common,
        kyc: { completed: true },
        selectApps: { completed: true },
        connectApps: { completed: false },
        completed: false,
      };
      break;
    }
    case 'done': {
      common = {
        ...common,
        kyc: { completed: true },
        selectApps: { completed: true },
        connectApps: { completed: true },
        completed: true,
      };
      break;
    }
  }

  return common;
};
