import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { UiIconModel } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventInfo, EventsService } from '@shared/services';
import { WorkspacesService } from 'src/app/bar/services';
import { HubService } from 'src/app/bar/services/hub.service';

type BannerType = 'sidebar' | 'large-sidebar' | 'icon-only' | 'launcher' | 'text-as-button';
@UntilDestroy()
@Component({
  selector: 'trial-banner',
  templateUrl: './trial-banner.component.html',
  styleUrls: ['./trial-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialBannerComponent {
  readonly icon: UiIconModel = { type: 'font', value: 'icon-stars' };

  @HostBinding('class')
  @Input()
  bannerType: BannerType;
  get class() {
    return this.bannerType;
  }

  @HostListener('click', ['$event'])
  onHostClick() {
    if (['icon-only', 'text-as-button'].includes(this.bannerType)) {
      this.upgradeClicked();
    }
  }

  @Input() bannerText: string;
  @Input() bannerIcon: UiIconModel;
  @Input() showButton: boolean = true;
  @Input() iconOnly: boolean = false;
  @Input() planDaysLeft: number;
  @Input() onTrialPlan: boolean;

  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

  private readonly TELEMETRY_VIEW_TYPE: Partial<{ [key in BannerType]: string }> = {
    'icon-only': 'side_menu',
    'text-as-button': 'invite_to_workspace',
    launcher: 'banner',
    sidebar: 'sidebar_menu',
  };

  constructor(private eventsService: EventsService, private hubService: HubService, private workspaceService: WorkspacesService) {
    this.workspaceService.onTrial$.pipe(untilDestroyed(this)).subscribe((onTrial) => {
      this.onTrialPlan = onTrial;
    });
  }

  upgradeClicked() {
    const telemetryEvent: Partial<EventInfo> = {
      location: {
        title: this.hubService.currentLocation,
      },
      target: this.TELEMETRY_VIEW_TYPE[this.bannerType],
    };
    if (this.onTrialPlan) {
      telemetryEvent.label = this.planDaysLeft + '';
    }
    this.eventsService.event('upgrade_plan', telemetryEvent);
    this.onClick.emit();
  }
}
