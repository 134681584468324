import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UGalleriaComponent } from './u-galleria.component';
import { UiInfraModule } from '../../ui-infra.module';
import { GalleriaModule } from 'primeng/galleria';

@NgModule({
  declarations: [UGalleriaComponent],
  exports: [UGalleriaComponent],
  imports: [CommonModule, FormsModule, UiInfraModule, GalleriaModule],
})
export class UGalleriaModule {}
