import { Injectable } from '@angular/core';
import { Subscriptions } from '@local/client-contracts';
import { SubscriptionsRpcInvoker } from '@local/common';
import { isNativeWindow } from '@local/common-web';
import { ServicesRpcService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService implements Subscriptions.Service {
  private service: Subscriptions.Service;
  private planUrlMap: { [url: string]: {plan: Promise<Subscriptions.ManagePlan>, timestamp: number }} = {};
  private returnUrl: string;

  private readonly PLAN_FETCH_TIMEOUT = 60 * 1000;

  constructor(services: ServicesRpcService) {
    this.service = services.invokeWith(SubscriptionsRpcInvoker, 'subscriptions');
    this.returnUrl = isNativeWindow() ? `https://open.${window.location.host}/open-desktop/settings` : window.location.href;
  }

  getSubscriptions(): Promise<Subscriptions.Plan[]> {
    return this.service.getSubscriptions();
  }

  createSubscription(priceId: string, successUrl: string, cancelUrl: string): Promise<Subscriptions.NewPlan> {
    return this.service.createSubscription(priceId, successUrl, cancelUrl);
  }

  manageSubscription(returnUrl?: string) {
    const now = Date.now();
    const url = returnUrl || this.returnUrl
    const value = this.planUrlMap[url];
    if (value?.timestamp + this.PLAN_FETCH_TIMEOUT > now) {
      return value.plan;
    }
    const plan = this.service.manageSubscription(url);
    this.planUrlMap[url] = { plan, timestamp: now};
    return plan;
  }

  async getYourPlanUrl(): Promise<Subscriptions.ManagePlan> {
    return this.manageSubscription(this.returnUrl);
  }
}
