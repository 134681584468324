import { Injectable } from '@angular/core';
import { Config } from '@environments/config';
import { Walkthrough } from '@local/client-contracts';
import { isEmbed } from '@local/common-web';
import { KeyName, getModifiers, isKey, keyCodes, normalizeKeys, removeModifiers } from '@local/ts-infra';
import { PopupService, TelemetryTarget } from '@local/ui-infra';
import { EventsService, LogService } from '@shared/services';
import { AppService } from '@shared/services/app.service';
import { KeyboardService } from '@shared/services/keyboard.service';
import { RouterService } from '@shared/services/router.service';
import { SessionService } from '@shared/services/session.service';
import { WalkthroughService } from '@shared/services/walkthrough.service';
import { Subject, Subscription, delay, firstValueFrom } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { SearchPopupItem } from '../views/special-views/search-popup/model';
import { SearchPopUpService } from '../views/special-views/search-popup/search-popup.service';

@Injectable()
export class CommandBarService extends SearchPopUpService {
  private tour: Promise<Walkthrough.Tour>;
  private subscriptions: Subscription[] = [];
  private isLauncher: boolean;
  private shortcutKeyId: string;
  private readonly destroy$ = new Subject();

  constructor(
    protected popupService: PopupService,
    protected keyboardService: KeyboardService,
    protected eventsService: EventsService,
    protected routerService: RouterService,
    private walkthroughService: WalkthroughService,
    private sessionService: SessionService,
    private appService: AppService,
    logService: LogService
  ) {
    super(popupService, keyboardService, 'CommandBar', logService, eventsService, routerService, 'command_bar');
    this.logger = logService.scope('CommandBarService');
    this.placeholder = 'Search for an action or a page';
    const _isEmbed = isEmbed();
    this.appService.windowStyle$.pipe(takeUntil(this.destroy$)).subscribe((b) => {
      const isLauncher = b != 'standard';
      if (isLauncher === this.isLauncher) return;
      if (!isLauncher && !_isEmbed) {
        this.init();
      } else {
        if (this.shortcutKeyId) {
          this.keyboardService.unregisterKeyHandler(this.shortcutKeyId);
          this.shortcutKeyId = undefined;
        }
        this.subscriptions.forEach((s) => s.unsubscribe());
      }
      this.isLauncher = isLauncher;
    });
  }

  private init() {
    this.initShortcut();

    this.sessionService.current$.subscribe((s) => {
      if (s) this.tour = this.walkthroughService.getTour('commandBar');
    });
  }

  destroy() {
    super.destroy();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  async open(via: TelemetryTarget, checkTour = true) {
    if (checkTour) {
      const tour = await this.tour;
      if (tour && !tour.status.completed) {
        this.walkthroughService.start(tour, this.routerService.location);
        this.openAfterWalkthrough(via);
        return;
      }
    }
    super.open(via);
  }

  private openAfterWalkthrough(via: TelemetryTarget) {
    this.subscriptions.push(
      this.walkthroughService.activeTour$
        .pipe(
          filter((t) => !t),
          take(1),
          delay(250) // Wait for walkthrough animation finish
        )
        .subscribe(() => {
          this.open(via, false);
        })
    );
  }

  private async initShortcut() {
    if (!this.keyboardService || Config.customDomain) return;

    const s = await firstValueFrom(this.keyboardService.get('openCommandBar'));
    const shortcut = normalizeKeys(s.keys as string[]);

    this.shortcutKeyId = this.keyboardService.registerKeyHandler((keys, event) => {
      if (this.ref) {
        return;
      }

      const mod = getModifiers(event);
      const nonMod = removeModifiers([...(shortcut as KeyName[])]);
      if (mod.length === 1 && mod.includes('control') && nonMod.length === 1 && isKey(event, keyCodes[nonMod[0].toLowerCase()])) {
        if (this.ref) {
          this.ref.destroy();
        }

        this.open('keyboard');
        event.preventDefault();
      }
    });
  }

  protected _sortBy(a: SearchPopupItem<'parent'>, b: SearchPopupItem<'parent'>): number {
    const [aSortId, bSortId]: Level[] = [a.data?.sortId, b.data?.sortId];
    if (!aSortId || !bSortId) {
      this.logger.warn('No sort level found for this items. Unable to sort, returning default behavior', { a, b });
      return 0;
    }

    if (!isLevel(aSortId) || typeof aSortId !== 'string' || !isLevel(bSortId) || typeof bSortId !== 'string') {
      // Type guard
      this.logger.warn("Sort id type doesn't match.", { a, b });
      return;
    }

    return LEVELS_AND_ORDER.indexOf(aSortId) - LEVELS_AND_ORDER.indexOf(bSortId);
  }
}

const LEVELS_AND_ORDER = ['COMMANDS', 'CONTEXTUAL_CREATE', 'GOTO', 'WEB_SEARCH', 'CREATE', 'PREFERENCES', 'HELP'] as const;
type Level = (typeof LEVELS_AND_ORDER)[keyof typeof LEVELS_AND_ORDER];
const STATES = ['closed', 'opened'] as const;
type CommandBarState = (typeof STATES)[keyof typeof STATES];
export const isCommandBarState = (x: any): x is CommandBarState => STATES.includes(x);
export const isLevel = (x: any): x is Level => LEVELS_AND_ORDER.includes(x);
