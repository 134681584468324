import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LinkResult, WrongAccountLinkError, DuplicateLinkError } from '@shared/components/link-settings/models';

@Component({
  selector: 'new-link-done',
  templateUrl: './new-link-done.component.html',
  styleUrls: ['./new-link-done.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewLinkDoneComponent {

  @Output() connectAnother = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() closeOverlay = new EventEmitter();
  @Output() toggleSuppressSync = new EventEmitter();
  @Input() linkResult: LinkResult;
  @Input() suppressSyncMessage: boolean;

  isWrongAccountError(result: LinkResult) {
    return result.error && result.error instanceof WrongAccountLinkError;
  }

  isDuplicateError(result: LinkResult) {
    return result.error && result.error instanceof DuplicateLinkError;
  }
}
