import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Applications, Filters } from '@local/client-contracts';

const content = {
  title: 'Refine your search scope ',
  description: 'Minimize search noise by setting a time filter over the indexed information',
  filterHeader: 'Time filter',
};

@Component({
  selector: 'new-link-pick-relative-time',
  templateUrl: './new-link-pick-relative-time.component.html',
  styleUrls: ['./new-link-pick-relative-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewLinkPickRelativeTimeComponent {
  @Output() onActionEvent = new EventEmitter();
  @Input() app: Applications.DisplayItem;

  content = content;
  currentFilter: Filters.Values;

  constructor(private cdr: ChangeDetectorRef) {}

  onFilterChange($event) {
    this.currentFilter = $event;
    this.cdr.markForCheck();
  }
}
