import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';
import { URadioButtonComponent } from './u-radio-button.component';

@NgModule({
  declarations: [URadioButtonComponent],
  imports: [CommonModule, RadioButtonModule, FormsModule, CommonModule],
  exports: [URadioButtonComponent],
})
export class URadioButtonModule {}
