import { NgModule } from '@angular/core';
import { UChipsComponent } from './u-chips.component';
import { ChipsModule } from 'primeng/chips';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [UChipsComponent],
  imports: [ChipsModule, FormsModule, CommonModule, TooltipModule],
  exports: [UChipsComponent],
})
export class UChipsModule {}
