<div class="tabs">
  <ng-container *ngFor="let tab of tabs; index as index; trackBy: trackTabs">
    <label class="tabs-item" [for]="'tab-' + index" #tabLabel (click)="value = tab.id" [class.active]="tab.active">
      <ng-container [ngTemplateOutlet]="tab.tabTemplate ? tab.tabTemplate : title"> </ng-container>
      <ng-template #title style="padding: 5px 20px">
        {{ tab.title }}
      </ng-template>
    </label>
    <input [class]="'tab-input-' + index" type="radio" [id]="'tab-' + index" [name]="index" />
  </ng-container>
  <div
    id="indicator"
    [style]="{ width: activeTabWidth ? activeTabWidth + 'px' : 0, transform: 'translate(' + indicatorPosition + 'px,0px)' }"
  ></div>
</div>
