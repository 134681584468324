<button
  pButton
  #button
  type="button"
  [iconPos]="iconPos"
  [ngClass]="[
    type + '-button',
    withIcon ? ' with-icon' : '',
    onlyIcon ? 'only-icon' : '',
    elementSize ?? '',
    active ? 'active' : '',
    customPrimary ? 'custom-primary' : ''
  ]"
  class="u-button"
  [label]="loading ? null : label"
  [disabled]="disabled || loading"
  (click)="onClick.emit($event)"
  [style]="styles"
  [icon]="fontIcon && !loading ? 'font-icon icon-' + fontIcon : null"
  [attr.has-font-icon]="!!fontIcon"
  [class.permanent-dark-mode]="permanentDarkMode"
>
  <ng-content></ng-content>
  <i *ngIf="loading" class="pi pi-spin pi-spinner" style="font-size: 14px"></i>
</button>
