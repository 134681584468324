import { Search } from '@local/client-contracts';

export interface LinkResourcesResultExtra {
  searchRequest: Search.Request;
  search?: Search.Context;
  sentEndEvents?: { [src: string]: boolean };
  postFilters?: Search.ResponseFilters;
  isSingleApp?: boolean;
  lastPageInfo?: { startIndex: number; endIndex: number; last: boolean };
  fetchedPages?: number;
  nextPagePromise?: Promise<Search.Response>;
  cloudTotalResults?: number;
  prevSortDateFormat?: string;
  prevSortDate?: Date;
  sort?: Search.Sort;
  ignoreDateHeaders?: boolean;
  lastSyncTime?: number;
  dateHeadersInserted?: boolean;
  localDone?: boolean;
  searchId: string;
  source: string;
  origin: Search.Origin;
  typeSuggestedFilters: Search.FilterCountDetails[]
}
