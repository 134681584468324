import { Directive, HostListener, Input } from '@angular/core';

import { EventsService } from '@shared/services';

@Directive({
  selector: '[clickTrack]',
})
export class ClickTrackDirective {
  @Input() clickTrack: string;

  constructor(private eventsService: EventsService) {}

  @HostListener('click')
  onClick() {
    if (typeof this.clickTrack === 'string') {
      this.eventsService.event(this.clickTrack);
    }
  }
}
