import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UToggleButtonComponent } from './u-toggle-button.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [UToggleButtonComponent],
  imports: [CommonModule, InputSwitchModule, FormsModule],
  exports: [UToggleButtonComponent],
})
export class UToggleButtonModule {}
