import {
  AppsBrowserCount,
  BrowserHistory,
  BrowserHistoryRecord,
  BrowserHistorySearchRequest,
  BrowserHistorySearchResponse,
  BrowserHistorySearchResult,
  BrowserHistoryVisitFrequencyRecord,
  DomainStats,
} from '@local/common';
import { handler } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class BrowserHistoryProxyRpcHandler implements BrowserHistory {
  constructor(public inner?: BrowserHistory) {}

  @handler
  getVisitFrequency(since: number): Promise<BrowserHistoryVisitFrequencyRecord[]> {
    return this.inner?.getVisitFrequency(since);
  }

  @handler
  get new$(): Observable<BrowserHistorySearchResult> {
    return this.inner?.new$;
  }

  @handler
  async search(r: BrowserHistorySearchRequest): Promise<BrowserHistorySearchResponse> {
    return this.inner?.search(r) || { results: [] };
  }
  @handler
  getCounts(windowSize: number, domains: { [domain: string]: string[] }): Promise<AppsBrowserCount> {
    return this.inner?.getCounts(windowSize, domains);
  }
  @handler
  getBrowserUsage(): Promise<{ [name: string]: number }> {
    return this.inner?.getBrowserUsage();
  }
  @handler
  getInterestingApps(extraDomains?: string[]): Promise<any[]> {
    return this.inner?.getInterestingApps(extraDomains);
  }
  @handler
  fetchDomainStats(since: number, domains: string[], partitionCount?: number, windowSize?: number): Promise<DomainStats> {
    return this.inner?.fetchDomainStats(since, domains, partitionCount, windowSize);
  }
  @handler
  fetchHistoryRecords(since: number): Promise<BrowserHistoryRecord[]> {
    return this.inner?.fetchHistoryRecords(since);
  }
  @handler
  getBrowserAppsActivity(
    since: number,
    domainApp: { [id: string]: string },
    partitionCount?: number,
    windowSize?: number
  ): Promise<DomainStats> {
    return this.inner?.getBrowserAppsActivity(since, domainApp, partitionCount, windowSize);
  }
}
