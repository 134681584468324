import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgModule } from '@angular/core';
import { UEmojiPickerComponent } from './u-emoji-picker/u-emoji-picker.component';
import { CommonModule } from '@angular/common';
import { UIconModule } from '../u-icon/u-icon.module';
import { UEmojiComponent } from './u-emoji-item/u-emoji-item.component';
import { UEmojiWrapperComponent } from './u-emoji-picker-wrapper/u-emoji-picker-wrapper.component';

@NgModule({
  declarations: [UEmojiPickerComponent, UEmojiComponent, UEmojiWrapperComponent],
  imports: [PickerModule, CommonModule, UIconModule],
  exports: [UEmojiPickerComponent, UEmojiComponent],
})
export class UEmojiPickerModule {}
