import { Search } from '@local/client-contracts';
import { MemorySearchSettings } from '../memory-search-client/memory-search-client';

export interface GoLinksSourceSettings extends MemorySearchSettings {
  type: 'go-links';
  aggregations?: string[];
  title?: string;
  visit?: boolean;
  showHeaderButton?: boolean;
  withVisits: boolean;
  useMemSearch?: boolean;
  maxCount?: number;
  groupsOptions?: {
    maxGroups: number;
    groupSize: number;
    groupingType: Search.GroupingType;
  };
  changeAfterSearch?: boolean;
}
