import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'preHtml',
})
export class PreHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: any): any {
    if (!value) return;

    return `<span>${this.sanitizer.sanitize(SecurityContext.HTML, value)}</span>`;
  }
}
