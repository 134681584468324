import { Injectable } from '@angular/core';
import { QuickLinks } from '@local/client-contracts';
import { observable } from '@local/common';
import { PopupRef, PopupService } from '@local/ui-infra';
import { CreateQuickLinksPopupComponent } from '@shared/components/quick-links-popup.ts/create-quick-links-popup/create-quick-links-popup.component';
import { QuickLinksPopupComponent } from '@shared/components/quick-links-popup.ts/quick-links-popup.component';
import { EventsService, LogService, ServicesRpcService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';
import { isEqual, pickBy } from 'lodash';
import { Observable, ReplaySubject, takeUntil, take, firstValueFrom, filter } from 'rxjs';
import { AppPopupComponent, AppPopupData } from '../components/app-popup/app-popup.component';
import { HubService } from './hub.service';
import { QuickLinksRpcInvoker } from './invokers/quick-links.rpc-invoker';
import { WorkspacesService } from './workspaces.service';
import { ToasterData } from '../components/toaster/toaster-data';
import { ShowToasterService } from './show-toaster.service';

@Injectable({
  providedIn: 'root',
})
export class QuickLinksService {
  private readonly WARNING_DATA: AppPopupData = {
    showButtons: true,
    content: {
      title: 'Closing the window will discard <br> changes.',
      secondaryButton: 'Cancel',
      primaryButton: 'Discard',
    },
  };
  private readonly DELETE_DATA: AppPopupData = {
    message: 'This will affect the entire workspace',
    showButtons: true,
    content: {
      title: 'Are you sure you want to <br> delete this link?',
      secondaryButton: 'Cancel',
      primaryButton: 'Delete',
    },
    messageStyle: { fontSize: '12px' },
  };
  private readonly TOASTER_DATA: ToasterData = {
    id: 'quick-link-create',
    title: 'Link has been successfully created ',
    icon: { type: 'font', value: 'icon-check-circle' },
    iconIntent: 'success',
  };
  private readonly TOASTER_ERROR_DATA: ToasterData = {
    id: 'quick-links-error',
    title: `Oops.. something went wrong. Please try again! `,
    icon: { type: 'font', value: 'icon-duo-exclamation-circle' },
    iconIntent: 'danger',
  };
  private warningPopupRef: PopupRef<AppPopupComponent, AppPopupData>;
  private quickLinksPopupRef: PopupRef<QuickLinksPopupComponent, any>;
  private createQuickLinksPopupRef: PopupRef<CreateQuickLinksPopupComponent, QuickLinks.QuickLink>;

  private logger: Logger;
  private service: QuickLinks.Service;
  private _isOwnerOrAdmin: boolean;
  private _accountId: string;
  private _all$ = new ReplaySubject<QuickLinks.QuickLink[]>(1);

  get isOwnerOrAdmin(): boolean {
    return this._isOwnerOrAdmin;
  }

  get accountId(): string {
    return this._accountId;
  }

  constructor(
    services: ServicesRpcService,
    logger: LogService,
    private popupService: PopupService,
    private eventsService: EventsService,
    private hubService: HubService,
    private workspacesService: WorkspacesService,
    private showToasterService: ShowToasterService
  ) {
    this.logger = logger.scope('QuickLinks.Service');
    this.service = services.invokeWith(QuickLinksRpcInvoker, 'quicklinks');
    this.service.all$.subscribe((all) => this._all$.next(all));
    this.workspacesService.current$.subscribe((workspace) => {
      this._accountId = workspace?.accountId;
      this._isOwnerOrAdmin = workspace?.isOwner || workspace?.isAdmin;
    });
  }

  @observable
  get all$(): Observable<QuickLinks.QuickLink[]> {
    return this._all$.asObservable();
  }

  create(quickLink: QuickLinks.QuickLink): Promise<void> {
    return this.service.create(quickLink);
  }

  update(id: string, actions: QuickLinks.UpdateAction[], isShared: boolean = false, isUpdate: boolean = true): Promise<void> {
    this.closeCreateQuickLinkPopup();
    return this.service
      .update(id, actions, isShared)
      .then(() => {
        if (!isUpdate) {
          this.showToasterService.showToaster(this.TOASTER_DATA);
        }
      })
      .catch((e) => {
        this.showToasterService.showToaster(this.TOASTER_ERROR_DATA);
      });
  }

  delete(id: string): Promise<void> {
    return this.service.delete(id).catch((e) => {
      this.showToasterService.showToaster(this.TOASTER_ERROR_DATA);
    });
  }

  refresh(): Promise<void> {
    return this.service.refresh();
  }

  openWarningPopup(deleted: boolean = false, id?: string) {
    if (this.warningPopupRef) {
      this.closeWarningPopup();
    }
    this.warningPopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      deleted ? this.DELETE_DATA : this.WARNING_DATA,
      {
        position: 'center',
      }
    );
    this.warningPopupRef.compInstance.primaryButton.pipe(takeUntil(this.warningPopupRef.destroy$)).subscribe(() => {
      if (deleted) {
        this.service.delete(id);
      }
      this.closeWarningPopup();
      this.closeCreateQuickLinkPopup();
      this.sendImpressionEvent(deleted, deleted ? 'delete' : 'discard');
    });
    this.warningPopupRef.compInstance.secondaryButton.pipe(takeUntil(this.warningPopupRef.destroy$)).subscribe(() => {
      this.sendImpressionEvent(false, 'cancel');
      this.closeWarningPopup();
    });
  }

  openCreateQuickLinkPopup(model?: QuickLinks.QuickLink) {
    if (this.createQuickLinksPopupRef) {
      this.closeCreateQuickLinkPopup();
    }
    this.closeQuickLinkPopup();
    this.createQuickLinksPopupRef = this.popupService.open<CreateQuickLinksPopupComponent, any>(
      'center',
      CreateQuickLinksPopupComponent,
      model,
      {
        closeOnClickOut: false,
        position: 'center',
        backdropStyle: 'blur-2',
      }
    );
    this.createQuickLinksPopupRef.outsidePointerEvents$.pipe().subscribe((e) => {
      if (this.createQuickLinksPopupRef.compInstance.isUpdatedPopup()) {
        this.openWarningPopup();
      } else {
        this.closeCreateQuickLinkPopup();
      }
    });
  }

  openQuickLinkPopup(event: MouseEvent): PopupRef<QuickLinksPopupComponent, any> {
    if (this.quickLinksPopupRef) {
      this.quickLinksPopupRef.destroy();
    }
    const openAt: { x: number; y: number } = event;
    this.eventsService.event('quick_links.menu', { location: { title: 'home' }, target: 'open_menu' });
    this.quickLinksPopupRef = this.popupService.open<QuickLinksPopupComponent, any>(
      openAt,
      QuickLinksPopupComponent,
      {},
      { closeOnClickOut: true, position: 'below' }
    );
    this.quickLinksPopupRef.destroy$.subscribe(() => {
      this.eventsService.event('quick_links.menu', { location: { title: 'home' }, target: 'close_menu' });
      this.quickLinksPopupRef = null;
    });
    return this.quickLinksPopupRef;
  }

  closeWarningPopup() {
    this.warningPopupRef?.destroy();
    this.warningPopupRef = null;
  }

  closeCreateQuickLinkPopup() {
    this.createQuickLinksPopupRef?.destroy();
    this.createQuickLinksPopupRef = null;
  }

  closeQuickLinkPopup() {
    this.quickLinksPopupRef?.destroy();
    this.quickLinksPopupRef = null;
  }

  isEqual(originalItem: QuickLinks.QuickLink, updateItem: QuickLinks.QuickLink): Partial<QuickLinks.QuickLink> {
    return pickBy(updateItem, (v, k) => !isEqual(originalItem[k], v));
  }

  private sendImpressionEvent(deleted: boolean, target: string) {
    this.eventsService.event('quick_links.action', {
      name: deleted ? 'delete_quick_link_prompt' : 'unsaved_quick_link_prompt',
      target,
      location: {
        title: this.hubService.currentLocation,
      },
    });
  }
}
