import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { Links } from '@local/client-contracts';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CardSetSelection } from '../../../../shared/components/card-set-selection/card-set.model';

@UntilDestroy()
@Component({
  selector: 'choose-link-level',
  templateUrl: './choose-link-level.component.html',
  styleUrls: ['./choose-link-level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseLinkLevelComponent {
  readonly CARDS_SET: CardSetSelection = {
    cards: [
      {
        id: 'Private',
        title: 'Private',
        content: 'For personal<br />use only',
      },
      {
        id: 'Public',
        title: 'Shared',
        content: 'To connect with<br />colleagues',
      },
    ],
  };

  @Output() linkLevel = new EventEmitter<Links.LinkLevel>();

  constructor(private cdr: ChangeDetectorRef) {}

  cardSelected(level: Links.LinkLevel) {
    this.linkLevel.emit(level);
  }
}
