import { Injectable } from '@angular/core';
import { Fyis } from '@local/client-contracts';
import { Syncs, SyncsRpcInvoker } from '@local/common';
import { Logger } from '@unleash-tech/js-logger';
import { Observable, firstValueFrom, map } from 'rxjs';
import { LogService, ServicesRpcService } from './';

@Injectable({
  providedIn: 'root',
})
export class SyncsService implements Syncs {
  private logger: Logger;
  private service: Syncs;

  linksSyncStatuses: { [linkId: string]: Fyis.SyncStatus };

  constructor(private services: ServicesRpcService, log: LogService) {
    this.logger = log.scope('SyncsService');
    this.service = this.services.invokeWith(SyncsRpcInvoker, 'syncs');
    this.initLinkStatus();
  }

  get all$(): Observable<Fyis.Sync[]> {
    return this.service.all$;
  }

  get latest$(): Observable<Fyis.Sync[]> {
    return this.service.latest$;
  }

  refresh(linkIds?: string[], withLatest = false): Promise<void> {
    return this.service.refresh(linkIds, withLatest);
  }

  async getLatestSync(linkIds: string[]): Promise<Fyis.Sync[]> {
    const syncs = [];
    const initial = await firstValueFrom(this.all$);
    const latest = await firstValueFrom(this.latest$);
    for (const linkId of linkIds || []) {
      let sync = latest?.find((s) => s.linkId === linkId);
      if (!sync) {
        sync = initial?.find((s) => s.linkId === linkId);
      }
      if (sync) {
        syncs.push(sync);
      }
    }
    return syncs;
  }

  private initLinkStatus() {
    this.all$
      .pipe(
        map<Fyis.Sync[], { [linkId: string]: Fyis.SyncStatus }>((syncs: Fyis.Sync[]) => {
          return syncs?.reduce((acc, curr) => {
            acc[curr.linkId] = curr.status;
            return acc;
          }, {});
        })
      )
      .subscribe((linksSyncStatuses) => {
        this.linksSyncStatuses = linksSyncStatuses;
      });
  }
}
