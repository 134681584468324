import { MemorySearch } from '@local/client-contracts';
import { LogService } from '@shared/services';
import { BrowserTabsService } from '@shared/services/browser-tabs.service';
import { DownloadsService } from '@shared/services/downloads.service';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { firstValueFrom } from 'rxjs';
import { BrowserTabItem, SearchResults } from 'src/app/bar/views/results/models/results-types';
import { MemorySearchClient } from '../memory-search-client/memory-search-client';
import { SearchRequest } from '../search-request';
import { BrowserTabSourceSettings } from './browser-tabs-source-settings';

export class BrowserTabsSearchClient extends MemorySearchClient<BrowserTabSourceSettings> {
  constructor(
    logService: LogService,
    memorySearchService: MemorySearchService,
    private browserTabsService: BrowserTabsService,
    private downloadsService: DownloadsService
  ) {
    super(logService, memorySearchService, ['Alphabetical']);
    this.logger = logService.scope('browser-tabs');
  }

  async getInput(request: SearchRequest<BrowserTabSourceSettings>): Promise<MemorySearch.Item[]> {
    const tabs = await firstValueFrom(this.browserTabsService.all$);

    // start with the newest first
    const sorting = request.sourceSettings.sorting;
    return tabs.map((t) => ({
      searchText: t.title + ' ' + t.url + ' in browser tab',
      data: <BrowserTabItem>{ ...t, type: 'browser-tab' },
      sortValue: sorting?.by === 'Alphabetical' ? t.title : null,
    }));
  }

  async getOutput(items: MemorySearch.Item[]): Promise<SearchResults[]> {
    const promises: Promise<string>[] = [];
    const res = [];
    for (const item of items) {
      const data = item.data as BrowserTabItem;
      if (data.profile?.pictureFileName) {
        promises.push(
          this.downloadsService.getLocalFileUrl(data.profile.pictureFileName).then((p) => (data.profile.pictureFileName = p.url))
        );
      }
      res.push(item.data);
    }
    await Promise.all(promises);
    return res;
  }
}
