import { Injectable } from '@angular/core';
import { NativeApp, SessionInfo } from '@local/client-contracts';
import { NativeAppRpcInvoker } from '@local/common';
import { isNativeWindow } from '@local/common-web';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ServicesRpcService } from '@shared/services/rpc.service';
import { Observable } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class NativeAppService implements NativeApp.Service {
  private service: NativeApp.Service;
  private status: string;
  private readonly isNativeWindow = isNativeWindow();

  get user$(): Observable<string> {
    return this.service.user$;
  }

  get trackingIds$(): Observable<NativeApp.TrackingIds> {
    return this.service.trackingIds$;
  }

  constructor(services: ServicesRpcService) {
    this.service = services.invokeWith(NativeAppRpcInvoker, 'nativeapp');
  }

  signalOnboardingDone(): Promise<void> {
    return this.service.signalOnboardingDone();
  }

  completeAuthentication(nonce: string, session: SessionInfo, via?: string): Promise<void> {
    return this.service.completeAuthentication(nonce, session, via);
  }

  update(session: SessionInfo): Promise<void> {
    return this.service.update(session);
  }

  transferTrackingIds(ids: NativeApp.TrackingIds): Promise<void> {
    return this.service.transferTrackingIds(ids);
  }

  canSearchPc() {
    return this.isNativeWindow || (this.status && this.status === 'running');
  }
}
