import { QuickLinks } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class QuickLinksRpcInvoker implements QuickLinks.Service {
  @invoker
  get all$(): Observable<QuickLinks.QuickLink[]> {
    return;
  }

  @invoker
  create(quickLink: QuickLinks.QuickLink): Promise<void> {
    return;
  }

  @invoker
  delete(id: string): Promise<void> {
    return;
  }

  @invoker
  update(id: string, actions: QuickLinks.UpdateAction[], isShared?: boolean): Promise<void> {
    return;
  }

  @invoker
  refresh(): Promise<void> {
    return;
  }
}
