<div class="rename-container">
  <u-edit-text
    [placeholder]="inputPlaceholder"
    (onTextChange)="onChangeName($event)"
    (blur)="rename()"
    (outsideClick)="rename()"
    (onEnterPressed)="rename()"
    [textSelected]="true"
  ></u-edit-text>
</div>
