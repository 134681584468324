import { Injectable } from '@angular/core';
import { Applications } from '@local/client-contracts';
import { observable } from '@local/common';
import { firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { ApplicationsPageRpcInvoker } from './invokers/applications-page-rpc-invoker';
import { ServicesRpcService } from './rpc.service';
import { isNativeWindow } from '@local/common-web';
import { NativeAppLinkService } from './native-app-link.service';
import { tapOnce } from '@local/ts-infra';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsService {
  apps: Record<string, Applications.DisplayItem> = {};
  cacheLoaded: Promise<void>;

  private _all$: ReplaySubject<Applications.DisplayItem[]> = new ReplaySubject(1);
  private allAvailableTypes$: ReplaySubject<{ [type: string]: string[] }> = new ReplaySubject(1);
  private service: ApplicationsPageRpcInvoker;
  private isNativeInstalled: boolean;
  private readonly isNativeWindow = isNativeWindow();

  private set _all(value: Applications.DisplayItem[]) {
    this._all$.next(value);
  }

  constructor(services: ServicesRpcService, private nativeAppLinkService: NativeAppLinkService) {
    this.service = services.invokeWith(ApplicationsPageRpcInvoker, 'applicationspage');
    this.initCache();
    this.service.all$.subscribe((apps) => (this._all = apps));

    this.all$.subscribe(async (apps) => {
      const types = new Set<string>();
      for (const app of apps || []) {
        app.resources.forEach((r) => types.add(r.title));
      }
      const availableTypes: { [type: string]: string[] } = {};
      for (const type of types) {
        const apps = await this.hasType([type]);
        const appIds = apps.map((a) => a.id);
        availableTypes[type] = appIds;
      }
      return this.allAvailableTypes$.next(availableTypes);
    });
    this.nativeAppLinkService?.status$.subscribe(async (s) => {
      this.isNativeInstalled = s !== 'not-installed';
    });
  }

  @observable
  get all$(): Observable<Applications.DisplayItem[]> {
    return this._all$;
  }

  get sorted$(): Observable<Applications.SortedDisplayItems> {
    return this.service.sorted$;
  }

  all(): Promise<Applications.DisplayItem[]> {
    return firstValueFrom(this._all$);
  }

  one(appId: string, local?: boolean): Promise<Applications.DisplayItem> {
    return this.service.one(appId, local);
  }

  one$(appId: string, local?: boolean): Observable<Applications.DisplayItem> {
    return this.service.one$(appId, local);
  }

  getDescription(appId: string): PromiseLike<Applications.Description> {
    return this.service.getDescription(appId);
  }

  async hasType(types: string[]): Promise<Applications.DisplayItem[]> {
    const UpperCassedTypes = types.filter((t) => t).map((t) => t.toLocaleUpperCase());
    const relevant = (await firstValueFrom(this.all$)).filter(
      ({ resources }) => resources && resources.some(({ title }) => UpperCassedTypes.includes(title?.toLocaleUpperCase()))
    );
    return relevant;
  }

  async getTypeAvailableApps(types: string[]): Promise<string[]> {
    const apps = new Set<string>();
    const availableTypes = await firstValueFrom(this.allAvailableTypes$);
    for (const type of types) {
      const typeApps = availableTypes[type];
      typeApps?.forEach((a) => apps.add(a));
    }
    return [...apps];
  }

  /** Creates subscription to update the local cache. Also resolves a 'loaded' promise on first time */
  private initCache() {
    this.cacheLoaded = new Promise((res) => {
      this.all$.pipe(tapOnce((apps) => apps.length && res())).subscribe((apps) => {
        this.apps = {};
        for (const app of apps) {
          this.apps[app.id] = app;
        }
      });
    });
  }

  ignoreApps(apps: Applications.DisplayItem[]): Applications.DisplayItem[] {
    apps = apps.filter(
      (app) => (!app.state.ignore && !app.state.manual) || (app.id === 'pc' && (this.isNativeWindow || this.isNativeInstalled))
    );
    return apps;
  }
}
