import { Filters, Search } from '@local/client-contracts';
import { EventInfo } from '@shared/services';
import { SearchRequest } from './search-request';
import { SearchResponse } from './search-response';
import { SearchResponseType } from './search-response-type';
import { SourceSettings } from './source-settings';

export interface SearchClient<Settings extends SourceSettings> {
  search(request: SearchRequest<Settings>, response: SearchResponse): SearchResponseType;
  nextPage(request: SearchRequest<Settings>, response: SearchResponse, trigger: string): Promise<void>;
  destroy(id: number, sessionName: string): void;
  supportsFilters(filters: { preFilters?: Filters.Values; postFilters?: Filters.Values }): boolean;
  supportsSort(sort: Search.Sort): boolean;
  getTelemetryEndEvent(response: SearchResponse): Partial<EventInfo>[];
}
