import { ServicesRpcService } from '@shared/services';
import { ExperienceErrorsRpcInvoker } from './invokers/experience-errors-rpc-invoker';
import { ExperienceErrors } from '@local/client-contracts';
import { Injectable } from '@angular/core';

@Injectable()
export class ExperienceErrorsService {
  private service: ExperienceErrors.Service;

  constructor(services: ServicesRpcService) {
    this.service = services.invokeWith(ExperienceErrorsRpcInvoker, 'experienceerrors');
  }

  getErrorById$(id: string) {
    return this.service.getErrorById$(id);
  }
}
