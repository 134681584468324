import { Injectable, NgZone } from '@angular/core';
import { WindowRpcInvoker } from '@local/common-web';
import * as uuid from 'uuid';
import { ServicesRpcService } from '.';
import { RouterService } from './router.service';

export interface TimerOptions {
  focusInterval?: number;
  visibleInterval?: number;
  hiddenInterval?: number;
}

interface TimerContext {
  options: TimerOptions;
  callback: () => any;
  interval?: any;
  ngzone: boolean;
  lastRun?: number;
  intervalMs?: number;
}

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  private focused: boolean;
  private visible: boolean;
  private nextId = 0;
  private timers: { [id: number]: TimerContext } = {};

  constructor(private ngzone: NgZone, private services: ServicesRpcService, private routerService: RouterService) {
    let windowRpc = this.services.invokeWith(WindowRpcInvoker, 'window');
    let id = uuid.v4();
    let updateState = (r?) => {
      let focused = document.hasFocus();
      let visible = document.visibilityState == 'visible';

      if ((this.focused == focused && this.visible == visible) || this.routerService.currentAppModule === 'window') return;

      this.focused = focused;
      this.visible = visible;
      this.updateIntervals();
      windowRpc.updateState(id, this.visible, this.focused);
    };

    updateState();

    window.addEventListener(
      'focus',
      () => {
        updateState();
      },
      { passive: true }
    );
    window.addEventListener('blur', () => updateState(), { passive: true });
    document.addEventListener('visibilitychange', () => updateState(), { capture: true, passive: true });
  }

  register(callback: () => any, options: TimerOptions): number {
    const ctx = (this.timers[++this.nextId] = { callback, options, ngzone: NgZone.isInAngularZone() });
    this.updateInterval(ctx);
    return this.nextId;
  }

  unregister(timer: number) {
    let t = this.timers[timer];
    delete this.timers[timer];
    if (t?.interval) {
      clearInterval(t.interval);
    }
  }

  private updateIntervals() {
    for (let t of Object.values(this.timers)) this.updateInterval(t);
  }

  private updateInterval(t: TimerContext) {
    let intervalMs = null;

    if (this.focused) intervalMs = t.options.focusInterval;

    if (this.visible && !intervalMs) intervalMs = t.options.visibleInterval;

    if (!intervalMs) intervalMs = t.options.hiddenInterval;

    const func = () => {
      t.lastRun = Date.now();
      if (t.ngzone && !NgZone.isInAngularZone()) this.ngzone.run(() => t.callback());
      else if (!t.ngzone && NgZone.isInAngularZone()) this.ngzone.runOutsideAngular(() => t.callback());
      else t.callback();
    };
    if (!intervalMs && t.interval) {
      setTimeout(func, t.intervalMs - (Date.now() - t.lastRun));
      clearInterval(t.interval);
      t.interval = null;
      t.intervalMs = null;
    } else {
      if (intervalMs && t.intervalMs != intervalMs) {
        if (t.interval) clearInterval(t.interval);
        if (t.lastRun && Date.now() - t.lastRun >= intervalMs) setTimeout(func, 0);
        t.intervalMs = intervalMs;
        t.interval = setInterval(func, intervalMs);
      }
    }
  }
}
