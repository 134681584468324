<div class="u-input-group">
  <div class="p-inputgroup" [ngClass]="{ focus: focus, disabled: disabled }">
    <span class="p-inputgroup-addon group" [style.height]="heightInput">
      <p *ngIf="text" [ngClass]="[inputSize + '-input']">{{ text }}</p>
      <u-icon *ngIf="icon" [ngClass]="[inputSize + '-input']" [model]="icon"> </u-icon>
    </span>
    <u-input
      style="width: 100%"
      [placeholder]="placeholder"
      [model]="model"
      [disabled]="disabled"
      [styles]="styles"
      [maxlength]="maxLength"
      [isSearchInput]="isSearchInput"
      [inputSize]="inputSize"
      [removeText]="removeText"
      [inputFocus]="focus"
      (onChange)="onChangeEvent($event)"
      (onFocus)="onFocusEvent($event)"
      (onBlur)="onBlurEvent($event)"
    ></u-input>
  </div>
</div>
