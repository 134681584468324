import { GoLinks } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class GoLinksRpcInvoker implements GoLinks.Service {
  @invoker
  get all$(): Observable<GoLinks.ListResponse> {
    return;
  }
  @invoker
  get(name: string): Promise<GoLinks.GetByIdResponse> {
    return;
  }

  @invoker
  create(request: GoLinks.CreateRequest): Promise<void> {
    return;
  }

  @invoker
  update(request: GoLinks.UpdateRequest): Promise<number> {
    return;
  }

  @invoker
  delete(id: string): Promise<void> {
    return;
  }

  @invoker
  getVisits(request: GoLinks.GetVisitsRequest): Promise<GoLinks.GetVisitsResponse> {
    return;
  }

  @invoker
  getBatchVisits(request: GoLinks.GetVisitsBatchRequest): Promise<GoLinks.GetVisitsBatchResponse> {
    return;
  }

  @invoker
  createVisit(request: GoLinks.CreateVisitRequest): Promise<void> {
    return;
  }

  @invoker
  refresh(): Promise<void> {
    return;
  }
}
