import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Intent } from 'src/app/bar/views/hub/shared/model';

@Component({
  selector: 'button-solid',
  templateUrl: './button-solid.component.html',
  styleUrls: ['./button-solid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonSolidComponent {
  @Input() intent: Intent = 'primary';
  @Input() faIcon: string;
  @Input() disabled: boolean;
  @Input() rounded: { size: string };
  @Input() transparent: boolean;

  @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter();

  emitClick($event) {
    if (this.disabled) return;
    this.onClick.emit($event);
  }

  get StyleClass() {
    return {
      [this.intent]: true,
      rounded: this.rounded ? this.rounded.size : false,
      disabled: this.disabled,
      transparent: this.transparent,
    };
  }
}
