<div
  [class]="'wiki-card-popup-wrapper ' + (previewWidthBreakpoint ?? '')"
  [class.window]="pageMode === 'window'"
  [class.inline-mode]="['inline', 'full'].includes(pageMode)"
  (click)="closePopup()"
>
  <div
    #contextContainer
    class="wiki-card-popup-container"
    [class.fullPage]="pageMode === 'full'"
    [class.window]="pageMode === 'window'"
    [class.inline-mode]="['inline', 'full'].includes(pageMode)"
    (click)="$event.stopPropagation()"
  >
    <ng-container *ngIf="errorState; else contentSection">
      <ng-container *ngTemplateOutlet="errorSection"></ng-container>
    </ng-container>
  </div>
</div>

<!-- Content Section -->
<ng-template #contentSection>
  <div class="header-section">
    <wiki-card-header-line
      [class.add-drag]="pageMode === 'window'"
      [collection]="collection"
      [wikiCard]="card"
      [updatedVerification]="updatedVerification"
      [wikiCardPermissionRole]="model?.permissionRole"
      (closePopup)="closePopup()"
      (openExternal)="openExternal($event)"
      (duplicateWikiCard)="duplicateWikiCard()"
      (moved)="updateBreadcrumbsAndTitle($event)"
      (switchView)="onSwitchView($event)"
      [pageMode]="pageMode"
      [class]="previewWidthBreakpoint ?? ''"
      [breadcrumbsItems]="breadcrumbsItems"
      [viewMode]="model?.viewMode"
      [showCloseButton]="showCloseButton"
    ></wiki-card-header-line>

    <ng-container *ngTemplateOutlet="subHeaderSection"> </ng-container>
  </div>

  <requests-history-line
    *ngIf="hasRequests && publishedMode"
    data-cy="verification-requests"
    class="card-verification-requests-history"
    (openRequestsHistory)="openRequestsHistory()"
  ></requests-history-line>

  <ng-container *ngTemplateOutlet="titleSection"> </ng-container>

  <div class="editor-container" #editorContainer>
    <div class="editor-wrapper" *ngIf="contentLoaded; else loadingContent" (outsideClick)="outsideClick()">
      <editor
        #editor
        class="wiki-editor"
        data-cy="card-editor"
        [class.wiki-viewer]="publishedMode"
        [init]="editorInit"
        (onInit)="onInitEditor($event)"
        [(ngModel)]="html"
        (ngModelChange)="onHtmlChange($event)"
      ></editor>
    </div>
    <ng-container *ngIf="!visibleEditor">
      <ng-container *ngTemplateOutlet="loadingContent"></ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="model?.permissionRole === 'write' || (model?.permissionRole === 'read' && tags?.length)">
    <ng-container *ngTemplateOutlet="footerSection"> </ng-container>
  </ng-container>
</ng-template>

<!-- Loading Section -->
<ng-template #loadingContent>
  <div class="hidden-card-editor">
    <result-ghost class="ghost-card" [showAvatar]="false" [ghostingLine]="3"></result-ghost>
  </div>
</ng-template>

<!-- Title Section -->
<ng-template #titleSection>
  <div class="card-title" ghost [ghostUntil]="!!title" [ghostStyle]="{ width: '50%', height: '40px', margin: '0 24px 12px 24px' }">
    <div class="title-container">
      <u-textarea
        #textAreaTitle
        [rows]="textareaRows"
        [maxLength]="300"
        [class.new-title]="initialTitle || title?.length === 0"
        class="no-state inline-input"
        [placeholder]="initialTitle ? title : COLLECTION_CONTENT.untitled"
        [model]="initialTitle ? '' : title"
        [autofocus]="false"
        [readonly]="publishedMode"
        (click)="onInputClick()"
        (onChange)="titleChanged($event)"
        (onFocus)="inputKeyDown($event)"
        (keydown)="inputKeyDown($event)"
        (onEnter)="inputKeyDown($event.target)"
        [truncateLines]="true"
        [pTooltip]="tooltipText"
        [tooltipPosition]="'bottom'"
        tooltipStyleClass="u-tooltip"
      ></u-textarea>
    </div>
  </div>
</ng-template>

<!-- Sub Header Section -->
<ng-template #subHeaderSection>
  <ng-container *ngIf="draftMode; else publishedSubHeader">
    <wiki-card-draft-sub-header [autoSaveStatus]="autoSaveStatus" [pageMode]="pageMode" [draft]="card?.draft"> </wiki-card-draft-sub-header>
  </ng-container>
  <ng-template #publishedSubHeader>
    <wiki-card-sub-header
      [collection]="collection"
      [wikiCard]="card"
      [updatedVerification]="updatedVerification"
      [pageMode]="pageMode"
    ></wiki-card-sub-header>
  </ng-template>
</ng-template>

<!-- Footer Section -->
<ng-template #footerSection>
  <div class="divider"></div>
  <ng-container *ngIf="draftMode; else publishedFooter">
    <wiki-card-draft-footer
      (published)="publishCard()"
      [pageMode]="pageMode"
      [class]="previewWidthBreakpoint ?? ''"
      [publishInProgress]="publishInProgress"
    ></wiki-card-draft-footer>
  </ng-container>

  <ng-template #publishedFooter>
    <wiki-card-footer
      [ghostUntil]="!!card"
      [tags]="tags"
      [closeDropdownTags]="closeDropdownTags"
      (tagChange)="onTagChange($event)"
      [canEdit]="model?.permissionRole === 'write'"
      [pageMode]="pageMode"
    ></wiki-card-footer>
  </ng-template>
</ng-template>

<!-- Error Section -->
<ng-template #errorSection>
  <div class="no-available-wrapper">
    <u-button
      *ngIf="showCloseButton"
      class="icon-button-clean close-popup-icon"
      elementSize="small"
      fontIcon="times"
      type="secondary"
      [styles]="{ fontSize: '20px', width: '28px', height: '28px' }"
      (click)="closePopup()"
    ></u-button>
    <div class="no-available-container">
      <div class="no-available">
        <u-icon class="no-available-icon" [model]="{ type: 'font', value: 'icon-lock-light' }" [styles]="{ fontSize: '24px' }"> </u-icon>
        <div class="text">
          <span class="title">Not available</span>
          <span class="sub-title">Access to this page is restricted</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
