import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeStyle, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safe',
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, ctx: 'style' | 'resource' | 'url' | 'html' = 'style'): SafeUrl | SafeStyle | SafeResourceUrl {
    switch (ctx) {
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle('url(' + value + ')');
      case 'resource':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      default:
        return this.sanitizer.bypassSecurityTrustUrl(value);
    }
  }
}
