<div class="scroll-area" *ngIf="items?.length > 0" #view>
  <ng-scrollbar>
    <ng-container *ngFor="let item of items; index as i; trackBy: trackResult">
      <web-search-results-item
        #scrollItem
        [class.keyboardOn]="keyboardOn"
        [class.selected]="selectedIndex === i"
        [selected]="selectedIndex === i"
        class="web-search-result-item"
        [model]="item"
        [firstResult]="i === 0"
        [engine]="currentEngine"
        (click)="onItemClick(i)"
        (mouseover)="onHoverItem(i)"
      ></web-search-results-item>
    </ng-container>
  </ng-scrollbar>
</div>
