import { Pipe, PipeTransform } from '@angular/core';

/** Make element repeat n times.
 * @example *ngFor="let i of (5 | fill)" -> repeat element 5 times
 */

@Pipe({
  name: 'fill',
})
export class FillPipe implements PipeTransform {
  transform(n: number): Array<any> {
    return new Array(n).fill(1);
  }
}
