import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lineDownAt',
})
export class LineDownAtPipe implements PipeTransform {
  transform(value: string, limit: number): string {
    if (!value) return;
    if (value.length <= limit) return value;

    let lineCount = value.length % limit;

    let final = [];
    for (let i = 0, length = value.length; i < length; i += limit) {
      final.push(value.substr(i, limit));
    }

    return final.join(' <br> ');
  }

  chunk(str, n) {
    var ret = [];
    var i;
    var len;

    for (i = 0, len = str.length; i < len; i += n) {
      ret.push(str.substr(i, n));
    }

    return ret;
  }
}
