import { Injectable } from '@angular/core';
import { EmbedService } from '@shared/embed.service';
import { EventsService, InternetService, LogService } from '@shared/services';
import { RouterService } from '@shared/services/router.service';
import { Logger } from '@unleash-tech/js-logger';
import { SearchSession } from '.';
import { FiltersService } from '../filters.service';
import { HubService } from '../hub.service';
import { SearchClientFactoryService } from './search-client-factory-service';

@Injectable()
export class SearchService {
  private logger: Logger;
  private searchSessions: { [key: string]: SearchSession } = {};

  constructor(
    private internetService: InternetService,
    private hubService: HubService,
    private logService: LogService,
    private filtersService: FiltersService,
    private eventsService: EventsService,
    private searchClientFactory: SearchClientFactoryService,
    private routerService: RouterService,
    private embedService: EmbedService
  ) {
    this.logger = logService.scope('SearchService');
    if (embedService) {
      embedService.show$.subscribe(() => {
        this.sendEmbedShowEvents();
      });
    }
  }

  getOrCreateSearchSession(name: string) {
    this.logger.info('creating a new search session', { sessionName: name });
    if (!this.searchSessions[name]) {
      this.searchSessions[name] = new SearchSession(
        name,
        this.hubService,
        this.logService,
        this.internetService,
        this.filtersService,
        this.eventsService,
        this.searchClientFactory,
        this.embedService,
        this.routerService,
        this
      );
    }
    return this.searchSessions[name];
  }

  isSearchAlive(sessionName: string, id: number) {
    return this.searchSessions[sessionName]?.searchAlive(id);
  }

  private sendEmbedShowEvents(): void {
    const location: string = this.hubService.currentLocation;
    this.eventsService.event('pageview', { location: { title: location } });
    const defaultSearchName: string = 'results';
    const searchName = ['results', 'calendar', 'home', 'collections'].includes(location) ? location : defaultSearchName;
    this.sendImpressionEventByName(searchName);
  }

  private sendImpressionEventByName(name: string) {
    const searchSession: SearchSession = this.searchSessions[name];
    if (searchSession?.currentSearch?.workContext?.searchTelemetry) {
      searchSession.setImpressionEvent(searchSession.currentSearch.workContext.id, searchSession.currentSearch.workContext.searchTelemetry);
    }
  }
}
