import { Links } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class LinksRpcInvoker implements Links.Service {
  @invoker
  one$(linkId: string): Observable<Links.DisplayItem> {
    return;
  }

  @invoker
  async one(linkId: string): Promise<Links.DisplayItem> {
    return;
  }

  @invoker
  byApp$(appId: string): Observable<Links.DisplayItem[]> {
    return;
  }

  @invoker
  create(createLink: Links.CreateLink<any, any>): Promise<Links.CreateResult> {
    return;
  }

  @invoker
  refresh(linkId: string, createLink: Links.CreateLink<any, any>): Promise<void> {
    return;
  }

  @invoker
  update(linkId: string, payload: Partial<Links.CreateLink<any, any>>): Promise<Links.DisplayItem> {
    return;
  }

  @invoker
  delete(id: string): Promise<void> {
    return;
  }

  @invoker
  get all$(): Observable<Links.DisplayItem[]> {
    return;
  }

  @invoker
  get visible$(): Observable<Links.DisplayItem[]> {
    return;
  }

  sync(linkId: string): Promise<void> {
    return;
  }

  @invoker
  refreshAll(): Promise<void> {
    return;
  }

  @invoker
  get peopleLinkId$(): Observable<string> {
    return;
  }
}
