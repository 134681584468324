<u-multi-select
  [ngClass]="{ disabled: disabled }"
  [styles]="styles ?? { width: 'fit-content', minWidth: MIN_WIDTH_MULTI_SELECT }"
  [placeholder]="model.title"
  [autoSort]="true"
  [model]="options"
  [multiSelectIcon]="multiSelectIcon"
  [(ngModel)]="selectedItems"
  (onSelectedChange)="onChange($event)"
  (onFilter)="onFilter($event)"
  (onPanelShow)="onPanel(true)"
  (onPanelHide)="onPanel(false)"
  (optionsUpdated)="optionsUpdated($event)"
  (onClearAllEvent)="onClearAllEvent()"
  [noCheckbox]="noCheckbox"
  [filterPlaceholder]="filterPlaceholder ?? model.title + '...'"
  [displayLabel]="'title'"
  [limitShowSelectedLabels]="limitShowSelectedLabels"
  [isNoColorCheckbox]="true"
  [showSelectedItemIcons]="showSelectedItemIcons"
  [itemSize]="model?.viewDetails?.isTwoLine ? 46 : 36"
  [virtualScroll]="virtualScroll && model?.values?.length > 5"
  [keepPlaceholder]="keepPlaceholder"
  [appendTo]="appendTo"
  [filterBy]="'value,title,subtitle,label'"
  [inlineFilterPlaceHolder]="inlineFilterPlaceHolder"
  (onKeyboardItemChange)="onKeyboardItemChangeEvent($event)"
  [showClear]="showClear"
  [oneValue]="oneValue"
  [showTooltipIcons]="showTooltipIcons"
  [hideOnSelect]="hideOnSelect"
  [showHasValues]="showHasValues"
  (onBack)="onBack.emit()"
  [displayBackIcon]="displayBackIcon"
  [shortSelected]="shortSelected"
  [showClearAll]="showClearAll"
  [wideWidth]="wideWidth"
  [enableBack]="enableBack"
  [enableWriteValue]="false"
  [clearSearchAfterSelect]="clearSearchAfterSelect"
  [separateSelected]="separateSelected"
  [showItemTooltip]="false"
  [hideSearchFilter]="hideSearchFilter"
  [readonly]="model.readonly"
>
  <ng-template let-item pTemplate="item">
    <ng-container *ngIf="displayCustomDateItemTemplate && item.type === 'custom-date'; else regularItem">
      <ng-container *ngTemplateOutlet="datePicker"></ng-container>
    </ng-container>
    <ng-template #regularItem>
      <ng-container *ngIf="!isFullDetailLine; else fullDetail">
        <span>{{ item.value }}</span>
      </ng-container>
      <ng-template #fullDetail>
        <div class="full-detail-line">
          <div class="details">
            <u-icon
              class="detail-icon"
              *ngIf="model?.viewDetails?.showItemIcon"
              [model]="item.icon"
              [styles]="{ fontSize: '16px' }"
            ></u-icon>
            <div class="option-text">
              <div *ngIf="item.title" class="title" [class.limit-length]="item.data?.showLabelOnTag && item.subtitle">{{ item.title }}</div>
              <div *ngIf="item.subtitle" class="subtitle" [class.limit-length]="item.data?.showLabelOnTag">{{ item.subtitle }}</div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </ng-template>

  <ng-container *ngIf="shortSelected">
    <ng-template let-value pTemplate="selectedItems">
      <short-selected
        [multiSelectIcon]="multiSelectIcon"
        [placeholder]="model.title"
        [countSelected]="customSelectedCount != undefined ? customSelectedCount : selectedItems.length"
      ></short-selected>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="!shortSelected">
    <ng-template pTemplate="selectedItems">
      <div
        class="date-picker-selected-items-wrapper"
        [pTooltip]="showFilterTooltip ? filterTooltip : ''"
        tooltipPosition="top"
        tooltipStyleClass="u-tooltip"
      >
        <u-icon [styles]="{ fontSize: '14px', width: '16px' }" [model]="multiSelectIcon" class="padding-left"></u-icon>
        <div class="placeholder" *ngIf="keepPlaceholder">
          {{ model.title + (selectedItems?.length || displayCustomDateTemplate ? ':' : '') }}
        </div>
        <ng-container *ngIf="!displayCustomDateTemplate; else dateSelectedItems">
          <div *ngFor="let item of selectedItems | slice : 0 : limitShowSelectedLabels; let last = last" class="selected-items">
            <u-icon
              *ngIf="showSelectedItemIcons"
              class="selected-icon"
              [styles]="{ fontSize: '14px', width: '14px' }"
              [model]="item.icon"
            ></u-icon>
            <div class="selected-item-title">
              {{ item?.title }}
            </div>
            <ng-container *ngIf="!last">
              <div class="coma">,</div>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #dateSelectedItems> <ng-container *ngTemplateOutlet="datePicker"></ng-container></ng-template>
      </div>
    </ng-template>
  </ng-container>
</u-multi-select>

<ng-template #datePicker>
  <div class="date-picker-selected">
    <div
      *ngIf="
        !!customValueSelected &&
          ((customValueSelected.type !== 'range' && customValueSelected.start) ||
            (customValueSelected.type === 'range' && (customValueSelected.start || customValueSelected.end)));
        else empty
      "
    >
      <div class="content" *ngIf="customValueSelected?.type !== 'range'">
        <p class="type">{{ customValueSelected?.type | titlecase }}:</p>
        <p class="date">{{ customStartDisplay || customEndDisplay }}</p>
      </div>
      <div class="content" *ngIf="customValueSelected?.type === 'range'">
        <p class="date">{{ customStartDisplay }}</p>
        <u-icon class="arrow-icon" [model]="{ type: 'font', value: 'icon-arrow-right' }"></u-icon>
        <p class="date">{{ customEndDisplay }}</p>
      </div>
    </div>
    <ng-template #empty>
      <div class="content">
        <div *ngIf="customValueSelected?.type === 'range'" class="range-type">
          <p class="type">From - To</p>
        </div>
        <div *ngIf="customValueSelected?.type !== 'range'" class="type-container">
          <div class="single-type">
            <p class="type">{{ customValueSelected?.type | titlecase }}</p>
          </div>
        </div>
        <p>(mm/dd/yyyy)</p>
      </div>
    </ng-template>
  </div>
</ng-template>
