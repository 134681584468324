import { Injectable } from '@angular/core';
import { Collections, Results, Search, Commands } from '@local/client-contracts';
import { isIcon } from '@shared/utils';
import { ItemType } from './collections.service';
import { WorkspacesService } from './workspaces.service';
import { SearchResults, isPerson, isRelevantPeopleItem } from '../views';
import { cloneDeep } from 'lodash';
import { AvatarListService } from './avatar-list.service';
import { isLiveCollection, isStaticCollection, isWikiCollection } from '@local/common-web';
import { COLLECTION_TYPE_TO_ICON_MAP } from '../views/collections-page/helpers/collection-type-icon-map';
import { SelectedItem } from '../components/selected-item-popup/selected-item-popup.component';
import { CommandsService } from 'src/app/bar/services/commands/commands.service';
@Injectable()
export class CollectionsUtilService {
  private isOwnerOrAdmin: boolean;

  constructor(
    private workspacesService: WorkspacesService,
    private avatarListService: AvatarListService,
    private commandsService: CommandsService
  ) {
    this.workspacesService.ownerOrAdmin$.subscribe((s) => {
      this.isOwnerOrAdmin = s;
    });
  }

  cleanResourceItemData(res: Search.ResultResourceItem): Search.ResultResourceItem {
    const newRes = cloneDeep(res);
    if (isRelevantPeopleItem(newRes)) {
      delete newRes.expert;
    }
    if (isPerson(newRes)) {
      delete newRes?.['settings'];
    }
    if (newRes?.link?.id) {
      newRes.link.id = null;
    }
    const fixIcon = (icon: Results.Icon | Results.FontIcon) => {
      if (!icon || !isIcon(icon)) {
        return;
      }
      icon.lightUrl = fixIconUrl(icon.lightUrl);
      icon.darkUrl = fixIconUrl(icon.darkUrl);
    };
    const fixIconUrl = (url: string): string => {
      return url?.startsWith('local://') ? url.replace('local://', 'https://') : url;
    };
    const type: string = newRes.type;
    if (type !== 'go-link') {
      delete newRes['data'];
    }
    delete newRes?.highlights;
    const traits = newRes.resource?.traits;
    for (const key in traits) {
      if (key.includes('.')) {
        delete newRes.resource.traits[key];
      }
    }
    fixIcon(newRes?.view?.icon);
    fixIcon(newRes?.view?.iconOverlay);
    return newRes;
  }

  canEdit(collection: Collections.Collection, type: ItemType = 'collection', tabId?: string): boolean {
    if (!collection) {
      return false;
    }
    if (type === 'pin') {
      return this.isOwnerOrAdmin;
    }
    return (
      collection.isShared === false ||
      collection.shareOptions?.level === 'Private' ||
      collection.effectiveScopes?.findIndex((e) => e.scope === 'write') > -1
    );
  }

  async initSelectedItems(collections: Collections.Collection[], selectedItemId?: string): Promise<SelectedItem[]> {
    const itemsList = [];
    const avatarLists = collections?.map((c) => this.avatarListService.createAvatarList(c.accountId, c.shareOptions));
    let selectedItem: SelectedItem;
    for (let index = 0; index < collections.length; index++) {
      const element = collections[index];
      let itemsNumber: number = 0;
      if (element && isStaticCollection(element)) {
        itemsNumber = element.items?.filter((e) => e.kind !== 'header').length;
      } else if (isWikiCollection(element)) {
        itemsNumber = (element as any)?.cardsCount || 0;
      } else if (isLiveCollection(element)) {
        itemsNumber = element.resultsCount;
      }
      const item = {
        avatarList: await avatarLists[index],
        collection: element,
        name: element.title,
        id: element.id,
        icon: COLLECTION_TYPE_TO_ICON_MAP[element.kind],
        itemsNumber,
      };
      if (selectedItemId && item.id === selectedItemId) {
        selectedItem = item;
      } else {
        itemsList.push(item);
      }
    }
    if (selectedItem) {
      itemsList.unshift(selectedItem);
    }
    return itemsList;
  }

  openLearnMore(link: string) {
    window.open(link);
  }

  async openWikiCard(collectionId?: string) {
    const item = {
      action: null,
      type: 'result',
      id: 'new',
      filterType: 'Wiki Card',
      resource: {
        externalId: 'new',
        appId: 'wiki',
      },
    };
    item.action = {
      type: 'wiki card',
      click: { primary: { type: 'preview' } },
    };
    if (collectionId) {
      item['collectionId'] = collectionId;
    }
    this.commandsService.executeCommand({
      type: 'preview',
      model: item,
      previewable: true,
      state: 'popup',
      pageType: 'search',
    } as Commands.Preview<SearchResults>);
  }
}
