import { Injectable, Optional } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { observable } from '@local/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TitleBarService {
  constructor(@Optional() service?: Title) {
    if (service) {
      this.active$.pipe(untilDestroyed(this)).subscribe((v) => {
        service.setTitle(`${v ? v + ' | ' : ''}Unleash`);
      });
    }
  }
  private _active$ = new BehaviorSubject<string>('');

  get active() {
    return this._active$.getValue();
  }

  set active(value: string) {
    this._active$.next(value);
  }

  @observable
  get active$(): Observable<string> {
    return this._active$.asObservable();
  }

  private _locationTitle$ = new BehaviorSubject<string>('');

  set locationTitle(v: string) {
    this._locationTitle$.next(v);
  }

  get locationTitle(): string {
    return this._locationTitle$.getValue();
  }
}
