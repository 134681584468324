import { Collections } from '@local/client-contracts';

export const COLLECTION_KIND_TO_FILTER: { [key in Collections.Kind]: any } = {
  Live: {
    value: 'Live',
    title: 'Live',
    icon: { type: 'font-icon', value: 'icon-live-collection' },
  },

  Static: {
    value: 'Static',
    title: 'Curated',
    icon: { type: 'font-icon', value: 'icon-curated' },
  },
  Wiki: {
    value: 'Wiki',
    title: 'Wiki',
    icon: { type: 'font-icon', value: 'icon-wiki' },
  },
};
