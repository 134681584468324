export function getFileSize(size: number, includeBytes = true, includeDecimalPoint = true): string {
  const fileSizeBytes = size;

  if (fileSizeBytes < 1024) {
    return includeBytes ? fileSizeBytes.toFixed(0) + ' bytes' : '1 KB';
  } else if (fileSizeBytes < 1024 * 1000) {
    const fileSizeKB = fileSizeBytes / 1024;
    return fileSizeKB.toFixed(includeDecimalPoint ? 1 : 0) + ' KB';
  } else {
    const fileSizeMB = fileSizeBytes / (1024 * 1024);
    return fileSizeMB.toFixed(includeDecimalPoint ? 1 : 0) + ' MB';
  }
}
