<p-galleria
  class="u-galleria"
  [value]="model"
  [style]="styles"
  [circular]="circular"
  [showThumbnails]="false"
  [autoPlay]="autoPlay"
  [showIndicators]="showIndicators"
  [showItemNavigators]="showNavigators"
  [showIndicatorsOnItem]="true"
  [showItemNavigatorsOnHover]="true"
  (click)="pageClick($event)"
  (activeIndexChange)="activeIndexChange($event)"
  [transitionInterval]="3000"
>
  <ng-template let-item pTemplate="item">
    <div class="image">
      <ng-container *ngTemplateOutlet="itemTemplate || defaultItem; context: { $implicit: item }"></ng-container>
    </div>
  </ng-template>
</p-galleria>

<ng-template #defaultItem let-value pTemplate="item">
  <div class="product-item">
    <div class="product-item-content">
      <img [attr.hasLink]="!!value.link" [src]="value.imageSrc | safe : 'resource'" alt="" />
    </div>
  </div>
</ng-template>
