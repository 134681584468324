import { fromEvent, debounceTime, map, startWith } from 'rxjs';

export function windowSizeObserver(dTime = 300) {
  return fromEvent(window, 'resize').pipe(
    debounceTime(dTime),
    map((event) => {
      const window = event.target as Window;

      return { width: window.innerWidth, height: window.innerHeight };
    }),
    startWith({ width: window.innerWidth, height: window.innerHeight })
  );
}
