import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, QueryList } from '@angular/core';
import { BulletItemComponent } from './bullet/bullet-item.component';

@Component({
  selector: 'bullet-list',
  templateUrl: './bullet-list.component.html',
  styleUrls: ['./bullet-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulletListComponent implements AfterContentInit {
  constructor(private cdr: ChangeDetectorRef) {}

  @ContentChildren(BulletItemComponent) bullets: QueryList<BulletItemComponent>;

  ngAfterContentInit() {
    // let activeBullets = this.bullets.filter((item)=>item.active);
  }
}
