import { OAuth, OAuth1 } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class OAuth1SessionsRpcInvoker implements OAuth1.SessionService {
  @invoker
  creating$(id: string): Observable<void> {
    return;
  }

  @invoker
  completed$(id: string): Observable<OAuth1.SessionResult> {
    return;
  }

  @invoker
  complete(url: string): Promise<void> {
    return;
  }

  @invoker
  create(id: string, request: OAuth1.Request, appId: string): Promise<OAuth.Session> {
    return;
  }

  @invoker
  reset(id: string): Promise<void> {
    return;
  }

  @invoker
  destroy(id: string, reason?: string): Promise<void> {
    return;
  }
}
