import { Injectable } from '@angular/core';
import { LogService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';
import { ParamSelections, Style } from '@local/client-contracts';
import { BehaviorSubject } from 'rxjs';
import { HubService } from './hub.service';

export interface ParamSelectionsModel {
  name: string;
  icon: Style.EntityIcon<Style.EntityIconType>;
  resourceName: string;
  type: 'account' | 'resource' | 'collection';
  highlights: ParamSelections.Item[];
  recency: ParamSelections.Item[];
}

@Injectable()
export class ParamsSelectorService {
  private logger: Logger;
  private readonly STATE_KEY = 'ps';
  private readonly STATE_VALUE = 'true';
  private _paramsSelector: ParamSelectionsModel;

  get paramsSelector(): ParamSelectionsModel {
    return this._paramsSelector;
  }

  constructor(logService: LogService, private hubService: HubService) {
    this.logger = logService.scope('ParamsSelectorService');
  }

  addParamsSelector(model: ParamSelectionsModel) {
    this.hubService.setState(this.STATE_KEY, this.STATE_VALUE);
    this._paramsSelector = model;
  }

  removeParamsSelector() {
    this.hubService.setState(this.STATE_KEY, null);
    this._paramsSelector = null;
  }
}
