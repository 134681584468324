import { ComponentType } from '@angular/cdk/overlay';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Data, Params } from '@angular/router';
import { Config } from '@environments/config';
import {
  Applications,
  Calculators,
  Collections,
  Commands,
  Experiences,
  Filters,
  Fyis,
  Links,
  LocalActions,
  NativeApp,
  NavTree,
  Omnibox,
  Results,
  Search,
  SessionInfo,
  WebSearch,
  Window,
} from '@local/client-contracts';
import { AppName, Constants, ManualPromise } from '@local/common';
import {
  BrowserName,
  ViewSettings,
  isEmbed,
  isLiveCollection,
  isMac,
  isNativeWindow,
  isStaticCollection,
  isWikiCollection,
  launcherResultsViewSettings,
  resultsViewSettings,
} from '@local/common-web';
import {
  KeyName,
  delay,
  getModifiers,
  isEnterKey,
  isKey,
  isPrintableKey,
  isSemicolonKey,
  isSingleKey,
  keyCodes,
  removeModifiers,
} from '@local/ts-infra';
import { PopupRef, PopupService, UButtonComponent, UInputComponent } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EmptyStateModel } from '@shared/components/app-empty-state/app-empty-state.component';
import { DisplaySearchFilterValue, Filter, FilterChangeData } from '@shared/components/filters/models';
import { ResultsFiltersBoxComponent } from '@shared/components/filters/results-filters-box/results-filters-box.component';
import { EmbedService } from '@shared/embed.service';
import { EventInfo, EventInfoResource, EventsService, LogService, TelemetryService, WindowService } from '@shared/services';
import { AppService } from '@shared/services/app.service';
import { ApplicationsService } from '@shared/services/applications.service';
import { BrowserExtensionService } from '@shared/services/browser-extension.service';
import { BrowserTabsService } from '@shared/services/browser-tabs.service';
import { DatePickerService } from '@shared/services/date-picker.service';
import { FlagsService } from '@shared/services/flags.service';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { LinksService } from '@shared/services/links.service';
import { NativeAppLinkService } from '@shared/services/native-app-link.service';
import { ResourcesService } from '@shared/services/resources.service';
import { RouterService } from '@shared/services/router.service';
import { componentServicesRpcProvider } from '@shared/services/rpc.service';
import { SessionService } from '@shared/services/session.service';
import { SyncsService } from '@shared/services/syncs.service';
import { getWidthBreakpointScreen, isDynamicCommand, isNewCollectionCommand, isPreviewCommand, windowSizeObserver } from '@shared/utils';
import { BreakpointsWidth } from '@shared/utils/break-point.utils';
import { resultHeaderCountKey } from '@shared/utils/header-builder.util';
import { Logger } from '@unleash-tech/js-logger';
import { cloneDeep, isArray, isEmpty, isEqual, isNumber, mergeWith, uniq, uniqBy } from 'lodash';
import { NgScrollbar } from 'ngx-scrollbar';
import * as pluralize from 'pluralize';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, debounce, firstValueFrom, of, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { AppPopupComponent, AppPopupData } from 'src/app/bar/components/app-popup/app-popup.component';
import { BrowseBarModel } from 'src/app/bar/components/browse-bar/browse-bar.component';
import { PreviewFocus } from 'src/app/bar/models/preview-focus';
import { SearchGroup } from 'src/app/bar/models/search-group';
import { WorkspacesService } from 'src/app/bar/services';
import { CollectionsUtilService } from 'src/app/bar/services/collections-util.service';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { ExperiencesService } from 'src/app/bar/services/experiences.service';
import { GoLinksService } from 'src/app/bar/services/go-links.service';
import { ParamSelectionsModel, ParamsSelectorService } from 'src/app/bar/services/params-selector.service';
import { PreviewKeyboardService } from 'src/app/bar/services/preview-keyboard.service';
import { PreviewService, PreviewViewModel } from 'src/app/bar/services/preview.service';
import { QuestionnaireService } from 'src/app/bar/services/questionnaire.service';
import { SearchOptions, SearchResultContext, SearchService, SearchSession, WorkContext } from 'src/app/bar/services/search';
import { SearchParamsService } from 'src/app/bar/services/search-params.service';
import { AnswersSourceSettings, StaticItemsSourceSettings } from 'src/app/bar/services/search/client';
import { LinkResourcesResultExtra } from 'src/app/bar/services/search/client/link-resources/link-resources-result-extra';
import { MailResultExtra } from 'src/app/bar/services/search/client/mail';
import {
  isAnswersSettings,
  isGoLinksSettings,
  isGroupResourcesSettings,
  isLinkResourcesSettings,
  isMailResourcesSettings,
  isPeopleSettings,
  isRelevantPeopleSettings,
  isStaticCollectionSettings,
  isVisitsSettings,
  isWikiCollectionSettings,
} from 'src/app/bar/services/search/client/source-setings.util';
import { RetrySourceModel } from 'src/app/bar/services/search/models/retry-source-modal';
import { SourceResult } from 'src/app/bar/services/search/source-result';
import { ShowToasterService } from 'src/app/bar/services/show-toaster.service';
import { SidebarService } from 'src/app/bar/services/sidebar.service';
import { SortService } from 'src/app/bar/services/sort.service';
import { SuggestionsSearchService } from 'src/app/bar/services/suggestions-search.service';
import { SuggestionsMultipleProviders } from 'src/app/bar/services/suggestions/suggestions-multiple-providers';
import { SummaryService } from 'src/app/bar/services/summary.service';
import { WikiCardsService } from 'src/app/bar/services/wikis/wiki-cards.service';
import { WikiDraftsService } from 'src/app/bar/services/wikis/wiki-drafts.service';
import { WikisService } from 'src/app/bar/services/wikis/wikis.service';
import { SEARCH_WITH_FILTERS_PLACEHOLDER } from 'src/app/bar/utils/constants';
import { ToasterData } from '../../../components/toaster/toaster-data';
import { ToasterComponent } from '../../../components/toaster/toaster.component';
import { CommandsService } from '../../../services/commands/commands.service';
import { ResultCommandService } from '../../../services/commands/result-command.service';
import { FilterCounter, FiltersService } from '../../../services/filters.service';
import { FocusPosition, HubService, SearchMethod } from '../../../services/hub.service';
import { NavTreeService } from '../../../services/nav-tree.service';
import { ResultsService } from '../../../services/results.service';
import { SuggestionsService } from '../../../services/suggestions/suggestions.service';
import { TagsService } from '../../../services/tags.service';
import { CalcResultItemComponent } from '../../calculators/components/calc-result-item/calc-result-item.component';
import { ChatsService } from '../../chat-page/services/chats.service';
import { CollectionItemComponent } from '../../collections-page/components/collection-item/collection-item.component';
import { CollectionTag } from '../../collections-page/models';
import { CollectionViewType } from '../../collections-page/models/collection-view-type.model';
import { WikiCardType } from '../../collections-page/models/wiki-card-model';
import { CollectionPopupService } from '../../collections-page/services/collection-popup.service';
import { VerificationsCardsCommandsService } from '../../collections-page/services/verifications-cards-commands-service';
import { WikiCardPreviewService } from '../../collections-page/services/wiki-card-preview.service';
import { WikiItemSelectionPopupService } from '../../collections-page/services/wiki-item-selection-popup.service';
import { ConnectAppsModel } from '../../hub/connect-apps/connect-apps.component';
import { WEB_SEARCH_ITEMS } from '../../hub/shared/sidebar/menu-items';
import { PreviewContainerComponent } from '../../preview/components/preview-container/preview-container.component';
import { FilePreviewService } from '../../preview/file-preview/services/file-preview.service';
import { DirtyChangeStatus, PreviewComponent } from '../../preview/model/preview-component';
import { PreviewMode } from '../../preview/model/preview-mode';
import { SplitAreaPreviewModel } from '../../preview/model/split-area-preview.model';
import { PeopleService } from '../../preview/people-preview/services/people.service';
import { CreateGoLinkItemComponent } from '../../results/components/create-go-link-item/create-go-link-item.component';
import { ErrorItemComponent } from '../../results/components/error-item/error-item.component';
import { LocalActionsItemComponent } from '../../results/components/local-actions-item/local-actions-item.component';
import { RecentSearchItemComponent } from '../../results/components/recent-search-item/recent-search-item.component';
import { ResultsHeaderComponent } from '../../results/components/result-header/result-header.component';
import { ResultItemComponent } from '../../results/components/result-item/result-item.component';
import { StaticSearchItemType } from '../../results/components/static-search-item/static-search-item.model';
import { SyncingStateData } from '../../results/components/syncing-state/models';
import { FilterSuggestionData } from '../../results/models/filter-suggestion-data';
import { FilterTagData } from '../../results/models/filter-tag-data';
import { InvokeCommand } from '../../results/models/invoke-command.model';
import {
  AddToCollectionItem,
  AnswerFeedbackSearchItem,
  AnswerGenerateState,
  AnswerSearchItem,
  AnswerSearchReady,
  BrowserBookmarkItem,
  CommandContext,
  CreateGoLinkItem,
  DisplayItemData,
  FilterActivationMethod,
  GalleryGroup,
  GoToItem,
  HeaderItem,
  ImpressionResourceList,
  RecentSearchItem,
  RelevantPeopleItem,
  ResultDisplayModel,
  ResultItem,
  ResultsOrigin,
  RfpSearchItem,
  ScrollDirection,
  ScrollTrigger,
  SearchResults,
  Select,
  StaticSearchItem,
  SuggestionsItem,
  TelemetryTrigger,
} from '../../results/models/results-types';
import { Action, PageType } from '../../results/models/view-filters';
import { RecentSearchesService } from '../../results/recent-searches.service';
import { InfiniteScrollService } from '../../results/services/infinite-scroll.service';
import { EMPTY_STATE_LIST } from '../../results/utils/results-empty-state';
import { ResultSettings, SettingsFormat, SettingsPlatform } from '../../results/utils/results-settings';
import {
  descriptionsToBullets,
  getPrimaryActionClick,
  isAddToCollection,
  isAnswerItem,
  isAnswerSearch,
  isAssistantItem,
  isBookmark,
  isCalculation,
  isCollection,
  isCollectionUrl,
  isCreateGoLink,
  isErrorItem,
  isGalleryGroup,
  isGoLink,
  isGoTo,
  isHeader,
  isLocalAction,
  isOpenAdvancedSearch,
  isOpenChat,
  isPerson,
  isPreviewClickAction,
  isRecentSearch,
  isRelevantPeopleItem,
  isResourceItem,
  isResourceResult,
  isResult,
  isRfpItem,
  isSelectable,
  isSuggestion,
  isTab,
  isWebSearch,
  isWikiCard,
  listNameByType,
  pcAppName,
  supportPreview,
} from '../../results/utils/results.util';
import { SORT_OPTIONS, SortOption, getSortOptions } from '../../results/utils/sorts.utils';
import { APPS_SUPPORT_VISITS } from '../../results/utils/visits.util';
import { ResourceViewItemComponent } from '../view-item/resource-view-item.component';

type resultType = 'default' | 'user-search' | 'app-tag' | 'search-group';
export const ResultsQueryParams = ['q', 'if-', 'of-', 'search-', 'actionsOnly', 'sg', 'ps', 'ap', 'col', 'sort', 'q2'] as const;
export type PossibleResultsParams = (typeof ResultsQueryParams)[number];
export type PreviewItem = { item: Search.ResultResourceItem; trigger?: TelemetryTrigger; isFirst?: boolean; isLast?: boolean };
export type SuggestionFilters = { id: number; types: Search.FilterCountDetails[] };
type GalleryItems = RelevantPeopleItem;

export interface ResultDisplayContext {
  id: number;
  items: SearchResults[];
  lastHeaderIndex?: number;
  query?: string;
  clientSearchId?: string;
  sessionId?: string;
  itemHeights?: number[];
  searchCompleted?: boolean;
  sources?: SourceResult[];
  options?: SearchOptions;
  iterations?: number;
}

@UntilDestroy()
@Component({
  selector: 'results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
  providers: [componentServicesRpcProvider],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsComponent implements OnInit, OnDestroy, AfterViewChecked {
  private readonly PREVENT_PREVIEW_PAGES: PageType[] = ['golinks', 'assistants', 'collections', 'wikis', 'relevant-people'];
  private readonly MIN_WIDTH_ITEM_GALLERY = 195;
  private readonly ITEM_HEIGHT = {
    people: { list: 62, gallery: 120 },
    'relevant-people': { list: 58, gallery: 136 },
  };
  private readonly INITIAL_ITEMS_LIMIT = 14;

  itemIdTrack = (index, item: any) => {
    return item.type + '##' + item.id + '##' + item.title;
  };
  @ViewChild('resultsSplitArea') resultsRef: ElementRef;
  @ViewChildren('resultItem') resultsItems: QueryList<
    | ResultItemComponent
    | CalcResultItemComponent
    | ResultsHeaderComponent
    | LocalActionsItemComponent
    | ErrorItemComponent
    | ResourceViewItemComponent
    | RecentSearchItemComponent
    | CreateGoLinkItemComponent
    | CollectionItemComponent
  >;
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
  @ViewChild('wrapper') wrapperRef: ElementRef;
  @ViewChild('previewSplitArea') previewRef: ElementRef;
  @ViewChildren('resultItem', { read: ElementRef }) resultElements: QueryList<ElementRef>;
  @ViewChild('smallInputSearch') smallInputSearch: UInputComponent;
  @ViewChild(PreviewContainerComponent) previewContainer: PreviewContainerComponent;
  @ViewChild(ResultsFiltersBoxComponent) filtersContainer: ResultsFiltersBoxComponent;
  @ViewChild('scrollElement') infiniteScrollRef: ElementRef;

  @HostBinding('style.--scroll-height') get splitAreaHeight() {
    return `${this.resultsRef?.nativeElement?.offsetHeight || 0}px`;
  }

  displayedContext: ResultDisplayContext;
  paramsSelector: ParamSelectionsModel;
  filters: string[];
  isOwnerOrAdmin: boolean;
  pageType: PageType;
  syncingStateData: SyncingStateData;
  nativeAppStatus: NativeApp.Status;
  actionsShortcut: Array<string>;
  resultsBySource: Record<string, Array<ResultDisplayModel>>;
  userInput$ = new Subject<boolean>();
  userInputOptions = null;
  connectApps: ConnectAppsModel;
  localShortcut: Array<string>;
  idToLoadPeople$: Subject<string> = new Subject<string>();
  selected$ = new BehaviorSubject<PreviewItem[]>(null);
  innerSelectedIndex: number;
  hoveredIndex: number;
  windowStyle: Window.WindowStyle;
  onOpenContextMenu: boolean;
  onOpenSummaryPopup: boolean;
  isMac: boolean = isMac();
  isEmbed: boolean = isEmbed();
  embedInline: boolean;
  isBrowserExtension: boolean;
  connectedApps: Observable<Applications.DisplayItem[]>;
  view: 'results' | 'actions' | 'error' | 'connect-apps' | 'syncing' | 'view' | 'connect-pc';
  keyboardOn = false;
  activeRoute$: Observable<Data>;
  inboxFilterOnly: boolean;
  focusedGroup: number;
  PreviewFocusEnum = PreviewFocus;
  itemSize: number;
  noGoLinks = false;
  noCards = false;
  noDrafts = false;
  emptyStateModel: EmptyStateModel;
  resultsType: resultType;
  displayBarText: boolean;
  query: string;
  showGhost$ = new BehaviorSubject<boolean>(false);
  isLoadingAnswers: boolean;
  isTypedAnswer: boolean;
  private isFeedbackFocused: boolean;
  private isAnswerFeedbackOpen: boolean;
  private answerFeedbackIndex: number;
  private readonly HEIGHT_SNIPPET: number = 70;
  private readonly ID = 'ResultsComponent';
  private readonly BROWSE_BAR_TELEMETRY = 'filter_suggestion';
  private readonly SHOW_GHOST_DEBOUNCE = 20;
  private readonly SESSION_NAME: string = 'results';
  private readonly DEBOUNCE_SEARCH_TIME = 200;
  readonly SPECIAL_VIEWS: PageType[] = ['analytics'];
  readonly RESULTS_MIN_SIZE: number = 380;
  private readonly MAX_LONG_SEARCH_TIME = 1000000;
  private readonly LONG_SEARCH_TIME = 5000;
  private nextPageRunning: boolean;
  private isNative = isNativeWindow();
  private destroy$ = new Subject();
  private keyHandlerId: string;
  private state$ = new BehaviorSubject<Params>(undefined);
  private currentSearch$: Observable<SearchResultContext>;
  private currentContext: WorkContext;
  private lastState: any;
  private viewReady: boolean;
  private refreshCount: number;
  private toasterRef: PopupRef<ToasterComponent, ToasterData>;
  private linksStateSessionId: string;
  private showLinksStateToaster$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private allLinks: Links.DisplayItem[];
  private searchSession: SearchSession;
  private barModel: BrowseBarModel;
  private hoveredItem: SearchResults;
  private triggerSearchId = 0;
  private textCleared: boolean;
  private searchGroup: SearchGroup;
  private searchSubscription: Subscription;
  private fetchedPages: number;
  private lastPageInfo: { startIndex: number; endIndex: number; last: boolean };
  private aggregations: string[];
  private searchFirstCompleted = false;
  private extensionInstalled: boolean;
  private toasterTimeOut: NodeJS.Timeout;
  private newGoLink: string;
  private showToaster$: Subject<ToasterData> = new Subject();
  private _viewReady$: BehaviorSubject<boolean>;
  private _selectedIndex: number;
  private staleToasterOnDestroy: () => void;
  private _showGhost$ = new BehaviorSubject(false);
  protected logger: Logger;
  private longSearchTimeout: NodeJS.Timeout;
  private shortcuts: Record<ResultsOrigin, Array<string>> = {};
  private searchBarFocus: boolean;
  private destroyed: boolean;
  private pageFilterSubscription: Subscription;
  private suggestedFiltersData: SuggestionFilters;
  private viewSettings: ViewSettings;
  private inLuncherWithAnswerItem: boolean;
  private _resultFilters: Filter[];
  private resultsPostFilters: Search.ResponseFilters;
  private searchTracker: ResultsSearchTracker;
  private longSearchReported = false;
  private nextPagePromise = new ManualPromise<void>();
  private searchTimes: number[] = [];
  private windowSize$ = windowSizeObserver(100);
  private disableCollections: boolean;
  private disableWikis: boolean;
  private longSearchTime: number = this.LONG_SEARCH_TIME;

  readonly DEFAULT_SELECTED_SORT = SORT_OPTIONS[0];

  searchId: string;
  displayTogglePreview: boolean;
  displaySortDropDown: boolean;
  isEmptyState: boolean;
  showBranding: boolean;
  smallInputSearchFocus = false;
  sortOptions: SortOption[];
  feedbackSentItems: string[] = [];
  resultsFiltersSub: Subscription;
  filtersOpenWithKeyboard = false;
  disableButtons: boolean;
  sortSelected: SortOption;
  noLinkedApps: boolean;
  displayAppsEmptyState: boolean;
  arrowDirection: ScrollDirection;
  resultsLimit: number;
  isLauncher: boolean;
  smallScreen: boolean;
  firstSearchCompleted: boolean;
  isAskUnleash: boolean;
  isLauncherSearchAnswerDone = true;
  generatingState = false;

  get barItems(): Results.BrowseBarItem[] {
    return this.resultsService.barItems$.value;
  }

  get currentNode() {
    return this.navTreeService.currentNode;
  }

  get inSearchNode() {
    return this.navTreeService.currentNode?.id === 'search';
  }

  get isGoLinksOnlyState(): boolean {
    const preFilters = this.filtersService.getPreFilters(true);
    const postFilters = this.filtersService.postFilters;
    return (
      this.pageType === 'search' &&
      this.hubService.query?.trim()?.startsWith('go/') &&
      !Object.keys(preFilters || {}).length &&
      !Object.keys(postFilters || {}).length
    );
  }

  get showFilterBox() {
    const existFilters = this.resultFilters?.length;
    return existFilters;
  }

  get showBrowseBar() {
    return false;
  }

  get viewReady$() {
    if (!this._viewReady$) this._viewReady$ = new BehaviorSubject(null);
    return this._viewReady$;
  }

  private get state() {
    return this.state$.value;
  }

  get pcName(): string {
    return this.applicationsService.apps['pc']?.name || pcAppName();
  }

  get selected(): SearchResults {
    const si = this.displayedContext?.items?.[this.selectedIndex];
    if (si && isGalleryGroup(si)) {
      const items: SearchResults[] = (si as GalleryGroup)?.items;
      if (items) {
        return items[this.innerSelectedIndex || 0];
      }
    }
    return <SearchResults>si;
  }

  get isFirstItem() {
    return this.getRealPosition(this.selectedIndex) === 0;
  }

  get isLastItem() {
    return this.getRealPosition(this.selectedIndex) === this.displayedContext?.items?.length - 1;
  }

  set selectedIndex(value: number) {
    if (value !== this._selectedIndex) {
      this.innerSelectedIndex = undefined;
    }
    this._selectedIndex = value;
  }

  get selectedIndex() {
    return this._selectedIndex;
  }

  get isPreviewMode() {
    return this.previewViewModel.previewState === 'side';
  }

  shouldDisplayTogglePreview(): boolean {
    const disabledPage = this.PREVENT_PREVIEW_PAGES.includes(this.pageType);
    return (
      !disabledPage &&
      this.previewViewModel.previewState !== 'full' &&
      !this.isLauncher &&
      !this.isSmallScreen &&
      !this.isEmptyState &&
      (!this.noLinkedApps || this.isGroupOrView)
    );
  }

  shouldDisplaySortDropDown(): boolean {
    return (
      !this.isLauncher &&
      !this.isEmptyState &&
      this.view !== 'connect-pc' &&
      !['static-collection'].includes(this.pageType) &&
      (!this.noLinkedApps || this.isGroupOrView)
    );
  }

  get showGhost(): boolean {
    return this._showGhost$.value;
  }

  set showGhost(show: boolean) {
    this._showGhost$.next(show);
  }

  private get isActivePreview(): boolean {
    const source = this.displayedContext?.sources?.map((s) => s.source.type);
    if (!source) return true;
    const defaultSearch = this.resultsService.isDefaultSearch() && this.pageType === 'search';
    return (
      source?.find((s) => this.PREVIEW_TYPE.includes(s)) && (!this.isEmptyResults() || this.currentNode?.id != 'search') && !defaultSearch
    );
  }

  private get isSmallScreen() {
    return ['small', 'extra-small'].includes(getWidthBreakpointScreen());
  }

  @HostBinding('class.input-open')
  get inputOpen() {
    return this.userInputOptions != null;
  }

  private get collectionId() {
    return this.searchParamsService.collection;
  }

  get enableEnterIndicationFunc(): boolean {
    return this.searchBarFocus && !this.searchOnEnterMode;
  }

  private get onSearchView() {
    return !(this.hubService.activePage || !this.inSearchNode);
  }

  get showButtonsContainer() {
    return (
      this.displaySortDropDown ||
      this.displayTogglePreview ||
      this.showCreateGoLinkButton() ||
      (this.currentNode?.id != 'search' && this.isEmptyState === false)
    );
  }

  get noAssistants() {
    return (
      this.displayedContext?.searchCompleted &&
      this.displayedContext?.items?.length === 0 &&
      this.resultsService.isDefaultSearch() &&
      this.pageType === 'assistants'
    );
  }

  get noCollections() {
    return (
      this.displayedContext?.searchCompleted &&
      this.displayedContext?.items?.length === 0 &&
      !this.hubService.query &&
      Object.keys(this.filtersService.inlineFilters || {}).length === 0 &&
      this.pageType === 'collections'
    );
  }

  get noWikis() {
    return (
      this.displayedContext?.searchCompleted &&
      this.displayedContext?.items?.length === 0 &&
      !this.hubService.query &&
      Object.keys(this.filtersService.inlineFilters || {}).length === 0 &&
      this.pageType === 'wikis'
    );
  }

  get noCollectionItems() {
    return (
      this.displayedContext?.searchCompleted &&
      this.displayedContext?.items?.length === 0 &&
      this.resultsService.isDefaultSearch() &&
      this.collectionTypes.includes(this.pageType)
    );
  }

  get isGroupOrView(): boolean {
    return (
      !!this.searchParamsService.getGroup() ||
      !this.inSearchNode ||
      !!this.hubService.activePage ||
      !!this.collectionId ||
      this.isGoLinksOnlyState
    );
  }

  shouldDisableButtons(): boolean {
    return !this.displayedContext.items?.length || (this.resultsService.isDefaultSearch() && !this.isGroupOrView);
  }

  get shouldDisplayAppsEmptyState(): boolean {
    return this.noLinkedApps && !this.isGroupOrView;
  }

  get emptyRelevantPeopleQuery() {
    return !this.query?.length && this.searchGroup && this.searchGroup.name === 'relevant-people';
  }

  private get searchAnswersItems() {
    return this.displayedContext?.sources?.find((item) => item.source.type === 'answers')?.items as AnswerSearchItem[];
  }

  private get currentAnswerItem() {
    return this.searchAnswersItems?.find((s) => isAnswerItem(s));
  }

  get isOnlyAnswerResultInLauncher() {
    return this.isLauncher && this.searchAnswersItems?.some((item) => [...AnswerSearchReady, 'Loading'].includes(item.state));
  }

  private get searchOnEnterMode() {
    return this.getSearchMethod() === 'Search-On-Enter';
  }

  get isEmptyResultsOrAnswers() {
    if (!this.displayedContext?.searchCompleted) {
      return false;
    }
    const answersItems = this.searchAnswersItems;
    return this.displayedContext?.items.length === 0 || (this.isEmptyAnswers(answersItems) && this.isOnlyAnswers(answersItems));
  }

  get resultFilters(): Filter[] {
    return this._resultFilters;
  }

  set resultFilters(value: Filter[]) {
    this._resultFilters = value;
    this.cdr.markForCheck();
  }

  //preview
  previewViewModel: PreviewViewModel = {};
  splitAreaPreviewModel: SplitAreaPreviewModel;
  private currentBreakpoint: BreakpointsWidth;
  private isComponentFocused = true;
  private readonly PREVIEW_TYPE = [
    'link-resources',
    'mail-resources',
    'visits',
    'favorites',
    'people',
    'relevant-people',
    'wiki-collection-items',
    'static-collection-items',
    'wiki-drafts',
  ];
  private readonly DEFAULT_SIDE_PREVIEW_VIEW = ['people', 'mail'];
  // private readonly BASE_RESULTS_FILTERS = ['app', 'type'];
  previewComponent: ComponentType<PreviewComponent>;
  selectedForPreview$: Observable<PreviewItem[]>;
  readonly collectionTypes: string[] = ['live-collection', 'static-collection', 'wiki-collection'];
  readonly supportedGalleryItemTypes = ['relevant-people', 'people'];
  private readonly DISABLED_SUGGESTIONS_PAGE = ['files', 'golinks', ...this.collectionTypes, 'collections', 'wikis', 'cards', 'drafts'];

  get showLauncherButton(): boolean {
    return this.isLauncher && ['live-collection', 'static-collection'].includes(this.pageType);
  }

  constructor(
    logService: LogService,
    protected cdr: ChangeDetectorRef,
    private wikiCardsService: WikiCardsService,
    protected eventsService: EventsService,
    protected hubService: HubService,
    protected previewService: PreviewService,
    private commandsService: CommandsService,
    private tagsService: TagsService,
    protected ngZone: NgZone,
    private keyboardService: KeyboardService,
    protected suggestionService: SuggestionsService,
    private applicationsService: ApplicationsService,
    private analyticsService: TelemetryService,
    private filtersService: FiltersService,
    private resultsService: ResultsService,
    private searchService: SearchService,
    private searchParamsService: SearchParamsService,
    private routerService: RouterService,
    private popupService: PopupService,
    private linksService: LinksService,
    private syncsService: SyncsService,
    private nativeAppLinkService: NativeAppLinkService,
    private navTreeService: NavTreeService,
    private filePreviewService: FilePreviewService,
    private peopleService: PeopleService,
    private appService: AppService,
    private embedService: EmbedService,
    private sessionService: SessionService,
    private recentSearches: RecentSearchesService,
    private windowService: WindowService,
    private suggestionsService: SuggestionsService,
    private resultCommandService: ResultCommandService,
    private paramsSelectorService: ParamsSelectorService,
    private previewKeyboardService: PreviewKeyboardService,
    private sidebarService: SidebarService,
    private collectionsService: CollectionsService,
    private collectionsPopupService: CollectionPopupService,
    private goLinksService: GoLinksService,
    private browserExtension: BrowserExtensionService,
    private browserTabsService: BrowserTabsService,
    private suggestionsSearchService: SuggestionsSearchService,
    private workspaceService: WorkspacesService,
    private resourcesService: ResourcesService,
    private datePickerService: DatePickerService,
    private summaryService: SummaryService,
    private sortService: SortService,
    private wikiCardPreviewService: WikiCardPreviewService,
    private verificationsCardsCommandsService: VerificationsCardsCommandsService,
    private assistantService: ExperiencesService,
    private showToasterService: ShowToasterService,
    private questionnaireService: QuestionnaireService,
    private infiniteScrollService: InfiniteScrollService,
    private collectionsUtilService: CollectionsUtilService,
    private flagsService: FlagsService,
    private chatsService: ChatsService,
    private wikisService: WikisService,
    private wikiDraftsService: WikiDraftsService,
    private wikiItemSelectionPopupService: WikiItemSelectionPopupService
  ) {
    this.logger = logService.scope('ResultsComponent');
    this.activeRoute$ = this.routerService.activeRoute.data;
    this.selectedForPreview$ = this.selected$.asObservable().pipe(
      distinctUntilChanged(),
      debounce(() => (this.previewViewModel.previewState === 'full' ? of({}) : timer(200)))
    );
    this.splitAreaPreviewModel = {
      minPreviewWidth: this.previewService.minPreviewWidth,
      maxPreviewWidth: this.previewService.maxPreviewWidth,
      visibleResults: true,
      visiblePreview: false,
    };
    this.searchSession = this.searchService.getOrCreateSearchSession(this.SESSION_NAME);
    this.searchTracker = new ResultsSearchTracker(appService, analyticsService, hubService);
    this.hubService.isLauncher$.pipe(untilDestroyed(this)).subscribe((l) => (this.isLauncher = l));
    this.hubService.textCleared$.pipe(untilDestroyed(this)).subscribe((value) => (this.textCleared = value));
    if (this.isNative) {
      this.nativeAppLinkService.status$?.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((res: NativeApp.Status) => {
        const triggerSearch = this.nativeAppStatus != null;
        this.nativeAppStatus = res;
        if (triggerSearch) {
          let trigger = 'native-app-status';
          if (this.nativeAppStatus === 'running' && this.view == 'connect-pc') {
            trigger = 'native-app';
          }
          this.hubService.stateChangedTime = Date.now();
          this.startSearch(trigger);
        }
      });
    }
    this.hubService.state$.pipe(untilDestroyed(this)).subscribe((params: Params) => {
      const queryParams = {};

      for (const [key, value] of Object.entries(params)) {
        const param = key.includes('-') ? key.split('-')[0] + '-' : key;
        if (!ResultsQueryParams.includes(param as PossibleResultsParams)) {
          continue;
        }
        queryParams[key] = Array.isArray(value) ? value : [value];
      }
      if (!isEqual(this.state, queryParams)) this.state$.next(queryParams);
    });
    if (this.isEmbed) {
      this.embedService.isExternalWebSite().then((x) => {
        this.isBrowserExtension = !x;
        this.showBranding = x;
      });
      this.setEmbedInline();
    }

    this.appService.windowStyle$.pipe(takeUntil(this.destroy$)).subscribe((b) => {
      this.windowStyle = b;

      if (this.hubService.currentLocation === this.hubService.defaultPage) {
        this.windowStyle = 'left-side-bar';
      }
      this.cdr.markForCheck();
    });

    this.linksService.visible$.pipe(takeUntil(this.destroy$)).subscribe((links) => (this.allLinks = links));
    this.initSuggestions();
    this.initTags();
    this.initBarItems();
    this.displayedContext = {
      id: 0,
      iterations: 0,
      items: [],
    };

    this.wikiCardsService.fetchRunning$.pipe(untilDestroyed(this)).subscribe((res) => {
      if (this.pageType !== 'cards') {
        return;
      }
      this.showGhost = !!(!this.displayedContext?.items?.length && res === 'running' && !this.noWikis);
      this.cdr.markForCheck();
    });
  }

  isEmptyResults() {
    if (this.isEmptyState) {
      return false;
    }
    const isDefaultSearch = this.resultsService.isDefaultSearch();
    if (isDefaultSearch && this.displayedContext?.searchCompleted && this.collectionTypes.includes(this.pageType) && this.inSearchNode) {
      return !this.collectionId;
    }
    return this.displayedContext && this.displayedContext.searchCompleted && this.isEmptyResultsOrAnswers;
  }

  track() {
    if (this.resultsService.isDefaultSearch()) {
      return;
    }
    this.searchTracker.track(this.displayedContext, this.isLauncher, this.extensionInstalled, this.showGhost);
  }

  async setEmbedInline() {
    this.embedInline = await this.embedService?.isInline();
  }

  private async startSearch(trigger: string, newSession = false, filterTrigger: 'tag' | 'box' | 'none' = 'none'): Promise<void> {
    if (this.showLinksStateToaster$.value) {
      this.showLinksStateToaster$?.next(false);
    }
    this.cdr.markForCheck();
    if (!this.hubService.stateChangedTime || trigger === 'reload') {
      this.hubService.stateChangedTime = Date.now();
    }
    const startTime = this.hubService.stateChangedTime;
    this.searchTracker.start(startTime, trigger);
    this.searchTracker.updateTime('beforePreSearch');
    const shouldSearch = await this.preSearch(trigger);
    if (!shouldSearch) {
      this.displayedContext.searchCompleted = true;
      if (this.collectionTypes.includes(this.pageType)) {
        this.hubService.readOnly = false;
      }
      return;
    }
    this.searchTracker.updateTime('afterPreSearch');
    const searchAlive = this.getSearchAliveCheck(this.triggerSearchId);
    let newSearchSession = newSession;
    const query = this.hubService.query || '';
    this.searchTracker.updateSearchParams(query, this.filtersService.allFilters);
    if (!query.length || this.textCleared) {
      newSearchSession = true;
      this.textCleared = false;
    }
    const stateTriggerState = (this.hubService.getState('search-trigger') || [])[0];
    const optionTrigger = stateTriggerState === 'home_filter_suggestion' ? stateTriggerState : trigger;
    await this.addRouteFilters();
    if (!searchAlive()) {
      return;
    }
    let sources = await this.getSources(this.aggregations);
    if (!searchAlive()) {
      return;
    }
    sources = sources.map((s) => {
      const tag = trigger ? `results-component-${trigger}` : 'results-component';
      return { ...s, tag };
    });
    const searchSources = sources;
    const options: SearchOptions = {
      query,
      trigger: optionTrigger,
      resetSession: newSearchSession,
      sources: searchSources,
    };
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
      this.searchSubscription = undefined;
    }
    this.isAnswerFeedbackOpen = false;
    this.nextPageRunning = false;
    this.isTypedAnswer = false;
    this.isAskUnleash = false;
    this.currentSearch$ = this.searchSession.search$(options);
    this.initGoLinksRoute();

    if (this.hubService.searchMethod !== 'Quick-Search') {
      // this only for the scenario when u had previous results before the search, and we want to avoid a flicking in the side preview
      this.selectedIndex = undefined;
    }
    this.isLauncherSearchAnswerDone = false;
    this.displayedContext.searchCompleted = false;
    this.query = query;
    this.longSearchReported = false;
    if (this.longSearchTimeout) {
      clearTimeout(this.longSearchTimeout);
      this.longSearchTimeout = null;
    }
    this.longSearchTimeout = setTimeout(() => {
      this.handleLongSearch();
    }, this.longSearchTime);
    const focusStatus = !this.goLinksService.isOpenedPopup && this.shouldFocusSearchBar(trigger, filterTrigger);
    setTimeout(() => {
      this.hubService.changeFocusState(focusStatus);
      if (!focusStatus && this.isEmbed) {
        this.wrapperRef?.nativeElement?.focus();
      }
    }, 50);
    this.searchTracker.updateTime('searchReached');
    const searchStartTime = Date.now();
    const onDebounce = (state: SearchResultContext) => {
      if (state.searchCompleted) return of({});
      const diffTime = Date.now() - searchStartTime;
      return diffTime < this.DEBOUNCE_SEARCH_TIME ? timer(this.DEBOUNCE_SEARCH_TIME - diffTime) : of({});
    };
    this.searchSubscription = this.currentSearch$
      .pipe(
        untilDestroyed(this),
        debounce((state) => onDebounce(state))
      )
      .subscribe(async (context) => {
        if (!searchAlive()) {
          return;
        }

        if (
          context.searchCompleted &&
          !context.items.length &&
          this.resultsService.isDefaultSearch() &&
          ['golinks', 'collections', 'wikis', 'assistants'].includes(this.pageType)
        ) {
          this.hubService.readOnly = true;
        }

        const ctx = cloneDeep(context);
        const relevantPeopleSource = ctx?.sources?.find((s) => s.source.type === 'relevant-people');
        if (relevantPeopleSource?.done) {
          // we want to hide relevant People items if also there is people
          const peopleSource = ctx?.sources?.find((s) => s.source.type === 'people');
          if (relevantPeopleSource?.items.length > 0 && peopleSource?.items?.flat().length > 0) {
            ctx.items = ctx.items.filter((i) => i.source !== 'relevant-people');
          }
        }

        const groupSourcesDone = context.sources
          ?.filter((s) => !['answers', 'web-search', 'relevant-people', 'group-resources'].includes(s.source.type))
          .every((s) => s.done || (s.source.type === 'link-resources' && s.extra?.localDone));

        this.showGhost = groupSourcesDone && !ctx.searchCompleted;
        const answers = context?.sources?.find((item) => item.source.type === 'answers')?.items as AnswerSearchItem[];
        this.isLoadingAnswers = answers?.some((answer) => answer.state === 'Loading') ?? false;

        const first = this.displayedContext.id != ctx.id;
        const resourceSource = this.getSource(ctx);
        const extra = resourceSource?.extra as LinkResourcesResultExtra | MailResultExtra;
        if (extra) {
          if (resourceSource.done) {
            const suggestions = this.suggestedFiltersData;
            if (!suggestions || suggestions.id !== ctx.id || !isEqual(suggestions.types, extra.typeSuggestedFilters)) {
              this.suggestedFiltersData = {
                id: ctx.id,
                types: extra.typeSuggestedFilters,
              };
              this.resultsPostFilters = extra.postFilters;
              this.subscribeToResultsFilters(this.pageType, extra.typeSuggestedFilters, extra.postFilters);
            }
          }
          this.searchId = extra?.searchId;
          this.fetchedPages = extra.fetchedPages;
          this.lastPageInfo = extra?.lastPageInfo;
        }
        if (this.searchGroup?.name === 'relevant-people') {
          const resourceSource = ctx.sources?.find((s) => isRelevantPeopleSettings(s.source));
          const postFilters = resourceSource?.extra?.postFilters;
          if (resourceSource?.done && !isEqual(this.resultsPostFilters, postFilters)) {
            this.resultsPostFilters = postFilters;
            this.subscribeToResultsFilters(this.pageType, null, this.resultsPostFilters);
          }
        } else {
          if (ctx.sources && !resourceSource && (this.resultsPostFilters || this.suggestedFiltersData)) {
            this.resultsPostFilters = null;
            this.suggestedFiltersData = null;
            this.subscribeToResultsFilters(this.pageType);
          }
          if ((this.viewSettings?.supportCustomFilters || this.isGoLinksOnlyState) && ctx.searchCompleted) {
            this.subscribeToResultsFilters(this.pageType);
          }
        }

        this.emptyStateModel = null;
        const goLinksSourceResult: SourceResult = ctx.sources?.find((s) => isGoLinksSettings(s.source));
        if (this.pageType === 'golinks' && goLinksSourceResult) {
          this.previewService.setPreviewState('none');
          if (goLinksSourceResult?.extra) {
            this.updateGoLinksPostFilters(goLinksSourceResult, this.viewSettings);
          }
          this.noGoLinks =
            !goLinksSourceResult.items?.length &&
            !Object.keys(this.filtersService.postFilters || {}).length &&
            !Object.keys(this.filtersService.getPreFilters() || {}).length &&
            !query;
          if (this.noGoLinks && !this.hubService.readOnly) {
            this.hubService.readOnly = true;
          }
        }

        const items = this.displayedContext.items.map((a) => a);
        const lastSearchId = this.displayedContext.id;
        if (lastSearchId != ctx.id) {
          this.feedbackSentItems = [];
        }
        const itemsChanged = !ctx.items.every((newItem: ResultItem) => items.find((item: ResultItem) => item.id === newItem.id));
        this.searchFirstCompleted = !this.displayedContext.searchCompleted && ctx.searchCompleted;
        this.displayedContext = { ...ctx, iterations: first ? 0 : this.displayedContext.iterations + 1 };
        this.addAnswerFeedbackIfNeeded(items);

        if (!ctx.searchCompleted && !this.displayedContext?.items?.length && !groupSourcesDone) {
          this.displayedContext.items = [...items];
        }
        if (first) {
          this.resultsLimit = this.INITIAL_ITEMS_LIMIT;
          if (!this.nextPagePromise.status) {
            this.nextPagePromise.resolve();
          }
        }

        const currentAnswerState = this.currentAnswerItem?.state;
        this.generatingState = AnswerGenerateState.includes(currentAnswerState);
        this.isLauncherSearchAnswerDone = AnswerSearchReady.includes(currentAnswerState);
        if (this.isLauncher) {
          this.handleLauncherResultsAfterSearch();
        }

        this.updateItems();
        const answersItems = this.displayedContext?.items?.find((item) => isAnswerItem(item));
        if (answersItems && this.isLauncher) {
          this.selectedIndex = 1;
          this.inLuncherWithAnswerItem = true;
        } else if (!this.isFirstSelected() && first) {
          this.selectedIndex = undefined;
        } else {
          if (this.inLuncherWithAnswerItem) {
            this.select('first');
            this.inLuncherWithAnswerItem = false;
          }
        }

        const launcherFocusCondition: boolean = this.isLauncher && (this.isGroupOrView || !this.resultsService.isDefaultSearch());
        if (
          (this.selectedIndex == null || this.isFirstChange()) &&
          (!this.searchOnEnterMode || this.isGoLinksOnlyState) &&
          (launcherFocusCondition || (!this.isLauncher && ctx.searchCompleted))
        ) {
          this.select('first');
          this.hubService.focusPosition = 'results';
        }

        if (ctx?.searchCompleted) {
          if (!ctx.items?.length || (this.resultsService.isDefaultSearch() && this.pageType === 'search')) {
            this.previewService.setPreviewState('none');
          }
        }

        this.searchForFocusGroup(this.selectedIndex);

        if (itemsChanged || ctx.searchCompleted) {
          this.resultsType = this.getResultsType();
        }

        if (ctx.searchCompleted) {
          if (!searchAlive()) {
            return;
          }
          this.onSearchCompleted(query, extra, startTime);

          setTimeout(() => {
            const scrollEl = this.infiniteScrollRef?.nativeElement;
            if (scrollEl && scrollEl.scrollHeight <= scrollEl.clientHeight) {
              this.resultsLimit = this.displayedContext.items.length;
              this.nextPagePromise = new ManualPromise();
            }
          }, 24);
        }
        this.cdr.markForCheck();
      });
  }

  private updateItems() {
    const listViewItems = [];

    for (let index = 0; index < this.displayedContext.items.length; index++) {
      const item = this.displayedContext.items[index];
      if (item['settings']?.viewMode === 'gallery') {
        index = this.buildGalleryGroup(index, item, listViewItems);
      } else {
        listViewItems.push(item);
      }
    }

    this.displayedContext.items = listViewItems;
  }

  private buildGalleryGroup(firstIndex: number, firstItem: SearchResults, listViewItems: ResultDisplayModel[]) {
    let galleryViewItems = [];

    let endIndex = this.displayedContext.items.findIndex((item, index) => index > firstIndex && item.type !== firstItem?.type);
    if (endIndex === -1) {
      endIndex = this.displayedContext.items.length;
    }
    galleryViewItems = this.displayedContext.items.slice(firstIndex, endIndex);

    if (galleryViewItems.length) {
      const galleryGroupItem: GalleryGroup = {
        type: 'gallery-group',
        resultsType: firstItem?.source,
        index: firstIndex,
        items: galleryViewItems,
      };

      listViewItems.push(galleryGroupItem);
    }
    return endIndex - 1;
  }

  private isFirstChange() {
    const selectedItem = this.selected$.value?.[0]?.item;
    const results = this.displayedContext.items.filter((r) => r.type !== 'header');
    return this.isFirstSelected() && results[0]?.['id'] !== selectedItem?.id;
  }

  private async addRouteFilters() {
    if (this.viewSettings?.routeFilters && this.viewSettings?.routeFilters.name === 'account') {
      const peopleLinkId = await firstValueFrom(this.linksService.peopleLinkId$);
      if (peopleLinkId) {
        const peopleAccountName = this.linksService.links[peopleLinkId]?.name;
        this.filtersService.routeFilters = { account: [peopleAccountName] };
      }
    }
  }

  private handleLongSearch() {
    const currentSearch = this.searchSession.currentSearch;
    const sourceDetails = {};
    for (const { request, response, sourceSettings } of currentSearch.workContext.sourceWorkContexts || []) {
      sourceDetails[sourceSettings.type] = {
        query: request.query,
        trigger: request.trigger,
        done: response.done,
        cancelled: response.cancelled,
        duration: response.duration,
        itemCounts: response.totalCount,
        settings: sourceSettings,
      };
    }
    this.logger.error('search took too much time', { sources: sourceDetails });
  }

  private getSearchAliveCheck(id: number) {
    return () => id === this.triggerSearchId && !this.destroyed;
  }

  private removeUnTaggedFilters() {
    const inFilters = this.filtersService.unTaggedInlineFilters;
    for (const [key, values] of Object.entries(inFilters || {})) {
      for (const value of values) {
        this.filtersService.removeFilter(key, value, false, 'pre');
      }
    }
  }

  private updateGoLinksPostFilters(resourceSource: SourceResult, resultsViewSetting: ViewSettings) {
    const settingsPostFilter = resultsViewSetting?.filters;
    if (!resourceSource.done || !settingsPostFilter?.length) return;

    this.goLinksService.getPostFilters(settingsPostFilter, resourceSource.extra.postFilters).then((filters) => {
      const allSelected = this.filtersService.allFilters || {};
      const names = [];
      for (const filter of filters) {
        names.push(filter.name);
        const selected = new Set(allSelected[filter.name]);
        filter.values = filter.values?.map((v) => ({ ...v, selected: selected.has(v.value) }));
      }
      this.initSuggestionProviders(names);
      this.resultFilters = filters;
    });
  }

  private async preSearch(trigger: string): Promise<boolean> {
    this.hubService.preventSearch = false;
    await firstValueFrom(this.linksService.all$);
    const id = (this.triggerSearchId = this.triggerSearchId + 1);
    const searchAlive = this.getSearchAliveCheck(id);
    if (!(await this.handleMultipleQuickSearches(searchAlive))) {
      return false;
    }
    const query = this.hubService.query || '';
    const prePageType = this.pageType;
    const collectionId = this.collectionId;
    let viewName = this.hubService.activePage;
    this.searchGroup = this.searchParamsService.getGroup();
    if (['analytics'].includes(viewName)) {
      const searchTrigger = this.hubService.getState('search-trigger')[0];
      let url = `/${viewName}`;
      if (searchTrigger) {
        url += `?search-trigger=${searchTrigger}`;
      }
      this.routerService.navigateByUrl(url);
      return;
    }

    let collection;
    if (!viewName && collectionId) {
      if (collectionId !== collection?.id) {
        collection = await this.collectionsService.getById(collectionId);
        if (!searchAlive()) {
          return false;
        }
      }
      if (isLiveCollection(collection)) {
        viewName = 'live-collection';
      } else if (isStaticCollection(collection)) {
        viewName = 'static-collection';
      } else if (isWikiCollection(collection)) {
        viewName = 'wiki-collection';
      }
    }
    if (!viewName) {
      viewName = await this.resultsService.getPageType(query || '', this.currentNode);
      if (!searchAlive()) {
        return false;
      }
    }
    const pageTypeChanged = prePageType != viewName;
    if (pageTypeChanged) {
      this.pageFilterSubscription?.unsubscribe();
    }

    let node = this.currentNode;
    const onSearchNode = this.inSearchNode;
    if (viewName === 'search' || pageTypeChanged) {
      this.pageType = viewName;
      if (viewName === 'golinks') {
        this.goLinksService.refresh();
      }
      if (viewName === 'cards') {
        this.longSearchTime = this.MAX_LONG_SEARCH_TIME;
      } else {
        this.longSearchTime = this.LONG_SEARCH_TIME;
      }
      if (!this.collectionTypes.includes(this.pageType) && !onSearchNode && prePageType) {
        this.removeUnTaggedFilters();
      }
    }
    if (!this.previewService.previewStateCache) {
      this.previewService.setPreviewStateCache(this.DEFAULT_SIDE_PREVIEW_VIEW.includes(this.pageType) ? 'side' : 'none');
      this.calcPreviewState();
    } else if (this.previewViewModel.previewState === 'full') {
      this.previewService.setPreviewState('none');
    }

    if (this.SPECIAL_VIEWS.includes(viewName)) {
      if (this.pageType === 'favorites') {
        this.updatePlaceholder(null, null);
      }
      return false;
    }

    this.viewSettings = this.getResultsViewSettings();
    if (this.pageType && this.viewSettings) {
      this.view = 'view';

      this.subscribeToResultsFilters(this.pageType, this.suggestedFiltersData?.types, this.resultsPostFilters);

      this.aggregations = this.viewSettings?.aggregations;
      this.cdr.markForCheck();
    } else {
      this.view = 'results';
    }
    this.updatePlaceholder(id, query);
    if (onSearchNode && this.hubService.activePage) {
      node = await this.navTreeService.get(this.hubService.activePage);
      if (!searchAlive()) {
        return false;
      }
    }
    const connectApps = await this.resultsService.getSuggestedApps(node);
    if (!searchAlive()) {
      return false;
    }

    if (connectApps) {
      this.view = 'connect-apps';
      this.connectApps = connectApps;
      if (onSearchNode) {
        this.hubService.preventSearch = true;
      } else {
        this.hubService.readOnly = true;
      }
      this.displayedContext.items = [];
      this.displayedContext.lastHeaderIndex = 0;
      this.viewReady = true;
      this.hubService.placeholder = '';
      this.cdr.markForCheck();
      return false;
    } else {
      this.connectApps = null;
    }
    await this.updateDisplayedBarItems();
    if (!searchAlive()) {
      return false;
    }

    if (collection && isLiveCollection(collection)) {
      const canSearch = !isEmpty(collection.searchParams?.filters) || collection.searchParams?.query;
      if (!canSearch && this.resultsService.isDefaultSearch()) {
        this.displayedContext.items = [];
        return false;
      }
    }

    const preFilters = this.filtersService.getPreFilters(true);

    const appFilters = preFilters['app'] || [];
    const filterForPc = appFilters.length === 1 && appFilters.includes(this.pcName);

    if (!this.nativeAppLinkService.canSearchPc() && filterForPc && this.pageType !== 'live-collection') {
      this.view = 'connect-pc';
      this.displayedContext.items = [];
      this.displayedContext.lastHeaderIndex = 0;
      this.hubService.readOnly = true;
      this.resultsType = 'default';
      return false;
    }
    if (!['connect-apps', 'connect-pc'].includes(this.view) && onSearchNode) {
      this.hubService.readOnly = false;
    }

    const noInlineFilters =
      !this.resultsService.hasPreFilters() &&
      !['golinks', ...this.collectionTypes, 'collections', 'wikis', 'cards'].includes(this.pageType);
    if (!query) {
      if (this.pageType === 'live-collection' && !this.filtersService.hasFilters) {
        return false;
      }
      if (noInlineFilters) {
        for (const filterName of Object.keys(this.filtersService.inlineFilters)) {
          this.filtersService.setFilters(filterName, [], 'pre');
        }
        this.hubService.setState('search-trigger', []);
        for (const [key, values] of Object.entries(this.filtersService.postFilters || {})) {
          for (const value of values) {
            this.filtersService.removeFilter(key, value, false, 'post');
          }
        }
        if (this.state['search-trigger']) {
          this.hubService.setState('search-trigger', []);
        }
      }
      if (!query) {
        if (trigger === 'user_query') {
          const currentState = this.hubService.getState('ap');
          const location = currentState.length ? `${this.hubService.currentLocation}_${currentState}` : this.hubService.currentLocation;
          this.eventsService.event('search.clear', {
            location: { title: location },
            search: { sessionId: this.displayedContext?.sessionId },
          });
        }
        if (pageTypeChanged) {
          this.displayedContext.items = [];
          this.resultsLimit = this.INITIAL_ITEMS_LIMIT;
        }
        return true;
      }
      if (this.resultsService.hasPostFilters() && noInlineFilters) {
        // skipping this search if had outline filter before reset
        return false;
      }
    }

    return searchAlive();
  }

  private async handleMultipleQuickSearches(searchAlive: () => boolean) {
    if (this.hubService.searchMethod !== 'Quick-Search') {
      return true;
    }
    const now = Date.now();
    this.searchTimes = this.searchTimes.filter((t) => t + 1000 > now);
    this.searchTimes.push(now);
    if (this.searchTimes.length < 5) {
      return true;
    }
    await delay(150);
    return searchAlive();
  }

  private async setupSearchPageFilters(postFilters: Search.ResponseFilters, typeSuggestedFilters: Search.FilterCountDetails[]) {
    const settings = this.viewSettings;
    const moreFilters = settings.moreFilters;
    const filters = settings.filters;
    const counters: FilterCounter = {};

    for (const [key, value] of Object.entries(postFilters || {})) {
      const withCounters = filters.find((f) => f.name === key)?.withCounters;
      if (withCounters) {
        counters[key] = value?.map((a) => ({ value: a.title, count: a.count })) || [];
      }
    }
    const autoCompleteOptions = this.isGroupOrView
      ? filters?.filter((f) => !f.optional).map((f) => f.name)
      : this.filtersService.baseFilters;
    this.handleAutoComplete(autoCompleteOptions);
    const assistantId = await this.getCurrentAssistantId();
    const subject$ = this.filtersService.getFloatingResultsFilters({
      sessionName: this.SESSION_NAME,
      baseFilters: filters,
      typeSuggestedFilters: settings.withRecommendations ? typeSuggestedFilters || [] : [],
      moreFilters,
      includeSelected: settings.includeSelected,
      minimumCount: settings.minimumCount,
      spreadAsMultiSelect: settings.spreadAsMultiSelect,
      counters,
      supportSingleBaseTag: settings.supportSingleBaseTag,
      recommendationsWhenNoFilters: settings.recommendationsWhenNoFilters,
      allowSingleItem: settings.allowSingleItem || !this.resultsService.isDefaultSearch(),
      allowEmptyFilter: settings.allowEmptyFilter,
      assistantId,
    });
    this.resultsFiltersSub = subject$.pipe(untilDestroyed(this)).subscribe((r) => {
      this.resultFilters = r;
      this.cdr.markForCheck();
    });
  }

  private async subscribeToResultsFilters(
    pageType: PageType,
    typeSuggestedFilters?: Search.FilterCountDetails[],
    postFilters?: Search.ResponseFilters
  ) {
    this.resultsFiltersSub?.unsubscribe();
    this.resultsFiltersSub = null;
    if (this.isGoLinksOnlyState) {
      this.resultFilters = [];
      return;
    }
    switch (pageType) {
      case 'golinks':
        break;
      case 'cards': {
        const cardsPageFilters = firstValueFrom(this.wikiCardsService.cardsPageFilters$.pipe(filter((filters) => !!filters)));
        const allFilters = firstValueFrom(this.filtersService.allFilters$);
        const allCards = firstValueFrom(this.wikiCardsService.cardsCount$.pipe(filter((cards) => !!cards)));
        Promise.all([cardsPageFilters, allFilters, allCards]).then(([cardsFilters, selectedFilters, cardsCount]) => {
          if (!cardsCount) {
            this.resultFilters = [];
            return;
          }
          const filters = cloneDeep(cardsFilters);
          for (const filter of filters) {
            const selected = new Set(selectedFilters[filter.name] || []);
            if (filter.picker === 'time-dropdown' && selected.size) {
              const selectedCustomValues: DisplaySearchFilterValue[] = this.filtersService.getCustomSelectedTimeFilter(
                filter.values,
                selected,
                filter.name,
                filter.icon
              );
              filter.values.push(...selectedCustomValues);
            }
            filter.values = filter.values?.map((v) => {
              const value = typeof v.value === 'boolean' ? v.value?.toString() : v.value;
              return {
                ...v,
                selected: selected.has(value),
              };
            });
          }
          this.resultFilters = filters;
          if (filters) {
            this.initSuggestionProviders(filters.map((f) => f.name));
          }
        });
        break;
      }
      case 'wikis':
      case 'collections': {
        const collectionsFilters = this.collectionsService.getPageFiltersByType(pageType === 'wikis' ? ['Wiki'] : ['Live', 'Static']);
        const allFilters = firstValueFrom(this.filtersService.allFilters$);
        Promise.all([allFilters, collectionsFilters]).then(([_, filters]) => {
          const selectedFilters = this.filtersService.inlineFilters;

          const newFilters = cloneDeep(filters || []);
          this.resultFilters = this.collectionsService.buildCollectionsFilters(newFilters, selectedFilters, [], new Set([]));
          if (newFilters) {
            this.initSuggestionProviders(newFilters.map((f) => f.name));
          }
        });
        break;
      }
      case 'assistants': {
        const assistantsFilters = firstValueFrom(this.assistantService.assistantPageFilters$);
        const allFilters = firstValueFrom(this.filtersService.allFilters$);
        Promise.all([allFilters, assistantsFilters]).then(([selectedFilters, filters]) => {
          const filtersToRemove = [];
          if (!selectedFilters['assistant-type']?.includes('Slack')) {
            filtersToRemove.push('assistant-slack-workspace');
          }
          if (!selectedFilters['assistant-type']?.includes('Teams')) {
            filtersToRemove.push('assistant-team');
          }
          const newFilters = cloneDeep(filters?.filter((f) => !filtersToRemove.includes(f.name)) || []);
          for (const filter of newFilters) {
            const selected = new Set(selectedFilters[filter.name] || []);
            filter.values = filter.values?.map((v) => ({ ...v, selected: selected.has(v.value) }));
          }
          this.resultFilters = newFilters;
          if (newFilters) {
            this.initSuggestionProviders(newFilters.map((f) => f.name));
          }
        });
        break;
      }
      default:
        this.setupSearchPageFilters(postFilters, typeSuggestedFilters);
        break;
    }
  }

  private async handleAutoComplete(filterNames: string[]) {
    const assistantId = await this.getCurrentAssistantId();
    const routeFilters = new Set(Object.keys(this.filtersService.routeFilters || {}));
    const allFilters = Object.keys(this.filtersService.allFilters || {}).filter((n) => !routeFilters.has(n));
    let enablePages = !assistantId;
    let enableAutoComplete = true;
    if (this.filtersService.hasFilters) {
      enablePages = false;
    }
    const hasNonBaseFilter = !!allFilters.find((f) => !this.filtersService.baseFilters.includes(f));
    if (hasNonBaseFilter) {
      enableAutoComplete = false;
    }
    this.hubService.enablePagesSuggestion = enablePages;

    //After selecting filter, we will disable this filter autocomplete
    this.initSuggestionProviders(
      enableAutoComplete ? filterNames.filter((f) => !allFilters.includes(f)) : [],
      enablePages && !this.disableCollections,
      enablePages && !this.disableWikis
    );
  }

  private getResultsViewSettings(): ViewSettings {
    if (!this.isLauncher) {
      if (this.searchGroup && resultsViewSettings[this.searchGroup.name]) {
        return resultsViewSettings[this.searchGroup.name];
      }
      return resultsViewSettings[this.pageType];
    }

    let view: string = this.pageType;
    if (this.resultsService.isDefaultSearch() && !this.isGroupOrView) {
      view = 'default';
    } else {
      const filters = this.filtersService.allFilters || {};
      const singleFilter = Object.keys(filters).length === 1;
      if (singleFilter) {
        const tagFilters = this.filtersService.tagFilters || {};
        const singleTagFilter = Object.keys(tagFilters).length;
        if (singleTagFilter) {
          for (const filterName of this.filtersService.baseFilters) {
            if (tagFilters[filterName]?.length === 1) {
              view = `single_${filterName}`;
              break;
            }
          }
        }
      }
    }
    return launcherResultsViewSettings[view];
  }

  private async getCurrentAssistantId() {
    return (await this.hubService.globalAssistantId) || this.searchParamsService.assistant;
  }

  private async getSources(aggregations: string[]) {
    let settingsView: string = this.pageType;
    this.searchGroup = this.searchParamsService.getGroup();
    if (this.searchGroup) {
      settingsView = this.searchGroup.name;
    } else if (
      (!settingsView || settingsView === 'search') &&
      (this.resultsService.hasPostFilters() || this.resultsService.hasPreFilters())
    ) {
      const filters = this.filtersService.allFilters;
      const appFilterValues = filters.app || [];
      if (appFilterValues.some((app) => APPS_SUPPORT_VISITS.includes(app))) {
        settingsView = 'appView';
      } else {
        settingsView = 'view';
      }
    }
    if (this.DISABLED_SUGGESTIONS_PAGE.includes(this.pageType) || this.searchGroup) {
      this.hubService.suggestionsEnabled = false;
    } else {
      this.hubService.suggestionsEnabled = !this.searchGroup;
    }

    const format = this.getSearchFormat();
    this.sortOptions = getSortOptions(settingsView);
    const assistantId = await this.getCurrentAssistantId();
    const platform: SettingsPlatform = this.getSearchPlatform(assistantId);
    const preFilters = this.filtersService.getPreFilters(true);
    const postFilters = this.filtersService.postFilters;
    const sort = this.searchParamsService.getSort();
    const collection = this.searchParamsService.collection;
    const settings = cloneDeep(
      this.isGoLinksOnlyState ? [ResultSettings.goLinksOnly] : ResultSettings.GetSettings(platform, format, settingsView)
    );
    const viewInSearchPage: boolean = (!!this.hubService.activePage || !!this.searchGroup) && this.searchOnEnterMode;
    for (const setting of settings) {
      if (assistantId) {
        setting.assistantId = assistantId;
      }
      const isLinkResources = isLinkResourcesSettings(setting) || isMailResourcesSettings(setting) || isGroupResourcesSettings(setting);
      if (isLinkResources) {
        setting.aggregations = aggregations;
        setting.node = this.navTreeService.currentNode;
        if (viewInSearchPage) {
          setting.advancedSearch = true;
          setting.contentSearch = true;
          setting.disableAggregations = false;
        }
      }
      if (sort) {
        setting.sorting = sort;
      }
      if (isRelevantPeopleSettings(setting)) {
        setting.aggregations = aggregations;
      }
      if (isStaticCollectionSettings(setting) || isWikiCollectionSettings(setting) || isAnswersSettings(setting)) {
        setting.collectionId = collection;
      }
      const customerMergeFunc = (objValue: any, srcValue: any) => {
        if (isArray(objValue)) {
          return uniq(objValue.concat(srcValue));
        }
      };
      const mergedPreFilters = mergeWith(cloneDeep(preFilters) || {}, setting.filters?.preFilters || {}, customerMergeFunc);
      const mergedPostFilters = mergeWith(cloneDeep(postFilters) || {}, setting.filters?.postFilters || {}, customerMergeFunc);

      setting.filters = { ...(setting.filters || {}), preFilters: mergedPreFilters, postFilters: mergedPostFilters };
      if (setting?.filters?.excludeFilters?.includes('Person')) {
        const peopleLinkId = await firstValueFrom(this.linksService.peopleLinkId$);
        if (isLinkResources && peopleLinkId && settings.some((s) => isRelevantPeopleSettings(s) || isPeopleSettings(s))) {
          setting.filters.excludeFilters.push('PeopleLink');
        }
      }

      if (this.disableWikis) {
        if (setting?.filters?.excludeFilters) {
          setting?.filters?.excludeFilters.push('Wiki');
        } else {
          setting.filters.excludeFilters = ['Wiki'];
        }
      }

      setting.group = this.searchGroup;
      if (this.currentNode?.id != 'search' && setting.header) {
        setting.header.title = format == 'default' ? 'Results' : `Showing ${resultHeaderCountKey} results`;
      }
      if (isVisitsSettings(setting) && settingsView === 'appView') {
        if (setting.filters?.postFilters?.app?.length) {
          const matchedAppValues = cloneDeep(setting.filters.postFilters.app.filter((f) => APPS_SUPPORT_VISITS.includes(f)));
          setting.filters.postFilters = { ...setting.filters.postFilters, app: matchedAppValues };
        }
        if (setting.filters?.preFilters?.app?.length) {
          const matchedAppValues = cloneDeep(setting.filters.preFilters.app.filter((f) => APPS_SUPPORT_VISITS.includes(f)));
          setting.filters.preFilters = { ...setting.filters.preFilters, app: matchedAppValues };
        }
      }
    }
    return settings;
  }

  private getSearchPlatform(assistantId?: string): SettingsPlatform {
    if (this.isNative) {
      return this.isLauncher ? 'desktop-native-search-bar' : 'desktop-native-search-page';
    }
    if (this.isBrowserExtension) {
      return this.isLauncher ? 'desktop-browser-extension-search-bar' : 'desktop-browser-extension-search-page';
    }
    if (assistantId) {
      if (this.isEmbed) {
        return this.isLauncher ? 'desktop-assistant-embed-search-bar' : 'desktop-assistant-embed-search-page';
      }
      return 'desktop-assistant-web-search-page';
    }
    if (this.isLauncher) {
      return 'desktop-web-embed-search-bar';
    }
    if (this.embedInline) {
      return 'desktop-web-search-page';
    }
    if (this.isEmbed) {
      return 'desktop-web-embed-search-page';
    }
    return 'desktop-web-search-page';
  }

  private getSearchFormat(): SettingsFormat {
    const query = this.hubService.query;
    const hasFilters = Object.keys(this.filtersService.inlineFilters || {}).length || this.resultsService.hasPostFilters();
    let format: SettingsFormat;
    const sort = this.searchParamsService.getSort();
    if (sort) {
      format = 'sorting';
    } else if (this.searchGroup?.name === 'golinks' || this.searchGroup?.name === 'collections') {
      format = 'group';
    } else {
      format = !!query || hasFilters ? 'search' : 'default';
    }
    return format;
  }

  async onSearchCompleted(query: string, extra: LinkResourcesResultExtra | MailResultExtra, searchStart: number) {
    this.firstSearchCompleted = true;
    if (!this.longSearchReported && (Config.search.traceTimes || Date.now() > searchStart + this.longSearchTime)) {
      this.handleLongSearch();
      this.longSearchReported = true;
    }
    if (this.longSearchTimeout) {
      clearTimeout(this.longSearchTimeout);
      this.longSearchTimeout = null;
    }
    if (isPerson(this.selected)) {
      const results = this.displayedContext.items.filter((r) => r.type !== 'header');
      if (results.length === 1 && this.selected?.action?.type === 'people' && !this.resultsService.isDefaultSearch()) {
        this.previewService.setPreviewState('full', 1, results[0]);
      }
    }
    this.calcPreviewState();
    this.viewReady = true;

    this.updateEmptyState();
    if (this.searchFirstCompleted) {
      this.loadPeopleData(0, true);
    }
    if (!this.isActivePreview) {
      this.previewService.setPreviewState('none');
    } else if (this.isActivePreview && !this.isSmallScreen) {
      const resultsLength = this.displayedContext.items.filter((r) => r.type !== 'header').length;
      this.previewService.manageStateOnComplete(this.selected, resultsLength, extra, this.resultsService.isDefaultSearch());
    }
    if (this.pageType === 'mail') {
      this.inboxFilterOnly = (extra as MailResultExtra).inboxFilterOnly;
    }
    if (this.pageType === 'golinks') {
      if (this.newGoLink) {
        this.showCreateGoLinkToaster(this.newGoLink);
        this.goLinksService.newGoLink = null;
      }
      if (this.displayedContext?.items?.length && !this.extensionInstalled) {
        this.showToasterInstallExtension();
      }
    }
    if (this.pageType != 'golinks' && this.toasterRef?.data.id === 'install-extension') {
      this.toasterRef.destroy();
    }
    if (!this.displayedContext) return;
    this.displayedContext.items.forEach((item, i) => {
      item.resourceList = listNameByType(item);
      item.resultIndex = this.getRealPosition(i);
    });
    const ctx = this.displayedContext;
    this.handleResultsLinksState();
    if (ctx.sessionId !== this.linksStateSessionId) {
      this.handleResultsLinksState();
    }

    const noResultItems = !ctx.items?.length;
    if (noResultItems && this.resultsService.isDefaultSearch()) {
      this.noCards = this.pageType === 'cards';
      this.noDrafts = this.pageType === 'drafts';
      if (this.noCards) {
        this.resultFilters = [];
      }
      this.emptyStateModel = EMPTY_STATE_LIST[this.pageType];
      if (this.emptyStateModel) {
        this.hubService.readOnly = true;
        this.hubService.suggestionsEnabled = false;
      }
    } else {
      this.noCards = this.noDrafts = false;
    }
    this.updateEmptyState();

    await this.updateDisplayedBarItems();
    const hasNonAppFilters = Object.keys(this.filtersService.getPreFilters()).filter((key) => key !== 'app').length === 0;
    if (extra?.isSingleApp && hasNonAppFilters && !query && noResultItems) {
      const apps = extra.searchRequest.preFilters?.app;
      const accounts = extra.searchRequest.preFilters?.account;

      if (this.view === 'view') {
        this.select('first');
        this.handleButtonsAfterSearch();
        return;
      }
      let appId;
      let linkIds: string[];

      if (accounts?.length === 1) {
        const links = this.allLinks?.filter((l) => {
          if (l.name !== accounts[0]) return false;
          else if (apps?.length === 0) return true;
          const appNames: string[] = Object.values(this.applicationsService.apps).map((a) => a.name);
          return apps.some((appName) => {
            const idx = appNames?.indexOf(appName);
            return Object.keys(this.applicationsService.apps)[idx] === l.appId;
          });
        });
        linkIds = links?.map((l) => l.id);
      } else if (apps?.length === 1) {
        const idx = Object.values(this.applicationsService.apps)
          .map((a) => a.name)
          .indexOf(apps[0]);
        if (idx !== -1) appId = Object.keys(this.applicationsService.apps)[idx];

        linkIds = this.allLinks?.filter((link) => link.appId === appId).map((l) => l.id);
      }

      if (linkIds) {
        let status: Fyis.SyncStatus = 'Succeeded';
        for (const id of linkIds) {
          const linkStatus = this.syncsService.linksSyncStatuses?.[id];

          if (linkStatus === 'Started') {
            status = linkStatus;
            break;
          } else if (linkStatus === 'Failed') status = linkStatus;
        }

        this.syncingStateData = {
          status,
          appId,
          linkId: accounts?.length === 1 || linkIds?.length === 1 ? linkIds[0] : undefined,
          location: this.hubService.currentLocation,
        };

        this.view = 'syncing';
        this.handleResultsLinksState();
      }
    }
    this.handleButtonsAfterSearch();
    const session = await firstValueFrom(this.sessionService.current$);
    if (session) {
      this.displayAppsEmptyState = this.shouldDisplayAppsEmptyState;
    }
  }

  private handleButtonsAfterSearch() {
    this.displayTogglePreview = this.shouldDisplayTogglePreview();
    this.displaySortDropDown = this.shouldDisplaySortDropDown();
    this.disableButtons = this.shouldDisableButtons();
  }

  private handleLauncherResultsAfterSearch() {
    if (this.resultsService.isDefaultSearch() || this.isGroupOrView) {
      return;
    }
    const currentAnswerState = this.currentAnswerItem?.state;
    if (AnswerSearchReady.includes(currentAnswerState)) {
      this.displayedContext.items = this.displayedContext.items.filter(
        (elm) => isAnswerItem(elm) || isOpenAdvancedSearch(elm) || isOpenChat(elm)
      );
    }
    if (currentAnswerState === 'Loading') {
      this.displayedContext.items = this.displayedContext.items.filter((elm) => isAnswerItem(elm));
    }
    this.cdr.markForCheck();
  }

  ngAfterViewInit(): void {
    this.showLinksStateToaster$ = new BehaviorSubject(null);
    this.windowSize$.pipe(untilDestroyed(this)).subscribe(() => {
      this.calcPreviewState();
      this.closePopups();
    });
    this.showToaster$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.showToaster(data);
    });

    this.resultElements.changes.pipe(takeUntil(this.destroy$)).subscribe((changes: QueryList<ElementRef<any>>) => {
      const array = changes.toArray().sort((a, b) => a.nativeElement.dataset?.index - b.nativeElement.dataset?.index);
      this.resultElements.reset(array);
    });
  }

  closePopups() {
    if (this.displaySortDropDown && this.sortService.popupSortRef) {
      this.sortService.popupSortRef.destroy();
    }
  }

  async onStaticItemInvoke(type: StaticSearchItemType, item: StaticSearchItem) {
    switch (type) {
      case 'open-external': {
        this.onOpenFullAppClicked();
        break;
      }
      case 'search-answer': {
        this.retryAnswersSearch();
        break;
      }
      case 'fill-questionnaire': {
        this.rfpItemClicked(item);
        break;
      }
      case 'open-chat': {
        if (isAnswerItem(this.currentAnswerItem)) {
          const globalAssistantId = await this.hubService.globalAssistantId;
          this.chatsService.goToChatPageWithHistory(this.currentAnswerItem, globalAssistantId);
        }
        break;
      }
    }
  }

  private retryAnswersSearch() {
    const retrySources: RetrySourceModel[] = [];
    const answerSourceIndex = this.displayedContext.sources.findIndex((item) => item.source.type === 'answers');
    if (answerSourceIndex === -1) return;
    const answerSourceSettings = this.displayedContext.sources[answerSourceIndex].source as AnswersSourceSettings;
    answerSourceSettings.noResults = false;
    retrySources.push({ sourceIndex: answerSourceIndex, sourceSettings: answerSourceSettings });
    if (this.isLauncher) {
      this.isAskUnleash = true;
    }
    const staticSourceIndex = this.displayedContext.sources.findIndex((item) => item.source.type === 'static-items');
    if (staticSourceIndex != -1) {
      const staticSourceSettings = this.displayedContext.sources[staticSourceIndex]?.source as StaticItemsSourceSettings;
      retrySources.push({ sourceIndex: staticSourceIndex, sourceSettings: staticSourceSettings });
    }
    this.searchSession.retrySources(retrySources, true);
  }

  private async updatePlaceholder(id: number, query: string) {
    if (query) return;
    if (this.isEmptyState && (this.isLauncher || this.isEmbed)) {
      this.hubService.placeholder = '';
      return;
    }
    const searchAlive = id ? this.getSearchAliveCheck(id) : () => true;
    const DEFAULT = SEARCH_WITH_FILTERS_PLACEHOLDER;
    const placeholder = resultsViewSettings[this.pageType]?.placeholder;

    if (this.searchGroup) {
      this.hubService.placeholder = `Search in ${this.searchGroup.placeholder ?? this.searchGroup.title}`;
      return;
    }

    if (['collections', 'wikis', 'cards', 'assistants'].includes(this.pageType)) {
      this.hubService.placeholder = `Search in ${this.pageType}`;
      return;
    }

    if (this.collectionTypes.includes(this.pageType)) {
      const collection = await this.collectionsService.getById(this.collectionId);
      this.hubService.placeholder = `Search in ${collection.title}`;
      return;
    }

    if (placeholder) {
      this.hubService.placeholder = placeholder;
      return;
    }

    const inFilters = this.filtersService.getPreFilters();

    if (inFilters.type?.length) {
      const types = inFilters.type.map((t) => pluralize(t));
      this.hubService.placeholder = 'Search for ' + types.join(', ');
      return;
    }

    if (inFilters.service?.length) {
      this.hubService.placeholder = 'Search ' + inFilters.service[0];
      return;
    }

    if (!Object.keys(inFilters).length) {
      this.hubService.placeholder = DEFAULT;
      return;
    }
    if (inFilters.app?.length === 1 && inFilters.app[0] === 'Microsoft 365') {
      this.hubService.placeholder = 'Search Microsoft 365';
    } else {
      const descriptions = [];
      const apps = (await this.applicationsService.all()).filter((app) => inFilters?.app?.includes(app.name) && app.id != 'office365');
      for (const app of apps) {
        const desc = await this.applicationsService.getDescription(app.id);
        if (!searchAlive()) {
          return;
        }
        if (!desc) {
          continue;
        }
        descriptions.push(desc);
      }

      if (!descriptions || descriptions.length === 0) {
        this.hubService.placeholder = DEFAULT;
        return;
      }

      const bullets = descriptionsToBullets(descriptions);
      this.hubService.placeholder = bullets ? `Search for ${bullets.join(', ')} and more...` : DEFAULT;
    }
  }

  private getResultsType(): resultType {
    const currentNode = this.navTreeService.currentNode;
    if (this.resultsService.isDefaultSearch() && !this.hubService.getState('ap')?.length && currentNode?.group !== 'views') {
      return 'default';
    }
    if (Object.keys(this.filtersService.tagFilters).length) {
      return 'app-tag';
    }
    if (this.searchGroup) {
      return 'search-group';
    }
    return 'user-search';
  }

  ngAfterViewChecked() {
    if (!this.viewReady) return;
    this.hubService.setViewReady(this.ID);
    this.viewReady = false;
  }

  deselect() {
    if (this.displayedContext) {
      this.selectedIndex = undefined;
    }
  }

  async handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): Promise<void> {
    if (this.wikiCardPreviewService.popupRef || this.isPreviewDirty()) return;
    const modifiers = getModifiers(keys);
    const nonModifiers = removeModifiers([...keys]);
    const shouldHandleLocalOnly =
      this.localShortcut && this.displayedContext?.items.some((item) => isHeader(item) && item.origin === 'local');
    if (shouldHandleLocalOnly) {
      const local = this.localShortcut.map((k) => k.toLowerCase());
      if (local.includes('commandorcontrol')) local[local.indexOf('commandorcontrol')] = 'control';

      if (local.every((k) => keys.includes(k as KeyName))) {
        this.localFilter(!keys.includes('shift'), 'keyboard');
        event.stopPropagation();
        return;
      }
    }
    const item = this.displayedContext.items[this.selectedIndex];
    const key = nonModifiers[0];
    if (isEnterKey(key) && (item?.type !== 'header' || (item as HeaderItem)?.selectable) && item?.type === 'local-action') {
      this.onActionItemClick(this.selectedIndex, 'keyboard');
    }
    if (key === 'tab' && this.isComponentFocused) {
      if (modifiers.length && modifiers[0] === 'shift') {
        this.previewKeyboardService.setComponentFocused('prev');
      } else {
        this.previewKeyboardService.setComponentFocused('next');
      }
      if (this.fetchedPages) {
        const group = (this.displayedContext.items[this.focusedGroup] as HeaderItem)?.group;
        if (group) {
          this.searchParamsService.addGroup(group);
        }
        event.stopPropagation();
      }
      return;
    }

    if (key == 'home' && modifiers[0] == 'control') {
      this.keyboardOn = true;
      this.select('first');
      event.stopPropagation();
      return;
    }

    if (key == 'end' && modifiers[0] == 'control') {
      this.keyboardOn = true;
      this.select(this.displayedContext?.items.length - 1);
      event.stopPropagation();
      return;
    }

    const selectedItem = this.hoveredIndex ? this.getResultItem(this.hoveredIndex) : this.getResultItem(this.selectedIndex);

    if (modifiers.length === 1 && modifiers[0] === 'shift' && nonModifiers.length === 1 && isEnterKey(nonModifiers[0])) {
      await this.enterPressed(event, true);
      return;
    }
    if (modifiers.length && selectedItem?.['shortcutsHandlers']) {
      if (
        (keys.length === 2 && (keys[0] === 'r' || keys[0] === 'f' || keys[0] === 'd') && keys[1] === 'control') ||
        (keys.length === 3 && keys[0] === 'c' && keys[1] === 'control' && keys[2] === 'shift')
      ) {
        event.stopPropagation();
        event.preventDefault();
      }
      if (isMac()) {
        keys = keys.map((k) => (k = k === 'control' ? 'command' : k));
      }
      const action = selectedItem['shortcutsHandlers']?.find(
        (a) => a.keyBinding?.length === keys.length && a.keyBinding?.every((k) => keys.includes(k))
      );
      if (!action) return;
      action.fn(selectedItem, 'keyboard');
      return;
    }

    if (
      isSingleKey('c', keys) &&
      this.currentNode?.id === 'cards' &&
      !this.searchBarFocus &&
      !this.smallInputSearchFocus &&
      this.hubService.focusPosition !== 'filters'
    ) {
      this.openCard('keyboard');
      return;
    }

    if (isSingleKey('PageDown', keys)) {
      this.keyboardOn = true;
      return;
    }

    if (isSingleKey('PageUp', keys)) {
      this.keyboardOn = true;
      return;
    }

    if (nonModifiers.length > 1) return;
    const isGalleryItem = this.isGalleryItem();
    switch (key) {
      case 'ArrowDown':
        if (isGalleryItem) {
          this.findNotGalleryItem('ArrowDown', event);
          return;
        }
        this.onKeyArrowDown(event);
        return;
      case 'ArrowUp': {
        if (this.smallInputSearch) {
          event.preventDefault();
        }
        if (isGalleryItem) {
          this.findNotGalleryItem('ArrowUp', event);
          return;
        }
        this.onKeyArrowUp(event);
        return;
      }
      case 'ArrowRight': {
        if (isGalleryItem) {
          this.onKeyArrowDown(event);
          return;
        }
        if (this.hubService.focusPosition === 'filters') this.updateFocusState('next');
        return;
      }
      case 'ArrowLeft': {
        if (isGalleryItem) {
          this.onKeyArrowUp(event);
          return;
        }
        if (this.hubService.focusPosition === 'filters') this.updateFocusState('prev');
        return;
      }
      case 'escape': {
        if (this.isAnswerFeedbackOpen) {
          this.hideAnswerFeedback(this.answerFeedbackIndex);
          event.stopPropagation();
        }
        if (this.isPreviewMode && !this.keyboardService.stopListening) {
          this.updatePreviewState('previous');
          this.hubService.changeFocusState(true);
          event.stopPropagation();
        }
        return;
      }
      case 'slash': {
        if (this.smallInputSearch && !this.smallInputSearchFocus) {
          this.smallInputSearch.focusInput();
          event.stopPropagation();
          event.preventDefault();
          return;
        }
      }
    }
    if (isEnterKey(key)) {
      if (this.datePickerService.isVisible) return;
      if (this.isComponentFocused) {
        if (this.previewKeyboardService.componentFocused?.compId === this.PreviewFocusEnum.PreviewToggle) {
          this.toggleResultsPreview('keyboard');
          event.stopPropagation();
          return;
        }
        if (this.isActivePreview && getPrimaryActionClick(this.selected?.action) === 'preview') {
          if (this.previewViewModel.previewState === 'popup' && this.selected?.action?.type === 'files') {
            this.onFileItemClick();
          }
          this.updatePreviewState('popup', this.hoveredItem || this.selected, 'keyboard');
          event.stopPropagation();
          return;
        }
        if ((this.displayedContext?.items[this.selectedIndex] as HeaderItem)?.selectable) {
          this.focusGroupClick();
          event.stopPropagation();
          return;
        }
      }
      await this.enterPressed(event);
    }
    const printableKey = isPrintableKey(event) && !['slash', 'enter'].includes(key) && !isSemicolonKey(event);
    const barNotOnFocus = this.searchOnEnterMode ? !this.searchBarFocus : this.smallInputSearch && !this.smallInputSearchFocus;
    if (printableKey && barNotOnFocus && this.hubService.canFocus(true) && !this.isFeedbackFocused) {
      this.showToaster$.next({
        id: 'hit-slash-for-search',
        title: 'Hit / to jump back to the search box',
        irremovable: true,
      });
      event.stopPropagation();
    }
  }

  findNotGalleryItem(op: 'ArrowUp' | 'ArrowDown', event) {
    if (op === 'ArrowDown') {
      for (let index = this.selectedIndex + 1; index < this.displayedContext.items.length; index++) {
        const item = this.displayedContext.items[index];
        if (this.isGalleryItem(item)) {
          this.selectedIndex++;
        } else {
          break;
        }
      }
      this.onKeyArrowDown(event);
    } else {
      for (let index = this.selectedIndex - 1; index > 0; index--) {
        const item = this.displayedContext.items[index];
        if (this.isGalleryItem(item)) {
          this.selectedIndex--;
        } else {
          break;
        }
      }
      this.onKeyArrowUp(event);
    }
  }

  onKeyArrowDown(event) {
    if (this.previewViewModel.previewState !== 'popup') {
      this.keyboardOn = true;
      this.selectNext('keyboard');
      this.searchForFocusGroup(this.selectedIndex);
    }
    this.arrowDirection = 'down';
    this.updateFocusState('next');
    event.stopPropagation();
  }

  onKeyArrowUp(event) {
    const currentIndex = this.selectedIndex;
    if (this.previewViewModel.previewState !== 'popup') {
      this.keyboardOn = true;
      this.selectPrevious('keyboard');
      this.searchForFocusGroup(this.selectedIndex);
    }
    this.arrowDirection = 'up';
    this.updateFocusState('prev', event);
    if (this.selectedIndex !== null && currentIndex != this.selectedIndex) {
      event.stopPropagation();
    }
  }

  isGalleryItem(customItem?: SearchResults) {
    const item = customItem ?? this.displayedContext.items[this.selectedIndex];
    const viewMode = this.previewViewModel.previewState === 'side' ? 'small' : (item as GalleryItems)?.settings?.viewMode;
    return this.supportedGalleryItemTypes.includes(item?.action?.type) && viewMode === 'gallery';
  }

  private selectNext(trigger: TelemetryTrigger) {
    this.select('next');
    this.emitSelected(trigger);
    this.cdr.markForCheck();
  }

  private selectPrevious(trigger: TelemetryTrigger) {
    this.select('prev');
    this.emitSelected(trigger);
    this.cdr.markForCheck();
  }

  private localFilter(activate: boolean, via: FilterActivationMethod) {
    if (activate) {
      this.filtersService.addFilter('app', this.pcName, true);
      this.hubService.setState('search-trigger', 'local_results_see_more');
      this.reportHeaderFilterActivation(via, 'local_results_box');
    } else {
      this.filtersService.removeFilter('app', this.pcName, true);
    }
  }

  private actionsFilter(activate: boolean, via: FilterActivationMethod) {
    if (activate) {
      this.hubService.setState('actionsOnly', 'true');
      this.reportHeaderFilterActivation(via, 'actions_results_box');
    } else {
      const { actionsOnly, ...state } = this.hubService.state;
      this.hubService.openPage('', this.hubService.query, state);
    }
  }

  private reportHeaderFilterActivation(via: FilterActivationMethod, name: string): void {
    let record: Partial<EventInfo> = {
      category: 'interaction.keyboard',
      target: this.localShortcut.join('+'),
    };
    if (via === 'mouse') {
      record = {
        category: 'interaction.click',
        target: 'see_more',
      };
    }
    record = {
      ...record,
      location: { title: this.hubService.currentLocation },
      name,
      search: { clientSearchId: this.displayedContext?.clientSearchId, sessionId: this.displayedContext.sessionId },
    };

    this.eventsService.event('results.preview_box', record);
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.destroy$.next(null);
    this.searchSession.destroy();
    this.suggestionService.provider = null;
    this.tagsService.reset();

    if (this.keyHandlerId) this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    if (this.toasterRef) {
      this.toasterRef.destroy();
    }
  }

  ngOnInit(): void {
    this.hubService.enableCollectionIconFunc = () => this.resultsService.enableCollectionIcon;

    const autoFocus$ = this.hubService.autoFocus$.pipe(distinctUntilChanged());
    combineLatest([this.hubService.focusInputState$.pipe(distinctUntilChanged()), autoFocus$, this.flagsService.all$])
      .pipe(untilDestroyed(this))
      .subscribe(([res, autoFocus, flags]) => {
        if (flags) {
          this.disableCollections = flags.find((f) => f.flag === Constants.DISABLED_COLLECTIONS_FLAG)?.value;
          this.disableWikis = flags.find((f) => f.flag === Constants.DISABLED_WIKIS_FLAG)?.value;
        }
        this.searchBarFocus = res;
        if (this.searchBarFocus && !autoFocus) {
          this.hubService.focusPosition = 'searchBar';
          this.deselect();
        }
      });
    combineLatest([this.hubService.focusPosition$.pipe(distinctUntilChanged()), autoFocus$])
      .pipe(untilDestroyed(this))
      .subscribe(([focusPosition, autoFocus]) => {
        this.hubService.autoCompleteEnabled = focusPosition === 'searchBar' || (autoFocus && focusPosition === 'results');
      });
    this.workspaceService.ownerOrAdmin$.pipe(untilDestroyed(this)).subscribe((s) => {
      this.isOwnerOrAdmin = s;
    });

    this.previewService.previewModelChange$.pipe(untilDestroyed(this)).subscribe((v: PreviewViewModel) => {
      this.previewViewModel = cloneDeep(v);
      this.setSplitArea();
      this.cdr.markForCheck();
    });

    this.previewKeyboardService.init();

    this.previewKeyboardService.componentFocused$.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.isComponentFocused =
        !val ||
        [PreviewFocus.Results, PreviewFocus.PreviewToggle].includes(val?.compId) ||
        (this.previewViewModel.previewState === 'none' && this.isActivePreview);
      this.cdr.markForCheck();
    });
    this.sidebarService.onNodeClick$.pipe(untilDestroyed(this)).subscribe(() => {
      this.hubService.changeFocusState(true);
    });

    this.hubService.fullFocus = true;
    this.initRouteFilters();
    this.initShortcuts();
    this.initScroll();

    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 5);
    this.hubService.changeFocusStateMultiCalls(true, true);

    this.idToLoadPeople$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
      this.loadPeopleData(this.selectedIndex);
    });

    this.browserExtension.current$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.extensionInstalled = res ? true : false;
    });

    this.sessionService.current$.pipe(takeUntil(this.destroy$)).subscribe((s) => {
      this.searchTracker.updateAccount(s);
    });

    this.goLinksService.newGoLink$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.newGoLink = res;
    });

    this.hubService.openCollection.pipe(takeUntil(this.destroy$)).subscribe(() => this.resultsService.openCollection());

    this._showGhost$.pipe(takeUntil(this.destroy$), debounceTime(this.SHOW_GHOST_DEBOUNCE)).subscribe(this.showGhost$);

    this.summaryService.popupOpen$.pipe(untilDestroyed(this)).subscribe((res) => {
      this.onOpenSummaryPopup = res;
      this.cdr.markForCheck();
    });

    const collectionId = this.collectionId;
    if (collectionId) {
      this.collectionsService.addCollectionTag(collectionId);
    }

    combineLatest([this.applicationsService.all$, this.linksService.visible$])
      .pipe(untilDestroyed(this))
      .subscribe(async ([allApps, links]) => {
        if (!links) {
          return;
        }
        const apps = new Set(this.applicationsService.ignoreApps(allApps).map((a) => a.id));
        let hasApps = false;
        for (const link of links) {
          if (apps.has(link.appId)) {
            hasApps = true;
            break;
          }
        }
        this.noLinkedApps = !hasApps;
        const session = await firstValueFrom(this.sessionService.current$);
        if (session) {
          this.displayAppsEmptyState = this.shouldDisplayAppsEmptyState;
        }
        this.cdr.markForCheck();
      });

    this.windowSize$.pipe(untilDestroyed(this)).subscribe(() => {
      this.checkSmallScreen();
    });

    this.checkSmallScreen();
    this.cdr.markForCheck();
  }

  private checkSmallScreen() {
    this.smallScreen = getWidthBreakpointScreen() === 'small';
    this.cdr.markForCheck();
  }

  private initRouteFilters() {
    let lastNodeId: string;
    combineLatest([this.state$, this.resultsService.refreshCount$, this.navTreeService.currentNode$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(async ([_, refreshCount, node]: [any, number, any]) => {
        const currentNodeId = node?.id;
        if (!currentNodeId) {
          return;
        }
        const pageChanged = lastNodeId != currentNodeId;
        lastNodeId = node?.id;
        this.hubService.searchMethod = this.getSearchMethod();
        if (this.hubService.searchMethod === 'Quick-Search' && currentNodeId != 'search') {
          this.hubService.readOnly = true;
        }

        const refresh: boolean = refreshCount && this.refreshCount !== refreshCount;
        this.refreshCount = refreshCount;
        const newSession = !!(pageChanged && this.currentContext) || refresh;

        if (this.paramsSelectorService.paramsSelector) {
          this.paramsSelector = this.paramsSelectorService.paramsSelector;
          this.hubService.readOnly = false;
          return;
        }
        this.paramsSelector = null;
        this.hubService.autoFocus = this.hubService.searchMethod === 'Quick-Search';

        if (pageChanged) {
          this.hubService.stateChangedTime = Date.now();
          this.toasterRef?.destroy();
          const trigger = this.routerService.canGoBack ? 'navigation_tree' : 'reload';
          this.displayedContext = {
            id: 0,
            iterations: 0,
            ...(this.displayedContext || {}),
            items: [],
            searchCompleted: false,
          };
          this.view = undefined;
          this.firstSearchCompleted = false;
          this.selected$.next(null);
          this.focusedGroup = null;
          this.noGoLinks = false;
          this.isEmptyState = false;
          this.noCards = this.noDrafts = false;
          this.displayTogglePreview = !this.isLauncher && !this.isSmallScreen && supportPreview(currentNodeId);
          this.displaySortDropDown = this.shouldDisplaySortDropDown();
          this.cdr.markForCheck();
          this.lastState = JSON.parse(JSON.stringify(this.state));
          this.startSearch(trigger, newSession);
        } else {
          this.removeSortIfNeeded();
          this.onStateChanged(newSession, refresh);
        }
      });
  }

  private removeSortIfNeeded() {
    const sort = this.searchParamsService.getSort();
    if (this.resultsService.isDefaultSearch() && sort && !this.isGroupOrView) {
      this.searchParamsService.removeSort();
      this.sortSelected = null;
    }
  }

  resultFiltersOpen($event) {
    this.hubService.autoFocus = this.hubService.searchMethod === 'Quick-Search' ? !Number.isInteger($event) : false;
  }

  private updateFocusState(status: 'next' | 'prev', event?: CustomKeyboardEvent) {
    let newFocusState: FocusPosition;
    const currentFocusState: FocusPosition = this.hubService.focusPosition;
    if (status === 'prev') {
      if (this.selectedIndex || this.selectedIndex === 0) newFocusState = 'results';
      else {
        if (currentFocusState === 'filters' || !currentFocusState || currentFocusState === 'searchBar') newFocusState = 'searchBar';
        else {
          newFocusState = this.showFilterBox || (this.showBrowseBar && this.barModel?.items?.length) ? 'filters' : 'searchBar';
        }
      }
      if (newFocusState === 'searchBar') {
        this.hubService.changeFocusState(true);
      }
      if (this.isLauncher && newFocusState === 'filters') {
        this.hubService.changeFocusState(false, true);
        event.stopPropagation();
      }
    }
    if (status === 'next') {
      if (currentFocusState === 'filters' || this.selectedIndex || currentFocusState === 'searchBar') newFocusState = 'results';
      if (currentFocusState === 'searchBar' && newFocusState != 'searchBar' && !this.hubService.autoFocus) {
        this.hubService.changeFocusState(false);
      }
    }
    this.hubService.focusPosition = newFocusState;
  }

  async initSuggestionProviders(allowedFilters?: string[], enableCollection = true, enableWikis = true) {
    let providers = [];
    if (['search', 'favorites', 'people', 'mail', 'assistants'].includes(this.pageType)) {
      const assistantId = await this.getCurrentAssistantId();
      providers.push(this.filtersService.getSuggestionProvider(allowedFilters, assistantId));
    }

    if (enableCollection) {
      if (['collections'].includes(this.pageType) || this.pageType === 'search') {
        providers.push(this.collectionsService.getSuggestionProvider('collections'));
      }
    }

    if (enableWikis) {
      if (['wikis'].includes(this.pageType) || this.pageType === 'search') {
        providers.push(this.collectionsService.getSuggestionProvider('wikis'));
      }
    }

    if (this.searchGroup?.name === 'relevant-people') {
      providers = [];
    }
    this.suggestionsService.provider = new SuggestionsMultipleProviders(providers).provider;
  }

  async onAction(data: { command: Commands.Command; context: { resourceId: string; linkId: string; searchId: string; userInput?: any } }) {
    const { command, context } = data;
    await this.commandsService.executeCommand(command, context);
  }

  onFilterChange(data: FilterChangeData) {
    this.resultsService.handleDataChanges(data);
  }

  clearAllFilters() {
    this.filtersService.removeAllFilters('all', false);
  }

  onCalcItemClick(index: number): void {
    this.updateSelectedIndex(index);
    const item: Calculators.Item = this.displayedContext.items[this.selectedIndex] as Calculators.Item;
    this.onInvoke({ command: item?.resource.result.onClick, trigger: 'mouse_click' }, 'calculator');
  }

  onBookmarkClick(trigger: TelemetryTrigger) {
    const item = this.selected as SearchResults;
    const data = {
      command: { type: 'open-url', url: (item as BrowserBookmarkItem).url },
      trigger,
      context: { openInSelf: false, searchId: (<any>item).searchId || this.searchId },
      invokerItem: item,
    } as InvokeCommand;

    this.onInvoke(data, 'browser-bookmark');
  }

  onDisclaimerClick(url: string): void {
    try {
      const command: Commands.OpenUrl = { type: 'open-url', url };
      this.onInvoke({ command, trigger: 'mouse_click' }, 'calculator');
    } catch (e) {
      this.logger.error(e);
    }
  }

  onTabClick(trigger: TelemetryTrigger) {
    const item = this.selected as Search.Item;
    const position = this.getRealPosition(this.selectedIndex);
    const event = this.resultCommandService.getEventMetadata(
      this.displayedContext?.sessionId,
      this.displayedContext?.clientSearchId,
      (<any>item).searchId || this.searchId,
      trigger,
      'browser-tab',
      item,
      position,
      'tabs'
    );
    this.eventsService.event('results.redirect', event);
    this.browserTabsService.activate(item.id);
  }

  onAnswerTypedFinished() {
    this.isTypedAnswer = true;
  }

  showAnswerFeedback($event, index: number) {
    const { resources, intent, type } = $event;
    if (this.isAnswerFeedbackOpen) {
      this.hideAnswerFeedback(index + 1);
    }
    const answerFeedback: AnswerFeedbackSearchItem = {
      type: 'answer-feedback',
      intent,
      feedbackType: type,
      resources: resources,
      linkIds: resources?.map((r) => r.link.id).join(','),
      resourceIds: resources?.map((r) => r.id).join(','),
    };
    this.displayedContext?.items.splice(index + 1, 0, answerFeedback);
    this.isAnswerFeedbackOpen = true;
    this.answerFeedbackIndex = index + 1;
    this.resultsLimit += 1;
    this.cdr.markForCheck();
    setTimeout(() => {
      this.infiniteScrollService.scrollIfNeeded(this.resultElements?.toArray()[index + 1]);
    }, 0);
  }

  hideAnswerFeedback(index: number) {
    this.displayedContext?.items.splice(index, 1);
    this.isAnswerFeedbackOpen = false;
    this.resultsLimit -= 1;
    this.cdr.markForCheck();
  }

  private addAnswerFeedbackIfNeeded(items: SearchResults[]) {
    const answerFeedback = items.find((item) => item.type === 'answer-feedback');
    if (answerFeedback && this.isAnswerFeedbackOpen) {
      this.displayedContext?.items.splice(1, 0, answerFeedback);
      this.resultsLimit += 1;
      this.cdr.markForCheck();
    }
  }

  focusAnswerFeedbackText($event) {
    this.isFeedbackFocused = $event;
    this.hubService.autoFocus = !this.isFeedbackFocused;
  }

  async onInvoke(data: InvokeCommand, type: Search.ResultType, index?: number): Promise<void> {
    if (index && data.command?.type !== 'summary') {
      this.updateSelectedIndex(index);
    }
    const items = this.displayedContext.items;
    const sessionId = this.displayedContext?.sessionId;
    const clientSearchId = this.displayedContext?.clientSearchId;
    const resultItem = items[this.selectedIndex];

    if (isDynamicCommand(data.command)) {
      const position = this.getRealPosition(this.selectedIndex);
      const event = this.resultCommandService.getEventMetadata(
        this.displayedContext?.sessionId,
        this.displayedContext?.clientSearchId,
        (<any>resultItem).searchId || this.searchId,
        data.trigger,
        type,
        resultItem as Search.Item,
        position
      );
      const command: Commands.DynamicCommand = data.command as Commands.DynamicCommand;
      this.onDynamicCommand(command.value, data.invokerItem, event);
      return;
    }

    if (isPreviewCommand(data.command)) {
      if (this.isPreviewClickAction(this.selected?.action)) {
        this.selected$.next([
          {
            item: <Search.ResultResourceItem>this.selected,
            trigger: data.trigger as TelemetryTrigger,
            isFirst: this.isFirstItem,
            isLast: this.isLastItem,
          },
        ]);
      }
    }
    if (isNewCollectionCommand(data.command)) {
      this.collectionNavigate(data.command.value);
      return;
    }

    this.resultCommandService.onInvoke(
      items,
      data,
      type,
      sessionId,
      clientSearchId,
      (<any>resultItem)?.searchId,
      this.selectedIndex,
      this.displayedContext.lastHeaderIndex
    );
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (isKey(event, keyCodes.tab) && this.displayedContext.items && this.selectedIndex === this.displayedContext.items.length - 1) {
      event.preventDefault();
      this.select('first');
    }
  }

  onUserInput(input: any) {
    this.userInputOptions = null;
    this.userInput$.next(input);
    this.cdr.markForCheck();
  }

  openSiteLink(event: { item: Search.SiteLink; isRoot: boolean }) {
    this.eventsService.event('search.openSiteLink', {
      target: event.isRoot ? 'root' : 'sub',
      label: event.item.name,
    });
  }

  private isFirstSelected() {
    const all = this.displayedContext.items;
    let index = 0;
    let firstSelectable = 0;
    while (index < all.length) {
      const item = all[index];
      if (isSelectable(item)) {
        firstSelectable = index;
        break;
      }
      ++index;
    }
    return this.selectedIndex === firstSelectable;
  }

  select(value: Select, noScroll?: boolean): void {
    if (!this.displayedContext) return;

    this.previewKeyboardService.setComponentFocused(PreviewFocus.Results);
    const all = this.displayedContext.items;
    if (!all?.length) {
      this.selectedIndex = null;
      return;
    }
    if (Number.isInteger(value as any)) {
      let dx = value as number;

      if (dx < 0 || dx >= all.length) return;
      const ri = all[dx];
      if (!isSelectable(ri)) {
        if (all[dx + 1] != null && isSelectable(all[dx + 1])) ++dx;
        else if (all[dx - 1] != null && isSelectable(all[dx - 1])) --dx;
      }
      this.selectedIndex = dx;
    } else if (value === 'prev') {
      if (this.selectedIndex == null) {
        return;
      }
      let dx = this.selectedIndex - 1;
      while (dx !== -1) {
        const item = all[dx];
        if (isSelectable(item)) {
          this.selectedIndex = dx;
          break;
        }
        --dx;
      }
      if (dx === -1) {
        this.deselect();
      }
    } else if (value === 'next' || value === 'first') {
      let dx = 0;
      if (this.selectedIndex !== undefined && this.selectedIndex !== null && value !== 'first') {
        dx = this.selectedIndex + 1;
      } else if (this.selectedIndex === null || this.selectedIndex === undefined) {
        dx = 0;
      }

      while (dx < all.length) {
        const item = all[dx];
        if (isSelectable(item)) {
          this.selectedIndex = dx;
          break;
        }
        ++dx;
      }
    }
    if ((value === 'next' || value === 'prev') && this.pageType === 'people') {
      const selected: Search.ResultResourceItem = this.selected as Search.ResultResourceItem;
      this.idToLoadPeople$.next(selected?.resource?.externalId);
    }

    // first mark the item then scroll
    this.cdr.markForCheck();

    let ti = this.selectedIndex;
    if (!this.selectedIndex && this.displayedContext.items[0]?.type == 'header') ti = 0;
    if (!noScroll) {
      this.infiniteScrollService.scrollIfNeeded(this.resultElements?.toArray()[ti]);
    }
    if (isResourceResult(this.selected) || isPerson(this.selected)) {
      this.selected$.next([{ item: <Search.ResultResourceItem>this.selected, isFirst: this.isFirstItem, isLast: this.isLastItem }]);
    }
  }

  private async enterPressed(event: CustomKeyboardEvent, shiftKey?: boolean, trigger: TelemetryTrigger = 'keyboard') {
    if (this.datePickerService.isVisible) return;
    const item = this.hoveredItem || this.selected;
    const resultItem = (this.hoveredItem || this.selected) as Search.ResultItem;
    if (!resultItem) {
      return;
    }
    event.stopPropagation();

    if (
      (isResult(item) || isGoLink(item) || isPerson(item) || isCollectionUrl(item)) &&
      resultItem.view.title &&
      resultItem.view.title.onClick
    ) {
      const context: CommandContext = { searchId: (<any>item).searchId || this.searchId };
      if (isResourceResult(item)) {
        context.appId = item.resource.appId;
      }
      if (shiftKey && isResourceResult(item)) {
        const openWith = await this.resourcesService.openWith(item.resource.id);
        if (openWith) {
          context.openWith = openWith === 'app' ? 'browser' : 'app';
        }
      }
      this.onInvoke({ command: resultItem.view.title.onClick, context, invokerItem: item, trigger }, isGoLink(item) ? 'go-link' : 'result');
      this.hoveredIndex = null;
      this.hoveredItem = null;
      this.cdr.markForCheck();
    } else if (isCalculation(item) && item.resource.result?.displayText) {
      this.onInvoke({ command: item.resource.result.onClick, trigger: 'keyboard' }, 'calculator');
    } else if (isErrorItem(item)) {
      const curr = this.getResultItem(this.selectedIndex) as ErrorItemComponent;
      curr.stackExpanded = !curr.stackExpanded;
    } else if (item.type === 'local-action') {
      if (item.id === 'new-go-link') {
        this.onCreateGoLinkItemClick();
      }
    } else if (isRecentSearch(item)) {
      this.recentSearchClicked(item);
    } else if (isWebSearch(item)) {
      this.onWebSearchItemClick(item);
    } else if (isGoTo(item)) {
      this.onGoToItemClick(item);
    } else if (isCreateGoLink(item)) {
      this.onCreateGoLinkItemClick((item as CreateGoLinkItem).url);
    } else if (isPerson(item)) {
      this.onPreviewItemClick(this.selected as Search.ResultResourceItem, this.selectedIndex);
    } else if (isCollection(item)) {
      this.openCollection(item, this.isLauncher ? 'search-view' : 'collections-view');
    } else if (isSuggestion(item)) {
      this.onSuggestionItemClicked(item);
    } else if (isBookmark(item)) {
      this.onBookmarkClick(trigger);
    } else if (isTab(item)) {
      this.onTabClick(trigger);
    } else if (isAddToCollection(item)) {
      this.onAddToCollectionItemClick((item as AddToCollectionItem).url, 'keyboard');
    } else if (isOpenAdvancedSearch(item)) {
      this.onOpenFullAppClicked();
    } else if (isAnswerSearch(item)) {
      this.retryAnswersSearch();
    } else if (isAssistantItem(item)) {
      await this.openAssistant(item);
    } else if (isRfpItem(item)) {
      this.rfpItemClicked(item);
    } else if (isOpenChat(item)) {
      this.chatsService.openChat();
    }
  }

  onEmbedItemClick(i) {
    this.embedService.openUrl(i.url);
  }

  private async initShortcuts() {
    const s = await firstValueFrom(this.keyboardService.get('_results_localOnly'));
    if (s) {
      this.localShortcut = s.keys as Array<string>;
      this.addShortcut('local', <string[]>s.keys);
    }

    const s1 = await firstValueFrom(this.keyboardService.get('_results_actionsOnly'));
    if (s1) {
      this.actionsShortcut = s1.keys as Array<string>;
      this.addShortcut('actions', <string[]>s1.keys);
    }
    this.cdr.markForCheck();
  }

  private initScroll() {
    this.infiniteScrollService = new InfiniteScrollService();
  }

  headerClicked(source: ResultsOrigin) {
    switch (source) {
      case 'local':
        this.localFilter(true, 'mouse');
        break;
      case 'actions': {
        this.actionsFilter(true, 'mouse');
      }
    }

    return false;
  }

  async recentSearchClicked(recentSearch: RecentSearchItem) {
    const position = this.displayedContext.items.filter((i) => i.type === 'recent-search').findIndex((elm) => elm === recentSearch);
    const assistantId = await this.getCurrentAssistantId();
    this.recentSearches.reSearch(recentSearch, position, assistantId);
  }

  private async rfpItemClicked(item: RfpSearchItem) {
    await this.questionnaireService.open({ processId: item.processId });
  }

  private addTagFilter(tag: Omnibox.Tag | Omnibox.Suggestion, addTag = true) {
    const filter = <FilterSuggestionData>tag.data;
    this.filtersService.addFilter(filter.name, filter.value, addTag, 'pre');
  }

  async updateResultsLimit() {
    if (!this.displayedContext.items?.length) {
      return;
    }
    await this.nextPagePromise;
    this.resultsLimit = Math.min(this.resultsLimit + this.INITIAL_ITEMS_LIMIT, this.infiniteScrollService.MAX_RESULTS_LIMIT);
    this.cdr.markForCheck();
  }

  onScrollStart($event) {
    const readyForNextPage = this.infiniteScrollService.handleScroll($event);
    if (!readyForNextPage) {
      return;
    }

    this.prepareNextPage();
  }

  private prepareNextPage(trigger?: ScrollTrigger) {
    if (this.pageType == 'analytics' || !this.lastPageInfo) {
      return;
    }
    if (this.displayedContext.items.length - this.resultsLimit > this.infiniteScrollService.NEXT_PAGE_MIN_COUNT) {
      return;
    }
    const ended = !!this.lastPageInfo?.last || this.lastPageInfo?.endIndex >= this.infiniteScrollService.MAX_RESULTS_LIMIT;
    if (this.nextPageRunning || ended || !this.displayedContext.searchCompleted) {
      return;
    }
    this.nextPageRunning = true;
    const isMailResource = this.displayedContext.sources?.some((s) => s.source.type === 'mail-resources');
    let resourceType;
    if (this.pageType === 'people') {
      resourceType = this.pageType;
    } else {
      resourceType = isMailResource ? 'mail-resources' : 'link-resources';
    }
    this.nextPagePromise = new ManualPromise();
    const sources = this.displayedContext.sources
      .filter((s) => s.source.type === resourceType)
      .map((s) => ({ id: s.source.id, type: s.source.type }));
    this.searchSession.nextPage$(trigger, sources).subscribe({
      next: (ctxs) => {
        if (!ctxs) {
          this.showGhost = true;
          return;
        }
        const resourceSource = this.getSource();
        const extra = resourceSource?.extra;
        this.lastPageInfo = extra?.lastPageInfo;
      },
      complete: () => {
        this.showGhost = false;
        this.nextPageRunning = false;
        if (!this.nextPagePromise.status) {
          this.nextPagePromise.resolve();
        }
      },
    });
  }

  private getSource(ctx?: SearchResultContext) {
    const sources = this.pageType === 'people' ? ['people'] : ['link-resources', 'mail-resources'];
    return (ctx || this.displayedContext).sources?.find((s) => sources.includes(s.source.type));
  }

  backToActions() {
    this.paramsSelectorService.removeParamsSelector();
    this.cdr.markForCheck();
  }

  async onActionItemClick(index: number, trigger: TelemetryTrigger) {
    this.updateSelectedIndex(index);
    const action = this.displayedContext.items[index] as LocalActions.LocalActionItem;
    switch (action.id) {
      case 'new-go-link':
        this.onCreateGoLinkItemClick();
        break;
      case 'new-curated-collection':
      case 'new-live-collection':
      case 'new-wiki-collection':
        this.collectionNavigate((<Commands.DynamicCommand>action.command).value);
        break;
      case 'new-card': {
        const item = {
          action: null,
          type: 'result',
          id: 'new',
          filterType: 'Wiki Card',
          resource: {
            externalId: 'new',
            appId: 'wiki',
          },
        };
        item.action = await this.resultsService.getResultAction(item as SearchResults);
        this.commandsService.executeCommand({
          type: 'preview',
          model: item,
          previewable: true,
          state: 'popup',
          pageType: 'search',
        } as Commands.Preview<SearchResults>);
        break;
      }
      case 'new-rfp-assistant':
        this.assistantService.createAssistant({ experienceType: 'rfp' });
        break;
      case 'new-salesforce-cases-assistant':
        this.assistantService.createAssistant({ experienceType: 'salesforce' });
        break;
      case 'new-general-assistant':
        this.assistantService.createDraftAssistant('general');
        break;
      case 'new-chat':
        this.chatsService.openChat();
        break;
      default:
        {
          const firstLinkId = Object.keys(action.urls)[0];
          const context: Commands.Context = {
            searchId: (<any>action).searchId || this.searchId,
            linkId: action.urls[firstLinkId][0].linkId,
            resource: { id: action.id, kind: 'local-action' },
          };
          this.onInvoke({ command: action.command, context, trigger, invokerItem: action }, 'local-action');
        }
        break;
    }
  }

  diffState(): string[] {
    const changes = [];

    const nstate = this.state;
    const lstate = this.lastState || {};

    for (const k of new Set(Object.keys(nstate).concat(Object.keys(lstate)))) {
      if (
        !lstate[k] ||
        !nstate[k] ||
        lstate[k].length != this.state[k].length ||
        lstate[k].some((x) => !nstate[k].includes(x)) ||
        nstate[k].some((x) => !lstate[k].includes(x))
      )
        changes.push(k);
    }
    return changes;
  }

  handleScrollImpression(results: ResultDisplayModel[]) {
    const resources: EventInfoResource[] = results
      .map((r) => {
        if (isResourceItem(r) && !isHeader(r) && r.resource) {
          return {
            position: r.displayIndex,
            category: '',
            appId: r.resource.appId,
            linkId: r.resource.linkId,
            id: r.resource.id,
            list: listNameByType(r),
            type: r.resource.type,
          };
        }
        if (isLocalAction(r)) {
          return {
            position: r.displayIndex,
            category: '',
            appId: r.appId,
            list: listNameByType(r),
            id: undefined,
            type: undefined,
            linkId: undefined,
          };
        }
      })
      .filter((i) => !!i);

    this.analyticsService.event({
      category: 'resources',
      name: 'impression',
      location: { title: this.hubService.currentLocation },
      resources,
      search: {
        sessionId: this.displayedContext.sessionId,
        clientSearchId: this.displayedContext.clientSearchId,
      },
    });
  }

  onStateChanged(newSession: boolean, refresh?: boolean): Promise<void> {
    const diff = this.diffState();
    let filtersTrigger: 'tag' | 'box' | 'none' = 'none';

    if (this.lastState) {
      if (!diff.length && !refresh) return;

      const replaceSpaces = (str: string) => str.replace(/  +/g, ' ');
      const oldQuery = diff.length === 1 && diff[0] === 'q' && this.lastState.q && this.lastState.q[0];
      if (oldQuery && replaceSpaces(oldQuery) === replaceSpaces(this.hubService.query || '')) return;
    }

    let trigger = 'reload';

    const diffLength = diff.length;

    const stateTrigger = this.hubService.getState('search-trigger')[0];
    if (diffLength === 1 && diff[0] === 'search-trigger' && !stateTrigger) {
      // only diff is removeing the search-trigger
      return;
    }
    const nonUserQueryTriggers = ['if-', 'of-'];
    if (diffLength > 0) {
      if (diff.includes('q') && nonUserQueryTriggers.every((s) => diff.every((d) => !d.startsWith(s)))) {
        if (this.hubService.getState('search-trigger')[0] === 'omnibox') {
          this.hubService.removeState('search-trigger', 'omnibox');
          trigger = 'omnibox';
        } else {
          trigger = 'user_query';
        }
      } else {
        const hasSearchTrigger = diff.some((d) => d.startsWith('search-trigger'));
        if (diff.includes('sort')) {
          trigger = 'sort';
        } else if (diff.some((d) => d.startsWith('if-')) && !hasSearchTrigger) {
          trigger = 'user_scope';
          filtersTrigger = 'box';
        } else if (diff.some((d) => d.startsWith('of-'))) {
          trigger = 'user_filter';
          filtersTrigger = 'box';
        } else if (hasSearchTrigger) {
          trigger = this.hubService.getState('search-trigger')[0];
        }
        if (diff.some((d) => d.startsWith('if-t-') || d.startsWith('of-t-'))) {
          filtersTrigger = 'tag';
        }
      }
    }

    this.lastState = JSON.parse(JSON.stringify(this.state));
    const postFilters = Object.entries(this.filtersService.postFilters || {});
    if (this.onSearchView && !this.lastState.q?.length && postFilters.length && !this.resultsService.hasPreFilters()) {
      for (const [key, values] of postFilters) {
        for (const value of values) {
          this.filtersService.removeFilter(key, value, false, 'post');
        }
      }
      return;
    }

    this.startSearch(trigger, newSession, filtersTrigger);
  }

  private getSearchMethod(): SearchMethod {
    if (this.isLauncher || !this.inSearchNode) {
      return 'Quick-Search';
    }
    return 'Search-On-Enter';
  }

  private shouldFocusSearchBar(trigger: string, filterTrigger: string) {
    if (!this.searchOnEnterMode) {
      return true;
    }
    const filtersOpenWithKeyboard = this.filtersOpenWithKeyboard && filterTrigger === 'box';
    if (filtersOpenWithKeyboard) {
      this.filtersOpenWithKeyboard = false;
      return true;
    }
    const forceFocus = ['navigation_tree', 'filter_suggestion', 'reload'].includes(trigger) && this.query;
    if (
      (['keyboard', 'user_query', 'sort'].includes(trigger) && !isEmpty(this.state)) ||
      forceFocus ||
      typeof this.filtersContainer?.indexOpen === 'number'
    ) {
      return false;
    }
    return true;
  }

  async updateDisplayedBarItems() {
    let views: NavTree.Node[] = [];
    if (
      (this.isLauncher || this.isEmbed || this.hubService.currentLocation === 'search') &&
      (this.resultsService.isDefaultSearch() || this.resultsType === 'default')
    ) {
      views = await firstValueFrom(this.navTreeService.roots$.pipe(filter((nodes) => nodes?.length > 0))).then((nodes) => {
        return nodes.filter((n) => n.group === 'views' && n.type === 'standard');
      });
    }
    this.displayBarText = !!views.length;
    const currentNode = this.navTreeService.currentNode;
    const path = currentNode ? this.navTreeService.getNodeUrl(currentNode) : '/search';
    if ((this.view === 'view' && this.pageType !== 'search') || this.collectionId?.length > 0) {
      this.barModel = { items: [], telemetryName: this.BROWSE_BAR_TELEMETRY, redirectPath: path };
      return;
    }
    this.barModel = { items: [], telemetryName: this.BROWSE_BAR_TELEMETRY, redirectPath: path, isAppsItemsModel: false };
    const inf = this.filtersService.getPreFilters();
    if (
      Object.entries(inf).some(
        (e) =>
          e[0] != 'app' &&
          e[0] != 'service' &&
          e[0] != 'account' &&
          ((e[0] === 'type' && e[1].length > 1) || (e[0] !== 'type' && e[1].length))
      )
    )
      return;
    if (!Object.values(inf).some((i) => i.length)) {
      this.barModel.isAppsItemsModel = true;
      if (this.barItems) {
        this.barModel.items = this.resultsService.getFilteredBarApps(this.barItems);
      }
    }
    if (this.query || !this.barItems) return;
    if (inf.app?.length && !inf.account?.length) {
      const accountsItems = this.barItems.filter((b) => b.type == 'account' && inf.app?.includes(b.app));
      this.barModel.items = uniqBy(accountsItems, (a) => a.name);
      if (this.barModel.items.length <= 1) this.barModel.items = [];
    }
    if (inf.app?.length && !inf.service?.length && !inf.type?.length && this.barModel.items.length <= 1) {
      this.barModel.items = this.barItems.filter((b) => b.type == 'service' && inf.app?.includes(b.app));
    }
    if (!inf.type?.length && (inf.service?.length || (inf.app?.length && !(this.barModel.items.length > 1)))) {
      const currentBarItems = this.barModel.items || [];
      const types = [];
      for (const barItem of this.barItems) {
        if (barItem.type !== 'type' || (currentBarItems.length && barItem.service)) {
          continue;
        }
        if (inf.app?.length && !inf.app.includes(barItem.app)) {
          continue;
        }
        if (inf.service?.length && !inf.service.includes(barItem.service)) {
          continue;
        }
        if (inf.account?.length && this.allLinks?.every((l) => !inf.account.includes(l.name) || barItem.appId != l.appId)) {
          continue;
        }
        types.push(barItem);
      }
      this.barModel.items = [...currentBarItems, ...types.filter((b, i) => types.findIndex((t) => t.name === b.name) === i)];
    }
  }

  private isEmptyAnswers(answersItems: AnswerSearchItem[]) {
    return answersItems?.some((item) => item.state === 'NoResults');
  }

  private isOnlyAnswers(answersItems: AnswerSearchItem[]) {
    return this.displayedContext?.items?.length === answersItems?.length;
  }

  private async loadPeopleData(selectedIdx?: number, firstLoad?: boolean): Promise<void> {
    if (!this.isActivePreview) return;
    const allPeople = this.peopleService.all;
    const start = selectedIdx || 0;

    const items: string[] = this.displayedContext?.items
      .filter((i) => i?.action?.type === 'people')
      .slice(start)
      ?.reduce((acc, curr, _i, arr) => {
        if (acc.length === 5) {
          arr.splice(1);
          return acc;
        }
        const resultItem = curr as Search.ResultResourceItem;
        if (!resultItem || !resultItem?.resource?.externalId) return acc;
        if (firstLoad || !allPeople.find((p) => p.externalId === resultItem.resource?.externalId)) {
          acc.push(resultItem.resource?.externalId);
        }
        return acc;
      }, []);

    if (items && items.length) {
      this.peopleService.getPeople(items);
    }
  }

  private handleResultsLinksState(): void {
    const { linksStateToasterData, onDestroy } = this.resultsService.handleResultsLinksState(
      this.displayedContext.items,
      this.syncsService.linksSyncStatuses
    );
    this.staleToasterOnDestroy = onDestroy;
    if (linksStateToasterData) {
      this.showLinksStateToaster$.next(true);
    }
  }

  private initGoLinksRoute() {
    this.routerService.queryParams$.pipe(filter((p) => p?.golinks === 'open')).subscribe((params) => {
      this.onCreateGoLinkItemClick(null, params.name);
    });
  }

  private showToaster(data: ToasterData) {
    if (!data) {
      this.logger.warn('result component showToaster no data');
      return;
    }
    if (this.toasterRef) {
      this.toasterRef.destroy();
    }

    this.toasterRef = this.showToasterService.showToaster(data);

    if (this.toasterTimeOut) {
      clearTimeout(this.toasterTimeOut);
    }

    this.toasterRef.destroy$.pipe(untilDestroyed(this), take(1)).subscribe(() => {
      this.toasterRef = null;
      if (this.staleToasterOnDestroy) {
        this.staleToasterOnDestroy();
        this.staleToasterOnDestroy = null;
      }
      if (data.id === 'create-go-link') {
        this.showToasterInstallExtension();
      }
    });

    this.toasterRef.compInstance.invoke.subscribe(({ id, label }) => this.onToasterInvoke(id, label, data));
    this.toasterRef.compInstance.dismiss.subscribe((label) => this.onToasterDismiss(label));
  }

  private async onToasterInvoke(id: string, label: string, data: ToasterData): Promise<void> {
    if (!id) return;
    if (!data?.clickable) return;
    this.toasterRef?.destroy();
    if (id === 'install-extension') {
      window.open(Constants.DOWNLOAD_EXTENSION, '_blank');
      this.resultsService.displayInstallExtensionMessage = true;
      return;
    }
    let url: string;
    if (id.startsWith('stale-apps')) {
      url = 'apps';
    } else if (id.startsWith('stale-link?')) {
      const linkId: string = id.split('?')[1];
      if (!linkId) {
        return;
      }

      const link: Links.DisplayItem = await this.linksService.one(linkId);
      if (!link) {
        return;
      }
      url = `connect/${link.appId}/new?linkId=${link.id}&key=${link.key}`;
    } else if (id.startsWith('stale-links?')) {
      const appId: string = id.split('?')[1];
      if (!appId) return;
      url = `connect/${appId}`;
    }

    this.openAppFromLauncher(url);

    if (id.startsWith('stale')) this.sendToasterInteractionEvent(label, 'reconnnect');
  }

  private onToasterDismiss(label: string): void {
    this.sendToasterInteractionEvent(label, 'dismiss');
    if (label.startsWith('stale')) {
      this.linksStateSessionId = this.displayedContext.sessionId;
    }
    if (label === 'install-extension') {
      this.resultsService.displayInstallExtensionMessage = true;
    }
    this.toasterRef?.destroy();
  }

  private sendToasterInteractionEvent(label: string, target: string): void {
    this.analyticsService.event({
      category: 'interaction.click',
      name: 'alert',
      label,
      target,
    });
  }

  private getItemHeight(item: ResultDisplayModel, index: number, ignoreTagGroups = false) {
    if (!item) return 0;

    if (isHeader(item)) {
      return 32 + (item.isFooter ? 4 : 0);
    }

    const margin = index > 0 && isHeader(this.displayedContext.items[index - 1]) ? 2 : 4; // first line after header get margin of 2

    if (isLocalAction(item) || isGoTo(item) || isSuggestion(item)) {
      return 40 + margin;
    }

    if (isRecentSearch(item)) {
      return 36 + margin;
    }

    if (item.type === 'query') {
      return 40 + margin;
    }

    if (isCalculation(item)) {
      return 115 + margin;
    }

    if (isWebSearch(item)) {
      return 45;
    }

    if (item.type === 'static-search-item') {
      return 40 + margin;
    }

    if (isRelevantPeopleItem(item) && this.searchGroup?.name === 'relevant-people') {
      let height = 94;
      item.expert?.contributions?.forEach((c, index) => {
        if (index < 3) {
          height += this.calculateResultsHeight(c, margin, ignoreTagGroups);
        }
      });
      if (item.expert?.contributions?.length > 3) {
        height += 32;
      }
      return height + margin;
    }

    if (isGalleryGroup(item)) {
      return this.calculateHeightGalleryGroup(item, margin);
    }

    if (isCollection(item)) {
      if (item.tags?.length) {
        return 82 + margin;
      }
      return 62 + margin;
    }

    const view = item['view'];

    if (view?.subView) {
      return 30 + this.HEIGHT_SNIPPET;
    }

    if (view?.annotation) {
      return 40 + this.HEIGHT_SNIPPET;
    }

    if (this.pageType === 'mail') {
      if ((item as Search.ResultResourceItem).resource?.traits?.hasAttachments) {
        return 60 + margin;
      }
      return 36 + margin;
    }

    if (this.pageType === 'people') {
      return 60 + margin;
    }

    if (item.type === 'go-link') {
      const { subtitle, tagGroups } = (item as Search.GoLinkResultItem).view;
      if (subtitle.text && tagGroups[0].tags.length) return 115;
      if (tagGroups[0].tags.length) return 95;
      if (subtitle.text) return 92;
      return 72;
    }

    if ((<Search.ResultResourceItem>item).resource?.appId === 'pc') {
      if ((item as Search.ResultResourceItem).view?.bullets?.length) {
        return 46 + margin; // 2 lines
      } else {
        return 28 + margin;
      }
    }

    if ((item as Search.ResultItem).view && this.pageType === 'search') {
      return this.calculateResultsHeight(item, margin, ignoreTagGroups);
    }

    return 64 + margin; // Two liner
  }

  calculateResultsHeight(item: any, margin: number, ignoreTagGroups: boolean): number {
    const { bullets, tagGroups } = (item as Search.ResultItem).view;
    if (item?.showResultSections?.showSingleLineView) return 44 + margin;
    if (bullets?.length && tagGroups?.length) return 84 + margin; // Three liner
    if (!bullets?.length && tagGroups?.length && !ignoreTagGroups) return 64 + margin; // Three liner
    return 64 + margin;
  }

  calculateHeightGalleryGroup(item: GalleryGroup, margin: number): number {
    const resultsWidth = this.resultElements?.toArray()[0]?.nativeElement.offsetWidth || 0;
    const itemsLength = item.items?.length || 0;
    const maxItemsInLines = Math.floor(resultsWidth / this.MIN_WIDTH_ITEM_GALLERY);
    let numberLines: number;

    if (!item.items || maxItemsInLines === 0) {
      numberLines = 1;
    } else {
      numberLines = Math.ceil(itemsLength / maxItemsInLines);
    }

    const isPreviewMode = this.previewViewModel.previewState === 'side';
    const lines = isPreviewMode ? itemsLength : numberLines;
    const basicHeight = this.ITEM_HEIGHT[item.resultsType];
    const height = lines * (isPreviewMode ? basicHeight.list : basicHeight.gallery);

    return height + margin;
  }

  updateHeightSnippetItem(height: number, index: number) {
    if (!height) return;
    if (height >= this.HEIGHT_SNIPPET && index === this.selectedIndex) {
      this.infiniteScrollService.scrollIfNeeded(this.resultElements?.toArray()[this.selectedIndex]);
    }
  }

  updateHeightItem(index: number) {
    this.infiniteScrollService.scrollIfNeeded(this.resultElements?.toArray()[index]);
  }

  private initSuggestions() {
    this.suggestionService.accepted$.pipe(takeUntil(this.destroy$)).subscribe(async (s) => {
      this.hubService.query = this.hubService.inputQuery;
      if (['resource-filter', 'query-filter'].includes(s.type)) {
        if (s.type === 'resource-filter') {
          let addTag = false;
          if (this.viewSettings?.supportSingleBaseTag) {
            addTag = !this.filtersService.hasFilters || this.filtersService.hasSingleFilterTag(s.label);
          }
          this.addTagFilter(s, addTag);
          this.filtersOpenWithKeyboard = true;
        } else {
          const f = <FilterSuggestionData>s.data;
          this.hubService.query = f.value;
        }
      } else if (s.id.startsWith('collection')) {
        this.filtersService.removeAllFilters();
        this.hubService.removeActivePage();
        const collectionId = s.id.substring('collection-'.length, s.id.length);
        let collection = this.collectionsService.currentCollection;
        if (collection?.id !== collectionId) {
          collection = await this.collectionsService.getById(collectionId);
        }
        this.collectionsService.addCollectionTag(collectionId);
        this.searchParamsService.collection = collectionId;
        await this.collectionsService.updateCollectionSearchParams(collection);
      }
    });
  }

  private initBarItems() {
    this.resultsService.initBarItems();
    this.resultsService.barItems$.pipe(takeUntil(this.destroy$)).subscribe(async () => {
      if (this.displayedContext && this.resultsService.isDefaultSearch) {
        await this.updateDisplayedBarItems();
      }
      this.cdr.markForCheck();
    });
  }

  private initTags() {
    this.tagsService.removed$.pipe(takeUntil(this.destroy$)).subscribe((t) => {
      this.hubService.query = this.hubService.inputQuery;
      if (t.type == 'actions') {
        this.hubService.removeState('actionsOnly', 'true');
      }
      if (t.type == 'filter') {
        if (this.viewSettings?.supportSingleBaseTag && this.tagsService.all.length === 1) {
          this.filtersService.removeAllFilters();
        } else {
          const f = <FilterTagData>t.data;
          this.filtersService.removeFilter(f.name, f.value, true);
        }
      }
      if (t.type == 'group') {
        this.searchParamsService.removeGroup();
      }
      if (t.id.startsWith('collection-')) {
        this.collectionsService.onRemoveCollectionTag();
      }
    });
  }

  listName(item: Search.Item): ImpressionResourceList {
    if (isGalleryGroup(item)) {
      return item['resultsType'];
    }
    return listNameByType(item);
  }

  addShortcut(id: string, keys: string[]) {
    this.shortcuts[id] = keys;
  }

  getRealPosition(index: number) {
    return this.resultCommandService.getRealPosition(this.displayedContext?.items, index, this.displayedContext?.lastHeaderIndex);
  }

  private getResultItem(index: number) {
    return this.resultsItems.toArray().find((i) => i.index === index);
  }

  clearRecentSearches() {
    this.eventsService.event('search_page.recent_searches_clear');
    this.recentSearches.clearAll();
  }

  emitSelected(trigger: TelemetryTrigger) {
    if (!this.selected || (!isResourceResult(this.selected) && !isPerson(this.selected))) return;
    this.selected$.next([{ item: <Search.ResultResourceItem>this.selected, trigger, isFirst: this.isFirstItem, isLast: this.isLastItem }]);
    this.cdr.markForCheck();
  }

  openContextMenu(index: number, item: SearchResults) {
    this.updateSelectedIndex(index);
    this.hoveredIndex = index;
    this.hoveredItem = item;
    this.onOpenContextMenu = true;
    if (this.isPreviewClickAction(this.selected?.action)) {
      this.selected$.next([
        {
          item: <Search.ResultResourceItem>this.selected,
          trigger: 'context_menu_click',
          isFirst: this.isFirstItem,
          isLast: this.isLastItem,
        },
      ]);
    }
  }

  closeContextMenu() {
    this.hoveredIndex = null;
    this.hoveredItem = null;
    this.onOpenContextMenu = false;
  }

  openAssistant(assistant: Experiences.ExperienceItem) {
    return this.assistantService.openAssistant(assistant.id);
  }

  openAppFromLauncher(path = '/') {
    this.hubService.openUrl(path);
  }

  onGoToItemClick(item: GoToItem) {
    this.commandsService.executeCommand(item.command);
  }

  onWebSearchItemClick(item: WebSearch.ResultItem): void {
    this.commandsService.executeCommand({ type: 'open-url', url: item.url } as Commands.OpenUrl);
  }

  onFileItemClick() {
    const resultItem = (this.hoveredItem || this.selected) as Search.ResultItem;
    this.commandsService.executeCommand(resultItem.view?.title?.onClick as Commands.OpenUrl);
  }

  onHoverItem(index: number) {
    this.hoveredIndex = index;
    this.searchForFocusGroup(index);
  }

  searchForFocusGroup(index: number) {
    let found = false;
    for (let i = index; i >= 0; i--) {
      if (isHeader(this.displayedContext.items[i]) && (this.displayedContext.items[i] as HeaderItem)?.group) {
        this.focusedGroup = i;
        found = true;
        break;
      }
    }
    if (!found) this.focusedGroup = null;
  }

  @HostListener('mousemove')
  onMouseMove() {
    this.keyboardOn = false;
  }

  focusGroupClick() {
    const headerGroup = (this.displayedContext.items[this.focusedGroup] as HeaderItem)?.group;
    if (headerGroup?.name === 'web-search') {
      const url = `/web-search/${headerGroup.value}?q=${this.query}`;
      this.routerService.navigateByUrl(url);
      const i = WEB_SEARCH_ITEMS.find((p) => p.id.split('web-search_')[1] === headerGroup.value);
      if (i) {
        this.tagsService.all = [this.hubService.suggestionsToTag(i)];
      }
      return;
    }
    if (headerGroup) {
      this.searchParamsService.addGroup(headerGroup);
    }
  }

  isFirstItemInGroup(index: number): boolean {
    return index > 0 && isHeader(this.displayedContext.items[index - 1]);
  }

  onCreateGoLinkItemClick(url?: string, name?: string) {
    if (name) {
      name = name.replace(/(go\/|Go)/, '');
    }
    this.eventsService.event('interaction_click', {
      name: 'create_go_links',
      location: {
        title: 'golinks',
      },
    });
    this.goLinksService.openPopup(undefined, url, name);
  }

  onAddToCollectionItemClick(url?: string, trigger?: 'keyboard' | 'mouse_click') {
    const eventInfo: Partial<EventInfo> = {
      location: { title: this.hubService.currentLocation },
      target: trigger,
      search: { clientSearchId: this.displayedContext?.clientSearchId, sessionId: this.displayedContext.sessionId },
    };
    const command: Commands.DynamicCommand = { type: 'add-to-collection', value: url };
    this.resultCommandService.executeCommand(command, { searchId: this.searchId }, undefined, eventInfo);
  }

  openCollection(collection: Collections.Collection, viewType: CollectionViewType) {
    this.collectionsService.openCollection(collection, viewType);
  }

  private async showToasterInstallExtension() {
    if (!this.resultsService.displayInstallExtensionMessage && !this.toasterRef) {
      const data: ToasterData = {
        id: 'install-extension',
        content: 'Browser extension is required for using Go Links',
        icon: { type: 'font', value: 'icon-puzzle' },
        buttonText: 'Install',
        intent: 'primary',
        iconIntent: 'none',
        clickable: true,
      };
      this.showToaster$.next(data);
    }
  }

  private showCreateGoLinkToaster(name: string) {
    const data: ToasterData = {
      id: 'create-go-link',
      title: `go/${name} `,
      content: 'has been successfully created',
      boldTitle: true,
      icon: { type: 'img', value: { lightUrl: '/assets/go-links/check-create-go-link.svg' } },
    };
    this.showToaster$.next(data);
  }

  showCreateGoLinkButton(): boolean {
    return (
      this.view === 'view' &&
      this.pageType === 'golinks' &&
      this.currentNode?.id === 'golinks' &&
      !this.isLauncher &&
      !Object.keys(this.filtersService.tagFilters || {}).length
    );
  }

  async onSuggestionItemClicked(item: SuggestionsItem) {
    this.suggestionsSearchService.onSuggestionSelected(item);
  }

  async openCollectionExternal() {
    this.collectionsService.openExternal(this.collectionId);
  }

  //preview
  isPreviewClickAction(action: Action) {
    return isPreviewClickAction(action);
  }

  toggleResultsPreview(trigger: TelemetryTrigger) {
    document.activeElement instanceof HTMLElement && document.activeElement.blur();
    const previewState = this.isPreviewMode ? 'none' : 'side';
    this.previewService.setPreviewState(previewState, this.getRealPosition(this._selectedIndex), this.selected);
    this.previewService.setPreviewStateCache(previewState);
    if (trigger === 'keyboard') {
      this.previewKeyboardService.setComponentFocused(PreviewFocus.PreviewToggle);
    }

    const ev: Partial<EventInfo> = {
      location: { title: this.hubService.currentLocation },
      target: trigger ? trigger : 'mouse_click',
    };

    this.eventsService.event(`preview.column_${this.previewViewModel.previewState.includes('side') ? 'open' : 'close'}`, ev);
    this.emitSelected(trigger);
    this.hubService.changeFocusStateMultiCalls(true, true);
  }

  previewDragEnd(event) {
    const size = Number(event.sizes[1]);
    this.previewService.setPreviewWidth(size);
  }

  updatePreviewState(state: PreviewMode, hoveredItem?: SearchResults, trigger?: TelemetryTrigger) {
    if (state === 'none' || this.isActivePreview) {
      this.previewService.setPreviewState(state, this.getRealPosition(this._selectedIndex), hoveredItem || this.selected);
      this.emitSelected(trigger);
    }
  }

  onPreviewItemClick(item: Search.ResultResourceItem, index: number) {
    if (!this.isPreviewClickAction((item as DisplayItemData)?.action)) return;
    this.updateSelectedIndex(index);
    this.selected$.next([
      { item: <Search.ResultResourceItem>this.selected, trigger: 'mouse_click', isFirst: this.isFirstItem, isLast: this.isLastItem },
    ]);
    this.previewService.openFullPreview(item);
  }

  checkPreviewVisibility(event) {
    if (this.isActivePreview && this.isPreviewMode && this.isPreviewClickAction(this.selected?.action)) {
      const currentSelected = this.selected$.value;
      if (currentSelected && currentSelected.length && currentSelected[0].item === this.selected) return;
      this.selected$.next([
        { item: <Search.ResultResourceItem>this.selected, trigger: 'mouse_click', isFirst: this.isFirstItem, isLast: this.isLastItem },
      ]);
      this.previewService.openFullPreview(this.selected, this.getRealPosition(this._selectedIndex));
    }
  }

  calcPreviewState() {
    if (!this.isActivePreview || this.currentBreakpoint === getWidthBreakpointScreen()) return;
    this.displayTogglePreview = this.shouldDisplayTogglePreview();
    this.cdr.markForCheck();
    if (!this.isPreviewMode) {
      this.previewService.setPreviewWidth(this.previewService.minPreviewWidth);
      return;
    }
    if (['small', 'extra-small'].includes(getWidthBreakpointScreen())) {
      this.previewService.setPreviewState('none');
    }
    this.currentBreakpoint = getWidthBreakpointScreen();
    this.setSplitArea();
    this.previewService.setPreviewWidth(this.previewService.minPreviewWidth);
  }

  setSplitArea(size?: PreviewMode) {
    switch (size ?? this.previewViewModel.previewState) {
      case 'full':
        this.splitAreaPreviewModel.visiblePreview = true;
        this.splitAreaPreviewModel.visibleResults = false;
        break;
      case 'popup':
      case 'none':
        this.splitAreaPreviewModel.visiblePreview = false;
        this.splitAreaPreviewModel.visibleResults = true;
        break;
      case 'side':
        this.splitAreaPreviewModel.visiblePreview = true;
        this.splitAreaPreviewModel.visibleResults = true;
    }
    this.splitAreaPreviewModel.minPreviewWidth = this.previewService.minPreviewWidth;
    this.splitAreaPreviewModel.maxPreviewWidth = this.previewService.maxPreviewWidth;
  }

  getTogglePreviewTooltip(): string {
    let state = 'Hide';
    if (this.previewViewModel.previewState === 'none') {
      state = 'Show';
    }
    return `${state} Side Preview`;
  }

  onQuickSearch(event: string) {
    this.hubService.inputQuery = event;
  }

  onDynamicCommand(value: string, item: any, event: Partial<EventInfo>) {
    switch (value) {
      case 'remove':
        this.deleteCard('card', item);
        break;
      case 'delete-draft':
        this.deleteCard('draft', item);
        break;
      case 'rename':
        item.showResultSections.showInputTitle = true;
        this.cdr.markForCheck();
        break;
      case 'make-a-copy':
        this.wikiItemSelectionPopupService.duplicateCard(item);
    }
    if (this.verificationsCardsCommandsService.isVerificationCommand(value) && isWikiCard(item)) {
      this.eventsService.event('results.action', {
        ...event,
        label: value,
      });
      this.verificationsCardsCommandsService.executeCommand(value, item);
    }
  }

  private deleteCard(type: WikiCardType, item: any) {
    const deletePopupRef: PopupRef<AppPopupComponent, AppPopupData> = this.collectionsPopupService.openAreYouSureToRemoveItemPopup(
      [item],
      1,
      type
    );
    deletePopupRef.compInstance.primaryButton.pipe(untilDestroyed(this), take(1)).subscribe(async () => {
      if (type === 'draft') {
        await this.wikiDraftsService.delete(item.id);
      } else {
        await this.wikisService.deleteWikiItem({ itemId: item.id });
      }
    });
  }

  async titleChanged(newTitle, item) {
    if (!newTitle) {
      return;
    }
    this.resultsItems.get(item['resultIndex'])['showInput'] = 0;
    await this.wikiCardsService.update({
      card: { id: item.id, title: newTitle, collectionId: item.collectionId },
      updateModifiedTime: true,
    });
  }

  async onOpenFullAppClicked(isDefault = false) {
    this.eventsService.event('launcher.avatar', {
      name: 'expand_app',
      location: { title: 'launcher' },
    });
    this.filtersService.convertTagFiltersToRegular();
    setTimeout(async () => {
      if (this.isEmbed) {
        if (isDefault) {
          await this.embedService.openUrl(this.routerService.currentFullUrl);
        } else {
          this.hubService.openStandardEmbed();
        }
        return;
      }
      this.windowService.switchToStandard();
    }, 0);
  }

  openCard(trigger: string) {
    this.eventsService.event('collections.new_card', {
      location: { title: this.hubService.currentLocation },
      label: trigger,
    });
    this.collectionsUtilService.openWikiCard();
  }

  collectionNavigate(value: string) {
    this.collectionsService.openCollectionView({ kind: value as Collections.Kind }, 'new', false).then((url) => {
      this.hubService.openUrl(url, { state: { mode: 'new' } });
    });
  }

  isCard(item: Search.ResultItem) {
    return isWikiCard(item);
  }

  private updateEmptyState() {
    this.isEmptyState = false;
    const cardsEmptyState = this.pageType === 'cards' && this.noCards;
    const goLinksEmptyState = this.pageType === 'golinks' && this.noGoLinks;
    const collectionsEmptyState = this.pageType === 'collections' && this.noCollections;
    const assistantsEmptyState = this.pageType === 'assistants' && this.noAssistants;
    const wikisEmptyState = this.pageType === 'wikis' && this.noWikis;
    const draftsEmptyState = this.pageType === 'drafts' && this.noDrafts;

    if (
      cardsEmptyState ||
      goLinksEmptyState ||
      collectionsEmptyState ||
      assistantsEmptyState ||
      wikisEmptyState ||
      draftsEmptyState ||
      this.connectApps ||
      this.emptyStateModel
    ) {
      this.isEmptyState = true;
    }
    this.updatePlaceholder(null, null);
  }

  openBranding() {
    window.open('https://www.unleash.so/home', '_blank');
  }

  isPreviewDirty() {
    return (
      this.isActivePreview && this.isPreviewMode && this.isPreviewClickAction(this.selected?.action) && this.previewContainer?.isDirty()
    );
  }

  updateSelectedIndex(val: number) {
    this.selectedIndex = val;
    this.cdr.markForCheck();
  }

  updateInnerIndex(val: number, index: number) {
    if (isNumber(val)) {
      this.updateSelectedIndex(index);
    }
    this.innerSelectedIndex = val;
    this.emitSelected('keyboard');
    this.cdr.markForCheck();
  }

  galleryGroupFocusFinished(direction: ScrollDirection, event: CustomKeyboardEvent) {
    switch (direction) {
      case 'up':
        this.onKeyArrowUp(event);
        break;
      case 'down':
        this.onKeyArrowDown(event);
        break;
    }
  }

  onChangeSort(selected: SortOption) {
    this.sortSelected = selected;
    this.searchParamsService.addSort(selected.value);
    this.sortService.sendSortEvent(selected.value);
  }

  openSort(elm: UButtonComponent) {
    const popupRefSort = this.sortService.openSortPopup(elm, { sortOptions: this.sortOptions });

    popupRefSort.close$.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  get sortTooltipText() {
    const currentSort = this.searchParamsService.getSort();
    const currentSortLabel = this.sortOptions?.find((option) => isEqual(option.value, currentSort))?.label ?? 'Most relevant';
    return `Sort: ${currentSortLabel}`;
  }

  onCloseFilters() {
    this.hubService.changeFocusState(true);
  }

  onCollectionTagClick(tag: CollectionTag) {
    if (this.isLauncher) {
      this.filtersService.setFilters('collection-tags', [tag.value], 'pre', false);
    }
  }

  onInvokeDirty($event: DirtyChangeStatus) {
    if ($event !== 'open') {
      this.selected$.next([]);
      this.previewContainer.clear();
      this.cdr.markForCheck();
    }
  }
}

type SearchTiming = {
  triggeredTime?: number;
  beforePreSearch?: number;
  afterPreSearch?: number;
  searchReached?: number;
  ghostStarted?: number;
  ghostEnded?: number;
  firstItemRenderred?: number;
  itemRenderCompleted?: number;
  liveDuration?: number;
};
export class ResultsSearchTracker {
  private sentOnCompleted = false;
  private readonly MIN_STAGE = 5;
  private readonly MAX_STAGE = 20;
  constructor(
    private appService: AppService,
    private analyticsService: TelemetryService,
    private hubService: HubService
  ) {
    this.searchState = {
      iterations: 0,
      completed: false,
      clientDetails: {
        browser: this.appService.browser,
        platform: this.appService.platform,
        version: Config.version,
      },
      stage: this.MIN_STAGE,
      abandoned: false,
      timing: {},
      clients: {},
    };
  }

  searchState: {
    id?: number;
    iterations: number;
    stage: number;
    trigger?: string;
    url?: string;
    timing: SearchTiming;
    completed: boolean;
    appStart?: number;
    readyTime?: number;
    query?: string;
    filters?: string;
    clientDetails: {
      browser: BrowserName;
      version: string;
      platform: AppName;
      mode?: 'Launcher' | 'Search-Page';
      extensionInstalled?: boolean;
      accountId?: string;
      workspaceId?: string;
    };
    abandoned: boolean;
    clientList?: string;
    clients: any;
  };

  updateAccount(s: SessionInfo) {
    this.searchState.clientDetails.accountId = s?.workspace?.accountId;
    this.searchState.clientDetails.workspaceId = s?.workspace?.id;
  }

  updateSearchParams(query: string, filters: Filters.Values) {
    this.searchState.query = query;
    this.searchState.filters = filters ? JSON.stringify(filters) : null;
  }

  start(time: number, trigger: string) {
    if (this.searchState && (!this.searchState.completed || !this.sentOnCompleted) && this.searchState.stage !== this.MIN_STAGE) {
      // sending uncompleted state before moving to the next search
      this.searchState.abandoned = true;
      this.searchState.appStart = Date.now() - this.appService.startTime;
      this.searchState.readyTime = this.appService.readyTime;
      this.analyticsService.insight('long-search', this.searchState);
    }
    this.searchState.clients = {};
    this.searchState.query = null;
    this.searchState.trigger = trigger;
    this.searchState.filters = null;
    this.searchState.timing = { triggeredTime: time };
    this.searchState.completed = false;
    this.searchState.clientList = null;
    this.searchState.abandoned = false;
    this.searchState.stage = this.MIN_STAGE;
    this.sentOnCompleted = false;
  }

  updateTime(name: keyof SearchTiming) {
    this.searchState.timing[name] = Date.now() - this.searchState.timing.triggeredTime;
  }

  track(ctx: ResultDisplayContext, isLauncher: boolean, extensionInstalled: boolean, showGhost: boolean) {
    if (!ctx) {
      return;
    }
    this.searchState.clientDetails.mode = isLauncher ? 'Launcher' : 'Search-Page';
    this.searchState.clientDetails.extensionInstalled = extensionInstalled;
    const now = Date.now();
    const startTime = this.searchState.timing.triggeredTime;
    const completed = ctx.searchCompleted;
    if (showGhost && !this.searchState.timing.ghostStarted) {
      this.searchState.timing.ghostStarted = Date.now() - startTime;
      delete this.searchState.timing.ghostEnded;
    } else if (!showGhost && !this.searchState.timing.ghostEnded && (this.searchState.timing.ghostStarted || completed)) {
      this.searchState.timing.ghostEnded = Date.now() - startTime;
    }
    if (!this.searchState.timing.firstItemRenderred && ctx?.items?.length) {
      this.searchState.timing.firstItemRenderred = Date.now() - startTime;
    }
    const list = [];
    for (const source of ctx.sources || []) {
      const id = source.source.id || source.source.type;
      list.push(id);
      const client = this.searchState.clients[id] || {};
      if (client?.done) {
        continue;
      }
      const sourceType = source.source.type;
      if (sourceType === 'link-resources') {
        const req = source.extra?.search;
        const origins = req?.origins;
        const cloud = origins?.Cloud;
        const local = origins?.Local;
        const performance = req?.performance;
        client.cloud = {
          ...(performance?.Cloud || {}),
          ...(performance?.general || {}),
          duration: source?.duration,
          source: cloud?.source,
          status: cloud?.status,
          searchId: cloud?.response?.id,
        };
        client.local = {
          ...(performance?.Local || {}),
          duration: source?.duration,
          nativeDuration: local?.duration,
          status: local?.status,
        };
      }
      if (['go-links', 'relevant-people'].includes(sourceType)) {
        const durations = source.extra?.durations;
        for (const [key, value] of Object.entries(durations || {})) {
          if (key === '$lastTimestamp$') {
            continue;
          }
          client[key] = value;
        }
      }
      client.done = source.done;
      client.source = source.source;
      if (source.error) {
        client.error = source.error;
      }
      if (client.done) {
        client.duration = source.duration;
        client.itemsLength = source.items?.length;
      }
      this.searchState.clients[id] = client;
    }
    this.searchState.clientList = list.join(',');
    this.searchState.id = ctx.id;
    this.searchState.iterations = ctx.iterations;
    this.searchState.completed = completed;
    this.searchState.url = location.href;
    const currentStage = this.searchState.stage;
    if (now - startTime > currentStage * 1000 && ((completed && !this.sentOnCompleted) || currentStage < this.MAX_STAGE)) {
      this.searchState.stage = !completed ? currentStage * 2 : this.MAX_STAGE;
      if (completed) {
        this.sentOnCompleted = true;
      }
      this.searchState.appStart = now - this.appService.startTime;
      this.searchState.readyTime = this.appService.readyTime;
      this.analyticsService.insight('long-search', this.searchState);
    }
  }
}
