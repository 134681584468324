import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UInputComponent } from './u-input.component';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { UIconModule } from '../u-icon/u-icon.module';

@NgModule({
  declarations: [UInputComponent],
  imports: [CommonModule, FormsModule, InputTextModule, TooltipModule, UIconModule],
  exports: [UInputComponent],
})
export class UInputModule {}
