import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectButtonOption } from '../../types/select-button';

@Component({
  selector: 'u-select-button',
  templateUrl: './u-select-button.component.html',
  styleUrls: ['./u-select-button.component.scss'],
})
export class USelectButtonComponent {
  @Input() options: SelectButtonOption[];
  @Input() tabindex = 0;
  @Input() multiple: boolean;
  @Input() disabled: boolean;
  @Input() styleClass: any;
  @Input() selectedValue: any;
  @Output() onOptionClick: EventEmitter<any> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  onOptionClickEvent($event) {
    this.onOptionClick.emit($event.value);
  }

  onChangeEvent($event) {
    this.onChange.emit($event.value);
  }
}
