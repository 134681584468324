<div class="u-input-number">
  <u-input
    [styles]="styles"
    [model]="inputModel"
    [disabled]="disabled"
    [placeholder]="''"
    (onChange)="onChangeEvent($event)"
    (onKeydown)="onKeydown($event)"
    (onBlur)="onBlur()"
    class="u-input-number-input"
  ></u-input>
  <div class="arrows-buttons" *ngIf="!disabled">
    <u-icon [model]="{ type: 'font', value: 'icon-chevron-up' }" (click)="incrementNumber()" class="arrow-icon"></u-icon>
    <u-icon [model]="{ type: 'font', value: 'icon-chevron-down' }" (click)="decrementNumber()" class="arrow-icon"></u-icon>
  </div>
</div>
