<div class="view-layout" #view [style.display]="stage === 'PickRelativeTime' ? 'none' : 'initial'">
  <div *ngIf="showTitleBar$ | async">
    <u-progress-bar
      [style.visibility]="(isLoading$ | async) ? 'inherit' : 'hidden'"
      class="loader"
      [mode]="(isLoading$ | async) ? 'indeterminate' : 'determinate'"
      [styles]="{ width: '100%', height: '4px' }"
    ></u-progress-bar>
  </div>
  <div class="settingsFrame">
    <template #frame></template>
  </div>
</div>

<ng-container *ngIf="stage === 'LinkType'">
  <choose-link-level (linkLevel)="onLinkLevelChosen($event)"></choose-link-level>
</ng-container>

<ng-container *ngIf="stage === 'ResourceAccessType'">
  <choose-resource-access-type (type)="onResourceAccessTypeChosen($event)" [appName]="app?.name"></choose-resource-access-type>
</ng-container>

<ng-container *ngIf="stage === 'WaitingForAuth'">
  <new-link-waiting-auth
    [isCreating]="isCreating$ | async"
    (closeSyncMessage)="closeSyncMessage()"
    (abort)="abort()"
  ></new-link-waiting-auth>
</ng-container>

<ng-container *ngIf="stage === 'SharingOptions'">
  <new-link-share
    *ngIf="!sharedLinkOption"
    [focusedButton]="focusedButton"
    [tempSharedLinkOption]="tempSharedLinkOption"
    [resourceAccessType]="resourceAccessType"
    (optionChecked)="optionChecked($event)"
    (cancelButton)="close()"
    (onActionEvent)="sentActionEvent('cancel')"
    (onShareDoneClick)="onShareDoneClick($event)"
  ></new-link-share>
</ng-container>

<ng-container *ngIf="stage === 'SaveLink'">
  <new-link-save [show]="(isCreating$ | async) && sharedLinkOption"></new-link-save>
</ng-container>

<ng-container *ngIf="stage === 'PickRelativeTime'">
  <new-link-pick-relative-time (onActionEvent)="onPickRelativeTimeAction($event)" [app]="app"></new-link-pick-relative-time>
</ng-container>

<ng-container *ngIf="stage === 'Done' && linkResult$ | async as linkResult">
  <new-link-done
    [linkResult]="linkResult"
    [suppressSyncMessage]="suppressSyncMessage"
    (closeOverlay)="closeSyncMessage()"
    (toggleSuppressSync)="toggleSuppressSyncMessage()"
    (back)="close()"
    (connectAnother)="connectAnother()"
  ></new-link-done>
</ng-container>
