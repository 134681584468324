import { LinkResourcesSourceSettings } from '../link-resources';

export interface MailSourceSettings extends Omit<LinkResourcesSourceSettings, 'type'> {
  type: 'mail-resources';
  header?: {
    title?: string;
    titleEnd?: string;
    multipleAccountsTitle?: string;
  };
  isDefault?: boolean;
}
