import { Injectable } from '@angular/core';
import { BrowserTab, BrowserTabs, BrowserTabsRpcInvoker } from '@local/common';
import { isNativeWindow } from '@local/common-web';
import { from, Observable } from 'rxjs';
import { BrowserExtensionService } from './browser-extension.service';
import { NativeServicesRpcService } from './rpc.service';

@Injectable({
  providedIn: 'root',
})
export class BrowserTabsService implements BrowserTabs {
  private service: BrowserTabs;

  constructor(private nativeServices: NativeServicesRpcService, private browserExtService: BrowserExtensionService) {
    if (isNativeWindow()) this.service = nativeServices?.invokeWith(BrowserTabsRpcInvoker, 'browsertabs');
    else {
      this.browserExtService.current$.subscribe((bext) => {
        this.service = bext?.invokeWith(BrowserTabsRpcInvoker, 'browsertabs');
      });
    }
  }
  get all$(): Observable<BrowserTab[]> {
    return this.service?.all$ || from([[]]);
  }

  activate(id: string): Promise<void> {
    return this.service?.activate(id);
  }
}
