import { Injectable } from '@angular/core';
import { Omnibox } from '@local/client-contracts';
import { observable } from '@local/common';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SuggestionProvider } from './suggestion-provider';

@Injectable({
  providedIn: 'root',
})
export class SuggestionsService {
  private _accepted$ = new Subject<Omnibox.Suggestion>();
  private _provider$ = new BehaviorSubject<SuggestionProvider>(null);

  @observable
  get provider$(): Observable<SuggestionProvider> {
    return this._provider$;
  }
  
  set provider(v: SuggestionProvider) {
    this._provider$.next(v);
  }

  set accepted(suggestion: Omnibox.Suggestion) {
    this._accepted$.next(cloneDeep(suggestion));
  }

  get provider() {
    return this._provider$.value;
  }

  @observable
  get accepted$(): Observable<Omnibox.Suggestion> {
    return this._accepted$;
  }

  accept(suggestion: Omnibox.Suggestion) {
    this.accepted = suggestion;
  }
}
