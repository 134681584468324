import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'u-radio-button',
  templateUrl: './u-radio-button.component.html',
  styleUrls: ['./u-radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class URadioButtonComponent {
  @Input() options: SelectItem[];
  @Input() selectedOption: string;
  @Input() optionTemplate: any;

  @Output() onClick = new EventEmitter<SelectItem>();

  onRadioButtonClick(option: SelectItem) {
    this.onClick.emit(option);
  }
}
