import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenubarModule } from 'primeng/menubar';
import { UMenubarComponent } from './u-menubar.component';

@NgModule({
  declarations: [UMenubarComponent],
  imports: [CommonModule, MenubarModule],
  exports: [UMenubarComponent],
})
export class UMenubarModule {}
