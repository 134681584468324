import { Search } from '@local/client-contracts';
import { untilDestroyed } from '@ngneat/until-destroy';
import { EventInfo, EventsService } from '@shared/services';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { BreakpointsWidth, getWidthBreakpointScreen, isPreviewCommand, windowSizeObserver } from '@shared/utils';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, debounce, distinctUntilChanged, Subject, timer } from 'rxjs';
import { PreviewFocus } from 'src/app/bar/models/preview-focus';
import { ResultCommandService } from 'src/app/bar/services/commands/result-command.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { PreviewKeyboardService } from 'src/app/bar/services/preview-keyboard.service';
import { PreviewService, PreviewViewModel } from 'src/app/bar/services/preview.service';
import { ResultsService } from 'src/app/bar/services/results.service';
import { PreviewMode } from '../../../preview/model/preview-mode';
import { SplitAreaPreviewModel } from '../../../preview/model/split-area-preview.model';
import {
  DisplayItemData,
  getPrimaryActionClick,
  isPerson,
  isPreviewClickAction,
  isResourceResult,
  SearchResults,
  TelemetryTrigger,
} from '../../../results';
import { PreviewItem, ResultDisplayContext } from '../../../results-views/results/results.component';
import { InvokeCommand } from '../../../results/models/invoke-command.model';
import { isCollectionFileStaticItem } from '../../../results/utils/results.util';
import { BlobPreviewService } from '../../services/blobs-preview.service';
import { KeyName, getModifiers, isEnterKey, removeModifiers } from '@local/ts-infra';

export class PreviewHandlerService {
  readonly RESULTS_MIN_SIZE: number = 380;
  PreviewFocusEnum = PreviewFocus;

  selected$ = new BehaviorSubject<PreviewItem[]>(null);
  previewViewModel: PreviewViewModel = {};
  splitAreaPreviewModel: SplitAreaPreviewModel;
  private currentBreakpoint: BreakpointsWidth;
  private isComponentFocused = true;
  private readonly PREVIEW_TYPE = [
    'link-resources',
    'mail-resources',
    'visits',
    'favorites',
    'people',
    'static-collection-items',
    'wiki-collection-items',
    'wiki-tree',
    'wiki-drafts',
  ];
  selectedForPreview$ = this.selected$.asObservable().pipe(
    distinctUntilChanged(),
    debounce(() => timer(200))
  );

  //inputs from user
  displayedContext: ResultDisplayContext;
  changeSelectedIndex = true;
  innerSelected: SearchResults;
  selectedIndex: number;
  hoveredItem: SearchResults;

  //outputs
  public updateCdr = new Subject<void>();
  public updateSelectedIndex = new Subject<number>();
  public updatePreviewViewModel = new Subject<PreviewViewModel>();

  constructor(
    protected previewService: PreviewService,
    private resultsService: ResultsService,
    private previewKeyboardService: PreviewKeyboardService,
    protected eventsService: EventsService,
    protected hubService: HubService,
    private keyboardService: KeyboardService,
    private resultCommandService: ResultCommandService,
    private blobPreviewService: BlobPreviewService
  ) {
    this.splitAreaPreviewModel = {
      minPreviewWidth: this.previewService.minPreviewWidth,
      maxPreviewWidth: this.previewService.maxPreviewWidth,
      visibleResults: true,
      visiblePreview: false,
    };
  }

  init(thiss) {
    this.previewService.previewModelChange$.pipe(untilDestroyed(thiss)).subscribe((v: PreviewViewModel) => {
      this.previewViewModel = cloneDeep(v);
      this.updatePreviewViewModel.next(this.previewViewModel);
      this.setSplitArea();
      this.updateCdr.next();
    });

    // listen to blob preview
    this.blobPreviewService.popupRefOpen$.pipe(untilDestroyed(thiss)).subscribe((res) => {
      if (res) {
        this.previewViewModel = { previewState: 'popup' };
        this.setSplitArea();
        this.updateCdr.next();
      }
    });

    this.previewKeyboardService.init();

    this.previewKeyboardService.componentFocused$.pipe(untilDestroyed(thiss)).subscribe((val) => {
      this.isComponentFocused =
        !val ||
        [PreviewFocus.Results, PreviewFocus.PreviewToggle].includes(val?.compId) ||
        (this.previewViewModel.previewState === 'none' && this.isActivePreview);
      this.updateCdr.next();
    });

    windowSizeObserver(100)
      .pipe(untilDestroyed(thiss))
      .subscribe(() => this.calcPreviewState());

    this.previewService.setPreviewStateCache('none');
    this.calcPreviewState();
  }

  get isActivePreview(): boolean {
    const source = this.displayedContext?.sources?.map((s) => s.source.type);
    return source?.find((s) => this.PREVIEW_TYPE.includes(s)) && !this.emptyResults;
  }

  get emptyResults() {
    return this.displayedContext && this.displayedContext.searchCompleted && this.displayedContext.items?.length == 0;
  }

  get selected(): SearchResults {
    if (this.innerSelected) {
      return this.innerSelected;
    }
    const si = this.displayedContext?.items?.[this.selectedIndex];
    return <SearchResults>si;
  }

  get isFirstItem() {
    return this.getRealPosition(this.selectedIndex) === 0;
  }

  get isLastItem() {
    return this.getRealPosition(this.selectedIndex) === this.displayedContext?.items?.length - 1;
  }

  get isSmallScreen() {
    return ['small', 'extra-small'].includes(getWidthBreakpointScreen());
  }

  get displayTogglePreview(): boolean {
    const isLauncher = false,
      showGhost = true; //temp
    return (showGhost || this.isActivePreview) && this.previewViewModel.previewState !== 'full' && !isLauncher && !this.isSmallScreen;
  }

  checkPreviewVisibility(event: any) {
    if (this.isActivePreview && this.previewViewModel.previewState === 'side' && isPreviewClickAction(this.selected?.action)) {
      this.selected$.next([
        { item: <Search.ResultResourceItem>this.selected, trigger: 'mouse_click', isFirst: this.isFirstItem, isLast: this.isLastItem },
      ]);
      this.previewService.openFullPreview(this.selected, this.getRealPosition(this.selectedIndex));
    }
  }

  getRealPosition(index: number) {
    return this.resultCommandService.getRealPosition(this.displayedContext?.items, index, this.displayedContext?.lastHeaderIndex);
  }

  previewDragEnd(event) {
    const size = Number(event.sizes[1]);
    this.previewService.setPreviewWidth(size);
  }

  toggleResultsPreview(trigger: TelemetryTrigger) {
    document.activeElement instanceof HTMLElement && document.activeElement.blur();
    const previewState = this.previewViewModel.previewState === 'side' ? 'none' : 'side';
    this.previewService.setPreviewState(previewState, this.getRealPosition(this.selectedIndex), this.selected);
    this.previewService.setPreviewStateCache(previewState);
    if (trigger === 'keyboard') {
      this.previewKeyboardService.setComponentFocused(PreviewFocus.PreviewToggle);
    }

    const ev: Partial<EventInfo> = {
      location: { title: this.hubService.currentLocation },
      target: trigger ? trigger : 'mouse_click',
    };

    this.eventsService.event(`preview.column_${this.previewViewModel.previewState.includes('side') ? 'open' : 'close'}`, ev);
    this.emitSelected(trigger);
    this.hubService.changeFocusStateMultiCalls(true, true);
  }

  emitSelected(trigger: TelemetryTrigger) {
    if (
      !this.selected ||
      !this.displayedContext.items ||
      (!isResourceResult(this.selected) && !isPerson(this.selected) && !isCollectionFileStaticItem(this.selected))
    ) {
      return;
    }
    if (this.changeSelectedIndex) {
      this.selectedIndex = this.displayedContext.items.indexOf(this.selected);
    }
    this.updateSelectedIndex.next(this.selectedIndex);
    this.selected$.next([{ item: <Search.ResultResourceItem>this.selected, trigger, isFirst: this.isFirstItem, isLast: this.isLastItem }]);
  }

  updatePreviewState(state: PreviewMode, hoveredItem?: SearchResults, trigger?: TelemetryTrigger) {
    if (state === 'none' || this.isActivePreview) {
      this.previewService.setPreviewState(state, this.getRealPosition(this.selectedIndex), hoveredItem || this.selected);
      this.emitSelected(trigger);
    }
  }

  calcPreviewState() {
    if (!this.isActivePreview || this.currentBreakpoint === getWidthBreakpointScreen()) return;
    if (this.previewViewModel.previewState !== 'side') {
      this.previewService.setPreviewWidth(this.previewService.minPreviewWidth);
      return;
    }
    if (['small', 'extra-small'].includes(getWidthBreakpointScreen())) {
      this.previewService.setPreviewState('none');
    }
    this.currentBreakpoint = getWidthBreakpointScreen();
    this.setSplitArea();
    this.previewService.setPreviewWidth(this.previewService.minPreviewWidth);
  }

  setSplitArea(size?: PreviewMode) {
    switch (size ?? this.previewViewModel.previewState) {
      case 'full':
        this.splitAreaPreviewModel.visiblePreview = true;
        this.splitAreaPreviewModel.visibleResults = false;
        break;
      case 'popup':
      case 'none':
        this.splitAreaPreviewModel.visiblePreview = false;
        this.splitAreaPreviewModel.visibleResults = true;
        break;
      case 'side':
        this.splitAreaPreviewModel.visiblePreview = true;
        this.splitAreaPreviewModel.visibleResults = true;
    }
    this.splitAreaPreviewModel.minPreviewWidth = this.previewService.minPreviewWidth;
    this.splitAreaPreviewModel.maxPreviewWidth = this.previewService.maxPreviewWidth;
  }

  getTogglePreviewTooltip(): string {
    let state = 'Hide';
    if (this.previewViewModel.previewState === 'none') {
      state = 'Show';
    }
    return `${state} Side Preview`;
  }

  onInvoke(data: InvokeCommand, type: Search.ResultType) {
    if (isPreviewCommand(data.command)) {
      if (isPreviewClickAction(this.selected?.action)) {
        this.selected$.next([
          {
            item: <Search.ResultResourceItem>this.selected,
            trigger: data.trigger as TelemetryTrigger,
            isFirst: this.isFirstItem,
            isLast: this.isLastItem,
          },
        ]);
      }
    }
  }

  checkSelected() {
    if (!this.selected) return;
    if (isResourceResult(this.selected) || isPerson(this.selected) || isCollectionFileStaticItem(this.selected)) {
      this.selected$.next([{ item: <Search.ResultResourceItem>this.selected, isFirst: this.isFirstItem, isLast: this.isLastItem }]);
    }
  }

  openContextMenu() {
    if (isPreviewClickAction(this.selected?.action)) {
      this.selected$.next([
        {
          item: <Search.ResultResourceItem>this.selected,
          trigger: 'context_menu_click',
          isFirst: this.isFirstItem,
          isLast: this.isLastItem,
        },
      ]);
    }
  }

  handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    const modifiers = getModifiers(keys);
    const nonModifiers = removeModifiers([...keys]);

    if (nonModifiers.length > 1) return;
    const key = nonModifiers[0];

    if (keys[0] === 'tab' && this.isComponentFocused) {
      if (modifiers.length && modifiers[0] === 'shift') {
        this.previewKeyboardService.setComponentFocused('prev');
      } else {
        this.previewKeyboardService.setComponentFocused('next');
      }
      return;
    }

    switch (key) {
      case 'escape': {
        if (this.previewViewModel.previewState === 'side' && !this.keyboardService.stopListening) {
          this.updatePreviewState('previous');
          this.hubService.changeFocusState(true, false);
          event.stopPropagation();
        }
        return;
      }
    }
    if (isEnterKey(key)) {
      event.stopPropagation();
      if (this.isComponentFocused) {
        if (this.previewKeyboardService.componentFocused?.compId === PreviewFocus.PreviewToggle) {
          this.toggleResultsPreview('keyboard');
          return;
        }
        if (this.isActivePreview && getPrimaryActionClick(this.selected?.action) === 'preview') {
          this.updatePreviewState('popup', this.hoveredItem || this.selected, 'keyboard');
          return;
        }
      }
    }
  }
}
