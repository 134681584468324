import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'result-ghost',
  templateUrl: './result-ghost.component.html',
  styleUrls: ['./result-ghost.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultGhostComponent {
  @Input() ghostingLine = 2;
  @Input() galleryView = false;
  @Input() flatView = false;
  @Input() showAvatar = true;
  @Input() width = '100%';
}
