import { Pipe, PipeTransform } from '@angular/core';
import { sanitize } from '@local/ts-infra';

@Pipe({
  name: 'richTextFormatted',
})
export class RichTextFormattedPipe implements PipeTransform {
  transform(text: string): any {
    return sanitize(text);
  }
}
