import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Filters, Style } from '@local/client-contracts';
import {
  MultiSelectSelectedEvent,
  UMultiSelectComponent,
  MultiSelectOptionsUpdatedEvent,
  MultiSelectSortField,
  MultiSelectModel,
  SearchState,
} from '@local/ui-infra';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TelemetryService } from '@shared/services';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { isEnterKey, KeyName } from '@local/ts-infra';
import { HubService } from 'src/app/bar/services/hub.service';
import { DisplaySearchFilterValue, Filter, FilterChangeData } from './models';

export type OpenFilterData = { opened: boolean; reset: boolean };
export type ValuesUpdatedEvent = {
  name: string;
  values: DisplaySearchFilterValue[];
  filterInput: string;
  picker: Filters.PickerType;
};
@UntilDestroy()
@Component({
  template: '',
})
export abstract class MultiSelectFilterBaseComponent<T extends Filter> implements OnDestroy {
  readonly MIN_WIDTH_MULTI_SELECT: string = '76px';
  private valuesFetched: boolean;
  private keyHandlerId: string;
  private isOpened: boolean;
  private _filterBy = 'value,title,subtitle,label';
  private _model: T;

  selectedItems: DisplaySearchFilterValue[] = [];
  selectedItemIcon: Style.EntityIcon<Style.EntityIconType>;
  itemOptions: DisplaySearchFilterValue[];

  @ViewChild(UMultiSelectComponent) uMultiSelect: UMultiSelectComponent;

  //multi-select inputs
  @Input() allowMultiSelectIcon: boolean;
  @Input() keepPlaceholder: boolean;
  @Input() showSelectedItemIcons = false;
  @Input() virtualScroll = true;
  @Input() filterPlaceholder: string;
  @Input() appendTo: any = null;
  @Input() inlineFilterPlaceHolder: string = null;
  @Input() showClear = false;
  @Input() oneValue = false;
  @Input() autoSort = true;
  @Input() showTooltipIcons = false;
  @Input() styles: any = null;
  @Input() showSpecialSelectedItemsView = false;
  @Input() open = false;
  @Input() noCheckbox: boolean;
  @Input() updateOnRemove: boolean;
  @Input() hideOnSelect: boolean;
  @Input() excludeSelectedFromHeader: boolean;
  @Input() showHasValues = true;
  @Input() showClearAll: boolean;
  @Input() sortBy: MultiSelectSortField[];
  @Input() showGroupOptions: 'All' | 'FilterOnly';
  @Input() groupOptionsField: string;
  @Input() displayBackIcon: boolean;
  @Input() showRemoveIcon: boolean;
  @Input() clearAllMinSelected: number;
  @Input() shortSelected: boolean;
  @Input() tooltipSelected: string;
  @Input() customSelectedCount: number;
  @Input() optionsFetcher: (term: string) => Promise<any[]>;
  @Input() itemSize: number;
  @Input() filterDisabled: SearchState | boolean;
  @Input() filterMatchMode: string;
  @Input() wideWidth = false;
  @Input() enableBack = false;
  @Input() clearSearchAfterSelect = true;
  @Input() version: number;
  @Input() separateSelected: boolean;
  @Input() searchDebounce = 100;
  @Input() hideSearchFilter: boolean;
  @Input() tooltipLabel: string;
  @Input() set filterBy(value: string) {
    if (value) {
      this._filterBy = value;
    }
  }
  @Input() set marked({ marked, keys, event }: { marked: boolean; keys: KeyName[]; event: CustomKeyboardEvent }) {
    if (marked) this.registerKeyHandler(keys, event);
    else this.unregisterKeyHandler();
  }
  @Input() set model(val: T) {
    this._model = val;
    this.valuesFetched = false;
    this.selectedItems = val.values.filter((a) => a.selected);
  }

  @Output() onBack = new EventEmitter<void>();
  @Output() onFilterChange = new EventEmitter<FilterChangeData>();
  @Output() onOpened = new EventEmitter<OpenFilterData>();
  @Output() onInputFilterChange = new EventEmitter();
  @Output() onKeyboardItemChange = new EventEmitter<any>();
  @Output() onValuesUpdated = new EventEmitter<ValuesUpdatedEvent>();
  @Output() onClickEvent = new EventEmitter<any>();

  get model() {
    return this._model;
  }

  get options(): MultiSelectModel {
    return {
      optionLabel: 'value',
      options: this.model?.values || [],
      open: this.open,
      version: this.version || 0,
      groupOptionsField: this.groupOptionsField,
      fetcher: this.optionsFetcher,
    };
  }

  get filterBy(): string {
    return this._filterBy;
  }

  get disabled() {
    return (
      !this.isOpened && (this.model.disabled || (this.model.values?.length === 0 && (!this.model.valuesFetcher || this.valuesFetched)))
    );
  }

  constructor(
    public cdr: ChangeDetectorRef,
    protected telemetryService: TelemetryService,
    protected ref: ElementRef,
    protected keyboardService: KeyboardService,
    protected hubService: HubService
  ) {}

  onUpdateModel() {
    const newSelectedItems = [];
    if (this.selectedItems.length === 0 || !this.selectedItems) return;
    (this.itemOptions || this.model.values).forEach((elm) => {
      const newSelected = this.selectedItems.find((selected) => selected.value === elm.value);
      if (newSelected) {
        newSelectedItems.push(elm);
      }
    });
    this.selectedItems = newSelectedItems;
  }

  ngOnDestroy(): void {
    this.unregisterKeyHandler();
  }

  private registerKeyHandler(keys?: KeyName[], event?: CustomKeyboardEvent): void {
    if (this.keyHandlerId) return;
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 8);
    if (keys) this.handleKeys(keys, event);
  }

  private unregisterKeyHandler(): void {
    if (!this.keyHandlerId) return;
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    this.keyHandlerId = null;
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent) {
    const key = keys[0];

    if (this.uMultiSelect?.multiSelect?.overlayVisible) {
      if (key === 'tab' || key === 'ArrowLeft' || key === 'ArrowRight') {
        this.togglePanel();
        return;
      }

      if (key === 'escape') {
        this.togglePanel();
        event.stopPropagation();
        return;
      }

      if (key === 'ArrowDown' || key === 'ArrowUp' || isEnterKey(key)) {
        event.stopPropagation();
        return;
      }
      if (isEnterKey(key)) {
        event.stopPropagation();
        event.preventDefault();
      }
      event.stopPropagation();
    }

    if (!this.uMultiSelect?.multiSelect?.overlayVisible && (isEnterKey(key) || key === 'space') && !this.disabled) {
      this.togglePanel();
      event.preventDefault();
      event.stopPropagation();
      return;
    }
  }

  togglePanel() {
    if (!this.uMultiSelect?.multiSelect) {
      return;
    }
    this.uMultiSelect.multiSelect.overlayVisible ? this.uMultiSelect.multiSelect.hide() : this.uMultiSelect.multiSelect.show();
    if (!this.uMultiSelect.multiSelect.overlayVisible) {
      this.unregisterKeyHandler();
    }
  }

  optionsUpdated($event: MultiSelectOptionsUpdatedEvent) {
    this.valuesFetched = true;
    this._model.values = $event.options as DisplaySearchFilterValue[];
    this.onValuesUpdated.emit({
      values: this._model.values,
      name: this.model.name,
      filterInput: $event.filterInput,
      picker: this.model.picker,
    });
  }

  onChange($event: MultiSelectSelectedEvent) {
    this.selectedItems = $event.value || [];
    const currentValues = this.selectedItems;
    this.onFilterChange.emit({
      name: this.model.name,
      changes: {
        values: $event.itemValue ? [$event.itemValue] : [],
      },
      current: {
        values: currentValues,
      },
      action: $event.action,
    });
    let icon = null;
    if (this.selectedItems?.length === 1) {
      icon = this.selectedItems[0]?.icon;
    }
    this.selectedItemIcon = icon;
    if (icon) {
      this.cdr.markForCheck();
    }
  }

  onFilter($event: { originalEvent: InputEvent; filter: string }) {
    this.onInputFilterChange.emit($event);
    let resultsCount: number;
    if ($event.filter === '') {
      resultsCount = this.model.values.length;
    } else {
      if (this.uMultiSelect?.multiSelect?._filteredOptions) {
        resultsCount = this.uMultiSelect.multiSelect._filteredOptions.length;
      } else {
        resultsCount = this.model.values.length;
      }
    }
  }

  onClearAllEvent() {
    this.onChange({ value: null, itemValue: null, action: 'ClearAll', originalEvent: null });
  }

  onPanel(isPanelOpen: boolean, reset = true) {
    isPanelOpen ? this.registerKeyHandler() : this.unregisterKeyHandler();
    this.isOpened = isPanelOpen;
    this.onOpened.emit({ opened: isPanelOpen, reset });
  }

  onKeyboardItemChangeEvent($event) {
    this.onKeyboardItemChange.emit({ value: $event, filter: this.uMultiSelect.multiSelect.filterValue });
  }

  updateSelectedItems(newSelectedItems: any) {
    this.selectedItems = newSelectedItems;
    this.onUpdateModel();
    this.cdr.markForCheck();
  }

  onClick($event) {
    const allowReadonlyClick = this.model.readonly && this.model.viewDetails.allowClickInReadOnly;
    if ((!this.model.disabled && !this.model.readonly) || allowReadonlyClick) {
      this.onClickEvent.emit();
    }
  }
}
