import { SearchResults } from '../../views';
import { SearchOptions } from './search-options';
import { SourceResult } from './source-result';

export interface SearchResultContext {
  id: number;
  items: SearchResults[];
  lastHeaderIndex?: number;
  clientSearchId: string;
  sessionId: string;
  searchCompleted?: boolean;
  sources?: SourceResult[];
  options?: SearchOptions;
}
