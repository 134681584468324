import { OverlayRef } from '@angular/cdk/overlay';
import { ElementRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

export type TelemetryTarget = 'mouse_click' | 'keyboard';
export type CloseData = { via: TelemetryTarget };

export class PopupRef<ComponentType, DataType> {
  public panelRef?: ElementRef;
  public compInstance: ComponentType;
  public outsidePointerEvents$ = new Subject<any>();

  get data(): DataType {
    return this._data;
  }

  get close$(): Observable<CloseData> {
    return this._close$;
  }

  get overlayRef(): OverlayRef {
    return this._overlayRef;
  }

  private onUpdateFn: (data: DataType) => void;
  private readonly _close$ = new Subject<CloseData>();
  private closed = false;
  private destroyed = false;
  readonly destroy$ = new Subject<DataType>();

  constructor(private _overlayRef: OverlayRef, private _data: DataType, public fullScreenDialog: boolean = false) {
    this.initCloseOnClickOut();
    this.outsidePointerEvents();
  }

  private initCloseOnClickOut() {
    this._overlayRef
      .backdropClick()
      .pipe(take(1), takeUntil(this._close$))
      .subscribe(() => {
        this.close('mouse_click');
        this.destroy();
      });
  }

  close(via?: TelemetryTarget) {
    this._close$.next({ via });
    this._close$.complete();
    this.closed = true;
  }

  update(data: DataType) {
    this._data = data;
    if (this.onUpdateFn) {
      this.onUpdateFn(data);
    }
  }

  private outsidePointerEvents(): void {
    this._overlayRef
      .outsidePointerEvents()
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        this.outsidePointerEvents$.next(event);
      });
  }

  onUpdate(callback: (data: DataType) => void) {
    this.onUpdateFn = callback;
  }

  destroy() {
    if (this.destroyed) {
      return;
    }
    if (!this.closed) {
      this._close$.complete();
    }
    this.outsidePointerEvents$.complete();
    this.overlayRef.detach();
    this.overlayRef.dispose();
    this.destroy$.next(null);
    this.destroy$.complete();
    this.destroyed = true;
  }
}
