import { Results, Search } from '@local/client-contracts';
import { EventInfo, LogService } from '@shared/services';
import { HeaderItem, SearchResults } from 'src/app/bar/views';
import { SearchRequest } from '../search-request';
import { RelevantPeopleSourceSettings } from './relevant-people-source-settings';
import { ResultsService } from '../../../results.service';
import { Action } from 'src/app/bar/views/results/models/view-filters';
import { filter, firstValueFrom } from 'rxjs';
import { SearchResponse } from '../search-response';
import { getDisplayHeader } from '@shared/utils/header-builder.util';
import { SearchClient } from '../search-client';
import { SearchResponseType } from '../search-response-type';
import { Logger } from '@unleash-tech/js-logger';
import { PeopleService } from 'src/app/bar/views/preview/people-preview/services/people.service';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { FlagsService } from '@shared/services/flags.service';
import { FiltersService } from '../../../filters.service';
import { LinksService } from '@shared/services/links.service';
const PEOPLE_FILTERS_LIST: string[] = ['department', 'jobTitle', 'location', 'isManager', 'managedBy'];

export class RelevantPeopleSearchClient implements SearchClient<RelevantPeopleSourceSettings> {
  private logger: Logger;

  constructor(
    logService: LogService,
    private peopleService: PeopleService,
    private resultsService: ResultsService,
    private memorySearchService: MemorySearchService,
    private linksService: LinksService,
    private flagsService: FlagsService,
    private filtersService: FiltersService
  ) {
    this.logger = logService.scope('relevant-people-search-client');
  }

  supportsSort(): boolean {
    return true;
  }

  supportsFilters(): boolean {
    return true;
  }

  nextPage(): Promise<void> {
    return;
  }

  destroy(): void {
    return;
  }

  getTelemetryEndEvent(): Partial<EventInfo>[] {
    return;
  }

  search(request: SearchRequest<RelevantPeopleSourceSettings>, response: SearchResponse): SearchResponseType {
    return this.innerSearch(request, response);
  }

  async innerSearch(request: SearchRequest<RelevantPeopleSourceSettings>, response: SearchResponse) {
    this.initExtra(response);
    this.addTime('innerSearch-start', response);
    const isRelevantPeopleEnabled = await this.flagsService.isEnabled('showRelevantPeople');
    if (!isRelevantPeopleEnabled) {
      response.items = [];
      response.complete(true);
      return;
    }
    this.addTime('after-isEnabled', response);
    const peopleLinkId = await firstValueFrom(this.linksService.peopleLinkId$);
    this.addTime('after-ws-current$', response);
    if (!peopleLinkId) {
      this.logger.info('RelevantPeopleSearchClient: Missing peopleLinkId');
      response.items = [];
      response.complete(true);
      return;
    }

    const preFilters = this.filtersService.inlineFilters;
    const timestamp = Date.now();
    const observable = await this.peopleService.getExperts({
      query: request.query,
      timestamp,
      preFilters,
      excludeFilters: { appId: ['slack'] },
    });
    this.addTime('after-getExperts', response);
    const res = await firstValueFrom(observable.pipe(filter((x) => !!x)));
    this.addTime('after-getExperts-firstValueFrom', response);
    for (const [name, duration] of Object.entries(res?.durations || [])) {
      this.addTime(name, response, duration);
    }
    if (response.cancelled) {
      return;
    }
    const experts = res.items;
    if (!experts.length) {
      response.items = [];
      response.complete(true);
      return;
    }
    const sourceSettings = request.sourceSettings;
    const length = experts.length;
    response.extra = { ...response.extra, postFilters: this.getPostFilters(experts, sourceSettings) };
    const expertsSliced = experts?.slice(0, sourceSettings.maxCount || experts.length) || [];
    const expertResults = await this.transformOutputItem(expertsSliced, sourceSettings);
    this.addTime('after-transformOutputItem', response);
    const expertResultsFiltered: SearchResults[] = await this.filter(expertResults, sourceSettings);
    this.addTime('after-filter', response);
    let expertResultsSorted: SearchResults[] = [];
    if (expertResultsFiltered.length > 0) {
      if (request.sourceSettings.sorting) {
        const res = await this.memorySearchService.rank(
          [],
          expertResultsFiltered.map((e: any) => ({ data: e, sortValue: e?.view?.title?.text, searchText: e?.view?.title?.text })),
          request.sourceSettings.sorting
        );
        expertResultsSorted = res.map((e) => e.data);
      } else {
        expertResultsSorted = expertResultsFiltered;
      }
      this.addHeaders(request, expertResultsSorted, length);
    }
    this.addTime('after-rank', response);

    if (response.cancelled) {
      return;
    }
    this.adjustItemIcons(expertResultsSorted);
    response.items = expertResultsSorted;
    response.complete(true);
  }

  protected addHeaders(request: SearchRequest<RelevantPeopleSourceSettings>, items: SearchResults[], totalResults: number): void {
    const settings = request.sourceSettings;
    if (!settings.header) return;

    const { title, titleEnd } = getDisplayHeader(
      { title: settings.header?.title, titleEnd: settings.header?.titleEnd },
      settings.maxCount ? totalResults : items.length
    );

    const header: HeaderItem = {
      type: 'header',
      clickable: settings.header.clickable,
      origin: settings.type,
      title,
      titleEnd,
      group: settings.header?.group,
      leftIcon: settings.header?.leftIcon,
      leftIconStyles: settings.header?.leftIconStyles,
    };
    items.unshift(header);

    const addFooter = totalResults > settings.maxCount;
    if (addFooter) {
      const footer: HeaderItem = {
        type: 'header',
        clickable: true,
        origin: `footer-${settings.type}`,
        title: 'See All',
        isFooter: true,
        selectable: true,
        group: settings.header.group ?? undefined,
      };
      items.push(footer);
    }
  }

  private async adjustItemIcons(items: SearchResults[]) {
    for (const i of items.filter((i) => i.type == 'person')) {
      const resultItem = <Search.ResultItem>i;

      const view = resultItem.view;
      if (view?.iconOverlay) {
        view.icon = view.iconOverlay;
        view.iconOverlay = null;
      }
    }
  }

  private async transformOutputItem(items: Search.RelevantPeopleItem[], settings: RelevantPeopleSourceSettings): Promise<SearchResults[]> {
    let index = 0;
    const arrOutput: any[] = [];
    for (const item of items) {
      const action: Action = await this.resultsService.getResultAction(item);
      this.convertItemBullets(item);
      arrOutput.push({
        ...item,
        type: 'person',
        action: { ...action, type: settings.kind },
        kind: settings.kind,
        settings: { viewMode: settings.viewMode, index },
      });
      index++;
    }
    return arrOutput;
  }

  private convertItemBullets(item: Search.RelevantPeopleItem) {
    const data = item.resource.traits;
    if (data.department) {
      item.view.bullets = [{ parts: [{ text: data.department, icon: { name: 'icon-department' }, tooltip: 'Department' }] }];
    } else {
      item.view.bullets = [];
    }
  }

  private async filter(items: SearchResults[], settings: RelevantPeopleSourceSettings): Promise<SearchResults[]> {
    const postFilters = settings.filters?.postFilters;
    const preFilters = settings.filters?.preFilters;
    const filtersValues: Record<string, string[]> = {};
    PEOPLE_FILTERS_LIST.forEach((filter) => {
      const values = [
        ...(postFilters[filter] || []).map((j) => j.toLowerCase()),
        ...(preFilters[filter] || []).map((j) => j.toLowerCase()),
      ];
      if (values.length) {
        filtersValues[filter] = values;
      }
    });
    if (!Object.values(filtersValues).some((val) => val.length)) {
      return items;
    }
    const filterItems = items.filter((item) => {
      const personData = item?.['resource']?.traits;
      return Object.keys(filtersValues).some((f) => {
        switch (f) {
          case 'isManager':
            return personData[f];
          case 'managedBy':
            return filtersValues[f].includes(personData[f]?.email?.toLowerCase());
          default:
            return filtersValues[f].includes(personData[f]?.toLowerCase());
        }
      });
    });
    return filterItems;
  }

  private getPostFilters(experts: Search.RelevantPeopleItem[], sourceSettings: RelevantPeopleSourceSettings) {
    const postFilters: Search.ResponseFilters = {};
    for (const name of sourceSettings.aggregations || []) {
      postFilters[name] = [];
      for (const expert of experts) {
        const filterValue = expert.resource.traits?.[name];
        if (!filterValue) {
          continue;
        }
        let filter: Search.Filter = postFilters[name].find((f) => f.title === filterValue);
        if (!filter) {
          filter = {
            id: `${filterValue}_${filterValue}`,
            selected: false,
            count: 0,
            title: filterValue,
          };
          postFilters[name].push(filter);
        }
        filter.count++;
      }
    }
    return postFilters;
  }

  private initExtra(response: SearchResponse) {
    const now = Date.now();
    response.extra = response.extra || { durations: { $lastTimestamp$: now } };
  }

  private addTime(name: string, response: SearchResponse, time: number = null) {
    const extra = response.extra;
    const now = Date.now();
    if (time === null) {
      time = now - extra.durations.$lastTimestamp$;
      extra.durations.$lastTimestamp$ = now;
    }
    extra.durations[name] = time;
  }
}
