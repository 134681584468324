import { Injectable } from '@angular/core';
import {
  KeyValueStorageEntry,
  SessionKeyValueStorage,
  SessionStorage,
  SessionStorageRpcInvoker,
  StoreLocation,
  StoreScope,
} from '@local/common';
import { Observable } from 'rxjs';
import { ServicesRpcService } from '.';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService implements SessionStorage {
  private service: SessionStorage;

  constructor(services: ServicesRpcService) {
    this.service = services.invokeWith(SessionStorageRpcInvoker, 'sessionstorage');
  }

  getStore(loc: StoreLocation, scope: StoreScope, namespace?: string) {
    return new SessionKeyValueStorage(this, loc, scope, namespace);
  }

  async keys(loc: StoreLocation, scope: StoreScope, namespace: string): Promise<string[]> {
    return this.service.keys(loc, scope, namespace);
  }

  async entries(loc: StoreLocation, scope: StoreScope, namespace: string): Promise<KeyValueStorageEntry[]> {
    return this.service.entries(loc, scope, namespace);
  }

  value$<T>(field: string, loc: StoreLocation, scope: StoreScope, namespace: string): Observable<T> {
    return this.service.value$<T>(field, loc, scope, namespace);
  }

  async setValue(field: string, v: any, loc: StoreLocation, scope: StoreScope, namespace: string): Promise<void> {
    return this.service.setValue(field, v, loc, scope, namespace);
  }

  async removeValue<T>(field: string, loc: StoreLocation, scope: StoreScope, namespace: string): Promise<void> {
    return this.service.removeValue(field, loc, scope, namespace);
  }

  async getValue<T>(field: string, loc: StoreLocation, scope: StoreScope, namespace: string): Promise<T> {
    let res = await this.service.getValue<T>(field, loc, scope, namespace);
    return res;
  }
}
