import { ElementRef } from '@angular/core';
import { Commands, Results, Search } from '@local/client-contracts';
import { ContextMenuService } from '@shared/components';
import { isDownloadUrlCommand } from '@shared/utils';
import { Logger } from '@unleash-tech/js-logger';
import { GlobalErrorHandler } from 'src/app/global-error-handler';
import { ResultCommandBuilder } from '../../views/results';
import { ResourcesService } from '@shared/services/resources.service';
import { ResultCommandBuilderParams } from '../../views/results/services/result-command-builder.service';
import { ResultsItemContextMenuHelper } from './results-item-context-menu-helper';
import { FlagsService } from '@shared/services/flags.service';
import { HubService } from '../hub.service';

export type ContextMenuInvokeSource = 'actions' | 'title' | 'subtitle';

export class ResultsResourceItemContextMenuHelper extends ResultsItemContextMenuHelper {
  constructor(
    builder: ResultCommandBuilder,
    logger: Logger,
    contextMenuService: ContextMenuService,
    hostRef: ElementRef,
    titleRef: ElementRef,
    subtitleRef: ElementRef,
    actionsRef: ElementRef,
    errorHandler: GlobalErrorHandler,
    resourcesService: ResourcesService,
    hubService: HubService,
    protected flagsService: FlagsService
  ) {
    super(
      builder,
      logger,
      contextMenuService,
      hostRef,
      titleRef,
      subtitleRef,
      actionsRef,
      errorHandler,
      resourcesService,
      hubService,
      flagsService
    );
  }

  async buildItemsRequest(
    state?: Commands.PreviewState,
    model?: Search.ResultResourceItem,
    source?: ContextMenuInvokeSource,
    isPopUp?: boolean,
    previewable = false,
  ): Promise<ResultCommandBuilderParams> {
    const itemsRequest: ResultCommandBuilderParams = await super.buildItemsRequest(
      state,
      model,
      source,
      isPopUp,
      previewable,
      false,
      model.link?.shareOptions?.restricted ? { addToCollection: true } : {}
    );
    const downloadUrl: string = await this.shouldAllowDownload(model);
    return { ...itemsRequest, download: downloadUrl ? { url: downloadUrl } : null, resourceId: model?.resource?.id };
  }

  async shouldAllowDownload(model: Search.ResultResourceItem): Promise<string> {
    const title = model?.view?.title as Results.Title;

    return title?.onDrag &&
      isDownloadUrlCommand(title?.onDrag) &&
      model?.link?.appId !== 'pc' &&
      (await this.flagsService.isEnabled('fileDownloads', model?.link?.id))
      ? title?.onDrag.url
      : null;
  }
}
