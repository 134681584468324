import { Injectable } from '@angular/core';
import { Analytics } from '@local/client-contracts';
import { AnalyticsRpcInvoker, observable } from '@local/common';
import { ServicesRpcService } from '@shared/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService implements Analytics.Service {
  private service: Analytics.Service;

  constructor(services: ServicesRpcService) {
    this.service = services.invokeWith(AnalyticsRpcInvoker, 'analytics');
  }

  @observable
  get analytics$(): Observable<Analytics.Analytics> {
    return this.service.analytics$;
  }

  getAnalytics(): Promise<Analytics.Analytics> {
    return this.service.getAnalytics();
  }
}
