import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopupRef } from '@local/ui-infra';

export type SynonymsItemPreviewData = { key: string; synonym: string };

export type SynonymsPreviewData = {
  fileName: string;
  header: SynonymsItemPreviewData;
  synonyms: SynonymsItemPreviewData[];
  count?: number;
};

@Component({
  templateUrl: './synonyms-preview-popup.component.html',
  styleUrls: ['./synonyms-preview-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SynonymsPreviewPopupComponent implements OnInit {
  @Output() onCancel = new EventEmitter();
  isLoading: boolean = true;

  get count(){
    return `${!isNaN(this.data?.count) ? '(' + this.data.count + ')' : ''}`
  }

  private _data: SynonymsPreviewData;
  get data(): SynonymsPreviewData {
    return this._data;
  }
  @Input() set data(value: SynonymsPreviewData) {
    this._data = value;
    this.checkLoadingStatus();
    this.cdr.markForCheck();
  }

  constructor(public cdr: ChangeDetectorRef, private ref: PopupRef<SynonymsPreviewPopupComponent, any>) {}

  ngOnInit(): void {
    this.data = this.ref?.data;
    this.checkLoadingStatus();
  }

  private checkLoadingStatus() {
    this.isLoading = !this.data;
    this.cdr.markForCheck();
  }
}
