import { ConnectedPosition } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { Collections } from '@local/client-contracts';
import { PopupRef, PopupService } from '@local/ui-infra';
import { EventsService } from '@shared/services';
import { take } from 'rxjs';
import { AppPopupComponent, AppPopupData } from 'src/app/bar/components/app-popup/app-popup.component';
import { VerificationOptionsDetailsModel } from 'src/app/bar/components/verifications/verification-options-details/verification-options-details.component';
import { HubService } from 'src/app/bar/services/hub.service';
import { MultiChoicePopupService } from 'src/app/bar/services/multi-choice-popup.service';
import { MultiChoicePopupType } from '../components/multi-choice-popup/multi-choice-popup.component';
import { NewStaticItemPopupComponent } from '../components/new-static-item-popup/new-static-item-popup.component';
import { collectionContent } from '../helpers/collection.content';
import { WikiPopupsService } from './wiki-popups.service';

@Injectable()
export class CollectionPopupService {
  constructor(
    public popupService: PopupService,
    private eventsService: EventsService,
    private hubService: HubService,
    private wikiPopupsService: WikiPopupsService,
    private multiChoicePopupService: MultiChoicePopupService
  ) {}

  pinPopupRef: PopupRef<AppPopupComponent, AppPopupData> = null;
  deletePopupRef: PopupRef<AppPopupComponent, AppPopupData> = null;
  private popupNewStaticItemRef: PopupRef<NewStaticItemPopupComponent, any>;
  areYouSureToRemoveItemRef: PopupRef<AppPopupComponent, AppPopupData> = null;

  isEmojiPopupRefOpen: boolean;

  openPinSharedCollectionMessagePopup(tabName: string, checkbox?: { show: boolean; value?: boolean }) {
    if (this.pinPopupRef) {
      this.pinPopupRef.destroy();
    }

    const position: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' }];
    this.pinPopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      {
        message: `Are you sure you want to unpin this collection from "<b>${tabName}</b>"? This will affect all members it is shared with.`,
        showButtons: true,
        rightButtonStyle: { type: 'primary', size: 100 },
        leftButtonStyle: { size: 100 },
        content: {
          title: 'Are you sure?',
          secondaryButton: 'Cancel',
          primaryButton: collectionContent.unpin,
        },
        checkbox: !checkbox?.show
          ? null
          : {
              value: checkbox.value,
              text: 'Do not show this again',
            },
        messageStyle: { fontSize: '12px' },
      },
      { position }
    );

    this.pinPopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.pinPopupRef = null;
    });
    return this.pinPopupRef;
  }

  openAreYouSureToRemoveItemPopup(items: any[], itemsCount = 1, itemString?: string, message?: string) {
    if (this.areYouSureToRemoveItemRef) {
      this.areYouSureToRemoveItemRef.destroy();
    }
    const position: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' }];
    if (!itemString) {
      itemString = (itemsCount === 1 && items[0].kind === 'header') || items.every((i) => i.kind === 'header') ? 'header' : 'item';
    }
    const relevantMessage = itemsCount > 1 ? `these ${itemsCount} ${itemString}s` : `this ${itemString}`;

    this.areYouSureToRemoveItemRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      {
        showButtons: true,
        rightButtonStyle: { size: 76 },
        leftButtonStyle: { size: 76 },
        message,
        content: {
          title: `Are you sure you want to <br> delete ${relevantMessage}?`,
          secondaryButton: 'No',
          primaryButton: 'Yes',
        },
        messageStyle: { fontSize: '12px' },
        popupStyle: { paddingTop: '32px' },
      },
      { position }
    );
    this.areYouSureToRemoveItemRef.compInstance.primaryButton.subscribe(() => this.sendDeleteEvent('yes', 'delete_resource_prompt'));
    this.areYouSureToRemoveItemRef.compInstance.secondaryButton.subscribe(() => this.sendDeleteEvent('no', 'delete_resource_prompt'));
    this.areYouSureToRemoveItemRef.destroy$.pipe(take(1)).subscribe(() => {
      this.areYouSureToRemoveItemRef = null;
    });
    return this.areYouSureToRemoveItemRef;
  }

  private sendDeleteEvent(target: string, name: string) {
    this.eventsService.event(`collections.${name}`, {
      target,
      location: { title: this.hubService.currentLocation },
    });
  }

  openDeleteCollectionPopup(collection: Collections.Collection) {
    if (this.deletePopupRef) {
      this.deletePopupRef.destroy();
    }

    const position: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' }];
    this.deletePopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      {
        message: `Are you sure you want to delete <br> the ‘${collection.title}’ ${collection.kind === 'Wiki' ? 'wiki' : 'collection'}?`,
        showButtons: true,
        rightButtonStyle: { size: 76 },
        leftButtonStyle: { size: 76 },
        content: {
          secondaryButton: 'Cancel',
          primaryButton: 'Delete',
        },
        messageStyle: { fontSize: '14px' },
        popupStyle: { paddingTop: '32px' },
      },
      { position }
    );
    this.deletePopupRef.compInstance.primaryButton.subscribe(() => this.sendDeleteEvent('delete', 'delete_prompt'));
    this.deletePopupRef.compInstance.secondaryButton.subscribe(() => this.sendDeleteEvent('cancel', 'delete_prompt'));
    this.deletePopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.deletePopupRef = null;
    });
    return this.deletePopupRef;
  }

  openDeleteTabPopup() {
    if (this.deletePopupRef) {
      this.deletePopupRef.destroy();
    }

    const position: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' }];
    this.deletePopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      {
        message: `This will affect all members it is shared with`,
        showButtons: true,
        rightButtonStyle: { size: 96 },
        leftButtonStyle: { size: 96 },
        content: {
          title: `Are you sure you want to <br> delete this tab?`,
          secondaryButton: 'Cancel',
          primaryButton: 'Delete',
        },
        messageStyle: { fontSize: '14px' },
        popupStyle: { paddingTop: '32px' },
      },
      { position }
    );
    this.deletePopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.deletePopupRef = null;
    });
    return this.deletePopupRef;
  }

  openNewCollectionPopup(elm: Element, isPopupPositionBelow: boolean, type: MultiChoicePopupType) {
    return this.multiChoicePopupService.openMultiChoicePopup(elm, isPopupPositionBelow, type);
  }

  openNewStaticItemPopup(position: { x; y }, trigger: 'button' | 'empty-state' | 'right-click') {
    const popupHeight = 110;
    const popupWidth = 160;
    const rightClickMargin = { x: -60, y: 24 };
    const buttonClickMargin = { x: -50, y: 36 };
    const emptyStateMargin = { x: 0, y: 48 };
    if (this.popupNewStaticItemRef) {
      this.popupNewStaticItemRef.destroy();
    }
    const { x, y } = position;
    let popupPosition;
    switch (trigger) {
      case 'button':
        popupPosition = { left: x + buttonClickMargin.x, top: y + buttonClickMargin.y };
        break;
      case 'empty-state':
        popupPosition = { left: x + emptyStateMargin.x, top: y + emptyStateMargin.y };
        break;
      default:
        // y of bottom of popup if it opens under the click position
        const positionPopupY = y + rightClickMargin.y + popupHeight;
        // x of bottom of popup if it opens in the right to the click position
        const positionPopupX = x + rightClickMargin.x + popupWidth;
        let positionY = y + rightClickMargin.y;
        let positionX = x + rightClickMargin.x;
        if (positionPopupY >= document.body.offsetHeight) {
          positionY = y - rightClickMargin.y - popupHeight;
        }
        if (positionPopupX >= document.body.offsetWidth) {
          positionX = x - popupWidth;
        }
        popupPosition = { left: positionX, top: positionY };
        break;
    }
    this.popupNewStaticItemRef = this.popupService.open<NewStaticItemPopupComponent, any>(popupPosition, NewStaticItemPopupComponent, {});
    this.popupNewStaticItemRef.destroy$.pipe(take(1)).subscribe(() => {
      this.popupNewStaticItemRef = null;
    });
    return this.popupNewStaticItemRef;
  }

  telemetryEvent(key: string, target: string) {
    this.eventsService.event(`collections.${key}`, {
      target,
      location: {
        title: this.hubService.currentLocation,
      },
    });
  }

  openVerificationDetailsPopup(verificationDetailsModel: VerificationOptionsDetailsModel) {
    return this.wikiPopupsService.openVerificationDetailsPopup(verificationDetailsModel);
  }
}
