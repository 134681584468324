import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Search } from '@local/client-contracts';
import { EventInfo } from '@shared/services';
import { Observable } from 'rxjs';
import { PreviewComponent } from '../../model/preview-component';
import { PreviewMode } from '../../model/preview-mode';

@Component({
  selector: 'preview-not-available',
  templateUrl: './preview-not-available.component.html',
  styleUrls: ['./preview-not-available.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewNotAvailableComponent implements PreviewComponent {
  size$: Observable<PreviewMode>;
  @Input() size: PreviewMode;
  @Input() telemetrySearch: Partial<EventInfo>;
  @Input() model: Search.ResultResourceItem;
  @Input() set index(value: number) {
    this.message = !value ? 'Select an item to preview its content' : 'Preview is not available';
    this.cdr.markForCheck();
  }
  message: string = 'Preview is not available';
  constructor(private cdr: ChangeDetectorRef) {}
}
