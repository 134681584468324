import { animate, state, style, transition, trigger } from '@angular/animations';
import { KeyValue } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  TrackByFunction,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { User as UserInt } from '@local/client-contracts';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeysNavigationComponent } from '@shared/components/keys-navigation.component';
import { EventInfoSearch, InternetService, LogService } from '@shared/services';
import { ApplicationsService } from '@shared/services/applications.service';
import { KeyboardService } from '@shared/services/keyboard.service';
import { componentServicesRpcProvider } from '@shared/services/rpc.service';
import { SessionService } from '@shared/services/session.service';
import { TimerService } from '@shared/services/timer.service';
import * as moment from 'moment/moment';
import { map, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CalendarService } from 'src/app/bar/services/calendar.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { SearchService, SearchSession } from 'src/app/bar/services/search';
import { CalendarEvent } from '../../results/models/results-types';
import { UpNextResultItemComponent } from './up-next-result-item.component';

@UntilDestroy()
@Component({
  selector: 'up-next',
  providers: [componentServicesRpcProvider],
  templateUrl: './up-next.component.html',
  styleUrls: ['./up-next.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [
      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1, transform: 'translateX(0)' })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [style({ opacity: 0 }), animate(200)]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave', animate(280, style({ opacity: 0, transform: 'translateX(-100%)' }))),
    ]),
  ],
})
export class UpNextComponent
  extends KeysNavigationComponent<CalendarEvent, UpNextResultItemComponent>
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChildren('scrollItem') elementsComp: QueryList<UpNextResultItemComponent>;
  @ViewChild('scrollArea') scrollAreaRef: ElementRef;

  user: string;
  apps: string[] = [];
  isLoading: boolean;
  error: any;
  eventsByDay: Record<number, CalendarEvent[]> = {};
  eventsAmount: number;
  searchInfo: Partial<EventInfoSearch>;
  isOnline$: Observable<boolean>;

  private destroy$ = new Subject();
  private firstLoaded: boolean;
  private refreshInterval: number;
  static readonly ID = 'UpNextComponent';
  searchSession: SearchSession;

  constructor(
    logService: LogService,
    cdr: ChangeDetectorRef,
    private barService: HubService,
    private applicationsService: ApplicationsService,
    private sessionService: SessionService,
    private timer: TimerService,
    private internet: InternetService,
    keyboardService: KeyboardService,
    private calendarService: CalendarService,
    private searchService: SearchService
  ) {
    super(logService, cdr, keyboardService);
    this.logger = logService.scope('UpNextComponent');

    this.refreshInterval = this.timer.register(() => this.load(), { visibleInterval: 1000 * 60 * 5 });
  }

  ngAfterViewInit(): void {
    this.scrollArea = this.scrollAreaRef;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.barService.readOnly = true;
    this.barService.query = '';
    this.sessionService.current$
      .pipe(
        filter((v) => !!v),
        takeUntil(this.destroy$),
        map((s) => s?.user)
      )
      .subscribe((user: UserInt.Info) => {
        this.user = user.firstName;
      });
    this.isOnline$ = this.internet.isOnline$;
    this.searchSession = this.searchService.getOrCreateSearchSession('calendar');
    this.load();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.timer.unregister(this.refreshInterval);
    this.destroy$.next(null);
    this.destroy$.complete();
    this.searchSession?.destroy();
  }

  private async load() {
    if (this.isLoading) return;
    if (!this.firstLoaded) {
      this.isLoading = true;
      this.cdr.markForCheck();
    }
    await this.applicationsService.cacheLoaded;

    const apps = await this.applicationsService.hasType(['event']);
    if (!apps.length) {
      this.error = 'No Calendar Apps';
      this.isLoading = false;
      this.cdr.markForCheck();
      return;
    }
    if (apps.every((app) => app.links?.length === 0)) {
      this.apps = apps.map(({ id }) => id);
      this.isLoading = false;
      this.cdr.markForCheck();
      this.barService.setViewReady(UpNextComponent.ID);
      return;
    }
    this.loadData();
  }

  private loadData() {
    this.calendarService
      .watch$(moment.now().valueOf() - 60 * 1000 * 60, moment().clone().add(30, 'day').valueOf(), true)
      .pipe(untilDestroyed(this))
      .subscribe(
        (result) => {
          this.isLoading = false;
          const events = result.events;
          this.organizeEventsByDay(events);
          this.barService.setViewReady(UpNextComponent.ID);
          this.cdr.markForCheck();
        },
        (err) => {
          this.error = err;
          this.isLoading = false;
          this.cdr.markForCheck();
        }
      );
  }

  private organizeEventsByDay(items: CalendarEvent[]): void {
    const now = moment();
    items = items.filter((item) => item.end?.isSameOrAfter(now)); // Removing full day events that started before today
    this.eventsByDay = this.calendarService.organizeEventsByDay(items);
    this.items = [...(this.firstLoaded ? this.items : []), ...items];
    this.setDisplayIndices();
    this.eventsAmount = this.items.length - 1;
  }

  private setDisplayIndices() {
    let displayIndex = 0;
    Object.keys(this.eventsByDay)
      .map((dateKey) => parseInt(dateKey))
      .sort((dateA: number, dateB: number) => dateA - dateB)
      .forEach((date: number) => this.eventsByDay[date].forEach((item) => (item.displayIndex = displayIndex++)));
  }

  goBack(): void {
    this.barService.goBack();
  }

  tryAgain(): void {
    this.error = null;
    this.loadData();
    this.cdr.markForCheck();
  }

  excludeResource(date: number, index: number, displayIndex: number): void {
    const itemToExclude = this.eventsByDay[date][index];
    // Dismissing all splitted events that were related to the dismissed original event event
    this.items = this.items.filter((i) => i.id !== itemToExclude.id);
    Object.values(this.eventsByDay).forEach((events) => {
      for (let j = 0; j < events.length; j++) {
        if (events[j].id === itemToExclude.id) {
          events.splice(j--, 1);
          this.eventsAmount--;
        }
      }
    });

    this.setDisplayIndices();
    this.selectedIndex = displayIndex <= this.eventsAmount ? displayIndex : this.eventsAmount;
    this.cdr.markForCheck();
  }

  getDateForTooltip(timestamp: number): string {
    return moment.unix(timestamp).format('L');
  }

  getDateFromTimestamp(timestamp: number): string {
    return moment.unix(timestamp).calendar({
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'MMM D, YYYY',
      sameElse: 'MMM D, YYYY',
    });
  }

  trackEventsByDate: TrackByFunction<KeyValue<string, CalendarEvent[]>> = (
    index: number,
    eventsForDate: KeyValue<string, CalendarEvent[]>
  ): string => eventsForDate.key.toString();
  trackEventsByResourceId: TrackByFunction<CalendarEvent> = (index: number, event: CalendarEvent): string => event.resource.id;

  getIndex(item: CalendarEvent): number {
    return item.displayIndex;
  }
}
