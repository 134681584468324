import { Filters, Search, WebSearch } from '@local/client-contracts';
import { EventInfo, LogService } from '@shared/services';
import { WebSearchService } from '@shared/services/web-search.service';
import { Logger } from '@unleash-tech/js-logger';
import { HeaderItem, TelemetryTrigger, WebSearchItem } from 'src/app/bar/views';
import { WebSourceSettings } from '.';
import { SearchClient } from '../search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { SearchResponseType } from '../search-response-type';

export class WebSearchClient implements SearchClient<WebSourceSettings> {
  private logger: Logger;
  constructor(private webSearchService: WebSearchService, private logService: LogService) {
    this.logger = this.logService.scope('web-search');
  }

  supportsSort(sort: Search.Sort): boolean {
    return false;
  }

  supportsFilters(filters: Filters.Values): boolean {
    return false;
  }

  search(request: SearchRequest<WebSourceSettings>, response: SearchResponse): SearchResponseType {
    return this.innerSearch(request, response);
  }

  nextPage(request: SearchRequest<WebSourceSettings>, response: SearchResponse, trigger: TelemetryTrigger): Promise<void> {
    return;
  }

  destroy(): void {
    return;
  }

  private async innerSearch(request: SearchRequest<WebSourceSettings>, response: SearchResponse) {
    const engine: string = 'Google';
    const sourceSettings = request.sourceSettings;
    const searchResults = await this.getWebSearchItems(request.query, sourceSettings, engine);

    if (searchResults.length) {
      const count = searchResults.length;
      const items = searchResults.slice(0, sourceSettings.maxCount || count);
      if (sourceSettings.noHeader) {
        response.items = items;
        response.complete(true);
        return;
      }
      const webSearchHeader: HeaderItem = {
        type: 'header',
        clickable: false,
        origin: 'web-search',
        title: `${engine} search`,
        group: {
          name: 'web-search',
          value: engine,
        },
      };
      response.items = [webSearchHeader, ...items];
    }
    response.complete(true);
  }

  private async getWebSearchItems(query: string, sourceSettings: WebSourceSettings, engine: string): Promise<WebSearchItem[]> {
    const result: WebSearch.Result = await this.webSearchService.search({ engine, query });
    const items: WebSearchItem[] = result?.items.map((s) => {
      return {
        type: 'web-search',
        engine,
        ...s,
      };
    });
    return items;
  }

  getTelemetryEndEvent(response: SearchResponse): Partial<EventInfo>[] {
    return;
  }
}
