import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { isEmbed } from '@local/common-web';
import { EmbedService, EmbedType } from '@shared/embed.service';
import { RouterService } from '@shared/services/router.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HomeGuard {
  private readonly EMBED_TYPES_PREVENT_HOME: EmbedType[] = ['quick-search', 'search-page', 'assistant', 'aux'];
  private readonly isEmbed = isEmbed();

  constructor(private routerService: RouterService, private embedService: EmbedService) {}

  async canActivate(): Promise<boolean | UrlTree> {
    if (this.isEmbed) {
      const opts = await firstValueFrom(this.embedService.options$);
      if (this.EMBED_TYPES_PREVENT_HOME.includes(opts.type)) {
        if (this.embedService.isSearchEmbedType(opts.type)) {
          this.routerService.navigateByUrl('/search', { replaceUrl: true });
        }
        return false;
      }
    }
    return true;
  }
}
