import { Results } from '@local/client-contracts';
import { UiIconModel } from '@local/ui-infra';
import { IconConstants } from '@shared/consts';

export function getDownloadIcon(icon: Results.Icon | Results.FontIcon, iconOverlay?: Results.Icon | Results.FontIcon): Results.Icon {
  if (!iconOverlay) {
    return (icon as Results.Icon)?.lightUrl ? (icon as Results.Icon) : undefined;
  }
  return (iconOverlay as Results.Icon)?.lightUrl
    ? (iconOverlay as Results.Icon)
    : (icon as Results.Icon)?.lightUrl
    ? (icon as Results.Icon)
    : undefined;
}

export function getIconModel(icon: Results.Icon | Results.FontIcon): UiIconModel {
  const fontIcon = icon as Results.FontIcon;
  if (!fontIcon?.name && !icon) return;
  return fontIcon?.name ? { type: 'font', value: fontIcon.name } : { type: 'img', value: icon };
}

export const getSlackIcon = (isPrivate?: boolean) => {
  return isPrivate ? 'icon-private-channel' : 'icon-public-channel';
};

export const getProfileIcon = (img?: string): Results.Icon => {
  return img ? { lightUrl: img, rounded: true } : { ...IconConstants.DEFAULT_AVATAR_MODEL, rounded: true };
};

export const getDefaultProfile = (): string => {
  return IconConstants.DEFAULT_AVATAR;
};
