import { Window } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class WindowRpcInvoker implements Window.Service {
  @invoker
  get properties(): Promise<Window.WindowProperties> {
    return;
  }
  @invoker
  openDevTools(): Promise<void> {
    return;
  }

  @invoker
  get style$(): Observable<Window.WindowStyle> {
    return;
  }

  @invoker
  getInfo(): Promise<Window.WindowInfo> {
    return;
  }

  @invoker
  switchToStandard(): Promise<void> {
    return;
  }

  @invoker
  openNative(u: string): Promise<void> {
    return;
  }

  @invoker
  get minimized$(): Observable<boolean> {
    return;
  }
  @invoker
  get isFullScreen$(): Observable<boolean> {
    return;
  }
  @invoker
  setFullScreen(next: boolean): Promise<void> {
    return;
  }
  @invoker
  setResizable(value: boolean): Promise<void> {
    return;
  }

  @invoker
  get titleBar(): Promise<Partial<Array<Window.Controls>> | null> {
    return;
  }
  @invoker
  get maximized$(): Observable<boolean> {
    return;
  }

  @invoker
  get alwaysOnTop$(): Observable<boolean> {
    return;
  }

  @invoker
  get focused$(): Observable<boolean> {
    return;
  }
  @invoker
  get visible$(): Observable<boolean> {
    return;
  }
  @invoker
  get autoHide$(): Observable<boolean> {
    return;
  }

  @invoker
  get visible(): Promise<boolean> {
    return;
  }
  @invoker
  get size(): Promise<{ width: number; height: number }> {
    return;
  }
  @invoker
  alwaysOnTop(alwaysOnTop?: boolean): Promise<boolean> {
    return;
  }
  @invoker
  autoHide(autoHide?: boolean): Promise<boolean> {
    return;
  }
  @invoker
  resize(width: number, height: number): Promise<void> {
    return;
  }
  @invoker
  close(): Promise<void> {
    return;
  }
  @invoker
  minimize(): Promise<void> {
    return;
  }
  @invoker
  show(): Promise<void> {
    return;
  }
  @invoker
  hide(): Promise<void> {
    return;
  }
  @invoker
  openAuxWindow(s: Window.OpenAuxWindowOptions): Promise<void> {
    return;
  }

  @invoker
  async moveTop(): Promise<void> {
    return;
  }

  @invoker
  async maximize(forcedValue?: boolean): Promise<void> {
    return;
  }
}
