import { Injectable } from '@angular/core';
import { Calculators } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';

@Injectable({
  providedIn: 'root',
})
export class CalculatorsRpcInvoker implements Calculators.Service {
  constructor() {}

  @invoker
  calculate(query: string): Promise<Calculators.Item> {
    return;
  }
}
