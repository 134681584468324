import { Injectable } from '@angular/core';
import { PopupRef, PopupService } from '@local/ui-infra';
import { take } from 'rxjs';
import { ToasterData } from 'src/app/bar/components/toaster/toaster-data';
import { ToasterComponent } from 'src/app/bar/components/toaster/toaster.component';

@Injectable({
  providedIn: 'root',
})
export class ShowToasterService {
  private toasterRef: PopupRef<ToasterComponent, ToasterData>;

  constructor(public popupService: PopupService) {}

  showToaster(data: ToasterData, onDestroy?: () => void) {
    const main = document.querySelector('main') ?? document.querySelector('body');
    const { x } = main.getBoundingClientRect();
    const position = data.position || { left: x + 24, bottom: 24 };
    if (this.toasterRef) {
      this.toasterRef.destroy();
    }
    this.toasterRef = this.popupService.open(position, ToasterComponent, data, { hasBackdrop: false });
    this.toasterRef.destroy$.pipe(take(1)).subscribe(() => {
      if (this.toasterRef) {
        this.toasterRef.destroy();
      }
      if (onDestroy) {
        onDestroy();
      }
    });
    this.toasterRef.compInstance.dismiss.subscribe((id) => {
      this.toasterRef.destroy();
    });
    return this.toasterRef;
  }

  showErrorToaster(id: string, errorText?: string) {
    this.showToaster({
      id,
      title: errorText ?? `Oops.. something went wrong. Please try again! `,
      icon: { type: 'font', value: 'icon-duo-exclamation-circle' },
      iconIntent: 'danger',
    });
  }
}
