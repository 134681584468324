import { Injectable } from '@angular/core';
import { observable } from '@local/common';
import { cloneDeep } from 'lodash';
import { ReplaySubject } from 'rxjs';

type VerificationFyiType = 'collection' | 'card';
export type VerificationFyiData = { title: string; type: VerificationFyiType };
@Injectable({
  providedIn: 'root',
})
export class VerificationsFyiHelperService {
  private _objectsMap: Record<string, VerificationFyiData> = {};
  private _objectsMap$: ReplaySubject<Record<string, VerificationFyiData>> = new ReplaySubject(1);

  @observable
  get objectsMap$(): ReplaySubject<Record<string, VerificationFyiData>> {
    return this._objectsMap$;
  }

  getItemById(id: string) {
    return this._objectsMap[id];
  }

  insertItems(items: { id: string; title: string }[], type: VerificationFyiType) {
    for (const item of items) {
      if (!item?.id) {
        continue;
      }
      this._objectsMap[item.id] = { title: item.title, type };
    }
    this._objectsMap$.next(cloneDeep(this._objectsMap));
  }
}
