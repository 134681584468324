import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { UDropdownComponent } from '@local/ui-infra';
import { TelemetryService } from '@shared/services';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { isEnterKey, KeyName } from '@local/ts-infra';
import { HubService } from 'src/app/bar/services/hub.service';
import { Filter, FilterChangeData } from '../models';
import { OpenFilterData } from '../multi-select-filter-base.component';

@Component({
  selector: 'dropdown-wrapper',
  templateUrl: './dropdown-wrapper.component.html',
  styleUrls: ['./dropdown-wrapper.component.scss'],
})
export class DropdownWrapperComponent implements OnDestroy {
  private _model: Filter;
  private _filterInputValue: string;
  private keyHandlerId: string;
  private _selectedFilterValue: string = '';

  @Input() set selectedFilterValue(value: string) {
    this._selectedFilterValue = value;
  }

  @Input() set filterInputValue(value: string) {
    this._filterInputValue = value;
    if (value) {
      setTimeout(() => {
        if (this.uDropdown.dropdown) {
          this.uDropdown.dropdown.filterValue = value;
          this.uDropdown.dropdown.cd.detectChanges();
        }
      }, 0);
    }
  }

  @Input() set model(val: Filter) {
    this._model = val;
  }

  @Input() set marked({ marked, keys, event }: { marked: boolean; keys: KeyName[]; event: CustomKeyboardEvent }) {
    if (marked) this.registerKeyHandler(keys, event);
    else this.unregisterKeyHandler();
  }

  @Input() addedClass: string;
  @Input() filterPlaceholder: string;
  @Input() appendTo: any = null;
  @Input() open: boolean = false;
  @Input() selectByEnter: boolean = false;
  @Input() hideOnClickOut: boolean = true;

  get model() {
    return this._model;
  }

  get options(): string[] {
    return this.model?.values?.map((v) => v.value);
  }

  get filterInputValue(): string {
    return this._filterInputValue;
  }

  get selectedFilterValue(): string {
    return this._selectedFilterValue;
  }

  @Output() onFilterChange = new EventEmitter<FilterChangeData>();
  @Output() onOpened: EventEmitter<OpenFilterData> = new EventEmitter();

  @ViewChild(UDropdownComponent) uDropdown: UDropdownComponent;

  constructor(
    public cdr: ChangeDetectorRef,
    protected telemetryService: TelemetryService,
    public ref: ElementRef,
    protected keyboardService: KeyboardService,
    protected hubService: HubService
  ) {}

  onPanel(isPanelOpen: boolean) {
    this.onOpened.emit({ opened: isPanelOpen, reset: !this.filterInputValue?.length });
    if (!isPanelOpen && this.selectByEnter) {
      this.onChange();
    }
  }

  private registerKeyHandler(keys: KeyName[], event: CustomKeyboardEvent): void {
    if (this.keyHandlerId) return;
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 8);
    if (keys) this.handleKeys(keys, event);
  }

  private unregisterKeyHandler(): void {
    if (!this.keyHandlerId) return;
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    this.keyHandlerId = null;
  }

  ngOnDestroy(): void {
    this.unregisterKeyHandler();
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent) {
    if (!this.uDropdown) return;

    const key = keys[0];

    if (this.uDropdown.dropdown.overlayVisible) {
      if (key === 'tab') {
        this.togglePanel();
        return;
      }

      if (key === 'escape') {
        this.togglePanel();
        event.stopPropagation();
        return;
      }

      if (key === 'ArrowDown' || key === 'ArrowUp') {
        event.stopPropagation();
        return;
      }
      if (isEnterKey(key)) {
        if (this.selectByEnter) {
          this.onChange();
        }
        event.stopPropagation();
        event.preventDefault();
      }
      event.stopPropagation();
    }

    if (!this.uDropdown.dropdown.overlayVisible && (isEnterKey(key) || key === 'space')) {
      this.togglePanel();
      event.preventDefault();
      event.stopPropagation();
      return;
    }
  }

  onChange() {
    this.onFilterChange.emit({
      name: this.model.name,
      action: 'Set',
      changes: { values: [{ value: this.filterInputValue, filterName: this.model.name, id: this.filterInputValue }] },
      current: { values: [{ value: this.filterInputValue, filterName: this.model.name, id: this.filterInputValue }] },
    });
    this.selectedFilterValue = this.filterInputValue;
    this.cdr.markForCheck();
  }

  filterChanged($event) {
    this.filterInputValue = $event.filter;
  }

  onClearFilter() {
    this.filterInputValue = '';
  }

  togglePanel() {
    this.uDropdown.dropdown.overlayVisible ? this.uDropdown.dropdown.hide() : this.uDropdown.dropdown.show();
    if (!this.uDropdown.dropdown.overlayVisible) {
      this.unregisterKeyHandler();
    }
  }
}
