import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Icon } from '@local/ui-infra';
import { pushTag } from '@shared/analytics';
import { LoaderService } from '@shared/loader.service';
import { AppService } from '@shared/services/app.service';
import { takeUntil } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventsService } from '@shared/services';

@UntilDestroy()
@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent implements OnInit, AfterViewInit {
  icon: Icon = { lightUrl: '/assets/not-found/404-page-light.svg', darkUrl: '/assets/not-found/404-page-dark.svg' };
  url: string;
  constructor(private loaderService: LoaderService, private appService: AppService, private eventsService: EventsService) {
    this.appService.windowStyle$.pipe(untilDestroyed(this)).subscribe((style) => {
      this.url = style != 'standard' ? '/search' : '/';
    });
  }

  ngOnInit() {
    pushTag({
      event: 'ng-pageview',
      pagePath: window.location.href.replace(location.origin, ''),
      pageTitle: 'Page not found',
    });
    this.telemetryEvent();
  }

  ngAfterViewInit(): void {
    this.loaderService.ready$.next(true);
  }

  telemetryEvent() {
    this.eventsService.event('status_view', {
      location: {
        title: 'not-found',
      },
      label: 'page_not_found',
    });
  }
}
