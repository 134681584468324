import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number, quotes?: boolean): any {
    if (value && value.length > limit) {
      return quotes ? '"' + value.substring(0, limit) + '...' + '"' : value.substring(0, limit) + '...';
    }

    return value;
  }
}
