import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIconModule } from '../u-icon/u-icon.module';
import { UInputNumberComponent } from './u-input-number.component';
import { UInputModule } from '../u-input/u-input.module';

@NgModule({
  declarations: [UInputNumberComponent],
  imports: [CommonModule, FormsModule, UInputModule, UIconModule],
  exports: [UInputNumberComponent],
})
export class UInputNumberModule {}
