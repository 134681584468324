import { Collections, Wiki } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class WikiFoldersRpcInvoker implements Wiki.FoldersService {
  @invoker
  get all$(): Observable<Wiki.Folder[]> {
    return;
  }

  @invoker
  update(id: string, actions: Collections.UpdateAction[]): Promise<void> {
    return;
  }

  @invoker
  refresh(): Promise<string> {
    return;
  }
}
