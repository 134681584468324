import { observable, SearchCanceledError } from '@local/common';
import { Observable, ReplaySubject, takeUntil } from 'rxjs';
import { SearchService } from '../search.service';
import { SourceResultItems } from '../models/source-results-items.model';
import { SearchSourceType } from './search-source-type';

export class SearchResponse {
  private _extra: any;
  private _error: any;
  private _items: SourceResultItems;
  private _update$ = new ReplaySubject<void>(1);
  private _done: boolean;
  private _duration: number;
  private _start: number;

  get cancelled() {
    return !this.searchService.isSearchAlive(this.sessionName, this.id);
  }

  get extra() {
    this.throwIfCancelled();
    return this._extra;
  }

  set extra(extra: any) {
    this.throwIfCancelled();
    this._extra = extra;
  }

  get error() {
    this.throwIfCancelled();
    return this._error;
  }

  set error(error: any) {
    this.throwIfCancelled();
    this._error = error;
  }

  get items() {
    this.throwIfCancelled();
    return this._items;
  }

  get totalCount() {
    return this._items?.length;
  }

  set items(items: SourceResultItems) {
    this.throwIfCancelled();
    this._items = items;
  }

  get done() {
    return this._done;
  }

  private set done(done: boolean) {
    this._done = done;
  }

  get duration() {
    return this._duration;
  }

  @observable
  get update$() {
    return this._update$.pipe(takeUntil(this.destroy$));
  }

  constructor(
    private searchService: SearchService,
    private id: number,
    private sessionName: string,
    private type: SearchSourceType,
    private destroy$: Observable<void>
  ) {
    this._start = Date.now();
  }

  notifyUpdated() {
    this.throwIfCancelled();
    this._update$.next();
  }

  complete(notify?: boolean) {
    this.done = true;
    if (!this._duration) {
      this._duration = Date.now() - this._start;
    }
    if (notify) {
      this.notifyUpdated();
    }
  }

  clone() {
    return new SearchResponse(this.searchService, this.id, this.sessionName, this.type, this.destroy$);
  }

  private throwIfCancelled() {
    if (this.cancelled) {
      throw new SearchCanceledError(`Search Cancelled - ${this.type}`);
    }
  }
}
