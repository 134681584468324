<ng-container *ngFor="let v of displayValues; trackBy: trackItem">
  <div
    class="spread-filter-item"
    (click)="select(v)"
    *ngIf="v"
    [ngClass]="{
      selected: selectedItems.includes(v.value),
      disabled: v.disabled,
      'spread-filter-item-title': !model.viewDetails?.showItemIcon
    }"
    [pTooltip]="v.value"
    tooltipPosition="bottom"
    tooltipStyleClass="u-tooltip"
    [id]="v.value"
    #option
  >
    <entity-icon *ngIf="model.viewDetails?.showItemIcon" class="spread-filter-item-icon" [model]="v.icon" [size]="'16px'"></entity-icon>
    <div
      *ngIf="!model.viewDetails?.showItemIcon"
      class="spread-filter-item-title"
      [attr.data-cy]="'spread-filter-item-' + v.value"
      [class.limit-length]="v.data?.showLabelOnTag"
    >
      {{ v.value }}
    </div>
  </div>
</ng-container>
<div
  #moreValues
  *ngIf="displayMoreButton"
  class="spread-filter-item"
  (click)="onMoreClicked()"
  tooltipPosition="bottom"
  pTooltip="More"
  tooltipStyleClass="u-tooltip"
>
  <u-icon [model]="{ type: 'font', value: 'icon-chevron-down' }" [styles]="{ fontSize: '12px' }"></u-icon>
</div>
