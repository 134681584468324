import { MainRoute } from '../../bar/routes/main-routes-config';

export const getRouteById = (id: string, routes: MainRoute[] | null | undefined): MainRoute => {
  if (!routes) {
    return;
  }

  for (const route of routes) {
    if (route?.data?.id === id) {
      return route;
    }
    if (route.children) {
      const r = getRouteById(id, route.children);
      if (r) return r;
    }
  }
};
