import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, HostBinding, Input } from '@angular/core';
@Component({
  selector: 'smooth-height',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: block;
        /* Cause an issue with overflow items like dropdown / context menu and etc. */
        overflow: hidden;
      }
    `,
  ],
  animations: [
    trigger('grow', [
      transition('void <=> *', []),
      transition('* <=> *', [style({ height: '{{startHeight}}px' }), animate('.1s ease')], { params: { startHeight: 0 } }),
    ]),
  ],
})
export class SmoothHeightComponent {
  startHeight: number;
  private _trigger: string;
  private _panelVisible: boolean;

  @Input() set trigger(value: string) {
    this._trigger = value;
    this.setStartHeight();
  }

  @Input() set panelVisible(value: boolean) {
    if (!value) return;
    this._panelVisible = value;
    this.setStartHeight();
  }

  get trigger() {
    return this._trigger;
  }

  get panelVisible() {
    return this._panelVisible;
  }

  constructor(private element: ElementRef) {}

  @HostBinding('style.overflow') get overflow() {
    return this.panelVisible ? 'hidden' : null;
  }

  @HostBinding('@grow') get grow() {
    return { value: this.trigger, params: { startHeight: this.startHeight } };
  }

  setStartHeight() {
    this.startHeight = this.element.nativeElement.clientHeight;
  }
}
