import { Component, Input } from '@angular/core';
import { Intent } from 'src/app/bar/views/hub/shared/model';

@Component({
  selector: 'chase-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() isActive: boolean;
  @Input() intent: Intent;
  @Input() outlineMode: 'permanent' | 'hover' | 'disabled' | 'on-active';
}
