import { Collections } from '@local/client-contracts';

export type CollectionRouteType = 'collections' | 'wikis';

export const COLLECTIONS_ROUTE_TYPES: CollectionRouteType[] = ['collections', 'wikis'];

export type CollectionRouteToKind = {
  [key in CollectionRouteType]: Collections.Kind[];
};

export const COLLECTION_ROUTE_TO_KIND_MAP: CollectionRouteToKind = {
  collections: ['Live', 'Static'],
  wikis: ['Wiki'],
};
