import { EventInfo } from '@shared/services';
import { SearchClient, SearchRequest, SearchResponse, SearchResponseType } from '..';
import { QuestionnaireSourceSettings } from './questionnaire-source-settings';
import { RfpSearchItem } from 'src/app/bar/views';
import { QuestionnaireService } from '../../../questionnaire.service';

type RfpStatus = 'active' | 'new';

export class QuestionnaireSearchClient implements SearchClient<QuestionnaireSourceSettings> {
  private readonly MATCH_QUERIES: string[] = ['rfp', 'fill rfp'];

  constructor(private questionnaireService: QuestionnaireService) {}

  search(request: SearchRequest<QuestionnaireSourceSettings>, response: SearchResponse): SearchResponseType {
    return this.innerSearch(request, response);
  }

  private async innerSearch(request: SearchRequest<QuestionnaireSourceSettings>, response: SearchResponse) {
    if (!this.MATCH_QUERIES.includes(request.query.toLowerCase())) {
      response.complete(true);
      return;
    }
    const rfpProcessId: string = await this.questionnaireService.getCurrentProcessId();
    const rfpStatus: RfpStatus = rfpProcessId ? 'active' : 'new';
    const item: RfpSearchItem = {
      type: 'static-search-item',
      title: rfpStatus === 'active' ? 'RFP is already in process...' : 'Fill RFP',
      description: rfpStatus === 'active' ? 'Click to open...' : 'Complete automatically RFPs, security questionnaires and more',
      processId: rfpProcessId,
      invokeType: 'fill-questionnaire',
      icon: { type: 'font-icon', value: 'icon-answer' },
    };
    response.items = [item];
    response.complete(true);
    return;
  }

  nextPage(): Promise<void> {
    return;
  }

  destroy(): void {
    return;
  }

  supportsFilters(): boolean {
    return true;
  }

  supportsSort(): boolean {
    return true;
  }

  getTelemetryEndEvent(): Partial<EventInfo>[] {
    return;
  }
}
