import { ChangeDetectorRef, Directive, ElementRef, Input, OnInit } from '@angular/core';
import * as uuid from 'uuid';

@Directive({
  selector: '[ghost]',
})
export class GhostDirective implements OnInit {
  private readonly GHOST_ID = 'ghostByDirective';
  private id: string;
  private overlay: HTMLDivElement;
  private hostEl: HTMLElement;

  private _initialDisplay: string;
  get initialDisplay(): string {
    return this._initialDisplay;
  }
  set initialDisplay(value: string) {
    if (!this.initialDisplay) {
      this._initialDisplay = value;
    }
  }

  private _ghostStyle: any;
  get ghostStyle(): any {
    return this._ghostStyle;
  }
  @Input()
  set ghostStyle(value: any) {
    this._ghostStyle = value;
    if (this.overlay) {
      this.initGhostStyle();
    }
  }

  private _ghostUntil: boolean;
  get ghostUntil(): boolean {
    return this._ghostUntil;
  }
  @Input()
  set ghostUntil(value: boolean) {
    if (value === undefined) return;
    this._ghostUntil = value;
    if (value) {
      this.toggleGhost(false);
    }
  }

  constructor(private el: ElementRef, private cdr: ChangeDetectorRef) {
    this.hostEl = this.el.nativeElement;
    this.initialDisplay = this.hostEl.style.display || 'block';
    this.id = `${this.GHOST_ID}-${uuid.v4()}`;
  }

  ngOnInit(): void {
    this.createOverlayGhost();
    this.toggleGhost(true);
  }

  private createOverlayGhost() {
    if (this.overlay) return;
    this.overlay = document.createElement('div');
    this.overlay.id = this.id;
    this.overlay.classList.add('ghost-basic');
    this.initGhostStyle();
  }

  private initGhostStyle() {
    for (const key of Object.keys(this.ghostStyle || {})) {
      this.overlay.style[key] = this.ghostStyle[key];
    }
    if (!this.ghostStyle?.width && !this.ghostStyle?.height) {
      this.overlay.style.width = this.el.nativeElement.offsetWidth + 'px';
      this.overlay.style.height = this.el.nativeElement.offsetHeight + 'px';
    }
  }

  private toggleGhost(showGhost: boolean) {
    if (!this.hostEl) return;
    if (showGhost) {
      this.hostEl.style.display = 'none';
      this.hostEl.insertAdjacentElement('beforebegin', this.overlay);
    } else {
      setTimeout(() => {
        this.hostEl.style.display = this.initialDisplay;
      }, 0);
      setTimeout(() => {
        const overlayEl = document.getElementById(this.id);
        overlayEl?.remove();
      }, 0);
    }
    this.cdr.markForCheck();
  }
}
