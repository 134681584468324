import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { Config } from '@environments/config';
import { Constants } from '@local/common';

@Component({
  selector: 'worker-change-popup',
  templateUrl: './worker-change-popup.component.html',
  styleUrls: ['./worker-change-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkerChangePopupComponent {
  @Output() dismiss = new EventEmitter();
  url = Constants.getReleaseNoteURL(Config.version, Config.autoUpdate.ignoreBannerVersions);

  whatsNew() {
    window.open(this.url, '_blank');
    this.reload();
  }

  reload() {
    location.reload();
  }

  onDismiss() {
    this.dismiss.emit();
  }
}
