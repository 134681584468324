import { Assistants, Filters, Search } from '@local/client-contracts';
import { EventInfo, LogService } from '@shared/services';
import { AssistantsService } from '@shared/services/assistants.service';
import { Logger } from '@unleash-tech/js-logger';
import { Subscription } from 'rxjs';
import {
  AssistantTicketResultType,
  getAssistantTicketResultTitle,
} from 'src/app/bar/views/assistant-incontext/models/assistant-ticket-result-type.enum';
import { AnswerSearchItem, TelemetryTrigger, TimeActionItem } from 'src/app/bar/views/results/models/results-types';
import { ResultMarkdownService } from '../../../result-markdown.service';
import { ResultsService } from '../../../results.service';
import { SearchClient } from '../search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { SearchResponseType } from '../search-response-type';
import { AssistantIncontextExtraData } from './assistant-incontext-extra-data';
import { AssistantIncontextSourceSettings } from './assistant-incontext-source-settings';
import { AnswerResourcesService } from '../../../answer-resources.service';
import { AnswerSearchService } from '../../../answers-search.service';

export class AssistantIncontextSearchClient implements SearchClient<AssistantIncontextSourceSettings> {
  private logger: Logger;
  private instances: { [sessionName: string]: Subscription } = {};

  constructor(
    logService: LogService,
    private assistantService: AssistantsService,
    private resultMarkdownService: ResultMarkdownService,
    private resultsService: ResultsService,
    private answerResourcesService: AnswerResourcesService,
    private answerSearchService: AnswerSearchService
  ) {
    this.logger = logService.scope('answers');
  }

  supportsSort(_sort: Search.Sort): boolean {
    return true;
  }

  supportsFilters(_filters: Filters.Values): boolean {
    return true;
  }

  search(request: SearchRequest<AssistantIncontextSourceSettings>, response: SearchResponse): SearchResponseType {
    const subscription = this.instances[request.sessionName];
    if (subscription) {
      subscription.unsubscribe();
      this.instances[request.sessionName] = null;
    }
    return this.innerSearch(request, response);
  }

  nextPage(
    _request: SearchRequest<AssistantIncontextSourceSettings>,
    _response: SearchResponse,
    _trigger: TelemetryTrigger
  ): Promise<void> {
    return;
  }

  destroy(_id: number, sessionName: string): void {
    const subscription = this.instances[sessionName];
    if (subscription) {
      subscription?.unsubscribe();
      delete this.instances[sessionName];
    }
    this.answerResourcesService.clearResources();
  }

  private async innerSearch(request: SearchRequest<AssistantIncontextSourceSettings>, response: SearchResponse) {
    if (!request.sourceSettings.data.ticket?.description && !request.sourceSettings.data.ticket?.messages?.length) {
      response.items = [];
      response.complete(true);
      return;
    }
    const extraData: AssistantIncontextExtraData[] = Object.entries(AssistantTicketResultType).map((e) => ({
      name: e[0],
      title: getAssistantTicketResultTitle(request.sourceSettings.data.type, e[0], e[1]),
      totalResults: 0,
    }));
    try {
      const ticket$ = await this.assistantService.ticket$(request.sourceSettings.data);
      if (response.cancelled) {
        return;
      }
      this.instances[request.sessionName] = ticket$.subscribe({
        next: async (context: Assistants.AssistantTicketContext) => {
          if (response.cancelled) {
            return;
          }
          if (context.performance) {
            response.extra = {
              performance: context.performance,
              searchId: context.id,
            };
          }

          if (!context.response) {
            return;
          }

          extraData.push({ name: 'Answer', title: 'Resolution', results: [] });
          const answer = await this.addAnswerResult(context.response, request.sourceSettings.data?.ticket?.title);
          if (answer) {
            const resolutionTimeItem = this.addTimeResolutionResult(context.response);
            const answerExtraData = extraData.find((e) => e.name === 'Answer');
            if (answerExtraData) {
              answerExtraData.results.push(answer, resolutionTimeItem);
            }
          }

          if (response.cancelled) {
            return;
          }
          context.response.results.forEach((result) => {
            const typeExtraData = extraData.find((e) => e.name === result.name);
            if (typeExtraData) {
              typeExtraData.results = result.results;
              typeExtraData.totalResults = result.results?.length || 0;
            }
          });

          this.mergeConversationsIntoKnowledge(extraData);

          response.extra = {
            ...(response.extra || {}),
            extraData,
            searchId: context.id,
          };

          response.complete(true);
        },
        error: (ex) => {
          this.logger.error('got error in assistant incontext search client', ex);
          response.error = ex;
          response.complete(true);
        },
      });
    } catch (ex) {
      this.logger.error('got error in assistant incontext search client', ex);
      response.error = ex;
      response.complete(true);
    }
  }

  private mergeConversationsIntoKnowledge(extraData: AssistantIncontextExtraData[]) {
    const knowledgeBase = extraData.find((data) => data.name === 'KnowledgeBase');
    const conversations = extraData.find((data) => data.name === 'Conversations');

    if (knowledgeBase && conversations && conversations.totalResults > 0) {
      knowledgeBase.results.push(...conversations.results);
      knowledgeBase.totalResults = knowledgeBase.results.length;
    }

    extraData = extraData.filter((data) => data.name !== 'Conversations');
  }

  private async addAnswerResult(res: Assistants.AssistantTicketResponse, query: string): Promise<AnswerSearchItem> {
    if (!res.federatedAnswer?.answer) {
      return;
    }
    const flatResults = res.results.map((r) => r.results).flat();
    let items = [];
    if (res.federatedAnswer.resourceIds?.length) {
      const uniqueIds = new Set();
      items = flatResults
        ?.filter((r) => {
          if (res.federatedAnswer.resourceIds.includes(r.id) && !uniqueIds.has(r.id)) {
            uniqueIds.add(r.id);
            return true;
          }
          return false;
        })
        ?.slice(0, 12);
    }
    for (const item of items) {
      item.action = await this.resultsService.getResultAction(item);
    }
    this.answerResourcesService.resources = items;

    let answerText = res.federatedAnswer.answer;
    if (res.federatedAnswer?.references) {
      answerText = this.answerSearchService.insertReferencesIntoText(res.federatedAnswer, items);
    }

    const answer: AnswerSearchItem = {
      type: 'answer',
      resources: items,
      text: res.federatedAnswer.answer,
      state: 'Full',
      query,
      formattedAnswer: this.resultMarkdownService.render(
        answerText,
        res.federatedAnswer?.references.length ? { items, sourceType: 'result-answer' } : null
      ),
      debugInfo: res.debugInfo,
      searchId: res.searchId,
    };

    return answer;
  }

  private addTimeResolutionResult(res: Assistants.AssistantTicketResponse) {
    const time: TimeActionItem = {
      type: 'time-action',
      actionButton: { buttonIcon: 'sync', buttonTooltip: 'Refresh', buttonAction: 'refresh' },
      time: res.resolutionTime,
      timeText: 'Last updated',
      selectable: false,
    };
    return time;
  }

  getTelemetryEndEvent(_response: SearchResponse): Partial<EventInfo>[] {
    return;
  }
}
