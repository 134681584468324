import { Commands, LocalActions } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class LocalActionsRpcInvoker implements LocalActions.Service {
  constructor() {}

  @invoker
  get all$(): Observable<LocalActions.LocalActionItem[]> {
    return;
  }

  @invoker
  getActionUrl(actionId: string): Promise<Commands.OpenUrlParams> {
    return;
  }
}
