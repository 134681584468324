import { isNativeWindow } from '@local/common-web';

export type WorkspaceCustomColorProperty =
  | 'primary-custom-color'
  | 'primary-custom-text-color'
  | 'sidebar-custom-color'
  | 'sidebar-custom-text-color'
  | 'sidebar-custom-color-theme';

export type ColorPickersIds = 'primary-color' | 'primary-text-color' | 'sidebar-bg-color' | 'sidebar-text-color';

export type CustomColorNames =
  | '--color-primary'
  | '--color-primary-btn-text'
  | '--side-bar-color-bg-secondary'
  | '--side-bar-color-text-secondary'
  | '--side-bar-color-text-tertiary';

export type CustomGeneratedColorItem = {
  colorName: CustomColorNames;
  defaultColor: DefaultColor;
};

export type CustomColorItem = {
  colorName: CustomColorNames;
  colorFormat: 'hex' | 'rgb';
  createRgbVersion?: boolean;
  defaultColor: DefaultColor;
  rootPropertyName?: WorkspaceCustomColorProperty;
  type?: 'primary' | 'secondary';
  subtype?: 'background' | 'foreground';
  generatedColor?: CustomGeneratedColorItem;
};

type DefaultColor = {
  dark: string;
  light: string;
};

export type CustomColorsMap = {
  [key in ColorPickersIds]: CustomColorItem;
};

const colorPrimary = '#be8df6';
const colorWhite = '#fbfcfd';
const colorGray50 = '#f4f7f7';
const colorGray300 = '#aeadad';
const colorGray400 = '#797979';
const colorGray450 = '#524d4d';
const colorGray800 = '#252525';

export const workspaceCustomColorsMap: CustomColorsMap = {
  'primary-color': {
    colorName: '--color-primary',
    colorFormat: 'hex',
    createRgbVersion: true,
    rootPropertyName: 'primary-custom-color',
    defaultColor: {
      dark: colorPrimary,
      light: colorPrimary,
    },
    type: 'primary',
    subtype: 'background',
  },
  'primary-text-color': {
    colorName: '--color-primary-btn-text',
    colorFormat: 'hex',
    rootPropertyName: 'primary-custom-text-color',
    defaultColor: {
      dark: colorWhite,
      light: colorWhite,
    },
    type: 'primary',
    subtype: 'foreground',
  },
  'sidebar-bg-color': {
    colorName: '--side-bar-color-bg-secondary',
    colorFormat: isNativeWindow() ? 'rgb' : 'hex',
    rootPropertyName: 'sidebar-custom-color',
    defaultColor: {
      dark: colorGray800,
      light: colorGray50,
    },
    type: 'secondary',
    subtype: 'background',
  },
  'sidebar-text-color': {
    colorName: '--side-bar-color-text-secondary',
    colorFormat: 'hex',
    rootPropertyName: 'sidebar-custom-text-color',
    defaultColor: {
      dark: colorGray300,
      light: colorGray450,
    },
    type: 'secondary',
    subtype: 'foreground',
    generatedColor: {
      colorName: '--side-bar-color-text-tertiary',
      defaultColor: {
        dark: colorGray400,
        light: colorGray400,
      },
    },
  },
};
