import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'powered-by',
  templateUrl: './powered-by.component.html',
  styleUrls: ['./powered-by.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PoweredByComponent {
  @Input() displayBackground: boolean;
}
