import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { delay } from '@local/ts-infra';
import { UntilDestroy } from '@ngneat/until-destroy';

export type TypingViewMode = 'interactive' | 'static';

@UntilDestroy()
@Component({
  selector: 'u-typing-typography',
  templateUrl: './u-typing-typography.component.html',
  styleUrls: ['./u-typing-typography.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UTypingTypographyComponent {
  private readonly MAX_TYPING_DELAY = 20;
  private readonly MIN_TYPING_DELAY = 10;

  private _text: string;
  private typeEffectStarted = false;
  private _viewMode: TypingViewMode = 'interactive';

  @Input() set viewMode(value: TypingViewMode) {
    if (this.viewMode && value !== this.viewMode) {
      this.resetContainer();
    }
    this._viewMode = value;
  }
  @Input() disableTyped = false;
  @Input() set text(value: string) {
    if (this._text && value && value !== this._text && this.isTypingMode) {
      this.resetContainer();
      this.typeEffectStarted = false;
    }
    this._text = value;
    this.handleTextUpdate();
  }
  @Output() typedFinished = new EventEmitter<void>();
  @Output() onTyping = new EventEmitter<void>();

  @ViewChild('typingContainer', { static: true }) typingContainerRef: ElementRef<HTMLElement>;

  private get isTypingMode() {
    return this.viewMode === 'interactive';
  }

  get viewMode(): TypingViewMode {
    return this._viewMode;
  }

  get text(): string {
    return this._text;
  }

  get isStaticMode() {
    return this.viewMode === 'static';
  }

  constructor(private cdr: ChangeDetectorRef) {}

  private async handleTextUpdate() {
    if (!this.text) return;

    if (this.isStaticMode) {
      this.onTyping.emit();
      return;
    }

    if (this.disableTyped) {
      await this.updateContainer();
      return;
    }

    await this.typingEffect();
  }

  private async typingEffect(): Promise<void> {
    if (this.typeEffectStarted) return;
    this.typeEffectStarted = true;
    await this.typeTextContent();
    this.typeEffectStarted = false;
    this.typedFinished.emit();
    this.cdr.markForCheck();
  }

  private async typeNode(node: Node, container: HTMLElement) {
    if (node.nodeType === Node.TEXT_NODE) {
      await this.typeTextNode(node, container);
      return;
    }
    const element = node.cloneNode() as HTMLElement;
    container.appendChild(element);
    for (const child of Array.from(node.childNodes)) {
      this.onTyping.emit();
      await this.typeNode(child, element);
    }
  }

  private async typeTextNode(node: Node, container: HTMLElement) {
    const words = node.textContent.split(/(\s+)/);
    for (const word of words) {
      container.innerHTML += word;
      const typingDelay = this.getRandomTypingDelay();
      await delay(typingDelay);
    }
  }

  private getRandomTypingDelay(): number {
    const range = this.MAX_TYPING_DELAY - this.MIN_TYPING_DELAY;
    return Math.floor(Math.random() * range) + this.MIN_TYPING_DELAY;
  }

  private resetContainer() {
    if (this.typingContainerRef) {
      this.typingContainerRef.nativeElement.innerHTML = '';
    }
  }

  private async updateContainer() {
    this.resetContainer();
    await this.typeTextContent(true, false);
    this.cdr.markForCheck();
  }

  private async typeTextContent(clean = false, typed = true): Promise<void> {
    try {
      await this.parseAndTypeNodes(clean, typed);
    } catch (error) {
      this.typedFinished.emit();
    }
  }

  private async parseAndTypeNodes(clean = false, typed = true) {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(this.text, 'text/html');
    for (const node of Array.from(htmlDoc.body.childNodes)) {
      if (typed) {
        await this.typeNode(node, this.typingContainerRef.nativeElement);
      } else {
        this.typingContainerRef.nativeElement.appendChild(clean ? node.cloneNode(true) : node);
      }
    }
  }
}
