import { Injectable } from '@angular/core';
import { Commands, NavTree } from '@local/client-contracts';
import { observable } from '@local/common';
import { ServicesRpcService } from '@shared/services';
import { ApplicationsService } from '@shared/services/applications.service';
import { Breadcrumb } from '@shared/services/breadcrumbs.service';
import { RouterService } from '@shared/services/router.service';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NavTreeRpcInvoker } from './invokers/nav-tree.rpc-invoker';
import { SearchPopupItem } from '../views/special-views/search-popup/model';
import { delay } from '@local/ts-infra';
import { isEqual } from 'lodash';

@Injectable()
export class NavTreeService {
  private service: NavTree.Service;
  private subs: Record<string, Subscription> = {};
  nodes: Record<string, NavTree.Node> = {};
  roots = new Set<string>();
  _roots$ = new ReplaySubject<NavTree.Node[]>(1);
  private _all$ = new BehaviorSubject<NavTree.Node[]>([]);
  private _currentNode$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  private prefixResolver: { [prefix: string]: (nodeId: string, nodes: NavTree.Node[]) => Observable<NavTree.Node> } = {};
  readonly collection_id_length: number = 36;

  @observable
  get currentNode$(): Observable<any> {
    return this._currentNode$.asObservable();
  }

  set currentNode(node) {
    this._currentNode$.next(node);
  }

  get all$() {
    return this._all$;
  }

  get currentNode() {
    return this._currentNode$.value;
  }

  get activeNode() {
    return this.currentNode?.id;
  }

  @observable
  get roots$(): Observable<NavTree.Node[]> {
    return this._roots$.pipe(tap((v) => v.forEach((n) => (this.nodes[decodeURIComponent(n.id)] = n))));
  }

  set _roots(roots: NavTree.Node[]) {
    this._roots$.next(roots);
  }

  constructor(services: ServicesRpcService, private applicationsService: ApplicationsService, private routerService: RouterService) {
    this.service = services.invokeWith(NavTreeRpcInvoker, 'navTree');

    this.service.children$().subscribe((roots) => {
      this.updateNodeCache(roots);
      roots.forEach((r) => this.roots.add(r.id));
      this._roots = roots;
    });

    let nextId = 0;

    this.all$.subscribe(async (nodes) => {
      // prevent race where routeService is not set
      const nid = ++nextId;
      while (!this.routerService.active) await delay(50);

      if (nextId != nid) return;

      if (this.currentNode && nodes?.length) {
        const currentNode = nodes.find((n) => n.id === this.currentNode.id);
        if (currentNode) {
          this.currentNode = currentNode;
        }
      }
      if (!this.currentNode) {
        let nodeId = this.routerService.active;
        nodeId = nodeId.split('?')[0];
        if (!this.nodes[nodeId]) {
          for (const [prefix, resolver] of Object.entries(this.prefixResolver)) {
            if (!nodeId.startsWith(prefix)) {
              continue;
            }
            resolver(nodeId, nodes).subscribe((newNode) => {
              if (newNode) {
                this.currentNode = newNode;
              }
            });
          }
        } else {
          this.currentNode = this.nodes[nodeId];
        }
      }
    });

    this.routerService.data$.subscribe((d) => {
      const node = (<any>d)?.node || this.nodes[this.routerService.active];

      if (isEqual(node, this.currentNode)) {
        return;
      }

      this.currentNode = node;
    });
  }

  refreshCurrentNodeWithSameValue() {
    //this will trigger the observer with same value
    this.currentNode = this.currentNode;
  }

  async get(nodeId: string): Promise<NavTree.Node> {
    nodeId = decodeURIComponent(nodeId);
    if (!this.nodes[nodeId]) this.nodes[nodeId] = await this.service.get(nodeId);

    return this.nodes[nodeId];
  }

  async getAncestors(nodeId: string): Promise<NavTree.Node[]> {
    const anc = [];
    let node = await this.get(decodeURIComponent(nodeId));
    while (node?.parentId && typeof node.parentId === 'string') {
      node = await this.get(node.parentId);
      anc.unshift(node);
    }
    return anc;
  }

  registerPrefixResolver(prefix: string, resolver: (nodeId: string, nodes: NavTree.Node[]) => Observable<NavTree.Node>) {
    this.prefixResolver[prefix] = resolver;
  }

  unsubscribe(id: string) {
    if (!this.subs[id]) return;
    this.subs[id].unsubscribe();
    delete this.subs[id];
  }

  unsubscribeAll() {
    if (!this.subs) return;

    Object.values(this.subs).forEach((s) => s.unsubscribe());
    this.subs = {};
  }

  private updateNodeCache(nodes: NavTree.Node[]) {
    const newNodes = {};
    const inner = (nodes: NavTree.Node[]) => {
      for (const n of nodes) {
        // TODO: remove from cache all node ids of this child

        if (n.children) {
          inner(n.children);

          if (this.subs[n.id])
            // the children of this node are not dynamic so no need to have subscription anymore
            this.unsubscribe(n.id);
        }
        let newNodesKey;
        try {
          newNodesKey = decodeURIComponent(n.id);
        } catch (e) {
          newNodesKey = n.id;
        }
        newNodes[newNodesKey] = n;
      }
    };
    inner(nodes);
    this.nodes = newNodes;
    this._all$.next(Object.values(this.nodes));
  }

  async getChildren(node: NavTree.Node): Promise<NavTree.Node[]> {
    const children$ = this.service.children$(node.id);
    return new Promise((res) => {
      this.subs[node.id] = children$.subscribe((children) => {
        this.updateNodeCache(children);
        res(children);
      });
    });
  }

  getNodeUrl(node: NavTree.Node['data']) {
    if (!node) return;
    const url: string = node?.data?.url;

    return url || node.id.replace(/\(/g, '%28').replace(/\)/g, '%29');
  }

  async nodeToSearchPopupItem(node: NavTree.Node): Promise<SearchPopupItem<'child'>> {
    const { id, title, type, data } = node;
    let { icon } = node;
    const breadcrumbs = Object.keys(data?.filters ?? {}).length > 1 ? await this.nodeToBreadcrumbs(node) : null;

    // TODO: resource icon fallback
    if (!icon && data?.filters?.app?.length) {
      icon = { type: 'img', value: Object.values(this.applicationsService.apps).find((a) => a.name === data?.filters?.app[0])?.icon };
    }

    const titleAndSubtitleEqual = breadcrumbs?.length === 1 && breadcrumbs[0].title === title;
    const subtitle: Breadcrumb[] = titleAndSubtitleEqual ? null : breadcrumbs;

    return {
      type: 'child',
      id,
      icon,
      title,
      subtitle,
      command: { type: 'open-page', url: this.getNodeUrl(node) } as Commands.OpenPageCommand,
      visibility: this.getVisibilityToSearchPopupItem(id, type === 'standard'),
    };
  }

  getVisibilityToSearchPopupItem(id: string, isStandardType: boolean) {
    return this.roots.size === 0 || (this.roots.has(id) && isStandardType) ? 'always' : 'search-only';
  }

  async nodeToBreadcrumbs(node: NavTree.Node): Promise<Breadcrumb[]> {
    const anc: NavTree.Node[] = await this.getAncestors(node.id);
    const isHeader = (n) => n.headerNode && !n.showInBreadcrumbs;
    const res = [...anc.filter((n) => n.type !== 'root' && !isHeader(n)), node].map((n) => ({
      path: n.id,
      title: n.title,
      icon: n.inlineIcon ?? n.icon,
      emoji: n.data?.collection?.emoji ?? null,
      notClickable: n.headerNode && n.showInBreadcrumbs,
    }));
    return res;
  }
}
