<div class="avatar-popup">
  <div
    data-cy="avatar-popup-email"
    *ngIf="avatarPopupData.title"
    class="avatar-popup-email"
    tooltipPosition="right"
    [pTooltip]="avatarPopupData.title.length >= 18 ? avatarPopupData.title : null"
    tooltipStyleClass="u-tooltip hide"
  >
    {{ avatarPopupData.title | truncate: 18 }}
  </div>
  <ng-scrollbar [style.height.px]="scrollHeight">
    <cdk-virtual-scroll-viewport ngScrollbarView smoothScroll scrollViewport [itemSize]="itemSize">
      <ng-container *ngFor="let item of scrollItems; index as i">
        <div #popupOptionScrollItem class="item" [ngClass]="{ selected: selectedIndex === i }">
          <div (click)="onInvoke(item.item)" data-cy="avatar-popup-item" [id]="item.item.label">
            <u-icon data-cy="avatar-popup-icon" [styles]="{ width: '10px' }" class="option-icon" [model]="item.item.icon"></u-icon>
            <span>{{ item.item.label }}</span>
          </div>
        </div>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </ng-scrollbar>
</div>
