import { Component, Input } from '@angular/core';

@Component({
  selector: 'new-indication',
  templateUrl: './new-indication.component.html',
  styleUrls: ['./new-indication.component.scss'],
})
export class NewIndicationComponent {
  @Input() tooltip: string;
}
