import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom, filter, Subject, pipe, map, distinctUntilChanged } from 'rxjs';
import { observable } from '@local/common';
import { HubService } from './hub.service';
import { HomeTabsService } from './home-tabs.service';
import { HomeTabs } from '@local/client-contracts';
import { RouterService } from '@shared/services/router.service';

export enum HomeComponentFocused {
  SEARCH_INPUT = 1,
  TABS = 2,
  WIDGETS = 3,
  BROWSER_BAR = 4,
}

export type ComponentFocused = { step: 'next' | 'prev' | 'current'; live: HomeComponentFocused };
export type MethodsList = { id: string; fn: () => void }[];
@Injectable()
export class HomePageService {
  private _autoFocus$: BehaviorSubject<boolean>;
  private _triggerComponentFocus$ = new Subject<ComponentFocused>();
  private _stickyHeader$ = new Subject<boolean>();
  private _stickyHeader: boolean;
  private _componentFocus: HomeComponentFocused;
  keyboardComponentFocus$ = new Subject<ComponentFocused>();

  private readonly UNSAFE_CHARS_PATTERN = /[!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|`~\s]/g;

  get autoFocus(): boolean {
    return this._autoFocus$.value;
  }

  @observable
  public get autoFocus$(): Observable<boolean> {
    return this._autoFocus$;
  }

  @observable
  get triggerComponentFocus$() {
    return this._triggerComponentFocus$.asObservable();
  }

  @observable
  get stickyHeader$(): Observable<boolean> {
    return this._stickyHeader$;
  }

  get stickyHeader(): boolean {
    return this._stickyHeader;
  }

  set stickyHeader(value: boolean) {
    this._stickyHeader = value;
    this._stickyHeader$.next(value);
  }

  public set autoFocus(v: boolean) {
    this._autoFocus$.next(v);
  }

  get currentTabId$(): Observable<string> {
    return this.hubService.state$.pipe(
      map((s) => s.t?.[0]?.split('-')?.[0]),
      distinctUntilChanged()
    );
  }

  private get currentTab(): string {
    return this.hubService.getState('t')[0];
  }

  private set currentTab(tab: string) {
    this.hubService.setState('t', tab);
  }

  get currentTabId(): string {
    return this.currentTab?.split('-')?.[0];
  }

  get componentFocus() {
    return this._componentFocus || HomeComponentFocused.SEARCH_INPUT;
  }

  buildTabAsUrlParam(tab: HomeTabs.HomeTab) {
    let tabAsUrlParam = tab.id;
    if (tab.id !== this.hubService.HOME_TAB_ID) {
      tabAsUrlParam += `-${tab.name.replace(this.UNSAFE_CHARS_PATTERN, '-')}`;
    }
    return tabAsUrlParam;
  }

  setCurrentTab(tab: HomeTabs.HomeTab) {
    this.currentTab = this.buildTabAsUrlParam(tab);
  }

  constructor(private routerService: RouterService, private hubService: HubService, private homeTabService: HomeTabsService) {
    this._autoFocus$ = new BehaviorSubject<boolean>(true);
    this.keyboardComponentFocus$.subscribe((val) => {
      this.manageKeyboard(val);
    });
  }

  waitTilTabExists(tabId: string) {
    return firstValueFrom(this.currentTabId$.pipe(filter((t) => t === tabId)));
  }

  async getCurrentTab(): Promise<HomeTabs.HomeTab> {
    await this.routerService.navigationEnd;
    const tabId = this.currentTabId;
    const tabs = await firstValueFrom(this.homeTabService.all$.pipe(filter((t) => !!t)));
    return tabs.find((tab) => tab.id === tabId);
  }

  manageKeyboard(val: ComponentFocused) {
    switch (val.step) {
      case 'next':
        this._componentFocus = val.live === HomeComponentFocused.BROWSER_BAR ? 1 : ++val.live;
        break;
      case 'prev':
        this._componentFocus = val.live === HomeComponentFocused.SEARCH_INPUT ? 4 : --val.live;
        break;
      case 'current':
        this._componentFocus = val.live;
        break;
    }
    this._triggerComponentFocus$.next({ step: val.step, live: this._componentFocus });
  }
}
