<div class="container" [ngClass]="{ side: mode === 'side' }">
  <div class="content" [ngClass]="{ disabled: disabled, 'side-with-link': links?.length > 0 && mode === 'side' }">
    <ng-container *ngIf="model">
      <!-- Define which content to render according to state -->
      <ng-container *ngTemplateOutlet="(status$ | async) === 'stale' ? stale : regular"> </ng-container>
    </ng-container>

    <ng-template #regular>
      <div class="header" [ngClass]="{ 'has-local': showLocal$ | async }">
        <div *ngIf="showLocal$ | async" class="local-badge">Local</div>
        <ng-container *ngTemplateOutlet="indicatorTemplate"></ng-container>
      </div>

      <div *ngIf="mode !== 'side'; else sideModeBody" class="body">
        <u-icon [attr.data-cy]="'app-item-icon'" class="app-icon" [model]="{ type: 'img', value: model.icon }"></u-icon>
        <span [attr.data-cy]="'app-item-header'" class="app-title">
          {{ model.name }}
        </span>
      </div>

      <ng-template #sideModeBody>
        <div class="side-mode-body">
          <u-icon [attr.data-cy]="'app-item-icon'" class="app-icon" [model]="{ type: 'img', value: model.icon }"></u-icon>
          <div
            class="text-container"
            tooltipPosition="bottom"
            tooltipStyleClass="u-tooltip"
            [pTooltip]="'Please contact your workspace administrator to connect this app'"
            [tooltipDisabled]="!disabled || !model.state.workspaceDisabled"
          >
            <span class="app-title" [attr.data-cy]="'app-item-title'">
              {{ model.name }}
            </span>
            <div *ngIf="!links?.length" class="action-button" [attr.data-cy]="'app-item-subTitle'">Connect now</div>
          </div>
        </div>
      </ng-template>

      <div
        class="footer"
        [ngClass]="{ 'footer-side': links?.length > 0 && mode === 'side' }"
        *ngIf="!isPcLink; else pcLinkFooter"
        tooltipPosition="bottom"
        tooltipStyleClass="u-tooltip"
        [pTooltip]="'Please contact your workspace administrator to connect this app'"
        [tooltipDisabled]="!disabled || !model.state.workspaceDisabled"
      >
        <ng-container *ngIf="links?.length > 0; else connectButton">
          <div class="action-button">See more</div>
          <div class="avatars">
            <ng-container *ngFor="let link of links.slice(0, 3)">
              <div
                [pTooltip]="sharedLinks[link.name] ? 'Link created by Administrator' : link.name"
                tooltipPosition="bottom"
                tooltipStyleClass="u-tooltip"
              >
                <avatar class="avatar" [size]="'20px'" [url]="sharedLinks[link.name] ? workspaceIcon : link.avatar"></avatar>
              </div>
            </ng-container>
            <div *ngIf="links.length > 3" class="more-accounts">+{{ links.length - 3 }}</div>
          </div>
        </ng-container>
        <ng-template #connectButton>
          <div *ngIf="mode !== 'side'" class="action-button">Connect now</div>
        </ng-template>
      </div>
    </ng-template>

    <ng-template #pcLinkFooter>
      <div class="footer pc-footer" *ngIf="isPcLink">
        <u-toggle-button
          class="toggle-button"
          [checked]="isPcEnable"
          [disabled]="!isNativeRunning"
          type="small"
          [pTooltip]="!isNativeRunning ? 'Open the desktop app to enable/disable' : isPcEnable ? 'Connected' : 'Not connected'"
          tooltipPosition="top"
          tooltipStyleClass="u-tooltip"
          (onChange)="togglePcLink($event)"
          (click)="$event.stopPropagation()"
        >
        </u-toggle-button>
      </div>
    </ng-template>

    <ng-template #stale>
      <div class="header" [class.has-local]="showLocal$ | async">
        <div class="app-stale-container">
          <u-icon class="app-stale-icon" [model]="{ type: 'img', value: model.icon }"></u-icon>
          <span class="app-stale-title">
            {{ model.name }}
          </span>
        </div>
        <ng-container *ngTemplateOutlet="indicatorTemplate"></ng-container>
      </div>

      <div class="body">
        <p class="outdated-text">
          {{ staleText[0] }} <br /><span class="no-break"> {{ staleText[1] }} </span>
          <u-icon
            class="font-info"
            [model]="{ type: 'font', value: 'icon-info' }"
            pTooltip="Outdated links may cause information to not be accurate. Please reconnect to update the information."
            tooltipPosition="top"
            tooltipStyleClass="u-tooltip"
          ></u-icon>
        </p>
      </div>
      <div class="footer">
        <div class="action-button icon-button">
          <u-button
            *ngIf="model.links.length > 0"
            label="Reconnect"
            type="tertiary"
          ></u-button>
        </div>
      </div>
    </ng-template>

    <!--  The indicator is shared among the states but used differently on the layout -->
    <ng-template #indicatorTemplate>
      <indicator
        *ngIf="(status$ | async) !== 'no-links'"
        class="indicator"
        [name]="stateItem"
        [intent]="['stale', 'sync-failed'].includes(status$ | async) ? 'danger' : stateItem === 'Syncing' ? 'warning' : 'success'"
        [blink]="model.syncStatus === 'Syncing'"
      ></indicator>
    </ng-template>
  </div>
  <u-icon
    *ngIf="model.state.disabled"
    [model]="{ type: 'font', value: 'icon-lock-fill' }"
    class="lock-icon"
    [pTooltip]="'Available in Desktop App only'"
    tooltipPosition="top"
    tooltipStyleClass="u-tooltip"
  ></u-icon>
</div>
