<u-icon [model]="bannerIcon ?? icon" [styles]="{ fontSize: '20px' }"></u-icon>
<div class="button-text-container" *ngIf="bannerText">
  <div class="upgrade-banner-text">{{ bannerText }}</div>
  <u-button
    *ngIf="showButton"
    class="upgrade-banner-btn"
    label="Upgrade"
    type="secondary"
    [styles]="{ backgroundColor: 'var(--yellow-500)', borderColor: 'var(--gray-900)', height: '24px', color: 'var(--gray-900)' }"
    (onClick)="upgradeClicked()"
  >
  </u-button>
</div>
