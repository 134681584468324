import { ExperienceErrors } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class ExperienceErrorsRpcInvoker implements ExperienceErrors.Service {
  @invoker
  get error$(): Observable<ExperienceErrors.ExperienceError> {
    return;
  }

  @invoker
  getErrorById$(id: string): Observable<ExperienceErrors.ExperienceError> {
    return;
  }

  @invoker
  setError(experienceId: string, error: string) {
    return;
  }
}
