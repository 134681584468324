import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { LogService } from '@shared/services';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { NativeAppLinkService } from '@shared/services/native-app-link.service';
import { getModifiers, isEnterKey, KeyName } from '@local/ts-infra';
import { Logger } from '@unleash-tech/js-logger';
import { cloneDeep, isEqual } from 'lodash';
import { BrowseEvent, FiltersList } from '../filters-list';
import { DisplaySearchFilterValue, FilterChangeData, FilterChangeDataAction, SpreadFilter } from '../models';
import { PopupRef, PopupService } from '@local/ui-infra';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OptionsPopupComponent, OptionsPopupData } from 'src/app/bar/views/hub/components/launcher-avatar-popup/options-popup.component';

@UntilDestroy()
@Component({
  selector: 'spread-filter',
  templateUrl: './spread-filter.component.html',
  styleUrls: ['./spread-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpreadFilterComponent implements AfterViewInit, OnDestroy {
  @Input() enabledOnly: boolean;
  @Input() set model(sf: SpreadFilter) {
    this._model = sf;
    this.updateModel();
  }
  @Input() set marked({ marked, keys, event }: { marked: boolean; keys: KeyName[]; event: CustomKeyboardEvent }) {
    if (marked) this.registerKeyHandler(keys, event);
    else this.unregisterKeyHandler();
  }
  @Input() resultFocused: boolean;
  @Input() limitItemsCount: number;
  @Output() change: EventEmitter<FilterChangeData> = new EventEmitter();

  private _selectedItems: DisplaySearchFilterValue[] = [];
  private keyHandlerId: string;
  private list: FiltersList;
  private _model: SpreadFilter;
  private _values: DisplaySearchFilterValue[];
  private logger: Logger;
  private optionsPopupRef: PopupRef<OptionsPopupComponent, OptionsPopupData>;

  @ViewChild('moreValues') moreValuesRef: ElementRef;
  @ViewChildren('option', { read: ElementRef }) optionsCompRef: QueryList<ElementRef>;

  get model(): SpreadFilter {
    return this._model;
  }
  get values(): DisplaySearchFilterValue[] {
    let filters = this._values;
    if (this.enabledOnly) {
      filters = this._values?.filter((v) => !v.disabled);
    }
    return filters.length === 1 ? [] : filters;
  }

  get displayValues() {
    return this.limitItemsCount ? this.values.slice(0, this.limitItemsCount) : this.values;
  }

  get selectedItems(): string[] {
    return this._selectedItems.map((i) => i.value);
  }

  get displayMoreButton(): boolean {
    return this.limitItemsCount && this.limitItemsCount < this.values.length;
  }

  constructor(
    private keyboardService: KeyboardService,
    private nativeAppLinkService: NativeAppLinkService,
    private cdr: ChangeDetectorRef,
    logService: LogService,
    public ref: ElementRef,
    private popupService: PopupService
  ) {
    this.logger = logService.scope('ResultsComponent');
  }

  trackItem(i, v) {
    return v.value;
  }

  ngAfterViewInit() {
    this.list = new FiltersList(this.optionsCompRef);
  }

  ngOnDestroy(): void {
    this.unregisterKeyHandler();
  }

  private updateModel() {
    if (isEqual(this.model.values, this._values)) {
      return;
    }
    const selected = this.model.values?.filter((f) => f.selected);
    const selectedIds = selected.map((s) => s.id);
    if (
      !isEqual(
        selectedIds,
        this._selectedItems.map((s) => s.id)
      )
    ) {
      this._selectedItems = selected;
    }
    this._values = cloneDeep(this.model.values);
    this.cdr.markForCheck();
  }

  private registerKeyHandler(keys: KeyName[], event: CustomKeyboardEvent): void {
    if (this.keyHandlerId) return;
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 9);
    if (keys) this.handleKeys(keys, event);
  }

  private unregisterKeyHandler(): void {
    this.list?.unmark();
    if (!this.keyHandlerId) return;
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    this.keyHandlerId = null;
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent) {
    const key = keys[0];
    const modifiers = getModifiers(keys);
    if (!this.list) {
      this.logger.warn('this.list is undefined for some reason', { keys });
      return;
    }

    if (modifiers.length === 1 && modifiers[0] === 'shift' && key === 'tab') {
      if (this.list.markedIndex === 0) {
        this.list.unmark();
        return;
      } else if (this.list.markedIndex === null) {
        this.mark('last');
        event.stopPropagation();
        return;
      }
      this.mark('prev');
      event.stopPropagation();
      return;
    } else if (modifiers.length) {
      return;
    }

    if (key === 'ArrowLeft') {
      if (this.list.markedIndex === 0) {
        this.list.unmark();
        event.stopPropagation();
        return;
      }
      this.mark('prev');
      event.stopPropagation();
      return;
    }

    if (key === 'ArrowRight' || key === 'tab') {
      if (this.list.markedIndex === null) {
        this.mark('first');
        event.stopPropagation();
        return;
      } else if (this.list.markedIndex === this.list.length - 1) {
        this.list.unmark();
        return;
      }
      this.mark('next');
      event.stopPropagation();
      return;
    }

    if (isEnterKey(key) || key === 'space') {
      if (this.list.markedIndex === null || this.list.markedIndex === undefined) return true;
      const filterValue: DisplaySearchFilterValue = this.values[this.list.markedIndex];
      if (!filterValue.disabled) this.select(filterValue);
      event.stopPropagation();
      return;
    }

    if (key === 'ArrowUp' || key === 'ArrowDown') {
      if ((this.list.markedIndex === null || this.list.markedIndex === undefined) && !this.resultFocused) {
        this.mark('first');
        event.stopPropagation();
        return;
      }

      if (this.list.markedIndex >= 0) {
        this.list.unmark();
        return;
      }
    }
  }

  mark(type: BrowseEvent) {
    this.list.mark(type);
    while (this.values[this.list.markedIndex]?.disabled) {
      switch (type) {
        case 'first':
        case 'next':
          this.list.mark('next');
          break;
        case 'prev':
        case 'last':
          this.list.mark('prev');
          break;
        default:
          break;
      }
      if (this.values[this.list.markedIndex]?.disabled && this.list.markedIndex === this.list.length - 1) {
        this.list.unmark();
        break;
      }
    }
  }

  select(selectedFilter: DisplaySearchFilterValue): void {
    const index: number = this.selectedItems.indexOf(selectedFilter.value);
    const newValue = index === -1;
    let action: FilterChangeDataAction = 'Add';
    if (!newValue) {
      action = 'Remove';
      this._selectedItems.splice(index, 1);
    } else {
      this._selectedItems.push(selectedFilter);
    }
    this.change.emit({
      name: this.model.name,
      action,
      changes: {
        values: [selectedFilter],
      },
      current: {
        values: this._selectedItems,
      },
    });
    this.cdr.markForCheck();
  }

  onMoreClicked() {
    const { x, y } = this.moreValuesRef.nativeElement.getBoundingClientRect();
    const offset: Partial<ConnectedPosition> = { offsetY: 38, offsetX: 0 };
    const position: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top', ...offset }];
    const options: OptionsPopupData = { options: [] };
    this.values.slice(this.limitItemsCount)?.forEach((item) => {
      options.options.push({
        label: item.title,
        type: 'navigation',
        value: item.id,
        icon: { type: 'img', value: item.icon?.value },
        id: item.id,
      });
    });
    this.optionsPopupRef = this.popupService.open<OptionsPopupComponent, OptionsPopupData>({ x, y }, OptionsPopupComponent, options, {
      position,
    });
    this.optionsPopupRef.compInstance.close.pipe(untilDestroyed(this)).subscribe(() => {
      this.optionsPopupRef.destroy();
    });
    this.optionsPopupRef.compInstance.invoke.pipe(untilDestroyed(this)).subscribe((item) => {
      const filter = this.values.find((f) => f.id === item.id);
      if (filter) {
        this.select(filter);
      }
      this.optionsPopupRef.destroy();
    });
  }
}
