import { EventEmitter, Injectable } from '@angular/core';
import { Config } from '@environments/config';
import { Downloads, Resources, Tickets } from '@local/client-contracts';
import { DownloadsRpcInvoker } from '@local/common';
import { isEmbed, isNativeWindow } from '@local/common-web';
import { addQueryParamToUrl, isHttpOrHttps } from '@local/ts-infra';
import { EmbedService } from '@shared/embed.service';
import { Logger } from '@unleash-tech/js-logger';
import { Observable, ReplaySubject, filter, firstValueFrom } from 'rxjs';
import { LogService, NativeMainRpcService, NativeServicesRpcService, ServicesRpcService } from '.';
import { MainDownloadsRpcInvoker } from './invokers/main-downloads.rpc-invoker';
import { SessionService } from './session.service';
import { TicketsService } from './tickets.service';
import * as uuid from 'uuid';
@Injectable({
  providedIn: 'root',
})
export class DownloadsService {
  all: Downloads.Downloads = {};
  activeDownloads: { [key: string]: boolean } = {};
  resources: { [key: string]: Resources.Resource };
  allCount: number;
  activeCount: number;
  completedCount: number;
  downloadsChange = new EventEmitter<void>();
  downloadStarted = new EventEmitter<string>();
  private readonly id = uuid.v4();

  private mainService: Downloads.MainService;
  private nativeService: Downloads.Service;
  private webService: Downloads.Service;
  private localPaths: { [key: string]: Promise<Downloads.DownloadResponse> } = {};
  private tickets$: { [name: string]: ReplaySubject<Tickets.Ticket> } = {};
  private accountId: string;
  private logger: Logger;
  private readonly isEmbed = isEmbed();
  private readonly isNativeWindow = isNativeWindow();
  private readonly TICKETS_NAME: string[] = ['links:api', 'blobs'];

  constructor(
    services: ServicesRpcService,
    nativeServices: NativeServicesRpcService,
    main: NativeMainRpcService,
    log: LogService,
    private embedService: EmbedService,
    private ticketsService: TicketsService,
    private sessionService: SessionService
  ) {
    this.logger = log.scope('DownloadsService');

    this.webService = services.invokeWith(DownloadsRpcInvoker, 'downloads');
    if (this.isNativeWindow) {
      this.nativeService = nativeServices.invokeWith(DownloadsRpcInvoker, 'downloads');
      this.mainService = main.invokeWith(MainDownloadsRpcInvoker, 'downloads');
      this.subscribeToDownloads();
    }
    for (const ticketName of this.TICKETS_NAME) {
      this.tickets$[ticketName] = new ReplaySubject<Tickets.Ticket>(1);
      this.ticketsService.getTicket$(ticketName).subscribe((ticket) => {
        this.tickets$[ticketName].next(ticket);
      });
    }

    this.sessionService.current$.subscribe((s) => {
      this.accountId = s?.workspace?.accountId;
    });
  }

  private async subscribeToDownloads(): Promise<void> {
    const downloads$: Observable<Downloads.DownloadsDetails> = this.nativeService.downloads$;
    downloads$.subscribe((downloads: Downloads.DownloadsDetails) => {
      const newAll = {};
      let allCount = 0,
        activeCount = 0,
        completedCount = 0;
      Object.keys(downloads || {}).forEach((id: string) => {
        const download = downloads[id];
        if (this.id === download.locationId) {
          newAll[id] = download;
          if (download.visibility === 'hidden') return;
          allCount++;
          if (download.visibility === 'visible' && download.status === 'in-progress') activeCount++;
          else if (download.status === 'completed') completedCount++;
        }
      });
      this.all = newAll;
      this.allCount = allCount;
      this.activeCount = activeCount;
      this.completedCount = completedCount;
      this.downloadsChange.emit();
    });
  }

  resourceDownload(request: Downloads.ResourceDownloadRequest, visibility: Downloads.DownloadVisibility = 'visible') {
    return this.download(request, visibility);
  }

  async urlDownload(request: Downloads.UrlDownloadRequest, visibility: Downloads.DownloadVisibility = 'visible') {
    if (request.grantType) {
      request.url = await this.addTicketToUrl(request.grantType, request.url);
    }
    return this.download(request, visibility);
  }

  private async download(request: Downloads.DownloadRequest, visibility: Downloads.DownloadVisibility): Promise<string> {
    if (!this.isNativeWindow) {
      let url = request.url;
      if (!isHttpOrHttps(url)) {
        url = await this.getDownloadUrl(request);
        if (this.isEmbed) {
          await this.embedService.download(url);
          return;
        }
      }
      if (this.isEmbed && Config.baseUrl !== new URL(url).origin) {
        // it is not allowed to load url of a different origin inside an iframe so the url will be opened in another tab.
        window.open(url);
        return;
      }

      // create an a tag and initiating download
      const link = document.createElement('a');
      link.href = url;
      link.download = '';
      link.click();

      return;
    }
    if (visibility === 'visible') {
      this.downloadStarted.emit();
    }
    request.locationId = this.id;
    return this.nativeService.download(request, visibility);
  }

  async clear(id: string): Promise<void> {
    if (!this.isNativeWindow) return;
    await this.nativeService.clear(id);
  }

  async clearAll(): Promise<void> {
    if (!this.isNativeWindow) return;
    await this.nativeService.clearAll();
  }

  async cancel(id: string): Promise<void> {
    if (!this.isNativeWindow) return;
    await this.nativeService.cancel(id);
  }

  async retry(id: string): Promise<void> {
    if (!this.isNativeWindow) return;
    await this.nativeService.retry(id);
  }

  async dragStarted(url: string, icon: string, appId?: string) {
    if (!this.isNativeWindow) return;
    await this.mainService.dragStarted(url, icon, appId);
  }

  async dragged(id: string): Promise<void> {
    if (!this.isNativeWindow) return;

    if (await this.mainService.draggedInsideBarWindow()) return;

    await this.nativeService.dragged(id);
  }

  async setVisibility(id: string, visibility: Downloads.DownloadVisibility): Promise<void> {
    if (!this.isNativeWindow) return;
    await this.nativeService.setVisibility(id, visibility);
    this.downloadStarted.emit(id);
  }

  getId(url: string, linkId: string): Promise<string> {
    if (!this.isNativeWindow) return;
    return this.nativeService.getId(url, linkId);
  }

  async getDownloadUrl(request: Downloads.DownloadRequest, inline = false): Promise<string> {
    const ticketName = request.grantType || 'links:api';
    const { url, isInternal } = await (this.nativeService || this.webService).getDownloadUrl(request);
    if (!isInternal) {
      return url;
    }
    return this.addTicketToUrl(ticketName, url, inline);
  }

  getLocalFileUrl(path: string): Promise<Downloads.DownloadResponse> {
    if (!this.localPaths[path]) {
      this.localPaths[path] = (this.nativeService || this.webService).getLocalFileUrl(path);
    }
    return this.localPaths[path];
  }

  private async getTicket(ticketName: Downloads.GrantType) {
    let ticket = await firstValueFrom(this.tickets$[ticketName]);
    if (!ticket || Date.now() + 10 * 1000 > ticket.expire) {
      await this.ticketsService.refresh(ticketName);
      ticket = await firstValueFrom(this.tickets$[ticketName].pipe(filter(a => a?.value !== ticket?.value)));
    }
    if (!ticket) {
      throw new Error(`No ticket for downloads. ticketName: ${ticketName}`);
    }
    return ticket;
  }

  async addTicketToUrl(grantType: Downloads.GrantType, url: string, inline = false): Promise<string> {
    await firstValueFrom(this.sessionService.current$.pipe(filter((c) => !!c)));
    const ticket = await this.getTicket(grantType);
    if (!this.accountId) {
      throw new Error('No accountId for downloads');
    }

    let res = addQueryParamToUrl(url, 'access_token', ticket.value, true);

    res = addQueryParamToUrl(res, 'x-unleash-acc', this.accountId);

    if (inline) {
      res = addQueryParamToUrl(res, 'disp', 'inline');
    }
    return res;
  }
}
