import { Logger } from '@unleash-tech/js-logger';
import * as uuid from 'uuid';

export function withTimeout<T>(proc: () => Promise<T>, timeout: number, logger: Logger): Promise<T> {
  return new Promise(async (res, rej) => {
    const timeoutId = uuid.v4();
    let done = false;
    const tm = setTimeout(async () => {
      if (done) return;
      done = true;
      error = new Error('Timeout expired');
      error.timeoutId = timeoutId;
      rej(error);
    }, timeout);

    let error: any;
    let result: any;
    try {
      result = await proc();
    } catch (e) {
      error = e;
      error.timeoutId = timeoutId;
    } finally {
      clearTimeout(tm);
      if (done) {
        if (error) logger.error('error in Timeout', error);
        return;
      }
      done = true;
      if (error) rej(error);
      else res(result);
    }
  });
}
