import { Injectable } from '@angular/core';
import { Groups } from '@local/client-contracts';
import { GroupsRpcInvoker } from '@local/common';
import { ReplaySubject } from 'rxjs';
import { ServicesRpcService } from '.';

@Injectable({
  providedIn: 'root',
})
export class GroupsService implements Groups.Service {
  private service: Groups.Service;
  private _all: Groups.WorkspaceGroup[];

  constructor(host: ServicesRpcService) {
    this.service = host.invokeWith(GroupsRpcInvoker, 'groups');
    this.all$.subscribe((res) => (this._all = res));
  }

  get all$(): ReplaySubject<Groups.WorkspaceGroup[]> {
    return this.service.all$;
  }

  get all(): Groups.WorkspaceGroup[] {
    return this._all;
  }

  getGroups(groupIds: string[]): Promise<Groups.WorkspaceGroup[]> {
    return this.service.getGroups(groupIds);
  }
}
