import { Injectable } from '@angular/core';
import { IActionMapping, TreeComponent, TreeModel, TREE_ACTIONS } from '@odymaui/angular-tree-component';
import { TreeNode } from '@odymaui/angular-tree-component/lib/defs/api';
import { TreeOptions } from '@odymaui/angular-tree-component/lib/models/tree-options.model';
import { NavTree } from '@local/client-contracts';
import { observable, performanceCheckpoint } from '@local/common';
import { EventsService, LogService } from '@shared/services';
import { RouterService } from '@shared/services/router.service';
import { Logger } from '@unleash-tech/js-logger';
import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, Subject, take } from 'rxjs';
import { HubService } from './hub.service';
import { NavTreeService } from './nav-tree.service';
import { TagsService } from './tags.service';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private logger: Logger;

  private _nodes$: Observable<NavTree.Node[]>;
  activeNode$: BehaviorSubject<any> = new BehaviorSubject<any>('search');
  activeParents$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(['search']);
  activeParents = [];

  isUserBadgeOpen$: Subject<any> = new Subject<any>();
  sidebarStateChange$: Subject<any> = new Subject<any>();
  sidebarState: 'open' | 'close';
  onNodeClick$: Subject<string> = new Subject<string>();

  @observable
  public get nodes$(): Observable<NavTree.Node[]> {
    return this._nodes$;
  }

  constructor(
    private navTreeService: NavTreeService,
    private routingService: RouterService,
    private hubService: HubService,
    private eventsService: EventsService,
    private tagsService: TagsService,
    private routerService: RouterService,
    logService: LogService
  ) {
    this._nodes$ = this.navTreeService.roots$.pipe(
      map((nodes: NavTree.Node[]) =>
        nodes
          .filter((node) => !node.hidden)
          .map((node) => {
            const clonedNode = cloneDeep(node);
            if (clonedNode.children?.length) {
              clonedNode.children = clonedNode.children.filter((child) => !child.hidden);
              if (!clonedNode.children.length) {
                delete clonedNode.children;
                delete clonedNode.hasChildren;
              }
            }
            return clonedNode;
          })
      )
    );
    this.logger = logService.scope(this.constructor.name);
  }

  toggleSidebarState(state: 'open' | 'close'): void {
    this.sidebarState = state;
    this.sidebarStateChange$.next(state);
  }

  async loadTree(tree: TreeComponent, activeNode) {
    if (!tree) return;

    performanceCheckpoint('first_load_tree');
    firstValueFrom(this.nodes$).then((x) => {
      performanceCheckpoint('first_tree_result');
    });

    combineLatest([this.nodes$, this.navTreeService.currentNode$]).subscribe(async ([nodes, currentNode]) => {
      let id = currentNode?.id;
      if (!currentNode && id !== 'search' && id !== 'home') {
        id = null;
      }
      if (!tree?.treeModel) return;
      if (!id) {
        this.deactivateLastNode(tree, activeNode);
        return;
      }
      const treeNode = tree.treeModel.getNodeById(id);
      this.updateActiveNode(treeNode);
    });
  }

  updateActiveNode(treeNode: any) {
    if (treeNode) {
      this.expendParents(treeNode);
      treeNode.setIsActive(true);
      this.activeNode$.next(treeNode);

      this.activeParents = [];
      this.getActiveParents(treeNode);
      this.activeParents$.next(this.activeParents);
      return;
    }
  }

  clearActiveNode() {
    this.activeNode$.next(null);
  }

  getActiveParents(treeNode) {
    const parent = treeNode.parent;
    if (!treeNode) return;
    if (!parent) {
      this.activeParents.push(treeNode);
      return;
    }
    this.activeParents.push(parent);
    this.getActiveParents(parent);
  }

  getTreeOptions(tree: TreeComponent) {
    const clickableNode = (node: TreeNode): boolean => node.data.type === 'standard';
    const notHeader = (node: TreeNode): boolean => node.data.headerNode;

    const actions: IActionMapping = {
      mouse: {
        click: (treeModel: TreeModel, node: TreeNode, event: MouseEvent) =>
          clickableNode(node) && !notHeader(node) && TREE_ACTIONS.TOGGLE_ACTIVE(treeModel, node, event) && this.onNodeClick(tree, node),
        expanderClick: (tree: TreeModel, node: TreeNode, event: MouseEvent) =>
          clickableNode(node) && TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, event) && this.toggleExpended(node),
      },
    };
    const options: Partial<TreeOptions> = {
      getChildren: async (node) => this.navTreeService.getChildren(node),
      actionMapping: actions,
    };

    return options;
  }

  private expendParents(node: TreeNode) {
    let curr = node;
    while (!!curr) {
      curr?.parent?.setIsExpanded(true);
      curr = curr?.parent;
    }
  }

  private deactivateLastNode(tree: TreeComponent, activeNode): void {
    const activeNodeId = activeNode?.id;
    if (!activeNodeId || !tree?.treeModel) return;
    const node = tree.treeModel.getNodeById(activeNodeId);
    if (node) {
      node.setIsActive(false);
      node.blur();
    }
    this.activeNode$.next(undefined);
    this.activeParents$.next(undefined);
  }
  async onNodeClick(tree: TreeComponent, node: TreeNode) {
    const label: string = node.data?.location ?? node.data.id;
    const location = this.hubService.currentLocation;
    this.onNodeClick$.next(node.id);
    this.routingService.active$.pipe(take(1)).subscribe((v) => {
      const jsonData = JSON.stringify({ window: { side_panel_mode: this.sidebarState } });
      this.eventsService.event('tree.click', {
        label,
        location: { title: location },
        jsonData,
      });
    });
    this.tagsService.all = [];
    await this.routingService.navigateByUrl(node.id == 'home' ? '' : this.navTreeService.getNodeUrl(node.data));

    tree?.treeModel?.setFocus(false); // Disables keyboard navigation  --?
  }

  async toggleExpended(node: TreeNode) {
    const label: string = node.data?.location ?? node.data.id;

    this.routingService.active$.pipe(take(1)).subscribe((v) => {
      this.eventsService.event('tree.toggle', {
        label,
        location: { title: this.hubService.currentLocation },
        target: node.isExpanded ? 'expand' : 'collapse',
      });
    });
  }

  stickyNodeClicked(tree: TreeComponent, id: string) {
    const node: TreeNode = tree.treeModel.getNodeById(id);
    if (!node) return;
    node.setIsActive(true);
    this.onNodeClick(tree, node);
  }
}
