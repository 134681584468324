import { Injectable } from '@angular/core';
import { PopupRef, PopupService } from '@local/ui-infra';
import { take } from 'rxjs';
import {
  MultiChoicePopupComponent,
  MultiChoicePopupType,
} from '../views/collections-page/components/multi-choice-popup/multi-choice-popup.component';
import { getWidthBreakpointScreen } from '@shared/utils';

@Injectable()
export class MultiChoicePopupService {
  private readonly TOP_DISTANCE = 36;
  private readonly MARGIN_SMALL = 260;
  private readonly MARGIN_LARGE = 140;
  private popupMultiChoiceRef: PopupRef<MultiChoicePopupComponent, any>;

  constructor(private popupService: PopupService) {}

  openMultiChoicePopup(elm: Element, isPopupPositionBelow: boolean, type: MultiChoicePopupType) {
    const leftDistance = getWidthBreakpointScreen() === 'small' ? this.MARGIN_SMALL : this.MARGIN_LARGE;
    if (this.popupMultiChoiceRef) {
      this.popupMultiChoiceRef.destroy();
    }
    const { x, y } = elm.getBoundingClientRect();
    let position;
    if (isPopupPositionBelow) {
      position = { left: x, top: y + this.TOP_DISTANCE };
    } else {
      position = { left: x - leftDistance, top: y + this.TOP_DISTANCE };
    }
    this.popupMultiChoiceRef = this.popupService.open<MultiChoicePopupComponent, any>(position, MultiChoicePopupComponent, { type });
    this.popupMultiChoiceRef.destroy$.pipe(take(1)).subscribe(() => {
      this.popupMultiChoiceRef = null;
    });
    return this.popupMultiChoiceRef;
  }
}
