import { Results } from '@local/client-contracts';
export class IconConstants {
  static readonly DEFAULT_AVATAR_MODEL: Results.Icon = {
    lightUrl: '/assets/settings/avatar-settings.svg',
    darkUrl: '/assets/settings/avatar-settings.svg',
    rounded: true,
  };
  static readonly DEFAULT_AVATAR = '/assets/settings/avatar-settings.svg';
  static readonly APP_ICON: Results.Icon = { lightUrl: '/assets/appicon.svg', darkUrl: '/assets/appicon.svg' };
  static readonly APP_ICON_LOADER = { lightUrl: 'assets/loader/loader_trans.gif' };
  static readonly APP_MAIN_ICON_LOADER = { lightUrl: 'assets/loader/loader_light.gif', darkUrl: 'assets/loader/loader_dark_trans.gif' };
  static readonly APP_BANNER = { lightUrl: 'assets/banner/unleash-banner-light.svg', darkUrl: 'assets/banner/unleash-banner-dark.svg' };
  static readonly APP_MAIN_ICON_LOADER_DARK_ONLY = { lightUrl: 'assets/loader/loader_dark_trans.gif' };
}
