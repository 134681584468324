<u-multi-select
  [ngClass]="{ disabled: disabled }"
  [styles]="styles ?? { width: 'fit-content', minWidth: hideTitle ? 'auto' : MIN_WIDTH_MULTI_SELECT }"
  [placeholder]="model.title"
  [model]="options"
  [sortBy]="sortBy"
  [multiSelectIcon]="multiSelectIcon"
  [(ngModel)]="selectedItems"
  (onSelectedChange)="onChange($event)"
  (onFilter)="onFilter($event)"
  (onPanelShow)="onPanel(true)"
  (onPanelHide)="onPanel(false)"
  (optionsUpdated)="optionsUpdated($event)"
  (onClearAllEvent)="onClearAllEvent()"
  (click)="onClick($event)"
  [noCheckbox]="noCheckbox"
  [filterPlaceholder]="filterPlaceholder ?? model.title + '...'"
  [displayLabel]="'title'"
  [limitShowSelectedLabels]="limitShowSelectedLabels"
  [isNoColorCheckbox]="true"
  [showSelectedItemIcons]="showSelectedItemIcons"
  [virtualScroll]="virtualScroll && (model?.values?.length > 5 || !model?.values?.length)"
  [keepPlaceholder]="keepPlaceholder"
  [appendTo]="appendTo"
  [filterBy]="filterBy"
  [itemSize]="itemSize"
  [inlineFilterPlaceHolder]="inlineFilterPlaceHolder"
  (onKeyboardItemChange)="onKeyboardItemChangeEvent($event)"
  [showClear]="showClear"
  [showClearAll]="showClearAll"
  [oneValue]="oneValue"
  [updateOnRemove]="updateOnRemove"
  [autoSort]="autoSort"
  [showTooltipIcons]="showTooltipIcons"
  [maxCharacters]="MAX_CHARACTERS"
  [showItemTooltip]="!isTwoLine"
  [excludeSelectedFromHeader]="excludeSelectedFromHeader"
  [showGroupOptions]="showGroupOptions"
  [hideOnSelect]="hideOnSelect"
  [showHasValues]="showHasValues"
  [displayBackIcon]="displayBackIcon"
  (onBack)="onBack.emit()"
  [clearAllMinSelected]="clearAllMinSelected"
  [shortSelected]="shortSelected"
  [filterDisabled]="filterDisabled"
  [filterMatchMode]="filterMatchMode"
  [readonly]="model.readonly"
  [wideWidth]="wideWidth"
  [enableBack]="enableBack"
  [enableWriteValue]="false"
  [clearSearchAfterSelect]="clearSearchAfterSelect"
  [separateSelected]="separateSelected"
  [showSingleItemSelected]="showSingleItemSelected"
  [searchDebounce]="searchDebounce"
  [tooltipLabel]="tooltipLabel"
>
  <ng-container *ngIf="shortSelected">
    <ng-template let-value pTemplate="selectedItems">
      <short-selected
        [pTooltip]="tooltipSelected"
        [tooltipDisabled]="!tooltipSelected"
        tooltipPosition="top"
        tooltipStyleClass="u-tooltip"
        [escape]="false"
        [multiSelectIcon]="multiSelectIcon"
        [placeholder]="hideTitle ? null : model.title"
        [countSelected]="customSelectedCount != undefined ? customSelectedCount : selectedItems.length"
      ></short-selected>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="isTwoLine">
    <ng-template let-item pTemplate="item">
      <div class="option-text">
        <div
          *ngIf="item.title"
          class="title"
          [class.title-full-details]="item.subtitle || fullDetailIds?.includes(item.id)"
          [pTooltip]="item.title?.length > MAX_CHARACTERS ? item.title : ''"
          tooltipPosition="top"
          tooltipStyleClass="u-tooltip"
        >
          {{ item.title }}
        </div>
        <div
          *ngIf="item.subtitle"
          class="subtitle"
          [pTooltip]="item.subtitle.length > MAX_CHARACTERS ? item.subtitle : ''"
          tooltipPosition="top"
          tooltipStyleClass="u-tooltip"
        >
          {{ item.subtitle }}
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="isFullDetailLine">
    <ng-template let-item pTemplate="item">
      <multi-select-value
        [item]="item"
        [showItemIcon]="showItemIcon"
        [showItemLabel]="showItemLabel"
        [showRemoveIcon]="showRemoveIcon"
        [showItemIconLabel]="showItemIconLabel"
        [maxCharacters]="MAX_CHARACTERS"
      ></multi-select-value>
    </ng-template>
  </ng-container>
</u-multi-select>
