<div class="banner-container" *ngIf="message" [style]="message.style" [class]="message.class" [ngClass]="{ short: shortWidth }">
  <div class="main">
    <div class="content">
      <u-icon class="banner-icon" *ngIf="message.icon" [model]="message.icon"></u-icon>
      <div class="message" [innerHtml]="message.content"></div>
    </div>
    <u-button
      class="banner-button"
      *ngIf="message.buttonText"
      [label]="message.buttonText"
      (click)="onClick()"
      type="secondary"
      elementSize="small"
      [styles]="message.buttonStyle ?? buttonStyle"
    ></u-button>
  </div>
  <u-button *ngIf="message.enableClosing" class="no-state close-button" [styles]="{ padding: '4px' }">
    <u-icon
      class="close-icon"
      (click)="onClose()"
      [model]="{ type: 'font', value: 'icon-Windows-close' }"
      [styles]="{ fontSize: '14px', color: 'var(--primary-A100)' }"
    ></u-icon>
  </u-button>
</div>
