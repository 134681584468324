import { GoLinks } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';

export class GoLinksIndexerRpcInvoker implements GoLinks.Indexer {
  @invoker
  shutdown(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  @invoker
  stats(): Promise<any> {
    throw new Error('Method not implemented.');
  }
  @invoker
  status(): Promise<GoLinks.IndexerStatus> {
    throw new Error('Method not implemented.');
  }
  @invoker
  reload(req?: GoLinks.ListResponse): Promise<void> {
    return;
  }
  @invoker
  unload(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  @invoker
  update(req: GoLinks.ListResponse): Promise<void> {
    return;
  }
  @invoker
  delete(req: GoLinks.ListResponse): Promise<void> {
    return;
  }
  @invoker
  query(query: string) {
    return;
  }
  @invoker
  search(request: GoLinks.SearchRequest): Promise<GoLinks.SearchResponse> {
    return;
  }
  @invoker
  getTags(): Promise<GoLinks.TagItem[]> {
    return;
  }
  @invoker
  validateField(key: 'url' | 'name', value: string): Promise<GoLinks.SearchItem[]> {
    return;
  }
}
