import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Ai, Commands } from '@local/client-contracts';
import { PopupRef, UiIconModel } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventInfo, EventInfoResource, EventsService } from '@shared/services';
import { AiService } from '@shared/services/ai.service';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { RouterService } from '@shared/services/router.service';
import { KeyName } from '@local/ts-infra'
import { Tooltip } from 'primeng/tooltip';
import { Subscription } from 'rxjs';
import { CommandsService } from 'src/app/bar/services/commands/commands.service';

export interface SummaryPopupData {
  resourceId: string;
  command: Commands.Command;
  resources?: Partial<EventInfoResource>[];
  position?: any;
  displayX?: boolean;
}
@UntilDestroy()
@Component({
  selector: 'summary-popup',
  templateUrl: './summary-popup.component.html',
  styleUrls: ['./summary-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryPopupComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly INDICATE_SUMMARY_ICON: UiIconModel = {
    type: 'font',
    value: 'icon-board-list',
  };
  private readonly SPACE_X = 28;
  private readonly SPACE_Y = 20;
  readonly ERROR_MESSAGE = 'Something went wrong, try again later';
  readonly ERROR_RATE_LIMIT_MESSAGE = 'Server busy. </br> Please try again in a few minutes';
  readonly ERROR_NOT_AVAILABLE_MESSAGE = 'Summary is not available for this item';

  private summarySubscription: Subscription;
  private firstTextSummary = true;
  private keyHandlerId: string;
  summary = '';
  data: SummaryPopupData;
  tooltipCopyText = 'Copy text';
  hover = false;
  errorSummary: string;

  @Output() invoke = new EventEmitter<Commands.Command>();

  @ViewChildren(Tooltip) private tooltips: QueryList<Tooltip>;

  private get currentLocation(): string {
    return this.routerService.location;
  }

  constructor(
    private ref: PopupRef<SummaryPopupComponent, SummaryPopupData>,
    private aiService: AiService,
    private cdr: ChangeDetectorRef,
    private commandsService: CommandsService,
    private eventsService: EventsService,
    private routerService: RouterService,
    private keyboardService: KeyboardService
  ) {
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys: Array<KeyName>, event) => this.handleKeys(keys, event), 9);
  }

  ngOnInit(): void {
    this.data = this.ref.data;

    try {
      this.summarySubscription = this.aiService
        .summarize$({ resourceId: this.data.resourceId })
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (v: Ai.SummarizeResponse) => {
            if (v.error) {
              this.handleError(v.error);
              return;
            }
            this.summary += v.data.text;
            this.cdr.markForCheck();
            if (this.firstTextSummary) {
              this.sendInitialEvent();
              this.firstTextSummary = false;
            }
          },
          error: (err) => {
            this.handleError(err);
          },
          complete: () => {
            if (!this.summary?.length) {
              this.errorSummary = this.ERROR_NOT_AVAILABLE_MESSAGE;
              this.sendInitialEvent('summary_not_available');
              this.cdr.markForCheck();
            }
          },
        });
    } catch (err) {
      this.errorSummary = this.ERROR_MESSAGE;
      this.sendInitialEvent('try_later');
    }

    this.cdr.markForCheck();
  }

  private handleError(err: Ai.SummarizeErrorResponse) {
    switch (err.type) {
      case 'TooManyRequests':
        this.errorSummary = this.ERROR_RATE_LIMIT_MESSAGE;
        this.sendInitialEvent('server_busy');
        break;
      case 'ResourceContentProblem':
        this.errorSummary = this.ERROR_NOT_AVAILABLE_MESSAGE;
        this.sendInitialEvent('summary_not_available');
        break;
      default:
        this.errorSummary = this.ERROR_MESSAGE;
        this.sendInitialEvent('try_later');
        break;
    }
    this.cdr.markForCheck();
  }

  ngAfterViewInit(): void {
    if (this.data.position === 'center') {
      return;
    }
    this.initView();
  }

  ngOnDestroy(): void {
    this.cancelSubscription();
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
  }

  initView(): void {
    const cdk = document.getElementsByClassName('cdk-overlay-container')[0];

    cdk.addEventListener('mousemove', (event) => {
      const positionX = this.data.position['clientX'];
      const positionY = this.data.position['clientY'];
      const eventPositionX = event['clientX'];
      const eventPositionY = event['clientY'];

      if (
        eventPositionY < positionY - this.SPACE_Y ||
        eventPositionX < positionX - this.SPACE_X ||
        eventPositionX > positionX + this.SPACE_X ||
        eventPositionY > positionY + this.SPACE_Y
      ) {
        this.closePopup();
      }
    });
  }

  cancelSubscription() {
    this.summarySubscription?.unsubscribe();
    this.summarySubscription = null;
  }

  closePopup() {
    this.cancelSubscription();
    this.ref?.destroy();
    this.ref = null;
  }

  openResource() {
    this.sendEventTelemetry('summary.action', { label: 'open' });
    if (!this.data.command) {
      return;
    }
    this.commandsService.executeCommand(this.data.command);
    this.sendEventTelemetry('summary.redirect', { target: 'mouse_click' });
  }

  copyText() {
    this.sendEventTelemetry('summary.action', { label: 'copy' });
    if (!this.summary) {
      return;
    }
    this.commandsService.executeCommand({
      type: 'copy-clipboard',
      value: this.summary,
    } as Commands.CopyClipboard);
    this.sendEventTelemetry('summary.copy', { target: 'mouse_click' });
    this.tooltipCopyText = 'Text copied to clipboard';
    setTimeout(() => {
      this.tooltips.get(0).show();
      setTimeout(() => {
        this.tooltips.get(0).hide();
        this.tooltipCopyText = 'Copy text';
        this.cdr.markForCheck();
      }, 500);
    }, 0);
  }

  sendInitialEvent(error?: string): void {
    this.sendEventTelemetry('summary.impression', {
      label: error ? 'error' : undefined,
      location: { title: this.currentLocation },
      resources: this.data.resources,
      jsonData: error ? JSON.stringify({ error_type: error }) : undefined,
    });
  }

  sendEventTelemetry(key: string, info?: Partial<EventInfo>): void {
    this.eventsService.event(key, {
      ...info,
      location: { title: this.currentLocation },
      resources: this.data.resources,
    });
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    event.stopPropagation();
    const key = keys[0];
    switch (key) {
      case 'escape': {
        event.preventDefault();
        this.closePopup();
        break;
      }
    }
  }
}
