import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UInputComponent } from '@local/ui-infra';
import { UntilDestroy } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'search-expandable-button',
  templateUrl: './search-expandable-button.component.html',
  styleUrls: ['search-expandable-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchExpandableButtonComponent {
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() iconClick: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(UInputComponent) uInput: UInputComponent;
  @Input() placeholder: string = 'Search for anything';
  @Input() size: string;

  model: string = '';
  focusInput: boolean;
  searchIcon = { type: 'font', value: 'icon-search-icon-fixed' };

  constructor(private cdr: ChangeDetectorRef) {}

  onChangeEvent($event) {
    this.model = $event;
    this.onChange.emit($event);
    if (!$event) {
      setTimeout(() => {
        this.model = null;
        this.uInput.focusInput();
        this.cdr.detectChanges();
      }, 0);
    }
  }

  resetData() {
    setTimeout(() => {
      this.model = null;
      this.uInput.model = null;
      this.focusInput = false;
      this.cdr.markForCheck();
    }, 0);
  }

  onBlur() {
    this.focusInput = !!this.model;
    this.cdr.markForCheck();
  }

  clear() {
    this.uInput.model = this.model = '';
    this.onBlur();
  }
}
