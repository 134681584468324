export class CardEditorConstants {
  static readonly PLUGINS = [
    'advlist',
    'autolink',
    'codesample',
    'emoticons',
    'image',
    'link',
    'lists',
    'media',
    'quickbars',
    'save',
    'table',
    'slashcommands',
    'context-menu-plugin',
  ];
  static readonly TEXT_PATTERNS = [
    { start: '#', format: 'h1' },
    { start: '##', format: 'h2' },
    { start: '###', format: 'h3' },
    { start: '####', format: 'h4' },
    { start: '#####', format: 'h5' },
    { start: '######', format: 'h6' },
    { start: '* ', cmd: 'InsertUnorderedList' },
    { start: '- ', cmd: 'InsertUnorderedList' },
    { start: '1. ', cmd: 'InsertOrderedList', value: { 'list-style-type': 'decimal' } },
    { start: '1) ', cmd: 'InsertOrderedList', value: { 'list-style-type': 'decimal' } },
    { start: 'a. ', cmd: 'InsertOrderedList', value: { 'list-style-type': 'lower-alpha' } },
    { start: 'a) ', cmd: 'InsertOrderedList', value: { 'list-style-type': 'lower-alpha' } },
    { start: 'i. ', cmd: 'InsertOrderedList', value: { 'list-style-type': 'lower-roman' } },
    { start: 'i) ', cmd: 'InsertOrderedList', value: { 'list-style-type': 'lower-roman' } },
    { start: '---', replacement: '<hr/>' },
    { start: '(c)', replacement: '©' },
  ];
  static readonly QUICKBARS_SELECTION_TOOLBAR = 'styles bold underline italic strikethrough codesample numlist bullist link';
  static readonly CONTEXTMENU = 'image table';
  static readonly CONTEXTMENU_VIEW = 'image';
  static readonly STYLE_FORMATS = [
    { title: 'Paragraph', format: 'p', icon: 'visualchars' },
    { title: 'Heading 1', format: 'h1', icon: 'h1' },
    { title: 'Heading 2', format: 'h2', icon: 'h2' },
    { title: 'Heading 3', format: 'h3', icon: 'h3' },
    { title: 'Ordered List', icon: 'ordered-list', block: 'ol', name: 'ordered-list' },
    { title: 'Bullet List', icon: 'unordered-list', block: 'ul', name: 'bullet-list' },
    { title: 'Blockquote', format: 'blockquote', icon: 'quote' },
    { title: 'Code Block', format: 'code', icon: 'sourcecode' },
  ];

  static readonly TOOLBAR =
    'styles | bold underline italic strikethrough forecolor backcolor | align outdent indent | fileUpload link myQuickImage media table codesample emoticons hr';
  static readonly CONTENT_STYLE_VIEW = '.attachment .action-attachment .icon-delete-attachment {display: none;}';
  static readonly CLASS_DELETE_ATTACHMENT = 'icon-delete-attachment';
  static readonly CLASS_CONTEXT_MENU_ATTACHMENT = 'context-menu-attachment';
  static readonly CLASS_EDITOR_HEADER = '.tox-editor-header';

  static readonly CLASS_EDITOR_DIALOG = 'tox-pop__dialog';
  static readonly CLASS_EDITOR_BIG_DIALOG = 'tox-dialog-wrap';
  static readonly CLASS_EDITOR_CONTEXT_MENU = 'tox-menu';
  static readonly CLASS_EDITOR_TIERED_MENU = 'tox-tiered-menu';
  static readonly CLASS_ATTACHMENT = 'attachment';
  static readonly CLASS_TINYMCE_FOCUS_BORDER = 'data-mce-selected';

  static readonly CLASS_ACTIVE_ATTACHMENT = 'active-attachment';
  static readonly CLASS_NAME_ATTACHMENT = 'name-attachment';
  static readonly CLASS_FAILED_ATTACHMENT = 'failed-attachment';
  static readonly CLASS_SIZE_ATTACHMENT = 'size-attachment';
  static readonly CLASS_REAL_SIZE_ATTACHMENT = 'real-size-attachment';
  static readonly CLASS_EXT_ATTACHMENT = 'ext-file-attachment';
  static readonly CLASS_ICON_ATTACHMENT = 'icon-attachment';
}
