import { Injectable } from '@angular/core';
import { BlobsService } from 'src/app/bar/services/blob.service';
import { ShowToasterService } from 'src/app/bar/services/show-toaster.service';
import { ContextMenuService } from '@shared/components';
import { PopupService } from '@local/ui-infra';
import { WikiCardFileHelper } from '../helpers/wiki/wiki-card-file-helper';
import { Blobs, Permissions, Wiki } from '@local/client-contracts';
import { EventsService } from '@shared/services';
import { WikiCardBlobService } from './wiki-card-blob.service';
import { WikiCardMenuActions } from '../helpers/wiki/wiki-card-menu-actions.helper';
import { WikiAttachmentStorageHandler } from '../helpers/wiki/wiki-attachment-storage-handler';

export type StatusUploader = 'success' | 'failed' | 'deleted' | 'none';
@Injectable()
export class WikiCardFileService {
  private fileHelpers: { [key: string]: WikiCardFileHelper } = {};

  constructor(
    private blobsService: BlobsService,
    private showToasterService: ShowToasterService,
    private contextMenuService: ContextMenuService,
    private popupService: PopupService,
    private eventsService: EventsService,
    private wikiCardBlobService: WikiCardBlobService,
    private wikiAttachmentStorageHandler: WikiAttachmentStorageHandler,
    private wikiCardMenuActions: WikiCardMenuActions
  ) {}

  getOrCreateFileHelper(name: string) {
    if (!this.fileHelpers[name]) {
      this.fileHelpers[name] = new WikiCardFileHelper(
        this.blobsService,
        this.showToasterService,
        this.contextMenuService,
        this.popupService,
        this.eventsService,
        this.wikiCardBlobService,
        this.wikiCardMenuActions,
        this.wikiAttachmentStorageHandler
      );
    }
    return this.fileHelpers[name];
  }

  destroyFileHelper(name: string) {
    if (!this.fileHelpers[name]) {
      return;
    }
    this.fileHelpers[name].destroy();
    delete this.fileHelpers[name];
  }

  async duplicateAttachments(
    content: string,
    attachments: Wiki.CardAttachment[],
    shareOptions: Permissions.ShareOptions
  ): Promise<{ content?: string; attachments?: Wiki.CardAttachment[]; status: StatusUploader }> {
    if (!attachments) {
      return { content, attachments, status: 'none' };
    }

    try {
      const duplicateBlobRequests: Blobs.DuplicateBlobRequest[] = attachments.map((attachment) => ({
        id: attachment.blobId,
        shareOptions,
      }));

      const duplicateBlobResponses = await this.blobsService.duplicate({ entries: duplicateBlobRequests });

      duplicateBlobResponses.entries.forEach((response) => {
        content = content.replace(response.fromId, response.toId);
      });

      const duplicateBlobMap = new Map<string, string>();
      duplicateBlobResponses.entries.forEach((response) => {
        duplicateBlobMap.set(response.fromId, response.toId);
      });

      attachments.forEach((attachment) => {
        const toId = duplicateBlobMap.get(attachment.blobId);
        if (toId !== undefined) {
          attachment.blobId = toId;
        }
      });
    } catch (error) {
      console.error('Error duplicating file on wiki card', error);
      return { status: 'failed' };
    }

    return { content, attachments, status: 'success' };
  }
}
