import { Filters, Search } from '@local/client-contracts';
import { EventInfo, LogService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';
import { StaticSearchItem, TelemetryTrigger } from 'src/app/bar/views/results/models/results-types';
import { SearchClient } from '../search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { SearchResponseType } from '../search-response-type';
import { StaticItemsSourceSettings, StaticSearchItemType } from './static-items-source-settings';
import { SessionService } from '@shared/services/session.service';
import { firstValueFrom } from 'rxjs';

export class StaticItemsSearchClient implements SearchClient<StaticItemsSourceSettings> {
  private logger: Logger;

  constructor(logService: LogService, private sessionService: SessionService) {
    this.logger = logService.scope('StaticItemsSearchClient');
  }

  supportsSort(sort: Search.Sort): boolean {
    return true;
  }

  supportsFilters(filters: Filters.Values): boolean {
    return true;
  }

  search(request: SearchRequest<StaticItemsSourceSettings>, response: SearchResponse): SearchResponseType {
    return this.innerSearch(request, response);
  }

  private async innerSearch(request: SearchRequest<StaticItemsSourceSettings>, response: SearchResponse) {
    const { itemTypes, isExternalEmbed } = request.sourceSettings;
    const items = [];
    for (const type of itemTypes) {
      const item = await this.getRelevantItem(type, isExternalEmbed);
      items.push(item);
    }
    response.items = items;
    response.complete();
  }

  private async getRelevantItem(type: StaticSearchItemType, isExternalEmbed: boolean) {
    switch (type) {
      case 'open-external': {
        const currentSession = await firstValueFrom(this.sessionService.current$);
        const openExternalItem: StaticSearchItem = {
          type: 'static-search-item',
          icon: { type: 'font-icon', value: 'icon-search-icon-fixed' },
          title: isExternalEmbed ? 'View all search results' : `Search in ${currentSession?.workspace?.name}`,
          description: 'Search across all your apps, within documents, pages and more',
          invokeType: 'open-external',
        };
        return openExternalItem;
      }
    }
  }

  nextPage(request: SearchRequest<StaticItemsSourceSettings>, response: SearchResponse, trigger: TelemetryTrigger): Promise<void> {
    return;
  }

  destroy(id: number, sessionName: string): void {}

  getTelemetryEndEvent(response: SearchResponse): Partial<EventInfo>[] {
    return;
  }
}
