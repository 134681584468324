import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@Component({
  selector: 'banner-alert',
  templateUrl: './banner-alert.component.html',
  styleUrls: ['./banner-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class BannerAlertComponent {
  BANNER_BETA_MESSAGE = 'Optimized for Q&A';

  @Input() message: string = this.BANNER_BETA_MESSAGE;
  @Input() labelModel: { text: string; backgroundColor: string; textColor: string } = {
    text: 'Beta',
    backgroundColor: 'var(--color-primary)',
    textColor: 'var(--color-primary-btn-text)',
  };

  constructor(private cdr: ChangeDetectorRef) {}
}
