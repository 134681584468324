import { Pipe, PipeTransform } from '@angular/core';
export type HighlightStyle = 'yellow-bold' | 'default' | string;
/** @param ignoreFullWord - If the word equals to the highlight don't mark it. Defaults to true */
@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, words?: Array<string>, cssStyle: HighlightStyle = 'default', ignoreFullWord = true): any {
    if (!value) {
      return;
    }

    let ntext = value + '';
    if (words && Array.isArray(words)) {
      words.forEach((word) => {
        if (typeof word === 'number') word = word + '';
        const regEx = new RegExp(this.escapeRegExp(word), 'ig');
        ntext = ntext.replace(regEx, (x, index, p2, p3, offset, string) => {
          const execUnicode = /&.*2?;/g.exec(p2);
          const match = execUnicode?.[0];
          if (index > execUnicode?.index && index < execUnicode?.index + match?.length) {
            return x;
          }
          return '\x0c' + x + '\x1c'; // Adds space that later detected and replaced with span tag.
        });
      });
    }

    if (
      ignoreFullWord &&
      ntext.startsWith('\x0c') &&
      ntext.endsWith('\x1c') &&
      ntext.match(/\x0c/g)?.length === 1 &&
      ntext.match(/\x1c/g)?.length === 1
    ) {
      // whole word
      return value;
    }
    let cssClass: string = this.getHighLightClass(cssStyle);

    ntext = ntext.replace(new RegExp(/\x0c/, 'ig'), `<span class="${cssClass}">`);
    ntext = ntext.replace(new RegExp(/\x1c/, 'ig'), '</span>');

    return ntext;
  }

  private escapeRegExp(value: string) {
    return value.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  private getHighLightClass(style: HighlightStyle): string {
    switch (style) {
      case 'default':
        return 'text-bold-highlight';
      case 'yellow-bold':
        return 'text-highlight';
      default:
        return style;
    }
  }
}
