import { Injectable } from '@angular/core';
import { Logger } from '@unleash-tech/js-logger';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(public logger: Logger) {}

  scope(name: string, params: { [index: string]: any } = {}): Logger {
    return this.logger.scope(name, params);
  }
}
