import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UCheckboxComponent } from './u-checkbox.component';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [UCheckboxComponent],
  imports: [FormsModule, CommonModule, CheckboxModule],
  exports: [UCheckboxComponent],
})
export class UCheckboxModule {}
