import { delay } from '@local/ts-infra';

export async function pushTag(t) {
  const TIMEOUT = 1000;
  if (!(<any>window).dataLayer) {
    return;
  }
  (<any>window).dataLayer.push(t);
  const tm = Date.now();
  while ((<any>window).dataLayer.length && Date.now() - tm < TIMEOUT) {
    // wait for queue to drain
    await delay(100);
  }
}
