import { Links } from '@local/client-contracts';
import { LinkError } from './errors/link-error';

export type ExistingLinkInfo = { linkId: string; key: string; isRlp: boolean };
export type LinkResultStatus = 'success' | 'error' | 'cancel';

export interface LinkResult {
  id?: string;
  status: LinkResultStatus;
  error?: LinkError;
  createdLink?: Links.CreateLink;
}
