<ng-container *ngIf="!custom; else customTpl">
  <div class="title">
    <ng-content select="[overlay-title]"></ng-content><dynamic-ellipsis *ngIf="showDynamicEllipsis"></dynamic-ellipsis>
  </div>
  <div class="content">
    <ng-content select="[overlay-content]"></ng-content>
    <div class="bottom-note">
      <ng-content select="[overlay-note]"></ng-content>
    </div>
  </div>
  <div class="actions">
    <ng-content select="[overlay-actions]"></ng-content>
  </div>
  <div class="bottom-note">
    <ng-content select="[overlay-note]"></ng-content>
  </div>
</ng-container>
<ng-template #customTpl>
  <ng-content></ng-content>
</ng-template>
