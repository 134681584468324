import { Directive, EventEmitter, HostListener, Output, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[outsideClick]',
})
export class OutsideClickDirective {
  @Output() outsideClick: EventEmitter<MouseEvent> = new EventEmitter();
  @Input() ignoreElement: ElementRef;
  @Input() actualElement: ElementRef;

  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    const ref = this.actualElement ? this.actualElement : this.elementRef.nativeElement;
    if (!ref.contains(event.target)) {
      if (this.ignoreElement) {
        if (!this.ignoreElement.nativeElement.contains(event.target)) {
          this.outsideClick.emit(event);
        }
      } else {
        this.outsideClick.emit(event);
      }
    }
  }

  constructor(private elementRef: ElementRef) {}
}
