<header *ngIf="show">
  <span class="title">Emojis</span>
  <a target="_blank" class="remove-btn" (click)="clear.emit()">Remove</a>
</header>
<emoji-mart
  [set]="set"
  [showPreview]="showPreview"
  [emojiTooltip]="emojiTooltip"
  [autoFocus]="autoFocus"
  [color]="color"
  [emojiSize]="emojiSize"
  [darkMode]="darkMode"
  [backgroundImageFn]="backgroundImageFn"
  (emojiSelect)="emojiSelect.emit($event)"
></emoji-mart>
