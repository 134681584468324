import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightSearch',
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: any, args: any): any {
    if (args && value) {
      /* eslint-disable no-useless-escape */
      let pattern = args.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
      pattern = pattern
        .split(' ')
        .filter((t) => {
          return t.length > 0;
        })
        .join('|');
      const regex = new RegExp(pattern, 'gi');
      return value.replace(regex, (match) => `<mark>${match}</mark>`);
    } else {
      return value;
    }
  }
}
