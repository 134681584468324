import { Route, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';

export function navTreeNodeMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult {
  if (!segments.length) return;

  return {
    consumed: segments,
    posParams: {
      nodeId: new UrlSegment(segments.join('/'), {}),
    },
  };
}
