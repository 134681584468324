import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { UDatePickerComponent } from '../components/u-date-picker/u-date-picker.component';
import { CloseData, PopupRef } from './popup/popup-ref';
import { PopupService } from './popup/popup.service';
import { BackdropStyle, GlobalPosition } from './popup/popup-options';
import { DatePickerPopupData } from '../components/u-date-picker/date-picker-data.model';
import { DateValue } from '../components/u-date-picker/date-value.model';

@Injectable({
  providedIn: 'root',
})
export class DatePickerPopupService {
  private _valueSelected$: ReplaySubject<DateValue> = new ReplaySubject(1);
  private popupRef: PopupRef<UDatePickerComponent, DatePickerPopupData>;
  private selectedSub: Subscription;

  get valueSelected$() {
    return this._valueSelected$.asObservable();
  }

  set valueSelected(val: DateValue) {
    this._valueSelected$.next(val);
  }

  get close$(): Observable<CloseData> {
    return this.popupRef.close$;
  }

  constructor(private popupService: PopupService) {}

  private reset() {
    this.selectedSub?.unsubscribe();
    this._valueSelected$.complete();
    this._valueSelected$ = new ReplaySubject(1);
  }

  open(
    position: GlobalPosition | 'center',
    popupData: DatePickerPopupData,
    backdropStyle?: BackdropStyle
  ): PopupRef<UDatePickerComponent, DatePickerPopupData> {
    this.reset();
    this.popupRef = this.popupService.open<UDatePickerComponent, DatePickerPopupData>(position, UDatePickerComponent, popupData, {
      position: 'right',
      fullScreenDialog: true,
      backdropStyle,
    });
    this.selectedSub = this.popupRef.compInstance.onSelectedChange.subscribe((v) => {
      if (v.type === 'range') {
        if (popupData.maxDate && v.start && !v.end) {
          v.end = popupData.maxDate;
        }
        if (popupData.minDate && !v.start && v.end) {
          v.start = popupData.minDate;
        }
      }
      this._valueSelected$.next(v);
    });
    return this.popupRef;
  }
}
