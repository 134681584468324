import { Injectable } from '@angular/core';
import { Accounts, Groups, Permissions, SessionInfo, Workspace } from '@local/client-contracts';
import { capitalCase, extractInitials } from '@local/ts-infra';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AccountsService } from '@shared/services/accounts.service';
import { GroupsService } from '@shared/services/groups.service';
import { SessionService } from '@shared/services/session.service';
import { cloneDeep, isEmpty } from 'lodash';
import { BehaviorSubject, combineLatest, filter, firstValueFrom } from 'rxjs';
import {
  DEFAULT_SCOPE_PERMISSION_OPTIONS,
  ScopePermissionsOptions,
} from '../components/share-options-permissions/share-options-permissions.component';
import { AvatarItemModel } from '../models/avatar-item.model';
import { WorkspacesService } from './workspaces.service';

export type WorkspaceMemberDetails = Accounts.WorkspaceAccount | Groups.WorkspaceGroup | Partial<Accounts.WorkspaceAccount>;

export type WorkspaceMemberDetailsType = 'group' | 'account' | 'workspace' | 'external-group';

export interface PermissionDictionary {
  [id: string]: { scope?: Permissions.ShareScope; details?: WorkspaceMemberDetails; type?: WorkspaceMemberDetailsType };
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AvatarListService {
  private readonly COLORS_LIMIT = 6;
  private colorsSeq = 1;
  private ready$ = new BehaviorSubject<boolean>(null);
  private sessionInfo: SessionInfo;
  private workspaceMemberDetailsMap: { [id: string]: { details: WorkspaceMemberDetails; type?: WorkspaceMemberDetailsType } } = {};

  get workspace(): Workspace.Workspace {
    return this.sessionInfo?.workspace;
  }

  constructor(
    private sessionService: SessionService,
    private accountsService: AccountsService,
    private groupsService: GroupsService,
    private workspaceService: WorkspacesService
  ) {
    this.initSession();
  }

  private initSession(): void {
    combineLatest([this.sessionService.current$, this.accountsService.all$, this.groupsService.all$]).subscribe({
      next: ([sessionInfo, accounts, groups]) => {
        if (!sessionInfo) {
          this.ready$.next(false);
          return;
        }
        this.sessionInfo = cloneDeep(sessionInfo);
        if (this.sessionInfo.workspace) {
          this.sessionInfo.workspace.accounts = accounts;
          this.sessionInfo.workspace.groups = groups;
        }

        this.workspaceMemberDetailsMap = {
          ...Object.fromEntries([
            ...(this.workspace?.accounts || []).map(({ id, ...data }) => [id, { details: data, type: 'account' }]),
            ...(this.workspace?.groups || []).map(({ id, ...data }) => [id, { details: data, type: 'group' }]),
          ]),
        };
        this.ready$.next(true);
      },
    });
  }

  getInitialImg(name: string): any {
    const initials = {
      type: 'initials',
      value: {
        color: `ml-c${this.colorsSeq}`,
        initials: name ? extractInitials(name)[0] : '',
      },
    };
    this.colorsSeq = this.colorsSeq >= this.COLORS_LIMIT ? 1 : this.colorsSeq + 1;
    return initials;
  }

  async createAvatarList(
    accountId: string,
    shareOptions: Permissions.ShareOptions,
    viewDetails = true,
    scopePermissionsOptions: ScopePermissionsOptions[] = DEFAULT_SCOPE_PERMISSION_OPTIONS
  ): Promise<AvatarItemModel[]> {
    await firstValueFrom(this.ready$.pipe(filter((c) => !!c)));
    const avatarsList: AvatarItemModel[] = [];
    const owner = await this.getOwnerAvatar(accountId, viewDetails);
    if (!isEmpty(owner)) {
      avatarsList.push(owner);
    }
    if (shareOptions?.level === 'Protected') {
      const avatarList: AvatarItemModel[] = await this.addSharedMemberToAvatarList(
        accountId,
        shareOptions,
        viewDetails,
        scopePermissionsOptions
      );
      if (!isEmpty(avatarList)) {
        avatarsList.push(...avatarList);
      }
    }
    return avatarsList;
  }

  async createOwOrAdminsAvatarList(): Promise<AvatarItemModel[]> {
    await firstValueFrom(this.ready$.pipe(filter((c) => !!c)));
    const accounts = this.sessionInfo.workspace?.accounts || [];
    const avatarsList: AvatarItemModel[] = [];
    for (const account of accounts) {
      if (!account.isAdmin && !account.isOwner) {
        continue;
      }
      avatarsList.push(this.accountToAvatarItem(account));
    }
    return avatarsList;
  }

  private accountToAvatarItem(account: Accounts.WorkspaceAccount): AvatarItemModel {
    const item: AvatarItemModel = {
      imgUrl: account.picture,
      name: account.name,
      description: account.isOwner ? 'WS Owner' : 'Admin',
    };
    if (!item.imgUrl) {
      item.initialsImg = this.getInitialImg(account.name);
    }
    return item;
  }

  async getOwnerAvatar(accountId: string, viewDetails = true): Promise<AvatarItemModel> {
    await firstValueFrom(this.ready$.pipe(filter((c) => !!c)));
    if (this.workspaceService.isMe(accountId)) {
      const item: AvatarItemModel = {
        imgUrl: this.sessionInfo?.user?.picture,
        name: `${this.sessionInfo?.user?.name} (You)`,
        isNameFromEmail: this.sessionInfo?.user?.isNameFromEmail,
      };
      return viewDetails
        ? {
            ...item,
            email: this.sessionInfo?.user?.email,
            description: 'Owner',
          }
        : item;
    }
    const owner = this.workspace?.accounts?.find((a) => a.id === accountId) || this.getDefaultAccount();
    const item: AvatarItemModel = { imgUrl: owner?.picture, name: owner.name || '', isNameFromEmail: owner.isNameFromEmail };
    return viewDetails ? { ...item, email: owner.email, description: 'Owner' } : item;
  }

  private getDefaultAccount(): Partial<Accounts.WorkspaceAccount> {
    return { name: 'N/A' };
  }

  getAvatarById(userId: string, key = 'userId'): AvatarItemModel {
    const user = this.workspace?.accounts?.find((a) => a[key] === userId) || this.getDefaultAccount();
    return {
      imgUrl: user.picture,
      name: user.name,
      email: user.email,
      isNameFromEmail: user.isNameFromEmail,
    };
  }

  private async addSharedMemberToAvatarList(
    accountId: string,
    shareOptions: Permissions.ShareOptions,
    viewDetails = true,
    scopePermissionOptions: ScopePermissionsOptions[]
  ): Promise<AvatarItemModel[]> {
    const avatarsList: AvatarItemModel[] = [];
    const dic: PermissionDictionary = (await this.initPermissionDictionary(accountId, shareOptions, scopePermissionOptions)) || {};
    const workspacePermission = dic[this.workspace?.id];
    if (workspacePermission) {
      avatarsList.push({
        ...this.createWorkspaceAvatar(),
        description: viewDetails ? scopePermissionOptions.find((s) => workspacePermission.scope == s.value)?.label : null,
      });
    }

    avatarsList.push(
      ...Object.values(dic)
        .filter((obj) => obj.type !== 'workspace')
        .map((value) => {
          const account = value.details as Accounts.WorkspaceAccount;
          const group = value.details as Groups.WorkspaceGroup;
          return {
            imgUrl: account?.picture,
            name: this.getName(value.type, account, group),
            email: viewDetails ? account?.email : null,
            description: viewDetails ? scopePermissionOptions.find((s) => value.scope == s.value)?.label : null,
          } as AvatarItemModel;
        })
    );
    return avatarsList;
  }

  async initPermissionDictionary(
    accountId: string,
    shareOptions: Permissions.ShareOptions,
    scopePermissionsOptions: ScopePermissionsOptions[]
  ): Promise<PermissionDictionary> {
    if (!shareOptions?.permissions) {
      return {};
    }
    const allowScopeWrite = scopePermissionsOptions.find((s) => s.value == 'write');
    const allowScopeRead = scopePermissionsOptions.find((s) => s.value == 'read');
    const allowScopeSearch = scopePermissionsOptions.find((s) => s.value == 'search');

    const scopeWrite: Permissions.ShareOptionsItem = shareOptions.permissions.find((a) => a.scope === 'write');
    const scopeRead: Permissions.ShareOptionsItem = shareOptions.permissions.find((a) => a.scope === 'read');
    const scopeSearch: Permissions.ShareOptionsItem = shareOptions.permissions.find((a) => a.scope === 'search');
    const dicScopes: PermissionDictionary = {};
    const externalGroups: string[] = [];

    if (scopeWrite && allowScopeWrite) {
      this.addMemberToPermissionDictionary(dicScopes, externalGroups, scopeWrite.accountIds, 'account', 'write', (id) => id === accountId);
      this.addMemberToPermissionDictionary(dicScopes, externalGroups, scopeWrite.groupIds, 'group', 'write', () => false);
      this.addMemberToPermissionDictionary(dicScopes, externalGroups, scopeWrite.workspaceIds, 'workspace', 'write', () => false);
    }

    if (scopeRead && allowScopeRead) {
      this.addMemberToPermissionDictionary(
        dicScopes,
        externalGroups,
        scopeRead.accountIds,
        'account',
        'read',
        (id) => id === accountId || !!dicScopes[id]
      );
      this.addMemberToPermissionDictionary(dicScopes, externalGroups, scopeRead.groupIds, 'group', 'read', (id) => !!dicScopes[id]);
      this.addMemberToPermissionDictionary(dicScopes, externalGroups, scopeRead.workspaceIds, 'workspace', 'read', (id) => !!dicScopes[id]);
    }

    if (scopeSearch && allowScopeSearch) {
      this.addMemberToPermissionDictionary(
        dicScopes,
        externalGroups,
        scopeSearch.accountIds,
        'account',
        'search',
        (id) => id === accountId || !!dicScopes[id]
      );
      this.addMemberToPermissionDictionary(dicScopes, externalGroups, scopeSearch.groupIds, 'group', 'search', (id) => !!dicScopes[id]);
      this.addMemberToPermissionDictionary(
        dicScopes,
        externalGroups,
        scopeSearch.workspaceIds,
        'workspace',
        'search',
        (id) => !!dicScopes[id]
      );
    }
    if (externalGroups.length) {
      try {
        const groups = await this.groupsService.getGroups(externalGroups);
        if (groups.length) {
          for (const group of groups) {
            const typePermission = scopeWrite.groupIds.includes(group.id) ? 'write' : 'read';
            this.workspaceMemberDetailsMap[group.id] = { details: group, type: 'external-group' };
            dicScopes[group.id] = { scope: typePermission, details: group, type: 'external-group' };
          }
        }
      } catch (error) {
        console.error('Error occured in get external groups', error);
      }
    }

    return dicScopes;
  }

  private addMemberToPermissionDictionary(
    dicScopes: PermissionDictionary,
    externalGroups: string[],
    permissions: string[],
    typeMember: WorkspaceMemberDetailsType,
    typePermission: Permissions.ShareScope,
    condition: (id: string) => boolean
  ) {
    if (!permissions?.length) return;
    for (const id of permissions) {
      if (condition(id)) {
        continue;
      }
      const memberDetails = this.workspaceMemberDetailsMap[id];
      if (!memberDetails && typeMember === 'group') {
        externalGroups.push(id);
        continue;
      }

      dicScopes[id] = {
        scope: typePermission,
        details: { id, ...(memberDetails?.details || this.getDefaultAccount()) },
        type: memberDetails?.type || typeMember,
      };
    }
  }

  getName(type: WorkspaceMemberDetailsType, account?: Accounts.WorkspaceAccount, group?: Groups.WorkspaceGroup): string {
    if (['group', 'external-group'].includes(type)) {
      return capitalCase(group.name || '');
    }
    if (type == 'account') {
      if (this.workspaceService.isMe(account.id)) {
        return `${account?.name} (You)`;
      }
      return account?.name;
    }
  }

  createWorkspaceAvatar(): AvatarItemModel {
    return {
      imgUrl: this.workspaceService.getLogo(),
      name: this.workspace?.name,
    };
  }
}
