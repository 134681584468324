import { Injectable } from '@angular/core';
import { StaticData } from '@local/client-contracts';
import { ServicesRpcService } from '@shared/services';
import { StaticDataRpcInvoker } from './invokers/static-data-rpc-invoker';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StaticDataService {
  private service: StaticData.Service;

  constructor(services: ServicesRpcService) {
    this.service = services.invokeWith(StaticDataRpcInvoker, 'staticdata');
  }

  getAppsByView$(view: StaticData.ViewName): Observable<string[]> {
    return this.service.getAppsByView$(view);
  }

  getFiltersPolicy$(): Observable<StaticData.FiltersPolicy> {
    return this.service.filtersPolicy$;
  }
}
