import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { TelemetryService } from '@shared/services';
import { KeyboardService } from '@shared/services/keyboard.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { DisplaySearchFilterValue, Filter, SearchFilterValue } from '../models';
import { MultiSelectFilterBaseComponent } from '../multi-select-filter-base.component';

@Component({
  selector: 'avatar-multi-select-filter',
  templateUrl: './avatar-multi-select-filter.component.html',
  styleUrls: ['./avatar-multi-select-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarMultiSelectFilterComponent extends MultiSelectFilterBaseComponent<Filter> implements OnInit, OnDestroy {
  DISPLAYED_AVATARS_LIMIT: number = 2;
  displayedAvatars: SearchFilterValue[];

  constructor(
    public cdr: ChangeDetectorRef,
    protected telemetryService: TelemetryService,
    public ref: ElementRef,
    protected keyboardService: KeyboardService,
    protected hubService: HubService
  ) {
    super(cdr, telemetryService, ref, keyboardService, hubService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.updateDisplayedAvatars();
    this.cdr.detectChanges();
  }

  onUpdateModel() {
    super.onUpdateModel();
    this.updateDisplayedAvatars();
    this.cdr.detectChanges();
  }

  private updateDisplayedAvatars(): void {
    let sorted = this.model.values.sort((a, b) => {
      if (a.disabled && !b.disabled) return 1;
      else if (!a.disabled && b.disabled) return -1;
      else return a.value - b.value;
    });

    if (this.model.values.length < 4) {
      this.displayedAvatars = sorted;
      return;
    }

    const moreAvatarLabel: string = `+${this.model.values.length - this.DISPLAYED_AVATARS_LIMIT}`;
    const moreAvatar: SearchFilterValue = this.createMoreAccountsIcon(moreAvatarLabel);
    this.displayedAvatars = [...sorted.slice(0, this.DISPLAYED_AVATARS_LIMIT), moreAvatar];
  }

  private createMoreAccountsIcon(moreAvatarLabel: string): SearchFilterValue {
    if (!parseInt(moreAvatarLabel)) return;
    return {
      id: moreAvatarLabel,
      filterName: this.model.name,
      icon: {
        type: 'account',
        value: {
          type: 'initials',
          value: {
            initials: moreAvatarLabel,
            color: 'ml-c0',
          },
        },
      },
      value: moreAvatarLabel,
      isMoreAccounts: true,
    };
  }

  isItemSelected(item: SearchFilterValue): boolean {
    if (item.isMoreAccounts) return false;
    if (this.selectedItems?.length === 0) return false;
    return !!this.selectedItems.find((i) => i.value === item.value);
  }

  onAccountClick(event: PointerEvent, item: SearchFilterValue) {
    event.stopPropagation();
    if (item.isMoreAccounts || item.disabled) return;
    const selectedAccountIndex = this.selectedItems.findIndex((i) => i.value === item.value);
    let action: 'Add' | 'Remove' = 'Add';
    if (selectedAccountIndex === -1) {
      this.selectedItems.push(item as DisplaySearchFilterValue);
    } else {
      action = 'Remove';
      this.selectedItems.splice(selectedAccountIndex, 1);
    }
    this.onChange({ itemValue: item, action, value: this.selectedItems, originalEvent: event });
    this.cdr.markForCheck();
  }

  canOpenPanel() {
    return this.model?.values.length > this.DISPLAYED_AVATARS_LIMIT + 1;
  }

  isElementIsOutside(event: any) {
    //if element is the arrow don't hide element
    if (
      event.target.classList.contains('avatar-multi-select-filter-arrow-icon') ||
      event.path[1].classList.contains('avatar-multi-select-filter-arrow-icon')
    )
      return false;

    return true;
  }
}
