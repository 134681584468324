import { Injectable } from '@angular/core';
import { observable } from '@local/common';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { HubService } from 'src/app/bar/services/hub.service';

@Injectable()
export class ResultFiltersFocusService {
  private _currentFiltersFocus$ = new BehaviorSubject<number>(null);

  @observable
  get currentFiltersFocus$(): BehaviorSubject<number> {
    return this._currentFiltersFocus$;
  }

  constructor(private hubService: HubService) {}

  focus$(id: number) {
    return combineLatest([this.hubService.focusPosition$, this.currentFiltersFocus$]).pipe(
      map(([hubFocus, filtersFocus]) => {
        return hubFocus === 'filters' && id == filtersFocus;
      })
    );
  }
}
