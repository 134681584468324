import { Injectable } from '@angular/core';
import { Commands, Resources, Search } from '@local/client-contracts';
import { observable } from '@local/common';
import { ResourcesRpcInvoker } from '@local/common-web';
import { Logger } from '@unleash-tech/js-logger';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { ApplicationsService } from './applications.service';
import { LogService } from './log.service';
import { ServicesRpcService } from './rpc.service';

export interface AppResource {
  title: string;
  service: string;
  app: string;
}

@Injectable({
  providedIn: 'root',
})
export class ResourcesService implements Resources.Service {
  service: Resources.Service;
  private logger: Logger;
  private _typeMap$: ReplaySubject<{ [type: string]: AppResource }> = new ReplaySubject(1);

  @observable
  get typeMap$() {
    return this._typeMap$.asObservable();
  }

  constructor(logService: LogService, private services: ServicesRpcService, private apps: ApplicationsService) {
    this.logger = logService.scope(this.constructor.name);
    this.service = this.services.invokeWith(ResourcesRpcInvoker, 'resources');

    this.apps.all$.subscribe((apps) => {
      const res: { [type: string]: AppResource } = {};

      for (const app of apps) {
        for (const r of app.resources) {
          res[r.type] = { title: r.title, app: app.name, service: r.service };
        }
      }

      this._typeMap$.next(res);
    });
  }

  async get(type: string): Promise<AppResource> {
    return (await firstValueFrom(this.typeMap$))[type];
  }

  async desktopApp(id: string): Promise<boolean> {
    return this.service.desktopApp(id);
  }

  async openWith(id: string): Promise<Commands.OpenWith> {
    return this.service.openWith(id);
  }

  listShareItems(url: string, resourceId?: string, resourceTitle?: string): Promise<Resources.ShareItem[]> {
    return this.service.listShareItems(url, resourceId, resourceTitle);
  }

  feedback(request: Resources.FeedbackRequest): Promise<void> {
    return this.service.feedback(request);
  }

  getBulkByExternalId(externalIds: string[], cache: Resources.CacheMode, origin: Search.Origin): Promise<Resources.ResourceEntry[]> {
    return this.service.getBulkByExternalId(externalIds, cache, origin);
  }

  searchFilters(request: Resources.SearchFiltersRequest): Promise<Resources.SearchFiltersResponse> {
    throw new Error('Method not implemented.');
  }

  getFilterStats(request: Resources.FilterStatsRequest): Promise<Resources.FilterStatsResponse> {
    throw new Error('Method not implemented.');
  }
}
