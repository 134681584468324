import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { Commands } from '@local/client-contracts';
import { EventsService } from '@shared/services';
import { isRightClick } from '@shared/utils';
import { Tooltip } from 'primeng/tooltip';
import { CommandsService } from 'src/app/bar/services/commands/commands.service';
@Component({
  selector: 'auth-loader-overlay',
  templateUrl: './auth-loader-overlay.component.html',
  styleUrls: ['./auth-loader-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthLoaderOverlayComponent implements OnDestroy {
  @Input() clickTrack: string;
  @Input() showCancel = true;
  @Input() set link(value: string) {
    this.onChanges();
    this._link = value;
  }
  @Output() onCancel = new EventEmitter<void>();
  @Output() linkClicked = new EventEmitter<void>();
  @ViewChild(Tooltip) tooltip: Tooltip;
  showLink: boolean = false;
  private showLinkTimer: NodeJS.Timeout;
  private _link: string;

  tooltipText = 'Right click to copy';

  get location() {
    return { title: window.location.pathname.replace(/^[\/\s]/gm, '') };
  }

  get link() {
    return this._link;
  }

  constructor(private cdr: ChangeDetectorRef, private commandService: CommandsService, private eventsService: EventsService) {}

  onChanges() {
    if (!this.link) {
      this.showLinkTimer = setTimeout(() => {
        this.showLink = true;
        this.cdr.markForCheck();
      }, 5000);
    }
  }

  ngOnDestroy() {
    this.teardownTimer();
  }

  private teardownTimer() {
    if (this.showLinkTimer) {
      clearTimeout(this.showLinkTimer);
      this.showLinkTimer = null;
    }
  }

  onCancelClick() {
    this.onCancel.emit();
    this.eventsService.event('auth_note', { target: 'cancel', location: this.location });
  }

  onLinkClicked(event: MouseEvent) {
    event.preventDefault();
    this.linkClicked.emit();
    this.eventsService.event('auth_note', { target: 'open_externally', location: this.location });
  }

  async onRightClick(event: MouseEvent) {
    if (!isRightClick(event)) return;

    const command: Commands.CopyClipboard = { type: 'copy-clipboard', value: this.link };

    await this.commandService.executeCommand(command, {});
    if (this.tooltip) {
      this.tooltipText = 'Copied!';
      this.cdr.detectChanges();
      this.tooltip.show();
      this.closeTooltip();
    }
    this.eventsService.event('auth_note', { label: 'copy', location: this.location });
  }

  closeTooltip() {
    setTimeout(() => {
      if (this.tooltip) {
        this.tooltipText = 'Right click to copy';
        this.cdr.detectChanges();
      }
    }, 1000);
  }
}
