import { Injectable } from '@angular/core';
import { ClientStorage, ClientStorageRpcInvoker, KeyValueStorage, observable } from '@local/common';
import { isNativeWindow } from '@local/common-web';
import { EmbedService } from '@shared/embed.service';
import { Observable } from 'rxjs';
import { NativeServicesRpcService, ServicesRpcService } from '.';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ClientStorageService {
  private _storage: ClientStorage;
  private fastStorage: Promise<KeyValueStorage>;
  constructor(services: ServicesRpcService, nativeServices: NativeServicesRpcService, embed: EmbedService, local: LocalStorageService) {
    if (!isNativeWindow()) this.fastStorage = local.create('client');

    this._storage = (nativeServices || services).invokeWith(ClientStorageRpcInvoker, 'clientstorage');
  }

  @observable
  current$<T>(field: string): Observable<T> {
    return this._storage.current$<T>(field);
  }

  async set(field: string, v: any): Promise<void> {
    return this._storage.set(field, v);
  }

  async get<T>(field: string): Promise<T> {
    if (this.fastStorage) return (await this.fastStorage).entry<T>(field).get();
    return this._storage.get<T>(field);
  }
}
