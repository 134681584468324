import { Injectable } from '@angular/core';
import { Collections, Commands, Downloads, Permissions, Resources, Search, Wiki } from '@local/client-contracts';
import { ManualPromise } from '@local/common';
import { generateId, isStaticCollection, isWikiCollection } from '@local/common-web';
import { cutText, getExtensionByFileName } from '@local/ts-infra';
import { extension as getExtensionByMimeType } from '@local/common-web';
import { PopupService } from '@local/ui-infra';
import { EventsService, LogService } from '@shared/services';
import { DownloadsService } from '@shared/services/downloads.service';
import { RouterService } from '@shared/services/router.service';
import { Logger } from '@unleash-tech/js-logger';
import { cloneDeep } from 'lodash';
import { filter, firstValueFrom, take } from 'rxjs';
import { AddToCollectionSelect } from '../../components/selected-item-popup/models/add-to-collection-select';
import { SelectedItemPopupData } from '../../components/selected-item-popup/models/select-item-base';
import { SelectedItem, TelemetryEvent } from '../../components/selected-item-popup/selected-item-popup.component';
import { CommandContext, isWikiCardLocal, isWikiCardRemote } from '../../views';
import { collectionContent } from '../../views/collections-page/helpers/collection.content';
import { CollectionPopupService } from '../../views/collections-page/services/collection-popup.service';
import { ResultSettings } from '../../views/results/utils/results-settings';
import { AvatarListService } from '../avatar-list.service';
import { BlobsService } from '../blob.service';
import { CollectionsUtilService } from '../collections-util.service';
import { CollectionsService } from '../collections.service';
import { HubService } from '../hub.service';
import { PermissionsService } from '../permissions.service';
import { SearchOptions, SearchResultContext, SearchService, SearchSession } from '../search';
import { CollectionsSourceSettings } from '../search/client';
import { ShowToasterService } from '../show-toaster.service';
import { UrlResolverService } from '../url-resolver.service';
import { WikiCardsService } from '../wikis/wiki-cards.service';
import { WorkspacesService } from '../workspaces.service';
import { OpenSelectPopupModel, SelectItemsBasePopupService } from './select-items-popup-base.service';
import { wikiContent } from '../../views/collections-page/helpers/wiki/wiki.content';

interface StaticCollectionItem extends Collections.CollectionItem {
  title?: string;
}

type OpenCollectionView = {
  collection: Collections.Collection;
  mode?: 'new' | 'edit' | 'new-from';
  navigate?: boolean;
  wikiCard?: Wiki.UpsertCardRequest;
};

type UpdateCollection = {
  collectionId: string;
  actions: Collections.UpdateAction[];
  shareOptions?: Permissions.ShareOptions;
  showToaster: boolean;
  toasterTitle?: string;
  showToasterButton: boolean;
};

@Injectable()
export class AddToCollectionPopupService extends SelectItemsBasePopupService<AddToCollectionSelect> {
  private readonly THUMBNAIL_EXPIRED_IN_MS: number = 7 * 24 * 60 * 60 * 1000;
  private readonly FILE_MAX_SIZE: number = 5 * 1024 * 1024; //5mb
  private readonly SELECTED_POPUP_TYPE = ['add-to-collection-popup', 'move-to-collection-popup'];

  readonly ADD_COLLECTION_DATA: SelectedItemPopupData = {
    title: collectionContent.addToCollection,
    type: 'collection',
    description: collectionContent.addToCollectionPopupMessage,
    placeholder: 'Select',
  };
  readonly MOVE_COLLECTION_DATA: SelectedItemPopupData = {
    title: collectionContent.moveToCollection,
    type: 'collection',
    buttonText: 'Move',
    description: collectionContent.addToCollectionPopupMessageWiki,
    placeholder: 'Select',
    warningMessage: collectionContent.moveToCollectionWarningMessage,
  };
  readonly MOVE_WIKI_DATA: SelectedItemPopupData = {
    title: wikiContent.moveToWiki,
    type: 'collection',
    buttonText: 'Move',
    description: wikiContent.moveToWikiPopupMessage,
    placeholder: 'Select',
    warningMessage: wikiContent.moveToWikiWarningMessage,
  };
  private url: string;
  private urlItem: Collections.UrlItem;
  private resourceItem: Search.ResultResourceItem;
  private urlItemPromise: ManualPromise<void> = new ManualPromise();
  private collections: Collections.Collection[] = [];
  private searchSession: SearchSession;
  protected logger: Logger;

  wikiId: string;

  constructor(
    protected searchService: SearchService,
    protected popupService: PopupService,
    protected workspacesService: WorkspacesService,
    protected wikiCardsService: WikiCardsService,
    protected urlResolverService: UrlResolverService,
    protected avatarListService: AvatarListService,
    protected collectionsUtilService: CollectionsUtilService,
    protected collectionsService: CollectionsService,
    protected eventsService: EventsService,
    protected hubService: HubService,
    protected routerService: RouterService,
    protected collectionPopupService: CollectionPopupService,
    protected downloadsService: DownloadsService,
    protected blobsService: BlobsService,
    protected permissionsService: PermissionsService,
    protected showToasterService: ShowToasterService,

    logService: LogService
  ) {
    super(popupService, workspacesService);
    this.logger = logService.scope('AddToCollectionPopupService');
    this.isInvalidItem = (selectedItem: SelectedItem) => this.isCollectionItem() && !selectedItem?.collection;
    this.initOpenSelectPopupSub();
  }

  initOpenSelectPopupSub() {
    this.collectionsService.openSelectPopup$
      .pipe(filter((r) => this.SELECTED_POPUP_TYPE.includes(r.type)))
      .subscribe(async (res: OpenSelectPopupModel) => {
        const setUpData = res?.options?.doSetup
          ? await this.getSelectedItemData(res.options.data.command, res.options.data.context)
          : res.data;
        const openedData = res.type === 'move-to-collection-popup' ? this.getMoveToData(setUpData.item) : this.ADD_COLLECTION_DATA;
        this.openSelectedItemPopup({ ...openedData, ...setUpData } as AddToCollectionSelect);
      });
  }

  private getMoveToData(item: any) {
    return this.isCardItem(item) ? this.MOVE_WIKI_DATA : this.MOVE_COLLECTION_DATA;
  }

  private isCardItem(item: any) {
    return item?.source === 'wiki-collection-items';
  }

  private async getSelectedItemData(command: Commands.Command, context: CommandContext): Promise<AddToCollectionSelect> {
    return {
      item: context.item,
      url: (<Commands.DynamicCommand>command).value,
      currentUser: this.collectionsService.currentUser,
      collections: [],
    };
  }

  private get currentCollectionId(): string {
    return this.data.item?.collectionId;
  }

  protected async init() {
    this.getIsWiki();
    this.handleUrlItem();
    await this.initCollections();
  }

  protected onPrimaryButtonClick() {
    this.addToCollection();
  }

  protected onTelemetryEvent(event: TelemetryEvent) {
    const selectedItem = this.selectedItem as SelectedItem;
    this.eventsService.event('collections.add_collection', {
      location: { title: this.hubService.currentLocation },
      target: event?.target ?? selectedItem?.isNew ? 'new_collection' : 'existing_collection',
      label: event?.trigger ?? 'mouse_click',
    });
  }

  private getIsWiki() {
    if (isWikiCardLocal(this.data.item)) {
      this.wikiId = this.data.item.id;
    } else if (isWikiCardRemote(this.data.item)) {
      this.wikiId = this.data.item.resource?.externalId;
    } else {
      this.wikiId = null;
    }
  }

  private handleUrlItem() {
    if (!this.data.url) {
      return;
    }
    this.url = this.data.url;
    this.urlResolverService
      .resolve(this.url)
      .then((res: Search.ResultResourceItem | Resources.WebsiteMetadata) => {
        if (!res) {
          this.logger.error('onAddLink error');
          this.urlItemPromise.resolve();
          return;
        }
        if (res.type === 'WebsiteMetadata') {
          const now = Date.now();
          const currentUser = this.data.currentUser;
          const item: Collections.UrlItem = {
            kind: 'url',
            url: this.url,
            id: generateId(),
            modifiedTime: now,
            modifiedBy: currentUser?.id,
            title: res?.title === '' || !res?.title ? 'Untitled' : res.title,
            collectionId: this.currentCollectionId,
            createdTime: now,
            thumbnail: res?.banner,
            thumbnailExpired: res?.banner ? Date.now() + this.THUMBNAIL_EXPIRED_IN_MS : undefined,
            addedTime: Date.now(),
          };
          this.urlItem = item;
        } else {
          this.resourceItem = res;
        }
        this.urlItemPromise.resolve();
      })
      .catch((err) => {
        this.logger.error('onAddLink error', err);
        this.urlItemPromise.resolve();
      });
  }

  private async initCollections() {
    const checkCollection = this.wikiId ? (c) => isWikiCollection(c) : (c) => isStaticCollection(c);
    this.searchSession = this.searchService.getOrCreateSearchSession('collections');
    const options: SearchOptions = {
      resetSession: true,
      sources: [
        {
          ...ResultSettings.defaultCollections,
          tag: 'add-to-collections-popup',
        } as CollectionsSourceSettings,
      ],
      trigger: 'collections/user_query',
      includeNullResultContext: true,
    };

    const ctx: SearchResultContext = await firstValueFrom(
      this.searchSession.search$(options).pipe(
        filter((c) => c?.searchCompleted),
        take(1)
      )
    );
    this.searchSession.destroy();
    if (!ctx) {
      return;
    }
    const collectionUtil = ctx?.items.filter((i) => i.type === 'collection') as Collections.Collection[];
    this.collections = collectionUtil;
    const availableCollections = [];
    for (const collection of collectionUtil) {
      if (this.data.type === 'collection') {
        if (
          this.collectionsUtilService.canEdit(collection, this.data.type, this.data.tabId) &&
          this.currentCollectionId !== collection.id &&
          (!this.isCollectionItem() || collection.id !== this.data?.item?.id) &&
          checkCollection(collection)
        ) {
          availableCollections.push(collection);
        }
      }
    }
    this.data.itemsList = await this.collectionsUtilService.initSelectedItems(availableCollections);
  }

  private async addToCollection() {
    const selectedItem = this.selectedItem as SelectedItem;
    if (selectedItem.isNew) {
      this.addNewToCollection();
      return;
    }
    if (this.wikiId) {
      this.wikiCardsService.update({ card: { id: this.wikiId, collectionId: selectedItem.collection.id }, updateModifiedTime: true });
      setTimeout(() => {
        this.selectedItemPopupRef.destroy();
      }, 0);
      return;
    }
    const staticItemToAdd: Collections.StaticItem | StaticCollectionItem = await this.getStaticItem(selectedItem.collection.id);
    if (!staticItemToAdd) {
      return;
    }

    const collection: Collections.StaticCollection = <Collections.StaticCollection>selectedItem.collection;
    const items = collection.items ? cloneDeep(collection.items) : [];
    items.unshift(staticItemToAdd as any);
    const updateAction: Collections.UpdateAction = { field: 'items', type: 'Update', value: items };
    const toasterTitle = this.getToasterTitle(selectedItem.collection?.title, staticItemToAdd as any);
    this.updateCollection({
      collectionId: selectedItem.collection.id,
      actions: [updateAction],
      showToaster: true,
      toasterTitle: toasterTitle,
      showToasterButton: this.allowOpenCollectionToasterButton,
    });
  }

  async addNewToCollection() {
    const selectedItem = this.selectedItem as SelectedItem;
    const id = generateId();
    if (this.wikiId) {
      await this.openCollectionView({
        collection: { kind: 'Wiki', title: selectedItem.name, id } as Wiki.WikiCollection,
        mode: 'new-from',
        navigate: false,
        wikiCard: {
          card: {
            id: this.wikiId,
            collectionId: id,
          },
        },
      });
    } else {
      await this.collectionsService.openCollectionView(
        { kind: 'Static', title: selectedItem.name, id } as Collections.StaticCollection,
        'new-from',
        false
      );
      const staticItemToAdd: Collections.StaticItem | StaticCollectionItem = await this.getStaticItem(id);
      if (!staticItemToAdd) {
        return;
      }

      const updateAction: Collections.UpdateAction = { field: 'items', type: 'Update', value: [staticItemToAdd] };
      const toasterTitle = this.getToasterTitle(selectedItem.name, staticItemToAdd as any);
      await this.collectionsService.updateCollection(id, [updateAction], true, toasterTitle, null, this.allowOpenCollectionToasterButton);
    }
  }

  private async openCollectionView(data: OpenCollectionView) {
    await this.collectionsService.openCollectionView(data.collection, data.mode, data.navigate, true, true);
    if (data.wikiCard) {
      await this.wikiCardsService.update({ ...data.wikiCard, updateModifiedTime: true });
    }
  }

  private async updateCollection(data: UpdateCollection) {
    this.collectionsService.updateCollection(
      data.collectionId,
      data.actions,
      data.showToaster,
      data.toasterTitle,
      data.shareOptions,
      data.showToasterButton
    );
  }

  private async getStaticItem(collectionId: string): Promise<Collections.StaticItem | StaticCollectionItem> {
    if (this.isCollectionItem()) {
      const collectionData = this.collections.find((c) => c.id === this.data.item.id);
      return {
        kind: 'collection',
        id: this.data.item.id,
        collectionId: this.data.item.id,
        title: collectionData.title,
        addedTime: Date.now(),
      };
    }
    let staticItemToAdd: Collections.StaticItem;
    if (this.url) {
      await this.urlItemPromise;
      if (!this.urlItem && !this.resourceItem) {
        return;
      }
      staticItemToAdd = this.urlItem ? this.urlItem : await this.getLinkResourceStaticItem(collectionId, this.resourceItem);
    } else {
      staticItemToAdd = await this.getLinkResourceStaticItem(collectionId);
    }
    return staticItemToAdd;
  }

  async getLinkResourceStaticItem(
    collectionId: string,
    resultItem?: Search.ResultResourceItem
  ): Promise<Collections.LinkResourceItem | Collections.StaticItem> {
    const item = this.collectionsUtilService.cleanResourceItemData(resultItem || this.data.item);
    if (item['kind'] === 'static-collection-item') {
      const collection: Collections.StaticCollection = this.collections.find(
        (c) => c.id === this.currentCollectionId
      ) as Collections.StaticCollection;
      const item = collection?.items?.find(
        (i) => this.data.item.id === i.id || this.data.item.id === (i as Collections.LinkResourceItem).searchResource?.id
      );
      return item ? { ...item, id: item.id } : null;
    }

    const dragUrl = (item.view.title.onDrag as Commands.DownloadUrl)?.url;

    if (!dragUrl) {
      const selectedItem = this.selectedItem as SelectedItem;
      return {
        kind: 'link-resource',
        id: item.id,
        searchResource: { ...item, collectionId: selectedItem?.collection?.id, kind: 'static-collection-item' },
        addedTime: Date.now(),
      };
    }

    try {
      const traits = item.resource.traits;
      let blobId: string;

      blobId = traits?.size > this.FILE_MAX_SIZE ? null : await this.getBlobId(item, collectionId);
      const selectedItem = this.selectedItem as SelectedItem;

      if (blobId) {
        const downloadUrl = this.blobsService.getDownloadUrl(blobId);
        const thumbnailUrl = this.blobsService.getThumbnailUrl(blobId);
        const onDrag = { type: 'download-url', url: downloadUrl } as Commands.DownloadUrl;
        item.view = { ...item.view, title: { ...item.view.title, onDrag }, thumbnail: { url: thumbnailUrl } };
      } else {
        item.view.title.onDrag = null;
      }

      return {
        kind: 'link-resource',
        id: item.id,
        searchResource: { ...item, collectionId: selectedItem?.collection?.id, kind: 'static-collection-item', fileId: blobId },
        addedTime: Date.now(),
      };
    } catch (error) {
      this.logger.error('error to add file to static collection', error);
    }
  }

  private async getBlobId(item: Search.ResultResourceItem, collectionId: string) {
    try {
      const dragUrl = (item.view.title.onDrag as Commands.DownloadUrl)?.url;
      const traits = item.resource?.traits;

      const request: Downloads.ResourceDownloadRequest = {
        resource: item.resource,
        type: 'Resource',
        url: dragUrl,
        grantType: 'links:api',
      };
      const url = await this.downloadsService.getDownloadUrl(request);

      const f = await fetch(url);
      const blob = await f.blob();

      if (blob.size > this.FILE_MAX_SIZE) {
        return;
      }

      const mimeType = blob.type || traits.mimetype;

      const name = traits.extension ? `${traits.name}.${traits.extension}` : traits.name;

      const file: File = new File([blob], name, {
        type: mimeType,
      });

      const shareOptions: Permissions.ShareOptions = {
        level: 'Following',
        followingIds: [collectionId],
      };

      const blobId = (await this.blobsService.create(name, mimeType, shareOptions))?.id;
      if (!blobId) {
        throw new Error('failed to create blobId');
      }

      const uploadResponse = await this.blobsService.upload(file, blobId);

      if (uploadResponse.status >= 400) {
        throw new Error(`failed to upload file to blob, ${{ error: uploadResponse.status }}`);
      }

      return blobId;
    } catch (error) {
      this.logger.info('Did not succeed to upload blob', error);
      return;
    }
  }

  private getToasterTitle(collectionTitle: string, item: Collections.StaticItem): string {
    const title: string =
      (item as Collections.LinkResourceItem)?.searchResource?.view?.title?.text ||
      (item as Collections.UrlItem).title ||
      (item as Collections.FileItem).name;
    return `"${cutText(title, 5)}" was added to the "${cutText(collectionTitle, 10)}" Collection`;
  }

  private isCollectionItem() {
    return (
      this.data.item?.type === 'collection' ||
      ['Static', 'Live', 'Wiki'].includes(this.data.item?.kind) ||
      this.data?.item?.source === 'wiki-collection-items'
    );
  }
}
