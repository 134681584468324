import { NgModule } from '@angular/core';
import { SafePipe } from './pipes/safe.pipe';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InfraComponentsSharedModule } from './shared/infra-components-shared.module';
import { OutsideClickDirective } from './directives/outsideClick.directive';
import { GhostDirective } from './directives/ghost.directive';

const pipes = [SafePipe];

const providers = [SafePipe];

const directives = [OutsideClickDirective, GhostDirective];

const primeNgModules = [TableModule, OverlayPanelModule, DragDropModule];

const primengDirectives = [TooltipModule];

@NgModule({
  declarations: [...pipes, ...directives],
  providers: [...providers],
  exports: [...pipes, ...primeNgModules, ...primengDirectives, InfraComponentsSharedModule, ...directives],
})
export class UiInfraModule {}
