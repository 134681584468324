type ScreenSizeValue = {
  [key in BreakpointsWidth]: number;
};
export const SCREEN_SIZE_MAX_WIDTH: Partial<ScreenSizeValue> = {
  small: 910,
  medium: 1280,
  large: 1920,
};
export type BreakpointsWidth = 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';
export enum BreakpointsWidthEnum {
  'extra-small' = 0,
  'small' = 1,
  'medium' = 2,
  'large' = 3,
}

/**return the current width breakpoint screen */
export function getWidthBreakpointScreen(): BreakpointsWidth {
  const screenWidth = window.innerWidth;
  if (screenWidth <= 640) {
    return 'extra-small';
  }
  if (screenWidth <= 910) {
    return 'small';
  }
  if (screenWidth <= 1280) {
    return 'medium';
  }
  if (screenWidth <= 1920) {
    return 'large';
  }

  return 'extra-large';
}

/**return if given breakpoint is wider or not from the current width */
export function isWidthBreakpointWider(breakpointWidth: BreakpointsWidth): boolean {
  const currentBreakpointWidth = getHeightBreakpointScreen();
  return BreakpointsWidthEnum[breakpointWidth] > BreakpointsWidthEnum[currentBreakpointWidth];
}

/**return the current height breakpoint screen */
export function getHeightBreakpointScreen(): 'small' | 'medium' | 'large' {
  const screenHeight = window.innerHeight;
  if (screenHeight <= 600) {
    return 'small';
  }
  if (screenHeight > 800) {
    return 'large';
  }
  return 'medium';
}
