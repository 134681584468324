<div class="header tooltip-secondary-color" *ngIf="header">{{ header }}</div>
<ng-container *ngFor="let item of items || []">
  <div class="list-avatars-tooltip">
    <div class="avatar-tooltip-details">
      <ng-container *ngIf="!!item.imgUrl; else defaultIcon">
        <avatar [url]="item.imgUrl" [size]="sizeAvatarTooltip"></avatar>
      </ng-container>
      <ng-template #defaultIcon>
        <account-icon [model]="item.initialsImg" [size]="sizeAvatarTooltip" [state]="'default'"></account-icon>
      </ng-template>
      <div class="avatar-tooltip-name tooltip-main-color" *ngIf="item.name">{{ item.name }}</div>
    </div>
    <div class="avatar-tooltip-description tooltip-secondary-color" *ngIf="item.description">{{ item.description }}</div>
  </div>
</ng-container>
<div class="footer" *ngIf="footer">{{ footer }}</div>
