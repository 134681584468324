import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Commands, Links, Style } from '@local/client-contracts';
import { PopupRef, UButtonComponent, UCheckboxComponent } from '@local/ui-infra';
import { EventInfo, EventsService } from '@shared/services';
import { ApplicationsService } from '@shared/services/applications.service';
import { DesktopService } from '@shared/services/desktop.service';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { LinksService } from '@shared/services/links.service';
import { getModifiers, isEnterKey, KeyName } from '@local/ts-infra';
import { HubService } from 'src/app/bar/services/hub.service';
import { TelemetryTrigger } from 'src/app/bar/views';

export interface OpenWithPromptData {
  linkId: string;
  title: string;
  command: Commands.Command;
  commandContext: Commands.Context;
  event: Partial<EventInfo>;
}
@Component({
  selector: 'open-with-prompt',
  templateUrl: './open-with-prompt.component.html',
  styleUrls: ['./open-with-prompt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenWithPromptComponent implements OnDestroy, OnInit {
  @Input() model: OpenWithPromptData;
  @Output() executeCommand = new EventEmitter<{ command: Commands.Command; commandContext: Commands.Context; event: Partial<EventInfo> }>();

  @ViewChildren('markable') markable: QueryList<UButtonComponent | UCheckboxComponent>;

  browser: string;
  appIcon: Style.Icon;
  openWith: Commands.OpenWith;
  setAsDefault: boolean;
  link: Links.DisplayItem;

  private markedIndex = -1;

  keysHandlerId: string;

  constructor(
    private barService: HubService,
    private applicationsService: ApplicationsService,
    private linksService: LinksService,
    private desktopService: DesktopService,
    private eventsService: EventsService,
    private cdr: ChangeDetectorRef,
    private keyboardService: KeyboardService,
    private ref: PopupRef<OpenWithPromptComponent, OpenWithPromptData>
  ) {
    this.keysHandlerId = keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 8);
  }

  async ngOnInit(): Promise<void> {
    const browserTask = this.desktopService.defaultBrowser();
    this.link = await this.linksService.one(this.model.linkId);
    const app = await this.applicationsService.one(this.link.appId);
    this.browser = await browserTask;
    this.appIcon = app?.icon;
    this.openWith = this.link.preferences.openWith ?? 'app';
    this.setAsDefault = true; //this.link.preferences.openWith === undefined
    this.markedIndex = this.openWith === 'app' ? 0 : 1;
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    if (this.keysHandlerId) this.keyboardService.unregisterKeyHandler(this.keysHandlerId);
  }

  async onPromptSelect(selection: Commands.OpenWith, trigger: TelemetryTrigger) {
    const location: string = this.barService.currentLocation;
    if (this.setAsDefault) {
      await Promise.all([
        this.linksService.update(this.model.linkId, { preferences: { openWith: selection } }),
        this.linksService.updateOpenWith(this.link.id, selection),
      ]);
    }
    this.eventsService.event('results.openWith', {
      target: this.link.appId,
      label: selection,
      jsonData: JSON.stringify({ openWithSetting: { setAsDefault: this.setAsDefault } }),
      location: {
        title: location,
      },
    });
    const commandData = {
      command: this.model.command,
      commandContext: { ...this.model.commandContext, openWith: selection, setAsDefault: this.setAsDefault },
      event: this.model.event,
    };
    this.executeCommand.emit(commandData);
    this.ref.destroy();
  }

  handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    const modifiers = getModifiers(keys);
    const key = keys[0];
    if (modifiers.length === 1 && modifiers[0] === 'shift' && key === 'tab') {
      this.select('prev');
      event.stopPropagation();
      return;
    }

    if (!modifiers.length) {
      if (key === 'escape') {
        this.ref.destroy();
      } else if (key === 'ArrowRight') {
        this.openWith = 'browser';
        this.updateFocusedSelection(1);
      } else if (key === 'ArrowLeft') {
        this.openWith = 'app';
        this.updateFocusedSelection(0);
      } else if (key === 'tab') {
        this.select('next');
      } else if (key === 'space' || isEnterKey(key)) {
        if (this.markable.toArray()[this.markedIndex] instanceof UCheckboxComponent) {
          this.setAsDefault = !this.setAsDefault;
          this.cdr.markForCheck();
        } else {
          this.onPromptSelect(this.openWith, 'keyboard');
        }
      }
      event.stopPropagation();
    }
  }

  updateFocusedSelection(idx: number) {
    this.select(idx);
    this.cdr.markForCheck();
  }

  select(event: 'next' | 'prev' | number) {
    if (!this.markable) return;
    let targetIdx;
    if (!Number.isInteger(event)) {
      targetIdx = event === 'next' ? ++this.markedIndex : --this.markedIndex;
      if (targetIdx >= this.markable.length) targetIdx = 0;
      else if (targetIdx < 0) targetIdx = this.markable.length - 1;
    } else targetIdx = event;

    const next = this.markable.toArray()[targetIdx];
    if (next) {
      this.openWith = next instanceof UButtonComponent ? this.getOpenWithValue(targetIdx) : null;
      this.cdr.markForCheck();
    }
    this.markedIndex = targetIdx;
  }

  getOpenWithValue(idx: number): Commands.OpenWith {
    return idx === 0 ? 'app' : 'browser';
  }

  toggleSetAsDefault($event) {
    this.setAsDefault = $event.checked;
  }
}
