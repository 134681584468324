import { Commands, LocalActions, MemorySearch } from '@local/client-contracts';
import { AssistantsIconsConst, Constants } from '@local/common';
import { isEmbed } from '@local/common-web';
import { isHttpsUrl } from '@local/ts-infra';
import { EmbedOptions, EmbedService } from '@shared/embed.service';
import { LogService } from '@shared/services';
import { ApplicationsService } from '@shared/services/applications.service';
import { DesktopService } from '@shared/services/desktop.service';
import { FlagsService } from '@shared/services/flags.service';
import { LinksService } from '@shared/services/links.service';
import { LocalActionsService } from '@shared/services/local-actions.service';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { firstValueFrom } from 'rxjs';
import { isAddToCollection, isCreateGoLink, isLocalAction } from 'src/app/bar/views';
import { PeopleService } from 'src/app/bar/views/preview/people-preview/services/people.service';
import { AddToCollectionItem, CreateGoLinkItem, SearchResults } from 'src/app/bar/views/results/models/results-types';
import { FiltersService } from '../../../filters.service';
import { GoLinksService } from '../../../go-links.service';
import { ResourcesMemorySearchClient } from '../resources-memory-search/resources-memory-search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { LocalActionSourceSettings } from './local-actions-source-settings';
import { ExperiencesService } from '../../../experiences.service';

export class LocalActionsSearchClient extends ResourcesMemorySearchClient<LocalActionSourceSettings> {
  constructor(
    logService: LogService,
    memorySearchService: MemorySearchService,
    filtersService: FiltersService,
    private actionsService: LocalActionsService,
    private desktopService: DesktopService,
    private goLinksService: GoLinksService,
    private embedService: EmbedService,
    protected appsService: ApplicationsService,
    linksService: LinksService,
    peopleService: PeopleService,
    private flagsService: FlagsService,
    private experiencesService: ExperiencesService
  ) {
    super(logService, memorySearchService, filtersService, appsService, linksService, peopleService, ['Alphabetical']);
  }

  async getInput(request: SearchRequest<LocalActionSourceSettings>, response: SearchResponse): Promise<MemorySearch.Item[]> {
    const sourceSettings = request.sourceSettings;
    const query = request.query;

    const [disabledGoLinks, disableCollections, disableWikis] = await Promise.all([
      this.flagsService.isEnabled(Constants.DISABLED_GO_LINKS_FLAG),
      this.flagsService.isEnabled(Constants.DISABLED_COLLECTIONS_FLAG),
      this.flagsService.isEnabled(Constants.DISABLED_WIKIS_FLAG),
    ]);

    const enableSalesforceCasesAssistant = this.experiencesService.checkAssistantsFlags('Salesforce');
    const enableGeneralAssistant = this.experiencesService.checkAssistantsFlags('General');

    let results: SearchResults[] = [];
    if (sourceSettings.withGoLinksAction || sourceSettings.withAddToCollectionAction) {
      const goLinkItems = [];
      const collectionItems = [];
      const urls = await this.getUrlToAction(query);
      for (const url of urls) {
        if (url && sourceSettings.withGoLinksAction && !disabledGoLinks) {
          const existUrl = (await firstValueFrom(this.goLinksService.allAvailableLinks$))?.findIndex(
            (l) => l.url.toLocaleLowerCase() === url.toLocaleLowerCase()
          );
          if (existUrl === -1) {
            const item: CreateGoLinkItem = {
              id: 'create-go-link',
              type: 'create-go-link',
              url,
              icon: { type: 'font-icon', value: 'font-icon icon-link2' },
              title: 'Create a new Go Link for',
            };
            goLinkItems.push(item);
          }
        }
        if (url && sourceSettings.withAddToCollectionAction && !disableCollections) {
          const item: AddToCollectionItem = {
            id: 'add-to-collection',
            type: 'add-to-collection',
            title: 'Add to Collection for',
            url,
            icon: { type: 'font-icon', value: 'font-icon icon-collection' },
          };
          collectionItems.push(item);
        }
      }
      results.push(...[...goLinkItems, ...collectionItems]);
    }

    if (sourceSettings.withAppActions) {
      const actions = await firstValueFrom(this.actionsService.all$);
      results = [...results, ...actions];
    }
    if (sourceSettings.addActionByKey) {
      results.unshift(this.addActionByKey(sourceSettings.addActionByKey));
    }
    if (query?.length) {
      if ('new go link'.includes(query) && !disabledGoLinks) {
        results.unshift(this.addActionByKey('new-go-link'));
      }
      if (['new curated collection', 'curated', 'collection'].some((q) => q.includes(query)) && !disableCollections) {
        results.unshift(this.addActionByKey('new-curated-collection'));
      }
      if (['new live collection', 'live', 'collection'].some((q) => q.includes(query)) && !disableCollections) {
        results.unshift(this.addActionByKey('new-live-collection'));
      }
      if (['new wiki collection', 'wiki', 'collection'].some((q) => q.includes(query)) && !disableWikis) {
        results.unshift(this.addActionByKey('new-wiki-collection'));
      }
      if (['new card', 'card'].some((q) => q.includes(query)) && !disableWikis) {
        results.unshift(this.addActionByKey('new-card'));
      }
      if (['new rfp', 'rfp'].some((q) => q.includes(query))) {
        results.unshift(this.addActionByKey('new-rfp-assistant'));
      }
      if (['new salesforce cases', 'salesforce cases'].some((q) => q.includes(query)) && enableSalesforceCasesAssistant) {
        results.unshift(this.addActionByKey('new-salesforce-cases-assistant'));
      }
      if (['new general assistant', 'general assistant'].some((q) => q.includes(query)) && enableGeneralAssistant) {
        results.unshift(this.addActionByKey('new-general-assistant'));
      }
    }

    const apps = await this.appsService.all();

    return results.map((r) => {
      let searchText = '';
      if (isLocalAction(r)) {
        searchText =
          (r.title || '') + apps.find((x) => x.id == r.appId)?.name + ' ' + (r.description || '') + ' ' + (r.keywords || []).join(' ');
      } else if (isCreateGoLink(r) || isAddToCollection(r)) {
        searchText = (r.title || '') + (r.url || '');
      }
      return {
        searchText,
        data: r,
        sortValue: sourceSettings.sorting?.by === 'Alphabetical' ? (r as LocalActions.LocalActionItem)?.title : null,
      };
    });
  }

  private async getUrlToAction(query: string): Promise<string[]> {
    const urls: string[] = [];
    let url = query || (await this.desktopService.getClipboardData());
    if (isHttpsUrl(url)) {
      urls.push(url);
    }
    if (isEmbed() && !(await this.embedService.isExternalWebSite())) {
      const embedOptions: EmbedOptions = await firstValueFrom(this.embedService.options$);
      if (embedOptions?.popup?.locationUrl) {
        url = embedOptions?.popup?.locationUrl;
      }
      if (isHttpsUrl(url)) {
        urls.push(url);
      }
    }
    return urls;
  }

  protected getFilterValues(item: any): { appId: string; linkId: string; type: string } {
    return <any>{ ...(<LocalActions.LocalActionItem>item) };
  }

  async getOutput(items: MemorySearch.Item[], sourceSettings?: LocalActionSourceSettings): Promise<SearchResults[]> {
    this.adjustItemIcons(items, sourceSettings);
    return items.map((x) => x.data);
  }

  addActionByKey(actionKey: string): LocalActions.LocalActionItem {
    switch (actionKey) {
      case 'new-go-link':
        return {
          id: 'new-go-link',
          command: null,
          type: 'local-action',
          title: 'New Go Link',
          icon: { type: 'font-icon', value: 'font-icon icon-link2' },
        };
      case 'new-curated-collection':
        return {
          id: 'new-curated-collection',
          type: 'local-action',
          title: 'New Curated Collection',
          params: null,
          command: { type: 'new-collection', value: 'Static' } as Commands.DynamicCommand,
          icon: { type: 'font-icon', value: 'font-icon icon-curated' },
        };
      case 'new-live-collection':
        return {
          id: 'new-live-collection',
          type: 'local-action',
          title: 'New Live Collection',
          params: null,
          command: { type: 'new-collection', value: 'Live' } as Commands.DynamicCommand,
          icon: { type: 'font-icon', value: 'font-icon icon-live-collection' },
        };
      case 'new-wiki-collection':
        return {
          id: 'new-wiki-collection',
          type: 'local-action',
          title: 'New Wiki',
          params: null,
          command: { type: 'new-collection', value: 'Wiki' } as Commands.DynamicCommand,
          icon: { type: 'font-icon', value: 'font-icon icon-wiki' },
        };
      case 'new-card':
        return {
          id: 'new-card',
          type: 'local-action',
          title: 'New Card',
          params: null,
          command: null,
          icon: { type: 'font-icon', value: 'font-icon icon-card' },
        };
      case 'new-rfp-assistant':
        return {
          id: 'new-rfp-assistant',
          type: 'local-action',
          title: 'New RFP Assistant',
          params: null,
          command: null,
          icon: AssistantsIconsConst['rfp'],
        };
      case 'new-salesforce-cases-assistant':
        return {
          id: 'new-salesforce-cases-assistant',
          type: 'local-action',
          title: 'New Salesforce Cases Assistant',
          params: null,
          command: null,
          icon: AssistantsIconsConst['salesforce'],
        };
      case 'new-general-assistant':
        return {
          id: 'new-general-assistant',
          type: 'local-action',
          title: 'New General Assistant',
          params: null,
          command: null,
          icon: AssistantsIconsConst['general'],
        };
      default:
        break;
    }
  }
}
