import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isEqual, isEqualWith } from 'lodash';
import { OverlayOptions, PrimeTemplate } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { MultiSelect, MultiSelectChangeEvent, MultiSelectFilterEvent } from 'primeng/multiselect';
import { UiIconModel } from '../../types/ui.icon.model';
import { OverlayPanel } from 'primeng/overlaypanel';
import { delay } from '@local/ts-infra';

export type MultiSelectAction = 'Add' | 'Remove' | 'Set' | 'ClearAll';
export interface MultiSelectSelectedEvent extends MultiSelectChangeEvent {
  action?: MultiSelectAction;
  value: any[];
}
export type USelectItem = { icon?: UiIconModel; value: any; height?: number; id?: string };
export type MultiSelectSortField = { name: string; order: 'asc' | 'desc' };
export type MultiSelectOptionsUpdatedEvent = { options: USelectItem[]; filterInput: string };
export type FilterMatchMode = 'endsWith' | 'startsWith' | 'contains' | 'equals' | 'notEquals' | 'in' | 'lt' | 'lte' | 'gt' | 'gte';
export interface MultiSelectModel {
  options: any[];
  optionLabel: string;
  groupOptionsField?: string;
  open?: boolean;
  version?: number;
  fetcher?: (term: string) => Promise<any[]>;
  forcePreOpen?: boolean;
}
export type SearchState = 'All' | 'FilterOnly';

@UntilDestroy()
@Component({
  selector: 'u-multi-select',
  templateUrl: './u-multi-select.component.html',
  styleUrls: ['./u-multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UMultiSelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UMultiSelectComponent implements ControlValueAccessor, AfterViewInit, AfterContentInit {
  private readonly CLOSE_CLASS_NAME = '.p-multiselect-close';
  private readonly FILTER_CONTAINER_CLASS_NAME = '.p-multiselect-filter-container';
  private readonly FILTER_PLACEHOLDER_CLASS_NAME = '.filter-input-placeholder';
  private readonly ITEM_FOCUS_CLASS_NAME = 'item-focus';
  private readonly ITEM_FOCUS_PRIMENG_CLASS_NAME = 'p-focus';
  private readonly ITEM_NAME = 'p-multiselect-item';
  private readonly ITEM_CLASS_NAME = `.${this.ITEM_NAME}`;
  private readonly HIGHLIGHT_CLASS_NAME = '.p-highlight';
  private readonly SCROLL_VIEWPORT_CLASS = 'cdk-virtual-scroll-viewport';
  private readonly ICON_FILTER_CLASS = 'p-multiselect-filter-icon';
  private readonly MULTI_SELECT_ELEMENT = 'p-multiselectitem';
  private readonly DEFAULT_CLEAR_ALL_MIN_SELECTED = 1;
  private readonly CUSTOM_ITEM_CLASS_NAME = '.multi-select-item';
  private readonly LAST_SELECTED_ITEM_CLASS_NAME = 'last-selected';
  private readonly FILTER_GHOSTING = 300;
  readonly DEFAULT_FOOTER_LABEL = 'Clear All';
  readonly SINGLE_CHOICE_FOOTER_LABEL = 'Clear';

  itemTemplate: TemplateRef<any>;
  emptyFilterTemplate: TemplateRef<any>;
  footerTemplate: TemplateRef<any>;
  selectedItemsTemplate: TemplateRef<any>;
  selectedItems: USelectItem[];
  clearingCheckbox: boolean;
  showSingleItem: boolean;

  private _model: MultiSelectModel;
  private _options: any[];
  private _inlineFilterPlaceHolder: string;
  private currentChangeEvent: MultiSelectSelectedEvent;
  private _optionsPreSort: any[];
  private panelOpened = false;
  private _open: boolean;
  private _optionLabel = 'name';
  private _filterInputValue = '';
  private shouldPreopen: boolean;
  private _clearAllMinSelected: number = this.DEFAULT_CLEAR_ALL_MIN_SELECTED;
  private _groupOptionsField: string;
  private _optionsFetcher: (term: string) => Promise<any[]>;
  private _showHasValues = true;
  private searchId = 0;
  private _emptyMessage = '';
  private _liveSearchActive: boolean;
  private selectedChanged: boolean;
  private readonly LIVE_SEARCH_EMPTY_MESSAGE = 'Searching';
  private _optionsDic: Record<string, any> = {};
  private forcePreOpen: boolean;
  private searchVersion = 0;

  /* eslint-disable */
  onChange: any = () => {};
  onTouch: any = () => {};

  @Input() sortBy: MultiSelectSortField[];
  @Input() placeholder: string = null;
  @Input() styles: any = {};
  @Input() appendTo: string = null;
  @Input() multiSelectSize = '';
  @Input() emptyFilterMessage = 'Nothing found';
  @Input() showToggleAll = false;
  @Input() display = 'comma';
  @Input() filterPlaceholder = 'Type...';
  @Input() resetFilterOnHide = true;
  @Input() disabled = false;
  @Input() showClear = false;
  @Input() readonly = false;
  @Input() wideWidth = false;
  @Input() enableBack = false;
  @Input() limitShowSelectedLabels = 10;
  @Input() maxSelectedLabelLength = 12;
  @Input() multiSelectIcon: UiIconModel;
  @Input() noCheckbox: boolean;
  @Input() selectionLimit: number = null;
  @Input() isNoColorCheckbox = false;
  @Input() keepPlaceholder = false;
  @Input() showSelectedItemIcons = false;
  @Input() autoSort = true;
  @Input() excludeSelectedFromHeader = false;
  @Input() itemSize: number = null;
  @Input() virtualScroll = false;
  @Input() displayLabel: string = null;
  @Input() filterBy: string = null;
  @Input() filterMatchMode: FilterMatchMode = 'contains';
  @Input() oneValue = false;
  @Input() hideSelection = false;
  @Input() highlightItem = true;
  @Input() disableShowMoreLabels: boolean;
  @Input() showTooltipIcons = false;
  @Input() maxCharacters = 25;
  @Input() showClearAll = true;
  @Input() showItemTooltip = true;
  @Input() hideOnClickout = true;
  @Input() hideOnSelect = false;
  @Input() updateOnRemove: boolean;
  @Input() showGroupOptions: SearchState;
  @Input() displayBackIcon: boolean;
  @Input() shortSelected: boolean;
  @Input() filterDisabled: SearchState | boolean;
  @Input() enableWriteValue = true;
  @Input() clearSearchAfterSelect = false;
  @Input() separateSelected: boolean;
  @Input() panelStyleClass: string;
  @Input() showSingleItemSelected: boolean;
  @Input() overlayOptions: OverlayOptions;
  @Input() searchDebounce: number;
  @Input() ghostLinesCount: number = 5;
  @Input() hideSearchFilter: boolean;
  @Input() tooltipLabel: string;

  @Input() set model(input: MultiSelectModel) {
    if (!this.modelChanged(this._model, input)) {
      return;
    }
    const { options, fetcher, optionLabel, groupOptionsField } = (this._model = input);
    if (options) this._optionLabel = optionLabel;
    this._optionsPreSort = options;
    if (options?.length) {
      this.setupOptions();
    }
    this._groupOptionsField = groupOptionsField;
    this._optionsFetcher = fetcher;
    if (this.currentChangeEvent) {
      setTimeout(() => {
        this.multiSelect.filterInputChild?.nativeElement?.focus();
      }, 200);
    }
    this.setOpen(input.open);
    this.shouldPreopen = !this.panelOpened || this.forcePreOpen || input.forcePreOpen;
    if (this.shouldPreopen) {
      this.preopen();
      this.shouldPreopen = false;
      this.forcePreOpen = false;
    }
  }

  @Input() set showHasValues(val: boolean) {
    if (val !== undefined) {
      this._showHasValues = val;
    }
  }

  @Input() set inlineFilterPlaceHolder(val: any) {
    this._inlineFilterPlaceHolder = val;
    if (!this.multiSelect?.overlayVisible) return;
    if (val) {
      this.getHtmlElement(this.FILTER_PLACEHOLDER_CLASS_NAME).style.display = 'block';
    } else {
      this.getHtmlElement(this.FILTER_PLACEHOLDER_CLASS_NAME).style.display = 'hide';
    }
  }

  @Input() set emptyMessage(msg: string) {
    this._emptyMessage = msg;
  }

  setOpen(o: boolean) {
    if (this.multiSelect && this.panelOpened != (o || false)) {
      o ? this.multiSelect.show() : this.multiSelect.hide();
      this.panelOpened = o;
    }
    if (o) {
      setTimeout(() => {
        this.fixOverlayOptions();
      }, 0);
    }
    this._open = o;
  }

  @Output() onClear = new EventEmitter<{ originalEvent: PointerEvent; itemValue: any; value: any[] }>();
  @Output() onSelectedChange = new EventEmitter<MultiSelectSelectedEvent>();
  @Output() onFilter = new EventEmitter<MultiSelectFilterEvent>();
  @Output() optionsUpdated = new EventEmitter<MultiSelectOptionsUpdatedEvent>();
  @Output() onClick = new EventEmitter<any>();
  @Output() onBlur = new EventEmitter<any>();
  @Output() onFocus = new EventEmitter<any>();
  @Output() onPanelShow = new EventEmitter<void>();
  @Output() onPanelHide = new EventEmitter<void>();
  @Output() onClearAllEvent = new EventEmitter<void>();
  @Output() onKeyboardItemChange = new EventEmitter<void>();
  @Output() onBack = new EventEmitter<void>();
  @Output() enterPressed = new EventEmitter<KeyboardEvent>();

  @ViewChild(MultiSelect) multiSelect: MultiSelect;

  @ViewChild('filterInputPlaceholder') filterInputPlaceholder: ElementRef;

  @ContentChildren(PrimeTemplate) templates: QueryList<any>;

  get options() {
    const op = this.filterDisabledOptions(this._options);
    return op;
  }

  get optionLabel(): string {
    return this._optionLabel;
  }

  get inlineFilterPlaceHolder() {
    return this._inlineFilterPlaceHolder;
  }

  get emptyMessage() {
    return this.liveSearchActive ? this.LIVE_SEARCH_EMPTY_MESSAGE : this._emptyMessage;
  }

  get open() {
    return this._open;
  }

  get groupOptionsField() {
    return this._groupOptionsField;
  }

  set value(val: any[]) {
    if (isEqual(this.selectedItems, val)) {
      return;
    }
    this.selectedItems = val;
    this.onChange(val);
    this.onTouch(val);
  }

  get clearAllMinSelected() {
    return this._clearAllMinSelected;
  }

  get showHasValues(): boolean {
    return this._showHasValues;
  }

  get filterInputValue(): string {
    return this._filterInputValue;
  }

  get liveSearchActive(): boolean {
    return this._liveSearchActive;
  }

  get displayClearAllFooter(): boolean {
    return this.selectedItems?.length >= this.clearAllMinSelected && !this.filterInputValue && this.showClearAll;
  }

  @Input()
  set clearAllMinSelected(val: number) {
    if (val || val === 0) {
      this._clearAllMinSelected = val;
      return;
    }
  }

  get overlay() {
    if (this.appendTo || !!this.overlayOptions?.appendTo) {
      return this.multiSelect?.overlayViewChild?.contentViewChild?.nativeElement || (<any>this.multiSelect)?.overlay;
    }
    return this.multiSelect?.overlayViewChild?.el?.nativeElement || (<any>this.multiSelect)?.overlay;
  }

  constructor(
    public cdr: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {}

  ngAfterViewInit(): void {
    this.multiSelect.close = (event: Event) => {
      (<HTMLInputElement>event.target).value = '';
      this.multiSelect.onFilterInputChange(event as KeyboardEvent);
      const element: HTMLElement = this.getHtmlElement(this.CLOSE_CLASS_NAME);
      element.style.visibility = 'hidden';
      element.blur();
      this.multiSelect.filterInputChild.nativeElement.focus();
    };
    if (this.open && !this.panelOpened) {
      this.multiSelect.show();
    }

    this.multiSelect.onFilterKeyDown = (event) => this.onOptionKeydown({ originalEvent: event, option: null });
    this.multiSelect.onKeyDown = (event) => this.onOptionKeydown({ originalEvent: event, option: null });
    this.multiSelect.hide = () => {
      // fix ExpressionChangedAfterItHasBeenCheckedError exception the happened because primng calling this function multiple times
      if (!this.multiSelect.overlayVisible) return;
      this.multiSelect.overlayVisible = false;
      if (this.multiSelect.resetFilterOnHide) {
        this.multiSelect.resetFilter();
      }
      this.multiSelect.onPanelHide.emit();
      this.multiSelect.cd.markForCheck();
    };
    this.fixOverlayOptions();
  }

  private fixOverlayOptions() {
    if (!this.multiSelect) {
      return;
    }
    const multiRect = this.multiSelect.el?.nativeElement?.getBoundingClientRect();
    const overlayWidth = this.wideWidth ? 256 : 220;
    if (window.innerWidth - multiRect?.left < overlayWidth) {
      this.overlayOptions = { contentStyle: { position: 'absolute', right: 0 } };
    } else {
      this.overlayOptions = this.overlayOptions ?? null;
    }
    this.cdr.markForCheck();
  }

  ngAfterContentInit() {
    this.initTemplates();
  }

  initTemplates() {
    this.templates?.forEach((item) => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;
        case 'emptyfilter':
          this.emptyFilterTemplate = item.template;
          break;
        case 'footer':
          this.footerTemplate = item.template;
          break;
        case 'selectedItems':
          this.selectedItemsTemplate = item.template;
          break;
        default:
          break;
      }
    });
  }

  @HostListener('click', ['$event.target']) onMouseClick(e) {
    if (e.classList.contains(this.ICON_FILTER_CLASS) && this.displayBackIcon) {
      this.onBack.emit();
    }
  }

  @HostListener('document:mousedown', ['$event'])
  onDocumentMouseDown(event: MouseEvent) {
    if (this.hideOnClickout && this.panelOpened && !this.multiSelect?.el?.nativeElement?.contains(event.target) && !this.appendTo) {
      this.multiSelect?.hide();
    }
  }

  writeValue(value: any): void {
    if (this.enableWriteValue) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  private sortListBySelected(options: any[]) {
    if (!options || !this.autoSort) return;
    options?.sort((a, b) => {
      if (a.selected != b.selected) {
        return a.selected ? -1 : 1;
      }
      if (a.disabled != b.disabled) {
        return a.disabled ? 1 : -1;
      }
      if (this.sortBy?.length) {
        for (const sortField of this.sortBy) {
          const res = this.compareField(a, b, sortField.name, sortField.order);
          if (res) {
            return res;
          }
        }
        return (<string>a[this.optionLabel]).localeCompare(<string>b[this.optionLabel]);
      }
      const indexA = options.indexOf(a);
      const indexB = options.indexOf(b);
      return indexA - indexB;
    });
  }

  private compareField(a: any, b: any, name: string, order: 'asc' | 'desc') {
    if (!a[name] && !b[name]) {
      return 0;
    }
    if (typeof a[name] === 'number' || typeof b[name] === 'number') {
      const res = (a[name] || 0) - (b[name] || 0);
      return order === 'asc' ? res : 0 - res;
    }
    const res = (<string>a[name]).localeCompare(<string>b[name]);
    return order === 'asc' ? res : 0 - res;
  }

  private preopen() {
    if (this._optionsFetcher) {
      this.liveSearch('');
      return;
    }
    this.postOptionsReceived();
  }

  private liveSearch(query: string, initSelected = true) {
    const searchId = ++this.searchId;
    let searchEnded: boolean;
    if (!this._filterInputValue) {
      this._liveSearchActive = true;
    } else {
      delay(this.FILTER_GHOSTING).then(() => {
        //reset items for display ghosting
        if (!searchEnded && searchId === this.searchId) {
          this._liveSearchActive = true;
          this._optionsPreSort = [];
          this.postOptionsReceived(false);
        }
      });
    }
    const filterInput = this._filterInputValue;
    return this._optionsFetcher(query).then((res) => {
      searchEnded = true;
      if (searchId !== this.searchId) {
        return;
      }
      this._optionsPreSort = res;
      this.setupOptions();
      this.postOptionsReceived(initSelected);
      this._liveSearchActive = false;
      this.optionsUpdated.emit({ options: this._options, filterInput });
    });
  }

  private postOptionsReceived(initSelected = true) {
    const newOptions = [...this.filterDisabledOptions(this._optionsPreSort || [])];
    // this fix the blinking of the first item, if the first item is not change we want to keep it to prevent this blinking mid searching
    if (newOptions?.length > 0 && this._options?.length > 0 && newOptions[0][this.optionLabel] === this._options[0][this.optionLabel]) {
      this._options.splice(1);
      this._options[0].selected = newOptions[0].selected;
      this._options[0].disabled = newOptions[0].disabled;
      this._options.push(...newOptions.slice(1));
    } else {
      this._options = newOptions;
    }
    if (this.multiSelect) {
      this.multiSelect._options.set(this._options);
    }
    this.updateDicOptions(this._options);
    if (initSelected) {
      this.value = this._options.filter((f) => f.selected);
    }
    this.updateShowSingleItem();
    this.updateListHeight();
    this.adjustItems();
    this.fixOverlayOptions();
    this.renderOverlay();
    setTimeout(() => {
      this.highlightFirstItem();
      this.updateLastSelected();
    }, 0);
    this.cdr.markForCheck();
  }

  private updateShowSingleItem() {
    this.showSingleItem =
      !this.filterInputValue &&
      this.showSingleItemSelected &&
      this.options?.length &&
      this.options[0].selected &&
      this.options.length === 1;
  }

  private updateLastSelected() {
    if (!this.multiSelect?.overlayVisible) return;
    if (!this.selectedItems?.length || !this.separateSelected) {
      return;
    }
    const lastSelectedId = this.selectedItems[this.selectedItems.length - 1].id;
    const allItems = this.getAllHtmlElement(this.ITEM_CLASS_NAME) || [];
    allItems.forEach((elm) => elm.classList.remove(this.LAST_SELECTED_ITEM_CLASS_NAME));
    if (allItems.length <= this.selectedItems.length) return;
    for (let index = 0; index < allItems.length; index++) {
      const element = allItems[index];
      if (this.getItemId(element) === lastSelectedId) {
        element.classList.add(this.LAST_SELECTED_ITEM_CLASS_NAME);
        break;
      }
    }
    this.cdr.markForCheck();
  }

  onClearEvent() {
    this.value = [];
    this.onClearAllEvent.emit();
    this.currentChangeEvent = null;
  }

  onChangeEvent($event: MultiSelectChangeEvent) {
    this.currentChangeEvent = $event;
    let action: MultiSelectAction = $event.value.find((elm) => elm === $event.itemValue) ? 'Add' : 'Remove';
    if (this.oneValue && $event.value.length > 0) {
      const value = [$event.value[$event.value.length - 1]];
      this.value = value;
      $event.value = value;
      action = 'Set';
    } else {
      this.value = $event.value;
    }
    if (this.updateOnRemove && action === 'Remove') {
      const updated = this._optionsPreSort.filter((op: any) => op[this.optionLabel] !== $event.itemValue[this.optionLabel]);
      this._optionsPreSort = updated;
      this.preopen();
    }
    this.selectedChanged = true;
    this.onSelectedChange.emit({ ...$event, action });
    this.setHighlightItem(['Add', 'Set'].includes(action));
    this.updateListHeight();
    setTimeout(() => {
      if (this.hideOnSelect && this.multiSelect.overlayVisible) {
        this.multiSelect.hide();
      }
      if (this.filterInputValue && this.clearSearchAfterSelect) {
        this.multiSelect._filterValue.set('');
        this._filterInputValue = '';
        if (this._optionsFetcher) {
          this.liveSearch('');
        } else {
          this.forcePreOpen = true;
        }
      }
      this.fixMultiSelectOverlayPosition();
      this.fixOverlayOptions();
      this.multiSelect.filterInputChild?.nativeElement?.focus();
    }, 0);
  }

  private fixMultiSelectOverlayPosition() {
    if (this.multiSelect.overlayVisible && this.overlay && this.multiSelect.el.nativeElement) {
      const el = this.multiSelect.el.nativeElement.getBoundingClientRect();
      const overlay = this.overlay.getBoundingClientRect();
      if (el.x !== overlay.x) {
        //the overlay should be right below the dropdown
        const currentChangeEvent = this.currentChangeEvent;
        //todo tomer - fix this issue without hiding the multiselect
        // this.multiSelect.hide();
        // this.multiSelect.show();
        this.currentChangeEvent = currentChangeEvent;
      }
    }
  }

  private updateListHeight() {
    if (!this.multiSelect?.overlayVisible) return;
    setTimeout(() => {
      const allItems = this.getAllHtmlElement(this.ITEM_CLASS_NAME);
      if (!allItems || allItems?.length === 0) return;
      const currentItemsLength = allItems[0].clientHeight * allItems.length;
      if (this.virtualScroll && currentItemsLength < 200) {
        //200 is the default scroll size
        this.multiSelect.scrollHeight = currentItemsLength + 24 + 'px'; //24- is the margin of the scroller
      } else {
        this.multiSelect.scrollHeight = '200px';
      }
      this.multiSelect.cd.markForCheck();
    }, 0);
  }

  private setHighlightItem(addHighlight = true) {
    this.removeHighlightItem();
    if (!this.highlightItem) return;
    setTimeout(() => {
      const allItems = this.getAllHtmlElement(this.ITEM_CLASS_NAME);
      if (!this.currentChangeEvent || !allItems || allItems?.length === 0) {
        return;
      }
      if (this.currentChangeEvent.value.length && this.currentChangeEvent.itemValue && addHighlight) {
        allItems.forEach((elm, index) => {
          if (this.isNativeElementEqualToValueObject(elm, this.currentChangeEvent.itemValue)) {
            this.multiSelect.focusedOptionIndex.set(index);
          }
        });
      }
    }, 0);
  }

  private setupOptions() {
    // this._extendedOptions = this.generateExtendedOptions();
    this.sortListBySelected(this._optionsPreSort);
    // this.sortListBySelected(this._extendedOptions);
    this.cdr.markForCheck();
  }

  onClearAll($event) {
    $event?.stopPropagation();
    this.value = [];
    this.onClearAllEvent.emit();
    this.multiSelect.filterInputChild.nativeElement.focus();
    this.currentChangeEvent = null;
    this.removeHighlightItem();
    this.updateListHeight();
    setTimeout(() => {
      this.fixMultiSelectOverlayPosition();
    }, 0);
  }

  private modelChanged(prev: MultiSelectModel, next: MultiSelectModel) {
    return !isEqualWith(prev, next, (f1, f2) => (typeof f1 === 'function' && typeof f2 === 'function' ? true : undefined));
  }

  private removeHighlightItem() {
    const allItems = this.getAllHtmlElement(this.ITEM_CLASS_NAME);
    allItems.forEach((elm) => elm.classList.remove(this.ITEM_FOCUS_CLASS_NAME, this.ITEM_FOCUS_PRIMENG_CLASS_NAME));
  }

  renderOverlay() {
    this.multiSelect?.zone?.runOutsideAngular(() => {
      setTimeout(() => {
        this.multiSelect?.overlayViewChild?.alignOverlay();
      }, 1);
    });
  }

  async onFilterEvent($event: MultiSelectFilterEvent) {
    this._filterInputValue = this.multiSelect?.filterValue;
    if (this.searchDebounce) {
      const currentSearchVersion = ++this.searchVersion;
      await delay(this.searchDebounce);
      if (currentSearchVersion !== this.searchVersion) {
        return;
      }
    }
    this.onFilter.emit($event);
    const callback = () => {
      if (this.multiSelect) {
        this.multiSelect.filtered = true;
      }
      if (this.multiSelect?._filteredOptions?.length === 0) {
        this.renderOverlay();
      }
      const element = this.getHtmlElement(this.CLOSE_CLASS_NAME);
      if (element) {
        element.style.visibility = !$event.filter ? 'hidden' : 'initial';
      }
      this.highlightFirstItem();
      this.updateLastSelected();
      if (!this.filterInputValue) {
        const scroller = this.getAllHtmlElement(this.SCROLL_VIEWPORT_CLASS)?.[0];
        if (scroller) {
          scroller.scrollTo(0, 0);
        }
      }
    };
    if (this._optionsFetcher && $event.originalEvent) {
      this.liveSearch($event?.filter, false).then(() => setTimeout(() => callback(), 0));
    } else {
      this.updateListHeight();
      this.adjustItems();
      setTimeout(() => callback(), 0);
    }
  }

  private adjustItems() {
    const shownItems = this.elementRef.nativeElement.getElementsByClassName(this.ITEM_NAME);
    if (!shownItems?.length) {
      return;
    }
    for (let i = 0; i < shownItems.length; i++) {
      const option = this.getCurrentOption(shownItems[i]);
      const height = option?.height;
      if (height && shownItems[i]?.style) {
        shownItems[i].style.height = height + 'px';
      }
    }
  }

  private highlightFirstItem() {
    if (!this.multiSelect?.overlayVisible) return;
    const allItems = this.getAllHtmlElement(this.ITEM_CLASS_NAME);
    if (allItems?.length) {
      this.multiSelect.focusedOptionIndex.set(0);
      allItems[0].classList.add(this.ITEM_FOCUS_CLASS_NAME); //for fix the blink focus after search in filter
    }
  }

  private filterDisabledOptions(options: any[]) {
    if (
      this.filterDisabled === true ||
      this.filterDisabled === 'All' ||
      (this.filterDisabled === 'FilterOnly' && this.filterInputValue?.length)
    ) {
      options = options?.filter((o) => !o.disabled) || [];
    }
    return options;
  }

  onPanelShowEvent() {
    this.panelOpened = true;
    if (this.shouldPreopen) {
      this.preopen();
    }
    this.onPanelShow.emit();

    this.getHtmlElement(this.FILTER_CONTAINER_CLASS_NAME).append(this.filterInputPlaceholder.nativeElement);
    this.getHtmlElement(this.FILTER_PLACEHOLDER_CLASS_NAME).style.display = 'hide';
    setTimeout(() => {
      const closeIcon = this.getHtmlElement(this.CLOSE_CLASS_NAME);
      if (closeIcon) {
        closeIcon.style.visibility = 'hidden';
      }
      const itemFocus = this.getAllHtmlElement(`${this.ITEM_CLASS_NAME}${this.HIGHLIGHT_CLASS_NAME}.${this.ITEM_FOCUS_PRIMENG_CLASS_NAME}`);
      if (itemFocus?.length > 0) {
        // already item-focus added to the list
        return;
      }
      this.highlightFirstItem();
      this.updateListHeight();
      this.updateLastSelected();

      const scroller = this.getAllHtmlElement(this.SCROLL_VIEWPORT_CLASS)?.[0];
      if (!scroller) {
        return;
      }
      scroller.onscroll =
        scroller.onscroll ||
        (() => {
          this.adjustItems();
        });
    }, 0);
  }

  onPanelHideEvent() {
    const currentInput = this._filterInputValue;
    this._filterInputValue = this.multiSelect?.filterValue;
    this.panelOpened = false;
    this.currentChangeEvent = null;
    const scroller = this.getAllHtmlElement(this.SCROLL_VIEWPORT_CLASS);
    if (scroller && scroller.length > 0) {
      scroller[0].onscroll = null;
    }
    if ((currentInput || this.selectedChanged) && this._optionsFetcher) {
      this.liveSearch('');
    }
    this.selectedChanged = false;
    this.onPanelHide.emit();
  }

  selectedItemShowLabel(item: USelectItem) {
    const value = this.getSelectedItem(item);
    return value.length > this.maxSelectedLabelLength ? `${value.slice(0, this.maxSelectedLabelLength)}...` : value;
  }

  getMoreLabelsTooltip(): string {
    const items = this.selectedItems.slice(this.limitShowSelectedLabels).map((elm) => this.getSelectedItem(elm));
    return this.concatValuesToString(items);
  }

  private concatValuesToString(values: string[]): string {
    let concat = '';
    values.forEach((value: string, index: number) => {
      const last: boolean = index === values.length - 1;
      concat += last ? value : `${value}, `;
    });
    return concat;
  }

  getSelectedItem(item: USelectItem) {
    return item[this.displayLabel] || item[this.optionLabel];
  }

  onKeydown($event: KeyboardEvent) {
    this.onOptionKeydown({ originalEvent: $event, option: null });
  }

  private isCharacterKeyPress(event: KeyboardEvent) {
    if (event.key) {
      return !event.ctrlKey && !event.metaKey && !event.altKey && ![8, 27].includes(event.keyCode);
    }
    return false;
  }

  private onOptionKeydown(optionEvent: { originalEvent: Event; option: any }): void {
    const event = optionEvent.originalEvent as KeyboardEvent;
    if (this.multiSelect.readonly) {
      return;
    }

    if (!this.multiSelect.overlayVisible) {
      return;
    }

    const currItem = this.getHtmlElement(`.${this.ITEM_FOCUS_CLASS_NAME}`) || this.getHtmlElement(`.${this.ITEM_FOCUS_PRIMENG_CLASS_NAME}`);

    switch (event.keyCode) {
      //down
      case 40:
        let nextItem: any;
        if (currItem) {
          nextItem = this.multiSelect.findNextItem(currItem.parentElement);
          if (nextItem) {
            currItem.classList.remove(this.ITEM_FOCUS_CLASS_NAME, this.ITEM_FOCUS_PRIMENG_CLASS_NAME);
          }
        } else {
          nextItem = this.findNextItem(this.getAllHtmlElement(this.MULTI_SELECT_ELEMENT)[0], true);
        }

        if (nextItem) {
          nextItem.classList.add(this.ITEM_FOCUS_CLASS_NAME);
          if (nextItem?.scrollIntoViewIfNeeded) {
            nextItem.scrollIntoViewIfNeeded({
              behavior: 'auto',
            });
          }
          this.onKeyboardItemChangeEvent(nextItem);
        }
        event.stopPropagation();
        event.preventDefault();
        return;

      //up
      case 38:
        if (currItem) {
          const prevItem: any = this.multiSelect.findPrevItem(currItem.parentElement);
          if (prevItem) {
            currItem.classList.remove(this.ITEM_FOCUS_CLASS_NAME, this.ITEM_FOCUS_PRIMENG_CLASS_NAME);
            prevItem.classList.add(this.ITEM_FOCUS_CLASS_NAME);
            if (prevItem?.scrollIntoViewIfNeeded) {
              prevItem.scrollIntoViewIfNeeded({
                behavior: 'auto',
              });
            }
            this.onKeyboardItemChangeEvent(prevItem);
          }
        }
        event.stopPropagation();
        event.preventDefault();
        return;

      //enter
      case 13:
        if (currItem) {
          const option = this.getCurrentOption(currItem);
          if (option) {
            this.multiSelect.onOptionSelect({ originalEvent: event, option });
          }
          event.preventDefault();
          event.stopPropagation();
          return;
        }
        break;
      //tab
      case 9:
        if (currItem && this.inlineFilterPlaceHolder) {
          const option = this.getCurrentOption(currItem);
          if (option) {
            this.multiSelect.onOptionSelect({ originalEvent: event, option });
          }
        }
        event.preventDefault();
        event.stopPropagation();
        return;
      case 39: //ArrowRight
      case 37: //ArrowLeft
        event.stopPropagation();
        return;
      //backspace
      case 8:
        if (this.enableBack && !this.filterInputValue) {
          this.onBack.emit();
          event.stopPropagation();
          event.preventDefault();
        }
        return;
    }
    if (this.isCharacterKeyPress(event)) {
      event.stopPropagation();
    }
    if (event.key === 'Enter') {
      this.enterPressed.emit(event);
    }
  }

  onKeyboardItemChangeEvent(item: Element) {
    const realItem = this.options.find((elm) => elm[this.optionLabel] === item.attributes['aria-label'].value);
    this.onKeyboardItemChange.emit(realItem);
  }

  private getCurrentOption(item: Element): any {
    const itemId = this.getItemId(item);
    return this._optionsDic[itemId];
  }

  private getItemId(item: Element): string {
    const itemId = item.querySelector(this.CUSTOM_ITEM_CLASS_NAME)?.id;
    return itemId;
  }

  private isNativeElementEqualToValueObject(item: Element, op: any): boolean {
    return this.getItemId(item) === op.id;
  }

  private findNextItem(item: HTMLElement, isFirst = false) {
    const nextItem = isFirst ? item : <HTMLElement>item.nextElementSibling;
    if (!nextItem) {
      return null;
    }

    const child = <HTMLElement>nextItem.children[0];
    if (DomHandler.hasClass(child, 'p-disabled') || DomHandler.isHidden(child)) {
      return this.findNextItem(nextItem);
    } else {
      return nextItem.children[0];
    }
  }

  private getHtmlElement(className: string): HTMLElement {
    return this.overlay?.querySelector(className);
  }

  private getAllHtmlElement(className: string): NodeListOf<HTMLElement> {
    return this.overlay?.querySelectorAll(className);
  }

  updateDicOptions(options: any[]) {
    for (const op of options || []) {
      if (op.id) {
        this._optionsDic[op.id] = op;
      }
    }
  }

  onHidePanel(op: OverlayPanel) {
    op.overlayVisible = false;
    op.cd.markForCheck();
  }
}
