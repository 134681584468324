import { ConnectedPosition } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { capitalCase } from '@local/ts-infra';
import { PopupRef, PopupService } from '@local/ui-infra';
import * as markdownItAttrs from '@marked-it/markdown-it-attrs';
import { copyToClipboard } from '@shared/utils';
import hljs from 'highlight.js';
import MarkdownIt from 'markdown-it';
import * as markdownItBracketedSpan from 'markdown-it-bracketed-spans';
import { ReplaySubject, take } from 'rxjs';
import {
  ResourcePanelPopupComponent,
  ResourcePanelPopupData,
} from '../views/results/components/resource-panel-popup/resource-panel-popup.component';

@Injectable()
export class ResultMarkdownService {
  private markdownIt: MarkdownIt;
  private resourcePopupRef: PopupRef<ResourcePanelPopupComponent, { resourceId: string; answerType: string }>;
  resourcePopupOpen$: ReplaySubject<{ index: number; id?: string }> = new ReplaySubject(1);

  constructor(private popupService: PopupService) {
    this.init();
  }

  render(markdown: string, markdownData?: any): string {
    return this.markdownIt.render(markdown, markdownData);
  }
  async copyHighlightCode(button: HTMLElement) {
    const codeBlock = button?.parentElement.nextElementSibling.querySelector('code');
    if (codeBlock) {
      const code = codeBlock.innerText;
      await copyToClipboard(code);
      button.textContent = 'Copied!';
      setTimeout(() => (button.textContent = 'Copy code'), 2000);
    }
  }

  private init() {
    this.markdownIt = new MarkdownIt({
      breaks: true,
      html: true,
      linkify: true,
      typographer: true,
      highlight: (str, lang) => {
        if (lang && hljs.getLanguage(lang)) {
          try {
            const highlighted = hljs.highlight(lang, str, true).value;
            const languageLabel = `<div class="code-header">
                                 <span class="language-name">${capitalCase(lang)}</span>
                                 <button class="copy-code-btn" onclick="___copyHighlightCode(this)">Copy code</button>
                               </div>`;
            return `${languageLabel}<div class="code-content"><pre class="hljs language-${lang}"><code class="hljs language-${lang} ${lang}">${highlighted}</code></pre></div>`;
          } catch (__) {}
        }
        const esc = this.markdownIt.utils.escapeHtml;
        return lang ? '<pre class="hljs"><code class="hljs">' + esc(str) + '</code></pre>' : esc(str);
      },
    }).enable(['link']);

    const defaultRender =
      this.markdownIt.renderer.rules.link_open ||
      function (tokens, idx, options, _env, self) {
        return self.renderToken(tokens, idx, options);
      };
    this.markdownIt.use(markdownItAttrs).use(markdownItBracketedSpan);

    this.markdownIt.inline.ruler.before('emphasis', 'custom_reference_item', function (state, silent) {
      if (!['chat-answer', 'result-answer'].includes(state.env?.sourceType)) {
        return;
      }

      const start = state.pos;
      const marker = state.src.charCodeAt(start);

      if (marker !== 0x25 || state.src.charCodeAt(start + 1) !== 0x25) {
        return false;
      }

      const match = state.src.slice(start).match(/^%%(.*?)%%/);

      if (!match) return false;

      const content = match[1];

      if (silent) {
        return false;
      }

      const token = state.push('custom_reference_item', 'i', 0);
      token.content = content;
      token.meta = state.env;
      state.pos += match[0].length;

      return true;
    });

    this.markdownIt.renderer.rules.custom_reference_item = function (tokens, index) {
      const token = tokens[index];
      const idIndex = Number(token.content);
      if (!token.meta.items?.length) {
        return `<i class="font-icon icon-number-${token.content} reference-null"></i>`;
      }
      if (idIndex < 1 || idIndex > token.meta.items.length) {
        return;
      }
      let id;
      if (token.meta.sourceType === 'chat-answer') {
        id = token.meta.items[idIndex - 1]?.externalId;
      } else if (token.meta.sourceType === 'result-answer') {
        id = token.meta.items[idIndex - 1]?.id;
      }
      if (!id) {
        return;
      }
      return `<i class="font-icon icon-number-${token.content} reference" onmouseover="___handleMouseOver(event, this, '${id}', '${token.meta.sourceType}', '${idIndex}', '${token.meta.identity}')"></i>`;
    };

    this.markdownIt.renderer.rules.link_open = (tokens, idx, options, env, self) => {
      const hrefIndex = tokens[idx].attrIndex('target');

      if (hrefIndex < 0) {
        tokens[idx].attrPush(['target', '_blank']);
      } else {
        tokens[idx].attrs[hrefIndex][1] = '_blank';
      }
      return defaultRender(tokens, idx, options, env, self);
    };

    window['___copyHighlightCode'] = this.copyHighlightCode.bind(this);
    window['___handleMouseOver'] = this.openResourcePopup.bind(this);
  }

  openResourcePopup($event: PointerEvent, target: HTMLElement, id: string, answerType: string, index: number, answerId: string) {
    if (this.resourcePopupRef) {
      this.resourcePopupRef.destroy();
    }
    const rect = target.getBoundingClientRect();
    const openAt: { x: number; y: number } = { x: rect.left - 30, y: rect.top + 24 };
    const position: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' }];
    this.resourcePopupRef = this.popupService.open<ResourcePanelPopupComponent, ResourcePanelPopupData>(
      openAt,
      ResourcePanelPopupComponent,
      { resourceId: id, answerType, position: $event },
      {
        position,
      }
    );
    this.resourcePopupOpen$.next({ index, id: answerId });
    target.classList.add('active-reference');

    this.resourcePopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.resourcePopupRef = null;
      this.resourcePopupOpen$.next({ index: null, id: answerId });
      target.classList.remove('active-reference');
    });
  }
}
