import { HomeSettings } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class HomeSettingsRpcInvoker implements HomeSettings.Service {
  @invoker
  get all$(): Observable<HomeSettings.HomeSettings[]> {
    return;
  }

  @invoker
  create(homeSetting: HomeSettings.HomeSettings): Promise<HomeSettings.HomeSettings> {
    return;
  }

  @invoker
  update(homeSettingId: string, actions: HomeSettings.UpdateAction[]): Promise<void> {
    return;
  }

  @invoker
  delete(id: string): Promise<void> {
    return;
  }

  @invoker
  refresh(): Promise<void> {
    return;
  }
}
