import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopupRef } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccountsService } from '@shared/services/accounts.service';

@UntilDestroy()
@Component({
  templateUrl: './trial-upgrade-popup.component.html',
  styleUrls: ['./trial-upgrade-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialUpgradePopupComponent implements OnInit {
  ownerEmail: string;

  ngOnInit(): void {
    this.accountsService.all$.pipe(untilDestroyed(this)).subscribe((accounts) => {
      this.ownerEmail = accounts?.find((a) => a.isOwner)?.email;
      this.cdr.markForCheck();
    });

    this.ref.outsidePointerEvents$.pipe(untilDestroyed(this)).subscribe((ev) => {
      this.ref.destroy();
    });
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private accountsService: AccountsService,
    private ref: PopupRef<TrialUpgradePopupComponent, any>
  ) {}
}
