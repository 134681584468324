import { Commands, Wiki } from '@local/client-contracts';
import { getExtensionByFileName, getIconByExtension } from '@local/ts-infra';
import { PopupRef } from '@local/ui-infra';
import { ContextMenuComponent, ContextMenuData, ContextMenuItem, ContextMenuService } from '@shared/components';
import { EventInfo, EventsService } from '@shared/services';
import { take } from 'rxjs';
import { ShowToasterService } from 'src/app/bar/services/show-toaster.service';
import tinymce, { Editor } from 'tinymce';
import * as uuid from 'uuid';
import { CardEditorConstants } from '../../components/wiki-card-popup/card-editor/card-editor.constants';
import { WikiCardMenuActions } from './wiki-card-menu-actions.helper';
import { attachmentsRegex, getSizeFileError, showWikiToasterError, validateFileSize, wikiCardNumbers } from './wiki-card-utils';
import { BlobsService } from 'src/app/bar/services/blob.service';
import { WikiCardBlobService } from '../../services/wiki-card-blob.service';
import { WikiAttachmentStorageHandler } from './wiki-attachment-storage-handler';

export type SupportFileType = 'image' | 'video';
export interface MediaErrorMap {
  [id: string]: { file: File; type: SupportFileType };
}

export class WikiCardMediaHelper {
  private readonly ACCEPT_MEDIA_UPLOAD_FILE_TYPES: { [name: string]: string[] } = { image: ['image'], video: ['video', 'audio'] };

  private readonly MAX_MEDIA_FILE_SIZE_MB = 10;
  private readonly CLASS_TOX_DIALOG = '.tox-dialog';
  private readonly CLASS_ERROR_BORDER = 'error-border';
  private readonly CLASS_TOX_FORM_GROUP = '.tox-form__group';
  private readonly CLASS_ERROR_MESSAGE = 'error-message';

  //contextmenu
  private contextMenuPopup: PopupRef<ContextMenuComponent, ContextMenuData>;
  private _contextMenuOpened: boolean;

  get contextMenuOpened(): boolean {
    return this._contextMenuOpened;
  }

  constructor(
    private showToasterService: ShowToasterService,
    private contextMenuService: ContextMenuService,
    private eventsService: EventsService,
    private blobsService: BlobsService,
    private wikiCardBlobService: WikiCardBlobService,
    private wikiCardMenuActions: WikiCardMenuActions,
    private wikiAttachmentStorageHandler: WikiAttachmentStorageHandler
  ) {}

  addImageOrVideoToEditor(callback, meta, cardId: string) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    const accept = meta.filetype === 'image' ? 'image/*' : 'video/*, audio/*';
    input.setAttribute('accept', accept);
    input.onchange = () => {
      this.handleMedia(input, cardId, null, callback);
    };
    input.click();
  }

  handleMedia(input, cardId: string, editor?: Editor, callback?, fileType?: SupportFileType) {
    const file: File = input.files[0];
    const maxSize = this.MAX_MEDIA_FILE_SIZE_MB * wikiCardNumbers.MB;
    if (file.size > maxSize) {
      return this.showErrorInput(maxSize);
    }
    this.removeErrorInput();
    this.handleAddedMedia(file, cardId, editor, callback, fileType);
  }

  uploadImage(cardId: string) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.onchange = () => {
      this.handleMedia(input, cardId, tinymce.activeEditor, null, 'image');
    };
    input.click();
  }

  private showErrorInput(maxSize: number) {
    const dialog = document.querySelector(this.CLASS_TOX_DIALOG);
    if (!dialog) {
      return showWikiToasterError(this.showToasterService, getSizeFileError(maxSize));
    }
    const firstInput = dialog.querySelector('input');
    if (firstInput.className.includes(this.CLASS_ERROR_BORDER)) {
      return;
    }
    if (!firstInput) return;
    const firstGroup = dialog.querySelector(this.CLASS_TOX_FORM_GROUP);
    firstInput.classList.add(this.CLASS_ERROR_BORDER);
    const errorMessageElement = document.createElement('div');
    errorMessageElement.innerText = getSizeFileError(maxSize);
    errorMessageElement.classList.add(this.CLASS_ERROR_MESSAGE);
    firstGroup.appendChild(errorMessageElement);
  }

  private removeErrorInput() {
    const dialog = document.querySelector('.tox-dialog');
    if (!dialog) {
      return;
    }
    const firstInput = dialog.querySelector('input');
    const errorMessage = dialog.querySelector('.error-message');
    if (firstInput) {
      firstInput.classList.remove('error-border');
      errorMessage?.remove();
    }
  }

  handleAddedMedia(file, cardId: string, editor?: Editor, callback?, fileType?: SupportFileType) {
    const reader = new FileReader();
    try {
      reader.onload = async () => {
        const tempId = 'blobid-' + uuid.v4();
        const blobCache = tinymce.activeEditor.editorUpload.blobCache;
        const base64 = (reader.result as string)?.split(',')[1];
        const blobInfo = blobCache.create(tempId, file, base64);
        blobCache.add(blobInfo);
        const fileUrl = blobInfo.blobUri();
        const id = await this.uploadMediaBlob(file, cardId);
        if (editor) {
          const selectedNode = editor.selection.getNode();
          if (
            selectedNode.textContent ||
            selectedNode.nodeName.toLocaleLowerCase() !== 'p' ||
            selectedNode.querySelector('img') ||
            selectedNode.querySelector('video')
          ) {
            if (selectedNode.querySelector('video')) {
              selectedNode.insertAdjacentHTML('afterend', '<p><br></p>');
              tinymce.activeEditor.selection.setCursorLocation(selectedNode.nextElementSibling, 0);
              tinymce.activeEditor.focus();
            }
            this.moveSelectionToEndOfLine(editor);
            editor.insertContent('<p><br></p>');
          }
          if (fileType === 'image') {
            editor.insertContent(`<img src="${fileUrl}" alt="${file.name}" id="${id}">`);
          } else {
            const source = `<source alt="${file.name}" title="${file.name}" src="${fileUrl}">`;
            const videoElement = ` <video controls="controls" width="300" height="150" title="${file.name}" id="${id}">
              ${source}
              </video>`;

            setTimeout(() => {
              editor.insertContent(videoElement);
            }, 100);
          }
        }
        if (callback) {
          callback(fileUrl, {
            alt: file.name,
            //HACK - Assign the blob id to poster param for video elements so that the ID can be extracted when saving content
            poster: id,
          });
        }
      };
    } catch (e) {
      showWikiToasterError(this.showToasterService);
    }
    reader.readAsDataURL(file);
  }

  private moveSelectionToEndOfLine(editor: Editor) {
    editor.selection.select(editor.selection.getNode(), true);
    editor.selection.collapse(false);
  }

  private async uploadMediaBlob(file: File, cardId: string): Promise<string> {
    const { name, type, size } = file;
    const id = await this.wikiCardBlobService.createBlob(cardId, name, type);
    if (!id) {
      return;
    }
    const wikiAttachment = this.wikiCardBlobService.createdAttachment(id, type, name, size, 'Media');
    this.wikiAttachmentStorageHandler.addAttachment(cardId, wikiAttachment);
    this.blobsService.upload(file, id).catch(() => {
      this.wikiCardBlobService.failedToUpload(file.name, 'upload_media_failed', id);
    });
    return id;
  }

  validateMediaFileSize(fileSize: number) {
    return validateFileSize(fileSize, this.MAX_MEDIA_FILE_SIZE_MB, this.showToasterService);
  }

  isSupportMediaUploadFile(type: string): SupportFileType {
    for (const [name, values] of Object.entries(this.ACCEPT_MEDIA_UPLOAD_FILE_TYPES)) {
      if (values.some((v) => type.startsWith(v))) {
        return name as SupportFileType;
      }
    }
  }

  removeMedia(html: string, mediaErrorMap: MediaErrorMap): string {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    const imageElements = tempDiv.querySelectorAll('img');
    const videoElements = tempDiv.querySelectorAll('video');
    const elements = [...imageElements, ...videoElements];

    elements.forEach((elm) => {
      if (mediaErrorMap[elm.id]) {
        elm.parentElement.remove();
      }
    });

    // we remove \n if it's the last char in the html , to fix issue with Tinymce that ignore last enter
    let innerHTML = tempDiv.innerHTML;
    if (innerHTML.lastIndexOf('\n') === innerHTML.length - 1) {
      innerHTML = innerHTML.slice(0, innerHTML.length - 1);
    }

    return innerHTML;
  }

  openVideoContextMenu(
    event: { x: number; y: number },
    data: { selectedElement: HTMLElement; editor },
    locationTitle: string,
    canEdit?: boolean
  ) {
    if (this.contextMenuPopup) {
      this.contextMenuPopup.destroy();
    }
    this._contextMenuOpened = true;
    data.selectedElement.classList.add('right-click-overlay');
    const items = this.buildVideoContextMenuItems(data, locationTitle, canEdit);
    this.contextMenuPopup = this.contextMenuService.open(
      event,
      {
        items,
        onInvoke: (item: ContextMenuItem) => this.handleVideoContextMenuCommand(item, locationTitle),
        minWidthItem: 90,
      },
      { position: 'right' }
    );
    setTimeout(() => {
      tinymce.activeEditor.selection.getNode().setAttribute(CardEditorConstants.CLASS_TINYMCE_FOCUS_BORDER, '1');
    }, 0);
    this.contextMenuPopup.destroy$.pipe(take(1)).subscribe(() => {
      this._contextMenuOpened = false;
      data.selectedElement.classList.remove('right-click-overlay');
    });
    return this.contextMenuPopup;
  }

  private async handleVideoContextMenuCommand(command: ContextMenuItem, locationTitle: string) {
    const selectedElement = command.data.selectedElement;
    const id = selectedElement.id || selectedElement.getAttribute('data-mce-p-id');
    this.sendEvent('context_menu.open', locationTitle, { label: command.id });
    switch (command.id) {
      case 'download':
        this.wikiCardMenuActions.startDownload(id);
        break;
      case 'edit':
        setTimeout(() => {
          command.data.editor.execCommand('mceMedia');
        }, 100);
        break;
      case 'preview': {
        const name = selectedElement.getAttribute('alt');
        const iconUrl = getIconByExtension(getExtensionByFileName(name || 'video.mp4'));
        await this.wikiCardMenuActions.openPreview(id, iconUrl, command.data.collectionId);
        break;
      }
      case 'delete':
        selectedElement.remove();
        break;
      case 'copy': {
        const clone = selectedElement.parentElement.cloneNode(true);
        selectedElement.parentElement.parentElement.appendChild(clone);
        break;
      }
      case 'playback':
        selectedElement.firstChild.playbackRate = command.data.playback;
        break;
      case 'picture':
        selectedElement?.firstChild?.requestPictureInPicture();
        break;
    }
    setTimeout(() => {
      tinymce.activeEditor?.save(); // this needed for the affect of the insert will happen
    }, 50);
    this.sendEvent('results.action', locationTitle, { label: command.id, target: 'mouse_click' });
  }

  sendEvent(key: string, locationTitle: string, info: Partial<EventInfo>): void {
    this.eventsService.event(key, {
      ...info,
      location: { title: locationTitle },
    });
  }

  buildVideoContextMenuItems(data: { selectedElement: HTMLElement; editor }, locationTitle: string, canEdit?: boolean): ContextMenuItem[] {
    const contextMenuItems: ContextMenuItem[] = [];
    // uncomment to add the playback and picture in picture for the video context menu
    if (canEdit) {
      contextMenuItems.push(
        // {
        //   id: 'playback',
        //   text: 'Playback speed',
        //   icon: { type: 'font-icon', value: 'icon-playback-speed' },
        //   items: this.buildPlayBackItems(locationTitle, data),
        // },
        // {
        //   id: 'picture',
        //   text: 'Picture in Picture',
        //   data,
        //   command: { type: 'picture' },
        //   icon: { type: 'font-icon', value: 'icon-pic-in-pic' },
        // },
        // {
        //   id: 'edit',
        //   text: 'Edit Media',
        //   data,
        //   command: { type: 'edit' },
        //   icon: { type: 'font-icon', value: 'icon-edit' },
        // },
        {
          id: 'copy',
          text: 'Copy',
          data,
          command: { type: 'copy' },
          icon: { type: 'font-icon', value: 'icon-copy2' },
        },
        {
          id: 'delete',
          text: 'Delete',
          data,
          command: { type: 'delete' },
          icon: { type: 'font-icon', value: 'icon-delete' },
        }
      );
    }
    const id = data.selectedElement.id || data.selectedElement.getAttribute('data-mce-p-id');
    if (id) {
      contextMenuItems.push({
        id: 'download',
        text: 'Download',
        data,
        command: { type: 'download-url' } as Commands.DownloadUrl,
        icon: { type: 'font-icon', value: 'icon-download2' },
      });
    }

    return contextMenuItems;
  }

  buildPlayBackItems(locationTitle: string, data: any) {
    const items = ['0.5', '0.75', 'Normal', '1.25', '1.5', '1.75', '2'];
    const menuItems = items.map(
      (i) =>
        ({
          id: 'playback',
          text: i,
          data: { ...data, playback: i === 'Normal' ? 1 : Number(i) },
          command: { type: 'playback' },
        } as ContextMenuItem)
    );
    return {
      items: menuItems,
      onInvoke: (item: ContextMenuItem) => this.handleVideoContextMenuCommand(item, locationTitle),
      menuWidth: 200,
    };
  }

  validateAttachments(html: string, oldAttachments: Wiki.CardAttachment[]) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const mediaAttachments = new Map(oldAttachments.filter((a) => a.type === 'Media').map((obj) => [obj.blobId, obj]));
    const fileAttachments = oldAttachments.filter((a) => a.type === 'File' || !a.type);
    const newMediaAttachments: Wiki.CardAttachment[] = [];

    const imageElements = doc.querySelectorAll('img');
    for (let index = 0; index < imageElements.length; index++) {
      const img = imageElements[index];
      if (img.id) {
        const ma = mediaAttachments.get(img.id);
        if (ma) {
          newMediaAttachments.push(ma);
        }
      }
    }
    const videoElements = doc.querySelectorAll('video');
    for (let index = 0; index < videoElements.length; index++) {
      const vid = videoElements[index];
      if (vid.id) {
        const ma = mediaAttachments.get(vid.id);
        if (ma) {
          newMediaAttachments.push(ma);
        }
      }
    }
    return [...newMediaAttachments.values(), ...fileAttachments];
  }

  getAttachmentsIds(html: string): string[] {
    if (!html) return [];
    return Array.from(html.matchAll(attachmentsRegex.FIND_ID_MEDIA_RXJS), (match) => match[2]);
  }
}
