import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EventsService } from '@shared/services';
import { getOS } from '@local/common-web';
import { SessionService } from '@shared/services/session.service';

@Component({
  selector: 'suggest-resync-overlay',
  templateUrl: './suggest-resync-overlay.component.html',
  styleUrls: ['./suggest-resync-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestResyncOverlayComponent implements OnInit {
  @Output() close = new EventEmitter<boolean>();
  @Output() updateStorage = new EventEmitter<number>();
  isLinux: boolean;

  constructor(
    private eventsService: EventsService,
    private cdr: ChangeDetectorRef,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.eventsService.event('resync_data_suggestion');
    const os = getOS();
    this.isLinux = os === 'Linux' || os === 'Unix';
    this.cdr.markForCheck();
  }

  resyncClick(value: 'sync' | 'no_sync'): void {
    this.eventsService.event('resync_data_suggestion_click', { name: value });
    this.eventsService.event('resync_click_interaction', { target: value });
    this.close.emit(true);
    if (value === 'sync') this.sessionService.activateUser();
    this.cdr.markForCheck();
  }
}
