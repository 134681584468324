<ng-container *ngIf="galleryView; else default">
  <u-icon [model]="{ type: 'img', value: { lightUrl: 'assets/ghost-img.svg' } }" [styles]="{ height: '50px', width: '50px' }"></u-icon>
  <div class="footer">
    <div class="ghost-circle"></div>
    <div class="ghost-row"></div>
  </div>
</ng-container>

<ng-template #default>
  <div class="list" [style.width]="width" [ngClass]="{ flat: flatView, lines: !showAvatar }">
    <div class="avatar"></div>
    <p *ngFor="let i of ghostingLine | fill"></p>
  </div>
</ng-template>
