import { ResourceMemorySourceSettings } from '../resources-memory-search';

export interface LocalActionSourceSettings extends ResourceMemorySourceSettings {
  type: 'local-action';
  maxCount?: number;
  withAppActions: boolean;
  withGoLinksAction: boolean;
  addActionByKey?: string;
  withAddToCollectionAction?: boolean;
}
