import { Results, Search } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class ResultsRpcInvoker implements Results.Service {
  @invoker
  shouldDisplayExtensionMessage(): Promise<boolean> {
    return;
  }

  @invoker
  setExtensionMessageDisplayed(enabled: boolean): Promise<void> {
    return;
  }

  @invoker
  getItems$(r: Results.GetItemsRequest): Observable<Search.ResultResourceItem[]> {
    return;
  }

  @invoker
  get updates$(): Observable<Search.Item[]> {
    return;
  }

  @invoker
  nextPage(origin: Search.Origin, token: string): Promise<Search.Response> {
    return;
  }

  @invoker
  search$(request: Search.Request): Promise<Observable<Search.Context>> {
    return;
  }

  @invoker
  isSiteLinksEnabled(): Promise<boolean> {
    return;
  }

  @invoker
  setSiteLinksEnabled(enabled: boolean): Promise<void> {
    return;
  }

  @invoker
  isContentSearchEnabled(): Promise<boolean> {
    return;
  }

  @invoker
  setContentSearchEnabled(enabled: boolean): Promise<void> {
    return;
  }
}
