import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Style } from '@local/client-contracts';
import { isIcon, randomColor } from '@shared/utils';
import { getDefaultProfile, getProfileIcon } from '@shared/utils/set-icon.util';
import { UiIconModel } from '@local/ui-infra';

export type AvatarShape = 'circle' | 'rectangle';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  initials: string;
  color = randomColor();
  sizeAvatar: { height: string; width: string } | {} = { height: '20px', width: '20px' };
 @Input() avatarShape: AvatarShape  = 'circle';

  @Input() set url(value: string | Style.Icon | UiIconModel) {
    if (typeof value === 'string') {
      if (value.length === 1) {
        this.initials = value;
      } else {
        this.model = { type: 'img', value: { lightUrl: value, darkUrl: value } };
      }
    } else if (isIcon(value)) {
      this.model = { type: 'img', value };
    } else {
      this.model = value;
    }
  }
  @Input() set size(size: string) {
    if (!size) return;
    this.sizeAvatar = size === 'none' ? {} : { height: size, width: size };
  }
  @Input() customClass: string;
  @Input() fallback = getDefaultProfile();
  model: UiIconModel = { type: 'img', value: getProfileIcon() };
}
