import { Desktop } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';

export class DesktopRpcInvoker implements Desktop.Service {
  @invoker
  defaultBrowser(): Promise<string> {
    return;
  }

  @invoker
  getClipboardData(): Promise<string> {
    return;
  }

  @invoker
  isDesktopAppInstalled(appId: string, resourceType?: string): Promise<boolean> {
    return;
  }
}
