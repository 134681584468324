import { PopupService } from '@local/ui-infra';
import { Injectable } from '@angular/core';
import { CollectionsService } from '../collections.service';
import { EventsService } from '@shared/services';
import { HubService } from '../hub.service';
import { WorkspacesService } from '../workspaces.service';
import { SelectedItem, TelemetryEvent } from '../../components/selected-item-popup/selected-item-popup.component';
import { SelectedItemPopupData } from '../../components/selected-item-popup/models/select-item-base';
import { AddToCollectionSelect } from '../../components/selected-item-popup/models/add-to-collection-select';
import { SelectItemsBasePopupService } from './select-items-popup-base.service';
import { CollectionsUtilService } from '../collections-util.service';
import { SearchOptions, SearchResultContext, SearchService, SearchSession } from '../search';
import { ResultSettings } from '../../views/results/utils/results-settings';
import { CollectionsSourceSettings } from '../search/client';
import { filter, firstValueFrom, take } from 'rxjs';
import { Collections } from '@local/client-contracts';

@Injectable()
export class AddCollectionToTabPopupService extends SelectItemsBasePopupService<AddToCollectionSelect> {
  readonly SETUP_DATA: SelectedItemPopupData = {
    description: 'Select which collection you want to add to this tab',
    placeholder: 'Select Collection',
    warningMessage: 'Ensure that users who have access to the collection also have access to the tab.',
  };
  private searchSession: SearchSession;

  constructor(
    protected popupService: PopupService,
    protected workspacesService: WorkspacesService,
    protected collectionsService: CollectionsService,
    protected eventsService: EventsService,
    protected hubService: HubService,
    private collectionsUtilService: CollectionsUtilService,
    protected searchService: SearchService
  ) {
    super(popupService, workspacesService);
    this.isInvalidItem = (selectedItem: SelectedItem) => !selectedItem?.collection;
    this.initOpenSelectPopupSub();
  }

  protected async init() {
    await this.initCollections();
  }

  private async initCollections() {
    this.searchSession = this.searchService.getOrCreateSearchSession('collections');
    const options: SearchOptions = {
      resetSession: true,
      sources: [
        {
          ...ResultSettings.defaultCollections,
          tag: 'add-collections-to-tab-popup',
        } as CollectionsSourceSettings,
      ],
      trigger: 'collections/user_query',
      includeNullResultContext: true,
    };

    const ctx: SearchResultContext = await firstValueFrom(
      this.searchSession.search$(options).pipe(
        filter(
          (c) =>
            c.searchCompleted &&
            c?.items.filter((i) => i.type === 'collection').every((c) => (c as Collections.Collection).resultsCount !== undefined)
        ),
        take(1)
      )
    );
    this.searchSession.destroy();
    if (!ctx) {
      return;
    }

    const collections = ctx?.items.filter((i) => i.type === 'collection') as Collections.Collection[];

    this.data.itemsList = await this.collectionsUtilService.initSelectedItems(collections);
  }

  initOpenSelectPopupSub() {
    this.collectionsService.openSelectPopup$.pipe(filter((r) => r.type === 'add-collection-to-tab')).subscribe((res) => {
      this.openSelectedItemPopup(res.data as AddToCollectionSelect);
    });
  }

  protected onTelemetryEvent(event: TelemetryEvent) {
    this.eventsService.event('collections.add_collection', {
      location: { title: this.hubService.currentLocation },
      target: event?.target ?? 'existing_collection',
      label: event?.trigger ?? 'mouse_click',
    });
  }

  protected getPopupDataOptions(): SelectedItemPopupData {
    return {
      ...this.SETUP_DATA,
      autoCompleteSettings: {
        displayCreateNew: false,
        createNewOption: false,
        showArrow: false,
      },
    };
  }

  protected async onPrimaryButtonClick() {
    const selectedItem = this.selectedItem as SelectedItem;
    const collection = await this.collectionsService.getById(selectedItem.id);
    this.collectionsService.pinToTabs([this.data.tabId], collection);
  }
}
