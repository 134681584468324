<ng-container *ngFor="let item of items; index as i">
  <div
    [attr.data-cy]="'context-menu-item-' + item.text"
    [class.disabled]="item.disabled"
    class="item"
    [style.min-width.px]="minWidthItem"
    #menuItem
    (click)="!item.items ? onInvoke(item, 'context_menu_click') : null"
    (mouseover)="item.items ? onInvokeItems($event, item, true) : closeSecondLevel()"
    *ngIf="!item.type || item.type !== 'separator'; else groupSeparator"
  >
    <ng-container *ngIf="item.icon" [ngSwitch]="item.icon.type">
      <ng-container *ngSwitchCase="'img'" #imgIcon>
        <u-icon class="icon" [model]="{ type: 'img', value: item.icon.value }"></u-icon>
      </ng-container>
      <ng-container *ngSwitchCase="'font-icon'" #fontIcon>
        <u-icon class="icon" [model]="{ type: 'font', value: item.icon.value }"></u-icon>
      </ng-container>
    </ng-container>
    <div class="text" [innerHTML]="item.text"></div>
    <div class="shortcut" *ngIf="item.shortcut">
      <span [innerHTML]="item.shortcut | keyIcon"></span>
    </div>
    <div class="shortcut" *ngIf="item.items">
      <u-icon class="icon icon-arrow" [model]="{ type: 'font', value: 'icon-arrow-right-key' }"></u-icon>
    </div>
  </div>
</ng-container>

<ng-template #groupSeparator>
  <div class="separator"></div>
</ng-template>
