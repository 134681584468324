import { Preferences } from '@local/client-contracts';
import { capitalCase } from '@local/ts-infra'
import { GeneralPreferencesCommand } from '../models/preferences-command';
import { SearchPopupItem } from '../views/special-views/search-popup/model';

//@ts-ignore
export class PreferencesToSearchPopUpBuilder implements GeneralPreferencesBuilder {
  build(general: Preferences.GeneralPreferences): PreferencesSearchPopupItem<any>[] {
    const children: PreferencesSearchPopupItem<any>[] = [];
    for (const property of Object.keys(general)) {
      if (!this[`build${capitalCase(property)}`]) {
        continue;
      }
      const child = this[`build${capitalCase(property)}`](general[property]);
      if (child) {
        if (Array.isArray(child)) {
          child.forEach((c) => children.push(c));
        } else {
          children.push(child);
        }
      }
    }
    return children;
  }

  buildShowOnDock(dock: boolean): PreferencesSearchPopupItem<'showOnDock'> {
    return {
      id: 'preferences_show_on_dock',
      visibility: 'always',
      icon: { type: 'font-icon', value: 'icon-cog2' },
      command: { type: 'preferences', value: { property: 'showOnDock', value: !dock } },
      type: 'child',
      title: `${dock ? 'Hide from' : 'Show on'} Dock`,
    };
  }

  buildStartOnStartUp(startOnStartUp: boolean): PreferencesSearchPopupItem<'startOnStartUp'> {
    return {
      id: 'preferences_start_on_startup',
      visibility: 'always',
      icon: { type: 'font-icon', value: 'icon-cog2' },
      command: {
        type: 'preferences',
        value: { property: 'startOnStartUp', value: !startOnStartUp },
      },
      type: 'child',
      title: `${!startOnStartUp ? 'Launch' : "Don't launch"} Unleash on startup`,
    };
  }

  buildBarWindowAutoHide(barWindowAutoHide: boolean): PreferencesSearchPopupItem<'barWindowAutoHide'> {
    return {
      id: 'preferences_bar_auto_hide',
      visibility: 'always',
      icon: { type: 'font-icon', value: 'icon-cog2' },
      command: { type: 'preferences', value: { property: 'barWindowAutoHide', value: !barWindowAutoHide } },
      type: 'child',
      title: `${!barWindowAutoHide ? 'A' : "Don't a"}utomatically hide Unleash`,
    };
  }

  buildScheme(scheme: Preferences.Theme): PreferencesSearchPopupItem<'scheme'>[] {
    const command: Omit<GeneralPreferencesCommand<'scheme'>, 'value'> = {
      type: 'preferences',
    };
    const common: Omit<PreferencesSearchPopupItem<'scheme'>, 'title' | 'command'> = {
      id: 'preferences_scheme',
      visibility: 'always',
      icon: { type: 'font-icon', value: 'icon-cog2' },
      type: 'child',
    };

    const items = [];
    if (scheme !== 'dark') {
      items.push({ ...common, title: 'Change to dark theme', command: { ...command, value: { property: 'scheme', value: 'dark' } } });
    }
    if (scheme !== 'light') {
      items.push({ ...common, title: 'Change to light theme', command: { ...command, value: { property: 'scheme', value: 'light' } } });
    }
    if (scheme !== 'auto') {
      items.push({
        ...common,
        title: 'Sync Unleash theme with the system',
        command: { ...command, value: { property: 'scheme', value: 'auto' } },
      });
    }

    return items;
  }
  //@ts-ignore
  buildWindowType(barMode: BarMode): PreferencesSearchPopupItem<'barMode'>[] {
    //@ts-ignore
    const command: Omit<GeneralPreferencesCommand<'barMode'>, 'value'> = {
      type: 'preferences',
    };
    //@ts-ignore
    const common: Omit<PreferencesSearchPopupItem<'barMode'>, 'title' | 'command'> = {
      id: 'preferences_scheme',
      visibility: 'always',
      icon: { type: 'font-icon', value: 'icon-cog2' },
      type: 'child',
    };

    const commands = [
      { ...common, title: 'Floating', command: { ...command, value: { property: 'barMode', value: 'Floating' } } },
      { ...common, title: 'Tile to left', command: { ...command, value: { property: 'barMode', value: 'Tile to left' } } },
      { ...common, title: 'Tile to right', command: { ...command, value: { property: 'barMode', value: 'Tile to right' } } },
      { ...common, title: 'Unleash app', command: { ...command, value: { property: 'barMode', value: 'Unleash app' } } },
    ];
    //@ts-ignore
    return commands.filter((c) => c.title !== barMode);
  }

  buildPinToTaskbar(pinToTaskbar: boolean): PreferencesSearchPopupItem<'pinToTaskbar'> {
    return {
      id: 'preferences_pin_to_taskbar',
      visibility: 'always',
      icon: { type: 'font-icon', value: 'icon-cog2' },
      command: { type: 'preferences', value: { property: 'pinToTaskbar', value: !pinToTaskbar } },
      type: 'child',
      title: `${!pinToTaskbar ? 'Pin' : 'Unpin'} Unleash ${!pinToTaskbar ? 'to' : 'from'} Taskbar`,
    };
  }
}

type GeneralProperty = Capitalize<keyof Preferences.GeneralPreferences>;
type GeneralBuilderName = `build${GeneralProperty}`;

export type _GeneralPreferencesBuilder = {
  [L in GeneralBuilderName]: (
    value: any
  ) =>
    | PreferencesSearchPopupItem<keyof Preferences.GeneralPreferences>
    | PreferencesSearchPopupItem<keyof Preferences.GeneralPreferences>[];
};

export interface PreferencesSearchPopupItem<K> extends SearchPopupItem<'child'> {
  command: GeneralPreferencesCommand<K>;
}
export interface GeneralPreferencesBuilder extends _GeneralPreferencesBuilder {
  build(general: Preferences.GeneralPreferences): PreferencesSearchPopupItem<any>[];
}
