import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { Experiences, Filters, Omnibox } from '@local/client-contracts';
import { AssistantsIconsConst, Constants } from '@local/common';
import { ViewSettings, getAssistantTitle, resultsViewSettings } from '@local/common-web';
import { KeyName, isKey, keyCodes } from '@local/ts-infra';
import { PopupService, UiIconModel } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ConstantFilter,
  Filter,
  FilterChangeData,
  FilterViewDetails,
  SpecialSearchFilterValue,
  isNestedFilterValue,
} from '@shared/components/filters/models';
import { ValuesUpdatedEvent } from '@shared/components/filters/multi-select-filter-base.component';
import { ResultFiltersFocusService } from '@shared/components/filters/result-filters-focus.service';
import { ResultsFiltersBoxComponent } from '@shared/components/filters/results-filters-box/results-filters-box.component';
import { KeyboardHelperService } from '@shared/helper/keyboard-helper.service';
import { EventsService, LogService } from '@shared/services';
import { BreadcrumbsService } from '@shared/services/breadcrumbs.service';
import { FlagsService } from '@shared/services/flags.service';
import { KeyboardService } from '@shared/services/keyboard.service';
import { RouterService } from '@shared/services/router.service';
import { Logger } from '@unleash-tech/js-logger';
import { cloneDeep, isEmpty, isEqual, union } from 'lodash';
import { NgScrollbar } from 'ngx-scrollbar';
import { ReplaySubject, Subject, Subscription, debounceTime, distinctUntilChanged, filter, take } from 'rxjs';
import { FyiService } from 'src/app/bar/services';
import { ExperiencesService } from 'src/app/bar/services/experiences.service';
import { FiltersService, MoreFiltersOptions, SuggestionsSettings } from 'src/app/bar/services/filters.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { SearchOptions, SearchResultContext, SearchService } from 'src/app/bar/services/search';
import { LinkResourcesResultExtra, LinkResourcesSourceSettings } from 'src/app/bar/services/search/client';
import { isLinkResourcesSettings } from 'src/app/bar/services/search/client/source-setings.util';
import { ShareOptionsService } from 'src/app/bar/services/share-options.service';
import { ShowToasterService } from 'src/app/bar/services/show-toaster.service';
import * as uuid from 'uuid';
import { ResultsFilterEvent, ResultsFilterEventOperation } from '../../../collections-page/models';
import { ExperienceSearchItem, ResultItem } from '../../../results';
import { ScrollService } from '../../../results/services/scroll.service';
import { AssistantFilterPriority } from '../../enum/assistant-filter-priority.enum';
import { AssistantViewHelper } from '../../helpers/assistant-view-helper';
import { AssistantConst } from '../../helpers/assistant.const';
import { assistantContent } from '../../helpers/assistant.content';
import { FilterSourceViewModel, SourceUnavailableState } from '../../models/filter-source-view-model';
import { AssistantPopupService } from '../../services/assistant-popup.service';

enum AssistantComponentFocused {
  TITLE = 1,
  DESCRIPTION = 2,
  WIKI_COLLECTION = 3,
  APPS = 4,
}

@UntilDestroy()
@Component({
  selector: 'assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssistantComponent implements OnInit, AfterViewInit {
  readonly assistantContent = assistantContent;
  readonly AssistantFilterPriority = AssistantFilterPriority;
  private readonly FIXED_VIEW_DETAILS_PER_FILTER: { [filterName: string]: FilterViewDetails } = {
    [AssistantConst.ACCOUNT_FILTER_NAME]: { showIndicatorInList: true },
    [AssistantConst.TYPE_FILTER_NAME]: {
      noCheckbox: true,
      oneValue: true,
      hideOnSelect: true,
    },
  };
  private readonly LIMIT_FILTERS_COUNT: { [filterName: string]: number } = {
    [AssistantConst.ACCOUNT_FILTER_NAME]: 2,
    [AssistantConst.TYPE_FILTER_NAME]: 3,
  };

  private predicateId: string;
  private originalAssistant: ExperienceSearchItem;
  private _assistant: ExperienceSearchItem;
  private deletedAssistantId: string;
  private logger: Logger;
  private enableAssistantChannelFiltersCreatedBy: boolean;
  private channelFiltersSub: Subscription;
  formInputChanged = new Subject<string>();
  draftMode: boolean;
  isEqualAssistant = true;
  loadingSaveButton: boolean;
  loading = true;
  experienceType: Experiences.ExperienceType;
  title: string;
  subtitle: string;
  titleTooltip: string;
  subtitleTooltip: string;
  disableWikis: boolean;

  set assistant(value: ExperienceSearchItem) {
    this._assistant = value;
    this.experienceType = value?.experienceType;
    this.title = getAssistantTitle(value);
    this.subtitle = value?.properties.teamName;

    setTimeout(() => {
      this.titleTooltip = this.getAssistantNameTooltip();
      this.subtitleTooltip = this.getAssistantSubNameTooltip();
    }, 5);

    if (this.originalAssistant) {
      this.isEqualAssistant = isEqual(this.originalAssistant, this.assistant);
    }
    this.cdr.markForCheck();
  }

  get assistant(): ExperienceSearchItem {
    return this._assistant;
  }

  get disableWikisAndApps() {
    return this.draftMode && !this.isFullGeneralProperties();
  }

  @ViewChild(ResultsFiltersBoxComponent) resultsFiltersBoxComponent: ResultsFiltersBoxComponent;
  @ViewChild('assistantName', { read: ElementRef }) assistantNameRef: ElementRef;
  @ViewChild('assistantSubName', { read: ElementRef }) assistantSubNameRef: ElementRef;

  //apps filters
  private viewSettings: ViewSettings;
  filterSourceViews: FilterSourceViewModel[] = [];
  initLoadFiltersApp: boolean;
  private objectsSharedWithCreator: Experiences.ExperienceSharedObjects;
  objectsSharedWithCreator$ = new ReplaySubject<{ res: Experiences.ExperienceSharedObjects; assistantId: string }>(1);

  //channel filters
  channelDataSource: Experiences.FilterDataSource;
  channelFilters: Filter[];

  //query
  showAddQuery: boolean;
  allowAddQuery: boolean;
  dataSourceExist: boolean;

  //keyboard
  private keyHandlerId: string;
  componentFocused: AssistantComponentFocused;
  AssistantComponentFocused = AssistantComponentFocused;
  private keyboardHelperService: KeyboardHelperService = new KeyboardHelperService('vertical');
  selectedIndex: number;

  // scroll
  private scrollService: ScrollService<any>;
  @ViewChild(CdkVirtualScrollViewport) scrollViewport: CdkVirtualScrollViewport;
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
  @ViewChildren('resultsFiltersBoxLine', { read: ElementRef }) resultsFiltersBoxLines: QueryList<ElementRef>;

  constructor(
    private fyiService: FyiService,
    private activeRoute: ActivatedRoute,
    private assistantService: ExperiencesService,
    private cdr: ChangeDetectorRef,
    private hubService: HubService,
    private filtersService: FiltersService,
    private keyboardService: KeyboardService,
    private eventsService: EventsService,
    private resultFiltersFocusService: ResultFiltersFocusService,
    private ngZone: NgZone,
    private routerService: RouterService,
    private searchService: SearchService,
    private assistantPopupService: AssistantPopupService,
    private showToasterService: ShowToasterService,
    private popupService: PopupService,
    private shareOptionsService: ShareOptionsService,
    private assistantViewHelper: AssistantViewHelper,
    private flagsService: FlagsService,
    private logService: LogService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.viewSettings = resultsViewSettings['assistants'];
    this.logger = logService.scope('AssistantComponent');
  }
  ngAfterViewInit(): void {
    this.formInputChanged.pipe(debounceTime(800), distinctUntilChanged(), untilDestroyed(this)).subscribe((value: string) => {
      this.assistant = {
        ...this.assistant,
        properties: {
          ...(this.assistant.properties || {}),
          description: value,
        },
      };
      this.cdr.markForCheck();
    });
  }
  ngOnInit(): void {
    if (this.activeRoute.snapshot.params['new'] === 'new') {
      const assistantType = this.activeRoute.snapshot.params['title'];
      if (Experiences.ExperiencesWithDraftMode.includes(assistantType)) {
        this.initDraftAssistant(assistantType);
        this.initBreadcrumbs(assistantType);
        this.objectsSharedWithCreator = null;
        this.objectsSharedWithCreator$.next({
          res: null,
          assistantId: null,
        });
      } else {
        this.assistantService.createAssistant({ experienceType: 'rfp', properties: { title: assistantType } });
      }
    }
    this.hubService.clearQueryParams(['mode']);
    this.hubService.readOnly = true;
    this.initLoadFiltersApp = true;
    this.initFlags();

    if (this.activeRoute.snapshot.data.assistant) {
      this.assistant = this.activeRoute.snapshot.data.assistant;
    }
    this.assistantService.currentAssistant$
      .pipe(
        filter((r) => !!r),
        untilDestroyed(this),
        distinctUntilChanged((prev, next) => this.assistantService.compareWithoutDataSources(prev, next))
      )
      .subscribe(async (currentAssistant: ExperienceSearchItem) => {
        this.loading = false;
        const icon = AssistantsIconsConst[currentAssistant.experienceType];
        currentAssistant = { ...currentAssistant, icon: icon as UiIconModel };
        this.originalAssistant = cloneDeep(currentAssistant);
        this.assistant = cloneDeep(currentAssistant);
        this.resetFilterData();
        let res: Experiences.ExperienceSharedObjects = null;
        if (this.assistant.permissionRole !== 'creator') {
          res = await this.assistantService.getSharedObjects(this.assistant.createdBy);
        }
        this.objectsSharedWithCreator = res;
        this.objectsSharedWithCreator$.next({
          res,
          assistantId: currentAssistant.id,
        });
        await this.setAssistantData();
        this.componentFocused = AssistantComponentFocused.WIKI_COLLECTION;
        this.predicateId = this.routerService.addPredicateNavigation(async (url) => this.shouldNavigate(url));
        this.eventsService.event('pageview', { location: { title: this.hubService.currentLocation } });
      });

    this.assistantService.onDeletedAssistantId$.pipe(untilDestroyed(this)).subscribe((val) => {
      this.deletedAssistantId = val;
    });

    this.setUpKeyboardHelperService();
    this.registerKeyboardHandler();
    this.fyiService.active$.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        this.resultsFiltersBoxComponent?.closeAll();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.predicateId) {
      this.routerService.removePredicateNavigation(this.predicateId);
    }
    this.unregisterKeyHandler();
    this.filterSourceViews.forEach((d) => {
      d?.searchSession?.destroy();
    });
    this.objectsSharedWithCreator$?.unsubscribe();
  }

  async initFlags() {
    const [disableWikis, enableAssistantChannelFiltersCreatedBy] = await Promise.all([
      this.flagsService.isEnabled(Constants.DISABLED_WIKIS_FLAG),
      this.flagsService.isEnabled(Constants.ENABLE_ASSISTANT_CHANNEL_FILTERS_CREATED_BY),
    ]);
    this.disableWikis = disableWikis;
    this.enableAssistantChannelFiltersCreatedBy = enableAssistantChannelFiltersCreatedBy;
  }

  private initDraftAssistant(assistantType: Experiences.ExperienceType) {
    this.assistant = {
      type: assistantType,
      id: null,
      dataSources: [],
      filtersDataSources: [],
      experienceType: assistantType,
      icon: { type: 'font', value: 'icon-assistant' },
      properties: {
        title: 'Untitled',
      },
      permissionRole: 'creator',
    };
    this.draftMode = true;
    this.loading = false;
    this.resetFilterData();
    this.setAssistantData();
  }

  private initBreadcrumbs(assistantType: Experiences.ExperienceType) {
    this.breadcrumbsService.showGhost = false;
    this.breadcrumbsService.items = [
      {
        title: 'Assistants',
        path: 'assistants',
        icon: {
          type: 'font-icon',
          value: 'icon-assistant',
        },
      },
      {
        title: 'Untitled',
        path: `new/${assistantType}`,
      },
    ];
  }

  private getAssistantNameTooltip() {
    const titleEl: HTMLElement = this.assistantNameRef?.nativeElement;
    if (!titleEl) return;
    const width = titleEl.clientWidth;
    const scrollWidth = titleEl.scrollWidth;
    return width < scrollWidth ? this.title : '';
  }

  private getAssistantSubNameTooltip() {
    const titleEl: HTMLElement = this.assistantSubNameRef?.nativeElement;
    if (!titleEl) return;
    const width = titleEl.clientWidth;
    const scrollWidth = titleEl.scrollWidth;
    return width < scrollWidth ? this.subtitle : '';
  }

  private shouldNavigate(url: string): boolean {
    if (this.assistant.readOnly || this.isEqualAssistant || this.deletedAssistantId === this.assistant.id) {
      this.routerService.removePredicateNavigation(this.predicateId);
      return true;
    }
    const onPrimary = () => {
      this.save();
      this.navigate(url);
    };
    this.assistantPopupService.openAreYouSureLeavePage(onPrimary, () => this.navigate(url));
    return false;
  }

  private navigate(url: string) {
    this.routerService.removePredicateNavigation(this.predicateId);
    this.routerService.navigateByUrl(url);
  }

  //keyboard & scroll
  private registerKeyboardHandler() {
    if (this.keyHandlerId) return;
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => {
      if (this.popupService.hasDialog) return;
      if (this.componentFocused === AssistantComponentFocused.APPS) {
        this.handleKeys(keys, event);
        this.keyboardHelperService.handleArrows(keys, event);
        this.scrollService.scrollIfNeeded(this.selectedIndex, this.filterSourceViews);
      }
    }, 9);
    this.cdr.markForCheck();
  }

  private async handleKeys(keys: Array<KeyName>, event: KeyboardEvent) {
    if (isKey(event, keyCodes.ArrowUp)) {
      if (this.selectedIndex === 0) {
        this.componentFocusedFinish();
      }
    }
  }

  private componentFocusedFinish() {
    this.componentFocused =
      this.componentFocused === AssistantComponentFocused.WIKI_COLLECTION
        ? AssistantComponentFocused.APPS
        : AssistantComponentFocused.WIKI_COLLECTION;

    if (this.componentFocused === AssistantComponentFocused.APPS) {
      this.hubService.focusPosition = 'filters';
      this.selectedIndex = 0;
    } else {
      this.selectedIndex = null;
    }
    this.keyboardHelperService.selectedIndex = this.selectedIndex;
    this.resultFiltersFocusService.currentFiltersFocus$.next(this.selectedIndex);
    this.cdr.markForCheck();
  }

  private unregisterKeyHandler() {
    if (!this.keyHandlerId) return;
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    this.keyHandlerId = null;
  }

  private setUpKeyboardHelperService() {
    this.keyboardHelperService.updateCdr.pipe(untilDestroyed(this)).subscribe(() => this.cdr.markForCheck());
    this.keyboardHelperService.updateSelectedIndex.pipe(untilDestroyed(this)).subscribe((index) => {
      if (index === undefined) {
        this.selectedIndex = null;
      } else {
        this.selectedIndex = index;
      }
      this.resultFiltersFocusService.currentFiltersFocus$.next(this.selectedIndex);
      this.cdr.markForCheck();
    });
    this.keyboardHelperService?.onInit(0, this.filterSourceViews, null);
  }

  private refreshScrollService() {
    if (!this.scrollService) {
      this.scrollService = new ScrollService(this.ngZone);
    }
    this.scrollService.items = this.filterSourceViews;
    this.scrollService.itemHeights = this.filterSourceViews.map(() => 60);
    this.scrollService.init(this.scrollbarRef, this.resultsFiltersBoxLines, this.scrollViewport);
    if (!this.scrollViewport?.elementRef?.nativeElement) {
      this.scrollService?.destroy();
    }
  }

  //init data
  private resetFilterData() {
    this.filterSourceViews?.forEach((s) => {
      this.resetFilterSourceView(s);
    });
    this.filterSourceViews = [];
    this.initLoadFiltersApp = true;
    this.loadingSaveButton = false;
    this.channelFilters = null;
    this.channelDataSource = null;
    this.showAddQuery = null;
    this.allowAddQuery = null;
    this.dataSourceExist = null;
    this.channelFiltersSub?.unsubscribe();
    this.cdr.markForCheck();
  }

  private async setAssistantData() {
    let filterSources = cloneDeep(this.assistant.filtersDataSources);
    if (filterSources?.length === 0 && !this.assistant.readOnly) {
      filterSources = [this.assistantViewHelper.getEmptyFilterSource()];
    }

    if (filterSources.length === 0) {
      this.initLoadFiltersApp = false;
    }
    this.cdr.markForCheck();
    const sharedObjects = this.objectsSharedWithCreator;
    const promises = filterSources.map((source) => {
      return this.assistantViewHelper.initFilterSourceView(source, this.assistant, sharedObjects).catch((err) => {
        this.logger.error('failed to convert filterSource', { err, source });
        return null;
      });
    });
    const results = await Promise.all(promises);
    this.filterSourceViews = results.filter((r) => !!r);
    for (let index = 0; index < this.filterSourceViews.length; index++) {
      const isLast = index === filterSources.length - 1;
      this.updateFilters(index, isLast);
    }
    if (this.assistant.experienceType === 'slack' && this.assistant.channelDataSource) {
      this.channelDataSource = cloneDeep(this.assistant.channelDataSource);
      this.updateChannelDataSource();
    }
    if (this.filterSourceViews) {
      setTimeout(() => {
        this.refreshScrollService();
        this.keyboardHelperService?.onInit(0, this.filterSourceViews, this.scrollService);
      }, 0);
    }
    this.cdr.markForCheck();
  }

  private updateFilters(index: number, resetLoading = false) {
    const dataSourcesView = this.getFilterSourceViewModel(index);
    this.checkAddQueryStatus();
    const searchParamsFilters: Filters.Values = this.getFilterSourceFilters(index) || {};
    dataSourcesView.resourcesCount = null;
    if (!dataSourcesView.preventResourcesSearch && !isEmpty(searchParamsFilters)) {
      this.search(index, dataSourcesView);
    }
    const { filters, moreFilters, spreadAsMultiSelect, nestedFilters } = this.viewSettings;
    dataSourcesView.filtersSub?.unsubscribe();
    //Assistant with viewer permission - display only filters with selected values (Except an account that will always display)
    const isViewer = this.assistant.permissionRole === 'viewer';
    let base = filters.map((v) => v.name);
    if (isViewer) {
      base = base.filter((f) => f === AssistantConst.ACCOUNT_FILTER_NAME || searchParamsFilters[f]);
    }
    const nestedFiltersNames = nestedFilters.flat();
    const allFilterNames = union(base, nestedFiltersNames);
    const baseFilterNames = [...base, ...Object.keys(searchParamsFilters).filter((v) => !allFilterNames.includes(v))];
    //Display more filters only when the line is not disabled or read-only
    const preventMoreFilters = dataSourcesView.disabled || dataSourcesView.readonly;
    const moreFiltersSettings: MoreFiltersOptions = {
      ...moreFilters,
      inlineFilter: this.getSpecificResourceFilter(index),
      disabled: preventMoreFilters,
    };
    //When the filter is read-only or disabled: display only selected values (Avoid unnecessary backend searches)
    const displayOnlySelected = dataSourcesView.readonly || dataSourcesView.disabled;
    dataSourcesView.filtersSub = this.filtersService
      .getFloatingResultsFilters({
        sessionName: `assistant-${this.assistant.id}-${index}`,
        typeSuggestedFilters: [],
        moreFilters: moreFiltersSettings,
        baseFilters: baseFilterNames.map((n) => ({ name: n })),
        selectedFilters: searchParamsFilters,
        spreadAsMultiSelect,
        optionsSuggestionProvider: {
          maxItems: 50,
          filtersToSearchOnlySelected: displayOnlySelected ? 'all' : [],
        },
        suggestionProvider: (term, group, ctx, options, activeFilters, sessionName) =>
          this.suggestionProvider(term, group, ctx, options, activeFilters, sessionName),
        moreFilterValuesViewDetails: { appendTo: 'body', showItemIconLabel: true },
        showSingleItemSelected: true,
        allowEmptyFilter: true,
        typeFiltersSingleItemSelected: ['type', 'account'],
        addSelectedValuesToMoreFilters: true,
        nestedFilters,
        timeFilterSettings: {
          pickerTypes: ['before', 'after', 'range'],
          maxDate: new Date(),
        },
        defaultViewDetails: {
          appendTo: 'body',
          showItemIconLabel: true,
        },
        viewDetailsPerFilter: this.FIXED_VIEW_DETAILS_PER_FILTER,
      })
      .subscribe((f) => {
        this.addDisableToFilters(
          f,
          index,
          dataSourcesView.disabled || this.disableWikisAndApps,
          dataSourcesView.readonly,
          dataSourcesView.unavailableState
        );
        dataSourcesView.filterValues = f;
        if (resetLoading) {
          this.initLoadFiltersApp = false;
        }
        this.cdr.markForCheck();
      });
  }

  private search(index: number, dataSourcesView: FilterSourceViewModel) {
    if (!dataSourcesView.searchSession) {
      dataSourcesView.searchSession = this.searchService.getOrCreateSearchSession(`assistant-${index}`);
    }
    const options: SearchOptions = {
      resetSession: false,
      sources: [
        {
          id: 'link-resources',
          type: 'link-resources',
          requestMaxCount: 0,
          caching: { strategy: 'cache-or-source' },
          useSourceFilters: true,
          disableCloudGroup: true,
          sessionId: uuid.v4(),
          tag: 'assistant',
          disableAggregations: true,
          filters: { preFilters: this.getSearchFilters(index), postFilters: {} },
          preventRTF: true,
          advancedSearch: false,
          contentSearch: false,
          shouldSortByDate: true,
          includeHiddenLinks: true,
        } as LinkResourcesSourceSettings,
      ],
      trigger: 'assistant',
    };
    dataSourcesView.searchSession
      .search$(options)
      .pipe(
        filter((c) => c.searchCompleted),
        take(1)
      )
      .subscribe((ctx: SearchResultContext) => {
        const resourceSource = ctx.sources?.find((s) => isLinkResourcesSettings(s.source));
        const extra = resourceSource?.extra as LinkResourcesResultExtra;
        const cloud = extra.search.origins?.Cloud?.response;
        const local = extra.search.origins?.Local?.response;
        dataSourcesView.resourcesCount = cloud?.totalResults || 0 + local?.totalResults || 0;
        this.cdr.markForCheck();
      });
  }

  private getSpecificResourceFilter(index: number) {
    let childFilter: Filter = null;
    const resource = AssistantConst.SPECIAL_FILTER_TITLE;
    const filterName = AssistantConst.SPECIAL_FILTER_NAME;
    const resources = this.getFilterSourceResources(index)?.filter((r) => r) || [];
    if (resources.length) {
      childFilter = {
        title: resource,
        name: filterName,
        picker: 'multi-select',
        values: resources.map((item) => ({ ...item, selected: true, value: item.id, subtitle: '', filterName: filterName })),
        type: 'pre',
        icon: { type: 'font-icon', value: 'icon-page' },
        readonly: true,
        viewDetails: {
          keepPlaceholder: true,
          allowClickInReadOnly: true,
        },
      };
    }

    return {
      id: `filter:${resource}`,
      type: 'special',
      title: resource,
      value: resource,
      subtitle: '',
      icon: { type: 'font-icon', value: 'icon-page' },
      childFilter,
      filterName,
    } as SpecialSearchFilterValue;
  }

  private async suggestionProvider(
    term: string,
    group: string,
    ctx: Omnibox.SuggestionContext,
    options: SuggestionsSettings,
    activeFilters: Filters.ActiveFilters,
    sessionName: string
  ): Promise<Omnibox.Suggestion[]> {
    const appState = activeFilters[AssistantConst.APP_FILTER_NAME]?.[0]?.value;
    const sharedLinks = this.objectsSharedWithCreator?.links;
    const items = await this.filtersService.getSuggestionsAssistant(
      sessionName,
      term,
      group,
      ctx,
      { ...options },
      appState,
      activeFilters,
      this.assistant.permissionRole === 'creator',
      sharedLinks
    );
    switch (group) {
      case 'type':
        return items.filter((i) => !i.disabled);
      default:
        return items;
    }
  }

  private addDisableToFilters(
    f: Filter[],
    index: number,
    setAllDisabled?: boolean,
    setAllReadonly?: boolean,
    unavailableState?: SourceUnavailableState
  ) {
    const filters = this.getFilterSourceFilters(index);
    const hasTypeFilter = !!filters[AssistantConst.TYPE_FILTER_NAME];
    const hasAccountFilter = !!filters[AssistantConst.ACCOUNT_FILTER_NAME];
    if (setAllDisabled || setAllReadonly) {
      for (const filter of f) {
        if (setAllDisabled) {
          filter.disabled = true;
          filter.viewDetails.showSingleItemSelected = false;
        }
        if (setAllReadonly) {
          filter.picker = 'constant';
          //Hack to display account name when the filter is readonly (constant), because the app filter is nested
          //When the user does not have access to the account - a message that there is no access is displayed
          if (filter.name === AssistantConst.APP_FILTER_NAME) {
            const constantLabel = this.getAccountConstantLabel(unavailableState, filter);
            if (constantLabel) {
              (filter as ConstantFilter).constantLabel = `(${constantLabel})`;
            }
          }
        }
      }
      return;
    }
    const typeFilter = f.find((t) => t.name === AssistantConst.TYPE_FILTER_NAME);
    if (typeFilter) {
      typeFilter.disabled = !hasAccountFilter;
    }
    const canShowMoreFilters = hasAccountFilter && hasTypeFilter;
    const hasSelectedResources = !!this.getFilterSourceResources(index)?.length;
    const moreFilterInd = f.findIndex((t) => t.name === AssistantConst.MORE_FILTERS_FILTER_NAME);
    if (moreFilterInd !== -1) {
      f[moreFilterInd].disabled = !canShowMoreFilters || hasSelectedResources;
    }
    //if specificResourceItems exist , all filters that in more filters become readonly
    f?.filter(
      (filter) =>
        ![
          AssistantConst.APP_FILTER_NAME,
          AssistantConst.TYPE_FILTER_NAME,
          AssistantConst.MORE_FILTERS_FILTER_NAME,
          AssistantConst.SPECIAL_FILTER_NAME,
        ].includes(filter.name)
    ).forEach((filter) => {
      if (hasSelectedResources) {
        filter.picker = 'constant';
      }
    });
  }

  private getAccountConstantLabel(unavailableState: SourceUnavailableState, filter: Filter) {
    if (unavailableState === 'noAccess') {
      return assistantContent.accountNoAccessMessage;
    }
    const nestedValue = filter.values.find((v) => v.selected);
    if (!isNestedFilterValue(nestedValue)) {
      return;
    }
    return nestedValue.childFilter?.values?.find((v) => v.selected)?.title;
  }

  //filter source view
  private getFilterSourceViewModel(index: number) {
    return this.filterSourceViews[index];
  }

  private getFilterSource(index: number) {
    return this.getFilterSourceViewModel(index)?.filterSource;
  }

  private getFilterSourceFilters(index: number) {
    return this.getFilterSource(index)?.filters;
  }

  private getFilterSourceResources(index: number) {
    return this.getFilterSource(index)?.resources;
  }

  private getSearchFilters(index: number) {
    const { resourceId, ...filters } = this.getFilterSourceFilters(index) || {};
    return filters;
  }

  private hasOneEmptySource(): boolean {
    const firstSourceFilters = this.getFilterSourceFilters(0);
    const singularEmptySource = this.filterSourceViews.length === 1 && isEmpty(firstSourceFilters);
    return singularEmptySource;
  }

  private resetPriority(sourceView: FilterSourceViewModel) {
    const highPriority = sourceView.filterSource?.tier === AssistantFilterPriority.HIGH;
    if (highPriority) {
      sourceView.filterSource.tier = AssistantFilterPriority.SECOND;
    }
  }

  private resetResources(sourceView: FilterSourceViewModel) {
    sourceView.filterSource.resources = [];
  }

  //actions
  async save() {
    if (this.isEqualAssistant) return;
    this.originalAssistant = cloneDeep(this.assistant);
    this.isEqualAssistant = true;
    this.loadingSaveButton = true;
    if (!this.assistant.id) {
      this.createAssistant();
    } else {
      this.updateAssistant();
    }
  }

  private createAssistant() {
    const unavailableFilterSourceIds = this.filterSourceViews.filter((f) => f.unavailableState).map((f) => f.filterSource.id);
    this.assistantService.saveDraftAssistant(this.assistant, unavailableFilterSourceIds).then((title) => {
      const toaster = this.showToasterService.showToaster({
        id: 'save-assistant',
        content: `${title} ${this.assistantContent.createText}`,
        intent: 'primary',
        icon: { type: 'font', value: 'icon-check-circle' },
        iconIntent: 'success',
      });
      toaster.compInstance.invoke.subscribe(() => {
        toaster.destroy();
      });
      this.loadingSaveButton = false;
      this.cdr.markForCheck();
    });
  }

  private updateAssistant() {
    const unavailableFilterSourceIds = this.filterSourceViews.filter((f) => f.unavailableState).map((f) => f.filterSource.id);
    this.assistantService.update(this.assistant, unavailableFilterSourceIds).then(() => {
      const toaster = this.showToasterService.showToaster({
        id: 'save-assistant',
        content: this.assistantContent.updateText,
        intent: 'primary',
      });
      toaster.compInstance.invoke.subscribe(() => {
        toaster.destroy();
      });
      this.loadingSaveButton = false;
      this.cdr.markForCheck();
    });
  }

  openLearnMore(url: string) {
    window.open(url, '_blank');
  }

  cancel() {
    if (this.isEqualAssistant) return;
    if (this.draftMode) {
      this.routerService.back();
      return;
    }
    this.initLoadFiltersApp = true;
    this.cdr.markForCheck();
    setTimeout(async () => {
      this.assistant = cloneDeep(this.originalAssistant);
      this.resetFilterData();
      await this.setAssistantData();
    }, 0);
  }

  onSelectedCollection($event: string[]) {
    this.assistant = { ...this.assistant, collectionIds: $event };
  }

  private isFullGeneralProperties(): boolean {
    const generalProperties = this.assistant?.properties.general;
    if (generalProperties?.deploymentContext && generalProperties?.identity) {
      return true;
    }
    return false;
  }

  onChangeProperties($event: Experiences.ExperienceProperties) {
    this.assistant = {
      ...this.assistant,
      properties: {
        ...(this.assistant.properties || {}),
        ...($event || {}),
      },
    };
    if (this.draftMode) {
      this.isEqualAssistant = !this.isFullGeneralProperties();
      this.updateFilters(0);
    }
  }

  addQuery() {
    const emptySourceView = this.assistantViewHelper.initEmptyFilterSourceView();
    this.filterSourceViews.push(emptySourceView);
    const newIndex = this.filterSourceViews.length - 1;
    this.updateFilters(newIndex);

    setTimeout(() => {
      this.scrollService.scrollToEnd();
    }, 100);
  }

  onRemoveLine(index: number) {
    this.eventsService.event('assistant.remove_app', { location: { title: this.hubService.currentLocation }, target: 'remove' });
    this.clearAllFilters(index, false);
    this.removeFilterSource(index);
    const firstFilterSource = this.getFilterSource(0);
    if (this.filterSourceViews.length === 1 && firstFilterSource?.tier === AssistantFilterPriority.HIGH) {
      firstFilterSource.tier = AssistantFilterPriority.SECOND;
      this.saveDataSource();
      this.cdr.markForCheck();
    }
  }

  addPriority(index: number) {
    const filterSources = this.getFilterSource(index);
    filterSources.tier = this.calcPriority(filterSources.tier);
    this.saveDataSource();
    this.cdr.markForCheck();
  }

  private calcPriority(current: number) {
    if (current === AssistantFilterPriority.SECOND || !current) {
      return AssistantFilterPriority.HIGH;
    }
    return AssistantFilterPriority.SECOND;
  }

  private removeFilterSource(index: number) {
    const dataSource = this.getFilterSourceViewModel(index);
    this.resetFilterSourceView(dataSource);
    this.filterSourceViews.splice(index, 1);
    this.checkAddQueryStatus();
    this.saveDataSource();
    if (this.filterSourceViews.length === 0) {
      const emptySourceView = this.assistantViewHelper.initEmptyFilterSourceView();
      this.filterSourceViews = [emptySourceView];
      this.updateFilters(0);
    }
  }

  private resetFilterSourceView(dataSource: FilterSourceViewModel) {
    dataSource?.filtersSub?.unsubscribe();
    dataSource?.searchSession?.destroy();
  }

  //Filters box
  filtersBoxValuesUpdated($event: ValuesUpdatedEvent, index: number) {
    // For type filter with only a single value:
    // The value is automatically selected unless the filter is read-only or disabled.
    const filterView = this.getFilterSourceViewModel(index);
    const filter = filterView.filterValues.find((f) => f.name === $event.name);
    if (!filter || filter.disabled || filter.readonly) {
      return;
    }
    const currentFilter = this.getFilterSourceFilters(index);
    const typeFilter = currentFilter[AssistantConst.TYPE_FILTER_NAME];
    const isType = $event.name === AssistantConst.TYPE_FILTER_NAME;
    const singleValue = $event.values.length === 1;

    if (!$event.filterInput && isType && !typeFilter && singleValue) {
      this.onFilterChange(
        {
          action: 'Set',
          changes: { values: $event.values },
          current: { values: $event.values },
          name: 'type',
          supportTag: false,
        },
        index
      );
    }
  }

  onFilterChange(data: FilterChangeData, index: number) {
    switch (data.name) {
      case AssistantConst.SPECIAL_FILTER_NAME: {
        this.handleSpecificResourceChange(index);
        break;
      }
      case AssistantConst.APP_FILTER_NAME: {
        this.handleAppFilterChange(data, index);
        break;
      }
      case AssistantConst.ACCOUNT_FILTER_NAME: {
        this.handleAccountFilterChange(data, index);
        break;
      }
      case AssistantConst.TYPE_FILTER_NAME: {
        this.handelTypeFilterChange(data, index);
        break;
      }
      default: {
        this.handelFilterChange(data, index);
        break;
      }
    }
  }

  private handleSpecificResourceChange(index: number) {
    this.assistantPopupService.openSpecificResourcePopup({ filters: this.getSearchFilters(index) }, (value) =>
      this.openSpecificResourcePopupSave(value, index)
    );
  }

  private handleAppFilterChange(changeData: FilterChangeData, index: number) {
    const sourceView = this.getFilterSourceViewModel(index);
    const currentFilters = this.getFilterSourceFilters(index) || {};
    const isRemovingFilter = ['Remove', 'ClearAll'].includes(changeData.action);
    const existSelectedValue = currentFilters[AssistantConst.APP_FILTER_NAME];
    if (isRemovingFilter || existSelectedValue) {
      //happen when we change the app filter and we want to reset all filters
      this.assistantViewHelper.resetLinkState(sourceView);
      this.resetResources(sourceView);
      this.clearAllFilters(index, isRemovingFilter);
    }
    if (isRemovingFilter) {
      return;
    }
    this.handelFilterChange(changeData, index);
  }

  private handleAccountFilterChange(changeData: FilterChangeData, index: number) {
    const sourceView = this.getFilterSourceViewModel(index);
    const currentFilters = this.getFilterSourceFilters(index) || {};
    //when changing account - the priority and resources are reset
    this.resetPriority(sourceView);
    this.resetResources(sourceView);
    if (Object.keys(currentFilters).length > this.LIMIT_FILTERS_COUNT[AssistantConst.ACCOUNT_FILTER_NAME]) {
      // if we remove account filter and we have filters from more filters
      Object.keys(currentFilters).forEach((key) => {
        if (key === AssistantConst.ACCOUNT_FILTER_NAME && changeData.action === 'Set') {
          currentFilters[key] = [changeData.changes.values?.[0]?.value];
        } else if (key !== AssistantConst.APP_FILTER_NAME) {
          delete currentFilters[key];
        }
      });
      this.assistantViewHelper.updateLinkState(sourceView);
      this.updateFilters(index);
      this.saveDataSource();
      return;
    }
    this.handelFilterChange(changeData, index);
    this.assistantViewHelper.updateLinkState(sourceView);
  }

  private handelTypeFilterChange(changeData: FilterChangeData, index: number) {
    const sourceView = this.getFilterSourceViewModel(index);
    const currentFilters = this.getFilterSourceFilters(index) || {};
    // if we remove type filter and we have filters from more filters or specific resources
    this.resetResources(sourceView);
    if (Object.keys(currentFilters).length > this.LIMIT_FILTERS_COUNT[AssistantConst.TYPE_FILTER_NAME]) {
      Object.keys(currentFilters).forEach((key) => {
        if (key === AssistantConst.TYPE_FILTER_NAME && changeData.action === 'Set') {
          currentFilters[key] = [changeData.changes.values?.[0]?.value];
        } else if (key !== AssistantConst.APP_FILTER_NAME && key !== AssistantConst.ACCOUNT_FILTER_NAME) {
          delete currentFilters[key];
        }
      });
      this.updateFilters(index);
      this.saveDataSource();
      return;
    }
    this.handelFilterChange(changeData, index);
  }

  private handelFilterChange(changeData: FilterChangeData, index: number) {
    this.onUpdateFilterBox(
      {
        name: changeData.name,
        operation: changeData.action.toLowerCase() as ResultsFilterEventOperation,
        value: changeData.changes.values?.[0]?.value,
      },
      index
    );
  }

  clearAllFilters(index: number, isSave = true) {
    this.filtersService.removeAllFilters('all', false);
    this.onUpdateFilterBox({ name: null, operation: 'clearParams', value: null }, index, isSave);
  }

  onUpdateFilterBox(event: ResultsFilterEvent, index: number, isSave = true) {
    const currentDataSourceFilters = this.getFilterSourceFilters(index);
    const updatedFilters = this.handleFilterAction(cloneDeep(currentDataSourceFilters), event);
    if (isEqual(currentDataSourceFilters, updatedFilters)) {
      return;
    }
    const keyOverridden = new Set<string>();
    for (const [key, value] of Object.entries(updatedFilters)) {
      currentDataSourceFilters[key] = value;
      keyOverridden.add(key);
    }
    for (const key of Object.keys(currentDataSourceFilters || {})) {
      if (!keyOverridden.has(key)) {
        delete currentDataSourceFilters[key];
      }
    }
    this.cdr.markForCheck();
    this.updateFilters(index);
    if (isSave) {
      this.saveDataSource();
    }
  }

  onClickFilterBox($event: number, index: number) {
    const filtersSourceView = this.getFilterSourceViewModel(index);
    const filterRow = filtersSourceView?.filterValues;
    const filter = filterRow[$event];
    if (filter.name === AssistantConst.SPECIAL_FILTER_NAME && filter.title === AssistantConst.SPECIAL_FILTER_TITLE) {
      this.assistantPopupService.openSpecificResourcePopup(
        { filters: this.getSearchFilters(index), selected: filter.values.map((v) => v.id) },
        (value) => this.openSpecificResourcePopupSave(value, index)
      );
    }
  }

  private handleFilterAction(filters: Filters.Values, event: ResultsFilterEvent): Filters.Values {
    switch (event.operation) {
      case 'add':
        if (filters[event.name]) {
          filters[event.name].push(<string>event.value);
        } else {
          filters[event.name] = [<string>event.value];
        }
        break;
      case 'remove':
        if (filters[event.name]?.length === 1 || !event.value) {
          delete filters[event.name];
        } else {
          filters[event.name] = filters[event.name].filter((elm) => elm !== <string>event.value);
        }
        if (isEmpty(filters)) {
          filters = {};
        }
        break;
      case 'set':
        filters[event.name] = [<string>event.value];
        break;
      case 'clearall':
        delete filters[event.name];
        break;
      case 'clearParams':
        filters = {};
        break;
    }
    return filters;
  }

  private saveDataSource() {
    const filtersDataSources = this.hasOneEmptySource()
      ? []
      : this.filterSourceViews.map((s) => s.filterSource).filter((d) => !isEmpty(d.filters));
    this.assistant = { ...this.assistant, filtersDataSources };
  }

  private checkAddQueryStatus() {
    const hasOneEmptySource = this.hasOneEmptySource();
    this.dataSourceExist =
      this.filterSourceViews.some((source) => !isEmpty(source.filterSource.filters)) ||
      hasOneEmptySource ||
      (this.assistant.experienceType === 'slack' && !isEmpty(this.channelDataSource));
    this.showAddQuery = this.filterSourceViews.length > 0 && !this.assistant.readOnly;
    this.allowAddQuery = !this.filterSourceViews.some((filter) => isEmpty(filter.filterSource.filters));
  }

  // specific resources
  private openSpecificResourcePopupSave(value: ResultItem[], index: number) {
    this.updateSearchResource(value, index);
    this.updateFilters(index);
    this.saveDataSource();
  }

  private updateSearchResource(value: ResultItem[], index: number) {
    const filterSource = this.getFilterSource(index);
    filterSource.resources = value?.map((item) => ({ id: item.id, title: item.view?.title.text })) || [];
    this.cdr.markForCheck();
  }

  //channel filters
  private async updateChannelDataSource() {
    this.checkAddQueryStatus();
    const staticFilterNames = ['app', 'topic'];
    let dynamicFilters = ['timeAssistant'];
    if (this.enableAssistantChannelFiltersCreatedBy) {
      dynamicFilters.push('createdBy');
    }
    if (this.assistant.readOnly) {
      dynamicFilters = dynamicFilters.filter((f) => this.channelDataSource.filters[f]);
    }
    const filterNames = [...staticFilterNames, ...dynamicFilters];
    this.channelFiltersSub?.unsubscribe();
    this.channelFiltersSub = this.filtersService
      .getFloatingResultsFilters({
        sessionName: `channel-assistant-${this.assistant.id}`,
        typeSuggestedFilters: [],
        baseFilters: filterNames.map((n) => ({ name: n })),
        selectedFilters: this.channelDataSource.filters,
        suggestionProvider: (term, group, ctx, options, activeFilters, sessionName) =>
          this.suggestionProvider(term, group, ctx, options, activeFilters, sessionName),
        moreFilterValuesViewDetails: { appendTo: 'body', showItemIconLabel: true },
        timeFilterSettings: {
          pickerTypes: ['before', 'after', 'range'],
          maxDate: new Date(),
        },
        optionsSuggestionProvider: {
          filtersToSearchOnlySelected: staticFilterNames,
          //HACK: use assistantID to get filter results from slack link (external link)
          searchAssistantId: this.assistant.id,
        },
        allowSingleItem: true,
        defaultViewDetails: {
          appendTo: 'body',
        },
      })
      .subscribe((filters) => {
        for (const f of filters) {
          f.disabled = this.channelDataSource.disabled;
          const constantFilter = staticFilterNames.includes(f.name) || this.assistant.readOnly;
          if (constantFilter) {
            f.picker = 'constant';
          }
        }
        this.channelFilters = filters;
        this.cdr.markForCheck();
      });
  }

  onChannelFilterChange(data: FilterChangeData) {
    const event: ResultsFilterEvent = {
      name: data.name,
      operation: data.action.toLowerCase() as ResultsFilterEventOperation,
      value: data.changes.values?.[0]?.value,
    };
    this.channelDataSource.filters = this.handleFilterAction(this.channelDataSource.filters, event);
    this.saveChannelDataSource();
    this.updateChannelDataSource();
  }

  changeChannelToggle() {
    this.channelDataSource.disabled = !this.channelDataSource.disabled;
    this.saveChannelDataSource();
    this.updateChannelDataSource();
  }

  private saveChannelDataSource() {
    this.assistant = { ...this.assistant, channelDataSource: this.channelDataSource };
    this.cdr.markForCheck();
  }
  onSelectedEmoji(emoji: EmojiData) {
    const unicode = emoji?.unified;
    this.assistant = {
      ...this.assistant,
      properties: {
        ...(this.assistant.properties || {}),
        emoji: unicode,
      },
    };
    this.cdr.markForCheck();
  }
  onChangeName(value: string) {
    this.assistant = {
      ...this.assistant,
      properties: {
        ...(this.assistant.properties || {}),
        title: value,
      },
    };
    this.cdr.markForCheck();
  }
}
