import { Collections, MemorySearch } from '@local/client-contracts';
import { isCollectionItem, isFileItem, isHeaderItem, isLinkResourceItem, isStaticCollection, isUrlItem } from '@local/common-web';
import { LogService } from '@shared/services';
import { MemorySearchService } from '@shared/services/memory-search.service';
import moment from 'moment';
import { Observable, ReplaySubject, Subscription, firstValueFrom } from 'rxjs';
import { SearchResults, TelemetryTrigger, isCollectionStaticItem } from 'src/app/bar/views';
import { StaticCollectionItemSourceSettings } from '.';
import { AvatarListService } from '../../../avatar-list.service';
import { BlobsService } from '../../../blob.service';
import { CollectionsUtilService } from '../../../collections-util.service';
import { CollectionsService } from '../../../collections.service';
import { FavoritesService } from '../../../favorites.service';
import { ResultsService } from '../../../results.service';
import { UrlResolverService } from '../../../url-resolver.service';
import { WorkspacesService } from '../../../workspaces.service';
import { MemorySearchClient } from '../memory-search-client/memory-search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { StaticCollectionItemBuilder } from './static-collection-item-builder';

export class StaticCollectionItemSearchClient extends MemorySearchClient<StaticCollectionItemSourceSettings> {
  private staticCollectionItemBuildResultView: StaticCollectionItemBuilder;
  private allCollectionsMap: { [id: string]: Collections.Collection } = {};
  private instances: { [sessionName: string]: { sub?: Subscription; refreshRunning?: boolean } } = {};
  private finished: boolean;

  constructor(
    logService: LogService,
    private collectionsService: CollectionsService,
    private collectionsHelperService: CollectionsUtilService,
    private blobsService: BlobsService,
    memorySearchService: MemorySearchService,
    private resultsService: ResultsService,
    private favoritesService: FavoritesService,
    private workspaceService: WorkspacesService,
    private avatarListService: AvatarListService,
    private urlResolverService: UrlResolverService
  ) {
    super(logService, memorySearchService, [], ['favorite']);
    this.staticCollectionItemBuildResultView = new StaticCollectionItemBuilder(
      this.collectionsService,
      this.collectionsHelperService,
      this.blobsService,
      this.workspaceService,
      this.avatarListService,
      this.urlResolverService
    );
  }

  nextPage(request: SearchRequest<StaticCollectionItemSourceSettings>, response: SearchResponse, trigger: TelemetryTrigger): Promise<void> {
    return;
  }

  destroy(id: number, sessionName: string): void {
    this.instances[sessionName]?.sub?.unsubscribe();
    this.instances[sessionName] = null;
    this.finished = null;
    return;
  }

  protected async filter(items: MemorySearch.Item[], settings: StaticCollectionItemSourceSettings): Promise<any[]> {
    const filters = settings.filters.postFilters;
    const newItems = [];
    for (const item of items) {
      const collectionItem = item.data;
      const favorite: any[] = filters['favorite'];
      const isFavorite = !!collectionItem?.result?.favoriteMarkedTime;
      const hasFavorite = !(favorite?.length && !isFavorite);
      if (hasFavorite) {
        newItems.push(item);
      }
    }
    return newItems;
  }

  protected async rank(
    queryTokens: string[],
    items: MemorySearch.Item[],
    settings: StaticCollectionItemSourceSettings
  ): Promise<MemorySearch.Item[]> {
    const groups: MemorySearch.Item[][] = [];
    for (const item of items) {
      const groupIndex = item.data.groupIndex;
      if (!groups[groupIndex]) {
        groups[groupIndex] = [];
      }
      item.data = item.data.result;
      groups[groupIndex].push(item);
    }
    const rankedGroups: MemorySearch.Item[][] = [];
    for (const group of groups.filter((g) => !!g)) {
      const rankedGroup = await super.rank(queryTokens, group, settings);
      rankedGroups.push(rankedGroup);
    }
    const resultGroups = [];
    const groupCandidates = rankedGroups.map((g) => (g.length === 1 ? g[0] : g.find((i) => !isHeaderItem(i.data))));
    const candidatesMap = Object.fromEntries(groupCandidates.map((x, index) => [x.data.id, { item: x, index }]));
    const rankedCandidates = await super.rank(queryTokens, groupCandidates, settings);
    for (const candidate of rankedCandidates) {
      const groupIndex = candidatesMap[candidate.data.id].index;
      const group = rankedGroups[groupIndex];
      const headerIndex = group.findIndex((i) => isHeaderItem(i.data));
      if (headerIndex > 0) {
        const header = group.splice(headerIndex, 1)[0];
        group.unshift(header);
      }
      resultGroups.push(group);
    }
    return resultGroups.flat().sort((a, b) => a.index - b.index);
  }

  getInput(request: SearchRequest<StaticCollectionItemSourceSettings>, response: SearchResponse): Observable<MemorySearch.Item[]> {
    const sessionName = request.sessionName;
    const subscription = this.instances[sessionName]?.sub;
    if (subscription) {
      subscription.unsubscribe();
      this.instances[sessionName].sub = null;
    }
    this.finished = false;
    const res = [];
    this.instances[sessionName] = {};
    this.instances[sessionName].sub = this.favoritesService.getByType$('collection-item').subscribe((favorites: any[]) => {
      if (this.finished && res.length !== favorites?.length) {
        const instance = this.instances[sessionName];
        if (instance && !instance.refreshRunning) {
          this.instances[sessionName].refreshRunning = true;
          this.refresh(request, response);
        }
      }
    });
    const subject = new ReplaySubject<MemorySearch.Item[]>();
    this._innerSearch(request, subject, response, sessionName, res);
    return subject;
  }

  private async _innerSearch(
    request: SearchRequest<StaticCollectionItemSourceSettings>,
    subject: ReplaySubject<MemorySearch.Item[]>,
    response: SearchResponse,
    sessionName: string,
    res: string[]
  ) {
    const favorites = await firstValueFrom(this.favoritesService.getByType$('collection-item'));
    res = favorites?.map((f) => f.id) || [];
    let collections = (await firstValueFrom(this.collectionsService.all$)) || [];
    collections.forEach((c) => (this.allCollectionsMap[c.id] = c));
    if (response.cancelled) return;
    const settings = request.sourceSettings;
    if (settings.collection) {
      collections = [];
      collections.push(settings.collection);
    } else if (settings.collectionId) {
      collections = [collections.find((c) => c.id === settings.collectionId)];
    }

    const results: Collections.StaticItem[] = [];
    for (const collection of collections) {
      if (!isStaticCollection(collection) || !collection.items?.length) {
        continue;
      }
      results.push(...this.mapCollectionItems(collection, this.allCollectionsMap, res));
    }
    const updated = await this.checkForFileItem(results, subject, response);
    if (response.cancelled) {
      return;
    }
    this.finished = true;
    if (this.instances[sessionName]) {
      this.instances[sessionName].refreshRunning = false;
    }

    response.extra = { totalResults: results?.filter((i) => !isHeaderItem(i))?.length || 0 };
    if (!updated) {
      subject.next(this.toMemorySearchResults(results, this.allCollectionsMap));
    }
  }

  async checkForFileItem(results: Collections.StaticItem[], subject: ReplaySubject<MemorySearch.Item[]>, response: SearchResponse) {
    const fileItems: Collections.FileItem[] = results?.map((i) => (isFileItem(i) ? i : null)).filter((item) => !!item);
    if (fileItems.length > 0) {
      const req = fileItems.map((f) => ({ id: f.fileId, lastUpdateTime: f.uploadTime }));
      const metas = await this.blobsService.getBlobMetadataByIds(req);
      const resultMap: { id: { data: Collections.StaticItem; index: number } } = Object.fromEntries(
        results.map((x, i) => (x['fileId'] ? [x['fileId'], { data: x, index: i }] : [x['id'], { data: x, index: i }]))
      );

      if (response.cancelled) return;
      for (const meta of metas.entries) {
        if (!meta) {
          continue;
        }
        const data = { ...resultMap[meta.id].data, meta };
        resultMap[meta.id] = { data, index: resultMap[meta.id].index };
      }

      const sortedResult = Object.values(resultMap)
        .sort((a, b) => a.index - b.index)
        .map((x) => x.data);

      subject.next(this.toMemorySearchResults(sortedResult, this.allCollectionsMap));
      return true;
    }
  }

  mapCollectionItems(
    collection: Collections.StaticCollection,
    allCollectionsMap: { [key in string]: Collections.Collection },
    res: string[]
  ) {
    const favoriteIdsMap = {};
    res.forEach((id) => (favoriteIdsMap[id] = true));
    return collection.items
      .map((item) => {
        if (item?.kind === 'collection' && !allCollectionsMap[item.id]) return;
        const isFavorite = favoriteIdsMap[item?.id];
        return { ...item, type: 'collection-static-item', collectionId: collection.id, favoriteMarkedTime: isFavorite };
      })
      .filter((item) => item && (!isCollectionItem(item) || this.allCollectionsMap[item.id]));
  }

  private toMemorySearchResults(
    items: Collections.StaticItem[],
    allCollectionsMap: { [key in string]: Collections.Collection }
  ): MemorySearch.Item[] {
    const results: MemorySearch.Item[] = [];
    let currentHeaderText = '';
    let groupIndex = 0;
    for (const item of items) {
      let searchText: string = '';
      const isHeader = isHeaderItem(item);
      let newGroupIndex = groupIndex;
      if (isHeader) {
        searchText = currentHeaderText = (item as Collections.HeaderItem).text;
        newGroupIndex++;
      } else if (isLinkResourceItem(item)) {
        const resource = item.searchResource;
        const textTitle = resource?.view?.title?.text || '';
        const textSubtitle = resource?.view?.subtitle?.text || '';
        searchText = `${textTitle} ${textSubtitle}`;
      } else if (item.kind === 'collection') {
        const collection = allCollectionsMap[item.id];
        if (collection) {
          searchText = collection.title;
        }
      } else if (isUrlItem(item)) {
        const textTitle = item.title || '';
        searchText = `${item.url} ${textTitle} `;
      } else if (isFileItem(item)) {
        searchText = (item as Collections.FileItem).name;
      }
      if (!searchText) {
        this.logger.error('static collection item kind not supported', { kind: item.kind });
      }
      groupIndex = newGroupIndex;
      results.push({
        data: { result: item, groupIndex },
        searchText: searchText + (isHeader ? '' : ` ${currentHeaderText}`),
        sortValue: null,
      } as MemorySearch.Item);
    }
    return results;
  }

  async getOutput(items: MemorySearch.Item[], sourceSettings?: StaticCollectionItemSourceSettings): Promise<SearchResults[]> {
    const newItems: SearchResults[] = [];
    const collection = !!sourceSettings.collectionId ? sourceSettings.collection : this.mapCollectionItems[sourceSettings.collectionId];

    if (sourceSettings.onlyCount) {
      return newItems;
    }
    for (const item of items) {
      const data = item.data;
      if (isCollectionStaticItem(data)) {
        const addedTime = this.getAddedTimeFormatting(data.addedTime, data.showAddedTime);
        if (isLinkResourceItem(data)) {
          const action = await this.resultsService.getResultAction({ ...data.searchResource, type: 'result' });
          const showResultSections = {
            showNewIndication: addedTime,
          };
          const resource = await this.staticCollectionItemBuildResultView.buildItemResource(
            data,
            sourceSettings.collectionId || item.data.collectionId
          );
          if (sourceSettings.convertItem && sourceSettings.isFavoriteItem) {
            newItems.push({
              ...resource.searchResource,
              showResultSections,
              favoriteMarkedTime: resource.favoriteMarkedTime,
              action,
            });
          } else {
            newItems.push({
              ...resource.searchResource,
              showResultSections,
              action,
            });
          }
        } else if (data.kind === 'collection') {
          const collectionItem = await this.staticCollectionItemBuildResultView.updateCollectionItem(data, addedTime);
          newItems.push({ ...collectionItem, type: 'collection' });
        } else {
          let newItem;
          if (isUrlItem(data)) {
            newItem = await this.staticCollectionItemBuildResultView.buildUrlItemView(
              data,
              this.allCollectionsMap[data.collectionId] || collection,
              addedTime
            );
          } else if (isHeaderItem(data)) {
            newItem = await this.staticCollectionItemBuildResultView.buildHeaderView(data);
          } else if (isFileItem(data)) {
            newItem = await this.staticCollectionItemBuildResultView.buildFileView(
              <Collections.FileItem>data,
              this.allCollectionsMap[data.collectionId] || collection,
              addedTime,
              null,
              sourceSettings.stateView
            );
          }
          newItem = { ...newItem, favoriteMarkedTime: item.data.favoriteMarkedTime, isFavorite: item.data.favoriteMarkedTime };
          newItems.push(newItem);
        }
      }
    }
    return newItems;
  }

  private getAddedTimeFormatting(resourceAddedTime: number, showAddedTime: boolean) {
    if (!resourceAddedTime || showAddedTime === false) {
      return null;
    }
    const currentDate = Date.now();
    const duration = moment(currentDate).diff(resourceAddedTime, 'days');
    if (duration > 2) {
      return null;
    }
    return `Added ${moment(resourceAddedTime).local().fromNow()}`;
  }
}
