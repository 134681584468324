import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, HostBinding, Inject, Input, OnDestroy, Renderer2 } from '@angular/core';
import { SafeValue } from '@angular/platform-browser';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IStyleService, STYLE_SERVICE } from '../injectionToken/style.service.interface';
import { SafePipe } from '../pipes/safe.pipe';
import { Icon } from '../types/icon';
import { Scheme } from '../types/scheme';
import { isIconEmoji } from '../utils/emoji';

@Directive({
  selector: '[icon]',
})
export class IconDirective implements AfterViewInit, OnDestroy {
  private _src: SafeValue;
  private _icon: Icon;
  private readonly destroy$ = new Subject();
  private emojiEl: Element;
  @Input() reverseTheme = false;

  constructor(
    private hostRef: ElementRef,
    private safePipe: SafePipe,
    @Inject(STYLE_SERVICE) private styleService: IStyleService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {}

  @Input() set icon(value: Icon) {
    let dirty = false;
    if (this._icon?.lightUrl !== value?.lightUrl) {
      dirty = true;
    }
    this._icon = value;
    if (dirty) {
      firstValueFrom(this.styleService.theme$).then((x: any) => this.setSrc(x));
    }
  }

  /** Use 'style' for `background-image`
   * Use 'url' for src
   */
  @Input('iconCtx') ctx?: 'url' | 'style' = 'url';

  @HostBinding('attr.src') get src() {
    if (this.ctx === 'url') return this._src || '';
  }

  @HostBinding('style.background-image') get bg() {
    if (this.ctx === 'style') return this._src || '';
  }

  @HostBinding('style.background-color') get bgColor() {
    if (this.ctx === 'style') return this._icon?.backgroundColor;
  }

  ngOnInit() {
    this.styleService.theme$.pipe(takeUntil(this.destroy$)).subscribe((s: any) => {
      this.setSrc(s as any);
      this.cdr.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    if (!this.hostRef || !this._icon) return;
    const { nativeElement: el } = this.hostRef;

    if (this._icon['rounded'] !== undefined) {
      this._icon['rounded'] ? el.classList.add('rounded') : el.classList.remove('rounded');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private setSrc(scheme: Scheme) {
    if (!this._icon) return;

    if (typeof this._icon === 'string') {
      if (isIconEmoji(this._icon)) this.addEmojiIconEl(this._icon);
      this._src = this.safePipe.transform(this._icon, this.ctx);
    }

    const { lightUrl, darkUrl } = this.getUrls();

    const url = scheme === 'dark' && darkUrl ? darkUrl : lightUrl;

    if (isIconEmoji(url)) {
      this.addEmojiIconEl(url);
    } else if (this.emojiEl) {
      this.emojiEl.remove();
    }

    this._src = this.safePipe.transform(url, this.ctx);
    this.cdr.markForCheck();
  }

  private getUrls() {
    let { lightUrl, darkUrl } = this._icon;
    if (this.reverseTheme) {
      const temp = lightUrl;
      lightUrl = darkUrl || temp;
      darkUrl = temp;
    }
    return { lightUrl, darkUrl };
  }

  private addEmojiIconEl(text: string) {
    if (!this.hostRef) return;
    if (this.emojiEl) {
      this.emojiEl.remove();
      this.emojiEl = null;
    }
    const el: HTMLElement = this.hostRef.nativeElement;
    this.emojiEl = this.renderer.createElement('span');
    this.renderer.addClass(this.emojiEl, 'emoji-icon');
    this.renderer.appendChild(this.emojiEl, this.renderer.createText(text));
    this.renderer.insertBefore(el, this.emojiEl, el.childNodes[0]);
  }
}
