export const isTruncated = (el: HTMLElement): boolean => el.scrollWidth > el.clientWidth;

export const hasChildWithSelector = (parent: Element, selector: string) => {
  for (const child of parent.children) {
    if (child.webkitMatchesSelector(selector)) return true;
    else return hasChildWithSelector(child, selector);
  }
  return false;
};

export const findChild = (parent: Element, selector: string) => {
  for (const child of parent.children) {
    if (child.webkitMatchesSelector(selector)) return child;
    else return findChild(child, selector);
  }
};
export const findChildren = (parent: Element, selector: string, children: Array<any> = []): HTMLElement[] => {
  for (const child of parent.children) {
    if (child.webkitMatchesSelector(selector)) {
      children.push(child);
    } else {
      children.concat(findChildren(child, selector, children));
    }
  }
  return children;
};

export const findAncestor = (el: Element, selector: string) => {
  if (el.webkitMatchesSelector(selector)) return el;
  else return findAncestor(el.parentElement, selector);
};

export const countLines = (el: HTMLElement): number => {
  const divHeight = el.offsetHeight;
  const lineHeight = (getComputedStyle(el, null).lineHeight === 'normal' ? getLineHeight(el) : +getComputedStyle(el, null).lineHeight) || 1;
  const lines = Math.ceil(divHeight / lineHeight) || 1;

  return lines;
};

export const getLineHeight = (el: HTMLElement) => {
  const temp = document.createElement(el.nodeName);
  let height;
  temp.setAttribute(
    'style',
    'margin:0; padding:0; ' + 'font-family:' + (el.style.fontFamily || 'inherit') + '; ' + 'font-size:' + (el.style.fontSize || 'inherit')
  );
  temp.innerHTML = 'A';

  el.parentNode.appendChild(temp);
  height = temp.clientHeight;
  temp.parentNode.removeChild(temp);

  return height;
};

export const isElement = (el: any): el is Element => !!el?.nodeName;

/** Returns the intersection values for the element comparing to the view. If the element is fully container in the view value will be 0 */
export const getIntersection = (el: HTMLElement, container: HTMLElement): { top: number; left: number; right: number; bottom: number } => {
  const rect = el.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  const calculate = (value: number, containerValue: number): number => {
    const v = value - containerValue;
    return v < 0 ? Math.ceil(Math.abs(v)) : 0;
  };
  return {
    top: calculate(rect.top, containerRect.top),
    left: calculate(rect.left, containerRect.left),
    right: calculate(containerRect.right, rect.right),
    bottom: calculate(containerRect.bottom, rect.bottom),
  };
};

export const stopEvent = (event) => {
  event.stopImmediatePropagation();
  event.stopPropagation();
  event.preventDefault();
};
