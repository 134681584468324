import { Injectable } from '@angular/core';
import { PopupRef, PopupService } from '@local/ui-infra';
import { ContextMenuService } from '@shared/components';
import { Subject, filter, take } from 'rxjs';
import { PreviewService, ResultPopupData } from 'src/app/bar/services/preview.service';
import { SortService } from 'src/app/bar/services/sort.service';
import { WikiCardPopupComponent } from '../components/wiki-card-popup/wiki-card-popup.component';
import { WikiPopupsService } from './wiki-popups.service';
import { Search } from '@local/client-contracts';
import { WikiCardPopupModel } from '../models/wiki-card-model';

@Injectable({
  providedIn: 'root',
})
export class WikiCardPreviewService {
  private readonly previewType = 'wiki card';

  popupRef: PopupRef<WikiCardPopupComponent, WikiCardPopupModel>;
  wikiCardPopupStatus: Subject<boolean> = new Subject();

  constructor(
    private popupService: PopupService,
    private previewService: PreviewService,
    private contextMenuService: ContextMenuService,
    private sortService: SortService,
    private wikiPopupsService: WikiPopupsService
  ) {
    this.previewService.clearPreviewPopup$.pipe(filter((previewType) => previewType === this.previewType)).subscribe(() => {
      if (this.popupRef) {
        this.popupRef.destroy();
        this.popupRef = null;
      }
    });
  }

  openWikiCardPopup(data?: WikiCardPopupModel, popupData?: ResultPopupData) {
    const initData = { ...data };

    if (this.popupRef) {
      this.popupRef.update(initData);
      return;
    }

    this.popupRef = this.popupService.open<WikiCardPopupComponent, WikiCardPopupModel>('center', WikiCardPopupComponent, initData, {
      closeOnClickOut: true,
      backdropStyle: 'blur-2',
      panelClass: 'wiki-card-popup',
    });

    this.wikiCardPopupStatus.next(true);
    this.popupRef.outsidePointerEvents$.pipe().subscribe((event) => {
      if (this.popupRef.compInstance.isExternalMenuOpen(event)) return;
      if (this.wikiPopupsService.warningRequestsPopupRef) return;
      if (Object.values(this.popupService.refs)?.length > 1) return;
      if (this.contextMenuService.currentOpenRefs() > 0) {
        this.contextMenuService.destroyAllRefs();
      } else if (this.sortService.popupSortRef) {
        this.sortService.destroyPopUpSort();
      } else if (this.wikiPopupsService.verificationStatusPopupRef) {
        this.wikiPopupsService.verificationStatusPopupRef.destroy();
      } else if (this.wikiPopupsService.verificationDetailsPopupRef) {
        return;
      } else {
        event.stopPropagation();
        this.popupRef.close();
      }
    });

    this.popupRef.close$.pipe(take(1)).subscribe(() => {
      this.wikiCardPopupStatus.next(false);
      this.previewService.onDestroyPreview(
        popupData?.item as Search.ResultResourceItem,
        this.previewType,
        popupData?.preventClearQueryParams
      );
      if (popupData) {
        this.previewService.setPreviewState('previous', popupData.index, popupData.item);
      }
      this.popupRef.destroy();
      this.popupRef = null;
    });
    return this.popupRef;
  }
}
