export const COLLECTION_COLUMNS_COUNTER: { maxWidth: number; maxColumns?: number; minColumns: number }[] = [
  {
    maxWidth: 400,
    maxColumns: 1,
    minColumns: 1,
  },
  {
    maxWidth: 480,
    maxColumns: 2,
    minColumns: 1,
  },
  {
    maxWidth: 620,
    maxColumns: 2,
    minColumns: 2,
  },
  {
    maxWidth: 730,
    maxColumns: 3,
    minColumns: 2,
  },
  {
    maxWidth: 810,
    maxColumns: 3,
    minColumns: 3,
  },
  {
    maxWidth: 990,
    maxColumns: 4,
    minColumns: 3,
  },
  {
    maxWidth: 1200,
    maxColumns: 5,
    minColumns: 4,
  },
  {
    maxWidth: 1000000,
    maxColumns: 6,
    minColumns: 4,
  },
];
