import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Constants } from '@local/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccountsService } from '@shared/services/accounts.service';
import { SessionService } from '@shared/services/session.service';
import { WorkspacesService } from 'src/app/bar/services';
import { PopupRef } from '@local/ui-infra';
import { filter } from 'rxjs';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { KeyName } from '@local/ts-infra';

@UntilDestroy()
@Component({
  templateUrl: './trial-expired-popup.component.html',
  styleUrls: ['./trial-expired-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialExpiredComponent implements OnInit, OnDestroy {
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    event.stopPropagation();
  }

  @Output() selectPlanClicked = new EventEmitter<void>();

  private keyHandlerId: string;

  ownerEmail: string;
  isOwner: boolean;

  get contactUsURL() {
    return Constants.CONTACT_US;
  }

  constructor(
    private workspaceService: WorkspacesService,
    private cdr: ChangeDetectorRef,
    private accountsService: AccountsService,
    private sessionService: SessionService,
    private keyboardService: KeyboardService,
    private ref: PopupRef<TrialExpiredComponent, any>
  ) {}

  ngOnInit(): void {
    this.workspaceService.current$
      .pipe(
        untilDestroyed(this),
        filter((workspace) => !!workspace)
      )
      .subscribe((workspace) => {
        this.isOwner = workspace.isOwner;
        this.cdr.markForCheck();
      });
    this.accountsService.all$.pipe(untilDestroyed(this)).subscribe((accounts) => {
      this.ownerEmail = accounts?.find((a) => a.isOwner)?.email;
      this.cdr.markForCheck();
    });
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 100);
  }

  ngOnDestroy(): void {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
      this.keyHandlerId = null;
    }
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    event.stopPropagation();
  }

  logOut() {
    this.sessionService.signOut();
    this.ref?.destroy();
  }
}
