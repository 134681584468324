import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SplitButtonModule } from 'primeng/splitbutton';
import { USplitButtonComponent } from './u-split-button.component';

@NgModule({
  declarations: [USplitButtonComponent],
  imports: [CommonModule, SplitButtonModule],
  exports: [USplitButtonComponent],
})
export class USplitButtonModule {}
