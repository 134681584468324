import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { InputText } from 'primeng/inputtext';
import { UiIconModel } from '../../types/ui.icon.model';

@Component({
  selector: 'u-input-group',
  templateUrl: './u-input-group.component.html',
  styleUrls: ['./u-input-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UInputGroupComponent implements OnInit {
  @Input() isSearchInput = false;
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() inputSize = 'medium';
  @Input() maxLength = 100;
  @Input() model = '';
  @Input() styles: any = {};
  @Input() symbol: UiIconModel | string = '';
  @Input() removeText = false;
  @Input() focus = false;

  @ViewChild(InputText) inputElement: InputText;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFocus: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();

  icon: UiIconModel;
  text: string;
  heightInput = '40px';
  hover = false;

  ngOnInit(): void {
    this.initView();
  }

  initView() {
    Object.keys(this.styles).forEach((key) => {
      if (key === 'height') {
        this.heightInput = this.styles['height'];
      }
    });
    if (typeof this.symbol === 'string') this.text = this.symbol;
    else {
      this.icon = this.symbol;
    }
  }

  @HostListener('focusin')
  focusin() {
    if (!this.disabled) this.focus = true;
  }

  @HostListener('focusout')
  focusout() {
    this.focus = false;
  }

  onChangeEvent($event) {
    this.onChange.emit($event);
  }

  onFocusEvent($event) {
    this.onFocus.emit($event);
  }

  onBlurEvent($event) {
    this.onBlur.emit($event);
  }
}
