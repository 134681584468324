<div @fade class="card-preview-container-wrapper">
  <wiki-card-popup
    *ngIf="size === 'side'"
    class="card-preview-container-content"
    [model]="cardModel"
    [size]="size"
    [containerSize]="{ width: previewWidth, height: previewHeight }"
    style="width: 100%"
    [ngStyle]="previewWidth ? { 'max-width': previewWidth - 1 + 'px ' } : {}"
    [previewWidthBreakpoint]="previewWidthBreakpoint"
  >
  </wiki-card-popup>
</div>
