import { ErrorHandler, Injectable } from '@angular/core';
import { LogService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  private logger: Logger;
  private sentryError: any;

  private _error$: BehaviorSubject<Error> = new BehaviorSubject<Error>(null);

  get error$() {
    return this._error$.asObservable();
  }

  get error() {
    return this._error$.getValue();
  }

  set error(error: Error | string) {
    if (this._error$.getValue() == error) return;
    if (typeof error === 'string') {
      error = new Error(error);
    }
    this._error$.next(error);
  }

  constructor(logger: LogService) {
    this.logger = logger.scope('GlobalErrorHandler');
  }

  clear() {
    this.error = null;
  }

  handleError(error: Error) {
    // handle pdf preview manual destroy
    if (error.stack?.includes('ensureNotTerminated')) {
      return;
    }

    this.logger.error('Unhandled Global Error', { message: error?.message, stack: error?.stack });
    this._error$.next(error);
    if (this.sentryError) {
      this.sentryError.handleError(error);
    }
  }
}
