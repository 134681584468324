import { ComponentType } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Search } from '@local/client-contracts';
import { ManualPromise } from '@local/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventInfo, EventsService } from '@shared/services';
import { Subscription } from 'rxjs';
import { FavoritesService } from 'src/app/bar/services/favorites.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { convertResultTypeToFavoriteType } from '../../../results';
import { PreviewItem } from '../../../results-views/results/results.component';
import { InvokeCommand } from '../../../results/models/invoke-command.model';
import { PreviewType } from '../../../results/models/view-filters';
import { CardPreviewComponent } from '../../card-preview/components/card-preview/card-preview.component';
import { FilePreviewContainerComponent } from '../../file-preview/file-preview-container/file-preview-container.component';
import { FilePreview } from '../../file-preview/file-preview-types';
import { MailPreviewContainerComponent } from '../../mail-preview/components/mail-preview-container/mail-preview-container.component';
import { DirtyChangeStatus, PreviewComponent } from '../../model/preview-component';
import { PreviewMode } from '../../model/preview-mode';
import { PeoplePreviewContainerComponent } from '../../people-preview/components/people-preview-container/people-preview-container.component';
import { PreviewNotAvailableComponent } from '../preview-not-available/preview-not-available.component';
import { PreviewViewModel } from 'src/app/bar/services/preview.service';
import { isEqual } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'preview',
  templateUrl: './preview-container.component.html',
  styleUrls: ['./preview-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewContainerComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('resultPreviewContainer', { read: ViewContainerRef }) container: ViewContainerRef;
  @Output() sizeChanged: EventEmitter<PreviewMode> = new EventEmitter<PreviewMode>();
  @Output() invoke = new EventEmitter<InvokeCommand>();
  @Output() invokeDirty = new EventEmitter<DirtyChangeStatus>();

  private _index: number;
  public get index(): number {
    return this._index;
  }
  @Input() telemetrySearch: Partial<EventInfo>;

  @Input() public set index(value: number) {
    if (this.isDirty()) return;
    this._index = value;
    if (this.componentRef) {
      this.componentRef.instance.index = this.index;
      this.componentRef.instance.isLauncher = this.isLauncher;
    }
  }

  private _previewMode: PreviewMode;
  @Input() set previewMode(val: PreviewMode) {
    const lastPreviewState = this._previewMode;
    this._previewMode = val;
    if (['none', 'popup'].includes(this.previewMode)) {
      this.clearComponentRef();
      return;
    }
    if (this.isSideAndPopupNavigation(lastPreviewState, val)) {
      this.updateComponentSize();
    } else {
      this.setPreviewComponent(this.previewType);
    }
  }
  get previewMode(): PreviewMode {
    return this._previewMode;
  }

  _previewViewModel: PreviewViewModel;
  @Input() set previewViewModel(val: PreviewViewModel) {
    if (this._previewViewModel?.previewWidth !== val.previewWidth) {
      if (this.componentRef?.instance) {
        this.componentRef.instance.previewWidth = this.previewWidth;
        this.cdr.markForCheck();
      }
    }
  }

  @Input() set previewWidth(val: number) {
    if (this.componentRef?.instance) {
      this.componentRef.instance.previewWidth = this.previewWidth;
      this.cdr.markForCheck();
    }
  }

  get previewWidth(): number {
    const preview = document.querySelector('preview').getBoundingClientRect();
    return window.innerWidth - preview.left;
  }

  private _items: PreviewItem[];
  @Input() set items(items: PreviewItem[]) {
    if (!items?.length) {
      this._items = items;
      return;
    }
    if (!isEqual(items, this.items)) {
      this.setPreviewComponent(this.previewType);
    }
    if (this.isDirty()) {
      this.componentRef.instance.dirtyChange$.next('open');
      return;
    }
    this._items = items;
    this.items.forEach((item) => this.updateComponentModel(item));
    this.cdr.markForCheck();
  }
  get items(): PreviewItem[] {
    return this._items;
  }

  private _isLauncher: boolean;
  public get isLauncher(): boolean {
    return this._isLauncher;
  }
  @Input() public set isLauncher(value: boolean) {
    this._isLauncher = value;
  }
  private _previewType: PreviewType;
  @Input() set previewType(previewType: PreviewType) {
    if (this.previewType === previewType) return;
    this._previewType = previewType;
    this.setPreviewComponent(previewType);
  }
  get previewType(): PreviewType {
    return this._previewType;
  }

  private _previewHeight: number;
  @Input() set previewHeight(val: number) {
    this._previewHeight = val;
    if (this.componentRef?.instance) {
      this.componentRef.instance.previewHeight = val;
    }
  }

  get previewHeight(): number {
    return this._previewHeight;
  }

  private componentRef: ComponentRef<PreviewComponent>;
  private previewComponent: ComponentType<PreviewComponent>;
  private itemsSubscriptions: Subscription[] = [];
  private afterViewInitPromise: ManualPromise<void> = new ManualPromise();

  constructor(
    private cdr: ChangeDetectorRef,
    private hubService: HubService,
    private eventsService: EventsService,
    private favoritesService: FavoritesService
  ) {}

  ngOnInit(): void {
    this.listenToFavoriteChange();
  }

  private setPreviewComponent(previewType?: PreviewType) {
    switch (previewType ?? this.previewType) {
      case 'people':
      case 'relevant-people':
        this.previewComponent = PeoplePreviewContainerComponent;
        break;
      case 'files':
      case 'collection-file':
        this.previewComponent = FilePreviewContainerComponent;
        break;
      case 'mail':
        this.previewComponent = MailPreviewContainerComponent;
        break;
      case 'wiki card':
        this.previewComponent = CardPreviewComponent;
        break;
      default:
        this.previewComponent = PreviewNotAvailableComponent;
        break;
    }
    if (this.previewComponent) {
      this.loadComponent().then(() => {
        if (!this.componentRef.instance.model && !!previewType) {
          this.items?.forEach((item) => this.updateComponentModel(item));
        }
        this.cdr.markForCheck();
      });
    }
  }

  clear() {
    this.previewComponent = PreviewNotAvailableComponent;
    this.loadComponent();
    this.cdr.markForCheck();
  }

  private async loadComponent() {
    await this.afterViewInitPromise;
    if (!this.container || !this.previewComponent) return;

    if (!this.componentRef || this.componentRef.componentType != this.previewComponent) {
      if ((this.componentRef && this.componentRef.componentType != this.previewComponent) || this.previewMode === 'none') {
        this.clearComponentRef();
      }
      if (!this.componentRef) {
        this.componentRef = this.container.createComponent(this.previewComponent);
      }
    }
    this.updateComponentSize();
    this.cdr.markForCheck();
  }

  private listenToFavoriteChange() {
    this.favoritesService.all$.pipe(untilDestroyed(this)).subscribe(async (favorites) => {
      if (this.previewMode !== 'side') return;
      if (!favorites) favorites = [];
      const item = (this.items || [])[0]?.item;
      if (!item) {
        return;
      }
      const found = favorites?.find((favorite) => {
        if (!favorite?.id || !favorite?.type) return false;
        return item.id === favorite.id && convertResultTypeToFavoriteType(item.type) === favorite.type;
      });
      item.isFavorite = !!found;
      await this.afterViewInitPromise;
      this.componentRef.instance.model = item;
      this.componentRef.instance.isFavorite = item.isFavorite;
    });
  }

  private updateComponentModel(item: PreviewItem): void {
    if (!this.componentRef || !this.items) return;
    this.unsubscribeOldItem(this.itemsSubscriptions);

    if (this.previewType === 'files') {
      (this.componentRef.instance as FilePreview).isFirst = item.isFirst;
      (this.componentRef.instance as FilePreview).isLast = item.isLast;
    }
    this.componentRef.instance.model = item.item;
    this.componentRef.instance.telemetrySearch = this.telemetrySearch;
    this.componentRef.instance.isLauncher = this.isLauncher;
    this.componentRef.instance.previewWidth = this.previewWidth;
    this.componentRef.instance.previewHeight = this.previewHeight;

    this.sendInitialEvent(item);
    const sizeSub = this.componentRef.instance.size$?.pipe(untilDestroyed(this)).subscribe((state) => {
      this.sizeChanged.emit(state);
    });
    const reloadSub = this.componentRef.instance.reload$?.pipe(untilDestroyed(this)).subscribe(() => {
      this.componentRef.instance.model = item.item;
    });
    const invokeSub = this.componentRef.instance?.invoke?.pipe(untilDestroyed(this)).subscribe((event) => this.onInvoke(event));
    const dirtySub = this.componentRef.instance?.dirtyChange$?.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res !== 'open') {
        this.invokeDirty.emit(res);
      }
    });
    this.itemsSubscriptions = [sizeSub, reloadSub, invokeSub, dirtySub];
  }

  private sendInitialEvent(previewItem: PreviewItem) {
    if (this.previewMode === 'none') return;
    const item: Search.ResultResourceItem = previewItem.item;
    if (!item) return;
    const ev: Partial<EventInfo> = {
      location: { title: this.hubService.currentLocation },
      resources: [
        {
          id: item.id,
          appId: item.resource?.appId,
          type: item.type,
          linkId: item.resource?.linkId,
          list: this.previewType,
          position: this.index,
        },
      ],
      label: this.previewMode === 'side' ? 'side_preview' : 'popup',
      target: previewItem.trigger ? previewItem.trigger : 'mouse_click',
    };
    this.eventsService.event('preview.resource', ev);
  }

  ngAfterViewInit(): void {
    this.afterViewInitPromise.resolve();
  }

  onInvoke(invokeData: InvokeCommand) {
    this.invoke.emit(invokeData);
  }

  private updateComponentSize(): void {
    if (!this.componentRef) return;
    this.componentRef.instance.size = this.previewMode;
  }

  private clearComponentRef() {
    this.container?.clear();
    this.componentRef?.destroy();
    this.componentRef = null;
  }

  ngOnDestroy(): void {
    this.clearComponentRef();
  }

  private isSideAndPopupNavigation(lastState: PreviewMode, currentState: PreviewMode) {
    return (lastState === 'side' && currentState === 'popup') || (lastState === 'popup' && currentState === 'side');
  }

  private unsubscribeOldItem(subscriptions: Subscription[]) {
    if (!this.itemsSubscriptions.length) return;
    subscriptions.forEach((sb) => sb?.unsubscribe());
  }

  isDirty() {
    return this.componentRef?.instance?.isDirty && this.componentRef.instance.isDirty();
  }
}
