import { KeyName } from '@local/ts-infra';
import { CustomKeyboardEvent } from '@shared/services/keyboard.service';
import { Subject } from 'rxjs';
import { SearchResults } from 'src/app/bar/views';
import { ScrollService } from 'src/app/bar/views/results/services/scroll.service';

export type ScrollMode = 'horizontal' | 'vertical';

export class KeyboardHelperService {
  private items: Array<any>;
  private scrollService: ScrollService<SearchResults | any>;
  public selectedIndex: number;
  public updateCdr = new Subject<void>();
  public updateSelectedIndex = new Subject<number>();
  private nextKey: KeyName;
  private prevKey: KeyName;

  constructor(private scrollMode: ScrollMode = 'vertical') {
    this.updateScrollMode(this.scrollMode);
  }

  get keys() {
    return { prevKey: this.prevKey, nextKey: this.nextKey };
  }

  onInit(selectedIndex: number, items: Array<any>, scrollService?: ScrollService<SearchResults | any>) {
    this.selectedIndex = selectedIndex;
    this.items = items;
    this.scrollService = scrollService;
  }

  updateScrollMode(scrollMode: ScrollMode) {
    const isVertical = scrollMode === 'vertical';
    this.nextKey = isVertical ? 'ArrowDown' : 'ArrowRight';
    this.prevKey = isVertical ? 'ArrowUp' : 'ArrowLeft';
  }

  public handleArrows(keys: Array<KeyName>, event: CustomKeyboardEvent) {
    const key = keys[0];
    switch (key) {
      case this.nextKey:
        if (this.selectedIndex === undefined) {
          this.onSelectedIndex(0, event);
        } else if (this.selectedIndex + 1 < this.items?.length) {
          this.onSelectedIndex(1, event);
        }
        event.preventDefault();
        event.stopPropagation();
        return;
      case this.prevKey: {
        if (this.selectedIndex === 0) {
          this.selectedIndex = undefined;
          this.onSelectedIndex(undefined, event);
          event.stopPropagation();
          this.updateCdr.next();
        }
        if (this.selectedIndex - 1 >= 0) {
          this.onSelectedIndex(-1, event);
        }
        event.preventDefault();
        return;
      }
    }
  }

  onSelectedIndex(value: number, event: CustomKeyboardEvent) {
    if (value === 0) this.selectedIndex = 0;
    if (value === undefined) this.selectedIndex = undefined;
    else this.selectedIndex += value;
    this.checkIfScrollNeeded();
    event.preventDefault();
    event.stopPropagation();
    this.updateCdr.next();
    this.updateSelectedIndex.next(this.selectedIndex);
  }

  async checkIfScrollNeeded() {
    const newIdx = this.scrollService?.scrollIfNeeded(this.selectedIndex, this.items);
    if (typeof newIdx === 'number') this.selectedIndex = newIdx;
  }
}
