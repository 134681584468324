<overlay *ngIf="linkResult.status === 'success' && !suppressSyncMessage" (closeOverlay)="closeOverlay.emit()">
  <ng-container overlay-title>We are processing the data</ng-container>
  <ng-container overlay-content
    >You can start making searches and running actions from the search bar.<br />
    Not all your data maybe available as it takes us some times to process all your resources.</ng-container
  >
  <ng-container overlay-actions>
    <u-button
      label="Got it"
      type="primary"
      fontIcon="thumbs-up"
      [iconStyles]="{ fontWeight: '700' }"
      iconPos="left"
      (click)="closeOverlay.emit()"
      data-cy="linkapp-got-it-button"
    ></u-button>
  </ng-container>
  <ng-container overlay-note>
    <div style="cursor: pointer; margin: auto; display: flex; align-items: center" (click)="toggleSuppressSync.emit()">
      <div class="checkbox" [class.checked]="suppressSyncMessage">
        <i class="font-icon icon-check"></i>
      </div>
      <span>Don't show this again</span>
    </div>
  </ng-container>
</overlay>

<overlay *ngIf="linkResult.status === 'success' && suppressSyncMessage" (closeOverlay)="closeOverlay.emit()">
  <ng-container overlay-title>Link connected successfully</ng-container>
  <ng-container overlay-content>Great! Now you can go back and search from the search bar. </ng-container>
  <ng-container overlay-actions>
    <u-button label="Go back" type="primary" class="got-it" (click)="closeOverlay.emit()"></u-button>
  </ng-container>
</overlay>

<overlay *ngIf="linkResult.status === 'cancel'" (closeOverlay)="back.emit()">
  <ng-container overlay-title>No permission granted</ng-container>
  <ng-container overlay-content>
    Without permission we won't be able to offer Unleash experience,<br />
    You can go back and try again.
  </ng-container>
  <ng-container overlay-actions>
    <u-button label="Back" style="margin-right: 15px" type="secondary" (onClick)="back.emit()"></u-button>
    <u-button
      label="Try again"
      type="primary"
      fontIcon="redo2"
      [iconStyles]="{ fontSize: '12px', fontWeight: 'bold' }"
      [styles]="{ width: '100%' }"
      (click)="connectAnother.emit()"
    ></u-button>
  </ng-container>
</overlay>

<ng-container *ngIf="linkResult.status === 'error'">
  <overlay *ngIf="isWrongAccountError(linkResult); else duplicate" (closeOverlay)="back.emit()">
    <ng-container overlay-title>You've connected the wrong account</ng-container>
    <ng-container overlay-content
      >It looks like you tried connecting the wrong account.<br />
      When re-connecting a link, you must use the same account.<br />
      If you can't do that, you can always remove the link and create a new one.</ng-container
    >
    <ng-container overlay-content>
      <p style="font-size: smaller; margin-top: 16px">The existing account key is "{{ linkResult.error.key }}"</p>
    </ng-container>
    <ng-container overlay-actions>
      <u-button label="Back" style="margin-right: 15px" type="secondary" (onClick)="back.emit()"></u-button>
      <u-button
        label="Try again"
        type="primary"
        fontIcon="redo2"
        [iconStyles]="{ fontSize: '12px', fontWeight: 'bold' }"
        [styles]="{ width: '100%' }"
        (click)="connectAnother.emit()"
      ></u-button>
    </ng-container>
  </overlay>

  <ng-template #duplicate>
    <overlay *ngIf="isDuplicateError(linkResult); else defaultError" (closeOverlay)="back.emit()">
      <ng-container overlay-title>This account is already connected</ng-container>
      <ng-container overlay-content
        >It looks like this account is already connected.<br />
        You can go back or connect different account.</ng-container
      >
      <ng-container overlay-actions>
        <u-button
          data-cy="linkapp-already-connected-back-btn"
          label="Back"
          style="margin-right: 15px"
          type="secondary"
          (onClick)="back.emit()"
        ></u-button>
        <u-button
          label="Add Account"
          type="primary"
          fontIcon="plus"
          [iconStyles]="{ fontSize: '10px', fontWeight: 'bold' }"
          [styles]="{ width: '100%' }"
          (click)="connectAnother.emit()"
        ></u-button>
      </ng-container>
    </overlay>
  </ng-template>

  <ng-template #defaultError>
    <error-overlay
      [displayText]="linkResult.error?.message || 'For some reason we could not connect with your account.'"
      (back)="back.emit()"
      (again)="connectAnother.emit()"
    >
    </error-overlay>
  </ng-template>
</ng-container>
