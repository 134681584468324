<div class="u-date-picker-wrapper" [ngClass]="{ 'u-date-picker-wrapper-center': popupData?.centerPositionPopup }" (click)="closePopup()">
  <p-calendar
    (click)="$event.stopPropagation()"
    [inline]="true"
    [selectionMode]="selectionMode"
    [(ngModel)]="dateValue"
    (onSelect)="onCalendarDateSelected($event)"
    (onMonthChange)="onChangeMonth($event)"
    [minDate]="minDate"
    [maxDate]="maxDate"
  >
    <ng-template pTemplate="header">
      <div class="top-header">
        <p class="top-header-title">{{ headerTitle }}</p>
        <ng-container *ngIf="datePickerOptions.length === 1; else multipleOptions">
          <div class="date-picker-option">{{ datePickerOptions[0].label }}</div>
        </ng-container>
        <ng-template #multipleOptions>
          <u-dropdown
            class="top-header-dropdown"
            [options]="datePickerOptions"
            [selectedValue]="selectedTypeValue"
            optionLabel="label"
            [inTable]="true"
            (onChange)="onDatePickerTypeChange($event)"
            [styles]="{ maxWidth: dropDownOptions?.maxWidth + 'px' }"
            [showSelectedItemTooltip]="dropDownOptions?.showSelectedItemTooltip"
            [maxCharacters]="dropDownOptions?.maxCharacters"
            [appendTo]="optionsAppendTo"
          >
          </u-dropdown>
        </ng-template>
      </div>
      <div class="inputs-container">
        <u-input
          [model]="inputs.values.start"
          (onChange)="onChangeInput($event, 'start')"
          (onBlur)="saveInputValue('start')"
          (onFocus)="onFocusInput('start')"
          (click)="onClickInput('start')"
          (onEnter)="saveInputValue('start')"
          [placeholder]="inputs.placeholders.start"
          [inputSize]="'small'"
          [errorBorder]="!!inputs.errors.start"
          class="input"
          [removeText]="true"
          #startInput
        ></u-input>
        <span *ngIf="inputs.errors.start" class="error-text">{{ inputs.errors.start }}</span>
        <u-input
          *ngIf="displayEndInput"
          [model]="inputs.values.end"
          (onChange)="onChangeInput($event, 'end')"
          (onBlur)="saveInputValue('end')"
          (onFocus)="onFocusInput('end')"
          (click)="onClickInput('end')"
          (onEnter)="saveInputValue('end')"
          [placeholder]="inputs.placeholders.end"
          [inputSize]="'small'"
          [errorBorder]="!!inputs.errors.end"
          class="input end"
          [removeText]="true"
          #endInput
        ></u-input>
        <span *ngIf="inputs.errors.end" class="error-text">{{ inputs.errors.end }}</span>
      </div>
    </ng-template>
  </p-calendar>
</div>
