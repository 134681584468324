import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { User as UserInt, Window, Workspace } from '@local/client-contracts';
import { isEmbed, isMac, isNativeWindow } from '@local/common-web';
import { PopupRef, PopupService, UiIconModel } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventsService } from '@shared/services';
import { RouterService } from '@shared/services/router.service';
import { componentServicesRpcProvider, ServicesRpcService } from '@shared/services/rpc.service';
import { SessionService } from '@shared/services/session.service';
import { TitleBarService } from '@shared/services/title-bar.service';
import { getRouteById } from '@shared/utils/routes.util';
import { Observable, take } from 'rxjs';
import { FyiService } from 'src/app/bar/services';
import { HelpPopupService } from 'src/app/bar/services/help-popup.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { SidebarService } from 'src/app/bar/services/sidebar.service';
import { MainRoute, routes } from '../../../../../bar/routes/main-routes-config';
import { ActionItem } from './menu-items';
import { EmbedService } from '@shared/embed.service';
import { AvatarPopupComponent, AvatarPopupData } from '../../components/avatar-popup/avatar-popup.component';

@UntilDestroy()
@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  providers: [componentServicesRpcProvider],
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() actionsEmitter: EventEmitter<ActionItem['event']> = new EventEmitter();
  @Output() trialBannerClick = new EventEmitter<void>();

  private readonly isMac = isMac();
  private readonly isNativeWindow = isNativeWindow();
  private readonly SIDEBAR_LARGE_WIDTH = 260;
  private avatarPopupRef: PopupRef<AvatarPopupComponent, AvatarPopupData>;

  BOTTOM_MENU_ITEMS_IDS = ['apps', 'settings'];
  actions: ActionItem[];
  primaryAction: ActionItem['event'] = 'quit';
  bottomMenuItems: MainRoute[] = [];
  updateReady: boolean;
  isUserBadgeOpen = false;
  resizeObserver: ResizeObserver;
  shortenDraggableArea: boolean;
  unreadFyi$: Observable<number>;
  bannerText: string;
  trialBannerType: 'sidebar' | 'large-sidebar';
  embedInline: boolean;
  isEmbed: boolean = isEmbed();

  private _planDaysLeft: number;
  get planDaysLeft(): number {
    return this._planDaysLeft;
  }
  @Input() set planDaysLeft(value: number) {
    this._planDaysLeft = value;
    this.bannerText = `${this.planDaysLeft} days left`;
  }
  @Input() onTrialPlan: boolean;
  @Input() workspace: Workspace.Workspace;
  @Input() workspaceIcon: UiIconModel;
  @Input() user: UserInt.Info;

  @ViewChild('avatarElement') avatarElement: ElementRef;

  @Output() fyiClick: EventEmitter<ElementRef> = new EventEmitter();
  @Output() helpClick: EventEmitter<ElementRef> = new EventEmitter();
  @ViewChild('helpButtonRef', { read: ElementRef }) helpButtonRef: ElementRef;
  @ViewChild('fyiButton', { read: ElementRef }) fyiButtonRef: ElementRef;

  constructor(
    private services: ServicesRpcService,
    public hubService: HubService,
    private sessionService: SessionService,
    private popupService: PopupService,
    public routerService: RouterService,
    private sidebarService: SidebarService,
    protected eventsService: EventsService,
    private elRef: ElementRef,
    private fyiService: FyiService,
    private helpPopupService: HelpPopupService,
    private titleBarService: TitleBarService,
    private cdr: ChangeDetectorRef,
    private embedService: EmbedService
  ) {}

  get activePage$() {
    return this.titleBarService.active$;
  }

  get isFyiActive$() {
    return this.fyiService.active$;
  }
  get isHelpActive$() {
    return this.helpPopupService.active$;
  }

  ngAfterViewInit(): void {
    const currentWidth = this.elRef?.nativeElement?.getBoundingClientRect()?.width;
    this.calcTrialBannerType(currentWidth);
    if (this.onTrialPlan) {
      this.initResizeObserver();
    } else {
      this.resizeObserver?.disconnect();
    }
  }

  async setEmbedInline() {
    if (this.isEmbed) {
      this.embedInline = await this.embedService?.isInline();
    }
  }

  initResizeObserver() {
    this.resizeObserver = new ResizeObserver((entries) => {
      this.calcTrialBannerType(entries[0]?.contentRect?.width);
    });
    this.resizeObserver.observe(this.elRef.nativeElement);
  }

  private calcTrialBannerType(currentSize: number) {
    this.trialBannerType = currentSize < this.SIDEBAR_LARGE_WIDTH ? 'sidebar' : 'large-sidebar';
    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    this.sidebarService.isUserBadgeOpen$.pipe(untilDestroyed(this)).subscribe((v) => {
      this.isUserBadgeOpen = v;
      this.cdr.markForCheck();
    });

    this.unreadFyi$ = this.fyiService.unread$;
    this.bottomMenuItems = this.BOTTOM_MENU_ITEMS_IDS.map((id) => getRouteById(id, routes));

    this.routerService.active$.pipe(untilDestroyed(this)).subscribe((page) => {
      this.shortenDraggableArea = !this.isMac && this.isNativeWindow && page === 'home';
      this.cdr.markForCheck();
    });

    this.setEmbedInline();
  }

  ngOnDestroy(): void {
    this.avatarPopupRef?.destroy();
    this.services.destroy();
    this.resizeObserver?.disconnect();
  }

  onFyiClick() {
    this.fyiClick.emit(this.fyiButtonRef);
  }

  onHelpClick(event) {
    event.stopPropagation();
    this.helpClick.emit(this.helpButtonRef);
  }

  closeApp(): void {
    this.services.invoke('app.close');
  }

  public async signOut(): Promise<void> {
    await this.sessionService.signOut();
  }

  public async onAvatarClick(event: MouseEvent): Promise<void> {
    this.isUserBadgeOpen = true;
    const location: string = this.hubService.currentLocation;
    let { x, y } = this.avatarElement.nativeElement.getBoundingClientRect();
    this.eventsService.event('hub_side_menu.profile_button_open', {
      location: { title: location },
    });

    const offset: Partial<ConnectedPosition> = { offsetY: 32, offsetX: 16 };
    const position: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top', ...offset }];
    this.avatarPopupRef = this.popupService.open<AvatarPopupComponent, AvatarPopupData>(
      { x, y },
      AvatarPopupComponent,
      {
        email: this.user.email,
        location: { title: location },
        workspace: this.workspace,
        workspaceIcon: this.workspaceIcon,
        hideLogout: this.embedInline,
      },
      { position }
    );
    this.avatarPopupRef.compInstance.logout.pipe(take(1)).subscribe(() => this.signOut());
    this.avatarPopupRef.destroy$.pipe(untilDestroyed(this)).subscribe(() => {
      this.isUserBadgeOpen = false;
      this.cdr.markForCheck();
      this.eventsService.event('hub_side_menu.profile_button_close', {
        location: { title: location },
      });
    });
    this.cdr.markForCheck();
  }
}
