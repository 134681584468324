import { SelectItem } from 'primeng/api';

export class SizeImageConstants {
  static readonly SELECTED_SIZE_OPTIONS: SelectItem[] = [
    { label: 'Full width post', value: 'large' },
    { label: 'Small post', value: 'small' },
  ];
  static readonly DESCRIPTION_SIZE_IMAGES = {
    small: 'recommended size 350 x 300 pixels (7:6)',
    large: 'recommended size 1280 x 320 pixels (4:1)',
  };
  static readonly WARNING_MESSAGE = 'Changing the layout will effect existing images.';
}
