import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { isMac } from '@local/common-web';
import { KeyboardService } from '@shared/services/keyboard.service';
import { isEnterKey, isModifierKey, KeyName, removeModifiers } from '@local/ts-infra';
import { ContextMenuData, ContextMenuItem, Trigger } from './models';
import { PopupRef } from '@local/ui-infra';
import { ContextMenuService } from './context-menu.service';
import { ConnectedPosition } from '@angular/cdk/overlay';

@Component({
  selector: 'context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly MIN_WIDTH_ITEM: number = 190;
  items: Array<ContextMenuItem>;
  markedIndex: number;
  minWidthItem: number;
  private keyHandlerId: string;
  hasSecondLevel: boolean;

  get actionableItems() {
    return this.items.filter((i) => i?.type !== 'separator');
  }

  @ViewChildren('menuItem') itemsRefs: QueryList<ElementRef>;
  constructor(
    private ref: PopupRef<ContextMenuComponent, ContextMenuData>,
    private keyboardService: KeyboardService,
    private contextMenuService: ContextMenuService
  ) {}

  @HostListener('document:contextmenu', ['$event'])
  onDocumentContextMenu(event: MouseEvent) {
    event.preventDefault();
  }

  ngOnInit(): void {
    this.items = this.ref.data.items;
    this.minWidthItem = this.ref.data.minWidthItem || this.MIN_WIDTH_ITEM;
    if (this.items.find((elm) => elm.items)) this.hasSecondLevel = true;
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.keysHandler(keys, event), 8);
  }

  ngAfterViewInit() {
    this.select('first');
  }

  ngOnDestroy() {
    this.contextMenuService.destroy();
    if (this.keyHandlerId) this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
  }

  keysHandler(keys: Array<KeyName>, event): void {
    if (isMac()) keys = keys.map((k) => (k = k === 'control' ? 'command' : k));
    const key = keys[0];
    const matchingIdx = this.items.findIndex(
      (item) => item?.shortcut && item?.shortcut?.length === keys.length && item.shortcut.every((key) => keys.includes(key))
    );

    if (matchingIdx !== -1 && !keys.includes('enter')) {
      this.select(matchingIdx);
      this.onInvoke(this.items[matchingIdx], 'context_menu_keyboard');
      event.stopPropagation();
      return;
    }

    if (keys.length === 1) {
      if (key === 'ArrowDown') {
        this.select('next');
        event.stopPropagation();
        event.preventDefault();
        return;
      }

      if (key === 'ArrowUp') {
        this.select('prev');
        event.stopPropagation();
        event.preventDefault();
        return;
      }

      if (key === 'escape') {
        this.ref.destroy();
        event.stopPropagation();
        return;
      }

      if (isEnterKey(key)) {
        this.onInvoke(this.actionableItems[this.markedIndex], 'context_menu_keyboard');
        event.stopPropagation();
        return;
      }

      if (key.length === 1 || isModifierKey(key)) {
        const idx = this.actionableItems.findIndex((i) =>
          isModifierKey(key) ? i.shortcut && i.shortcut.includes(key) : i.text && i.text.toLowerCase().includes(key.toLowerCase())
        );
        if (idx !== -1) this.select(idx);

        event.stopPropagation();
        return;
      }
    }
    const noMod = [...keys];
    removeModifiers(noMod);
    if (noMod.length > 0) {
      this.ref.destroy();
    }
  }

  select(event: 'next' | 'prev' | 'first' | number) {
    const items = this.itemsRefs.toArray();

    // ItemsRefs doesnt include the separators so we need to deduct the difference to find the right index
    if (typeof event === 'number') event = event - (this.items.length - items.length);

    let targetIdx: number;
    if (event === 'first') targetIdx = 0;
    else if (event === 'next') targetIdx = this.markedIndex + 1;
    else if (event === 'prev') targetIdx = this.markedIndex - 1;
    else if (event < items.length && event >= 0) targetIdx = event;
    else {
      throw new Error('invalid index');
    }

    if (targetIdx > items.length - 1 || targetIdx < 0) return;
    else {
      const prev = items[this.markedIndex]?.nativeElement as HTMLElement;
      const next = items[targetIdx]?.nativeElement as HTMLElement;
      if (prev) prev.classList.remove('marked');
      if (next) next.classList.add('marked');
      this.markedIndex = targetIdx;
    }
  }

  onInvoke(item: ContextMenuItem, trigger?: Trigger) {
    this.ref.data.onInvoke(item, trigger);
    this.contextMenuService.destroyAllRefs();
  }

  onInvokeItems($event, item: ContextMenuItem) {
    const menuWidth = item.items.menuWidth + 10; //second level width menu, 10 - margin
    if (item.items && this.contextMenuService.currentOpenRefs() === 1) {
      let { x, y, right, width } = $event.currentTarget.getBoundingClientRect();
      const offsetX = window.innerWidth - right > menuWidth ? width + 10 : menuWidth * -1; //10-margin of the component
      const offset: Partial<ConnectedPosition> = { offsetY: 0, offsetX };
      const position: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top', ...offset }];
      this.contextMenuService.open({ x, y }, item.items, { position, hasBackdrop: false }, 2);
    }
  }

  closeSecondLevel() {
    if (this.hasSecondLevel) {
      this.contextMenuService.destroyRef(2);
    }
  }
}
