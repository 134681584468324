import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { Constants } from '@local/common';

@Component({
  selector: 'worker-change-popup',
  templateUrl: './arm-emulator-popup.component.html',
  styleUrls: ['./arm-emulator-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArmEmulatorPopupComponent {
  @Output() dismiss = new EventEmitter();

  onDismiss() {
    this.dismiss.emit();
  }

  upgrade() {
    window.open(Constants.NATIVE_MACOS_URL, '_blank');
    this.dismiss.emit();
  }
}
