import { Component, Input, OnInit } from '@angular/core';
import { Style } from '@local/client-contracts';

@Component({
  selector: 'short-selected',
  templateUrl: './short-selected.component.html',
  styleUrls: ['./short-selected.component.scss'],
})
export class ShortSelectedComponent {
  @Input() countSelected: number;
  @Input() placeholder: string;
  @Input() multiSelectIcon: Style.EntityIcon<Style.EntityIconType>;
}
