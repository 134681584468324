import { Filters, Style } from '@local/client-contracts';
import { DatePickerType } from '@local/common-web';

export type FilterViewDetails = {
  isFullDetailLine?: boolean;
  showItemLabel?: boolean;
  showItemIcon?: boolean;
  showItemIconLabel?: boolean;
  isTwoLine?: boolean;
  placeholder?: string;
  showSelectedItemIcons?: boolean;
  noCheckbox?: boolean;
  openOnSemicolon?: boolean;
  oneValue?: boolean;
  showSplitLine?: boolean;
  showHasValues?: boolean;
  excludeSelectedFromHeader?: boolean;
  showClearAll?: boolean;
  hideOnSelect?: boolean;
  limitShowSelectedLabels?: number;
  sortBy?: { name: string; order: 'asc' | 'desc' }[];
  showRemoveIcon?: boolean;
  showBackIcon?: boolean;
  clearAllMinSelected?: number;
  shortSelected?: boolean;
  tooltipSelected?: string;
  showSeparate?: boolean;
  position?: FilterPosition;
  customSelectedCount?: number;
  enabledOnly?: boolean;
  limitItemsCount?: number;
  wideWidth?: boolean;
  keepPlaceholder?: boolean;
  enableBack?: boolean;
  appendTo?: string;
  separateSelected?: boolean;
  showSingleItemSelected?: boolean;
  allowClickInReadOnly?: boolean;
  showIndicatorInList?: boolean;
  enableEmptyFilter?: boolean;
  filterTitle?: string;
  hideSearchFilter?: boolean;
  tooltipLabel?: string;
  hideTitle?: boolean;
};

export type FilterPosition = 'Main' | 'Side';
export interface Filter {
  title?: string;
  name: string;
  picker: Filters.PickerType;
  values: DisplaySearchFilterValue[];
  disabled?: boolean;
  icon?: Style.EntityIcon<Style.EntityIconType>;
  placeholder?: string;
  searchable?: boolean;
  singleChoice?: boolean;
  sort?: Filters.SortOrder;
  type: FilterRequestType;
  supportSingleTag?: boolean;
  valuesFetcher?: (term: string) => Promise<DisplaySearchFilterValue[]>;
  viewDetails?: FilterViewDetails;
  readonly?: boolean;
  disableMarkSelected?: boolean;
}
export interface TimeDropdown extends Filter {
  picker: 'time-dropdown';
  pickerTypes: DatePickerType[];
  minDate: Date;
  maxDate: Date;
  defaultCustomTimeFilter?: DatePickerType;
}
export interface DropdownFilter extends Filter {
  picker: 'dropdown';
}

export interface ToggleFilter extends Filter {
  picker: 'toggle';
}

export interface SpreadFilter extends Filter {
  picker: 'spread';
}

export interface ConstantFilter extends Filter {
  picker: 'constant';
  constantLabel?: string;
}

export interface NestedFilter extends Filter {
  picker: 'nested';
  values: NestedSearchFilterValue[];
}

export interface SearchFilterValue {
  id: string;
  value: any;
  filterName: string;
  icon?: Style.EntityIcon<Style.EntityIconType>;
  disabled?: boolean;
  type?: string;
  label?: string;
  sortLevel?: number;
  selected?: boolean;
  isMoreAccounts?: boolean;
  updateOnRemove?: boolean;
  disableGroupValues?: boolean;
  supportTag?: boolean;
  iconLabel?: IconLabel;
}

export interface IconLabel {
  icon: string;
  tooltip?: string;
  color?: string;
}

export interface FilterChangeDataItem {
  values?: SearchFilterValue[];
}

export type FilterChangeDataAction = 'Add' | 'Remove' | 'Set' | 'ClearAll';
export interface FilterChangeData {
  name: string;
  current: FilterChangeDataItem;
  changes: FilterChangeDataItem;
  action: FilterChangeDataAction;
  supportTag?: boolean;
}

export type DisplaySearchFilterValue = SearchFilterValue & {
  title: string;
  subtitle: string;
  filterName: string;
  count?: number;
  order?: number;
  height?: number;
};
export type GroupDisplaySearchFilterValue = DisplaySearchFilterValue & { type: 'group'; childFilter?: Filter };
export type SpecialSearchFilterValue = DisplaySearchFilterValue & { type: 'special'; childFilter?: Filter };
export type NestedSearchFilterValue = DisplaySearchFilterValue & { type: 'nested'; childFilter?: NestedFilter };

export type FilterRequestType = 'pre' | 'post';

export const isGroupFilterValue = (x: DisplaySearchFilterValue): x is GroupDisplaySearchFilterValue => x.type === 'group';
export const isSpecialFilterValue = (x: DisplaySearchFilterValue): x is SpecialSearchFilterValue => x.type === 'special';
export const isNestedFilterValue = (x: DisplaySearchFilterValue): x is NestedSearchFilterValue => x.type === 'nested';
