<ng-container *ngIf="model" [ngSwitch]="model.type">
  <ng-container *ngSwitchCase="'img'" #imgIcon>
    <u-icon
      [class]="model.type + ' ' + (class ?? '')"
      class="icon"
      [model]="{ type: 'img', value: model.value }"
      [styles]="{ width: size, height: size }"
    ></u-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'font-icon'" #fontIcon>
    <u-icon
      [class]="(mirrorClass ? class ?? '' : '') + ' ' + model.type"
      class="icon"
      [style.font-size]="size"
      [model]="{ type: 'font', value: model.value || 'font-icon icon-question-mark-circle' }"
      [styles]="completedStyle"
    ></u-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'account'" #accountIcon>
    <account-icon
      class="account-icon"
      [class]="model.type + ' ' + (class ?? '')"
      [model]="model.value"
      [size]="size"
      [state]="state"
    ></account-icon>
  </ng-container>
</ng-container>
