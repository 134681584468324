import { Injectable } from '@angular/core';
import { Style } from '@local/client-contracts';
import { observable } from '@local/common';
import { BehaviorSubject, distinctUntilChanged, Observable, ReplaySubject } from 'rxjs';

export interface Breadcrumb {
  title: string;
  icon?: Style.EntityIcon<Style.EntityIconType>;
  path: string;
  emoji?: string;
  notClickable?: boolean;
  tooltip?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  private _items$ = new ReplaySubject<Breadcrumb[]>(1);
  private _navigationEnabled$ = new BehaviorSubject<boolean>(true);
  private _show$ = new BehaviorSubject<boolean>(true);
  private _showGhost$ = new BehaviorSubject<boolean>(false);

  @observable
  get show$(): Observable<boolean> {
    return this._show$.asObservable();
  }

  set show(val: boolean) {
    this._show$.next(val);
  }

  @observable
  get showGhost$(): Observable<boolean> {
    return this._showGhost$.asObservable();
  }

  set showGhost(val: boolean) {
    this._showGhost$.next(val);
  }

  constructor() {}

  set items(b: Breadcrumb[]) {
    this._items$.next(b);
  }

  @observable
  get items$(): Observable<Breadcrumb[]> {
    return this._items$.pipe(
      distinctUntilChanged((prev, current) => {
        if (prev?.length !== current?.length) return false;
        for (let index = 0; index < prev.length; index++) {
          const p = prev[index];
          const c = current[index];
          if (p.path !== c.path || p.title !== c.title || p?.emoji !== c?.emoji) return false;
        }
        return true;
      })
    );
  }

  set navigationEnabled(enabled: boolean) {
    this._navigationEnabled$.next(enabled);
  }

  @observable
  get navigationEnabled$(): Observable<boolean> {
    return this._navigationEnabled$;
  }
}
