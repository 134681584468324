import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Filters, Search } from '@local/client-contracts';
import { PopupRef, UInputComponent } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventsService } from '@shared/services';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { resultHeaderCountKey } from '@shared/utils/header-builder.util';
import { KeyName } from '@local/ts-infra';
import { BehaviorSubject, ReplaySubject, Subject, distinctUntilChanged, filter, firstValueFrom } from 'rxjs';
import { HubService } from 'src/app/bar/services/hub.service';
import { ResultsService } from 'src/app/bar/services/results.service';
import { SearchOptions, SearchResultContext, SearchService, SearchSession } from 'src/app/bar/services/search';
import { LinkResourcesResultExtra, LinkResourcesSourceSettings } from 'src/app/bar/services/search/client';
import { isLinkResourcesSettings } from 'src/app/bar/services/search/client/source-setings.util';
import { SearchResultsListComponent } from '../../../collections-page/components/search-results-list/search-results-list.component';
import { ResultItem, ScrollTrigger, SearchResults } from '../../../results';
import { specificResourcePopupContent } from '../../helpers/assistant.content';

export interface SpecificResourcePopupModel {
  filters: Filters.Values;
  selected?: string[];
}

export enum SpecificResourceFocused {
  SEARCH = 1,
  RESULTS = 2,
  BUTTONS = 3,
}

@UntilDestroy()
@Component({
  selector: 'specific-resource-popup',
  templateUrl: './specific-resource-popup.component.html',
  styleUrls: ['./specific-resource-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecificResourcePopupComponent implements OnInit, OnDestroy {
  readonly specificResourcePopupContent = specificResourcePopupContent;

  model: SpecificResourcePopupModel;

  //keyboard
  private keyHandlerId: string;
  specificResourceFocused = SpecificResourceFocused;
  componentFocused: SpecificResourceFocused;

  //search
  private searchSession: SearchSession;
  total: number;
  loading: boolean;
  displayedContext$ = new BehaviorSubject<SearchResultContext>(null);
  items$ = new BehaviorSubject<SearchResults[]>(null);
  emptyResults$ = new Subject<boolean>();
  searchId: string;

  //search input
  searchTerm = '';
  formInputChanged = new Subject<string>();
  @ViewChild('searchElement') searchElement: UInputComponent;
  @ViewChild(SearchResultsListComponent) searchResultsListComponent: SearchResultsListComponent;

  //checkbox
  checkedItems: Set<string>;
  clearCheckedItems: EventEmitter<any> = new EventEmitter();
  resultsMap = new Map<string, ResultItem>();
  itemsChanged: boolean;
  checkedItemsResults: Search.Item[];
  checkedItemsResultsReady: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() savePopup = new EventEmitter<Search.Item[]>();

  constructor(
    private cdr: ChangeDetectorRef,
    private keyboardService: KeyboardService,
    private eventsService: EventsService,
    private hubService: HubService,
    private ref: PopupRef<SpecificResourcePopupComponent, SpecificResourcePopupModel>,
    private searchService: SearchService,
    private resultsService: ResultsService
  ) {}

  ngOnInit(): void {
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 10);
    this.model = this.ref?.data;
    if (this.model.selected) {
      this.checkedItems = new Set(this.model.selected);
      this.getAllCheckedItems();
    } else {
      this.checkedItemsResultsReady.next(true);
    }
    this.searchSession = this.searchService.getOrCreateSearchSession('assistant-specific-search');
    this.search();
    this.componentFocused = SpecificResourceFocused.SEARCH;
    this.formInputChanged.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((value) => {
      this.searchTerm = value;
      this.search();
    });
  }

  ngOnDestroy(): void {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    }
    this.searchSession?.destroy();
  }

  async getAllCheckedItems() {
    const req = Array.from(this.checkedItems).map((v) => ({ resourceId: v, linkId: null }));
    this.resultsService
      .getItems$({
        resources: req,
        cache: 'first',
        includeHiddenLinks: true,
      })
      .pipe(untilDestroyed(this))
      .subscribe((res: Search.Item[]) => {
        this.checkedItemsResults = res;
        this.checkedItemsResultsReady.next(true);
      });
  }

  search() {
    this.loading = true;
    this.items$.next([]);
    setTimeout(() => {
      this.focusSearch();
    }, 0);
    this.cdr.markForCheck();
    const trigger = 'assistant-specific-resource';
    const options: SearchOptions = {
      resetSession: false,
      query: this.searchTerm,
      sources: [
        {
          id: 'link-resources',
          type: 'link-resources',
          requestMaxCount: 20,
          caching: { strategy: 'cache-or-source' },
          header: {
            title: `Showing ${resultHeaderCountKey} results`,
          },
          noHeader: false,
          disableCloudGroup: true,
          useSourceFilters: true,
          filters: { preFilters: this.model.filters },
          advancedSearch: false,
          contentSearch: false,
          disableAggregations: true,
          preventRTF: true,
          shouldSortByDate: true,
          ignoreDateHeaders: true,
          tag: trigger,
          includeHiddenLinks: true,
        } as LinkResourcesSourceSettings,
      ],
      trigger,
    };
    this.searchSession
      .search$(options)
      .pipe(untilDestroyed(this))
      .subscribe(async (ctx: SearchResultContext) => {
        if (ctx?.searchCompleted) {
          await firstValueFrom(this.checkedItemsResultsReady.pipe(filter((val) => !!val)));
          const resourceSource = ctx.sources?.find((s) => isLinkResourcesSettings(s.source));
          const extra = resourceSource?.extra as LinkResourcesResultExtra;
          this.searchId = extra?.searchId;
          const updateCtx = this.addCheckedItemsToResult(ctx);
          this.displayedContext$.next(updateCtx);
          this.items$.next(updateCtx?.items);
          this.emptyResults$.next(updateCtx && updateCtx.searchCompleted && updateCtx.items?.length == 0);
          this.updateResultsMap(updateCtx?.items);
          this.total = extra?.cloudTotalResults;
          this.loading = false;
          this.cdr.markForCheck();
        }
      });
  }

  addCheckedItemsToResult(ctx: SearchResultContext) {
    if (this.searchTerm !== '' || !this.checkedItems?.size) return ctx;
    const newItems = ctx.items.filter((item) => !this.checkedItems.has((item as Search.Item).id));
    newItems.splice(1, 0, ...(this.checkedItemsResults as SearchResults[]));
    return { ...ctx, items: newItems };
  }

  nextPage(trigger: ScrollTrigger) {
    this.searchSession
      .nextPage$(trigger, [{ type: 'link-resources' }])
      .pipe(untilDestroyed(this))
      .subscribe((ctx: SearchResultContext[]) => {
        const ctxResult = ctx[0];
        if (ctxResult?.searchCompleted) {
          this.displayedContext$.next(ctxResult);
          this.items$.next(ctxResult?.items);
          this.updateResultsMap(ctxResult?.items);
          this.cdr.markForCheck();
        }
      });
  }

  updateResultsMap(items: SearchResults[]) {
    items.forEach((item) => {
      item = item as ResultItem;
      if (!this.resultsMap.has(item.id)) {
        this.resultsMap.set(item.id, item as ResultItem);
      }
    });
  }

  checkedItemsUpdate($event) {
    this.eventsService.event('assistant.select_remove_item_collection', {
      location: { title: this.hubService.currentLocation },
    });
    this.checkedItems = $event;

    const newCheckedItemsResults = [];
    this.checkedItems.forEach((checked) => {
      if (this.resultsMap.has(checked)) {
        newCheckedItemsResults.push(this.resultsMap.get(checked));
      } else {
        newCheckedItemsResults.push(this.checkedItemsResults.find((c) => c.id === checked));
      }
    });
    this.checkedItemsResults = newCheckedItemsResults;

    this.itemsChanged = this.model.selected && this.model.selected.length !== this.checkedItems.size;
    this.cdr.markForCheck();
  }

  cancel() {
    this.ref.destroy();
  }

  save() {
    this.savePopup.emit(this.checkedItemsResults);
    this.ref.destroy();
  }

  clearAll() {
    this.clearCheckedItems.emit(true);
  }

  sendImpressionEvent(target: string, label?: string): void {
    this.eventsService.event('assistant.specificResource', {
      location: { title: this.hubService.currentLocation },
      target,
      label,
    });
  }

  componentFocusedFinishResults($event) {
    switch ($event) {
      case 'next':
        this.componentFocused = this.specificResourceFocused.BUTTONS;
        break;
      case 'prev':
        this.componentFocused = this.specificResourceFocused.SEARCH;
        break;
      default:
        break;
    }
    this.cdr.markForCheck();
  }

  onEnter() {
    if (
      this.componentFocused === this.specificResourceFocused.RESULTS &&
      !this.loading &&
      this.searchResultsListComponent.selectedIndex > 0
    ) {
      // after init phase
      this.searchResultsListComponent?.toggleCheckboxOnEnter();
    }
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    const key = keys[0];
    if (this.componentFocused === this.specificResourceFocused.RESULTS) {
      this.focusSearch();
      return;
    }
    switch (key) {
      case 'ArrowDown':
        if (this.componentFocused !== this.specificResourceFocused.BUTTONS) {
          this.componentFocused++;
        }
        break;
      case 'ArrowUp':
        if (this.componentFocused !== this.specificResourceFocused.SEARCH) {
          this.componentFocused--;
        }
        break;
      default:
        break;
    }
    this.cdr.markForCheck();
  }

  focusSearch() {
    this.searchElement?.inputElement?.el?.nativeElement?.focus();
  }
}
