<div class="recipients-container" [style.width.px]="widthRecipients">
  <ng-scrollbar *ngIf="recipients?.length > 0" [style.height.px]="scrollHeight">
    <cdk-virtual-scroll-viewport ngScrollbarView smoothScroll scrollViewport [itemSize]="itemSize">
      <ng-container *cdkVirtualFor="let r of recipients; index as i">
        <div #recipientItem class="recipient" [ngClass]="{ selected: selectedIndex === i }">
          <div *ngIf="r.name">{{ r.name }}</div>
          <span
            *ngIf="r.mail"
            (click)="copyMail(r, i)"
            [pTooltip]="'Click to copy' | toggleTooltipText: 'Copied!' : 1000 : tooltips : toggleCopyTooltip()[i] : i | async"
            tooltipPosition="top"
            tooltipStyleClass="u-tooltip"
            class="recipient-mail"
          >
            <div class="mail">{{ r.mail }}</div>
            <u-icon [model]="{ type: 'font', value: 'icon-duplicate' }" [styles]="{ fontSize: '14px' }"></u-icon>
          </span>
        </div>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </ng-scrollbar>
</div>
