import { Injectable } from '@angular/core';
import { Desktop } from '@local/client-contracts';
import { NativeMainRpcService, ServicesRpcService } from '.';
import { DesktopRpcInvoker } from './invokers/desktop.rpc-invoker';

@Injectable({
  providedIn: 'root',
})
export class DesktopService implements Desktop.Service {
  private service: Desktop.Service;

  constructor(services: ServicesRpcService, nativeServices: NativeMainRpcService) {
    this.service = (nativeServices || services).invokeWith(DesktopRpcInvoker, 'desktop');
  }

  defaultBrowser(): Promise<string> {
    return this.service.defaultBrowser();
  }

  getClipboardData(): Promise<string> {
    return this.service.getClipboardData();
  }

  isDesktopAppInstalled(appId: string, resourceType?: string): Promise<boolean> {
    return this.service.isDesktopAppInstalled(appId, resourceType);
  }
}
