import { Preferences } from '@local/client-contracts';

// returns light or dark. if theme sets to auto then return system theme
export function getTheme(theme: Preferences.Theme): Preferences.Theme {
  if (theme === 'dark' || theme === 'light') return theme;
  else return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
}

let _currentTheme = null;
export function currentTheme(): Preferences.Theme {
  if (!_currentTheme) {
    _currentTheme = localStorage ? (localStorage.getItem('unleash-theme') as Preferences.Theme) : null;
  }
  return _currentTheme;
}

export function updateTheme(theme: Preferences.Theme) {
  try {
    localStorage.setItem('unleash-theme', theme);
  } catch {}
  _currentTheme = theme;
}
