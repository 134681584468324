import { Injectable } from '@angular/core';
import { WebSearch } from '@local/client-contracts';
import { isNativeWindow } from '@local/common-web';
import { firstValueFrom } from 'rxjs';
import { WebSearchRpcInvoker } from 'src/app/bar/views/web-search/web-search.rpc-invoker';
import { BrowserExtensionService } from './browser-extension.service';
import { NativeServicesRpcService } from './rpc.service';

@Injectable({
  providedIn: 'root',
})
export class WebSearchService implements WebSearch.Service {
  private readonly isNativeWindow = isNativeWindow();

  constructor(private nativeRpc: NativeServicesRpcService, private extService: BrowserExtensionService) {}

  async getService(): Promise<WebSearch.Service> {
    if (this.isNativeWindow) return this.nativeRpc.invokeWith(WebSearchRpcInvoker, 'websearch');

    const ext = await firstValueFrom(this.extService.current$);
    if (ext) return ext.invokeWith(WebSearchRpcInvoker, 'websearch');

    // TODO: allow the request to be proxied via native app

    return null;
  }

  async search(query: WebSearch.SearchRequest): Promise<WebSearch.Result> {
    const svc = await this.getService();

    return svc?.search(query) || { status: 'skipped', items: [], isCached: false };
  }
}
