// AuthGuard Service
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouterService } from '@shared/services/router.service';
import { isProdEnv } from '@shared/utils';
import { Observable } from 'rxjs';
import { GlobalErrorHandler } from 'src/app/global-error-handler';

@Injectable()
export class ErrorGuard  {
  constructor(private errorHandler: GlobalErrorHandler, private routerService: RouterService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.errorHandler.error || isProdEnv()) {
      this.routerService.navigateByUrl('/');
      return false;
    }
    return true;
  }
}
