import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { InstallSlackComponent } from './install-slack/install-slack.component';

const components = [InstallSlackComponent];

const providers = [];

const imports = [SharedModule];

@NgModule({
  imports: [...imports],
  declarations: [...components],
  exports: [...components],
  providers: [...providers],
})
export class SlackInstallModule {}
