<div class="open-with-prompt">
  <header class="title-container" tabindex="-1">
    <u-icon *ngIf="appIcon" class="title-icon" [model]="{ type: 'img', value: appIcon }"></u-icon>
    <div class="title-text">
      {{ model.title }}
    </div>
  </header>

  <div class="button-selection">
    <u-button
      class="desktop-app-button"
      #markable
      label="Desktop app"
      [type]="openWith === 'app' ? 'primary' : 'secondary'"
      [styles]="{ width: '119px' }"
      (click)="this.onPromptSelect('app', 'mouse_click')"
    >
    </u-button>
    <u-button
      class="browser-button"
      #markable
      label="Browser"
      [type]="openWith === 'browser' ? 'primary' : 'secondary'"
      [styles]="{ width: '117px' }"
      (click)="this.onPromptSelect('browser', 'mouse_click')"
    >
    </u-button>
  </div>
  <div class="checkbox-container">
    <u-checkbox
      #markable
      label="Remember my selection"
      [checked]="setAsDefault"
      [binary]="true"
      inputId="binary"
      (onChange)="toggleSetAsDefault($event)"
    ></u-checkbox>
  </div>
</div>
