<div class="request-wrapper" (click)="clickOutPopup()">
  <div class="request-container" (click)="$event.stopPropagation()">
    <span class="request-title">Request Verification</span>
    <div class="request-textarea">
      <u-textarea
        [styles]="{ width: '260px', height: '96px' }"
        [rows]="4"
        [cols]="49"
        [placeholder]="'What is the reason for verification?'"
        (onChange)="onRequestChange($event)"
        (onFocus)="onFocusTextArea()"
        #textAreaRequest
      ></u-textarea>
    </div>
    <div class="request-buttons">
      <u-button
        [label]="'Cancel'"
        type="secondary"
        (click)="closePopup()"
        [ngClass]="{ focus: currentFocus === 'leftButton' }"
        [elementSize]="'small'"
      ></u-button>
      <u-button
        [label]="'Send a request'"
        type="primary"
        [disabled]="!validRequest"
        (click)="sendRequest()"
        [ngClass]="{ focus: currentFocus === 'rightButton' }"
        [elementSize]="'small'"
      ></u-button>
    </div>
  </div>
</div>
