import { Directive, ElementRef, NgZone } from '@angular/core';

@Directive({
  selector: '[intersect]',
})
export class IntersectDirective {
  constructor(private hostRef: ElementRef, ngZone: NgZone) {
    ngZone.runOutsideAngular(() => {
      const el: HTMLElement = this.hostRef.nativeElement;
      let previousY = 0;
      let previousRatio = 0;
      const thresholdArray = (steps) =>
        Array(steps + 1)
          .fill(0)
          .map((_, index) => index / steps || 0);

      const handleIntersect = (entries: IntersectionObserverEntry[]) => {
        if (entries[0].intersectionRatio <= 0) return;

        entries.forEach((entry) => {
          const currentY = entry.boundingClientRect.y;
          /** Number between 0-1 that represnt the percentage of element visibility (fully in view === 1) */
          const currentRatio = entry.intersectionRatio;
          const isIntersecting = entry.isIntersecting;

          currentRatio < 1 ? el.classList.add('intersects') : el.classList.remove('intersects');

          previousY = currentY;
          previousRatio = currentRatio;
        });
      };

      new IntersectionObserver(handleIntersect, {
        threshold: thresholdArray(20),
      }).observe(el);
    });
  }
}
