export const svgToDataURL = (path: string): Promise<string> => {
  return new Promise((res) => {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.setAttribute('height', '20px');
    canvas.setAttribute('width', '20px');
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    const img = new Image();
    img.src = path;
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      context.drawImage(img, 0, 0, 20, 20);
      const dataURL: string = canvas.toDataURL();
      res(dataURL);
    };
  });
};
