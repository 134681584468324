export type KeyValueParams = { [key: string]: string };

/** Using URLSearchParams is removing duplicates, so we need to parse in ourself and then aggregate
 @description Extracts params from url and return as object */
export const getParams = (url: string) => {
  const search = new URL(url).search.match(/[^\?]+/g);
  if (!search) return {};
  const params = search[0].match(/[^&]+/g);
  if (!params) return {};
  const result: Record<string, string | string[]> = {};

  for (var i = 0; i < params.length; i++) {
    let item = params[i].match(/[^=]+/g);
    const { key, value } = { key: item[0], value: item[1] };

    if (result[key] && Array.isArray(result[key])) (result[key] as Array<string>).push(value);
    else if (result[key] && !Array.isArray(result[key])) result[key] = [value, result[key] as string];
    else result[key] = value;
  }

  return result;
};

export function removeSpecialCharacters(url: string, replaceWith: string = '-') {
  return url?.replace(/[\/\s#%&]/g, replaceWith);
}

export function extractParamsFromUrl(path: string, url: string): KeyValueParams | null {
  const pathParts = path?.split('/')?.filter((part) => part !== ''); // Remove empty parts
  const urlParts = url?.split('/')?.filter((part) => part !== '');

  if (!pathParts?.length || !urlParts?.length) {
    return null; // Not enough parts in the URL to match the path
  }

  const params: KeyValueParams = {};

  urlParts.forEach((part, i) => {
    const pathPart = pathParts[i]?.startsWith(':') ? pathParts[i].slice(1) : pathParts[i];
    params[pathPart] = part;
  });

  return params;
}
