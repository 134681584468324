<div class="specific-resource-wrapper" data-cy="specific-resource-popup">
  <div class="specific-resource-container">
    <div class="specific-resource-header">
      <u-input
        #searchElement
        (onChange)="formInputChanged.next($event)"
        [model]="searchTerm"
        [maxlength]="50"
        [placeholder]="specificResourcePopupContent.placeholder"
        [isSearchInput]="true"
        [inlineExtraIcon]="{ type: 'font', value: 'icon-search-icon-fixed' }"
        inlineExtraIconPos="left"
        [styles]="{ width: '100%' }"
        class="search-input"
        (onEnter)="onEnter()"
        inputSize="medium-large"
        [inputFocus]="componentFocused === specificResourceFocused.SEARCH"
      ></u-input>
    </div>
    <div class="specific-resource-body">
      <search-results-list
        [loading]="loading"
        [displayedContext]="displayedContext$ | async"
        [items]="items$ | async"
        [emptyResults]="emptyResults$ | async"
        (nextPage)="nextPage($event)"
        [componentFocused]="componentFocused === specificResourceFocused.RESULTS"
        (componentFocusedFinish)="componentFocusedFinishResults($event)"
        [listName]="'specific-resource'"
        pageType="assistant"
        [searchId]="searchId"
        [defaultResultSections]="{
          showSubtitleInSecondLine: false,
          showFavoriteAction: false,
          showAddToCollectionAction: false,
          showFeedback: false,
          showFavoriteContextMenuItem: false,
          showAddToCollectionContextMenuItem: false,
          showSummaryIcon: false,
          showContextMenu: false,
          showTags: false,
          showLinkIndicator: false
        }"
        [listView]="{ isEditable: true, limitResults: true }"
        (checkedItemsUpdate)="checkedItemsUpdate($event)"
        [showSearchResults]="false"
        layoutMode="list"
        [clearCheckedItems]="clearCheckedItems"
        [checkboxIsNoColor]="false"
        [checkedItems]="checkedItems"
        [resultListOptions]="{
          checkboxAlwaysVisible: true,
          allowEnterClickMarkCheckbox: true
        }"
      >
      </search-results-list>
    </div>
    <div class="specific-resource-footer">
      <div class="result-details">
        <div class="total-amount">
          {{ checkedItems && checkedItems?.size !== 0 ? checkedItems?.size + ' ' + specificResourcePopupContent.resourcesSelected : ' ' }}
        </div>
        <a class="link-button" *ngIf="checkedItems && checkedItems?.size !== 0" target="_blank" (click)="clearAll()">{{
          specificResourcePopupContent.clearAll
        }}</a>
      </div>

      <div class="specific-resource-btns">
        <u-button [label]="specificResourcePopupContent.cancel" type="secondary" (click)="cancel()"></u-button>
        <u-button
          [label]="specificResourcePopupContent.select"
          [disabled]="(!checkedItems || checkedItems?.size === 0) && !itemsChanged"
          type="primary"
          (click)="save()"
        ></u-button>
      </div>
    </div>
  </div>
</div>
