import { ConnectedPosition } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { observable } from '@local/common';
import { PopupRef, PopupService } from '@local/ui-infra';
import { EventsService } from '@shared/services';
import { ReplaySubject, take } from 'rxjs';
import { AppPopupComponent, AppPopupData } from 'src/app/bar/components/app-popup/app-popup.component';
import {
  VerificationOptionsDetailsComponent,
  VerificationOptionsDetailsModel,
} from 'src/app/bar/components/verifications/verification-options-details/verification-options-details.component';
import { HubService } from 'src/app/bar/services/hub.service';

@Injectable()
export class WikiPopupsService {
  private readonly WARNING_REQUESTS_POPUP_DATA: AppPopupData = {
    message: 'Pending verification requests <br> remain. Would you like to <br> dismiss them before you leave?',
    messageStyle: { fontSize: '14px' },
    showButtons: true,
    rightButtonStyle: { type: 'primary', size: 89, fontSize: 13 },
    leftButtonStyle: { fontSize: 13, size: 89 },
    content: {
      secondaryButton: 'Not now',
      primaryButton: 'Dismiss',
    },
    popupStyle: { padding: '32px 29px 21px 29px' },
  };
  private _onCancelChanges$: ReplaySubject<void> = new ReplaySubject();
  warningRequestsPopupRef: PopupRef<AppPopupComponent, AppPopupData>;
  verificationDetailsPopupRef: PopupRef<VerificationOptionsDetailsComponent, VerificationOptionsDetailsModel> = null;
  verificationStatusPopupRef: PopupRef<AppPopupComponent, AppPopupData> = null;

  @observable
  get onCancelChanges$() {
    return this._onCancelChanges$.asObservable();
  }

  constructor(private popupService: PopupService, private eventsService: EventsService, private hubService: HubService) {}

  changeVerificationStatus(status: 'Verify' | 'Unverify', cardsCount = 1) {
    if (this.verificationStatusPopupRef) {
      this.verificationStatusPopupRef.destroy();
    }
    const position: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' }];
    const relevantMessage =
      cardsCount > 1
        ? `mark <br> these ${cardsCount} cards as ${status === 'Verify' ? 'verified' : 'unverified'}`
        : `${status.toLowerCase()} <br> this card`;
    this.verificationStatusPopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      {
        message: `Are you sure you want to ${relevantMessage}?`,
        showButtons: true,
        rightButtonStyle: { type: status === 'Verify' ? 'green' : 'primary-danger', size: 89 },
        leftButtonStyle: { size: 89 },
        content: {
          secondaryButton: 'Cancel',
          primaryButton: status,
        },
        messageStyle: { fontSize: '14px' },
      },
      { position }
    );
    this.verificationStatusPopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.verificationStatusPopupRef = null;
    });
    this.verificationStatusPopupRef.compInstance.primaryButton.pipe(take(1)).subscribe(() => {
      if (status === 'Verify') {
        this.sendVerifyEvent('verify');
      }
    });
    this.verificationStatusPopupRef.compInstance.secondaryButton.pipe(take(1)).subscribe(() => {
      if (status === 'Verify') {
        this.sendVerifyEvent('cancel');
      }
    });
    return this.verificationStatusPopupRef;
  }

  sendVerifyEvent(target: 'cancel' | 'verify') {
    this.eventsService.event('cards_verifications.verify_card_prompt', {
      location: { title: this.hubService.currentLocation },
      target,
    });
  }

  openWarningRequestsPopup(): PopupRef<AppPopupComponent, AppPopupData> {
    this.warningRequestsPopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      this.WARNING_REQUESTS_POPUP_DATA,
      {
        position: 'center',
        closeOnClickOut: false,
        backdropStyle: 'blur-2',
      }
    );
    this.warningRequestsPopupRef.destroy$.subscribe(() => {
      this.warningRequestsPopupRef = null;
    });
    return this.warningRequestsPopupRef;
  }

  openVerificationDetailsPopup(verificationDetailsModel: VerificationOptionsDetailsModel) {
    if (this.verificationDetailsPopupRef) {
      this.verificationDetailsPopupRef.destroy();
    }
    this.verificationDetailsPopupRef = this.popupService.open<VerificationOptionsDetailsComponent, VerificationOptionsDetailsModel>(
      'center',
      VerificationOptionsDetailsComponent,
      {
        ...verificationDetailsModel,
        updateGroupAndMembers: 'Verifier',
      },
      { closeOnClickOut: true, backdropStyle: 'blur-2', panelClass: 'verification-options-details-popup' }
    );

    this.verificationDetailsPopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.verificationDetailsPopupRef = null;
    });
    return this.verificationDetailsPopupRef;
  }
}
