import { Omnibox } from '@local/client-contracts';
import { SuggestionProvider } from './suggestion-provider';
import { SuggestionsSettings } from '../filters.service';

export class SuggestionsMultipleProviders {
  private providers: SuggestionProvider[];

  get provider() {
    return async (term: string, group: string, context: Omnibox.SuggestionContext, options?: SuggestionsSettings) => {
      const suggestions = [];
      if (group) {
        const split = group.split('-');
        const index = +split.splice(-1)[0];
        group = split.join('-');
        return this.providers[index](term, group, context, options);
      }
      for (let index = 0; index < this.providers.length; index++) {
        const provider = this.providers[index];
        const res = await provider(term, group, context, options);
        for (const item of res) {
          if (item.type === 'group') {
            item.id = `${item.id}-${index}`;
          }
        }
        suggestions.push(...res);
      }
      return suggestions;
    };
  }

  constructor(providers: SuggestionProvider[]) {
    this.providers = providers;
  }
}
