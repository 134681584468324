import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';

const DEFAULT_UNIT: string = '%';
@Component({
  selector: 'u-progress-bar',
  templateUrl: './u-progress-bar.component.html',
  styleUrls: ['./u-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UProgressBarComponent {
  @Input() value: number;
  @Input() showValue: boolean = false;
  @Input() unit: string = DEFAULT_UNIT;
  @Input() mode: ProgressBarMode = 'determinate';
  @Input() styles: any = {};
}
