import { BlobsService } from 'src/app/bar/services/blob.service';
import { WikiCardMediaHandler } from './wiki-card-media-handler';
import { LogService } from '@shared/services';

export class WikiCardImageHandler extends WikiCardMediaHandler {
  constructor(protected blobsService: BlobsService, logService: LogService) {
    super(logService);
  }

  async insertMediaToHtml(html: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const imageElements = doc.querySelectorAll('img');
    const promises = Array.from(imageElements).map(async (img) => {
      if (img.id) {
        try {
          img.src = await this.blobsService.getBlobFullUrl(img.id);
        } catch (error) {
          img.src = '';
        }
      }
    });

    await Promise.all(promises);
    return doc.body.innerHTML;
  }

  removeAllMediaSrc(html: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const imageElements = doc.querySelectorAll('img');
    for (let index = 0; index < imageElements.length; index++) {
      const img = imageElements[index];
      if (img.id) {
        img.src = '';
      }
    }
    return doc.body.innerHTML;
  }
}
