import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { getOS, isNativeWindow } from '@local/common-web';
import { LogService, WindowService } from '@shared/services';
import { last } from '@shared/utils';
import { camelToLowerKebabCase, removeEmpty } from '@local/ts-infra';
import { Logger } from '@unleash-tech/js-logger';

/** This resolver is meant for window-level modules that in order to apply certain metadata to our html
 * Some of the resolvers are depandent on answer form the rpc. We dont want to wait that long, therefore
 * We will use default value and when promise fullfilled we will do the logic.
 */
const env = ['native', 'web'] as const;
type Env = (typeof env)[number];
type ModuleName = `${string}-${Env}`;

interface DocuemntAttributes extends NativeAttributes {
  env: Env;
  moduleName: ModuleName;
}

interface NativeAttributes {
  hasNativeShadow: boolean;
  hasVibrancy: boolean;
}

@Injectable({ providedIn: 'root' })
export class AttributesResolver {
  private logger: Logger;
  private readonly isNativeWindow = isNativeWindow();

  constructor(@Inject(DOCUMENT) private doc: Document, logService: LogService, @Optional() private windowService?: WindowService) {
    this.logger = logService.scope('DocumentAttributesResolver');
  }

  resolve(route: ActivatedRouteSnapshot): Partial<DocuemntAttributes> {
    let { moduleName } = route.data;
    const env = this.isNativeWindow ? 'native' : 'web';

    if (typeof moduleName === 'string' && !env.includes(last(moduleName?.split('-')))) {
      this.logger.error(`Module name ${moduleName} is not valid. It should be ${moduleName}-${env}`);
      moduleName = null;
    }

    const attributes = {
      moduleName,
      env: this.isNativeWindow ? 'native' : 'web',
      os: getOS()?.toLowerCase(),
      hasNativeShadow: false,
      hasVibrancy: false,
    } as DocuemntAttributes;

    const deleteAll = (attr) => Object.keys(attr).forEach((key) => this.doc.body.removeAttribute(camelToLowerKebabCase(key)));

    const addAll = (attr) =>
      Object.entries(removeEmpty(attr)).forEach(([key, value]) => this.doc.body.setAttribute(camelToLowerKebabCase(key), '' + value));

    deleteAll(attributes);
    addAll(attributes);

    const windowAttributes = { hasNativeShadow: '', hasVibrancy: '' };
    if (this.windowService) {
      // Shouldn't be awaited on this will prevent navigation on the top-level
      this.windowService.properties
        .then(
          (properties): DocuemntAttributes => ({
            ...attributes,
            hasNativeShadow: properties.shadow,
            hasVibrancy: properties.vibrancy,
          })
        )
        .then((v) => {
          deleteAll(attributes);
          addAll(v);
        })
        .catch((e) => {
          deleteAll(windowAttributes);

          this.logger.error('error retrieving attributes from winodow', e);
        });
    } else {
      deleteAll(windowAttributes);
    }

    return attributes;
  }

  addAttribute(key: string, value: string) {
    this.doc.body.setAttribute(camelToLowerKebabCase(key), '' + value);
  }
}
