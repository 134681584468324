import { getExtensionByFileName } from '@local/ts-infra';
import { FilePreviewType } from '../../bar/views/preview/file-preview/file-preview-types';

export function getFileType(mimeType: string, fileName?: string): FilePreviewType {
  if (!mimeType) return;
  const type = mimeType.split('/')[0];
  switch (type) {
    case 'image': {
      return 'image';
    }
    case 'audio': {
      return 'audio';
    }
    case 'video': {
      return 'video';
    }
    case 'application': {
      return getExtension(mimeType);
    }
    case 'text': {
      if (mimeType === 'text/plain') {
        if (fileName && overrideTypes.includes(getExtensionByFileName(fileName))) return 'code';
        return 'text';
      }
      if (mimeType === 'text/markdown') {
        return 'text';
      }
      return getExtension(mimeType);
      return mimeType?.includes('html') ? 'html' : 'text';
    }
  }
}

const overrideTypes = ['jsx', 'c', 'py', 'h', 'vb', 'cs'];

const googleAppsTypes = {
  'application/vnd.google-apps.document': 'pdf',
  'application/vnd.google-apps.spreadsheet': 'pdf',
  'application/vnd.google-apps.presentation': 'pdf',
  'application/msword': 'online',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'online',
  'application/vnd.ms-excel': 'online',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'online',
  'application/vnd.ms-powerpoint': 'online',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'online',
};

const types = {
  'application/pdf': 'pdf',
  'application/json': 'code',
  'application/javascript': 'code',
  'application/xhtml+xml': 'code',
  'application/x-python-code': 'code',
  'application/postscript': 'code',
  'application/java-vm': 'code',
  'application/x-httpd-php': 'code',
  'application/x-perl': 'code',
  'application/x-sh': 'code',
  'application/xml': 'code',
  'application/octet-stream': 'code',
  'application/x-php': 'code',
  'application/hta': 'code',
  'application/x-httpd-eruby': 'code',
  'application/x-httpd-php3': 'code',
  'application/x-httpd-php4': 'code',
  'application/x-yaml': 'code',
  'text/javascript': 'code',
  'text/css': 'code',
  'text/x-c': 'code',
  'text/x-sh': 'code',
  'text/xml': 'code',
  'text/html': 'code',
  'text/plain.cs=code': 'code',
  'text/x.typescript': 'code',
  'text/x-python': 'code',
  'text/x-java-source': 'code',
  'text/yaml': 'code',
  'text/x-scss': 'code',
  'text/jsx': 'code',
  'text/coffeescript': 'code',
  'text/less': 'code',
  'text/x-c-code': 'code',
  'text/x-objcsrc': 'code',
  'text/x-python-script': 'code',
  'text/php': 'code',
  'text/x-csharp': 'code',
  'text/x-csrc': 'code',
  'text/texmacs': 'code',
  'text/x-java': 'code',
  'text/x-chdr': 'code',
  'text/x-perl-script': 'code',
  'text/x-ruby-script': 'code',
  'text/csv': 'text',
  ...googleAppsTypes,
};

export function getExtension(mimeType: string) {
  return types[mimeType];
}

export function isGoogleAppsType(mimeType: string): boolean {
  return !!(mimeType && Object.keys(googleAppsTypes).find((k) => k === mimeType));
}

export function getCodeLanguage(name: string): string {
  const language: string = getExtensionByFileName(name);
  const types = {
    crc: 'r',
    phtml: 'html',
    rhtml: 'html',
    jhtml: 'html',
    htm: 'html',
    pyc: 'py',
    class: 'r',
    axd: 'r',
    ashx: 'xml',
    asmx: 'xml',
    aspx: 'xml',
    asp: 'html',
    cfm: 'xml',
    yaws: 'xml',
    jspx: 'xml',
    action: 'r',
    php4: 'php',
    php3: 'php',
    cgi: 'r',
    cake: 'yaml',
    cjsx: 'jsx',
    io: 'html',
    hta: 'html',
  };
  return types[language] || language;
}
