/** On Some OS / Mouse, the event for right click is 'auxclick' */
export const isRightClick = (event: MouseEvent) => {
  if (event.type === 'contextmenu') return true;
  if (event.type === 'auxclick' && event.button === 2) return true;
  return false;
};

export const isMiddleClick = (event: MouseEvent) => {
  if (event.type === 'auxclick' && event.button === 1) return true;
};
