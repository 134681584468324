import { RecentSearches } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class RecentSearchesRpcInvoker implements RecentSearches.Service {
  @invoker
  get current$(): Observable<RecentSearches.Item[]> {
    return;
  }

  @invoker
  add(recentSearch: RecentSearches.Item): Promise<void> {
    return;
  }

  @invoker
  clearAll(): Promise<void> {
    return;
  }
}
