import { Filters, MemorySearch, Search } from '@local/client-contracts';
import { SimpleTextRanker } from '@local/common';
import { LogService } from '@shared/services';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { cloneDeep, mergeWith, union } from 'lodash';
import { combineLatest, isObservable, Observable, of, Subject, Subscription, take } from 'rxjs';
import { SearchResults } from 'src/app/bar/views';
import { SearchResponseType } from '..';
import { CollectionsSearchClient } from '../collections';
import { GoLinksSearchClient } from '../go-links';
import { MemorySearchClient, MemorySearchSettings } from '../memory-search-client/memory-search-client';
import { ResourcesFavoritesSearchClient } from '../resources-favorites/resources-favorites-search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { SearchSourceType } from '../search-source-type';
import { StaticCollectionItemSearchClient } from '../static-collection-items';
import { FavoritesSourceSettings } from './favorites-source-settings';
import { SearchRequestFilters } from '../search-request-filters';

type SearchInstance = {
  clients: MemorySearchClient<MemorySearchSettings>[];
  subscription?: Subscription;
};
export class FavoritesSearchClient extends MemorySearchClient<FavoritesSourceSettings> {
  private instances: { [sessionName: string]: SearchInstance } = {};

  constructor(
    logService: LogService,
    private resourceFavoritesSearchClient: ResourcesFavoritesSearchClient,
    private goLinksSearchClient: GoLinksSearchClient,
    private collectionsSearchClient: CollectionsSearchClient,
    private staticCollectionItemSearchClient: StaticCollectionItemSearchClient,
    memorySearchService: MemorySearchService
  ) {
    super(logService, memorySearchService, ['Alphabetical', 'Time']);
    this.logger = logService.scope('favorites');
  }

  getInput(request: SearchRequest<FavoritesSourceSettings>, response: SearchResponse): Observable<MemorySearch.Item[]> {
    const sources = Object.values(cloneDeep(request.sourceSettings.sources) || {});
    const tasks: SearchResponseType[] = [];
    const innerResponses: Record<string, SearchResponse> = {};
    const instance = (this.instances[request.sessionName] = this.instances[request.sessionName] || { clients: [] });
    if (instance.subscription) {
      instance.subscription.unsubscribe();
      instance.subscription = null;
    }
    const subject: Subject<MemorySearch.Item[]> = new Subject();
    for (const settings of sources) {
      settings.filters = this.mergeAllFilters(request.sourceSettings.filters, settings.filters);
      const client: MemorySearchClient<MemorySearchSettings> = this.getClientSearch(settings.type);
      const hasFilters = Object.keys(settings.filters?.preFilters || {}).length || Object.keys(settings.filters?.postFilters || {}).length;
      if (hasFilters && !client.supportsFilters(settings.filters)) {
        continue;
      }
      const innerResponse = (innerResponses[settings.type] = response.clone());
      instance.clients.push(client);
      tasks.push(client.search({ ...request, sourceSettings: settings }, innerResponse));
    }
    const sorting = request.sourceSettings.sorting;
    Promise.all(tasks).then((observables: Observable<void>[]) => {
      const updates = Object.values(innerResponses || {}).map((s) => s.update$);
      instance.subscription = combineLatest(updates.concat(observables || [])).subscribe(() => {
        const items: MemorySearch.Item[] = Object.values(innerResponses || {})
          .map((r) => r.items)
          .flat(2)
          .map((item) => ({ data: item, searchText: (item.searchTokens || []).join(' '), sortValue: this.getSortValue(item, sorting) }));
        subject.next(items);
      });
    });
    return subject;
  }

  private getClientSearch(type: SearchSourceType): MemorySearchClient<MemorySearchSettings> {
    switch (type) {
      case 'collection':
        return this.collectionsSearchClient;
      case 'go-links':
        return this.goLinksSearchClient;
      case 'resources-favorites':
        return this.resourceFavoritesSearchClient;
      case 'static-collection-items':
        return this.staticCollectionItemSearchClient;
      default:
        this.logger.error('search client not found for SearchSourceType in favorites search', { type });
    }
  }

  private mergeAllFilters(requestFilters: SearchRequestFilters, settingsFilters: SearchRequestFilters) {
    const excldeSet = new Set([...(requestFilters?.excludeFilters || []), ...(settingsFilters?.excludeFilters || [])]);
    return {
      excludeFilters: [...excldeSet],
      preFilters: this.mergeFilters(requestFilters?.preFilters || {}, settingsFilters?.preFilters || {}),
      postFilters: this.mergeFilters(requestFilters?.postFilters || {}, settingsFilters?.postFilters || {}),
    };
  }

  private mergeFilters(obj1: Filters.Values, obj2: Filters.Values) {
    return mergeWith(cloneDeep(obj1), cloneDeep(obj2), (x, y) => union(x, y));
  }

  protected defaultSort(items: MemorySearch.Item[]): MemorySearch.Item[] {
    return items.sort((a, b) => b.data?.favoriteMarkedTime - a.data?.favoriteMarkedTime);
  }

  async getOutput(items: MemorySearch.Item[]): Promise<SearchResults[]> {
    return items.map((i) => i.data);
  }

  protected sortTokens(queryTokens: string[], a: MemorySearch.Item, b: MemorySearch.Item): number {
    if (!queryTokens.length) return a.index - b.index;
    const res = new SimpleTextRanker().rank(queryTokens, a.tokens, b.tokens);
    return res || a.index - b.index;
  }

  destroy(id: number, sessionName: string): void {
    const instance = this.instances[sessionName];
    if (instance) {
      instance.subscription?.unsubscribe();
      instance.clients?.forEach((c) => c.destroy(id, sessionName));
      delete this.instances[sessionName];
    }
  }

  supportsFilters(filters: { preFilters: Filters.Values; postFilters: Filters.Values }): boolean {
    return (
      this.resourceFavoritesSearchClient.supportsFilters(filters) ||
      this.collectionsSearchClient.supportsFilters(filters) ||
      this.goLinksSearchClient.supportsFilters(filters) ||
      this.staticCollectionItemSearchClient.supportsFilters(filters)
    );
  }

  getSortValue(item, sorting: Search.Sort): string {
    let sortValue;
    if (sorting?.by === 'Alphabetical') {
      switch (item?.type) {
        case 'collection':
          sortValue = item?.title;
          break;
        default:
          sortValue = item?.view?.title?.text;
          break;
      }
    } else if (sorting?.by === 'Timestamp') {
      switch (item?.type) {
        case 'collection':
          sortValue = item?.modifiedTime;
          break;
        case 'go-link':
          sortValue = item?.data?.modifiedTime;
          break;
        case 'result':
          sortValue = Date.parse(item?.resource?.traits?.modifiedAt);
          break;
        default:
          break;
      }
    }
    return sortValue;
  }
}
