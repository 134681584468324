import { Injectable } from '@angular/core';
import { Assistants, Chats, Search } from '@local/client-contracts';

@Injectable({
  providedIn: 'root',
})
export class AnswerSearchService {
  insertReferencesIntoText(
    federatedAnswer: Assistants.FederatedAnswer,
    resources: Search.ResultResourceItem[] | Chats.MessageResource[]
  ): string {
    const text = federatedAnswer?.answer;
    if (!text) {
      return;
    }

    const references = federatedAnswer?.references;
    if (!references?.length || (!federatedAnswer.resourceIds?.length && !resources?.length)) {
      return text;
    }

    references.sort((a, b) => b.position - a.position);

    let updatedText = text;
    references.forEach((reference) => {
      const position = reference.position;
      const referencesIds = [...reference.referenceIds].reverse();

      referencesIds.map((referenceId) => {
        let index;
        if (resources?.length) {
          index = resources.findIndex((resource) => resource.id === referenceId || resource.resourceId === referenceId);
        } else {
          index = federatedAnswer.resourceIds.indexOf(referenceId);
        }
        if (index < 0) {
          return;
        }
        updatedText = `${updatedText.slice(0, position)} %%${index + 1}%%${updatedText.slice(position)}`;
      });
    });

    return updatedText;
  }
}
