import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { CollectionsService } from '../../services/collections.service';
import { isEqual } from 'lodash';
import { FiltersService } from '../../services/filters.service';
import { Collections } from '@local/client-contracts';
import { CollectionsUtilService } from '../../services/collections-util.service';
import { HubService } from '../../services/hub.service';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateCollectionGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(
    private collectionsService: CollectionsService,
    private filtersService: FiltersService,
    private collectionsHelperService: CollectionsUtilService,
    private hubService: HubService
  ) {}

  canDeactivate(
    component: CanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (
      this.collectionsService.currentCollectionView?.kind !== 'Live' ||
      !this.collectionsHelperService.canEdit(this.collectionsService.currentCollectionView) ||
      this.hubService.getState('skip-collections-guard')[0] === 'true'
    ) {
      return true;
    }
    const collection = this.collectionsService.currentCollectionView as Collections.LiveCollection;
    const isFiltersChanged = !isEqual(this.filtersService.inlineFilters, collection?.searchParams?.filters ?? {});
    if (isFiltersChanged && !this.collectionsService.isDeleteMode) {
      const deactivateSubject = new Subject<boolean>();
      this.collectionsService.openAreYouSurePopup(deactivateSubject);
      return deactivateSubject.asObservable();
    }
    return true;
  }
}
