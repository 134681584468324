<div class="contiainer">
  <div class="title">{{ content.title }}</div>
  <div class="description">{{ content.description }}</div>
  <div class="filter-container">
    <div class="filter-header">
      <div class="filter-title">
        {{ content.filterHeader }}
      </div>
    </div>
    <div class="filter">
      <link-time-filter (filterChange)="onFilterChange($event)" [app]="app"></link-time-filter>
    </div>
  </div>
  <div class="buttons-container">
    <u-button label="Do it later" type="secondary" [styles]="{ width: '236px' }" (click)="onActionEvent.emit()"> </u-button>
    <u-button
      class="done-button"
      label="Done"
      type="primary"
      (onClick)="onActionEvent.emit(currentFilter)"
      [disabled]="!currentFilter"
      [styles]="{ width: '236px' }"
    >
    </u-button>
  </div>
</div>
