import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UInputComponent } from '../../components/u-input/u-input.component';

@Component({
  selector: 'input-title',
  templateUrl: './input-title.component.html',
  styleUrls: ['./input-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputTitleComponent implements OnInit {
  @Input() text: string;
  @Input() inputPlaceholder: string;
  @Input() defaultInputModel: string;
  @Input() inputModel: string;
  @Input() fontSize: 'large' | 'regular' = 'regular';
  @Input() maxlength: number;
  @Input() set initFocus(val: boolean) {
    if (val) {
      this.showTempTitle = false;
    }
  }
  @ViewChild('resultInputTitle') resultInputTitle: UInputComponent;

  tempInputModel: string;
  showTempTitle: boolean = true;

  @Output() onDisplayInput = new EventEmitter<UInputComponent>();
  @Output() onBlurUInput = new EventEmitter<{ inputElement: UInputComponent; defaultInputModel: string }>();
  @Output() onChange = new EventEmitter<string>();

  constructor(private cdr: ChangeDetectorRef) {}

  @HostListener('window:keydown', ['$event'])
  compOnKeyDown(event): void {
    this.onKeyDown(event);
  }

  ngOnInit(): void {
    this.tempInputModel = this.inputModel;
  }

  onKeyDown(event: KeyboardEvent): void {
    event.stopPropagation();
    if (event.key === 'Enter' || event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      this.blurUInput();
    } else if (this.showTempTitle) {
      if (event.key !== 'ArrowRight') {
        this.tempInputModel = '';
      }
      this.onTextClick(event);
    }
    this.displayInput('keydown');
  }

  onTextClick(event) {
    event.stopPropagation();
    this.showTempTitle = false;
    this.cdr.markForCheck();
    setTimeout(() => {
      this.resultInputTitle?.focusInput();
      if (event.key === 'ArrowRight') {
        this.resultInputTitle?.focusEndInput();
      }
      this.displayInput('mousedown');
    }, 0);
  }

  displayInput(trigger?: 'keydown' | 'mousedown') {
    if (this.resultInputTitle) {
      this.onDisplayInput.emit(this.resultInputTitle);
    }
  }

  blurUInput() {
    this.tempInputModel = null;
    this.onBlurUInput.emit({ inputElement: this.resultInputTitle, defaultInputModel: this.defaultInputModel });
  }

  onChangeInput(event: string) {
    this.onChange.emit(event);
  }
}
