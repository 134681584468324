<div class="walk-through-container" *ngIf="step$ | async as step">
  <div class="walk-through-content">
    <div class="walk-through-dots">
      <div
        *ngFor="let w of [].constructor(maxSteps); let i = index"
        class="dot"
        [ngClass]="{ 'selected-dot': i === stepIndex - 1, 'available-dot': i < stepIndex - 1 || i === stepIndex }"
        (click)="changeStep(i + 1)"
      ></div>
    </div>
    <u-icon
      *ngIf="step.icon"
      [model]="step.icon.model"
      class="walk-through-icon"
      [ngClass]="{ 'walk-through-small-icon': step.icon.size === 'small' }"
    ></u-icon>
    <span *ngIf="step.comment" class="walk-through-comment">{{ step.comment }}</span>
    <span class="walk-through-title">{{ step.title }}</span>
    <div *ngIf="step.subTitle" class="walk-through-subtitle">
      <span [innerHTML]="step.subTitle.section1"> </span>
      <span class="subTitle-shortcut" *ngIf="step.subTitle.showShortcut && shortcut?.length === 2"
        >{{ shortcut[0] | keyIcon }} {{ shortcut[1] }}</span
      >
      <span [innerHTML]="step.subTitle.section2 || ''"></span>
      <span
        class="subTitle-link"
        *ngIf="step.subTitle.link"
        [innerHTML]="step.subTitle.link.text"
        (click)="openUrl(step.subTitle.link.url)"
      ></span>
    </div>
    <span *ngIf="step.action" class="walk-through-action">
      <span *ngIf="step.action.title" class="action-title">{{ step.action.title }}</span>
      <span *ngIf="step.action.subTitle" class="action-subTitle" [innerHTML]="step.action.subTitle"></span>
      <span *ngIf="step.action.link" class="action-link" [ngClass]="{ 'action-link-with-icon': step.action.link?.icon }">
        <u-icon *ngIf="step.action.link.icon" class="action-icon" [model]="step.action.link.icon"></u-icon>
        <span class="action-link-text" (click)="openUrl(step.action.link.url)">{{ step.action.link.text }}</span></span
      ></span
    >
  </div>
  <div>
    <u-button [label]="stepIndex < maxSteps ? 'Next' : 'Done'" (click)="next()" [styles]="{ width: '194px' }"></u-button>
  </div>
</div>
