import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TieredMenu } from 'primeng/tieredmenu';

@Component({
  selector: 'u-tiered-menu',
  templateUrl: './u-tiered-menu.component.html',
  styleUrls: ['./u-tiered-menu.component.scss'],
})
export class UTieredMenuComponent {
  isDisplayed = false;

  @ViewChild(TieredMenu) menu: TieredMenu;

  @Input() items: MenuItem[];
  @Input() popup = false;

  @Input() set showEvent(show: MouseEvent) {
    if (this.menu) {
      if (show?.currentTarget && !this.isDisplayed) {
        this.menu.show(show);
        this.isDisplayed = true;
      } else if (!show && this.isDisplayed) {
        this.menu.hide();
        this.isDisplayed = false;
      }
    }
  }

  @Output() itemClick: EventEmitter<string> = new EventEmitter<string>();

  onClick(event): void {
    const id = event.path[1].id;
    this.itemClick.emit(id);
  }
}
