const preloaded = new Set();
/**
 * preload assets before being used to avoid flicker
 * use `asType=fetch` for non-image resources (i.e `video`)
 */
export function preloadAssets(assets: Array<string>, asType: 'image' | 'fetch' = 'image'): Promise<void> {
  let counter = 0;
  const uniqueAssets = new Set(assets);
  return new Promise((resolve) => {
    uniqueAssets.forEach((asset) => {
      if (preloaded.has(asset)) {
        return;
      }

      const e: HTMLLinkElement = document.createElement('link');
      e.href = asset;
      e.rel = 'preload';
      e.as = asType;
      e.onload = () => {
        counter++;
        if (counter === uniqueAssets.size) {
          resolve();
        }
      };
      e.onerror = (err) => {
        counter++;
        if (counter === uniqueAssets.size) {
          resolve();
        }
        console.warn('Could not preload', { asset, error: err.toString() });
      };
      preloaded.add(asset);
      document.head.appendChild(e);
    });
  });
}
