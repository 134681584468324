import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { PopupRef, UiIconModel } from '@local/ui-infra';
import { Intent } from '../../views/hub/shared/model';
import { ToasterData } from './toaster-data';

@Component({
  selector: 'toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterComponent implements ToasterData, OnInit {
  private readonly TOASTER_TIMEOUT = 5000;

  id: string;
  title: string;
  content: string;
  spinner: boolean;
  icon: UiIconModel;
  irremovable?: boolean;
  buttonText?: string;
  intent?: Intent;
  iconIntent?: Intent;
  label?: string;
  isAnimated: boolean;
  showIndicator: boolean;
  boldTitle: boolean;
  defaultErrorIcon: boolean;

  @Output() dismiss = new EventEmitter<string>();
  @Output() invoke = new EventEmitter<{ id: string; label: string }>();

  @HostBinding('class') get class() {
    return { [this.intent]: true };
  }

  private isDismissed: boolean;

  constructor(private ref: PopupRef<ToasterComponent, ToasterData>) {}

  ngOnInit(): void {
    const {
      irremovable,
      title,
      spinner,
      icon,
      content,
      buttonText,
      id,
      intent,
      iconIntent,
      label,
      isAnimated,
      showIndicator,
      boldTitle,
      defaultErrorIcon,
      timeout,
    } = this.ref.data;
    this.title = title;
    this.content = content;
    this.spinner = spinner;
    this.icon = icon;
    this.irremovable = irremovable;
    this.buttonText = buttonText;
    this.id = id;
    this.intent = intent ?? 'none';
    this.iconIntent = iconIntent ?? 'none';
    this.label = label;
    this.isAnimated = isAnimated;
    this.showIndicator = showIndicator;
    this.boldTitle = boldTitle;
    this.defaultErrorIcon = defaultErrorIcon;

    setTimeout(() => {
      if (!this.isDismissed) {
        this.onDismiss();
      }
    }, timeout || this.TOASTER_TIMEOUT);
  }

  onInvoke(): void {
    this.invoke.emit({ id: this.id, label: this.label });
  }

  onDismiss(): void {
    this.dismiss.emit(this.id);
    this.isDismissed = true;
  }
}
