import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { getOS } from '@local/common-web';
import { PopupRef } from '@local/ui-infra';
import { KeysNavigationComponent } from '@shared/components/keys-navigation.component';
import { EventsService, LogService } from '@shared/services';
import { KeyboardService } from '@shared/services/keyboard.service';
import { RouterService } from '@shared/services/router.service';
import { isKey, isSingleKey, keyCodes, KeyName } from '@local/ts-infra';
import { isElement } from '@shared/utils/elements-util';
import { cloneDeep } from 'lodash';
import { NgScrollbar } from 'ngx-scrollbar';
import { BehaviorSubject, combineLatest, fromEvent, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import {
  isChild,
  isSearchLine,
  ITEMS_HEIGHT,
  SearchPopupData,
  SearchPopupItem,
  SearchPopupItemType,
  SortFunction,
  TelemetryTarget,
} from './model';
import { SearchPopupItemComponent } from './search-popup-item/search-popup-item.component';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { GoLinksService } from 'src/app/bar/services/go-links.service';
import { CollectionsUtilService } from 'src/app/bar/services/collections-util.service';

@Component({
  selector: 'search-popup',
  templateUrl: './search-popup.component.html',
  styleUrls: ['./search-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPopupComponent
  extends KeysNavigationComponent<SearchPopupItem<SearchPopupItemType>, SearchPopupItemComponent>
  implements SearchPopupData, OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(NgScrollbar) public scrollAreaRef: NgScrollbar;
  @ViewChild('resultsContainer') resultsContainerRef: ElementRef;
  @ViewChild('searchInput') searchInputRef: ElementRef;
  @ViewChild('searchInputAutoComplete') searchInputAutocompleteRef: ElementRef;

  @Output() select$ = new EventEmitter<{ item: SearchPopupItem<'child'>; via: TelemetryTarget }>();
  @Output() searchSelect$ = new EventEmitter<TelemetryTarget>();
  sortBy: SortFunction<SearchPopupItem<'parent'>>;
  query$ = new BehaviorSubject<string>('');
  items$: Observable<SearchPopupItem<SearchPopupItemType>[]>;
  placeholder$: Observable<string>;
  autoComplete: string;
  name: string;
  noResults$ = new Subject<boolean>();
  hasUserInteracted = false;
  private viewReady$ = new ReplaySubject<void>(1);
  private readonly destroy$ = new Subject();
  initialized = false;
  telemetryName: string;
  noScrollbar = false;
  readonly MAX_SCROLL_HEIGHT: number = 280;

  @HostBinding('style.opacity') get opacity() {
    return this.initialized ? '1' : '0';
  }

  get query() {
    return this.query$.getValue();
  }

  set query(v: string) {
    this.query$.next(v);
  }

  get supportsTelemetry(): boolean {
    return !!this.eventsService && !!this.routerService && !!this.telemetryName;
  }

  @ViewChild(CdkVirtualScrollViewport) public scrollViewport: CdkVirtualScrollViewport;

  constructor(
    private host: ElementRef,
    private ref: PopupRef<SearchPopupItem<SearchPopupItemType>, SearchPopupData>,
    private ngZone: NgZone,
    logService: LogService,
    cdr: ChangeDetectorRef,
    keyboard: KeyboardService,
    private goLinksService: GoLinksService,
    public collectionsService: CollectionsService,
    private collectionsUtilService: CollectionsUtilService,
    @Optional() private eventsService?: EventsService,
    @Optional() private routerService?: RouterService
  ) {
    super(logService, cdr, keyboard);

    const { placeholder$, sortBy, telemetryName, name } = this.ref.data;
    this.placeholder$ = placeholder$;
    this.sortBy = sortBy;
    this.telemetryName = telemetryName;
    this.name = name;
    this.logger.scope(`${name}Component`);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.ref.data.items$) {
      this.initItems$();
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.scrollArea = this.scrollAreaRef;
    if (this.searchInputRef) {
      this.focusInput();
    }
    if (this.searchInputAutocompleteRef?.nativeElement) {
      fromEvent(this.searchInputAutocompleteRef.nativeElement, 'autocomplete-changed')
        .pipe(takeUntil(this.destroy$))
        .subscribe((ev: CustomEvent<string>) => this.onAutoCompleteChange(ev));
    }
    if (this.searchInputRef?.nativeElement) {
      fromEvent(this.searchInputRef.nativeElement, 'input')
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(() => (this.hasUserInteracted = true));
    }
    this.viewReady$.next();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  private async initItems$() {
    const items = await this.ref.data.items$.pipe(take(1)).toPromise();

    this.items$ = combineLatest([this.ref.data.items$, this.query$]).pipe(
      startWith([items]),
      map(([items]) =>
        this.query
          ? this.handleSearch(items, this.query) // Search Logic
          : this.handleNonSearch(items)
      ),
      map((items) => items.sort(this.sortBy)), // Sort logic (Runs on parents only)
      map((items) => this.flatItems(items)), // Flat array
      map((items) => (this.query ? items : items.filter((i) => i.visibility === 'always' || i.type === 'parent'))), // Filter visibility
      map((items) => {
        if (this.query) {
          const searchItem: SearchPopupItem<'search-line'> = {
            type: 'search-line',
            id: '0',
            icon: { type: 'font-icon', value: 'icon-magnifier' },
            title: 'All search results for',
            visibility: 'search-only',
            command: null,
          };
          items.unshift(searchItem);
        }
        return items;
      }),
      tap((v) => this.onPostSearchItemsUpdated(v)) // Preform any post search tasks, runs on every change
    );
    this.items$.pipe(take(1)).subscribe(() => (this.initialized = true));

    if (this.supportsTelemetry) {
      combineLatest([this.items$, this.query$])
        .pipe(
          takeUntil(this.destroy$),
          distinctUntilChanged(([_1, prevQ], [_2, nextQ]) => prevQ === nextQ), // Report only when the query changes
          filter(([_, q]) => q?.length > 0) // Report only if there is query
        )
        .subscribe(([items, query]) => this.reportSearch(query, (items ?? []).filter((i) => i.type === 'child').length ?? 0));
    }

    combineLatest([this.items$, this.viewReady$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([items]) => {
        const actualPredicatedHeight = items.reduce((total, { type }, index) => {
          // Consider only first parent (will stay sticky)
          if (index === 0 && items.length > 1) {
            total += ITEMS_HEIGHT['parent'];
          }
          if (type === 'child' || type === 'search-line') {
            return (total += ITEMS_HEIGHT[type]);
          }
          return total;
        }, 0);
        this.scrollViewport.setTotalContentSize(actualPredicatedHeight);
        this.scrollAreaRef.nativeElement.style.height = actualPredicatedHeight + 'px';
        const itemsHeight = items.reduce((total, { type }) => {
          return (total += ITEMS_HEIGHT[type]);
        }, 0);
        this.noScrollbar = itemsHeight <= this.MAX_SCROLL_HEIGHT;
      });
  }

  private onPostSearchItemsUpdated(items: SearchPopupItem<SearchPopupItemType>[]) {
    this.items = items;
    const noResults = !items?.length;
    this.noResults$.next(noResults);
    if (noResults) {
      return;
    }
    this.select('first');
  }

  private reportSearch(query: string, resultsCount: number) {
    if (!this.supportsTelemetry) {
      return;
    }
    this.eventsService.event('command_bar.look_up', {
      label: query,
      location: { title: this.routerService.location },
      jsonData: JSON.stringify({ command_bar: { resultsCount } }),
    });
  }

  protected handleKeys(keys: KeyName[], event): void {
    if (this.eventToSelectAction(event)) {
      this.hasUserInteracted = true;
    }

    if (isSingleKey('ArrowUp', keys) && (this.selectedIndex === null || this.selectedIndex === this.firstSelectableIndex)) {
      this.focusInput();
      if (this.selectedIndex === 0) {
        event.stopPropagation();
        return;
      }
    }

    super.handleKeys(keys, event);
    if (event.propagationStopped) {
      return;
    }

    if (isSingleKey('escape', keys) && this.ref) {
      this.ref.close('keyboard');
      event.stopPropagation();
      return;
    }

    if (this.items) {
      if (getOS() === 'MacOS') keys = keys.map((k) => (k = k === 'control' ? 'command' : k));

      const matchingIdx = this.items.findIndex(
        (item) =>
          item?.shortcut &&
          item.shortcut.length === keys.length &&
          item.shortcut.every((key) => keys.map((k) => k.toLowerCase()).includes(key.toLowerCase()))
      );

      if (matchingIdx !== -1 && !keys.includes('enter')) {
        const match = this.items[matchingIdx];
        if (!isChild(match)) {
          return;
        }
        if (this.selectedIndex === matchingIdx) {
          this.emitSelect(match, 'keyboard');
        }
        event.stopPropagation();
        return;
      }
    }

    const selected = this.selectedItem;

    if (isChild(selected)) {
      if (isSingleKey('enter', keys) && this.selectedIndex !== null) {
        if (!selected) {
          event.stopPropagation();
          return;
        }
        this.emitSelect(selected, 'keyboard');
        event.stopPropagation();
        return;
      }

      if (isSingleKey('tab', keys)) {
        this.emitSelect(selected, 'keyboard');
        event.stopPropagation();
        return;
      }
      if (isSingleKey('ArrowRight', keys) && this.autoComplete) {
        event.stopPropagation();
        return;
      }
    }

    if (isSearchLine(selected)) {
      if (isSingleKey('enter', keys) && this.selectedIndex !== null) {
        if (!selected) {
          event.stopPropagation();
          return;
        }
        this.onSearch(selected, 'keyboard');
        event.stopPropagation();
        return;
      }
    }

    if (isElement(event.target) && !(this.host?.nativeElement as HTMLElement)?.contains(event.target)) {
      event.stopPropagation();
      return;
    }
    event.stopPropagation();
    return;
  }

  emitSelect(item: SearchPopupItem<'child'>, via: TelemetryTarget) {
    if (this.supportsTelemetry && item.data?.context?.resource?.params?.highlights?.length <= 1) {
      this.eventsService.event('command_bar.commands', {
        location: { title: this.routerService.active },
        target: via,
        //@ts-ignore
        label: item.title.toLocaleLowerCase().split(':')[0]?.replaceAll(' ', '_'),
        resources: [{ appId: item.keywords[0], linkId: item.data.context.linkId }],
        jsonData: JSON.stringify({ commands: { location: 'command_bar' } }),
      });
    }
    if (item.id === 'local-action-create-go-link') {
      this.goLinksService.openPopup();
      this.ref.close(via);
    } else if (item.id === 'local-action-create-new-collection') {
      this.collectionsService.openCollectionView({ kind: 'Static' });
      this.ref.close(via);
    } else if (item.id === 'local-action-create-new-live-collection') {
      this.collectionsService.openCollectionView({ kind: 'Live' });
      this.ref.close(via);
    } else if (item.id === 'local-action-create-new-wiki-collection') {
      this.collectionsService.openCollectionView({ kind: 'Wiki' });
      this.ref.close(via);
    } else if (item.id === 'local-action-create-new-card') {
      this.collectionsUtilService.openWikiCard();
      this.ref.close(via);
    } else {
      this.select$.emit({ item, via });
    }
  }

  onItemClick(event: MouseEvent, item: SearchPopupItem<'child'>) {
    if (this.supportsTelemetry) {
      this.eventsService.event('command_bar.click', {
        location: { title: this.routerService.active },
        name: this.telemetryName,
        //@ts-ignore
        label: item.title.toLowerCase().replaceAll(' ', '_'),
        jsonData: JSON.stringify({ action: { trigger: 'command_bar' } }),
      });
    }

    this.emitSelect(item, 'mouse_click');
  }

  trackItem: TrackByFunction<SearchPopupItem<SearchPopupItemType>> = (index: number, item: SearchPopupItem<SearchPopupItemType>): string =>
    `${item.id}_${item.type}_${index}`;

  flatItems(items: SearchPopupItem<SearchPopupItemType>[]): Exclude<SearchPopupItem<SearchPopupItemType>[], 'children'> {
    let flat: Exclude<SearchPopupItem<SearchPopupItemType>[], 'children'> = [];
    for (const item of items) {
      flat.push(item);
      if (item.children) {
        flat = flat.concat(item.children);
      }
    }
    return flat;
  }

  isSelectable(item: SearchPopupItem<SearchPopupItemType>): boolean {
    return item.type === 'child' || item.type === 'search-line';
  }

  onKeyDown(event: KeyboardEvent): void {
    if (isKey(event, keyCodes.ArrowRight) && this.autoComplete) {
      this.query = this.autoComplete;
    }
  }

  onAutoCompleteChange(ev: CustomEvent<string>) {
    if (!ev) return;
    this.autoComplete = ev.detail;
  }

  focusInput() {
    if (!this.searchInputRef) {
      return;
    }
    setTimeout(() => (this.searchInputRef.nativeElement as HTMLInputElement).focus(), 0);
  }

  /** Remove items that appears on search only, and sort the children of each group A-Z */
  handleNonSearch(items: SearchPopupItem<SearchPopupItemType>[]): SearchPopupItem<SearchPopupItemType>[] {
    return items
      .filter((i) => (i.children ?? []).some((i) => i.visibility === 'always'))
      .map((parent) => {
        // Custom sort function
        if (parent.children?.length && typeof parent.sortBy === 'function') {
          parent.children = parent.children.sort(parent.sortBy);
        }

        // Default A-Z sorting
        if (parent.sortBy === 'default') {
          parent.children = parent.children.sort((a, b) => a.title.localeCompare(b.title));
        }

        return parent;
      }); // Dont show header is all kids are on search only
  }

  /** @description filter the results that includes the search term, sorted by the term position in the text, also enrich the items with highlights */
  handleSearch(items: SearchPopupItem<SearchPopupItemType>[], term: string): SearchPopupItem<SearchPopupItemType>[] {
    return this.ngZone.runOutsideAngular(() => {
      if (!term) return items;
      items = cloneDeep(items);

      for (const group of items) {
        if (!group.children?.length) continue;

        const suggestions = group.children;

        /** Returns array of all the string to search in (currently title & subtitle) */
        const getSearchableProperties = ({ title, subtitle, keywords }: SearchPopupItem<SearchPopupItemType>): string[] => {
          const searchableSubtitle = subtitle
            ? typeof subtitle === 'string'
              ? subtitle
              : subtitle.map((b) => b.title).join(' ')
            : undefined;

          return [title ?? '', searchableSubtitle ?? '', ...(keywords ?? [])]; // return order is important
        };

        group.children = suggestions
          .map<{ item: SearchPopupItem<'child'>; matches: boolean; highlights: string[] }>((item) => {
            let match = false;
            let highlights = [];
            const regex = new RegExp(term.replace(/(\S+)/g, (s) => '\\b(' + s + ')(.*)').replace(/\s+/g, ''), 'gi');

            for (const property of getSearchableProperties(item)) {
              const res = regex.exec(property);
              if (!res) {
                continue;
              }

              if (!match) {
                match = true;
              }

              highlights = [...highlights, ...(res ?? []).filter((c, i) => i % 2 !== 0)];
            }

            return {
              item,
              matches: match,
              highlights,
            };
          })
          .filter(({ matches }) => matches)
          .map(({ item, highlights }) => ({ ...item, highlights }))
          .sort((a, b) => {
            // Sort results by matching name with keyword position in name
            const [c, d] = [getSearchableProperties(a).join(' '), getSearchableProperties(b).join(' ')];
            if (c.toLowerCase().indexOf(term.toLowerCase()) > d.toLowerCase().indexOf(term.toLowerCase())) {
              return 1;
            } else if (c.toLowerCase().indexOf(term.toLowerCase()) < d.toLowerCase().indexOf(term.toLowerCase())) {
              return -1;
            } else {
              if (c > d) return 1;
              else return -1;
            }
          });
      }
      items = items.filter((i) => i.children?.length); // Remove parent with no children
      return items;
    });
  }

  onSearch(item: SearchPopupItem<'search-line'>, via: TelemetryTarget = 'mouse_click') {
    this.routerService.navigateByUrl(`search?q=${this.query}`);
    this.searchSelect$.emit(via);
  }
}
