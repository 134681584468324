import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Mail } from '@local/client-contracts';
import { observable } from '@local/common';
import { isEmbed, isNativeWindow } from '@local/common-web';
import { DynamicComponentBase, PopupRef, UiIconModel } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EmbedService } from '@shared/embed.service';
import { LoaderService } from '@shared/loader.service';
import { WindowService } from '@shared/services';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { isEnterKey, KeyName } from '@local/ts-infra';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResultCommandService } from 'src/app/bar/services/commands/result-command.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { ResultPopupData } from 'src/app/bar/services/preview.service';

@UntilDestroy()
@Component({
  selector: 'mail-preview-popup',
  templateUrl: './mail-preview-popup.component.html',
  styleUrls: ['./mail-preview-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailPreviewPopupComponent implements OnInit, OnDestroy, DynamicComponentBase<any> {
  readonly closeIcon: UiIconModel = {
    type: 'font',
    value: 'icon-Windows-close',
  };

  isLauncher = false;
  current: Mail.Mail;
  private keyHandlerId: string;
  private _error$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  windowMode = false;
  private ref: PopupRef<MailPreviewPopupComponent, ResultPopupData>;
  model: ResultPopupData;
  data: ResultPopupData;
  componentFather: any;
  @Output() loaded = new EventEmitter<any>();

  @observable
  get error$(): Observable<boolean> {
    return this._error$;
  }

  embedInline: boolean;
  isEmbed: boolean = isEmbed();

  constructor(
    private cdr: ChangeDetectorRef,
    private hubService: HubService,
    private keyboardService: KeyboardService,
    private resultCommandService: ResultCommandService,
    private activeRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private injector: Injector,
    private windowService: WindowService,
    private embedService: EmbedService
  ) {
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys: Array<KeyName>, event) => this.handleKeys(keys, event), 9);
  }

  async ngOnInit() {
    this.windowMode = this.activeRoute.snapshot.data.windowMode;

    if (this.windowMode) {
      this.setEmbedInline();
      this.loaderService.ready$.next(true);
      this.model = this.data;
    } else {
      this.ref = this.injector.get(PopupRef);
      this.model = this.ref.data;
    }

    this.hubService.isLauncher$.pipe(untilDestroyed(this)).subscribe((l) => (this.isLauncher = l));
    this._error$.next(null);
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
  }

  async setEmbedInline() {
    if (this.isEmbed) {
      this.embedInline = await this.embedService?.isInline();
    }
  }

  closePopUp() {
    this.ref?.destroy();
    if (this.windowMode) {
      if (isNativeWindow()) this.windowService.close();
      else if (this.isEmbed) this.embedService.close();
    }
  }

  openUrl() {
    this.resultCommandService.executeCommand(
      this.model.item.view.title.onClick,
      { item: this.model.item, searchId: this.model.searchId },
      'result',
      {
        target: 'keyboard',
        location: { title: this.hubService.currentLocation },
      }
    );
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent) {
    const key = keys[0];
    if (key === 'escape') {
      this.ref.destroy();
      this.keyboardService.stopListening = true;
      event.stopPropagation();
      event.preventDefault();

      return;
    }
    if (isEnterKey(key)) {
      this.openUrl();
    }
  }
}
