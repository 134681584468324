import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PopupRef } from '@local/ui-infra';
import { AnswerResourcesService } from 'src/app/bar/services/answer-resources.service';
import { ChatResourcesService } from '../../../chat-page/services/chat-resources.service';
import { Search } from '@local/client-contracts';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface ResourcePanelPopupData {
  resourceId: string;
  answerType: string;
  position: PointerEvent;
}

@UntilDestroy()
@Component({
  selector: 'resource-panel-popup',
  templateUrl: './resource-panel-popup.component.html',
  styleUrl: './resource-panel-popup.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcePanelPopupComponent implements OnInit, AfterViewInit {
  private readonly SPACE_X = 14;
  private readonly SPACE_Y = 25;
  resourceId: string;
  answerType: string;
  model: Search.ResultResourceItem;

  constructor(
    private ref: PopupRef<ResourcePanelPopupComponent, ResourcePanelPopupData>,
    private answerResourcesService: AnswerResourcesService,
    private chatResourcesService: ChatResourcesService
  ) {}

  ngAfterViewInit(): void {
    const cdk = document.getElementsByClassName('cdk-overlay-container')[0];

    cdk.addEventListener('mousemove', (event) => {
      if (!this.ref) {
        return;
      }
      const positionX = this.ref.data.position['clientX'];
      const positionY = this.ref.data.position['clientY'];
      const eventPositionX = event['clientX'];
      const eventPositionY = event['clientY'];

      if (
        eventPositionY < positionY - this.SPACE_Y ||
        eventPositionX < positionX - this.SPACE_X ||
        eventPositionX > positionX + this.SPACE_X ||
        eventPositionY > positionY + this.SPACE_Y
      ) {
        this.closePopup();
      }
    });
  }

  ngOnInit() {
    this.resourceId = this.ref.data.resourceId;
    this.answerType = this.ref.data.answerType;
    if (!this.resourceId) {
      return;
    }
    if (this.answerType === 'result-answer') {
      this.model = this.answerResourcesService.getResourceById(this.resourceId);
    } else if (this.answerType === 'chat-answer') {
      this.chatResourcesService
        .getResourcesByIds$([this.resourceId])
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          this.model = res[0];
        });
    }
  }

  closePopup() {
    this.ref?.destroy();
    this.ref = null;
  }
}
