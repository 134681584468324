import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  AfterViewInit,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  NgZone,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { KeyName, isEnterKey } from '@local/ts-infra';
import { PopupRef, UiIconModel } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeyboardHelperService } from '@shared/helper/keyboard-helper.service';
import { KeyboardService } from '@shared/services/keyboard.service';
import { windowSizeObserver } from '@shared/utils';
import { NgScrollbar } from 'ngx-scrollbar';
import { CollectionItem, ScrollService } from 'src/app/bar/views/results/services/scroll.service';

export type PopupOptionType = 'navigation' | 'filter' | 'custom';

export type PopupOption = {
  label: string;
  value: string;
  type: PopupOptionType;
  icon: UiIconModel;
  id?: string;
};

export type OptionsPopupData = {
  title?: string;
  options: PopupOption[];
};

type PopupOptionScroll = CollectionItem & { item: PopupOption };
@UntilDestroy()
@Component({
  selector: 'options-popup',
  templateUrl: './options-popup.component.html',
  styleUrls: ['./options-popup.component.scss', '../avatar-popup/avatar-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsPopupComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() close = new EventEmitter();
  @Output() invoke = new EventEmitter<PopupOption>();

  readonly itemSize: number = 35;
  private scrollService: ScrollService<PopupOptionScroll>;
  private keyHandlerId: string;
  private keyboardHelperService: KeyboardHelperService;
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
  @ViewChild(CdkVirtualScrollViewport) scrollViewport: CdkVirtualScrollViewport;
  @ViewChildren('popupOptionScrollItem', { read: ElementRef }) scrollElements: QueryList<ElementRef>;

  avatarPopupData: OptionsPopupData;
  selectedIndex = 0;
  scrollItems: PopupOptionScroll[];
  scrollHeight: number;

  get scrollViewEl(): HTMLElement {
    return this.scrollViewport?.elementRef?.nativeElement;
  }

  constructor(
    private ref: PopupRef<OptionsPopupComponent, OptionsPopupData>,
    private cdr: ChangeDetectorRef,
    private keyboardService: KeyboardService,
    protected ngZone: NgZone
  ) {}

  ngOnInit() {
    this.initData();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.iniScrollHeight();
      this.initKeyboard();
      this.initScrollService();
      windowSizeObserver()
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.iniScrollHeight();
        });
    }, 0);
  }

  ngOnDestroy() {
    this.scrollService?.destroy();
    if (this.keyHandlerId) this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
  }

  private initData() {
    this.avatarPopupData = this.ref.data;
    this.scrollItems = this.avatarPopupData.options
      .filter((op) => op)
      .map((r, i) => {
        return { displayIndex: i, item: r };
      });
    this.selectedIndex = 0;
  }

  private iniScrollHeight() {
    const maxHeight = window.innerHeight - this.scrollViewEl?.getBoundingClientRect()?.top;
    this.scrollHeight = this.scrollItems.length * this.itemSize + 6;
    if (this.scrollHeight > maxHeight) {
      this.scrollHeight = maxHeight;
    }
    this.cdr.markForCheck();
  }

  private initScrollService() {
    this.scrollService.items = this.scrollItems;
    this.scrollService.itemHeights = Array(this.scrollItems.length).fill(this.itemSize);
    this.scrollService.init(this.scrollbarRef, this.scrollElements, this.scrollViewport);
    if (!this.scrollViewport?.elementRef?.nativeElement) {
      this.scrollService?.destroy();
    }
    this.cdr.markForCheck();
  }

  private initKeyboard() {
    this.scrollService = new ScrollService(this.ngZone);
    this.keyboardHelperService = new KeyboardHelperService();
    this.keyboardHelperService.onInit(this.selectedIndex, this.scrollItems, this.scrollService);
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => {
      this.keysHandler(keys, event);
      this.keyboardHelperService.handleArrows(keys, event);
    }, 10);
  }

  private keysHandler(keys: Array<KeyName>, event): void {
    const key = keys[0];
    if (isEnterKey(key)) {
      this.onInvoke(this.scrollItems[this.selectedIndex].item);
    }
    switch (key) {
      case 'ArrowDown':
        this.selectedIndex < this.scrollItems?.length - 1 ? (this.selectedIndex += 1) : null;
        break;
      case 'ArrowUp':
        this.selectedIndex > 0 ? (this.selectedIndex -= 1) : null;
        break;
      case 'escape':
        this.ref.destroy();
        break;
    }
    event.stopPropagation();
    this.cdr.markForCheck();
  }

  onInvoke(item: PopupOption) {
    this.invoke.emit(item);
  }
}
