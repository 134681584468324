import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '@environments/config';
import { LogService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';
import { Observable } from 'rxjs';

@Injectable()
export class ImageService {
  private logger: Logger;
  private readonly imageStorage = Config.uploadCare;

  constructor(log: LogService, private http: HttpClient) {
    this.logger = log.scope('ImageService');
  }

  public uploadImage(image: File): Observable<any> {
    const formData = new FormData();

    formData.append('file', image);
    formData.append('UPLOADCARE_PUB_KEY', this.imageStorage.key);
    formData.append('UPLOADCARE_STORE', '1');

    try {
      return this.http.post(this.imageStorage.api, formData);
    } catch (error) {
      this.logger.error('got error while trying to upload image', { error });
      throw error;
    }
  }

  getImageUrl(imageKey: string) {
    return `${this.imageStorage.cdnUrl}${imageKey}/`;
  }
}
