import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  TrackByFunction,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Accounts, Applications, Fyis, Groups, Links } from '@local/client-contracts';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventsService } from '@shared/services';
import { AccountsService } from '@shared/services/accounts.service';
import { ApplicationsService } from '@shared/services/applications.service';
import { GroupsService } from '@shared/services/groups.service';
import { LinksService } from '@shared/services/links.service';
import { OAuthWindowCollection } from '@shared/services/oauth-windows/oauth-windows-collection';
import { RouterService } from '@shared/services/router.service';
import { ServicesRpcService, componentServicesRpcProvider } from '@shared/services/rpc.service';
import { SyncsService } from '@shared/services/syncs.service';
import { equal, removeKey } from '@shared/utils';
import { Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { WorkspacesService } from 'src/app/bar/services';
import { HubService } from 'src/app/bar/services/hub.service';
import { GlobalErrorHandler } from 'src/app/global-error-handler';
import { LinkCommand, LinkItemComponent, isLinkDeleteCommand, isLinkRefreshCommand, isLinkUpdateCommand } from '../link-item';

export interface LinkFullDisplayItem extends Links.DisplayItem {
  groups?: Groups.WorkspaceGroup[];
  accounts?: Accounts.WorkspaceAccount[];
}
@UntilDestroy()
@Component({
  selector: 'app-item',
  templateUrl: './app-item.component.html',
  styleUrls: ['./app-item.component.scss', '../shared.scss'],
  providers: [componentServicesRpcProvider],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppItemComponent implements OnInit, OnDestroy {
  private readonly _tooltipDisabledConnect: string = 'Please contact your workspace administrator to connect this app';
  @ViewChildren('linkItem') linkComps: QueryList<LinkItemComponent>;
  appId: string;
  links: LinkFullDisplayItem[];
  model: Applications.DisplayItem;
  description: Applications.Description;
  usedColors: string[];
  isEditMode: boolean;
  editableIndex: number;

  isLoading: boolean;
  error: any;
  lastCommand: LinkCommand;
  destroy$ = new Subject();
  emptyState: boolean;
  isOwnerOrAdmin = false;
  private latestSyncLinks = new Map<string, Fyis.Sync>();
  private linksSyncsReady: boolean;

  get isDisableApp(): boolean {
    const disableByWorkSpace = this.model.state.workspaceDisabled && !this.isOwnerOrAdmin;
    return this.model.state.disabled || disableByWorkSpace;
  }

  get tooltipDisabledConnect() {
    return this.isDisableApp ? this._tooltipDisabledConnect : '';
  }

  constructor(
    private services: ServicesRpcService,
    private applicationsService: ApplicationsService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private routerService: RouterService,
    private eventsService: EventsService,
    private linksService: LinksService,
    private barService: HubService,
    private errorHandler: GlobalErrorHandler,
    private oauthWindows: OAuthWindowCollection,
    private groupsService: GroupsService,
    private accountService: AccountsService,
    private workspaceService: WorkspacesService,
    private syncsService: SyncsService
  ) {
    this.appId = this.route.snapshot.params.appId;
  }

  async ngOnInit() {
    this.barService.readOnly = true;
    this.initSubscriptions();

    await this.applicationsService.all();
    this.applicationsService.getDescription(this.appId).then((description) => {
      this.description = description;
      this.cdr.markForCheck();
    });
    this.workspaceService.ownerOrAdmin$.pipe(untilDestroyed(this)).subscribe((s) => {
      this.isOwnerOrAdmin = s;
    });
  }

  async initSubscriptions() {
    this.isLoading = true;
    this.appId = this.route.snapshot.params.appId;
    const published = this.route.snapshot.queryParams.published === 'true';

    combineLatest([
      this.applicationsService.one$(this.appId, published),
      this.linksService.byApp$(this.appId),
      this.groupsService.all$,
      this.accountService.all$,
    ])
      .pipe(
        takeUntil(this.destroy$),
        filter(([nextApp, nextLinks]) => {
          if (!this.model || !nextApp) return true;
          const appEqual = equal(removeKey(this.model, 'links'), removeKey(nextApp, 'links'));
          if (!appEqual) return true;
          const linksEqual = equal(this.links, nextLinks);
          return !appEqual || !linksEqual;
        })
      )
      .subscribe(([app, links, groups, accounts]) => this.onUpdate(app, links, groups, accounts));
  }

  ngOnDestroy(): void {
    this.services.destroy();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private setGroupsAndAccounts(groupsList: Groups.WorkspaceGroup[], accountsList: Accounts.WorkspaceAccount[]) {
    for (const link of this.links) {
      if (!link.shareOptions) continue;

      link.groups = [];
      for (const groupId of link.shareOptions?.groupIds || []) {
        const group: Groups.WorkspaceGroup = groupsList?.find((g) => g.id === groupId);
        if (group) link.groups.push(group);
      }
      link.accounts = [];
      for (const accountId of link.shareOptions?.accountIds || []) {
        const account: Accounts.WorkspaceAccount = accountsList?.find((a) => a.id === accountId);
        if (account) link.accounts.push(account);
      }
    }
  }

  private onUpdate(
    app: Applications.DisplayItem,
    links: Links.DisplayItem[],
    groups: Groups.WorkspaceGroup[],
    accounts: Accounts.WorkspaceAccount[]
  ) {
    this.model = { ...(this.model || {}), ...app };
    this.links = links;
    this.getLinksSyncs();
    this.setGroupsAndAccounts(groups, accounts);
    this.emptyState = !links.length;
    this.usedColors = this.links.map((l) => l.color);
    this.isLoading = false;
    this.cdr.markForCheck();
  }

  async getLinksSyncs() {
    if (this.linksSyncsReady) {
      return;
    }
    this.linksSyncsReady = true;
    const linkIds = this.links.map((link) => link.id);
    const latestSyncs = await this.syncsService.getLatestSync(linkIds);

    this.links.forEach((link) => {
      const lastSync = latestSyncs.find((s) => s?.linkId === link.id);
      this.latestSyncLinks.set(link.id, lastSync);
    });
    this.cdr.markForCheck();
  }

  goBack() {
    if (this.isEditMode) {
      this.linkComps.get(this.editableIndex).setEditStyle(false);
      return;
    }
    this.barService.goBack();
  }

  addLink(): void {
    let oauthSessionId: string;
    if (this.isDisableApp) return;

    this.eventsService.event('app_actions', {
      location: { title: 'apps' },
      target: 'add_link',
      label: this.appId,
    });
    this.routerService.navigate(['connect', this.appId, 'new'], {
      queryParams: {
        source: `${this.appId}-links`,
        oauthSessionId: oauthSessionId,
        published: this.model.published,
      },
    });
  }

  refreshLink(linkId: string, key: string, isRlp: boolean) {
    this.routerService.navigate(['connect', this.appId, 'new'], {
      queryParams: {
        linkId,
        key,
        isRlp,
      },
    });
  }

  trackLink: TrackByFunction<Links.DisplayItem> = (index: number, item: Links.DisplayItem): string => `${item.id}_${index}`;

  retryInvoke(linkOperation: LinkCommand) {
    this.error = undefined;
    this.errorHandler.clear();
    this.onInvoke(linkOperation);
  }

  async onInvoke(command: LinkCommand) {
    this.lastCommand = command;
    const { id } = command;
    const idx = this.links.findIndex((l) => l.id === id);
    try {
      this.isLoading = true;
      this.cdr.detectChanges();
      let target: string;

      if (isLinkUpdateCommand(command)) {
        await this.linksService.update(id, command.payload);
        target = 'edit_link';
      }

      if (isLinkDeleteCommand(command)) {
        this.linksService.delete(id);
        this.links = this.links.filter((elm) => elm.id !== id);
        if (!this.links.length) {
          this.emptyState = true;
          this.isLoading = false;
          this.cdr.detectChanges();
        }
        target = 'disconnect';
      }

      if (isLinkRefreshCommand(command)) {
        this.refreshLink(id, command.key, command.payload.resourcePermissions?.enabled);
        target = 'refresh';
      }

      this.eventsService.event(`link_action.${target}`, { target, label: this.appId, location: { title: this.routerService.location } });

      this.lastCommand = undefined;
    } catch (e) {
      this.error = e;
      this.errorHandler.error = e;
    } finally {
      this.isLoading = false;
      this.setEditMode(false, idx);
      this.cdr.markForCheck();
    }
  }

  setEditMode(isEdit: boolean, i: number) {
    let anyEdit: boolean;
    this.linkComps.forEach((c, index) => {
      if (c.isPaletteOpen) {
        anyEdit = true;
      }
      if (isEdit && c.isPaletteOpen && index !== i) c.setPaletteState(false);
    });
    this.barService.fullFocus = !anyEdit;
    this.isEditMode = !!anyEdit;
    this.editableIndex = i;
    this.cdr.markForCheck();
  }
}
