import { Experiences, NavTree, Search } from '@local/client-contracts';
import { IconAdjustmentsOptions } from '../icon-adjustments-options';
import { SourceSettings } from '../source-settings';

export interface LinkResourcesSourceSettings extends SourceSettings {
  type: 'link-resources';
  requestMaxCount: number;
  tag?: string;
  localMaxCount?: number;
  cloudMaxCount?: number;
  debounce?: number;
  aggregations?: string[];
  localOnly?: boolean;
  iconAdjustments?: IconAdjustmentsOptions;
  groupsOptions?: Search.GroupsOptions;
  groupsFilters?: Experiences.GroupsFilterValues[];
  contentSearch?: boolean;
  advancedSearch?: boolean;
  node?: NavTree.Node;
  groupsFirst?: boolean;
  disableCloudGroup?: boolean;
  caching?: Search.SearchCache;
  useSourceFilters?: boolean;
  disableAggregations?: boolean;
  ignoreDateHeaders?: boolean;
  sessionId?: string;
  lastSyncTimes?: boolean;
  shortSnippet?: boolean;
  forceFooter?: boolean;
  preventRTF?: boolean;
  includeHiddenLinks?: boolean;
}
