import { Injectable } from '@angular/core';
import { Ai } from '@local/client-contracts';
import { Logger } from '@unleash-tech/js-logger';
import { Observable } from 'rxjs';
import { AiServiceRpcInvoker } from './invokers/ai-rpc-invoker';
import { LogService } from './log.service';
import { ServicesRpcService } from './rpc.service';

@Injectable({
  providedIn: 'root',
})
export class AiService {
  private service: Ai.Service;
  private logger: Logger;

  constructor(services: ServicesRpcService, log: LogService) {
    this.logger = log.scope('AiService');
    this.service = services.invokeWith(AiServiceRpcInvoker, 'aiservice');
  }

  summarize$(req: Ai.SummarizeRequest): Observable<Ai.SummarizeResponse> {
    return this.service.summarize$(req);
  }
}
