import { ConnectedPosition } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { EMOJI_BG_IMAGE_URL, EMOJI_SET } from '../u-emoji.consts';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SpriteCssEmojiPosition } from '../../../types/emoji-sprite-position';
import { IStyleService, STYLE_SERVICE } from '../../../injectionToken/style.service.interface';
import { Scheme } from '../../../types/scheme';
import { PopupRef } from '../../../services/popup/popup-ref';
import { UEmojiWrapperComponent } from '../u-emoji-picker-wrapper/u-emoji-picker-wrapper.component';
import { PopupService } from '../../../services/popup/popup.service';
import { take } from 'rxjs';
import { UiIconModel } from '../../../types/ui.icon.model';

declare type PickerButtonType = 'font' | 'emoji';
@UntilDestroy()
@Component({
  selector: 'u-emoji-picker',
  templateUrl: './u-emoji-picker.component.html',
  styleUrls: ['./u-emoji-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UEmojiPickerComponent {
  private _unicode: string;
  @Input()
  public set emojiUnicode(value: string) {
    this._unicode = value;
    this.pickerButtonType = value ? 'emoji' : 'font';
    this.cdr.markForCheck();
  }
  public get emojiUnicode(): string {
    return this._unicode;
  }
  @Input()
  public set emojiSprite(value: SpriteCssEmojiPosition) {
    this.sheet = value;
    this.pickerButtonType = value ? 'emoji' : 'font';
    this.cdr.markForCheck();
  }
  @Input() model: UiIconModel = { type: 'font', value: 'icon-emoji-smile' };
  @Input() placeholderColor = 'var(--color-text-primary)';
  @Input() placeholderFontSize = '18px';
  @Input() nativePrefix: string;
  @Input() title = 'Pick your emoji…';
  @Input() spriteEmojiSize = 24;
  @Input() closeOnSelect = false;
  @Input() autoFocus = true;
  @Input() color = 'var(--color-primary)';
  @Input() pickerEmojiSize = 24;
  @Input() darkMode: boolean;
  @Input() replacePlaceholderOnSelect = true;
  @Input() backgroundImageFn: string = EMOJI_BG_IMAGE_URL; //? for now we supports apple emojis only
  @Output() selectedEmojiSprite = new EventEmitter<SpriteCssEmojiPosition>();
  @Output() selectedEmoji = new EventEmitter<EmojiData>();
  @Output() all = new EventEmitter<SpriteCssEmojiPosition[]>();
  @Output() click = new EventEmitter<void>();
  @Output() popupStatus = new EventEmitter<boolean>();

  @Input() readonly = false;

  theme: Scheme;
  sheet: SpriteCssEmojiPosition;
  pickerButtonType: PickerButtonType = 'font';
  pickerPosition: { top: string; left: string };
  private _selected: EmojiData;
  private _all: SpriteCssEmojiPosition[] = [];
  private readonly popupOffset = 12;

  constructor(
    private cdr: ChangeDetectorRef,
    private popupService: PopupService,
    @Inject(STYLE_SERVICE) private styleService: IStyleService
  ) {
    this.styleService.theme$.pipe(untilDestroyed(this)).subscribe((theme: Scheme) => {
      this.theme = theme;
      this.cdr.markForCheck();
    });
  }

  private compPopupRef: PopupRef<UEmojiWrapperComponent, any>;
  openPickerPopup(event) {
    this.click.emit();
    this.popupStatus.emit(true);
    if (this.compPopupRef) {
      this.compPopupRef.destroy();
    }
    let { x, y } = event;
    y -= this.popupOffset;
    x += this.popupOffset;

    const position: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' }];
    this.compPopupRef = this.popupService.open<UEmojiWrapperComponent, any>({ x, y }, UEmojiWrapperComponent, {}, { position });
    this.compPopupRef.compInstance.set = EMOJI_SET;
    this.compPopupRef.compInstance.showPreview = false;
    this.compPopupRef.compInstance.emojiTooltip = true;
    this.compPopupRef.compInstance.autoFocus = this.autoFocus;
    this.compPopupRef.compInstance.color = this.color;
    this.compPopupRef.compInstance.emojiSize = this.pickerEmojiSize;
    this.compPopupRef.compInstance.darkMode = this.theme === 'dark';
    this.compPopupRef.compInstance.backgroundImageFn = () => this.backgroundImageFn;

    this.compPopupRef.compInstance.emojiSelect.pipe(untilDestroyed(this)).subscribe((val) => this.emojiSelect(val));
    this.compPopupRef.compInstance.clear.pipe(untilDestroyed(this)).subscribe(() => this.emojiSelect(null));
    this.compPopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.popupStatus.emit(false);
    });
    this.cdr.markForCheck();
  }

  emojiSelect(event) {
    if (event) {
      this._selected = event.emoji as EmojiData;
      this.sheet = this._selected.sheet;
      this.pickerButtonType = this.replacePlaceholderOnSelect ? 'emoji' : 'font';
    } else {
      this._selected = this.sheet = null;
      this.pickerButtonType = 'font';
    }
    this._all.push(this.sheet);
    this.all.emit(this._all);
    this.selectedEmojiSprite.emit(this.sheet);
    this.selectedEmoji.emit(this._selected);
    if (this.closeOnSelect) {
      this.compPopupRef.destroy();
    }
    this.cdr.markForCheck();
  }
}
