<!-- todo make generic -->
<a class="dismiss-button" (click)="onCancel.emit()">
  <u-icon class="close-button" [model]="{ type: 'font', value: 'icon-times-large' }"></u-icon>
</a>
<ng-container *ngTemplateOutlet="isLoading ? loadingIcon : table"></ng-container>

<!-- TEMPLATES -->
<ng-template #loadingIcon>
  <div>
    <loader-overlay [darkOnly]="previewMode === 'popup'" [styles]="{ backgroundColor: 'transparent', backdropFilter: 'none' }">
    </loader-overlay>
  </div>
</ng-template>

<ng-template #table>
  <div class="header">{{ data?.fileName }} {{ count }}</div>
  <div class="table">
    <p-table [value]="data?.synonyms" [scrollable]="true" scrollHeight="500px">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ data.header.key }}</th>
          <th>{{ data.header.synonym }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{ item.key }}</td>
          <td>{{ item.synonym }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-template>
