import { Injectable } from '@angular/core';
import { Config } from '@environments/config';
import { Subscriptions, Workspace } from '@local/client-contracts';
import { isEmbed, isNativeWindow } from '@local/common-web';
import { PopupRef, PopupService } from '@local/ui-infra';
import { TrialUpgradePopupComponent } from '@shared/components/trial-upgrade-popup/trial-upgrade-popup.component';
import { EmbedService } from '@shared/embed.service';
import { RouterService } from '@shared/services/router.service';
import { filter, map, Observable, ReplaySubject } from 'rxjs';
import { SubscriptionsService } from './subscriptions.service';
import { WorkspacesService } from './workspaces.service';
import { hasTimestampPassed } from '@local/ts-infra';

type PlansCache = { timestamp: number; plans: Partial<Subscriptions.Plan>[] };
type Prices = Record<string, { month: number; year: number }>;

enum StateValue {
  year = 'year',
  month = 'month',
}
@Injectable({ providedIn: 'root' })
export class PricingService {
  private readonly PRICING_URL = '/pricing';
  private readonly isNativeWindow = isNativeWindow();
  private readonly isEmbed: boolean = isEmbed();
  private readonly ONE_DOLLAR_IN_CENTS = 100;
  private readonly ONE_YEAR_IN_MONTHS = 12;
  private readonly PLANS_LOCAL_STORAGE_KEY = 'subscription_plans';
  private readonly PLAN_NAME_ENTERPRISE = 'Enterprise';
  private trialUpgradePopupRef: PopupRef<TrialUpgradePopupComponent, any>;
  private workspace: Workspace.Workspace;
  private _prices: Prices = {};

  get prices$(): Observable<Prices> {
    return this.plans$.pipe(
      map((plans) => {
        const prices: Prices = {};
        plans.map((p) => {
          const year = this.getPriceByState(p, StateValue.year);
          const month = this.getPriceByState(p, StateValue.month);
          prices[p.name] = { month, year };
        });
        return prices;
      })
    );
  }
  plans$: ReplaySubject<Partial<Subscriptions.Plan>[]> = new ReplaySubject(1);

  constructor(
    private popupService: PopupService,
    private routerService: RouterService,
    private workspaceService: WorkspacesService,
    private subscriptionsService: SubscriptionsService,
    private embedService: EmbedService
  ) {
    this.workspaceService.current$.pipe(filter((w) => !!w)).subscribe(async (workspace) => {
      if (workspace?.isOwner && this.workspace?.id !== workspace.id) {
        this.initPlans();
      }
      this.workspace = workspace;
    });
  }

  async navigateToPricingPage() {
    if (this.workspace?.isOwner) {
      if (this.workspace.hasPurchased && !!this.workspace.plan && this.workspace?.plan?.isPaid) {
        this.navigateToMyPlan();
        return;
      }
      if (this.isEmbed) {
        this.embedService.openUrl(Config.pricing.url);
      } else if (this.isNativeWindow) {
        window.open(Config.pricing.url);
      } else {
        this.routerService.navigateByUrl(this.PRICING_URL);
      }
    } else {
      this.trialUpgradePopupRef?.destroy();
      this.trialUpgradePopupRef = this.popupService.open(
        'center',
        TrialUpgradePopupComponent,
        {},
        {
          position: 'center',
          backdropStyle: 'blur-2',
        }
      );
    }
  }

  async navigateToMyPlan() {
    const subscriptionPLan: Subscriptions.ManagePlan = await this.subscriptionsService.getYourPlanUrl();
    if (this.isNativeWindow || this.isEmbed) {
      window.open(subscriptionPLan.sessionUrl);
    } else {
      window.location.href = subscriptionPLan.sessionUrl;
    }
  }

  getPlanFinalPrice(plan: Workspace.Plan): number | null {
    if (!plan?.details) return null;
    let price = plan.details.price / this.ONE_DOLLAR_IN_CENTS;

    if (plan.details.discount) {
      const discount: Workspace.PlanDiscount = plan.details.discount;
      if (discount.amountOff) {
        return price - discount.amountOff;
      } else if (discount.percentOff) {
        return this.subtractPercent(price, discount.percentOff);
      }
    } else {
      return price;
    }
  }

  getPlanFinalPriceText(plan: Workspace.Plan): string | null {
    if (!plan?.details) return null;
    let priceAsText: string;
    priceAsText = `$${this.getPlanFinalPrice(plan)}/${plan.details.interval}`;
    if (plan.details?.discount) {
      priceAsText = `${priceAsText} (${this.getDiscountAsText(plan.details)})`;
    }

    return priceAsText;
  }

  private getDiscountAsText(details: Workspace.PlanDetails) {
    if (!details.discount) return;
    const discount = details.discount;
    let discountText = `${discount.couponName.toLocaleUpperCase()}`;
    if (discount?.amountOff) {
      discountText = `${discountText} - ${discount.amountOff}${details.currency.toLocaleUpperCase()}`;
    } else if (discount?.percentOff) {
      discountText = `${discountText} - ${discount.percentOff}%`;
    }
    return discountText;
  }

  private subtractPercent(number: number, percent: number) {
    let decimalPoint = percent / 100;
    return number - number * decimalPoint;
  }

  private async initPlans() {
    let plans: Partial<Subscriptions.Plan>[];
    const subscriptionsCache: PlansCache = JSON.parse(this.getPlansFromCache()) as PlansCache;
    if (subscriptionsCache && !hasTimestampPassed(subscriptionsCache.timestamp, { amount: 1, type: 'hour' })) {
      plans = subscriptionsCache.plans;
    } else {
      plans = await this.subscriptionsService.getSubscriptions();

      plans = plans.sort((p1, p2) => {
        return p1.prices.find((p) => p.interval === StateValue.month).price - p2.prices.find((p) => p.interval === StateValue.month).price;
      });
      plans = [
        ...plans,
        {
          name: this.PLAN_NAME_ENTERPRISE,
          description: 'Empowering larger organizations with AI driven, enterprise-grade, knowledge search discovery solutions',
        },
      ];
      this.savePlansInCache(plans);
    }
    this.plans$.next(plans);
  }

  private getPriceByState(plan: Partial<Subscriptions.Plan>, state: StateValue) {
    const p = plan.prices?.find((price) => price.interval === state);
    if (p) {
      let price = p.price / this.ONE_DOLLAR_IN_CENTS || 0;
      if (state === StateValue.year) price = price / this.ONE_YEAR_IN_MONTHS;
      return price;
    }
  }

  // localStorage
  private savePlansInCache(plans: Partial<Subscriptions.Plan>[]) {
    localStorage.setItem(
      this.PLANS_LOCAL_STORAGE_KEY,
      JSON.stringify({
        timestamp: Date.now(),
        plans,
      })
    );
  }

  private getPlansFromCache() {
    return localStorage.getItem(this.PLANS_LOCAL_STORAGE_KEY);
  }
}
