export class DateUtil {
  static isAllDayEvent(start: moment.Moment, end: moment.Moment): boolean {
    return this.isMidnight(start) && this.isMidnight(end);
  }

  static isMidnight(time: moment.Moment): boolean {
    const midnight = time.clone().startOf('day');
    return time.isSame(midnight);
  }
}
