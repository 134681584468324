import { ContextMenuItem } from '@shared/components';

export type CollectionOptionsId =
  | 'open_collection'
  | 'search_in_collection'
  | 'search_in_wiki'
  | 'copy_url'
  | 'make_a_copy'
  | 'delete'
  | 'remove'
  | 'favorite'
  | 'edit'
  | 'paste'
  | 'save'
  | 'pin'
  | 'unpin'
  | 'share'
  | 'add_to_collection'
  | 'change_layout'
  | 'verification_details'
  | 'verify'
  | 'unverify'
  | 'request_verification'
  | 'save_as_new'
  | 'move_card'
  | 'delete_draft'
  | 'view_publish';

export type CollectionOptionsItem = {
  [key in CollectionOptionsId]: ContextMenuItem;
};

export type CollectionContextMenuPlace =
  | 'collection'
  | 'collection-view'
  | 'search-result'
  | 'search-result-wiki'
  | 'collection-search-result'
  | 'search-result-view'
  | 'search-result-view-wiki'
  | 'collection-search-result-view'
  | 'card'
  | 'card-view'
  | 'list'
  | 'list-view'
  | 'gallery-card'
  | 'gallery-card-view'
  | 'static-collection'
  | 'split-button'
  | 'widget'
  | 'widget-view'
  | 'wiki-card-draft'
  | 'wiki-card-edit'
  | 'wiki-card-view'
  | 'wiki-card-inline'
  | null;

export type ItemsMap = {
  [key in CollectionContextMenuPlace]: Partial<CollectionOptionsId>[];
};
