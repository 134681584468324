import { Wiki } from '@local/client-contracts';
import { WikiCardsService } from 'src/app/bar/services/wikis/wiki-cards.service';
import { WikiCardFileHelper } from './wiki-card-file-helper';
import { WikiCardMediaHandler } from './media-handler/wiki-card-media-handler';
import { WikiCardMediaHelper } from './wiki-card-media-helper';
import { WikisService } from 'src/app/bar/services/wikis/wikis.service';
import { WikiDraftsService } from 'src/app/bar/services/wikis/wiki-drafts.service';
import { Injectable } from '@angular/core';
import { WikiAttachmentStorageHandler } from './wiki-attachment-storage-handler';
import { concat } from 'lodash';
import { CardEditorConstants } from '../../components/wiki-card-popup/card-editor/card-editor.constants';
import { ShowToasterService } from 'src/app/bar/services/show-toaster.service';
import { cutText } from '@local/ts-infra';
import { PreviewService } from 'src/app/bar/services/preview.service';

export interface DraftSaveModel {
  wikiCardMediaHandler: WikiCardMediaHandler[];
  wikiCardMediaHelper: WikiCardMediaHelper;
  fileHelper: WikiCardFileHelper;
  cardId: string;
  htmlContent: string;
  title: string;
  contentText: string;
  accountId: string;
}

@Injectable()
export class WikiCardSaveFlowHandler {
  constructor(
    public wikiCardsService: WikiCardsService,
    private wikisService: WikisService,
    private wikiDraftsService: WikiDraftsService,
    private wikiAttachmentStorageHandler: WikiAttachmentStorageHandler,
    private showToasterService: ShowToasterService,
    private previewService: PreviewService
  ) {}

  updatePublishedCard(card: Wiki.Card) {
    const req: Wiki.UpsertCardRequest = { card };
    this.wikiCardsService.update(req);
  }

  createDraft(card: Wiki.Card, accountId: string): Wiki.Draft {
    const { id, attachments, content, contentText, title } = card;
    const newDraft = this.getDraft({ title, content, attachments, contentText }, accountId);
    const req: Wiki.CreateDraftRequest = { cardId: id, draft: newDraft };
    this.wikiDraftsService.create(req);
    return newDraft;
  }

  saveDraftCard(draftSaveModel: DraftSaveModel) {
    const updatedDraft: Wiki.Draft = this.initUpdatedDraft(draftSaveModel);
    if (!updatedDraft) return;
    const req: Wiki.UpdateDraftRequest = { cardId: draftSaveModel.cardId, draft: updatedDraft };
    this.wikiDraftsService.update(req);
    return updatedDraft;
  }

  async publishCard(draft: Wiki.Draft, cardId: string, collectionId: string, publishedTime: number) {
    const req: Wiki.PublishDraftRequest = {
      cardId,
      collectionId,
      draft,
      publishedTime,
    };
    try {
      const updatedCard = await this.wikiDraftsService.publish(req);
      const toaster = this.showToasterService.showToaster({
        id: 'published-card',
        content: `A card ${cutText(draft.title, 40)} was successfully published`,
        icon: { type: 'font', value: 'icon-check-circle' },
        intent: 'primary',
        buttonText: 'Open',
      });
      toaster.compInstance.invoke.subscribe(() => {
        this.previewService.setPreviewState('popup', 0, {
          type: 'result',
          filterType: 'wiki-local',
          id: cardId,
          view: { title: { text: draft.title }, icon: null },
          action: { type: 'wiki card' },
        });
      });
      return updatedCard;
    } catch (error) {
      this.showToasterService.showErrorToaster('publish-card-failed');
      throw error;
    }
  }

  private initUpdatedDraft(draftSaveModel: DraftSaveModel): Wiki.Draft {
    const { title, contentText, accountId } = draftSaveModel;
    const updatedTitle = title?.length > 0 ? title : 'Untitled';
    const { attachments, content } = this.extractFilesDataFromHtml(draftSaveModel);
    const updatedDraft = this.getDraft({ title: updatedTitle, content, attachments, contentText }, accountId);
    return updatedDraft;
  }

  private extractFilesDataFromHtml(draftSaveModel: DraftSaveModel): { attachments: Wiki.CardAttachment[]; content: string } {
    const { htmlContent, fileHelper, wikiCardMediaHandler, wikiCardMediaHelper, cardId } = draftSaveModel;
    if (!htmlContent) {
      return { attachments: [], content: '' };
    }
    let updatedHtml = htmlContent;
    updatedHtml = fileHelper.removeClassFromAttachment(updatedHtml, CardEditorConstants.CLASS_ACTIVE_ATTACHMENT);
    for (const mediaHandler of wikiCardMediaHandler) {
      updatedHtml = mediaHandler.removeAllMediaSrc(updatedHtml);
    }
    const mediaIds = wikiCardMediaHelper.getAttachmentsIds(updatedHtml) || [];
    const attachmentsIds = fileHelper.getAttachmentsIds(updatedHtml) || [];
    const mergeBlobIds = concat([], mediaIds, attachmentsIds);
    const attachments: Wiki.CardAttachment[] = this.wikiAttachmentStorageHandler.getAttachments(cardId, mergeBlobIds);
    updatedHtml = fileHelper.extractAttachmentsFromEditor(updatedHtml);
    return { attachments, content: updatedHtml };
  }

  getDraft(draft: Omit<Wiki.Draft, 'contributors'>, accountId: string): Wiki.Draft {
    const { attachments, content, contentText, title } = draft;
    return {
      attachments: (attachments || []).filter((a) => !!a),
      content: content || '',
      contentText: contentText || '',
      title,
      contributors: null,
      modifiedBy: accountId,
      modifiedTime: Date.now(),
    };
  }

  async saveCardNewMode(cardData: Wiki.Card, parentId: string): Promise<Wiki.CreateCardResponse> {
    const upsertRequest: Wiki.UpsertCardRequest = {
      card: cardData,
      parentId: parentId,
      updateModifiedTime: true,
    };
    return this.wikisService.createCard(upsertRequest);
  }
}
