import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Commands, Downloads } from '@local/client-contracts';
import { isResourceDownloadRequest } from '@local/common';
import { isMac } from '@local/common-web';
import { DragData } from '@shared/directives/drag-and-drop.directive';

@Component({
  selector: 'download-item',
  templateUrl: './download-item.component.html',
  styleUrls: ['./download-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('change', [
      transition(':enter', [style({ opacity: 0 }), animate(200, style({ opacity: 1 }))]),
      transition(':leave', [animate(200, style({ opacity: 0 }))]),
    ]),
    trigger('clear', [
      state('true', style({ opacity: 0, transform: 'translateX(-100%)' })),
      state('false', style({ opacity: 1, transform: 'translateX(0)' })),
      transition('true <=> false', animate(100)),
    ]),
    trigger('completed', [
      transition(
        '* => true',
        animate(1000, keyframes([style({ opacity: 0 }), style({ opacity: 1 }), style({ opacity: 0 }), style({ opacity: 1 })]))
      ),
    ]),
  ],
})
export class DownloadItemComponent implements OnInit {
  res: Downloads.Download;
  @Input() index: number;
  @Input() animate: boolean = true;
  @Input() set downloadRes(res: Downloads.ResourceDownload) {
    if (this.res?.status === 'in-progress' && res.status === 'completed') {
      this.animateCompleted();
    }
    this.res = res;
    if (res.visibility !== 'visible' || res.status !== 'completed') return;
    const resource = isResourceDownloadRequest(res) ? res.resource : null;
    this.dragData = {
      model: {
        text: res.displayName || res.name,
        onDrag: {
          type: 'downloaded-file',
          path: `${res.path}/${res.name}`,
        } as Commands.DownloadedFile,
      },
      metadata: {
        type: 'Resource',
        resource,
        icon: res.icon,
        eventInfo: {
          position: this.index,
          list: 'downloads_bar_list',
        },
      },
    };
    this.cdr.markForCheck();
  }
  @Output() show = new EventEmitter<string>();
  @Output() clear = new EventEmitter<string>();
  @Output() retry = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<string>();
  @Output() open = new EventEmitter<string>();
  dragData: DragData = { model: null, metadata: null };
  showText: string;
  clearedIndication: boolean = false;
  completedIndication: boolean;

  constructor(private cdr: ChangeDetectorRef, private hostRef: ElementRef) {}

  private animateCompleted() {
    this.completedIndication = true;
    this.cdr.markForCheck();
    setTimeout(() => {
      this.completedIndication = false;
      this.cdr.markForCheck();
    }, 1000);
  }

  ngOnInit(): void {
    this.showText = `Show in ${isMac() ? 'Finder' : 'folder'}`;
  }

  onShow(id: string): void {
    this.show.emit(id);
  }

  handleClear(id: string) {
    if (this.animate) {
      this.clearedIndication = true;
      return;
    }
    this.clear.emit(id);
  }

  onClear(ev: AnimationEvent): void {
    this.clearedIndication && this.clear.emit(this.res.id);
  }

  onRetry(id: string): void {
    this.retry.emit(id);
  }

  onCancel(id: string): void {
    this.cancel.emit(id);
  }

  onOpen(id: string): void {
    this.open.emit(id);
  }
}
