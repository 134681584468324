import { Injectable } from '@angular/core';
import { Omnibox, Style } from '@local/client-contracts';
import { DatePickerType, DateValue, dateValueToString, stringToDateValue } from '@local/common-web';
import { DatePickerPopupData, DatePickerPopupService, GlobalPosition } from '@local/ui-infra';
import { DisplaySearchFilterValue } from '@shared/components/filters/models';
import * as moment from 'moment/moment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DatePickerService {
  private _isVisible: boolean;
  private readonly dateFormatToDisplay = 'MMM D, YYYY';
  private readonly dateShortFormatToDisplay = 'MMM D';
  readonly datePickerOptions: Array<{ label: string; value: DatePickerType }> = [
    { label: 'On', value: 'on' },
    { label: 'Before', value: 'before' },
    { label: 'After', value: 'after' },
    { label: 'Between', value: 'range' },
  ];

  get close$(): Observable<any> {
    return this.datePickerPopupService.close$;
  }

  get valueSelected$(): Observable<DateValue> {
    return this.datePickerPopupService.valueSelected$;
  }

  set valueSelected(val: DateValue) {
    this.datePickerPopupService.valueSelected = val;
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  set isVisible(v: boolean) {
    this._isVisible = v;
  }

  constructor(private datePickerPopupService: DatePickerPopupService) {}

  onCustomSelected(
    element: HTMLElement,
    title: string,
    customValueSelected?: DateValue,
    pickerTypes?: DatePickerType[],
    minDate?: Date,
    maxDate?: Date,
    defaultCustomTimeFilter?: DatePickerType
  ) {
    const { x, y } = element.getBoundingClientRect();
    this.openPopup({ x, y }, title, customValueSelected, minDate, maxDate, pickerTypes, defaultCustomTimeFilter);
  }

  openPopup(
    elementPosition: { x: number; y: number },
    title: string,
    customValueSelected?: DateValue,
    minDate?: Date,
    maxDate?: Date,
    pickerTypes: DatePickerType[] | 'all' = 'all',
    defaultCustomTimeFilter?: DatePickerType
  ) {
    this.isVisible = true;
    const datePickerOptions = this.datePickerOptions.filter((op) => pickerTypes === 'all' || pickerTypes.includes(op.value));
    const data: DatePickerPopupData = {
      filterTitle: title,
      styleHeight: 100,
      selectedValue: customValueSelected,
      datePickerOptions,
      defaultDatePickerType: defaultCustomTimeFilter || datePickerOptions[0]?.value,
      supportEndPickerType: ['range'],
      resetDateWhenChangePicker: true,
      displayPlaceholderType: true,
      minDate,
      maxDate,
    };
    const position: GlobalPosition = this.calcPopupPosition(elementPosition);
    this.datePickerPopupService.open(position, data);

    this.datePickerPopupService.close$.subscribe(() => (this.isVisible = false));
  }

  private calcPopupPosition(elementPosition: { x: number; y: number }) {
    const { x, y } = elementPosition;
    const heightScreen: number = window.innerHeight;
    const widthScreen: number = window.innerWidth;
    const datePickerHeight = 425;
    const datePickerWidth = 256;
    const filtersButtonWidth = 75;
    const position: GlobalPosition = { left: x, top: y + 32 };
    if (position.top + datePickerHeight > heightScreen) {
      delete position.top;
      let bottom = heightScreen - y;
      if (bottom + datePickerHeight > heightScreen) {
        bottom = 1;
      }
      position.bottom = bottom;
    }
    if (x + datePickerWidth > widthScreen) {
      delete position.left;
      position.right = widthScreen - x - filtersButtonWidth;
    }
    return position;
  }

  getFilter(filterName: string, value: string, icon: Style.EntityIcon<Style.EntityIconType>): DisplaySearchFilterValue {
    let title = value;
    const isCustomValue = value.includes(':');
    if (isCustomValue) {
      const dateValue = stringToDateValue(value);
      title = dateValueToString(dateValue);
    }
    return {
      id: `filter:${filterName}:value:customDate`,
      value,
      title,
      subtitle: '',
      type: isCustomValue ? 'custom-date' : null,
      filterName,
      icon,
    };
  }

  buildSelectedTimeEventValue(value: DateValue) {
    if (!value || (!value.end && !value.start)) {
      return;
    }

    let startingDate: string;
    let endingDate: string;
    let type = value.type;

    let firstDate = moment(value.start).format('MM/DD/YYYY');
    const lastDate = moment(value.end).format('MM/DD/YYYY');

    if (type === 'range' && (!value.start || !value.end)) {
      if (!value.start) {
        type = 'before';
        firstDate = lastDate;
      } else if (!value.end) {
        type = 'after';
      }
    }

    switch (type) {
      case 'on':
        startingDate = firstDate;
        endingDate = firstDate;
        break;

      case 'after':
        startingDate = firstDate;
        endingDate = undefined;
        break;

      case 'before':
        startingDate = undefined;
        endingDate = firstDate;
        break;

      case 'range':
        startingDate = firstDate;
        endingDate = lastDate;
        break;

      default:
        break;
    }

    return [`${startingDate}-${endingDate}`];
  }

  buildCustomFilter(customDateOption: DisplaySearchFilterValue, filterValue: string[]): DisplaySearchFilterValue {
    return {
      ...customDateOption,
      value: filterValue,
      title: filterValue ? filterValue[0] : null,
      type: 'custom-date',
    };
  }

  buildSuggestion(id: string, options: Omnibox.Suggestion[], filterValue: string[]): Omnibox.Suggestion {
    return {
      ...options[0],
      id,
      data: { ...options[0].data, value: filterValue },
      disabled: false,
      title: filterValue.join('-'),
      type: 'custom-date',
    };
  }
}
