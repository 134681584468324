import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UDropdownComponent } from './u-dropdown.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { UIconModule } from '../u-icon/u-icon.module';
import { UButtonModule } from '../u-button/u-button.module';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [UDropdownComponent],
  imports: [CommonModule, DropdownModule, FormsModule, UIconModule, UButtonModule, TooltipModule],
  exports: [UDropdownComponent],
})
export class UDropdownModule {}
