import { Fyis } from '@local/client-contracts';

const VERIFICATIONS_FYI_TYPES: Fyis.FyiType[] = [
  'verification_unverified',
  'verification_expired',
  'verifier_assigned',
  'verification_requested',
  'verifiers_notification',
];

const ACCOUNT_FYI_TYPES: Fyis.FyiType[] = [
  'collection_shared',
  'collection_pinned',
  'verification_requested',
  'verification_unverified',
  'experience_shared',
];

const COLLECTIONS_FYI_TYPES: Fyis.FyiType[] = ['collection_shared', 'collection_pinned'];

export const isVerificationFyi = (item: Fyis.Fyi): item is Fyis.VerificationFyi => VERIFICATIONS_FYI_TYPES.includes(item.type);
export const isAccountFyi = (type: Fyis.FyiType): boolean => {
  return ACCOUNT_FYI_TYPES.includes(type);
};

export const isCollectionFyi = (item: Fyis.Fyi): item is Fyis.CollectionFyi => COLLECTIONS_FYI_TYPES.includes(item.type);
export const isPinnedCollectionFyi = (item: Fyis.Fyi): item is Fyis.CollectionPinnedFyi => item.type === 'collection_pinned';
export const isExperienceFyi = (item: Fyis.Fyi): item is Fyis.ExperienceFyi => item.type === 'experience_shared';
