<ng-container>
  <main #appsMain>
    <section class="filter-container">
      <tabs
        #tabs
        [tabChange]="tabChange$ | async"
        [(ngModel)]="activeTab"
        [initialValue]="activeTab"
        (ngModelChange)="onTabChange($event)"
        class="tabs"
      >
        <tab [title]="'All Apps'" [id]="'all'"> </tab>
        <tab [title]="'My Apps'" [id]="'linked'"> </tab>
        @if (local?.length) {
          <tab [title]="'Local'" [id]="'local'"> </tab>
        }
      </tabs>
    </section>

    @if (loading$ | async) {
      <div class="app-list-ghost">
        <div class="app-list-ghost-item" intersect *ngFor="let i of 20 | fill"></div>
      </div>
    } @else {
      <ng-scrollbar
        visibility="hover"
        class="scroll-area apps-container"
        [ngClass]="{ 'empty-state-scroll': !(selectedTabItems || [] | apps: { query: searchQuery }).length > 0 }"
      >
        @if (!searchQuery) {
          @switch (activeTab) {
            @case ('all') {
              <section class="app-list-container content-container">
                @if (recommended?.length) {
                  <app-list class="app-list" [items]="recommended" [title]="'Top picks for You'"></app-list>
                }
                @if (notRecommended?.length) {
                  <app-list
                    class="app-list"
                    [items]="notRecommended"
                    [title]="notRecommendedTitle.title"
                    [titleEnd]="notRecommendedTitle.titleEnd"
                  ></app-list>
                }
              </section>
            }
            @case ('linked') {
              <section class="app-list-container content-container">
                @if (linked?.length !== 0) {
                  <app-list
                    class="app-list"
                    [searchDetector]="searchQuery"
                    [items]="linked"
                    [title]="linkedTitle.title"
                    [titleEnd]="linkedTitle.titleEnd"
                  ></app-list>
                } @else if (filter.linked && showFilteredList) {
                  <app-empty-state
                    class="empty-state-container"
                    (actionClick)="connectApps()"
                    clickTrack="link_action.add_link"
                    [title]="'No apps connected'"
                    [subtitle]="'Press the button below to browse apps'"
                    buttonText="Connect"
                    [icon]="emptyStateIcon"
                  ></app-empty-state>
                }
              </section>
            }
            @case ('local') {
              <section class="app-list-container content-container">
                @if (local?.length) {
                  <app-list class="app-list" [items]="local" [title]="'Local'"></app-list>
                }
              </section>
            }
          }
        } @else if ((selectedTabItems || [] | apps: { query: searchQuery }).length > 0) {
          <section class="app-list-container content-container">
            <app-list
              class="app-list"
              [filter]="filter"
              [searchDetector]="searchQuery"
              [items]="selectedTabItems | apps: { query: searchQuery }"
              [title]="searchTitle"
            ></app-list>
          </section>
        } @else {
          <section class="no-results">
            <h2 class="no-results-title">
              We're Sorry! <br />
              No results found.
            </h2>
            <u-icon class="icon" [model]="{ type: 'img' }" [inSvg]="'./assets/bar/apps/no-results-hub.svg'"></u-icon>
          </section>
        }

        <section class="apps-container-bottom content-container">
          <p class="apps-container-bottom-message">
            Don't see the app you're looking for? <br />
            Let us know which apps you would like us to support.
          </p>
          <div class="input-container-wrapper">
            <chat-input
              class="chat-input"
              #chatInput
              [placeholder]="'Type your request here..'"
              (send)="send($event)"
              [minLength]="SUGGESTIONS_MIN_LENGTH"
              [maxLength]="SUGGESTIONS_MAX_LENGTH"
              [name]="'apps_suggestion_box'"
              [location]="location"
            ></chat-input>
          </div>
        </section>
      </ng-scrollbar>
    }
  </main>
</ng-container>
