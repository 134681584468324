import { HomeTabs, Permissions } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class HomeTabsRpcInvoker implements HomeTabs.Service {
  @invoker
  get all$(): Observable<HomeTabs.HomeTab[]> {
    return;
  }

  @invoker
  get(id: string): Promise<HomeTabs.HomeTab> {
    return;
  }

  @invoker
  create(homeTab: HomeTabs.HomeTab): Promise<HomeTabs.HomeTab> {
    return;
  }

  @invoker
  addWidget(tabId: string, widget: HomeTabs.Widget, addAsFirst?: boolean, newState?: boolean): Promise<HomeTabs.Widget> {
    return;
  }

  @invoker
  deleteWidget(tabId: string, widgetId: string): Promise<void> {
    return;
  }

  @invoker
  update(homeTab: HomeTabs.HomeTab, actions: HomeTabs.UpdateAction[], shareOptions?: Permissions.ShareOptions): Promise<void> {
    return;
  }

  @invoker
  delete(id: string): Promise<void> {
    return;
  }

  @invoker
  refresh(): Promise<void> {
    return;
  }

  @invoker
  updateWidgets(tabId: string, widgets: HomeTabs.Widget[]): Promise<void> {
    return;
  }

  @invoker
  getHomeTabId(): Promise<string> {
    return;
  }
}
