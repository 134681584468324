import { User } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable, Subject } from 'rxjs';

export class UserServiceRpcInvoker implements User.Service {
  @invoker
  get current$(): Observable<User.Info> {
    return;
  }
  @invoker
  get current(): Promise<User.Info> {
    return;
  }

  @invoker
  get signOut$(): Subject<void> {
    return;
  }

  @invoker
  delete(): Promise<void> {
    return;
  }

  @invoker
  signOut(): Promise<void> {
    return;
  }
}
