import { Component, Output, EventEmitter, AfterContentInit, Input } from '@angular/core';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';

@Component({
  selector: 'u-emoji-wrapper',
  templateUrl: './u-emoji-picker-wrapper.component.html',
  styleUrls: ['./u-emoji-picker-wrapper.component.scss'],
})
export class UEmojiWrapperComponent implements AfterContentInit {
  @Output() clear = new EventEmitter<any>();
  @Output() emojiSelect = new EventEmitter<any>();
  @Input() set: Emoji['set'] = 'apple';
  @Input() showPreview = true;
  @Input() emojiTooltip = false;
  @Input() autoFocus = false;
  @Input() color = '#ae65c5';
  @Input() emojiSize: Emoji['size'] = 24;
  @Input() darkMode = false;
  @Input() backgroundImageFn: Emoji['backgroundImageFn'];

  show: boolean;

  ngAfterContentInit(): void {
    this.show = true;
  }
}
