import { Wiki } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class WikiDraftsRpcInvoker implements Wiki.DraftsService {
  @invoker
  get all$(): Observable<Wiki.DraftStructure[]> {
    return;
  }

  @invoker
  get draftsCount$(): Observable<number> {
    return;
  }

  @invoker
  get(cardId: string, useCache: boolean): Promise<Wiki.DraftStructure> {
    return;
  }

  @invoker
  create(req: Wiki.CreateDraftRequest): Promise<string> {
    return;
  }

  @invoker
  delete(cardId: string): Promise<void> {
    return;
  }

  @invoker
  update(req: Wiki.UpdateDraftRequest): Promise<string> {
    return;
  }

  @invoker
  publish(req: Wiki.PublishDraftRequest): Promise<Wiki.Card> {
    return;
  }
}
