<div class="collection-view-wrapper">
  <div *ngIf="loading">
    <loader-overlay [styles]="{ backgroundColor: 'transparent', backdropFilter: 'none' }"> </loader-overlay>
  </div>
  <div class="collection-view-container" *ngIf="!loading">
    <div class="collection-view-top">
      <div class="collection-view-title">
        <div class="title-container">
          <u-emoji-picker
            class="collection-emoji-picker"
            [closeOnSelect]="true"
            (selectedEmoji)="onSelectedEmoji($event)"
            [model]="{ type: 'font', value: collectionTypeIconMap[collectionsService.currentCollectionView?.kind] }"
            [emojiUnicode]="collectionsService.currentCollectionView?.emoji"
            [readonly]="!canEdit"
            (click)="changeFocus('emoji-picker')"
            [spriteEmojiSize]="24"
            (popupStatus)="collectionPopupService.isEmojiPopupRefOpen = $event"
            [placeholderFontSize]="'12px'"
            [placeholderColor]="
              collectionsService.currentCollectionView.kind === 'Live' && !canEdit ? 'var(--red-500)' : 'var(--color-text-secondary)'
            "
          ></u-emoji-picker>
          <u-input
            #inputTitle
            *ngIf="canEdit; else titleReadonly"
            [class.new-title]="collectionViewState === 'new-from' || (collectionViewState === 'new' && !inputTitle.model)"
            class="no-state inline-input"
            data-cy="input-title"
            [class.is-empty]="!currentState?.title"
            [placeholder]="collectionViewState === 'new' ? currentState?.title : collectionContent.untitled"
            (click)="changeComponentFocus(CollectionsComponentFocused.TITLE)"
            (onEnter)="changeComponentFocus(CollectionsComponentFocused.DESCRIPTION)"
            (onChange)="formInputChanged.next({ key: 'title', value: $event })"
            [model]="collectionViewState === 'new' ? null : currentState?.title"
            [inputFocus]="componentFocused === CollectionsComponentFocused.TITLE"
            [readonly]="!canEdit"
            [pTooltip]="tooltipText"
            [tooltipPosition]="'bottom'"
            tooltipStyleClass="u-tooltip"
            (outsideClick)="
              isAllowOutsideClick &&
                componentFocused === CollectionsComponentFocused.TITLE &&
                componentFocusedUpdate(CollectionsComponentFocused.RESULTS)
            "
          ></u-input>
        </div>
        <div class="description-container">
          <u-edit-text
            *ngIf="canEdit; else descriptionReadonly"
            class="no-state inline-input-description"
            data-cy="input-description"
            [placeholder]="currentState?.description ?? collectionContent.descriptionPlaceholder"
            (onTextChange)="formInputChanged.next({ key: 'description', value: $event })"
            [allowFocus]="componentFocused === CollectionsComponentFocused.DESCRIPTION"
            (outsideClick)="
              isAllowOutsideClick &&
                componentFocused === CollectionsComponentFocused.DESCRIPTION &&
                componentFocusedUpdate(CollectionsComponentFocused.RESULTS)
            "
            (onEnterPressed)="changeComponentFocus(CollectionsComponentFocused.TAGS)"
            (onClick)="changeComponentFocus(CollectionsComponentFocused.DESCRIPTION)"
            [focusNoColor]="true"
            [ignoreEnter]="true"
            [textSelected]="true"
            [maxLength]="280"
          ></u-edit-text>
        </div>
      </div>
      <div class="collection-view-top-content">
        <collection-tag-section
          [initialTags]="collectionsService.currentCollectionView?.tags"
          [id]="collectionsService.currentCollectionView?.id"
          [componentFocused]="componentFocused === CollectionsComponentFocused.TAGS"
          (componentFocusedFinish)="componentFocusedFinish($event)"
          (componentFocusedUpdate)="componentFocusedUpdate($event)"
          (outsideClick)="isAllowOutsideClick && componentFocused === CollectionsComponentFocused.TAGS && componentFocusedFinish('next')"
          [notifyAvailableTagsChange]="collectionsService.collectionTags$"
          (onTagChange)="onTagChange($event)"
          [canEdit]="canEdit"
          [itemSubtitle]="
            collectionsService.currentCollectionView.kind === 'Wiki'
              ? collectionContent.wikis?.toLowerCase()
              : collectionContent.collections?.toLowerCase()
          "
        >
        </collection-tag-section>
      </div>
    </div>
    <div class="collection-view-bottom">
      <ng-container *ngIf="collectionsService.currentCollectionView">
        <live-collection
          *ngIf="!collectionsService.currentCollectionView.kind || collectionsService.currentCollectionView.kind === 'Live'"
          [collection]="collectionsService.currentCollectionView"
          [state]="collectionViewState"
          [componentFocused]="componentFocused"
          (componentFocusedFinish)="componentFocusedFinish($event)"
          (updateLastSync)="updateLastSync($event)"
          [layoutMode]="layoutMode"
          (viewModeChanged)="changeViewMode()"
          [updateHeight]="updateHeight"
        ></live-collection>
        <static-collection
          *ngIf="collectionsService.currentCollectionView.kind === 'Static'"
          [collection]="collectionsService.currentCollectionView"
          [state]="collectionViewState"
          [componentFocused]="componentFocused"
          (componentFocusedFinish)="componentFocusedFinish($event)"
          (componentFocusedUpdate)="componentFocusedUpdate($event)"
          [layoutMode]="layoutMode"
          (viewModeChanged)="changeViewMode()"
          [updateHeight]="updateHeight"
        >
        </static-collection>
        <wiki-collection
          *ngIf="collectionsService.currentCollectionView.kind === 'Wiki'"
          [collection]="collectionsService.currentCollectionView"
          [state]="collectionViewState"
          [componentFocused]="componentFocused"
          (componentFocusedFinish)="componentFocusedFinish($event)"
          (componentFocusedUpdate)="componentFocusedUpdate($event)"
          [updateHeight]="updateHeight"
        >
        </wiki-collection>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #titleReadonly>
  <div class="collection-title-readonly">{{ collectionViewState === 'new' ? null : currentState?.title }}</div>
</ng-template>

<ng-template #descriptionReadonly>
  <div *ngIf="!!currentState?.description" class="collection-description-readonly">{{ currentState?.description }}</div>
</ng-template>
