import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input() size: string | number = 60;

  getFormattedSize(scale?: number) {
    this.size = 32;
    if (Number.isInteger(this.size)) {
      return scale ? Number(this.size) * scale + 'px' : this.size + 'px';
    } else {
      return this.size;
    }
  }

  get loaderStyle() {
    const size = this.getFormattedSize();
    return {
      width: size,
      height: size,
      maxWidth: size,
      maxHeight: size,
      minWidth: size,
      minHeight: size,
    };
  }

  get circleStyle() {
    this.size = 32;
    return {
      width: this.getFormattedSize(),
      height: this.getFormattedSize(0.9),
    };
  }
}
