import { Filters, MemorySearch, RecentSearches } from '@local/client-contracts';
import { EventInfo, LogService } from '@shared/services';
import { FlagsService } from '@shared/services/flags.service';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { getDisplayHeader } from '@shared/utils/header-builder.util';
import { Subject, Subscription, firstValueFrom, takeUntil } from 'rxjs';
import { HeaderItem, RecentSearchItem, SearchResults, TelemetryTrigger } from 'src/app/bar/views';
import { RecentSearchesService } from 'src/app/bar/views/results/recent-searches.service';
import { FiltersService } from '../../../filters.service';
import { MemorySearchClient } from '../memory-search-client/memory-search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { RecentSearchesSourceSettings } from './';

export class RecentSearchesSearchClient extends MemorySearchClient<RecentSearchesSourceSettings> {
  private destorySubjects: { [id: string]: Subject<void> } = {};
  private subscription: Subscription;

  getDestroy$(sessionName: string): Subject<void> {
    if (!this.destorySubjects[sessionName]) {
      this.destorySubjects[sessionName] = new Subject<void>();
    }
    return this.destorySubjects[sessionName];
  }

  constructor(
    logService: LogService,
    memorySearchService: MemorySearchService,
    private recentSearches: RecentSearchesService,
    private filtersService: FiltersService,
    private flagsService: FlagsService
  ) {
    super(logService, memorySearchService, [], ['app', 'type', 'service', 'account']);
    this.logger = logService.scope('recent-searches');
  }

  nextPage(request: SearchRequest<RecentSearchesSourceSettings>, response: SearchResponse, trigger: TelemetryTrigger): Promise<void> {
    return;
  }

  getTelemetryEndEvent(response: SearchResponse): Partial<EventInfo>[] {
    return [
      {
        search: {
          origin: 'Recent-Searches',
        },
      },
    ];
  }

  destroy(id: number, sessionName: string): void {
    this.getDestroy$[sessionName]?.complete();
    delete this.destorySubjects[sessionName];
  }

  async getInput(request: SearchRequest<RecentSearchesSourceSettings>, response: SearchResponse) {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
    let finished = false;
    let isRecentSearchEnabled = true;
    if (request.sourceSettings.flag) {
      isRecentSearchEnabled = await this.flagsService.isEnabled(request.sourceSettings.flag);
    }
    if (!isRecentSearchEnabled) {
      return [];
    }

    this.subscription = this.recentSearches.searches$
      .pipe(takeUntil(this.getDestroy$(request.sessionName)))
      .subscribe((items: RecentSearches.Item[]) => {
        if (finished) {
          this.refresh(request, response);
        }
      });
    const items = await firstValueFrom(this.recentSearches.searches$);
    if (items.length > 0) {
      const assistantId = request.sourceSettings.assistantId;
      const promises = items.map((i) =>
        Object.keys(i.filters || {})?.length ? this.filtersService.tags(i.filters, true, assistantId) : Promise.resolve([])
      );
      const tags = (await Promise.all(promises)).map((tag) =>
        tag.sort((a, b) => {
          return a?.order - b?.order || a?.label?.localeCompare(b?.label) || a?.title?.localeCompare(b?.title);
        })
      );
      const recentSearchItems = items
        .map(
          (item, index) =>
            ({
              type: 'recent-search',
              query: item.query,
              tags: tags[index],
              nodeId: item.nodeId,
              filters: item.filters,
            } as RecentSearchItem)
        )
        .map((i) => ({ data: i, searchText: i.query, sortValue: null } as MemorySearch.Item));
      finished = true;
      return recentSearchItems;
    }
    return [];
  }

  async getOutput(items: MemorySearch.Item[]): Promise<SearchResults[]> {
    return items.map((x) => x.data);
  }

  protected addHeaders(request: SearchRequest<RecentSearchesSourceSettings>, items: SearchResults[]): void {
    const settings = request.sourceSettings;
    if (!settings.header) return;

    const { title, titleEnd } = getDisplayHeader({ title: settings.header?.title, titleEnd: settings.header?.titleEnd }, items.length);
    const recentSearchHeader: HeaderItem = {
      type: 'header',
      clickable: false,
      origin: 'recent-searches',
      title,
      titleEnd,
      showButton: true,
      textButton: 'clear',
    };
    items.unshift(recentSearchHeader);
  }

  protected async filter(items: MemorySearch.Item[], settings: RecentSearchesSourceSettings): Promise<any[]> {
    const filters = settings.filters?.preFilters;
    if (!filters || !Object.keys(filters).length) {
      return items;
    }
    const activeFilters: Filters.ActiveFilters = {};
    for (const [key, value] of Object.entries(filters || {})) {
      activeFilters[key] = value.map((v) => ({ value: v, exclusive: false } as Filters.ActiveFilterValue));
    }
    const assistantId = settings.assistantId;
    const relevantTypes = await this.filtersService.getRelevantStandardTypes(activeFilters, assistantId);
    const filtered = [];
    for (const item of items) {
      const recentSearchItem = item.data as RecentSearchItem;
      const entries = Object.entries(recentSearchItem.filters);
      if (!entries.length) {
        continue;
      }
      const itemFilters: Filters.ActiveFilters = {};
      for (const [key, value] of entries) {
        itemFilters[key] = value.map((v) => ({ value: v, exclusive: false } as Filters.ActiveFilterValue));
      }
      const itemRelevant = new Set(await this.filtersService.getRelevantStandardTypes(itemFilters, assistantId));
      if (relevantTypes.some((i) => !itemRelevant.has(i))) {
        continue;
      }
      filtered.push(item);
    }
    return filtered;
  }
}
