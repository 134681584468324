import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { isNativeWindow } from '@local/common-web';
import { RouterService } from '@shared/services/router.service';
import { SessionService } from '@shared/services/session.service';
import { firstValueFrom } from 'rxjs';
import { WorkspacesService } from '../../services';
import { AnalyticsService } from '../../services/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsGuard {
  private hasAccessPermission: boolean;
  private readonly isNativeWindow = isNativeWindow();
  constructor(
    private routerService: RouterService,
    private session: SessionService,
    private workspaceService: WorkspacesService,
    private analyticsService: AnalyticsService
  ) {
    this.workspaceService.ownerOrAdmin$.subscribe((s) => {
      this.hasAccessPermission = s;
    });
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const analytics = await firstValueFrom(this.analyticsService.analytics$);
    if (!analytics?.reportId || this.isNativeWindow || !this.hasAccessPermission) {
      this.routerService.navigateByUrl('/');
      return false;
    }
    return true;
  }
}
