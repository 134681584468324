import { Component, ChangeDetectionStrategy, ElementRef, AfterViewInit, Input } from '@angular/core';

@Component({
  selector: 'gradient-overflow',
  templateUrl: './gradient-overflow.component.html',
  styleUrls: ['./gradient-overflow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GradientOverflowComponent implements AfterViewInit {
  @Input() set height(value: string) {
    this._height = value;
    this.setHeight();
  }

  get height() {
    return this._height;
  }

  private _height = '4em';

  constructor(private host: ElementRef) {}

  ngAfterViewInit() {
    this.setHeight();
  }

  private setHeight() {
    if (!this.host) return;

    const { nativeElement: el }: { nativeElement: HTMLElement } = this.host;
    el.style.setProperty('--height', this.height);
  }
}
