import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UInputGroupComponent } from './u-input-group.component';
import { UInputModule } from '../u-input/u-input.module';
import { UIconModule } from '../u-icon/u-icon.module';

@NgModule({
  declarations: [UInputGroupComponent],
  imports: [CommonModule, FormsModule, UInputModule, UIconModule],
  exports: [UInputGroupComponent],
})
export class UInputGroupModule {}
