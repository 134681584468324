import { Injectable } from '@angular/core';
import { Stats } from '@local/client-contracts';
import { generateId } from '@local/common-web';
import { ServicesRpcService } from '@shared/services';
import { ReplaySubject, Observable } from 'rxjs';
import { StatsRpcInvoker } from './invokers/stats.rpc-invoker';

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  private service: Stats.Service;
  private _updated$ = new ReplaySubject<Stats.Stat>(1);

  constructor(services: ServicesRpcService) {
    this.service = services.invokeWith(StatsRpcInvoker, 'stats');
    this.service.updated$.subscribe((c) => this._updated$.next(c));
  }

  get updated$(): Observable<Stats.Stat> {
    return this._updated$;
  }

  async refresh() {
    this.service.refresh();
  }

  upsert(stats: Stats.Stat): Promise<void> {
    stats.id = generateId();
    return this.service.upsert(stats);
  }

  delete(id: string): Promise<void> {
    return this.service.delete(id);
  }

  getViewsById(ids: Set<string>): Promise<Stats.StatVal[]> {
    return this.service.getViewsById(ids);
  }
}
