<header>
  <span class="text-fyi">fyi</span>
  <div class="dismiss" *ngIf="(count$ | async) > 0 && dismissibleCount > 0" (click)="dismissAll('mouse_click')">Dismiss all</div>
</header>
<ng-container *ngIf="(count$ | async) > 0">
  <ng-scrollbar visibility="hover" [style.height]="scrollHeight">
    <cdk-virtual-scroll-viewport itemSize="fyisPerDay.length" scrollViewport>
      <ng-container *cdkVirtualFor="let fyis of fyisPerDay | keyvalue: dateTitlesDescOrder; trackBy: trackDay">
        <div class="date" *ngIf="fyisPerDay[fyis.key].length > 0">
          <h1 class="text-date" [pTooltip]="getDateForTooltip(fyis.key)" tooltipStyleClass="u-tooltip" tooltipPosition="bottom">
            {{ getDateFromTimestamp(fyis.key) }}
          </h1>
        </div>
        <fyi-dropdown-item
          class="fyi-item"
          *ngFor="let fyi of fyis.value; index as i; trackBy: trackFyi"
          [resourcesCount]="fyi.resourcesCount ? fyi.resourcesCount : undefined"
          [syncStatus]="fyi.syncStatus ? fyi.syncStatus : undefined"
          [lastUpdateTime]="fyi.lastUpdateTime ? fyi.lastUpdateTime : undefined"
          [viewFyi]="fyi"
          [locationTitle]="locationForTelemetry"
          [jsonData]="convertFyiItemToJson(fyi, i)"
          [workspaceAccount]="getWorkspaceAccount(fyi)"
          #scrollItem
        >
        </fyi-dropdown-item>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </ng-scrollbar>
</ng-container>
<fyi-empty-state *ngIf="(count$ | async) == 0" class="empty-state"> </fyi-empty-state>
