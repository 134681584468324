import { Injectable } from '@angular/core';
import { Assistants } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';
import { ServicesRpcService } from './rpc.service';

class AssistantsRpcInvoker implements Assistants.Service {
  @invoker
  ticket$(_req: Assistants.AssistantTicketRequest): Observable<Assistants.AssistantTicketContext> {
    return;
  }
  @invoker
  summaryTicket$(_req: Assistants.AssistantSummaryRequest): Observable<Assistants.AssistantSummaryContext> {
    return;
  }
  @invoker
  answers$(_req: Assistants.AnswersSearchRequest): Observable<Assistants.AnswersSearchResponse> {
    return;
  }
}
@Injectable()
export class AssistantsService implements Assistants.Service {
  private service: Assistants.Service;

  constructor(rpcServices: ServicesRpcService) {
    this.service = rpcServices.invokeWith(AssistantsRpcInvoker, 'assistants');
  }
  ticket$(req: Assistants.AssistantTicketRequest): Observable<Assistants.AssistantTicketContext> {
    return this.service.ticket$(req);
  }
  summaryTicket$(req: Assistants.AssistantSummaryRequest): Observable<Assistants.AssistantSummaryContext> {
    return this.service.summaryTicket$(req);
  }
  answers$(req: Assistants.AnswersSearchRequest): Observable<Assistants.AnswersSearchResponse> {
    return this.service.answers$(req);
  }
}
