import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventsService } from '@shared/services';
import { BannerMessage, BannerService } from '@shared/services/banner.service';
import { HubService } from 'src/app/bar/services/hub.service';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class BannerComponent implements OnInit {
  message: BannerMessage;
  buttonStyle: any = { color: 'var(--primary-A100)', borderColor: 'var(--primary-A100)' };
  @Input() shortWidth: boolean;

  @HostBinding('style.--banner-bg-color')
  get bannerBgColor() {
    return this.message?.style?.backgroundColor;
  }

  constructor(
    private bannerService: BannerService,
    private cdr: ChangeDetectorRef,
    private eventService: EventsService,
    private hubService: HubService
  ) {}

  ngOnInit(): void {
    this.bannerService.current$.pipe(untilDestroyed(this)).subscribe((message) => {
      this.message = message;
      this.cdr.markForCheck();
    });
  }

  onClick() {
    if (!this.message.onClick) return;
    this.bannerService.buttonClicked(this.message);
    this.eventService.event('banner.click', {
      target: this.message.clickTarget,
      location: { title: this.hubService.currentLocation },
      name: this.message.id,
    });
    this.message.onClick();
    if (!this.message?.persistentBanner) {
      this.bannerService.removeMessage(this.message);
    }
  }

  onClose() {
    const event = {
      name: this.message.id,
      location: { title: this.hubService.currentLocation },
    };
    this.eventService.event('banner.close', event);
    if (this.message?.onClose) {
      this.message.onClose();
    }
    this.bannerService.removeMessage(this.message);
  }
}
