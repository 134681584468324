import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public readonly observe: Subject<{ key: string; value: any }> = new Subject();

  constructor() {
    for (const [key, value] of Object.entries(localStorage)) {
      this.observe.next({ key, value });
    }
  }

  getItem(key: string): any {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
    this.observe.next({ key, value });
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
    this.observe.next({ key, value: null });
  }

  clear() {
    localStorage.clear();
  }
}
