<u-icon class="trial-expire-icon" [model]="{ type: 'img' }" [inSvg]="'/assets/workspace-plan/plan_upgrade.svg'"></u-icon>
<h1 class="trial-upgrade-title">Upgrade Time!</h1>
<div class="trial-upgrade-subtitle">
  <div class="trial-upgrade-subtitle-part">
    <div >Please contact your workspace owner</div>
    <span *ngIf="ownerEmail">-</span>
  </div>
  <div *ngIf="ownerEmail" class="trial-upgrade-subtitle-part">{{ ownerEmail }}</div>
  <div class="trial-upgrade-subtitle-part">to upgrade your account</div>
</div>
