import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit, TrackByFunction } from '@angular/core';
import { Accounts, Fyis } from '@local/client-contracts';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventsService } from '@shared/services';
import { AccountsService } from '@shared/services/accounts.service';
import { KeyboardService, Shortcuts } from '@shared/services/keyboard.service';
import { RouterService } from '@shared/services/router.service';
import { windowSizeObserver } from '@shared/utils';
import * as moment from 'moment/moment';
import { Observable } from 'rxjs';
import { FyiService, FyiShortcuts } from 'src/app/bar/services/fyi.service';
import { FyiViewModel } from './fyi-models';
import { isAccountFyi, isCollectionFyi, isExperienceFyi, isVerificationFyi } from 'src/app/bar/utils/fyi-utils';

@UntilDestroy()
@Component({
  selector: 'fyi-dropdown',
  templateUrl: './fyi-dropdown.component.html',
  styleUrls: ['./fyi-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FyiDropdownComponent implements OnInit {
  fyisPerDay: Record<string, Array<Fyis.Fyi>>;
  isEmpty = false;
  count: number;
  count$: Observable<number>;
  dismissibleCount: number;
  shortcuts: FyiShortcuts;
  isIndexingRunning: boolean;
  scrollHeight: string;
  private windowSize$ = windowSizeObserver();
  private workspaceAccounts: Accounts.WorkspaceAccount[];

  get locationForTelemetry(): string {
    const irrelevantSegments = ['b', this.routerService.prefix];
    const url = this.routerService.activeRoute?.snapshot.url;
    let cleanPath = url?.reduce(
      (acc, curr) => (irrelevantSegments.includes(curr.path) ? acc : acc ? (acc += '/' + curr) : (acc += curr)),
      ''
    );
    if (url?.[0]?.path === 'b') cleanPath = cleanPath?.replace('apps/', 'tree/');
    return cleanPath.length ? cleanPath : 'home';
  }

  constructor(
    private fyiService: FyiService,
    private keyboardService: KeyboardService,
    private eventsService: EventsService,
    private routerService: RouterService,
    private accountsService: AccountsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.accountsService.all$.subscribe((value) => {
      this.workspaceAccounts = value;
    });
    this.fyisPerDay = this.fyiService.fyisPerDay;
    this.count$ = this.fyiService.count$;
    this.loadShortcuts();

    this.fyiService.count$.pipe(untilDestroyed(this)).subscribe((count) => {
      if (count) {
        this.isEmpty = false;
      } else {
        setTimeout(() => {
          this.isEmpty = true;
        }, 280);
      }
      this.count = count as number;
      this.scrollHeight = this.fyiService.calcHeight();
      this.cdr.markForCheck();
    });

    this.fyiService.dismissibleCount$.pipe(untilDestroyed(this)).subscribe((count) => {
      if (count === this.dismissibleCount) return;

      this.dismissibleCount = count as number;
      this.cdr.markForCheck();
    });

    const imprisoned: Fyis.Fyi[] = this.getFlatFyisPerDay();
    if (imprisoned.length) {
      this.sendImpressionEvent(imprisoned);
    }

    this.windowSize$.pipe(untilDestroyed(this)).subscribe(() => {
      this.scrollHeight = this.fyiService.calcHeight();
      this.cdr.markForCheck();
    });
  }

  indexingRunning(): boolean {
    if (!this.fyisPerDay) return false;

    return (
      Object.values(this.fyisPerDay)
        .reduce((all, current) => all.concat(current), [])
        .filter((i) => i.type === 'initial_sync' && (i as Fyis.InitialSyncFyi).syncStatus === 'Started').length > 0
    );
  }

  /** Shouldn't be awaited on  */
  private async loadShortcuts() {
    Promise.all([this.keyboardService.loadShortcuts<Shortcuts>(['_home']), this.fyiService.getShortcuts()]).then((shortcuts) => {
      this.shortcuts = { ...shortcuts[0], ...shortcuts[1] };
    });
  }

  private getFlatFyisPerDay(): Fyis.Fyi[] {
    const fyis = Object.values(this.fyisPerDay).flatMap((fyiPerDay) => fyiPerDay);
    return fyis;
  }

  private sendImpressionEvent(items: Fyis.Fyi[]) {
    this.eventsService.event('fyi_impression', {
      location: { title: this.locationForTelemetry },
      jsonData: JSON.stringify(
        items.map((fyi, index) => {
          return this.convertFyiItemToJson(fyi, index);
        })
      ),
    });
  }

  private convertFyiItemToJson(fyi: Fyis.Fyi, index: number) {
    switch (fyi.type) {
      case 'initial_sync': {
        const fyiJson = fyi as Fyis.InitialSyncFyi;
        return { type: fyiJson.type, app: fyiJson.appId, status: fyiJson.syncStatus || 'unknown', time: moment().unix(), pos: index };
      }

      case 'link_staled': {
        const fyiJson = fyi as Fyis.AppDataFyi;
        return { type: 'outdated_link', app: fyiJson.appId };
      }

      case 'admin_announcements': {
        const fyiJson = fyi as Fyis.AdminAnnouncementFyi;
        return { type: fyiJson.type, title: fyiJson.title, trackingLabel: fyiJson.trackingLabel, time: moment().unix(), pos: index };
      }

      case 'collection_shared': {
        const fyiJson = fyi as Fyis.CollectionFyi;
        return { type: 'collection_edit_access', title: fyiJson.collectionTitle, time: Date.now(), pos: index };
      }

      case 'collection_pinned': {
        const fyiJson = fyi as Fyis.CollectionFyi;
        return { type: fyiJson.type, title: fyiJson.collectionTitle, time: Date.now(), pos: index };
      }

      default:
        return { type: 'sync_completed', time: moment().unix(), pos: index };
    }
  }

  getWorkspaceAccount(fyi: Fyis.Fyi) {
    if (!isAccountFyi(fyi.type)) {
      return;
    }
    let id: string;
    if (isCollectionFyi(fyi) || isExperienceFyi(fyi)) {
      id = fyi.sharedBy;
    } else if (isVerificationFyi(fyi)) {
      id = fyi.accountId;
    }
    if (!id) {
      return;
    }
    return this.workspaceAccounts?.find((u) => u.id === id);
  }

  @HostBinding('class.empty') get empty() {
    return !this.count;
  }

  trackFyi: TrackByFunction<FyiViewModel> = (index: number, item: FyiViewModel): string => item.id;
  trackDay: TrackByFunction<{ key: string; value: any }> = (index: number, item: { key: string; value: any }): string => item.key;

  dateTitlesDescOrder = (a: KeyValue<number, any>, b: KeyValue<number, any>): number => {
    return a.key > b.key ? -1 : b.key > a.key ? 1 : 0;
  };

  getDateForTooltip(timestamp: number): string {
    return moment.unix(timestamp).format('L');
  }

  getDateFromTimestamp(timestamp: number) {
    return moment.unix(timestamp).calendar({
      lastDay: '[Yesterday]',
      sameDay: '[Today]',
      sameElse: 'MMM D, YYYY',
      lastWeek: 'MMM D, YYYY',
    });
  }

  async dismissAll(target: 'keyboard' | 'mouse_click') {
    await this.fyiService.dismissAll(this.locationForTelemetry, target);
  }
}
