import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UTabViewComponent } from './u-tab-view.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UIconModule } from '../u-icon/u-icon.module';
import { UInputModule } from '../u-input/u-input.module';
import { TooltipModule } from 'primeng/tooltip';
import { UDropdownModule } from '../u-dropdown/u-dropdown.module';
import { UMenubarModule } from '../u-menubar/u-menubar.module';
import { InfraComponentsSharedModule } from '../../shared/infra-components-shared.module';
import { UiInfraModule } from '../../ui-infra.module';

@NgModule({
  declarations: [UTabViewComponent],
  imports: [
    CommonModule,
    DragDropModule,
    UIconModule,
    UInputModule,
    TooltipModule,
    UDropdownModule,
    UMenubarModule,
    InfraComponentsSharedModule,
    UiInfraModule,
  ],
  exports: [UTabViewComponent],
})
export class UTabViewModule {}
