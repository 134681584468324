<div [ngClass]="{ 'popup-background': componentOptions.isPopUp && !componentOptions.isPopUpWithAllContent }">
  <div
    class="share-options-container"
    *ngIf="!isLoading || componentOptions.isPopUp"
    [ngClass]="{ 'popup-mode': componentOptions.isPopUp }"
  >
    <ng-container *ngIf="componentOptions.isPopUpWithAllContent">
      <div class="close-icon-solo">
        <ng-container *ngTemplateOutlet="closeIcon"></ng-container>
      </div>
    </ng-container>

    <div class="share-options-title" *ngIf="componentOptions.showTitle">{{ componentOptions.showTitle }}</div>

    <!--private link-->
    <div
      id="private-link"
      [attr.all-content]="componentOptions.isPopUpWithAllContent"
      *ngIf="componentOptions.showPrivateLink"
      class="prvate-link-container container option-container small-mode"
      (click)="clickCheckbox($event, 0)"
    >
      <div class="private-main">
        <div class="main center">
          <u-checkbox
            class="option-checkbox"
            name="option"
            (click)="$event.stopPropagation(); clickCheckbox($event, 0)"
            [checked]="options[0]?.checked"
            [binary]="true"
          >
          </u-checkbox>
          <div class="content">
            <div class="user-avatar-member-container">
              <u-icon
                [model]="{ type: 'img', value: user?.picture ? { lightUrl: user.picture } : MEMBER_ICON_FALLBACK }"
                class="user-avatar-member-wrapper"
              ></u-icon>
            </div>
            <div class="text-content small-mode">
              <span class="title"> {{ user?.firstName || '' + user?.lastName || '' }}</span>
              <span class="private-subtitle">Only you</span>
            </div>
          </div>
        </div>
        <span class="container-side-title">{{ userAccess }}</span>
      </div>
    </div>

    <div *ngIf="componentOptions.allowPopupHeader" class="popup-header">
      <div class="link-name">
        <u-icon
          class="ws-logo"
          [model]="{ type: 'img', value: model?.headerIcon ? model?.headerIcon : model?.workspaceLogo }"
          [styles]="{ width: '18px', height: '18px', 'border-radius': '10px', border: '1px solid var(--' + model?.linkColor + ')' }"
        ></u-icon>
        <span class="medium-font-size" [style.color]="'var(--' + model?.linkColor + ')'">{{ model?.link?.name }}</span>
      </div>
      <ng-container *ngTemplateOutlet="closeIcon"></ng-container>
    </div>

    <div *ngIf="isLoading" class="popup-loading-container">
      <loader-overlay class="popup-loading"> </loader-overlay>
    </div>

    <div
      *ngIf="!isLoading"
      id="share-group-and-members-container"
      [attr.all-content]="componentOptions.isPopUpWithAllContent"
      [style.margin-top]="componentOptions.isPopUpWithAllContent ? '0px' : '16px'"
      class="container"
      (click)="componentOptions.hasBorder && !openedPanel && !activeIndex ? clickCheckbox($event, 1) : (openedPanel = false)"
      [ngClass]="{
        active: [1, 2, 3].includes(focusedIndex),
        'no-border': componentOptions.isPopUp && !componentOptions.hasBorder,
        'has-border': componentOptions.hasBorder
      }"
    >
      <div class="sub-title" *ngIf="componentOptions.showSubTitle">Select who can access</div>

      <!--public link - share with groups/members-->
      <div id="share-group-and-members" class="option-container">
        <div class="main">
          <u-checkbox
            class="option-checkbox"
            name="option"
            (click)="$event.stopPropagation(); clickCheckbox($event, 1)"
            [checked]="options[1].checked"
            [binary]="true"
          >
          </u-checkbox>
          <div class="text-content padding-content">
            <div *ngIf="componentOptions.updateGroupAndMembers; else normalGroupTitle" class="group-and-member-new-title-section">
              <span class="title">{{ componentOptions.updateGroupAndMembers }}</span>
              <span class="container-side-title">Viewers</span>
            </div>
            <ng-template #normalGroupTitle>
              <span class="title">Share with groups and members</span>
            </ng-template>
            <span class="small-subtitle"> Select which group or member to share this link with</span>
            <div class="auto-complete-container">
              <u-auto-complete
                class="share-options-auto-complete"
                #autoComplete
                label="name"
                [suggestionItems]="suggestions"
                placeholder="Add a group or a member"
                [multiple]="true"
                [group]="true"
                [groupItemsField]="'items'"
                [selectedItems]="selectedSuggestions"
                (onChange)="onSelectedSuggestions($event)"
                [filterInvoker]="filterTags"
                (click)="onAutoCompleteClicked($event)"
              >
                <ng-template let-value pTemplate="selectedItem">
                  <span>{{ value.name }}</span>
                </ng-template>

                <ng-template let-value pTemplate="item">
                  <div class="item-content">
                    <div class="item-left-side">
                      <u-icon
                        class="item-avatar"
                        [model]="{ type: 'img', value: { lightUrl: value.picture } }"
                        [fallback]="suggestionFallBack"
                        *ngIf="value.name !== 'No results' && value.picture"
                      ></u-icon>
                      <div *ngIf="value.name !== 'No results' && !value.picture" class="item-avatar label">
                        {{ value.name[0] | uppercase }}
                      </div>
                      <div class="name">{{ value.name }}</div>
                    </div>
                    <span class="item-right-side">{{ value.email || (value.membersCount ? value.membersCount + ' members' : '') }}</span>
                  </div>
                </ng-template>

                <ng-template let-group pTemplate="group">
                  <span>{{ group.name }}</span>
                </ng-template>
              </u-auto-complete>
            </div>
          </div>
        </div>
      </div>

      <!--public link - share with workspace-->
      <div id="share-workspace" class="option-container" (click)="$event.stopPropagation()">
        <div class="main">
          <u-checkbox
            class="option-checkbox"
            name="option"
            (click)="$event.stopPropagation(); clickCheckbox($event, 2)"
            [checked]="options[2].checked"
            [binary]="true"
          >
          </u-checkbox>
          <div class="text-content padding-content ws-content">
            <span class="title">{{ workspace.name }}</span>
            <div class="subtitle">
              <u-icon
                class="company-icon"
                [model]="{ type: 'font', value: 'icon-settings-company' }"
                [styles]="{ fontSize: '16px' }"
              ></u-icon>
              <span> {{ (this.workspace?.accounts ? this.workspace.accounts?.length : 0) + ' workspace members' }}</span>
            </div>
          </div>
        </div>
        <div class="ws-masaage">
          <span class="small-subtitle"> {{ 'This link will be accessible to everyone at ' + workspace.name + ' workspace' }}</span>
        </div>
      </div>

      <div *ngIf="componentOptions.showRestricted" class="restricted-container" [ngClass]="{ disabled: options[0].checked || updateMode }">
        <u-checkbox
          class="option-checkbox"
          name="restricted"
          (click)="onClickRestricted($event)"
          [disabled]="options[0].checked || updateMode"
          [checked]="options[activeIndex]?.restricted"
          [binary]="true"
        >
        </u-checkbox>
        <u-icon [model]="{ type: 'font', value: 'icon-lock-fill' }" [styles]="{ color: 'var(--color-text-tertiary)' }"></u-icon>
        <span>Restricted access only</span>
        <u-icon
          class="message"
          [model]="{ type: 'font', value: 'icon-exclamation-mark-circle' }"
          [styles]="{ fontSize: '13px' }"
          [pTooltip]="restrictedTooltip"
          [escape]="false"
          tooltipStyleClass="u-tooltip restricted"
          tooltipPosition="top"
          (click)="openLearnMore($event)"
        ></u-icon>
      </div>

      <div class="delete-link-message" *ngIf="componentOptions.showDeleteLinkMsg">
        To make this link private, you will need to <a class="delete-link" (click)="onDelete($event)">delete</a> the link and re-connect it
        as a private link.
      </div>

      <ng-container *ngIf="componentOptions.showButtons && !componentOptions.showFooterButtons">
        <ng-container *ngTemplateOutlet="buttonContainer"></ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="!isLoading && componentOptions.showButtons && componentOptions.showFooterButtons">
      <ng-container *ngTemplateOutlet="buttonContainer"></ng-container>
    </ng-container>
  </div>
</div>

<loader-overlay *ngIf="isLoading && !componentOptions.isPopUp"> </loader-overlay>

<ng-template #buttonContainer>
  <div class="update-buttons">
    <u-button
      #cancelButton
      label="Cancel"
      type="secondary"
      [styles]="{ width: componentOptions.isPopUpWithAllContent ? '196px' : '136px' }"
      [iconStyles]="{ width: '116px' }"
      (onClick)="onCancelButton($event)"
      [ngClass]="{ focus: focusedIndex === 3 }"
    >
    </u-button>
    <u-button
      #updateButton
      [label]="'Update'"
      type="primary"
      [iconStyles]="{ width: '116px' }"
      [disabled]="disableUpdate"
      [styles]="{ width: componentOptions.isPopUpWithAllContent ? '196px' : '136px' }"
      (onClick)="onUpdateButton($event)"
      [ngClass]="{ focus: focusedIndex === 4, 'update-button': true }"
    >
    </u-button>
  </div>
</ng-template>
<ng-template #closeIcon>
  <u-icon
    class="close-icon"
    (click)="onCancelButton($event)"
    [model]="{ type: 'font', value: 'icon-Windows-close' }"
    [styles]="{ fontSize: '10px', color: 'var(--gray-250)' }"
  ></u-icon>
</ng-template>
