import { Injectable } from '@angular/core';
import { RouterService } from '@shared/services/router.service';

@Injectable({
  providedIn: 'root',
})
export class InviteService {
  public invite;

  constructor(private routerService: RouterService) {
    this.invite = this.getInviteUser();
  }

  private getInviteUser() {
    const inviteUser = localStorage.getItem('invite-user');
    if (inviteUser) {
      return JSON.parse(inviteUser);
    }
  }

  signOut() {
    this.invite = null;
    localStorage.removeItem('invite-user');
    this.routerService.navigateByUrl('/');
  }

  setInviteUser(info) {
    this.invite = info;
    localStorage.setItem('invite-user', JSON.stringify(info));
  }
}
