import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Preferences } from '@local/client-contracts';
import { currentTheme, getTheme } from '@shared/utils/theme.util';

@Component({
  selector: 'new-link-waiting-auth',
  templateUrl: './new-link-waiting-auth.component.html',
  styleUrls: ['./new-link-waiting-auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewLinkWaitingAuthComponent {
  @Output() closeSyncMessage = new EventEmitter();
  @Output() abort = new EventEmitter();
  @Input() isCreating: boolean;
  theme: Preferences.Theme = getTheme(currentTheme());
}
