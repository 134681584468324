import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { InputText } from 'primeng/inputtext';
import { UiIconModel } from '../../types/ui.icon.model';

type InlineExtraIconPos = 'right' | 'left';

@Component({
  selector: 'u-input',
  templateUrl: './u-input.component.html',
  styleUrls: ['./u-input.component.scss'],
})
export class UInputComponent implements OnInit, AfterViewInit {
  inputsReady = false;

  @Input() placeholder: string = null;
  @Input() model = '';
  @Input() disabled = false;
  @Input() styles: any = {};
  @Input() isSearchInput = false;
  @Input() maxlength: number;
  @Input() inputSize = 'large';
  @Input() removeText = false;
  @ViewChild(InputText) inputElement: InputText;
  @Input() isLoading = false;
  @Input() isLoadingRight = false;
  @Input() type = 'text';
  @Input() pTooltipTextClear = 'Clear';
  @Input() errorBorder = false;
  @Input() copyOnly = false;
  @Input() backgroundColor: string;
  @Input() textColor: string = null;
  @Input() autoComplete: 'email' | 'on' | 'off' = 'off';
  @Input() errorText: string;
  @Input() readonly = false;
  @Input() inputPattern: string;
  @Input() label = '';
  @Input() extraIcon: UiIconModel;
  @Input() inlineExtraIcon: UiIconModel;
  @Input() ignoreEnter: boolean;

  @Input() set inputFocus(value: boolean) {
    this._focus = value;
    this.isInputFocus();
  }
  @Input() public set inlineExtraIconPos(value: InlineExtraIconPos) {
    this._inlineExtraIconPos = value;
    this.inlineExtraIconClass = `inline-extra-icon-${this._inlineExtraIconPos}`;
  }

  _focus: boolean;
  private _inlineExtraIconPos: InlineExtraIconPos = 'left';
  public get inlineExtraIconPos(): InlineExtraIconPos {
    return this._inlineExtraIconPos;
  }

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFocus: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
  @Output() iconClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onKeydown: EventEmitter<any> = new EventEmitter<any>();

  inlineExtraIconClass: string;

  ngOnInit(): void {
    this.inputsReady = true;
  }

  isInputFocus(): void {
    if (this._focus) {
      this.focusInput();
    } else {
      this.blurInput();
    }
  }

  focusInput() {
    this.inputElement?.el.nativeElement.focus();
  }

  focusEndInput() {
    const el = this.inputElement?.el?.nativeElement;
    if (!el) {
      return;
    }
    const endPosition = el.value.length;
    el.setSelectionRange(endPosition, endPosition);
    el.scrollLeft = el.scrollWidth;
  }

  blurInput() {
    this.inputElement?.el.nativeElement.blur();
  }

  ngAfterViewInit(): void {
    this.isInputFocus();
  }

  onChangeEvent($event) {
    this.onChange.emit($event);
  }

  copyTextInput($event) {
    return ($event.ctrlKey && ( $event.key === 'c'))
  }
  keydown($event) {
    this.onKeydown.emit($event);
    if (this.inputPattern) {
      const reg = new RegExp(this.inputPattern);
      if (reg.test($event.key)) {
        return true;
      } else {
        $event.preventDefault();
        return false;
      }
    }
    if (this.copyOnly && !this.copyTextInput($event)) {
      $event.preventDefault();
    }
  }

  onEnterEvent($event) {
    if (this.ignoreEnter) {
      return;
    }
    $event.stopPropagation();
    this.onEnter.emit({ target: $event, value: this.model });
  }

  onPaste($event) {
    const text = $event.clipboardData?.getData('text');
    if (this.inputPattern) {
      const reg = new RegExp(`^${this.inputPattern}+$`);
      if (reg.test(text)) {
        return true;
      } else {
        $event.preventDefault();
        return false;
      }
    }
  }

  blur($event) {
    this.onBlur.emit({ target: $event, value: this.model });
  }

  focus($event): boolean {
    this.onFocus.emit({ target: $event, value: this.model });
    return true;
  }

  onClearEvent() {
    this.model = '';
    this.onChange.emit('');
    this.onClear.emit();
  }

  invokeIconClick(event) {
    this.iconClick.emit(event);
  }
}
