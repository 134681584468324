import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Style } from '@local/client-contracts';

@Component({
  selector: 'multi-select-value',
  templateUrl: './multi-select-value.component.html',
  styleUrls: ['./multi-select-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectValueComponent {
  @Input() item;
  @Input() showItemIcon: boolean;
  @Input() showItemLabel: boolean;
  @Input() showRemoveIcon: boolean;
  @Input() showItemIconLabel: boolean;
  @Input() showIndicatorInList: boolean;
  @Input() iconLabelOnHover: Style.EntityIcon<Style.EntityIconType>;
  @Input() maxCharacters: number;
}
