import { MemorySearch, Search } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';

export class MemorySearchRpcInvoker implements MemorySearch.Service {
  @invoker
  search(request: MemorySearch.Request): Promise<MemorySearch.Response> {
    return;
  }

  @invoker
  rank(queryTokens: string[], items: MemorySearch.Item[], sorting: Search.Sort): Promise<MemorySearch.Item[]> {
    return;
  }
}
