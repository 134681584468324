<div [style.height]="!nodes?.length ? '0px' : '100%'">
  <ng-scrollbar [viewClass]="'sidebar-container'" visibility="hover" [style.maxHeight]="'calc(100vh - 130px)'">
    <tree-root
      class="tree"
      (toggleExpanded)="$event.isExpanded === false && unsubscribe($event.node.data.id)"
      [nodes]="nodes$ | async"
      [options]="treeOptions"
      [style.opacity]="nodes?.length === 1 ? 0 : 1"
    >
      <ng-template #treeNodeFullTemplate let-node let-index="index" let-model="node.data" let-templates="templates">
        <div
          [class]="node.getClass() + ' type-' + model.type + ' group-' + model.group"
          [class.tree-node]="true"
          [class.tree-node-expanded]="node.isExpanded && node.hasChildren"
          [class.tree-node-collapsed]="node.isCollapsed && node.hasChildren"
          [class.tree-node-leaf]="node.isLeaf"
          [class.tree-node-active]="activeNode?.id === node.id"
          [class.tree-node-focused]="node.isFocused"
          [class.tree-node-separator]="node.isFocused"
          [attr.data-node-id]="model.id"
        >
          <tree-node-wrapper [node]="node" [index]="index" [templates]="templates"> </tree-node-wrapper>
          <tree-node-children [node]="node" [templates]="templates"> </tree-node-children>
        </div>
      </ng-template>

      <ng-template #treeNodeWrapperTemplate let-node let-model="node.data" let-index="index" let-templates="templates">
        <div
          [id]="node.data.title"
          class="node-wrapper"
          (click)="node.mouseAction('click', $event); onNodeClicked(node)"
          (dblclick)="node.mouseAction('dblClick', $event)"
          (contextmenu)="node.mouseAction('contextMenu', $event)"
          [class.separator]="model.type === 'separator'"
          [class.node-wrapper-active]="activeNode?.id === node.id"
          [class]="'type-' + model.type"
        >
          <ng-container *ngIf="model.type !== 'separator'">
            <tree-node-expander
              class="tree-node-expander"
              [attr.data-cy]="'menu-item-expander-' + node.data.title"
              [attr.parent-id]="node.data.parentId === '' ? 'no-parent' : node.data.parentId"
              (click)="$event.stopPropagation()"
              [node]="node"
            ></tree-node-expander>
            <div
              class="node-content-wrapper"
              [class.with-extra-icon]="!!node.data?.data?.count || node.data.indicationIcon"
              [attr.data-cy]="'menu-item-container-' + node.data.title"
              [attr.parent-id]="node.data.parentId === '' ? 'no-parent' : node.data.parentId"
              [attr.group]="node.data.group"
            >
              <tree-node-content class="tree-node-content" [node]="node" [index]="index" [template]="templates.treeNodeTemplate">
              </tree-node-content>
            </div>

            <entity-icon
              *ngIf="node.data.indicationIcon"
              [model]="node.data.indicationIcon"
              [size]="'14px'"
              [style]="{ paddingLeft: '4px', paddingRight: '16px' }"
            ></entity-icon>

            <div *ngIf="node.data?.data?.count" class="node-count" [class.node-count-three-digit]="node?.data?.data?.count >= 100">
              {{ node.data.data.count }}
            </div>
          </ng-container>
        </div>
      </ng-template>

      <ng-template #treeNodeTemplate let-node let-model="node.data" let-index="index">
        <ng-container *ngTemplateOutlet="model.type === 'separator' ? separator : standard; context: { model: model }"></ng-container>

        <!-- Standard  -->
        <ng-template
          #standard
          let-model="model"
          let-title="model.title"
          let-badge="model.badge"
          let-icon="model.icon"
          let-parentId="model.parentId"
        >
          <entity-icon
            *ngIf="icon && !model?.emoji"
            [attr.parent-id]="parentId === '' ? 'no-parent' : parentId"
            [attr.data-cy]="'menu-item-icon-' + title"
            [class]="'tree-node-icon ' + 'tree-node-icon-' + icon.type"
            [model]="icon"
            [size]="'14px'"
            [mirrorClass]="false"
            [style.color]="model.iconColor"
          ></entity-icon>

          <u-emoji *ngIf="model?.emoji" class="emoji" [spriteEmojiSize]="14" [unicode]="model?.emoji"> </u-emoji>

          <span
            [attr.parent-id]="parentId === '' ? 'no-parent' : parentId"
            [attr.data-cy]="'menu-item-text-' + title"
            class="tree-node-text"
            [class.flex-it]="model?.actionIcon"
            [ngStyle]="model?.styles"
            >{{ title }}</span
          >

          <u-icon
            class="menu-action-icon"
            *ngIf="model?.actionIcon"
            [model]="model.actionIcon"
            [style]="{ fontSize: '12px' }"
            (click)="onActionIconClick(model, $event)"
            [class.active]="activeActions.has(model.id)"
          ></u-icon>

          <label class="tree-node-badge" *ngIf="badge">{{ badge }}</label>
        </ng-template>

        <!-- Separator -->
        <ng-template #separator>
          <hr />
        </ng-template>
      </ng-template>
    </tree-root>
  </ng-scrollbar>
</div>

<ng-container *ngIf="!nodes?.length">
  <div class="list-ghost">
    <div class="item-container" *ngFor="let i of 8 | fill; index as index">
      <div class="left-ghost ghost"></div>
      <div class="right-ghost ghost" [class.first-ghost]="index === 0"></div>
    </div>
  </div>
</ng-container>
