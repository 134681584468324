<u-dropdown
  #filterElement
  [options]="options"
  class="filter dropdown-filter free-text-dropdown"
  [filter]="true"
  [placeholder]="model.title + ': ' + (selectedFilterValue || '')"
  dropdownSize="small"
  [dropdownSelectIcon]="model.icon"
  (onFilter)="filterChanged($event)"
  (onClearFilter)="onClearFilter()"
  [filterPlaceholder]="filterPlaceholder"
  [hideItemsContainer]="true"
  (onShow)="onPanel(true)"
  (onHide)="onPanel(false)"
  [appendTo]="appendTo"
  [hasValues]="filterInputValue !== ''"
  [open]="open"
  [hideOnClickOut]="hideOnClickOut"
  [disableHandleKeys]="true"
>
</u-dropdown>
