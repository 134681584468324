import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FlagsService } from '@shared/services/flags.service';
import { RouterService } from '@shared/services/router.service';

export abstract class CanActivateViewGuard {
  protected abstract flag: string;

  constructor(private routerService: RouterService, private flags: FlagsService) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const enabled = await this.flags.isEnabled(this.flag).then((v) => !v);
    if (!enabled) {
      this.routerService.navigateByUrl('/');
    }
    return enabled;
  }
}
