import { Injectable } from '@angular/core';
import { Calendar, CalendarRpcInvoker } from '@local/common-web';
import { ServicesRpcService } from '@shared/services';
import { Shortcuts } from '@shared/services/keyboard.service';
import { TimerService } from '@shared/services/timer.service';
import { KeyName } from '@local/ts-infra'
import moment, { CalendarSpec } from 'moment';
import { fromEvent, map, Observable, take } from 'rxjs';
import { IActionItem } from '../models/action-item.model';
import { CalendarEvent, ResultItem } from '../views';
import { CalendarDisplayModel } from '../views/home-page/models/calendar-display-model';
import { DateUtil } from '../views/calendar-page/up-next/date-util';
import { UpNextDisplayModel } from '../views/calendar-page/up-next/up-next-result-item.component';

export interface WatchCalendarContext {
  events: CalendarEvent[];
  searchDone?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  private calendarFormats: CalendarSpec;
  private service: Calendar;
  private lastFocusWatch = 0;

  private readonly FOCUS_WATCH_INTERVAL = 10 * 1000;

  constructor(protected rpc: ServicesRpcService, private timer: TimerService) {
    this.service = this.rpc.invokeWith(CalendarRpcInvoker, 'calendar');
    this.calendarFormats = {
      sameDay: `[Today]`,
      nextDay: `[Tomorrow]`,
      nextWeek: `MMM D, YYYY`,
      sameElse: `MMM D, YYYY`,
    };

    fromEvent(window, 'focus').subscribe(async () => {
      const now = Date.now();
      if (this.lastFocusWatch + this.FOCUS_WATCH_INTERVAL < now) {
        this.lastFocusWatch = now;
        this.watch$(now)
          .pipe(take(1))
          .subscribe(() => {});
      }
    });

    this.timer.register(
      () => {
        this.watch$(Date.now())
          .pipe(take(1))
          .subscribe(() => {});
      },
      { visibleInterval: 1000 * 60 * 5 }
    );
  }

  watch$(start?: number, end?: number, withRtf?: boolean): Observable<WatchCalendarContext> {
    return this.service.watch$({ start, end, withRtf }).pipe(
      map((r) => {
        const events =
          r.events?.map(
            (x) =>
              ({
                ...x,
                displayIndex: x.displayIndex,
                originalStartDate: moment(x.start),
                originalEndDate: moment(x.end),
                start: moment(x.start),
                end: moment(x.end),
                rsvp: x.rsvp,
                isAllDay: x.isAllDay,
              } as CalendarEvent)
          ) || [];
        return {
          ...r,
          events,
        } as WatchCalendarContext;
      })
    );
  }

  protected removeOutOfRange(items: CalendarEvent[], start, end) {
    return items?.filter((item) => item.start?.isSameOrAfter(start) && item.end?.isSameOrBefore(end));
  }

  protected adjustFullDayEventsTimeZone(data: CalendarEvent[]): CalendarEvent[] {
    // Events that were defined as full day events in gCalendar and office365 are expected to have the 'event.isFullDay' trait.
    // In this case the event start and end times are 00:00:00 with no timezone offset regardless of the calendar's timezone,
    // So we have to adjust them to the local time.
    return data.map((item) => {
      const upNextEvent: CalendarEvent = { ...item };
      upNextEvent.start = moment(item.start);
      upNextEvent.end = moment(item.end);

      if (item.isAllDay) {
        const utcOffset = upNextEvent.start.utcOffset();
        upNextEvent.start = moment(upNextEvent.start.subtract(utcOffset, 'minutes').utc().format());
        upNextEvent.end = moment(upNextEvent.end.subtract(utcOffset, 'minutes').utc().format());
      }

      upNextEvent.originalStartDate = upNextEvent.start.clone();
      upNextEvent.originalEndDate = upNextEvent.end.clone();

      return upNextEvent;
    });
  }

  organizeEventsByDay(data: CalendarEvent[]): Record<number, CalendarEvent[]> {
    const eventsByDay: Record<number, CalendarEvent[]> = {};
    data.sort((itemA, itemB) => itemA.start.diff(itemB.start));

    data.forEach((event: CalendarEvent) => {
      const date = event.start.clone().startOf('day').unix();
      if (eventsByDay[date]) {
        if (DateUtil.isAllDayEvent(event.start, event.end)) {
          // All day events should always appear on top
          eventsByDay[date].unshift(event);
        } else {
          eventsByDay[date].push(event);
        }
      } else {
        eventsByDay[date] = [event];
      }
    });
    return eventsByDay;
  }

  getEventType(start: moment.Moment, end: moment.Moment): string {
    const now = moment();
    if (DateUtil.isAllDayEvent(start, end)) {
      return 'Full day';
    } else if (now >= start && now < end) {
      return 'In progress';
    } else {
      return start.format('h:mm a')?.split(':00').join('');
    }
  }

  setTimeFields(start: moment.Moment, end: moment.Moment, displayModel: CalendarDisplayModel | UpNextDisplayModel): void {
    let subtractedOneDay = false;
    if (DateUtil.isMidnight(end) && moment.duration(end.diff(start)).asDays() >= 1) {
      // Removing one day from the events that have more then a day and end at midnight, for example:
      // Full day event that starts on Jan 1st at midnight and end at Jan 3rd at midnight - will not include Jan 3rd in the displayed subtitle.
      end.subtract(1, 'days');
      subtractedOneDay = true;
    }

    let formattedTime: string;

    if (end.diff(start) === 0 && DateUtil.isAllDayEvent(start, end)) {
      formattedTime = start.calendar(this.calendarFormats);
    } else {
      const endWithDayFix = DateUtil.isMidnight(end) && !subtractedOneDay ? end.clone().subtract(1, 'day') : end;
      const isSameYear = start.year() === endWithDayFix.year();
      const isSameMonth = isSameYear && start.month() === endWithDayFix.month();
      const isSameDay = isSameMonth && start.date() === endWithDayFix.date();
      const showAmPm = !isSameDay || start.format('a') !== end.format('a');
      formattedTime = `${start.format(this.getMultidayTimeFormat(start, isSameYear, isSameDay, showAmPm))} - 
      ${end.format(this.getMultidayTimeFormat(end, isSameYear, isSameDay, true))}`;
    }

    displayModel.time = formattedTime;
  }

  private getMultidayTimeFormat(date: moment.Moment, isSameYear: boolean, isSameDay: boolean, showAmPm: boolean): string {
    const day = isSameDay ? null : 'MMM D';
    const time = DateUtil.isMidnight(date) && !isSameDay ? null : `${date.minutes() > 0 ? 'h:mm' : 'h'}${showAmPm ? 'a' : ''}`;
    const year = isSameYear ? null : `${date.format('YYYY')}`;
    return [day, time, year].filter((str) => str !== null).join(', ');
  }

  getMeetingsAction(model: ResultItem, shortcuts: Partial<Shortcuts>): IActionItem[] {
    const videoBulletNames = ['hangouts', 'zoom', 'teams'];
    const bullets = model?.view?.bullets?.find((bullet) =>
      bullet?.parts?.find((bulletPart) => videoBulletNames.includes(bulletPart.text?.toLowerCase()))
    );
    if (!bullets || !bullets?.parts?.length) return [];
    const action = bullets.parts[0];
    return [
      {
        faIcon: () => 'icon-camera',
        id: `meeting-${action.text}`,
        toolTipText: action.text,
        fn: (item, trigger) => item.onInvoke(action.onClick, trigger || shortcuts.video.join('_')),
        keyBinding: shortcuts.video as Array<KeyName>,
      },
    ];
  }
}
