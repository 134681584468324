import { Commands } from '@local/client-contracts';
import { GeneralPreferencesCommand } from 'src/app/bar/models/preferences-command';
import { SelectParamCommand } from 'src/app/bar/models/select-param-command';
import { ShareCommand } from 'src/app/bar/models/share-command';

export function isCopyToClipboardCommand(command: Commands.Command | Commands.CopyClipboard): command is Commands.CopyClipboard {
  return command && command.type === 'copy-clipboard';
}

export function isRunActionCommand(command: Commands.Command | Commands.RunAction): command is Commands.RunAction {
  return command && command.type === 'run-action';
}

export function isOpenUrlCommand(command: Commands.Command | Commands.OpenUrl): command is Commands.OpenUrl {
  return command && command.type === 'open-url';
}

export function isCopyTextCommand(command: Commands.DragCommand): command is Commands.CopyText {
  return command && command.type === 'copy-text';
}

export function isDownloadUrlCommand(command: Commands.DragCommand): command is Commands.DownloadUrl {
  return command && command.type === 'download-url';
}

export function isDownloadedFileCommand(command: Commands.DragCommand): command is Commands.DownloadedFile {
  return command && command.type === 'downloaded-file';
}

export function isOpenPageCommand(command: Commands.Command): command is Commands.OpenPageCommand {
  return command?.type === 'open-page';
}

export function isOpenUrlCommandResponse(response: any): response is Commands.OpenUrlResponse {
  return response?.type === 'open-url' && response?.data.askUserInput;
}

export function isShareCommand(command: Commands.Command): command is ShareCommand {
  return command.type === 'share';
}

export function isGeneralPreferencesCommand(command: Commands.Command): command is GeneralPreferencesCommand {
  return command.type === 'preferences';
}

export function isSelectParamCommand(command: any): command is SelectParamCommand {
  return command.type === 'select-param' && isOpenUrlCommand(command.value);
}

export function isChangeFavoriteStatusCommand(command: Commands.Command): command is Commands.ChangeFavoriteStatus {
  return command?.type === 'change-favorite-status';
}

export function copyToClipboard(text: string): Promise<void> {
  return navigator.clipboard.writeText(text);
}

export const isLocalActionContextResource = (x: any): x is Commands.LocalActionContextResource => x.kind === 'local-action';

export const isLinkContextResource = (x: any): x is Commands.LinkContextResource => x.kind === 'link-resource';

export const isNewCollectionCommand = (command: any): command is Commands.DynamicCommand => command.type === 'new-collection';

export const isDynamicCommand = (command: any): command is Commands.DynamicCommand => command.type === 'dynamicCommands';

export const isOpenFileCommand = (command: any): command is Commands.DynamicCommand => command.type === 'open-file';

export const isPreviewCommand = (command: any): command is Commands.Preview<any> => command.type === 'preview';

export const isSummaryCommand = (command: any): command is Commands.Summary => command.type === 'summary';
