import { Injectable } from '@angular/core';
import { Applications, Filters } from '@local/client-contracts';
import { isEmbed } from '@local/common-web';
import { AppService } from '@shared/services/app.service';
import { ApplicationsService } from '@shared/services/applications.service';
import { LinksService } from '@shared/services/links.service';
import { RouterService } from '@shared/services/router.service';
import { SuggestionsItem } from '../views';
import { FiltersService } from './filters.service';
import { HubService } from './hub.service';
import { SUGGESTIONS } from './suggestions-search.constants';
import { FlagsService } from '@shared/services/flags.service';
import { ChatsService } from '../views/chat-page/services/chats.service';

export type SuggestionSearch = {
  item: SuggestionsItem;
  condition?: {
    filters?: Filters.ActiveFilters;
    group?: { filterName: string; expectedValues: 'me' | string[] };
  };
  disableFlag?: string;
  status?: boolean;
};
@Injectable()
export class SuggestionsSearchService {
  private connectedApps: Applications.DisplayItem[];
  private isEmbed: boolean = isEmbed();

  async getSuggestions(): Promise<SuggestionsItem[]> {
    await this.initFilters();
    return Object.entries(SUGGESTIONS)
      .filter(([_, value]) => value?.status)
      .map(([_, value]) => value.item);
  }

  constructor(
    private filtersService: FiltersService,
    private routerService: RouterService,
    private hubService: HubService,
    private applicationsService: ApplicationsService,
    private linksService: LinksService,
    private appService: AppService,
    private flagsService: FlagsService,
    private chatsService: ChatsService
  ) {
    this.applicationsService.all$.subscribe((res) => {
      this.connectedApps = res.filter((app) => app.links?.length);
    });
    this.appService.windowStyle$.subscribe((windowType) => {
      if (windowType !== 'standard') {
        SUGGESTIONS.search.status = true;
      }
    });
    this.linksService.peopleLinkId$.subscribe((peopleLinkId) => {
      const status = !!peopleLinkId;
      if (status !== SUGGESTIONS.peopleInMyTeam.status) {
        SUGGESTIONS.peopleInMyTeam.status = status;
      }
    });
  }

  private async initFilters(): Promise<void> {
    const tasks = [];
    Object.entries(SUGGESTIONS)
      .filter(([_, suggestionValue]) => suggestionValue.condition || suggestionValue.disableFlag)
      .forEach(async ([suggestionName, suggestionValue]) => {
        const task = this.initFiltersToSuggestion(suggestionName, suggestionValue);
        tasks.push(task);
      });
    await Promise.all(tasks);
  }

  private async initFiltersToSuggestion(suggestionName: string, suggestionValue: SuggestionSearch) {
    const suggestion = SUGGESTIONS[suggestionName];
    if (suggestionValue.disableFlag) {
      const disabled = await this.flagsService.isEnabled(suggestionValue.disableFlag);
      if (disabled) {
        suggestion.status = false;
        return;
      }
      if (!suggestionValue.condition) {
        suggestion.status = true;
        return;
      }
    }
    const group = suggestionValue.condition?.group;
    let res = await this.filtersService.getSuggestions(
      '',
      group?.filterName,
      'box',
      { returnAllValues: true },
      suggestionValue.condition?.filters
    );
    res = res.filter((s) => (group?.expectedValues === 'me' ? s.title.includes('(Me)') : group?.expectedValues.includes(s.title)));

    if (res.length) {
      let suggestionFilters = suggestion.item.filters;
      suggestion.status = true;
      let types: string[] = suggestion.condition.filters.type.map((type) => type.value);
      const apps = this.connectedApps?.filter((app) => suggestion.condition.filters.app.map((a) => a.value)?.includes(app.name)) || [];
      const connectTypes: string[] = [];
      if (apps.length) {
        apps.forEach((app) => connectTypes.push(...app.resources.map((resource) => resource.title)));
        types = types.filter((type) => connectTypes.includes(type));
      }
      suggestionFilters = suggestionFilters.filter((s) => !['app', 'type', group.filterName].includes(s.name));
      suggestionFilters.push({ name: 'app', values: apps.map((a) => a.name) });
      suggestionFilters.push({ name: 'type', values: types });
      const values = new Set<string>();
      res.forEach((item) => {
        const value = group?.expectedValues === 'me' ? item.subtitle : item.title;
        values.add(value);
      });
      suggestionFilters.push({ name: group.filterName, values: [...values] });
      Object.entries(suggestion.condition.filters)
        .filter(([name, _]) => !['app', 'type'].includes(name))
        .forEach(([name, values]) => {
          suggestionFilters = suggestionFilters.filter((s) => ![name].includes(s.name));
          suggestionFilters.push({ name, values: values.map((t) => t.value) });
        });
      suggestion.item.filters = suggestionFilters;
    } else {
      suggestion.status = false;
    }
  }
  onChatSelected() {
    this.chatsService.openChat();
  }
  async onSuggestionSelected(item: SuggestionsItem) {
    if (item.page === 'chat') {
      this.onChatSelected();
      return;
    }
    if (
      item.redirectType === 'Page' ||
      ((this.isEmbed || (await this.hubService.getIsLauncher())) && item.launcherRedirectType == 'Page')
    ) {
      await this.hubService.addActivePage(item.page);
    } else {
      if (item.openExternal) {
        if (isEmbed()) {
          this.hubService.openStandardEmbed(`/${item.page}`);
        } else {
          this.appService.switchToStandard();
        }
        return;
      }
      await this.routerService.navigateByUrl(`/${item.page}`, { replaceUrl: this.isEmbed });
    }
    if (item.filters) {
      item.filters.forEach((filter) => {
        this.filtersService.addFilter(filter.name, filter.values, true);
      });
    }
    this.hubService.setState('search-trigger', 'suggestions');
  }
}
