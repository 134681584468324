<div class="link-tag" [ngClass]="{ 'tag-view': viewMode === 'tag' }">
  <ng-container *ngIf="tag.path?.length; else defaultTag">
    <ng-container *ngTemplateOutlet="linkTagPath; context: { path: tag.path }"></ng-container>
  </ng-container>
  <ng-template #defaultTag>
    <ng-container *ngTemplateOutlet="linkTagView; context: { tag: tag }"></ng-container>
  </ng-template>
</div>

<ng-template #linkTagPath let-path="path">
  <div class="link-tag-path" *ngFor="let tag of path">
    <ng-container *ngTemplateOutlet="linkTagView; context: { tag: tag }"></ng-container>
    <span class="separate-tag">/</span>
  </div>
</ng-template>

<ng-template #linkTagView let-tag="tag">
  <ng-container *ngIf="isEmoji(tag.icon?.lightUrl); else noEmoji">
    <u-icon iconCtx="url" class="result-icon-logo no-drag" [model]="{ type: 'img', value: tag.icon }"></u-icon>
  </ng-container>
  <ng-template #noEmoji>
    <u-icon *ngIf="tag.icon" [model]="{ type: 'img', value: tag.icon }" class="link-tag-icon"></u-icon>
  </ng-template>
  <span
    [pTooltip]="tag.tooltip?.length > maxCharsCount ? tag.tooltip : ''"
    tooltipPosition="top"
    tooltipStyleClass="u-tooltip"
    class="link-tag-name"
    [innerHtml]="tag.text | highlight : markedText : highlightStyle"
  ></span>
</ng-template>
