import { Injectable } from '@angular/core';
import { KeyValueStorage, MemoryKeyValueStorage } from '@local/common';
import { DixieKeyValueStorage } from '@local/common-web';
import { delay } from '@local/ts-infra';
import { EmbedService } from '@shared/embed.service';
import { Logger } from '@unleash-tech/js-logger';
import { firstValueFrom } from 'rxjs';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private logger: Logger;

  constructor(private embed: EmbedService, logService: LogService) {
    this.logger = logService.scope('LocalStorageService');
  }

  async create(name: string): Promise<KeyValueStorage> {
    if (await this.embed?.isExternalWebSite()) {
      const opts = await firstValueFrom(this.embed.options$);
      return this.createStorageSafe({ name: name + '-embed-' + opts.id });
    }

    return this.createStorageSafe({ name });
  }

  private async createStorageSafe(settings: { name: string; storeName?: string }): Promise<KeyValueStorage> {
    try {
      const MIN_SIZE = 1024 * 10;
      try {
        if (self.navigator?.storage?.estimate) {
          const estimate = await self.navigator?.storage?.estimate();
          if (estimate.quota && estimate.usage) {
            if (estimate.quota - estimate.usage < MIN_SIZE) {
              console.info('fall back to memory storage not enough space ' + estimate.quota + ' ' + estimate.usage);
              return new MemoryKeyValueStorage();
            }
            return DixieKeyValueStorage.create(settings, this.logger);
          }
        }
      } catch (e) {}
      const storage = DixieKeyValueStorage.create(settings, this.logger);
      const BUFFER = new ArrayBuffer(1024);
      const ARR = new Array(BUFFER);

      for (let i = 0; i < 2; ++i) {
        // give two attempts for writing the buffer before deciding storage is not available
        try {
          const entry = storage.entry('__test_storage');
          await entry.set(ARR); // local forage works in lazy fashion so only failst on first get
          await entry.remove();
          break;
        } catch (e) {
          if (i == 1) throw e;

          await delay(500);
        }
      }
      return storage;
    } catch (e) {
      console.error('failed to create local storage via local forage (indexedb,localstorage)', e);
      console.info('fall back to memory storage');
      return new MemoryKeyValueStorage();
    }
  }
}
