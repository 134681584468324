import { Constants } from '@local/common';
import { SuggestionSearch } from './suggestions-search.service';

export const SUGGESTIONS: Record<string, SuggestionSearch> = {
  search: {
    item: {
      type: 'suggestions',
      redirectType: 'Url',
      title: 'Search',
      icon: { type: 'font-icon', value: 'icon-magnifier' },
      page: 'search',
      openExternal: true,
    },
    status: false,
  },
  chat: {
    item: {
      type: 'suggestions',
      redirectType: 'Url',
      title: 'Chat',
      icon: { type: 'font-icon', value: 'icon-chat-dots' },
      page: 'chat',
      launcherRedirectType: 'Page',
    },
    status: true,
  },
  favorites: {
    item: {
      type: 'suggestions',
      redirectType: 'Url',
      title: 'Favorites',
      icon: { type: 'font-icon', value: 'icon-star' },
      page: 'favorites',
      launcherRedirectType: 'Page',
    },
    status: true,
  },
  myCollections: {
    item: {
      type: 'suggestions',
      redirectType: 'Url',
      title: 'Collections',
      icon: { type: 'font-icon', value: 'icon-collection' },
      page: 'collections',
      launcherRedirectType: 'Page',
    },
    disableFlag: Constants.DISABLED_COLLECTIONS_FLAG,
    status: true,
  },
  myWikis: {
    item: {
      type: 'suggestions',
      redirectType: 'Url',
      title: 'Wikis',
      icon: { type: 'font-icon', value: 'icon-wiki' },
      page: 'wikis',
      launcherRedirectType: 'Page',
    },
    disableFlag: Constants.DISABLED_WIKIS_FLAG,
    status: true,
  },
  myGoLinks: {
    item: {
      title: 'Go Links',
      redirectType: 'Url',
      type: 'suggestions',
      icon: { type: 'font-icon', value: 'icon-link2' },
      page: 'golinks',
      launcherRedirectType: 'Page',
    },
    disableFlag: Constants.DISABLED_GO_LINKS_FLAG,
    status: true,
  },
  peopleInMyTeam: {
    item: {
      type: 'suggestions',
      redirectType: 'Url',
      title: 'People',
      icon: { type: 'font-icon', value: 'font-icon icon-people-group' },
      page: 'people',
      launcherRedirectType: 'Page',
    },
  },
  relevantPeople: {
    item: {
      type: 'suggestions',
      redirectType: 'Url',
      title: 'Relevant People',
      icon: { type: 'font-icon', value: 'font-icon icon-people-group' },
      page: 'relevant-people',
      launcherRedirectType: 'Page',
    },
  },
  myCards: {
    item: {
      type: 'suggestions',
      redirectType: 'Url',
      title: 'Cards',
      icon: { type: 'font-icon', value: 'icon-lot-of-cards' },
      page: 'cards',
      launcherRedirectType: 'Page',
    },
    disableFlag: Constants.DISABLED_WIKIS_FLAG,
    status: true,
  },
  myDrafts: {
    item: {
      type: 'suggestions',
      redirectType: 'Url',
      title: 'My Drafts',
      icon: { type: 'font-icon', value: 'icon-draft' },
      page: 'drafts',
      launcherRedirectType: 'Page',
    },
    disableFlag: Constants.DISABLED_WIKIS_FLAG,
    status: true,
  },
  myAssistants: {
    item: {
      type: 'suggestions',
      redirectType: 'Url',
      title: 'Assistants',
      icon: { type: 'font-icon', value: 'icon-assistant' },
      page: 'assistants',
      launcherRedirectType: 'Page',
    },
    status: true,
  },
  /*  pendingPullRequests: {
    item: {
      type: 'suggestions',
      itemType: 'Page',
      title: 'Pending Pull requests',
      icon: { type: 'font-icon', value: 'icon-arrow-circle' },
      filters: [],
    },
    condition: {
      filters: {
        app: [{ value: 'GitLab' }, { value: 'GitHub' }],
        type: [{ value: 'Merge Request' }, { value: 'Pull Request' }],
        status: [{ value: 'opened' }, { value: 'Open' }],
      },
      group: { filterName: 'reviewer', expectedValues: 'me' },
    },
  },
  myIssues: {
    item: {
      type: 'suggestions',
      itemType: 'Page',
      title: 'My Issues',
      icon: { type: 'font-icon', value: 'icon-circle' },
      filters: [],
    },
    condition: {
      filters: {
        app: [{ value: 'GitHub' }, { value: 'Jira' }, { value: 'ClickUp' }, { value: 'Asana' }, { value: 'Monday' }],
        type: [{ value: 'Task' }, { value: 'Issue' }, { value: 'Item' }],
      },
      group: { filterName: 'assignedTo', expectedValues: 'me' },
    },
  },*/
};
