interface ResourceLookupResource {
  iconUrl?: string;
  subtitle?: string;
  link: string;
  title: string;
}

interface ResourceLookupAnswer {
  answer: string;
  resources: ResourceLookupResource[];
}

const parseResourceLookup = (jsonString: string): ResourceLookupAnswer => {
  let obj: ResourceLookupAnswer;
  try {
    obj = JSON.parse(jsonString);
  } catch {}

  return obj;
};

export const buildResourceLookupFromJson = (jsonString: string): string => {
  const answer = parseResourceLookup(jsonString);
  if (!answer) {
    return '';
  }
  const mainHtml = `<p class="resource-lookup">${answer.answer.replace('**"', '<strong>"').replace('"**', '"</strong>')}</p> `;

  const resourceItemsHtml = answer.resources.map((r: { iconUrl: string; subtitle: string; link: string; title: string }) => {
    const iconHtml = r.iconUrl ? `<img style="width:24px;height:24px!important" src="${r.iconUrl}" alt="resource icon"/>` : '';
    const linkHtml = `<a ${r.subtitle ? 'class="with-sub-title"' : ''} target="_blank" href="${r.link}">${r.title}</a>`;
    const subtitleHtml = r.subtitle ? `<span>${r.subtitle}</span>` : '';
    return `<p class="resource-item">
      ${iconHtml}
      ${linkHtml}
      ${subtitleHtml}</p> `;
  });

  return `${mainHtml}
  ${resourceItemsHtml.join('')}`;
};

export const buildTextResourceLookupFromJson = (jsonString: string): string => {
  const answer = parseResourceLookup(jsonString);
  if (!answer) {
    return '';
  }
  return `${answer.answer}
 ${answer.resources.map((r) => `[${r.title}](${r.link})`).join('\n')}`;
};
