import { Pipe, PipeTransform } from '@angular/core';
import { Applications } from '@local/client-contracts';
import { AppsFilter } from './apps-filter';

@Pipe({
  name: 'apps',
})
export class AppsPipe implements PipeTransform {
  transform(items: Applications.DisplayItem[], filter: AppsFilter): Applications.DisplayItem[] {
    if (!filter) return items;
    return items.filter((item) => this.predicate(item, filter)).sort((a, b) => (a.links?.length || 0) - (b.links?.length || 0));
  }

  predicate(item: Applications.DisplayItem, filter: AppsFilter): boolean {
    return !filter?.query || item?.name.toLowerCase().includes(filter.query.toLowerCase());
  }
}
