import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApplicationsService } from '@shared/services/applications.service';
import { RouterService } from '@shared/services/router.service';
import { TitleBarService } from '@shared/services/title-bar.service';
import { last } from '@shared/utils';
import { combineLatest, from, map, mergeMap, Observable, of, race, skip, take, takeUntil, timer } from 'rxjs';

@Injectable()
export class NavTreeNodeTitleResolver  {
  constructor(private applications: ApplicationsService, private titleService: TitleBarService, private router: RouterService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string | Observable<string> {
    const id = route.params['nodeId'];

    const segments: string[] = id.split('apps/');
    if (segments.length > 1 && last(segments).split('/').length === 1) {
      const appId = segments[1];
      this.lazyLoadTitle(appId, id);
    }

    return id;
  }

  /** Since until this resolve function retrun Observable.complete / Promise.resolve / value the page wont load.
   * therfore timing here is sensitive. We dont want to let other parts slow our page loading.
   * This private case override 'lazily' the default routerService behavior.
   * We provide the router service it's default which is the appId but lazily getting the name and replacing it.
   * It doesn't slow the page loading.
   */
  private lazyLoadTitle(appId: string, def: string): void {
    combineLatest([from(this.applications.cacheLoaded), this.titleService.active$])
      .pipe(
        map(() => this.applications.apps[appId]?.name ?? def),
        take(1)
      )
      .subscribe((title) => (this.titleService.active = title));
  }
}
