import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UFileUploadComponent } from './u-file-upload.component';
import { FileUploadModule } from 'primeng/fileupload';
import { UButtonModule } from '../u-button/u-button.module';
import { UIconModule } from '../u-icon/u-icon.module';
import { UiInfraModule } from '../../ui-infra.module';

@NgModule({
  declarations: [UFileUploadComponent],
  imports: [CommonModule, FileUploadModule, UButtonModule, UIconModule, UiInfraModule],
  exports: [UFileUploadComponent],
})
export class UFileUploadModule {}
