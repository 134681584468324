import { Injectable } from '@angular/core';
import { observable } from '@local/common';
import { UiIconModel } from '@local/ui-infra';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { RouterService } from './router.service';

export interface BannerMessage {
  id: string;
  priority: number;
  enableClosing: boolean;
  content: string;
  style: any;
  persistentBanner?: boolean;
  icon?: UiIconModel;
  buttonText?: string;
  locationsList?: string[];
  clickTarget?: string;
  buttonStyle?: any;
  class?: string;
  onClose?: () => void;
  onClick?: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  private _current$: ReplaySubject<BannerMessage> = new ReplaySubject<BannerMessage>(1);
  private _bannerActive$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  close$: Subject<BannerMessage> = new Subject<BannerMessage>();
  messages: BannerMessage[] = [];
  buttonClicked$: Subject<BannerMessage> = new Subject<BannerMessage>();
  hiddenMsgs: Array<BannerMessage> = [];

  @observable
  get bannerActive$(): Observable<boolean> {
    return this._bannerActive$.asObservable();
  }
  private set bannerActive(value: boolean) {
    this._bannerActive$.next(value);
  }

  @observable
  get current$(): Observable<BannerMessage> {
    return this._current$.asObservable();
  }
  private set current(value: BannerMessage) {
    this._current$.next(value);
  }

  constructor(private routerService: RouterService) {
    this.manageDisplayedMessages(window.URL.toString());
    this.routerService.active$.subscribe((url) => {
      this.manageDisplayedMessages(url);
    });
  }

  private manageDisplayedMessages(url: string): void {
    const allMessages: BannerMessage[] = [...this.messages, ...this.hiddenMsgs];
    const messages: BannerMessage[] = [];
    const hiddenMessages: BannerMessage[] = [];

    for (const msg of allMessages) {
      if (!msg?.locationsList?.length || msg.locationsList.includes(url)) {
        messages.push(msg);
      } else {
        hiddenMessages.push(msg);
      }
    }
    this.messages = messages;
    this.hiddenMsgs = hiddenMessages;
    this.sortMessage();
    this.current = this.messages[0];
    this.bannerActive = !!this.messages.length;
  }

  addMessage(msg: BannerMessage) {
    if (this.messages.some((m) => m.id === msg.id)) return;
    this.messages.push(msg);
    this.sortMessage();
    if (this.messages[0] === msg) this.current = msg;
    this.bannerActive = !!this.messages.length;
  }

  sortMessage() {
    this.messages.sort((m1, m2) => m1.priority - m2.priority);
  }

  removeMessage(msg: BannerMessage) {
    if (!msg || (!this.messages.some((m) => msg.id === m.id) && !this.hiddenMsgs.some((m) => msg.id === m.id))) return;
    this.messages = this.messages.filter((m) => m.id != msg.id);
    this.hiddenMsgs = this.hiddenMsgs.filter((m) => m.id != msg.id);
    this.current = this.messages[0];
    this.bannerActive = !!this.messages.length;
  }

  closeBanner(msg) {
    this.removeMessage(msg);
    this.close$.next(msg);
  }

  buttonClicked(message: BannerMessage) {
    this.buttonClicked$.next(message);
  }
}
