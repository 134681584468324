import { Injectable } from '@angular/core';
import { PopUpOptions, PopupRef, PopupService } from '@local/ui-infra';
import { SummaryPopupComponent, SummaryPopupData } from '../views/results/components/summary-popup/summary-popup.component';
import { ReplaySubject, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppService } from '@shared/services/app.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class SummaryService {
  private readonly MAX_POPUP_HEIGHT = 428;
  private summaryPopupRef: PopupRef<SummaryPopupComponent, SummaryPopupData>;
  popupOpen$: ReplaySubject<boolean> = new ReplaySubject(1);
  isLauncher: boolean = false;

  constructor(private popupService: PopupService, private appService: AppService) {
    this.appService.windowStyle$.pipe(untilDestroyed(this)).subscribe((b) => {
      this.isLauncher = b != 'standard';
    });
  }

  openSummaryPopup(data: SummaryPopupData) {
    if (this.summaryPopupRef) {
      this.summaryPopupRef.destroy();
    }
    const inCenter: boolean = data.position === 'center';
    const openAt: { x: number; y: number } | 'center' = this.isLauncher ? 'center' : data.position;
    let popUpOptions: PopUpOptions = { position: 'center', backdropStyle: 'blur-2' };
    if (this.isLauncher) {
      data.position = 'center';
    }
    if (!inCenter && !this.isLauncher) {
      const openPosition: number = openAt['y'];
      const maxSpace: number = window.innerHeight - openPosition;
      popUpOptions = { position: openPosition > this.MAX_POPUP_HEIGHT || maxSpace < openPosition ? 'above' : 'below' };
    }
    this.summaryPopupRef = this.popupService.open<SummaryPopupComponent, SummaryPopupData>(
      openAt,
      SummaryPopupComponent,
      { ...data, displayX: inCenter },
      popUpOptions
    );
    this.popupOpen$.next(true);

    this.summaryPopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.summaryPopupRef = null;
      this.popupOpen$.next(false);
    });
  }
}
