import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { PopupRef } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WorkspacesService } from 'src/app/bar/services';
import { PricingService } from 'src/app/bar/services/pricing.service';
import { SessionService } from '@shared/services/session.service';
import { AccountsService } from '@shared/services/accounts.service';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { KeyName } from '@local/ts-infra';

@UntilDestroy()
@Component({
  templateUrl: './plan-canceled-popup.component.html',
  styleUrls: ['./plan-canceled-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanCanceledPopupComponent implements OnInit, OnDestroy {
  @Output() selectPlanClicked = new EventEmitter<void>();

  private keyHandlerId: string;

  isOwner: boolean;
  ownerEmail: string;

  ngOnInit(): void {
    this.workspaceService.current$.pipe(untilDestroyed(this)).subscribe((workspace) => {
      if (!workspace) {
        this.ref.destroy();
        return;
      }
      this.isOwner = workspace.isOwner;
      this.cdr.markForCheck();
    });

    this.accountsService.all$.pipe(untilDestroyed(this)).subscribe((accounts) => {
      this.ownerEmail = accounts?.find((a) => a.isOwner)?.email;
      this.cdr.markForCheck();
    });
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 100);
  }

  ngOnDestroy(): void {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
      this.keyHandlerId = null;
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    event.stopPropagation();
  }

  @HostBinding('style.height')
  get height() {
    return this.isOwner ? '360px' : '320px';
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private workspaceService: WorkspacesService,
    private pricingService: PricingService,
    private sessionService: SessionService,
    private accountsService: AccountsService,
    private keyboardService: KeyboardService,
    private ref: PopupRef<PlanCanceledPopupComponent, any>
  ) {}

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    event.stopPropagation();
  }

  upgradeButtonClick() {
    this.pricingService.navigateToMyPlan();
  }

  logOut() {
    this.sessionService.signOut();
    this.ref?.destroy();
  }
}
