import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Config } from '@environments/config';
import { Keyboard } from '@local/client-contracts';
import { Constants } from '@local/common';
import { KNOWN_ERRORS, isMac, isNativeWindow } from '@local/common-web';
import { KeyName, getModifiers, isEnterKey, normalizeKeys } from '@local/ts-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EmbedService } from '@shared/embed.service';
import {
  EventsService,
  InternetService,
  LogService,
  ServicesRpcService,
  WindowService,
  componentServicesRpcProvider,
} from '@shared/services';
import { FlagsService } from '@shared/services/flags.service';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { ReportsService } from '@shared/services/reports.service';
import { RouterService } from '@shared/services/router.service';
import { TitleBarService } from '@shared/services/title-bar.service';
import { isProdEnv } from '@shared/utils';
import { stopEvent } from '@shared/utils/elements-util';
import * as moment from 'moment/moment';
import { BehaviorSubject, Observable, Subject, firstValueFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CommandBarService } from 'src/app/bar/services/command-bar.service';
import { GoLinksService } from 'src/app/bar/services/go-links.service';
import { HomeComponentFocused, HomePageService, MethodsList } from 'src/app/bar/services/home-page.service';
import { QuickLinksService } from 'src/app/bar/services/quick-links.service';
import { PostWidgetService } from 'src/app/bar/views/home-page/services/post-widget.service';
import { GlobalErrorHandler } from 'src/app/global-error-handler';

@UntilDestroy()
@Component({
  selector: 'app-status-badges',
  templateUrl: './app-status-badges.component.html',
  styleUrls: ['./app-status-badges.component.scss'],
  providers: [componentServicesRpcProvider],
})
export class AppStatusBadgesComponent implements OnInit, OnDestroy {
  @ViewChild('helpButton', { read: ElementRef }) helpButtonRef: ElementRef;

  @Input() isOwnerOrAdmin: boolean;

  isOnline$: Observable<boolean>;
  error: Error;
  pageType: string;
  popupQuickLinkOpen: boolean;
  disableQuickLinks: boolean;

  private logger: any;
  private readonly destroy$ = new Subject();
  private readonly MAX_ERROR_MSG_LENGTH = 1000;
  private lastVisible: number;
  private recentErrorSubmitted: Map<string, number> = new Map();
  private readonly clearIntervalInMs: number = 1000 * 60 * 1;
  private readonly APPEARANCE_URL = 'settings/workspace-appearance';
  private timer: NodeJS.Timeout;

  isMac = isMac();
  isLauncher: boolean;
  get activePage$() {
    return this.titleBarService.active$;
  }

  actionsShortcut$: Observable<string[]>;

  //keyboard
  private readonly COMPONENT_FOCUSED_ID = HomeComponentFocused.BROWSER_BAR;
  private componentFocused: boolean;
  private keyHandlerId: string;
  selectedIndex: number;
  methodsList: MethodsList = [];
  @ViewChild('quickLinks', { read: ElementRef }) quickLinksIconRef: ElementRef;

  constructor(
    logger: LogService,
    private cdr: ChangeDetectorRef,
    private internet: InternetService,
    private services: ServicesRpcService,
    public window: WindowService,
    public titleBarService: TitleBarService,
    private routerService: RouterService,
    private errorHandler: GlobalErrorHandler,
    private keyboard: KeyboardService,
    private embedService: EmbedService,
    private reportsService: ReportsService,
    private ngZone: NgZone,
    private commandBarService: CommandBarService,
    private goLinksService: GoLinksService,
    private quickLinksService: QuickLinksService,
    private postWidgetService: PostWidgetService,
    private eventsService: EventsService,
    private homePageService: HomePageService,
    private keyboardService: KeyboardService,
    private flagsService: FlagsService
  ) {
    this.logger = logger.scope('AppStatusBadgesComponent');
  }

  ngOnInit(): void {
    this.registerKeyboardHandler();
    this.lastVisible = moment().unix();
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        if (!isNativeWindow()) {
          this.goLinksService.refresh();
        }
        this.lastVisible = moment().unix();
      }
    });
    this.initData();
    this.initActionsShortcut();
    this.startTimer();
    this.routerService.active$.pipe(untilDestroyed(this)).subscribe((page) => {
      this.pageType = page;
      this.cdr.markForCheck();
    });
    this.homePageService.triggerComponentFocus$.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value.live === this.COMPONENT_FOCUSED_ID) {
        this.componentFocused = true;
        this.selectedIndex = value.step === 'next' ? 0 : this.methodsList.length - 1;
        this.cdr.markForCheck();
      } else {
        this.selectedIndex = null;
        this.componentFocused = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.services.destroy();
    this.destroy$.next(null);
    this.destroy$.complete();
    clearInterval(this.timer);
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
      this.keyHandlerId = null;
    }
  }
  private initFlags() {
    this.flagsService.all$.pipe(untilDestroyed(this)).subscribe(async () => {
      this.disableQuickLinks = await this.flagsService.isEnabled(Constants.DISABLED_QUICK_LINKS_FLAG);
      this.cdr.markForCheck();
    });
  }

  private async initActionsShortcut() {
    this.actionsShortcut$ = this.keyboard.get('openCommandBar').pipe(
      filter<Keyboard.Shortcut>((s) => !!s),
      map(({ keys }) => normalizeKeys(keys as string[]))
    );
  }

  private initData() {
    this.initNotifications();
    this.subscribeErrors();
    this.setMethodsList();
    this.initFlags();
    this.isOnline$ = this.internet.isOnline$;
  }

  private subscribeErrors() {
    this.errorHandler.error$.pipe(untilDestroyed(this)).subscribe((e) => {
      if (!e && this.error) {
        this.error = null;
        this.cdr.detectChanges();
      } else if (e && !this.error) {
        if (!isProdEnv()) {
          this.error = e;
        }
        if (['production', 'staging'].includes(Config.environment)) {
          this.submitError();
        }
        this.cdr.detectChanges();
      }
    });
  }

  async submitError() {
    try {
      const now = moment();
      const lastVisible = moment.unix(this.lastVisible);
      if (moment.duration(now.diff(lastVisible)).asMinutes() > 10) {
        this.errorHandler.clear();
        return;
      }
      const handlerError = this.errorHandler.error;
      const error = JSON.stringify(handlerError ?? {}, Object.getOwnPropertyNames(handlerError ?? {}));
      const errorMessage = error.substring(0, this.MAX_ERROR_MSG_LENGTH);
      if (KNOWN_ERRORS.some((k) => errorMessage.includes(k))) {
        this.errorHandler.clear();
        return;
      }

      const hashError = btoa(errorMessage.substring(0, 100));
      if (this.recentErrorSubmitted.has(hashError)) {
        this.errorHandler.clear();
        return;
      }
      this.recentErrorSubmitted.set(hashError, moment().add(1, 'minutes').unix());

      await this.reportsService.submit('Error', errorMessage).catch((e) => {
        const msg = 'Failed to report - ';
        e?.message ? (e.message = msg + e.message) : (e = e + msg);
        this.logger.error('failed to submit error from the reports service', { error: e });
        this.errorHandler.clear();
      });
    } catch (error) {
      this.logger.error('failed to submit error', error);
    }
  }

  customizeBtnClicked() {
    this.routerService.navigateByUrl(this.APPEARANCE_URL);
  }

  async announcementBtnClicked() {
    const tab = await this.homePageService.getCurrentTab();
    this.postWidgetService.openNewPostPopup({ tab });
  }

  quickLinksBtnClicked(event: MouseEvent) {
    const popupQuickLink = this.quickLinksService.openQuickLinkPopup(event);
    this.popupQuickLinkOpen = true;
    popupQuickLink.destroy$.pipe(untilDestroyed(this)).subscribe(() => {
      this.popupQuickLinkOpen = false;
      this.cdr.markForCheck;
    });
  }

  // Notification Management
  private async initNotifications() {
    const focused$ = new BehaviorSubject(document.hasFocus());

    window.addEventListener('focus', () => focused$.next(true), { capture: true });
    window.addEventListener('blur', () => focused$.next(false), { capture: true });

    let suffix = '';

    if (await this.embedService?.isExternalWebSite()) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      suffix = '-embed-' + (await firstValueFrom(this.embedService.options$)).id;
    }
  }

  onErrorClick() {
    this.ngZone.run(() => this.routerService.navigateByUrl('/error'));
  }

  onActionsClick() {
    this.commandBarService.open('mouse_click');
  }

  private startTimer() {
    if (this.timer) {
      return;
    }
    this.timer = setInterval(() => {
      if (!this.recentErrorSubmitted) {
        return;
      }
      for (const [key, value] of this.recentErrorSubmitted.entries()) {
        if (value <= moment().unix()) {
          this.recentErrorSubmitted.delete(key);
        }
      }
    }, this.clearIntervalInMs);
  }

  private registerKeyboardHandler() {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    }
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 7);
  }

  private setMethodsList() {
    setTimeout(() => {
      this.methodsList = [{ id: 'quick_links', fn: () => this.quickLinksBtnClicked(this.quickLinksIconRef?.nativeElement) }];
      if (this.isOwnerOrAdmin) {
        this.methodsList.push(
          { id: 'post', fn: () => this.announcementBtnClicked() },
          { id: 'customize', fn: () => this.customizeBtnClicked() }
        );
      }
    }, 0);
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    if (!this.componentFocused) return;
    const modifiers = getModifiers(keys);
    const key = keys[0];

    if (key === 'tab') {
      stopEvent(event);
      if (modifiers.length && modifiers[0] === 'shift') {
        if (this.selectedIndex === 0) {
          this.homePageService.keyboardComponentFocus$.next({ step: 'prev', live: this.COMPONENT_FOCUSED_ID });
        }
        this.selectedIndex--;
        this.cdr.markForCheck();
        return;
      }
      if (this.selectedIndex === this.methodsList.length - 1) {
        this.homePageService.keyboardComponentFocus$.next({ step: 'next', live: this.COMPONENT_FOCUSED_ID });
      } else {
        this.selectedIndex++;
      }
      this.cdr.markForCheck();
    }
    if (isEnterKey(key)) {
      stopEvent(event);
      this.methodsList[this.selectedIndex]?.fn();
    }
  }
}
