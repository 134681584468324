import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicComponentLoaderComponent } from './dynamic-component-loader.component';
import { DynamicComponentPlaceholderDirective } from './dynamic-component-placeholder.directive';

@NgModule({
  declarations: [DynamicComponentPlaceholderDirective, DynamicComponentLoaderComponent],
  imports: [CommonModule],
  exports: [DynamicComponentPlaceholderDirective, DynamicComponentLoaderComponent],
})
export class DynamicComponentLoaderModule {}
