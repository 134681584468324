<div *ngIf="isLoading"><loader-overlay class="loading"> </loader-overlay></div>
<div *ngIf="isEmbed">
  <powerbi-report #report
    [embedConfig]="reportConfig"
    [cssClassName]="reportClass"
    [phasedEmbedding]="phasedEmbeddingFlag"
    [eventHandlers]="eventHandlersMap"
  >
  </powerbi-report>
</div>
