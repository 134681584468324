import { BreakpointsWidth } from '@shared/utils/break-point.utils';

type WindowWidthBreakpoints = {
  [key in BreakpointsWidth]: number;
};

export const COLLECTIONS_CARD_JUMP: Partial<WindowWidthBreakpoints> = {
  'small': 2,
  'medium': 3,
  'large': 4,
};
