<h1>Personalize your workspace</h1>
<h4>Add a name and icon to make it your own</h4>

<div class="file-upload">
  <div class="file-upload-preview-container">
    <u-icon
      [model]="{ type: 'img', value: { lightUrl: 'assets/images/favicon.ico' } }"
      [styles]="{ width: '100%', height: '100%' }"
      imageId="workspace-image"
    ></u-icon>
  </div>

  <div class="file-upload-btn-container">
    <u-file-upload
      data-cy="workspace-general-upload-button"
      class="upload-image-button"
      label="Upload"
      (onSelect)="onUpload($event)"
      (onError)="onFileUploadError($event)"
      [maxFileSize]="maxFileSize"
      [accept]="accept"
    ></u-file-upload>
    <h5 class="file-upload-description">JPG, PNG, SVG (5MB max)</h5>
    <h5 *ngIf="errorMessage" class="file-upload-error">{{ errorMessage }}</h5>
  </div>
</div>

<u-input (onChange)="onNameChange($event)" placeholder="Acme co" [styles]="{ width: '100%' }" label="Workspace name"></u-input>

<u-button [disabled]="!nameValid && !icon" (onClick)="nameDone()" label="Continue" type="primary" [styles]="{ width: '100%', height: '40px' }"></u-button>

<div class="skip" (click)="skip()">Skip for now</div>
