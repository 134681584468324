import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WikiCardsService } from 'src/app/bar/services/wikis/wiki-cards.service';
import { WikiCardPageMode } from '../../models/wiki-card-model';

@UntilDestroy()
@Component({
  selector: 'wiki-card-footer',
  templateUrl: './wiki-card-footer.component.html',
  styleUrls: ['./wiki-card-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WikiCardFooterComponent {
  @Input() ghostUntil: boolean;
  @Input() tags: string[];
  @Input() closeDropdownTags = 0;
  @Input() canEdit: boolean;
  @Input() pageMode: WikiCardPageMode;

  @Output() tagChange: EventEmitter<string[]> = new EventEmitter();

  constructor(private collectionsService: CollectionsService, private cdr: ChangeDetectorRef, public wikiCardsService: WikiCardsService) {}

  onTagChange($event) {
    this.tags = $event.tags;
    this.tagChange.emit(this.tags);
    this.cdr.markForCheck();
  }

  changeFocus(focus: string) {
    this.collectionsService.onFocusChange = focus;
  }
}
