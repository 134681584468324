import { Injectable } from '@angular/core';
import { Commands } from '@local/client-contracts';
import { CommandsRpcInvoker } from '@local/common';
import { EmbedService } from '@shared/embed.service';
import { LogService, NativeServicesRpcService, ServicesRpcService, WindowService } from '@shared/services';
import { DownloadsService } from '@shared/services/downloads.service';
import { LinksService } from '@shared/services/links.service';
import { RouterService } from '@shared/services/router.service';
import { Logger } from '@unleash-tech/js-logger';
import { FavoritesService } from '../favorites.service';
import { PreviewService } from '../preview.service';
import { SummaryService } from '../summary.service';
import {
  ChangeFavoriteStatusInvoker,
  CopyToClipboardInvoker,
  DownloadUrlInvoker,
  GoLinksChangeFavoriteStatusInvoker,
  OpenPageInvoker,
  OpenUrlInvoker,
  PreviewInvoker,
  SummaryInvoker,
} from './command-invokers';

@Injectable({
  providedIn: 'root',
})
export class CommandsService implements Commands.Service {
  private commands: { [key: string]: Commands.CommandInvoker } = {};
  private logger: Logger;
  private service: Commands.Service;

  constructor(
    hostLogger: LogService,
    private services: ServicesRpcService,
    private nativeServices: NativeServicesRpcService,
    linksService: LinksService,
    favorites: FavoritesService,
    embed: EmbedService,
    window: WindowService,
    downloads: DownloadsService,
    router: RouterService,
    previewService: PreviewService,
    summaryService: SummaryService
  ) {
    this.logger = hostLogger.scope('Commands');
    this.service = (this.nativeServices || this.services).invokeWith(CommandsRpcInvoker, 'commands');
    const invokers = [
      new CopyToClipboardInvoker(),
      new OpenUrlInvoker(services, linksService, this.service, embed, window, downloads),
      new ChangeFavoriteStatusInvoker(favorites),
      new OpenPageInvoker(router),
      new GoLinksChangeFavoriteStatusInvoker(favorites),
      new PreviewInvoker(previewService),
      new SummaryInvoker(summaryService),
      new DownloadUrlInvoker(downloads),
    ];
    invokers.forEach((i) => this.add(i));
  }

  private add(handler: Commands.CommandInvoker): void {
    this.commands[handler.type] = handler;
  }

  async executeCommand(command: Commands.Command, context: Commands.Context = {}): Promise<Commands.Response> {
    if (command?.type) {
      const handler = this.commands[command.type];
      if (handler) {
        return handler.invoke({ command, context });
      }
    }
    this.logger.error('No command handler found', { command, context });
  }
}
