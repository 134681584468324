import { EmojiMapItem } from '../../types/emoji-item';

export const EMOJI_SET: 'apple' | 'google' | 'twitter' | 'facebook' = 'apple';
export const EMOJI_BG_IMAGE_URL = 'assets/emojis/apple-emoji-v2.png';
export const EMOJI_TABLE_COLUMNS = 61;
export const EMOJI_TABLE_ROWS = 60;

// this map needs to be synced with the emojis file
//? https://cdn.jsdelivr.net/npm/emoji-datasource-apple@14.0.0/img/apple/sheets-256/64.png
export const EMOJI_UNICODE_SHEET: EmojiMapItem[] = [
  {
    unicode: '1F600',
    sheet: [32, 20],
    native: '😀',
  },
  {
    unicode: '1F603',
    sheet: [32, 23],
    native: '😃',
  },
  {
    unicode: '1F604',
    sheet: [32, 24],
    native: '😄',
  },
  {
    unicode: '1F601',
    sheet: [32, 21],
    native: '😁',
  },
  {
    unicode: '1F606',
    sheet: [32, 26],
    native: '😆',
  },
  {
    unicode: '1F605',
    sheet: [32, 25],
    native: '😅',
  },
  {
    unicode: '1F923',
    sheet: [40, 15],
    native: '🤣',
  },
  {
    unicode: '1F602',
    sheet: [32, 22],
    native: '😂',
  },
  {
    unicode: '1F642',
    sheet: [33, 28],
    native: '🙂',
  },
  {
    unicode: '1F643',
    sheet: [33, 29],
    native: '🙃',
  },
  {
    unicode: '1FAE0',
    sheet: [55, 12],
    native: '🫠',
  },
  {
    unicode: '1F609',
    sheet: [32, 29],
    native: '😉',
  },
  {
    unicode: '1F60A',
    sheet: [32, 30],
    native: '😊',
  },
  {
    unicode: '1F607',
    sheet: [32, 27],
    native: '😇',
  },
  {
    unicode: '1F970',
    sheet: [43, 58],
    native: '🥰',
  },
  {
    unicode: '1F60D',
    sheet: [32, 33],
    native: '😍',
  },
  {
    unicode: '1F929',
    sheet: [40, 38],
    native: '🤩',
  },
  {
    unicode: '1F618',
    sheet: [32, 44],
    native: '😘',
  },
  {
    unicode: '1F617',
    sheet: [32, 43],
    native: '😗',
  },
  {
    unicode: '263A-FE0F',
    sheet: [57, 4],
    native: '☺️',
  },
  {
    unicode: '1F61A',
    sheet: [32, 46],
    native: '😚',
  },
  {
    unicode: '1F619',
    sheet: [32, 45],
    native: '😙',
  },
  {
    unicode: '1F972',
    sheet: [43, 60],
    native: '🥲',
  },
  {
    unicode: '1F60B',
    sheet: [32, 31],
    native: '😋',
  },
  {
    unicode: '1F61B',
    sheet: [32, 47],
    native: '😛',
  },
  {
    unicode: '1F61C',
    sheet: [32, 48],
    native: '😜',
  },
  {
    unicode: '1F92A',
    sheet: [40, 39],
    native: '🤪',
  },
  {
    unicode: '1F61D',
    sheet: [32, 49],
    native: '😝',
  },
  {
    unicode: '1F911',
    sheet: [38, 59],
    native: '🤑',
  },
  {
    unicode: '1F917',
    sheet: [39, 4],
    native: '🤗',
  },
  {
    unicode: '1F92D',
    sheet: [40, 42],
    native: '🤭',
  },
  {
    unicode: '1FAE2',
    sheet: [55, 14],
    native: '🫢',
  },
  {
    unicode: '1FAE3',
    sheet: [55, 15],
    native: '🫣',
  },
  {
    unicode: '1F92B',
    sheet: [40, 40],
    native: '🤫',
  },
  {
    unicode: '1F914',
    sheet: [39, 1],
    native: '🤔',
  },
  {
    unicode: '1FAE1',
    sheet: [55, 13],
    native: '🫡',
  },
  {
    unicode: '1F910',
    sheet: [38, 58],
    native: '🤐',
  },
  {
    unicode: '1F928',
    sheet: [40, 37],
    native: '🤨',
  },
  {
    unicode: '1F610',
    sheet: [32, 36],
    native: '😐',
  },
  {
    unicode: '1F611',
    sheet: [32, 37],
    native: '😑',
  },
  {
    unicode: '1F636',
    sheet: [33, 16],
    native: '😶',
  },
  {
    unicode: '1FAE5',
    sheet: [55, 17],
    native: '🫥',
  },
  {
    unicode: '1F636-200D-1F32B-FE0F',
    sheet: [33, 15],
    native: '😶‍🌫️',
  },
  {
    unicode: '1F60F',
    sheet: [32, 35],
    native: '😏',
  },
  {
    unicode: '1F612',
    sheet: [32, 38],
    native: '😒',
  },
  {
    unicode: '1F644',
    sheet: [33, 30],
    native: '🙄',
  },
  {
    unicode: '1F62C',
    sheet: [33, 3],
    native: '😬',
  },
  {
    unicode: '1F62E-200D-1F4A8',
    sheet: [33, 5],
    native: '😮‍💨',
  },
  {
    unicode: '1F925',
    sheet: [40, 17],
    native: '🤥',
  },
  {
    unicode: '1F60C',
    sheet: [32, 32],
    native: '😌',
  },
  {
    unicode: '1F614',
    sheet: [32, 40],
    native: '😔',
  },
  {
    unicode: '1F62A',
    sheet: [33, 1],
    native: '😪',
  },
  {
    unicode: '1F924',
    sheet: [40, 16],
    native: '🤤',
  },
  {
    unicode: '1F634',
    sheet: [33, 12],
    native: '😴',
  },
  {
    unicode: '1F637',
    sheet: [33, 17],
    native: '😷',
  },
  {
    unicode: '1F912',
    sheet: [38, 60],
    native: '🤒',
  },
  {
    unicode: '1F915',
    sheet: [39, 2],
    native: '🤕',
  },
  {
    unicode: '1F922',
    sheet: [40, 14],
    native: '🤢',
  },
  {
    unicode: '1F92E',
    sheet: [40, 43],
    native: '🤮',
  },
  {
    unicode: '1F927',
    sheet: [40, 36],
    native: '🤧',
  },
  {
    unicode: '1F975',
    sheet: [44, 2],
    native: '🥵',
  },
  {
    unicode: '1F976',
    sheet: [44, 3],
    native: '🥶',
  },
  {
    unicode: '1F974',
    sheet: [44, 1],
    native: '🥴',
  },
  {
    unicode: '1F635',
    sheet: [33, 14],
    native: '😵',
  },
  {
    unicode: '1F635-200D-1F4AB',
    sheet: [33, 13],
    native: '😵‍💫',
  },
  {
    unicode: '1F92F',
    sheet: [40, 44],
    native: '🤯',
  },
  {
    unicode: '1F920',
    sheet: [40, 12],
    native: '🤠',
  },
  {
    unicode: '1F973',
    sheet: [44, 0],
    native: '🥳',
  },
  {
    unicode: '1F978',
    sheet: [44, 10],
    native: '🥸',
  },
  {
    unicode: '1F60E',
    sheet: [32, 34],
    native: '😎',
  },
  {
    unicode: '1F913',
    sheet: [39, 0],
    native: '🤓',
  },
  {
    unicode: '1F9D0',
    sheet: [47, 11],
    native: '🧐',
  },
  {
    unicode: '1F615',
    sheet: [32, 41],
    native: '😕',
  },
  {
    unicode: '1FAE4',
    sheet: [55, 16],
    native: '🫤',
  },
  {
    unicode: '1F61F',
    sheet: [32, 51],
    native: '😟',
  },
  {
    unicode: '1F641',
    sheet: [33, 27],
    native: '🙁',
  },
  {
    unicode: '2639-FE0F',
    sheet: [57, 3],
    native: '☹️',
  },
  {
    unicode: '1F62E',
    sheet: [33, 6],
    native: '😮',
  },
  {
    unicode: '1F62F',
    sheet: [33, 7],
    native: '😯',
  },
  {
    unicode: '1F632',
    sheet: [33, 10],
    native: '😲',
  },
  {
    unicode: '1F633',
    sheet: [33, 11],
    native: '😳',
  },
  {
    unicode: '1F97A',
    sheet: [44, 12],
    native: '🥺',
  },
  {
    unicode: '1F979',
    sheet: [44, 11],
    native: '🥹',
  },
  {
    unicode: '1F626',
    sheet: [32, 58],
    native: '😦',
  },
  {
    unicode: '1F627',
    sheet: [32, 59],
    native: '😧',
  },
  {
    unicode: '1F628',
    sheet: [32, 60],
    native: '😨',
  },
  {
    unicode: '1F630',
    sheet: [33, 8],
    native: '😰',
  },
  {
    unicode: '1F625',
    sheet: [32, 57],
    native: '😥',
  },
  {
    unicode: '1F622',
    sheet: [32, 54],
    native: '😢',
  },
  {
    unicode: '1F62D',
    sheet: [33, 4],
    native: '😭',
  },
  {
    unicode: '1F631',
    sheet: [33, 9],
    native: '😱',
  },
  {
    unicode: '1F616',
    sheet: [32, 42],
    native: '😖',
  },
  {
    unicode: '1F623',
    sheet: [32, 55],
    native: '😣',
  },
  {
    unicode: '1F61E',
    sheet: [32, 50],
    native: '😞',
  },
  {
    unicode: '1F613',
    sheet: [32, 39],
    native: '😓',
  },
  {
    unicode: '1F629',
    sheet: [33, 0],
    native: '😩',
  },
  {
    unicode: '1F62B',
    sheet: [33, 2],
    native: '😫',
  },
  {
    unicode: '1F971',
    sheet: [43, 59],
    native: '🥱',
  },
  {
    unicode: '1F624',
    sheet: [32, 56],
    native: '😤',
  },
  {
    unicode: '1F621',
    sheet: [32, 53],
    native: '😡',
  },
  {
    unicode: '1F620',
    sheet: [32, 52],
    native: '😠',
  },
  {
    unicode: '1F92C',
    sheet: [40, 41],
    native: '🤬',
  },
  {
    unicode: '1F608',
    sheet: [32, 28],
    native: '😈',
  },
  {
    unicode: '1F47F',
    sheet: [25, 8],
    native: '👿',
  },
  {
    unicode: '1F480',
    sheet: [25, 9],
    native: '💀',
  },
  {
    unicode: '2620-FE0F',
    sheet: [56, 56],
    native: '☠️',
  },
  {
    unicode: '1F4A9',
    sheet: [27, 56],
    native: '💩',
  },
  {
    unicode: '1F921',
    sheet: [40, 13],
    native: '🤡',
  },
  {
    unicode: '1F479',
    sheet: [24, 58],
    native: '👹',
  },
  {
    unicode: '1F47A',
    sheet: [24, 59],
    native: '👺',
  },
  {
    unicode: '1F47B',
    sheet: [24, 60],
    native: '👻',
  },
  {
    unicode: '1F47D',
    sheet: [25, 6],
    native: '👽',
  },
  {
    unicode: '1F47E',
    sheet: [25, 7],
    native: '👾',
  },
  {
    unicode: '1F916',
    sheet: [39, 3],
    native: '🤖',
  },
  {
    unicode: '1F63A',
    sheet: [33, 20],
    native: '😺',
  },
  {
    unicode: '1F638',
    sheet: [33, 18],
    native: '😸',
  },
  {
    unicode: '1F639',
    sheet: [33, 19],
    native: '😹',
  },
  {
    unicode: '1F63B',
    sheet: [33, 21],
    native: '😻',
  },
  {
    unicode: '1F63C',
    sheet: [33, 22],
    native: '😼',
  },
  {
    unicode: '1F63D',
    sheet: [33, 23],
    native: '😽',
  },
  {
    unicode: '1F640',
    sheet: [33, 26],
    native: '🙀',
  },
  {
    unicode: '1F63F',
    sheet: [33, 25],
    native: '😿',
  },
  {
    unicode: '1F63E',
    sheet: [33, 24],
    native: '😾',
  },
  {
    unicode: '1F648',
    sheet: [34, 24],
    native: '🙈',
  },
  {
    unicode: '1F649',
    sheet: [34, 25],
    native: '🙉',
  },
  {
    unicode: '1F64A',
    sheet: [34, 26],
    native: '🙊',
  },
  {
    unicode: '1F48B',
    sheet: [26, 37],
    native: '💋',
  },
  {
    unicode: '1F48C',
    sheet: [26, 38],
    native: '💌',
  },
  {
    unicode: '1F498',
    sheet: [27, 39],
    native: '💘',
  },
  {
    unicode: '1F49D',
    sheet: [27, 44],
    native: '💝',
  },
  {
    unicode: '1F496',
    sheet: [27, 37],
    native: '💖',
  },
  {
    unicode: '1F497',
    sheet: [27, 38],
    native: '💗',
  },
  {
    unicode: '1F493',
    sheet: [27, 34],
    native: '💓',
  },
  {
    unicode: '1F49E',
    sheet: [27, 45],
    native: '💞',
  },
  {
    unicode: '1F495',
    sheet: [27, 36],
    native: '💕',
  },
  {
    unicode: '1F49F',
    sheet: [27, 46],
    native: '💟',
  },
  {
    unicode: '2763-FE0F',
    sheet: [59, 7],
    native: '❣️',
  },
  {
    unicode: '1F494',
    sheet: [27, 35],
    native: '💔',
  },
  {
    unicode: '2764-FE0F-200D-1F525',
    sheet: [59, 8],
    native: '❤️‍🔥',
  },
  {
    unicode: '2764-FE0F-200D-1FA79',
    sheet: [59, 9],
    native: '❤️‍🩹',
  },
  {
    unicode: '2764-FE0F',
    sheet: [59, 10],
    native: '❤️',
  },
  {
    unicode: '1F9E1',
    sheet: [53, 15],
    native: '🧡',
  },
  {
    unicode: '1F49B',
    sheet: [27, 42],
    native: '💛',
  },
  {
    unicode: '1F49A',
    sheet: [27, 41],
    native: '💚',
  },
  {
    unicode: '1F499',
    sheet: [27, 40],
    native: '💙',
  },
  {
    unicode: '1F49C',
    sheet: [27, 43],
    native: '💜',
  },
  {
    unicode: '1F90E',
    sheet: [38, 51],
    native: '🤎',
  },
  {
    unicode: '1F5A4',
    sheet: [31, 55],
    native: '🖤',
  },
  {
    unicode: '1F90D',
    sheet: [38, 50],
    native: '🤍',
  },
  {
    unicode: '1F4AF',
    sheet: [28, 6],
    native: '💯',
  },
  {
    unicode: '1F4A2',
    sheet: [27, 49],
    native: '💢',
  },
  {
    unicode: '1F4A5',
    sheet: [27, 52],
    native: '💥',
  },
  {
    unicode: '1F4AB',
    sheet: [28, 2],
    native: '💫',
  },
  {
    unicode: '1F4A6',
    sheet: [27, 53],
    native: '💦',
  },
  {
    unicode: '1F4A8',
    sheet: [27, 55],
    native: '💨',
  },
  {
    unicode: '1F573-FE0F',
    sheet: [30, 58],
    native: '🕳️',
  },
  {
    unicode: '1F4A3',
    sheet: [27, 50],
    native: '💣',
  },
  {
    unicode: '1F4AC',
    sheet: [28, 3],
    native: '💬',
  },
  {
    unicode: '1F441-FE0F-200D-1F5E8-FE0F',
    sheet: [11, 53],
    native: '👁️‍🗨️',
  },
  {
    unicode: '1F5E8-FE0F',
    sheet: [32, 11],
    native: '🗨️',
  },
  {
    unicode: '1F5EF-FE0F',
    sheet: [32, 12],
    native: '🗯️',
  },
  {
    unicode: '1F4AD',
    sheet: [28, 4],
    native: '💭',
  },
  {
    unicode: '1F4A4',
    sheet: [27, 51],
    native: '💤',
  },
  {
    unicode: '1F44B',
    sheet: [12, 38],
    native: '👋',
  },
  {
    unicode: '1F91A',
    sheet: [39, 17],
    native: '🤚',
  },
  {
    unicode: '1F590-FE0F',
    sheet: [31, 37],
    native: '🖐️',
  },
  {
    unicode: '270B',
    sheet: [58, 33],
    native: '✋',
  },
  {
    unicode: '1F596',
    sheet: [31, 49],
    native: '🖖',
  },
  {
    unicode: '1FAF1',
    sheet: [55, 26],
    native: '🫱',
  },
  {
    unicode: '1FAF2',
    sheet: [55, 32],
    native: '🫲',
  },
  {
    unicode: '1FAF3',
    sheet: [55, 38],
    native: '🫳',
  },
  {
    unicode: '1FAF4',
    sheet: [55, 44],
    native: '🫴',
  },
  {
    unicode: '1F44C',
    sheet: [12, 44],
    native: '👌',
  },
  {
    unicode: '1F90C',
    sheet: [38, 44],
    native: '🤌',
  },
  {
    unicode: '1F90F',
    sheet: [38, 52],
    native: '🤏',
  },
  {
    unicode: '270C-FE0F',
    sheet: [58, 39],
    native: '✌️',
  },
  {
    unicode: '1F91E',
    sheet: [40, 0],
    native: '🤞',
  },
  {
    unicode: '1FAF0',
    sheet: [55, 20],
    native: '🫰',
  },
  {
    unicode: '1F91F',
    sheet: [40, 6],
    native: '🤟',
  },
  {
    unicode: '1F918',
    sheet: [39, 5],
    native: '🤘',
  },
  {
    unicode: '1F919',
    sheet: [39, 11],
    native: '🤙',
  },
  {
    unicode: '1F448',
    sheet: [12, 20],
    native: '👈',
  },
  {
    unicode: '1F449',
    sheet: [12, 26],
    native: '👉',
  },
  {
    unicode: '1F446',
    sheet: [12, 8],
    native: '👆',
  },
  {
    unicode: '1F595',
    sheet: [31, 43],
    native: '🖕',
  },
  {
    unicode: '1F447',
    sheet: [12, 14],
    native: '👇',
  },
  {
    unicode: '261D-FE0F',
    sheet: [56, 50],
    native: '☝️',
  },
  {
    unicode: '1FAF5',
    sheet: [55, 50],
    native: '🫵',
  },
  {
    unicode: '1F44D',
    sheet: [12, 50],
    native: '👍',
  },
  {
    unicode: '1F44E',
    sheet: [12, 56],
    native: '👎',
  },
  {
    unicode: '270A',
    sheet: [58, 27],
    native: '✊',
  },
  {
    unicode: '1F44A',
    sheet: [12, 32],
    native: '👊',
  },
  {
    unicode: '1F91B',
    sheet: [39, 23],
    native: '🤛',
  },
  {
    unicode: '1F91C',
    sheet: [39, 29],
    native: '🤜',
  },
  {
    unicode: '1F44F',
    sheet: [13, 1],
    native: '👏',
  },
  {
    unicode: '1F64C',
    sheet: [34, 45],
    native: '🙌',
  },
  {
    unicode: '1FAF6',
    sheet: [55, 56],
    native: '🫶',
  },
  {
    unicode: '1F450',
    sheet: [13, 7],
    native: '👐',
  },
  {
    unicode: '1F932',
    sheet: [40, 57],
    native: '🤲',
  },
  {
    unicode: '1F91D',
    sheet: [39, 35],
    native: '🤝',
  },
  {
    unicode: '1F64F',
    sheet: [35, 26],
    native: '🙏',
  },
  {
    unicode: '270D-FE0F',
    sheet: [58, 45],
    native: '✍️',
  },
  {
    unicode: '1F485',
    sheet: [25, 53],
    native: '💅',
  },
  {
    unicode: '1F933',
    sheet: [41, 2],
    native: '🤳',
  },
  {
    unicode: '1F4AA',
    sheet: [27, 57],
    native: '💪',
  },
  {
    unicode: '1F9BE',
    sheet: [46, 3],
    native: '🦾',
  },
  {
    unicode: '1F9BF',
    sheet: [46, 4],
    native: '🦿',
  },
  {
    unicode: '1F9B5',
    sheet: [45, 6],
    native: '🦵',
  },
  {
    unicode: '1F9B6',
    sheet: [45, 12],
    native: '🦶',
  },
  {
    unicode: '1F442',
    sheet: [11, 55],
    native: '👂',
  },
  {
    unicode: '1F9BB',
    sheet: [45, 56],
    native: '🦻',
  },
  {
    unicode: '1F443',
    sheet: [12, 0],
    native: '👃',
  },
  {
    unicode: '1F9E0',
    sheet: [53, 14],
    native: '🧠',
  },
  {
    unicode: '1FAC0',
    sheet: [54, 42],
    native: '🫀',
  },
  {
    unicode: '1FAC1',
    sheet: [54, 43],
    native: '🫁',
  },
  {
    unicode: '1F9B7',
    sheet: [45, 18],
    native: '🦷',
  },
  {
    unicode: '1F9B4',
    sheet: [45, 5],
    native: '🦴',
  },
  {
    unicode: '1F440',
    sheet: [11, 52],
    native: '👀',
  },
  {
    unicode: '1F441-FE0F',
    sheet: [11, 54],
    native: '👁️',
  },
  {
    unicode: '1F445',
    sheet: [12, 7],
    native: '👅',
  },
  {
    unicode: '1F444',
    sheet: [12, 6],
    native: '👄',
  },
  {
    unicode: '1FAE6',
    sheet: [55, 18],
    native: '🫦',
  },
  {
    unicode: '1F476',
    sheet: [24, 28],
    native: '👶',
  },
  {
    unicode: '1F9D2',
    sheet: [50, 11],
    native: '🧒',
  },
  {
    unicode: '1F466',
    sheet: [13, 34],
    native: '👦',
  },
  {
    unicode: '1F467',
    sheet: [13, 40],
    native: '👧',
  },
  {
    unicode: '1F9D1',
    sheet: [50, 5],
    native: '🧑',
  },
  {
    unicode: '1F471',
    sheet: [23, 47],
    native: '👱',
  },
  {
    unicode: '1F468',
    sheet: [17, 13],
    native: '👨',
  },
  {
    unicode: '1F9D4',
    sheet: [50, 35],
    native: '🧔',
  },
  {
    unicode: '1F9D4-200D-2642-FE0F',
    sheet: [50, 29],
    native: '🧔‍♂️',
  },
  {
    unicode: '1F9D4-200D-2640-FE0F',
    sheet: [50, 23],
    native: '🧔‍♀️',
  },
  {
    unicode: '1F468-200D-1F9B0',
    sheet: [15, 29],
    native: '👨‍🦰',
  },
  {
    unicode: '1F468-200D-1F9B1',
    sheet: [15, 35],
    native: '👨‍🦱',
  },
  {
    unicode: '1F468-200D-1F9B3',
    sheet: [15, 47],
    native: '👨‍🦳',
  },
  {
    unicode: '1F468-200D-1F9B2',
    sheet: [15, 41],
    native: '👨‍🦲',
  },
  {
    unicode: '1F469',
    sheet: [21, 33],
    native: '👩',
  },
  {
    unicode: '1F469-200D-1F9B0',
    sheet: [18, 58],
    native: '👩‍🦰',
  },
  {
    unicode: '1F9D1-200D-1F9B0',
    sheet: [49, 12],
    native: '🧑‍🦰',
  },
  {
    unicode: '1F469-200D-1F9B1',
    sheet: [19, 3],
    native: '👩‍🦱',
  },
  {
    unicode: '1F9D1-200D-1F9B1',
    sheet: [49, 18],
    native: '🧑‍🦱',
  },
  {
    unicode: '1F469-200D-1F9B3',
    sheet: [19, 15],
    native: '👩‍🦳',
  },
  {
    unicode: '1F9D1-200D-1F9B3',
    sheet: [49, 30],
    native: '🧑‍🦳',
  },
  {
    unicode: '1F469-200D-1F9B2',
    sheet: [19, 9],
    native: '👩‍🦲',
  },
  {
    unicode: '1F9D1-200D-1F9B2',
    sheet: [49, 24],
    native: '🧑‍🦲',
  },
  {
    unicode: '1F471-200D-2640-FE0F',
    sheet: [23, 35],
    native: '👱‍♀️',
  },
  {
    unicode: '1F471-200D-2642-FE0F',
    sheet: [23, 41],
    native: '👱‍♂️',
  },
  {
    unicode: '1F9D3',
    sheet: [50, 17],
    native: '🧓',
  },
  {
    unicode: '1F474',
    sheet: [24, 16],
    native: '👴',
  },
  {
    unicode: '1F475',
    sheet: [24, 22],
    native: '👵',
  },
  {
    unicode: '1F64D',
    sheet: [35, 2],
    native: '🙍',
  },
  {
    unicode: '1F64D-200D-2642-FE0F',
    sheet: [34, 57],
    native: '🙍‍♂️',
  },
  {
    unicode: '1F64D-200D-2640-FE0F',
    sheet: [34, 51],
    native: '🙍‍♀️',
  },
  {
    unicode: '1F64E',
    sheet: [35, 20],
    native: '🙎',
  },
  {
    unicode: '1F64E-200D-2642-FE0F',
    sheet: [35, 14],
    native: '🙎‍♂️',
  },
  {
    unicode: '1F64E-200D-2640-FE0F',
    sheet: [35, 8],
    native: '🙎‍♀️',
  },
  {
    unicode: '1F645',
    sheet: [33, 43],
    native: '🙅',
  },
  {
    unicode: '1F645-200D-2642-FE0F',
    sheet: [33, 37],
    native: '🙅‍♂️',
  },
  {
    unicode: '1F645-200D-2640-FE0F',
    sheet: [33, 31],
    native: '🙅‍♀️',
  },
  {
    unicode: '1F646',
    sheet: [34, 0],
    native: '🙆',
  },
  {
    unicode: '1F646-200D-2642-FE0F',
    sheet: [33, 55],
    native: '🙆‍♂️',
  },
  {
    unicode: '1F646-200D-2640-FE0F',
    sheet: [33, 49],
    native: '🙆‍♀️',
  },
  {
    unicode: '1F481',
    sheet: [25, 22],
    native: '💁',
  },
  {
    unicode: '1F481-200D-2642-FE0F',
    sheet: [25, 16],
    native: '💁‍♂️',
  },
  {
    unicode: '1F481-200D-2640-FE0F',
    sheet: [25, 10],
    native: '💁‍♀️',
  },
  {
    unicode: '1F64B',
    sheet: [34, 39],
    native: '🙋',
  },
  {
    unicode: '1F64B-200D-2642-FE0F',
    sheet: [34, 33],
    native: '🙋‍♂️',
  },
  {
    unicode: '1F64B-200D-2640-FE0F',
    sheet: [34, 27],
    native: '🙋‍♀️',
  },
  {
    unicode: '1F9CF',
    sheet: [47, 5],
    native: '🧏',
  },
  {
    unicode: '1F9CF-200D-2642-FE0F',
    sheet: [46, 60],
    native: '🧏‍♂️',
  },
  {
    unicode: '1F9CF-200D-2640-FE0F',
    sheet: [46, 54],
    native: '🧏‍♀️',
  },
  {
    unicode: '1F647',
    sheet: [34, 18],
    native: '🙇',
  },
  {
    unicode: '1F647-200D-2642-FE0F',
    sheet: [34, 12],
    native: '🙇‍♂️',
  },
  {
    unicode: '1F647-200D-2640-FE0F',
    sheet: [34, 6],
    native: '🙇‍♀️',
  },
  {
    unicode: '1F926',
    sheet: [40, 30],
    native: '🤦',
  },
  {
    unicode: '1F926-200D-2642-FE0F',
    sheet: [40, 24],
    native: '🤦‍♂️',
  },
  {
    unicode: '1F926-200D-2640-FE0F',
    sheet: [40, 18],
    native: '🤦‍♀️',
  },
  {
    unicode: '1F937',
    sheet: [41, 50],
    native: '🤷',
  },
  {
    unicode: '1F937-200D-2642-FE0F',
    sheet: [41, 44],
    native: '🤷‍♂️',
  },
  {
    unicode: '1F937-200D-2640-FE0F',
    sheet: [41, 38],
    native: '🤷‍♀️',
  },
  {
    unicode: '1F9D1-200D-2695-FE0F',
    sheet: [49, 48],
    native: '🧑‍⚕️',
  },
  {
    unicode: '1F468-200D-2695-FE0F',
    sheet: [16, 4],
    native: '👨‍⚕️',
  },
  {
    unicode: '1F469-200D-2695-FE0F',
    sheet: [19, 33],
    native: '👩‍⚕️',
  },
  {
    unicode: '1F9D1-200D-1F393',
    sheet: [47, 36],
    native: '🧑‍🎓',
  },
  {
    unicode: '1F468-200D-1F393',
    sheet: [14, 3],
    native: '👨‍🎓',
  },
  {
    unicode: '1F469-200D-1F393',
    sheet: [17, 37],
    native: '👩‍🎓',
  },
  {
    unicode: '1F9D1-200D-1F3EB',
    sheet: [47, 54],
    native: '🧑‍🏫',
  },
  {
    unicode: '1F468-200D-1F3EB',
    sheet: [14, 21],
    native: '👨‍🏫',
  },
  {
    unicode: '1F469-200D-1F3EB',
    sheet: [17, 55],
    native: '👩‍🏫',
  },
  {
    unicode: '1F9D1-200D-2696-FE0F',
    sheet: [49, 54],
    native: '🧑‍⚖️',
  },
  {
    unicode: '1F468-200D-2696-FE0F',
    sheet: [16, 10],
    native: '👨‍⚖️',
  },
  {
    unicode: '1F469-200D-2696-FE0F',
    sheet: [19, 39],
    native: '👩‍⚖️',
  },
  {
    unicode: '1F9D1-200D-1F33E',
    sheet: [47, 12],
    native: '🧑‍🌾',
  },
  {
    unicode: '1F468-200D-1F33E',
    sheet: [13, 46],
    native: '👨‍🌾',
  },
  {
    unicode: '1F469-200D-1F33E',
    sheet: [17, 19],
    native: '👩‍🌾',
  },
  {
    unicode: '1F9D1-200D-1F373',
    sheet: [47, 18],
    native: '🧑‍🍳',
  },
  {
    unicode: '1F468-200D-1F373',
    sheet: [13, 52],
    native: '👨‍🍳',
  },
  {
    unicode: '1F469-200D-1F373',
    sheet: [17, 25],
    native: '👩‍🍳',
  },
  {
    unicode: '1F9D1-200D-1F527',
    sheet: [48, 17],
    native: '🧑‍🔧',
  },
  {
    unicode: '1F468-200D-1F527',
    sheet: [14, 60],
    native: '👨‍🔧',
  },
  {
    unicode: '1F469-200D-1F527',
    sheet: [18, 28],
    native: '👩‍🔧',
  },
  {
    unicode: '1F9D1-200D-1F3ED',
    sheet: [47, 60],
    native: '🧑‍🏭',
  },
  {
    unicode: '1F468-200D-1F3ED',
    sheet: [14, 27],
    native: '👨‍🏭',
  },
  {
    unicode: '1F469-200D-1F3ED',
    sheet: [18, 0],
    native: '👩‍🏭',
  },
  {
    unicode: '1F9D1-200D-1F4BC',
    sheet: [48, 11],
    native: '🧑‍💼',
  },
  {
    unicode: '1F468-200D-1F4BC',
    sheet: [14, 54],
    native: '👨‍💼',
  },
  {
    unicode: '1F469-200D-1F4BC',
    sheet: [18, 22],
    native: '👩‍💼',
  },
  {
    unicode: '1F9D1-200D-1F52C',
    sheet: [48, 23],
    native: '🧑‍🔬',
  },
  {
    unicode: '1F468-200D-1F52C',
    sheet: [15, 5],
    native: '👨‍🔬',
  },
  {
    unicode: '1F469-200D-1F52C',
    sheet: [18, 34],
    native: '👩‍🔬',
  },
  {
    unicode: '1F9D1-200D-1F4BB',
    sheet: [48, 5],
    native: '🧑‍💻',
  },
  {
    unicode: '1F468-200D-1F4BB',
    sheet: [14, 48],
    native: '👨‍💻',
  },
  {
    unicode: '1F469-200D-1F4BB',
    sheet: [18, 16],
    native: '👩‍💻',
  },
  {
    unicode: '1F9D1-200D-1F3A4',
    sheet: [47, 42],
    native: '🧑‍🎤',
  },
  {
    unicode: '1F468-200D-1F3A4',
    sheet: [14, 9],
    native: '👨‍🎤',
  },
  {
    unicode: '1F469-200D-1F3A4',
    sheet: [17, 43],
    native: '👩‍🎤',
  },
  {
    unicode: '1F9D1-200D-1F3A8',
    sheet: [47, 48],
    native: '🧑‍🎨',
  },
  {
    unicode: '1F468-200D-1F3A8',
    sheet: [14, 15],
    native: '👨‍🎨',
  },
  {
    unicode: '1F469-200D-1F3A8',
    sheet: [17, 49],
    native: '👩‍🎨',
  },
  {
    unicode: '1F9D1-200D-2708-FE0F',
    sheet: [49, 60],
    native: '🧑‍✈️',
  },
  {
    unicode: '1F468-200D-2708-FE0F',
    sheet: [16, 16],
    native: '👨‍✈️',
  },
  {
    unicode: '1F469-200D-2708-FE0F',
    sheet: [19, 45],
    native: '👩‍✈️',
  },
  {
    unicode: '1F9D1-200D-1F680',
    sheet: [48, 29],
    native: '🧑‍🚀',
  },
  {
    unicode: '1F468-200D-1F680',
    sheet: [15, 11],
    native: '👨‍🚀',
  },
  {
    unicode: '1F469-200D-1F680',
    sheet: [18, 40],
    native: '👩‍🚀',
  },
  {
    unicode: '1F9D1-200D-1F692',
    sheet: [48, 35],
    native: '🧑‍🚒',
  },
  {
    unicode: '1F468-200D-1F692',
    sheet: [15, 17],
    native: '👨‍🚒',
  },
  {
    unicode: '1F469-200D-1F692',
    sheet: [18, 46],
    native: '👩‍🚒',
  },
  {
    unicode: '1F46E',
    sheet: [23, 8],
    native: '👮',
  },
  {
    unicode: '1F46E-200D-2642-FE0F',
    sheet: [23, 2],
    native: '👮‍♂️',
  },
  {
    unicode: '1F46E-200D-2640-FE0F',
    sheet: [22, 57],
    native: '👮‍♀️',
  },
  {
    unicode: '1F575-FE0F',
    sheet: [31, 16],
    native: '🕵️',
  },
  {
    unicode: '1F575-FE0F-200D-2642-FE0F',
    sheet: [31, 10],
    native: '🕵️‍♂️',
  },
  {
    unicode: '1F575-FE0F-200D-2640-FE0F',
    sheet: [31, 4],
    native: '🕵️‍♀️',
  },
  {
    unicode: '1F482',
    sheet: [25, 40],
    native: '💂',
  },
  {
    unicode: '1F482-200D-2642-FE0F',
    sheet: [25, 34],
    native: '💂‍♂️',
  },
  {
    unicode: '1F482-200D-2640-FE0F',
    sheet: [25, 28],
    native: '💂‍♀️',
  },
  {
    unicode: '1F977',
    sheet: [44, 4],
    native: '🥷',
  },
  {
    unicode: '1F477',
    sheet: [24, 46],
    native: '👷',
  },
  {
    unicode: '1F477-200D-2642-FE0F',
    sheet: [24, 40],
    native: '👷‍♂️',
  },
  {
    unicode: '1F477-200D-2640-FE0F',
    sheet: [24, 34],
    native: '👷‍♀️',
  },
  {
    unicode: '1FAC5',
    sheet: [54, 57],
    native: '🫅',
  },
  {
    unicode: '1F934',
    sheet: [41, 8],
    native: '🤴',
  },
  {
    unicode: '1F478',
    sheet: [24, 52],
    native: '👸',
  },
  {
    unicode: '1F473',
    sheet: [24, 10],
    native: '👳',
  },
  {
    unicode: '1F473-200D-2642-FE0F',
    sheet: [24, 4],
    native: '👳‍♂️',
  },
  {
    unicode: '1F473-200D-2640-FE0F',
    sheet: [23, 59],
    native: '👳‍♀️',
  },
  {
    unicode: '1F472',
    sheet: [23, 53],
    native: '👲',
  },
  {
    unicode: '1F9D5',
    sheet: [50, 41],
    native: '🧕',
  },
  {
    unicode: '1F935',
    sheet: [41, 26],
    native: '🤵',
  },
  {
    unicode: '1F935-200D-2642-FE0F',
    sheet: [41, 20],
    native: '🤵‍♂️',
  },
  {
    unicode: '1F935-200D-2640-FE0F',
    sheet: [41, 14],
    native: '🤵‍♀️',
  },
  {
    unicode: '1F470',
    sheet: [23, 29],
    native: '👰',
  },
  {
    unicode: '1F470-200D-2642-FE0F',
    sheet: [23, 23],
    native: '👰‍♂️',
  },
  {
    unicode: '1F470-200D-2640-FE0F',
    sheet: [23, 17],
    native: '👰‍♀️',
  },
  {
    unicode: '1F930',
    sheet: [40, 45],
    native: '🤰',
  },
  {
    unicode: '1FAC3',
    sheet: [54, 45],
    native: '🫃',
  },
  {
    unicode: '1FAC4',
    sheet: [54, 51],
    native: '🫄',
  },
  {
    unicode: '1F931',
    sheet: [40, 51],
    native: '🤱',
  },
  {
    unicode: '1F469-200D-1F37C',
    sheet: [17, 31],
    native: '👩‍🍼',
  },
  {
    unicode: '1F468-200D-1F37C',
    sheet: [13, 58],
    native: '👨‍🍼',
  },
  {
    unicode: '1F9D1-200D-1F37C',
    sheet: [47, 24],
    native: '🧑‍🍼',
  },
  {
    unicode: '1F47C',
    sheet: [25, 0],
    native: '👼',
  },
  {
    unicode: '1F385',
    sheet: [7, 8],
    native: '🎅',
  },
  {
    unicode: '1F936',
    sheet: [41, 32],
    native: '🤶',
  },
  {
    unicode: '1F9D1-200D-1F384',
    sheet: [47, 30],
    native: '🧑‍🎄',
  },
  {
    unicode: '1F9B8',
    sheet: [45, 31],
    native: '🦸',
  },
  {
    unicode: '1F9B8-200D-2642-FE0F',
    sheet: [45, 25],
    native: '🦸‍♂️',
  },
  {
    unicode: '1F9B8-200D-2640-FE0F',
    sheet: [45, 19],
    native: '🦸‍♀️',
  },
  {
    unicode: '1F9B9',
    sheet: [45, 49],
    native: '🦹',
  },
  {
    unicode: '1F9B9-200D-2642-FE0F',
    sheet: [45, 43],
    native: '🦹‍♂️',
  },
  {
    unicode: '1F9B9-200D-2640-FE0F',
    sheet: [45, 37],
    native: '🦹‍♀️',
  },
  {
    unicode: '1F9D9',
    sheet: [51, 52],
    native: '🧙',
  },
  {
    unicode: '1F9D9-200D-2642-FE0F',
    sheet: [51, 46],
    native: '🧙‍♂️',
  },
  {
    unicode: '1F9D9-200D-2640-FE0F',
    sheet: [51, 40],
    native: '🧙‍♀️',
  },
  {
    unicode: '1F9DA',
    sheet: [52, 9],
    native: '🧚',
  },
  {
    unicode: '1F9DA-200D-2642-FE0F',
    sheet: [52, 3],
    native: '🧚‍♂️',
  },
  {
    unicode: '1F9DA-200D-2640-FE0F',
    sheet: [51, 58],
    native: '🧚‍♀️',
  },
  {
    unicode: '1F9DB',
    sheet: [52, 27],
    native: '🧛',
  },
  {
    unicode: '1F9DB-200D-2642-FE0F',
    sheet: [52, 21],
    native: '🧛‍♂️',
  },
  {
    unicode: '1F9DB-200D-2640-FE0F',
    sheet: [52, 15],
    native: '🧛‍♀️',
  },
  {
    unicode: '1F9DC',
    sheet: [52, 45],
    native: '🧜',
  },
  {
    unicode: '1F9DC-200D-2642-FE0F',
    sheet: [52, 39],
    native: '🧜‍♂️',
  },
  {
    unicode: '1F9DC-200D-2640-FE0F',
    sheet: [52, 33],
    native: '🧜‍♀️',
  },
  {
    unicode: '1F9DD',
    sheet: [53, 2],
    native: '🧝',
  },
  {
    unicode: '1F9DD-200D-2642-FE0F',
    sheet: [52, 57],
    native: '🧝‍♂️',
  },
  {
    unicode: '1F9DD-200D-2640-FE0F',
    sheet: [52, 51],
    native: '🧝‍♀️',
  },
  {
    unicode: '1F9DE',
    sheet: [53, 10],
    native: '🧞',
  },
  {
    unicode: '1F9DE-200D-2642-FE0F',
    sheet: [53, 9],
    native: '🧞‍♂️',
  },
  {
    unicode: '1F9DE-200D-2640-FE0F',
    sheet: [53, 8],
    native: '🧞‍♀️',
  },
  {
    unicode: '1F9DF',
    sheet: [53, 13],
    native: '🧟',
  },
  {
    unicode: '1F9DF-200D-2642-FE0F',
    sheet: [53, 12],
    native: '🧟‍♂️',
  },
  {
    unicode: '1F9DF-200D-2640-FE0F',
    sheet: [53, 11],
    native: '🧟‍♀️',
  },
  {
    unicode: '1F9CC',
    sheet: [46, 17],
    native: '🧌',
  },
  {
    unicode: '1F486',
    sheet: [26, 10],
    native: '💆',
  },
  {
    unicode: '1F486-200D-2642-FE0F',
    sheet: [26, 4],
    native: '💆‍♂️',
  },
  {
    unicode: '1F486-200D-2640-FE0F',
    sheet: [25, 59],
    native: '💆‍♀️',
  },
  {
    unicode: '1F487',
    sheet: [26, 28],
    native: '💇',
  },
  {
    unicode: '1F487-200D-2642-FE0F',
    sheet: [26, 22],
    native: '💇‍♂️',
  },
  {
    unicode: '1F487-200D-2640-FE0F',
    sheet: [26, 16],
    native: '💇‍♀️',
  },
  {
    unicode: '1F6B6',
    sheet: [37, 27],
    native: '🚶',
  },
  {
    unicode: '1F6B6-200D-2642-FE0F',
    sheet: [37, 21],
    native: '🚶‍♂️',
  },
  {
    unicode: '1F6B6-200D-2640-FE0F',
    sheet: [37, 15],
    native: '🚶‍♀️',
  },
  {
    unicode: '1F9CD',
    sheet: [46, 30],
    native: '🧍',
  },
  {
    unicode: '1F9CD-200D-2642-FE0F',
    sheet: [46, 24],
    native: '🧍‍♂️',
  },
  {
    unicode: '1F9CD-200D-2640-FE0F',
    sheet: [46, 18],
    native: '🧍‍♀️',
  },
  {
    unicode: '1F9CE',
    sheet: [46, 48],
    native: '🧎',
  },
  {
    unicode: '1F9CE-200D-2642-FE0F',
    sheet: [46, 42],
    native: '🧎‍♂️',
  },
  {
    unicode: '1F9CE-200D-2640-FE0F',
    sheet: [46, 36],
    native: '🧎‍♀️',
  },
  {
    unicode: '1F9D1-200D-1F9AF',
    sheet: [49, 6],
    native: '🧑‍🦯',
  },
  {
    unicode: '1F468-200D-1F9AF',
    sheet: [15, 23],
    native: '👨‍🦯',
  },
  {
    unicode: '1F469-200D-1F9AF',
    sheet: [18, 52],
    native: '👩‍🦯',
  },
  {
    unicode: '1F9D1-200D-1F9BC',
    sheet: [49, 36],
    native: '🧑‍🦼',
  },
  {
    unicode: '1F468-200D-1F9BC',
    sheet: [15, 53],
    native: '👨‍🦼',
  },
  {
    unicode: '1F469-200D-1F9BC',
    sheet: [19, 21],
    native: '👩‍🦼',
  },
  {
    unicode: '1F9D1-200D-1F9BD',
    sheet: [49, 42],
    native: '🧑‍🦽',
  },
  {
    unicode: '1F468-200D-1F9BD',
    sheet: [15, 59],
    native: '👨‍🦽',
  },
  {
    unicode: '1F469-200D-1F9BD',
    sheet: [19, 27],
    native: '👩‍🦽',
  },
  {
    unicode: '1F3C3',
    sheet: [8, 26],
    native: '🏃',
  },
  {
    unicode: '1F3C3-200D-2642-FE0F',
    sheet: [8, 20],
    native: '🏃‍♂️',
  },
  {
    unicode: '1F3C3-200D-2640-FE0F',
    sheet: [8, 14],
    native: '🏃‍♀️',
  },
  {
    unicode: '1F483',
    sheet: [25, 46],
    native: '💃',
  },
  {
    unicode: '1F57A',
    sheet: [31, 26],
    native: '🕺',
  },
  {
    unicode: '1F574-FE0F',
    sheet: [30, 59],
    native: '🕴️',
  },
  {
    unicode: '1F46F',
    sheet: [23, 16],
    native: '👯',
  },
  {
    unicode: '1F46F-200D-2642-FE0F',
    sheet: [23, 15],
    native: '👯‍♂️',
  },
  {
    unicode: '1F46F-200D-2640-FE0F',
    sheet: [23, 14],
    native: '👯‍♀️',
  },
  {
    unicode: '1F9D6',
    sheet: [50, 59],
    native: '🧖',
  },
  {
    unicode: '1F9D6-200D-2642-FE0F',
    sheet: [50, 53],
    native: '🧖‍♂️',
  },
  {
    unicode: '1F9D6-200D-2640-FE0F',
    sheet: [50, 47],
    native: '🧖‍♀️',
  },
  {
    unicode: '1F9D7',
    sheet: [51, 16],
    native: '🧗',
  },
  {
    unicode: '1F9D7-200D-2642-FE0F',
    sheet: [51, 10],
    native: '🧗‍♂️',
  },
  {
    unicode: '1F9D7-200D-2640-FE0F',
    sheet: [51, 4],
    native: '🧗‍♀️',
  },
  {
    unicode: '1F93A',
    sheet: [42, 31],
    native: '🤺',
  },
  {
    unicode: '1F3C7',
    sheet: [8, 52],
    native: '🏇',
  },
  {
    unicode: '26F7-FE0F',
    sheet: [58, 1],
    native: '⛷️',
  },
  {
    unicode: '1F3C2',
    sheet: [8, 8],
    native: '🏂',
  },
  {
    unicode: '1F3CC-FE0F',
    sheet: [9, 47],
    native: '🏌️',
  },
  {
    unicode: '1F3CC-FE0F-200D-2642-FE0F',
    sheet: [9, 41],
    native: '🏌️‍♂️',
  },
  {
    unicode: '1F3CC-FE0F-200D-2640-FE0F',
    sheet: [9, 35],
    native: '🏌️‍♀️',
  },
  {
    unicode: '1F3C4',
    sheet: [8, 44],
    native: '🏄',
  },
  {
    unicode: '1F3C4-200D-2642-FE0F',
    sheet: [8, 38],
    native: '🏄‍♂️',
  },
  {
    unicode: '1F3C4-200D-2640-FE0F',
    sheet: [8, 32],
    native: '🏄‍♀️',
  },
  {
    unicode: '1F6A3',
    sheet: [36, 18],
    native: '🚣',
  },
  {
    unicode: '1F6A3-200D-2642-FE0F',
    sheet: [36, 12],
    native: '🚣‍♂️',
  },
  {
    unicode: '1F6A3-200D-2640-FE0F',
    sheet: [36, 6],
    native: '🚣‍♀️',
  },
  {
    unicode: '1F3CA',
    sheet: [9, 11],
    native: '🏊',
  },
  {
    unicode: '1F3CA-200D-2642-FE0F',
    sheet: [9, 5],
    native: '🏊‍♂️',
  },
  {
    unicode: '1F3CA-200D-2640-FE0F',
    sheet: [8, 60],
    native: '🏊‍♀️',
  },
  {
    unicode: '26F9-FE0F',
    sheet: [58, 15],
    native: '⛹️',
  },
  {
    unicode: '26F9-FE0F-200D-2642-FE0F',
    sheet: [58, 9],
    native: '⛹️‍♂️',
  },
  {
    unicode: '26F9-FE0F-200D-2640-FE0F',
    sheet: [58, 3],
    native: '⛹️‍♀️',
  },
  {
    unicode: '1F3CB-FE0F',
    sheet: [9, 29],
    native: '🏋️',
  },
  {
    unicode: '1F3CB-FE0F-200D-2642-FE0F',
    sheet: [9, 23],
    native: '🏋️‍♂️',
  },
  {
    unicode: '1F3CB-FE0F-200D-2640-FE0F',
    sheet: [9, 17],
    native: '🏋️‍♀️',
  },
  {
    unicode: '1F6B4',
    sheet: [36, 52],
    native: '🚴',
  },
  {
    unicode: '1F6B4-200D-2642-FE0F',
    sheet: [36, 46],
    native: '🚴‍♂️',
  },
  {
    unicode: '1F6B4-200D-2640-FE0F',
    sheet: [36, 40],
    native: '🚴‍♀️',
  },
  {
    unicode: '1F6B5',
    sheet: [37, 9],
    native: '🚵',
  },
  {
    unicode: '1F6B5-200D-2642-FE0F',
    sheet: [37, 3],
    native: '🚵‍♂️',
  },
  {
    unicode: '1F6B5-200D-2640-FE0F',
    sheet: [36, 58],
    native: '🚵‍♀️',
  },
  {
    unicode: '1F938',
    sheet: [42, 7],
    native: '🤸',
  },
  {
    unicode: '1F938-200D-2642-FE0F',
    sheet: [42, 1],
    native: '🤸‍♂️',
  },
  {
    unicode: '1F938-200D-2640-FE0F',
    sheet: [41, 56],
    native: '🤸‍♀️',
  },
  {
    unicode: '1F93C',
    sheet: [42, 34],
    native: '🤼',
  },
  {
    unicode: '1F93C-200D-2642-FE0F',
    sheet: [42, 33],
    native: '🤼‍♂️',
  },
  {
    unicode: '1F93C-200D-2640-FE0F',
    sheet: [42, 32],
    native: '🤼‍♀️',
  },
  {
    unicode: '1F93D',
    sheet: [42, 47],
    native: '🤽',
  },
  {
    unicode: '1F93D-200D-2642-FE0F',
    sheet: [42, 41],
    native: '🤽‍♂️',
  },
  {
    unicode: '1F93D-200D-2640-FE0F',
    sheet: [42, 35],
    native: '🤽‍♀️',
  },
  {
    unicode: '1F93E',
    sheet: [43, 4],
    native: '🤾',
  },
  {
    unicode: '1F93E-200D-2642-FE0F',
    sheet: [42, 59],
    native: '🤾‍♂️',
  },
  {
    unicode: '1F93E-200D-2640-FE0F',
    sheet: [42, 53],
    native: '🤾‍♀️',
  },
  {
    unicode: '1F939',
    sheet: [42, 25],
    native: '🤹',
  },
  {
    unicode: '1F939-200D-2642-FE0F',
    sheet: [42, 19],
    native: '🤹‍♂️',
  },
  {
    unicode: '1F939-200D-2640-FE0F',
    sheet: [42, 13],
    native: '🤹‍♀️',
  },
  {
    unicode: '1F9D8',
    sheet: [51, 34],
    native: '🧘',
  },
  {
    unicode: '1F9D8-200D-2642-FE0F',
    sheet: [51, 28],
    native: '🧘‍♂️',
  },
  {
    unicode: '1F9D8-200D-2640-FE0F',
    sheet: [51, 22],
    native: '🧘‍♀️',
  },
  {
    unicode: '1F6C0',
    sheet: [37, 42],
    native: '🛀',
  },
  {
    unicode: '1F6CC',
    sheet: [37, 54],
    native: '🛌',
  },
  {
    unicode: '1F9D1-200D-1F91D-200D-1F9D1',
    sheet: [48, 41],
    native: '🧑‍🤝‍🧑',
  },
  {
    unicode: '1F46D',
    sheet: [22, 31],
    native: '👭',
  },
  {
    unicode: '1F46B',
    sheet: [21, 40],
    native: '👫',
  },
  {
    unicode: '1F46C',
    sheet: [22, 5],
    native: '👬',
  },
  {
    unicode: '1F48F',
    sheet: [26, 41],
    native: '💏',
  },
  {
    unicode: '1F469-200D-2764-FE0F-200D-1F48B-200D-1F468',
    sheet: [20, 42],
    native: '👩‍❤️‍💋‍👨',
  },
  {
    unicode: '1F468-200D-2764-FE0F-200D-1F48B-200D-1F468',
    sheet: [16, 48],
    native: '👨‍❤️‍💋‍👨',
  },
  {
    unicode: '1F469-200D-2764-FE0F-200D-1F48B-200D-1F469',
    sheet: [21, 7],
    native: '👩‍❤️‍💋‍👩',
  },
  {
    unicode: '1F491',
    sheet: [27, 7],
    native: '💑',
  },
  {
    unicode: '1F469-200D-2764-FE0F-200D-1F468',
    sheet: [19, 51],
    native: '👩‍❤️‍👨',
  },
  {
    unicode: '1F468-200D-2764-FE0F-200D-1F468',
    sheet: [16, 22],
    native: '👨‍❤️‍👨',
  },
  {
    unicode: '1F469-200D-2764-FE0F-200D-1F469',
    sheet: [20, 16],
    native: '👩‍❤️‍👩',
  },
  {
    unicode: '1F46A',
    sheet: [21, 39],
    native: '👪',
  },
  {
    unicode: '1F468-200D-1F469-200D-1F466',
    sheet: [14, 43],
    native: '👨‍👩‍👦',
  },
  {
    unicode: '1F468-200D-1F469-200D-1F467',
    sheet: [14, 45],
    native: '👨‍👩‍👧',
  },
  {
    unicode: '1F468-200D-1F469-200D-1F467-200D-1F466',
    sheet: [14, 46],
    native: '👨‍👩‍👧‍👦',
  },
  {
    unicode: '1F468-200D-1F469-200D-1F466-200D-1F466',
    sheet: [14, 44],
    native: '👨‍👩‍👦‍👦',
  },
  {
    unicode: '1F468-200D-1F469-200D-1F467-200D-1F467',
    sheet: [14, 47],
    native: '👨‍👩‍👧‍👧',
  },
  {
    unicode: '1F468-200D-1F468-200D-1F466',
    sheet: [14, 38],
    native: '👨‍👨‍👦',
  },
  {
    unicode: '1F468-200D-1F468-200D-1F467',
    sheet: [14, 40],
    native: '👨‍👨‍👧',
  },
  {
    unicode: '1F468-200D-1F468-200D-1F467-200D-1F466',
    sheet: [14, 41],
    native: '👨‍👨‍👧‍👦',
  },
  {
    unicode: '1F468-200D-1F468-200D-1F466-200D-1F466',
    sheet: [14, 39],
    native: '👨‍👨‍👦‍👦',
  },
  {
    unicode: '1F468-200D-1F468-200D-1F467-200D-1F467',
    sheet: [14, 42],
    native: '👨‍👨‍👧‍👧',
  },
  {
    unicode: '1F469-200D-1F469-200D-1F466',
    sheet: [18, 11],
    native: '👩‍👩‍👦',
  },
  {
    unicode: '1F469-200D-1F469-200D-1F467',
    sheet: [18, 13],
    native: '👩‍👩‍👧',
  },
  {
    unicode: '1F469-200D-1F469-200D-1F467-200D-1F466',
    sheet: [18, 14],
    native: '👩‍👩‍👧‍👦',
  },
  {
    unicode: '1F469-200D-1F469-200D-1F466-200D-1F466',
    sheet: [18, 12],
    native: '👩‍👩‍👦‍👦',
  },
  {
    unicode: '1F469-200D-1F469-200D-1F467-200D-1F467',
    sheet: [18, 15],
    native: '👩‍👩‍👧‍👧',
  },
  {
    unicode: '1F468-200D-1F466',
    sheet: [14, 34],
    native: '👨‍👦',
  },
  {
    unicode: '1F468-200D-1F466-200D-1F466',
    sheet: [14, 33],
    native: '👨‍👦‍👦',
  },
  {
    unicode: '1F468-200D-1F467',
    sheet: [14, 37],
    native: '👨‍👧',
  },
  {
    unicode: '1F468-200D-1F467-200D-1F466',
    sheet: [14, 35],
    native: '👨‍👧‍👦',
  },
  {
    unicode: '1F468-200D-1F467-200D-1F467',
    sheet: [14, 36],
    native: '👨‍👧‍👧',
  },
  {
    unicode: '1F469-200D-1F466',
    sheet: [18, 7],
    native: '👩‍👦',
  },
  {
    unicode: '1F469-200D-1F466-200D-1F466',
    sheet: [18, 6],
    native: '👩‍👦‍👦',
  },
  {
    unicode: '1F469-200D-1F467',
    sheet: [18, 10],
    native: '👩‍👧',
  },
  {
    unicode: '1F469-200D-1F467-200D-1F466',
    sheet: [18, 8],
    native: '👩‍👧‍👦',
  },
  {
    unicode: '1F469-200D-1F467-200D-1F467',
    sheet: [18, 9],
    native: '👩‍👧‍👧',
  },
  {
    unicode: '1F5E3-FE0F',
    sheet: [32, 10],
    native: '🗣️',
  },
  {
    unicode: '1F464',
    sheet: [13, 32],
    native: '👤',
  },
  {
    unicode: '1F465',
    sheet: [13, 33],
    native: '👥',
  },
  {
    unicode: '1FAC2',
    sheet: [54, 44],
    native: '🫂',
  },
  {
    unicode: '1F463',
    sheet: [13, 31],
    native: '👣',
  },
  {
    unicode: '1F3FB',
    sheet: [10, 41],
    native: '🏻',
  },
  {
    unicode: '1F3FC',
    sheet: [10, 42],
    native: '🏼',
  },
  {
    unicode: '1F3FD',
    sheet: [10, 43],
    native: '🏽',
  },
  {
    unicode: '1F3FE',
    sheet: [10, 44],
    native: '🏾',
  },
  {
    unicode: '1F3FF',
    sheet: [10, 45],
    native: '🏿',
  },
  {
    unicode: '1F435',
    sheet: [11, 40],
    native: '🐵',
  },
  {
    unicode: '1F412',
    sheet: [11, 4],
    native: '🐒',
  },
  {
    unicode: '1F98D',
    sheet: [44, 31],
    native: '🦍',
  },
  {
    unicode: '1F9A7',
    sheet: [44, 57],
    native: '🦧',
  },
  {
    unicode: '1F436',
    sheet: [11, 41],
    native: '🐶',
  },
  {
    unicode: '1F415',
    sheet: [11, 8],
    native: '🐕',
  },
  {
    unicode: '1F9AE',
    sheet: [45, 3],
    native: '🦮',
  },
  {
    unicode: '1F415-200D-1F9BA',
    sheet: [11, 7],
    native: '🐕‍🦺',
  },
  {
    unicode: '1F429',
    sheet: [11, 28],
    native: '🐩',
  },
  {
    unicode: '1F43A',
    sheet: [11, 45],
    native: '🐺',
  },
  {
    unicode: '1F98A',
    sheet: [44, 28],
    native: '🦊',
  },
  {
    unicode: '1F99D',
    sheet: [44, 47],
    native: '🦝',
  },
  {
    unicode: '1F431',
    sheet: [11, 36],
    native: '🐱',
  },
  {
    unicode: '1F408',
    sheet: [10, 55],
    native: '🐈',
  },
  {
    unicode: '1F408-200D-2B1B',
    sheet: [10, 54],
    native: '🐈‍⬛',
  },
  {
    unicode: '1F981',
    sheet: [44, 19],
    native: '🦁',
  },
  {
    unicode: '1F42F',
    sheet: [11, 34],
    native: '🐯',
  },
  {
    unicode: '1F405',
    sheet: [10, 51],
    native: '🐅',
  },
  {
    unicode: '1F406',
    sheet: [10, 52],
    native: '🐆',
  },
  {
    unicode: '1F434',
    sheet: [11, 39],
    native: '🐴',
  },
  {
    unicode: '1F40E',
    sheet: [11, 0],
    native: '🐎',
  },
  {
    unicode: '1F984',
    sheet: [44, 22],
    native: '🦄',
  },
  {
    unicode: '1F993',
    sheet: [44, 37],
    native: '🦓',
  },
  {
    unicode: '1F98C',
    sheet: [44, 30],
    native: '🦌',
  },
  {
    unicode: '1F9AC',
    sheet: [45, 1],
    native: '🦬',
  },
  {
    unicode: '1F42E',
    sheet: [11, 33],
    native: '🐮',
  },
  {
    unicode: '1F402',
    sheet: [10, 48],
    native: '🐂',
  },
  {
    unicode: '1F403',
    sheet: [10, 49],
    native: '🐃',
  },
  {
    unicode: '1F404',
    sheet: [10, 50],
    native: '🐄',
  },
  {
    unicode: '1F437',
    sheet: [11, 42],
    native: '🐷',
  },
  {
    unicode: '1F416',
    sheet: [11, 9],
    native: '🐖',
  },
  {
    unicode: '1F417',
    sheet: [11, 10],
    native: '🐗',
  },
  {
    unicode: '1F43D',
    sheet: [11, 49],
    native: '🐽',
  },
  {
    unicode: '1F40F',
    sheet: [11, 1],
    native: '🐏',
  },
  {
    unicode: '1F411',
    sheet: [11, 3],
    native: '🐑',
  },
  {
    unicode: '1F410',
    sheet: [11, 2],
    native: '🐐',
  },
  {
    unicode: '1F42A',
    sheet: [11, 29],
    native: '🐪',
  },
  {
    unicode: '1F42B',
    sheet: [11, 30],
    native: '🐫',
  },
  {
    unicode: '1F999',
    sheet: [44, 43],
    native: '🦙',
  },
  {
    unicode: '1F992',
    sheet: [44, 36],
    native: '🦒',
  },
  {
    unicode: '1F418',
    sheet: [11, 11],
    native: '🐘',
  },
  {
    unicode: '1F9A3',
    sheet: [44, 53],
    native: '🦣',
  },
  {
    unicode: '1F98F',
    sheet: [44, 33],
    native: '🦏',
  },
  {
    unicode: '1F99B',
    sheet: [44, 45],
    native: '🦛',
  },
  {
    unicode: '1F42D',
    sheet: [11, 32],
    native: '🐭',
  },
  {
    unicode: '1F401',
    sheet: [10, 47],
    native: '🐁',
  },
  {
    unicode: '1F400',
    sheet: [10, 46],
    native: '🐀',
  },
  {
    unicode: '1F439',
    sheet: [11, 44],
    native: '🐹',
  },
  {
    unicode: '1F430',
    sheet: [11, 35],
    native: '🐰',
  },
  {
    unicode: '1F407',
    sheet: [10, 53],
    native: '🐇',
  },
  {
    unicode: '1F43F-FE0F',
    sheet: [11, 51],
    native: '🐿️',
  },
  {
    unicode: '1F9AB',
    sheet: [45, 0],
    native: '🦫',
  },
  {
    unicode: '1F994',
    sheet: [44, 38],
    native: '🦔',
  },
  {
    unicode: '1F987',
    sheet: [44, 25],
    native: '🦇',
  },
  {
    unicode: '1F43B',
    sheet: [11, 47],
    native: '🐻',
  },
  {
    unicode: '1F43B-200D-2744-FE0F',
    sheet: [11, 46],
    native: '🐻‍❄️',
  },
  {
    unicode: '1F428',
    sheet: [11, 27],
    native: '🐨',
  },
  {
    unicode: '1F43C',
    sheet: [11, 48],
    native: '🐼',
  },
  {
    unicode: '1F9A5',
    sheet: [44, 55],
    native: '🦥',
  },
  {
    unicode: '1F9A6',
    sheet: [44, 56],
    native: '🦦',
  },
  {
    unicode: '1F9A8',
    sheet: [44, 58],
    native: '🦨',
  },
  {
    unicode: '1F998',
    sheet: [44, 42],
    native: '🦘',
  },
  {
    unicode: '1F9A1',
    sheet: [44, 51],
    native: '🦡',
  },
  {
    unicode: '1F43E',
    sheet: [11, 50],
    native: '🐾',
  },
  {
    unicode: '1F983',
    sheet: [44, 21],
    native: '🦃',
  },
  {
    unicode: '1F414',
    sheet: [11, 6],
    native: '🐔',
  },
  {
    unicode: '1F413',
    sheet: [11, 5],
    native: '🐓',
  },
  {
    unicode: '1F423',
    sheet: [11, 22],
    native: '🐣',
  },
  {
    unicode: '1F424',
    sheet: [11, 23],
    native: '🐤',
  },
  {
    unicode: '1F425',
    sheet: [11, 24],
    native: '🐥',
  },
  {
    unicode: '1F426',
    sheet: [11, 25],
    native: '🐦',
  },
  {
    unicode: '1F427',
    sheet: [11, 26],
    native: '🐧',
  },
  {
    unicode: '1F54A-FE0F',
    sheet: [30, 27],
    native: '🕊️',
  },
  {
    unicode: '1F985',
    sheet: [44, 23],
    native: '🦅',
  },
  {
    unicode: '1F986',
    sheet: [44, 24],
    native: '🦆',
  },
  {
    unicode: '1F9A2',
    sheet: [44, 52],
    native: '🦢',
  },
  {
    unicode: '1F989',
    sheet: [44, 27],
    native: '🦉',
  },
  {
    unicode: '1F9A4',
    sheet: [44, 54],
    native: '🦤',
  },
  {
    unicode: '1FAB6',
    sheet: [54, 37],
    native: '🪶',
  },
  {
    unicode: '1F9A9',
    sheet: [44, 59],
    native: '🦩',
  },
  {
    unicode: '1F99A',
    sheet: [44, 44],
    native: '🦚',
  },
  {
    unicode: '1F99C',
    sheet: [44, 46],
    native: '🦜',
  },
  {
    unicode: '1F438',
    sheet: [11, 43],
    native: '🐸',
  },
  {
    unicode: '1F40A',
    sheet: [10, 57],
    native: '🐊',
  },
  {
    unicode: '1F422',
    sheet: [11, 21],
    native: '🐢',
  },
  {
    unicode: '1F98E',
    sheet: [44, 32],
    native: '🦎',
  },
  {
    unicode: '1F40D',
    sheet: [10, 60],
    native: '🐍',
  },
  {
    unicode: '1F432',
    sheet: [11, 37],
    native: '🐲',
  },
  {
    unicode: '1F409',
    sheet: [10, 56],
    native: '🐉',
  },
  {
    unicode: '1F995',
    sheet: [44, 39],
    native: '🦕',
  },
  {
    unicode: '1F996',
    sheet: [44, 40],
    native: '🦖',
  },
  {
    unicode: '1F433',
    sheet: [11, 38],
    native: '🐳',
  },
  {
    unicode: '1F40B',
    sheet: [10, 58],
    native: '🐋',
  },
  {
    unicode: '1F42C',
    sheet: [11, 31],
    native: '🐬',
  },
  {
    unicode: '1F9AD',
    sheet: [45, 2],
    native: '🦭',
  },
  {
    unicode: '1F41F',
    sheet: [11, 18],
    native: '🐟',
  },
  {
    unicode: '1F420',
    sheet: [11, 19],
    native: '🐠',
  },
  {
    unicode: '1F421',
    sheet: [11, 20],
    native: '🐡',
  },
  {
    unicode: '1F988',
    sheet: [44, 26],
    native: '🦈',
  },
  {
    unicode: '1F419',
    sheet: [11, 12],
    native: '🐙',
  },
  {
    unicode: '1F41A',
    sheet: [11, 13],
    native: '🐚',
  },
  {
    unicode: '1FAB8',
    sheet: [54, 39],
    native: '🪸',
  },
  {
    unicode: '1F40C',
    sheet: [10, 59],
    native: '🐌',
  },
  {
    unicode: '1F98B',
    sheet: [44, 29],
    native: '🦋',
  },
  {
    unicode: '1F41B',
    sheet: [11, 14],
    native: '🐛',
  },
  {
    unicode: '1F41C',
    sheet: [11, 15],
    native: '🐜',
  },
  {
    unicode: '1F41D',
    sheet: [11, 16],
    native: '🐝',
  },
  {
    unicode: '1FAB2',
    sheet: [54, 33],
    native: '🪲',
  },
  {
    unicode: '1F41E',
    sheet: [11, 17],
    native: '🐞',
  },
  {
    unicode: '1F997',
    sheet: [44, 41],
    native: '🦗',
  },
  {
    unicode: '1FAB3',
    sheet: [54, 34],
    native: '🪳',
  },
  {
    unicode: '1F577-FE0F',
    sheet: [31, 23],
    native: '🕷️',
  },
  {
    unicode: '1F578-FE0F',
    sheet: [31, 24],
    native: '🕸️',
  },
  {
    unicode: '1F982',
    sheet: [44, 20],
    native: '🦂',
  },
  {
    unicode: '1F99F',
    sheet: [44, 49],
    native: '🦟',
  },
  {
    unicode: '1FAB0',
    sheet: [54, 31],
    native: '🪰',
  },
  {
    unicode: '1FAB1',
    sheet: [54, 32],
    native: '🪱',
  },
  {
    unicode: '1F9A0',
    sheet: [44, 50],
    native: '🦠',
  },
  {
    unicode: '1F490',
    sheet: [27, 6],
    native: '💐',
  },
  {
    unicode: '1F338',
    sheet: [5, 53],
    native: '🌸',
  },
  {
    unicode: '1F4AE',
    sheet: [28, 5],
    native: '💮',
  },
  {
    unicode: '1FAB7',
    sheet: [54, 38],
    native: '🪷',
  },
  {
    unicode: '1F3F5-FE0F',
    sheet: [10, 36],
    native: '🏵️',
  },
  {
    unicode: '1F339',
    sheet: [5, 54],
    native: '🌹',
  },
  {
    unicode: '1F940',
    sheet: [43, 11],
    native: '🥀',
  },
  {
    unicode: '1F33A',
    sheet: [5, 55],
    native: '🌺',
  },
  {
    unicode: '1F33B',
    sheet: [5, 56],
    native: '🌻',
  },
  {
    unicode: '1F33C',
    sheet: [5, 57],
    native: '🌼',
  },
  {
    unicode: '1F337',
    sheet: [5, 52],
    native: '🌷',
  },
  {
    unicode: '1F331',
    sheet: [5, 46],
    native: '🌱',
  },
  {
    unicode: '1FAB4',
    sheet: [54, 35],
    native: '🪴',
  },
  {
    unicode: '1F332',
    sheet: [5, 47],
    native: '🌲',
  },
  {
    unicode: '1F333',
    sheet: [5, 48],
    native: '🌳',
  },
  {
    unicode: '1F334',
    sheet: [5, 49],
    native: '🌴',
  },
  {
    unicode: '1F335',
    sheet: [5, 50],
    native: '🌵',
  },
  {
    unicode: '1F33E',
    sheet: [5, 59],
    native: '🌾',
  },
  {
    unicode: '1F33F',
    sheet: [5, 60],
    native: '🌿',
  },
  {
    unicode: '2618-FE0F',
    sheet: [56, 49],
    native: '☘️',
  },
  {
    unicode: '1F340',
    sheet: [6, 0],
    native: '🍀',
  },
  {
    unicode: '1F341',
    sheet: [6, 1],
    native: '🍁',
  },
  {
    unicode: '1F342',
    sheet: [6, 2],
    native: '🍂',
  },
  {
    unicode: '1F343',
    sheet: [6, 3],
    native: '🍃',
  },
  {
    unicode: '1FAB9',
    sheet: [54, 40],
    native: '🪹',
  },
  {
    unicode: '1FABA',
    sheet: [54, 41],
    native: '🪺',
  },
  {
    unicode: '1F347',
    sheet: [6, 7],
    native: '🍇',
  },
  {
    unicode: '1F348',
    sheet: [6, 8],
    native: '🍈',
  },
  {
    unicode: '1F349',
    sheet: [6, 9],
    native: '🍉',
  },
  {
    unicode: '1F34A',
    sheet: [6, 10],
    native: '🍊',
  },
  {
    unicode: '1F34B',
    sheet: [6, 11],
    native: '🍋',
  },
  {
    unicode: '1F34C',
    sheet: [6, 12],
    native: '🍌',
  },
  {
    unicode: '1F34D',
    sheet: [6, 13],
    native: '🍍',
  },
  {
    unicode: '1F96D',
    sheet: [43, 55],
    native: '🥭',
  },
  {
    unicode: '1F34E',
    sheet: [6, 14],
    native: '🍎',
  },
  {
    unicode: '1F34F',
    sheet: [6, 15],
    native: '🍏',
  },
  {
    unicode: '1F350',
    sheet: [6, 16],
    native: '🍐',
  },
  {
    unicode: '1F351',
    sheet: [6, 17],
    native: '🍑',
  },
  {
    unicode: '1F352',
    sheet: [6, 18],
    native: '🍒',
  },
  {
    unicode: '1F353',
    sheet: [6, 19],
    native: '🍓',
  },
  {
    unicode: '1FAD0',
    sheet: [55, 2],
    native: '🫐',
  },
  {
    unicode: '1F95D',
    sheet: [43, 39],
    native: '🥝',
  },
  {
    unicode: '1F345',
    sheet: [6, 5],
    native: '🍅',
  },
  {
    unicode: '1FAD2',
    sheet: [55, 4],
    native: '🫒',
  },
  {
    unicode: '1F965',
    sheet: [43, 47],
    native: '🥥',
  },
  {
    unicode: '1F951',
    sheet: [43, 27],
    native: '🥑',
  },
  {
    unicode: '1F346',
    sheet: [6, 6],
    native: '🍆',
  },
  {
    unicode: '1F954',
    sheet: [43, 30],
    native: '🥔',
  },
  {
    unicode: '1F955',
    sheet: [43, 31],
    native: '🥕',
  },
  {
    unicode: '1F33D',
    sheet: [5, 58],
    native: '🌽',
  },
  {
    unicode: '1F336-FE0F',
    sheet: [5, 51],
    native: '🌶️',
  },
  {
    unicode: '1FAD1',
    sheet: [55, 3],
    native: '🫑',
  },
  {
    unicode: '1F952',
    sheet: [43, 28],
    native: '🥒',
  },
  {
    unicode: '1F96C',
    sheet: [43, 54],
    native: '🥬',
  },
  {
    unicode: '1F966',
    sheet: [43, 48],
    native: '🥦',
  },
  {
    unicode: '1F9C4',
    sheet: [46, 9],
    native: '🧄',
  },
  {
    unicode: '1F9C5',
    sheet: [46, 10],
    native: '🧅',
  },
  {
    unicode: '1F344',
    sheet: [6, 4],
    native: '🍄',
  },
  {
    unicode: '1F95C',
    sheet: [43, 38],
    native: '🥜',
  },
  {
    unicode: '1FAD8',
    sheet: [55, 10],
    native: '🫘',
  },
  {
    unicode: '1F330',
    sheet: [5, 45],
    native: '🌰',
  },
  {
    unicode: '1F35E',
    sheet: [6, 30],
    native: '🍞',
  },
  {
    unicode: '1F950',
    sheet: [43, 26],
    native: '🥐',
  },
  {
    unicode: '1F956',
    sheet: [43, 32],
    native: '🥖',
  },
  {
    unicode: '1FAD3',
    sheet: [55, 5],
    native: '🫓',
  },
  {
    unicode: '1F968',
    sheet: [43, 50],
    native: '🥨',
  },
  {
    unicode: '1F96F',
    sheet: [43, 57],
    native: '🥯',
  },
  {
    unicode: '1F95E',
    sheet: [43, 40],
    native: '🥞',
  },
  {
    unicode: '1F9C7',
    sheet: [46, 12],
    native: '🧇',
  },
  {
    unicode: '1F9C0',
    sheet: [46, 5],
    native: '🧀',
  },
  {
    unicode: '1F356',
    sheet: [6, 22],
    native: '🍖',
  },
  {
    unicode: '1F357',
    sheet: [6, 23],
    native: '🍗',
  },
  {
    unicode: '1F969',
    sheet: [43, 51],
    native: '🥩',
  },
  {
    unicode: '1F953',
    sheet: [43, 29],
    native: '🥓',
  },
  {
    unicode: '1F354',
    sheet: [6, 20],
    native: '🍔',
  },
  {
    unicode: '1F35F',
    sheet: [6, 31],
    native: '🍟',
  },
  {
    unicode: '1F355',
    sheet: [6, 21],
    native: '🍕',
  },
  {
    unicode: '1F32D',
    sheet: [5, 42],
    native: '🌭',
  },
  {
    unicode: '1F96A',
    sheet: [43, 52],
    native: '🥪',
  },
  {
    unicode: '1F32E',
    sheet: [5, 43],
    native: '🌮',
  },
  {
    unicode: '1F32F',
    sheet: [5, 44],
    native: '🌯',
  },
  {
    unicode: '1FAD4',
    sheet: [55, 6],
    native: '🫔',
  },
  {
    unicode: '1F959',
    sheet: [43, 35],
    native: '🥙',
  },
  {
    unicode: '1F9C6',
    sheet: [46, 11],
    native: '🧆',
  },
  {
    unicode: '1F95A',
    sheet: [43, 36],
    native: '🥚',
  },
  {
    unicode: '1F373',
    sheet: [6, 51],
    native: '🍳',
  },
  {
    unicode: '1F958',
    sheet: [43, 34],
    native: '🥘',
  },
  {
    unicode: '1F372',
    sheet: [6, 50],
    native: '🍲',
  },
  {
    unicode: '1FAD5',
    sheet: [55, 7],
    native: '🫕',
  },
  {
    unicode: '1F963',
    sheet: [43, 45],
    native: '🥣',
  },
  {
    unicode: '1F957',
    sheet: [43, 33],
    native: '🥗',
  },
  {
    unicode: '1F37F',
    sheet: [7, 2],
    native: '🍿',
  },
  {
    unicode: '1F9C8',
    sheet: [46, 13],
    native: '🧈',
  },
  {
    unicode: '1F9C2',
    sheet: [46, 7],
    native: '🧂',
  },
  {
    unicode: '1F96B',
    sheet: [43, 53],
    native: '🥫',
  },
  {
    unicode: '1F371',
    sheet: [6, 49],
    native: '🍱',
  },
  {
    unicode: '1F358',
    sheet: [6, 24],
    native: '🍘',
  },
  {
    unicode: '1F359',
    sheet: [6, 25],
    native: '🍙',
  },
  {
    unicode: '1F35A',
    sheet: [6, 26],
    native: '🍚',
  },
  {
    unicode: '1F35B',
    sheet: [6, 27],
    native: '🍛',
  },
  {
    unicode: '1F35C',
    sheet: [6, 28],
    native: '🍜',
  },
  {
    unicode: '1F35D',
    sheet: [6, 29],
    native: '🍝',
  },
  {
    unicode: '1F360',
    sheet: [6, 32],
    native: '🍠',
  },
  {
    unicode: '1F362',
    sheet: [6, 34],
    native: '🍢',
  },
  {
    unicode: '1F363',
    sheet: [6, 35],
    native: '🍣',
  },
  {
    unicode: '1F364',
    sheet: [6, 36],
    native: '🍤',
  },
  {
    unicode: '1F365',
    sheet: [6, 37],
    native: '🍥',
  },
  {
    unicode: '1F96E',
    sheet: [43, 56],
    native: '🥮',
  },
  {
    unicode: '1F361',
    sheet: [6, 33],
    native: '🍡',
  },
  {
    unicode: '1F95F',
    sheet: [43, 41],
    native: '🥟',
  },
  {
    unicode: '1F960',
    sheet: [43, 42],
    native: '🥠',
  },
  {
    unicode: '1F961',
    sheet: [43, 43],
    native: '🥡',
  },
  {
    unicode: '1F980',
    sheet: [44, 18],
    native: '🦀',
  },
  {
    unicode: '1F99E',
    sheet: [44, 48],
    native: '🦞',
  },
  {
    unicode: '1F990',
    sheet: [44, 34],
    native: '🦐',
  },
  {
    unicode: '1F991',
    sheet: [44, 35],
    native: '🦑',
  },
  {
    unicode: '1F9AA',
    sheet: [44, 60],
    native: '🦪',
  },
  {
    unicode: '1F366',
    sheet: [6, 38],
    native: '🍦',
  },
  {
    unicode: '1F367',
    sheet: [6, 39],
    native: '🍧',
  },
  {
    unicode: '1F368',
    sheet: [6, 40],
    native: '🍨',
  },
  {
    unicode: '1F369',
    sheet: [6, 41],
    native: '🍩',
  },
  {
    unicode: '1F36A',
    sheet: [6, 42],
    native: '🍪',
  },
  {
    unicode: '1F382',
    sheet: [7, 5],
    native: '🎂',
  },
  {
    unicode: '1F370',
    sheet: [6, 48],
    native: '🍰',
  },
  {
    unicode: '1F9C1',
    sheet: [46, 6],
    native: '🧁',
  },
  {
    unicode: '1F967',
    sheet: [43, 49],
    native: '🥧',
  },
  {
    unicode: '1F36B',
    sheet: [6, 43],
    native: '🍫',
  },
  {
    unicode: '1F36C',
    sheet: [6, 44],
    native: '🍬',
  },
  {
    unicode: '1F36D',
    sheet: [6, 45],
    native: '🍭',
  },
  {
    unicode: '1F36E',
    sheet: [6, 46],
    native: '🍮',
  },
  {
    unicode: '1F36F',
    sheet: [6, 47],
    native: '🍯',
  },
  {
    unicode: '1F37C',
    sheet: [6, 60],
    native: '🍼',
  },
  {
    unicode: '1F95B',
    sheet: [43, 37],
    native: '🥛',
  },
  {
    unicode: '2615',
    sheet: [56, 48],
    native: '☕',
  },
  {
    unicode: '1FAD6',
    sheet: [55, 8],
    native: '🫖',
  },
  {
    unicode: '1F375',
    sheet: [6, 53],
    native: '🍵',
  },
  {
    unicode: '1F376',
    sheet: [6, 54],
    native: '🍶',
  },
  {
    unicode: '1F37E',
    sheet: [7, 1],
    native: '🍾',
  },
  {
    unicode: '1F377',
    sheet: [6, 55],
    native: '🍷',
  },
  {
    unicode: '1F378',
    sheet: [6, 56],
    native: '🍸',
  },
  {
    unicode: '1F379',
    sheet: [6, 57],
    native: '🍹',
  },
  {
    unicode: '1F37A',
    sheet: [6, 58],
    native: '🍺',
  },
  {
    unicode: '1F37B',
    sheet: [6, 59],
    native: '🍻',
  },
  {
    unicode: '1F942',
    sheet: [43, 13],
    native: '🥂',
  },
  {
    unicode: '1F943',
    sheet: [43, 14],
    native: '🥃',
  },
  {
    unicode: '1FAD7',
    sheet: [55, 9],
    native: '🫗',
  },
  {
    unicode: '1F964',
    sheet: [43, 46],
    native: '🥤',
  },
  {
    unicode: '1F9CB',
    sheet: [46, 16],
    native: '🧋',
  },
  {
    unicode: '1F9C3',
    sheet: [46, 8],
    native: '🧃',
  },
  {
    unicode: '1F9C9',
    sheet: [46, 14],
    native: '🧉',
  },
  {
    unicode: '1F9CA',
    sheet: [46, 15],
    native: '🧊',
  },
  {
    unicode: '1F962',
    sheet: [43, 44],
    native: '🥢',
  },
  {
    unicode: '1F37D-FE0F',
    sheet: [7, 0],
    native: '🍽️',
  },
  {
    unicode: '1F374',
    sheet: [6, 52],
    native: '🍴',
  },
  {
    unicode: '1F944',
    sheet: [43, 15],
    native: '🥄',
  },
  {
    unicode: '1F52A',
    sheet: [30, 6],
    native: '🔪',
  },
  {
    unicode: '1FAD9',
    sheet: [55, 11],
    native: '🫙',
  },
  {
    unicode: '1F3FA',
    sheet: [10, 40],
    native: '🏺',
  },
  {
    unicode: '1F30D',
    sheet: [5, 12],
    native: '🌍',
  },
  {
    unicode: '1F30E',
    sheet: [5, 13],
    native: '🌎',
  },
  {
    unicode: '1F30F',
    sheet: [5, 14],
    native: '🌏',
  },
  {
    unicode: '1F310',
    sheet: [5, 15],
    native: '🌐',
  },
  {
    unicode: '1F5FA-FE0F',
    sheet: [32, 14],
    native: '🗺️',
  },
  {
    unicode: '1F5FE',
    sheet: [32, 18],
    native: '🗾',
  },
  {
    unicode: '1F9ED',
    sheet: [53, 27],
    native: '🧭',
  },
  {
    unicode: '1F3D4-FE0F',
    sheet: [9, 60],
    native: '🏔️',
  },
  {
    unicode: '26F0-FE0F',
    sheet: [57, 56],
    native: '⛰️',
  },
  {
    unicode: '1F30B',
    sheet: [5, 10],
    native: '🌋',
  },
  {
    unicode: '1F5FB',
    sheet: [32, 15],
    native: '🗻',
  },
  {
    unicode: '1F3D5-FE0F',
    sheet: [10, 0],
    native: '🏕️',
  },
  {
    unicode: '1F3D6-FE0F',
    sheet: [10, 1],
    native: '🏖️',
  },
  {
    unicode: '1F3DC-FE0F',
    sheet: [10, 7],
    native: '🏜️',
  },
  {
    unicode: '1F3DD-FE0F',
    sheet: [10, 8],
    native: '🏝️',
  },
  {
    unicode: '1F3DE-FE0F',
    sheet: [10, 9],
    native: '🏞️',
  },
  {
    unicode: '1F3DF-FE0F',
    sheet: [10, 10],
    native: '🏟️',
  },
  {
    unicode: '1F3DB-FE0F',
    sheet: [10, 6],
    native: '🏛️',
  },
  {
    unicode: '1F3D7-FE0F',
    sheet: [10, 2],
    native: '🏗️',
  },
  {
    unicode: '1F9F1',
    sheet: [53, 31],
    native: '🧱',
  },
  {
    unicode: '1FAA8',
    sheet: [54, 26],
    native: '🪨',
  },
  {
    unicode: '1FAB5',
    sheet: [54, 36],
    native: '🪵',
  },
  {
    unicode: '1F6D6',
    sheet: [38, 6],
    native: '🛖',
  },
  {
    unicode: '1F3D8-FE0F',
    sheet: [10, 3],
    native: '🏘️',
  },
  {
    unicode: '1F3DA-FE0F',
    sheet: [10, 5],
    native: '🏚️',
  },
  {
    unicode: '1F3E0',
    sheet: [10, 11],
    native: '🏠',
  },
  {
    unicode: '1F3E1',
    sheet: [10, 12],
    native: '🏡',
  },
  {
    unicode: '1F3E2',
    sheet: [10, 13],
    native: '🏢',
  },
  {
    unicode: '1F3E3',
    sheet: [10, 14],
    native: '🏣',
  },
  {
    unicode: '1F3E4',
    sheet: [10, 15],
    native: '🏤',
  },
  {
    unicode: '1F3E5',
    sheet: [10, 16],
    native: '🏥',
  },
  {
    unicode: '1F3E6',
    sheet: [10, 17],
    native: '🏦',
  },
  {
    unicode: '1F3E8',
    sheet: [10, 19],
    native: '🏨',
  },
  {
    unicode: '1F3E9',
    sheet: [10, 20],
    native: '🏩',
  },
  {
    unicode: '1F3EA',
    sheet: [10, 21],
    native: '🏪',
  },
  {
    unicode: '1F3EB',
    sheet: [10, 22],
    native: '🏫',
  },
  {
    unicode: '1F3EC',
    sheet: [10, 23],
    native: '🏬',
  },
  {
    unicode: '1F3ED',
    sheet: [10, 24],
    native: '🏭',
  },
  {
    unicode: '1F3EF',
    sheet: [10, 26],
    native: '🏯',
  },
  {
    unicode: '1F3F0',
    sheet: [10, 27],
    native: '🏰',
  },
  {
    unicode: '1F492',
    sheet: [27, 33],
    native: '💒',
  },
  {
    unicode: '1F5FC',
    sheet: [32, 16],
    native: '🗼',
  },
  {
    unicode: '1F5FD',
    sheet: [32, 17],
    native: '🗽',
  },
  {
    unicode: '26EA',
    sheet: [57, 55],
    native: '⛪',
  },
  {
    unicode: '1F54C',
    sheet: [30, 29],
    native: '🕌',
  },
  {
    unicode: '1F6D5',
    sheet: [38, 5],
    native: '🛕',
  },
  {
    unicode: '1F54D',
    sheet: [30, 30],
    native: '🕍',
  },
  {
    unicode: '26E9-FE0F',
    sheet: [57, 54],
    native: '⛩️',
  },
  {
    unicode: '1F54B',
    sheet: [30, 28],
    native: '🕋',
  },
  {
    unicode: '26F2',
    sheet: [57, 58],
    native: '⛲',
  },
  {
    unicode: '26FA',
    sheet: [58, 21],
    native: '⛺',
  },
  {
    unicode: '1F301',
    sheet: [5, 0],
    native: '🌁',
  },
  {
    unicode: '1F303',
    sheet: [5, 2],
    native: '🌃',
  },
  {
    unicode: '1F3D9-FE0F',
    sheet: [10, 4],
    native: '🏙️',
  },
  {
    unicode: '1F304',
    sheet: [5, 3],
    native: '🌄',
  },
  {
    unicode: '1F305',
    sheet: [5, 4],
    native: '🌅',
  },
  {
    unicode: '1F306',
    sheet: [5, 5],
    native: '🌆',
  },
  {
    unicode: '1F307',
    sheet: [5, 6],
    native: '🌇',
  },
  {
    unicode: '1F309',
    sheet: [5, 8],
    native: '🌉',
  },
  {
    unicode: '2668-FE0F',
    sheet: [57, 24],
    native: '♨️',
  },
  {
    unicode: '1F3A0',
    sheet: [7, 35],
    native: '🎠',
  },
  {
    unicode: '1F6DD',
    sheet: [38, 8],
    native: '🛝',
  },
  {
    unicode: '1F3A1',
    sheet: [7, 36],
    native: '🎡',
  },
  {
    unicode: '1F3A2',
    sheet: [7, 37],
    native: '🎢',
  },
  {
    unicode: '1F488',
    sheet: [26, 34],
    native: '💈',
  },
  {
    unicode: '1F3AA',
    sheet: [7, 45],
    native: '🎪',
  },
  {
    unicode: '1F682',
    sheet: [35, 34],
    native: '🚂',
  },
  {
    unicode: '1F683',
    sheet: [35, 35],
    native: '🚃',
  },
  {
    unicode: '1F684',
    sheet: [35, 36],
    native: '🚄',
  },
  {
    unicode: '1F685',
    sheet: [35, 37],
    native: '🚅',
  },
  {
    unicode: '1F686',
    sheet: [35, 38],
    native: '🚆',
  },
  {
    unicode: '1F687',
    sheet: [35, 39],
    native: '🚇',
  },
  {
    unicode: '1F688',
    sheet: [35, 40],
    native: '🚈',
  },
  {
    unicode: '1F689',
    sheet: [35, 41],
    native: '🚉',
  },
  {
    unicode: '1F68A',
    sheet: [35, 42],
    native: '🚊',
  },
  {
    unicode: '1F69D',
    sheet: [36, 0],
    native: '🚝',
  },
  {
    unicode: '1F69E',
    sheet: [36, 1],
    native: '🚞',
  },
  {
    unicode: '1F68B',
    sheet: [35, 43],
    native: '🚋',
  },
  {
    unicode: '1F68C',
    sheet: [35, 44],
    native: '🚌',
  },
  {
    unicode: '1F68D',
    sheet: [35, 45],
    native: '🚍',
  },
  {
    unicode: '1F68E',
    sheet: [35, 46],
    native: '🚎',
  },
  {
    unicode: '1F690',
    sheet: [35, 48],
    native: '🚐',
  },
  {
    unicode: '1F691',
    sheet: [35, 49],
    native: '🚑',
  },
  {
    unicode: '1F692',
    sheet: [35, 50],
    native: '🚒',
  },
  {
    unicode: '1F693',
    sheet: [35, 51],
    native: '🚓',
  },
  {
    unicode: '1F694',
    sheet: [35, 52],
    native: '🚔',
  },
  {
    unicode: '1F695',
    sheet: [35, 53],
    native: '🚕',
  },
  {
    unicode: '1F696',
    sheet: [35, 54],
    native: '🚖',
  },
  {
    unicode: '1F697',
    sheet: [35, 55],
    native: '🚗',
  },
  {
    unicode: '1F698',
    sheet: [35, 56],
    native: '🚘',
  },
  {
    unicode: '1F699',
    sheet: [35, 57],
    native: '🚙',
  },
  {
    unicode: '1F6FB',
    sheet: [38, 29],
    native: '🛻',
  },
  {
    unicode: '1F69A',
    sheet: [35, 58],
    native: '🚚',
  },
  {
    unicode: '1F69B',
    sheet: [35, 59],
    native: '🚛',
  },
  {
    unicode: '1F69C',
    sheet: [35, 60],
    native: '🚜',
  },
  {
    unicode: '1F3CE-FE0F',
    sheet: [9, 54],
    native: '🏎️',
  },
  {
    unicode: '1F3CD-FE0F',
    sheet: [9, 53],
    native: '🏍️',
  },
  {
    unicode: '1F6F5',
    sheet: [38, 23],
    native: '🛵',
  },
  {
    unicode: '1F9BD',
    sheet: [46, 2],
    native: '🦽',
  },
  {
    unicode: '1F9BC',
    sheet: [46, 1],
    native: '🦼',
  },
  {
    unicode: '1F6FA',
    sheet: [38, 28],
    native: '🛺',
  },
  {
    unicode: '1F6B2',
    sheet: [36, 38],
    native: '🚲',
  },
  {
    unicode: '1F6F4',
    sheet: [38, 22],
    native: '🛴',
  },
  {
    unicode: '1F6F9',
    sheet: [38, 27],
    native: '🛹',
  },
  {
    unicode: '1F6FC',
    sheet: [38, 30],
    native: '🛼',
  },
  {
    unicode: '1F68F',
    sheet: [35, 47],
    native: '🚏',
  },
  {
    unicode: '1F6E3-FE0F',
    sheet: [38, 14],
    native: '🛣️',
  },
  {
    unicode: '1F6E4-FE0F',
    sheet: [38, 15],
    native: '🛤️',
  },
  {
    unicode: '1F6E2-FE0F',
    sheet: [38, 13],
    native: '🛢️',
  },
  {
    unicode: '26FD',
    sheet: [58, 22],
    native: '⛽',
  },
  {
    unicode: '1F6DE',
    sheet: [38, 9],
    native: '🛞',
  },
  {
    unicode: '1F6A8',
    sheet: [36, 28],
    native: '🚨',
  },
  {
    unicode: '1F6A5',
    sheet: [36, 25],
    native: '🚥',
  },
  {
    unicode: '1F6A6',
    sheet: [36, 26],
    native: '🚦',
  },
  {
    unicode: '1F6D1',
    sheet: [38, 3],
    native: '🛑',
  },
  {
    unicode: '1F6A7',
    sheet: [36, 27],
    native: '🚧',
  },
  {
    unicode: '2693',
    sheet: [57, 29],
    native: '⚓',
  },
  {
    unicode: '1F6DF',
    sheet: [38, 10],
    native: '🛟',
  },
  {
    unicode: '26F5',
    sheet: [58, 0],
    native: '⛵',
  },
  {
    unicode: '1F6F6',
    sheet: [38, 24],
    native: '🛶',
  },
  {
    unicode: '1F6A4',
    sheet: [36, 24],
    native: '🚤',
  },
  {
    unicode: '1F6F3-FE0F',
    sheet: [38, 21],
    native: '🛳️',
  },
  {
    unicode: '26F4-FE0F',
    sheet: [57, 60],
    native: '⛴️',
  },
  {
    unicode: '1F6E5-FE0F',
    sheet: [38, 16],
    native: '🛥️',
  },
  {
    unicode: '1F6A2',
    sheet: [36, 5],
    native: '🚢',
  },
  {
    unicode: '2708-FE0F',
    sheet: [58, 25],
    native: '✈️',
  },
  {
    unicode: '1F6E9-FE0F',
    sheet: [38, 17],
    native: '🛩️',
  },
  {
    unicode: '1F6EB',
    sheet: [38, 18],
    native: '🛫',
  },
  {
    unicode: '1F6EC',
    sheet: [38, 19],
    native: '🛬',
  },
  {
    unicode: '1FA82',
    sheet: [53, 58],
    native: '🪂',
  },
  {
    unicode: '1F4BA',
    sheet: [28, 17],
    native: '💺',
  },
  {
    unicode: '1F681',
    sheet: [35, 33],
    native: '🚁',
  },
  {
    unicode: '1F69F',
    sheet: [36, 2],
    native: '🚟',
  },
  {
    unicode: '1F6A0',
    sheet: [36, 3],
    native: '🚠',
  },
  {
    unicode: '1F6A1',
    sheet: [36, 4],
    native: '🚡',
  },
  {
    unicode: '1F6F0-FE0F',
    sheet: [38, 20],
    native: '🛰️',
  },
  {
    unicode: '1F680',
    sheet: [35, 32],
    native: '🚀',
  },
  {
    unicode: '1F6F8',
    sheet: [38, 26],
    native: '🛸',
  },
  {
    unicode: '1F6CE-FE0F',
    sheet: [38, 0],
    native: '🛎️',
  },
  {
    unicode: '1F9F3',
    sheet: [53, 33],
    native: '🧳',
  },
  {
    unicode: '231B',
    sheet: [56, 14],
    native: '⌛',
  },
  {
    unicode: '23F3',
    sheet: [56, 27],
    native: '⏳',
  },
  {
    unicode: '231A',
    sheet: [56, 13],
    native: '⌚',
  },
  {
    unicode: '23F0',
    sheet: [56, 24],
    native: '⏰',
  },
  {
    unicode: '23F1-FE0F',
    sheet: [56, 25],
    native: '⏱️',
  },
  {
    unicode: '23F2-FE0F',
    sheet: [56, 26],
    native: '⏲️',
  },
  {
    unicode: '1F570-FE0F',
    sheet: [30, 57],
    native: '🕰️',
  },
  {
    unicode: '1F55B',
    sheet: [30, 43],
    native: '🕛',
  },
  {
    unicode: '1F567',
    sheet: [30, 55],
    native: '🕧',
  },
  {
    unicode: '1F550',
    sheet: [30, 32],
    native: '🕐',
  },
  {
    unicode: '1F55C',
    sheet: [30, 44],
    native: '🕜',
  },
  {
    unicode: '1F551',
    sheet: [30, 33],
    native: '🕑',
  },
  {
    unicode: '1F55D',
    sheet: [30, 45],
    native: '🕝',
  },
  {
    unicode: '1F552',
    sheet: [30, 34],
    native: '🕒',
  },
  {
    unicode: '1F55E',
    sheet: [30, 46],
    native: '🕞',
  },
  {
    unicode: '1F553',
    sheet: [30, 35],
    native: '🕓',
  },
  {
    unicode: '1F55F',
    sheet: [30, 47],
    native: '🕟',
  },
  {
    unicode: '1F554',
    sheet: [30, 36],
    native: '🕔',
  },
  {
    unicode: '1F560',
    sheet: [30, 48],
    native: '🕠',
  },
  {
    unicode: '1F555',
    sheet: [30, 37],
    native: '🕕',
  },
  {
    unicode: '1F561',
    sheet: [30, 49],
    native: '🕡',
  },
  {
    unicode: '1F556',
    sheet: [30, 38],
    native: '🕖',
  },
  {
    unicode: '1F562',
    sheet: [30, 50],
    native: '🕢',
  },
  {
    unicode: '1F557',
    sheet: [30, 39],
    native: '🕗',
  },
  {
    unicode: '1F563',
    sheet: [30, 51],
    native: '🕣',
  },
  {
    unicode: '1F558',
    sheet: [30, 40],
    native: '🕘',
  },
  {
    unicode: '1F564',
    sheet: [30, 52],
    native: '🕤',
  },
  {
    unicode: '1F559',
    sheet: [30, 41],
    native: '🕙',
  },
  {
    unicode: '1F565',
    sheet: [30, 53],
    native: '🕥',
  },
  {
    unicode: '1F55A',
    sheet: [30, 42],
    native: '🕚',
  },
  {
    unicode: '1F566',
    sheet: [30, 54],
    native: '🕦',
  },
  {
    unicode: '1F311',
    sheet: [5, 16],
    native: '🌑',
  },
  {
    unicode: '1F312',
    sheet: [5, 17],
    native: '🌒',
  },
  {
    unicode: '1F313',
    sheet: [5, 18],
    native: '🌓',
  },
  {
    unicode: '1F314',
    sheet: [5, 19],
    native: '🌔',
  },
  {
    unicode: '1F315',
    sheet: [5, 20],
    native: '🌕',
  },
  {
    unicode: '1F316',
    sheet: [5, 21],
    native: '🌖',
  },
  {
    unicode: '1F317',
    sheet: [5, 22],
    native: '🌗',
  },
  {
    unicode: '1F318',
    sheet: [5, 23],
    native: '🌘',
  },
  {
    unicode: '1F319',
    sheet: [5, 24],
    native: '🌙',
  },
  {
    unicode: '1F31A',
    sheet: [5, 25],
    native: '🌚',
  },
  {
    unicode: '1F31B',
    sheet: [5, 26],
    native: '🌛',
  },
  {
    unicode: '1F31C',
    sheet: [5, 27],
    native: '🌜',
  },
  {
    unicode: '1F321-FE0F',
    sheet: [5, 32],
    native: '🌡️',
  },
  {
    unicode: '2600-FE0F',
    sheet: [56, 40],
    native: '☀️',
  },
  {
    unicode: '1F31D',
    sheet: [5, 28],
    native: '🌝',
  },
  {
    unicode: '1F31E',
    sheet: [5, 29],
    native: '🌞',
  },
  {
    unicode: '1FA90',
    sheet: [54, 2],
    native: '🪐',
  },
  {
    unicode: '2B50',
    sheet: [59, 24],
    native: '⭐',
  },
  {
    unicode: '1F31F',
    sheet: [5, 30],
    native: '🌟',
  },
  {
    unicode: '1F320',
    sheet: [5, 31],
    native: '🌠',
  },
  {
    unicode: '1F30C',
    sheet: [5, 11],
    native: '🌌',
  },
  {
    unicode: '2601-FE0F',
    sheet: [56, 41],
    native: '☁️',
  },
  {
    unicode: '26C5',
    sheet: [57, 47],
    native: '⛅',
  },
  {
    unicode: '26C8-FE0F',
    sheet: [57, 48],
    native: '⛈️',
  },
  {
    unicode: '1F324-FE0F',
    sheet: [5, 33],
    native: '🌤️',
  },
  {
    unicode: '1F325-FE0F',
    sheet: [5, 34],
    native: '🌥️',
  },
  {
    unicode: '1F326-FE0F',
    sheet: [5, 35],
    native: '🌦️',
  },
  {
    unicode: '1F327-FE0F',
    sheet: [5, 36],
    native: '🌧️',
  },
  {
    unicode: '1F328-FE0F',
    sheet: [5, 37],
    native: '🌨️',
  },
  {
    unicode: '1F329-FE0F',
    sheet: [5, 38],
    native: '🌩️',
  },
  {
    unicode: '1F32A-FE0F',
    sheet: [5, 39],
    native: '🌪️',
  },
  {
    unicode: '1F32B-FE0F',
    sheet: [5, 40],
    native: '🌫️',
  },
  {
    unicode: '1F32C-FE0F',
    sheet: [5, 41],
    native: '🌬️',
  },
  {
    unicode: '1F300',
    sheet: [4, 60],
    native: '🌀',
  },
  {
    unicode: '1F308',
    sheet: [5, 7],
    native: '🌈',
  },
  {
    unicode: '1F302',
    sheet: [5, 1],
    native: '🌂',
  },
  {
    unicode: '2602-FE0F',
    sheet: [56, 42],
    native: '☂️',
  },
  {
    unicode: '2614',
    sheet: [56, 47],
    native: '☔',
  },
  {
    unicode: '26F1-FE0F',
    sheet: [57, 57],
    native: '⛱️',
  },
  {
    unicode: '26A1',
    sheet: [57, 38],
    native: '⚡',
  },
  {
    unicode: '2744-FE0F',
    sheet: [58, 60],
    native: '❄️',
  },
  {
    unicode: '2603-FE0F',
    sheet: [56, 43],
    native: '☃️',
  },
  {
    unicode: '26C4',
    sheet: [57, 46],
    native: '⛄',
  },
  {
    unicode: '2604-FE0F',
    sheet: [56, 44],
    native: '☄️',
  },
  {
    unicode: '1F525',
    sheet: [30, 1],
    native: '🔥',
  },
  {
    unicode: '1F4A7',
    sheet: [27, 54],
    native: '💧',
  },
  {
    unicode: '1F30A',
    sheet: [5, 9],
    native: '🌊',
  },
  {
    unicode: '1F383',
    sheet: [7, 6],
    native: '🎃',
  },
  {
    unicode: '1F384',
    sheet: [7, 7],
    native: '🎄',
  },
  {
    unicode: '1F386',
    sheet: [7, 14],
    native: '🎆',
  },
  {
    unicode: '1F387',
    sheet: [7, 15],
    native: '🎇',
  },
  {
    unicode: '1F9E8',
    sheet: [53, 22],
    native: '🧨',
  },
  {
    unicode: '2728',
    sheet: [58, 57],
    native: '✨',
  },
  {
    unicode: '1F388',
    sheet: [7, 16],
    native: '🎈',
  },
  {
    unicode: '1F389',
    sheet: [7, 17],
    native: '🎉',
  },
  {
    unicode: '1F38A',
    sheet: [7, 18],
    native: '🎊',
  },
  {
    unicode: '1F38B',
    sheet: [7, 19],
    native: '🎋',
  },
  {
    unicode: '1F38D',
    sheet: [7, 21],
    native: '🎍',
  },
  {
    unicode: '1F38E',
    sheet: [7, 22],
    native: '🎎',
  },
  {
    unicode: '1F38F',
    sheet: [7, 23],
    native: '🎏',
  },
  {
    unicode: '1F390',
    sheet: [7, 24],
    native: '🎐',
  },
  {
    unicode: '1F391',
    sheet: [7, 25],
    native: '🎑',
  },
  {
    unicode: '1F9E7',
    sheet: [53, 21],
    native: '🧧',
  },
  {
    unicode: '1F380',
    sheet: [7, 3],
    native: '🎀',
  },
  {
    unicode: '1F381',
    sheet: [7, 4],
    native: '🎁',
  },
  {
    unicode: '1F397-FE0F',
    sheet: [7, 29],
    native: '🎗️',
  },
  {
    unicode: '1F39F-FE0F',
    sheet: [7, 34],
    native: '🎟️',
  },
  {
    unicode: '1F3AB',
    sheet: [7, 46],
    native: '🎫',
  },
  {
    unicode: '1F396-FE0F',
    sheet: [7, 28],
    native: '🎖️',
  },
  {
    unicode: '1F3C6',
    sheet: [8, 51],
    native: '🏆',
  },
  {
    unicode: '1F3C5',
    sheet: [8, 50],
    native: '🏅',
  },
  {
    unicode: '1F947',
    sheet: [43, 17],
    native: '🥇',
  },
  {
    unicode: '1F948',
    sheet: [43, 18],
    native: '🥈',
  },
  {
    unicode: '1F949',
    sheet: [43, 19],
    native: '🥉',
  },
  {
    unicode: '26BD',
    sheet: [57, 44],
    native: '⚽',
  },
  {
    unicode: '26BE',
    sheet: [57, 45],
    native: '⚾',
  },
  {
    unicode: '1F94E',
    sheet: [43, 24],
    native: '🥎',
  },
  {
    unicode: '1F3C0',
    sheet: [8, 6],
    native: '🏀',
  },
  {
    unicode: '1F3D0',
    sheet: [9, 56],
    native: '🏐',
  },
  {
    unicode: '1F3C8',
    sheet: [8, 58],
    native: '🏈',
  },
  {
    unicode: '1F3C9',
    sheet: [8, 59],
    native: '🏉',
  },
  {
    unicode: '1F3BE',
    sheet: [8, 4],
    native: '🎾',
  },
  {
    unicode: '1F94F',
    sheet: [43, 25],
    native: '🥏',
  },
  {
    unicode: '1F3B3',
    sheet: [7, 54],
    native: '🎳',
  },
  {
    unicode: '1F3CF',
    sheet: [9, 55],
    native: '🏏',
  },
  {
    unicode: '1F3D1',
    sheet: [9, 57],
    native: '🏑',
  },
  {
    unicode: '1F3D2',
    sheet: [9, 58],
    native: '🏒',
  },
  {
    unicode: '1F94D',
    sheet: [43, 23],
    native: '🥍',
  },
  {
    unicode: '1F3D3',
    sheet: [9, 59],
    native: '🏓',
  },
  {
    unicode: '1F3F8',
    sheet: [10, 38],
    native: '🏸',
  },
  {
    unicode: '1F94A',
    sheet: [43, 20],
    native: '🥊',
  },
  {
    unicode: '1F94B',
    sheet: [43, 21],
    native: '🥋',
  },
  {
    unicode: '1F945',
    sheet: [43, 16],
    native: '🥅',
  },
  {
    unicode: '26F3',
    sheet: [57, 59],
    native: '⛳',
  },
  {
    unicode: '26F8-FE0F',
    sheet: [58, 2],
    native: '⛸️',
  },
  {
    unicode: '1F3A3',
    sheet: [7, 38],
    native: '🎣',
  },
  {
    unicode: '1F93F',
    sheet: [43, 10],
    native: '🤿',
  },
  {
    unicode: '1F3BD',
    sheet: [8, 3],
    native: '🎽',
  },
  {
    unicode: '1F3BF',
    sheet: [8, 5],
    native: '🎿',
  },
  {
    unicode: '1F6F7',
    sheet: [38, 25],
    native: '🛷',
  },
  {
    unicode: '1F94C',
    sheet: [43, 22],
    native: '🥌',
  },
  {
    unicode: '1F3AF',
    sheet: [7, 50],
    native: '🎯',
  },
  {
    unicode: '1FA80',
    sheet: [53, 56],
    native: '🪀',
  },
  {
    unicode: '1FA81',
    sheet: [53, 57],
    native: '🪁',
  },
  {
    unicode: '1F3B1',
    sheet: [7, 52],
    native: '🎱',
  },
  {
    unicode: '1F52E',
    sheet: [30, 10],
    native: '🔮',
  },
  {
    unicode: '1FA84',
    sheet: [53, 60],
    native: '🪄',
  },
  {
    unicode: '1F9FF',
    sheet: [53, 45],
    native: '🧿',
  },
  {
    unicode: '1FAAC',
    sheet: [54, 30],
    native: '🪬',
  },
  {
    unicode: '1F3AE',
    sheet: [7, 49],
    native: '🎮',
  },
  {
    unicode: '1F579-FE0F',
    sheet: [31, 25],
    native: '🕹️',
  },
  {
    unicode: '1F3B0',
    sheet: [7, 51],
    native: '🎰',
  },
  {
    unicode: '1F3B2',
    sheet: [7, 53],
    native: '🎲',
  },
  {
    unicode: '1F9E9',
    sheet: [53, 23],
    native: '🧩',
  },
  {
    unicode: '1F9F8',
    sheet: [53, 38],
    native: '🧸',
  },
  {
    unicode: '1FA85',
    sheet: [54, 0],
    native: '🪅',
  },
  {
    unicode: '1FAA9',
    sheet: [54, 27],
    native: '🪩',
  },
  {
    unicode: '1FA86',
    sheet: [54, 1],
    native: '🪆',
  },
  {
    unicode: '2660-FE0F',
    sheet: [57, 20],
    native: '♠️',
  },
  {
    unicode: '2665-FE0F',
    sheet: [57, 22],
    native: '♥️',
  },
  {
    unicode: '2666-FE0F',
    sheet: [57, 23],
    native: '♦️',
  },
  {
    unicode: '2663-FE0F',
    sheet: [57, 21],
    native: '♣️',
  },
  {
    unicode: '265F-FE0F',
    sheet: [57, 19],
    native: '♟️',
  },
  {
    unicode: '1F0CF',
    sheet: [0, 15],
    native: '🃏',
  },
  {
    unicode: '1F004',
    sheet: [0, 14],
    native: '🀄',
  },
  {
    unicode: '1F3B4',
    sheet: [7, 55],
    native: '🎴',
  },
  {
    unicode: '1F3AD',
    sheet: [7, 48],
    native: '🎭',
  },
  {
    unicode: '1F5BC-FE0F',
    sheet: [31, 60],
    native: '🖼️',
  },
  {
    unicode: '1F3A8',
    sheet: [7, 43],
    native: '🎨',
  },
  {
    unicode: '1F9F5',
    sheet: [53, 35],
    native: '🧵',
  },
  {
    unicode: '1FAA1',
    sheet: [54, 19],
    native: '🪡',
  },
  {
    unicode: '1F9F6',
    sheet: [53, 36],
    native: '🧶',
  },
  {
    unicode: '1FAA2',
    sheet: [54, 20],
    native: '🪢',
  },
  {
    unicode: '1F453',
    sheet: [13, 15],
    native: '👓',
  },
  {
    unicode: '1F576-FE0F',
    sheet: [31, 22],
    native: '🕶️',
  },
  {
    unicode: '1F97D',
    sheet: [44, 15],
    native: '🥽',
  },
  {
    unicode: '1F97C',
    sheet: [44, 14],
    native: '🥼',
  },
  {
    unicode: '1F9BA',
    sheet: [45, 55],
    native: '🦺',
  },
  {
    unicode: '1F454',
    sheet: [13, 16],
    native: '👔',
  },
  {
    unicode: '1F455',
    sheet: [13, 17],
    native: '👕',
  },
  {
    unicode: '1F456',
    sheet: [13, 18],
    native: '👖',
  },
  {
    unicode: '1F9E3',
    sheet: [53, 17],
    native: '🧣',
  },
  {
    unicode: '1F9E4',
    sheet: [53, 18],
    native: '🧤',
  },
  {
    unicode: '1F9E5',
    sheet: [53, 19],
    native: '🧥',
  },
  {
    unicode: '1F9E6',
    sheet: [53, 20],
    native: '🧦',
  },
  {
    unicode: '1F457',
    sheet: [13, 19],
    native: '👗',
  },
  {
    unicode: '1F458',
    sheet: [13, 20],
    native: '👘',
  },
  {
    unicode: '1F97B',
    sheet: [44, 13],
    native: '🥻',
  },
  {
    unicode: '1FA71',
    sheet: [53, 47],
    native: '🩱',
  },
  {
    unicode: '1FA72',
    sheet: [53, 48],
    native: '🩲',
  },
  {
    unicode: '1FA73',
    sheet: [53, 49],
    native: '🩳',
  },
  {
    unicode: '1F459',
    sheet: [13, 21],
    native: '👙',
  },
  {
    unicode: '1F45A',
    sheet: [13, 22],
    native: '👚',
  },
  {
    unicode: '1F45B',
    sheet: [13, 23],
    native: '👛',
  },
  {
    unicode: '1F45C',
    sheet: [13, 24],
    native: '👜',
  },
  {
    unicode: '1F45D',
    sheet: [13, 25],
    native: '👝',
  },
  {
    unicode: '1F6CD-FE0F',
    sheet: [37, 60],
    native: '🛍️',
  },
  {
    unicode: '1F392',
    sheet: [7, 26],
    native: '🎒',
  },
  {
    unicode: '1FA74',
    sheet: [53, 50],
    native: '🩴',
  },
  {
    unicode: '1F45E',
    sheet: [13, 26],
    native: '👞',
  },
  {
    unicode: '1F45F',
    sheet: [13, 27],
    native: '👟',
  },
  {
    unicode: '1F97E',
    sheet: [44, 16],
    native: '🥾',
  },
  {
    unicode: '1F97F',
    sheet: [44, 17],
    native: '🥿',
  },
  {
    unicode: '1F460',
    sheet: [13, 28],
    native: '👠',
  },
  {
    unicode: '1F461',
    sheet: [13, 29],
    native: '👡',
  },
  {
    unicode: '1FA70',
    sheet: [53, 46],
    native: '🩰',
  },
  {
    unicode: '1F462',
    sheet: [13, 30],
    native: '👢',
  },
  {
    unicode: '1F451',
    sheet: [13, 13],
    native: '👑',
  },
  {
    unicode: '1F452',
    sheet: [13, 14],
    native: '👒',
  },
  {
    unicode: '1F3A9',
    sheet: [7, 44],
    native: '🎩',
  },
  {
    unicode: '1F393',
    sheet: [7, 27],
    native: '🎓',
  },
  {
    unicode: '1F9E2',
    sheet: [53, 16],
    native: '🧢',
  },
  {
    unicode: '1FA96',
    sheet: [54, 8],
    native: '🪖',
  },
  {
    unicode: '26D1-FE0F',
    sheet: [57, 51],
    native: '⛑️',
  },
  {
    unicode: '1F4FF',
    sheet: [29, 24],
    native: '📿',
  },
  {
    unicode: '1F484',
    sheet: [25, 52],
    native: '💄',
  },
  {
    unicode: '1F48D',
    sheet: [26, 39],
    native: '💍',
  },
  {
    unicode: '1F48E',
    sheet: [26, 40],
    native: '💎',
  },
  {
    unicode: '1F507',
    sheet: [29, 32],
    native: '🔇',
  },
  {
    unicode: '1F508',
    sheet: [29, 33],
    native: '🔈',
  },
  {
    unicode: '1F509',
    sheet: [29, 34],
    native: '🔉',
  },
  {
    unicode: '1F50A',
    sheet: [29, 35],
    native: '🔊',
  },
  {
    unicode: '1F4E2',
    sheet: [28, 57],
    native: '📢',
  },
  {
    unicode: '1F4E3',
    sheet: [28, 58],
    native: '📣',
  },
  {
    unicode: '1F4EF',
    sheet: [29, 9],
    native: '📯',
  },
  {
    unicode: '1F514',
    sheet: [29, 45],
    native: '🔔',
  },
  {
    unicode: '1F515',
    sheet: [29, 46],
    native: '🔕',
  },
  {
    unicode: '1F3BC',
    sheet: [8, 2],
    native: '🎼',
  },
  {
    unicode: '1F3B5',
    sheet: [7, 56],
    native: '🎵',
  },
  {
    unicode: '1F3B6',
    sheet: [7, 57],
    native: '🎶',
  },
  {
    unicode: '1F399-FE0F',
    sheet: [7, 30],
    native: '🎙️',
  },
  {
    unicode: '1F39A-FE0F',
    sheet: [7, 31],
    native: '🎚️',
  },
  {
    unicode: '1F39B-FE0F',
    sheet: [7, 32],
    native: '🎛️',
  },
  {
    unicode: '1F3A4',
    sheet: [7, 39],
    native: '🎤',
  },
  {
    unicode: '1F3A7',
    sheet: [7, 42],
    native: '🎧',
  },
  {
    unicode: '1F4FB',
    sheet: [29, 21],
    native: '📻',
  },
  {
    unicode: '1F3B7',
    sheet: [7, 58],
    native: '🎷',
  },
  {
    unicode: '1FA97',
    sheet: [54, 9],
    native: '🪗',
  },
  {
    unicode: '1F3B8',
    sheet: [7, 59],
    native: '🎸',
  },
  {
    unicode: '1F3B9',
    sheet: [7, 60],
    native: '🎹',
  },
  {
    unicode: '1F3BA',
    sheet: [8, 0],
    native: '🎺',
  },
  {
    unicode: '1F3BB',
    sheet: [8, 1],
    native: '🎻',
  },
  {
    unicode: '1FA95',
    sheet: [54, 7],
    native: '🪕',
  },
  {
    unicode: '1F941',
    sheet: [43, 12],
    native: '🥁',
  },
  {
    unicode: '1FA98',
    sheet: [54, 10],
    native: '🪘',
  },
  {
    unicode: '1F4F1',
    sheet: [29, 11],
    native: '📱',
  },
  {
    unicode: '1F4F2',
    sheet: [29, 12],
    native: '📲',
  },
  {
    unicode: '260E-FE0F',
    sheet: [56, 45],
    native: '☎️',
  },
  {
    unicode: '1F4DE',
    sheet: [28, 53],
    native: '📞',
  },
  {
    unicode: '1F4DF',
    sheet: [28, 54],
    native: '📟',
  },
  {
    unicode: '1F4E0',
    sheet: [28, 55],
    native: '📠',
  },
  {
    unicode: '1F50B',
    sheet: [29, 36],
    native: '🔋',
  },
  {
    unicode: '1FAAB',
    sheet: [54, 29],
    native: '🪫',
  },
  {
    unicode: '1F50C',
    sheet: [29, 37],
    native: '🔌',
  },
  {
    unicode: '1F4BB',
    sheet: [28, 18],
    native: '💻',
  },
  {
    unicode: '1F5A5-FE0F',
    sheet: [31, 56],
    native: '🖥️',
  },
  {
    unicode: '1F5A8-FE0F',
    sheet: [31, 57],
    native: '🖨️',
  },
  {
    unicode: '2328-FE0F',
    sheet: [56, 15],
    native: '⌨️',
  },
  {
    unicode: '1F5B1-FE0F',
    sheet: [31, 58],
    native: '🖱️',
  },
  {
    unicode: '1F5B2-FE0F',
    sheet: [31, 59],
    native: '🖲️',
  },
  {
    unicode: '1F4BD',
    sheet: [28, 20],
    native: '💽',
  },
  {
    unicode: '1F4BE',
    sheet: [28, 21],
    native: '💾',
  },
  {
    unicode: '1F4BF',
    sheet: [28, 22],
    native: '💿',
  },
  {
    unicode: '1F4C0',
    sheet: [28, 23],
    native: '📀',
  },
  {
    unicode: '1F9EE',
    sheet: [53, 28],
    native: '🧮',
  },
  {
    unicode: '1F3A5',
    sheet: [7, 40],
    native: '🎥',
  },
  {
    unicode: '1F39E-FE0F',
    sheet: [7, 33],
    native: '🎞️',
  },
  {
    unicode: '1F4FD-FE0F',
    sheet: [29, 23],
    native: '📽️',
  },
  {
    unicode: '1F3AC',
    sheet: [7, 47],
    native: '🎬',
  },
  {
    unicode: '1F4FA',
    sheet: [29, 20],
    native: '📺',
  },
  {
    unicode: '1F4F7',
    sheet: [29, 17],
    native: '📷',
  },
  {
    unicode: '1F4F8',
    sheet: [29, 18],
    native: '📸',
  },
  {
    unicode: '1F4F9',
    sheet: [29, 19],
    native: '📹',
  },
  {
    unicode: '1F4FC',
    sheet: [29, 22],
    native: '📼',
  },
  {
    unicode: '1F50D',
    sheet: [29, 38],
    native: '🔍',
  },
  {
    unicode: '1F50E',
    sheet: [29, 39],
    native: '🔎',
  },
  {
    unicode: '1F56F-FE0F',
    sheet: [30, 56],
    native: '🕯️',
  },
  {
    unicode: '1F4A1',
    sheet: [27, 48],
    native: '💡',
  },
  {
    unicode: '1F526',
    sheet: [30, 2],
    native: '🔦',
  },
  {
    unicode: '1F3EE',
    sheet: [10, 25],
    native: '🏮',
  },
  {
    unicode: '1FA94',
    sheet: [54, 6],
    native: '🪔',
  },
  {
    unicode: '1F4D4',
    sheet: [28, 43],
    native: '📔',
  },
  {
    unicode: '1F4D5',
    sheet: [28, 44],
    native: '📕',
  },
  {
    unicode: '1F4D6',
    sheet: [28, 45],
    native: '📖',
  },
  {
    unicode: '1F4D7',
    sheet: [28, 46],
    native: '📗',
  },
  {
    unicode: '1F4D8',
    sheet: [28, 47],
    native: '📘',
  },
  {
    unicode: '1F4D9',
    sheet: [28, 48],
    native: '📙',
  },
  {
    unicode: '1F4DA',
    sheet: [28, 49],
    native: '📚',
  },
  {
    unicode: '1F4D3',
    sheet: [28, 42],
    native: '📓',
  },
  {
    unicode: '1F4D2',
    sheet: [28, 41],
    native: '📒',
  },
  {
    unicode: '1F4C3',
    sheet: [28, 26],
    native: '📃',
  },
  {
    unicode: '1F4DC',
    sheet: [28, 51],
    native: '📜',
  },
  {
    unicode: '1F4C4',
    sheet: [28, 27],
    native: '📄',
  },
  {
    unicode: '1F4F0',
    sheet: [29, 10],
    native: '📰',
  },
  {
    unicode: '1F5DE-FE0F',
    sheet: [32, 8],
    native: '🗞️',
  },
  {
    unicode: '1F4D1',
    sheet: [28, 40],
    native: '📑',
  },
  {
    unicode: '1F516',
    sheet: [29, 47],
    native: '🔖',
  },
  {
    unicode: '1F3F7-FE0F',
    sheet: [10, 37],
    native: '🏷️',
  },
  {
    unicode: '1F4B0',
    sheet: [28, 7],
    native: '💰',
  },
  {
    unicode: '1FA99',
    sheet: [54, 11],
    native: '🪙',
  },
  {
    unicode: '1F4B4',
    sheet: [28, 11],
    native: '💴',
  },
  {
    unicode: '1F4B5',
    sheet: [28, 12],
    native: '💵',
  },
  {
    unicode: '1F4B6',
    sheet: [28, 13],
    native: '💶',
  },
  {
    unicode: '1F4B7',
    sheet: [28, 14],
    native: '💷',
  },
  {
    unicode: '1F4B8',
    sheet: [28, 15],
    native: '💸',
  },
  {
    unicode: '1F4B3',
    sheet: [28, 10],
    native: '💳',
  },
  {
    unicode: '1F9FE',
    sheet: [53, 44],
    native: '🧾',
  },
  {
    unicode: '1F4B9',
    sheet: [28, 16],
    native: '💹',
  },
  {
    unicode: '2709-FE0F',
    sheet: [58, 26],
    native: '✉️',
  },
  {
    unicode: '1F4E7',
    sheet: [29, 1],
    native: '📧',
  },
  {
    unicode: '1F4E8',
    sheet: [29, 2],
    native: '📨',
  },
  {
    unicode: '1F4E9',
    sheet: [29, 3],
    native: '📩',
  },
  {
    unicode: '1F4E4',
    sheet: [28, 59],
    native: '📤',
  },
  {
    unicode: '1F4E5',
    sheet: [28, 60],
    native: '📥',
  },
  {
    unicode: '1F4E6',
    sheet: [29, 0],
    native: '📦',
  },
  {
    unicode: '1F4EB',
    sheet: [29, 5],
    native: '📫',
  },
  {
    unicode: '1F4EA',
    sheet: [29, 4],
    native: '📪',
  },
  {
    unicode: '1F4EC',
    sheet: [29, 6],
    native: '📬',
  },
  {
    unicode: '1F4ED',
    sheet: [29, 7],
    native: '📭',
  },
  {
    unicode: '1F4EE',
    sheet: [29, 8],
    native: '📮',
  },
  {
    unicode: '1F5F3-FE0F',
    sheet: [32, 13],
    native: '🗳️',
  },
  {
    unicode: '270F-FE0F',
    sheet: [58, 51],
    native: '✏️',
  },
  {
    unicode: '2712-FE0F',
    sheet: [58, 52],
    native: '✒️',
  },
  {
    unicode: '1F58B-FE0F',
    sheet: [31, 34],
    native: '🖋️',
  },
  {
    unicode: '1F58A-FE0F',
    sheet: [31, 33],
    native: '🖊️',
  },
  {
    unicode: '1F58C-FE0F',
    sheet: [31, 35],
    native: '🖌️',
  },
  {
    unicode: '1F58D-FE0F',
    sheet: [31, 36],
    native: '🖍️',
  },
  {
    unicode: '1F4DD',
    sheet: [28, 52],
    native: '📝',
  },
  {
    unicode: '1F4BC',
    sheet: [28, 19],
    native: '💼',
  },
  {
    unicode: '1F4C1',
    sheet: [28, 24],
    native: '📁',
  },
  {
    unicode: '1F4C2',
    sheet: [28, 25],
    native: '📂',
  },
  {
    unicode: '1F5C2-FE0F',
    sheet: [32, 0],
    native: '🗂️',
  },
  {
    unicode: '1F4C5',
    sheet: [28, 28],
    native: '📅',
  },
  {
    unicode: '1F4C6',
    sheet: [28, 29],
    native: '📆',
  },
  {
    unicode: '1F5D2-FE0F',
    sheet: [32, 4],
    native: '🗒️',
  },
  {
    unicode: '1F5D3-FE0F',
    sheet: [32, 5],
    native: '🗓️',
  },
  {
    unicode: '1F4C7',
    sheet: [28, 30],
    native: '📇',
  },
  {
    unicode: '1F4C8',
    sheet: [28, 31],
    native: '📈',
  },
  {
    unicode: '1F4C9',
    sheet: [28, 32],
    native: '📉',
  },
  {
    unicode: '1F4CA',
    sheet: [28, 33],
    native: '📊',
  },
  {
    unicode: '1F4CB',
    sheet: [28, 34],
    native: '📋',
  },
  {
    unicode: '1F4CC',
    sheet: [28, 35],
    native: '📌',
  },
  {
    unicode: '1F4CD',
    sheet: [28, 36],
    native: '📍',
  },
  {
    unicode: '1F4CE',
    sheet: [28, 37],
    native: '📎',
  },
  {
    unicode: '1F587-FE0F',
    sheet: [31, 32],
    native: '🖇️',
  },
  {
    unicode: '1F4CF',
    sheet: [28, 38],
    native: '📏',
  },
  {
    unicode: '1F4D0',
    sheet: [28, 39],
    native: '📐',
  },
  {
    unicode: '2702-FE0F',
    sheet: [58, 23],
    native: '✂️',
  },
  {
    unicode: '1F5C3-FE0F',
    sheet: [32, 1],
    native: '🗃️',
  },
  {
    unicode: '1F5C4-FE0F',
    sheet: [32, 2],
    native: '🗄️',
  },
  {
    unicode: '1F5D1-FE0F',
    sheet: [32, 3],
    native: '🗑️',
  },
  {
    unicode: '1F512',
    sheet: [29, 43],
    native: '🔒',
  },
  {
    unicode: '1F513',
    sheet: [29, 44],
    native: '🔓',
  },
  {
    unicode: '1F50F',
    sheet: [29, 40],
    native: '🔏',
  },
  {
    unicode: '1F510',
    sheet: [29, 41],
    native: '🔐',
  },
  {
    unicode: '1F511',
    sheet: [29, 42],
    native: '🔑',
  },
  {
    unicode: '1F5DD-FE0F',
    sheet: [32, 7],
    native: '🗝️',
  },
  {
    unicode: '1F528',
    sheet: [30, 4],
    native: '🔨',
  },
  {
    unicode: '1FA93',
    sheet: [54, 5],
    native: '🪓',
  },
  {
    unicode: '26CF-FE0F',
    sheet: [57, 50],
    native: '⛏️',
  },
  {
    unicode: '2692-FE0F',
    sheet: [57, 28],
    native: '⚒️',
  },
  {
    unicode: '1F6E0-FE0F',
    sheet: [38, 11],
    native: '🛠️',
  },
  {
    unicode: '1F5E1-FE0F',
    sheet: [32, 9],
    native: '🗡️',
  },
  {
    unicode: '2694-FE0F',
    sheet: [57, 30],
    native: '⚔️',
  },
  {
    unicode: '1F52B',
    sheet: [30, 7],
    native: '🔫',
  },
  {
    unicode: '1FA83',
    sheet: [53, 59],
    native: '🪃',
  },
  {
    unicode: '1F3F9',
    sheet: [10, 39],
    native: '🏹',
  },
  {
    unicode: '1F6E1-FE0F',
    sheet: [38, 12],
    native: '🛡️',
  },
  {
    unicode: '1FA9A',
    sheet: [54, 12],
    native: '🪚',
  },
  {
    unicode: '1F527',
    sheet: [30, 3],
    native: '🔧',
  },
  {
    unicode: '1FA9B',
    sheet: [54, 13],
    native: '🪛',
  },
  {
    unicode: '1F529',
    sheet: [30, 5],
    native: '🔩',
  },
  {
    unicode: '2699-FE0F',
    sheet: [57, 34],
    native: '⚙️',
  },
  {
    unicode: '1F5DC-FE0F',
    sheet: [32, 6],
    native: '🗜️',
  },
  {
    unicode: '2696-FE0F',
    sheet: [57, 32],
    native: '⚖️',
  },
  {
    unicode: '1F9AF',
    sheet: [45, 4],
    native: '🦯',
  },
  {
    unicode: '1F517',
    sheet: [29, 48],
    native: '🔗',
  },
  {
    unicode: '26D3-FE0F',
    sheet: [57, 52],
    native: '⛓️',
  },
  {
    unicode: '1FA9D',
    sheet: [54, 15],
    native: '🪝',
  },
  {
    unicode: '1F9F0',
    sheet: [53, 30],
    native: '🧰',
  },
  {
    unicode: '1F9F2',
    sheet: [53, 32],
    native: '🧲',
  },
  {
    unicode: '1FA9C',
    sheet: [54, 14],
    native: '🪜',
  },
  {
    unicode: '2697-FE0F',
    sheet: [57, 33],
    native: '⚗️',
  },
  {
    unicode: '1F9EA',
    sheet: [53, 24],
    native: '🧪',
  },
  {
    unicode: '1F9EB',
    sheet: [53, 25],
    native: '🧫',
  },
  {
    unicode: '1F9EC',
    sheet: [53, 26],
    native: '🧬',
  },
  {
    unicode: '1F52C',
    sheet: [30, 8],
    native: '🔬',
  },
  {
    unicode: '1F52D',
    sheet: [30, 9],
    native: '🔭',
  },
  {
    unicode: '1F4E1',
    sheet: [28, 56],
    native: '📡',
  },
  {
    unicode: '1F489',
    sheet: [26, 35],
    native: '💉',
  },
  {
    unicode: '1FA78',
    sheet: [53, 51],
    native: '🩸',
  },
  {
    unicode: '1F48A',
    sheet: [26, 36],
    native: '💊',
  },
  {
    unicode: '1FA79',
    sheet: [53, 52],
    native: '🩹',
  },
  {
    unicode: '1FA7C',
    sheet: [53, 55],
    native: '🩼',
  },
  {
    unicode: '1FA7A',
    sheet: [53, 53],
    native: '🩺',
  },
  {
    unicode: '1FA7B',
    sheet: [53, 54],
    native: '🩻',
  },
  {
    unicode: '1F6AA',
    sheet: [36, 30],
    native: '🚪',
  },
  {
    unicode: '1F6D7',
    sheet: [38, 7],
    native: '🛗',
  },
  {
    unicode: '1FA9E',
    sheet: [54, 16],
    native: '🪞',
  },
  {
    unicode: '1FA9F',
    sheet: [54, 17],
    native: '🪟',
  },
  {
    unicode: '1F6CF-FE0F',
    sheet: [38, 1],
    native: '🛏️',
  },
  {
    unicode: '1F6CB-FE0F',
    sheet: [37, 53],
    native: '🛋️',
  },
  {
    unicode: '1FA91',
    sheet: [54, 3],
    native: '🪑',
  },
  {
    unicode: '1F6BD',
    sheet: [37, 39],
    native: '🚽',
  },
  {
    unicode: '1FAA0',
    sheet: [54, 18],
    native: '🪠',
  },
  {
    unicode: '1F6BF',
    sheet: [37, 41],
    native: '🚿',
  },
  {
    unicode: '1F6C1',
    sheet: [37, 48],
    native: '🛁',
  },
  {
    unicode: '1FAA4',
    sheet: [54, 22],
    native: '🪤',
  },
  {
    unicode: '1FA92',
    sheet: [54, 4],
    native: '🪒',
  },
  {
    unicode: '1F9F4',
    sheet: [53, 34],
    native: '🧴',
  },
  {
    unicode: '1F9F7',
    sheet: [53, 37],
    native: '🧷',
  },
  {
    unicode: '1F9F9',
    sheet: [53, 39],
    native: '🧹',
  },
  {
    unicode: '1F9FA',
    sheet: [53, 40],
    native: '🧺',
  },
  {
    unicode: '1F9FB',
    sheet: [53, 41],
    native: '🧻',
  },
  {
    unicode: '1FAA3',
    sheet: [54, 21],
    native: '🪣',
  },
  {
    unicode: '1F9FC',
    sheet: [53, 42],
    native: '🧼',
  },
  {
    unicode: '1FAE7',
    sheet: [55, 19],
    native: '🫧',
  },
  {
    unicode: '1FAA5',
    sheet: [54, 23],
    native: '🪥',
  },
  {
    unicode: '1F9FD',
    sheet: [53, 43],
    native: '🧽',
  },
  {
    unicode: '1F9EF',
    sheet: [53, 29],
    native: '🧯',
  },
  {
    unicode: '1F6D2',
    sheet: [38, 4],
    native: '🛒',
  },
  {
    unicode: '1F6AC',
    sheet: [36, 32],
    native: '🚬',
  },
  {
    unicode: '26B0-FE0F',
    sheet: [57, 42],
    native: '⚰️',
  },
  {
    unicode: '1FAA6',
    sheet: [54, 24],
    native: '🪦',
  },
  {
    unicode: '26B1-FE0F',
    sheet: [57, 43],
    native: '⚱️',
  },
  {
    unicode: '1F5FF',
    sheet: [32, 19],
    native: '🗿',
  },
  {
    unicode: '1FAA7',
    sheet: [54, 25],
    native: '🪧',
  },
  {
    unicode: '1FAAA',
    sheet: [54, 28],
    native: '🪪',
  },
  {
    unicode: '1F3E7',
    sheet: [10, 18],
    native: '🏧',
  },
  {
    unicode: '1F6AE',
    sheet: [36, 34],
    native: '🚮',
  },
  {
    unicode: '1F6B0',
    sheet: [36, 36],
    native: '🚰',
  },
  {
    unicode: '267F',
    sheet: [57, 27],
    native: '♿',
  },
  {
    unicode: '1F6B9',
    sheet: [37, 35],
    native: '🚹',
  },
  {
    unicode: '1F6BA',
    sheet: [37, 36],
    native: '🚺',
  },
  {
    unicode: '1F6BB',
    sheet: [37, 37],
    native: '🚻',
  },
  {
    unicode: '1F6BC',
    sheet: [37, 38],
    native: '🚼',
  },
  {
    unicode: '1F6BE',
    sheet: [37, 40],
    native: '🚾',
  },
  {
    unicode: '1F6C2',
    sheet: [37, 49],
    native: '🛂',
  },
  {
    unicode: '1F6C3',
    sheet: [37, 50],
    native: '🛃',
  },
  {
    unicode: '1F6C4',
    sheet: [37, 51],
    native: '🛄',
  },
  {
    unicode: '1F6C5',
    sheet: [37, 52],
    native: '🛅',
  },
  {
    unicode: '26A0-FE0F',
    sheet: [57, 37],
    native: '⚠️',
  },
  {
    unicode: '1F6B8',
    sheet: [37, 34],
    native: '🚸',
  },
  {
    unicode: '26D4',
    sheet: [57, 53],
    native: '⛔',
  },
  {
    unicode: '1F6AB',
    sheet: [36, 31],
    native: '🚫',
  },
  {
    unicode: '1F6B3',
    sheet: [36, 39],
    native: '🚳',
  },
  {
    unicode: '1F6AD',
    sheet: [36, 33],
    native: '🚭',
  },
  {
    unicode: '1F6AF',
    sheet: [36, 35],
    native: '🚯',
  },
  {
    unicode: '1F6B1',
    sheet: [36, 37],
    native: '🚱',
  },
  {
    unicode: '1F6B7',
    sheet: [37, 33],
    native: '🚷',
  },
  {
    unicode: '1F4F5',
    sheet: [29, 15],
    native: '📵',
  },
  {
    unicode: '1F51E',
    sheet: [29, 55],
    native: '🔞',
  },
  {
    unicode: '2622-FE0F',
    sheet: [56, 57],
    native: '☢️',
  },
  {
    unicode: '2623-FE0F',
    sheet: [56, 58],
    native: '☣️',
  },
  {
    unicode: '2B06-FE0F',
    sheet: [59, 20],
    native: '⬆️',
  },
  {
    unicode: '2197-FE0F',
    sheet: [56, 8],
    native: '↗️',
  },
  {
    unicode: '27A1-FE0F',
    sheet: [59, 14],
    native: '➡️',
  },
  {
    unicode: '2198-FE0F',
    sheet: [56, 9],
    native: '↘️',
  },
  {
    unicode: '2B07-FE0F',
    sheet: [59, 21],
    native: '⬇️',
  },
  {
    unicode: '2199-FE0F',
    sheet: [56, 10],
    native: '↙️',
  },
  {
    unicode: '2B05-FE0F',
    sheet: [59, 19],
    native: '⬅️',
  },
  {
    unicode: '2196-FE0F',
    sheet: [56, 7],
    native: '↖️',
  },
  {
    unicode: '2195-FE0F',
    sheet: [56, 6],
    native: '↕️',
  },
  {
    unicode: '2194-FE0F',
    sheet: [56, 5],
    native: '↔️',
  },
  {
    unicode: '21A9-FE0F',
    sheet: [56, 11],
    native: '↩️',
  },
  {
    unicode: '21AA-FE0F',
    sheet: [56, 12],
    native: '↪️',
  },
  {
    unicode: '2934-FE0F',
    sheet: [59, 17],
    native: '⤴️',
  },
  {
    unicode: '2935-FE0F',
    sheet: [59, 18],
    native: '⤵️',
  },
  {
    unicode: '1F503',
    sheet: [29, 28],
    native: '🔃',
  },
  {
    unicode: '1F504',
    sheet: [29, 29],
    native: '🔄',
  },
  {
    unicode: '1F519',
    sheet: [29, 50],
    native: '🔙',
  },
  {
    unicode: '1F51A',
    sheet: [29, 51],
    native: '🔚',
  },
  {
    unicode: '1F51B',
    sheet: [29, 52],
    native: '🔛',
  },
  {
    unicode: '1F51C',
    sheet: [29, 53],
    native: '🔜',
  },
  {
    unicode: '1F51D',
    sheet: [29, 54],
    native: '🔝',
  },
  {
    unicode: '1F6D0',
    sheet: [38, 2],
    native: '🛐',
  },
  {
    unicode: '269B-FE0F',
    sheet: [57, 35],
    native: '⚛️',
  },
  {
    unicode: '1F549-FE0F',
    sheet: [30, 26],
    native: '🕉️',
  },
  {
    unicode: '2721-FE0F',
    sheet: [58, 56],
    native: '✡️',
  },
  {
    unicode: '2638-FE0F',
    sheet: [57, 2],
    native: '☸️',
  },
  {
    unicode: '262F-FE0F',
    sheet: [57, 1],
    native: '☯️',
  },
  {
    unicode: '271D-FE0F',
    sheet: [58, 55],
    native: '✝️',
  },
  {
    unicode: '2626-FE0F',
    sheet: [56, 59],
    native: '☦️',
  },
  {
    unicode: '262A-FE0F',
    sheet: [56, 60],
    native: '☪️',
  },
  {
    unicode: '262E-FE0F',
    sheet: [57, 0],
    native: '☮️',
  },
  {
    unicode: '1F54E',
    sheet: [30, 31],
    native: '🕎',
  },
  {
    unicode: '1F52F',
    sheet: [30, 11],
    native: '🔯',
  },
  {
    unicode: '2648',
    sheet: [57, 7],
    native: '♈',
  },
  {
    unicode: '2649',
    sheet: [57, 8],
    native: '♉',
  },
  {
    unicode: '264A',
    sheet: [57, 9],
    native: '♊',
  },
  {
    unicode: '264B',
    sheet: [57, 10],
    native: '♋',
  },
  {
    unicode: '264C',
    sheet: [57, 11],
    native: '♌',
  },
  {
    unicode: '264D',
    sheet: [57, 12],
    native: '♍',
  },
  {
    unicode: '264E',
    sheet: [57, 13],
    native: '♎',
  },
  {
    unicode: '264F',
    sheet: [57, 14],
    native: '♏',
  },
  {
    unicode: '2650',
    sheet: [57, 15],
    native: '♐',
  },
  {
    unicode: '2651',
    sheet: [57, 16],
    native: '♑',
  },
  {
    unicode: '2652',
    sheet: [57, 17],
    native: '♒',
  },
  {
    unicode: '2653',
    sheet: [57, 18],
    native: '♓',
  },
  {
    unicode: '26CE',
    sheet: [57, 49],
    native: '⛎',
  },
  {
    unicode: '1F500',
    sheet: [29, 25],
    native: '🔀',
  },
  {
    unicode: '1F501',
    sheet: [29, 26],
    native: '🔁',
  },
  {
    unicode: '1F502',
    sheet: [29, 27],
    native: '🔂',
  },
  {
    unicode: '25B6-FE0F',
    sheet: [56, 34],
    native: '▶️',
  },
  {
    unicode: '23E9',
    sheet: [56, 17],
    native: '⏩',
  },
  {
    unicode: '23ED-FE0F',
    sheet: [56, 21],
    native: '⏭️',
  },
  {
    unicode: '23EF-FE0F',
    sheet: [56, 23],
    native: '⏯️',
  },
  {
    unicode: '25C0-FE0F',
    sheet: [56, 35],
    native: '◀️',
  },
  {
    unicode: '23EA',
    sheet: [56, 18],
    native: '⏪',
  },
  {
    unicode: '23EE-FE0F',
    sheet: [56, 22],
    native: '⏮️',
  },
  {
    unicode: '1F53C',
    sheet: [30, 24],
    native: '🔼',
  },
  {
    unicode: '23EB',
    sheet: [56, 19],
    native: '⏫',
  },
  {
    unicode: '1F53D',
    sheet: [30, 25],
    native: '🔽',
  },
  {
    unicode: '23EC',
    sheet: [56, 20],
    native: '⏬',
  },
  {
    unicode: '23F8-FE0F',
    sheet: [56, 28],
    native: '⏸️',
  },
  {
    unicode: '23F9-FE0F',
    sheet: [56, 29],
    native: '⏹️',
  },
  {
    unicode: '23FA-FE0F',
    sheet: [56, 30],
    native: '⏺️',
  },
  {
    unicode: '23CF-FE0F',
    sheet: [56, 16],
    native: '⏏️',
  },
  {
    unicode: '1F3A6',
    sheet: [7, 41],
    native: '🎦',
  },
  {
    unicode: '1F505',
    sheet: [29, 30],
    native: '🔅',
  },
  {
    unicode: '1F506',
    sheet: [29, 31],
    native: '🔆',
  },
  {
    unicode: '1F4F6',
    sheet: [29, 16],
    native: '📶',
  },
  {
    unicode: '1F4F3',
    sheet: [29, 13],
    native: '📳',
  },
  {
    unicode: '1F4F4',
    sheet: [29, 14],
    native: '📴',
  },
  {
    unicode: '2640-FE0F',
    sheet: [57, 5],
    native: '♀️',
  },
  {
    unicode: '2642-FE0F',
    sheet: [57, 6],
    native: '♂️',
  },
  {
    unicode: '26A7-FE0F',
    sheet: [57, 39],
    native: '⚧️',
  },
  {
    unicode: '2716-FE0F',
    sheet: [58, 54],
    native: '✖️',
  },
  {
    unicode: '2795',
    sheet: [59, 11],
    native: '➕',
  },
  {
    unicode: '2796',
    sheet: [59, 12],
    native: '➖',
  },
  {
    unicode: '2797',
    sheet: [59, 13],
    native: '➗',
  },
  {
    unicode: '1F7F0',
    sheet: [38, 43],
    native: '🟰',
  },
  {
    unicode: '267E-FE0F',
    sheet: [57, 26],
    native: '♾️',
  },
  {
    unicode: '203C-FE0F',
    sheet: [56, 1],
    native: '‼️',
  },
  {
    unicode: '2049-FE0F',
    sheet: [56, 2],
    native: '⁉️',
  },
  {
    unicode: '2753',
    sheet: [59, 3],
    native: '❓',
  },
  {
    unicode: '2754',
    sheet: [59, 4],
    native: '❔',
  },
  {
    unicode: '2755',
    sheet: [59, 5],
    native: '❕',
  },
  {
    unicode: '2757',
    sheet: [59, 6],
    native: '❗',
  },
  {
    unicode: '3030-FE0F',
    sheet: [59, 26],
    native: '〰️',
  },
  {
    unicode: '1F4B1',
    sheet: [28, 8],
    native: '💱',
  },
  {
    unicode: '1F4B2',
    sheet: [28, 9],
    native: '💲',
  },
  {
    unicode: '2695-FE0F',
    sheet: [57, 31],
    native: '⚕️',
  },
  {
    unicode: '267B-FE0F',
    sheet: [57, 25],
    native: '♻️',
  },
  {
    unicode: '269C-FE0F',
    sheet: [57, 36],
    native: '⚜️',
  },
  {
    unicode: '1F531',
    sheet: [30, 13],
    native: '🔱',
  },
  {
    unicode: '1F4DB',
    sheet: [28, 50],
    native: '📛',
  },
  {
    unicode: '1F530',
    sheet: [30, 12],
    native: '🔰',
  },
  {
    unicode: '2B55',
    sheet: [59, 25],
    native: '⭕',
  },
  {
    unicode: '2705',
    sheet: [58, 24],
    native: '✅',
  },
  {
    unicode: '2611-FE0F',
    sheet: [56, 46],
    native: '☑️',
  },
  {
    unicode: '2714-FE0F',
    sheet: [58, 53],
    native: '✔️',
  },
  {
    unicode: '274C',
    sheet: [59, 1],
    native: '❌',
  },
  {
    unicode: '274E',
    sheet: [59, 2],
    native: '❎',
  },
  {
    unicode: '27B0',
    sheet: [59, 15],
    native: '➰',
  },
  {
    unicode: '27BF',
    sheet: [59, 16],
    native: '➿',
  },
  {
    unicode: '303D-FE0F',
    sheet: [59, 27],
    native: '〽️',
  },
  {
    unicode: '2733-FE0F',
    sheet: [58, 58],
    native: '✳️',
  },
  {
    unicode: '2734-FE0F',
    sheet: [58, 59],
    native: '✴️',
  },
  {
    unicode: '2747-FE0F',
    sheet: [59, 0],
    native: '❇️',
  },
  {
    unicode: '00A9-FE0F',
    sheet: [0, 12],
    native: '©️',
  },
  {
    unicode: '00AE-FE0F',
    sheet: [0, 13],
    native: '®️',
  },
  {
    unicode: '2122-FE0F',
    sheet: [56, 3],
    native: '™️',
  },
  {
    unicode: '0023-FE0F-20E3',
    sheet: [0, 0],
    native: '#️⃣',
  },
  {
    unicode: '002A-FE0F-20E3',
    sheet: [0, 1],
    native: '*️⃣',
  },
  {
    unicode: '0030-FE0F-20E3',
    sheet: [0, 2],
    native: '0️⃣',
  },
  {
    unicode: '0031-FE0F-20E3',
    sheet: [0, 3],
    native: '1️⃣',
  },
  {
    unicode: '0032-FE0F-20E3',
    sheet: [0, 4],
    native: '2️⃣',
  },
  {
    unicode: '0033-FE0F-20E3',
    sheet: [0, 5],
    native: '3️⃣',
  },
  {
    unicode: '0034-FE0F-20E3',
    sheet: [0, 6],
    native: '4️⃣',
  },
  {
    unicode: '0035-FE0F-20E3',
    sheet: [0, 7],
    native: '5️⃣',
  },
  {
    unicode: '0036-FE0F-20E3',
    sheet: [0, 8],
    native: '6️⃣',
  },
  {
    unicode: '0037-FE0F-20E3',
    sheet: [0, 9],
    native: '7️⃣',
  },
  {
    unicode: '0038-FE0F-20E3',
    sheet: [0, 10],
    native: '8️⃣',
  },
  {
    unicode: '0039-FE0F-20E3',
    sheet: [0, 11],
    native: '9️⃣',
  },
  {
    unicode: '1F51F',
    sheet: [29, 56],
    native: '🔟',
  },
  {
    unicode: '1F520',
    sheet: [29, 57],
    native: '🔠',
  },
  {
    unicode: '1F521',
    sheet: [29, 58],
    native: '🔡',
  },
  {
    unicode: '1F522',
    sheet: [29, 59],
    native: '🔢',
  },
  {
    unicode: '1F523',
    sheet: [29, 60],
    native: '🔣',
  },
  {
    unicode: '1F524',
    sheet: [30, 0],
    native: '🔤',
  },
  {
    unicode: '1F170-FE0F',
    sheet: [0, 16],
    native: '🅰️',
  },
  {
    unicode: '1F18E',
    sheet: [0, 20],
    native: '🆎',
  },
  {
    unicode: '1F171-FE0F',
    sheet: [0, 17],
    native: '🅱️',
  },
  {
    unicode: '1F191',
    sheet: [0, 21],
    native: '🆑',
  },
  {
    unicode: '1F192',
    sheet: [0, 22],
    native: '🆒',
  },
  {
    unicode: '1F193',
    sheet: [0, 23],
    native: '🆓',
  },
  {
    unicode: '2139-FE0F',
    sheet: [56, 4],
    native: 'ℹ️',
  },
  {
    unicode: '1F194',
    sheet: [0, 24],
    native: '🆔',
  },
  {
    unicode: '24C2-FE0F',
    sheet: [56, 31],
    native: 'Ⓜ️',
  },
  {
    unicode: '1F195',
    sheet: [0, 25],
    native: '🆕',
  },
  {
    unicode: '1F196',
    sheet: [0, 26],
    native: '🆖',
  },
  {
    unicode: '1F17E-FE0F',
    sheet: [0, 18],
    native: '🅾️',
  },
  {
    unicode: '1F197',
    sheet: [0, 27],
    native: '🆗',
  },
  {
    unicode: '1F17F-FE0F',
    sheet: [0, 19],
    native: '🅿️',
  },
  {
    unicode: '1F198',
    sheet: [0, 28],
    native: '🆘',
  },
  {
    unicode: '1F199',
    sheet: [0, 29],
    native: '🆙',
  },
  {
    unicode: '1F19A',
    sheet: [0, 30],
    native: '🆚',
  },
  {
    unicode: '1F201',
    sheet: [4, 45],
    native: '🈁',
  },
  {
    unicode: '1F202-FE0F',
    sheet: [4, 46],
    native: '🈂️',
  },
  {
    unicode: '1F237-FE0F',
    sheet: [4, 54],
    native: '🈷️',
  },
  {
    unicode: '1F236',
    sheet: [4, 53],
    native: '🈶',
  },
  {
    unicode: '1F22F',
    sheet: [4, 48],
    native: '🈯',
  },
  {
    unicode: '1F250',
    sheet: [4, 58],
    native: '🉐',
  },
  {
    unicode: '1F239',
    sheet: [4, 56],
    native: '🈹',
  },
  {
    unicode: '1F21A',
    sheet: [4, 47],
    native: '🈚',
  },
  {
    unicode: '1F232',
    sheet: [4, 49],
    native: '🈲',
  },
  {
    unicode: '1F251',
    sheet: [4, 59],
    native: '🉑',
  },
  {
    unicode: '1F238',
    sheet: [4, 55],
    native: '🈸',
  },
  {
    unicode: '1F234',
    sheet: [4, 51],
    native: '🈴',
  },
  {
    unicode: '1F233',
    sheet: [4, 50],
    native: '🈳',
  },
  {
    unicode: '3297-FE0F',
    sheet: [59, 28],
    native: '㊗️',
  },
  {
    unicode: '3299-FE0F',
    sheet: [59, 29],
    native: '㊙️',
  },
  {
    unicode: '1F23A',
    sheet: [4, 57],
    native: '🈺',
  },
  {
    unicode: '1F235',
    sheet: [4, 52],
    native: '🈵',
  },
  {
    unicode: '1F534',
    sheet: [30, 16],
    native: '🔴',
  },
  {
    unicode: '1F7E0',
    sheet: [38, 31],
    native: '🟠',
  },
  {
    unicode: '1F7E1',
    sheet: [38, 32],
    native: '🟡',
  },
  {
    unicode: '1F7E2',
    sheet: [38, 33],
    native: '🟢',
  },
  {
    unicode: '1F535',
    sheet: [30, 17],
    native: '🔵',
  },
  {
    unicode: '1F7E3',
    sheet: [38, 34],
    native: '🟣',
  },
  {
    unicode: '1F7E4',
    sheet: [38, 35],
    native: '🟤',
  },
  {
    unicode: '26AB',
    sheet: [57, 41],
    native: '⚫',
  },
  {
    unicode: '26AA',
    sheet: [57, 40],
    native: '⚪',
  },
  {
    unicode: '1F7E5',
    sheet: [38, 36],
    native: '🟥',
  },
  {
    unicode: '1F7E7',
    sheet: [38, 38],
    native: '🟧',
  },
  {
    unicode: '1F7E8',
    sheet: [38, 39],
    native: '🟨',
  },
  {
    unicode: '1F7E9',
    sheet: [38, 40],
    native: '🟩',
  },
  {
    unicode: '1F7E6',
    sheet: [38, 37],
    native: '🟦',
  },
  {
    unicode: '1F7EA',
    sheet: [38, 41],
    native: '🟪',
  },
  {
    unicode: '1F7EB',
    sheet: [38, 42],
    native: '🟫',
  },
  {
    unicode: '2B1B',
    sheet: [59, 22],
    native: '⬛',
  },
  {
    unicode: '2B1C',
    sheet: [59, 23],
    native: '⬜',
  },
  {
    unicode: '25FC-FE0F',
    sheet: [56, 37],
    native: '◼️',
  },
  {
    unicode: '25FB-FE0F',
    sheet: [56, 36],
    native: '◻️',
  },
  {
    unicode: '25FE',
    sheet: [56, 39],
    native: '◾',
  },
  {
    unicode: '25FD',
    sheet: [56, 38],
    native: '◽',
  },
  {
    unicode: '25AA-FE0F',
    sheet: [56, 32],
    native: '▪️',
  },
  {
    unicode: '25AB-FE0F',
    sheet: [56, 33],
    native: '▫️',
  },
  {
    unicode: '1F536',
    sheet: [30, 18],
    native: '🔶',
  },
  {
    unicode: '1F537',
    sheet: [30, 19],
    native: '🔷',
  },
  {
    unicode: '1F538',
    sheet: [30, 20],
    native: '🔸',
  },
  {
    unicode: '1F539',
    sheet: [30, 21],
    native: '🔹',
  },
  {
    unicode: '1F53A',
    sheet: [30, 22],
    native: '🔺',
  },
  {
    unicode: '1F53B',
    sheet: [30, 23],
    native: '🔻',
  },
  {
    unicode: '1F4A0',
    sheet: [27, 47],
    native: '💠',
  },
  {
    unicode: '1F518',
    sheet: [29, 49],
    native: '🔘',
  },
  {
    unicode: '1F533',
    sheet: [30, 15],
    native: '🔳',
  },
  {
    unicode: '1F532',
    sheet: [30, 14],
    native: '🔲',
  },
  {
    unicode: '1F3C1',
    sheet: [8, 7],
    native: '🏁',
  },
  {
    unicode: '1F6A9',
    sheet: [36, 29],
    native: '🚩',
  },
  {
    unicode: '1F38C',
    sheet: [7, 20],
    native: '🎌',
  },
  {
    unicode: '1F3F4',
    sheet: [10, 35],
    native: '🏴',
  },
  {
    unicode: '1F3F3-FE0F',
    sheet: [10, 30],
    native: '🏳️',
  },
  {
    unicode: '1F3F3-FE0F-200D-1F308',
    sheet: [10, 28],
    native: '🏳️‍🌈',
  },
  {
    unicode: '1F3F3-FE0F-200D-26A7-FE0F',
    sheet: [10, 29],
    native: '🏳️‍⚧️',
  },
  {
    unicode: '1F3F4-200D-2620-FE0F',
    sheet: [10, 31],
    native: '🏴‍☠️',
  },
  {
    unicode: '1F1E6-1F1E8',
    sheet: [0, 31],
    native: '🇦🇨',
  },
  {
    unicode: '1F1E6-1F1E9',
    sheet: [0, 32],
    native: '🇦🇩',
  },
  {
    unicode: '1F1E6-1F1EA',
    sheet: [0, 33],
    native: '🇦🇪',
  },
  {
    unicode: '1F1E6-1F1EB',
    sheet: [0, 34],
    native: '🇦🇫',
  },
  {
    unicode: '1F1E6-1F1EC',
    sheet: [0, 35],
    native: '🇦🇬',
  },
  {
    unicode: '1F1E6-1F1EE',
    sheet: [0, 36],
    native: '🇦🇮',
  },
  {
    unicode: '1F1E6-1F1F1',
    sheet: [0, 37],
    native: '🇦🇱',
  },
  {
    unicode: '1F1E6-1F1F2',
    sheet: [0, 38],
    native: '🇦🇲',
  },
  {
    unicode: '1F1E6-1F1F4',
    sheet: [0, 39],
    native: '🇦🇴',
  },
  {
    unicode: '1F1E6-1F1F6',
    sheet: [0, 40],
    native: '🇦🇶',
  },
  {
    unicode: '1F1E6-1F1F7',
    sheet: [0, 41],
    native: '🇦🇷',
  },
  {
    unicode: '1F1E6-1F1F8',
    sheet: [0, 42],
    native: '🇦🇸',
  },
  {
    unicode: '1F1E6-1F1F9',
    sheet: [0, 43],
    native: '🇦🇹',
  },
  {
    unicode: '1F1E6-1F1FA',
    sheet: [0, 44],
    native: '🇦🇺',
  },
  {
    unicode: '1F1E6-1F1FC',
    sheet: [0, 45],
    native: '🇦🇼',
  },
  {
    unicode: '1F1E6-1F1FD',
    sheet: [0, 46],
    native: '🇦🇽',
  },
  {
    unicode: '1F1E6-1F1FF',
    sheet: [0, 47],
    native: '🇦🇿',
  },
  {
    unicode: '1F1E7-1F1E6',
    sheet: [0, 48],
    native: '🇧🇦',
  },
  {
    unicode: '1F1E7-1F1E7',
    sheet: [0, 49],
    native: '🇧🇧',
  },
  {
    unicode: '1F1E7-1F1E9',
    sheet: [0, 50],
    native: '🇧🇩',
  },
  {
    unicode: '1F1E7-1F1EA',
    sheet: [0, 51],
    native: '🇧🇪',
  },
  {
    unicode: '1F1E7-1F1EB',
    sheet: [0, 52],
    native: '🇧🇫',
  },
  {
    unicode: '1F1E7-1F1EC',
    sheet: [0, 53],
    native: '🇧🇬',
  },
  {
    unicode: '1F1E7-1F1ED',
    sheet: [0, 54],
    native: '🇧🇭',
  },
  {
    unicode: '1F1E7-1F1EE',
    sheet: [0, 55],
    native: '🇧🇮',
  },
  {
    unicode: '1F1E7-1F1EF',
    sheet: [0, 56],
    native: '🇧🇯',
  },
  {
    unicode: '1F1E7-1F1F1',
    sheet: [0, 57],
    native: '🇧🇱',
  },
  {
    unicode: '1F1E7-1F1F2',
    sheet: [0, 58],
    native: '🇧🇲',
  },
  {
    unicode: '1F1E7-1F1F3',
    sheet: [0, 59],
    native: '🇧🇳',
  },
  {
    unicode: '1F1E7-1F1F4',
    sheet: [0, 60],
    native: '🇧🇴',
  },
  {
    unicode: '1F1E7-1F1F6',
    sheet: [1, 0],
    native: '🇧🇶',
  },
  {
    unicode: '1F1E7-1F1F7',
    sheet: [1, 1],
    native: '🇧🇷',
  },
  {
    unicode: '1F1E7-1F1F8',
    sheet: [1, 2],
    native: '🇧🇸',
  },
  {
    unicode: '1F1E7-1F1F9',
    sheet: [1, 3],
    native: '🇧🇹',
  },
  {
    unicode: '1F1E7-1F1FB',
    sheet: [1, 4],
    native: '🇧🇻',
  },
  {
    unicode: '1F1E7-1F1FC',
    sheet: [1, 5],
    native: '🇧🇼',
  },
  {
    unicode: '1F1E7-1F1FE',
    sheet: [1, 6],
    native: '🇧🇾',
  },
  {
    unicode: '1F1E7-1F1FF',
    sheet: [1, 7],
    native: '🇧🇿',
  },
  {
    unicode: '1F1E8-1F1E6',
    sheet: [1, 8],
    native: '🇨🇦',
  },
  {
    unicode: '1F1E8-1F1E8',
    sheet: [1, 9],
    native: '🇨🇨',
  },
  {
    unicode: '1F1E8-1F1E9',
    sheet: [1, 10],
    native: '🇨🇩',
  },
  {
    unicode: '1F1E8-1F1EB',
    sheet: [1, 11],
    native: '🇨🇫',
  },
  {
    unicode: '1F1E8-1F1EC',
    sheet: [1, 12],
    native: '🇨🇬',
  },
  {
    unicode: '1F1E8-1F1ED',
    sheet: [1, 13],
    native: '🇨🇭',
  },
  {
    unicode: '1F1E8-1F1EE',
    sheet: [1, 14],
    native: '🇨🇮',
  },
  {
    unicode: '1F1E8-1F1F0',
    sheet: [1, 15],
    native: '🇨🇰',
  },
  {
    unicode: '1F1E8-1F1F1',
    sheet: [1, 16],
    native: '🇨🇱',
  },
  {
    unicode: '1F1E8-1F1F2',
    sheet: [1, 17],
    native: '🇨🇲',
  },
  {
    unicode: '1F1E8-1F1F3',
    sheet: [1, 18],
    native: '🇨🇳',
  },
  {
    unicode: '1F1E8-1F1F4',
    sheet: [1, 19],
    native: '🇨🇴',
  },
  {
    unicode: '1F1E8-1F1F5',
    sheet: [1, 20],
    native: '🇨🇵',
  },
  {
    unicode: '1F1E8-1F1F7',
    sheet: [1, 21],
    native: '🇨🇷',
  },
  {
    unicode: '1F1E8-1F1FA',
    sheet: [1, 22],
    native: '🇨🇺',
  },
  {
    unicode: '1F1E8-1F1FB',
    sheet: [1, 23],
    native: '🇨🇻',
  },
  {
    unicode: '1F1E8-1F1FC',
    sheet: [1, 24],
    native: '🇨🇼',
  },
  {
    unicode: '1F1E8-1F1FD',
    sheet: [1, 25],
    native: '🇨🇽',
  },
  {
    unicode: '1F1E8-1F1FE',
    sheet: [1, 26],
    native: '🇨🇾',
  },
  {
    unicode: '1F1E8-1F1FF',
    sheet: [1, 27],
    native: '🇨🇿',
  },
  {
    unicode: '1F1E9-1F1EA',
    sheet: [1, 28],
    native: '🇩🇪',
  },
  {
    unicode: '1F1E9-1F1EC',
    sheet: [1, 29],
    native: '🇩🇬',
  },
  {
    unicode: '1F1E9-1F1EF',
    sheet: [1, 30],
    native: '🇩🇯',
  },
  {
    unicode: '1F1E9-1F1F0',
    sheet: [1, 31],
    native: '🇩🇰',
  },
  {
    unicode: '1F1E9-1F1F2',
    sheet: [1, 32],
    native: '🇩🇲',
  },
  {
    unicode: '1F1E9-1F1F4',
    sheet: [1, 33],
    native: '🇩🇴',
  },
  {
    unicode: '1F1E9-1F1FF',
    sheet: [1, 34],
    native: '🇩🇿',
  },
  {
    unicode: '1F1EA-1F1E6',
    sheet: [1, 35],
    native: '🇪🇦',
  },
  {
    unicode: '1F1EA-1F1E8',
    sheet: [1, 36],
    native: '🇪🇨',
  },
  {
    unicode: '1F1EA-1F1EA',
    sheet: [1, 37],
    native: '🇪🇪',
  },
  {
    unicode: '1F1EA-1F1EC',
    sheet: [1, 38],
    native: '🇪🇬',
  },
  {
    unicode: '1F1EA-1F1ED',
    sheet: [1, 39],
    native: '🇪🇭',
  },
  {
    unicode: '1F1EA-1F1F7',
    sheet: [1, 40],
    native: '🇪🇷',
  },
  {
    unicode: '1F1EA-1F1F8',
    sheet: [1, 41],
    native: '🇪🇸',
  },
  {
    unicode: '1F1EA-1F1F9',
    sheet: [1, 42],
    native: '🇪🇹',
  },
  {
    unicode: '1F1EA-1F1FA',
    sheet: [1, 43],
    native: '🇪🇺',
  },
  {
    unicode: '1F1EB-1F1EE',
    sheet: [1, 44],
    native: '🇫🇮',
  },
  {
    unicode: '1F1EB-1F1EF',
    sheet: [1, 45],
    native: '🇫🇯',
  },
  {
    unicode: '1F1EB-1F1F0',
    sheet: [1, 46],
    native: '🇫🇰',
  },
  {
    unicode: '1F1EB-1F1F2',
    sheet: [1, 47],
    native: '🇫🇲',
  },
  {
    unicode: '1F1EB-1F1F4',
    sheet: [1, 48],
    native: '🇫🇴',
  },
  {
    unicode: '1F1EB-1F1F7',
    sheet: [1, 49],
    native: '🇫🇷',
  },
  {
    unicode: '1F1EC-1F1E6',
    sheet: [1, 50],
    native: '🇬🇦',
  },
  {
    unicode: '1F1EC-1F1E7',
    sheet: [1, 51],
    native: '🇬🇧',
  },
  {
    unicode: '1F1EC-1F1E9',
    sheet: [1, 52],
    native: '🇬🇩',
  },
  {
    unicode: '1F1EC-1F1EA',
    sheet: [1, 53],
    native: '🇬🇪',
  },
  {
    unicode: '1F1EC-1F1EB',
    sheet: [1, 54],
    native: '🇬🇫',
  },
  {
    unicode: '1F1EC-1F1EC',
    sheet: [1, 55],
    native: '🇬🇬',
  },
  {
    unicode: '1F1EC-1F1ED',
    sheet: [1, 56],
    native: '🇬🇭',
  },
  {
    unicode: '1F1EC-1F1EE',
    sheet: [1, 57],
    native: '🇬🇮',
  },
  {
    unicode: '1F1EC-1F1F1',
    sheet: [1, 58],
    native: '🇬🇱',
  },
  {
    unicode: '1F1EC-1F1F2',
    sheet: [1, 59],
    native: '🇬🇲',
  },
  {
    unicode: '1F1EC-1F1F3',
    sheet: [1, 60],
    native: '🇬🇳',
  },
  {
    unicode: '1F1EC-1F1F5',
    sheet: [2, 0],
    native: '🇬🇵',
  },
  {
    unicode: '1F1EC-1F1F6',
    sheet: [2, 1],
    native: '🇬🇶',
  },
  {
    unicode: '1F1EC-1F1F7',
    sheet: [2, 2],
    native: '🇬🇷',
  },
  {
    unicode: '1F1EC-1F1F8',
    sheet: [2, 3],
    native: '🇬🇸',
  },
  {
    unicode: '1F1EC-1F1F9',
    sheet: [2, 4],
    native: '🇬🇹',
  },
  {
    unicode: '1F1EC-1F1FA',
    sheet: [2, 5],
    native: '🇬🇺',
  },
  {
    unicode: '1F1EC-1F1FC',
    sheet: [2, 6],
    native: '🇬🇼',
  },
  {
    unicode: '1F1EC-1F1FE',
    sheet: [2, 7],
    native: '🇬🇾',
  },
  {
    unicode: '1F1ED-1F1F0',
    sheet: [2, 8],
    native: '🇭🇰',
  },
  {
    unicode: '1F1ED-1F1F2',
    sheet: [2, 9],
    native: '🇭🇲',
  },
  {
    unicode: '1F1ED-1F1F3',
    sheet: [2, 10],
    native: '🇭🇳',
  },
  {
    unicode: '1F1ED-1F1F7',
    sheet: [2, 11],
    native: '🇭🇷',
  },
  {
    unicode: '1F1ED-1F1F9',
    sheet: [2, 12],
    native: '🇭🇹',
  },
  {
    unicode: '1F1ED-1F1FA',
    sheet: [2, 13],
    native: '🇭🇺',
  },
  {
    unicode: '1F1EE-1F1E8',
    sheet: [2, 14],
    native: '🇮🇨',
  },
  {
    unicode: '1F1EE-1F1E9',
    sheet: [2, 15],
    native: '🇮🇩',
  },
  {
    unicode: '1F1EE-1F1EA',
    sheet: [2, 16],
    native: '🇮🇪',
  },
  {
    unicode: '1F1EE-1F1F1',
    sheet: [2, 17],
    native: '🇮🇱',
  },
  {
    unicode: '1F1EE-1F1F2',
    sheet: [2, 18],
    native: '🇮🇲',
  },
  {
    unicode: '1F1EE-1F1F3',
    sheet: [2, 19],
    native: '🇮🇳',
  },
  {
    unicode: '1F1EE-1F1F4',
    sheet: [2, 20],
    native: '🇮🇴',
  },
  {
    unicode: '1F1EE-1F1F6',
    sheet: [2, 21],
    native: '🇮🇶',
  },
  {
    unicode: '1F1EE-1F1F7',
    sheet: [2, 22],
    native: '🇮🇷',
  },
  {
    unicode: '1F1EE-1F1F8',
    sheet: [2, 23],
    native: '🇮🇸',
  },
  {
    unicode: '1F1EE-1F1F9',
    sheet: [2, 24],
    native: '🇮🇹',
  },
  {
    unicode: '1F1EF-1F1EA',
    sheet: [2, 25],
    native: '🇯🇪',
  },
  {
    unicode: '1F1EF-1F1F2',
    sheet: [2, 26],
    native: '🇯🇲',
  },
  {
    unicode: '1F1EF-1F1F4',
    sheet: [2, 27],
    native: '🇯🇴',
  },
  {
    unicode: '1F1EF-1F1F5',
    sheet: [2, 28],
    native: '🇯🇵',
  },
  {
    unicode: '1F1F0-1F1EA',
    sheet: [2, 29],
    native: '🇰🇪',
  },
  {
    unicode: '1F1F0-1F1EC',
    sheet: [2, 30],
    native: '🇰🇬',
  },
  {
    unicode: '1F1F0-1F1ED',
    sheet: [2, 31],
    native: '🇰🇭',
  },
  {
    unicode: '1F1F0-1F1EE',
    sheet: [2, 32],
    native: '🇰🇮',
  },
  {
    unicode: '1F1F0-1F1F2',
    sheet: [2, 33],
    native: '🇰🇲',
  },
  {
    unicode: '1F1F0-1F1F3',
    sheet: [2, 34],
    native: '🇰🇳',
  },
  {
    unicode: '1F1F0-1F1F5',
    sheet: [2, 35],
    native: '🇰🇵',
  },
  {
    unicode: '1F1F0-1F1F7',
    sheet: [2, 36],
    native: '🇰🇷',
  },
  {
    unicode: '1F1F0-1F1FC',
    sheet: [2, 37],
    native: '🇰🇼',
  },
  {
    unicode: '1F1F0-1F1FE',
    sheet: [2, 38],
    native: '🇰🇾',
  },
  {
    unicode: '1F1F0-1F1FF',
    sheet: [2, 39],
    native: '🇰🇿',
  },
  {
    unicode: '1F1F1-1F1E6',
    sheet: [2, 40],
    native: '🇱🇦',
  },
  {
    unicode: '1F1F1-1F1E7',
    sheet: [2, 41],
    native: '🇱🇧',
  },
  {
    unicode: '1F1F1-1F1E8',
    sheet: [2, 42],
    native: '🇱🇨',
  },
  {
    unicode: '1F1F1-1F1EE',
    sheet: [2, 43],
    native: '🇱🇮',
  },
  {
    unicode: '1F1F1-1F1F0',
    sheet: [2, 44],
    native: '🇱🇰',
  },
  {
    unicode: '1F1F1-1F1F7',
    sheet: [2, 45],
    native: '🇱🇷',
  },
  {
    unicode: '1F1F1-1F1F8',
    sheet: [2, 46],
    native: '🇱🇸',
  },
  {
    unicode: '1F1F1-1F1F9',
    sheet: [2, 47],
    native: '🇱🇹',
  },
  {
    unicode: '1F1F1-1F1FA',
    sheet: [2, 48],
    native: '🇱🇺',
  },
  {
    unicode: '1F1F1-1F1FB',
    sheet: [2, 49],
    native: '🇱🇻',
  },
  {
    unicode: '1F1F1-1F1FE',
    sheet: [2, 50],
    native: '🇱🇾',
  },
  {
    unicode: '1F1F2-1F1E6',
    sheet: [2, 51],
    native: '🇲🇦',
  },
  {
    unicode: '1F1F2-1F1E8',
    sheet: [2, 52],
    native: '🇲🇨',
  },
  {
    unicode: '1F1F2-1F1E9',
    sheet: [2, 53],
    native: '🇲🇩',
  },
  {
    unicode: '1F1F2-1F1EA',
    sheet: [2, 54],
    native: '🇲🇪',
  },
  {
    unicode: '1F1F2-1F1EB',
    sheet: [2, 55],
    native: '🇲🇫',
  },
  {
    unicode: '1F1F2-1F1EC',
    sheet: [2, 56],
    native: '🇲🇬',
  },
  {
    unicode: '1F1F2-1F1ED',
    sheet: [2, 57],
    native: '🇲🇭',
  },
  {
    unicode: '1F1F2-1F1F0',
    sheet: [2, 58],
    native: '🇲🇰',
  },
  {
    unicode: '1F1F2-1F1F1',
    sheet: [2, 59],
    native: '🇲🇱',
  },
  {
    unicode: '1F1F2-1F1F2',
    sheet: [2, 60],
    native: '🇲🇲',
  },
  {
    unicode: '1F1F2-1F1F3',
    sheet: [3, 0],
    native: '🇲🇳',
  },
  {
    unicode: '1F1F2-1F1F4',
    sheet: [3, 1],
    native: '🇲🇴',
  },
  {
    unicode: '1F1F2-1F1F5',
    sheet: [3, 2],
    native: '🇲🇵',
  },
  {
    unicode: '1F1F2-1F1F6',
    sheet: [3, 3],
    native: '🇲🇶',
  },
  {
    unicode: '1F1F2-1F1F7',
    sheet: [3, 4],
    native: '🇲🇷',
  },
  {
    unicode: '1F1F2-1F1F8',
    sheet: [3, 5],
    native: '🇲🇸',
  },
  {
    unicode: '1F1F2-1F1F9',
    sheet: [3, 6],
    native: '🇲🇹',
  },
  {
    unicode: '1F1F2-1F1FA',
    sheet: [3, 7],
    native: '🇲🇺',
  },
  {
    unicode: '1F1F2-1F1FB',
    sheet: [3, 8],
    native: '🇲🇻',
  },
  {
    unicode: '1F1F2-1F1FC',
    sheet: [3, 9],
    native: '🇲🇼',
  },
  {
    unicode: '1F1F2-1F1FD',
    sheet: [3, 10],
    native: '🇲🇽',
  },
  {
    unicode: '1F1F2-1F1FE',
    sheet: [3, 11],
    native: '🇲🇾',
  },
  {
    unicode: '1F1F2-1F1FF',
    sheet: [3, 12],
    native: '🇲🇿',
  },
  {
    unicode: '1F1F3-1F1E6',
    sheet: [3, 13],
    native: '🇳🇦',
  },
  {
    unicode: '1F1F3-1F1E8',
    sheet: [3, 14],
    native: '🇳🇨',
  },
  {
    unicode: '1F1F3-1F1EA',
    sheet: [3, 15],
    native: '🇳🇪',
  },
  {
    unicode: '1F1F3-1F1EB',
    sheet: [3, 16],
    native: '🇳🇫',
  },
  {
    unicode: '1F1F3-1F1EC',
    sheet: [3, 17],
    native: '🇳🇬',
  },
  {
    unicode: '1F1F3-1F1EE',
    sheet: [3, 18],
    native: '🇳🇮',
  },
  {
    unicode: '1F1F3-1F1F1',
    sheet: [3, 19],
    native: '🇳🇱',
  },
  {
    unicode: '1F1F3-1F1F4',
    sheet: [3, 20],
    native: '🇳🇴',
  },
  {
    unicode: '1F1F3-1F1F5',
    sheet: [3, 21],
    native: '🇳🇵',
  },
  {
    unicode: '1F1F3-1F1F7',
    sheet: [3, 22],
    native: '🇳🇷',
  },
  {
    unicode: '1F1F3-1F1FA',
    sheet: [3, 23],
    native: '🇳🇺',
  },
  {
    unicode: '1F1F3-1F1FF',
    sheet: [3, 24],
    native: '🇳🇿',
  },
  {
    unicode: '1F1F4-1F1F2',
    sheet: [3, 25],
    native: '🇴🇲',
  },
  {
    unicode: '1F1F5-1F1E6',
    sheet: [3, 26],
    native: '🇵🇦',
  },
  {
    unicode: '1F1F5-1F1EA',
    sheet: [3, 27],
    native: '🇵🇪',
  },
  {
    unicode: '1F1F5-1F1EB',
    sheet: [3, 28],
    native: '🇵🇫',
  },
  {
    unicode: '1F1F5-1F1EC',
    sheet: [3, 29],
    native: '🇵🇬',
  },
  {
    unicode: '1F1F5-1F1ED',
    sheet: [3, 30],
    native: '🇵🇭',
  },
  {
    unicode: '1F1F5-1F1F0',
    sheet: [3, 31],
    native: '🇵🇰',
  },
  {
    unicode: '1F1F5-1F1F1',
    sheet: [3, 32],
    native: '🇵🇱',
  },
  {
    unicode: '1F1F5-1F1F2',
    sheet: [3, 33],
    native: '🇵🇲',
  },
  {
    unicode: '1F1F5-1F1F3',
    sheet: [3, 34],
    native: '🇵🇳',
  },
  {
    unicode: '1F1F5-1F1F7',
    sheet: [3, 35],
    native: '🇵🇷',
  },
  {
    unicode: '1F1F5-1F1F8',
    sheet: [3, 36],
    native: '🇵🇸',
  },
  {
    unicode: '1F1F5-1F1F9',
    sheet: [3, 37],
    native: '🇵🇹',
  },
  {
    unicode: '1F1F5-1F1FC',
    sheet: [3, 38],
    native: '🇵🇼',
  },
  {
    unicode: '1F1F5-1F1FE',
    sheet: [3, 39],
    native: '🇵🇾',
  },
  {
    unicode: '1F1F6-1F1E6',
    sheet: [3, 40],
    native: '🇶🇦',
  },
  {
    unicode: '1F1F7-1F1EA',
    sheet: [3, 41],
    native: '🇷🇪',
  },
  {
    unicode: '1F1F7-1F1F4',
    sheet: [3, 42],
    native: '🇷🇴',
  },
  {
    unicode: '1F1F7-1F1F8',
    sheet: [3, 43],
    native: '🇷🇸',
  },
  {
    unicode: '1F1F7-1F1FA',
    sheet: [3, 44],
    native: '🇷🇺',
  },
  {
    unicode: '1F1F7-1F1FC',
    sheet: [3, 45],
    native: '🇷🇼',
  },
  {
    unicode: '1F1F8-1F1E6',
    sheet: [3, 46],
    native: '🇸🇦',
  },
  {
    unicode: '1F1F8-1F1E7',
    sheet: [3, 47],
    native: '🇸🇧',
  },
  {
    unicode: '1F1F8-1F1E8',
    sheet: [3, 48],
    native: '🇸🇨',
  },
  {
    unicode: '1F1F8-1F1E9',
    sheet: [3, 49],
    native: '🇸🇩',
  },
  {
    unicode: '1F1F8-1F1EA',
    sheet: [3, 50],
    native: '🇸🇪',
  },
  {
    unicode: '1F1F8-1F1EC',
    sheet: [3, 51],
    native: '🇸🇬',
  },
  {
    unicode: '1F1F8-1F1ED',
    sheet: [3, 52],
    native: '🇸🇭',
  },
  {
    unicode: '1F1F8-1F1EE',
    sheet: [3, 53],
    native: '🇸🇮',
  },
  {
    unicode: '1F1F8-1F1EF',
    sheet: [3, 54],
    native: '🇸🇯',
  },
  {
    unicode: '1F1F8-1F1F0',
    sheet: [3, 55],
    native: '🇸🇰',
  },
  {
    unicode: '1F1F8-1F1F1',
    sheet: [3, 56],
    native: '🇸🇱',
  },
  {
    unicode: '1F1F8-1F1F2',
    sheet: [3, 57],
    native: '🇸🇲',
  },
  {
    unicode: '1F1F8-1F1F3',
    sheet: [3, 58],
    native: '🇸🇳',
  },
  {
    unicode: '1F1F8-1F1F4',
    sheet: [3, 59],
    native: '🇸🇴',
  },
  {
    unicode: '1F1F8-1F1F7',
    sheet: [3, 60],
    native: '🇸🇷',
  },
  {
    unicode: '1F1F8-1F1F8',
    sheet: [4, 0],
    native: '🇸🇸',
  },
  {
    unicode: '1F1F8-1F1F9',
    sheet: [4, 1],
    native: '🇸🇹',
  },
  {
    unicode: '1F1F8-1F1FB',
    sheet: [4, 2],
    native: '🇸🇻',
  },
  {
    unicode: '1F1F8-1F1FD',
    sheet: [4, 3],
    native: '🇸🇽',
  },
  {
    unicode: '1F1F8-1F1FE',
    sheet: [4, 4],
    native: '🇸🇾',
  },
  {
    unicode: '1F1F8-1F1FF',
    sheet: [4, 5],
    native: '🇸🇿',
  },
  {
    unicode: '1F1F9-1F1E6',
    sheet: [4, 6],
    native: '🇹🇦',
  },
  {
    unicode: '1F1F9-1F1E8',
    sheet: [4, 7],
    native: '🇹🇨',
  },
  {
    unicode: '1F1F9-1F1E9',
    sheet: [4, 8],
    native: '🇹🇩',
  },
  {
    unicode: '1F1F9-1F1EB',
    sheet: [4, 9],
    native: '🇹🇫',
  },
  {
    unicode: '1F1F9-1F1EC',
    sheet: [4, 10],
    native: '🇹🇬',
  },
  {
    unicode: '1F1F9-1F1ED',
    sheet: [4, 11],
    native: '🇹🇭',
  },
  {
    unicode: '1F1F9-1F1EF',
    sheet: [4, 12],
    native: '🇹🇯',
  },
  {
    unicode: '1F1F9-1F1F0',
    sheet: [4, 13],
    native: '🇹🇰',
  },
  {
    unicode: '1F1F9-1F1F1',
    sheet: [4, 14],
    native: '🇹🇱',
  },
  {
    unicode: '1F1F9-1F1F2',
    sheet: [4, 15],
    native: '🇹🇲',
  },
  {
    unicode: '1F1F9-1F1F3',
    sheet: [4, 16],
    native: '🇹🇳',
  },
  {
    unicode: '1F1F9-1F1F4',
    sheet: [4, 17],
    native: '🇹🇴',
  },
  {
    unicode: '1F1F9-1F1F7',
    sheet: [4, 18],
    native: '🇹🇷',
  },
  {
    unicode: '1F1F9-1F1F9',
    sheet: [4, 19],
    native: '🇹🇹',
  },
  {
    unicode: '1F1F9-1F1FB',
    sheet: [4, 20],
    native: '🇹🇻',
  },
  {
    unicode: '1F1F9-1F1FC',
    sheet: [4, 21],
    native: '🇹🇼',
  },
  {
    unicode: '1F1F9-1F1FF',
    sheet: [4, 22],
    native: '🇹🇿',
  },
  {
    unicode: '1F1FA-1F1E6',
    sheet: [4, 23],
    native: '🇺🇦',
  },
  {
    unicode: '1F1FA-1F1EC',
    sheet: [4, 24],
    native: '🇺🇬',
  },
  {
    unicode: '1F1FA-1F1F2',
    sheet: [4, 25],
    native: '🇺🇲',
  },
  {
    unicode: '1F1FA-1F1F3',
    sheet: [4, 26],
    native: '🇺🇳',
  },
  {
    unicode: '1F1FA-1F1F8',
    sheet: [4, 27],
    native: '🇺🇸',
  },
  {
    unicode: '1F1FA-1F1FE',
    sheet: [4, 28],
    native: '🇺🇾',
  },
  {
    unicode: '1F1FA-1F1FF',
    sheet: [4, 29],
    native: '🇺🇿',
  },
  {
    unicode: '1F1FB-1F1E6',
    sheet: [4, 30],
    native: '🇻🇦',
  },
  {
    unicode: '1F1FB-1F1E8',
    sheet: [4, 31],
    native: '🇻🇨',
  },
  {
    unicode: '1F1FB-1F1EA',
    sheet: [4, 32],
    native: '🇻🇪',
  },
  {
    unicode: '1F1FB-1F1EC',
    sheet: [4, 33],
    native: '🇻🇬',
  },
  {
    unicode: '1F1FB-1F1EE',
    sheet: [4, 34],
    native: '🇻🇮',
  },
  {
    unicode: '1F1FB-1F1F3',
    sheet: [4, 35],
    native: '🇻🇳',
  },
  {
    unicode: '1F1FB-1F1FA',
    sheet: [4, 36],
    native: '🇻🇺',
  },
  {
    unicode: '1F1FC-1F1EB',
    sheet: [4, 37],
    native: '🇼🇫',
  },
  {
    unicode: '1F1FC-1F1F8',
    sheet: [4, 38],
    native: '🇼🇸',
  },
  {
    unicode: '1F1FD-1F1F0',
    sheet: [4, 39],
    native: '🇽🇰',
  },
  {
    unicode: '1F1FE-1F1EA',
    sheet: [4, 40],
    native: '🇾🇪',
  },
  {
    unicode: '1F1FE-1F1F9',
    sheet: [4, 41],
    native: '🇾🇹',
  },
  {
    unicode: '1F1FF-1F1E6',
    sheet: [4, 42],
    native: '🇿🇦',
  },
  {
    unicode: '1F1FF-1F1F2',
    sheet: [4, 43],
    native: '🇿🇲',
  },
  {
    unicode: '1F1FF-1F1FC',
    sheet: [4, 44],
    native: '🇿🇼',
  },
  {
    unicode: '1F3F4-E0067-E0062-E0065-E006E-E0067-E007F',
    sheet: [10, 32],
    native: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
  },
  {
    unicode: '1F3F4-E0067-E0062-E0073-E0063-E0074-E007F',
    sheet: [10, 33],
    native: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
  },
  {
    unicode: '1F3F4-E0067-E0062-E0077-E006C-E0073-E007F',
    sheet: [10, 34],
    native: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
  },
];
