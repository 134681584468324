import { Fyis } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class FyisReadRpcInvoker implements Fyis.ReadService {
  @invoker
  get readIds$(): Observable<string[]> {
    return;
  }

  @invoker
  updateRead(ids: string[]): Promise<void> {
    return;
  }
}
