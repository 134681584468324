<p-checkbox
  class="u-checkbox"
  [ngClass]="{ 'no-color': isNoColor }"
  [(ngModel)]="checked"
  [label]="label"
  [value]="value"
  [name]="name"
  [binary]="binary"
  [disabled]="disabled"
  [inputId]=""
  inputId
  (onChange)="onChange.emit($event)"
></p-checkbox>
