import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { isEnterKey, KeyName } from '@local/ts-infra';
import { FilterChangeData, ToggleFilter } from '../models';

@Component({
  selector: 'toggle-filter',
  templateUrl: './toggle-filter.component.html',
  styleUrls: ['./toggle-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleFilterComponent {
  @Output() change: EventEmitter<FilterChangeData> = new EventEmitter();
  @Input() model: ToggleFilter;
  private _marked: { marked: boolean; keys: KeyName[]; event: CustomKeyboardEvent };
  @Input() set marked({ marked, keys, event }: { marked: boolean; keys: KeyName[]; event: CustomKeyboardEvent }) {
    this._marked = { marked, keys, event };
    if (marked) this.registerKeyHandler(keys, event);
    else this.unregisterKeyHandler();
  }
  get marked() {
    return this._marked;
  }

  get disabled() {
    return !this.model?.values?.length;
  }

  @Input() isSelected = false;
  private keyHandlerId: string;

  constructor(private cdr: ChangeDetectorRef, public ref: ElementRef, private keyboardService: KeyboardService) {}

  onChange(): void {
    if (this.model.disabled) {
      return;
    }
    this.isSelected = !this.isSelected;
    this.change.emit({
      name: this.model.name,
      action: this.isSelected ? 'Add' : 'Remove',
      changes: { values: [this.model.values[0]] },
      current: { values: this.isSelected ? [this.model.values[0]] : [] },
    });
    this.cdr.markForCheck();
  }

  private registerKeyHandler(keys: KeyName[], event: CustomKeyboardEvent): void {
    if (this.keyHandlerId) return;
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 6);
    if (keys) this.handleKeys(keys, event);
  }

  private unregisterKeyHandler(): void {
    if (!this.keyHandlerId) return;
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    this.keyHandlerId = null;
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent) {
    const key = keys[0];
    if (this.model.disabled) return;

    if (isEnterKey(key) || key === 'space') {
      this.onChange();
      event.stopPropagation();
      return;
    }
  }
}
