import { EventInfo, LogService } from '@shared/services';
import { LinkResourcesSearchClient, LinkResourcesSourceSettings, SearchClient, SearchRequest, SearchResponse } from '..';
import { groupResourceSettings } from './group-resource-settings';
import { Logger } from '@unleash-tech/js-logger';
import { Filters, Search } from '@local/client-contracts';
import { Observable, firstValueFrom, isObservable, map, of } from 'rxjs';
import { TelemetryTrigger, SearchResults } from 'src/app/bar/views';
import { ExperiencesService } from '../../../experiences.service';

export class groupResourcesSearchClient implements SearchClient<groupResourceSettings> {
  private logger: Logger;

  constructor(
    private logService: LogService,
    private LinkResourceSearchClient: LinkResourcesSearchClient,
    private experiencesService: ExperiencesService
  ) {
    this.logger = this.logService.scope('group-resources');
  }

  async search(request: SearchRequest<groupResourceSettings>, response: SearchResponse): Promise<Observable<void>> {
    const assistantId = request.sourceSettings.assistantId;
    if (!assistantId || response.cancelled) {
      response.complete(true);
      return;
    }
    const assistant = await this.experiencesService.getExperience(assistantId);
    const searchGroup = assistant?.searchGroupsFilters;
    if (!searchGroup?.length || response.cancelled) {
      response.complete(true);
      return;
    }

    const sourceSettings = request.sourceSettings;
    const innerResponse = response.clone();
    const settings: LinkResourcesSourceSettings = { ...sourceSettings, type: 'link-resources', caching: { strategy: 'cache-and-source' } };
    const res = await this.LinkResourceSearchClient.search(
      {
        ...request,
        sourceSettings: { ...settings, groupsFilters: searchGroup },
      },
      innerResponse
    );
    if (response.cancelled) {
      response.complete(true);
      return;
    }
    if (isObservable(res)) {
      return res.pipe(
        map(() => {
          return this.handleInnerResponse(request, response, innerResponse);
        })
      );
    }
    return of(this.handleInnerResponse(request, response, innerResponse));
  }

  nextPage(request: SearchRequest<groupResourceSettings>, response: SearchResponse, trigger: TelemetryTrigger): Promise<void> {
    return;
  }

  getTelemetryEndEvent(response: SearchResponse): Partial<EventInfo>[] {
    return;
  }

  destroy(): void {
    this.LinkResourceSearchClient.destroy();
  }

  private handleInnerResponse(request: SearchRequest<groupResourceSettings>, response: SearchResponse, innerResponse: SearchResponse) {
    if (!innerResponse.done) {
      return;
    }
    response.extra = innerResponse.extra;
    if (!innerResponse.items || !Array.isArray(innerResponse.items[0])) {
      response.complete(true);
      return;
    }

    const itemsArray = innerResponse.items as SearchResults[][];
    if (itemsArray.every((a) => !a.length)) {
      response.complete(true);
      return;
    }

    response.extra = <groupResourceSettings>innerResponse.extra;
    const items: SearchResults[] = innerResponse.items[0] as SearchResults[];

    response.items = [...items];
    response.complete(true);
  }

  supportsSort(sort: Search.Sort): boolean {
    return true;
  }

  supportsFilters(filters: Filters.Values): boolean {
    return true;
  }
}
