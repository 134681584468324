<u-input
  #input
  id="expandable-search-btn"
  [placeholder]="placeholder"
  [inlineExtraIcon]="searchIcon"
  [isLoading]="false"
  (onBlur)="onBlur()"
  (onChange)="onChangeEvent($event)"
  (onFocus)="focusInput = true"
  [attr.focus-input]="focusInput"
  [isSearchInput]="true"
  [class.large]="size === 'large'"
></u-input>
