import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'u-toggle-button',
  templateUrl: './u-toggle-button.component.html',
  styleUrls: ['./u-toggle-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UToggleButtonComponent {
  @Input() checked = false;
  @Input() disabled = false;
  @Input() type: string;
  @Output() onChange = new EventEmitter();
}
