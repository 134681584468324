<div class="container">
  <div class="header">
    <span class="title">{{ title }}</span>
    <span *ngIf="showResetBtn" class="reset" (click)="onReset()">Reset</span>
  </div>
</div>

<u-button
  #colorPickerButton
  class="color-picker-btn"
  [label]="displayColorText ?? this.originalColor | uppercase"
  [styles]="{ backgroundColor: color ?? this.originalColor, color: textColor, width: '230px' }"
  type="secondary"
  fontIcon="eye-dropper"
  [iconStyles]="{ fontSize: '10px', fontWeight: 'bold' }"
  iconPos="right"
  (onClick)="openColorPicker()"
>
</u-button>
