import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UProgressBarComponent } from './u-progress-bar.component';
import { ProgressBarModule } from 'primeng/progressbar';

@NgModule({
  declarations: [UProgressBarComponent],
  imports: [CommonModule, ProgressBarModule],
  exports: [UProgressBarComponent],
})
export class UProgressBarModule {}
