import { Injectable } from '@angular/core';
import { Config } from '@environments/config';
import { Blobs, Permissions } from '@local/client-contracts';
import { BlobsRpcInvoker } from '@local/common';
import { WebCrypto, isEmbed } from '@local/common-web';
import { isHttpOrHttps } from '@local/ts-infra';
import { ServicesRpcService } from '@shared/services';
import { DownloadsService } from '@shared/services/downloads.service';
import { generateFullPrefixedURL } from '@shared/utils';

export interface ListBlobMetadataEntry {
  id: string;
  lastUpdateTime: number;
}

type CacheEntry = { blob: Blobs.BlobMetadata; lastUpdateTime: number };

@Injectable({
  providedIn: 'root',
})
export class BlobsService {
  private service: Blobs.Service;
  private baseUrl: string;
  private cdnUrl: string;
  private webCrypto: WebCrypto;
  private cache: { [id: string]: CacheEntry } = {};
  private isEmbed: boolean = isEmbed();

  constructor(services: ServicesRpcService, private downloadService: DownloadsService) {
    this.webCrypto = new WebCrypto();
    this.service = services.invokeWith(BlobsRpcInvoker, 'blobs');
    this.baseUrl = Config.blobs.baseUrl;
    this.cdnUrl = Config.blobs.cdnUrl;
  }

  create(name: string, type: string, shareOptions: Permissions.ShareOptions) {
    return this.service.create(name, type, shareOptions);
  }

  update(id: string, name?: string, type?: string, shareOptions?: Permissions.ShareOptions) {
    return this.service.update(id, name, type, shareOptions);
  }

  delete(ids: string[]) {
    return this.service.delete(ids);
  }

  duplicate(request: Blobs.ListDuplicateBlobRequest): Promise<Blobs.ListDuplicateBlobResponse> {
    return this.service.duplicate(request);
  }
  private readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result instanceof ArrayBuffer) {
          resolve(reader.result);
        } else {
          reject(new Error('FileReader result is not an ArrayBuffer'));
        }
      };
      reader.onerror = () => {
        reject(new Error('Error reading file'));
      };
      reader.readAsArrayBuffer(file);
    });
  }

  async upload(file: File, id: string): Promise<Response> {
    //todo: workaround to card file upload on new, try file.arrayBuffer() after new card creation flow.
    const arrayBuffer = await this.readFileAsArrayBuffer(file);
    const [hash, uploadUrl] = await Promise.all([this.webCrypto.computeSHA256Hex(arrayBuffer), this.getBlobFullUrl(id)]);

    const req = new Request(uploadUrl, {
      method: 'POST',
      headers: { 'If-Match': hash },
      body: arrayBuffer,
    });

    return fetch(req);
  }

  getThumbnailUrl(id: string, width = 210, format = 'webp'): string {
    return `${this.isEmbed ? this.cdnUrl : this.baseUrl}/blobs/${id}/thumbnail?width=${width}&format=${format}`;
  }

  getCdnUrl(separateUrl: string): Promise<string> {
    const type = isHttpOrHttps(separateUrl) ? 'url' : 'path';
    const url: string = generateFullPrefixedURL(separateUrl, type, true);
    return this.downloadService.addTicketToUrl('blobs', url);
  }

  getBlobFullUrl(id: string): Promise<string> {
    const url = `${this.baseUrl}/blobs/${id}/content`;
    return this.downloadService.addTicketToUrl('blobs', url);
  }

  getDownloadUrl(fileId: string): string {
    return `${this.isEmbed ? this.cdnUrl + '/api' : ''}/blobs/${fileId}/content`;
  }

  async getBlobMetadata(id: string, lastUpdateTime: number): Promise<Blobs.BlobMetadata> {
    let entry = this.getFromCache(id, lastUpdateTime);
    if (!entry) {
      const res = await this.service.getBlobMetadata(id);
      this.cache[id] = entry = { blob: res, lastUpdateTime };
    }
    return entry?.blob;
  }

  async getBlobMetadataByIds(req: ListBlobMetadataEntry[]): Promise<Blobs.ListBlobMetadata> {
    const notCached: { id: string; lastUpdateTime: number; index: number }[] = [];
    const entries: Blobs.BlobMetadata[] = [];
    for (let index = 0; index < req.length; index++) {
      const { id, lastUpdateTime } = req[index];
      const entry = this.getFromCache(id, lastUpdateTime);
      if (!entry) {
        notCached.push({ id, index, lastUpdateTime });
        continue;
      }
      entries[index] = this.cache[id].blob;
    }
    const ids = notCached.map((a) => a.id);
    const res = ids.length ? await this.service.getBlobMetadataByIds(ids) : { entries: [] };
    for (const { id, lastUpdateTime, index } of notCached) {
      const blob = res.entries.find((i) => i.id === id);
      entries[index] = blob;
      if (!blob) {
        continue;
      }
      this.cache[id] = { blob, lastUpdateTime };
    }
    return { entries };
  }

  private getFromCache(id: string, lastUpdateTime: number): CacheEntry {
    const entry = this.cache[id];
    const cacheTime = entry?.lastUpdateTime;
    if (cacheTime && cacheTime >= lastUpdateTime) {
      return entry;
    }
  }

  isBlobApi(url: string) {
    return url.startsWith(`${this.baseUrl}/blobs/`);
  }
}
