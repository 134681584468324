<div class="container">
  <div class="main">
    <div *ngIf="icon || defaultErrorIcon" class="title-icon" [class.showIndicator]="showIndicator">
      <indicator *ngIf="icon?.type === 'img' && showIndicator" [size]="'5px'" class="title-icon-indicator" [intent]="'danger'"></indicator>
      <u-icon *ngIf="icon?.type === 'img'" class="icon" [class.sync-animation]="isAnimated" [model]="icon"></u-icon>
      <exclamation-circle [size]="'16px'" *ngIf="defaultErrorIcon"></exclamation-circle>
      <u-icon
        *ngIf="icon?.type === 'font'"
        class="toaster-icon"
        [class.sync-animation]="isAnimated"
        [ngClass]="iconIntent"
        [model]="icon"
      ></u-icon>
    </div>

    <h1 class="title" [class.bold]="boldTitle" [class.has-sibling]="!!content" *ngIf="title">{{ title }}</h1>
    <p class="content" data-cy="toaster-content" [class.showIndicator]="showIndicator" [class.has-sibling]="!!buttonText" *ngIf="content">
      {{ content }}
    </p>
    <a *ngIf="buttonText" class="action" [ngClass]="intent" (click)="onInvoke()">
      {{ buttonText }}
    </a>
  </div>

  <a class="dismiss" [class.has-sibling]="!irremovable" (click)="onDismiss()" *ngIf="!irremovable">
    <u-icon
      id="notification-close"
      *ngIf="!irremovable"
      class="close-button"
      [model]="{ type: 'font', value: 'icon-times-large' }"
    ></u-icon>
  </a>
</div>
