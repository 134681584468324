import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SplitButton } from 'primeng/splitbutton';
import { ElementSize } from '../../types/element-size';

@Component({
  selector: 'u-split-button',
  templateUrl: './u-split-button.component.html',
  styleUrls: ['./u-split-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class USplitButtonComponent implements AfterViewInit {
  @ViewChild(SplitButton) splitButton: SplitButton;
  @Input() disabled = false;
  @Input() type = 'primary';
  @Input() elementSize: ElementSize;
  @Input() styles: any = {};

  @Output() onClick = new EventEmitter();
  @Output() onDropdownClick = new EventEmitter();

  _label: string;
  @Input() set label(value: string) {
    this._label = value;
    this.cdr.detectChanges();
    this.updateClass();
  }

  get label() {
    return this._label;
  }

  constructor(public cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.splitButton.onDropdownButtonClick = () => {
      this.onDropdownClick.emit();
    };
    this.updateClass();
  }

  updateClass() {
    if (!this.splitButton) return;
    const splitButtonParts = [...this.splitButton.containerViewChild.nativeElement.children].filter((c: HTMLElement) =>
      c.className.includes('p-splitbutton')
    );
    splitButtonParts.forEach((b) => b.classList.add('u-button', 'u-split-button', `${this.type}-button`, `${this.elementSize}`));
  }
}
