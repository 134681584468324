<div @fade class="people-preview-container-wrapper">
  <div *ngIf="!current && !(error$ | async); else content">
    <loader-overlay *ngIf="startLoad"> </loader-overlay>
  </div>
  <ng-template #content>
    <preview-error *ngIf="error$ | async; else people_content" (again)="tryAgain()"></preview-error>
    <ng-template #people_content>
      <people-preview
        [ngClass]="{ 'people-preview-container-single-state': size === 'full' }"
        class="people-preview-container-content"
        [size]="size"
        [smallWidthPreview]="smallWidthPreview"
        [extraSmallWidthPreview]="extraSmallWidthPreview"
        [person]="current$ | async"
        [personIndex]="index"
        [isFavorite]="isFavorite"
        [isLauncher]="isLauncher"
        [telemetrySearch]="telemetrySearch"
        [ngStyle]="!extraSmallWidthPreview ? { 'max-width': previewWidth + 'px ' } : {}"
        [previewType]="previewType"
      ></people-preview>
    </ng-template>
  </ng-template>
</div>
