import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Style } from '@local/client-contracts';
import { IconConstants } from '@shared/consts';

@Component({
  selector: 'loader-overlay',
  templateUrl: './loader-overlay.component.html',
  styleUrls: ['./loader-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderOverlayComponent implements OnInit {
  @Input() displayText: boolean = false;
  @Input() styles = {};
  @Input() darkOnly: boolean = false;

  loaderIcon: Style.Icon;

  ngOnInit(): void {
    if (this.darkOnly) {
      this.loaderIcon = IconConstants.APP_MAIN_ICON_LOADER_DARK_ONLY;
    } else {
      this.loaderIcon = IconConstants.APP_MAIN_ICON_LOADER;
    }
  }
}
