<div
  class="download-item-wrapper"
  *ngIf="res"
  [ngClass]="{ active: res.visibility === 'visible' && res.status === 'in-progress' }"
  [dragAndDrop]="dragData"
  [@clear]="clearedIndication.toString()"
  (@clear.done)="onClear($event)"
  [pTooltip]="
    res.status === 'in-progress' && res.totalBytes && res.receivedBytes
      ? (res.receivedBytes | fileSize) + '/' + (res.totalBytes | fileSize)
      : undefined
  "
  tooltipPosition="bottom"
  tooltipStyleClass="u-tooltip"
>
  <ng-container *ngIf="res.visibility === 'visible'; else canceled">
    <ng-container [ngSwitch]="res.status">
      <ng-container *ngSwitchCase="'in-progress'">
        <div class="progress-bar-container">
          <div class="progress-bar">
            <mat-progress-spinner
              *ngIf="res.visibility === 'visible'"
              [mode]="res.progress ? 'determinate' : 'indeterminate'"
              [diameter]="26"
              [value]="res.progress"
              [strokeWidth]="1"
            ></mat-progress-spinner>
            <u-icon
              iconCtx="style"
              class="icon"
              [model]="{ type: 'img', value: res.icon }"
              [styles]="{ backgroundSize: 'contain' }"
            ></u-icon>
          </div>
        </div>
        <div class="name" [pTooltip]="res.displayName ? res.name : undefined" tooltipPosition="bottom" tooltipStyleClass="u-tooltip">
          {{ res.displayName || res.name }}
        </div>
        <div class="actions">
          <div class="cancel" (click)="onCancel(res.id)" [@change]>Cancel</div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'completed'">
        <div class="progress-bar-container">
          <div class="progress-bar">
            <mat-progress-spinner
              *ngIf="res.visibility === 'visible' && completedIndication"
              [mode]="'determinate'"
              [diameter]="26"
              [value]="100"
              [strokeWidth]="1"
              [@completed]="completedIndication?.toString()"
            ></mat-progress-spinner>
            <u-icon
              iconCtx="style"
              class="icon"
              [model]="{ type: 'img', value: res.icon }"
              [styles]="{ backgroundSize: 'contain' }"
            ></u-icon>
          </div>
        </div>

        <div
          class="name"
          [ngClass]="{ clickable: res.visibility === 'visible' }"
          (click)="onOpen(res.id)"
          [pTooltip]="res.displayName ? res.name : undefined"
          tooltipPosition="bottom"
          tooltipStyleClass="u-tooltip"
        >
          {{ res.displayName || res.name }}
        </div>
        <div class="actions">
          <div class="show" (click)="onShow(res.id)" [@change]>{{ showText }}</div>
          <div class="clear" (click)="handleClear(res.id)" [@change]>
            <u-icon
              [model]="{ type: 'font', value: 'icon-times-large' }"
              pTooltip="Clear"
              tooltipPosition="bottom"
              tooltipStyleClass="u-tooltip"
            ></u-icon>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'interrupted'">
        <div class="progress-bar-container">
          <div class="progress-bar" [@change]>
            <u-icon class="icon" [model]="{ type: 'font', value: 'icon-exclamation-mark-circle' }">
              <i class="path1"></i>
              <i class="path2"></i>
            </u-icon>
          </div>
        </div>
        <div class="name" [pTooltip]="res.displayName ? res.name : undefined" tooltipPosition="bottom" tooltipStyleClass="u-tooltip">
          {{ res.displayName || res.name }}
        </div>
        <div class="actions">
          <div class="retry" (click)="onRetry(res.id)" [@change]>Retry</div>
          <div class="clear" (click)="handleClear(res.id)" [@change]>
            <u-icon
              [model]="{ type: 'font', value: 'icon-times-large' }"
              pTooltip="Clear"
              tooltipPosition="bottom"
              tooltipStyleClass="u-tooltip"
            ></u-icon>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #canceled>
    <ng-container *ngIf="res.status === 'canceled'">
      <div class="progress-bar-container">
        <div class="progress-bar">
          <u-icon iconCtx="style" class="icon" [model]="{ type: 'img', value: res.icon }" [styles]="{ backgroundSize: 'contain' }"></u-icon>
        </div>
      </div>
      <div class="name" [pTooltip]="res.displayName ? res.name : undefined" tooltipPosition="bottom" tooltipStyleClass="u-tooltip">
        {{ res.displayName || res.name }}
      </div>
      <div class="actions">
        <div class="canceled" [@change]>Canceled</div>
        <div class="clear" (click)="handleClear(res.id)" [@change]>
          <u-icon
            [model]="{ type: 'font', value: 'icon-times-large' }"
            pTooltip="Clear"
            tooltipPosition="bottom"
            tooltipStyleClass="u-tooltip"
          ></u-icon>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>
