import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  FlexibleConnectedPositionStrategyOrigin,
  GlobalPositionStrategy,
  Overlay,
} from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Component, ElementRef, Injectable, Injector } from '@angular/core';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as uuid from 'uuid';
import { GlobalPosition, PopUpOptions, PopUpPosition } from './popup-options';
import { PopupRef } from './popup-ref';

const isGlobalPosition = (x: any): x is GlobalPosition => 'top' in x || 'bottom' in x || 'left' in x || 'right' in x;
@Injectable({
  providedIn: 'root',
})
export class PopupService {
  refs: Record<string, { ref: PopupRef<Component, any>; sub?: Subscription }> = {};
  notify$ = new Subject<void>();

  get hasGlobalDialog(): boolean {
    return Object.values(this.refs).some((r) => r.ref.fullScreenDialog);
  }

  get hasDialog(): boolean {
    return !!Object.keys(this.refs).length;
  }

  constructor(private overlay: Overlay, private injector: Injector) {}

  /** This fn enriches and simplify angular cdk-overlay. The params are inferred from there.
   *  **Connected Popup** - to a point, element or etc. Relatively positioned to something.
   *  **Global Popup** - Relative to the whole screen. Center the component and the top start will be after the first 1/4 of the screen
   * @param options.preventUserEvents - Prevents scroll (`wheel`) & keyboard (`keydown`)
   * @param options.closeOnClickOut  - Should the popup close on click out. On global popups defaults to false
   */
  open<T, C>(
    positionOrigin: FlexibleConnectedPositionStrategyOrigin | GlobalPosition | 'center',
    component: ComponentType<T>,
    data: C,
    {
      position,
      backdropStyle,
      preventUserEvents,
      closeOnClickOut,
      fullScreenDialog,
      preserveWidthRatio,
      hasBackdrop,
      panelClass,
      ...config
    }: PopUpOptions = {
      position: 'left',
      backdropStyle: 'clear',
      preventUserEvents: false,
      closeOnClickOut: true,
    }
  ): PopupRef<T, C> {
    const positionStrategy = this.buildPositionStrategy(positionOrigin, position);
    const _hasBackdrop = hasBackdrop === false ? false : true;
    const overlayRef = this.overlay.create({
      positionStrategy,
      panelClass,
      hasBackdrop: _hasBackdrop,
      backdropClass: backdropStyle ? `${backdropStyle}-backdrop` : null,
      ...config,
    });

    const popupRef = new PopupRef<T, C>(overlayRef, data, fullScreenDialog);

    const inc = Injector.create({
      providers: [{ provide: PopupRef, useValue: popupRef }],
      parent: this.injector,
    });
    const popupComponent = new ComponentPortal(component, null, inc);

    const { location, instance } = overlayRef.attach(popupComponent);
    popupRef.panelRef = location;
    popupRef.compInstance = instance;

    const id = uuid.v4();
    this.refs[id] = { ref: popupRef, sub: new Subscription() };
    if (preventUserEvents) {
      this.refs[id].sub.add(this.preventUserEvents(id));
    }

    if (preserveWidthRatio) {
      let element: HTMLElement;

      if (positionOrigin instanceof ElementRef) element = positionOrigin.nativeElement;

      if (positionOrigin instanceof Element) element = positionOrigin as HTMLElement;

      if (element) this.preserveRatio(element, popupRef, preserveWidthRatio);
    }

    if (closeOnClickOut === false) {
      this.refs[id].sub.add(this.preventCloseOnClickOut(id));
    }

    this.notify$.next(null);

    popupRef.destroy$.pipe(take(1)).subscribe(() => {
      // ensure unsubscribe on ref destroy / close
      if (this.refs[id]?.sub) {
        this.refs[id].sub.unsubscribe();
      }

      delete this.refs[id];

      this.notify$.next(null);
    });

    return popupRef;
  }

  private preserveRatio(element: HTMLElement, popUpRef: PopupRef<any, any>, ratio: number) {
    let resizeObs: ResizeObserver = new (<any>window).ResizeObserver(([entry]: ResizeObserverEntry[]) => {
      if (!entry) return;
      const { width } = element.getBoundingClientRect();
      (popUpRef.panelRef.nativeElement as HTMLElement).style.maxWidth = `${width * ratio}px`;
    });

    resizeObs.observe(element);
    popUpRef.destroy$.subscribe(() => {
      resizeObs.disconnect();
      resizeObs = null;
    });
  }

  private buildPositionStrategy(
    positionOrigin: FlexibleConnectedPositionStrategyOrigin | GlobalPosition | 'center',
    position?: PopUpPosition | ConnectedPosition[]
  ): GlobalPositionStrategy | FlexibleConnectedPositionStrategy {
    let strategy: GlobalPositionStrategy | FlexibleConnectedPositionStrategy;

    if (positionOrigin === 'center') {
      strategy = this.overlay
        .position()
        .global()
        .centerVertically()
        .centerHorizontally()
        .top(window.innerHeight / 4 + 'px');
    } else if (isGlobalPosition(positionOrigin)) {
      const addPx = (n: number): string => (n ? Math.round(n) + 'px' : null);

      const { bottom, top, right, left } = positionOrigin;

      strategy = this.overlay.position().global();

      if (bottom) {
        strategy.bottom(addPx(bottom));
      }

      if (right) {
        strategy.right(addPx(right));
      }

      if (left) {
        strategy.left(addPx(left));
      }

      if (top) {
        strategy.top(addPx(top));
      }
    } else {
      strategy = this.overlay
        .position()
        .flexibleConnectedTo(positionOrigin)
        .withPositions(Array.isArray(position) ? position : this.getConnectedPosition(position))
        .withViewportMargin(15);
    }

    return strategy;
  }

  /** Return the events preventer subscription
   * Prevents `keydown` event and `scroll` event
   */
  private preventUserEvents(id: string): Subscription {
    const popup = this.refs[id];
    if (!popup) return;

    // prevent keyboard interaction
    const sub = fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(takeUntil(popup.ref.destroy$))
      .subscribe((event) => (event.returnValue = false));

    // prevent scroll
    sub.add(
      fromEvent<MouseEvent>(document, 'wheel', { passive: false })
        .pipe(takeUntil(popup.ref.destroy$))
        .subscribe((event) => (event.returnValue = false))
    );

    return sub;
  }

  private preventCloseOnClickOut(id: string): Subscription {
    const popup = this.refs[id];
    if (!popup) return;

    return popup.ref.overlayRef
      .outsidePointerEvents()
      .pipe(takeUntil(popup.ref.destroy$))
      .subscribe((event) => {
        event.stopPropagation();
        event.preventDefault();
      });
  }

  private getConnectedPosition(position: PopUpPosition): ConnectedPosition[] {
    switch (position) {
      case 'below': {
        return [{ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'top', offsetY: 20 }];
      }
      case 'above': {
        return [
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
            offsetY: -16,
          },
        ];
      }
      case 'right': {
        return [
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
        ];
      }
      case 'left': {
        return [
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
        ];
      }
    }
  }
}
