<div class="collections-container">
  <ng-container *ngIf="emptyState">
    <ng-container *ngTemplateOutlet="contentType === 'collections' ? collectionsEmptyStateRef : wikiEmptyStateRef"></ng-container>
  </ng-container>

  <div *ngIf="collections?.length === 0 && displayContext?.searchCompleted && !emptyState" class="no-results">
    <h1 class="no-results-title headline-1">{{ collectionContent.noResults }}</h1>
    <q class="headline-1 bold" *ngIf="query">
      {{ query | truncate : 30 }}
    </q>
  </div>
  <div class="collections-header" *ngIf="!emptyState">
    <div class="collections-header-content">
      <div class="results-header-title">{{ title | titlecase }}</div>
      <div class="collections-header-buttons" *ngIf="!isLauncher">
        <u-input
          #smallInputSearch
          class="small-input-search"
          data-cy="collection-search-input"
          [inlineExtraIcon]="{ type: 'font', value: 'icon-search-icon-fixed' }"
          [placeholder]="'Search'"
          [isSearchInput]="true"
          (onChange)="updateQuery($event)"
          [model]="hubService.inputQuery"
          (onFocus)="smallInputSearchFocus = true"
          (onBlur)="smallInputSearchFocus = false"
          [extraIcon]="!smallInputSearchFocus && !hubService.inputQuery ? { type: 'font', value: 'icon-slash' } : null"
          [ngClass]="{ 'small-input-blur': !smallInputSearchFocus && hubService.inputQuery }"
          [ignoreEnter]="true"
        ></u-input>
        <u-button
          (click)="changeLayout()"
          type="secondary"
          elementSize="small"
          [fontIcon]="nextLayoutMode === 'list' ? 'list-view' : 'gallery-view'"
          [pTooltip]="nextLayoutMode === 'list' ? 'List layout' : 'Gallery layout'"
          tooltipPosition="top"
          tooltipStyleClass="u-tooltip"
          [styles]="{ fontSize: '17px', width: '32px' }"
        >
        </u-button>
        <u-button
          #sortButtonRef
          class="sort-icon"
          elementSize="small"
          fontIcon="sort-list-down"
          type="secondary"
          [styles]="{ fontSize: '14px', height: '32px' }"
          [class.sort-active]="!!sortService?.popupSortRef"
          (click)="openSort(sortButtonRef)"
          [class.active]="searchParamsService.getSort()"
        ></u-button>
        <u-button
          *ngIf="!isLauncher"
          #openCollectionButtonRef
          class="add-icon"
          data-cy="add-collection"
          elementSize="small"
          type="primary"
          fontIcon="plus"
          [iconStyles]="{ 'font-weight': 'bold' }"
          [styles]="{ fontSize: '14px' }"
          (click)="openNewCollectionPopup(openCollectionButtonRef)"
          [pTooltip]="collectionContent.createCollection"
          [label]="smallScreen ? '' : contentType === 'collections' ? collectionContent.newCollection : collectionContent.createNew"
          tooltipPosition="top"
          tooltipStyleClass="u-tooltip"
        ></u-button>
      </div>
    </div>
    <results-filters-box
      id="filters"
      [filters]="filters"
      [enableClearAll]="true"
      (filterChange)="onFilterChange($event)"
      (clearAll)="clearAllFilters()"
      [separateLayout]="true"
      [fullDetailIds]="['shared-with-me']"
    ></results-filters-box>
  </div>

  <ng-container *ngIf="!emptyState">
    <div class="scrollbar-wrapper" #contextContainer>
      <ng-scrollbar *ngIf="collections?.length > 0" visibility="hover">
        <cdk-virtual-scroll-viewport [itemSize]="40" ngScrollbarView smoothScroll scrollViewport appendOnly [class.virtual-scroll]="true">
          <div
            class="grid collections-items"
            [class.grid-cards]="collectionItemViewMode === 'card'"
            [class.flex-gap]="collectionItemViewMode === 'list'"
          >
            <ng-container *ngIf="loaded; else loading">
              <result-header
                [class.container-header]="collectionItemViewMode === 'card'"
                *ngIf="showHeader && header"
                [model]="header"
              ></result-header>
              <ng-container *cdkVirtualFor="let collection of collections; let i = index; trackBy: trackByCollection">
                <collection-item
                  #collectionItem
                  class="result-item"
                  [attr.data-index]="i"
                  [collection]="collection"
                  [selected]="i === selectedIndex"
                  [viewMode]="collectionItemViewMode"
                  (click)="selectedIndex = i"
                  (mouseover)="onHoverItem(i)"
                  (tagClicked)="onTagClick($event)"
                  [class.disabled]="collectionsService.deleteCollectionId === collection.id"
                  [class.viewPageItem]="[ALL_CARDS_ID, ALL_DRAFTS_ID].includes(collection.id)"
                  (duplicateCollection)="duplicateMode = true"
                >
                </collection-item>
                <result-header *ngIf="i === 1 && showSubHeader && header" [model]="header" class="wikis-header"></result-header>
              </ng-container>
            </ng-container>
          </div>
        </cdk-virtual-scroll-viewport>
        <gradient-overflow *ngIf="!isLauncher"></gradient-overflow>
      </ng-scrollbar>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <ng-container *ngIf="!isLauncher; else resultGhost">
    <collection-ghost *ngFor="let i of 10 | fill"></collection-ghost>
  </ng-container>
</ng-template>

<ng-template #resultGhost>
  <result-ghost *ngFor="let i of 10 | fill"></result-ghost>
</ng-template>

<ng-template #collectionsEmptyStateRef>
  <collections-empty-state></collections-empty-state>
</ng-template>

<ng-template #wikiEmptyStateRef>
  <wiki-empty-state></wiki-empty-state>
</ng-template>
