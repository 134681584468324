<u-icon
  *ngIf="multiSelectIcon"
  [styles]="{ fontSize: '14px', width: '16px' }"
  [model]="multiSelectIcon"
  class="short-selected-icon"
></u-icon>
<div *ngIf="placeholder">
  {{ placeholder }}
</div>
<div *ngIf="countSelected" class="p-multiselect-label round-count-selected">
  {{ countSelected }}
</div>
