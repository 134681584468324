<div @fade class="mail-preview-container-wrapper">
  <mail-preview
    class="mail-preview-container-content"
    [model]="model"
    [size]="size"
    [isLauncher]="isLauncher"
    [telemetrySearch]="telemetrySearch"
    (loaded)="loaded.emit()"
  ></mail-preview>
</div>
