import { Injectable } from '@angular/core';
import { NavigationBehaviorOptions } from '@angular/router';
import { Accounts, Commands, Experiences, Permissions, Results, SessionInfo, Workspace } from '@local/client-contracts';
import { EntityIcon, EntityIconType } from '@local/client-contracts/src/style';
import { AssistantsIconsConst, CreateExperienceResponse, observable } from '@local/common';
import {
  generateUniqTitle,
  getAssistantDisplayName,
  getAssistantGroupName,
  getAssistantSort,
  getAssistantTitle,
  isEmbed,
} from '@local/common-web';
import { capitalCase } from '@local/ts-infra';
import { PopupRef, PopupService } from '@local/ui-infra';
import { DisplaySearchFilterValue, Filter } from '@shared/components/filters/models';
import { DateFormat } from '@shared/consts';
import { EventsService, LogService, ServicesRpcService } from '@shared/services';
import { AccountsService } from '@shared/services/accounts.service';
import { BreadcrumbsService } from '@shared/services/breadcrumbs.service';
import { RouterService } from '@shared/services/router.service';
import { SessionStorageService } from '@shared/services/session-storage.service';
import { SessionService } from '@shared/services/session.service';
import { SlackBotService } from '@shared/services/slack-bot.service';
import { TitleBarService } from '@shared/services/title-bar.service';
import { generateFullPrefixedURL } from '@shared/utils';
import { getTimeFromNowInText } from '@shared/utils/date-format.util';
import { Logger } from '@unleash-tech/js-logger';
import { cloneDeep, isEqual, omit } from 'lodash';
import moment from 'moment';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  distinctUntilChanged,
  firstValueFrom,
  map,
  take,
  takeUntil,
} from 'rxjs';
import { AppPopupComponent, AppPopupData } from '../components/app-popup/app-popup.component';
import {
  ScopePermissionsOptions,
  ShareOptionsPermissionsModel,
} from '../components/share-options-permissions/share-options-permissions.component';
import { AvatarItemModel } from '../models/avatar-item.model';
import { ResultContextMenuItem, TelemetryTrigger } from '../views';
import { assistantContent } from '../views/assistant-page/helpers/assistant.content';
import { CollectionsService } from './collections.service';
import { CommandsService } from './commands/commands.service';
import { HubService } from './hub.service';
import { ExperiencesRpcInvoker } from './invokers/experiences-rpc-invoker';
import { ShareOptionsService } from './share-options.service';

@Injectable()
export class ExperiencesService {
  readonly SHARE_OPTIONS_ASSISTANTS: ScopePermissionsOptions[] = [
    {
      label: 'Full access',
      subLabel: 'Edit preferences',
      value: 'write',
    },
    {
      label: 'Limited access',
      subLabel: 'View preferences',
      value: 'read',
    },
  ];
  private logger: Logger;
  private service: Experiences.Service;
  private _currentAssistant$ = new BehaviorSubject<Experiences.ExperienceItem>(null);
  private _assistantPageFilters$ = new BehaviorSubject<Filter[]>(null);
  private _all$ = new ReplaySubject<Experiences.ExperienceItem[]>(1);
  private allExperiences: Experiences.ExperienceItem[] = [];
  private sessionInfo: SessionInfo;
  private areYouSurePopupRef: PopupRef<AppPopupComponent, AppPopupData> = null;
  private readonly SHARE_OPTIONS_GENERAL_ASSISTANT: ScopePermissionsOptions[] = [
    {
      label: 'Full access',
      subLabel: 'Edit preferences & interact',
      value: 'write',
    },
    {
      label: 'Limited access',
      subLabel: 'View preferences & interact',
      value: 'read',
    },
    {
      label: 'Interact',
      subLabel: 'Can search using the assistant',
      value: 'search',
    },
  ];
  private readonly ARE_YOU_SURE_POPUP: AppPopupData = {
    showButtons: true,
    content: {
      title: 'Are you sure you want to delete <br> this assistant?',
      secondaryButton: 'Cancel',
      primaryButton: 'Delete',
    },
    titleStyle: { 'font-weight': 'normal' },
    messageStyle: { fontSize: '12px' },
  };
  private readonly SHARE_POPUP_MESSAGE = ' This will affect all members it is shared with';
  private readonly ASSISTANTS_LAYOUT_MODE_ENTRY = 'assistantsLayoutMode';
  private readonly isEmbed = isEmbed();
  private readonly DATA_SOURCES_PROPERTIES = ['filtersDataSources', 'modifiedAt', 'modifiedBy'];
  private readonly ASSISTANTS_URL = '/assistants';
  private _assistantsLayoutMode$ = new BehaviorSubject<Results.LayoutType>(null);
  private isOwnerOrAdmin: boolean;
  private _disableCreateSlackAssistant$ = new BehaviorSubject<boolean>(null);
  private _onDeletedAssistantId$ = new BehaviorSubject<string>(null);
  private _slackWorkspaces: Set<string> = new Set();

  @observable
  get currentAssistant$(): BehaviorSubject<Experiences.ExperienceItem> {
    return this._currentAssistant$;
  }

  private set currentAssistant(assistant: Experiences.ExperienceItem) {
    this._currentAssistant$.next(assistant);
  }

  get currentAssistant(): Experiences.ExperienceItem {
    return this._currentAssistant$.value;
  }

  @observable
  get disableCreateSlackAssistant$(): Observable<boolean> {
    return this._disableCreateSlackAssistant$.asObservable();
  }

  @observable
  get assistantPageFilters$(): Observable<Filter[]> {
    return this._assistantPageFilters$.asObservable();
  }

  @observable
  get assistantsLayoutMode$(): Observable<Results.LayoutType> {
    return this._assistantsLayoutMode$.asObservable();
  }

  @observable
  get onDeletedAssistantId$(): Observable<string> {
    return this._onDeletedAssistantId$.asObservable();
  }

  constructor(
    services: ServicesRpcService,
    logger: LogService,
    private sessionService: SessionService,
    private accountsService: AccountsService,
    private breadcrumbsService: BreadcrumbsService,
    private eventsService: EventsService,
    private routerService: RouterService,
    private commandsService: CommandsService,
    private titleBarService: TitleBarService,
    private collectionService: CollectionsService,
    private popupService: PopupService,
    private shareOptionsService: ShareOptionsService,
    private sessionStorageService: SessionStorageService,
    private slackBotService: SlackBotService,
    private hubService: HubService
  ) {
    this.logger = logger.scope('AssistantService');
    this.service = services.invokeWith(ExperiencesRpcInvoker, 'experiences');
    combineLatest([this.visible$, this.routerService.activeRoute$]).subscribe(([assistants, currentRoute]) => {
      const assistantRoute = currentRoute?.snapshot?.data?.assistant as Experiences.ExperienceItem;

      if (!assistantRoute || !assistants) {
        this.currentAssistant = null;
        return;
      }

      if (!currentRoute) {
        return;
      }

      const updatedAssistant = assistants.find((b) => b.id === assistantRoute.id);

      if (!updatedAssistant) {
        this.currentAssistant = null;
        return;
      }

      const breadcrumbIcon = AssistantsIconsConst[updatedAssistant.experienceType] as EntityIcon<EntityIconType>;
      this.breadcrumbsService.items = [
        {
          title: 'Assistants',
          path: 'assistants',
          icon: {
            type: 'font-icon',
            value: 'icon-assistant',
          },
        },
        {
          title: getAssistantTitle(updatedAssistant),
          path: updatedAssistant.id,
          icon: updatedAssistant.experienceType === 'general' ? null : breadcrumbIcon,
        },
      ];

      const assistantChanged = this.currentAssistant?.id !== updatedAssistant?.id;
      if (assistantChanged || this.currentAssistant?.permissionRole !== updatedAssistant?.permissionRole) {
        this.currentAssistant = { ...updatedAssistant };
        this.titleBarService.active = getAssistantTitle(updatedAssistant);
      }

      if (this.currentAssistant?.modifiedAt !== updatedAssistant?.modifiedAt) {
        this.currentAssistant = {
          ...this.currentAssistant,
          modifiedBy: updatedAssistant.modifiedBy,
          modifiedAt: updatedAssistant.modifiedAt,
        };
      }

      if (!this.shareOptionsService.compareShareOptions(this.currentAssistant.shareOptions, updatedAssistant?.shareOptions)) {
        this.currentAssistant = {
          ...this.currentAssistant,
          shareOptions: updatedAssistant.shareOptions,
          isShared: updatedAssistant.isShared,
          effectiveScopes: updatedAssistant.effectiveScopes,
          modifiedAt: updatedAssistant.modifiedAt,
          modifiedBy: updatedAssistant.modifiedBy,
        };
      }
    });

    this.service.all$.subscribe((all) => {
      this.allExperiences = all;
      this._all$.next(all);
    });

    this.initSession();
    this.initAssistantPageFilters();

    this.sessionService.accountChanged$.subscribe((s) => {
      if (!s) {
        this._assistantsLayoutMode$.next('list');
      } else {
        this.loadAssistantsLayoutModeRoaming();
      }
    });
  }

  @observable
  get all$(): Observable<Experiences.ExperienceItem[]> {
    return this._all$;
  }

  @observable
  get visible$(): Observable<Experiences.ExperienceItem[]> {
    return this.all$.pipe(map((all) => all?.filter((e) => e.effectiveScopes?.find((ef) => ['write', 'read'].includes(ef?.scope)))));
  }

  get workspace(): Workspace.Workspace {
    return this.sessionInfo?.workspace;
  }

  get slackWorkspaces(): Set<string> {
    return this._slackWorkspaces;
  }

  private initSession(): void {
    combineLatest([this.sessionService.current$, this.accountsService.all$]).subscribe({
      next: ([sessionInfo, accounts]) => {
        if (!sessionInfo) {
          return;
        }
        this.sessionInfo = cloneDeep(sessionInfo);
        if (this.sessionInfo.workspace) {
          this.sessionInfo.workspace.accounts = accounts;
        }
        this.isOwnerOrAdmin = this.workspace?.isAdmin || this.workspace?.isOwner;
      },
    });
  }

  async initSlackBotInstallInfo() {
    if (!this.checkAssistantsFlags('slack')) {
      this._disableCreateSlackAssistant$.next(true);
      return;
    }
    if (this.isOwnerOrAdmin) {
      this._disableCreateSlackAssistant$.next(false);
      return;
    }
    const installations = (await this.slackBotService.getWorkspaceInstallation())?.installations;
    this._disableCreateSlackAssistant$.next(!!installations[0]?.strictInvites);
  }

  checkAssistantsFlags(assistantType: string): boolean {
    const assistantFlags = this.workspace?.settings.assistant?.configs;
    const assistantConfig = assistantFlags.find((a) => a.type === capitalCase(assistantType));
    return assistantConfig?.enabled;
  }

  async getExperience(id: string, useCache?: boolean) {
    return this.service.getExperience(id, useCache);
  }

  refresh(): Promise<void> {
    return this.service.refresh();
  }

  isOwner(assistant: Experiences.ExperienceItem): boolean {
    return assistant?.createdBy === this.workspace?.accountId;
  }

  isLastModifiedByCurrentUser(assistant: Experiences.ExperienceItem): boolean {
    return assistant?.modifiedBy === this.workspace?.accountId;
  }

  update(assistant: Experiences.ExperienceItem, unavailableFilterSourceIds?: number[]) {
    return this.service.updateById(assistant, unavailableFilterSourceIds);
  }

  updateShareOptions(id: string, shareOptions: Permissions.ShareOptions, type: Experiences.ExperienceType): Promise<void> {
    return this.service.updateShareOptions(id, shareOptions, type);
  }

  private async loadAssistantsLayoutModeRoaming() {
    return this.sessionStorageService
      .getStore('roaming', 'account')
      .entry<Results.LayoutType>(this.ASSISTANTS_LAYOUT_MODE_ENTRY)
      .get()
      .then((layout: Results.LayoutType) => {
        this._assistantsLayoutMode$.next(layout);
      });
  }

  async saveAssistantsLayoutModeRoaming(layoutMode: Results.LayoutType) {
    this.sessionStorageService
      .getStore('roaming', 'account')
      .entry<Results.LayoutType>(this.ASSISTANTS_LAYOUT_MODE_ENTRY)
      .set(layoutMode)
      .then(() => {
        this._assistantsLayoutMode$.next(layoutMode);
      });
  }

  //shared functions
  getLastModified(assistant: Experiences.ExperienceItem): AvatarItemModel {
    const user = this.isLastModifiedByCurrentUser(assistant)
      ? this.sessionInfo?.user
      : this.workspace?.accounts?.find((a) => a.id === assistant?.modifiedBy);
    const name = `${capitalCase(user?.name || '')}`;
    return { name, imgUrl: user?.picture, email: user?.email, isNameFromEmail: user?.isNameFromEmail };
  }

  async getAssistantTimePhrases(assistant: Experiences.ExperienceItem) {
    const displayModifiedTime = getTimeFromNowInText(assistant.modifiedAt).toLowerCase();
    const preDisplayModifiedTime =
      assistant.modifiedAt === assistant.createdAt
        ? capitalCase(assistantContent.created) + ' '
        : capitalCase(assistantContent.updated) + ' ';
    let fullDetails = moment(assistant.modifiedAt).format(DateFormat.FULL_DATE_HOURS_A);
    let startModifiedPhrase: string;
    if (this.isLastModifiedByCurrentUser(assistant)) {
      startModifiedPhrase = 'You';
    } else {
      const user = this.getLastModified(assistant);
      startModifiedPhrase = user.name;
      if (user.isNameFromEmail) {
        fullDetails = `${user.email} - ${fullDetails}`;
      }
    }
    return {
      displayModifiedTime,
      preDisplayModifiedTime,
      fullDetails,
      startModifiedPhrase,
    };
  }

  async onContextMenuCommand(
    item: ResultContextMenuItem,
    trigger: TelemetryTrigger,
    assistant: Experiences.ExperienceItem,
    currentLocation: string
  ) {
    this.eventsService.event('assistant.assistant_actions', {
      target: item.id,
      label: trigger === 'context_menu_click' ? 'mouse_click' : 'keyboard',
      location: { title: currentLocation },
    });

    this.eventsService.event('assistant.menu_action', {
      label: item.id,
      location: { title: currentLocation },
    });

    switch (item.command.type) {
      case 'open-assistant':
        this.openAssistant(assistant.id);
        break;
      case 'copy-url':
        this.copyUrl(assistant);
        break;
      case 'delete':
        return this.deleteAssistant(assistant);
      case 'share-assistant':
        this.shareAssistant(assistant);
        break;
      case 'copy-id':
        this.copyAssistantId(assistant.id);
        break;
    }
  }

  private copyAssistantId(assistantId: string) {
    this.commandsService.executeCommand({
      type: 'copy-clipboard',
      value: assistantId,
    } as Commands.CopyClipboard);
  }

  async buildTitle(id: string, title: string, experienceType?: Experiences.ExperienceType): Promise<string> {
    const allAssistant = await firstValueFrom(this.visible$);
    const current = allAssistant.find((b) => b.id === id);
    if (current && current?.properties?.title === title) {
      return title;
    }
    const titles: string[] = allAssistant
      .filter((as) => as.experienceType === experienceType)
      .map((assistant) => getAssistantTitle(assistant));
    return generateUniqTitle(title, titles);
  }

  async openAssistant(assistantId: string, extras?: NavigationBehaviorOptions) {
    const url = `assistant/${assistantId}`;
    this.hubService.openUrl(url, extras);
  }

  async createAssistant(
    assistant?: Experiences.ExperienceItemData,
    unavailableFilterSourceIds?: number[],
    replaceUrl = false
  ): Promise<string> {
    const { experienceType, properties } = assistant;
    this.breadcrumbsService.showGhost = true;
    const title = properties?.title ?? (await this.buildTitle(null, 'Untitled', experienceType));
    let response: CreateExperienceResponse;
    try {
      response = await this.service.create(
        experienceType,
        { ...assistant, properties: { ...properties, title } },
        unavailableFilterSourceIds
      );
      this.openAssistant(response.id, { state: { mode: 'new' }, replaceUrl });
    } catch (error) {
      this.routerService.navigateByUrl('assistants', { replaceUrl });
      this.logger.error('failed to create assistant', error);
    }
    this.breadcrumbsService.showGhost = false;
    return response?.id;
  }

  async createDraftAssistant(experienceType: Experiences.ExperienceType, extras?: NavigationBehaviorOptions) {
    this.breadcrumbsService.showGhost = true;
    const url = `assistant/new/${experienceType}`;
    this.hubService.openUrl(url, extras);
  }

  async saveDraftAssistant(assistant: Experiences.ExperienceItem, unavailableFilterSourceIds?: number[], replaceUrl = false) {
    const { experienceType, properties } = assistant;
    this.breadcrumbsService.showGhost = true;
    const title = properties?.title !== 'Untitled' ? properties?.title : await this.buildTitle(null, 'Untitled', experienceType);
    assistant.properties.title = title;
    try {
      const response = await this.service.create(experienceType, assistant, unavailableFilterSourceIds);
      const url = `assistant/${response.id}`;
      this.routerService.navigateByUrl(url, { replaceUrl });
    } catch (error) {
      this.routerService.navigateByUrl('assistants', { replaceUrl });
      this.logger.error('failed to create assistant', error);
    }
    this.breadcrumbsService.showGhost = false;
    return title;
  }

  deleteAssistant(assistant: Experiences.ExperienceItem, navigate?: boolean) {
    this._onDeletedAssistantId$.next(assistant.id);
    return this.openAreYouSurePopup(assistant, navigate);
  }

  private openAreYouSurePopup(assistant: Experiences.ExperienceItem, navigate?: boolean) {
    if (this.areYouSurePopupRef) {
      this.areYouSurePopupRef.destroy();
    }
    this.areYouSurePopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      assistant.isShared ? { ...this.ARE_YOU_SURE_POPUP, message: this.SHARE_POPUP_MESSAGE, titleStyle: {} } : this.ARE_YOU_SURE_POPUP
    );
    this.areYouSurePopupRef.compInstance.primaryButton.pipe(takeUntil(this.areYouSurePopupRef.destroy$)).subscribe(async () => {
      if (navigate) {
        this.hubService.openUrl(this.ASSISTANTS_URL);
      }
      await this.service.delete(assistant.id);
    });
    this.areYouSurePopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.areYouSurePopupRef = null;
    });
    return this.areYouSurePopupRef;
  }

  shareAssistant(experience: Experiences.ExperienceItem) {
    const updatedExperience = this.allExperiences?.find((exp) => exp.id === experience.id);
    if (!updatedExperience) {
      return;
    }
    const title = getAssistantTitle(updatedExperience);
    const writePermissions = experience.shareOptions?.permissions?.find((p) => p.scope === 'write');
    const hasRolePermission = writePermissions?.roles?.length;
    const shareOptionsModel: ShareOptionsPermissionsModel = {
      accountId: experience.createdBy,
      isShared: experience.isShared,
      shareOptions: experience.shareOptions,
      title: `Share "${title}" Assistant`,
      displayPermissionOption: true,
      selectedOwnerOrAdminByRole: hasRolePermission
        ? {
          enabled: true,
          label: 'Full access',
          updateRolesWhenSave: true,
        }
        : null,
      telemetryName: 'assistant_share',
      isOwner: this.isOwner(experience),
      inputPlaceholder: 'Name or email',
      scopePermissionOptions:
        experience.experienceType === 'general' ? this.SHARE_OPTIONS_GENERAL_ASSISTANT : this.SHARE_OPTIONS_ASSISTANTS,
      defaultScopePermission: experience.experienceType === 'general' ? 'search' : 'read',
    };
    this.shareOptionsService.openShareOptionPopup(shareOptionsModel, async (target, shareOptions) => {
      await this.updateShareOptions(experience.id, shareOptions, experience.experienceType);
    });
  }

  getSharedObjects(accountId: string): Promise<Experiences.ExperienceSharedObjects> {
    return this.service.getSharedObjects(accountId);
  }

  copyUrl(assistant: Experiences.ExperienceItem) {
    this.commandsService.executeCommand({
      type: 'copy-clipboard',
      value: generateFullPrefixedURL(`/assistant/${assistant.id}`, 'path'),
    } as Commands.CopyClipboard);
  }

  //@@@@@@@FILTERS

  initAssistantPageFilters(typeFilter?: string) {
    combineLatest([this.visible$, this.accountsService.all$.pipe(distinctUntilChanged()), this.sessionService.current$]).subscribe(
      async ([assistants, accounts, sessionInfo]) => {
        if (!assistants) return;
        if (!sessionInfo) return;

        const createdByMap = new Set();
        const typesMap = new Map();
        const teamsMap = new Set<string>();

        assistants.forEach((c) => {
          if (!createdByMap.has(c.createdBy)) {
            if (!typeFilter) {
              createdByMap.add(c.createdBy);
            } else if (typeFilter === c.experienceType) {
              createdByMap.add(c.createdBy);
            }
          }
          if (!typesMap.has(c.experienceType)) {
            typesMap.set(c.experienceType, getAssistantGroupName(c.experienceType));
          }
          if (c.experienceType === 'teams') {
            const teamName = c.properties?.teamName;
            if (teamName && !teamsMap.has(teamName)) {
              teamsMap.add(teamName);
            }
          }
          if (c.experienceType === 'slack' && c.properties?.workspaceName) {
            this._slackWorkspaces.add(c.properties.workspaceName);
          }
        });

        let meElement = null;
        let isNotMeAdded = false;
        const createdBy = [];
        [...createdByMap.keys()].forEach((c) => {
          const elm = accounts?.find((w) => w.id === c);
          if (elm) {
            if (elm?.userId !== sessionInfo?.user.id) {
              if (!isNotMeAdded) {
                createdBy.unshift({
                  id: 'shared-with-me',
                  name: 'Not me',
                });
                isNotMeAdded = true;
              }
              createdBy.push(elm);
            } else if (elm?.userId === sessionInfo?.user.id) {
              meElement = { ...elm };
              meElement.name = 'Me';
            }
          }
        });
        if (meElement) {
          createdBy.unshift(meElement);
        }

        const filters: Filter[] = await this.createCollectionPageFilters(createdBy, [...typesMap.values()], [...teamsMap]);
        this._assistantPageFilters$.next(filters);
      }
    );
  }

  async createCollectionPageFilters(createdBy: Accounts.WorkspaceAccount[], types: string[], teams: string[]): Promise<Filter[]> {
    const filters: Filter[] = [];

    const createdByName = 'assistant-createdBy';
    filters.push({
      type: 'post',
      name: createdByName,
      title: 'Created by',
      icon: { type: 'font-icon', value: 'icon-user-circle' },
      picker: 'multi-select',
      viewDetails: {
        isTwoLine: true,
        showClearAll: true,
      },
      sort: 'none',
      values: createdBy?.map(
        (value) =>
          <DisplaySearchFilterValue>{
            id: value?.id,
            value: value?.id,
            title: value?.name,
            subtitle: value?.email || '',
            filterName: createdByName,
          }
      ),
      disabled: false,
    });

    types = types?.sort((a, b) => {
      return getAssistantSort(a, b);
    });

    const typesName = 'assistant-type';
    filters.push({
      type: 'post',
      name: typesName,
      title: 'Type',
      icon: { type: 'font-icon', value: 'icon-assistant' },
      picker: 'multi-select',
      values: types.map(
        (value) =>
          <DisplaySearchFilterValue>{
            id: value,
            value: value,
            title: getAssistantDisplayName(value.toLowerCase() as Experiences.ExperienceType),
            subtitle: null,
            filterName: typesName,
            icon: AssistantsIconsConst[getAssistantGroupName(value, 'lower')],
          }
      ),
      disabled: false,
      viewDetails: {
        showItemIcon: true,
        isFullDetailLine: true,
        showSelectedItemIcons: true,
        oneValue: true,
        noCheckbox: true,
      },
    });

    const teamName = 'assistant-team';
    filters.push({
      type: 'post',
      name: teamName,
      title: 'Team',
      icon: { type: 'font-icon', value: 'icon-team' },
      picker: 'multi-select',
      values: teams.map(
        (value) =>
          <DisplaySearchFilterValue>{
            id: value,
            value: value,
            title: value,
            subtitle: null,
            filterName: teamName,
          }
      ),
      disabled: false,
      viewDetails: {
        showItemIcon: true,
        isFullDetailLine: true,
        showSelectedItemIcons: true,
      },
    });

    if (this.slackWorkspaces.size > 1) {
      const workspaceName = 'assistant-slack-workspace';
      filters.push({
        type: 'post',
        name: workspaceName,
        title: 'Workspace',
        icon: { type: 'font-icon', value: 'icon-buildings' },
        picker: 'multi-select',
        values: [...this.slackWorkspaces].map(
          (value) =>
            <DisplaySearchFilterValue>{
              id: value,
              value: value,
              title: value,
              subtitle: null,
              filterName: workspaceName,
            }
        ),
        disabled: false,
        viewDetails: {
          showItemIcon: true,
          isFullDetailLine: true,
          showSelectedItemIcons: true,
        },
      });
    }

    return filters;
  }

  async findWiki(name: string): Promise<string> {
    if (!name) {
      return;
    }
    const experience = this.allExperiences?.find((exp) => exp.properties.subdomain === name);
    if (!experience) {
      return;
    }

    const collectionDataSourceFilters = experience.dataSources
      .find((ds) => ds.type === 'collections')
      ?.items?.find((f) => f.filter?.appId?.includes('wiki'));
    if (!collectionDataSourceFilters) {
      return;
    }
    const collections = await firstValueFrom(this.collectionService.all$);

    const wiki = collections?.find((c) => c.title === name && c.kind === 'Wiki');
    if (!wiki) {
      return;
    }

    if (collectionDataSourceFilters.filter?.collectionId?.includes(wiki.id)) {
      return wiki.id;
    }
  }

  compareWithoutDataSources(prev: any, current: any) {
    const prevItem = omit(prev, this.DATA_SOURCES_PROPERTIES);
    const currentItem = omit(current, this.DATA_SOURCES_PROPERTIES);

    return isEqual(prevItem, currentItem);
  }
}
