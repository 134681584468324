<p-tree
  [value]="value"
  [selectionMode]="selectionMode"
  [(selection)]="selection"
  class="u-tree"
  (onNodeSelect)="onNodeSelect.emit($event)"
  (onNodeUnselect)="onNodeUnselect.emit($event)"
  (selectionChange)="selectionChange.emit($event)"
  (onNodeExpand)="nodeExpand($event)"
  (onNodeCollapse)="nodeCollapse($event)"
  [loading]="loading"
  [virtualScroll]="virtualScroll"
  [virtualScrollItemSize]="virtualNodeHeight"
  [scrollHeight]="scrollHeight"
  [style]="styles"
  [filter]="filter"
  [filterPlaceholder]="filterPlaceholder"
  [draggableNodes]="dragAndDrop"
  [droppableNodes]="dragAndDrop"
  [draggableScope]="dragAndDropScope"
  [droppableScope]="dragAndDropScope"
  [validateDrop]="true"
  (onFilter)="onFilter($event)"
  (onNodeDrop)="nodeDrop($event)"
  [ngClass]="{ 'disabled-button': disabledButton }"
  #tree
>
  <ng-template let-node pTemplate="default">
    <ng-container *ngTemplateOutlet="nodeTemplate || defaultTemplate; context: { $implicit: node }"></ng-container>
  </ng-template>
  <ng-template let-check pTemplate="checkboxicon">
    <div class="checkbox-icon-container">
      <u-icon *ngIf="check" class="p-checkbox-icon" [model]="{ type: 'font', value: 'icon-check' }" [styles]="{ fontSize: '8px' }">
      </u-icon>
    </div>
    <u-icon
      *ngIf="dragAndDrop"
      class="dragging-icon"
      [model]="{ type: 'font', value: 'icon-dragging-ind' }"
      [styles]="{ fontSize: '12px' }"
      pTooltip="Drag to move"
      tooltipPosition="bottom"
      tooltipStyleClass="u-tooltip"
    >
    </u-icon>
  </ng-template>
  <ng-template let-toggle pTemplate="togglericon">
    <u-icon
      [model]="{ type: 'font', value: toggle ? 'icon-tree-expended' : 'icon-tree-collapse' }"
      [styles]="{ fontSize: '36px', display: 'block' }"
    >
    </u-icon>
  </ng-template>
  <ng-template pTemplate="empty">
    <ng-container *ngTemplateOutlet="emptyTemplate || defaultEmptyTemplate"></ng-container>
  </ng-template>
</p-tree>

<ng-template #defaultTemplate let-node>
  <div style="display: flex">
    <ng-container *ngIf="node?.src && isEmoji(node.src.lightUrl); else noEmoji">
      <u-icon iconCtx="url" class="result-icon-logo no-drag" [model]="{ type: 'img', value: node.src }"></u-icon>
    </ng-container>
    <ng-template #noEmoji>
      <u-icon *ngIf="node?.src" [model]="{ type: 'img', value: node?.src }" class="node-img"></u-icon>
    </ng-template>
    <div class="label-title" [class.is-focus-node]="node.isFocus" [innerHtml]="node.label | highlightSearch : markedText"></div>
    <div *ngIf="node.description" class="label-description" [innerHtml]="node.description | highlightSearch : markedText"></div>
  </div>
</ng-template>

<ng-template #defaultEmptyTemplate> {{ emptyMessage }} </ng-template>
