import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, TrackByFunction } from '@angular/core';
import { Applications, Links } from '@local/client-contracts';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApplicationsService } from '@shared/services/applications.service';
import { LinksService } from '@shared/services/links.service';
import { SessionService } from '@shared/services/session.service';
import { isEqual, orderBy } from 'lodash';
import { combineLatest } from 'rxjs';
import { WorkspacesService } from 'src/app/bar/services';
import { HubService } from 'src/app/bar/services/hub.service';
import { AppsFilter } from '../apps-filter';

@UntilDestroy()
@Component({
  selector: 'app-list',
  templateUrl: './app-list.component.html',
  styleUrls: ['./app-list.component.scss', '../shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppListComponent implements OnInit {
  @Input() title: string;
  @Input() titleEnd: string;
  @Input() telemetryLocation: string;
  private _items: Applications.DisplayItem[] = [];
  get items(): Applications.DisplayItem[] {
    return this._items;
  }
  @Input() set items(value: Applications.DisplayItem[]) {
    this._items = orderBy(value, [v => v.name], ['asc']);
  }
  @Input() filter?: AppsFilter;
  @Input() searchDetector: string;
  @Input() mode: 'side' | undefined;

  itemLinksMap: Record<string, Links.DisplayItem[]> = {};
  isInternalUser: boolean;

  private _links: Links.DisplayItem[];
  private isOwnerOrAdmin: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private linksService: LinksService,
    private applicationService: ApplicationsService,
    private session: SessionService,
    private hubService: HubService,
    private workspaceService: WorkspacesService
  ) {}

  ngOnInit(): void {
    combineLatest([this.linksService.all$, this.applicationService.all$])
      .pipe(untilDestroyed(this))
      .subscribe(([links, _]) => {
        this.updateLinks(links);
      });
    this.session.current$.pipe(untilDestroyed(this)).subscribe((s) => {
      this.isInternalUser = s?.user.internal;
    });
    this.hubService.fullFocus = true;
    this.hubService.autoFocus = true;

    document.addEventListener('visibilitychange', () => {
      this.linksService.refreshAll();
    });
    this.workspaceService.ownerOrAdmin$.pipe(untilDestroyed(this)).subscribe((s) => {
      this.isOwnerOrAdmin = s;
    });
  }

  updateLinks(links: Links.DisplayItem[]) {
    if (this._links && isEqual(this._links, links)) return;

    this._links = links;
    const next = {};

    for (let link of links) {
      const { appId } = link;
      next[appId] = next[appId] || [];
      next[appId].push(link);
    }
    this.itemLinksMap = { ...this.itemLinksMap, ...next };
    this.cdr.markForCheck();
  }

  trackItems: TrackByFunction<Applications.DisplayItem> = (index: number, item: Applications.DisplayItem): string => `${item.id}_${index}`;

  isDisableItem(item: Applications.DisplayItem): boolean {
    const disableByWorkSpace = item.state.workspaceDisabled && !this.isOwnerOrAdmin;
    const disableState: boolean = (item.state.disabled && !this.isInternalUser) || disableByWorkSpace;
    return disableState && !item?.links?.length;
  }
}
