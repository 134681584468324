import { BrowserExtensions } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class SettingsRpcInvoker implements BrowserExtensions.Service {
  @invoker
  get current$(): Observable<BrowserExtensions.ExtensionSettings> {
    return;
  }
  @invoker
  set(v: BrowserExtensions.ExtensionSettings): Promise<void> {
    return;
  }
}
