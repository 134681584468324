import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Experiences } from '@local/client-contracts';
import { RouterService } from '@shared/services/router.service';
import { firstValueFrom } from 'rxjs';
import { WorkspacesService } from 'src/app/bar/services';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { ExperiencesService } from 'src/app/bar/services/experiences.service';

@Injectable({ providedIn: 'root' })
export class AssistantResolver {
  constructor(
    private assistantService: ExperiencesService,
    private collectionsService: CollectionsService,
    private routerService: RouterService,
    private workspacesService: WorkspacesService
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<Experiences.ExperienceItem> {
    const id = route.params.id;
    let channel = await this.checkIfLoaded(id);
    if (channel) {
      return channel;
    }
    await this.assistantService.refresh();
    channel = await this.checkIfLoaded(id);
    if (channel) {
      return channel;
    }
    this.routerService.navigateByUrl('/not-found');
    return null;
  }

  private async checkIfLoaded(id: string) {
    const channel = await this.getChannel(id);
    if (channel) {
      await this.ensureCollectionsAvailable(channel);
      return channel;
    }
  }

  async getChannel(id: string) {
    const data = await firstValueFrom(this.assistantService.visible$);
    return data?.find((a) => a.id === id);
  }

  async ensureCollectionsAvailable(channel: Experiences.ExperienceItem) {
    const ids = channel.collectionIds;
    if (!ids?.length || !this.workspacesService.isMe(channel.createdBy)) {
      return true;
    }
    const collectionIds = await firstValueFrom(this.collectionsService.all$).then((c) => new Set(c?.map((c) => c.id) || []));
    const hasAll = ids.every((a) => collectionIds.has(a));
    if (!hasAll) {
      await this.collectionsService.refresh();
    }
  }
}
