import { Injectable } from '@angular/core';
import { Permissions } from '@local/client-contracts';
import { ServicesRpcService } from '@shared/services';
import { PermissionsRpcInvoker } from './invokers/permissions-rpc-invoker';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private service: Permissions.Service;

  constructor(services: ServicesRpcService) {
    this.service = services.invokeWith(PermissionsRpcInvoker, 'permissions');
  }

  list(request: Permissions.ListPermissionsRequest): Promise<Permissions.ListPermissionsResponse> {
    return this.service.list(request);
  }
}
