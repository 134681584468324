import { Downloads } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';

export class MainDownloadsRpcInvoker implements Downloads.MainService {
  @invoker
  draggedInsideBarWindow(): Promise<boolean> {
    return;
  }

  @invoker
  dragStarted(url: string, icon: string, appId?: string): Promise<void> {
    return;
  }
}
