import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { Links } from '@local/client-contracts';
import { PopupRef } from '@local/ui-infra';
import { KeyboardService } from '@shared/services/keyboard.service';
import { KeyName } from '@local/ts-infra';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'tag-group-popup',
  templateUrl: './tag-group-popup.component.html',
  styleUrls: ['./tag-group-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagGroupPopupComponent implements OnInit {
  tagsNode: TreeNode[];
  tooltip: string;
  markedText: string[];

  private keyHandlerId: string;

  constructor(
    private ref: PopupRef<TagGroupPopupComponent, Links.TagGroup>,
    private cdr: ChangeDetectorRef,
    private keyboardService: KeyboardService
  ) {}

  ngOnInit(): void {
    this.tooltip = this.ref.data.tooltip;
    this.tagsNode = this.buildNode();
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => {
      this.keysHandler(keys, event);
    }, 10);
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
      this.keyHandlerId = null;
    }
  }

  private buildNode(): TreeNode[] {
    return this.ref.data.tags
      .map((tag) => {
        return {
          label: tag.path?.length ? tag.path.map((p) => p.text).join(' ') : tag.text,
          data: { tag },
        };
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  }

  onSearch(filter: string): void {
    this.markedText = [filter];
    this.cdr.markForCheck();
  }

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    event.stopPropagation();
  }

  private keysHandler(keys: Array<KeyName>, event): void {
    event.stopPropagation();

    if (keys[0] === 'escape') {
      this.ref.destroy();
      event.preventDefault();
    }
  }
}
