import { Injectable } from '@angular/core';

export type SuggestionsList = { name: string; items: any[] }[];

@Injectable()
export class IdentitiesSuggestionsService {
  searchSuggestions(query: string, suggestions: SuggestionsList, maxItems = 30) {
    const filtered: SuggestionsList = [];
    const readyQuery: string = (query || '')?.toLocaleLowerCase();

    for (const list of suggestions || []) {
      const copyList: { [id: string]: any } = {};
      let count = 0;
      for (const item of list?.items) {
        if (
          !item.name?.toLowerCase().includes(readyQuery) &&
          (list?.name !== 'Members' || !item.email?.toLowerCase().includes(readyQuery))
        ) {
          continue;
        }
        copyList[item.id] = item;
        count++;
        // taking 10 times the max number to maximize the results quality.
        if (count >= maxItems * 10) {
          break;
        }
      }

      let items = Object.values(copyList);
      if (items.length) {
        items = items.sort((a, b) => {
          for (const field of ['name', 'email']) {
            const indexA = a[field]?.toLowerCase()?.indexOf(readyQuery) ?? -1;
            const indexB = b[field]?.toLowerCase()?.indexOf(readyQuery) ?? -1;
            if (indexA === -1 && indexB === -1) {
              continue;
            }
            if (indexA === -1 || indexB === -1) {
              return indexB - indexA;
            }
            if (indexA !== null && indexB !== null && indexA !== indexB) {
              return indexA - indexB;
            }
          }
          return 0;
        });
        items = items.splice(0, maxItems);
        filtered.push({ items, name: list.name });
      }
    }

    return filtered;
  }
}
