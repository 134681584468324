import {
  Assistants,
  Calculators,
  Collections,
  Commands,
  Experiences,
  Filters,
  LocalActions,
  Omnibox,
  People,
  Resources,
  Results,
  Search,
  Style,
  Traits,
  WebSearch,
  Wiki,
} from '@local/client-contracts';
import { BrowserBookmark, BrowserTab } from '@local/common';
import { QueryClass, Visit } from '@local/common-web';
import { UiIconModel } from '@local/ui-infra';
import { ContextMenuItem } from '@shared/components';
import { EmbedItem as EmbedItemInt } from '@shared/embed.service';
import { Breadcrumb } from '@shared/services/breadcrumbs.service';
import { SearchGroup } from 'src/app/bar/models/search-group';
import { SearchSourceType } from 'src/app/bar/services/search/client/search-source-type';
import { RelevantPeopleViewType } from '../../results-views/relevant-people/models/relevant-people-views.type';
import { StaticSearchItemType } from '../components/static-search-item/static-search-item.model';
import { Action } from './view-filters';

export type RendererResultType =
  | Search.ResultType
  | 'header'
  | 'recent-search'
  | 'answer'
  | 'answer-feedback'
  | 'gallery-group'
  | 'time-action';

export type RendererResults =
  | HeaderItem
  | Calculators.Item
  | Search.ResultItem
  | Search.ResultResourceItem
  | LocalActions.LocalActionItem
  | RecentSearchItem
  | TopPicksResult
  | GoToItem
  | WebSearchItem
  | QueryItem
  | Search.GoLinkResultItem
  | CreateGoLinkItem
  | CalendarEvent
  | SuggestionsItem
  | CollectionItem
  | BrowserBookmarkItem
  | BrowserTabItem
  | CollectionsStaticItem
  | AddToCollectionItem
  | StaticSearchItem
  | FavoritesItem
  | AnswerSearchItem
  | AssistantItem
  | RelevantPeopleItem
  | RfpSearchItem
  | AnswerFeedbackSearchItem
  | TimeActionItem;

export interface EmbedItem extends EmbedItemInt {
  type: 'embed-item';
}
export interface HeaderItem {
  type: 'header';
  origin: ResultsOrigin;
  clickable: boolean;
  title?: string; /// The text to be marked and clickable
  titleEnd?: string; /// The text to be marked and clickable
  titleHighlight?: string; /// The text to be marked and clickable
  shortcut?: Array<string>;
  icon?: string;
  headerType?: 'results';
  showButton?: boolean;
  textButton?: string;
  group?: SearchGroup;
  selectable?: boolean;
  leftIcon?: string;
  leftIconStyles?: any;
  isFooter?: boolean;
}

export interface FolderItem {
  type: 'folder';
  id: string;
  title: string;
  itemsCount: number;
  resultIndex: number;
  path?: Wiki.Path[];
  highlights?: string[];
}

export interface GalleryGroup {
  type: 'gallery-group';
  index: number;
  resultsType: SearchSourceType;
  items: SearchResults[];
}

export interface ExperienceSearchItem extends Experiences.ExperienceItem {
  type: string;
  icon: UiIconModel;
  emoji?: string;
}

export interface RecentSearchItem {
  type: 'recent-search';
  query: string;
  tags: Omnibox.Tag[];
  nodeId: string;
  displayIndex?: number;
  filters: Filters.Values;
}

export interface FavoritesItem extends Search.ResultResourceItem {
  isFavorite: boolean;
}

export interface PersonItem extends People.PersonDetails {
  type: 'person';
}

export interface BrowserBookmarkItem extends BrowserBookmark {
  type: 'browser-bookmark';
}

export interface VisitItem extends Visit {
  type: 'visit';
}

export interface BrowserTabItem extends BrowserTab {
  type: 'browser-tab';
}

export interface QueryItem {
  type: 'query';
  icon: Style.EntityIcon<Style.EntityIconType>;
  title: string;
  subtitle?: string;
  url: string;
  class: QueryClass;
}

export interface GoToItem {
  type: 'goto';
  id: string;
  icon: Style.EntityIcon<Style.EntityIconType>;
  title: string;
  subtitle?: string | Breadcrumb[];
  state?: 'static' | 'dynamic' | 'standard';
  command: Commands.Command;
  parentId?: string;
}
export interface CreateGoLinkItem {
  type: 'create-go-link';
  url: string;
  icon: Style.EntityIcon<Style.EntityIconType>;
  title: string;
  id: string;
}

export interface AddToCollectionItem {
  type: 'add-to-collection';
  url: string;
  icon: Style.EntityIcon<Style.EntityIconType>;
  title: string;
  id: string;
}

export interface StaticSearchItem {
  type: 'static-search-item';
  icon: Style.EntityIcon<Style.EntityIconType>;
  title: string;
  subTitle?: string;
  description?: string;
  invokeType: StaticSearchItemType;
  classList?: string[];
}

export interface RfpSearchItem extends StaticSearchItem {
  processId?: string;
}

export const AnswerGenerateState = ['Generating', 'GeneratingDone'];
export const AnswerSearchReady = ['Full', 'NoResults', 'RephraseRequired', 'Generating', 'GeneratingDone'];
export const AnswerNoResults = ['NoResults', 'RephraseRequired'];

export interface AnswerSearchItem {
  type: 'answer';
  query: string;
  resources: Search.ResultResourceItem[];
  state: Assistants.AnswerStatusType;
  text: string;
  searchId?: string;
  debugInfo?: any;
  collectionId?: string;
  intent?: Assistants.QuestionIntent;
  formattedAnswer?: string;
  references?: Assistants.AnswerReference[];
  resourceIds?: string[];
}

export interface AnswerFeedbackSearchItem {
  type: 'answer-feedback';
  intent: Assistants.QuestionIntent;
  feedbackType: Resources.FeedbackType;
  resources: Search.ResultResourceItem[];
  linkIds: string;
  resourceIds: string;
}

export interface TimeActionItem {
  type: 'time-action';
  time: number;
  timeText: string;
  actionButton: TimeActionButton;
  selectable?: boolean;
}

export type TimeActionButton = { buttonIcon: string; buttonTooltip?: string; buttonAction: any };

export interface WebSearchItem extends WebSearch.ResultItem {
  type: 'web-search';
  engine: string;
}

export interface CalendarEvent extends Search.ResultResourceItem {
  displayIndex?: number;
  originalStartDate?: moment.Moment;
  originalEndDate?: moment.Moment;
  start?: moment.Moment;
  end?: moment.Moment;
  rsvp?: Traits.RsvpType;
  isAllDay?: boolean;
}
export interface SuggestionsItem {
  type: 'suggestions';
  redirectType: 'Page' | 'Url';
  launcherRedirectType?: 'Page' | 'Url';
  title: string;
  icon?: Style.EntityIcon<Style.EntityIconType>;
  filters?: { name: string; values: string[] }[];
  page?: string;
  openExternal?: boolean;
}

export interface CollectionItem extends Collections.Collection {
  type: 'collection';
  highlights?: string[];
  showResultSections?: Results.ShowResultSections;
}

export interface AssistantItem extends Experiences.ExperienceItem {
  type: 'assistant';
  highlights?: string[];
}

export interface CollectionsStaticItem extends Collections.StaticItem {
  type: 'collection-static-item';
}

export type DisplayItemData = {
  displayIndex?: number;
  type: RendererResultType;
  resultIndex?: number;
  resourceList?: string;
  action?: Action;
};

export type SearchResults = RendererResults &
  DisplayItemData & { source?: SearchSourceType; searchTokens?: string[]; highlights?: string[] };

export type ResultDisplayModel = HeaderItem | GalleryGroup | SearchResults;

export interface CommandContext {
  searchId?: string;
  resourceId?: string;
  linkId?: string;
  userInput?: any;
  [key: string]: any;
  item?: Search.Item;
}

export interface TopPicksResult extends Search.ResultResourceItem {
  kind: 'highlights' | 'recently_viewed';
}

export interface RelevantPeopleItem extends Search.ResultResourceItem {
  kind: string;
  settings: {
    viewMode: RelevantPeopleViewType;
    index: number;
  };
}

export type PCName = `This ${'Mac' | 'PC'}`;

export type ResultsOrigin = Lowercase<keyof Search.Context['origins']> | PCName | string | 'actions'; // App name;

export type ScrollDirection = 'up' | 'down';

export type ScrollTrigger = 'arrowdown' | 'reach_bottom_scroll' | 'pagedown_key' | 'reach_bottom_keyboard' | 'item_selection' | 'end_key';

export type Select = 'next' | 'prev' | 'first' | number;

export type ImpressionResourceList =
  | 'local_search_results'
  | 'calculator_search_results'
  | 'collection_cloud_results'
  | 'collections'
  | 'cloud_search_results'
  | 'actions_search_results'
  | 'highlights'
  | 'recently_viewed'
  | 'people'
  | 'go_links'
  | 'bookmarks'
  | 'tabs'
  | 'favorites';

export type FilterActivationMethod = 'mouse' | 'keyboard';

export type ResultContextMenuItemId =
  | 'copy'
  | 'copy-url'
  | 'open_desktop'
  | 'open_browser'
  | 'open'
  | 'favorite'
  | 'pin'
  | 'download'
  | 'open_preview'
  | 'copy_email'
  | 'copy_phone'
  | 'share'
  | 'close_preview_popup'
  | 'copy_goLink'
  | 'edit_goLink'
  | 'delete_goLink'
  | 'rename'
  | 'add_to_collection'
  | 'remove'
  | 'change_layout'
  | 'copy-answer'
  | 'create-card'
  | 'verification_details'
  | 'request_verification'
  | 'verify'
  | 'unverify'
  | 'make_a_copy';

export type ResultContextMenuItem = ContextMenuItem & { id: ResultContextMenuItemId };

export type ResultItem = Search.ResultResourceItem & DisplayItemData;

export type FileViewTitle = { start: Results.Title; end: string };

export type TelemetryTrigger = 'mouse_click' | 'keyboard' | 'context_menu_keyboard' | 'context_menu_click' | 'refresh';

export type SourceClick = 'title' | 'avatar';
