import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DateFormat } from '@shared/consts';
import { getTimeFromNowInText } from '@shared/utils/date-format.util';
import moment from 'moment';
import { RequestData } from '../requests-history-models';

@Component({
  selector: 'request-history-item',
  templateUrl: './request-history-item.component.html',
  styleUrls: ['./request-history-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestHistoryItemComponent implements AfterViewInit {
  readonly MAX_HEIGHT_MESSAGE: number = 37;
  readonly DEFAULT_USER_NAME: string = 'N/A';
  private _item: RequestData;
  dateAsString: string;
  dateFormatted: string;
  showFullMessage: boolean = false;
  showMoreMessageButton: boolean = false;
  @ViewChild('messageRequest', { read: ElementRef }) messageRef: ElementRef;

  get item(): RequestData {
    return this._item;
  }
  @Input() set item(value: RequestData) {
    this._item = value;
    this.dateAsString = this.generateTime(value.date);
    this.dateFormatted = moment(value.date).format(DateFormat.FULL_DATE);
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.maxHeightMessage();
    }, 0);
  }

  generateTime(time: number): string {
    return getTimeFromNowInText(time).toLowerCase();
  }

  maxHeightMessage() {
    const height: number = this.messageRef?.nativeElement?.offsetHeight;
    this.showMoreMessageButton = height >= this.MAX_HEIGHT_MESSAGE;
    this.cdr.markForCheck();
  }
}
