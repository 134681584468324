import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { ManualPromise } from '@local/common';
import { ElementSize, PopupRef } from '@local/ui-infra';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { isEnterKey, KeyName } from '@local/ts-infra';

export type ButtonStyle = { type?: string; size?: number; fontSize?: number; elementSize?: ElementSize };
export type Position = 'bottom' | 'center';

export interface AppPopupData {
  message?: string;
  showButtons?: boolean;
  onlyPrimaryButton?: boolean;
  onlySecondaryButton?: boolean;
  rightButtonStyle?: ButtonStyle;
  leftButtonStyle?: ButtonStyle;
  content?: {
    title?: string;
    primaryButton?: string;
    secondaryButton?: string;
    supportMail?: string;
  };
  templateContent?: ElementRef;
  templateContentPosition?: Position;
  messageStyle?: any;
  titleStyle?: any;
  popupStyle?: any;
  showCloseIcon?: boolean;
  showErrorIcon?: boolean;
  checkbox?: {
    value: boolean;
    disabled?: boolean;
    text: string;
  };
  supportLoadingPrimaryButton?: boolean;
}
export type PrimaryButtonEvent = { event: PointerEvent; promise?: ManualPromise<void> };

@Component({
  selector: 'app-popup',
  templateUrl: './app-popup.component.html',
  styleUrls: ['./app-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppPopupComponent {
  keyHandlerId: string;
  selectedKey: boolean | number = -1;
  loadingPrimaryButton: boolean;
  @Output() primaryButton = new EventEmitter<PrimaryButtonEvent>();
  @Output() secondaryButton = new EventEmitter<PointerEvent>();
  @Output() closeButton = new EventEmitter<PointerEvent>();
  @Output() checkBoxChanged = new EventEmitter<boolean>();

  data: AppPopupData;

  constructor(
    private ref: PopupRef<AppPopupComponent, AppPopupData>,
    private keyboardService: KeyboardService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.data = this.ref.data;
    const defaultRightButton: ButtonStyle = { size: 80, type: 'primary-danger', fontSize: 14 };
    this.data.rightButtonStyle = { ...defaultRightButton, ...this.data.rightButtonStyle };

    const defaultLeftButton: ButtonStyle = { size: 80, type: 'secondary', fontSize: 14 };
    this.data.leftButtonStyle = { ...defaultLeftButton, ...this.data.leftButtonStyle };
    this.data.templateContentPosition = this.data.templateContentPosition ? this.data.templateContentPosition : 'center';
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 7);
  }

  ngOnDestroy(): void {
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
  }

  async onSecondaryButton(event) {
    this.secondaryButton.emit(event);
    this.close();
  }

  async onPrimaryButton(event) {
    const primaryEvent: PrimaryButtonEvent = { event };
    if (this.data.supportLoadingPrimaryButton) {
      const primaryPromise = new ManualPromise<void>();
      primaryEvent.promise = primaryPromise;
      this.loadingPrimaryButton = true;
      this.cdr.markForCheck();
    }
    this.primaryButton.emit(primaryEvent);
    if (primaryEvent.promise) {
      await primaryEvent.promise;
      this.loadingPrimaryButton = false;
    }
    this.close();
  }

  async onCloseButton(event) {
    this.closeButton.emit(event);
    this.close();
  }

  onChangeCheckbox(event) {
    this.checkBoxChanged.emit(event.checked);
  }

  close() {
    this.ref.destroy();
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    const key = keys[0];
    event.stopPropagation();
    event.preventDefault();

    if (isEnterKey(key)) {
      if (this.selectedKey === -1) {
        return;
      }
      if (this.selectedKey) {
        this.primaryButton.emit();
      } else {
        this.secondaryButton.emit();
      }
      this.close();
      return;
    }
    switch (key) {
      case 'escape':
        this.close();
        break;
      case 'ArrowLeft':
        this.selectedKey = this.selectedKey === -1 ? true : !this.selectedKey;
        break;
      case 'ArrowRight':
        this.selectedKey = this.selectedKey === -1 ? false : !this.selectedKey;
        break;
    }
    this.cdr.markForCheck();
  }
}
