import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { isMac } from '@local/common-web';
import { Keyboard } from '@local/client-contracts';
import { KeyboardService } from '@shared/services/keyboard.service';
import { Logger } from '@unleash-tech/js-logger';
import { fromEvent, race, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EventsService, LogService } from '../../services';
import { getKeyName, getOSShortcut, isKey, isModifierKey, keyCodes, normalizeKeys } from '@local/ts-infra';
import { bindingEqual } from '@local/common';

@Component({
  selector: 'key-binding',
  templateUrl: './key-binding.component.html',
  styleUrls: ['./key-binding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyBindingComponent implements OnDestroy {
  logger: Logger;

  @Input() set keys(value: Array<string>) {
    const shortcut = getOSShortcut(value);
    this.mappedKeys = normalizeKeys(shortcut).sort((a, b) => b.length - a.length);
  }
  get keys(): Array<string> {
    return this.modifiersFirst(this.mappedKeys);
  }
  @Input() type: Keyboard.Type;
  @Input() readonly = false;
  @Input() clearable = false;

  @Output() update = new EventEmitter<Array<string>>();
  @Output() cancel = new EventEmitter<void>();

  editing = false;
  tempKeys: Array<string> = [];
  errorMsg: 'Combination already in use' | 'Invalid Combination';

  private mappedKeys = [];
  private stopKeyListener$ = new Subject<void>();
  private readonly destroy$ = new Subject<void>();

  constructor(private eventsService: EventsService, private cdr: ChangeDetectorRef, log: LogService, private keyboard: KeyboardService) {
    this.logger = log.scope('KeyBindingComponent');
  }

  ngOnDestroy(): void {
    this.keyboard.stopListening = false;
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  async listenToKeyboard(): Promise<void> {
    if (this.editing || this.readonly) {
      return;
    }

    this.keyboard.stopListening = true;
    this.eventsService.event('keyboard.start_editing');
    this.editing = true;
    this.cdr.markForCheck();
    try {
      const seq = await this.getSequence();
      this.logger.info('Sequence Changed: ' + seq.join('+'));
      this.update.emit(seq);
    } catch (e) {
      this.logger.warn('listenToKeyboard()', { error: e });
    } finally {
      this.editing = false;
      this.tempKeys = [];
      this.cdr.markForCheck();
    }
  }

  cancelKeyboardListener(): void {
    this.editing = false;
    this.tempKeys = [];
    this.keyboard.stopListening = false;
    this.stopKeyListener$.next(null);
    this.cancel.emit();
    this.cdr.markForCheck();
  }

  private getSequence(): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      let initiator = null;
      this.tempKeys = [];
      let exceeded = false;
      this.cdr.markForCheck();

      fromEvent(window, 'blur')
        .pipe(takeUntil(race(this.destroy$, this.stopKeyListener$)))
        .subscribe(() => {
          this.keyboard.stopListening = false;
          reject('Blurred during key listening');
        });

      fromEvent<KeyboardEvent>(window, 'keydown')
        .pipe(takeUntil(race(this.stopKeyListener$, this.destroy$)))
        .subscribe((event) => {
          event.preventDefault();

          if (isKey(event, keyCodes.escape)) this.cancelKeyboardListener();

          let name = getKeyName(event);

          // Always parse the english letter out of the key
          if (!isModifierKey(name)) name = String.fromCharCode(event.keyCode || event.which);

          if (name === 'meta' && isMac()) name = 'command';

          if (event.repeat || this.errorMsg || this.tempKeys.includes(name)) return;

          if (initiator === null && isModifierKey(name)) {
            this.tempKeys = normalizeKeys([name]);
            initiator = event.key;
            exceeded = false;
          } else if (isModifierKey(event.key) || initiator !== null) {
            if (this.tempKeys.length > 3) {
              exceeded = true;
            } else {
              this.tempKeys.push(normalizeKeys([name])[0]);
            }
          }

          this.cdr.markForCheck();
        });

      fromEvent<KeyboardEvent>(window, 'keyup')
        .pipe(takeUntil(race(this.stopKeyListener$, this.destroy$)))
        .subscribe(async (event) => {
          event.preventDefault();

          if (event.key === initiator || isModifierKey(getKeyName(event))) {
            if (!exceeded && (await this.isValidShortcut(this.tempKeys))) {
              this.stopKeyListener$.next(null);
              return resolve(this.tempKeys);
            }

            if (this.errorMsg) {
              setTimeout(() => {
                this.cancelKeyboardListener();
                this.errorMsg = undefined;
                this.tempKeys = [];
                this.cdr.markForCheck();
              }, 1500);
            }

            initiator = null;
            this.cdr.markForCheck();
            return;
          }
        });
    });
  }

  private async isValidShortcut(keys: Array<string>): Promise<boolean> {
    const invalidMsg = 'Invalid Combination';

    if (bindingEqual(this.keys, keys)) {
      return true;
    }

    if (keys.length < 2 || keys.length > 3) {
      this.errorMsg = invalidMsg;
      return false;
    }

    const modifiers = keys.filter((key) => isModifierKey(key));
    if (modifiers.length < 1 || modifiers.length > 2) {
      this.errorMsg = invalidMsg;
      return false;
    }

    if (modifiers.length === keys.length) {
      this.errorMsg = invalidMsg;
      return false;
    }

    const characters = keys.filter((key) => !modifiers.includes(key));

    if (characters.length !== 1) {
      this.errorMsg = invalidMsg;
      return false;
    }

    if (!characters.every((char) => isValidChar(char))) {
      this.errorMsg = invalidMsg;
      return false;
    }

    if (await this.keyboard.bindingTaken(keys)) {
      this.errorMsg = 'Combination already in use';
      return false;
    }

    return true;
  }

  private modifiersFirst(keys: Array<string>): Array<string> {
    return keys.sort((k) => (isModifierKey(k) ? -1 : 1));
  }
}

function isEnglishLetter(str: string) {
  return str.length === 1 && str.toLowerCase().match(/[a-z]/i);
}

function isValidChar(char: string): boolean {
  if (isEnglishLetter(char)) return true;

  if (!isNaN(+char)) return true;

  if (char.toLowerCase() === 'space') return true;

  return false;
}
