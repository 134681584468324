import { Experiences, Filters, Links } from '@local/client-contracts';
import { ApplicationsService } from '@shared/services/applications.service';
import { LinksService } from '@shared/services/links.service';
import { AssistantConst } from './assistant.const';
import { filterSourceWarning } from './assistant.content';
import { Injectable } from '@angular/core';
import { FilterSourceViewModel, SourceUnavailableState } from '../models/filter-source-view-model';
import { AssistantFilterPriority } from '../enum/assistant-filter-priority.enum';
import { firstValueFrom } from 'rxjs';
import { keyBy } from 'lodash';

@Injectable()
export class AssistantViewHelper {
  private linksByApp: { [appName: string]: { [linkName: string]: Links.DisplayItem } };

  constructor(private linksService: LinksService, private applicationsService: ApplicationsService) {
    this.initLinksByApp();
  }

  async initFilterSourceView(
    filterSource: Experiences.FilterDataSource,
    assistant: Experiences.ExperienceItem,
    objectsShared: Experiences.ExperienceSharedObjects
  ) {
    const filterSourceView: FilterSourceViewModel = { filterSource };
    filterSourceView.readonly = assistant.permissionRole === 'viewer';
    const unavailableState: SourceUnavailableState = await this.getDataSourceUnavailableState(
      filterSource,
      assistant.permissionRole === 'creator',
      objectsShared
    );
    filterSourceView.unavailableState = unavailableState;
    if (unavailableState) {
      filterSourceView.disabled = ['deleted', 'creatorNoAccess'].includes(unavailableState);
      if (unavailableState === 'noAccess') {
        filterSourceView.readonly = true;
        filterSourceView.preventResourcesSearch = true;
      }
      filterSourceView.warningMessage = this.getUnavailableWarning(unavailableState, assistant.permissionRole);
    }
    this.updateLinkState(filterSourceView);
    return filterSourceView;
  }

  initEmptyFilterSourceView(): FilterSourceViewModel {
    const filterSource = this.getEmptyFilterSource();
    const filterSourceView: FilterSourceViewModel = { filterSource };
    return filterSourceView;
  }

  getEmptyFilterSource() {
    return { filters: {}, resources: [], tier: AssistantFilterPriority.SECOND };
  }

  updateLinkState(filterSourceView: FilterSourceViewModel) {
    const filters = filterSourceView.filterSource?.filters;
    const isStale = this.isStaleLink(filters);
    filterSourceView.linkState = {
      isPrivate: this.isPrivateLink(filters),
      isStale,
    };
    //Reset warning message only when It's stale massage (and not reset when link in unavailable)
    if (filterSourceView.warningMessage === filterSourceWarning.staleLink) {
      filterSourceView.warningMessage = null;
    }
    if (isStale && !filterSourceView.warningMessage) {
      //Display stale warning only if the link is available
      filterSourceView.warningMessage = filterSourceWarning.staleLink;
    }
  }

  resetLinkState(filterSourceView: FilterSourceViewModel) {
    filterSourceView.linkState = {};
    filterSourceView.warningMessage = null;
  }

  private getUnavailableWarning(unavailableState: SourceUnavailableState, permissionRole: Experiences.PermissionRole) {
    switch (unavailableState) {
      case 'deleted':
        return filterSourceWarning.deletedLink;
      case 'noAccess':
        return filterSourceWarning.noAccess;
      case 'creatorNoAccess':
        return filterSourceWarning.creatorNoAccess[permissionRole];
    }
  }

  private initLinksByApp() {
    this.linksService.all$.subscribe((links) => {
      const linksByApp: { [appName: string]: { [linkName: string]: Links.DisplayItem } } = {};
      for (const link of links) {
        const appName = this.applicationsService.apps[link.appId]?.name;
        if (!appName) {
          continue;
        }
        if (!linksByApp[appName]) {
          linksByApp[appName] = {};
        }
        linksByApp[appName][link.name] = link;
      }
      this.linksByApp = linksByApp;
    });
  }

  private findLink(filters: Filters.Values): Links.DisplayItem {
    const appName = filters?.[AssistantConst.APP_FILTER_NAME]?.[0];
    const account = filters?.[AssistantConst.ACCOUNT_FILTER_NAME]?.[0];
    //TODO: change linksByApp to be subject?
    const link = this.linksByApp?.[appName]?.[account];
    return link;
  }

  private isPrivateLink(filters: Filters.Values): boolean {
    const link = this.findLink(filters);
    return link?.shareOptions?.level === 'Private';
  }

  private isStaleLink(filters: Filters.Values): boolean {
    const link = this.findLink(filters);
    return link?.syncStatus === 'stale';
  }

  private async getDataSourceUnavailableState(
    filterSource: Experiences.FilterDataSource,
    isExperienceCreator: boolean,
    objectsShared: Experiences.ExperienceSharedObjects
  ): Promise<SourceUnavailableState> {
    const backendFilters = filterSource.backendFilters;
    const linkIds = backendFilters?.linkId;
    if (!linkIds?.length) {
      return null;
    }
    const links = await this.filterLinksByApps(linkIds, backendFilters?.appId || []);
    if (!links?.length) {
      return isExperienceCreator ? 'deleted' : 'noAccess';
    }
    if (isExperienceCreator) {
      return null;
    }
    const isRlpLink = links.some((l) => l.resourcePermissions?.enabled);
    if (isRlpLink) {
      return 'noAccess';
    }
    const creatorHasAccess = this.creatorHasAccess(linkIds, objectsShared);
    return creatorHasAccess ? null : 'creatorNoAccess';
  }

  private async filterLinksByApps(linkIds: string[], appIds: string[]): Promise<Links.DisplayItem[]> {
    const appSet = new Set(appIds || []);
    const allLinks = await firstValueFrom(this.linksService.all$);
    const linksMap = keyBy(allLinks, 'id');
    const matchLinks: Links.DisplayItem[] = [];
    for (const id of linkIds) {
      const link = linksMap[id];
      if (!link) {
        continue;
      }
      if (appSet.has(link.appId)) {
        matchLinks.push(link);
      }
    }
    return matchLinks;
  }

  private creatorHasAccess(linkIds: string[], objectsShared: Experiences.ExperienceSharedObjects): boolean {
    const linksSharedWithCreator = objectsShared?.links;
    const creatorHasAccess = linkIds.some((id) => linksSharedWithCreator?.has(id));
    return creatorHasAccess;
  }
}
