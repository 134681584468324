import { Injectable } from '@angular/core';
import { BlobsService } from 'src/app/bar/services/blob.service';
import { SessionService } from '@shared/services/session.service';
import { Permissions, SessionInfo, Wiki } from '@local/client-contracts';
import { Logger } from '@unleash-tech/js-logger';
import { EventsService, LogService } from '@shared/services';
import { showWikiToasterError } from '../helpers/wiki/wiki-card-utils';
import { ShowToasterService } from 'src/app/bar/services/show-toaster.service';

@Injectable()
export class WikiCardBlobService {
  private readonly FAILED_UPLOAD_ATTACHMENT_MESSAGES = 'Files failed to upload';
  private sessionInfo: SessionInfo;
  protected logger: Logger;

  constructor(
    private blobsService: BlobsService,
    private sessionService: SessionService,
    private eventsService: EventsService,
    private showToasterService: ShowToasterService,
    logService: LogService
  ) {
    this.logger = logService.scope('WikiCardBlobService');

    this.sessionService.current$.subscribe((sessionInfo) => {
      this.sessionInfo = sessionInfo;
    });
  }

  async convertImagesToBlobId(file: File, cardId: string): Promise<string> {
    const { name, type } = file;

    const shareOptions: Permissions.ShareOptions = {
      level: 'Following',
      followingIds: [cardId],
    };
    try {
      const blobId = (await this.blobsService.create(name, type, shareOptions))?.id;
      const uploadResponse = await this.blobsService.upload(file, blobId);

      if (uploadResponse.status >= 400) {
        this.logger.error('error to upload file to wiki collection', { error: uploadResponse.status });
        return null;
      }
      return blobId;
    } catch (error) {
      this.logger.error('error to upload file to wiki collection', { error });
      return null;
    }
  }

  createdAttachment(
    blobId: string,
    mimeType?: string,
    name?: string,
    size?: number,
    type: Wiki.CardAttachmentType = 'File'
  ): Wiki.CardAttachment {
    const accountId = this.sessionInfo?.workspace?.accountId;
    const createdTime = Date.now();
    return {
      blobId,
      createdBy: accountId,
      modifiedBy: accountId,
      createdTime,
      modifiedTime: createdTime,
      mimeType,
      name,
      size,
      type,
    };
  }

  async createBlob(cardId: string, name: string, type: string): Promise<string> {
    const shareOptions: Permissions.ShareOptions = {
      level: 'Following',
      followingIds: [cardId],
    };
    try {
      const res = await this.blobsService.create(name, type, shareOptions);
      return res.id;
    } catch (error) {
      this.failedToUpload(name, 'create_blob_failed');
      return;
    }
  }

  failedToUpload(fileName: string, target: 'create_blob_failed' | 'upload_media_failed' | 'upload_attachment_failed', id?: string) {
    showWikiToasterError(this.showToasterService, this.FAILED_UPLOAD_ATTACHMENT_MESSAGES);
    this.eventsService.event('hub_error_modal', {
      name: 'show',
      label: 'card_upload_failed',
      target,
      jsonData: {
        id,
        fileName: fileName,
      },
    });
  }
}
