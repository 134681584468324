import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { WindowService } from '@shared/services';
import { BehaviorSubject } from 'rxjs';
import { Style } from '@local/client-contracts';

export interface EmptyStateModel {
  viewName: 'favorites';
  title?: string;
  subtitle?: string;
  icon?: Style.Icon;
  animation?: 'bounce' | 'draw';
  svgUrl?: string;
  buttonText?: string;
}

@Component({
  selector: 'app-empty-state',
  templateUrl: './app-empty-state.component.html',
  styleUrls: ['./app-empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppEmptyStateComponent {
  @Input() svgUrl: string;
  @Input() icon: Style.Icon;
  @Output() actionClick = new EventEmitter();
  @Input() buttonText: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() animation?: 'bounce' | 'draw';
  @Input() showAdditionalSubtitle: boolean = false;
  @Input() isLauncher: boolean = false;

  focus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(document.hasFocus());
  docCallback: () => void;

  constructor(public window: WindowService) {
    this.docCallback = () => this.focus$.next(document.hasFocus());
    document.addEventListener('focus', this.docCallback);
    document.addEventListener('blur', this.docCallback);
  }

  ngOnDestroy() {
    document.removeEventListener('focus', this.docCallback);
    document.removeEventListener('blur', this.docCallback);
  }
}
