import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIconBlinkComponent } from './u-icon-blink.component';
import { UIconModule } from '../u-icon/u-icon.module';

@NgModule({
  declarations: [UIconBlinkComponent],
  imports: [CommonModule, UIconModule],
  exports: [UIconBlinkComponent],
})
export class UIconBlinkModule {}
