import { Injectable } from '@angular/core';
import { Wiki } from '@local/client-contracts';
import { takeUntil } from 'rxjs';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { WikiCardsVerificationsService } from 'src/app/bar/services/wikis/wiki-cards-verifications.service';
import { WikiCardsService } from 'src/app/bar/services/wikis/wiki-cards.service';
import { isWikiItem } from '../../results';

const VERIFICATIONS_COMMANDS: string[] = ['verification-details', 'verify', 'unverify', 'request-verification'];

@Injectable()
export class VerificationsCardsCommandsService {
  constructor(
    private wikiCardsService: WikiCardsService,
    private collectionService: CollectionsService,
    private wikiCardsVerificationsService: WikiCardsVerificationsService
  ) {}

  executeCommand(value: string, item: Wiki.Card) {
    switch (value) {
      case 'verification-details': {
        const collection = this.collectionService.getCollectionById(item.collectionId);
        this.wikiCardsService.openVerificationDetails(
          item.id,
          collection as Wiki.WikiCollection,
          item.verification?.policy,
          item.verification
        );
        break;
      }
      case 'verify': {
        const areYouSureVerify = this.wikiCardsService.changeVerificationStatus('Verify');
        areYouSureVerify.compInstance.primaryButton.pipe(takeUntil(areYouSureVerify.destroy$)).subscribe(() => {
          this.wikiCardsService.updateVerificationStatus('Verified', [item.id], true);
        });
        break;
      }
      case 'unverify': {
        const areYouSureUnverify = this.wikiCardsService.changeVerificationStatus('Unverify');
        areYouSureUnverify.compInstance.primaryButton.pipe(takeUntil(areYouSureUnverify.destroy$)).subscribe(() => {
          this.wikiCardsService.updateVerificationStatus('Unverified', [item.id], true);
        });
        break;
      }
      case 'request-verification': {
        const requestVerificationPopupRef = this.wikiCardsVerificationsService.openRequestVerificationPopup();
        requestVerificationPopupRef.compInstance.sendMessage.pipe(takeUntil(requestVerificationPopupRef.destroy$)).subscribe((message) => {
          let title = item?.title;
          if (isWikiItem(item)) {
            title = item?.view?.title?.text;
          }
          this.wikiCardsVerificationsService.createVerificationRequest(item.id, message, title);
        });
        break;
      }
    }
  }

  isVerificationCommand(command: string): boolean {
    return VERIFICATIONS_COMMANDS.includes(command);
  }
}
