import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIconComponent } from './u-icon.component';
import { IconDirective } from '../../directives/icon.directive';
import { ImgFallbackDirective } from '../../directives/img-fallback.directive';
import { SvgRendererDirective } from '../../directives/svg-renderer.directive';

const directives = [ImgFallbackDirective, IconDirective, SvgRendererDirective];

@NgModule({
  declarations: [...directives, UIconComponent],
  imports: [CommonModule],
  exports: [...directives, UIconComponent],
})
export class UIconModule {}
