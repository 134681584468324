import { CollectionsSourceSettings } from '../collections';
import { GoLinksSourceSettings } from '../go-links';
import { MemorySearchSettings } from '../memory-search-client/memory-search-client';
import { ResourcesFavoritesSourceSettings } from '../resources-favorites/resources-favorites-source-settings';
import { StaticCollectionItemSourceSettings } from '../static-collection-items';
export interface FavoritesSourceSettings extends MemorySearchSettings {
  type: 'favorites';
  sources: {
    resources?: ResourcesFavoritesSourceSettings;
    collections?: CollectionsSourceSettings;
    goLinks?: GoLinksSourceSettings;
    staticCollection?: StaticCollectionItemSourceSettings;
  };
}
