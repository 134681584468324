import { Injectable } from '@angular/core';
import { Omnibox, Results, Search } from '@local/client-contracts';
import { LogService } from '@shared/services';
import { RouterService } from '@shared/services/router.service';
import { Logger } from '@unleash-tech/js-logger';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { SearchGroup } from '../models/search-group';
import { FiltersService } from './filters.service';
import { HubService } from './hub.service';
import { TagsService } from './tags.service';

export type SearchParamName = 'sort' | 'innerQuery' | 'group' | 'collection' | 'layoutMode' | 'assistantId';
export const PARAMS_STATE_KEYS: Record<SearchParamName, string> = {
  sort: 'sort',
  innerQuery: 'q2',
  group: 'sg',
  collection: 'col',
  layoutMode: 'layout',
  assistantId: 'assistantId',
};
@Injectable()
export class SearchParamsService {
  private logger: Logger;
  private readonly SORT_KEYS = ['by', 'order', 'key', 'type'];
  private readonly SEPARATOR = '#';

  get searchParamsKeys() {
    return PARAMS_STATE_KEYS;
  }

  get innerQuery(): string {
    return this.getStateValue(PARAMS_STATE_KEYS.innerQuery);
  }

  set innerQuery(innerQuery: string) {
    this.hubService.setState(PARAMS_STATE_KEYS.innerQuery, innerQuery);
  }

  get collection(): string {
    return this.getStateValue(PARAMS_STATE_KEYS.collection);
  }

  set collection(collectionId: string) {
    this.hubService.setState(PARAMS_STATE_KEYS.collection, collectionId);
  }

  get layoutMode(): Results.LayoutType {
    const value: string = this.getStateValue(PARAMS_STATE_KEYS.layoutMode);
    return value as Results.LayoutType;
  }

  get assistant(): string {
    return this.getStateValue(PARAMS_STATE_KEYS.assistantId);
  }

  set layoutMode(layoutMode: Results.LayoutType) {
    this.hubService.setState(PARAMS_STATE_KEYS.layoutMode, layoutMode);
  }

  constructor(
    logService: LogService,
    private hubService: HubService,
    private filtersService: FiltersService,
    private routerService: RouterService,
    private tagService: TagsService
  ) {
    this.logger = logService.scope('SearchParamsService');
    this.initRouteGroups();
  }

  addSort(sort: Search.Sort) {
    if (!sort) {
      return this.removeSort();
    }
    const sortStr = this.getSortValue(sort);
    this.hubService.setState(PARAMS_STATE_KEYS.sort, sortStr);
  }

  getSortValue(sort: Search.Sort) {
    return this.SORT_KEYS.map((k) => sort?.[k] || '').join(this.SEPARATOR);
  }

  getSort(): Search.Sort {
    const value = this.getStateValue(PARAMS_STATE_KEYS.sort);
    if (!value) {
      return;
    }
    const split = value.split(this.SEPARATOR);
    const sort: Search.Sort = {};
    for (let index = 0; index < split.length; index++) {
      const key = this.SORT_KEYS[index];
      const value = split[index];
      if (value) {
        sort[key] = value;
      }
    }
    return isEmpty(sort) ? null : sort;
  }

  removeSort() {
    this.removeValue(PARAMS_STATE_KEYS.sort);
  }

  addGroup(group: SearchGroup) {
    if (group.type === 'filter') {
      this.filtersService.addFilter(group.filterName, group.value, group.isTagFilter ?? true, 'pre');
      return;
    } else if (group.type === 'active-page') {
      this.hubService.addActivePage(group.value, this.hubService.query, this.assistant);
      return;
    }
    this.addDecodedValue(PARAMS_STATE_KEYS.group, group);
  }

  getGroup(): SearchGroup {
    const groupBase64 = this.getStateValue(PARAMS_STATE_KEYS.group);
    if (!groupBase64) {
      return;
    }
    try {
      const groupRaw = atob(groupBase64);
      return JSON.parse(groupRaw);
    } catch (error) {
      this.logger.error('falid parsing search group', { ecodedState: groupBase64 });
    }
  }

  removeGroup() {
    this.removeValue(PARAMS_STATE_KEYS.group);
  }

  getParamsState$(params?: SearchParamName[]): Observable<any> {
    const stateKeys: string[] = params ? params.map((p) => PARAMS_STATE_KEYS[p]) : Object.values(PARAMS_STATE_KEYS || {});
    return this.hubService.stateWithParams$(stateKeys);
  }

  private initRouteGroups() {
    this.routerService.activeRoute$.subscribe(async () => {
      const group: SearchGroup = this.getGroup();
      const currentTags = this.tagService.all;
      const groupCurrentTag = currentTags.find((t) => t.type === 'group');
      if (group && !groupCurrentTag) {
        const groupTag: Omnibox.Tag = {
          icon: null,
          id: `search-group:${group.value}`,
          title: group.title || group.value || group.name,
          type: 'group',
          label: null,
        };
        this.tagService.add(groupTag);
      }
      if (!group && groupCurrentTag) {
        this.tagService.remove(groupCurrentTag.id);
      }
    });
  }

  private getStateValue(key: string) {
    return this.hubService.getState(key)?.[0];
  }

  private addDecodedValue(key: string, value: any) {
    const orderedValue = {};
    // making sure object fields are sorted so comparison will be accurate
    for (const k of Object.keys(value).sort((a, b) => a.localeCompare(b))) {
      orderedValue[k] = value[k];
    }
    const encodedValue = btoa(JSON.stringify(orderedValue));
    this.hubService.setState(key, encodedValue);
  }

  private removeValue(key: string) {
    const sortValue = this.getStateValue(key);
    if (sortValue) {
      this.hubService.setState(key, null);
    }
  }

  private getEncodedValue(key: string): Search.Sort {
    const sortBase64 = this.getStateValue(key);
    if (!sortBase64) {
      return;
    }
    try {
      const sortRaw = atob(sortBase64);
      return JSON.parse(sortRaw);
    } catch (error) {
      this.logger.error('failed parsing search sort', { ecodedState: sortBase64 });
    }
  }
}
