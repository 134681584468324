<section class="app-list-container">
  <div class="header">
    <h2 *ngIf="mode !== 'side'" class="header-title">{{ title }}</h2>
    <h2 *ngIf="mode !== 'side'" class="header-titleend">{{ titleEnd }}</h2>
  </div>

  <ol
    data-cy="app-list"
    class="app-list"
    [ngClass]="{ center: !!filter?.query, side: mode === 'side', 'grid-1-of-4-col': items.length < 3 }"
  >
    <app-list-item
      *ngFor="let item of items; trackBy: trackItems"
      class="app-item app-list-item"
      [model]="item"
      [links]="itemLinksMap[item.id]"
      [mode]="mode"
      [attr.data-cy]="'apps-item-' + item.id"
      [disabled]="isDisableItem(item)"
      [ngClass]="{ disabled: isDisableItem(item) }"
      [telemetryLocation]="telemetryLocation"
    ></app-list-item>
  </ol>
</section>
