import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { PopupRef, UiIconModel } from '@local/ui-infra';
import { RequestData, RequestsHistoryData } from './requests-history-models';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { KeyName } from '@local/ts-infra';

@Component({
  selector: 'requests-history-popup',
  templateUrl: './requests-history-popup.component.html',
  styleUrls: ['./requests-history-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestsHistoryPopupComponent implements OnDestroy {
  readonly closeIcon: UiIconModel = {
    type: 'font',
    value: 'icon-Windows-close',
  };
  private keyHandlerId: string;
  @Output() approveRequests = new EventEmitter<void>();
  requests: RequestData[] = [];
  isVerifier: boolean;

  constructor(private ref: PopupRef<RequestsHistoryPopupComponent, RequestsHistoryData>, private keyboardService: KeyboardService) {
    const { requests, isVerifier } = ref.data;
    this.requests = requests;
    this.isVerifier = isVerifier;
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 10);
  }

  ngOnDestroy(): void {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    }
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    const key = keys[0];
    if (key === 'escape') {
      this.closePopup();
      event.stopPropagation();
      return;
    }
  }

  closePopup() {
    this.ref.destroy();
  }

  onDismissRequests() {
    this.approveRequests.emit();
    this.ref.destroy();
  }
}
