import { PopupService } from '@local/ui-infra';
import { Injectable } from '@angular/core';
import { HomeTabsService } from '../home-tabs.service';
import { PostWidgetService } from '../../views/home-page/services/post-widget.service';
import { SelectedItem, TelemetryEvent } from '../../components/selected-item-popup/selected-item-popup.component';
import { EventsService } from '@shared/services';
import { HubService } from '../hub.service';
import { WorkspacesService } from '../workspaces.service';
import { HomeTabs } from '@local/client-contracts';
import { HomePinsService } from '../home-pins.service';
import { AvatarListService } from '../avatar-list.service';
import { ShowToasterService } from '../show-toaster.service';
import { SelectItemsBasePopupService } from './select-items-popup-base.service';
import { MoveWidgetToTabSelect } from '../../components/selected-item-popup/models/move-widget-to-tab-select';
import { generateId } from '@local/common-web';
import { SelectedItemPopupData } from '../../components/selected-item-popup/models/select-item-base';
import { CollectionsService } from '../collections.service';
import { filter } from 'rxjs';

@Injectable()
export class MovePostWidgetToTabPopupService extends SelectItemsBasePopupService<MoveWidgetToTabSelect> {
  readonly SETUP_DATA: SelectedItemPopupData = {
    title: 'Move to Tab',
    type: 'collection',
    description: 'Select which tab you want to pin the post to',
    placeholder: 'Select Tab',
  };

  constructor(
    protected popupService: PopupService,
    protected workspacesService: WorkspacesService,
    protected homePinsService: HomePinsService,
    protected homeTabService: HomeTabsService,
    protected showToasterService: ShowToasterService,
    protected hubService: HubService,
    protected eventsService: EventsService,
    protected avatarListService: AvatarListService,
    private postWidgetService: PostWidgetService,
    protected collectionsService: CollectionsService
  ) {
    super(popupService, workspacesService);
    this.isInvalidItem = (selectedItem: SelectedItem) => !selectedItem?.name;
    this.collectionsService.openSelectPopup$.pipe(filter((r) => r.type === 'move-post-widget')).subscribe((res) => {
      this.openSelectedItemPopup(res.data as MoveWidgetToTabSelect);
    });
  }

  protected async init() {}

  protected getPopupDataOptions(): SelectedItemPopupData {
    return this.SETUP_DATA;
  }

  protected onTelemetryEvent(event: TelemetryEvent) {
    this.eventsService.event('home_tabs.selected_tab', {
      location: { title: this.hubService.currentLocation },
      target: event?.target ?? 'tab_selection',
      label: event?.trigger ?? 'mouse_click',
    });
  }

  protected async onPrimaryButtonClick() {
    const selectedItem = this.selectedItem as SelectedItem;
    if (selectedItem.isNew) {
      selectedItem.id = generateId();
      await this.homeTabService.create(selectedItem.id, selectedItem.name);
    }
    const postWidget = this.data.widget as HomeTabs.PostWidget;
    this.homeTabService.moveWidgetToTab(postWidget, postWidget.tabId, selectedItem.id, true, true, true);
    const realTab = await this.postWidgetService.getRealTabId(selectedItem.id);
    this.postWidgetService.updateCarouselPermissions(postWidget.post.carousel, realTab);
    this.postWidgetService.updatePost(
      selectedItem.id,
      {
        ...postWidget,
        isShared: true,
        tabId: realTab,
      },
      false
    );
  }
}
