import { Injectable } from '@angular/core';
import { HttpApiClient } from '@local/common';
import { HttpClientHandlerFactory, isNativeWindow } from '@local/common-web';
import { LogService } from './log.service';
import { delay } from '@local/ts-infra';

interface LeadInfo {
  Action:
    | 'Sign Up'
    | 'Web App Started'
    | 'Lipro'
    | 'Update Session'
    | 'Interesting Apps'
    | 'Extension Installed'
    | 'KYC'
    | 'Apps Connected';
  [name: string]: string;
}

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  private http: HttpApiClient;

  static readonly URL = 'https://hook.us1.make.com/i3e1p82mwjr7urypb7izn9szgeg22eau';

  constructor(private loggerService: LogService) {
    const fac = new HttpClientHandlerFactory(
      { retry: { maxRetries: 5, maxDelay: 1000, startingDelay: 1000 }, traceLevel: 'no' },
      this.loggerService.logger,
      null,
      null
    );

    this.http = new HttpApiClient(LeadService.URL, fac.create({ credentials: 'omit' }));
  }

  private getDeviceId() {
    try {
      let blink = JSON.parse(sessionStorage.getItem('blink') || localStorage.getItem('blink'));
      return blink.did;
    } catch {}
  }

  async track(info: LeadInfo) {
    //disabled
    return;
    if (location.hostname != 'app.unleash.so') {
      return;
    }
    if (!(<any>window).__taid) {
      // waiting a few seconds to see if the taid might by updated
      await delay(5000);
    }
    const taidText = (<any>window).__taid;
    try {
      const taid = taidText ? JSON.parse(atob(taidText)) : {};

      const t = { ...info };
      t.BrowserId = taid.ulid || 'NA';
      t.DownloadId = taid.downloadId || 'NA';
      t.DeviceId = this.getDeviceId() || 'NA';
      t.Source = isNativeWindow() ? 'native' : 'web';
      await this.http.post('', t);
    } catch (e) {
      console.error('failed to track lead', { e, taidText });
      throw e;
    }
  }
}
