import { NavTree } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class NavTreeRpcInvoker implements NavTree.Service {
  @invoker
  get(id?: string): Promise<NavTree.Node> {
    return;
  }

  @invoker
  children$(id?: string): Observable<NavTree.Node[]> {
    return;
  }
}
