import { Injectable } from '@angular/core';
import { Constants } from '@local/common';
import { FlagsService } from '@shared/services/flags.service';
import { RouterService } from '@shared/services/router.service';
import { CanActivateViewGuard } from './can-activate-view.guard';
import { getNodeIdFromRoute } from '../nav-tree-node-id.resolver';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class CanActivateGoLinksGuard extends CanActivateViewGuard {
  protected flag = Constants.DISABLED_GO_LINKS_FLAG;

  constructor(routerService: RouterService, flags: FlagsService) {
    super(routerService, flags);
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let nodeId: string = getNodeIdFromRoute(route);
    if (nodeId !== 'golinks') {
      return true;
    }
    return super.canActivate(route, state);
  }
}
