import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Commands, Verifications, Wiki } from '@local/client-contracts';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventsService, LogService } from '@shared/services';
import { RouterService } from '@shared/services/router.service';
import { generateFullPrefixedURL } from '@shared/utils';
import { Logger } from '@unleash-tech/js-logger';
import { take, takeUntil, filter } from 'rxjs';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { CommandsService } from 'src/app/bar/services/commands/commands.service';
import { ResultsService } from 'src/app/bar/services/results.service';
import { WikiCardsVerificationsService } from 'src/app/bar/services/wikis/wiki-cards-verifications.service';
import { WikiCardsService } from 'src/app/bar/services/wikis/wiki-cards.service';
import { CollectionPopupService } from '../../services/collection-popup.service';
import { WikisService } from 'src/app/bar/services/wikis/wikis.service';
import { Breadcrumb } from '@shared/services/breadcrumbs.service';
import { WikiItemSelectionPopupService } from '../../services/wiki-item-selection-popup.service';
import { generateId } from '@local/common-web';
import { SwitchViewState, WikiCardPageMode, WikiCardPermission, WikiCardType, WikiCardViewMode } from '../../models/wiki-card-model';
import { InvokeModel } from '../../helpers/collection-context-menu-helper';
import { WikiDraftsService } from 'src/app/bar/services/wikis/wiki-drafts.service';
import { HubService } from 'src/app/bar/services/hub.service';

@UntilDestroy()
@Component({
  selector: 'wiki-card-header-line',
  templateUrl: './wiki-card-header-line.component.html',
  styleUrls: ['./wiki-card-header-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WikiCardHeaderLineComponent {
  private logger: Logger;
  private _wikiCard: Wiki.Card;
  private _wikiCardPermissionRole: WikiCardPermission;

  @Input() collection: Wiki.WikiCollection;
  @Input() set wikiCard(val: Wiki.Card) {
    this._wikiCard = val;
  }

  @Input() updatedVerification: Verifications.Verification;
  @Input() pageMode: WikiCardPageMode;
  @Input() breadcrumbsItems: Breadcrumb[];
  @Input() isNewMode: boolean;
  @Input() showCloseButton: boolean;
  @Input() viewMode: WikiCardViewMode;
  @Input() set wikiCardPermissionRole(val: WikiCardPermission) {
    this._wikiCardPermissionRole = val;
  }

  //Outputs
  @Output() closePopup = new EventEmitter<boolean>();
  @Output() duplicateWikiCard = new EventEmitter<boolean>();
  @Output() openExternal = new EventEmitter<boolean>();
  @Output() moved = new EventEmitter<Wiki.Path[]>();
  @Output() switchView = new EventEmitter<SwitchViewState>();

  get wikiCard() {
    return this._wikiCard;
  }

  get wikiCardPermissionRole() {
    return this._wikiCardPermissionRole;
  }

  get showVerificationIndications(): boolean {
    return this.collection?.policy?.disabled === false;
  }

  @HostBinding('attr.pageMode')
  get pageModeAttr() {
    return this.pageMode;
  }

  constructor(
    public collectionsService: CollectionsService,
    private cdr: ChangeDetectorRef,
    public resultsService: ResultsService,
    private wikiCardsService: WikiCardsService,
    public collectionPopupService: CollectionPopupService,
    logService: LogService,
    private eventsService: EventsService,
    private wikiCardsVerificationsService: WikiCardsVerificationsService,
    private routerService: RouterService,
    private commandsService: CommandsService,
    private wikisService: WikisService,
    private wikiDraftsService: WikiDraftsService,
    private wikiItemSelectionPopupService: WikiItemSelectionPopupService,
    private hubService: HubService
  ) {
    this.logger = logService.scope('WikiCardHeaderLineComponent');
  }

  changeFocus(focus: string) {
    this.collectionsService.onFocusChange = focus;
  }

  ///ContextMenu
  async onInvoke(event: InvokeModel) {
    const { item, trigger } = event;
    this.eventsService.event('collections.collection_actions', {
      target: item.id,
      label: trigger === 'context_menu_click' ? 'mouse_click' : 'keyboard',
      location: { title: this.getTelemetryLocation() },
    });

    const label = item.id as string;

    this.eventsService.event(this.pageMode === 'inline' ? 'collections.menu_action_preview' : 'collections.menu_action', {
      label,
      location: { title: this.getTelemetryLocation() },
    });

    const commandType: string = item.command.type;
    switch (commandType) {
      case 'make_a_copy':
        this.duplicateWikiCard.emit();
        break;
      case 'copy-url':
        this.copyUrl();
        break;
      case 'delete':
        this.onDeletePopup('card');
        break;
      case 'delete-draft':
        this.onDeletePopup('draft');
        break;
      case 'verification-details':
        this.wikiCardsService.openVerificationDetails(
          this.wikiCard.id,
          this.collection,
          this.updatedVerification?.policy,
          this.updatedVerification,
          this.wikiCard.title
        );
        break;
      case 'verify':
        this.changeVerificationStatus('Verified');
        break;
      case 'unverify':
        this.changeVerificationStatus('Unverified');
        break;
      case 'request-verification':
        this.openRequestVerificationPopup();
        break;
      case 'move-card':
        this.moveCard();
        break;
      case 'view-publish':
        this.openCard();
        break;
      default:
        this.logger.warn('missing command type onContextMenuCommand');
        break;
    }
  }

  private openCard() {
    const url = this.getCardUrl();
    this.hubService.openUrl(url, null, true, this.pageMode === 'window');
  }

  private onDeletePopup(type: WikiCardType) {
    const deletePopupRef = this.collectionPopupService.openAreYouSureToRemoveItemPopup(null, 1, type);
    deletePopupRef.compInstance.primaryButton.pipe(untilDestroyed(this), take(1)).subscribe(async () => {
      this.onRemove(type);
      if (this.pageMode === 'full') {
        this.routerService.navigateByUrl('cards');
      } else {
        this.closePopup.emit(true);
      }
    });
  }

  private async onRemove(type: WikiCardType) {
    if (type === 'draft') {
      await this.wikiDraftsService.delete(this.wikiCard.id);
    } else {
      await this.wikisService.deleteWikiItem({ itemId: this.wikiCard.id, parentId: this.collection?.id });
    }
    this.cdr.markForCheck();
  }

  private moveCard() {
    const id = generateId();
    this.collectionsService.openSelectPopup$.next({
      id,
      data: null,
      type: 'move-to-wiki-popup',
      options: { doSetup: true, data: { context: { item: this.wikiCard } } },
    });
    this.wikiItemSelectionPopupService.destroySelectPopup$
      .pipe(
        untilDestroyed(this),
        filter((r) => r.processId === id)
      )
      .subscribe((res) => {
        if (res.newPath) {
          this.moved.emit(res.newPath);
        }
      });
  }

  private getCardUrl() {
    let title = this.wikiCard.title;
    if (!this.wikiCard.publishedTime) {
      title = this.wikiCard.draft?.title;
    }
    return this.wikiCardsService.getCardUrl(title, this.wikiCard.id);
  }

  private copyUrl(): Promise<any> {
    const url = this.getCardUrl();
    return this.commandsService.executeCommand({
      type: 'copy-clipboard',
      value: generateFullPrefixedURL(`/${url}`, 'path'),
    } as Commands.CopyClipboard);
  }

  private changeVerificationStatus(status: Verifications.VerificationStatus) {
    const areYouSureUnverify = this.wikiCardsService.changeVerificationStatus(status === 'Unverified' ? 'Unverify' : 'Verify');
    areYouSureUnverify.compInstance.primaryButton.pipe(takeUntil(areYouSureUnverify.destroy$)).subscribe(() => {
      const prevStatus = this.updatedVerification.status;
      this.updatedVerification.status = status;
      this.wikiCardsService.updateVerificationStatus(status, [this.wikiCard.id], true).catch(() => {
        this.updatedVerification.status = prevStatus;
      });
      this.cdr.markForCheck();
    });
  }

  private openRequestVerificationPopup() {
    const requestVerificationPopupRef = this.wikiCardsVerificationsService.openRequestVerificationPopup();
    requestVerificationPopupRef.compInstance.sendMessage.pipe(takeUntil(requestVerificationPopupRef.destroy$)).subscribe((message) => {
      this.updatedVerification.requests.push({ accountId: this.collectionsService.workspace?.accountId, message, createdAt: Date.now() });
      this.wikiCardsVerificationsService.createVerificationRequest(this.wikiCard.id, message, this.wikiCard.title);
      this.cdr.markForCheck();
    });
  }

  private getTelemetryLocation() {
    const cardId = this.wikiCard?.id ?? 'new';
    return `cards/Wiki/${cardId}`;
  }
}
