import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UTieredMenuComponent } from './u-tiered-menu.component';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [UTieredMenuComponent],
  imports: [CommonModule, FormsModule, TieredMenuModule],
  exports: [UTieredMenuComponent],
})
export class UTieredMenuModule {}
