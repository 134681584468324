import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { PopupRef } from '@local/ui-infra';

export type StaticItemsType = 'link' | 'file' | 'header';

@Component({
  selector: 'new-static-item-popup',
  templateUrl: './new-static-item-popup.component.html',
  styleUrls: ['./new-static-item-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewStaticItemPopupComponent {
  readonly imageMaxSize: number = 5 * 1024 * 1024; //5mb
  readonly staticItemsOptions: { text: string; icon: string; type: StaticItemsType }[] = [
    {
      text: 'Link',
      icon: 'icon-link2',
      type: 'link',
    },
    {
      text: 'Header',
      icon: 'icon-header',
      type: 'header',
    },
  ];
  selectedIndex: number = 0;
  fileItemIndex: number;

  @Output() onClickEvent = new EventEmitter<any>();
  @Output() onUploadEvent = new EventEmitter<any>();
  @Output() onUploadErrorEvent = new EventEmitter<string>();

  constructor(private ref: PopupRef<NewStaticItemPopupComponent, any>, private cdr: ChangeDetectorRef) {
    this.fileItemIndex = this.staticItemsOptions.length;
  }

  onClickOption(option) {
    this.onClickEvent.emit(option.type);
    this.ref.destroy();
  }

  onHoverItem(index: number) {
    this.selectedIndex = index;
    this.cdr.markForCheck();
  }

  onUploadError(event) {
    this.onUploadErrorEvent.emit(event);
    this.ref.destroy();
  }

  onUpload(event) {
    this.onUploadEvent.emit(event);
    this.ref.destroy();
  }
}
