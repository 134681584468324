import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Style } from '@local/client-contracts';
import { LogService } from '@shared/services';
import { Logger } from '@unleash-tech/js-logger';
import { WEB_SEARCH_ITEMS } from '../views/hub/shared/sidebar/menu-items';

@Injectable()
export class WebSearchResolver  {
  items = WEB_SEARCH_ITEMS;
  private logger: Logger;
  constructor(logService: LogService) {
    this.logger = logService.scope('WebSearchResolver');
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): { icon: Style.EntityIcon<Style.EntityIconType>; title: string } {
    const engine = route.paramMap.get('engine');
    if (!engine) {
      this.logger.warn('No engine found in url params');
      return;
    }

    const item = this.items.find((i) => i.page.endsWith(engine));

    if (!item) {
      this.logger.warn(`${engine} wasn't found in web-search constants, can't resolve title and icon`);
      return;
    }

    return { icon: item.icon, title: item.title };
  }
}
