import { Filters, MemorySearch, Search } from '@local/client-contracts';
import { LogService } from '@shared/services';
import { ApplicationsService } from '@shared/services/applications.service';
import { LinksService } from '@shared/services/links.service';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { FiltersService } from '../../../filters.service';
import { MemorySearchClient } from '../memory-search-client/memory-search-client';
import { ResourceMemorySourceSettings } from './resources-memory-source-settings';
import { PeopleService } from 'src/app/bar/views/preview/people-preview/services/people.service';

export abstract class ResourcesMemorySearchClient<T extends ResourceMemorySourceSettings> extends MemorySearchClient<T> {
  private linkNames: { [id: string]: string[] } = {};
  private linkIds: string[] = [];

  constructor(
    logService: LogService,
    memorySearchService: MemorySearchService,
    protected filtersService: FiltersService,
    protected appsService: ApplicationsService,
    protected linksService: LinksService,
    protected peopleService: PeopleService,
    sortTypes?: Search.SortType[],
    filters?: string[]
  ) {
    super(logService, memorySearchService, sortTypes || [], [...(filters || []), 'app', 'type', 'service', 'account']);
    linksService.visible$.subscribe((links) => {
      const linkNames: { [name: string]: string[] } = {};
      for (const link of links || []) {
        linkNames[link.name] = linkNames[link.name] || [];
        linkNames[link.name].push(link.id);
      }
      this.linkNames = linkNames;
      this.linkIds = (links || []).map((l) => l.id);
    });
  }

  async filter(items: any[], settings: T): Promise<any[]> {
    const activeFilters: Filters.ActiveFilters = {};
    const assistantId = settings.assistantId;
    const excludeFilters = (await this.filtersService.generateExcludeFilters(settings.filters?.excludeFilters, assistantId)) || {};
    const combinedFilters: Filters.Values = settings.filters?.preFilters || {};
    for (const key of Object.keys(settings.filters?.postFilters || [])) {
      const preFilters = combinedFilters[key];
      const postFilters = settings.filters?.postFilters[key];
      combinedFilters[key] = [...(preFilters || []), ...(postFilters || [])];
    }

    for (const e of Object.entries(combinedFilters)) {
      activeFilters[e[0]] = e[1].map((x) => ({ exclusive: false, value: x }));
    }

    if (activeFilters.account || excludeFilters.account) {
      let allowedLinkIds = null;
      if (activeFilters.account) {
        allowedLinkIds = new Set(activeFilters.account.map((name) => this.linkNames[name.value] || []).flat());
      } else {
        allowedLinkIds = new Set(this.linkIds.filter((id) => !excludeFilters.account.includes(id)));
      }
      items = items.filter((item) => {
        const linkId = this.getFilterValues(item.data)?.linkId;
        return allowedLinkIds.has(linkId);
      });
    }

    if (activeFilters.type || activeFilters.service || excludeFilters?.type || excludeFilters?.service) {
      let typesArr: string[];
      let excludeTypesArr: string[] = [];
      if (activeFilters.type || activeFilters.service) {
        typesArr = await this.filtersService.getRelevantTypes(activeFilters, assistantId);
      }
      if (excludeFilters?.type && !activeFilters.type) {
        excludeTypesArr = excludeFilters.type;
      }
      return (items = items.filter((item) => {
        const type = this.getFilterValues(item.data).type;
        return typesArr ? typesArr.includes(type) && !excludeTypesArr.includes(type) : !excludeTypesArr.includes(type);
      }));
    }

    if (activeFilters?.app || excludeFilters?.app) {
      const apps = await this.appsService.all();
      const appIds = apps.filter((x) => (activeFilters['app'] || []).find((y) => y.value == x.name)).map((x) => x.id);
      const excludeAppsIds = excludeFilters.app || [];
      items = items.filter((item) => {
        const appId = this.getFilterValues(item.data).appId;
        return appIds.length ? appIds.includes(appId) && !excludeAppsIds.includes(appId) : !excludeAppsIds.includes(appId);
      });
    }
    return items;
  }

  protected getFilterValues(item: any): { appId: string; linkId: string; type: string } {
    return item.resource;
  }

  protected adjustItemIcons(items: MemorySearch.Item[], sourceSettings: ResourceMemorySourceSettings) {
    const iconAdjustments = sourceSettings.iconAdjustments || 'Default';
    if (iconAdjustments === 'Skip' || !this.hasSingleAppFilter(sourceSettings)) return;

    for (const i of items.filter((i) => i.data.type == 'result')) {
      const resultItem = <Search.ResultItem>i.data;
      if (iconAdjustments === 'Remove-Icon') {
        resultItem.view.icon = null;
        continue;
      }
      if (resultItem?.view?.iconOverlay) {
        resultItem.view.icon = resultItem.view.iconOverlay;
        resultItem.view.iconOverlay = null;
      }
    }
  }

  private hasSingleAppFilter(sourceSettings: ResourceMemorySourceSettings) {
    const preFilters = sourceSettings.filters?.preFilters || {};
    return preFilters.app?.length === 1;
  }
}
