import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Accounts, Workspace } from '@local/client-contracts';
import { EMAIL_REGEX, extractNameAndDomain, isKey, keyCodes } from '@local/ts-infra';
import { ChipsItem, UiIconModel } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventsService } from '@shared/services';
import { AccountsService } from '@shared/services/accounts.service';
import { copyToClipboard } from '@shared/utils';
import { Tooltip } from 'primeng/tooltip';
import { combineLatest } from 'rxjs';
import { WorkspacesService } from 'src/app/bar/services';

export type WorkspaceInvitePopupData = {
  items: ChipsItem[];
};
@UntilDestroy()
@Component({
  selector: 'workspace-invite',
  templateUrl: './workspace-invite.component.html',
  styleUrls: ['./workspace-invite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceInviteComponent implements OnInit {
  @HostBinding('style.width')
  get width() {
    return this.isPopup ? '502px' : '388px';
  }

  @HostBinding('style.padding')
  get padding() {
    return this.isPopup ? '32px 32px 24px 32px' : '0';
  }

  @HostBinding('attr.is-popup')
  get isPopupAttr() {
    return this.isPopup;
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (isKey(event, keyCodes.escape)) {
      this.closePopup();
    }
    if (isKey(event, keyCodes.backspace)) {
      event.stopPropagation();
    }
    event.stopPropagation();
  }

  @ViewChild(Tooltip) tooltip: Tooltip;

  private _componentType: 'popup' | 'signInPage' = 'popup';
  public get componentType(): 'popup' | 'signInPage' {
    return this._componentType;
  }
  @Input() public set componentType(value: 'popup' | 'signInPage') {
    this._componentType = value;
    this.isPopup = value === 'popup';
  }

  private _maxEmails = 50;
  get maxEmails(): number {
    return this._maxEmails;
  }
  @Input() set maxEmails(value: number) {
    this._maxEmails = value;
    this.maxEmailsErrorMag = `Unfortunately, we currently don't support adding more than ${this.maxEmails} emails at a time. Try using the invitation link`;
  }
  @Output() inviteEvent: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter();
  @Output() itemsChanged: EventEmitter<ChipsItem[]> = new EventEmitter<ChipsItem[]>();
  @Output() upgradeButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  private get locationTitle() {
    return `${location.pathname.startsWith('/setup') ? 'onboarding.' : ''}invite_to_workspace`;
  }

  readonly closeIcon: UiIconModel = {
    type: 'font',
    value: 'icon-Windows-close',
  };

  private workspace: Workspace.Workspace;
  isPopup: boolean;
  currentEmail: string = null;
  emails: ChipsItem[] = [];
  inviteBtnDisabled = true;
  tooltipText = '';
  inviteLink: string;
  chipsStyle = { width: '100%' };
  chipsValidationRegex: RegExp = EMAIL_REGEX;
  errorMessage: string;
  isInvalidTagError = false;
  disableCopyLink = true;
  showMaxPlanUsersBanner = false;
  planAvailableSeats: number;

  readonly invalidEmailErrorMsg = 'One of the email addresses you entered is not valid';
  maxEmailsErrorMag: string;
  maxUsersTrial: string;
  readonly invalidDomain = 'Invitations only allowed for';

  get maxTags() {
    if (this.planAvailableSeats === null) {
      return null;
    }
    return Math.min(this.planAvailableSeats, this.maxEmails);
  }

  constructor(
    private eventsService: EventsService,
    private workspacesService: WorkspacesService,
    private cdr: ChangeDetectorRef,
    private accountsService: AccountsService
  ) {}

  ngOnInit(): void {
    this.eventsService.event('pageview', { location: { title: this.locationTitle } });
    this.workspacesService.getInviteLink().then((l) => {
      this.inviteLink = l;
      this.disableCopyLink = !this.inviteLink;
      this.cdr.markForCheck();
    });
    combineLatest([this.workspacesService.current$, this.accountsService.all$])
      .pipe(untilDestroyed(this))
      .subscribe(([workspace, accounts]: [Workspace.Workspace, Accounts.WorkspaceAccount[]]) => {
        if (workspace && accounts) {
          this.workspace = workspace;
          const planSeats = workspace.plan?.maxSeats;
          this.planAvailableSeats = planSeats ? planSeats - accounts.length : null;
          this.maxUsersTrial = this.getMaxUsersTrialMessage();
        }
        this.cdr.markForCheck();
      });
  }

  private getMaxUsersTrialMessage() {
    if (this.planAvailableSeats == null) {
      return '';
    }
    if (this.planAvailableSeats > 0) {
      return `Please note that only ${this.planAvailableSeats} more users can join the workspace for this plan.`;
    }
    return 'You’ve exceeded the amount of users who can join the workspace';
  }

  onChipsChanged(items: ChipsItem[]) {
    if (this.emails.length !== items.length) {
      this.currentEmail = '';
    }
    this.emails = items;
    this.validateTags();
    this.itemsChanged.emit(this.emails);
    this.cdr.markForCheck();
  }

  onChipsInputValueChanged(value: string) {
    this.currentEmail = value;
    this.cdr.markForCheck();
  }

  sendInvite() {
    if (!this.emails?.length && !this.currentEmail) {
      return;
    }
    this.onExitTelemetry(true);

    if (this.workspace.settings.invites?.blockUnknownDomains && this.workspace.domains.length > 0 && this.currentEmail?.length) {
      const domain = extractNameAndDomain(this.currentEmail)?.domain;
      if (!this.workspace.domains?.includes(domain)) {
        this.errorMessage = `${this.invalidDomain} ${this.workspace.domains.map((s) => '@' + s).toString()} ${
          this.workspace.domains.length > 1 ? 'domains' : 'domain'
        }.`;
        return;
      }
    }

    const emails = this.emails.filter((item) => item.isValid).map((item) => item.value);
    if (this.currentEmail) {
      emails.push(this.currentEmail);
    }
    const allowedDomains = this.workspace.domains;
    if (this.workspace.settings.invites?.blockUnknownDomains && allowedDomains?.length > 0) {
      for (const email of emails) {
        const domain = extractNameAndDomain(email)?.domain;
        if (!allowedDomains.includes(domain)) {
          this.errorMessage = `${this.invalidDomain} ${allowedDomains.map((s) => '@' + s).toString()} ${
            this.workspace.domains.length > 1 ? 'domains' : 'domain'
          }.`;
          return;
        }
      }
    }

    this.inviteEvent.emit(emails);
    this.emails = [];
    this.itemsChanged.emit(this.emails);
    this.closePopup();
  }

  private onExitTelemetry(sent?: boolean) {
    this.eventsService.event('invite_to_workspace', {
      category: 'interaction.click',
      name: 'invite_to_workspace',
      target: sent ? 'send' : 'skip',
      location: { title: this.locationTitle },
    });
  }

  skip() {
    this.onExitTelemetry(false);
    this.inviteEvent.emit(null);
  }

  closePopup() {
    this.cancelEvent.emit();
  }

  async onCopyInviteClick() {
    this.eventsService.event('invite_to_workspace_link', {
      location: { title: this.locationTitle },
    });
    this.updateTooltipText('Link copied to clipboard');
    return copyToClipboard(this.inviteLink);
  }

  private updateTooltipText(text: string) {
    this.tooltipText = text;
    setTimeout(() => {
      this.tooltip.show();
    }, 0);

    setTimeout(() => {
      this.tooltip.hide();
      this.tooltipText = '';
      this.cdr.markForCheck();
    }, 1000);
  }

  // validation order: Trial maximum users > Maximum tags > Email validation
  private validateTags() {
    const emailsLength = this.emails?.length || 0;
    let planMaxUsersError = this.planAvailableSeats !== null && emailsLength >= this.planAvailableSeats;
    const maxTagsError = emailsLength + 1 > this.maxEmails;
    const invalidTagError = !!this.emails?.find((i) => !i.isValid);
    this.errorMessage = planMaxUsersError ? this.maxUsersTrial : null;
    if (!this.errorMessage) {
      this.errorMessage = maxTagsError ? this.maxEmailsErrorMag : null;
    }
    if (!this.errorMessage) {
      this.errorMessage = invalidTagError ? this.invalidEmailErrorMsg : null;
    }
    this.isInvalidTagError = invalidTagError;
    this.showMaxPlanUsersBanner = planMaxUsersError;
    this.cdr.markForCheck();
  }

  onUpgradeButtonClicked() {
    this.upgradeButtonClicked.emit();
  }
}
