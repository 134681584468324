import { Injectable } from '@angular/core';
import { Tickets } from '@local/client-contracts';
import { TicketsRpcInvoker } from '@local/common';
import { Logger } from '@unleash-tech/js-logger';
import { LogService, ServicesRpcService } from '.';

@Injectable({
  providedIn: 'root',
})
export class TicketsService {
  private service: Tickets.Service;
  private logger: Logger;

  constructor(services: ServicesRpcService, log: LogService) {
    this.logger = log.scope('TicketsService');
    this.service = services.invokeWith(TicketsRpcInvoker, 'tickets');
  }

  refresh(name: string) {
    return this.service.refresh(name);
  }

  getTicket$(name: string) {
    return this.service.getTicket$(name);
  }
}
