import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'u-image-cropper',
  templateUrl: './u-image-cropper.component.html',
  styleUrls: ['./u-image-cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UImageCropperComponent {
  @Output() onCroppedImageReady = new EventEmitter<ImageCroppedEvent>();
  @Input() imageFile: any;
  @Input() aspectRatio: number = 4 / 1;
  @Input() maintainAspectRatio = true;
  @Input() cropperOutputType: 'blob' | 'base64' = 'base64';

  imageCropped(event: ImageCroppedEvent) {
    this.onCroppedImageReady.emit(event);
  }
}
