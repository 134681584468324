import { AppPopupData } from 'src/app/bar/components/app-popup/app-popup.component';

export class ErrorQuestionnaireConstants {
  static readonly CLOSE_SUMMARY_MESSAGE = 'Make sure you download the RFP file <br> before exiting';
  static readonly CLOSE_SUMMARY_QUESTION = 'Are you sure you want to leave?';
  static readonly ERROR_FAILED_MESSAGE =
    'It appears that some worksheets <br> contain duplicate lines. Please correct <br> them and run the process again.';
  static readonly ERROR_FAILED_TITLE = 'Running process failed';
  static readonly ERROR_FAILED_PROCESS_DATA: AppPopupData = {
    message: 'It appears that an error occurred while <br> processing the file',
    showButtons: true,
    showErrorIcon: true,
    rightButtonStyle: { size: 100 },
    onlyPrimaryButton: true,
    content: {
      title: 'Something went wrong',
      primaryButton: 'Try again',
    },
    messageStyle: { fontSize: '14px' },
    titleStyle: { 'font-weight': '700' },
  };
  static readonly ERROR_MAPPING_DATA: AppPopupData = {
    message: 'A new process can not be started until <br> the existing one has been completed <br> or stopped',
    showButtons: true,
    showErrorIcon: true,
    leftButtonStyle: { size: 100 },
    onlySecondaryButton: true,
    content: {
      title: 'An RFP is already in progress',
      secondaryButton: 'Got it',
    },
    messageStyle: { fontSize: '14px' },
    titleStyle: { 'font-weight': '700' },
  };
}
