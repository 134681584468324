import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiIconModel } from '@local/ui-infra';

@Component({
  selector: 'error-overlay',
  templateUrl: './error-overlay.component.html',
  styleUrls: ['./error-overlay.component.scss'],
})
export class ErrorOverlayComponent {
  @Input() displayText: string;
  @Input() titleText = 'Something went wrong';
  @Output() back = new EventEmitter();
  @Output() again = new EventEmitter();

  @Input() showBack = true;
  @Input() showTryAgain = true;

  redoIcon: UiIconModel = {
    type: 'font',
    value: 'icon-redo2',
  };

  public goBack() {
    this.back.emit();
  }

  public tryAgain() {
    this.again.emit();
  }
}
