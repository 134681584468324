import { HomePins, Permissions } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class HomePinsRpcInvoker implements HomePins.Service {
  @invoker
  get all$(): Observable<HomePins.HomePin[]> {
    return;
  }

  @invoker
  get(id: string): Promise<HomePins.HomePin> {
    return;
  }

  @invoker
  create(homePin: HomePins.HomePin, privatePin?: boolean): Promise<HomePins.HomePin> {
    return;
  }

  @invoker
  createMany(items: HomePins.HomePin[], privatePin: boolean = true) {
    return;
  }

  @invoker
  update(homePin: HomePins.HomePin, actions: HomePins.UpdateAction[], privatePin?: boolean): Promise<void> {
    return;
  }

  @invoker
  delete(id: string): Promise<void> {
    return;
  }

  @invoker
  deleteByPinedId(pinedId: string, type: HomePins.HomePinType): Promise<void> {
    return;
  }

  @invoker
  refresh(): Promise<void> {
    return;
  }
}
