import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalErrorHandler } from '../global-error-handler';

@Injectable({ providedIn: 'root' })
export class ExternalLinkResolver  {
  constructor(private errorHandler: GlobalErrorHandler) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> {
    return new Observable((observer) => {
      const { url } = route.queryParams;

      const endNavigation = () => observer.complete(); // Completing without calling observer.next() will cancel the navigation
      if (!url) {
        this.errorHandler.error = 'ExternalLinkResolver: No URL provided in query params';
        endNavigation();
        return;
      }
      window.open(url);

      endNavigation();
    });
  }
}
