import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, HostListener, Inject, Input, OnInit } from '@angular/core';
import { Links, Preferences } from '@local/client-contracts';
import { STYLE_SERVICE } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StyleService } from '@shared/services/style.service';
import { State } from '@shared/types/account-icon-states';

export const isInitials = (x: any): x is Links.LinkIcon<Links.LinkIconType> => x.type === 'initials';

const fillMap = {
  light: {
    default: '0.7',
    hover: '0.5',
    selected: '0.7',
    disabled: '0.7',
  },
  dark: {
    default: '0.25',
    hover: '0.4',
    selected: '0.25',
    disabled: '0.25',
  },
};

const borderMap = {
  light: '#FFFFFF',
  dark: '#292F35',
};

@UntilDestroy()
@Component({
  selector: 'account-icon',
  templateUrl: './account-icon.component.html',
  styleUrls: ['./account-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountIconComponent implements OnInit {
  private _model: Links.LinkIcon<Links.LinkIconType>;
  _state: State;
  color: Links.Colors;

  @Input() size: string;
  @Input() set state(state: State) {
    this._state = state;
  }
  @Input() set model(value: Links.LinkIcon<Links.LinkIconType>) {
    this._model = value;
    this.onChangeModel();
  }
  get model(): Links.LinkIcon<Links.LinkIconType> {
    return this._model;
  }
  @Input() initials: string;

  get state() {
    return this._state;
  }

  theme: Preferences.Theme;

  private prevState: State;
  private border: string;
  private backgroundColor: string;

  constructor(@Inject(STYLE_SERVICE) private styleService: StyleService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.onChangeModel();
  }

  private onChangeModel() {
    if (this.model?.type === 'initials') {
      const { color, initials } = this.model.value as { color: `ml-c${number}`; initials: string };
      this.color = color;
      this.initials = initials;
    }
    if (!this.state) {
      this.setColors();
      this.subscribeToThemeChange();
    } else {
      this.prevState = this.state;
      this.theme = this.styleService.currentScheme;
      this.setColors();
      this.subscribeToThemeChange();
    }
  }

  subscribeToThemeChange() {
    this.styleService.theme$.pipe(untilDestroyed(this)).subscribe((theme) => {
      this.theme = theme;
      this.setColors();
    });
  }

  setColors() {
    const fill = !this.state ? fillMap?.[this.theme]?.selected : fillMap?.[this.theme]?.[this.state];
    this.backgroundColor =
      this.theme === 'light'
        ? `linear-gradient(0deg, rgba(255, 255, 255, ${fill}), rgba(255, 255, 255, ${fill})), var(--${this.color})`
        : `linear-gradient(0deg, rgba(0, 0, 0, ${fill}), rgba(0, 0, 0, ${fill})), var(--${this.color})`;
    this.border = this.prevState === 'selected' || !this.state ? `var(--${this.color})` : borderMap[this.theme];
    this.cdr.markForCheck();
  }

  @HostListener('mouseover')
  onMouseOver() {
    if (!this.state || this.state === 'disabled') return;
    this.state = 'hover';
    this.setColors();
  }
  @HostListener('mouseout')
  onMouseOut() {
    if (!this.state || this.state === 'disabled') return;
    this.state = this.prevState;
    this.setColors();
  }

  @HostBinding('class') get class() {
    return this.color;
  }

  @HostBinding('style')
  get style() {
    const style: any = {
      width: `calc(${this.size} + 2px)`,
      height: `calc(${this.size} + 2px)`,
      background: this.backgroundColor,
      border: `${this.border} 1px solid`,
      color: `var(--${this.color})`,
      fontSize: `calc(${this.size}  / 1.5)`,
    };
    if (this.state === 'disabled') style.opacity = 0.5;
    return style;
  }
}
