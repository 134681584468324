import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

export const EMPTY_IMG = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

@Directive({
  selector: 'img[fallback]',
})
export class ImgFallbackDirective {
  @Input() fallback = '';

  @Input() @HostBinding('style.visibility') visibility: string;

  constructor(private hostRef: ElementRef) {}
  @HostListener('error', ['$event'])
  async onError(): Promise<void> {
    if (!this.fallback) {
      this.hostRef.nativeElement.src = EMPTY_IMG;
      return;
    }
    this.hostRef.nativeElement.classList.add('fallback');
    try {
      const response = await fetch(this.fallback);
      this.hostRef.nativeElement.src = response.status !== 200 ? EMPTY_IMG : this.fallback;
    } catch (error) {
      this.hostRef.nativeElement.src = EMPTY_IMG;
    }
  }

  @HostListener('load', ['$event'])
  onLoad() {
    const element = this.hostRef?.nativeElement;
    if (element?.src.includes(this.fallback) || element?.src === EMPTY_IMG) {
      return;
    }
    element?.classList?.remove('fallback');
  }
}
