<ng-container *ngIf="model">
  <entity-icon *ngIf="model.type !== 'parent'" [model]="icon" [size]="'14px'" class="content-icon"></entity-icon>

  <h2
    data-cy="search-popup-header"
    *ngIf="model.title"
    class="content-title"
    [innerHTML]="model.title | highlight: model?.highlights ?? []"
    [class.has-subtitle]="!!subtitle || model.inlineIcon"
  ></h2>
  <u-icon *ngIf="model?.inlineIcon" [model]="model.inlineIcon" [styles]="{ width: '12px', height: '12px' }"></u-icon>

  <h3 [class.hidden]="!subtitle" #subtitleRef class="content-subtitle" [innerHTML]="subtitle | highlight: model?.highlights ?? []"></h3>
  <span *ngIf="model.shortcut" class="content-shortcut" [innerHTML]="model.shortcut | keyIcon"></span>
</ng-container>
