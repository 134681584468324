import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Commands, Search } from '@local/client-contracts';
import { UntilDestroy } from '@ngneat/until-destroy';
import { fade } from '@shared/animations/fade.animations';
import { EventInfo } from '@shared/services';
import { Subject } from 'rxjs';
import { DisplayItemData } from 'src/app/bar/views/results';
import { PreviewComponent } from '../../../model/preview-component';
import { PreviewMode } from '../../../model/preview-mode';

@UntilDestroy()
@Component({
  selector: 'mail-preview-container',
  templateUrl: './mail-preview-container.component.html',
  styleUrls: ['./mail-preview-container.component.scss'],
  animations: [fade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailPreviewContainerComponent implements PreviewComponent {
  size$: Subject<PreviewMode> = new Subject<PreviewMode>();
  private _model: Search.ResultResourceItem;
  @Input() size: Commands.PreviewState;
  @Input() isLauncher?: boolean = false;
  @Input() telemetrySearch: Partial<EventInfo>;
  @Input() set model(val: Search.ResultResourceItem & DisplayItemData) {
    if (!val || val.action?.type !== 'mail') return;
    this._model = val;
    this.cdr.markForCheck();
  }

  @Output() loaded = new EventEmitter<any>();

  get model(): Search.ResultResourceItem {
    return this._model;
  }

  constructor(private cdr: ChangeDetectorRef) {}
}
