import { EventEmitter, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Walkthrough } from '@local/client-contracts';
import { isEmbed } from '@local/common-web';
import { eventToKeys } from '@local/ts-infra';
import { PopupRef, PopupService, STYLE_SERVICE } from '@local/ui-infra';
import { TourComponent } from '@shared/components/walkthrough/tour/tour.component';
import { StyleService } from '@shared/services/style.service';
import { isClickInElement } from '@shared/utils';
import { Logger } from '@unleash-tech/js-logger';
import { BehaviorSubject, fromEvent, merge, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EventInfo, EventsService, LogService, ServicesRpcService } from '.';
import { AppService } from './app.service';
import { WalkthroughRpcInvoker } from './invokers/walkthrough.rpc-invoker';
import { KeyboardService } from './keyboard.service';
import { FlagsService } from './testim-flags.service';
import { WindowService } from './window.service';

export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
  radius: number;
}

export interface TourActionData {
  id: string;
  action: Walkthrough.StepButtonAction;
}

const HIGHLIGHT_CLASS = 'walkthrough-target';
const HIGHLIGHT_CLASS_APPS = 'walkthrough-target-apps';
const HIGHLIGHT_CLASS_APPS_LAST = 'walkthrough-target-apps-last';
@Injectable({
  providedIn: 'root',
})
export class WalkthroughService {
  private logger: Logger;
  private invoker: WalkthroughRpcInvoker;
  private renderer: Renderer2;
  private ref: PopupRef<TourComponent, Walkthrough.Tour>;
  private sub: Subscription;
  private highlightedEl: HTMLElement[];
  private style: { [key: string]: string };
  private isEmbed = isEmbed();
  private isLauncher: boolean;

  tourChange = new EventEmitter<void>();
  private _activeTour$: BehaviorSubject<Walkthrough.Tour> = new BehaviorSubject(null);
  set activeTour(tour: Walkthrough.Tour) {
    this._activeTour$.next(tour);
  }
  get activeTour() {
    return this._activeTour$.value;
  }

  get activeTour$() {
    return this._activeTour$;
  }

  skipAll(skip: boolean) {
    return this.invoker.skipAll(skip);
  }

  private activeId: string;
  private location: string;

  constructor(
    private services: ServicesRpcService,
    log: LogService,
    rendererFactory: RendererFactory2,
    private popupService: PopupService,
    @Inject(STYLE_SERVICE) private styleService: StyleService,
    private eventsService: EventsService,
    private windowService: WindowService,
    private keyboardService: KeyboardService,
    private appService: AppService,
    private flagsService: FlagsService
  ) {
    this.logger = log.scope('WalkthroughService');
    this.invoker = this.services.invokeWith(WalkthroughRpcInvoker, 'walkthrough');
    this.renderer = rendererFactory.createRenderer(null, null);
    this.highlightedEl = [];
    this.initStyle();
    this.appService.windowStyle$.subscribe((b) => {
      this.isLauncher = b != 'standard';
    });
  }

  initStyle(): void {
    this.styleService.style$.subscribe((style) => {
      this.style = style;
    });
  }

  async getTour(id: string) {
    return; // walkthrough is currently disabled.
    if (this.isEmbed || this.isLauncher) return;
    if (this.activeId === id) return this.activeTour;
    const tour: Walkthrough.Tour = await this.invoker.getTour(id);
    return tour;
  }

  start(tour: Walkthrough.Tour, location: string) {
    if (!tour || tour.status?.completed || this.isEmbed || this.isLauncher || this.flagsService.isFlagOn('noWalkthrough')) return;
    this.activeId = tour.id;
    this.activeTour = tour;
    this.location = location;
    this.createBackdrop(true);
    this.show();
  }

  get activeStep(): Walkthrough.Step {
    return this.activeTour?.steps[this.activeTour.status.activeStep];
  }

  totalSteps(): number {
    return this.activeTour.steps.length;
  }

  addSteps(steps: Walkthrough.Step[]): void {
    this.activeTour.steps = [...(this.activeTour.steps || []), ...steps];
  }

  back(): void {
    this.activeTour.status.activeStep--;
    this.clearHighlights();
    this.createBackdrop();
  }

  complete(): void {
    this.activeTour.status = { completed: true, activeStep: this.activeTour?.status?.activeStep || 0 };
    this.saveProgress();
    setTimeout(() => {
      this.hide();
      this.clearHighlights();
      this.removeBackdrop(true);
    }, 300);
  }

  private saveProgress() {
    this.invoker.saveProgress(this.activeId, this.activeTour);
  }

  private hide(): void {
    this.ref?.destroy();
  }

  next(): void {
    this.activeTour.status.activeStep++;
    this.clearHighlights();
    this.createBackdrop();
    this.saveProgress();
  }

  private clearHighlights(): void {
    if (!this.highlightedEl.length) return;
    this.highlightedEl.forEach((el) => {
      el.classList.contains(HIGHLIGHT_CLASS) && el.classList.remove(HIGHLIGHT_CLASS);
      el.classList.contains(HIGHLIGHT_CLASS_APPS) && el.classList.remove(HIGHLIGHT_CLASS_APPS);
      el.classList.contains(HIGHLIGHT_CLASS_APPS_LAST) && el.classList.remove(HIGHLIGHT_CLASS_APPS_LAST);
    });
  }

  show(): void {
    const tour: Walkthrough.Tour = this.activeTour;
    setTimeout(() => {
      if (tour !== this.activeTour) return;
      this.ref = this.popupService.open<TourComponent, Walkthrough.Tour>({ right: 24, bottom: 24 }, TourComponent, this.activeTour, {
        hasBackdrop: false,
      });
      this.ref.compInstance.action.subscribe((data: TourActionData) => this.onAction(data));
      this.ref.compInstance.show.subscribe((id: string) => this.sendShowEvent(id));
      this.animateOnClickOut();
      this.handleKeyboard();
      this.windowService?.setResizable(false);
    }, 300);
  }

  private destroy(): void {
    if (!this.sub) return;
    this.sub.unsubscribe();
    this.sub = null;
    this.ref = null;
    this.activeId = null;
    this.activeTour = null;
    this.windowService?.setResizable(true);
    this.keyboardService.preventKeyboard = false;
  }

  private onAction(data: TourActionData, target: 'mouse_click' | 'keyboard' = 'mouse_click'): void {
    this.sendInteractionEvent(data);
    switch (data.action) {
      case 'next':
        this.next();
        break;
      case 'back':
        this.back();
        break;
      case 'cancel':
        this.hide();
        break;
      case 'complete':
        this.complete();
        break;
    }
    this.sendConversionEvent(data, target);
  }

  baseElements: { [id: string]: HTMLElement };

  private createBackdrop(first = false): void {
    if (document.getElementById('backdrop')) this.removeBackdrop();
    const targets = this.activeStep?.highlightElements;
    if (!targets) return;
    const rects: HTMLElement[] = this.createTargetRects(targets);

    const { svg, fillRect, defs, maskedRect } = this.getBaseHtmlElements();

    if (first) svg.style.opacity = '0';

    const mask: HTMLElement = this.renderer.createElement('mask', 'svg');
    mask.setAttribute('id', 'backdrop-mask');
    this.renderer.appendChild(mask, fillRect);
    rects.forEach((rect) => this.renderer.appendChild(mask, rect));

    if (defs.firstChild) defs.removeChild(defs.firstChild);
    this.renderer.appendChild(defs, mask);

    this.renderer.appendChild(svg, defs);
    this.renderer.appendChild(svg, maskedRect);

    const body = document.getElementsByTagName('body')?.[0];
    this.renderer.appendChild(body, svg);
    if (!first) return;
    setTimeout(() => {
      svg.style.opacity = '1';
    }, 100);
  }

  private getBaseHtmlElements(): { [id: string]: HTMLElement } {
    if (this.baseElements) return this.baseElements;

    const svg: HTMLElement = this.renderer.createElement('svg', 'svg');
    svg.setAttribute('id', 'backdrop');
    svg.classList.add('backdrop-svg');

    const defs: HTMLElement = this.renderer.createElement('defs', 'svg');

    const maskedRect: HTMLElement = this.renderer.createElement('rect', 'svg');
    maskedRect.classList.add('masked');

    const fillRect: HTMLElement = this.renderer.createElement('rect', 'svg');
    fillRect.setAttribute('width', '100%');
    fillRect.setAttribute('height', '100%');
    fillRect.setAttribute('fill', '#fff');
    this.baseElements = { svg, fillRect, defs, maskedRect };
    return this.baseElements;
  }

  private removeBackdrop(last = false): void {
    const backdrop: HTMLElement = document.getElementById('backdrop');

    if (!backdrop) return;

    if (last) backdrop.style.opacity = '0';
    else {
      backdrop.remove();
      return;
    }

    setTimeout(() => {
      backdrop?.remove();
    }, 200);
  }

  private createTargetRects(targets: string[]): HTMLElement[] {
    let rects: HTMLElement[] = [];
    this.highlightedEl = [];
    targets.forEach((target) => {
      let element: HTMLElement;
      if (target.startsWith('.')) {
        element = document.getElementsByClassName(target.replace('.', ''))?.[0] as HTMLElement;
      } else if (target.startsWith('#')) {
        element = document.getElementById(target.replace('#', ''));
      } else if (target.startsWith('@')) {
        //special cases
        if (target.includes('apps')) {
          rects = this.createAllAppsRect();
        } else {
          return;
        }
      } else {
        this.logger.warn('Highlight target is not ID or Class', target);
        return;
      }

      if (!element) return;

      this.highlightedEl.push(element);
      element.classList.add(HIGHLIGHT_CLASS);

      const rectEl: HTMLElement = this.createRectHtmlElement(element);
      rects.push(rectEl);
    });
    return rects;
  }

  private createRectHtmlElement(element: HTMLElement) {
    const computedStyle: CSSStyleDeclaration = getComputedStyle(element);
    const rect: Rect = this.getRectBounds(element, computedStyle);
    const rectEl: HTMLElement = this.renderer.createElement('rect', 'svg');
    rectEl.setAttribute('x', rect.x.toString());
    rectEl.setAttribute('y', rect.y.toString());
    rectEl.setAttribute('width', rect.width.toString());
    rectEl.setAttribute('height', rect.height.toString());
    rectEl.setAttribute('rx', rect.radius.toString());
    return rectEl;
  }

  private createAllAppsRect(): HTMLElement[] {
    const elements = document.getElementsByClassName('group-apps');
    const rectElements: HTMLElement[] = [];
    const bottomMenu = document.getElementById('bottom-menu');
    const bottomMenuClientRect = bottomMenu.getBoundingClientRect();
    const lastIndex: number = elements.length - 1;
    Array.from(elements).some((element: HTMLElement, index: number) => {
      const elClientRect = element.getBoundingClientRect();
      // check if element not visible in DOM
      this.highlightedEl.push(element);
      const className: string = index === lastIndex ? HIGHLIGHT_CLASS_APPS_LAST : HIGHLIGHT_CLASS_APPS;
      element.classList.add(className);
      const rectEl: HTMLElement = this.createRectHtmlElement(element);
      rectElements.push(rectEl);
      if (bottomMenuClientRect.y <= elClientRect.y + elClientRect.height) return true;
    });

    return rectElements;
  }

  private getRectBounds(element: HTMLElement, style: CSSStyleDeclaration): Rect {
    const domRect: DOMRect = element.getBoundingClientRect();

    const radius = parseInt(style.borderRadius);

    const rect: Rect = {
      x: domRect.left,
      y: domRect.top,
      width: domRect.width,
      height: domRect.height,
      radius,
    };

    return rect;
  }

  private animateOnClickOut(): void {
    if (!this.ref) return;

    if (this.sub) this.logger.warn('For some reason, there is unhandled click out subscription');
    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter((event) => {
          if (!this.ref) return false;
          const clickInside = isClickInElement(this.ref.panelRef.nativeElement, event);
          return !clickInside;
        })
      )
      .subscribe((event) => {
        event.stopImmediatePropagation();
        this.ref?.compInstance?.animateButton();
      });

    const destroy$ = merge(this.ref.destroy$, this.ref.close$).subscribe(() => this.destroy());
    this.sub.add(destroy$);
  }

  private handleKeyboard(): void {
    if (!this.ref) return;
    this.keyboardService.preventKeyboard = true;
    this.sub.add(
      fromEvent<KeyboardEvent>(document, 'keydown').subscribe((event) => {
        const keys = eventToKeys(event);

        if (keys.includes('enter')) {
          this.handleEnterPress();

          return;
        }

        event.stopImmediatePropagation();
        event.preventDefault();
        this.ref?.compInstance?.animateButton();
      })
    );
  }

  private handleEnterPress(): void {
    const id: string = this.activeStep.id;
    const action: Walkthrough.StepButtonAction =
      this.activeTour.status.activeStep === this.activeTour.steps.length - 1 ? 'complete' : 'next';
    this.onAction({ id, action }, 'keyboard');
    this.tourChange.emit();
  }

  private sendInteractionEvent(data: TourActionData): void {
    const eventMetadata: Partial<EventInfo> = {
      name: 'walkthrough',
      target: data.action,
      label: data.id,
      location: { title: this.location },
    };
    this.eventsService.event('walkthrough.interaction', eventMetadata);
  }

  private sendConversionEvent(data: TourActionData, target: 'keyboard' | 'mouse_click'): void {
    const eventMetadata: Partial<EventInfo> = {
      name: data.action,
      target,
      label: data.id,
      location: { title: this.location },
    };
    this.eventsService.event('walkthrough.conversion', eventMetadata);
  }

  private sendShowEvent(stepId: string): void {
    const eventMetadata: Partial<EventInfo> = {
      name: 'show',
      label: stepId,
      location: { title: this.location },
    };
    this.eventsService.event('walkthrough.show', eventMetadata);
  }
}
