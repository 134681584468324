import { MemorySearch, Search } from '@local/client-contracts';
import { LogService } from '@shared/services';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { getDisplayHeader } from '@shared/utils/header-builder.util';
import { combineLatest, firstValueFrom, map } from 'rxjs';
import { GoToItem, HeaderItem, SearchResults } from 'src/app/bar/views';
import { GoToSourceSettings } from '.';
import { GoToService } from '../../../go-to.service';
import { MemorySearchClient } from '../memory-search-client/memory-search-client';
import { SearchRequest } from '../search-request';

export class GoToSearchClient extends MemorySearchClient<GoToSourceSettings> {
  private readonly ALLOWED_CHILDREN_TYPES = ['wikis'];
  constructor(private goToService: GoToService, logService: LogService, memorySearchService: MemorySearchService) {
    super(logService, memorySearchService, ['Alphabetical']);
    this.logger = logService.scope('goto-search-client');
  }

  async getInput(request: SearchRequest<GoToSourceSettings>): Promise<MemorySearch.Item[]> {
    const combineItems = combineLatest([this.goToService.helpItems$, this.goToService.gotoItems$]).pipe(
      map(([helpItems, goToItems]) => [...goToItems, ...helpItems]),
      map((items) => items.filter((i) => i.id !== 'search'))
    );
    const sorting = request.sourceSettings.sorting;
    const items: GoToItem[] = await firstValueFrom(combineItems);
    const mitems = items.map((i) => ({
      data: i,
      searchText: this.buildTextForSearch(i),
      sortValue: sorting?.by === 'Alphabetical' ? i.title : null,
    }));

    return this.getDefaultItemsOrder(mitems);
  }

  async getOutput(items: MemorySearch.Item[]): Promise<Search.ResultItem[]> {
    return items.map((i) => i.data);
  }

  addHeaders(request: SearchRequest<GoToSourceSettings>, items: SearchResults[], resultCount: number, totalResults: number): void {
    const settings = request.sourceSettings;
    const { title, titleEnd } = getDisplayHeader({ title: settings.header?.title, titleEnd: settings.header?.titleEnd }, totalResults);
    const header: HeaderItem = {
      type: 'header',
      clickable: settings.header.clickable,
      origin: 'goto',
      title: title || 'Best match',
      titleEnd,
      group: settings.showHeaderButton
        ? {
            name: 'goto',
            title: 'Go To',
          }
        : undefined,
    };
    const footer: HeaderItem = {
      type: 'header',
      clickable: true,
      origin: `footer-${settings.type}`,
      title: 'See All',
      isFooter: true,
      selectable: true,
      group: settings.showHeaderButton
        ? {
            name: 'goto',
            title: 'Go To',
          }
        : undefined,
    };

    items.unshift(header);
    if (totalResults > settings.maxCount) {
      items.push(footer);
    }
  }

  private buildTextForSearch({ title, subtitle }: GoToItem): string {
    const searchableSubtitle = subtitle ? (typeof subtitle === 'string' ? subtitle : subtitle.map((b) => b.title).join(' ')) : undefined;
    return [title ?? '', searchableSubtitle ?? ''].join(' ').toLowerCase();
  }

  private getDefaultItemsOrder(items: MemorySearch.Item[]): MemorySearch.Item[] {
    const orderItems: MemorySearch.Item[] = [];
    items
      .filter((item) => !item.data.parentId || this.ALLOWED_CHILDREN_TYPES.includes(item.data.parentId))
      .sort((prev, current) => prev.searchText.localeCompare(current.searchText))
      .forEach((parent) => {
        orderItems.push(parent); // ...this.getChildrenItems(parent, items));
      });
    const helpItems: MemorySearch.Item[] = items
      .filter((item) => item.data.parentId === 'help_items')
      .sort((prev, current) => prev.searchText.localeCompare(current.searchText));
    return [...orderItems, ...helpItems];
  }

  private getChildrenItems(parent: MemorySearch.Item, items: MemorySearch.Item[]): MemorySearch.Item[] {
    if (!items.filter((n) => n?.data.parentId === parent.data.id).length) {
      return [parent];
    }
    const orderItems = [parent];
    items
      .filter((n) => n.data.parentId === parent.data.id)
      .sort((prev, current) => prev.searchText.localeCompare(current.searchText))
      .forEach((item) => orderItems.push(...this.getChildrenItems(item, items)));
    return orderItems;
  }
}
