import { Pipe, PipeTransform } from '@angular/core';
import { capitalCase } from '@local/ts-infra';

@Pipe({
  name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string, firstWordOnly = false): string {
    if (!value) return;

    if (firstWordOnly) {
      const v = value.split(' ');
      v[0] = capitalCase(v[0]);
      return v.join(' ');
    }

    return value
      .split(' ')
      .map((w) => capitalCase(w))
      .join(' ');
  }
}
