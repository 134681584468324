import { ManualPromise, performanceCheckpoint } from '@local/common';
import { EmbedService } from '@shared/embed.service';
import { ReplaySubject } from 'rxjs';
export const servicesWorker: ManualPromise<Worker | ServiceWorker | MessagePort> = new ManualPromise();
export let servicesWorkerType: 'sw' | 'private' | 'shared';
export const platformUpdatePending$: ReplaySubject<boolean> = new ReplaySubject(1);

export async function applyUpdateWeb() {
  const regs = await navigator.serviceWorker?.getRegistrations();
  if (regs[0]?.active?.postMessage && regs[0]?.waiting) {
    regs[0].active.postMessage({ type: 'unleash:sw:kill' });
  }

  if (regs[0]?.waiting?.postMessage) {
    regs[0].waiting.postMessage({ type: 'unleash:sw:update' });
  } else {
    console.error('No registration of sw or waiting one', !!regs[0], !!regs[0]?.waiting);
  }
}

const unpacked = new URL(location.href).pathname.startsWith('/unpacked');
const serviceWorkerScript = '/services-sw' + (unpacked ? '-unpacked' : '') + '.js';

export async function initServicesWorker(embed: EmbedService) {
  performanceCheckpoint('init-services-worker');
  servicesWorker.resolve((<any>window).__servicesWorker?.port || (<any>window).__servicesWorker);
  servicesWorkerType = (<any>window).__servicesWorkerType;
  tryConnectToSw();
}

let trySwAttempt = 0;

async function handleSwState() {
  console.log('sw handle status');

  const regs = await navigator.serviceWorker?.getRegistrations();
  const reg = regs?.[0];
  if (!reg) {
    console.log('sw no registration');
    return;
  }

  console.log(
    'sw objects waiting: ' + (reg.waiting != null) + ' installing: ' + (reg.installing != null) + ' active: ' + (reg.active != null)
  );

  if (reg.waiting) {
    console.log('sw waiting suggest user to update');
    platformUpdatePending$.next(true);
  }

  if (reg.active) {
    reg.active.postMessage({ type: 'unleash:sw:clean' });
    reg.active.postMessage({ type: 'unleash:sw:install' });
  }

  if (reg.installing) {
    console.log('sw installing');

    reg.installing.addEventListener('statechange', (e) => {
      console.log('sw installing changed state to: ' + (<any>e.target).state);
      handleSwState();
    });
  }
}
async function tryConnectToSw() {
  try {
    const reg = await (<any>window).__serviceWorkerReg;

    reg?.addEventListener('updatefound', () => {
      console.log('sw update found');
      handleSwState();
    });
  } catch (e) {
    if (++trySwAttempt <= 3) {
      (<any>window).__serviceWorkerReg = navigator.serviceWorker.register(serviceWorkerScript, { scope: '/' });
      setTimeout(tryConnectToSw, 1000);
    } else {
      console.error('failed with sw  registration!! ' + e);
    }
    return;
  }
  console.log('sw initial state');
  handleSwState();
}
