import { Collections } from '@local/client-contracts';
import { MemorySearchSettings } from '../memory-search-client/memory-search-client';

export interface StaticCollectionItemSourceSettings extends MemorySearchSettings {
  type: 'static-collection-items';
  collectionId?: string;
  collection?: Collections.Collection;
  convertItem?: boolean;
  isFavoriteItem?: boolean;
  stateView?: boolean;
  onlyCount?: true;
}
