import { Filters, Search } from '@local/client-contracts';
import { EventInfo, LogService } from '@shared/services';
import { BrowserHistoryService } from '@shared/services/browser-history.service';
import { Logger } from '@unleash-tech/js-logger';
import { TelemetryTrigger } from 'src/app/bar/views';
import { SearchClient } from '../search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { SearchResponseType } from '../search-response-type';
import { BrowserHistorySourceSettings } from './browser-history-source-settings';

export class BrowserHistorySearchClient implements SearchClient<BrowserHistorySourceSettings> {
  private logger: Logger;
  constructor(private bhService: BrowserHistoryService, private logService: LogService) {
    this.logger = this.logService.scope('browser-history-search-client');
  }

  supportsSort(sort: Search.Sort): boolean {
    return false;
  }

  supportsFilters(filters: Filters.Values): boolean {
    return false;
  }

  search(request: SearchRequest<BrowserHistorySourceSettings>, response: SearchResponse): SearchResponseType {
    return this.innerSearch(request, response);
  }

  nextPage(request: SearchRequest<BrowserHistorySourceSettings>, response: SearchResponse, trigger: TelemetryTrigger): Promise<void> {
    return;
  }

  destroy(): void {
    return;
  }

  private async innerSearch(request: SearchRequest<BrowserHistorySourceSettings>, response: SearchResponse) {
    const query = request.query;
    const sourceSettings = request.sourceSettings;
    const searchResponse = await this.bhService.search({ query, maxResults: sourceSettings.maxCount });

    response.items = searchResponse.results.map((i) => {
      return { type: 'visit', site: { ...i }, resource: null, id: i.url };
    });
    response.complete(true);
  }

  getTelemetryEndEvent(response: SearchResponse): Partial<EventInfo>[] {
    return;
  }
}
