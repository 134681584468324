<div class="assistant-wrapper">
  <div *ngIf="loading">
    <loader-overlay [styles]="{ backgroundColor: 'transparent', backdropFilter: 'none' }"> </loader-overlay>
  </div>
  <div *ngIf="!loading" class="assistant-container">
    <div class="scrolling-area" [ngClass]="{ 'scrolling-area-readonly': assistant?.readOnly }">
      <ng-scrollbar visibility="hover">
        <cdk-virtual-scroll-viewport itemSize="40" ngScrollbarView smoothScroll scrollViewport [class.virtual-scroll]="true">
          <div class="limit-content">
            <div class="assistant-header">
              <u-emoji-picker
                *ngIf="experienceType === 'general'; else regularIcon"
                class="header-icon"
                [closeOnSelect]="true"
                (selectedEmoji)="onSelectedEmoji($event)"
                [model]="assistant.icon"
                [emojiUnicode]="assistant?.properties?.emoji"
                [readonly]="assistant.readOnly"
                [spriteEmojiSize]="24"
                [placeholderFontSize]="'14px'"
              ></u-emoji-picker>
              <ng-template #regularIcon>
                <u-icon
                  *ngIf="assistant?.icon"
                  class="header-icon"
                  [model]="assistant.icon"
                  [styles]="{ fontSize: '24px', height: '24px', width: '24px' }"
                ></u-icon>
              </ng-template>
              <div
                #assistantName
                *ngIf="
                  experienceType !== 'rfp' && experienceType !== 'salesforce' && experienceType !== 'general';
                  else questionnaireSection
                "
                class="header"
                data-cy="assistant-header"
                [pTooltip]="titleTooltip"
                tooltipPosition="top"
                tooltipStyleClass="u-tooltip"
              >
                {{ title }}
              </div>
              <ng-template #questionnaireSection>
                <questionnaire-section [title]="title" [disabledButton]="!isEqualAssistant" [assistant]="assistant" (changeTitle)="onChangeName($event)"></questionnaire-section>
              </ng-template>
              <span
                #assistantSubName
                *ngIf="subtitle"
                class="subtitle"
                [pTooltip]="subtitleTooltip"
                tooltipPosition="top"
                tooltipStyleClass="u-tooltip"
                >{{ subtitle }}</span
              >
            </div>
            <div class="description-container" *ngIf="experienceType === 'general'; else headerInfo">
              <u-edit-text
                *ngIf="!assistant.readOnly; else descriptionReadonly"
                class="no-state inline-input-description"
                data-cy="input-description"
                [placeholder]="assistant.properties?.description ?? 'Add description (optional)'"
                (onTextChange)="formInputChanged.next($event)"
                [allowFocus]="componentFocused === AssistantComponentFocused.DESCRIPTION"
                (outsideClick)="
                  componentFocused === AssistantComponentFocused.DESCRIPTION &&
                    (componentFocused = AssistantComponentFocused.WIKI_COLLECTION)
                "
                (onEnterPressed)="componentFocused = AssistantComponentFocused.WIKI_COLLECTION"
                (onClick)="componentFocused = AssistantComponentFocused.DESCRIPTION"
                [focusNoColor]="true"
                [ignoreEnter]="true"
                [textSelected]="true"
                [maxLength]="280"
              ></u-edit-text>
              <ng-template #descriptionReadonly>
                <div *ngIf="!!assistant?.properties?.description" class="assitant-description-readonly">
                  {{ assistant?.properties?.description }}
                </div>
              </ng-template>
            </div>
            <ng-template #headerInfo>
              <div class="assistant-header-info">
                {{ assistant.readOnly ? assistantContent.headerInfoReadonly[experienceType] : assistantContent.headerInfo[experienceType] }}
              </div>
            </ng-template>

            <ng-container [ngSwitch]="experienceType">
              <ng-container *ngSwitchCase="'slack'">
                <slack-preferences [assistant]="assistant" (updateProperties)="onChangeProperties($event)"></slack-preferences>
              </ng-container>
              <ng-container *ngSwitchCase="'teams'">
                <teams-preferences [assistant]="assistant" (updateProperties)="onChangeProperties($event)"></teams-preferences>
              </ng-container>
              <ng-container *ngSwitchCase="'general'">
                <general-preferences
                  [assistant]="assistant"
                  (updateProperties)="onChangeProperties($event)"
                  [disablePreferences]="disableWikisAndApps"
                ></general-preferences>
              </ng-container>
              <ng-container *ngSwitchCase="'rfp'">
                <rfp-preferences [assistant]="assistant" (updateProperties)="onChangeProperties($event)"></rfp-preferences>
              </ng-container>
              <ng-container *ngSwitchCase="'salesforce'">
                <ticket-preferences [assistant]="assistant" (updateProperties)="onChangeProperties($event)"></ticket-preferences>
              </ng-container>
              <ng-container *ngSwitchCase="'zendesk'">
                <ticket-preferences [assistant]="assistant" (updateProperties)="onChangeProperties($event)"></ticket-preferences>
              </ng-container>
            </ng-container>
            <div
              class="assistant-warning"
              *ngIf="assistantContent.warningsContent[experienceType]?.[assistant.permissionRole];else noWarning"
            >
              <u-icon
                [model]="{ type: 'font', value: 'icon-exclamation-mark-circle' }"
                [styles]="{ color: 'var(--yellow-600)', fontSize: '16px' }"
              >
              </u-icon>
              <span>{{ assistantContent.warningsContent[experienceType][assistant.permissionRole] }}</span>
            </div>
            <ng-container *ngIf="disableWikis; else wikiSection">
              <div [style]="{ 'margin-bottom': '16px' }"></div>
            </ng-container>

            <ng-template #noWarning>
              <div [style]="{ 'margin-bottom': '20px' }"></div>
            </ng-template>
            <ng-template #wikiSection>
              <div class="assistant-sub-header">
                <div class="title">{{ assistantContent.wikiCollections }}</div>
              </div>
              <div class="assistant-wiki-header">
                {{ assistantContent.wikiCollectionInfo[experienceType] }}
              </div>
              <div class="assistant-wiki-collections" data-cy="assistant-wiki-collections">
                <wiki-collection-section
                  (selectedCollection)="onSelectedCollection($event)"
                  (componentFocusedFinish)="componentFocusedFinish()"
                  [assistant]="assistant"
                  [componentFocused]="componentFocused === AssistantComponentFocused.WIKI_COLLECTION"
                  [objectsSharedWithCreator]="objectsSharedWithCreator$"
                  [draftMode]="draftMode"
                  [disable]="disableWikisAndApps"
                ></wiki-collection-section>
              </div>
            </ng-template>
            <div class="assistant-sub-header with-button">
              <div class="assistant-apps-header">
                <div class="title">{{ assistantContent.apps }}</div>
              </div>
              <u-button
                *ngIf="showAddQuery"
                [disabled]="!allowAddQuery"
                [class.disabled]="!allowAddQuery"
                class="add-query"
                type="secondary"
                [label]="assistantContent.addApp"
                (click)="addQuery()"
                fontIcon="plus"
              >
              </u-button>
            </div>
            <ng-container *ngTemplateOutlet="channelDataSourceRef"></ng-container>
            <ng-container>
              <div class="filter-ghost" *ngIf="initLoadFiltersApp && !draftMode; else assistantApp">
                <div class="item-container" *ngFor="let i of 8 | fill; index as index">
                  <div class="ghost"></div>
                </div>
              </div>
              <ng-template #assistantApp>
                <div class="assistant-apps" data-cy="assistant-apps" *ngIf="dataSourceExist || draftMode; else dataSourceNotExist">
                  <div
                    #resultsFiltersBoxLine
                    class="results-filters-box-line"
                    data-cy="app-line"
                    *ngFor="let sourceView of filterSourceViews; let index = index"
                  >
                    <div class="filter">
                      <results-filters-box
                        class="results-filters-box-item"
                        [id]="'filters-' + index"
                        [filters]="sourceView?.filterValues"
                        (filterChange)="onFilterChange($event, index)"
                        [index]="index"
                        (onValuesUpdated)="filtersBoxValuesUpdated($event, index)"
                        (onClick)="onClickFilterBox($event, index)"
                      >
                      </results-filters-box>
                      <assistant-filter-buttons
                        [index]="index"
                        [filterSourceView]="sourceView"
                        [filtersDataSourcesCount]="assistant.filtersDataSources?.length + (assistant.channelDataSource ? 1 : 0)"
                        [permissionRole]="assistant.permissionRole"
                        (addPriority)="addPriority(index)"
                        (onRemoveLine)="onRemoveLine(index)"
                      >
                      </assistant-filter-buttons>
                    </div>
                    <div *ngIf="sourceView.warningMessage" class="reconnect-link">
                      {{ sourceView.warningMessage }}
                    </div>
                  </div>
                  <u-button
                    *ngIf="showAddQuery"
                    class="add-query add-query-light"
                    [label]="assistantContent.addApp"
                    [class.disabled]="!allowAddQuery"
                    type="secondary-light"
                    fontIcon="plus"
                    (click)="addQuery()"
                  ></u-button>
                </div>
              </ng-template>
            </ng-container>
            <ng-template #dataSourceNotExist>
              <div class="assistant-no-data">
                {{ assistantContent.noAppsMessage }}
              </div>
            </ng-template>
          </div>
        </cdk-virtual-scroll-viewport>
      </ng-scrollbar>
    </div>

    <div class="assistant-footer">
      <div class="button-content">
        <u-button
          *ngIf="assistantContent.learnMoreUrl[experienceType]"
          [label]="'Learn More'"
          class="link-button"
          type="link"
          (mousedown)="openLearnMore(assistantContent.learnMoreUrl[experienceType])"
        ></u-button>
        <div class="assistant-footer-actions" *ngIf="!assistant.readOnly">
          <u-button
            [label]="assistantContent.cancel"
            [disabled]="isEqualAssistant"
            type="secondary"
            data-cy="assistant-cancel"
            (click)="cancel()"
          ></u-button>
          <u-button
            [label]="loadingSaveButton ? ' ' : assistantContent.save"
            [loading]="loadingSaveButton"
            [disabled]="isEqualAssistant"
            type="primary"
            data-cy="assistant-save"
            (click)="save()"
          ></u-button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #channelDataSourceRef>
  <div *ngIf="channelDataSource" class="results-filters-box-line">
    <div class="filter">
      <div class="channel-filters-container">
        <results-filters-box
          *ngIf="channelFilters"
          class="results-filters-box-item"
          [filters]="channelFilters"
          (filterChange)="onChannelFilterChange($event)"
        >
        </results-filters-box>
      </div>
      <u-toggle-button
        class="channel-toggle-button"
        [checked]="!channelDataSource.disabled"
        type="small"
        (onChange)="changeChannelToggle()"
        [pTooltip]="channelDataSource.disabled ? 'Open to see answers from this channel' : 'Close to disable answers from this channel'"
        [tooltipDisabled]="!!assistant.readOnly"
        tooltipPosition="top"
        tooltipStyleClass="u-tooltip"
        [disabled]="!!assistant.readOnly"
      ></u-toggle-button>
    </div>
  </div>
</ng-template>
