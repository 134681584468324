import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Commands, Downloads, Results, Search, SessionInfo, Style } from '@local/client-contracts';
import { getIconByExtension } from '@local/ts-infra';
import { isEmbed } from '@local/common-web';
import { PopupRef, UiIconModel } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ContextMenuComponent, ContextMenuData, ContextMenuService } from '@shared/components';
import { getFileType } from '@shared/consts/file-types';
import { EmbedService } from '@shared/embed.service';
import { EventInfo, EventsService, LogService } from '@shared/services';
import { DownloadsService } from '@shared/services/downloads.service';
import { FlagsService } from '@shared/services/flags.service';
import { ResourcesService } from '@shared/services/resources.service';
import { SessionService } from '@shared/services/session.service';
import { getWidthBreakpointScreen, isChangeFavoriteStatusCommand, isDownloadUrlCommand } from '@shared/utils';
import { getDownloadIcon, getIconModel } from '@shared/utils/set-icon.util';
import { Logger } from '@unleash-tech/js-logger';
import Cookies from 'js-cookie';
import moment from 'moment';
import { Subject, firstValueFrom } from 'rxjs';
import { ResultCommandService } from 'src/app/bar/services/commands/result-command.service';
import { ContextMenuInvokeSource } from 'src/app/bar/services/commands/results-item-context-menu-helper';
import { ResultsResourceItemContextMenuHelper } from 'src/app/bar/services/commands/results-resource-item-context-menu-helper';
import { FavoritesService } from 'src/app/bar/services/favorites.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { PreviewService } from 'src/app/bar/services/preview.service';
import { GlobalErrorHandler } from '../../../../../global-error-handler';
import { BlobPreviewService } from '../../../collections-page/services/blobs-preview.service';
import { InvokeCommand } from '../../../results/models/invoke-command.model';
import {
  CommandContext,
  DisplayItemData,
  FileViewTitle,
  ResultContextMenuItem,
  Select,
  TelemetryTrigger,
} from '../../../results/models/results-types';
import { Action } from '../../../results/models/view-filters';
import { ResultCommandBuilder } from '../../../results/services/result-command-builder.service';
import { buildFileViewItemTitle, isEnabledSummary } from '../../../results/utils/results.util';
import { PreviewMode } from '../../model/preview-mode';
import { FilePreview, FilePreviewType, ResultPreviewData } from '../file-preview-types';
import { FilePreviewService } from '../services/file-preview.service';

@UntilDestroy()
@Component({
  selector: 'file-preview-container',
  templateUrl: './file-preview-container.component.html',
  styleUrls: ['./file-preview-container.component.scss', '../../file-preview-shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilePreviewContainerComponent implements FilePreview, OnDestroy, AfterViewInit, OnInit {
  fileType: FilePreviewType;
  previewSize: 'small' | 'medium' | 'large';
  readonly INDICATE_PREVIEW_ICON: UiIconModel = {
    type: 'font',
    value: 'icon-eye',
  };
  readonly INDICATE_SUMMARY_ICON: UiIconModel = {
    type: 'font',
    value: 'icon-board-list',
  };
  readonly ACTIONS_WIDTH = 32;
  readonly TITLE_ICON = 52;
  readonly XS_HEADER_WIDTH = 130;
  headerWidth: number;

  @ViewChild('subtitle', { read: ElementRef }) subtitleRef: ElementRef;
  @ViewChild('title', { read: ElementRef }) titleRef: ElementRef;
  @ViewChild('actions', { read: ElementRef }) actionsRef: ElementRef;
  @Output() invoke = new EventEmitter<InvokeCommand>();
  @Output() onClosePopup = new EventEmitter<any>();
  @Output() loaded = new EventEmitter<any>();

  @Input() telemetrySearch: Partial<EventInfo>;
  private _isLauncher: boolean;
  @Input() set isLauncher(val: boolean) {
    this._isLauncher = val;
  }

  get isLauncher() {
    return this._isLauncher;
  }

  private get searchId() {
    return this.telemetrySearch?.search?.searchId;
  }

  calcHeaderWidth() {
    let width = this.ACTIONS_WIDTH;
    if (this.isDownloadEnabled) width += this.ACTIONS_WIDTH;
    if (this.size !== 'side') {
      if (this.isAvailableSummary(this.model?.action)) width += this.ACTIONS_WIDTH;
    }
    if (this.model && this.allowOpenUrl) width += this.ACTIONS_WIDTH;
    if (this.fileType === 'code') width += this.ACTIONS_WIDTH;
    this.headerWidth = this.previewWidth - width;
  }

  get isLargeBreakpoint(): boolean {
    return ['large', 'extra-large'].includes(getWidthBreakpointScreen());
  }

  private _size: PreviewMode;
  @Input() set size(val: PreviewMode) {
    this._size = val;
    this.popup = val === 'popup';
    this.cdr.markForCheck();
  }

  get size() {
    return this._size;
  }

  private _model: Search.ResultResourceItem & DisplayItemData;
  @Input() set model(val: Search.ResultResourceItem & DisplayItemData) {
    if (JSON.stringify(val) === JSON.stringify(this.model)) return;
    if (!val || !['files', 'collection-file'].includes(val.action?.type)) return;
    this.loadingError = false;
    this._model = val;
    if (this.model.action?.type === 'collection-file') {
      this._model = this.blobPreviewService.getBlobPreviewItem(val);
    }
    this.fileItemTitle = buildFileViewItemTitle(this.model.view?.title);
    this.fileType = getFileType(this.model.resource?.traits?.mimeType || '', this.model.resource?.name);
    this.init();
    this.buildFooterData();
    this.cdr.markForCheck();
  }

  get model(): Search.ResultResourceItem & DisplayItemData {
    return this._model;
  }

  private _previewWidth: number;
  @Input() set previewWidth(val: number) {
    this._previewWidth = val;
    this.calcHeaderWidth();
    this.cdr.markForCheck();
  }
  get previewWidth(): number {
    return this._previewWidth;
  }
  @Input() isPopup: boolean;
  smallWidthPreview: boolean;
  popup: boolean;
  metadataVisible: boolean;
  icon: UiIconModel;
  loadingError: boolean;
  traits: any;
  createdBy: string;
  modifiedBy: string;
  createAt: string;
  modifiedAt: string;
  size$: Subject<PreviewMode>;
  reload$: Subject<PreviewMode>;
  contextMenuHelper: ResultsResourceItemContextMenuHelper;
  slackApp: boolean;
  isFolder: boolean;
  isDownloadEnabled = true;
  sessionInfo: SessionInfo;
  private readonly generalIcon = '/assets/file-icons/general.svg';
  private timeoutRef;
  private logger: Logger;
  ref: PopupRef<ContextMenuComponent, ContextMenuData>;
  fileItemTitle: FileViewTitle;
  embedInline: boolean;
  isEmbed: boolean = isEmbed();
  hideCloseButtonInEmbed: boolean;

  private get eventResources() {
    return [
      {
        id: this.model?.id,
        appId: this.model?.link?.appId,
        type: this.model?.resource?.type,
        linkId: this.model?.link?.id,
      },
    ];
  }

  get allowOpenUrl() {
    return !['collection-file'].includes(this.model.type);
  }

  constructor(
    private filePreviewService: FilePreviewService,
    private contextMenuService: ContextMenuService,
    private resultCommandBuilder: ResultCommandBuilder,
    private hostRef: ElementRef,
    private logService: LogService,
    private errorHandler: GlobalErrorHandler,
    private cdr: ChangeDetectorRef,
    private downloadsService: DownloadsService,
    private resourcesService: ResourcesService,
    private flagsService: FlagsService,
    private sessionService: SessionService,
    private previewService: PreviewService,
    private resultCommandService: ResultCommandService,
    private hubService: HubService,
    private blobPreviewService: BlobPreviewService,
    private favoritesService: FavoritesService,
    private eventsService: EventsService,
    private embedService: EmbedService
  ) {
    this.size$ = new Subject<PreviewMode>();
    this.reload$ = new Subject<PreviewMode>();
  }

  ngOnInit() {
    this.logger = this.logService.scope(
      `FilePreviewContainerComponent- ${this.model?.view?.title?.text?.substring(0, 10) ?? ''}... -${this.model?.id}`
    );
    firstValueFrom(this.sessionService.current$).then((session) => {
      this.sessionInfo = session;
      Cookies.set('sto', this.sessionInfo.token);
    });
    this.setEmbedFields();
  }

  ngAfterViewInit(): void {
    this.contextMenuHelper = new ResultsResourceItemContextMenuHelper(
      this.resultCommandBuilder,
      this.logger,
      this.contextMenuService,
      this.hostRef,
      this.titleRef,
      this.subtitleRef,
      this.actionsRef,
      this.errorHandler,
      this.resourcesService,
      this.hubService,
      this.flagsService
    );
    this.contextMenuHelper.invoke$.pipe(untilDestroyed(this)).subscribe(({ item, trigger }) => {
      this.emitContextMenuCommand(item, trigger);
    });
    this.calcHeaderWidth();
    this.cdr.markForCheck();
    this.contextMenuHelper.invokeShareMenu$
      .pipe(untilDestroyed(this))
      .subscribe(({ item, trigger }) => this.emitContextMenuCommand(item, trigger));
  }

  async setEmbedFields() {
    if (this.isEmbed) {
      this.embedInline = await this.embedService?.isInline();
      const options = await firstValueFrom(this.embedService.options$);
      const sidebar = options?.popup?.sidebar || options?.sidebar;
      this.hideCloseButtonInEmbed = this.embedInline && sidebar?.style === 'hidden';
    }
  }

  private async init() {
    this.calcMetadataVisibility();
    this.slackApp = this.model.resource?.appId === 'slack';
    this.isFolder = !this.model.view.title?.text?.includes('.');
    const icon = getIconByExtension(this.model.view.title.text, this.model.resource?.appId);
    if (icon === this.generalIcon && this.model.view.iconOverlay) {
      this.icon = getIconModel(this.model.view.iconOverlay as Style.Icon);
    } else {
      this.icon = getIconModel(this.model.view.icon as Style.Icon);
    }
    this.createdBy = '';
    this.modifiedBy = '';

    this.filePreviewService.refreshCacheTimeout();
    this.filePreviewService
      .getPreviewUrl(this.model)
      .then((res) => {
        const cachePreview = this.filePreviewService.getPreviewFromCache(res.model.id);
        if (cachePreview) {
          this.emitPreview({ srcUrl: cachePreview.srcUrl, model: res.model, isFromCache: true });
        } else {
          this.emitPreview({ srcUrl: res.srcUrl || res.model['srcUrl'], model: res.model, isFromCache: false });
        }
      })
      .catch((e) => {
        this.logger.error('got error while trying to get preview url', e.error);
        this.emitPreview({ srcUrl: null, model: e.model, isFromCache: false });
      });

    if (this.model.link) {
      this.flagsService.isEnabled('fileDownloads', this.model.link.id).then((isEnabled) => {
        this.isDownloadEnabled = isEnabled;
      });
    }
  }

  isAvailableSummary(action: Action) {
    return isEnabledSummary(action);
  }

  onSummary(event?: MouseEvent) {
    event.stopPropagation();
    this.eventsService.event('preview.resource_action', {
      label: 'summary',
      location: { title: this.hubService.currentLocation },
      resources: this.eventResources,
    });
    this.invoke.emit({
      command: {
        type: 'summary',
        command: this.model?.view?.title?.onClick,
        position: event,
        resourceId: this.model.id,
        resources: this.eventResources,
      } as Commands.Summary,
      eventInfo: this.telemetrySearch,
      invokerItem: this.model,
    });
  }

  private emitPreview(result: ResultPreviewData) {
    this.filePreviewService.preview$.next({
      srcUrl: result.srcUrl,
      model: result.model,
      isFromCache: result.isFromCache,
    });
  }

  ngOnDestroy(): void {
    const ref = this.contextMenuHelper?.ref;
    if (ref) {
      ref.destroy();
    }
  }

  async openContextMenu(event: MouseEvent, source: ContextMenuInvokeSource) {
    this.ref = await this.contextMenuHelper.open(
      event,
      this.model,
      source,
      'popup',
      false,
      true,
      false,
      false,
      4,
      this.popup ? false : true
    );
    this.ref.close$.pipe(untilDestroyed(this)).subscribe(() => {
      this.ref.destroy();
      this.ref = null;
      this.cdr.markForCheck();
    });
  }

  onSizeChanged(mode: PreviewMode, event?) {
    if (mode !== 'popup') {
      this.onClosePopup.emit();
    }
    this.previewService.setPreviewState(mode, this.model.resultIndex, this.model);
    event?.stopPropagation();
  }

  openUrl(command: Commands.Command, context: Partial<CommandContext> = {}, trigger?: TelemetryTrigger) {
    if (!command) return;
    command.type = 'open-url';
    if (this.isPopup) {
      context = {
        linkId: this.model?.link?.id,
        resource: { id: this.model.id, kind: 'link-resource' },
        item: this.model,
      };
      this.resultCommandService.executeCommand(command, context, 'result', {
        target: trigger,
        ...this.telemetrySearch,
      });
      return;
    }
    this.invoke.emit({ command, context: { ...context, searchId: this.searchId }, trigger, invokerItem: this.model });
  }

  showMetadata(event?: MouseEvent) {
    this.metadataVisible = true;
  }

  hideMetadata() {
    this.metadataVisible = false;
  }

  calcMetadataVisibility() {
    if (!this.metadataVisible) {
      this.showMetadata();
    }
    if (this.timeoutRef) {
      this.clearTimeout();
    }
    if (!this.ref)
      this.timeoutRef = setTimeout(() => {
        this.hideMetadata();
        this.cdr.markForCheck();
      }, 2000);
  }

  private clearTimeout() {
    clearTimeout(this.timeoutRef);
    this.timeoutRef = null;
  }

  private emitContextMenuCommand(item: ResultContextMenuItem, trigger: TelemetryTrigger) {
    this.filePreviewService.contextMenuInvoke$.next({ item, trigger });
    if (item.command.type === 'share') return;
    if (isChangeFavoriteStatusCommand(item.command)) {
      this.model.isFavorite = !this.model.isFavorite;
      if ((<DisplayItemData>this.model).type === 'collection-file') {
        if (this.model.isFavorite) {
          this.favoritesService.create({ id: this.model.id, type: 'collection-item' });
        } else {
          this.favoritesService.delete(this.model.id);
        }
      } else {
        this.resultCommandService.toggleFavorite(this.model, [], trigger, this.searchId);
      }
      this.cdr.markForCheck();
      return;
    }
    const command: InvokeCommand = {
      context: {
        linkId: this.model?.link?.id,
        searchId: this.searchId,
        resource: { id: this.model.id, kind: 'link-resource' },
        item: this.model,
      },
      command: item.command,
      invokerItem: this.model,
      trigger: trigger,
      eventInfo: {
        target: trigger,
        ...this.telemetrySearch,
      },
    };
    if (this.isPopup) {
      this.resultCommandService.executeCommand(command.command, command.context, 'result', {
        target: trigger,
        ...this.telemetrySearch,
      });
      return;
    }
    this.invoke.emit(command);
  }

  async download(url?: Commands.DragCommand) {
    if (this.isFolder) return;
    const icon = getDownloadIcon(this.model.view.icon, this.model.view.iconOverlay);

    if (this.model['srcUrl']) {
      const req: Downloads.UrlDownloadRequest = {
        type: 'Url',
        url: this.model['srcUrl'],
        icon,
      };
      await this.downloadsService.urlDownload(req);
    } else {
      const dragCommand: Commands.DragCommand = url ?? (this.model.view.title as Results.Title).onDrag;
      if (!isDownloadUrlCommand(dragCommand)) return;

      const req: Downloads.ResourceDownloadRequest = {
        type: 'Resource',
        url: dragCommand.url,
        resource: this.model.resource,
        icon,
      };
      await this.downloadsService.resourceDownload(req);
    }
  }

  private buildFooterData() {
    this.traits = this.model.resource?.traits;
    if (!this.traits) return;

    this.createdBy = this.traits.createdBy?.name ? `by ${this.traits.createdBy?.isMe ? 'you' : this.traits.createdBy?.name}` : '';
    this.modifiedBy = this.traits.modifiedBy?.name ? `by ${this.traits.modifiedBy?.isMe ? 'you' : this.traits.modifiedBy?.name}` : '';

    this.createAt = this.getTime(this.traits.createdAt);
    this.modifiedAt = this.getTime(this.traits.modifiedAt);
  }

  private getTime(time: string): string {
    if (!time) return;
    const date = new Date(Date.parse(time));
    return `${date.getDate()} 
            ${moment(date.getMonth() + 1, 'M').format('MMMM')} 
            ${date.getFullYear()} at
            ${moment(date.getHours(), 'h').format('hh')}:${moment(date.getMinutes(), 'm').format('mm')}`;
  }

  buildPreviewCommand(): Commands.Preview<Search.ResultResourceItem> {
    return {
      type: 'preview',
      state: 'popup',
      previewable: true,
      model: this.model,
    };
  }

  onPreviewClick() {
    const ev: Partial<EventInfo> = {
      target: 'preview',
      label: 'open_preview',
      location: { title: this.hubService.currentLocation },
      resources: this.eventResources,
      ...this.telemetrySearch,
    };
    this.invoke.emit({ command: this.buildPreviewCommand(), trigger: 'mouse_click', eventInfo: ev, invokerItem: this.model });
  }
}
