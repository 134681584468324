import { Config } from '@environments/config';
import { Commands } from '@local/client-contracts';
import { isHttpOrHttps } from '@local/ts-infra';
import { PopupRef } from '@local/ui-infra';
import { ContextMenuComponent, ContextMenuData, ContextMenuItem, ContextMenuService } from '@shared/components';
import { EventInfo, EventsService } from '@shared/services';
import { stopEvent } from '@shared/utils/elements-util';
import { isEqual } from 'lodash';
import { take } from 'rxjs';
import { CommandsService } from 'src/app/bar/services/commands/commands.service';
import { HubService } from 'src/app/bar/services/hub.service';
import tinymce, { Editor } from 'tinymce';
import { WikiCardMediaHelper } from '../../../helpers/wiki/wiki-card-media-helper';
import { WikiCardMenuActions } from '../../../helpers/wiki/wiki-card-menu-actions.helper';
import { attachmentsRegex } from '../../../helpers/wiki/wiki-card-utils';
import { CardEditorConstants } from './card-editor.constants';
import { WikiCardFileHelper } from '../../../helpers/wiki/wiki-card-file-helper';

export type CardContextMenuOptions = 'a' | 'hr' | 'attachment' | 'video' | 'code';

export class CardEditorService {
  static readonly FILE_HELPER_NAME = 'file-wiki-card';
  private readonly NEW_EDITOR_LINE = '<p><br></p>';

  //contextmenu
  private contextMenuPopup: PopupRef<ContextMenuComponent, ContextMenuData>;
  private _contextMenuOpened: boolean;

  get contextMenuOpened(): boolean {
    return this._contextMenuOpened;
  }
  get location() {
    return this.hubService.currentLocation;
  }

  getEditorInit(viewMode: boolean) {
    return {
      skin: 'tinymce-5',
      plugins: CardEditorConstants.PLUGINS,
      text_patterns: CardEditorConstants.TEXT_PATTERNS,
      text_patterns_lookup: this.textPatternsLookup,
      icons: 'unleash',
      placeholder: viewMode ? '' : "Type '/' for commands...",
      base_url: '/tinymce',
      suffix: '.min',
      menubar: false,
      toolbar_location: 'top',
      toolbar_mode: 'sliding',
      quickbars_image_toolbar: false,
      quickbars_insert_toolbar: false,

      link_context_toolbar: false,
      link_title: false,
      link_target_list: false,

      imagetools_toolbar: false,
      image_advtab: false,
      image_caption: true,
      image_description: false,

      table_toolbar: '',
      table_advtab: false,
      table_cell_advtab: false,
      table_row_advtab: false,
      table_default_styles: {
        width: '50%',
      },

      resize: false,
      convert_urls: false,
      content_css: ['/assets/auth/colors.css', '/assets/prism.css', '/assets/tinymce-editor.css'].map((e) => Config.baseUrl + e),

      style_formats: CardEditorConstants.STYLE_FORMATS,
      file_picker_types: 'image | media',
      link_assume_external_targets: 'https',
      media_url_resolver: (data: any, resolve, reject) => {
        const VIDEO_URL_PATTERN = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+$/;
        if (!data?.url?.startsWith('blob:') && (!isHttpOrHttps(data?.url || '') || !VIDEO_URL_PATTERN.test(data?.url))) {
          reject({ msg: 'Media embed URL is not valid' });
          return;
        }
        resolve(data);
      },
    };
  }

  constructor(
    private eventsService: EventsService,
    private hubService: HubService,
    private wikiCardMediaHelper: WikiCardMediaHelper,
    private contextMenuService: ContextMenuService,
    private commandsService: CommandsService,
    private wikiCardMenuActions: WikiCardMenuActions,
    private fileHelper: WikiCardFileHelper
  ) {}

  private onActionFile(cardId: string) {
    this.fileHelper.uploadFile(cardId);
  }

  private onActionImage(cardId: string) {
    this.wikiCardMediaHelper.uploadImage(cardId);
  }

  private textPatternsLookup(ctx: any) {
    const parentTag = ctx.block.nodeName.toLowerCase();
    if (parentTag === 'p') {
      return [
        { start: '*', end: '*', format: 'bold' },
        { start: '`', end: '`', format: 'code' },
      ];
    }
    return [];
  }

  private addToolbarButtons(editor: Editor, cardId: string) {
    editor.ui.registry.addButton('fileUpload', {
      tooltip: 'File',
      icon: 'new-document',
      onAction: () => this.onActionFile(cardId),
    });

    editor.ui.registry.addButton('myQuickImage', {
      tooltip: 'Image',
      icon: 'image',
      onAction: () => this.onActionImage(cardId),
    });
  }

  private addContextMenuButtons(editor: Editor, viewMode: boolean) {
    tinymce.PluginManager.add('context-menu-plugin', (editor) => {
      editor.ui.registry.addMenuItem('delete', {
        icon: 'delete',
        text: 'Delete',
        onAction: () => {
          this.wikiCardMenuActions.onDelete();
        },
      });

      editor.ui.registry.addMenuItem('preview', {
        icon: 'preview-menu',
        text: 'Preview',
        onAction: () => {
          this.wikiCardMenuActions.onPreview();
        },
      });

      editor.ui.registry.addMenuItem('download', {
        icon: 'download',
        text: 'Download',
        onAction: async () => {
          this.wikiCardMenuActions.onDownload();
        },
      });

      // editor.ui.registry.addMenuItem('edit', {
      //   icon: 'edit',
      //   text: 'Edit Image',
      //   onAction: () => {
      //     tinymce.activeEditor.execCommand('mceImage');
      //   },
      // });

      const getContextMenuActions = (element) => {
        if (element?.src) {
          if (element?.id) {
            return !viewMode ? 'edit preview copy download delete ' : 'preview download ';
          } else {
            return 'edit copy delete';
          }
        } else {
          return viewMode ? '' : 'table';
        }
      };
      editor.ui.registry.addContextMenu('image', {
        update: (element) => getContextMenuActions(element),
      });
    });
  }

  private insertLists(value: string, type: 'bullet' | 'ordered', event: any, editor: Editor) {
    if (!value.startsWith(`custom-${type}`)) {
      return;
    }
    event.preventDefault();
    editor.execCommand(type === 'bullet' ? 'InsertUnorderedList' : 'InsertOrderedList', false);
  }

  private beforeExecCommand(event: any, editor: Editor) {
    if (this.beforeExecCommandValidateFormatChange(event, editor)) {
      return;
    }
    if (event.command === 'mceToggleFormat') {
      this.insertLists(event.value, 'bullet', event, editor);
      this.insertLists(event.value, 'ordered', event, editor);
    }
  }

  private beforeExecCommandValidateFormatChange(event: any, editor: Editor) {
    const selectedNode = tinymce.activeEditor.selection?.getNode();
    const orderOrBulletChange =
      ['ol', 'ul'].includes(selectedNode?.parentElement?.nodeName.toLocaleLowerCase()) &&
      ['h1', 'h2', 'h3', 'blockquote', 'code', 'p'].includes(event.value);
    const codeChange =
      ['code'].includes(selectedNode?.nodeName.toLocaleLowerCase()) &&
      ['h1', 'h2', 'h3', 'blockquote', 'custom-ordered-list', 'custom-bullet-list'].includes(event.value);
    const blockquoteChange =
      ['blockquote'].includes(selectedNode?.nodeName.toLocaleLowerCase()) &&
      ['h1', 'h2', 'h3', 'code', 'custom-ordered-list', 'custom-bullet-list'].includes(event.value);
    const headingChange =
      ['h1', 'h2', 'h3'].includes(selectedNode?.nodeName.toLocaleLowerCase()) && ['blockquote', 'code'].includes(event.value);
    const paragraphToBlackquoteChange = selectedNode?.nodeName.toLocaleLowerCase() === 'p' && event.value === 'blockquote';

    if (
      event.command === 'mceToggleFormat' &&
      (orderOrBulletChange || codeChange || blockquoteChange || headingChange || paragraphToBlackquoteChange)
    ) {
      const textContent = selectedNode?.textContent;
      const parentElement = selectedNode?.parentElement;
      setTimeout(() => {
        if (headingChange || paragraphToBlackquoteChange || blockquoteChange) {
          selectedNode?.remove();
        } else {
          parentElement.remove();
        }
        switch (event.value) {
          case 'custom-ordered-list':
            editor.insertContent(`<ol><li>${textContent}</li></ol>`);
            break;
          case 'custom-bullet-list':
            editor.insertContent(`<ul><li>${textContent}</li></ul>`);
            break;
          case 'blockquote':
            editor.insertContent(`${textContent}`);
            break;
          case 'code':
            editor.insertContent(`<p><code data-mce-selected="inline-boundary">${textContent}</code></p>`);
            break;
          default:
            editor.insertContent(`<${event.value}>${textContent}</${event.value}>`);
            break;
        }
        editor.save();
        editor.selection.select(editor.selection?.getNode());
      }, 0);
      return true;
    }
    return false;
  }

  private onContextMenu(event: any) {
    const targetElement = event.target as HTMLElement;
    const attachment = event.target.closest('.attachment');
    if (!['img', 'td', 'a'].includes(targetElement.tagName.toLowerCase()) && !attachment) {
      stopEvent(event);
    }
  }

  private onOpenPopup() {
    const dialog = document.querySelector('.tox-dialog');
    if (!dialog) {
      return;
    }
    this.focusInput(dialog);
    const tabs = dialog.getElementsByClassName('tox-dialog__body-nav-item');
    if (!tabs) {
      return;
    }
    for (const tab of tabs) {
      tab.addEventListener('click', () => {
        this.focusInput(dialog);
      });
    }
  }

  private focusInput(dialog: Element) {
    setTimeout(() => {
      const firstInput = dialog.querySelector('input') || dialog.querySelector('textarea');
      if (firstInput) {
        firstInput.focus();
      }
    }, 0);
  }

  private changeColor(element: HTMLElement, editor: Editor) {
    const color = editor.editorContainer.getElementsByClassName('tox-icon-text-color__color')?.[0] as HTMLElement;
    const backgroundColor = editor.editorContainer.getElementsByClassName('tox-icon-highlight-bg-color__color')?.[0] as HTMLElement;
    if (element && color && backgroundColor) {
      color.style.fill = element.style.color || 'var(--color-text-primary)';
      backgroundColor.style.fill = element.style.backgroundColor || 'var(--color-text-primary)';
    }
  }

  initTinyMce(cardId: string, sendCommandTelemetryEvent: (input: string) => void) {
    tinymce.PluginManager.add('slashcommands', (editor) => {
      const insertActions = [
        {
          text: 'Paragraph',
          icon: 'visualchars',
          action: function () {
            editor.execCommand('FormatBlock');
            sendCommandTelemetryEvent('Paragraph');
          },
        },
        {
          text: 'Heading 1',
          icon: 'h1',
          action: function () {
            editor.execCommand('mceInsertContent', false, '<h1>Heading 1</h1>');
            editor.selection.select(editor.selection?.getNode());
            sendCommandTelemetryEvent('Heading 1');
          },
        },
        {
          text: 'Heading 2',
          icon: 'h2',
          action: function () {
            editor.execCommand('mceInsertContent', false, '<h2>Heading 2</h2>');
            editor.selection.select(editor.selection?.getNode());
            sendCommandTelemetryEvent('Heading 2');
          },
        },
        {
          text: 'Heading 3',
          icon: 'h3',
          action: function () {
            editor.execCommand('mceInsertContent', false, '<h3>Heading 3</h3>');
            editor.selection.select(editor.selection?.getNode());
            sendCommandTelemetryEvent('Heading 3');
          },
        },
        {
          type: 'separator',
        },
        {
          text: 'Bulleted list',
          icon: 'unordered-list',
          action: function () {
            editor.execCommand('InsertUnorderedList', false);
            sendCommandTelemetryEvent('Bulleted list');
          },
        },
        {
          text: 'Numbered list',
          icon: 'ordered-list',
          action: function () {
            editor.execCommand('InsertOrderedList', false);
            sendCommandTelemetryEvent('Numbered list');
          },
        },
        {
          type: 'separator',
        },
        {
          text: 'File',
          icon: 'new-document',
          action: () => {
            this.onActionFile(cardId);
            sendCommandTelemetryEvent('File');
          },
        },
        {
          text: 'Link',
          icon: 'Link',
          action: function () {
            editor.execCommand('mceLink');
            sendCommandTelemetryEvent('Link');
          },
        },
        {
          text: 'Image',
          icon: 'Image',
          action: () => {
            this.wikiCardMediaHelper.uploadImage(cardId);
            sendCommandTelemetryEvent('Image');
          },
        },
        {
          text: 'Media',
          icon: 'embed',
          action: function () {
            editor.execCommand('mceMedia');
            sendCommandTelemetryEvent('Media');
          },
        },
        {
          text: 'Table',
          icon: 'table',
          action: function () {
            editor.execCommand('mceInsertTable', false, { rows: 2, columns: 2 });
            sendCommandTelemetryEvent('Table');
          },
        },
        {
          text: 'Emoji',
          icon: 'emoji',
          action: function () {
            editor.execCommand('mceEmoticons');
            sendCommandTelemetryEvent('Emoji');
          },
        },
        {
          text: 'Divider',
          icon: 'horizontal-rule',
          action: function () {
            editor.execCommand('InsertHorizontalRule');
            sendCommandTelemetryEvent('Divider');
          },
        },
        {
          type: 'separator',
        },
        {
          text: 'Quote',
          icon: 'quote',
          action: function () {
            editor.execCommand('mceBlockQuote');
            sendCommandTelemetryEvent('Quote');
          },
        },
        {
          text: 'Code',
          icon: 'code-sample',
          action: function () {
            editor.execCommand('CodeSample');
            sendCommandTelemetryEvent('Code');
          },
        },
      ];

      // Register the slash commands autocompleter
      editor.ui.registry.addAutocompleter('slashcommands', {
        ch: '/',
        minChars: 0,
        columns: 1,
        fetch: (pattern) => {
          this.eventsService.event('commands_menu.open', {
            target: 'keyboard',
            location: { title: this.location },
          });

          const matchedActions = insertActions.filter(function (action) {
            return action.type === 'separator' || action.text.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;
          });

          const results = matchedActions.filter((action) => action.type !== 'separator')?.length;
          if (!results) {
            matchedActions.push({
              text: 'No results',
              icon: null,
              action: null,
            });
          }

          return new Promise((resolve) => {
            const results = matchedActions.map((action) => {
              return {
                meta: action,
                text: action.text,
                icon: action.icon,
                value: action.text,
                type: action.type,
              };
            });
            resolve(<any>results);
          });
        },
        onAction: (autocompleteApi, rng, action, meta) => {
          editor.selection.setRng(rng);
          // Some actions don't delete the "slash", so we delete all the slash
          // command content before performing the action
          editor.execCommand('Delete');
          meta.action();
          autocompleteApi.hide();
        },
      });
      return {};
    });
  }

  setupTinyMce(editor: Editor, viewMode: boolean, cardId: string) {
    this.addToolbarButtons(editor, cardId);
    this.addContextMenuButtons(editor, viewMode);
    let isDialogOpen = false;
    editor.on('OpenWindow', () => {
      isDialogOpen = true;
      this.onOpenPopup();
    });
    editor.on('CloseWindow', () => {
      isDialogOpen = false;
    });
    editor.on('BeforeExecCommand', (e) => this.beforeExecCommand(e, editor));
    editor.on('contextmenu', this.onContextMenu);
    editor.on('auxclick', this.onContextMenu);
    editor.on('init', () => {
      document.addEventListener('click', (e: any) => {
        if (isDialogOpen && !e?.target?.closest('.tox-dialog')) {
          editor.windowManager.close();
          isDialogOpen = false;
        }
      });
      editor.contentDocument.documentElement.style.cssText = document.documentElement.style.cssText;
      editor.contentDocument.body.style.cssText = document.body.style.cssText;
    });
    editor.on('NodeChange', (e) => {
      if (e.element && e.element.nodeName === 'HR') {
        e.element.setAttribute('contenteditable', 'false');
      }
      this.changeColor(e.element as HTMLElement, editor);
    });
  }

  addNewLineToEnd(event, editor: Editor) {
    // get the node your cursor is in - the one you want to insert after
    const endNode = editor.selection.getEnd();
    const endElement = editor.selection?.getNode();
    const lastChildElem = editor.contentDocument.body.lastElementChild;

    if (endNode.tagName.toLowerCase() === 'br' && endElement === lastChildElem) {
      return;
    }
    const rect = lastChildElem.getBoundingClientRect();
    if (event.y > rect.top + rect.height) {
      if (['table', 'div'].includes(lastChildElem.nodeName.toLocaleLowerCase())) {
        const currentContent = editor.getContent();
        editor.setContent(`${currentContent}${this.NEW_EDITOR_LINE}`);
        this.moveSelectionToEnd(editor);
      } else if (lastChildElem.querySelector('video')) {
        tinymce.activeEditor.selection.setCursorLocation(lastChildElem.nextElementSibling, 0);
        lastChildElem.insertAdjacentHTML('afterend', this.NEW_EDITOR_LINE);
        tinymce.activeEditor.selection.setCursorLocation(lastChildElem.nextElementSibling, 0);
        tinymce.activeEditor.focus();
      } else {
        this.moveSelectionToEnd(editor);
        editor.execCommand('mceInsertNewLine');
      }
    }
  }

  private moveSelectionToEnd(editor: Editor) {
    editor.selection.select(editor.getBody(), true);
    editor.selection.collapse(false);
  }

  editorOnPasteEvent(event, editor: Editor, cardId: string) {
    const clipboardData = event.clipboardData;
    if (!clipboardData || !clipboardData.items) {
      return;
    }
    // Check if there are files in the clipboard data
    for (const item of clipboardData.items) {
      if (['text/plain', 'text/html'].includes(item.type)) return;
      // Check if the item is an image
      const supported = this.wikiCardMediaHelper.isSupportMediaUploadFile(item.type);
      const file = item.getAsFile();
      if (supported) {
        event.preventDefault();
        if (!this.wikiCardMediaHelper.validateMediaFileSize(file.size)) {
          return;
        }
        // Now you can handle the pasted image file
        this.wikiCardMediaHelper.handleAddedMedia(file, cardId, editor, null, supported);
      } else {
        // add attachment
        this.fileHelper.uploadAttachment(file, cardId);
      }
    }
  }

  editorOnDropEvent(event, editor: Editor, cardId: string) {
    // Prevent the browser's default handling of the drop event
    // Handle other types of dropped content

    const files = event.dataTransfer.files;
    const types = event.dataTransfer.types;
    if (
      isEqual(['text/plain', 'text/html'], types) ||
      isEqual(['text/plain', 'text/uri-list', 'text/html'], types) ||
      isEqual(['text/plain', 'text/uri-list', 'text/html', 'Files'], types) ||
      isEqual(['text/html'], types)
    ) {
      const droppedElement = event.dataTransfer.getData('text/html');
      const selectedNode = editor.selection.getNode();
      if (droppedElement?.includes(`class="${CardEditorConstants.CLASS_ATTACHMENT}"`)) {
        const attachId = droppedElement.match(attachmentsRegex.EXTRACT_ID_RXJS)[1];
        editor.getDoc().getElementById(attachId).remove();
        if (selectedNode?.innerText !== '\n' && selectedNode?.innerText?.length) {
          this.addNewLineBeforeDropElement(editor, event);
        }
        editor.selection.setCursorLocation(selectedNode, 0);
        editor.insertContent(droppedElement);
        editor.save();
        event.preventDefault();
        return;
      } else if (droppedElement.includes('<video ') && event.target.textContent && event.target.nodeName !== 'BODY') {
        if (droppedElement.includes(selectedNode?.querySelector('source')?.src)) {
          event.preventDefault();
          return;
        }
        this.addNewLineBeforeDropElement(editor, event);
      } else if (droppedElement.includes('<img ')) {
        this.addNewLineBeforeDropElement(editor, event);
        (selectedNode?.parentNode as HTMLElement).remove();
        editor.selection.select(event.target, true);
        editor.selection.collapse(false);
        if (event.target.textContent) {
          editor.insertContent(this.NEW_EDITOR_LINE);
          editor.insertContent(`<p>${droppedElement}</p>`);
          event.preventDefault();
        } else {
          editor.insertContent(`<p>${droppedElement}</p>`);
          event.preventDefault();
        }
      } else {
        //hr element
        if (event.target.textContent && event.target.nodeName !== 'BODY') {
          this.addNewLineBeforeDropElement(editor, event);
        }
      }
      return;
    }
    if (files?.length > 0) {
      event.preventDefault();
      for (const file of files) {
        const supported = this.wikiCardMediaHelper.isSupportMediaUploadFile(file.type);
        if (supported) {
          if (!this.wikiCardMediaHelper.validateMediaFileSize(file.size)) {
            continue;
          }
          // Now you can handle the pasted image file
          this.wikiCardMediaHelper.handleAddedMedia(file, cardId, editor, null, supported);
        } else {
          // add attachment
          this.fileHelper.uploadAttachment(file, cardId);
        }
      }
    }
  }

  addNewLineBeforeDropElement(editor: Editor, event) {
    editor.selection.select(event.target, true);
    editor.selection.collapse(false);
    editor.insertContent(this.NEW_EDITOR_LINE);
    editor.save();
  }

  canFocusOut() {
    const dialog = document.getElementsByClassName(CardEditorConstants.CLASS_EDITOR_DIALOG);
    const bigDialog = document.getElementsByClassName(CardEditorConstants.CLASS_EDITOR_BIG_DIALOG);
    const contextMenu = document.getElementsByClassName(CardEditorConstants.CLASS_EDITOR_CONTEXT_MENU);
    const tieredMenu = document.getElementsByClassName(CardEditorConstants.CLASS_EDITOR_TIERED_MENU);

    return !dialog.length && !contextMenu.length && !tieredMenu.length && !bigDialog.length && !this.fileHelper?.renameAttachmentPopupOpen;
  }
  //context menus

  openHrContextMenu(event: { x: number; y: number }, data: { selectedElement: HTMLElement; editor: Editor }, locationTitle: string) {
    const items = this.buildHrContextMenuItems(data);
    const onInvoke = (item: ContextMenuItem) => this.handleContextMenuCommand(item, locationTitle);
    return this.openExternalContextMenu(event, items, onInvoke, data.selectedElement);
  }

  openLinkContextMenu(event: { x: number; y: number }, data: { selectedElement; editor: Editor }, locationTitle: string) {
    const items = this.buildLinkContextMenuItems(data);
    const onInvoke = (item: ContextMenuItem) => this.handleContextMenuCommand(item, locationTitle);
    return this.openExternalContextMenu(event, items, onInvoke, data.selectedElement);
  }

  openCodeContextMenu(event: { x: number; y: number }, data: { selectedElement; editor: Editor }, locationTitle: string) {
    const items = this.buildCodeContextMenuItems(data);
    const onInvoke = (item: ContextMenuItem) => this.handleContextMenuCommand(item, locationTitle);
    return this.openExternalContextMenu(event, items, onInvoke, data.selectedElement);
  }

  private openExternalContextMenu(
    event: { x: number; y: number },
    items: ContextMenuItem[],
    onInvoke: (item: ContextMenuItem) => void,
    selectedElement: HTMLElement
  ) {
    if (this.contextMenuPopup) {
      this.contextMenuPopup.destroy();
    }
    this._contextMenuOpened = true;
    this.contextMenuPopup = this.contextMenuService.open(
      event,
      {
        items,
        onInvoke,
        minWidthItem: 90,
      },
      { position: 'right' }
    );

    setTimeout(() => {
      selectedElement.setAttribute(CardEditorConstants.CLASS_TINYMCE_FOCUS_BORDER, '1');
    }, 0);

    this.contextMenuPopup.destroy$.pipe(take(1)).subscribe(() => {
      this._contextMenuOpened = false;
    });
    return this.contextMenuPopup;
  }

  private buildLinkContextMenuItems(data: any): ContextMenuItem[] {
    const contextMenuItems: ContextMenuItem[] = [];
    contextMenuItems.push(
      {
        id: 'link',
        text: 'Edit Link',
        data,
        command: { type: 'link' },
        icon: { type: 'font-icon', value: 'icon-link' },
      },
      {
        id: 'unlink',
        text: 'Unlink',
        data,
        command: { type: 'unlink' },
        icon: { type: 'font-icon', value: 'icon-unlink' },
      },
      {
        id: 'openLink',
        text: 'Open',
        data,
        command: { type: 'openLink' },
        icon: { type: 'font-icon', value: 'icon-external-link' },
      }
    );
    return contextMenuItems;
  }

  private buildHrContextMenuItems(data: any): ContextMenuItem[] {
    const contextMenuItems: ContextMenuItem[] = [];
    contextMenuItems.push(
      {
        id: 'copy',
        text: 'Copy',
        data,
        command: { type: 'copy' },
        icon: { type: 'font-icon', value: 'icon-copy2' },
      },
      {
        id: 'duplicate',
        text: 'Duplicate',
        data,
        command: { type: 'duplicate' },
        icon: { type: 'font-icon', value: 'icon-copy1' },
      },
      {
        id: 'delete',
        text: 'Delete',
        data,
        command: { type: 'delete' },
        icon: { type: 'font-icon', value: 'icon-delete' },
      }
    );
    return contextMenuItems;
  }

  private buildCodeContextMenuItems(data: any): ContextMenuItem[] {
    const contextMenuItems: ContextMenuItem[] = [];
    contextMenuItems.push({
      id: 'copy',
      text: 'Copy',
      data,
      command: { type: 'copy' },
      icon: { type: 'font-icon', value: 'icon-copy2' },
    });
    return contextMenuItems;
  }

  private async handleContextMenuCommand(command: ContextMenuItem, locationTitle: string) {
    const selectedElement = command.data.selectedElement;
    this.sendEvent('context_menu.open', locationTitle, { label: command.id });
    switch (command.id) {
      case 'delete':
        selectedElement.remove();
        break;
      case 'copy':
        tinymce.activeEditor.execCommand('Copy');
        break;
      case 'duplicate':
        selectedElement.insertAdjacentElement('afterend', selectedElement.cloneNode(true));
        setTimeout(() => {
          command.data.editor.save();
        }, 0);
        break;
      case 'link':
        setTimeout(() => {
          command.data.editor.execCommand('mceLink');
        }, 100);
        break;
      case 'unlink':
        setTimeout(() => {
          command.data.editor.execCommand('unlink');
        }, 100);
        break;
      case 'openLink':
        const open: Commands.OpenUrl = { type: 'open-url', url: selectedElement.href };
        this.commandsService.executeCommand(open, {});
        break;
    }
    this.sendEvent('results.action', locationTitle, { label: command.id, target: 'mouse_click' });
  }

  sendEvent(key: string, locationTitle: string, info: Partial<EventInfo>): void {
    this.eventsService.event(key, {
      ...info,
      location: { title: locationTitle },
    });
  }
}
