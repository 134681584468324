import { Injectable } from '@angular/core';
import { Keyboard } from '@local/client-contracts';
import { bindingEqual, DEFAULT_KEYBOARD_SHORTCUTS } from '@local/common';
import { eventToKeys, KeyName, normalizeKeys } from '@local/ts-infra';
import { Logger } from '@unleash-tech/js-logger';
import { invoker } from '@unleash-tech/js-rpc';
import { fromEvent, map, Observable } from 'rxjs';
import * as uuid from 'uuid';
import { LogService, NativeMainRpcService } from '.';
import { GlobalErrorHandler } from '../../../app/global-error-handler';
import { PreferencesService } from './preferences.service';

type Registry = KeyBindingEntry[];
interface KeyBindingEntry {
  id: string;
  handler: KeyBindingHandler;
  priority: number;
}
export interface Shortcuts {
  exclude: string[];
  video: string[];
  favorite: string[];
  open: string[];
  copy: string[];
  download: string[];
  share: string[];
  openPreview: string[];
}

export interface CustomKeyboardEvent extends KeyboardEvent {
  propagationStopped: boolean;
  immediatePropagationStopped: boolean;
}

export type KeyBindingHandler = (keysPressed: KeyName[], event?: CustomKeyboardEvent) => void;
export class KeyboardRpcInvoker {
  @invoker
  stopListening(v: boolean): Promise<void> {
    return;
  }
}

@Injectable({
  providedIn: 'root',
})
export class KeyboardService {
  private _stopListening: boolean;
  private _preventKeyboard: boolean;
  private _keyBindingHandlers: Registry = [];
  private logger: Logger;
  keyboardRpc: KeyboardRpcInvoker;

  public get preventKeyboard(): boolean {
    return this._preventKeyboard;
  }

  public set preventKeyboard(v: boolean) {
    this._preventKeyboard;
  }

  get stopListening(): boolean {
    return this._stopListening;
  }

  set stopListening(value: boolean) {
    this._stopListening = value;

    this.keyboardRpc?.stopListening(value);
  }

  constructor(
    private errorHandler: GlobalErrorHandler,
    private preferences: PreferencesService,
    private nativeMain: NativeMainRpcService,
    private loggerService: LogService
  ) {
    this.keyboardRpc = this.nativeMain?.invokeWith(KeyboardRpcInvoker, 'keyboard');

    this.logger = loggerService.scope('KeyboardService');
    this.initKeyboardListener();
  }

  /** @return callback id, used to unregister */
  public registerKeyHandler(handler: KeyBindingHandler, priority = 0): string {
    const id = uuid.v4();
    this._keyBindingHandlers.unshift({ id, handler, priority });
    return id;
  }

  public unregisterKeyHandler(handlerId: string) {
    if (!handlerId) return;
    const i = this._keyBindingHandlers.findIndex((h) => h.id === handlerId);
    if (i > -1) this._keyBindingHandlers.splice(i, 1);
  }

  simulateClick(event: CustomKeyboardEvent) {
    this.innerKeyboardCallback(event);
  }

  private initKeyboardListener() {
    fromEvent<CustomKeyboardEvent>(window, 'keydown').subscribe((event) => this.innerKeyboardCallback(event));
  }

  private innerKeyboardCallback(event: CustomKeyboardEvent) {
    if (!this._keyBindingHandlers.length) {
      return;
    }
    const sortedHandlers = [...this._keyBindingHandlers].sort(({ priority: a }, { priority: b }) => b - a);

    event = this.overrideEventPropagations(event);

    if (this.preventKeyboard) return;

    const keys = eventToKeys(event);

    // HACK: for some reason we are getting alt event every time the window is opened
    if (keys.length === 1 && (keys[0] === 'alt' || <string>keys[0] === 'AltGraph')) return;

    for (const { handler } of sortedHandlers) {
      try {
        if (event.propagationStopped) break;
        handler(keys, event);
      } catch (e) {
        this.errorHandler.error = e;
      }
    }
  }

  private overrideEventPropagations(event: CustomKeyboardEvent): CustomKeyboardEvent {
    event.propagationStopped = false;

    const originStopPropagation = event.stopPropagation.bind(event);
    event.stopPropagation = () => {
      event.propagationStopped = true;
      originStopPropagation();
    };
    return event;
  }

  get(id: string): Observable<Keyboard.Shortcut> {
    return this.preferences.current$.pipe(map(() => DEFAULT_KEYBOARD_SHORTCUTS[id]));
  }

  async bindingTaken(keys: string[]): Promise<boolean> {
    return Object.values(DEFAULT_KEYBOARD_SHORTCUTS)
      .map((x) => x.keys)
      .some((k) => bindingEqual(k, keys));
  }

  public async loadShortcuts<T>(categoryList: string[]): Promise<T> {
    const shortcuts = Object.values(DEFAULT_KEYBOARD_SHORTCUTS).filter((s) => {
      return s.keys && categoryList.filter((c) => s.id.includes(c)).length > 0;
    });
    const r = {};
    for (const s of shortcuts) {
      r[s.id.split('_')[2]] = normalizeKeys(<string[]>s.keys);
    }
    return <T>r;
  }

  disableDocumentEvents() {
    const S_KEYWORD_NUMBER = 83;
    document.addEventListener(
      'keydown',
      (e) => {
        const keyCode = e.keyCode || e.which;
        if (e.ctrlKey && keyCode == S_KEYWORD_NUMBER) {
          // PREVENT CTRL + S
          e.preventDefault();
          return false;
        }
      },
      false
    );
  }
}
