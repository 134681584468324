import { Expert, MemorySearch, People, Search } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class PeopleRpcInvoker implements People.Service {
  @invoker
  get details$(): Observable<People.PersonDetails[]> {
    return;
  }

  @invoker
  get people$(): Observable<People.PersonContext[]> {
    return;
  }

  @invoker
  search(request: People.SearchRequest): Promise<People.SearchResponse> {
    return;
  }

  @invoker
  nextPage(pageToken: string): Promise<People.SearchResponse> {
    return;
  }

  @invoker
  getPeople(externalIds?: string[], emails?: string[]): Promise<Observable<People.PersonContext[]>> {
    return;
  }

  @invoker
  getPerson(externalId?: string, email?: string): Promise<Observable<People.PersonContext>> {
    return;
  }

  @invoker
  getExperts(searchRequest: Expert.GetExpertsRequest): Promise<Observable<Expert.GetExpertsContext>> {
    return;
  }
}
