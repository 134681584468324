import { ElementRef, Injectable } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';

export type CollectionItem = { displayIndex?: number };

@Injectable({
  providedIn: 'root',
})
export class InfiniteScrollService {
  private readonly MIN_SCROLL_PERCENTAGE = 20;
  readonly MAX_RESULTS_LIMIT = 100;
  readonly NEXT_PAGE_MIN_COUNT = 20;

  scrollToStart(scrollbarRef: NgScrollbar) {
    scrollbarRef.scrollTo({ top: 0, duration: 500 });
  }

  scrollIfNeeded(selectedItemRef: ElementRef) {
    if (!selectedItemRef || !selectedItemRef.nativeElement) {
      return;
    }
    const selectedElement = selectedItemRef.nativeElement;

    const parent = selectedElement.parentElement?.parentElement;

    const parentRect = parent.getBoundingClientRect();
    const elemRect = selectedElement.getBoundingClientRect();

    // Check if the selected element is above the visible area
    if (elemRect.top < parentRect.top) {
      parent.scrollTop -= parentRect.top - elemRect.top;
    }

    // Check if the selected element is below the visible area
    if (elemRect.bottom > parentRect.bottom) {
      parent.scrollTop += elemRect.bottom - parentRect.bottom;
    }
  }

  handleScroll($event) {
    // Handle the scroll start event (20% after the start)
    const scrollContainer = $event.srcElement;
    const scrollPercentage = (scrollContainer.scrollTop / (scrollContainer.scrollHeight - scrollContainer.clientHeight)) * 100;
    if (scrollPercentage < this.MIN_SCROLL_PERCENTAGE) {
      return false;
    }
    return true;
  }
}
