import { Injectable } from '@angular/core';
import { PopupRef, PopupService } from '@local/ui-infra';
import {
  ShareOptionsPermissionsComponent,
  ShareOptionsPermissionsModel,
} from '../components/share-options-permissions/share-options-permissions.component';
import { take, takeUntil } from 'rxjs';
import { Permissions } from '@local/client-contracts';
import { isEqual, omit } from 'lodash';

@Injectable()
export class ShareOptionsService {
  private readonly SHARE_OPTIONS_PROPERTIES = ['shareOptions', 'isShared', 'effectiveScopes', 'modifiedAt', 'modifiedBy'];
  shareOptionsPopupRef: PopupRef<ShareOptionsPermissionsComponent, ShareOptionsPermissionsModel>;

  constructor(private popupService: PopupService) {}

  openShareOptionPopup(shareOptionsModel: ShareOptionsPermissionsModel, onUpdateShareOptions: Function) {
    if (this.shareOptionsPopupRef) {
      this.shareOptionsPopupRef.destroy();
    }
    this.shareOptionsPopupRef = this.popupService.open<ShareOptionsPermissionsComponent, ShareOptionsPermissionsModel>(
      'center',
      ShareOptionsPermissionsComponent,
      {
        ...shareOptionsModel,
      },
      { closeOnClickOut: true, backdropStyle: 'blur-2', panelClass: 'share-options-permissions-popup' }
    );
    this.shareOptionsPopupRef.compInstance.closePopup.pipe(takeUntil(this.shareOptionsPopupRef.destroy$)).subscribe((value) => {
      onUpdateShareOptions(value.target, value.shareOptions);
    });
    this.shareOptionsPopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.shareOptionsPopupRef = null;
    });
    return this.shareOptionsPopupRef;
  }

  closeShareOptionsPopup() {
    this.shareOptionsPopupRef.destroy();
    this.shareOptionsPopupRef = null;
  }

  // true - equal , false - not equal
  compareShareOptions(prevShareOptions: Permissions.ShareOptions, newShareOptions: Permissions.ShareOptions): boolean {
    if (prevShareOptions?.level !== newShareOptions?.level) {
      return false;
    }
    if (prevShareOptions?.permissions?.length === 0 && !prevShareOptions?.permissions) return false;
    return isEqual(prevShareOptions?.permissions, newShareOptions?.permissions);
  }

  compareWithoutShareOptions(prev: any, current: any) {
    const prevItem = omit(prev, this.SHARE_OPTIONS_PROPERTIES);
    const currentItem = omit(current, this.SHARE_OPTIONS_PROPERTIES);

    return isEqual(prevItem, currentItem);
  }
}
