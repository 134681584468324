import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { People, Search } from '@local/client-contracts';
import { isNativeWindow, isEmbed } from '@local/common-web';
import { DynamicComponentBase, PopupRef, UiIconModel } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EmbedService } from '@shared/embed.service';
import { LoaderService } from '@shared/loader.service';
import { EventInfo, WindowService } from '@shared/services';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { ResourcesService } from '@shared/services/resources.service';
import { windowSizeObserver } from '@shared/utils';
import { getModifiers, isEnterKey, KeyName, removeModifiers } from '@local/ts-infra';
import { BehaviorSubject, filter } from 'rxjs';
import { ResultCommandService } from 'src/app/bar/services/commands/result-command.service';
import { ResultPopupData } from 'src/app/bar/services/preview.service';
import { ResultsService } from 'src/app/bar/services/results.service';
import { CommandContext, DisplayItemData, SearchResults } from 'src/app/bar/views/results/models/results-types';
import { PeopleService } from '../../services/people.service';
import { PreviewType } from 'src/app/bar/views/results/models/view-filters';
import { isRelevantPeopleItem } from 'src/app/bar/views/results';

@UntilDestroy()
@Component({
  selector: 'people-preview-popup',
  templateUrl: './people-preview-popup.component.html',
  styleUrls: ['./people-preview-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeoplePreviewPopupComponent implements OnInit, OnDestroy, DynamicComponentBase<any> {
  current: People.PersonContext;
  readonly closeIcon: UiIconModel = {
    type: 'font',
    value: 'icon-Windows-close',
  };
  readonly SMALL_SCREEN = 910;
  error$ = new BehaviorSubject(false);
  private firstPerson = true;
  private keyHandlerId: string;
  popupData: ResultPopupData;
  private windowSize$ = windowSizeObserver();
  widthScreen: number;
  private get resources() {
    return [
      {
        id: this.current.details.source?.id,
        list: 'cloud_search_results',
        appId: this.current.details.source?.link?.appId,
        type: this.current.details.source?.resource?.type,
        position: this.model.index,
        linkId: this.current.details.source?.link?.id,
      },
    ];
  }
  windowMode = false;
  private ref: PopupRef<PeoplePreviewPopupComponent, ResultPopupData>;
  model: ResultPopupData;
  data: ResultPopupData;
  componentFather: any;
  @Output() loaded = new EventEmitter<any>();
  previewType: PreviewType;
  embedInline: boolean;
  isEmbed: boolean = isEmbed();

  private get id() {
    return this.model?.id || this.model?.item?.resource?.externalId;
  }

  constructor(
    private peopleService: PeopleService,
    private cdr: ChangeDetectorRef,
    private keyboardService: KeyboardService,
    private resourcesService: ResourcesService,
    private resultCommandService: ResultCommandService,
    private activeRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private injector: Injector,
    private windowService: WindowService,
    private embedService: EmbedService,
    private resultsService: ResultsService
  ) {
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys: Array<KeyName>, event) => this.handleKeys(keys, event), 8);
  }

  ngOnInit() {
    this.windowMode = this.activeRoute.snapshot.data.windowMode;

    if (this.windowMode) {
      this.setEmbedInline();
      this.loaderService.ready$.next(true);
      this.model = this.data;
    } else {
      this.ref = this.injector.get(PopupRef);
      this.model = this.ref.data;
    }
    const item = this.model.item as DisplayItemData;
    this.previewType = item.action.type as PreviewType;

    this.windowSize$.pipe(untilDestroyed(this)).subscribe((res) => {
      setTimeout(() => {
        this.widthScreen = res.width;
        this.cdr.markForCheck();
      }, 0);
    });

    this.initData();
  }

  async initData() {
    if (isRelevantPeopleItem(this.model.item)) {
      await this.addActionToItems(this.model.item.expert.contributions);
      this.current = this.model.item.expert;
      (this.current.details.source as SearchResults).action = { type: 'relevant-people' };
      this.cdr.markForCheck();
    } else {
      this.initFirstPerson();
    }
  }

  async addActionToItems(items: Search.ResultResourceItem[]) {
    for (const item of items) {
      (item as SearchResults).action = await this.resultsService.getResultAction(item);
    }
  }

  ngOnDestroy(): void {
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
  }

  async setEmbedInline() {
    if (this.isEmbed) {
      this.embedInline = await this.embedService?.isInline();
    }
  }

  private initFirstPerson() {
    this.current = null;
    this.error$.next(false);
    this.initPersonFromServer(this.id);
  }

  private initPersonFromServer(id: string) {
    let existData = false;
    this.peopleService.getPerson(id).then((val) => {
      val
        .pipe(
          untilDestroyed(this),
          filter((val) => !!val)
        )
        .subscribe(
          async (person) => {
            this.current = person;
            (this.current.details.source as SearchResults).action = await this.resultsService.getResultAction(this.current.details.source);
            if (!existData) {
              this.sendInitialEvent();
              existData = true;
            }
            this.cdr.markForCheck();
            if (this.firstPerson) {
              this.firstPerson = false;
            }
          },
          () => {
            this.error$.next(true);
          }
        );
    });
  }

  closePopUp() {
    this.ref?.close();
    this.peopleService.popupRef?.close();
    if (this.windowMode) {
      if (isNativeWindow()) this.windowService.close();
      else if (this.isEmbed) this.embedService.close();
    }
  }

  tryAgain(): void {
    this.current = null;
    this.error$.next(false);
    this.cdr.markForCheck();
    if (this.firstPerson) {
      this.initPersonFromServer(this.id);
    } else {
      if (this.popupData) {
        this.initPersonFromServer(this.popupData.id);
      }
    }
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent) {
    const key = keys[0];
    if (key === 'escape') {
      this.ref?.close();
      this.peopleService.popupRef?.close();
      this.keyboardService.stopListening = true;
      return;
    }
    const modifiers = getModifiers(keys);
    const nonModifiers = removeModifiers([...keys]);
    if (key === 'tab') {
      event.stopPropagation();
      event.preventDefault();
    }
    if (modifiers.length === 1 && modifiers[0] === 'shift' && nonModifiers.length === 1 && isEnterKey(nonModifiers[0])) {
      event.stopPropagation();
      this.openUrl('keyboard', true);
      return;
    }
    if (isEnterKey(key)) {
      event.stopPropagation();
      this.openUrl('keyboard');
    }
  }

  private async openUrl(trigger: 'mouse_click' | 'keyboard', shiftKey?: boolean) {
    const person = this.current;
    const context: CommandContext = {
      item: person.details.source,
      appId: person.details.source.resource.appId,
      searchId: this.model.searchId,
    };
    if (shiftKey) {
      const openWith = await this.resourcesService.openWith(person.details.source.resource?.id);
      if (openWith) {
        context.openWith = openWith === 'app' ? 'browser' : 'app';
      }
    }
    this.resultCommandService.executeCommand(person.details.source.view.title.onClick, context, 'person', {
      target: trigger,
      location: { title: this.peopleService.currentLocation },
      resources: this.resources,
    });
  }

  private sendInitialEvent() {
    const eventInfo: Partial<EventInfo> = {
      location: { title: this.peopleService.currentLocation },
      resources: this.resources,
      label: 'popup',
      target: this.model?.trigger,
    };
    this.resultCommandService.executeCommand(
      { ...this.current.details.source.view.title.onClick, type: 'preview' },
      { searchId: this.model.searchId, item: this.current.details.source },
      'person',
      eventInfo
    );
  }
}
