import { Injectable } from '@angular/core';
import { BrowserHistoryRpcInvoker, BrowserHistorySearchRequest, BrowserHistorySearchResponse } from '@local/common';
import { BrowserHistoryProxyRpcHandler } from '@shared/browser-history-proxy-rpc-handler';
import { handler } from '@unleash-tech/js-rpc';
import { Observable, ReplaySubject } from 'rxjs';
import { BrowserExtensionService } from './browser-extension.service';
import { ServicesRpcService } from './rpc.service';

@Injectable({
  providedIn: 'root',
})
export class BrowserHistoryService {
  private bhProxy = new BrowserHistoryProxyRpcHandler();
  public ready$: ReplaySubject<boolean>;

  constructor(private services: ServicesRpcService, extensionService: BrowserExtensionService) {
    const rd$ = (this.ready$ = new ReplaySubject<boolean>(1));

    extensionService.current$.subscribe(async (c) => {
      this.bhProxy.inner = c?.invokeWith(BrowserHistoryRpcInvoker, 'browserhistory');
      this.ready$.next(!!this.bhProxy.inner);
    });

    class BrowserHistoryProxyStateRpcHandler {
      @handler
      get ready$(): Observable<boolean> {
        return rd$;
      }
    }

    this.services.handleWith(new BrowserHistoryProxyStateRpcHandler(), 'browserhistoryproxystate');
    this.services.handleWith(this.bhProxy, 'browserhistory');
  }

  getInterestingApps(extraDomains?: string[]): Promise<any[]> {
    return this.bhProxy.getInterestingApps(extraDomains);
  }

  search(r: BrowserHistorySearchRequest): Promise<BrowserHistorySearchResponse> {
    return this.bhProxy.search(r);
  }
}
